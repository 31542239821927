import React, { useState } from "react";

import RecipientQuestionPreview from "./RecipientQuestionPreview";
import "./Pages/index.css";

const RecipientQuestionTree = ({ data = [], handleChanged, totalQuestions, setid, loadtype }) => {
  return (
    <div className="d-tree">
      <ul className="d-flex d-tree-container flex-column p-0">
        {data.map((tree, i) => (
          <TreeNode
            key={i}
            node={tree}
            handleChanged={handleChanged}
            totalQuestions={totalQuestions}
            setid={setid}
            loadtype={loadtype}
          />
        ))}
      </ul>
    </div>
  );
};
const TreeNode = ({ node, handleChanged, totalQuestions, setid, loadtype }) => {
  const [childVisible, setChildVisiblity] = useState(true);

  const CurrentHandler = () => {
    setChildVisiblity((v) => !v);
  };
  const hasChild = node.children ? true : false;
  const loadIcon = () => {
    if (node.depth == 2) {
      return (
        <span
          className="appkiticon icon-news-outline  custom-grc-icon"
          onClick={(e) => setChildVisiblity((v) => !v)}
        />
      );
    } else {
      return (
        <span
          className="appkiticon icon-folder-closed-outline custom-grc-icon"
          onClick={(e) => setChildVisiblity((v) => !v)}
        />
      );
    }
  };

  return (
    <li className="d-tree-node border-0 sss" key={node.value}>
      <div className="d-flex">
        <div className={`col d-tree-head p-0 ${childVisible ? "selected-node" : ""}`}>
          {node.depth === 2 ? (
            <>
              {loadIcon()}
              <div
                key={node.value}
                className={`d-inline  pl-2 font-weight-bold ${childVisible ? "selected-node" : ""}`}
              >
                <a
                  className="custome-font"
                  onClick={(e) => {
                    handleChanged(e, node.value, "text", -1);
                  }}
                >
                  {node.label}
                </a>

                <RecipientQuestionPreview
                  parentId={node.value}
                  handleChanged={handleChanged}
                  totalQuestions={totalQuestions}
                  setid={setid}
                  loadtype={loadtype}
                />
              </div>
            </>
          ) : (
            <>
              {loadIcon()}
              <div
                key={node.value}
                className={`d-inline pl-2 font-weight-bold ${childVisible ? "selected-node" : ""}`}
              >
                {node.value !== undefined && (
                  <a
                    className="custome-font"
                    onClick={(e) => {
                      handleChanged(e, node.value, "text");
                    }}
                  >
                    {node.label}
                  </a>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {hasChild && childVisible && (
        <div className="d-tree-content">
          <ul className="d-flex d-tree-container flex-column">
            <RecipientQuestionTree
              data={node.children}
              handleChanged={handleChanged}
              totalQuestions={totalQuestions}
              setid={setid}
              loadtype={loadtype}
            />
          </ul>
        </div>
      )}
    </li>
  );
};
export default RecipientQuestionTree;
