import React, { useState } from "react";

import "./index.css";
import { useHistory } from "react-router-dom";

import DeleteQuestionnaire from "./DeleteQuestionnaire";
import { TransferQuestionnaireModal } from "./TransferQuestionnaireModal";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import { deleteFill } from "../../../Assets/Icons";
import editImg from "../../../Assets/Icons/pencil-outline.svg";
import workflow from "../../../Assets/Icons/workflow-outline.svg";
import { GRC_HOME_PER_PAGE } from "../../../Constants/Constant";
import * as path from "../../../Constants/Path";

import { Panel, Tooltip } from "appkit-react";

import "antd/dist/antd.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";

import { Pagination } from "antd";

import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import EmptyPanel from "../Shared/EmptyPanel";

const HomeGridViewContainer = ({ grid_data = [], searchStr, currentPage, setCurrentPage, getCategories }) => {
  const [questionnaireToDelete, setQuestionnaireToDelete] = useState({});
  const [openDeleteConfirmBox, setOpenDeleteConfirmBox] = useState(false);
  const [toggleTransferQuestionnaireModal, setToggleTransferQuestionnaireModal] = useState(false);
  const [questionnaireToTransfer, setQuestionnaireToTransfer] = useState();
  const history = useHistory();
  const polyglot = polyglotLoader();
  const map = {};
  const roots = [];

  const getSubCategory = (s) => {
    if (s.length === 0) {
      return "N/A";
    }

    let subcategorieNames = [];
    for (let j = 0; j < s.length; j++) {
      if (s[j].children.length > 0) {
        for (let g = 0; g < s[j].children.length; g++) {
          subcategorieNames.push(s[j].children[g].name);
        }
      }
    }

    if (subcategorieNames.length > 3) {
      const firstThree = subcategorieNames.slice(0, 3);
      return firstThree.join(",") + (subcategorieNames.length - 3).toString();
    }
    return subcategorieNames.join(",");
  };
  const getCategory = (v) => {
    if (v.length === 0) {
      return "N/A";
    }
    let categories = [];
    v.map((item) => {
      return categories.push(item.name);
    });
    if (categories.length > 3) {
      const firstThree = categories.slice(0, 3);
      return firstThree.join(",") + " + " + (categories.length - 3).toString();
    }
    return categories.join(",");
  };
  grid_data.forEach((v, i) => {
    map[v.id] = i;
    grid_data[i].children = [];
  });

  grid_data.forEach((v) =>
    v.parent_id !== 0 ? grid_data[map[v.parent_id]].children.push(v) : roots.push(v),
  );

  const handleNavigateToEdit = (e, id) => {
    e.preventDefault();
    history.push({
      pathname: path.CREATE_CATEGORY_PATH + `/${id}`,
      state: { mode: "edit", id: id },
    });
  };

  /**
   * delete api call will soft delete the perticular questionnaire with id
   * @param {object} questionnaireItem
   */
  const handleDeleteQuestionnaire = (questionnaireItem) => {
    try {
      // open delete confirm box
      setOpenDeleteConfirmBox(true);
      setQuestionnaireToDelete(questionnaireItem);
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  /**
   * after delete close the confirm box
   */
  const closeDeleteConfirmBox = () => {
    try {
      // close delete confirm box
      setOpenDeleteConfirmBox(false);
      setQuestionnaireToDelete({});
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // HANDLE PAGE CHANGE ON PAGINATION
  const handlePageChange = (change) => {
    setCurrentPage(change);
  };

  // GET CURRENT PAGE ITEMS
  const getCurrentPageItems = (listOfItems) => {
    return listOfItems.slice((currentPage - 1) * GRC_HOME_PER_PAGE, currentPage * GRC_HOME_PER_PAGE);
  };
  // get the value of the searchable keys
  const searchKeys = (row) => {
    const searchableKeys = ["name"];
    const keyValues = searchableKeys.map((key) => row[key]);
    return keyValues.toString();
  };
  // filter list of items based on search text
  const applySearchFilter = (listOfItems) => {
    let filteredItems = [...listOfItems];
    const trimmedsearchStr = searchStr.trim();
    if (trimmedsearchStr.length > 0) {
      const regex = new RegExp(trimmedsearchStr, "i");
      filteredItems = listOfItems.filter((r) => {
        return regex.test(searchKeys(r));
        // return searchKeys(r).includes(trimmedsearchStr.toLowerCase());
      });
    }
    return filteredItems;
  };

  // get filtered items
  const getFilteredItems = () => {
    // sorting roots descending by id
    roots.sort((a, b) => b.id - a.id);
    return getCurrentPageItems(applySearchFilter(roots));
  };

  // VIEW PROJECT NAME ON HOVER
  const DisplayTooltipText = (text) => {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#000",
                  textTransform: "capitalize",
                  maxWidth: "22.5rem",
                }}
              >
                {text}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const handleTransfer = (item) => {
    setQuestionnaireToTransfer(item);
    setToggleTransferQuestionnaireModal(true);
  };

  const onCancelTransferQuestionnaireModal = () => {
    setToggleTransferQuestionnaireModal(false);
    getCategories();
  };

  // display list of items
  const displayList = () => {
    return (
      <>
        {grid_data &&
          getFilteredItems().map((item, i) => {
            return (
              <div className="col-lg-4 col-md-6 col-sm-12 mb-3 p-l-0" key={i}>
                <Panel className="card-body-grc df-respo grid-contents grchomescroll">
                  <div className="row height-40px">
                    <div className="col-md-8 font-weight-bold m-auto grid-home">
                      <span className="appkiticon icon-folder-closed-outline custom-grc-icon folder-Icon" />
                      {item.name.length > 14 ? (
                        <Tooltip content={DisplayTooltipText(item.name)} placement="top">
                          {item.name.substring(0, 15) + "..."}
                        </Tooltip>
                      ) : (
                        item.name
                      )}
                    </div>
                    <div className="col-sm-4 col-md-4 d-flex justify-content-end p-r-0">
                      {/* IF QUESTION_COUNT === 0 HIDE TRANSFER BUTTON */}
                      {item.sumofquestioncount > 0 && (
                        <ButtonIcon
                          imgWidth={20}
                          onClick={() => {
                            handleTransfer(item);
                          }}
                          iconImg={workflow}
                          ariaLabel={polyglot.t("transfer_questionnaire")}
                        />
                      )}
                      {item.asigned_assessments.length === 0 && (
                        <>
                          <ButtonIcon
                            imgWidth={20}
                            onClick={(e) => {
                              handleNavigateToEdit(e, item.id);
                            }}
                            iconImg={editImg}
                            ariaLabel={polyglot.t("edit_questionaries")}
                          />
                          <ButtonIcon
                            imgWidth={24}
                            iconName={deleteFill}
                            onClick={() => {
                              handleDeleteQuestionnaire(item);
                            }}
                            ariaLabel={polyglot.t("delete.questionnaire")}
                          />
                        </>
                      )}
                    </div>
                  </div>
                  <hr className="label-divider mb-3" />
                  <div className="row mb-2">
                    <div className="col-sm-4 col-md-4 text-left q-title homewrap">Description:</div>
                    <div className="col-sm-8 col-md-8 p-t-9 q-description" style={{ wordWrap: "break-word" }}>
                      {/* {item.description} */}
                      {item.description.length > 150 ? (
                        <Tooltip content={DisplayTooltipText(item.description)} placement="top">
                          {item.description.substring(0, 149) + "..."}
                        </Tooltip>
                      ) : (
                        item.description
                      )}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-4 col-md-4 text-left q-title homewrap">Category:</div>
                    <div className="col-sm-8 col-md-8 p-t-9" style={{ wordWrap: "break-word" }}>
                      {getCategory(item.children)}
                      {/*  <span className="category-count">+1</span> */}
                    </div>
                  </div>
                  <div className="row mb-2">
                    <div className="col-sm-4 col-md-4 text-left p-r-0 q-title homewrap">Sub-Category:</div>
                    <div className="col-sm-8 col-md-8 p-t-9" style={{ wordWrap: "break-word" }}>
                      {getSubCategory(item.children)}
                    </div>
                  </div>
                </Panel>
              </div>
            );
          })}
      </>
    );
  };

  return (
    <>
      {displayList()}
      {getFilteredItems().length === 0 && (
        <div className="m-l-minus-point8rem w-100 ml-0">
          <EmptyPanel massage={"No data found."} />
        </div>
      )}
      {roots.length > 0 && (
        <div className="w-100 m-l-n-10">
          <Pagination
            current={currentPage}
            size="small"
            pageSize={GRC_HOME_PER_PAGE}
            total={roots.length}
            onChange={handlePageChange}
            showSizeChanger={false}
          />
        </div>
      )}
      <DeleteQuestionnaire
        Item={questionnaireToDelete}
        show={openDeleteConfirmBox}
        close={closeDeleteConfirmBox}
        getCategories={getCategories}
      />
      <TransferQuestionnaireModal
        show={toggleTransferQuestionnaireModal}
        onCancel={onCancelTransferQuestionnaireModal}
        questionnaireToTransfer={questionnaireToTransfer}
      />
    </>
  );
};
export default HomeGridViewContainer;
