import React from "react";
import { Switch } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import AskForSupport from "../Components/DFED/Support/Support";
import AdminContainer from "../Components/GI/Admin/AdminContainer";
import HomeContainer from "../Components/GI/Home/HomeContainer";
// ROUTES OF GI MODULE
function GIModuleRoutes() {
  return (
    <Switch>
      <ProtectedRoute path="/gi/home" component={HomeContainer} />

      <ProtectedRoute path="/gi/admin" component={AdminContainer} />
      <ProtectedRoute path="/gi/support" component={AskForSupport} />
    </Switch>
  );
}

export default GIModuleRoutes;
