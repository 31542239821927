/* eslint-disable sort-imports */
import { DatePicker, Tag, ConfigProvider } from "antd";
import gr from "antd/lib/locale-provider/de_DE";
import { Input, Select, SelectGroupTitle, SelectOption } from "appkit-react";
import { Formik } from "formik";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import "moment/locale/de";
import { date, object, string } from "yup";

import { StartContext } from "./Contexts/StartContext";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import calendar from "../../../Assets/Icons/calendar.svg";
import { NAME_REGEX } from "../../../Constants/regex";
import ProjectSurveyService from "../../../Services/DF/ProjectSurveyService";
import { getDisplayDate } from "../../../Services/Shared/Handlers";
import { polyglotLoader, getCurrentLanguage } from "../../../Services/Shared/Translate";
import { textFieldValidation } from "../../../Services/Shared/formHandlers";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];
// GET MONTH NUMBER FROM THE MONTH
const getNumberRelatedToMonth = (selectedMonth) => {
  return months.indexOf(selectedMonth);
};
// GET MONTH NAME FROM A NUMBER
const getMonthNameByNumber = (monthNumber) => {
  // starts with zero
  return months[monthNumber];
};
// GET YEAR FROM FINANCIAL YEAR
const getYearFromFinancialYear = (financialYear) => {
  const currentYear = new Date().getFullYear().toString();
  const prefixYear = currentYear.substr(0, 2);
  const selectedYear = `${prefixYear}${financialYear.split("FY")[1]}`;
  const choosenYear = Number(selectedYear);
  return choosenYear;
};
// CHECKS AND RETURNS IF THE GIVEN YEAR AND MONTH BELONGS TO A LEAP YEAR OR NOT
const isLeapYear = (financialYear, selectedMonth) => {
  const choosenYear = getYearFromFinancialYear(financialYear);
  const monthNumber = getNumberRelatedToMonth(selectedMonth);
  return new Date(choosenYear, monthNumber).getFullYear() % 4 == 0;
};

// RETURNS DATE FORMAT AS REQUIRED BY JAVASCRIPT
const requiredJSDateFormat = (DDdotMMdotYYY) => {
  const date = DDdotMMdotYYY.split(".")[0];
  const month = DDdotMMdotYYY.split(".")[1];
  const year = DDdotMMdotYYY.split(".")[2];
  return `${year}-${month}-${date}`;
};

// WORKFLOW FORM
const RequestParameterForm = (props) => {
  const polyglot = polyglotLoader();
  const { RangePicker } = DatePicker;
  const {
    projectSurvey,
    updateProjectSurvey,
    updateProjectSurveyFromToDates,
    updateProjectSurveyFYDates,
    updateWorkflowRecord,
  } = useContext(StartContext);
  const { requestParamFile, goBack, project_id, disableSubmit } = props;

  const initialFormValues = {
    workflow_name: projectSurvey.workflow_name,
    financial_year: projectSurvey.financial_year,
    financial_year_start_date: projectSurvey.financial_year_start_date,
    financial_year_end_date: projectSurvey.financial_year_end_date,
    time_scope_from: projectSurvey.time_scope_from,
    time_scope_to: projectSurvey.time_scope_to,
    company_codes: projectSurvey.company_codes,
  };

  const [formValues, setFormValues] = useState({ ...initialFormValues });
  const [companyName, setCompany] = useState("");
  const [workflows, setWorkflows] = useState([]);
  const [financialYears, setFinancialYears] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");

  // GET SELECTED MONTH
  const getSelectedMonth = () => {
    if (formValues.financial_year_start_date !== "" && formValues.financial_year_end_date !== "") {
      const monthNumber = new Date(formValues.financial_year_start_date).getMonth();
      return months[monthNumber];
    }
    return selectedMonth;
  };

  // GET SELECTED MONTH TO DISPLAY ON FORM
  const getSelectedMonth_ForSelectBox = (values) => {
    if (values.financial_year_start_date !== "" && values.financial_year_end_date !== "") {
      const monthNumber = new Date(values.financial_year_start_date).getMonth();
      return months[monthNumber];
    }
    return "";
  };

  const monthToDates = {
    "": 0,
    January: 31,
    February: isLeapYear(formValues.financial_year, getSelectedMonth()) ? 29 : 28,
    March: 31,
    April: 30,
    May: 31,
    June: 30,
    July: 31,
    August: 31,
    September: 30,
    October: 31,
    November: 30,
    December: 31,
  };

  // FETCH PROJECT SURVEY
  const fetchProjectSurvey = async () => {
    try {
      // API TO FETCH LIST OF ALL WORKFLOWS BY PROJECTID
      const res = await ProjectSurveyService.get().getProjectSurveyDetails(project_id);
      if (res.CODE) {
        const fetchProjectSurveys = res.data;
        setWorkflows(fetchProjectSurveys);
      } else {
        displayToastMessage(res.MSG, "error");
      }
    } catch (error) {
      displayToastMessage(error.message, "error");
    }
  };
  // FETCH FINANCIAL YEARS
  const fetchFinancialYears_DF = async () => {
    try {
      // API TO GET LIST OF FINANCIAL YEARS
      const res = await ProjectSurveyService.get().fetchFinancialYears_DF();
      if (res.CODE) {
        const fyYears = [];
        res.data.forEach((obj) => {
          fyYears.push(obj.financial_year);
        });
        setFinancialYears(fyYears);
      } else {
        displayToastMessage(res.MSG, "error");
      }
    } catch (error) {
      displayToastMessage(error.message, "error");
    }
  };
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    window.scrollTo(0, 0);
    fetchProjectSurvey();
    fetchFinancialYears_DF();
  }, []);

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    window.scrollTo(0, 0);
    setFormValues(initialFormValues);
    setSelectedMonth("");
  }, [projectSurvey]);

  // VALIDATION SCHEMA OF THE FORM
  const validationSchema = object().shape({
    workflow_name: string()
      .matches(NAME_REGEX, "text field should not contain any special characters")
      .required(polyglot.t("Name is required")),
    time_scope_from: date().nullable().required(polyglot.t("Select the start date")),
    time_scope_to: date().nullable().required(polyglot.t("Select the end date")),
    financial_year: string(),
    company_codes: string().required(polyglot.t("select a sponserlist")),
  });

  // CHECK IF FORM IS VALID
  const isFormValid = (formErrors) => {
    let isFormValid = true;
    if (formErrors.invalidWorkflowName) {
      isFormValid = false;
    }
    if (getSelectedMonth() === "") {
      isFormValid = false;
    }
    return isFormValid;
  };
  // ON SUBMITING FORM
  const submitForm = () => {
    requestParamFile();
  };

  // CHECK IF SUBMIT IS DISABLED
  const isSubmitDisabled = (values, formErrors) => {
    let isDisabled = false;
    if (disableSubmit) {
      isDisabled = disableSubmit;
      return isDisabled;
    }
    if (
      values.workflow_name === "" ||
      values.company_codes.length <= 0 ||
      values.financial_year === "" ||
      getSelectedMonth() === ""
    ) {
      isDisabled = true;
    }
    return isDisabled;
  };

  // RUNS ON FIELD CHANGE
  const fieldChange = (key, value, handleChange) => {
    const fromValuesClone = { ...formValues };
    fromValuesClone[key] = value;
    setFormValues(fromValuesClone);
    updateProjectSurvey(key, value);
    handleChange(key)({ target: { value } });
  };
  // RUNS ON FINANCIAL YEAR DATES ARE CHANGED
  const fieldChageFYDates = (start, end, handleChange, key, year) => {
    const fromValuesClone = { ...formValues };
    fromValuesClone.financial_year_start_date = start;
    fromValuesClone.financial_year_end_date = end;
    if (key === "financial_year") {
      fromValuesClone[key] = year;
    }
    setFormValues(fromValuesClone);
    updateProjectSurveyFYDates(start, end, year);
    handleChange("financial_year_start_date")({ target: { value: start } });
    handleChange("financial_year_end_date")({ target: { value: end } });
    handleChange("financial_year")({ target: { value: year } });
  };

  // GET MOMENT DATE
  const getMomentDate = (isoDate) => {
    if (!isoDate || isoDate === "") {
      return "";
    } else {
      return moment(new Date(isoDate));
    }
  };
  // ADD COMPANY CODE
  const addCompanyCode = () => {
    const updatedCompany_Codes = formValues.company_codes;
    let isValidName = true;
    if (companyName === "") {
      isValidName = false;
      displayToastMessage(polyglot.t("Company code cannot be empty"), "error");
    }
    if (updatedCompany_Codes.includes(companyName)) {
      isValidName = false;
      displayToastMessage(polyglot.t("Company code already exists"), "error");
    }
    if (isValidName) {
      updatedCompany_Codes.push(companyName);
      const fromValuesClone = { ...formValues };
      fromValuesClone.company_codes = updatedCompany_Codes;
      setFormValues(fromValuesClone);
      updateProjectSurvey("company_codes", updatedCompany_Codes);
      setCompany("");
    }
  };
  // CATCH KEY UP WHILE ENTERING COMPANY CODES
  const catchKeyUp = (event) => {
    // Note: code will be assigned on clicking enter
    if (event.keyCode === 13) {
      addCompanyCode();
    }
  };
  // DELETE A COMPANY CODE
  const closeTag = (tagIndex) => {
    const updatedCompany_Codes = [];
    formValues.company_codes.forEach((item, index) => {
      if (index !== tagIndex) {
        updatedCompany_Codes.push(item);
      }
    });
    const fromValuesClone = { ...formValues };
    fromValuesClone.company_codes = updatedCompany_Codes;
    setFormValues(fromValuesClone);
    updateProjectSurvey("company_codes", updatedCompany_Codes);
  };
  // CATCH COMPANY CODE WHILE USER ENTERS
  const catchCompanyCode = (val) => {
    if (val.length <= 4) {
      setCompany(val);
    } else {
      displayToastMessage(polyglot.t("Company code cannot be more than 4 letters"), "info");
    }
  };
  // CHECK IF WORKFLOW NAME IS VALID OR NOT
  const isWorkflowNameValid = (name) => {
    const validName = textFieldValidation(name);
    if (!validName) {
    }
    const workflowNames = workflows.map((obj) => obj.workflow_name);
    if (workflowNames.includes(name)) {
      return polyglot.t("Workflow name already exists");
    } else {
      return null;
    }
  };

  // GET START DATE IN ISO FORMAT
  const getStartDate = (monthSelected, FY_year) => {
    if (monthSelected !== "" && getNumberRelatedToMonth(monthSelected) + 1) {
      const fyYear = getYearFromFinancialYear(FY_year);
      const date = Date.UTC(fyYear, getNumberRelatedToMonth(monthSelected), 1);
      const startDate = new Date(date).toISOString();
      return startDate;
    }
    return null;
  };
  // GET END DATE IN ISO FORMAT
  const getEndDate = (monthSelected, FY_year) => {
    if (monthSelected !== "" && getNumberRelatedToMonth(monthSelected) + 1) {
      let fyYear = 0;
      let previousMonthOfSelectedMonth = 0;
      if (getNumberRelatedToMonth(monthSelected) === 0) {
        fyYear = getYearFromFinancialYear(FY_year);
        previousMonthOfSelectedMonth = getMonthNameByNumber(11);
      } else {
        fyYear = getYearFromFinancialYear(FY_year) + 1;
        previousMonthOfSelectedMonth = getMonthNameByNumber(getNumberRelatedToMonth(monthSelected) - 1);
      }
      const lastDateOfMonth = monthToDates[previousMonthOfSelectedMonth];
      const date = Date.UTC(fyYear, getNumberRelatedToMonth(previousMonthOfSelectedMonth), lastDateOfMonth);
      const endDate = new Date(date).toISOString();
      return endDate;
    }
    return null;
  };

  // SELECT FY YEAR
  const selectFYYear = (val, handleChange) => {
    if (getSelectedMonth() !== "" && getNumberRelatedToMonth(getSelectedMonth()) + 1) {
      const startDate = getStartDate(getSelectedMonth(), val);
      const endDate = getEndDate(getSelectedMonth(), val);
      fieldChageFYDates(startDate, endDate, handleChange, "financial_year", val);
    } else {
      fieldChange("financial_year", val, handleChange);
    }
  };

  // SELECT A MONTH
  const selectedAMonth = (val, handleChange) => {
    setSelectedMonth(val);
    if (formValues.financial_year !== "") {
      const startDate = getStartDate(val, formValues.financial_year);
      const endDate = getEndDate(val, formValues.financial_year);
      fieldChageFYDates(startDate, endDate, handleChange, "financial_year", formValues.financial_year);
    }
  };

  // DISLAY LOADING SELECT BOX
  const loadingSelect = (title) => (
    <Select placeholder={`${title} *`} showSearchOnToggle={true}>
      <SelectGroupTitle>{title}</SelectGroupTitle>
      <SelectOption>Loading...</SelectOption>
    </Select>
  );

  let formErrors = {};
  return (
    <Formik
      onSubmit={submitForm}
      initialValues={{ ...formValues }}
      validationSchema={validationSchema}
      enableReinitialize
    >
      {(prop) => {
        const { values, handleChange, handleSubmit, errors, touched } = prop;
        formErrors = { ...errors };
        formErrors.invalidWorkflowName = isWorkflowNameValid(values.workflow_name);
        formErrors.isSubmitDisabled = isSubmitDisabled(values, formErrors);

        const restrictStartDates = (current, endDate) => {
          const enddate = endDate ? endDate : "";
          let customDate = new Date(enddate);
          customDate.setDate(customDate.getDate());
          return current && current > customDate;
        };
        const restrictEndDates = (current, selectedStartDate) => {
          let startDate = selectedStartDate ? selectedStartDate : null;
          if (startDate) {
            let customDate = new Date(startDate);
            customDate.setDate(customDate.getDate());
            return current && current < customDate;
          }
        };
        const monthRestrictStartDates = (current) => {
          const today = moment();
          const currentDate = moment(current).date();
          if (moment(current).isBefore(today)) {
            return true;
          }
          return current && !(currentDate === 1);
        };
        const monthRestrictEndDates = (current, currentStart) => {
          const today = moment();
          if (moment(current).isBefore(today)) {
            return true;
          }
          if (moment(current).isBefore(moment(currentStart))) {
            return true;
          }
          const currentMonthEnd = moment(current).endOf("month").date();
          const currentDate = moment(current).date();
          return current && !(currentDate === currentMonthEnd);
        };

        return (
          <div>
            <div className="row df-rp-fields">
              <div className="col-12">
                <Input
                  placeholder={polyglot.t("Workflow name *")}
                  value={values.workflow_name}
                  onChange={(val) => fieldChange("workflow_name", val, handleChange)}
                />
                {errors.workflow_name && touched.workflow_name ? (
                  <div className="error">{errors.workflow_name}</div>
                ) : null}
                {formErrors.invalidWorkflowName && (
                  <div className="error">{formErrors.invalidWorkflowName}</div>
                )}
              </div>
            </div>

            <div className="row df-rp-fields">
              <div className="col-6">
                {getCurrentLanguage() === "ger" ? (
                  <ConfigProvider locale={gr}>
                    <DatePicker
                      style={{ width: "100%" }}
                      disabledDate={(current) => restrictStartDates(current, values.time_scope_to)}
                      placeholder={`${polyglot.t("Timescope from")} *`}
                      value={getMomentDate(values.time_scope_from)}
                      defaultPickerValue={moment}
                      onChange={(val) => fieldChange("time_scope_from", val, handleChange)}
                      format={"DD.MM.YYYY"}
                      className="antd-datepicker-custom-styles"
                      suffixIcon={<img src={calendar} width={14} height={14} />}
                    />
                  </ConfigProvider>
                ) : (
                  <DatePicker
                    style={{ width: "100%" }}
                    disabledDate={(current) => restrictStartDates(current, values.time_scope_to)}
                    placeholder={`${polyglot.t("Timescope from")} *`}
                    value={getMomentDate(values.time_scope_from)}
                    defaultPickerValue={moment}
                    onChange={(val) => fieldChange("time_scope_from", val, handleChange)}
                    format={"DD.MM.YYYY"}
                    className="antd-datepicker-custom-styles"
                    suffixIcon={<img src={calendar} width={14} height={14} />}
                  />
                )}
                {errors.time_scope_from && touched.time_scope_from ? (
                  <div className="error">{formErrors.time_scope_from}</div>
                ) : null}
              </div>
              <div className="col-6">
                {getCurrentLanguage() === "ger" ? (
                  <ConfigProvider locale={gr}>
                    <DatePicker
                      style={{ width: "100%" }}
                      disabledDate={(current) => restrictEndDates(current, values.time_scope_from)}
                      placeholder={`${polyglot.t("Timescope to")}*`}
                      value={getMomentDate(values.time_scope_to)}
                      defaultPickerValue={moment}
                      onChange={(val) => fieldChange("time_scope_to", val, handleChange)}
                      format={"DD.MM.YYYY"}
                      className="antd-datepicker-custom-styles"
                      suffixIcon={<img src={calendar} width={14} height={14} />}
                    />
                  </ConfigProvider>
                ) : (
                  <DatePicker
                    style={{ width: "100%" }}
                    disabledDate={(current) => restrictEndDates(current, values.time_scope_from)}
                    placeholder={`${polyglot.t("Timescope to")}*`}
                    value={getMomentDate(values.time_scope_to)}
                    defaultPickerValue={moment}
                    onChange={(val) => fieldChange("time_scope_to", val, handleChange)}
                    format={"DD.MM.YYYY"}
                    className="antd-datepicker-custom-styles"
                    suffixIcon={<img src={calendar} width={14} height={14} />}
                  />
                )}
                {errors.time_scope_to && touched.time_scope_to ? (
                  <div className="error">{formErrors.time_scope_to}</div>
                ) : null}
              </div>
            </div>
            <div className="row df-rp-fields">
              <div className="col-12">
                <Input
                  inputBoxSize="sm"
                  placeholder={`${polyglot.t("Company codes")} *`}
                  value={companyName}
                  onChange={catchCompanyCode}
                  onKeyUp={catchKeyUp}
                />
                {formValues.company_codes.map((code, i) => (
                  <Tag color={"#108ee9"} key={code} closable onClose={() => closeTag(i)}>
                    {code}
                  </Tag>
                ))}
                {formValues.company_codes.length === 0 &&
                  polyglot.t("Please confirm company codes separately with enter")}
              </div>
            </div>

            <div className="row df-rp-fields">
              <div className="col-6">
                {financialYears.length > 0 && (
                  <>
                    {values.financial_year === "" && (
                      <Select
                        placeholder={`${polyglot.t("Financial year")} *`}
                        multiple={false}
                        value={values.financial_year}
                        showSearchOnToggle={true}
                        onSelect={(val) => selectFYYear(val, handleChange)}
                      >
                        <SelectGroupTitle>{polyglot.t("Financial year")}</SelectGroupTitle>
                        {financialYears.map((item, index) => {
                          return (
                            <SelectOption key={index} value={item}>
                              {item}
                            </SelectOption>
                          );
                        })}
                      </Select>
                    )}
                    {values.financial_year !== "" && (
                      <Select
                        placeholder={`${polyglot.t("Financial year")} *`}
                        multiple={false}
                        value={values.financial_year}
                        showSearchOnToggle={true}
                        onSelect={(val) => selectFYYear(val, handleChange)}
                      >
                        <SelectGroupTitle>{polyglot.t("Financial year")}</SelectGroupTitle>
                        {financialYears.map((item, index) => {
                          return (
                            <SelectOption key={index} value={item}>
                              {item}
                            </SelectOption>
                          );
                        })}
                      </Select>
                    )}
                  </>
                )}
                {financialYears.length === 0 && loadingSelect("Financial year")}
              </div>
              <div className="col-6">
                {getSelectedMonth_ForSelectBox(values) === "" && (
                  <Select
                    placeholder={`${polyglot.t("FY month")} *`}
                    multiple={false}
                    value={getSelectedMonth_ForSelectBox(values)}
                    showSearchOnToggle={true}
                    onSelect={(val) => selectedAMonth(val, handleChange)}
                  >
                    <SelectGroupTitle>{polyglot.t("FY month")}</SelectGroupTitle>
                    {months.map((item, index) => {
                      return (
                        <SelectOption key={index} value={item}>
                          {polyglot.t(item)}
                        </SelectOption>
                      );
                    })}
                  </Select>
                )}
                {getSelectedMonth_ForSelectBox(values) !== "" && (
                  <Select
                    placeholder={`${polyglot.t("FY month")} *`}
                    multiple={false}
                    value={getSelectedMonth_ForSelectBox(values)}
                    showSearchOnToggle={true}
                    onSelect={(val) => selectedAMonth(val, handleChange)}
                  >
                    <SelectGroupTitle>{polyglot.t("FY month")}</SelectGroupTitle>
                    {months.map((item, index) => {
                      return (
                        <SelectOption key={index} value={item}>
                          {polyglot.t(item)}
                        </SelectOption>
                      );
                    })}
                  </Select>
                )}
              </div>
            </div>
            <div className="row df-rp-fields">
              <div className="col-6">
                <Tag className="disabled-tag-workflow-form">
                  {values.financial_year_start_date
                    ? getDisplayDate(values.financial_year_start_date)
                    : polyglot.t("FY start date")}
                </Tag>
              </div>
              <div className="col-6">
                <Tag className="disabled-tag-workflow-form">
                  {values.financial_year_end_date
                    ? getDisplayDate(values.financial_year_end_date)
                    : polyglot.t("FY end date")}
                </Tag>
              </div>
            </div>

            <div className="row df-rp-fields">
              <div className="col-md-6 mt-4">
                <ButtonSecondary onClick={goBack} ariaLabel={polyglot.t("Back")}>
                  {polyglot.t("Back")}
                </ButtonSecondary>
              </div>
              <div className="col-md-6 mt-4 align-right ">
                <ButtonPrimary
                  disabled={formErrors.isSubmitDisabled}
                  onClick={() => {
                    if (isFormValid(formErrors)) {
                      handleSubmit();
                    }
                  }}
                  //onClick={isFormValid(formErrors) && handleSubmit}
                  ariaLabel={polyglot.t("Request parameter file")}
                >
                  {polyglot.t("Request parameter file")}
                </ButtonPrimary>
              </div>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default RequestParameterForm;
