import React, { useEffect, useState } from "react";

import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/GRC/loading-spin.svg";
import AssessmentService from "../../../Services/GRC/AssessmentService";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import ErrorPanel from "../Shared/ErrorPanel";

import "./index.css";

const LeftPanelQuestionaris = ({ selectedId }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [selectedQuestionaries, setSelectedQuestionaries] = useState([]);

  useEffect(() => {
    const getSelectedQuestionaries = async () => {
      try {
        const responseData = await sendRequest(
          AssessmentService.get().getAassermentQuestionariesURL(selectedId),
        );
        setSelectedQuestionaries(responseData);
      } catch (err) {
        console.log(err);
      }
    };
    getSelectedQuestionaries();
  }, [sendRequest]);
  // todo add useCalback

  return (
    <>
      {error && <ErrorPanel error={error} onClear={clearError} />}
      {isLoading && (
        <div>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      )}

      <h3 className="right-panel-heading">Selected Questionaries</h3>
      <p>&nbsp;</p>
      <ul className="list-group">
        {!isLoading &&
          selectedQuestionaries &&
          selectedQuestionaries.map((item, i) => {
            return (
              <li className="list-group-item custome-list-group-item" key={item.questionary_id}>
                <span className="appkiticon icon-folder-closed-outline custom-grc-icon" />{" "}
                <span className="questionaire_text">{item.name}</span>
              </li>
            );
          })}
      </ul>
    </>
  );
};
export default LeftPanelQuestionaris;
