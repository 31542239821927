import React, { useContext, useEffect } from "react";

import RequestForm from "./RequestForm";
import NoAccess from "../../../Assets/Components/NoAccess";
import ProtectedRoute from "../../../Routes/ProtectedRoute";
import { getProjectID_Of_solution_module } from "../../../Services/Shared/Handlers";
import { hasCreatePermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";

// RENDERS ON PATH /ri/form
export default (props) => {
  // FETCH DATA FROM CONTEXT
  const { checkAndFetchRole, getCurrentUserPermissions } = useContext(DataContext);
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    const projectId = getProjectID_Of_solution_module();
    const screenName = "Home";
    // TODO send Risk Intelligence
    checkAndFetchRole(screenName, "Connected Risk Intelligence", projectId);
  }, []);
  const currentUserPermissions = getCurrentUserPermissions();
  return (
    <>
      {/* RENDER PATH IF USER HAS PERMISSION TO VIEW */}
      {hasCreatePermission(currentUserPermissions) && <ProtectedRoute path="/" component={RequestForm} />}
      {/* SHOW 'NO ACCESS' IF USER DOES NOT HAVE PERMISSION TO VIEW */}
      {!hasCreatePermission(currentUserPermissions) && <NoAccess />}
    </>
  );
};
