import "../../../Assets/Css/QueenBase/Projects/ProjectSelection.css";
import PropTypes from "prop-types";
import React, { useEffect, useContext } from "react";

import { VIEW_PROJECT_PATH } from "../../../Constants/Path";
import ProjectRoute from "../../../Routes/ProjectRoute";
import { DataContext } from "../../../Services/Shared/Store";

// RENDERS ON /queenbase/projects
// RENDERS PROJECT SCREEN ROUTES
const Project = (props) => {
  // DATA FROM CONTEXT
  const { checkAndFetchRole } = useContext(DataContext);
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    if (props.location.pathname === "/queenbase/projects") {
      props.history.push(VIEW_PROJECT_PATH);
    }
  });
  // FETCH USER ROLE
  useEffect(() => {
    const screenName = "Project";
    checkAndFetchRole(screenName, "Base");
  }, []);
  return <ProjectRoute />;
};
Project.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default Project;
