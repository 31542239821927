import React from "react";

import { polyglotLoader } from "../../../Services/Shared/Translate";
import "./index.css";

// THIS COMPONENT IS USED TO DISPLAY NO ACCESS CONTENT ON THE SCREEN
const NoAccess = () => {
  const polyglot = polyglotLoader();
  return (
    <>
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6" style={{ textAlign: "center" }}>
          <span className="appkiticon icon-alert-fill no-access-icon" />
        </div>
        <div className="col-md-3"></div>
      </div>
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6" style={{ textAlign: "center" }}>
          <div className="noaccess_heading">
            <p className="noaccess_header mt-3 mb-2">
              {polyglot.t("You don't have permission to view this page")}
            </p>
            <p>{polyglot.t("Please contact your administrator for further details")}.</p>
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </>
  );
};

export default NoAccess;
