import $ from "jquery";
$.extend($.fn.dataTableExt.oSort, {
  "date-de-pre": function (a) {
    if (a == null || a === "") {
      return 0;
    }
    var deDatea = a.split(".");
    return (deDatea[2] + deDatea[1] + deDatea[0]) * 1;
  },

  "date-de-asc": function (a, b) {
    return a < b ? -1 : a > b ? 1 : 0;
  },

  "date-de-desc": function (a, b) {
    return a < b ? 1 : a > b ? -1 : 0;
  },
});
