import React, { useEffect, useContext, useState } from "react";
import { useHistory } from "react-router-dom";

import LinkText from "../../../Assets/Components/LinkText/LinkText";
import { check_If_User_LoggedIn } from "../../../Services/Shared/Authenticate";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import ConfirmBox from "../../QueenBase/Projects/ConfirmBox";

function FooterComponent() {
  const history = useHistory();
  useEffect(() => {}, []);
  var polyglot = polyglotLoader();
  const { getUserLanguage, formGotOpened, formGotClosed, isFormOpen } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());

  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [selectedNav, setSelectedNav] = useState(null); //expected string
  // DISPLAY DATE, TIME IN UTC FORMAT
  const getSystemDateYear = () => {
    const year = new Date().getFullYear();
    return year;
  };
  // CLOSE FORM AND NAVIGATE TO VIEW PROJECTS
  const closeFormAndRedirect = () => {
    toggleConfirmBox();
    redirectionOnClosingConfirmBox();
    formGotClosed();
  };

  const redirection = (url) => {
    try {
      if (url) {
        if (isFormOpen) {
          // form is in open state, hence user cannot navigate to other screens
          toggleConfirmBox();
          setSelectedNav(url);
        } else {
          history.push(url);
          setSelectedNav(null);
        }
      }
    } catch (err) {
      // do nothing
    }
  };
  // redirect on closing conform box
  const redirectionOnClosingConfirmBox = () => {
    try {
      history.push(selectedNav);
      setSelectedNav(null);
    } catch (err) {
      // do nothing
    }
  };
  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  const toggleConfirmBox = () => {
    setOpenConfirmBox(!openConfirmBox);
  };

  return (
    <>
      {/* for extra space needed between content and footer */}
      <div className="footerPadding"></div>
      <footer className={check_If_User_LoggedIn() ? "positionNotFixed ipadPositionFooter" : "positionFixed"}>
        <div className="container p-l-0 col-md-10">
          <div className="footer-para-text m-b-0 p-t-6 p-l-0 m-l-1">
            &#169;&nbsp;2017 - {getSystemDateYear()}&nbsp;
            {getUserLanguage() === "en" && (
              <>
                {
                  "PwC. All rights reserved. PwC refers to the PwC network and/or one or more of its member firms, each of which is a seperate legal entity. Please see "
                }
                <a
                  target="_blank"
                  href="https://www.pwc.com/structure"
                  className="link-color-red footerAnchor footer-link"
                  style={{ textDecoration: "underline" }}
                  rel="noreferrer"
                >
                  www.pwc.com/structure
                </a>{" "}
                {"for further details."}
              </>
            )}
            {getUserLanguage() === "ger" && (
              <>
                {
                  "PwC. Alle Rechte vorbehalten. PwC bezieht sich auf das PwC-Netzwerk und/oder eine oder mehrere seiner Mitgliedsfirmen, die jeweils eine eigene juristische Person sind. Weitere Informationen finden Sie unter "
                }
                <a
                  target="_blank"
                  href="https://www.pwc.com/structure"
                  className="link-color-red footerAnchor footer-link"
                  style={{ textDecoration: "underline" }}
                  rel="noreferrer"
                >
                  www.pwc.com/structure
                </a>
              </>
            )}
          </div>
          <ul className="footer-link-item m-b-0 p-l-0 m-l-1" id="footerLinkId">
            <li>
              <LinkText
                onClick={() => {
                  document.body.classList.remove("bg-img");
                  redirection("/disclamier");
                }}
                className="footer-link"
                ariaLabel={polyglot.t("Disclaimer")}
                href=""
              >
                {polyglot.t("Disclaimer")}
              </LinkText>
            </li>
            <li>
              <LinkText
                onClick={() => {
                  document.body.classList.remove("bg-img");
                }}
                className="footer-link"
                ariaLabel={polyglot.t("Imprint")}
                href={
                  getUserLanguage() === "en"
                    ? "https://pwc.de/en/imprint.html"
                    : "https://pwc.de/de/impressum.html"
                }
              >
                {polyglot.t("Imprint")}
              </LinkText>
            </li>
            <li>
              <LinkText
                onClick={() => {
                  document.body.classList.remove("bg-img");
                  redirection("/term-of-use");
                }}
                className="footer-link"
                ariaLabel={polyglot.t("Terms of use")}
                href=""
              >
                {polyglot.t("Terms of use")}
              </LinkText>
            </li>
            <li>
              <LinkText
                onClick={() => {
                  document.body.classList.remove("bg-img");
                  redirection("/privacy");
                }}
                className="footer-link"
                ariaLabel={polyglot.t("Privacy policy")}
                href=""
              >
                {polyglot.t("Privacy policy")}
              </LinkText>
            </li>
            <li>
              <LinkText
                onClick={() => {
                  document.body.classList.remove("bg-img");
                  redirection("/cookies");
                }}
                className="footer-link"
                ariaLabel={polyglot.t("Cookies")}
                href="javascript:;"
              >
                {polyglot.t("Cookies")}
              </LinkText>
            </li>

            {check_If_User_LoggedIn() && (
              <li>
                <LinkText
                  onClick={() => redirection("/credits")}
                  className="footer-link"
                  ariaLabel={polyglot.t("Credits")}
                  href="javascript:;"
                >
                  {polyglot.t("Credits")}
                </LinkText>
              </li>
            )}
            <li className="footer-version-number">{"v" + process.env.REACT_APP_PROJECT_VERSION + ""}</li>
          </ul>
        </div>
      </footer>
      <ConfirmBox
        show={openConfirmBox}
        heading={"Cancel update"}
        cancel={toggleConfirmBox}
        proceed={closeFormAndRedirect}
      />
    </>
  );
}

export default FooterComponent;
