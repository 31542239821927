import React, { useEffect } from "react";
import "../../../../Assets/Css/DFED/Profile.css";
import { RadioGroup, Checkbox, Select, SelectOption, Input, Tooltip } from "appkit-react";
import { FormErrors } from "./FormErrors";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import FormFileUpload from "../../Shared/components/FormFileUpload";
import { render } from "react-dom";

class TabViewOcr extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tab5Pdf: this.props.tab5Pdf,
      tab5Images: this.props.tab5Images,
      tab5ImagesType: this.props.tab5ImagesType,
      tab5ExcludeType: this.props.tab5ExcludeType,
      tab5TextSection: this.props.tab5TextSection,
      tab5Quality: this.props.tab5Quality,
      tab5AnalysisLanguage: this.props.tab5AnalysisLanguage,
      selectedVal: "",
      selectedRotation: "",
      selectedPdfVal: this.props.selectedPdfVal,
      selectedNoneImgVal: this.props.selectedNoneImgVal,
    };
    this.handleChange = this.handleChange.bind(this);
  }
  componentDidMount() {
    this.setState({
      selectedPdfVal: this.props.selectedPdfVal,
      selectedNoneImgVal: this.props.selectedNoneImgVal,
    });
  }
  componentWillReceiveProps(nextProps) {
    if (
      this.props.selectedPdfVal !== nextProps.selectedPdfVal ||
      this.props.selectedNoneImgVal !== nextProps.selectedNoneImgVal
    ) {
      this.setState({
        selectedPdfVal: nextProps.selectedPdfVal,
        selectedNoneImgVal: nextProps.selectedNoneImgVal,
      });
    }
  }

  // ONCHANGE FUNCTIONALITY FOR OCR RADIO BUTTON CHANGE EVENT=========================
  handleChange = (ocrEvent) => {
    this.setState({
      selectedPdfVal: ocrEvent.target.value,
    });
    this.props.changed(this.props.tab5PDFAttr, ocrEvent, false);
  };
  //  OCR IMAGE RADIO ONCHANGE FUNCTION
  handleOcrImageChange = (ocrEvent) => {
    this.setState({
      selectedNoneImgVal: ocrEvent.target.value,
    });
    this.props.changed(this.props.tab5NonSpecificImgAttr, ocrEvent, false);
  };

  // RENDER FUNCTION FOR RENDER ALL OCR RELATED MENU
  render() {
    const polyglot = polyglotLoader();
    return (
      <div className="tbContentStrt">
        <div className="pdfSection">
          <div className="profileHeading ocrTabHed">
            {" "}
            <p> {polyglot.t("PDF")} </p>{" "}
          </div>
          <div className="form-group">
            {this.props.tab5Pdf.length > 0 ? (
              this.props.tab5Pdf.map((value, key) => {
                return (
                  <div key={key + "_ocr_pdf_selection"}>
                    <label
                      className={
                        this.props.isViewProfile === true
                          ? "customeRadioContainer radioDisable"
                          : "customeRadioContainer radioEnable"
                      }
                    >
                      {polyglot.t(value.CATEGORY)}
                      <input
                        type={"radio"}
                        name={"ocrPDFSelection"}
                        value={value.ID}
                        checked={parseInt(this.state.selectedPdfVal) === parseInt(value.ID)}
                        disabled={this.props.isViewProfile === true ? true : false}
                        onChange={this.handleChange}
                        // onChange={(e) => this.props.changed(this.props.tab5PDFAttr, e, false)}
                      />
                      <span className="radioCheckMark"></span>
                    </label>
                  </div>
                );
              })
            ) : (
              <div> {polyglot.t("No Data found")} </div>
            )}
          </div>
        </div>
        <div className="horizontalLine">
          {" "}
          <hr />{" "}
        </div>
        <div className="imageSection">
          <div className="profileHeading ocrTabHed">
            {" "}
            <p> {polyglot.t("Images")} </p>{" "}
          </div>
          <div className="form-group">
            {this.props.tab5Images.length > 0 ? (
              this.props.tab5Images.map((value, key) => {
                return (
                  <div key={key + "_non_spec_img"}>
                    <label
                      className={
                        this.props.isViewProfile === true
                          ? "customeRadioContainer radioDisable"
                          : "customeRadioContainer radioEnable"
                      }
                    >
                      {polyglot.t(value.SUB_CATEGORY)}
                      <input
                        type={"radio"}
                        name={"ocrNonSpecificImg"}
                        value={value.ID}
                        checked={parseInt(this.state.selectedNoneImgVal) === parseInt(value.ID)}
                        disabled={this.props.isViewProfile === true ? true : false}
                        onChange={this.handleOcrImageChange}
                      />
                      <span className="radioCheckMark"></span>
                    </label>
                  </div>
                );
              })
            ) : (
              <div> {polyglot.t("No Data found")} </div>
            )}
            <div className="subTextMargin">
              {this.props.tab5ImagesType.length > 0 &&
              this.state.selectedNoneImgVal ===
                this.props.tab5Images.find((img) => img.SUB_CATEGORY === "Specific types").ID ? (
                this.props.tab5ImagesType.map((value, key) => {
                  return (
                    <div key={key + "_img_type_tab5"}>
                      <Checkbox
                        key={value.ID}
                        value={value.ID}
                        data-checkboxname={"ocrTabSpecificImgCheckBoxList"}
                        name={value.name}
                        checked={value.isChecked}
                        onChange={this.props.ocrSpecificImageChange}
                        disabled={this.props.isViewProfile === true ? true : false}
                      >
                        {value.SUB_CATEGORY}
                      </Checkbox>
                    </div>
                  );
                })
              ) : (
                <div />
              )}
            </div>
            <FormErrors formErrors={this.props.ocrImgErr} />
            <p className="checkBoxPText"> </p>
            <div className="horizontalLine">
              {" "}
              <hr />{" "}
            </div>
          </div>
          <div className="checkBoxSection">
            {this.props.tab5ExcludeType.length > 0 ? (
              this.props.tab5ExcludeType.map((value, key) => {
                return (
                  <div key={key + "_tab5_exc_type"}>
                    <Checkbox
                      key={value.id}
                      value={JSON.stringify(value.id)}
                      data-checkboxname={"ocrTabExcludeCheckBoxList"}
                      name={value.name}
                      checked={value.isChecked}
                      onChange={(e) => this.props.changed(value.attr, e, true)}
                      disabled={this.props.isViewProfile === true ? true : false}
                    >
                      {value.title}
                    </Checkbox>
                    <p className="checkBoxPText"> </p>
                  </div>
                );
              })
            ) : (
              <div> {polyglot.t("No Data found")} </div>
            )}
            <div className="horizontalLine">
              {" "}
              <hr />{" "}
            </div>
            <p className="checkBoxPText"> </p>
            <RadioGroup
              name="ocrTextModify"
              onChange={(e) => this.props.changed(this.props.tab5ModifyTextAttr, e, false)}
            >
              {this.props.tab5TextSection.length > 0 ? (
                this.props.tab5TextSection.map((value, key) => {
                  return (
                    <div key={key + "_ocr_text_modify"}>
                      <label
                        className={
                          this.props.isViewProfile === true
                            ? "customeRadioContainer radioDisable"
                            : "customeRadioContainer radioEnable"
                        }
                      >
                        {value.title}
                        <input
                          type={"radio"}
                          name={"ocrTextModify"}
                          className={"ocrModifyTextClass"}
                          value={value.id}
                          disabled={this.props.isViewProfile === true ? true : false}
                          onChange={(e) => this.props.changed(this.props.tab5ModifyTextAttr, e, false)}
                        />
                        <span className="radioCheckMark"></span>
                      </label>
                    </div>
                  );
                })
              ) : (
                <div> {polyglot.t("No Data found")} </div>
              )}
            </RadioGroup>
          </div>
          <p className="checkBoxPText"> </p>
          <div className="horizontalLine">
            {" "}
            <hr />{" "}
          </div>
          <p className="checkBoxPText"> </p>
          <div className="qualityRotationSection">
            <div className="form-group">
              <label className="cmnLblStyl">{polyglot.t("Quality")}</label>
              {this.props.tab5Quality.length > 0 ? (
                <Select
                  placeholder="Choose Quality"
                  name="qualityDropDwon"
                  value={this.props.ocrQuality}
                  showSearchOnToggle={false}
                  onSelect={(e) => this.props.changed(this.props.ocrQualityAttr, e, false)}
                  className="dropDownInputWidth"
                  disabled={this.props.isViewProfile === true ? true : false}
                >
                  {this.props.tab5Quality.map((value, key) => {
                    return (
                      <SelectOption key={key + "_quality_tab5"} value={value.PR_Q_ID}>
                        {" "}
                        {value.PR_Q_NAME}
                      </SelectOption>
                    );
                  })}
                </Select>
              ) : (
                <div> {polyglot.t("No Data found")} </div>
              )}
              <FormErrors formErrors={this.props.ocrQualityErr} />
            </div>
            <div className="form-group">
              <label className="cmnLblStyl">{polyglot.t("Rotation")}</label>
              {this.props.tab5Rotation.length > 0 ? (
                <Select
                  placeholder="Choose Rotation"
                  name="rotationDropDown"
                  value={this.props.ocrRation}
                  showSearchOnToggle={false}
                  onSelect={(e) => this.props.changed(this.props.ocrRationAttr, e, false)}
                  className="dropDownInputWidth"
                  disabled={this.props.isViewProfile === true ? true : false}
                >
                  {this.props.tab5Rotation.map((value, key) => {
                    return (
                      <SelectOption key={key + "_rotation_tab5"} value={value.PR_ROTATION_ID}>
                        {" "}
                        {value.ROTATION_NAME}
                      </SelectOption>
                    );
                  })}
                </Select>
              ) : (
                <div> {polyglot.t("No Data found")} </div>
              )}
              <FormErrors formErrors={this.props.ocrRotationErr} />
            </div>
          </div>
          <div className="horizontalLine">
            {" "}
            <hr />{" "}
          </div>
          <p className="checkBoxPText"> </p>
          <div className="languageSection">
            <div className="form-group">
              <label className="cmnLblStyl">{polyglot.t("Language")}</label>
              {this.props.tab5AnalysisLanguage.length > 0 ? (
                <div className="dropDownInputWidth">
                  <Select
                    placeholder={polyglot.t("Choose language")}
                    showSearchOnToggle={true}
                    multiple
                    value={this.props.ocrLanguageVal}
                    onSelect={(e) => this.props.changed(this.props.ocrLanguageAttr, e, false)}
                    disabled={this.props.isViewProfile === true ? true : false}
                  >
                    {this.props.tab5AnalysisLanguage.map((value, key) => {
                      return (
                        <SelectOption key={key + "_tab5_ana_lan"} value={value.ID}>
                          {polyglot.t(value.language)}
                        </SelectOption>
                      );
                    })}
                  </Select>
                </div>
              ) : (
                <div> {polyglot.t("No Data found")} </div>
              )}
              <FormErrors formErrors={this.props.ocrLanguageErr} />
            </div>
          </div>

          <div className="horizontalLine">
            {" "}
            <hr />{" "}
          </div>
          <p className="checkBoxPText"> </p>
          <div className="form-group">
            <div className="secondary-input-container SecondaryInCon">
              <label className="cmnLblStyl" aria-label={polyglot.t("OCR Timeout value")}>
                {" "}
                {polyglot.t("OCR Timeout value")}{" "}
              </label>
              <Input
                className="dropDownInputWidth aInputTextCustom"
                name="ocrTimeoutValue"
                aria-label={polyglot.t("OCR Timeout value")}
                id="ocrTimeoutValInputId"
                onChange={(e) => this.props.changed(this.props.ocrTimeOutAttr, e, false)}
                value={this.props.selectedOcrTimeoutVal !== 0 ? this.props.selectedOcrTimeoutVal : ""}
                disabled={this.props.isViewProfile === true ? true : false}
              />
              <p className="subTextFont subTextMarginForCheckBox">
                {" "}
                {polyglot.t("Give OCR Time out value in minutes")}{" "}
              </p>
            </div>
          </div>

          <div className="form-group">
            <div key="isOcrCache">
              {this.props.ocrClearCacheData.length > 0 ? (
                this.props.ocrClearCacheData.map((value, key) => {
                  return (
                    <div key={key + "_tab5_exc_type"}>
                      <Checkbox
                        key={value.id}
                        value={JSON.stringify(value.id)}
                        data-checkboxname={"ocrTabClearCacheCheckBoxList"}
                        name={value.name}
                        checked={value.isChecked}
                        onChange={(e) => this.props.changed(value.attr, e, true)}
                        disabled={this.props.isViewProfile === true ? true : false}
                      >
                        {value.title}
                      </Checkbox>
                      <p className="checkBoxPText"> </p>
                    </div>
                  );
                })
              ) : (
                <div> {polyglot.t("No Data found")} </div>
              )}
              <p className="checkBoxPText"> </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default TabViewOcr;
