/* eslint-disable no-mixed-spaces-and-tabs */
import {
  Accordions,
  AccordionItem,
  AccordionItemHeader,
  AccordionItemBody,
  TextArea,
  Input,
} from "appkit-react";
import { TextEditor } from "appkit-react-editor";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import React, { useEffect, useState } from "react";

import DependancyQuestion from "./DependancyQuestionFolder/DependancyQuestion";
import CustomModal from "../../../Assets/Components/Modal";
import QuestionService from "../../../Services/GRC/QuestionService";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import { checkIfNumberIsDecimal } from "../handlers";

const AnswerBox = ({ answerIndex, answer, isReadonly, answerTypeId, handleChanges }) => {
  const polyglot = polyglotLoader();
  const [editorState, setEditorState] = useState(() =>
    answer.editor_raw_state
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(answer.editor_raw_state)))
      : EditorState.createEmpty(),
  );

  if (isReadonly || answerTypeId === 5) {
    return (
      <div className="readonly-answer-block" dangerouslySetInnerHTML={{ __html: answer.answer_text }}></div>
    );
  }

  const defaultProps = {
    placeholder: polyglot.t("answer_of_your_quest"),
    className: "Notes question-txt-area",
    "aria-label": polyglot.t("answer_of_your_quest"),
  };

  return (
    <TextEditor
      {...defaultProps}
      editorProps={{
        editorState,
      }}
      toolbar={{
        controls: [["Bold", "Italic"]],
      }}
      onChange={(htmlContent, state) => {
        handleChanges(
          JSON.stringify(convertToRaw(state.getCurrentContent())),
          answerIndex,
          "editor_raw_state",
        );
        handleChanges(htmlContent, answerIndex, "answer_text");
        setEditorState(state);
      }}
    />
  );
};

const AnswerBlock = ({ setAnswerdata, answers, answerTypeId, categoryid, disableAnswerTypes }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [expandCollapseKey, setExpandCollapseKey] = React.useState(["answer-block1"]);

  const polyglot = polyglotLoader();
  // modal
  const [showModal, setShowModal] = useState(false);
  const [redioQuestionsDefaultValue, setRedioQuestionsDefaultValue] = useState(-1);
  const [questionDescription, setQuestionDescription] = useState("");
  const [currentquestion, setCurrentQuestion] = useState(0);
  const [chieldCateqoriesByRoot, setChieldCateqoriesByRoot] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [scoringError, setScoringError] = useState("");
  const [weightageError, setWeightageError] = useState("");
  const [save, setSave] = useState(false);
  // VALIDATION OF SCORING FIELD
  const validationOfScoring = (value) => {
    try {
      let validationSuccessful = false;
      let errorMsg = "";
      // should not consider a decimal value
      if (checkIfNumberIsDecimal(Number(value))) {
        errorMsg = "Scoring cannot be in decimal value";
      }
      // min = 1, max = no limit
      if (Number(value) < 1) {
        errorMsg = "Scoring cannot be less than one";
      }
      setScoringError(errorMsg);
      if (errorMsg === "") {
        // validation successful
        validationSuccessful = true;
      } else {
        validationSuccessful = false;
      }
      return validationSuccessful;
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // VALIDATION OF WEIGHTAGE FIELD
  const validationOfWeightage = (value) => {
    try {
      let validationSuccessful = false;
      let errorMsg = "";
      // min = 0.1, max = no limit
      if (Number(value) < 0.1) {
        errorMsg = "Weightage cannot be less than 0.1";
      }
      setWeightageError(errorMsg);
      if (errorMsg === "") {
        // validation successful
        validationSuccessful = true;
      } else {
        validationSuccessful = false;
      }
      return validationSuccessful;
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // VALIDATION OF ANSWER TEXT
  const validationOfAnswerText = (value) => {
    try {
      let validationSuccessful = false;
      // restrict the user with max of 2000 char limit
      if (value.length > 2000) {
        // do not update the value
        validationSuccessful = false;
      } else {
        validationSuccessful = true;
      }
      return validationSuccessful;
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // VALIDATION OF ADDITIONAL ANSWER TEXT
  const validationOfAdditionalAnswerText = (value) => {
    try {
      let validationSuccessful = false;
      // restrict the user with max of 2000 char limit
      if (value.length > 2000) {
        // do not update the value
        validationSuccessful = false;
      } else {
        validationSuccessful = true;
      }
      return validationSuccessful;
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // updates the answer data
  const updateAnswerData = (data, index, field) => {
    try {
      const updateData = answers.map((item, i) =>
        i === index ? Object.assign(item, { [field]: data }) : item,
      );
      setAnswerdata(updateData);
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  const handleChanges = (data, index, field) => {
    switch (field) {
      case "editor_raw_state":
        updateAnswerData(data, index, field);
        break;
      case "answer_text":
        if (validationOfAnswerText(data)) {
          updateAnswerData(data, index, field);
        } else {
          // validation of answer_text is failed
        }
        break;
      case "additional_answer_text":
        if (validationOfAdditionalAnswerText(data)) {
          updateAnswerData(data, index, field);
        } else {
          // validation of additional_answer_text is failed
        }
        break;
      case "scoring":
        // validation of scoring
        updateAnswerData(data, index, field);
        if (validationOfScoring(data)) {
          // validation of scoring is successful and is valid
        } else {
          // validation of scoring is failed
          updateAnswerData(true, index, "hasError");
        }
        break;
      case "weightage":
        // validation of weightage
        updateAnswerData(data, index, field);
        if (validationOfWeightage(data)) {
          // validation of weightage is successful and is valid
        } else {
          // validation of weightage is failed
          updateAnswerData(true, index, "hasError");
        }
        break;
      default:
        updateAnswerData(data, index, field);
    }
    // all the validations are successfull, then update hasError to false
    const scoringValue = answers[index].scoring;
    const weightageValue = answers[index].weightage;
    if (validationOfScoring(scoringValue) && validationOfWeightage(weightageValue)) {
      updateAnswerData(false, index, "hasError");
    }
  };

  const test = (id) => {
    setCurrentQuestion(id);
  };

  const openModal = (cIndex) => {
    setCurrentIndex(cIndex);
    setShowModal(true);
  };
  const addDependancy = () => {
    // update handle change
    const updateData = answers.map((item, i) =>
      i === currentIndex ? Object.assign(item, { ["dependency_question_id"]: currentquestion }) : item,
    );

    setAnswerdata(updateData);
    setShowModal(false);
  };

  const updateQuestionDescription = (description, questionid) => {
    setQuestionDescription(description);
    setCurrentQuestion(questionid);
  };
  useEffect(() => {
    const getCategories = async () => {
      try {
        const responseData = await sendRequest(QuestionService.get().getQuestionsbyParent(categoryid));
        // setChieldCateqoriesByRoot(responseData);
      } catch (err) {
        alert(err);
      }
    };
    getCategories();
  }, [sendRequest]);

  useEffect(() => {
    if (save && currentquestion > 0) {
      addDependancy();
      setSave(false);
    }
  }, [save, currentquestion]);

  // used to update the state value to display the selected dependency question
  useEffect(() => {
    if (currentIndex >= 0) {
      const dependency_question_id = answers[currentIndex]["dependency_question_id"];
      // used to display the selected dependency question id
      setCurrentQuestion(dependency_question_id);
    }
  }, [currentIndex]);

  // EXPANDINGA ND COLLAPSEING BASED ON CONDITION
  const expandCollpaseHandler = (e) => {
    setExpandCollapseKey((prevExpandCollapseKey) => {
      if (prevExpandCollapseKey[0] === e) {
        return [];
      } else {
        return [e];
      }
    });
  };

  const setmodal = () => {
    setShowModal(false);
  };
  // DISPLAY ANSWER BLOCK IN ACCORDIANS

  return (
    <>
      <div className="overflow-auto answers-block-accordian">
        <Accordions activeItem={expandCollapseKey} onChange={expandCollpaseHandler}>
          {answers.map((answer, answerIndex) => {
            const isReadonly = disableAnswerTypes.includes(answer.answer_text);
            return (
              <AccordionItem itemId={`answer-block${answerIndex + 1}`} key={answer.id}>
                <AccordionItemHeader title={`Answer ${answerIndex + 1}`} />
                <AccordionItemBody>
                  <div className="ans-block mb-5">
                    <div className="row mt-4">
                      <div className="col-md-6">
                        <AnswerBox
                          isReadonly={isReadonly}
                          answerIndex={answerIndex}
                          answer={answer}
                          answerTypeId={answerTypeId}
                          handleChanges={handleChanges}
                        />

                        {/* IF FIELD IS EDITABLE, THEN SHOW THE BELOW div */}
                        {!isReadonly && (
                          <div>
                            {/* MAX NUMBER OF CHARACTERS ALLOWED IS 2000 */}
                            {answer.answer_text.length}/2000
                          </div>
                        )}
                      </div>
                      <div className="col-md-6">
                        <TextArea
                          placeholder={polyglot.t("additional_answer_information_texts")}
                          className="Notes question-txt-area"
                          aria-label={polyglot.t("additional_answer_information_texts")}
                          onChange={(e) => {
                            handleChanges(e, answerIndex, "additional_answer_text");
                          }}
                          value={answer.additional_answer_text}
                        />
                        {/* MAX NUMBER OF CHARACTERS ALLOWED IS 2000 */}
                        <div>{answer.additional_answer_text.length}/2000</div>
                      </div>
                    </div>
                    <div className="row mt-4">
                      <div className="col-md-4">
                        {/* <ButtonSecondaryWithIcon
                          onClick={() => openModal(answerIndex)}
                          ariaLabel={polyglot.t("add_dependancy_question")}
                          iconName={plusCircle}
                          className="button-expandable input-field-height"
                        >
                          {polyglot.t("add_dependancy_question")}
                        </ButtonSecondaryWithIcon> */}
                      </div>
                      <div className="col-md-4"></div>
                      <div className="col-md-4">
                        <Input
                          type="number"
                          inputBoxSize="sm"
                          placeholder="Weightage *"
                          onChange={(e) => {
                            handleChanges(e, answerIndex, "weightage");
                          }}
                          value={answer.weightage}
                        />
                        {answer.hasError && weightageError.length > 0 && (
                          <div className="error">{weightageError}</div>
                        )}
                      </div>
                    </div>
                  </div>
                </AccordionItemBody>
              </AccordionItem>
            );
          })}
        </Accordions>
      </div>
      <div className="add-dependancy-q-modal">
        <CustomModal
          show={showModal}
          onCancel={() => setShowModal(false)}
          title={polyglot.t("add_dependancy_question")}
          size="x-lg"
          dontshowButton={true}
        >
          <div className="row">
            <div className="col-md-12">
              <DependancyQuestion
                categoryId={categoryid}
                setSave={setSave}
                setmodal={setmodal}
                answers={answers}
                answerId={currentIndex}
                currentquestion={currentquestion}
                test={test}
              />
            </div>
          </div>
        </CustomModal>
      </div>
      {/* 
      PREVIOUS IMPLEMENTATION OF DEPENDENCT QUESTION
      <div className="add-dependancy-q-modal">
        <CustomModal
          show={showModal}
          onCancel={() => setShowModal(false)}
          title={polyglot.t("add_dependancy_question")}
          Footer={[           
              <ButtonPrimary
                disabled={chieldCateqoriesByRoot.length === 0 || parseInt(currentquestion)===0}
                ariaLabel={polyglot.t("Add")}
                onClick={() => addDependancy()}
              >
                {polyglot.t("Add")}
              </ButtonPrimary>
           
          ]}
        >
          <div className="row">Qid:{currentquestion}
            <div className="col-md-12">
              {error && <ErrorPanel error={error} onClear={clearError} />}
              {isLoading && (
                <div>
                  <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
                </div>
              )}
              {chieldCateqoriesByRoot.length === 0 && (
                <EmptyPanel massage={polyglot.t("no_question")} />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {questionDescription && (
                <div className="dep-question-desc">{questionDescription}</div>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="question-list questionslist mt-4">
                {chieldCateqoriesByRoot && chieldCateqoriesByRoot.length > 0 && (
                  <>
                  
                   
                    <RadioGroup
                      aria-label={polyglot.t("add_dependancy_question")}
                      name="dependancy_question"
                    >
                      {chieldCateqoriesByRoot.map((question, idx) => {
                        return (
                          <div className="dep-questions">
                            <Radio
                              value={question.id}
                              name={"dependancy_question"}
                              onClick={(e) =>
                                updateQuestionDescription(
                                  question.question_text,
                                  question.id
                                )
                              }
                            >
                              {question.question_text &&
                                (question.question_text.length > 40
                                  ? question.question_text.substring(0, 41) +
                                    "..."
                                  : question.question_text)}
                            </Radio>
                          </div>
                        );
                      })}
                    </RadioGroup>
                  </>
                )}
              </div>
            </div>
          </div>
        </CustomModal>
      </div> */}
    </>
  );
};

/* AnswerBlock.propTypes = {
  handleChange: PropTypes.func,
}; */
export default AnswerBlock;
