/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable sort-imports */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../../Assets/Css/QueenBase/Users/User.css";
import {
  getProjectID_Of_solution_module,
  getSelectedTextForMultiSelectBox,
} from "../../../Services/Shared/Handlers";
import { Progress } from "antd";
import $ from "jquery";
import { polyglotLoader, userLanguage } from "../../../Services/Shared/Translate";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import CustomDropZone from "../../DF/Start/SharedComponents/CustomDropZone";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import { closeIcon } from "../../../Assets/Icons";
import DownloadTemplate from "./DownloadTemplate";
import XLSX from "xlsx";
import { Panel, Select, SelectGroupTitle, SelectOption, Input, Tooltip, TextArea } from "appkit-react";
import { CalendarPicker } from "appkit-react-datepicker";
import Scoring from "./Scoring";
import SearchService from "../../../Services/CSS/SearchService";
import { CSS_HOME } from "../../../Constants/Path";
import moment from "moment";
import "moment/locale/de";
import { DataContext } from "../../../Services/Shared/Store";
import BulkUploadFileValidation from "./BulkUploadFileValidations";

const ALLOWED_BULK_UPLOAD_FILE_SIZE_IN_MB = 20;

class BulkUploadComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileDetails: "",
      isCorrectFile: false,
      errorsList: [],
      file: [],
      businessPartnerValues: [],
      submitButton: false,
      scoringParameter: "0.70",
      sourceList: [],
      sourceParameter: [],
      userenterfilename: "",
      nameError: false,
      userComment: "",
      dateFilter: {
        fromDate: "", // ISO date format
        toDate: "", // ISO date format
      },
    };
  }
  polyglot = polyglotLoader();

  static contextType = DataContext;

  checkIfTheSheetIncludeAnyOtherColumn = (sheetObject) => {
    const sheetRows = XLSX.utils.sheet_to_row_object_array(sheetObject);
    const isMultipleColumnsPresent = sheetRows.some((row) => Object.keys(row).length > 2);
    const notEnoughColumnsPresent = sheetRows.some((row) => Object.keys(row).length < 2);

    return {
      isMultipleColumnsPresent,
      notEnoughColumnsPresent,
    };
  };

  getFileRows = (data, options) => {
    const workbook = XLSX.read(data, options);
    const selectedSheet = workbook.SheetNames[0];
    const fileRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[selectedSheet]);
    return fileRows;
  };

  processCSV(csvFile, data, options = { type: "binary" }) {
    try {
      const errMsg = [];
      const csvRows = this.getFileRows(data, options);
      let values = [];

      if (csvRows.length > 0) {
        const { headerColumnIndex, headerErrMsg, arrOfValues, excelValidationType } =
          new BulkUploadFileValidation().csvHeaderCheck(csvRows);

        if (excelValidationType) return this.processExcel(csvFile, data, options);

        if (headerErrMsg) {
          errMsg.push({ msg: headerErrMsg });
        } else {
          arrOfValues.map((row) => {
            const val = row[headerColumnIndex];
            if (!values.includes(val) && val) values.push(val);
          });

          if (!values.length) {
            errMsg.push({ msg: "The 'Business Partner' column is empty" });
            values = [];
          }
        }

        if (errMsg.length > 0) {
          this.updateStateOnValidation(errMsg);
          return false;
        } else {
          this.setState(
            {
              isCorrectFile: true,
              errorsList: [],
              file: csvRows,
              submitButton: true,
              fileDetails: csvFile,
              businessPartnerValues: values,
            },
            () => {
              const polyglot = polyglotLoader();
              $("#validateFileSuccessMsg").text(polyglot.t("File Validated Sucessfully."));
              $("#validateFileErrorMsg").text("");
            },
          );
          return true;
        }
      } else {
        // file is empty
        errMsg.push({
          msg: "Observed that the file is empty. Please upload a file with valid rows in it",
        });
        this.updateStateOnValidation(errMsg);
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  }

  processExcel(excelFile, data, options = { type: "binary" }) {
    try {
      const errMsg = [];
      const excelRows = this.getFileRows(data, options);
      let values = [];

      if (excelRows.length > 0) {
        const { headerRowIndex, headerErrMsg, headerObjKey } = new BulkUploadFileValidation().headerCheck(
          excelRows,
        );

        if (headerErrMsg) {
          errMsg.push({ msg: headerErrMsg });
        } else {
          const trimmedHeader = new BulkUploadFileValidation().getTrimmedLowerCaseWord(headerObjKey);

          excelRows.map((row, i) => {
            let val;
            if (trimmedHeader === "businesspartner") {
              val = row[headerObjKey];
            } else if (i > headerRowIndex) val = row[headerObjKey];

            if (!values.includes(val) && val) values.push(val);
          });

          if (!values.length) {
            errMsg.push({ msg: "The 'Business Partner' column is empty" });
            values = [];
          }
        }
        if (errMsg.length > 0) {
          this.updateStateOnValidation(errMsg);
          return false;
        } else {
          this.setState(
            {
              isCorrectFile: true,
              errorsList: [],
              file: excelRows,
              submitButton: true,
              fileDetails: excelFile,
              businessPartnerValues: values,
            },
            () => {
              const polyglot = polyglotLoader();
              $("#validateFileSuccessMsg").text(polyglot.t("File Validated Sucessfully."));
              $("#validateFileErrorMsg").text("");
            },
          );
          return true;
        }
      } else {
        // file is empty
        errMsg.push({
          msg: "Observed that the file is empty. Please upload a file with valid rows in it",
        });
        this.updateStateOnValidation(errMsg);
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  }

  updateStateOnValidation = (errMsg) => {
    if (errMsg.length > 0) {
      // error is present
      this.setState({
        isCorrectFile: false,
        errorsList: errMsg,
        submitButton: false,
      });
    }
  };

  onFileDrop = (files) => {
    const currentFile = files[0];
    const allowedTypes = ["csv", "vnd.ms-excel", "vnd.openxmlformats-officedocument.spreadsheetml.sheet"];
    const fileType = currentFile.type.split("/").pop();
    if (!allowedTypes.includes(fileType)) {
      this.setState({ isCorrectFile: false });
      return false;
    }

    const currentFileSizeInMb = currentFile.size / 1024 / 1024;
    if (currentFileSizeInMb > ALLOWED_BULK_UPLOAD_FILE_SIZE_IN_MB) {
      this.setState({ isCorrectFile: false });
      return false;
    }

    if (fileType === "csv") {
      this.processCsvFile(currentFile);
    } else {
      this.processXlsxFile(currentFile);
    }
  };

  processCsvFile = (file) => {
    const fileReader = new FileReader();
    fileReader.readAsBinaryString(file);
    fileReader.addEventListener("load", (event) => {
      const workbookBinaryString = event.target.result;
      this.processCSV(file, workbookBinaryString, { type: "binary" });
    });
  };

  processXlsxFile = (file) => {
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(file);
    fileReader.addEventListener("load", (event) => {
      const workbookBuffer = event.target.result;
      this.processExcel(file, workbookBuffer, { type: "buffer" });
    });
  };

  // VIEW TO UPLOAD FILE
  UploadFileView = () => {
    if (!this.state.fileDetails.name) {
      return (
        <div>
          <div className="Content">
            <div>
              <CustomDropZone
                onFilesAdded={this.onFileDrop}
                customDropZoneBoxStyle={{
                  width: "100%",
                  margin: "0",
                  padding: "30px",
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  // REMOVE UPLOADED FILE
  removeUplaodedFile = () => {
    $("#validateFileErrorMsg").text("");
    $("#validateFileSuccessMsg").text("");
    this.setState({
      fileDetails: "",
      isCorrectFile: false,
      submitButton: false,
    });
  };
  // DISPLAY UPLOADED FILES
  displayUploadedFiles = () => {
    const fileObj = this.state.fileDetails;
    const uploadPercent = 100;
    if (fileObj.name) {
      const sizeInKB = fileObj.size / 1000;
      const sizeInMB = fileObj.size / 1024 / 1024;
      return (
        <div>
          <span>
            <b>{fileObj.name}</b>
          </span>
          <div className="row">
            <div className={"col-md-10 imageUpload"}>
              <Progress percent={Number(uploadPercent)} size="small" />
            </div>
            <div className="col-md-2 text-align-right">
              <ButtonIcon
                onClick={this.removeUplaodedFile}
                iconName={closeIcon}
                ariaLabel={this.polyglot.t("Close")}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              {sizeInMB > 1 && <p className="text-align-left">{sizeInMB.toFixed(2)} MB</p>}
              {sizeInMB < 1 && <p className="text-align-left">{sizeInKB.toFixed(2)} KB</p>}
            </div>
            <div className="col-md-6">
              {Number(uploadPercent) < 100 && Number(uploadPercent) > 0 && (
                <p className="text-align-right">{this.polyglot.t("Uploading...")}</p>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  getDifferenceInDays(toDate, fromDate) {
    let diffInDay = null;
    if (toDate != "" && fromDate !== "") {
      diffInDay = (new Date(toDate) - new Date(fromDate)) / (1000 * 60 * 60 * 24);
    }
    return diffInDay;
  }

  // CHECK FORM VALIDATION
  checkFormValidation = () => {
    let diffOfDays = null;
    if (this.state.dateFilter.fromDate && this.state.dateFilter.toDate) {
      diffOfDays = this.getDifferenceInDays(this.state.dateFilter.toDate, this.state.dateFilter.fromDate);
      if (diffOfDays < 0) {
        displayToastMessage(this.polyglot.t("From date cannot be less than To date"), "error");
        return false;
      }
    }

    if (!this.state.userenterfilename) {
      displayToastMessage(this.polyglot.t("Please enter name"), "error");
      return false;
    } else if (this.state.nameError === true) {
      displayToastMessage(this.polyglot.t("Name already exist"), "error");
      return false;
    } else if (this.state.sourceParameter.length === 0) {
      displayToastMessage(this.polyglot.t("Please select source"), "error");
      return false;
    } else if (this.state.userComment.length > 400) {
      displayToastMessage(this.polyglot.t("Comment cannot be more than 400 characters"), "error");
      return false;
    } else {
      return true;
    }
  };

  // UPDATE SOURCES ON CHANGE EVENT====================
  sourceParameterdata = (value) => {
    const { formGotOpened } = this.context;
    if (value.includes("appkit-select-component-select-all-key")) {
      // if all options are selected, then deselect.
      if (this.state.sourceParameter.length === this.state.sourceList.length) {
        this.setState({
          sourceParameter: [],
        });
      } else {
        // if all options are not selected
        this.setState({
          sourceParameter: this.state.sourceList.map((each) => each.name),
        });
      }
    } else {
      this.setState({
        sourceParameter: value,
      });
    }
    formGotOpened();
  };
  // UPDATE SCORING ON CHANGE EVENT====================
  scoringParameterdata = (value) => {
    const { formGotOpened } = this.context;
    this.setState({
      scoringParameter: value,
    });
    formGotOpened();
  };

  // UPDATE WORKFLOW ON SUBMITTING FORM
  handleSubmit = async (e) => {
    const { formGotClosed } = this.context;
    let data = {};
    //user enter file name
    const userenterfilename = this.state.userenterfilename;
    data.requestName = userenterfilename;

    //projectId
    const projectId = getProjectID_Of_solution_module();
    data.projectId = projectId;
    //sources
    const sourceParameter = this.state.sourceParameter;
    data.sourceParameter = sourceParameter;
    //scoring
    const scoringParameter = this.state.scoringParameter;
    data.scoringParameter = scoringParameter;
    //Usercomment
    const userComment = this.state.userComment;
    data.comments = userComment;
    //Date
    let fromDate = null;
    if (this.state.dateFilter.fromDate != "") {
      fromDate = moment(this.state.dateFilter.fromDate).format("YYYY-MM-DD");
    }
    data.fromDate = fromDate;
    let toDate = null;
    if (this.state.dateFilter.toDate != "") {
      toDate = moment(this.state.dateFilter.toDate).format("YYYY-MM-DD");
    }
    data.toDate = toDate;
    //csv data
    const csvData = this.state.file;
    data.csvData = csvData;
    data.businessPartnerValues = this.state.businessPartnerValues;
    let formData = new FormData();

    const formValidationSuccess = this.checkFormValidation();

    if (formValidationSuccess) {
      formData.append("upload_file", this.state.fileDetails);
      formData.append("upload_fileName", this.state.fileDetails.name);
      formData.append("upload_data", JSON.stringify(data));
      const res = await SearchService.get().saveBulkUploadRequest(formData);
      if (res.CODE) {
        displayToastMessage(this.polyglot.t("File successfully uploaded"), "success");
        formGotClosed();
        this.props.history.push(CSS_HOME);
      } else {
        displayToastMessage(this.polyglot.t(res.MSG), "error");
      }
    }
  };

  // RUNS ON INITIAL LOAD
  componentDidMount() {
    this.getSourceList();
  }

  // FETCH SOURCES FROM API
  async getSourceList() {
    try {
      var res = await SearchService.get().getSourcesList();
      if (res.CODE) {
        this.setState({
          sourceList: res.sources,
          sourceParameter: res.sources.map((each) => each.name),
        });
      } else {
        displayToastMessage(this.polyglot.t(res.MSG), "error");
      }
    } catch (err) {
      displayToastMessage(this.polyglot.t(err.message), "error");
    }
  }
  filename = (e) => {
    const { formGotOpened } = this.context;
    const val = e.target.value;
    this.checkRequestName(val);
    this.setState({
      userenterfilename: val,
    });
    formGotOpened();
  };
  TextAreaHandler = (e) => {
    this.setState({ userComment: e });
  };

  // ON CHANGING THE DATE OF A search
  handleChangeFromDate = (e) => {
    if (e) {
      let dateString = moment(e).format("DD.MM.YYYY");
      var strDate = dateString.split("."),
        date = new Date(parseInt(strDate[2]), parseInt(strDate[1]) - 1, parseInt(strDate[0]));
      const prevDate = date.setDate(date.getDate());

      const isoDate = new Date(prevDate);
      this.dateOnChange("fromDate", isoDate);
    }
  };

  // ON CHANGING THE DATE OF A MILESTONE
  handleChangeToDate = (e) => {
    if (e) {
      let dateString = moment(e).format("DD.MM.YYYY");
      var strDate = dateString.split("."),
        date = new Date(parseInt(strDate[2]), parseInt(strDate[1]) - 1, parseInt(strDate[0]));

      const nextDate = date.setDate(date.getDate());
      const isoDate = new Date(nextDate);
      this.dateOnChange("toDate", isoDate);
    }
  };
  //on change of date filter
  dateOnChange = (filterName, value) => {
    const dateFilterData = { ...this.state.dateFilter };
    dateFilterData[filterName] = value;
    this.setState({
      dateFilter: dateFilterData,
    });
  };

  disabledToDate = (current) => {
    // Can not select days after today
    return current && current > moment().endOf("day");
  };

  //check duplicate request name
  checkRequestName = async (val) => {
    try {
      const body = {
        requestName: val,
      };
      var res = await SearchService.get().checkRequestNameIfExists(body);
      if (res.CODE) {
        this.setState({
          nameError: res.isNameExists ? true : false,
        });
      } else {
        this.setState({
          nameError: false,
        });
        displayToastMessage(this.polyglot.t(res.MSG), "error");
      }
    } catch (err) {
      displayToastMessage(this.polyglot.t(err.message), "error");
    }
  };
  render() {
    this.polyglot = polyglotLoader();
    return (
      <div className="leftAlign">
        <Panel className=" a-mt-40 filterAlignment">
          <div className="row ">
            <div className="col-md-4">
              <input
                className="review-text-box ans-name a-input-text"
                id="profileNameId"
                placeholder={this.polyglot.t("RequestName")}
                aria-label={this.polyglot.t("RequestName")}
                value={this.state.userenterfilename}
                onChange={this.filename}
              />
              {this.state.nameError && <div className="error">{this.polyglot.t("Name already exists")}</div>}
            </div>
            <div className="col-md-3">
              {this.state.sourceList.length > 0 && userLanguage() === "en" && (
                <Select
                  value={this.state.sourceParameter}
                  onSelect={this.sourceParameterdata.bind(this)}
                  multiple={true}
                  placeholder={this.polyglot.t("Select source")}
                  showSelectAll={true}
                  customSelectAllStringOnList={this.polyglot.t("Select all")}
                  className="selectSource"
                  customSelectAllStringOnToggle={this.polyglot.t("All selected")}
                >
                  {this.state.sourceList.map((r, index) => {
                    return (
                      <SelectOption key={index + 1} value={r.name}>
                        {r.name}
                      </SelectOption>
                    );
                  })}
                </Select>
              )}
              {this.state.sourceList.length > 0 && userLanguage() === "ger" && (
                <Select
                  value={this.state.sourceParameter}
                  onSelect={this.sourceParameterdata.bind(this)}
                  multiple={true}
                  placeholder={this.polyglot.t("Select source")}
                  showSelectAll={true}
                  customSelectAllStringOnList={this.polyglot.t("Select all")}
                  className="selectSource"
                  customSelectAllStringOnToggle={this.polyglot.t("All selected")}
                  customSelectedCountStringGenerator={(activeItemsLength) =>
                    getSelectedTextForMultiSelectBox(activeItemsLength, this.polyglot)
                  }
                >
                  {this.state.sourceList.map((r, index) => {
                    return (
                      <SelectOption key={index + 1} value={r.name}>
                        {r.name}
                      </SelectOption>
                    );
                  })}
                </Select>
              )}
            </div>
            <div style={{ float: "left" }}>
              <Scoring
                label={this.polyglot.t("Scoring :")}
                updateScoringParmeter={this.scoringParameterdata.bind(this)}
                scoringParameter={this.state.scoringParameter}
                min={0.7}
                max={1}
                step={0.01}
              />
            </div>
          </div>

          <div style={{ clear: "both" }}></div>
          <div className="row ">
            <div className="col-md-4">
              <TextArea
                placeholder={this.polyglot.t("Comments")}
                value={this.state.userComment}
                onChange={this.TextAreaHandler}
                aria-label={this.polyglot.t("comments")}
              />
            </div>
            <div className="col-md-3">
              <div id="dt_picker">
                <CalendarPicker
                  placeholder={this.polyglot.t("From date")}
                  name="fromDate"
                  onChange={this.handleChangeFromDate}
                  maxDate={new Date()}
                  format={"dd.mm.yyyy"}
                  className="antd-datepicker-custom-styles hide-datepicker-close-icon mr-5"
                  value={this.state.dateFilter.fromDate != "" ? this.state.dateFilter.fromDate : null}
                />
                <CalendarPicker
                  placeholder={this.polyglot.t("To date")}
                  name="toDate"
                  onChange={this.handleChangeToDate}
                  maxDate={new Date()}
                  format={"dd.mm.yyyy"}
                  className="antd-datepicker-custom-styles hide-datepicker-close-icon"
                  value={this.state.dateFilter.toDate != "" ? this.state.dateFilter.toDate : null}
                />
              </div>
            </div>
          </div>
        </Panel>
        <div className="m-t-16">
          <DownloadTemplate />
        </div>
        &nbsp;
        <div className="sdnColor">
          <b>{this.polyglot.t("Validation of excel file:")}</b> <br />
          <b>1. {this.polyglot.t("Business Partner column with data")}</b> <br />
          <b>2. {this.polyglot.t("Business Partner header validation")}</b> <br />
          <b>3. {this.polyglot.t("Business Partner column data reading")}</b> <br />
          <b>4. {this.polyglot.t("Empty values are not stored as 'Business Partner'")}</b> <br />
          <b>5. {this.polyglot.t("Only distinct values are stored as 'Business Partner'")}</b> <br />
          <b>6. {this.polyglot.t("Content of the other columns is insignificant")}</b> <br />
          <br />
          <b>{this.polyglot.t("Validation of csv file:")}</b> <br />
          <b>1. {this.polyglot.t("Pipe is used as column separator")}</b> <br />
          <b>2. {this.polyglot.t("First row contains headers")}</b> <br />
          <b>3. {this.polyglot.t("The same count of columns")}</b> <br />
          <b>4. {this.polyglot.t("Business Partner is one column")}</b> <br />
          <b>5. {this.polyglot.t("Empty values are not stored as 'Business Partner'")}</b> <br />
          <b>6. {this.polyglot.t("Only distinct values are stored as 'Business Partner'")}</b> <br />
          <b>7. {this.polyglot.t("Content of the other columns is insignificant")}</b> <br />
          <br />
        </div>
        &nbsp;
        <div className="row mt-2 ">
          <div className="col-md-12 ">
            {this.UploadFileView()}
            {this.displayUploadedFiles()}
            <br />
            <ul>
              {this.state.errorsList.map((item, index) => (
                <li className="errorFntClr" key={index}>
                  {this.polyglot.t(item.msg)}
                  {item.rowNum ? item.rowNum : ""}
                </li>
              ))}
            </ul>
            <span id="validateFileErrorMsg" className="errorFntClr "></span>
            <span id="validateFileSuccessMsg" className="sucessFntClr"></span>
          </div>
        </div>
        <div className="row a-mt-20">
          <div className="col-md-6 ">
            {this.state.submitButton === true ? (
              <ButtonPrimary onClick={this.handleSubmit} ariaLabel={this.polyglot.t("Submit")}>
                {this.polyglot.t("Submit")}
              </ButtonPrimary>
            ) : (
              <ButtonPrimary disabled onClick={this.handleSubmit} ariaLabel={this.polyglot.t("Submit")}>
                {this.polyglot.t("Submit")}
              </ButtonPrimary>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default BulkUploadComponents;
