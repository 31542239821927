import { clearProjectID_Of_solution_module } from "../../Services/Shared/Handlers";

// SORT A JSON ARRAY IN DESCENDING ORDER
export const sortJSON_inDescendingOrder = (json, key) => {
  const sortedJSON = json.slice(0).sort((a, b) => b[key] - a[key]);
  return sortedJSON;
};

// GET PROJECT ID FROM THE APPLICATION URL
export const getProjectIDFromURL = (pathname) => {
  const splitted = pathname.split("/");
  return splitted[3];
};

// GET DASHBOARD URL BASED ON THE RECEIVED DOCUMENT ID
export const get_QlikView_Dashboard_URL_With_documentID = (documentID) => {
  if (documentID !== "" && documentID) {
    const dashboardURL =
      "https://datafactory.pwc.de/QVAJAXZFC/authenticate.aspx?type=html&try=/qvajaxzfc/opendoc.htm?document=";
    return `${dashboardURL}${documentID}&back=/LoginPage.htm&webticket=`;
  } else {
    const dashboardURL =
      "https://datafactory.pwc.de/QVAJAXZFC/authenticate.aspx?type=html&try=/qlikview&back=/LoginPage.htm&webticket=";
    return dashboardURL;
  }
};

// EXTRACT UPLOADED FILE NAME FROM THE UPLOADED FILE PATH
export const getFileNameFromPath = (filePath) => {
  if (filePath) {
    const isWindowsPath = filePath.includes("\\");
    let splittedFilePath = [];
    if (isWindowsPath) {
      splittedFilePath = filePath.split("\\");
    } else {
      splittedFilePath = filePath.split("/");
    }
    const lastIndex = splittedFilePath.length - 1;
    const fileName = splittedFilePath[lastIndex];
    return fileName;
  }
};

// Assign selected workflow id to session storage
export const setWorkflowID_Of_DF_module = (workflowId) => {
  sessionStorage.setItem("selectedWorkflow", workflowId);
};
export const setWorkflowName_Of_DF_module = (workflowName) => {
  sessionStorage.setItem("selectedWorkflowName", workflowName);
};
export const getWorkflowName_Of_DF_module = () => {
  const workflowName = sessionStorage.getItem("selectedWorkflowName");
  return workflowName;
};
// Get selected workflow id from session storage
export const getWorkflowID_Of_DF_module = () => {
  const workflowID = sessionStorage.getItem("selectedWorkflow");
  return workflowID;
};

// REMOVE selectedWorkflowName AND selectedWorkflow VALUES IN SESSION STORAGE
export const removeWorkflow_Details_Of_DF_module = () => {
  sessionStorage.removeItem("selectedWorkflow");
  sessionStorage.removeItem("selectedWorkflowName");
};
// Clear selected workflow name from session storage
const clearWorkflowName_Of_DF_module = () => {
  sessionStorage.removeItem("selectedWorkflowName");
};
// Clear selected workflow id from session storage
const clearWorkflowID_Of_DF_module = () => {
  sessionStorage.removeItem("selectedWorkflow");
};
// Clear session storage which got generated through df module
export const clearSessionStorage_Of_DF_module = () => {
  clearProjectID_Of_solution_module();
  clearWorkflowID_Of_DF_module();
  clearWorkflowName_Of_DF_module();
};

export const getWorkflowStatusName = (Item) => {
  if (Item.parameter_file_status === 1) {
    return "Parameter file requested";
  }
  if (Item.parameter_file_status === 2) {
    return "In progress";
  }
  if (Item.parameter_file_status === 3 && Item.dashboard_url) {
    return "Dashboard available";
  }
  if (
    Item.parameter_file_status === 3 &&
    (Item.dashboard_url === "" || !Item.dashboard_url) &&
    (Item.uploaded_file_path === "" || Item.uploaded_file_path === null)
  ) {
    return "Parameter file available";
  }
  if (
    Item.parameter_file_status === 3 &&
    (Item.dashboard_url === "" || !Item.dashboard_url) &&
    (Item.uploaded_file_path !== "" || Item.uploaded_file_path !== null)
  ) {
    return "Pushed to processing";
  }
};
