import React from "react";
import { Switch } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import AdminContainer from "../Components/RI/Admin/AdminContainer";
import HomeContainer from "../Components/RI/Home/HomeContainer";
import RequestFormContainer from "../Components/RI/Requests/RequestFormContainer";

function RIModuleRoutes() {
  return (
    <Switch>
      <ProtectedRoute path="/ri/home" component={HomeContainer} />
      <ProtectedRoute path="/ri/form" component={RequestFormContainer} />
      <ProtectedRoute path="/ri/admin" component={AdminContainer} />
    </Switch>
  );
}

export default RIModuleRoutes;
