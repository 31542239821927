import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import { refresh_jwt_token } from "./Authenticate";
import displayToastMessage from "../../Components/QueenBase/Root/displayToastMessage";
import AdminService from "../QueenBase/Admin/AdminServices";
import PermissionService from "../QueenBase/Permission/PermissionService";
import { getCurrentLanguage } from "../Shared/Translate";
export const DataContext = React.createContext();

export function Provider({ children }) {
  const [userLanguage, setUserLanguage] = useState("en");
  const [loadingRoles, setLoadingRoles] = useState(true);
  const [userRoles, setUserRoles] = useState([]);
  const [userProfileImage, setUserProfileImage] = useState("");
  const [isFormOpen, setFormOpen] = useState(false); // used to handle the form open status - used to not let user navigate to othere screens when form is in open state
  const [isUpdateUserImageOnHeader, setIsUpdateUserImageOnHeader] = useState(true);
  const [loadMessageOfTheDay, setLoadMessageOfTheDay] = useState(true); // by default it is true - used to fetch message of the day

  useEffect(() => {
    const userSelectedLanguage = getCurrentLanguage();
    setUserLanguage(userSelectedLanguage);
  }, []);

  const getUserRoles = async (screen_name, solution_name, projectId) => {
    try {
      setLoadingRoles(true);
      const roleRes = await PermissionService.get().getPermissionList(screen_name, projectId, solution_name);
      setUserRoles(roleRes);
      setLoadingRoles(false);
    } catch (err) {
      displayToastMessage(err, "error");
    }
  };
  const checkAndFetchRole = (screen_name, solution_name, projectId) => {
    let project_id = 0;
    if (projectId) {
      project_id = projectId;
    }
    getUserRoles(screen_name, solution_name, project_id);
  };

  const updateUserImageOnHeader = (bool) => {
    setIsUpdateUserImageOnHeader(bool);
  };

  const getCurrentUserPermissions = () => {
    if (userRoles.length > 0) {
      return userRoles[0].Permission;
    } else {
      return [];
    }
  };
  const getProjectPermissions = (projectId) => {
    try {
      return userRoles[0].PermissionByProject[projectId];
    } catch (err) {
      return [];
    }
  };
  const resetSessionTimer = async () => {
    // NOTE: this function runs every 2.5 secs
    const loginTimeStamp = sessionStorage.getItem("loginTimeStamp");
    if (loginTimeStamp) {
      // NOTE: if user is logged in
      const maximumSessionActiveTime = new Date(loginTimeStamp);
      maximumSessionActiveTime.setMinutes(new Date(loginTimeStamp).getMinutes() + 50);
      const distance = maximumSessionActiveTime - new Date();
      const mins = Math.round(distance / 1000 / 60);
      if (mins <= 5 && mins >= 1) {
        // NOTE: We call refresh jwt api, only if the user is logged in & jwt is about to expire in 5 mins
        const refreshedJwtToken = await refresh_jwt_token();
        if (refreshedJwtToken === "SUCCESS") {
          sessionStorage.setItem("loginTimeStamp", new Date().toLocaleString());
        }
      }
    }
  };

  const getUserLanguage = () => {
    return userLanguage;
  };

  const asignUserProfileImage = (img) => {
    setUserProfileImage(img);
  };
  // GET LANGUAGE KEY WHICH NEEDS TO BE PASSED TO API
  const getLanguageKeyAsPerAPI = () => {
    switch (userLanguage) {
      case "ger":
        return "de";
      default:
        return "en";
    }
  };

  const fetchCountriesList = async () => {
    const lang = getLanguageKeyAsPerAPI();
    try {
      setLoadingRoles(true);
      const countryRes = await AdminService.get().fetchCountriesListByLanguage(lang);
      setLoadingRoles(false);
      if (countryRes.CODE) {
        return countryRes.countriesList;
      } else {
        return [];
      }
    } catch (err) {
      displayToastMessage(err, "error");
      return [];
    }
  };

  // IF A FORM IS OPENED IN ANY SCREEN, THEN CALL THIS FUNCTION TO UPDATE THE STORE
  const formGotOpened = () => {
    setFormOpen(true);
  };
  // IF A FORM IS CLOSED IN ANY SCREEN, THEN CALL THIS FUNCTION TO UPDATE THE STORE
  const formGotClosed = () => {
    setFormOpen(false);
  };
  // trigger to fatch the message of the day
  const reloadMessageOfTheDay = () => {
    setLoadMessageOfTheDay(true);
  };
  // message of the day was fetched - hence we need to turn it to false
  const stopReloadMessageOfTheDay = () => {
    setLoadMessageOfTheDay(false);
  };

  const store = {
    userLanguage,
    setUserLanguage,
    checkAndFetchRole,
    getCurrentUserPermissions,
    getProjectPermissions,
    loadingRoles,
    resetSessionTimer,
    getUserLanguage,
    asignUserProfileImage,
    userProfileImage,
    fetchCountriesList,
    getLanguageKeyAsPerAPI,
    formGotOpened,
    formGotClosed,
    isFormOpen,
    updateUserImageOnHeader,
    isUpdateUserImageOnHeader,
    reloadMessageOfTheDay,
    stopReloadMessageOfTheDay,
    loadMessageOfTheDay,
  };
  return <DataContext.Provider value={store}>{children}</DataContext.Provider>;
}
Provider.propTypes = {
  children: PropTypes.any,
};
