//list of Clients avaliable
import { Pagination } from "antd";
import { Panel, Select, SelectOption } from "appkit-react";

import moment from "moment";

import { upperFirst } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";

import DeleteClient from "./DeleteClient";
import EditClientDetails from "./EditClientDetails";
import ViewClientDetails from "./ViewClientDetails";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import { deleteFill } from "../../../Assets/Icons";
import chevron from "../../../Assets/Icons/down-chevron-outline.svg";
import edit from "../../../Assets/Icons/pencil-outline.svg";
import Accordion from "../../../Assets/Icons/up-chevron-outline.svg";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/QueenBase/Root/loading-spin.svg";
import AdminService from "../../../Services/QueenBase/Admin/AdminServices";
import { getDisplayDate } from "../../../Services/Shared/Handlers";
import { hasDeletePermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";

const PER_PAGE = 5;
const ONLY_NUMBER_REGEX = /^\d+$/;

const isNumeric = (val) => ONLY_NUMBER_REGEX.test(val);

// RENDER CLIENT LIST
function ClientList(props) {
  const { listOfUsersOfClient, fetchClientDetailsById, acivateUser } = props;
  let mounted = true;
  const [show, setShow] = useState(false);
  const [pagelist, setPageList] = useState([]);
  const [ClientData, setclientData] = useState({});
  const [showDeleteClientModal, setshowDeleteClientModal] = useState(false);
  const [searchStr, setSearch] = useState("");
  const [loading, setloading] = useState(true);
  const [ClientsList, setClientsList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortOrder, setSortOrder] = useState("Newest"); // default sort by is done based on "Created On" records i.e. oldest records on top
  const [ClientListData, setClientListData] = useState([]);
  const [rclientid, setrclientid] = useState();
  const [editdata, setEditData] = useState(false);
  const [collapsedOnStart, setCollapsedOnStart] = useState(false);
  // DATA FROM CONTEXT
  const { getCurrentUserPermissions, getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const currentUserPermissions = getCurrentUserPermissions();

  // HANDLE PAGE CHANGE ON PAGINATION
  const handlePageChange = (change) => {
    setCurrentPage(change);
  };
  // CANCEL CLIENT FORM
  const cancelClientModal = (each) => {
    setclientData(each);
    setshowDeleteClientModal((prev) => !prev);
  };
  // RUNS WHEN SHOW IS UPDATED
  // useEffect(() => {
  // 	if (editdata) {
  // 		// form is in open state
  // 		formGotOpened()
  // 	} else {
  // 		// form is not in open state
  // 		formGotClosed()
  // 	}
  // }, [editdata])

  // ON SEARCH
  const onSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };
  // GET CLIENT DETAIL
  async function getClientDetail(r) {
    try {
      var res = await AdminService.get().getClientDetail(r.clientid);
      if (res.CODE) {
        setClientListData(res.ClientsList);
        return "success";
      } else {
        throw "failed";
      }
    } catch (err) {
      displayToastMessage(polyglot.t("No records found for this client"), "error");
      return "failed";
    }
  }

  // GET LIST OF CLIENTS FROM API
  const getClientsList = async () => {
    const res = await AdminService.get().ListOfClients();
    if (res.CODE) {
      if (mounted) {
        setClientsList(
          res.CODE.recordset.length > 0
            ? res.CODE.recordset.sort((a, b) => {
                return moment(a.createddate).isBefore(moment(b.createddate)) ? 0 : -1;
              })
            : [],
        );
        //setClientsList(res.CODE.recordset);previously written
      }
      setPageList(res.CODE.recordset);
      // default sort order is "newest"
      setSortOrder("Newest");
      setloading(false);
    } else {
      displayToastMessage(res.MSG, "error");
    }
  };
  // get list of clients of current page
  const getCurrentPageUsers = (users) => users.slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE);

  useEffect(() => {
    if (!collapsedOnStart && pagelist.length === 1) {
      setCollapsedOnStart(true);
      viewClient(pagelist[0], 0);
    }
  }, [collapsedOnStart, pagelist]);

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    document.body.classList.remove("bg-img");
    getClientsList();
    return function cleanup() {
      mounted = false;
    };
  }, []);
  //sortOrder useEffect
  useEffect(() => {
    if (sortOrder) {
      setCurrentPage(1);
      switch (sortOrder) {
        case "A-Z":
          // ascending order
          const ascendingList = pagelist.sort((a, b) => {
            if (a.clientid) {
              return a.clientid - b.clientid;
            }
          });
          setPageList([...ascendingList]);
          return;
        case "Z-A":
          // descending order
          const descendingList = pagelist.sort((a, b) => {
            if (b.clientid) {
              return b.clientid - a.clientid;
            }
          });
          setPageList([...descendingList]);
          return;
        case "Created On":
          setPageList([
            ...pagelist.sort((a, b) => {
              return moment(b.createddate).isAfter(moment(a.createddate)) ? -1 : 0;
            }),
          ]);
          return;
        case "Newest":
          setPageList([
            ...pagelist.sort((a, b) => {
              return moment(a.createddate).isBefore(moment(b.createddate)) ? 0 : -1;
            }),
          ]);

          return;
        default:
          return;
      }
    }
  }, [sortOrder]);

  // ADMIN FILTER SEARCH
  const adminFilterSearch = () => {
    return (
      <div className="col-md-12 p-0 m-b-2">
        <div className="row m-l-0 m-r-0 filter-search-qb">
          <div className={`searchIcon`}>
            <input
              className="SearchBar search-input"
              value={searchStr}
              placeholder={polyglot.t("Search clients")}
              onChange={onSearch}
              aria-label={polyglot.t("Search clients")}
            />
          </div>
          <div className="search-sort">
            {
              <Select
                id="filter-id-admin"
                placeholder={polyglot.t("Sort")}
                value={sortOrder}
                onSelect={setSortOrder}
              >
                <SelectOption key="A-Z" value="A-Z">
                  {getUserLanguage() === "en" ? polyglot.t("Client_ID") : polyglot.t("Client_ID")}
                  &nbsp;<span className="appkiticon icon-arrow-up-outline"></span>
                </SelectOption>
                <SelectOption key="Z-A" value="Z-A">
                  {getUserLanguage() === "en" ? polyglot.t("Client_ID") : polyglot.t("Client_ID")}
                  &nbsp;<span className="appkiticon icon-arrow-down-outline"></span>
                </SelectOption>
                <SelectOption key="Newest" value="Newest">
                  {polyglot.t("Newest")}
                </SelectOption>
                <SelectOption key="Created On" value="Created On">
                  {polyglot.t("Oldest")}
                </SelectOption>
              </Select>
            }
          </div>
        </div>
      </div>
    );
  };
  // EDIT A CLIENT
  const onEdit = async (r) => {
    setrclientid(r);
    // API TO GET CLIENT DETAILS BY PASSING CLIENTID
    const apiResponse = await getClientDetail(r);
    if (apiResponse === "success") {
      const list = [...pagelist];
      list.map((res) => {
        if (res.clientid === r.clientid) {
          res.isedit = res.isedit === 1 ? 0 : 1;
        } else {
          res.isedit = 0;
        }
      });
      setPageList(list);
    }
    setEditData(true);
  };
  // CLOSE EDIT CLIENT SCREEN
  const onEditClose = (refId) => {
    if(pagelist) {
    setPageList(
      pagelist.map((r) => {
        if (r.clientid === refId) {
          return {
            ...r,
            isedit: false,
          };
        }
        return r;
      }),
    );
    }
  };

  // GET CLIENT DETAILS ON EXPANDING A CLIENT
  const viewClient = async (r, index) => {
    try {
      // API TO FETCH CLIENT DETAILS ON PASSING CLIENTID
      const apiResponse = await fetchClientDetailsById(r.clientid);
      if (apiResponse === "success") {
        const list = [...pagelist];
        list.map((res) => {
          if (res.clientid === r.clientid) {
            res.isview = res.isview === 1 ? 0 : 1;
          } else {
            res.isview = 0;
          }
        });
        setPageList(list);
      }
    } catch (err) {
      displayToastMessage(err.MSG, "error");
    }
  };

  // SEARCH KEYS
  const searchKeys = (listObj) => {
    try {
      const keys = ["clientid", "createddate"];
      const keyValues = keys.map((key) => {
        if (key === "createddate") {
          const isodate = listObj[key];
          const displayedDate = getDisplayDate(isodate);
          return displayedDate;
        } else {
          return listObj[key];
        }
      });
      return keyValues.toString();
    } catch (err) {
      displayToastMessage(err.message, "error");
      return "";
    }
  };

  // FILTER CLIENTS
  const filterClients = (totalList) => {
    let filteredList = [...totalList];
    if (searchStr !== "") {
      // const trimmedSearchString = searchStr.trim();
      const listWithClientNameInIt = totalList.filter((r) => r.clientname);
      filteredList = listWithClientNameInIt.filter((r) => {
        return searchKeys(r).includes(searchStr.toLowerCase());
      });
    }
    return filteredList;
  };

  // HEADING OF INVOICE LIST
  const defaultRow = () => {
    return (
      <div>
        <div className="container-fluid project_container_edit p-0">
          <div className="row view-center mr-0">
            <div className="col-md-8 p-0">
              <Panel className="a-panel-project mb-2">
                <div className="a-text-heading">
                  <div className="row">
                    <div className="col-md-5 m-auto">
                      <div className="project-title-overflow project-data-desc view-center-name">
                        {polyglot.t("Client")}
                      </div>
                    </div>
                    <div className="col-md-5 ">
                      <div className="project-title-overflow project-data-desc view-center-name">
                        {polyglot.t("Created on")}
                      </div>
                    </div>

                    <div className="col-md-2 ">
                      <div className="project-title-overflow project-data-desc view-center-name-action pl-0">
                        {polyglot.t("Actions")}
                      </div>
                    </div>
                  </div>
                </div>
              </Panel>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div>
      <DeleteClient
        ClientData={ClientData}
        show={showDeleteClientModal}
        cancelClientModal={cancelClientModal}
      />
      <div className="container-fluid p-0 m-l-1">
        <h1 className="main_title_qb m-b-18 ">{polyglot.t("Client List")}</h1>
        {adminFilterSearch()}
        {defaultRow()}
        <div>
          <div className="container-fluid project_container_edit p-0">
            {loading === false ? (
              pagelist && filterClients(pagelist).length > 0 ? (
                getCurrentPageUsers(filterClients(pagelist)).map((each, index) => {
                  return each.isedit ? (
                    ClientListData[0] && (
                      <EditClientDetails
                        key={each.clientid}
                        ClientListData={ClientListData[0]}
                        each={each}
                        fetchClientsAfterEdit={getClientsList}
                        onClose={onEditClose}
                        rclientid={rclientid}
                      />
                    )
                  ) : (
                    <div key={each.clientid} className="row m-0 ">
                      <div className="row w-100 p-0 mr-0 ml-0">
                        <div className="col-md-8 Admin-data">
                          <div className="row">
                            <div className="col-md-5">
                              <div className="Admin-tittle " style={{ marginLeft: 10 }}>
                                <div className="f-acc capitalisedText">
                                  {!isNumeric(each.clientname) ? each.clientname : each.clientid}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="Admin-tittle " style={{ marginLeft: 10 }}>
                                <div className="f-acc capitalisedText">
                                  {getDisplayDate(each.createddate)}
                                </div>
                              </div>
                            </div>
                            <div className=" col-md-2 ">
                              <div className="row ">
                                <ButtonIcon
                                  iconImg={edit}
                                  imgWidth={16}
                                  alt="edit"
                                  onClick={() => onEdit(each)}
                                  ariaLabel={polyglot.t("edit")}
                                  title={polyglot.t("Edit")}
                                />
                                {hasDeletePermission(currentUserPermissions) && (
                                  <ButtonIcon
                                    iconName={deleteFill}
                                    alt="delete"
                                    onClick={() => cancelClientModal(each, index)}
                                    ariaLabel={polyglot.t("delete")}
                                    title={polyglot.t("Delete")}
                                  />
                                )}
                                <ButtonIcon
                                  alt="Accordion"
                                  imgWidth={16}
                                  iconImg={each.isview ? Accordion : chevron}
                                  onClick={() => viewClient(each, index)}
                                  ariaLabel={each.isview ? polyglot.t("Expand") : polyglot.t("Collapse")}
                                  title={each.isview ? polyglot.t("Expand") : polyglot.t("Collapse")}
                                />
                              </div>
                            </div>
                          </div>
                          {each.isview ? (
                            <ViewClientDetails
                              clientRow={each}
                              listOfUsers={listOfUsersOfClient}
                              acivateUser={acivateUser}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <>
                  <div className="row m-0 ">
                    <div className="row w-100 p-0 mr-0 ml-0">
                      <div className="col-md-8 Admin-data">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="Admin-tittle " style={{ marginLeft: 10 }}>
                              <p className="text-align-center w-100">{polyglot.t("No records found")}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )
            ) : (
              <div className="container-fluid">
                <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
              </div>
            )}
          </div>
        </div>

        <div className="AdminPadding-invoice-pagnation-client">
          {pagelist && filterClients(pagelist).length > 5 && (
            <Pagination
              current={currentPage}
              size="small"
              pageSize={PER_PAGE}
              total={filterClients(pagelist).length}
              onChange={handlePageChange}
              showSizeChanger={false}
            />
          )}
        </div>
      </div>
    </div>
  );
}
ClientList.propTypes = {
  props: PropTypes.object,
};
export default ClientList;
