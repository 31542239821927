import PropTypes from "prop-types";
import React from "react";

import LinkText from "../../../../Assets/Components/LinkText/LinkText";
import CustomModal from "../../../../Assets/Components/Modal";
import RequestFormService from "../../../../Services/GI/RequestFormServices";
import {
  getDisplayDate,
  capitaliseFirstLetter,
  getProjectID_Of_solution_module,
} from "../../../../Services/Shared/Handlers";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";

// WORKFLOW DETAILS SHOWN ON A POPUP
const RequestDetails = (props) => {
  var polyglot = polyglotLoader();
  const { show, item, toggleInfo } = props;
  const projectId = getProjectID_Of_solution_module();
  // DOWNLOAD  FILE ON CLICK
  const downloadFilePath = async (filePath, fileName) => {
    try {
      // API TO DOWNLOAD  FILE
      const downloadResponse = await RequestFormService.get().downloadRequestFile(fileName, filePath);
      if (downloadResponse.CODE) {
        const buffer = Buffer.from(downloadResponse.file.buffer);
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", downloadResponse.file.fileName);
        document.body.appendChild(link);
        link.click();
        displayToastMessage(downloadResponse.MSG, "success");
        document.body.removeChild(link);
      } else {
        if (downloadResponse.MSG.errno === -4058) {
          displayToastMessage(`${polyglot.t("File not found")}!!`, "error");
        } else {
          displayToastMessage(downloadResponse.MSG, "error");
        }
      }
    } catch (error) {
      if (
        (error.message = "First argument must be a string, Buffer, ArrayBuffer, Array, or array-like object.")
      ) {
      } else {
        displayToastMessage(error.message, "error");
      }
    }
  };

  return (
    <CustomModal
      show={show}
      onCancel={toggleInfo}
      title={`${polyglot.t("Requested ID")}: ${item.ID}`}
      className="requestDetails"
      Footer={[]}
      showCloseButton={true}
    >
      <div className=" row">
        <div className="f-acc-B col-md-6">{polyglot.t("Target name/Transactions ID")}:</div>
        <div className="f-acc-B col-md-6 count-align-t">{capitaliseFirstLetter(item.TargetName)}</div>
      </div>
      <div className="row">
        <div className="f-acc-B col-md-6">{polyglot.t("Requested on")}:</div>
        <div className="f-acc-B col-md-6 count-align-t">{getDisplayDate(item.CreatedOn)}</div>
      </div>
      <div className="row">
        <div className="f-acc-B col-md-6">{polyglot.t("Status")}:</div>
        <div className="f-acc-B col-md-6 count-align-t">{item.Status}</div>
      </div>
      <div className="row">
        <div className="f-acc-B col-md-6">{polyglot.t("Other information/comments")}:</div>
        <div className="f-acc-B col-md-6 count-align-t">{item.Misc}</div>
      </div>
      {item.TypeID == "1" && (
        <div className="row">
          <div className="f-acc-B col-md-6">{polyglot.t("Date of birth")}:</div>
          <div className="f-acc-B col-md-6 count-align-t">{getDisplayDate(item.DOB)}</div>
        </div>
      )}
      {item.TypeID == "2" && (
        <>
          <div className="row">
            <div className="f-acc-B col-md-6">{polyglot.t("Date of incorporation")}:</div>
            <div className="f-acc-B col-md-6 count-align-t">{getDisplayDate(item.DOB)}</div>
          </div>

          <div className="row">
            <div className="f-acc-B col-md-6">{polyglot.t("Company registration number")}:</div>
            <div className="f-acc-B col-md-6 count-align-t">{item.IdentificationNumber}</div>
          </div>
        </>
      )}
      {item.TypeID == "3" && (
        <>
          <div className="row">
            <div className="f-acc-B col-md-6">{polyglot.t("Wallet ID")}:</div>
            <div className="f-acc-B col-md-6 count-align-t">{item.IdentificationNumber}</div>
          </div>
        </>
      )}
      <div className="row">
        <div className="f-acc-B col-md-6">{polyglot.t("Priority")}:</div>
        <div className="f-acc-B col-md-6 count-align-t">{item.Priority}</div>
      </div>
      <div className="row">
        <div className="f-acc-B col-md-6">{polyglot.t("Category")}:</div>
        <div className="f-acc-B col-md-6 count-align-t">{item.Category}</div>
      </div>
      {item.TypeID != "3" && (
        <>
          <div className="row">
            <div className="f-acc-B col-md-6">{polyglot.t("Address of the target")}:</div>
            <div className="f-acc-B col-md-6 count-align-t">{item.address}</div>
          </div>
        </>
      )}
      {item.Status == "Information requested" && (
        <>
          <div className="row">
            <div className="f-acc-B col-md-6">{polyglot.t("Admin comment")}:</div>
            <div className="f-acc-B col-md-6 count-align-t">{item.AdminComments}</div>
          </div>
          <div className="row">
            <div className="f-acc-B col-md-6">{polyglot.t("Client comment")}:</div>
            <div className="f-acc-B col-md-6 count-align-t">
              <b>{item.ClientComments} </b>
            </div>
          </div>
        </>
      )}
      <div className="row">
        <div className="f-acc-B col-md-6">{polyglot.t("User document")}:</div>

        {item.files && item.files !== "" ? (
          <div className="f-acc-B col-md-6 count-align-t">
            <ul>
              {JSON.parse(item.files).map((downloadFile, index) => (
                <LinkText
                  onClick={() => downloadFilePath(downloadFile.Filepath, downloadFile.Filename)}
                  ariaLabel={downloadFile.Filename}
                >
                  <li>{downloadFile.Filename}</li>
                </LinkText>
              ))}
            </ul>
          </div>
        ) : (
          <div className="f-acc-B col-md-6 count-align-t cursor-notallowed">
            <div>{polyglot.t("File not available")}</div>
          </div>
        )}
      </div>
      {item.Status == "Report available" && (
        <div className="row">
          <div className="f-acc-B col-md-6">{polyglot.t("Report name")}:</div>

          {item.FinalReportName && item.FinalReportName !== "" ? (
            <div className="f-acc-B col-md-6 count-align-t">
              <LinkText
                onClick={() => downloadFilePath(item.FinalReportPath, item.FinalReportName)}
                ariaLabel={item.FinalReportName}
              >
                {item.FinalReportName}
              </LinkText>
            </div>
          ) : (
            <div className="f-acc-B col-md-6 count-align-t cursor-notallowed">
              <div>{polyglot.t("File not available")}</div>
            </div>
          )}
        </div>
      )}
      {item.FinalReportName && item.FinalReportName !== "" && (
        <div className="row">
          <div className="f-acc-B col-md-6">{polyglot.t("Report uploaded on")}:</div>
          <div className="f-acc-B col-md-6 count-align-t">{getDisplayDate(item.ReportUploadedOn)}</div>
        </div>
      )}
    </CustomModal>
  );
};

RequestDetails.propTypes = {
  show: PropTypes.bool,
  toggleInfo: PropTypes.func,
  item: PropTypes.object,
};

export default RequestDetails;
