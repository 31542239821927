// library imports//
import React from "react";
import {
  ButtonGroup,
  ButtonGroupItem,
  Accordions,
  AccordionItem,
  AccordionItemHeader,
  AccordionItemBody,
} from "appkit-react";

// file imports//
import CustodianService from "../../../Services/DFED/CustodianService";

import "../../../App.css";
import "../../../Assets/Css/COMMON/Custodians.css";
import "../../../Assets/Css/GI/RequestComponent.css";
import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import { plusCircle, backCircle, closeIcon } from "../../../Assets/Icons";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import TermsAndConditions from "./TermsAndConditions";
import DisplayPackage from "./DisplayPackage";
import * as module_Solution_Name from "../../../Constants/moduleName";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import ButtonSecondaryWithIcon from "../../../Assets/Components/ButtonSecondary/ButtonSecondaryWithIcon";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import ButtonGroupCustom from "../../../Assets/Components/ButtonGroupCustom/ButtonGroupCustom";
import RequestList from "./Request/RequestList";
// import ProjectList from './Request/RequestList_user'
import IndividualComponent from "./IndividualRequestForm/IndividualRequestFormComponent";
import EntityComponent from "./EntityRequestForm/EntityRequestFormComponent";
import VirtualAssetsComponent from "./VirtualAssetsRequestForm/VirtualAssetsRequestFormComponent";
import BulkUploadComponent from "./BulkUpload/BulkUploadComponent";
import ConfirmBox from "../../QueenBase/Projects/ConfirmBox";

//TAB FORM DETAILS
const initialFormTabs = [
  { value: "Individual", name: "Individual", disabled: false, crtTypeId: 1 },
  { value: "Entity Form", name: "Entity Form", disabled: false, crtTypeId: 2 },
  {
    value: "Virtual Assets",
    name: "Virtual assests",
    disabled: false,
    crtTypeId: 3,
  },
  { value: "Bulk Upload", name: "Bulk Upload", disabled: false, crtTypeId: 4 },
];

//THIS CLASS IS RESPONSIBLE FOR REQUEST RELATED OPERATION
class RequestComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showTermsAndCondition: false,
      showRequest: true,
      formTabs: initialFormTabs,
      showCustodian: false,
      CRT_Type_ID_of_form: 0,
      defaultHighlightClass: "custodianGroupButton ",
      openConfirmBox: false,
      showIndivialForm: false,
      showEntityForm: false,
      showVirtualForm: false,
      showBulkUploadForm: false,
      disableRequestTab1: "",
      disableRequestTab2: "",
      disableRequestTab3: "",
      disableRequestTab4: "",
      requestDataById: null,
      lengthOfTargetList: 0,
      selectedTab: "Individual",
    };
    this.onClick = this.onClick.bind(this);
    this.closeRequest = this.closeRequest.bind(this);
    this.showCustodianForm = this.showCustodianForm.bind(this);
    this.showRequestList = this.showRequestList.bind(this);
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
    this.closeRequestForm = this.closeRequestForm.bind(this);
    this.editRequest = this.editRequest.bind(this);
  }
  //reset request form after clicking on add request
  resetRequestForm = () => {
    this.setState({
      CRT_Type_ID_of_form: 0,
      formTabs: initialFormTabs,
      selectedTab: "Individual",
    });
  };

  // ONCLICK FUNCTION FOR ADD REQUEST
  onClick() {
    this.setState({
      showTermsAndCondition: true,
      showRequest: false,
      defaultHighlightClass: "custodianGroupButton defaultHighlightClass",
      requestDataById: null,
    });
    this.resetRequestForm();
  }

  // CLOSE REQUEST FORM FUNCTION, AFTER CLICKING ON THIS RENDER LIST OF REQUEST PAGE=============
  closeRequest() {
    this.setState({
      showTermsAndCondition: false,
      showRequest: true,
    });
  }

  editRequest(rowBody) {
    //UPDATE STATE AND SHOW FORM ON EDIT
    const crtTypeId = rowBody.TypeID;
    this.setState({
      showIndivialForm: true,
      custodianFormClass: "custodian-transition-delay",
      custodianTypeID: 1,
      showEntityForm: false,
      showVirtualForm: false,
      showBulkUploadForm: false,
      defaultHighlightClass: "custodianGroupButton t-l-b-r-4 b-l-b-r-4",
      selectedAccordianTab: [0],
      showRequest: false,
      showCustodian: true,
      requestDataById: rowBody,
      disableRequestTab1: parseInt(crtTypeId) === 1 ? "enablePerosnTab" : "disableTab",
      disableRequestTab2: parseInt(crtTypeId) === 2 ? "enablePerosnTab" : "disableTab",
      disableRequestTab3: parseInt(crtTypeId) === 3 ? "enablePerosnTab" : "disableTab",
      disableRequestTab4: parseInt(crtTypeId) === 4 ? "enablePerosnTab" : "disableTab",
    });
    // assign crt type id to state
    this.setState({
      CRT_Type_ID_of_form: parseInt(crtTypeId),
    });
    this.disableOtherTabs(parseInt(crtTypeId));
  }
  // SHOWING REQUEST FORM BASED ON DIFFERENT CONDITION
  showCustodianForm(value, id) {
    let selectedOption = value;
    if (selectedOption === "Individual") {
      this.setState({
        showIndivialForm: true,
        custodianFormClass: "custodian-transition-delay",
        custodianTypeID: 1,
        showEntityForm: false,
        showVirtualForm: false,
        showBulkUploadForm: false,
        defaultHighlightClass: "custodianGroupButton t-l-b-r-4 b-l-b-r-4",
        selectedAccordianTab: [0],
        requestDataById: null,
        selectedTab: value,
      });
    }
    if (selectedOption === "Entity Form") {
      this.setState({
        showIndivialForm: false,
        custodianFormClass: "custodian-transition-delay",
        custodianTypeID: 2,
        showEntityForm: true,
        showVirtualForm: false,
        showBulkUploadForm: false,
        defaultHighlightClass: "custodianGroupButton t-l-b-r-4 b-l-b-r-4",
        selectedAccordianTab: [1],
        requestDataById: null,
        selectedTab: value,
      });
    }
    if (selectedOption === "Virtual Assets") {
      this.setState({
        showIndivialForm: false,
        custodianFormClass: "custodian-transition-delay",
        custodianTypeID: 3,
        showEntityForm: false,
        showVirtualForm: true,
        showBulkUploadForm: false,
        defaultHighlightClass: "custodianGroupButton t-l-b-r-4 b-l-b-r-4",
        selectedAccordianTab: [2],
        requestDataById: null,
        selectedTab: value,
      });
    }
    if (selectedOption === "Bulk Upload") {
      this.setState({
        showIndivialForm: false,
        custodianFormClass: "custodian-transition-delay",
        custodianTypeID: 4,
        showEntityForm: false,
        showVirtualForm: false,
        showBulkUploadForm: true,
        defaultHighlightClass: "custodianGroupButton t-l-b-r-4 b-l-b-r-4",
        selectedAccordianTab: [3],
        requestDataById: null,
        selectedTab: value,
      });
    }
  }

  // enable or disable tabs
  disableOtherTabs = (crtTypeId) => {
    const formTabs = [];
    let formTab = {};
    this.state.formTabs.forEach((item) => {
      formTab = { ...item };
      if (item.crtTypeId !== crtTypeId) {
        formTab.disabled = true;
      }
      formTabs.push(formTab);
    });
    this.setState({
      formTabs,
    });
  };

  //  HANDLING TERMS AND CONDITION BEFORE CREATING REQUEST FORM FUNCTIONALITY===================
  checkBoxHandler = (e) => {
    this.setState({
      checkBox: !this.state.checkBox,
      showCustodian: true,
      showIndivialForm: true,
      showEntityForm: false,
      showVirtualForm: false,
      showBulkUploadForm: false,
      defaultHighlightClass: "custodianGroupButton t-l-b-r-4 b-l-b-r-4 defaultHighlightClass",
      showTermsAndCondition: false,
      disableRequestTab1: "",
      disableRequestTab2: "",
      disableRequestTab3: "",
      disableRequestTab4: "",
    });
  };

  // BACK BUTTON CLICK FUNCTIONALITY ON REQUEST FORM, IT WILL RENDER AGAIN LIST PAGE.===============

  onBackButtonClick() {
    if (this.state.showCustodian) {
      this.setState({
        showCustodian: false,
        showRequest: true,
        showTermsAndCondition: false,
        showIndivialForm: false,
        showEntityForm: false,
        showVirtualForm: false,
        showBulkUploadForm: false,
        requestDataById: null,
      });
    }
  }

  // CLOSE REQUEST FORM FUNCTION, AFTER CLICKING ON THIS RENDER LIST OF REQUEST PAGE=============
  closeRequestForm() {
    if (this.state.showCustodian) {
      this.setState({
        showCustodian: false,
        showRequest: true,
        showTermsAndCondition: false,
        showIndivialForm: false,
        showEntityForm: false,
        showVirtualForm: false,
        showBulkUploadForm: false,
      });
    } else {
      this.setState({
        showCustodian: false,
        showRequest: true,
        showTermsAndCondition: false,
        showIndivialForm: false,
        showEntityForm: false,
        showVirtualForm: false,
        showBulkUploadForm: false,
      });
    }
  }

  //  logic for show custodianList screen
  showRequestList() {
    this.setState({
      showCustodian: false,
      showRequest: true,
      showPersonalCustodianForm: false,
      showGroupCustodianForm: false,
      showImageDiv: false,
      showMultipleCustodianForm: false,
      showGlobalCustodianForm: false,
      checkBox: !this.state.checkBox,
      showTermsAndCondition: false,
    });
  }

  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  toggleConfirmBox = () => {
    this.setState({
      openConfirmBox: true,
    });
  };
  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  ConfirmBox = () => {
    this.setState({
      openConfirmBox: false,
    });
  };
  // CLOSE FORM AND NAVIGATE TO VIEW PROJECTS
  closebutton = () => {
    this.setState({
      openConfirmBox: false,
      showRequest: true,
    });
  };
  //RENDER FUNCTIONALITY FOR REQUEST PAGE IT WILL RENDER ALL TYPE OF FORM
  render() {
    var polyglot = polyglotLoader();

    //TERMS AND CONDITION MODULE FUNCTIONALITY
    var requestTermAndConditionComponent = (
      <>
        <div className="p-0 m-l-1-15">
          <>
            <div className="mt-3 full-width">
              <TermsAndConditions checkBoxHandler={this.checkBoxHandler} closeRequest={this.closeRequest} />
            </div>
          </>
        </div>
      </>
    );

    //REQUEST PAGE FUNCTIONALITY
    var requestHome = (
      <div>
        <>
          <div className="p-0 m-l-1-15">
            <div className="full-width">
              <div className="col-md-4 DF-mt-3 p-0">
                <ButtonPrimaryWithIcon
                  iconName={plusCircle}
                  onClick={this.onClick}
                  ariaLabel={polyglot.t("Add a request")}
                >
                  {polyglot.t("Add a request")}
                </ButtonPrimaryWithIcon>
              </div>
            </div>

            {/*Request List Component */}
            <RequestList
              editRequest={this.editRequest.bind(this)}
              receiveTargetListCount={(length) => this.setState({ lengthOfTargetList: length })}
            />
            {/* <ProjectList/> */}
            {/*Package List Component */}
            <DisplayPackage lengthOfTargetList={this.state.lengthOfTargetList} />
          </div>
        </>
      </div>
    );

    //RENDER PROCESS OF FORM
    var requestForm = (
      <div>
        <>
          <div className="p-0 m-l-1-15 ">
            <div className="full-width">
              <div className="col-md-4 DF-mt-3 p-0">
                <ButtonPrimaryWithIcon
                  onClick={this.onBackButtonClick}
                  iconName={backCircle}
                  ariaLabel={polyglot.t("Back")}
                >
                  {polyglot.t("Back")}
                </ButtonPrimaryWithIcon>
              </div>
            </div>
          </div>
        </>
        <>
          <div className="p-0 m-l-1-15 DF-mt-3">
            <div className="full-width">
              <div className="row fade-in-top m-l-0 m-r-0">
                <div className="col-lg-7 col-md-7 dropDownBoxDiv divCustodianSelection DF-Cust-sel">
                  <div className="row crossIcon cust-cross">
                    <div className="">
                      <ButtonIcon
                        alt="close"
                        iconName={closeIcon}
                        onClick={this.toggleConfirmBox}
                        ariaLabel={polyglot.t("Close")}
                      />
                    </div>
                  </div>
                  <ConfirmBox
                    show={this.state.openConfirmBox}
                    heading={"Cancel update"}
                    cancel={this.ConfirmBox}
                    proceed={this.closebutton}
                  />
                  <div className="row m-l-0 m-r-0">
                    <h1
                      className="custodian-header-text p-l-0 mb-2"
                      aria-label={polyglot.t("Choose request options")}
                    >
                      {polyglot.t("Choose request options")}
                    </h1>
                  </div>
                  <div className="row m-t-14">
                    <div className="tabDiv choose-cust-tab">
                      <ButtonGroupCustom
                        value={this.state.selectedTab}
                        onChange={(e, v) => {
                          this.showCustodianForm(v);
                        }}
                        tabs={this.state.formTabs}
                      />
                    </div>
                    {this.state.showIndivialForm && (
                      <div className="m-t-10 tabDiv choose-cust-tab">
                        <IndividualComponent
                          custodianTypeID={1}
                          showRequestList={this.showRequestList.bind(this)}
                          goBack={this.onBackButtonClick}
                          editableFormData={this.state.requestDataById}
                        />
                      </div>
                    )}
                    {this.state.showEntityForm && (
                      <div className="m-t-10 tabDiv choose-cust-tab">
                        <EntityComponent
                          custodianTypeID={2}
                          showRequestList={this.showRequestList.bind(this)}
                          editableFormData={this.state.requestDataById}
                        />
                      </div>
                    )}
                    {this.state.showVirtualForm && (
                      <div className="m-t-10 tabDiv choose-cust-tab">
                        <VirtualAssetsComponent
                          custodianTypeID={3}
                          showRequestList={this.showRequestList.bind(this)}
                          editableFormData={this.state.requestDataById}
                        />
                      </div>
                    )}
                    {this.state.showBulkUploadForm && (
                      <div className="m-t-10 tabDiv choose-cust-tab">
                        <BulkUploadComponent
                          custodianTypeID={4}
                          showRequestList={this.showRequestList.bind(this)}
                          editableFormData={this.state.requestDataById}
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </div>
    );

    //CONDITIONAL RENDREING PROCESS
    var returningObj;
    if (this.state.showTermsAndCondition) {
      returningObj = requestTermAndConditionComponent;
    } else if (this.state.showRequest) {
      returningObj = requestHome;
    } else if (this.state.showCustodian) {
      returningObj = requestForm;
    }
    return returningObj;
  }
}

export default RequestComponent;
