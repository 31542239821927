import React from "react";
import DragDropContainer from "./DragDropContainer";
import WidgetService from "../../../../Services/QueenBase/Widget/WidgetService";
import httpRequest from "../../../../Services/Shared/HttpClient";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
/*
    Boxable -- a thing you can drag into a Box
*/

// BOXABLE COMPONENT
export default class Boxable extends React.Component {
  constructor(props) {
    super(props);
    this.HttpRequest = new httpRequest();
  }

  // RENDERS HTML ON render FUNCTION
  displayTitle = () => {
    var polyglot = polyglotLoader();
    return polyglot.t(this.props.title);
  };

  render() {
    var polyglot = polyglotLoader();
    return (
      <div className="boxable_component" style={{ display: "inline-block" }}>
        <DragDropContainer
          targetKey={this.props.targetKey}
          dragData={({ label: this.props.label }, { type: this.props.type })}
          customDragElement={this.props.customDragElement}
          onDragStart={() => console.log("start")}
          onDrag={() => console.log("dragging")}
          onDragEnd={() => console.log("drag end")}
          onDrop={(e) => console.log(e)}
          divId={this.props.divId}
          wid={this.props.wid}
          label={this.props.label}
          jsonData={this.props.jsonData}
        >
          <div
            className="widgetname a-panel"
            bgcolor={this.props.bgcolor}
            title={this.props.title}
            style={{ marginLeft: 40 }}
          >
            {this.displayTitle()}
          </div>
        </DragDropContainer>
      </div>
    );
  }
}
