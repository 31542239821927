import React, { Component } from "react";
import { Button, Input } from "appkit-react";
import displayToastMessage from "./displayToastMessage";
import "../../../Assets/Css/QueenBase/Root/Login.css";
import * as path from "../../../Constants/Path";
import LoginService from "../../../Services/QueenBase/Root/LoginService";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import LinkText from "../../../Assets/Components/LinkText/LinkText";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";

var polyglot = polyglotLoader();

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientUserId: "",
      isErrorMessageShown: false,
      errorMessage: "",
    };
  }
  onBackLogin = async (e) => {
    this.props.history.push(path.LOGIN_PATH);
  };
  handleSubmit = async (e) => {
    if (this.state.clientUserId === "") {
      this.setState({
        errorMessage: polyglot.t("User Id is required"),
        isErrorMessageShown: true,
      });
      return false;
    } else {
      this.setState({
        errorMessage: "",
        isErrorMessageShown: false,
      });
    }
    let data = {
      clientUserId: this.state.clientUserId,
    };
    try {
      var res = await LoginService.get().forgotPassword(data);
      if (res === "Password reset request created") {
        displayToastMessage(polyglot.t("New password has been sent to the registered email"), "success");
      } else {
        displayToastMessage(polyglot.t("Invalid User ID Please try again!"), "error");
      }
    } catch (err) {
      return err;
    }
  };
  clientUserIdOnChng = (userId) => {
    this.setState({
      errorMessage: "",
      clientUserId: userId,
    });
  };
  render() {
    document.body.classList.remove("bg-img");
    return (
      <div>
        <div className="login-bg-image">
          <div className="container">
            <div className="login-form">
              <div className="d-flex">
                <div className="card bg-light margin-0-auto min-width-25">
                  <div className="card-header header_login">
                    <h1 className="headerText_Login">{polyglot.t("Welcome to Queen")}</h1>
                  </div>
                  <div className="card-body user-login">
                    <span className="font-weight-medium text-nowrap user-label">{polyglot.t("Account")}</span>
                    <div>
                      <Input
                        inputBoxSize="sm"
                        placeholder={polyglot.t("UserId")}
                        className="Box"
                        type="number"
                        name="clientUserId"
                        value={this.state.clientUserId}
                        onChange={this.clientUserIdOnChng.bind(this)}
                        aria-label={polyglot.t("UserId")}
                      />
                      <span
                        className="error"
                        style={{
                          display: this.state.isErrorMessageShown ? "block" : "none",
                        }}
                      >
                        {this.state.errorMessage}
                      </span>
                      <br />
                      <div className="row">
                        <div className="col-md-7">
                          <ButtonPrimary
                            type="submit"
                            onClick={this.handleSubmit}
                            ariaLabel={polyglot.t("Verify")}
                          >
                            {polyglot.t("Verify")}
                          </ButtonPrimary>
                        </div>
                        <div className="col-md-5">
                          <div className="Forgotpass-backToLogin">
                            <LinkText onClick={this.onBackLogin} ariaLabel={polyglot.t("Back to login")}>
                              {polyglot.t("Back to login")}
                            </LinkText>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default ForgotPassword;
