import React, { Fragment } from "react";

import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import { backCircle } from "../../../Assets/Icons";

class TermsAndUseComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      userLanguage: "en",
      isBackEnable: true,
    };

    this.callResize = this.callResize.bind(this);
    this.goBack = this.goBack.bind(this);
  }
  componentDidMount() {
    if (sessionStorage.length > 0) {
      this.setState({
        isBackEnable: true,
      });
    } else {
      this.setState({
        isBackEnable: false,
      });
    }
    window.scrollTo(0, 0);
    this.getUserLanguage();
    window.addEventListener("resize", this.callResize);
  }
  callResize() {
    this.setState({
      height: window.innerHeight,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.callResize);
  }
  goBack() {
    this.props.history.goBack();
  }

  componentDidUpdate() {
    this.getUserLanguage();
  }

  async getUserLanguage() {
    let userLanguage = await sessionStorage.getItem("USER_LANGUAGE");
    if (this.state.userLanguage !== userLanguage)
      this.setState({
        userLanguage: userLanguage,
      });
  }

  render() {
    const footerTermsAndContainerHeight = {
      minHeight: this.state.height + "px",
    };
    return (
      <Fragment>
        {this.state.userLanguage !== "ger" && (
          <div className="row m-0">
            <div className="col-md-1"></div>

            <div style={footerTermsAndContainerHeight} className=" col-md-10 m-t-20 p-l-0">
              <div className="row m-0">
                <div className=" col-md-8 header-credit-page p-l-1-p m-b-0 ">{"Terms of uses "}</div>
                <div className="col-md-4 p-0 float-right m-t-7">
                  {this.state.isBackEnable && (
                    <ButtonPrimaryWithIcon
                      onClick={this.goBack.bind(this)}
                      iconName={backCircle}
                      ariaLabel={"Back"}
                    >
                      {"Back"}
                    </ButtonPrimaryWithIcon>
                  )}
                </div>
              </div>
              <div>
                <div
                  style={{
                    boxSizing: "border-box",
                    padding: "0px",
                    margin: "0px",
                    border: "0px",
                    verticalAlign: "baseline",
                    color: "rgb(45, 45, 45)",
                    fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontVariantLigatures: "normal",
                    fontVariantCaps: "normal",
                    fontWeight: 400,
                    letterSpacing: "normal",
                    orphans: 2,
                    textAlign: "start",
                    textIndent: "0px",
                    textTransform: "none",
                    whiteSpace: "normal",
                    widows: 2,
                    wordSpacing: "0px",
                    WebkitTextStrokeWidth: "0px",
                    textDecorationStyle: "initial",
                    textDecorationColor: "initial",
                  }}
                >
                  <section
                    className=" page-section page-section--col-white     videobghost "
                    data-parallax="true"
                    data-wcmmode="false"
                    style={{
                      boxSizing: "border-box",
                      padding: "0px",
                      margin: "0px auto",
                      display: "block",
                      border: "0px",
                      verticalAlign: "baseline",
                      height: "auto",
                      position: "relative",
                      tableLayout: "fixed",
                      backgroundImage: "initial",
                      backgroundPosition: "center top",
                      backgroundSize: "inherit",
                      backgroundRepeat: "initial",
                      backgroundAttachment: "initial",
                      backgroundOrigin: "initial",
                      backgroundClip: "initial",
                    }}
                  >
                    <div
                      className="parsys sectionpar"
                      style={{
                        boxSizing: "border-box",
                        margin: "0px auto",
                        border: "0px",
                        verticalAlign: "baseline",
                      }}
                    >
                      <div
                        className="parsys_column pwccol1-longform "
                        style={{
                          boxSizing: "border-box",
                          padding: "0px",
                          margin: "0px auto 40px",
                          border: "0px",
                          verticalAlign: "baseline",
                          display: "table",
                          position: "relative",
                          tableLayout: "fixed",
                        }}
                      >
                        <div
                          className="parsys_column pwccol1-longform-c0"
                          style={{
                            boxSizing: "border-box",
                            margin: "0px auto",
                            border: "0px",
                            verticalAlign: "top",
                            display: "block",
                          }}
                        >
                          <div
                            className="text parbase section"
                            style={{
                              boxSizing: "border-box",
                              padding: "0px",
                              margin: "7px 0px 0px",
                              border: "0px",
                              verticalAlign: "baseline",
                            }}
                          >
                            <div
                              className="text-component "
                              data-emptytext="Edit Text"
                              style={{
                                boxSizing: "border-box",
                                margin: "0px",
                                border: "0px",
                                verticalAlign: "baseline",
                              }}
                            >
                              <p
                                className="t-a-j f-t-s-14 p-l-1-p"
                                style={{
                                  boxSizing: "border-box",
                                  padding: "0px",
                                  margin: "0px 0px 35px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  fontSize: "1.125rem",
                                  lineHeight: "1.7",
                                  fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                  color: "rgb(45, 45, 45)",
                                  fontStyle: "normal",
                                  fontVariantLigatures: "normal",
                                  fontVariantCaps: "normal",
                                  fontWeight: 400,
                                  letterSpacing: "normal",
                                  orphans: 2,
                                  textAlign: "start",
                                  textIndent: "0px",
                                  textTransform: "none",
                                  whiteSpace: "normal",
                                  widows: 2,
                                  wordSpacing: "0px",
                                  WebkitTextStrokeWidth: "0px",
                                  textDecorationStyle: "initial",
                                  textDecorationColor: "initial",
                                }}
                              >
                                (1) This website is subject to the following terms of use, which are binding
                                in the relationship between the user and PricewaterhouseCoopers GmbH
                                Wirtschaftsprüfungsgesellschaft, Friedrich-Ebert-Anlage 35-37, 60327 Frankfurt
                                am Main, hereinafter "PwC", by accessing this website. Insofar as special
                                conditions for individual use of this website deviate from the following terms
                                of use, this is expressly pointed out at the appropriate point on the website.
                                The special terms of use shall then apply in each individual case.
                              </p>
                              <p
                                className="t-a-j f-t-s-14 p-l-1-p"
                                style={{
                                  boxSizing: "border-box",
                                  padding: "0px",
                                  margin: "-8px 0px 35px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  fontSize: "1.125rem",
                                  lineHeight: "1.7",
                                  fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                  color: "rgb(45, 45, 45)",
                                  fontStyle: "normal",
                                  fontVariantLigatures: "normal",
                                  fontVariantCaps: "normal",
                                  fontWeight: 400,
                                  letterSpacing: "normal",
                                  orphans: 2,
                                  textAlign: "start",
                                  textIndent: "0px",
                                  textTransform: "none",
                                  whiteSpace: "normal",
                                  widows: 2,
                                  wordSpacing: "0px",
                                  WebkitTextStrokeWidth: "0px",
                                  textDecorationStyle: "initial",
                                  textDecorationColor: "initial",
                                }}
                              >
                                (2) This website contains data and information of all kinds which are
                                protected by trademark and/or copyright in favour of PwC or in individual
                                cases also in favour of third parties. It is therefore not permitted to
                                download, duplicate and/or distribute the website as a whole or individual
                                parts such as texts, images, graphics, layout, photos thereof. All rights,
                                including those of reproduction, distribution and translation, are reserved to
                                PwC. No part of this website may be reproduced in any form or stored,
                                processed, duplicated or distributed using electronic systems without the
                                express written permission of PwC.
                              </p>
                              <p
                                className="t-a-j f-t-s-14 p-l-1-p"
                                style={{
                                  boxSizing: "border-box",
                                  padding: "0px",
                                  margin: "-8px 0px 35px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  fontSize: "1.125rem",
                                  lineHeight: "1.7",
                                  fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                  color: "rgb(45, 45, 45)",
                                  fontStyle: "normal",
                                  fontVariantLigatures: "normal",
                                  fontVariantCaps: "normal",
                                  fontWeight: 400,
                                  letterSpacing: "normal",
                                  orphans: 2,
                                  textAlign: "start",
                                  textIndent: "0px",
                                  textTransform: "none",
                                  whiteSpace: "normal",
                                  widows: 2,
                                  wordSpacing: "0px",
                                  WebkitTextStrokeWidth: "0px",
                                  textDecorationStyle: "initial",
                                  textDecorationColor: "initial",
                                }}
                              >
                                Only the technically conditioned duplication for the purpose of browsing is
                                permitted, as far as this action serves neither commercial purposes, nor the
                                permanent duplication for &nbsp;own use. Furthermore, it is permitted to set a
                                link to this website, provided that it serves solely as a cross-reference. PwC
                                reserves the right to revoke this permission. Framing this website is
                                expressly prohibited.
                              </p>
                              <p
                                className="t-a-j f-t-s-14 p-l-1-p"
                                style={{
                                  boxSizing: "border-box",
                                  padding: "0px",
                                  margin: "-8px 0px 35px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  fontSize: "1.125rem",
                                  lineHeight: "1.7",
                                  fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                  color: "rgb(45, 45, 45)",
                                  fontStyle: "normal",
                                  fontVariantLigatures: "normal",
                                  fontVariantCaps: "normal",
                                  fontWeight: 400,
                                  letterSpacing: "normal",
                                  orphans: 2,
                                  textAlign: "start",
                                  textIndent: "0px",
                                  textTransform: "none",
                                  whiteSpace: "normal",
                                  widows: 2,
                                  wordSpacing: "0px",
                                  WebkitTextStrokeWidth: "0px",
                                  textDecorationStyle: "initial",
                                  textDecorationColor: "initial",
                                }}
                              >
                                (3) The information on this website is of a general nature and is intended to
                                introduce PwC to interested internet users. PwC assumes liability for the
                                content of its websites in accordance with legal provisions. The information
                                is selected with due care. While we have made every attempt to ensure that the
                                information contained in this site has been obtained and arranged with due
                                care, PwC is not responsible for any inaccuracies, errors or omissions
                                contained in or relating to, this information.
                              </p>
                              <p
                                className="t-a-j f-t-s-14 p-l-1-p"
                                style={{
                                  boxSizing: "border-box",
                                  padding: "0px",
                                  margin: "-8px 0px 35px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  fontSize: "1.125rem",
                                  lineHeight: "1.7",
                                  fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                  color: "rgb(45, 45, 45)",
                                  fontStyle: "normal",
                                  fontVariantLigatures: "normal",
                                  fontVariantCaps: "normal",
                                  fontWeight: 400,
                                  letterSpacing: "normal",
                                  orphans: 2,
                                  textAlign: "start",
                                  textIndent: "0px",
                                  textTransform: "none",
                                  whiteSpace: "normal",
                                  widows: 2,
                                  wordSpacing: "0px",
                                  WebkitTextStrokeWidth: "0px",
                                  textDecorationStyle: "initial",
                                  textDecorationColor: "initial",
                                }}
                              >
                                PwC accepts no responsibility for decisions made by the user on the basis of
                                the above information. PwC points out that the present content does not
                                constitute individual legal, accounting, tax or other professional information
                                or recommendation and is not suitable for replacing individual advice by
                                competent persons taking into account the specific circumstances of the
                                individual case. PwC reserves the right to change or update the information,
                                products or services offered on this website at any time without prior notice.
                              </p>
                              <p
                                className="t-a-j f-t-s-14 p-l-1-p"
                                style={{
                                  boxSizing: "border-box",
                                  padding: "0px",
                                  margin: "-8px 0px 35px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  fontSize: "1.125rem",
                                  lineHeight: "1.7",
                                  fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                  color: "rgb(45, 45, 45)",
                                  fontStyle: "normal",
                                  fontVariantLigatures: "normal",
                                  fontVariantCaps: "normal",
                                  fontWeight: 400,
                                  letterSpacing: "normal",
                                  orphans: 2,
                                  textAlign: "start",
                                  textIndent: "0px",
                                  textTransform: "none",
                                  whiteSpace: "normal",
                                  widows: 2,
                                  wordSpacing: "0px",
                                  WebkitTextStrokeWidth: "0px",
                                  textDecorationStyle: "initial",
                                  textDecorationColor: "initial",
                                }}
                              >
                                (4) References and links to third-party websites do not mean that PwC adopts
                                the content behind the reference or link as its own. PwC has no influence on
                                the content behind the link. PwC is therefore not liable for illegal,
                                incorrect or incomplete content and for damage caused by the use of content
                                behind the link.
                              </p>
                              <p
                                className="t-a-j f-t-s-14 p-l-1-p"
                                style={{
                                  boxSizing: "border-box",
                                  padding: "0px",
                                  margin: "-8px 0px 35px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  fontSize: "1.125rem",
                                  lineHeight: "1.7",
                                  fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                  color: "rgb(45, 45, 45)",
                                  fontStyle: "normal",
                                  fontVariantLigatures: "normal",
                                  fontVariantCaps: "normal",
                                  fontWeight: 400,
                                  letterSpacing: "normal",
                                  orphans: 2,
                                  textAlign: "start",
                                  textIndent: "0px",
                                  textTransform: "none",
                                  whiteSpace: "normal",
                                  widows: 2,
                                  wordSpacing: "0px",
                                  WebkitTextStrokeWidth: "0px",
                                  textDecorationStyle: "initial",
                                  textDecorationColor: "initial",
                                }}
                              >
                                (5) Use of the internet is at the user's own risk. In particular, PwC is not
                                liable for the technical failure of the Internet or access to the internet.
                              </p>
                              <p
                                className="t-a-j f-t-s-14 p-l-1-p"
                                style={{
                                  boxSizing: "border-box",
                                  padding: "0px",
                                  margin: "-8px 0px 35px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  fontSize: "1.125rem",
                                  lineHeight: "1.7",
                                  fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                  color: "rgb(45, 45, 45)",
                                  fontStyle: "normal",
                                  fontVariantLigatures: "normal",
                                  fontVariantCaps: "normal",
                                  fontWeight: 400,
                                  letterSpacing: "normal",
                                  orphans: 2,
                                  textAlign: "start",
                                  textIndent: "0px",
                                  textTransform: "none",
                                  whiteSpace: "normal",
                                  widows: 2,
                                  wordSpacing: "0px",
                                  WebkitTextStrokeWidth: "0px",
                                  textDecorationStyle: "initial",
                                  textDecorationColor: "initial",
                                }}
                              >
                                (6) If the contracting party is a merchant, a legal entity under public law or
                                a special fund under public law, the place of jurisdiction shall be at PwC's
                                registered office in Frankfurt. German law shall apply to the exclusion of the
                                UN Convention on Contracts for the International Sale of Goods and the rules
                                of conflict of the international private law.
                              </p>
                              <p
                                className="t-a-j f-t-s-14 p-l-1-p"
                                style={{
                                  boxSizing: "border-box",
                                  padding: "0px",
                                  margin: "-8px 0px 35px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  fontSize: "1.125rem",
                                  lineHeight: "1.7",
                                  fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                  color: "rgb(45, 45, 45)",
                                  fontStyle: "normal",
                                  fontVariantLigatures: "normal",
                                  fontVariantCaps: "normal",
                                  fontWeight: 400,
                                  letterSpacing: "normal",
                                  orphans: 2,
                                  textAlign: "start",
                                  textIndent: "0px",
                                  textTransform: "none",
                                  whiteSpace: "normal",
                                  widows: 2,
                                  wordSpacing: "0px",
                                  WebkitTextStrokeWidth: "0px",
                                  textDecorationStyle: "initial",
                                  textDecorationColor: "initial",
                                }}
                              >
                                (7) In the event that the user is a consumer (not an entrepreneur), the
                                reference to the possibility of out-of-court settlement of disputes shall be
                                made in accordance with Article 14 of Regulation (EU) No. 524/2013 - ODR
                                Regulation. Details can be found in the aforementioned regulation and at&nbsp;
                                <a
                                  className="footerAnchor"
                                  href="http://ec.europa.eu/consumers/odr"
                                  style={{
                                    boxSizing: "border-box",
                                    padding: "0px",
                                    margin: "0px",
                                    backgroundColor: "transparent",
                                    color: "inherit",
                                    textDecoration: "none",
                                    borderWidth: "0px 0px 1px",
                                    borderTopStyle: "initial",
                                    borderRightStyle: "initial",
                                    borderBottomStyle: "solid",
                                    borderLeftStyle: "initial",
                                    borderTopColor: "initial",
                                    borderRightColor: "initial",
                                    borderBottomColor: "rgb(125, 125, 125)",
                                    borderLeftColor: "initial",
                                    borderImage: "initial",
                                    verticalAlign: "baseline",
                                    outline: "0px",
                                    fontWeight: "bold",
                                    fontSize: "inherit",
                                  }}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  http://ec.europa.eu/consumers/odr
                                </a>
                                .
                              </p>
                              <p
                                className="t-a-j f-t-s-14 p-l-1-p"
                                style={{
                                  boxSizing: "border-box",
                                  padding: "0px",
                                  margin: "-8px 0px 0px",
                                  border: "0px",
                                  verticalAlign: "baseline",
                                  fontSize: "1.125rem",
                                  lineHeight: "1.7",
                                  fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                  color: "rgb(45, 45, 45)",
                                  fontStyle: "normal",
                                  fontVariantLigatures: "normal",
                                  fontVariantCaps: "normal",
                                  fontWeight: 400,
                                  letterSpacing: "normal",
                                  orphans: 2,
                                  textAlign: "start",
                                  textIndent: "0px",
                                  textTransform: "none",
                                  whiteSpace: "normal",
                                  widows: 2,
                                  wordSpacing: "0px",
                                  WebkitTextStrokeWidth: "0px",
                                  textDecorationStyle: "initial",
                                  textDecorationColor: "initial",
                                }}
                              >
                                (8) PwC reserves the right to modify these Terms of Use from time to time and
                                to adapt them to technical and legal developments. The user will be notified
                                of the change - insofar as he has registered. In the event that individual
                                provisions of this user agreement are invalid, this shall not affect the
                                validity of the remaining provisions.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div
                  style={{
                    boxSizing: "border-box",
                    padding: "0px",
                    margin: "0px",
                    border: "0px",
                    verticalAlign: "baseline",
                    color: "rgb(45, 45, 45)",
                    fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontVariantLigatures: "normal",
                    fontVariantCaps: "normal",
                    fontWeight: 400,
                    letterSpacing: "normal",
                    orphans: 2,
                    textAlign: "start",
                    textIndent: "0px",
                    textTransform: "none",
                    whiteSpace: "normal",
                    widows: 2,
                    wordSpacing: "0px",
                    WebkitTextStrokeWidth: "0px",
                    textDecorationStyle: "initial",
                    textDecorationColor: "initial",
                  }}
                >
                  <section
                    className=" page-section page-section--col-light-grey     videobghost "
                    data-parallax="true"
                    data-wcmmode="false"
                    style={{
                      boxSizing: "border-box",
                      padding: "0px",
                      margin: "0px auto",
                      display: "block",
                      border: "0px",
                      verticalAlign: "baseline",
                      height: "auto",
                      position: "relative",
                      tableLayout: "fixed",
                      backgroundImage: "initial",
                      backgroundPosition: "center top",
                      backgroundSize: "inherit",
                      backgroundRepeat: "initial",
                      backgroundAttachment: "initial",
                      backgroundOrigin: "initial",
                      backgroundClip: "initial",
                      backgroundColor: "rgb(242, 242, 242)",
                    }}
                  >
                    <div
                      className="parsys sectionpar"
                      style={{
                        boxSizing: "border-box",
                        padding: "0px 15px",
                        margin: "0px auto",
                        border: "0px",
                        verticalAlign: "baseline",
                      }}
                    >
                      <div
                        className="parsys_column pwccol1-longform "
                        style={{
                          boxSizing: "border-box",
                          padding: "0px",
                          margin: "0px auto 40px -15px",
                          border: "0px",
                          verticalAlign: "baseline",
                          display: "table",
                          position: "relative",
                          tableLayout: "fixed",
                        }}
                      >
                        <div
                          className="parsys_column pwccol1-longform-c0"
                          style={{
                            boxSizing: "border-box",
                            padding: "0px 15px",
                            margin: "0px auto",
                            border: "0px",
                            verticalAlign: "top",
                            display: "block",
                          }}
                        >
                          <div
                            className="text parbase section"
                            style={{
                              boxSizing: "border-box",
                              padding: "0px",
                              margin: "40px 0px 0px",
                              border: "0px",
                              verticalAlign: "baseline",
                            }}
                          >
                            <br />
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>{" "}
            </div>
          </div>
        )}

        {this.state.userLanguage === "ger" && (
          <div className="row m-0">
            <div className="col-md-1"></div>

            <div style={footerTermsAndContainerHeight} className=" col-md-10 m-t-20 p-l-0">
              <div className="row m-0">
                <div className=" col-md-8 header-credit-page p-l-1-p m-b-0 ">{"Nutzungsbedingungen"}</div>
                <div className="col-md-4 p-0 float-right m-t-7">
                  {this.state.isBackEnable && (
                    <ButtonPrimaryWithIcon
                      onClick={this.goBack.bind(this)}
                      iconName={backCircle}
                      ariaLabel={"Zurück"}
                    >
                      {"Zurück"}
                    </ButtonPrimaryWithIcon>
                  )}
                </div>
              </div>

              <div>
                <div
                  style={{
                    boxSizing: "border-box",
                    padding: "0px",
                    margin: "0px",
                    border: "0px",
                    verticalAlign: "baseline",
                    color: "rgb(45, 45, 45)",
                    fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontVariantLigatures: "normal",
                    fontVariantCaps: "normal",
                    fontWeight: 400,
                    letterSpacing: "normal",
                    orphans: 2,
                    textAlign: "start",
                    textIndent: "0px",
                    textTransform: "none",
                    whiteSpace: "normal",
                    widows: 2,
                    wordSpacing: "0px",
                    WebkitTextStrokeWidth: "0px",
                    textDecorationStyle: "initial",
                    textDecorationColor: "initial",
                  }}
                >
                  <section
                    className=" page-section page-section--col-white     videobghost "
                    data-parallax="true"
                    data-wcmmode="false"
                    style={{
                      boxSizing: "border-box",
                      padding: "0px",
                      margin: "0px auto",
                      display: "block",
                      border: "0px",
                      verticalAlign: "baseline",
                      height: "auto",
                      position: "relative",
                      tableLayout: "fixed",
                      backgroundImage: "initial",
                      backgroundPosition: "center top",
                      backgroundSize: "inherit",
                      backgroundRepeat: "initial",
                      backgroundAttachment: "initial",
                      backgroundOrigin: "initial",
                      backgroundClip: "initial",
                    }}
                  >
                    <div
                      className="parsys sectionpar"
                      style={{
                        boxSizing: "border-box",
                        margin: "0px auto",
                        border: "0px",
                        verticalAlign: "baseline",
                      }}
                    >
                      <div
                        className="parsys_column pwccol1-longform "
                        style={{
                          boxSizing: "border-box",
                          padding: "0px",
                          margin: "0px auto 40px",
                          border: "0px",
                          verticalAlign: "baseline",
                          display: "table",
                          position: "relative",
                          tableLayout: "fixed",
                        }}
                      >
                        <div
                          className="parsys_column pwccol1-longform-c0"
                          style={{
                            boxSizing: "border-box",
                            margin: "0px auto",
                            border: "0px",
                            verticalAlign: "top",
                            display: "block",
                          }}
                        >
                          <div
                            className="text parbase section"
                            style={{
                              boxSizing: "border-box",
                              padding: "0px",
                              margin: "7px 0px 0px",
                              border: "0px",
                              verticalAlign: "baseline",
                            }}
                          >
                            <div
                              className="text-component "
                              data-emptytext="Edit Text"
                              style={{
                                boxSizing: "border-box",
                                margin: "0px",
                                border: "0px",
                                verticalAlign: "baseline",
                              }}
                            >
                              <p className="t-a-j f-t-s-14 p-l-1-p">
                                (1) Diese Website ist Gegenstand der nachfolgenden Nutzungsbedingungen, die im
                                Verhältnis zwischen dem Nutzer und der PricewaterhouseCoopers GmbH
                                Wirtschaftsprüfungsgesellschaft, Friedrich-Ebert-Anlage 35-37, 60327 Frankfurt
                                am Main, nachfolgend “PwC”, mit dem Aufruf dieser Website verbindlich
                                vereinbart sind. Soweit spezielle Bedingungen für einzelne Nutzungen dieser
                                Website von den nachfolgenden Nutzungsbedingungen abweichen, wird in der
                                Website an entsprechender Stelle ausdrücklich darauf hingewiesen. Es gelten
                                dann im jeweiligen Einzelfall ergänzend die besonderen Nutzungsbedingungen.
                              </p>
                              <p className="t-a-j f-t-s-14 p-l-1-p">
                                <br />
                              </p>
                              <p className="t-a-j f-t-s-14 p-l-1-p">
                                (2) Diese Website beinhaltet Daten und Informationen aller Art, die marken-
                                und/oder urheberrechtlich zugunsten der PwC oder im Einzelfall auch zugunsten
                                Dritter geschützt sind. Es ist daher nicht gestattet, die Website im Ganzen
                                oder einzelne Teile wie z.B. Texte, Bilder, Grafiken, Layout, Fotos davon
                                herunterzuladen, zu vervielfältigen und/oder zu verbreiten. Alle Rechte, auch
                                die der Vervielfältigung, Verbreitung und Übersetzung, sind PwC vorbehalten.
                                Kein Teil dieser Website darf ohne ausdrückliche schriftliche Genehmigung von
                                PwC in irgendeiner Form reproduziert oder unter Verwendung elektronischer
                                Systeme gespeichert, verarbeitet, vervielfältigt oder verbreitet werden.
                              </p>
                              <p className="t-a-j f-t-s-14 p-l-1-p">
                                <br />
                              </p>
                              <p className="t-a-j f-t-s-14 p-l-1-p">
                                Gestattet ist lediglich die technisch bedingte Vervielfältigung zum Zwecke des
                                Browsing, soweit diese Handlung keinen wirtschaftlichen Zwecken dient, sowie
                                die dauerhafte Vervielfältigung für den eigenen Gebrauch. Es ist darüber
                                hinaus gestattet, einen Link auf diese Website zu setzen, soweit er allein der
                                Querreferenz dient. PwC behält sich das Recht vor, die Gestattung zu
                                widerrufen. Das Framen dieser Website ist ausdrücklich nicht gestattet.
                              </p>
                              <p className="t-a-j f-t-s-14 p-l-1-p">
                                <br />
                              </p>
                              <p className="t-a-j f-t-s-14 p-l-1-p">
                                (3) Die Informationen auf dieser Website sind allgemeiner Art und dienen dazu,
                                PwC dem interessierten Internetnutzer vorzustellen. PwC übernimmt die Haftung
                                für die Inhalte seiner Webseiten gemäß den gesetzlichen Bestimmungen. Die
                                Zusammenstellung der Informationen erfolgt mit der gebotenen Sorgfalt.
                              </p>
                              <p className="t-a-j f-t-s-14 p-l-1-p">
                                <br />
                              </p>
                              <p className="t-a-j f-t-s-14 p-l-1-p">
                                Für Entscheidungen, die der Verwender auf Grund der vorgenannten Informationen
                                trifft, übernimmt PwC keine Verantwortung. PwC weist darauf hin, dass der
                                vorliegende Inhalt weder eine individuelle rechtliche, buchführungstechnische,
                                steuerliche noch eine sonstige fachliche Auskunft oder Empfehlung darstellt
                                und nicht geeignet ist, eine individuelle Beratung durch fachkundige Personen
                                unter Berücksichtigung der konkreten Umstände des Einzelfalles zu ersetzen.
                                PwC behält sich das Recht vor, die auf dieser Website angebotenen
                                Informationen, Produkte oder Dienstleistungen ohne gesonderte Ankündigung
                                jederzeit zu verändern oder zu aktualisieren.
                              </p>
                              <p className="t-a-j f-t-s-14 p-l-1-p">
                                <br />
                              </p>
                              <p className="t-a-j f-t-s-14 p-l-1-p">
                                (4) Verweise und Links auf Websites Dritter bedeuten nicht, dass sich PwC die
                                hinter dem Verweis oder Link liegenden Inhalte zu eigen macht. PwC hat keinen
                                Einfluss auf die hinter dem Link liegenden Inhalte. Für rechtswidrige,
                                fehlerhafte oder unvollständige Inhalte und für Schäden, die aufgrund der
                                Nutzung von einem hinter dem Link liegenden Inhalt verursacht worden sind,
                                haftet PwC daher nicht.
                              </p>
                              <p className="t-a-j f-t-s-14 p-l-1-p">
                                <br />
                              </p>
                              <p className="t-a-j f-t-s-14 p-l-1-p">
                                (5) Die Nutzung des Internets erfolgt auf eigene Gefahr des Nutzers. PwC
                                haftet vor allem nicht für den technisch bedingten Ausfall des Internets bzw.
                                des Zugangs zum Internet.
                              </p>
                              <p className="t-a-j f-t-s-14 p-l-1-p">
                                <br />
                              </p>
                              <p className="t-a-j f-t-s-14 p-l-1-p">
                                (6) Gerichtsstand ist, wenn der Vertragspartner Kaufmann, juristische Person
                                des öffentlichen Rechts oder öffentlich-rechtliches Sondervermögen ist, am
                                Sitz der PwC in Frankfurt. Es gilt deutsches Recht unter Ausschluss des
                                UN-Kaufrechts sowie der Kollisionsnormen des internationalen Privatrechts.
                              </p>
                              <p className="t-a-j f-t-s-14 p-l-1-p">
                                <br />
                              </p>
                              <p className="t-a-j f-t-s-14 p-l-1-p">
                                (7) Für den Fall, dass der Nutzer Verbraucher (nicht Unternehmer) ist, erfolgt
                                der Hinweis gemäß Art. 14 der VO (EU) Nr. 524/2013 – ODR-Verordnung auf die
                                Möglichkeit außergerichtlicher Streitbeilegung. Details hierzu finden sich in
                                der vorgenannten Verordnung und unter{" "}
                                <span className="footerAnchor">http://ec.europa.eu/consumers/odr</span>.
                              </p>
                              <p className="t-a-j f-t-s-14 p-l-1-p">
                                <br />
                              </p>
                              <p className="t-a-j f-t-s-14 p-l-1-p">
                                (8) PwC behält sich das Recht vor, diese Nutzungsbedingungen von Zeit zu Zeit
                                zu modifizieren und sie der technischen sowie rechtlichen Entwicklung
                                anzupassen. Der Nutzer wird – soweit er sich registriert hat – auf die
                                Veränderung hingewiesen. Im Falle der Unwirksamkeit einzelner Regelungen
                                dieser Nutzungsvereinbarung bleibt die Wirksamkeit im Übrigen unberührt.
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
                <div
                  style={{
                    boxSizing: "border-box",
                    padding: "0px",
                    margin: "0px",
                    border: "0px",
                    verticalAlign: "baseline",
                    color: "rgb(45, 45, 45)",
                    fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                    fontSize: "16px",
                    fontStyle: "normal",
                    fontVariantLigatures: "normal",
                    fontVariantCaps: "normal",
                    fontWeight: 400,
                    letterSpacing: "normal",
                    orphans: 2,
                    textAlign: "start",
                    textIndent: "0px",
                    textTransform: "none",
                    whiteSpace: "normal",
                    widows: 2,
                    wordSpacing: "0px",
                    WebkitTextStrokeWidth: "0px",
                    textDecorationStyle: "initial",
                    textDecorationColor: "initial",
                  }}
                >
                  <section
                    className=" page-section page-section--col-light-grey     videobghost "
                    data-parallax="true"
                    data-wcmmode="false"
                    style={{
                      boxSizing: "border-box",
                      padding: "0px",
                      margin: "0px auto",
                      display: "block",
                      border: "0px",
                      verticalAlign: "baseline",
                      height: "auto",
                      position: "relative",
                      tableLayout: "fixed",
                      backgroundImage: "initial",
                      backgroundPosition: "center top",
                      backgroundSize: "inherit",
                      backgroundRepeat: "initial",
                      backgroundAttachment: "initial",
                      backgroundOrigin: "initial",
                      backgroundClip: "initial",
                      backgroundColor: "rgb(242, 242, 242)",
                    }}
                  >
                    <div
                      className="parsys sectionpar"
                      style={{
                        boxSizing: "border-box",
                        padding: "0px 15px",
                        margin: "0px auto",
                        border: "0px",
                        verticalAlign: "baseline",
                      }}
                    >
                      <div
                        className="parsys_column pwccol1-longform "
                        style={{
                          boxSizing: "border-box",
                          padding: "0px",
                          margin: "0px auto 40px -15px",
                          border: "0px",
                          verticalAlign: "baseline",
                          display: "table",
                          position: "relative",
                          tableLayout: "fixed",
                        }}
                      >
                        <div
                          className="parsys_column pwccol1-longform-c0"
                          style={{
                            boxSizing: "border-box",
                            padding: "0px 15px",
                            margin: "0px auto",
                            border: "0px",
                            verticalAlign: "top",
                            display: "block",
                          }}
                        >
                          <br />
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default TermsAndUseComponent;
