import React from "react";
import edit from "../../../Assets/Icons/pencil-outline.svg";
import cancel from "../../../Assets/Images/DFED/icon-x.svg";
import { Tooltip, Select, SelectOption } from "appkit-react";
import icon from "../../../Assets/Icons/duplicate-outline.svg";
import lock from "../../../Assets/Icons/lockclosed-locked-fill.svg";
import success from "../../../Assets/Images/DFED/success.svg";
import KeywordService from "../../../Services/DFED/KeywordService";
import error from "../../../Assets/Images/DFED/exclamation-mark.svg";
import { Input } from "reactstrap";
import Dropzone from "../dropzone/Dropzone";
import XLSX from "xlsx";
import $ from "jquery";
import { Pagination } from "antd";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import "../../../Assets/Css/QueenBase/Root/Login.css";
import { polyglotLoader, getCurrentLanguage } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import { checkPermission } from "../../../utility/checkPermission";
import commonFunctionForCallService from "../../../utility/commonFunctionForCallService";
import * as module_Solution_Name from "../../../Constants/moduleName";
import { validateKeyword } from "./KeywordValidation";
import { TextFieldLengthValidation } from "../../../utility/textFieldValidation";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import { closeIcon, plusCircle, saveIcon, deleteFill } from "../../../Assets/Icons";
import KeywordCard from "./KeywordCard";
import Accordion from "../../../Assets/Icons/up-chevron-outline.svg";
import chevron from "../../../Assets/Icons/down-chevron-outline.svg";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import "../../../Assets/Css/DFED/Keyword.css";

const keywordService = new KeywordService();

const TooltipText = (text) => {
  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#000",
                textTransform: "capitalize",
              }}
            >
              {text}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

var polyglot = polyglotLoader();
// UPDATE KEYWORD FUNCTIONALITY CLASS HANDELING EDITING OF KEYWORD+===================
class UpdateKeyword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: "",
      updateKeywords: props.updateKeywords,
      isLock: false,
      deleteArr: [],
      copyPanel: false,
      close: false,
      currentPage: 1,
      cflag: false,
      copyFile: [],
      lockedArray: [],
      showCopyFileupload: true,
      copyKeywordlist: [],
      displayArray: [],
      includesArray: [],
      editKeywords: [],
      showIcon: false,
      copyKeywordTitle: "",
      defaultActivePanel: null,
      editMode: true,
      projectId: this.props.projectId,
      projectName: props.projectName,
      visible: false,
      isEnable: false,
      keywordPerPage: 7,
      panelInEditIndex: [],
      tempFilterArray: [],
      tempUpdateKeywordsPagination: [],
      activeIndex: "",
      error: "",
      backupUpdateKeywords: [],
      writeKeywordlist: [{ keyword: "", isValid: false }],
      rolePermission: {},
      textFieldLengthError: false,
      visiablSaveForEdit: true,
      updatedKeywordName: "",
      updatedKeywordID: null,
    };
    this.searchTerm = this.searchTerm.bind(this);
    this.keywordPagination = this.keywordPagination.bind(this);
  }
  static contextType = DataContext;
  // life cycle METHOD FOR HANDLING PROPS WHEN EVER PARENT COMPONENT CHANGE================
  static getDerivedStateFromProps(nextProps, prevState) {
    if (nextProps.updateKeywords.length > prevState.updateKeywords.length) {
      return (
        {
          updateKeywords: nextProps.updateKeywords,
          tempUpdateKeywordsPagination: nextProps.updateKeywords,
          backupUpdateKeywords: nextProps.updateKeywords,
        },
        () => {
          this.visibleKeywords(1);
        }
      );
    } else {
      return null;
    }
  }
  // FILE UPLOAD FUNCTIONALITY========================

  fileUpload = (event) => {
    let fileObj = event[0];
    let parts = fileObj.name.split(".");
    if (parts[parts.length - 1] === "xlsx") {
      this.setState({
        copyFile: fileObj,
        fileName: event[0].name,
        isEnable: true,
        fileFlag: true,
      });
      // RENDERING EXCEL FILE FUNCTIONALITY
      const workbook = XLSX.readFile(fileObj);
      const currentSheet = workbook.Sheets[0];
      const parsedSheet = XLSX.utils.sheet_to_json(currentSheet);

      const hasOneColumn = parsedSheet.every((row) => Object.keys(row).length === 1);
      const isCorrectFirstColumnName = Object.keys(parsedSheet[0])[0] === "Keyword Name";
      if (!hasOneColumn || !isCorrectFirstColumnName) {
        return displayToastMessage(polyglot.t("Please Select Correct Template"), "error");
      }

      const rowKeywords = parsedSheet.map((row) => row["Keyword Name"]);
      this.setState({
        copyKeywordlist: [...this.state.copyKeywordlist, ...rowKeywords],
        visible: true,
        showCopyFileupload: false,
      });
    } else {
      displayToastMessage(polyglot.t("Please Select Correct Template"), "error");
    }
  };

  // used to display the error message on why the keyword is not valid
  displayKeywordTextErrorDetails = () => {
    try {
      return (
        <div style={{ textAlign: "left", fontWeight: "bold", color: "#000" }}>
          <table>
            <tbody>
              <tr>
                <td>Please consider the below parameters to enter a valid keyword</td>
              </tr>
              <tr>
                <td>You can use alphabets or numbers to create a valid keyword name</td>
              </tr>
              <tr>
                <td>If you choose to use any other characters, then please follow the below conditions</td>
              </tr>
            </tbody>
          </table>
          <ul>
            <li>a) There should be text inside brackets</li>
            <li>b) Opened quotations must be closed</li>
            <li>
              c) Special characters like '!', '@', '#', '$', '%', '^', '{"&"}', '=', ':', '{"<"}
              ', '{">"}', '/' are not allowed
            </li>
          </ul>
        </div>
      );
    } catch (err) {
      return TooltipText(err.message);
    }
  };

  // ACCORDION OPEN CLOSE HANDLING FUNCTIONALITY==========

  openAccrdion = (idx, fromEdit, strParameter) => {
    if (this.state.defaultActivePanel === idx.toString()) {
      if (strParameter === "onEdit") {
        this.setState({
          cflag: false,
        });
      } else {
        this.setState({ cflag: false, defaultActivePanel: "-1" });
        if (fromEdit) {
          this.setState({ defaultActivePanel: "-1" });
        }
      }
    } else {
      this.setState({
        defaultActivePanel: idx.toString(),
      });
    }
  };
  searchTerm = (term) => {
    return (x) => {
      return x.LIST_NAME.toLowerCase().includes(term.toLowerCase()) || !term;
    };
  };

  handleInputChange = (e) => {
    this.setState({
      query: e.target.value,
    });
  };
  // KEYWORD TITLE CHANGE FUNCTIONALITY==================

  titleChange = (e) => {
    if (TextFieldLengthValidation(e.target.value)) {
      this.setState({
        copyKeywordTitle: e.target.value,
        error: "",
        textFieldLengthError: false,
      });
    } else {
      this.setState({ textFieldLengthError: true });
    }
  };

  cancel = () => {
    this.setState({
      copyPanel: false,
    });
  };
  // LIFE CYCLE METHOD+=============

  componentDidMount() {
    this.getApplicationRolesWithPermission();
    this.setState(
      {
        updateKeywords: this.props.updateKeywords,
        tempUpdateKeywordsPagination: this.props.updateKeywords,
        backupUpdateKeywords: this.props.updateKeywords,
      },
      () => {
        this.visibleKeywords(1);
      },
    );
  }
  // HANDLING PAGINATION FOR KEYWORD+=================

  keywordPagination = (page) => {
    this.setState(
      {
        currentPage: page,
        defaultActivePanel: "-1",
        panelInEditIndex: [],
        editMode: true,
      },
      () => {
        this.visibleKeywords(page);
      },
    );
  };

  visibleKeywords(page) {
    const lastIndex = 5 * page;
    const firstIndex = lastIndex - 5;
    if (this.state.isLock === false) {
      let tempUpdateKeywordsPagination = JSON.parse(JSON.stringify(this.props.updateKeywords));
      let tempArray = tempUpdateKeywordsPagination.slice(firstIndex, lastIndex);
      this.setState({
        tempFilterArray: tempArray,
      });
    } else {
      // let newArr = this.state.tempUpdateKeywordsPagination.filter(el => el.LOCKED_STATUS === 1);
    }
    this.setState({
      currentPage: page,
    });
  }
  // ========CODE FOR GET ROLES======

  getApplicationRolesWithPermission = async () => {
    let selectedProjectId = this.state.projectId;
    let rolePermission = await commonFunctionForCallService.commonFunctionForCallService(
      module_Solution_Name.DFED_SOLUTION,
      selectedProjectId,
      module_Solution_Name.KEYWORD_MODULE,
    );
    if (rolePermission) {
      this.setState({
        rolePermission: rolePermission,
      });
    }
  };
  copyHandler = (index, value) => {
    let newArr1 = [];
    value.details.map((details) => {
      return newArr1.push(details.KEYWORD);
    });
    this.setState({
      copyPanel: true,
      cflag: true,
      copyKeywordTitle: "",
      copyKeywordlist: newArr1,
    });
  };
  // COPY KEYWORD FUNCTIONALITY=================

  addCopyKeyword() {
    let emptyFields = this.state.copyKeywordlist.filter((item) => item === "");

    if (emptyFields.length === 0) {
      this.setState({
        copyKeywordlist: this.state.copyKeywordlist.concat([""]),
      });
    } else {
      displayToastMessage("Please Enter write Keyword", "error");
      return;
    }
  }

  lockStatus(event) {
    const value = event;
    if (!value) {
      var filter = this.state.tempUpdateKeywordsPagination;
    } else {
      let filterArray = this.state.tempUpdateKeywordsPagination;
      var filter = filterArray.filter((item) => item.LOCKED_STATUS == value);
    }

    this.setState({
      updateKeywords: filter,
      lockedArray: filter,
    });
  }

  updateKeywordNameOnEdit = (value) => {
    const { formGotOpened } = this.context;
    this.setState({ updatedKeywordName: value });
    formGotOpened();
  };
  // SAVING OF COPIED KEYWORD FUNCTIONALITY====================

  saveCopyKeywords = () => {
    var polyglot = polyglotLoader();
    let projectId = $("select.selectStyle :selected").val();
    if (projectId === 0 || projectId === "" || projectId === null) {
    }
    if (this.state.copyKeywordTitle === "") {
      var polyglot = polyglotLoader();
      this.setState({ error: polyglot.t("Please Enter Keyword List Title") });
      return;
    }
    if (
      this.state.showErrorIcon ||
      this.state.copyKeywordlist[this.state.copyKeywordlist.length - 1] === ""
    ) {
      var polyglot = polyglotLoader();
      displayToastMessage("Please Enter write Keyword", "error");
      return;
    }
    var date = new Date();
    var DATE = date.toISOString();
    let keyword = [];
    let keywordlist = [];
    let formData = new FormData();
    keyword.push({
      LIST_NAME: this.state.copyKeywordTitle,
      PROJECT_ID: parseInt(this.props.projectId),
      CREATED_ON: DATE,
      CREATED_BY: "1",
      project_name: this.state.projectName,
    });
    keywordlist.push({
      keywordList: this.state.copyKeywordlist.map((x) => {
        return { keyword: x };
      }),
    });

    formData.append("keyword", JSON.stringify(keyword));
    formData.append("keywordList", JSON.stringify(keywordlist));
    keywordService.addKeywordList(formData).then((res) => {
      var polyglot = polyglotLoader();
      if (res.status === 200) {
        if (res.data === "Keyword List Name Already Exist") {
          displayToastMessage("Keyword List Name Already Exist", "error");
        } else {
          this.props.afterSaveGolist();
          this.props.getKeyword(this.state.projectId);
          this.getKeywordList();
          this.setState({
            copyPanel: false,
          });
          let Msg = polyglot.t("Keyword List has been copied");
          displayToastMessage(Msg, "success");
        }
      } else {
        let Msg = polyglot.t("Keyword List is not updated");
        displayToastMessage(Msg, "error");
      }
    });
  };

  // DELETED KEYWORD LIST FORM LIST====================

  removeKeywordHandler = (idx, idx1, value1) => {
    var polyglot = polyglotLoader();
    let newArr = this.state.updateKeywords;
    let keywords = newArr[idx].details;
    let notDeleatedKeywords = keywords.filter((item) => {
      return item.isdelete === 0;
    });
    if (notDeleatedKeywords.length !== 1) {
      newArr[idx].details[idx1].isdelete = 1;

      let keywordslistToBeDeleted = this.state.deleteArr;
      keywordslistToBeDeleted.push(value1.ID);
      this.setState({
        deleteArr: keywordslistToBeDeleted,
      });
      let id = this.state.deleteArr;
      keywordService.deleteKeyword(id, this.state.projectId).then((res) => {
        if (res.status === 200) {
          this.setState({
            updateKeywords: newArr,
          });
          let Msg = polyglot.t("Keyword deleted successfully");
          displayToastMessage(Msg, "success");
        } else {
          let Msg = polyglot.t("Keyword not deleted");
          displayToastMessage(Msg, "error");
        }
      });
    } else {
      displayToastMessage(polyglot.t("You can not delete all keywords."), "error");
    }
  };

  removeCopyKeyword = (idx) => () => {
    this.setState({
      copyKeywordlist: this.state.copyKeywordlist.filter((s, sidx) => idx !== sidx),
    });
  };

  // DELETE KEYWORD AND SEND TO PANEL ACCORDION================

  sendDeletedPanel(deleteArr, index) {
    var newArray = this.state.updateKeywords;
    newArray.splice(index, 1);
    this.setState({
      updateKeywords: newArray,
      defaultActivePanel: "-1",
      editMode: true,
      panelInEditIndex: [],
    });
    keywordService.deleteKeywordList(deleteArr, this.state.projectId).then((res) => {
      var polyglot = polyglotLoader();
      if (res.status === 200) {
        this.props.afterSaveGolist();
        let Msg = polyglot.t("Keyword List deleted successfully");
        displayToastMessage(Msg, "success");
      } else {
        let Msg = polyglot.t("Keyword List  not deleted");
        displayToastMessage(Msg, "error");
      }
    });
  }

  addKeywordHandler = () => {
    var polyglot = polyglotLoader();
    let obj =
      this.state.writeKeywordlist && this.state.writeKeywordlist.length > 0
        ? this.state.writeKeywordlist[this.state.writeKeywordlist.length - 1]
        : null;

    if (obj && obj.keyword === "") {
      displayToastMessage(polyglot.t("Please enter right Keyword"), "error");
      return;
    }
    this.setState({
      writeKeywordlist: this.state.writeKeywordlist.concat([{ keyword: "" }]),
      showIcon: false,
      // visible: true,
      visiablSaveForEdit: true,
    });
  };

  editingPanelHandler = (ID, idx, LIST_NAME) => {
    if (!this.state.panelInEditIndex.includes(ID)) {
      let newSet = this.state.panelInEditIndex;
      newSet.push(ID);
      this.setState({
        panelInEditIndex: newSet,
        editMode: false,
        updatedKeywordName: LIST_NAME,
        updatedKeywordID: ID,
      });
    }
    this.openAccrdion(idx, false, "onEdit");
  };

  // KEYWORD SAVE FUNCTIONALITY+====================

  saveKeywordData = (index, value) => {
    const { formGotClosed } = this.context;
    let projectId = this.state.projectId;
    var saveKeyword = value.details
      .filter((val) => val.isedit === 1)
      .map((val) => {
        return {
          ID: val.ID,
          LIST_ID: value.ID,
          KEYWORD: val.KEYWORD,
        };
      });

    const newKeywordList = value.details
      .filter((val) => val.isdelete === 0)
      .map((val) => {
        return {
          ID: val.ID,
          LIST_ID: value.ID,
          KEYWORD: val.KEYWORD,
        };
      });

    let dataOfNewlyAdded = this.state.writeKeywordlist;

    for (let i = 0; i < dataOfNewlyAdded.length; i++) {
      let obj = {
        ID: 0,
        LIST_ID: value.ID,
        KEYWORD: dataOfNewlyAdded[i].keyword,
      };
      var ckeckEmptyKeyword = false;
      if (dataOfNewlyAdded[i].keyword === "" || dataOfNewlyAdded[i].keyword === null) {
        ckeckEmptyKeyword = true;
      }
      saveKeyword.push(obj);
      newKeywordList.push(obj);
    }

    const lowerCaseKeywordsArr = newKeywordList.map((item) => item.KEYWORD.toLowerCase());
    if (lowerCaseKeywordsArr.length !== new Set(lowerCaseKeywordsArr).size) {
      displayToastMessage(polyglot.t("Duplicated keywords"), "error");
      return;
    }
    if (!ckeckEmptyKeyword) {
      const keywordListName = this.state.updatedKeywordName;
      const updatedKeywordID = this.state.updatedKeywordID;
      keywordService.editKeyword(saveKeyword, projectId, keywordListName, updatedKeywordID).then((res) => {
        var polyglot = polyglotLoader();
        if (res.status === 200) {
          this.props.getKeyword(this.state.projectId);
          this.getKeywordList();
          formGotClosed();
          let Msg = polyglot.t("Keyword saved successfully");
          displayToastMessage(Msg, "success");
          this.setState({
            editMode: true,
            panelInEditIndex: [],
            showIcon: false,
            showErrorIcon: false,
            defaultActivePanel: "-1",
            visible: false,
            updatedKeywordName: "",
            updatedKeywordID: null,
          });
        } else if (res.status === 400) {
          let Msg = polyglot.t(res.data);
          displayToastMessage(Msg, "error");
        } else {
          let Msg = polyglot.t("Keyword is not saved");
          displayToastMessage(Msg, "error");
        }
      });
    } else {
      displayToastMessage("Please enter right Keyword", "error");
    }
  };

  cancelEditing = (idx) => {
    this.setState({
      editMode: true,
      panelInEditIndex: [],
      visible: false,
      writeKeywordlist: [],
    });
    this.openAccrdion(idx, true);
  };
  genExtra = (value, index) => {
    if (value.LOCKED_STATUS === 1) {
      return (
        <div className="d-flex justify-content-end" key={index}>
          <Tooltip
            content={[
              <div>
                <div className="a-h6 font-weight-normal">
                  {polyglot.t(
                    "“Locked” keyword list indicates that these keywords had already been used for an analysis.",
                  )}
                </div>
                <div className="a-h6 font-weight-normal">
                  {polyglot.t(
                    "This list cannot be modified but a copy of this list can be created by clicking on a copy icon.",
                  )}
                </div>
              </div>,
            ]}
            placement="top"
            tooltipTheme="light"
            className="auth-tooltip"
          >
            <img
              src={lock}
              width={32}
              height={32}
              className="m-1 cursor"
              alt="lock"
              ariaLabel={polyglot.t("lock")}
            />
          </Tooltip>
          <ButtonIcon
            onClick={() => this.copyHandler(index, value)}
            alt="icon"
            iconImg={icon}
            imgWidth={20}
            ariaLabel={polyglot.t("copy")}
            title={polyglot.t("Copy")}
          />
          <ButtonIcon
            imgWidth={16}
            iconImg={this.state.defaultActivePanel === index.toString() ? Accordion : chevron}
            onClick={() => this.openAccrdion(String(index))}
            alt={this.state.defaultActivePanel === index.toString() ? "expand" : "close"}
            ariaLabel={polyglot.t("Expand project")}
            title={polyglot.t("Expand project")}
          />
        </div>
      );
    } else {
      return (
        <div className="d-flex justify-content-end" key={value.ID}>
          {this.state.panelInEditIndex.includes(value.ID) && !this.state.editMode ? (
            <ButtonIcon
              alt="cancel"
              onClick={() => this.cancelEditing(index, value)}
              iconImg={cancel}
              ariaLabel={polyglot.t("cancel")}
              title={polyglot.t("Cancel")}
            />
          ) : (
            <>
              <>
                {this.state.editMode &&
                  checkPermission(this.state.rolePermission, module_Solution_Name.KEYWORD_MODULE, "Edit") && (
                    <ButtonIcon
                      iconImg={edit}
                      alt="edit"
                      imgWidth={20}
                      onClick={() => this.editingPanelHandler(value.ID, index, value.LIST_NAME)}
                      ariaLabel={polyglot.t("edit")}
                      title={polyglot.t("Edit")}
                    />
                  )}
              </>
              {checkPermission(this.state.rolePermission, module_Solution_Name.KEYWORD_MODULE, "Delete") && (
                <ButtonIcon
                  alt={"delete"}
                  iconName={deleteFill}
                  onClick={(e) => this.sendDeletedPanel(value.ID, index)}
                  ariaLabel={polyglot.t("delete")}
                  title={polyglot.t("Delete")}
                />
              )}
              <ButtonIcon
                imgWidth={16}
                iconImg={this.state.defaultActivePanel === index.toString() ? Accordion : chevron}
                onClick={() => this.openAccrdion(String(index))}
                alt={this.state.defaultActivePanel === index.toString() ? "expand" : "close"}
                ariaLabel={polyglot.t("Expand project")}
                title={polyglot.t("Expand project")}
              />
            </>
          )}
        </div>
      );
    }
  };

  // check if the entered keyword text is valid
  checkIsKeywordTextValid = (text) => {
    return validateKeyword(text);
  };

  updateChange = (idx1, idx, e, value, value1) => {
    const { formGotOpened } = this.context;
    let newArr = this.state.updateKeywords;
    newArr[idx1].details[idx].isedit = 1;
    newArr[idx1].details[idx].KEYWORD = e.currentTarget.value;
    this.setState({
      updateKeywords: newArr,
      visible: true,
      activeIndex: newArr[idx1].details[idx].ID,
    });
    const updateStateWhenInvalidInput = () => {
      this.setState({
        showIcon: false,
        showErrorIcon: true,
        visible: false,
      });
    };
    const isKeywordValid = validateKeyword(e.currentTarget.value);
    if (!isKeywordValid) {
      updateStateWhenInvalidInput();
    } else {
      this.setState({
        showIcon: true,
        showErrorIcon: false,
        visible: true,
      });
    }
    formGotOpened();
  };

  copyKeywordChange = (idx, e, value) => {
    const updatedArray = this.state.copyKeywordlist;
    updatedArray[idx] = e.currentTarget.value;
    this.setState({
      copyKeywordlist: [...updatedArray],
      activeIndex: updatedArray[idx],
    });
    const isKeywordValid = validateKeyword(e.currentTarget.value);
    this.setState({
      showIcon: isKeywordValid,
      showErrorIcon: !isKeywordValid,
    });
  };

  getCurrentPageItems = (filteredItems) => {
    const PER_PAGE = 5;
    return filteredItems.slice((this.state.currentPage - 1) * PER_PAGE, this.state.currentPage * PER_PAGE);
  };

  addWriteKeyword = (idx) => (event) => {
    const currentKeyword = event.currentTarget.value;
    const isKeywordValid = validateKeyword(currentKeyword);
    if (isKeywordValid) {
      const newWriteKeywordlist =
        this.state.writeKeywordlist &&
        this.state.writeKeywordlist.map((keyword, sidx) => {
          if (idx !== sidx) return keyword;
          return { ...keyword, keyword: currentKeyword, isValid: true };
        });
      this.setState({
        writeKeywordlist: newWriteKeywordlist,
        error: "",
        activeIndex: idx,
        showIcon: true,
        fileFlag: false,
        showErrorIcon: false,
        // isEnable: true,
        visible: true,
      });
    } else {
      const newWriteKeywordlist =
        this.state.writeKeywordlist &&
        this.state.writeKeywordlist.map((keyword, sidx) => {
          if (idx !== sidx) return keyword;
          return { ...keyword, keyword: currentKeyword, isValid: false };
        });
      this.setState({
        writeKeywordlist: newWriteKeywordlist,
        activeIndex: idx,
        // isEnable: false,
        showErrorIcon: true,
        showIcon: false,
        visible: false,
      });
    }
    if (currentKeyword === "") {
      this.setState({
        showErrorIcon: true,
        showIcon: false,
      });
    }
  };

  removeKeyword = (idx) => () => {
    let NewKeywords = this.state.writeKeywordlist;
    if (NewKeywords.length === 1) {
      this.setState({
        visible: false,
      });
    }
    this.setState({
      writeKeywordlist: this.state.writeKeywordlist.filter((s, sidx) => idx !== sidx),
    });
  };

  getKeywordList() {
    let selectedProjectId = this.props.projectId;

    const keywordService = new KeywordService();
    keywordService.getKeywordData(selectedProjectId).then((result) => {
      if (result && result.data && result.data.length > 0) {
        this.setState({
          updateKeywords: result.data,
          writeKeywordlist: [],
        });
      }
    });
  }

  displayExpandOrEdit = (value, idx) => {
    if (this.state.defaultActivePanel === idx.toString()) {
      return value.details.map((value1, idx1) => {
        if (!value1.isdelete) {
          /* we see this if user clicks on expand or edit icon */
          return (
            <div key={value1.KEYWORD}>
              <>
                {this.state.panelInEditIndex.includes(value.ID) ? (
                  <div className="row keyword-upload-bg m-l-0 m-r-0 p-0">
                    <div className="col-md-8">
                      <Input
                        className="keyword-input"
                        key={idx1}
                        value={value1.KEYWORD}
                        aria-label={value1.KEYWORD}
                        onChange={(e) => this.updateChange(idx, idx1, e, value, value1)}
                      />
                    </div>
                    <div className="col-md-1">
                      <ButtonIcon
                        onClick={this.removeKeywordHandler.bind(this, idx, idx1, value1)}
                        key={idx1}
                        value={value1.KEYWORD}
                        iconName={deleteFill}
                        alt="delete"
                        ariaLabel={polyglot.t("delete")}
                        title={polyglot.t("Delete")}
                      />
                    </div>
                    {this.checkIsKeywordTextValid(value1.KEYWORD) && (
                      <div className="col-md-1 sucess-check transformY-minus40">
                        <img src={success} alt="success" />
                      </div>
                    )}
                    {!this.checkIsKeywordTextValid(value1.KEYWORD) && (
                      <Tooltip
                        content={
                          this.state.errorMsg && this.state.errorMsg.length != 0
                            ? TooltipText(this.state.errorMsg[this.state.errorMsg.length - 1])
                            : this.displayKeywordTextErrorDetails()
                        }
                        placement="top"
                      >
                        <div className="col-md-1 sucess-check margin-top-15 transformY-minus40">
                          <img className="keyword-img-font" src={error} alt="error" />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                ) : (
                  value1.KEYWORD
                )}
              </>
            </div>
          );
        }
        return null;
      });
    }
    return null;
  };
  displayEditView = (value, idx) => {
    if (this.state.defaultActivePanel === idx.toString() && this.state.panelInEditIndex.includes(value.ID)) {
      return (
        <div>
          <div className="col-md-12">
            {this.state.writeKeywordlist &&
              this.state.writeKeywordlist.map((keyword, idx) => (
                <div key={keyword.name}>
                  <div className="row key-word-bg">
                    <div className="col-md-8">
                      <Input
                        type="text"
                        key={idx}
                        className=""
                        value={keyword.name}
                        aria-label={keyword.name}
                        onChange={this.addWriteKeyword(idx)}
                        autoFocus
                        placeholder={polyglot.t("Keyword name")}
                      />
                    </div>
                    <div className="col-md-1">
                      <ButtonIcon
                        onClick={this.removeKeyword(idx)}
                        iconName={deleteFill}
                        alt="delete"
                        ariaLabel={polyglot.t("delete")}
                        title={polyglot.t("Delete")}
                      />
                    </div>
                    {keyword.isValid ? (
                      <div className="col-md-1 sucess-check transformY-minus40">
                        <img src={success} alt="success" />
                      </div>
                    ) : null}
                    {!keyword.isValid && keyword.keyword.length > 0 && (
                      <Tooltip
                        content={
                          this.state.errorMsg && this.state.errorMsg.length != 0
                            ? TooltipText(this.state.errorMsg[this.state.errorMsg.length - 1])
                            : this.displayKeywordTextErrorDetails()
                        }
                        placement="top"
                      >
                        <div className="col-md-1 sucess-check margin-top-15 transformY-minus40">
                          <img className="keyword-img-font" src={error} alt="error" />
                        </div>
                      </Tooltip>
                    )}
                  </div>
                </div>
              ))}
          </div>
        </div>
      );
    }
    return null;
  };
  displayEditViewRightPanel = (value, idx) => {
    return (
      <>
        <div className="col-md-2 mt-1 p-0 p-l-key-edit">
          <div className="d-flex justify-content-start">
            {this.state.panelInEditIndex.includes(value.ID) && (
              <div className="keyword-add add-btn-upload ">
                {this.state.panelInEditIndex.includes(value.ID) && (
                  <ButtonIcon
                    onClick={this.addKeywordHandler}
                    iconName={plusCircle}
                    ariaLabel={polyglot.t("New keyword")}
                    title={polyglot.t("New keyword")}
                  />
                )}
              </div>
            )}
          </div>
        </div>
        <div className="col-md-4 mt-4 mb-3">
          {this.state.panelInEditIndex.includes(value.ID) && (
            <ButtonPrimary
              onClick={() => this.saveKeywordData(idx, value)}
              ariaLabel={polyglot.t("Save Keyword list")}
            >
              {polyglot.t("Save Keyword list")}
            </ButtonPrimary>
          )}
        </div>
      </>
    );
  };

  render() {
    const filteredItems = this.state.updateKeywords.filter((item) =>
      item.LIST_NAME.toLowerCase().includes(this.state.query.toLowerCase()),
    );
    var polyglot = polyglotLoader();
    return (
      <div>
        <div className="row m-t-21  m-l-0 m-r-0">
          <div className="col-md-6 tex-ali-processing p-l-0 p-r-0">
            {filteredItems.length === 1 ? (
              <h5 className="main_title_qb m-b-21">
                {" "}
                {filteredItems.length} {polyglot.t("Keyword list")}
              </h5>
            ) : (
              <h5 className="main_title_qb m-b-21">
                {" "}
                {filteredItems.length} {polyglot.t("Keyword lists")}
              </h5>
            )}
          </div>
        </div>

        <div className="row m-t-4">
          <div className="col-md-3 m-l-0 m-r-0">
            <div>
              <Input
                role="presentation"
                className="keywordlist-search"
                aria-label={polyglot.t("Search keyword lists")}
                placeholder={polyglot.t("Search keyword lists")}
                value={this.state.query}
                onChange={this.handleInputChange}
              />
            </div>
          </div>
          <div className="col-md-2 m-auto m-l-0 m-r-0"></div>

          <div className="col-md-3  m-l-0 m-r-0">
            <Select
              id="keyword-filter"
              placeholder={polyglot.t("All")}
              onSelect={(event) => this.lockStatus(event)}
              value={this.state.selectedFilterType}
            >
              <SelectOption key="" value="">
                {polyglot.t("All")}{" "}
              </SelectOption>
              <SelectOption key="1" value="1">
                {polyglot.t("Locked")}{" "}
              </SelectOption>
              <SelectOption key="0" value="0">
                {polyglot.t("Unlocked")}
              </SelectOption>
            </Select>
          </div>
        </div>

        {/* -------------------------------- */}
        <div className="row m-t-12">
          <div className="col-md-12 col-lg-8 m-t-14">
            <div className="col-md-12 pl-0 keyword-collapse-list pr-0">
              {filteredItems.length > 0 ? (
                this.getCurrentPageItems(filteredItems).map((value, idx) => {
                  return (
                    <div className="dfed-keyword-list-div-1" key={value.ID}>
                      <KeywordCard
                        LIST_NAME={value.LIST_NAME}
                        editMode={!this.state.editMode && this.state.panelInEditIndex[0] === value.ID}
                        updateKeywordNameOnEdit={this.updateKeywordNameOnEdit}
                        updatedKeywordName={this.state.updatedKeywordName}
                        genExtra={this.genExtra(value, idx)}
                      >
                        {this.state.defaultActivePanel === idx.toString() && (
                          <>
                            <div className="border-top a-border-light mb-3"></div>
                            <div className="row container">
                              <div className="col-10 p-r-10 keyword-lock max-width-of-edit">
                                {this.displayExpandOrEdit(value, idx)}
                                {/* we see this if user clicks on edit icon */}
                                {this.displayEditView(value, idx)}
                              </div>

                              {/* user can see this on clicking on edit icon - start */}
                              {this.displayEditViewRightPanel(value, idx)}

                              {/* user can see this on clicking on edit icon - end */}
                            </div>
                          </>
                        )}
                      </KeywordCard>
                    </div>
                  );
                })
              ) : (
                <div className=" Admin-data">
                  <div className="col-md-6">
                    <div className="Admin-tittle pl-0 ">
                      <p>{polyglot.t("No records found")}</p>
                    </div>
                  </div>
                </div>
              )}
            </div>

            {/* -------- */}
            {/* User can see this only after clicking on copy icon - start */}
            {this.state.copyPanel ? (
              <div>
                <div className="col-md-12 pr-0 pl-0">
                  <div className="a-panel a-shadow-sm keyword-lock-total pb-4 pt-3">
                    <div className="row m-l-0 m-r-0">
                      <div className="co1-md-3" style={{ paddingBottom: "11px", width: "33%" }}>
                        <input
                          className="upload-keyword-search a-input-text ans-name"
                          id="profileNameId"
                          aria-label={polyglot.t("Enter keyword list name...")}
                          placeholder={polyglot.t("Enter keyword list name...")}
                          onChange={this.titleChange}
                          value={this.state.copyKeywordTitle}
                        />
                        {this.state.textFieldLengthError ? (
                          <p className="txt-field-error m-0 ">
                            {polyglot.t("Maximum character length is 40")}
                          </p>
                        ) : null}
                      </div>

                      <div className="col-md-1 mt-1 ">{/* <img src={edit} alt="edit"/> */}</div>
                      <div className="col-md-7 text-align-right">
                        <ButtonIcon
                          onClick={this.cancel}
                          alt="cancel"
                          iconName={closeIcon}
                          ariaLabel={polyglot.t("Cancel")}
                          title={polyglot.t("Cancel")}
                        />
                      </div>
                    </div>
                    <hr />
                    <div className="row mt-2 pl-0 m-l-0 m-r-0">
                      <div className="mt-3 mb-4">
                        <span className="keyword-desc-txt">
                          {polyglot.t(
                            "Quick edit your existing keywords or add new ones by uploading a file or writing them down",
                          )}
                        </span>
                      </div>
                      <div className="keyword-error">{this.state.error}</div>
                      <div className="col-md-10 keyword-lock">
                        {this.state.copyKeywordlist.map((value, idx) => {
                          return (
                            <div key={idx}>
                              <>
                                <div className="row p-0 m-l-0 m-r-0">
                                  <div className="col-md-8 pl-0">
                                    <Input
                                      className="m-0"
                                      key={idx}
                                      value={value}
                                      aria-label={value}
                                      onChange={(e) => this.copyKeywordChange(idx, e, value)}
                                      placeholder={polyglot.t("Keyword name")}
                                    />
                                  </div>
                                  <div className="col-md-4 d-flex justify-content-start">
                                    <ButtonIcon
                                      alt="delete"
                                      iconName={deleteFill}
                                      onClick={this.removeCopyKeyword(idx)}
                                      ariaLabel={polyglot.t("delete")}
                                      title={polyglot.t("Delete")}
                                    />
                                    {this.state.activeIndex === value && this.state.showIcon && (
                                      <img src={success} alt="success" />
                                    )}
                                    {this.state.activeIndex === value && this.state.showErrorIcon && (
                                      <img className="keyword-img-font" alt="error" src={error} />
                                    )}
                                  </div>
                                </div>
                              </>
                            </div>
                          );
                        })}
                      </div>

                      <div className="col-md-2 mt-1">
                        <div className="row m-r-0">
                          <ButtonIcon
                            onClick={(e) => this.addCopyKeyword(e)}
                            iconName={plusCircle}
                            ariaLabel={polyglot.t("New keyword")}
                            title={polyglot.t("New keyword")}
                          />
                          <ButtonIcon
                            onClick={this.saveCopyKeywords}
                            iconName={saveIcon}
                            ariaLabel={polyglot.t("Save")}
                            title={polyglot.t("Save")}
                          />
                        </div>
                      </div>
                    </div>

                    {this.state.showCopyFileupload ? (
                      <div className="col-md-10 pl-0 mt-3 pr-0">
                        <Dropzone
                          customDropZoneBoxStyle={{
                            width: "100%",
                          }}
                          onFilesAdded={this.fileUpload.bind(this)}
                          // accept="image/png, image/gif"
                          // accept="application/zip"
                          // accept="application/json"
                          disabled={this.state.uploading || this.state.successfullUploaded}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {/* User can see this only after clicking on copy icon - end */}

            {/* ----------- */}
          </div>
          <div className="col-md-12 col-lg-4 m-t-14">
            <div>
              {getCurrentLanguage() === "ger" && (
                <p className="para-txt">
                  {polyglot.t(
                    "You can create a search word list and link it to your data records. To do this, click the button",
                  )}
                  <span className="para-head"> {polyglot.t("Add Keyword Listgr")}</span>
                  {polyglot.t(
                    "You can also view or edit existing search word lists. Use the symbols on the right side of the line for this. As soon as you have created your search lists, start your analysis with the button",
                  )}
                  <span className="para-head"> {polyglot.t("Start an Analysisgr")}</span>
                </p>
              )}
              {getCurrentLanguage() === "en" && (
                <p className="para-txt">
                  {polyglot.t(
                    "You can generate a Keyword list and map it to your Datasets to start an analysis. On the right, you can consult and edit the already present Keyword lists",
                  )}
                  {polyglot.t("If you want to create an additional list, please click on ")}
                  <span className="para-head"> {polyglot.t("Add Keyword List")}</span>
                  {" " + polyglot.t("Otherwise, click ")}
                  <span className="para-head"> {polyglot.t("Start an Analysis")}</span>
                  {" " + polyglot.t("to proceed with the Analysis of your documents")}.
                </p>
              )}
            </div>
          </div>
        </div>
        <div className="m-l-n-10">
          {this.state.isLock === false && this.state.backupUpdateKeywords.length > 5 ? (
            <Pagination
              size="small"
              pageSize={5}
              current={this.state.currentPage}
              total={filteredItems.length}
              onChange={this.keywordPagination}
            />
          ) : (
            ""
          )}
          {this.state.isLock === true &&
          this.state.tempUpdateKeywordsPagination.filter((el) => el.LOCKED_STATUS === 1).length > 5 ? (
            <Pagination
              size="small"
              pageSize={5}
              current={this.state.currentPage}
              total={this.state.tempUpdateKeywordsPagination.filter((el) => el.LOCKED_STATUS === 1).length}
              onChange={this.keywordPagination}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    );
  }
}
export default UpdateKeyword;
