import React from "react";

import "./RunAnalysis.css";
import "./CommonStyles.css";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import img from "../../../Assets/Images/DFED/icon-loader.svg";
import { polyglotLoader } from "../../../Services/Shared/Translate";
// RUN ANALYSIS CLASS IS RESPONSIBLE FOR HANDELING NEW RUN ANALYSIS FUNCTIONALITY========================
const RunAnalysis = (props) => {
  // const {userLanguage} = useContext(DataContext);
  let res = ["", "", "inprogress", "active"];
  var polyglot = polyglotLoader();
  // CODE FOR SHOW PROGRESS BAR WITH STATUS===================
  const statusbar = () => {
    return (
      <div className="col-md-12 p-0">
        <ul className="progressbar">
          <li className={res["3"]}>{polyglot.t("Request Received")}</li>
          <li className={res[props.data.PREPARE_KEYWORD_STATUS]}>{polyglot.t("Preparing Keywords")}</li>
          <li className={res[props.data.RUNNING_KEYWORD_STATUS]}>{polyglot.t("Running Keywords")}</li>
          <li className={res[props.data.REPORT_GENERATED_STATUS]}>{polyglot.t("Report Generated")}</li>
        </ul>
      </div>
    );
  };
  // RETURN FUNCTION FOR RENDER UI=============================

  return (
    <div>
      <div className="row m-l-0 m-r-0" onClick={() => props.BackToReview("AnalysisList")}>
        {props.data.REPORT_GENERATED_STATUS === "3" ? null : (
          <div className="col-md-3 p-0  tex-ali">
            <ButtonPrimary onClick={props.goBackFunction} ariaLabel={polyglot.t("Go to overview")}>
              {polyglot.t("Go to overview")}
            </ButtonPrimary>
          </div>
        )}
        <div className="col-md-9"></div>
      </div>

      <div className="row m-l-0 m-r-0">
        <div className="col-md-6  col-sm-12 col-xs-12 p-0 mt-3 tex-ali inp-row justify">
          <div className="row  head_txt_big_ans justify m-l-0 m-r-0">
            <span className="tex-ali">{props.data.ANALYSIS_NAME}</span>
            {props.data.REPORT_GENERATED_STATUS === "3" && <img width="50px" src={img} alt="loading.." />}
          </div>
        </div>
        <div className="col-md-6"></div>
      </div>

      {props.data.REPORT_GENERATED_STATUS === "3" ? null : (
        <div className="row m-l-0 m-r-0">
          <div className="col-md-6  col-sm-12 col-xs-12 p-0 tex-ali inp-row justify">
            <div className="row  noofans-sty  justify m-l-0 m-r-0">
              <span className="tex-ali">
                {"Your data analysis is running.."}
                <img width="50px" src={img} alt="loading.." />
              </span>
            </div>
          </div>
          <div className="col-md-6"></div>
        </div>
      )}

      <div className="row mt-4 m-l-0 m-r-0">{statusbar()}</div>
    </div>
  );
};

export default RunAnalysis;
