import React, { Component } from "react";
import { Collapse } from "antd";
import AnalysisService from "../../../Services/DFED/AnalysisService";
import OutlineButton from "../Shared/button/OutlineButton";
import BackButton from "../Shared/button/BackButton";
import CommonButton from "../Shared/button/CommonButton";
import "./AnalysisReview.css";
import "../Shared/button/button.css";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { customNumberFormat } from "../../../utility/CustomNumberFormat";
import img from "../../../Assets/Images/DFED/icon-review-loader.svg";
import { Tooltip } from "appkit-react";
import CustomModal from "../../../Assets/Components/Modal";
import GetPriceInGermanFormat from "../../QueenBase/Root/GetPriceInGermanFormat";
import { RightOutlined, InfoCircleOutlined } from "@ant-design/icons";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import LinkText from "../../../Assets/Components/LinkText/LinkText";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import { downloadImg, infoIcon } from "../../../Assets/Icons";
import AnalysisReviewCard from "./AnalysisReviewCard";

const analysisService = new AnalysisService();
const { Panel } = Collapse;
const SearchTermMaxDisplaylength = 255;

// THIS CLASS IS RESPONSIBLE FOR ANALYSIS REVIEW COMPONENT=============================

class AnalysisReview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      res_div: {
        borderBottom: "solid",
        borderBottomWidth: "4px",
        borderBottomColor: "#E0301E",
      },
      family_div: {},
      // res_doc_data: [],
      showMoreDetails: "none",
      data: [],
      total1: 0,
      SEARCH_TERM: 0,
      TOP_LEVEL: 0,
      ATTACHMENTS_NON_UNIQUE: 0,
      openedAccordian: 0,
      checked: [],
      visibleTermsModel: false,
      modelBodyContent: "",
      selectedRun: 0,
      selectedRunThroughData: [],
    };
  }
  // LIFE CYCLE METHOD FOR GETTING ALL ANALYSIS DATA=====================

  componentDidMount() {
    this.getData();
  }
  // MODAL OPEN AND CLOSE FUNCTIONLAITY====================

  showModal = (modelContent) => {
    let modelContent1 = modelContent.map((item, index) => {
      return (
        <Collapse
          // activeKey={1}
          defaultActiveKey={0}
          className="mb-2  collapse_analysis_review"
          expandIconPosition="right"
          expandIcon={({ isActive }) => (
            <div className="col-2">
              <RightOutlined rotate={isActive ? 90 : -90} />
            </div>
          )}
        >
          <Panel header={<h5>{item.custodainName}</h5>}>
            {item.ds.map((dataset) => {
              return <p>{dataset.DT_DATASET_NAME}</p>;
            })}
          </Panel>
        </Collapse>
      );
    });
    this.setState({ visibleTermsModel: true, modelBodyContent: modelContent1 }, () => {});
  };

  handleConfirm = (e) => {
    this.setState({
      visibleTermsModel: false,
    });
  };
  // REDIRECTING TO REVIEW PAGE========================

  pushToReview = () => {
    this.props.history.push({
      pathname: "/dfed/reviewselection",
      state: {
        selectedProjectId: this.props.location,
        ID: this.props.data.ID,
        data: this.state.selectedRunThroughData,
      },
    });
  };

  // API CALLING FOR GETTING ALL ANALYSIS DETAILS DATA=========================
  async getData() {
    let polyglot = polyglotLoader();
    let analysisDetails = await analysisService.AnalysisDetails(this.props.data.ID, this.props.location);
    if (analysisDetails.data) {
      var total = 0;
      var SEARCH_TERM = 0;
      var TOP_LEVEL = 0;
      var ATTACHMENTS_NON_UNIQUE = 0;
      // CHECKING DATA IS THERE OR NOT
      if (analysisDetails.data && analysisDetails.data[0] && analysisDetails.data[0].details) {
        analysisDetails.data[0].details.forEach((item) => {
          total = total + Number(item.TOP_LEVEL_DOC_ATTACHMENTS);
          SEARCH_TERM = SEARCH_TERM + Number(item.SEARCH_TERM);
          TOP_LEVEL = TOP_LEVEL + Number(item.TOP_LEVEL);
          ATTACHMENTS_NON_UNIQUE = ATTACHMENTS_NON_UNIQUE + Number(item.ATTACHMENTS_NON_UNIQUE);
        });
        this.setState({
          data: analysisDetails.data,
          total1: total,
          SEARCH_TERM: SEARCH_TERM,
          TOP_LEVEL: TOP_LEVEL,
          ATTACHMENTS_NON_UNIQUE: ATTACHMENTS_NON_UNIQUE,
          checked: [analysisDetails.data.length - 1],
          openedAccordian: analysisDetails.data.length - 1,
          selectedRun: analysisDetails.data.length - 1,
        });
      }
      if (analysisDetails.data.length === 1) {
        this.setState({ selectedRunThroughData: analysisDetails.data[0] });
      } else {
        this.setState({
          selectedRunThroughData: analysisDetails.data[analysisDetails.data.length - 1],
        });
      }
    }
  }
  radioButtonHandler = (index, selectedRunThroughData) => {
    let checked = [];
    checked.push(index);
    this.setState({ checked: checked });
    if (this.state.openedAccordian === index) {
      this.setState({ openedAccordian: -1 });
    } else {
      this.setState({
        openedAccordian: index,
        selectedRun: selectedRunThroughData.runthroughID,
        selectedRunThroughData: selectedRunThroughData,
      });
    }
  };

  dataSetOpenHandler = (index, selectedRun) => {
    if (this.state.openedAccordian === index) {
      this.setState({ openedAccordian: -1 });
    } else {
      this.setState({ openedAccordian: index, selectedRun: selectedRun });
    }
  };
  // SHOW MORE SHOW LESS FUNCTIONALITY====================

  showMoreDivHandler = () => {
    if (this.state.showMoreDetails === "") {
      this.setState({ showMoreDetails: "none" });
    } else {
      this.setState({ showMoreDetails: "" });
    }
  };

  // FUNCTIONALITY FOR CHANGE SELECTED DIV===================
  changeSelecedDiv = (arg) => {
    if (arg === "res") {
      this.setState({
        res_div: {
          borderBottom: "solid",
          borderBottomWidth: "4px",
          borderBottomColor: "#E0301E",
        },
        family_div: {},
      });
    } else {
      this.setState({
        family_div: {
          borderBottom: "solid",
          borderBottomWidth: "4px",
          borderBottomColor: "#E0301E",
        },
        res_div: {},
      });
    }
  };
  // REDIRECTING TO ANALYSIS PAGE=======================
  goToAddAnalysis = (data, id, name) => {
    this.props.goToAddNewAnalysis("AddNewAnalysis", data, id, name);
  };
  // DOWNLOAD TEMPLATE FUNCTIONALITY===============================
  downloadTemplate = (filrnamePath) => {
    analysisService.getTemplate(filrnamePath).then((response) => {
      if (response && response.data) {
        var buf = Buffer.from(response.data.buffer);
        const url = window.URL.createObjectURL(new Blob([buf]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.data.fileName + ".xlsx"); //or any other extension
        document.body.appendChild(link);
        link.click();
      } else {
        alert("File does not available");
      }
    });
  };
  // HANDELING LIST EDIT BASED ON ID OF PARTICULAR ANALYSIS=========================s

  Resultsof = (details) => {
    let datasets = details;
    let datasetString = "";
    if (datasets && datasets.length > 0) {
      datasets.forEach((item, outerIndex) => {
        datasetString += item.custodainName + "(";
        item.ds.forEach((dataset, innerIndex) => {
          datasetString += dataset.DT_DATASET_NAME;

          let inner = innerIndex + 1;
          let lengthData = item.ds.length;

          if (!(inner === lengthData)) {
            datasetString += ", ";
          }
        });
        datasetString += ")";
        if (!(outerIndex === datasets.length - 1)) {
          datasetString += ", ";
        }
      });
      return datasetString;
    } else {
      return null;
    }
  };
  // FUNCTION FOR HANDLEING DYNAMIC CREATING STRING==========================
  strfunc = (data) => {
    let str = "";
    data.forEach((item) => {
      str = str + item.DT_DATASET_NAME + ",";
    });
    return str;
  };
  // REDIRECTING TO NEED HELP PAGE=======================
  goToneedhelp = () => {
    localStorage.setItem("previousComponent", "Analysis");
    this.props.history.push({
      pathname: "/dfed/support",
      state: { selectedProjectId: this.props.location },
    });
  };
  // RENDER UI ANALYSIS
  render() {
    var polyglot = polyglotLoader();
    return (
      <div>
        {/* -----------------maping------------ */}
        <div className="row m-l-0 m-r-0 mb-2">
          <BackButton
            onBackButtonClick={this.props.goBackFunction}
            buttonText={polyglot.t("Back")}
          ></BackButton>
        </div>
        <div className="row  m-l-0 m-r-0">
          <div className="col-md-8 p-0 analysis-review-collapse">
            {this.state.data ? null : (
              <div className="loader-div">
                <img className="loader-img" src={img} alt="" />
              </div>
            )}
            {this.state.data && (
              <div>
                {this.state.data.map((item, index) => {
                  return (
                    <div>
                      {index === 0 ? (
                        <div className="row  p-0 m-l-0 m-r-0 ">
                          <AnalysisReviewCard
                            title={this.props.data.ANALYSIS_NAME}
                            RightElement={() => {
                              return "";
                            }}
                          >
                            <div className="tex-ali">
                              <label className="sub-title p-0 tex-ali">
                                {polyglot.t("Results from running")}{" "}
                                <span className="for-bold">{item.KEYWORD_LIST_NAME}</span>
                                &nbsp;
                                <ButtonIcon
                                  iconName={infoIcon}
                                  alt="info"
                                  onClick={() => this.showModal(item.Custodian_Info)}
                                  ariaLabel={polyglot.t("More info")}
                                  title={polyglot.t("Info")}
                                />
                                {/* {this.Resultsof(item.details)} */}
                              </label>
                              <div className="row m-l-0 m-r-0 doc-count ">
                                <div className="col-md-4 p-0 tex-ali">
                                  <label>{customNumberFormat(item.DOCUMENT_COUNT, false)}</label>
                                </div>
                                <div className="col-md-4  tex-ali ">
                                  <label>{customNumberFormat(item.TOTAL_HIT_COUNT, false)}</label>
                                </div>
                                <div className="col-md-4 pad-le tex-ali p-l-12">
                                  <span>&#8765;</span> <label>{customNumberFormat(item.REVIEW_HOURS)}</label>
                                </div>
                              </div>

                              <div className="row m-l-0 m-r-0 mb-4">
                                <div className="col-md-4 tex-ali p-0">
                                  <label className="an-header-text4 p-0">
                                    {polyglot.t("Total document count")}
                                  </label>
                                </div>
                                <div className="col-md-4 tex-ali">
                                  <label className="an-header-text4 p-0">
                                    {polyglot.t("Document hit count")}
                                  </label>
                                </div>
                                <div className="col-md-4 tex-ali ">
                                  <label className="an-header-text4 p-0">{polyglot.t("Review hours")}</label>
                                </div>
                              </div>

                              <div
                                className="row m-l-0 m-r-0 mt-4 "
                                style={{
                                  display: this.state.showMoreDetails,
                                }}
                              >
                                <div
                                  className="col-md-3 p-0 tex-ali cursor"
                                  style={this.state.res_div}
                                  onClick={() => this.changeSelecedDiv("res")}
                                >
                                  <label className="doc-head">{polyglot.t("Responsive documents")}</label>
                                </div>
                                <div className="col-md-1  ">
                                  <div className="row m-l-0 m-r-0 row-border ">
                                    <div className="col-md-6 div-border p-0"></div>
                                    <div className="col-md-6 p-0"></div>
                                  </div>
                                </div>
                                <div
                                  className="col-md-5 p-0 tex-ali cursor"
                                  style={this.state.family_div}
                                  onClick={() => this.changeSelecedDiv("fam")}
                                >
                                  <label className="doc-head">
                                    {polyglot.t("Family Counts – responsive documents")}
                                  </label>
                                </div>
                                <div className="col-md-3"></div>
                              </div>

                              {this.state.showMoreDetails !== "" ? null : (
                                <div className="row m-l-0 m-r-0 pt-2 pb-3  ">
                                  <div className="col-md-4 tex-ali show-les">
                                    <LinkText
                                      onClick={this.showMoreDivHandler}
                                      ariaLabel={polyglot.t("Show less Details")}
                                    >
                                      {"- " + polyglot.t("Show less Details")}
                                    </LinkText>
                                  </div>
                                  <div className="col-md-4"></div>
                                  <div className="col-md-4"></div>
                                </div>
                              )}

                              <hr />

                              {this.state.res_div.borderBottom === "solid" ? (
                                <div>
                                  <div
                                    style={{
                                      display: this.state.showMoreDetails,
                                    }}
                                    aria-hidden="true"
                                    className="test_1"
                                  >
                                    <div className="row m-l-0 m-r-0 mt-3 mb-2 pl-3">
                                      <div className="col-md-1">
                                        <label className="doc-head3 ">S.No.</label>
                                      </div>
                                      <div className="col-md-3 pr-0 tex-ali ">
                                        <label className="doc-head3 ">{polyglot.t("Search Term")}</label>
                                      </div>
                                      <div className="col-md-1"> </div>
                                      <div className="col-md-5 p-0 tex-ali">
                                        <label className="doc-head3">
                                          {polyglot.t("Top level documents and attachments")}
                                        </label>
                                      </div>
                                      <div className="col-md-2"></div>
                                    </div>

                                    {item.details.map((item1, index) => {
                                      return (
                                        <div>
                                          {item.details.length !== index + 1 ? (
                                            <div className="row m-l-0 m-r-0 serch-row ">
                                              <div className="col-md-1">{index + 1}) </div>
                                              <div className="col-md-3 pr-0 tex-ali ">
                                                <label className="doc-head2 ">
                                                  {" "}
                                                  {item1.SEARCH_TERM.length <= SearchTermMaxDisplaylength
                                                    ? item1.SEARCH_TERM
                                                    : item1.SEARCH_TERM.substring(
                                                        0,
                                                        SearchTermMaxDisplaylength,
                                                      ) + "..."}
                                                </label>
                                              </div>
                                              <div className="col-md-1"> </div>
                                              <div className="col-md-5 p-0 tex-ali">
                                                <label className="doc-head2">
                                                  {customNumberFormat(item1.TOP_LEVEL_DOC_ATTACHMENTS, false)}
                                                </label>
                                              </div>
                                              <div className="col-md-2"></div>
                                            </div>
                                          ) : (
                                            <div className="row m-l-0 m-r-0 serch-row ">
                                              <div className="col-md-4 pr-0 tex-ali ">
                                                <label className="doc-head3 ">
                                                  {item1.SEARCH_TERM.length <= SearchTermMaxDisplaylength
                                                    ? item1.SEARCH_TERM
                                                    : item1.SEARCH_TERM.substring(
                                                        0,
                                                        SearchTermMaxDisplaylength,
                                                      ) + "..."}
                                                </label>
                                              </div>
                                              <div className="col-md-1"> </div>
                                              <div className="col-md-5 p-0 tex-ali">
                                                <label className="doc-head3">
                                                  {customNumberFormat(item1.TOP_LEVEL_DOC_ATTACHMENTS, false)}
                                                </label>
                                              </div>
                                              <div className="col-md-3"></div>
                                            </div>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              ) : (
                                <div>
                                  <div
                                    style={{
                                      display: this.state.showMoreDetails,
                                    }}
                                    aria-hidden="true"
                                    className="test_1"
                                  >
                                    <div className="row m-l-0 m-r-0 mt-3 mb-2 pl-3">
                                      <div className="col-md-1 tex-ali p-0">
                                        <label className="doc-head3 ">S.No.</label>
                                      </div>
                                      <div className="col-md-3 pr-0 tex-ali ">
                                        <label className="doc-head3">{polyglot.t("Search Term")}</label>
                                      </div>
                                      <div className="col-md-2 p-0 tex-ali ">
                                        <label className="doc-head3 ">{polyglot.t("Top level")}</label>
                                      </div>
                                      <div className="col-md-3 p-0 tex-ali ">
                                        <label className="doc-head3 ">
                                          {polyglot.t("Attachments (non-unique)")}
                                        </label>
                                      </div>
                                      <div className="col-md-3 p-0 tex-ali ">
                                        <label className="doc-head3 ">
                                          {polyglot.t("Top level") + "+" + polyglot.t("attachments")}
                                        </label>
                                      </div>
                                    </div>

                                    {item.details.map((item2, index) => {
                                      return (
                                        <div>
                                          {item.details.length !== index + 1 ? (
                                            <div className="row m-l-0 m-r-0 serch-row ">
                                              <div className="col-md-1 p-0 tex-ali ">
                                                <label className="doc-head2 ">{index + 1})</label>
                                              </div>
                                              <div className="col-md-3 pr-0 tex-ali ">
                                                <label className="doc-head2 ">
                                                  {item2.SEARCH_TERM.length <= SearchTermMaxDisplaylength
                                                    ? item2.SEARCH_TERM
                                                    : item2.SEARCH_TERM.substring(
                                                        0,
                                                        SearchTermMaxDisplaylength,
                                                      ) + "..."}
                                                </label>
                                              </div>
                                              <div className="col-md-2 p-0 tex-ali ">
                                                <label className="doc-head2 ">
                                                  {customNumberFormat(item2.TOP_LEVEL, false)}
                                                </label>
                                              </div>
                                              <div className="col-md-3 p-0 tex-ali ">
                                                <label className="doc-head2 ">
                                                  {customNumberFormat(item2.ATTACHMENTS_NON_UNIQUE, false)}
                                                </label>
                                              </div>
                                              <div className="col-md-3 p-0 tex-ali ">
                                                <label className="doc-head2 ">
                                                  {customNumberFormat(
                                                    item2.TOP_LEVEL + item2.ATTACHMENTS_NON_UNIQUE,
                                                    false,
                                                  )}
                                                </label>
                                              </div>
                                            </div>
                                          ) : (
                                            <div className="row m-l-0 m-r-0 serch-row total-row ">
                                              <div className="col-md-4 p-0 tex-ali ">
                                                <label className="doc-head3 ">
                                                  {item2.SEARCH_TERM.length <= SearchTermMaxDisplaylength
                                                    ? item2.SEARCH_TERM
                                                    : item2.SEARCH_TERM.substring(
                                                        0,
                                                        SearchTermMaxDisplaylength,
                                                      ) + "..."}
                                                </label>
                                              </div>
                                              <div className="col-md-2 p-0 tex-ali">
                                                <label className="doc-head3">
                                                  {customNumberFormat(item2.TOP_LEVEL, false)}
                                                </label>
                                              </div>
                                              <div className="col-md-3 p-0 tex-ali">
                                                <label className="doc-head3">
                                                  {customNumberFormat(item2.ATTACHMENTS_NON_UNIQUE, false)}
                                                </label>
                                              </div>
                                              <div className="col-md-3 p-0 tex-ali">
                                                <label className="doc-head3">
                                                  {customNumberFormat(item2.TOP_LEVEL_ATTATCHMENTS, false)}
                                                </label>
                                              </div>
                                              <div className="col-md-3"></div>
                                            </div>
                                          )}
                                        </div>
                                      );
                                    })}
                                  </div>
                                </div>
                              )}

                              {this.state.showMoreDetails !== "" ? (
                                <div className="row m-l-0 m-r-0 pt-2 pb-2">
                                  <div className="col-md-4 tex-ali show-mor pl-0">
                                    <LinkText
                                      onClick={this.showMoreDivHandler}
                                      ariaLabel={polyglot.t("Show more Details")}
                                    >
                                      {"+ " + polyglot.t("Show more Details")}
                                    </LinkText>
                                  </div>
                                  <div className="col-md-4"></div>
                                  <div className="col-md-4"></div>
                                </div>
                              ) : (
                                <div className="row m-l-0 m-r-0 pt-5 pb-2 ">
                                  <div className="col-md-4 tex-ali show-les">
                                    <LinkText
                                      onClick={this.showMoreDivHandler}
                                      ariaLabel={polyglot.t("Show less Details")}
                                    >
                                      {"- " + polyglot.t("Show less Details")}
                                    </LinkText>
                                  </div>
                                  <div className="col-md-4"></div>
                                  <div className="col-md-4"></div>
                                </div>
                              )}
                            </div>
                          </AnalysisReviewCard>
                        </div>
                      ) : null}
                    </div>
                  );
                })}
              </div>
            )}
            {/* --------------map------- */}
            <div className="row m-l-0 m-r-0 an-header-text3 tex-ali  ">
              <h1 className="mt-2 mb-2 label-styles font-22px">
                {polyglot.t(
                  "Do you want to review the data yourself or do you want PwC to conduct the review?",
                )}
              </h1>
            </div>
            <div className="row m-l-0 m-r-0  p-0 tex-ali mb-2 doc_head2 mb-1">
              <label>
                {/* {polyglot.t("The way_para_AnalysisReview")}&nbsp;
                            <span className="ter-cond" onClick={() => this.showModal("learn More")}>{polyglot.t("Learn more")}</span>
                            <br /> */}
                {polyglot.t("For PwC review support")} &nbsp;
                <LinkText onClick={this.goToneedhelp} ariaLabel={polyglot.t("click here")}>
                  {polyglot.t("click here")}
                </LinkText>
              </label>
            </div>

            <div className="row m-l-0 m-r-0 mt-1 analysis-review-button">
              <div className="col-md-4 col-lg-4 p-0  tex-ali">
                {this.state.data.map((item, index) => {
                  return (
                    <div>
                      <ButtonPrimary
                        onClick={() => this.downloadTemplate(item.filepath)}
                        ariaLabel={polyglot.t("Download report")}
                      >
                        <Tooltip
                          placement="top"
                          tooltipTheme="light"
                          content={[
                            <div className="a-h6 font-weight-normal">
                              {polyglot.t("The file is ready to be downloaded.")}
                            </div>,
                          ]}
                        >
                          {polyglot.t("Download report")}
                        </Tooltip>
                      </ButtonPrimary>
                    </div>
                  );
                })}
              </div>
              <div className="col-md-4 col-lg-4 p-0  tex-ali">
                {this.props.data.PUSHED_TO_REVIEW === 1 ? (
                  <ButtonPrimary disabled ariaLabel={polyglot.t("Push to review")}>
                    {polyglot.t("Push to review")}
                  </ButtonPrimary>
                ) : (
                  <ButtonPrimary onClick={this.pushToReview} ariaLabel={polyglot.t("Push to review")}>
                    {polyglot.t("Push to review")}
                  </ButtonPrimary>
                )}
              </div>

              <div className="col-md-4 col-lg-4 p-l-r-10  tex-ali-c">
                <OutlineButton name={polyglot.t("Reduce with AIRO")} />
              </div>
            </div>
          </div>
          <div className="col-md-4 pl-50 pr-0 analysis-review-hours">
            <div>
              <p className="para-txt">
                {polyglot.t(
                  "You can apply your keyword lists and analyse the documents within this section. On the right, you can consult and rerun the already present Analysis jobs. If you want to create a new analysis, please click on ",
                )}
                <span className="para-head">{polyglot.t("Add analysis")}.</span>
                {" " +
                  polyglot.t(
                    "To view the results of a finished analysis, please select it from the list. You may push the results to review afterwards",
                  )}
                .
              </p>
              <p className="para-txt">
                {polyglot.t("You can consult para_analysis")}
                <span className="para-head">{polyglot.t("Show more details -analysis review")}</span>
                &nbsp;
                <span className="para-txt">
                  {polyglot.t("a breakdown of all the keywords and the corresponding hit count pops up")}
                </span>
                .
              </p>

              <p className="para-txt">
                {polyglot.t("If you want to push the documents into Review, please click on ")}
                <span className="para-head">{polyglot.t("Push to review-analysisreview")}.</span>
                {" " +
                  polyglot.t(
                    "You can also ask for reducing the counts with advanced filtering and analysis technique (AIRO), by clicking on ",
                  )}
                <span className="para-head">{polyglot.t("Reduce with AIRO")}.</span>
              </p>
            </div>

            <div className="row  tex-ali  m_l_ana m-l-0 m-r-0">
              <label className="an-header-text2">{polyglot.t("Review hours")}</label>
              <br />
              <label className="doc_head2">{polyglot.t("Explanation_para_AnalysisReview")}</label>
            </div>
            <br />
            <div className="row  tex-ali mt-1 m_l_ana m-l-0 m-r-0">
              <label className="an-header-text2">{polyglot.t("What are top level documents?")}</label>
              <label className="doc_head2">{polyglot.t("Within a family_para_analysis")}</label>
            </div>
            <br />
            <div className="row  tex-ali mt-1 m_l_ana m-l-0 m-r-0">
              <label className="an-header-text2">{polyglot.t("What are descendants / attachments?")}</label>
              <label className="doc_head2">{polyglot.t("Within a family_descendants")}</label>
            </div>
          </div>
        </div>
        <CustomModal
          show={this.state.visibleTermsModel}
          onCancel={this.handleConfirm}
          title={polyglot.t("Custodians")}
        >
          <div className="row m-l-0 m-r-0 ">
            <div className="col-12 p-0">
              <div>
                <div className="flex-fill">{this.state.modelBodyContent}</div>
              </div>
            </div>
          </div>
        </CustomModal>
      </div>
    );
  }
}

export default AnalysisReview;
