import React, { useContext, useEffect } from "react";

import CompanyCenter from "./CompanyCenter.js";
import NoAccess from "../../../Assets/Components/NoAccess";
import { hasViewPermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";

// WILL RENDER ON /queenbase/companycenter/view
const CompanyCenterContainer = (props) => {
  // FETCH DATA FROM CONTEXT
  const { checkAndFetchRole, getCurrentUserPermissions } = useContext(DataContext);
  useEffect(() => {
    const screenName = "Company Center";
    checkAndFetchRole(screenName, "Base");
  }, []);
  const currentUserPermissions = getCurrentUserPermissions();
  return (
    <>
      {/* RENDER COMPONENT IF USER HAS PERMISSION TO VIEW */}
      {hasViewPermission(currentUserPermissions) && <CompanyCenter {...props} />}
      {/* SHOW 'NO ACCESS' IF USER DOES NOT HAVE PERMISSION TO VIEW */}
      {!hasViewPermission(currentUserPermissions) && <NoAccess />}
    </>
  );
};

export default CompanyCenterContainer;
