import { Input, Select, SelectOption } from "appkit-react";
import React, { useEffect, useState } from "react";
import CustodianService from "../../../../Services/DFED/CustodianService";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";
import FormSingleSelectAutoComplete from "../../Shared/components/FormSingleSelectAutoComplete";
import FormSingleSelectWithDescription from "../../Shared/components/FormSingleSelectWithDescription";

const nonRequiredKeys = ["Salutation", "Middle Name", "Email Address", "General Notes"];

export const CustodianRow = ({
  initialCustodian,
  selectedProjectId,
  prefixes,
  classifications,
  positions,
  countries,
  custodian,
  setCustodian
}) => {
  const polyglot = polyglotLoader();
  // const [custodian, setCustodian] = useState(initialCustodian);
  const [errors, setErrors] = useState({
    ["First Name"]: initialCustodian["First Name"] ? null : polyglot.t("required_field"),
    ["Last Name"]: initialCustodian["Last Name"] ? null : polyglot.t("required_field"),
    ["Position"]: initialCustodian["Position"] ? null : polyglot.t("required_field"),
    ["Alias"]: initialCustodian["Alias"] ? null : polyglot.t("required_field"),
    ["Country"]: initialCustodian["Country"] ? null : polyglot.t("required_field"),
    ["Entity"]: initialCustodian["Entity"] ? null : polyglot.t("required_field"),
    ["Department"]: initialCustodian["Department"] ? null : polyglot.t("required_field"),
    ["Alias"]: initialCustodian["Alias"] ? null : polyglot.t("required_field"),
    ["Classification"]: initialCustodian["Classification"] ? null : polyglot.t("required_field"),
  });
  const [isAliasUnique, setIsAliasUnique] = useState(true);

  const handleChange = (value, key) => {
    if (value) {
      setCustodian({ ...custodian, [key]: value });
      setErrors({ ...errors, [key]: null });
    } else {
      setCustodian({ ...custodian, [key]: value });
      setErrors({ ...errors, [key]: nonRequiredKeys.includes(key) ? null : polyglot.t("required_field") });
    }

    if (key === "Alias") {
      void checkIsAliasUnique(value);
    }
  };

  const checkIsAliasUnique = async (currentAlias) => {
    const custodianService = new CustodianService();

    const allCustodians = await custodianService.getCustodianData(selectedProjectId);
    let custodiansList = allCustodians.data;

    setCustodian({ ...custodian, ["Alias"]: currentAlias, isAliasUnique: !custodiansList.some(({ CRT_ALIAS: custodianAlias }) => custodianAlias === currentAlias) });

    setIsAliasUnique(
      !custodiansList.some(({ CRT_ALIAS: custodianAlias }) => custodianAlias === currentAlias),
    );
  };

  useEffect(() => {
    if (selectedProjectId) checkIsAliasUnique(custodian["Alias"]);
    Object.keys(custodian).map((key) => {
      if (!custodian[key])
        setErrors({ ...errors, [key]: nonRequiredKeys.includes(key) ? null : polyglot.t("required_field") });
    });
  }, [selectedProjectId]);

  return (
    <tr>
      <td>
        {prefixes.length > 0 && (
          <Select
            value={
              prefixes.find((el) => el.name === custodian["Salutation"])
                ? prefixes.find((el) => el.name === custodian["Salutation"]).id.toString()
                : custodian["Salutation"]
            }
            id="salutation"
            name="CRT_PREFIX_ID"
            onSelect={(e) => handleChange(e, "Salutation")}
          >
            {prefixes.map((prefix) => {
              return (
                <SelectOption key={prefix.id} value={Number(prefix.id).toString()}>
                  {polyglot.t(prefix.name)}
                </SelectOption>
              );
            })}
          </Select>
        )}
      </td>
      <td>
        <Input value={custodian["First Name"]} onChange={(e) => handleChange(e, "First Name")} />
        {errors["First Name"] ? (
          <p className="txt-field-error m-0 " aria-label={errors["First Name"]}>
            {errors["First Name"]}
          </p>
        ) : null}
      </td>
      <td>
        <Input value={custodian["Middle Name"]} onChange={(e) => handleChange(e, "Middle Name")} />
      </td>
      <td>
        <Input value={custodian["Last Name"]} onChange={(e) => handleChange(e, "Last Name")} />
        {errors["Last Name"] ? (
          <p className="txt-field-error m-0 " aria-label={errors["Last Name"]}>
            {errors["Last Name"]}
          </p>
        ) : null}
      </td>
      <td>
        <Input value={custodian["Email Address"]} onChange={(e) => handleChange(e, "Email Address")} />
      </td>
      <td>
        <Input value={custodian["Alias"]} onChange={(e) => handleChange(e, "Alias")} />
        {!isAliasUnique ? (
          <p className="txt-field-error m-0 " aria-label={polyglot.t("unique_alias")}>
            {polyglot.t("unique_alias")}
          </p>
        ) : null}
        {isAliasUnique && errors["Alias"] ? (
          <p className="txt-field-error m-0 " aria-label={polyglot.t("Alias")}>
            {errors["Alias"]}
          </p>
        ) : null}
      </td>
      <td style={{ width: "150px" }}>
        {positions.length > 0 && (
          <FormSingleSelectAutoComplete
            setPositionValue
            fillKey="positionData"
            preFillSelectWithDescription={{
              positionData: [
                positions.find((el) => el.value === custodian["Position"])
                  ? positions.find((el) => el.value === custodian["Position"]).value
                  : custodian["Position"],
              ],
            }}
            name="CRT_POSITION_ID"
            options={positions}
            onChangeFormField={(_, value) => handleChange(value, "Position")}
            isClearable={false}
          />
        )}
        {errors["Position"] ? (
          <p className="txt-field-error m-0 " aria-label={errors["Position"]}>
            {errors["Position"]}
          </p>
        ) : null}
      </td>
      <td style={{ width: "150px" }}>
        {countries.length > 0 && (
          <FormSingleSelectAutoComplete
            fillKey="countryData"
            preFillSelectWithDescription={{
              countryData: [
                countries.find((el) => el.label === custodian["Country"])
                  ? countries.find((el) => el.label === custodian["Country"])
                  : custodian["Country"],
              ],
            }}
            name="CRT_COUNTRY_ID"
            options={countries}
            onChangeFormField={(_, value) => handleChange(value, "Country")}
            isClearable={false}
          />
        )}
        {errors["Country"] ? (
          <p className="txt-field-error m-0 " aria-label={errors["Country"]}>
            {errors["Country"]}
          </p>
        ) : null}
      </td>
      <td style={{ width: "100px" }}>
        <Input value={custodian["Entity"]} onChange={(e) => handleChange(e, "Entity")} />
        {errors["Entity"] ? (
          <p className="txt-field-error m-0 " aria-label={errors["Entity"]}>
            {errors["Entity"]}
          </p>
        ) : null}
      </td>
      <td style={{ width: "100px" }}>
        <Input value={custodian["Department"]} onChange={(e) => handleChange(e, "Department")} />
        {errors["Department"] ? (
          <p className="txt-field-error m-0 " aria-label={errors["Department"]}>
            {errors["Department"]}
          </p>
        ) : null}
      </td>
      <td style={{ width: "150px" }}>
        {classifications.length > 0 && (
          <FormSingleSelectWithDescription
            id="classifications"
            preFillSelectWithDescription={{
              classificationData: classifications.find((el) => el.label === custodian["Classification"])
                ? classifications.find((el) => el.label === custodian["Classification"])
                : custodian["Classification"],
            }}
            name="CRT_CLASSIFICATION_ID"
            onChangeFormField={(_, value) => handleChange(value, "Classification")}
            classificationOptions={classifications}
            hideLabel
          />
        )}
        {errors["Classification"] ? (
          <p className="txt-field-error m-0 " aria-label={errors["Classification"]}>
            {errors["Classification"]}
          </p>
        ) : null}
      </td>
      <td style={{ width: "100px" }}>
        <Input value={custodian["General Notes"]} onChange={(e) => handleChange(e, "General Notes")} />
      </td>
    </tr>
  );
};
