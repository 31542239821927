import React, { Component } from "react";
import { Accordions, AccordionItem, AccordionItemHeader, AccordionItemBody, Tooltip } from "appkit-react";
import { Collapse, Progress } from "antd";
import { Pagination } from "antd";
import $ from "jquery";

import CustodianProcessDetails from "../../Services/DFED/HomeService";
import { polyglotLoader } from "../../Services/Shared/Translate";
import { DataContext } from "../../Services/Shared/Store";
import {
  getProjectID_Of_solution_module,
  getProjectName_Of_solution_module,
} from "../../Services/Shared/Handlers";
import LoadingIndicator from "../QueenBase/Root/LoadingIndicator";
import * as module_Solution_Name from "../../Constants/moduleName";
import commonFunctionForCallService from "../../utility/commonFunctionForCallService";

import { infoIcon } from "../../Assets/Icons";
import note from "../../Assets/Icons/cogs-outline.svg";
import analysis from "../../Assets/Icons/search-outline.svg";
import review from "../../Assets/Icons/workflow-outline.svg";
import NoAccess from "../../Assets/Components/NoAccess";
import ButtonPrimary from "../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../Assets/Components/ButtonSecondary/ButtonSecondary";
import ButtonIcon from "../../Assets/Components/ButtonIcon/ButtonIcon";

import "../../Assets/Css/DFED/HomeComponent.css";
import "antd/dist/antd.css";

const { Panel } = Collapse;
const custodianProcessDetails = new CustodianProcessDetails();
// THIS CLASS IS FOR HANDLING ALL HOME COMPONENT RELATED STUFF LIKE SHOWING ALL CUSTODINAN LIST, NUMBER PF PROCESSING REQUEST.

class HomeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isCustodianProcessDataIsExists: false,
      custodianProcessData: [],
      onChangeProjectId: getProjectID_Of_solution_module(),
      expandCollapseKey: [],
      currentPage: 1,
      tempStateProcessData: [],
      analysisCount: 0,
      processingCount: 0,
      reviewCount: 0,
      processingProgressCount: 0,
      processingCompletedCount: 0,
      processingNotStartedCount: 0,
      keywordCount: 0,
      analysisProgressCount: 0,
      analysisCompletedCount: 0,
      analysisNotStartedCount: 0,
      reviewProgressCount: 0,
      reviewCompletedCount: 0,
      reviewNotStartedCount: 0,
      loadingData: true,
      selectedProjectName: getProjectName_Of_solution_module(),
      rolePermission: {},
      rolePermissionForFastForWard: {},
      isViewPermission: true,
    };
    this.expandCollpaseHandler = this.expandCollpaseHandler.bind(this);
  }
  static contextType = DataContext;
  //  NAVIGATE TO CUSTODIAN PAGE=======================
  NavigateTab = () => {
    this.props.history.push({
      pathname: "/dfed/custodian",
      state: {
        selectedProjectId: this.state.onChangeProjectId,
        selectedProjectName: this.state.selectedProjectName,
      },
    });
  };
  // REDIRECTING TO ASK FR SUPPORT TAB
  goToAskForSupport = () => {
    this.props.history.push({
      pathname: "/dfed/support",
      state: { selectedProjectId: this.state.onChangeProjectId },
    });
    localStorage.setItem("previousComponent", "Home");
  };
  /* LIFE CYCLE METHOD FOR FETCHING CUSTODIAN DATA AND APPLICATION ROLES FOR HOME COMPONENT */
  componentDidMount = () => {
    if (this.state.onChangeProjectId) {
      this.getCustodianData(this.state.onChangeProjectId);
      this.getData(this.state.onChangeProjectId);
      this.getApplicationRolesWithPermission();
    }
  };
  //  GETTING HOMEPAGE DATA FROM API

  async getData(selectedProjectId) {
    const getKeyWordsCount = await custodianProcessDetails.getProcessingStatusCount(selectedProjectId);
    if (getKeyWordsCount && getKeyWordsCount.status === 200) {
      if (getKeyWordsCount.data.length > 0) {
        const { total, progress, completed, notstarted } = getKeyWordsCount.data[0];
        this.setState({
          processingCount: total,
          processingProgressCount: progress,
          processingCompletedCount: completed,
          processingNotStartedCount: notstarted,
        });
      }
    }
    const getAnalysisCount = await custodianProcessDetails.getAnalysisStatusCount(selectedProjectId);
    if (getAnalysisCount && getAnalysisCount.data) {
      const { TOTAL, progress, Completed, notstarted } = getAnalysisCount.data[0];
      this.setState({
        analysisCount: TOTAL,
        analysisProgressCount: progress,
        analysisCompletedCount: Completed,
        analysisNotStartedCount: notstarted,
      });
    }
    const getReviewCount = await custodianProcessDetails.getReviewStatusCount(selectedProjectId);
    if (getReviewCount && getReviewCount.data) {
      const { total, progress, completed, notstarted } = getReviewCount.data[0];
      this.setState({
        reviewCount: total,
        reviewProgressCount: progress,
        reviewCompletedCount: completed,
        reviewNotStartedCount: notstarted,
      });
    }
  }
  // GETTING CUSTODIAN DATA

  getCustodianData = async (selectedProjectId) => {
    await custodianProcessDetails
      .custodianProcessSummary(selectedProjectId)
      .then((custodianData) => {
        if (custodianData) {
          this.setState({ loadingData: false, expandCollapseKey: [] }, () => {
            if (custodianData.data.length > 0) {
              this.custodainProcessDataFilter(custodianData.data);
            }
          });
        }
      })
      .catch((err) => {
        if (err && err.status === 403) {
          this.setState({
            loadingData: false,
            isViewPermission: false,
          });
        }
      });
  };
  // GETTING APPLICATION ROLES===============
  getApplicationRolesWithPermission = async () => {
    let selectedProjectId = this.state.onChangeProjectId;
    let permissionData = await commonFunctionForCallService.commonFunctionForCallService(
      module_Solution_Name.DFED_SOLUTION,
      selectedProjectId,
      module_Solution_Name.HOME_MODULE,
    );
    if (permissionData) {
      this.setState({
        rolePermission: permissionData,
      });
    }
  };

  // FILTERING PROCESSED DATA FROM ALL PROCESSING DATA================
  custodainProcessDataFilter = (custodianData) => {
    let groupBy = (array, key) => {
      return array.reduce((result, currentValue) => {
        (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
        return result;
      }, {});
    };
    let custodianGroupBy = groupBy(custodianData, "CRT_ID");
    var custodianArr = Object.keys(custodianGroupBy).map(function (key) {
      return [Number(key), custodianGroupBy[key]];
    });
    this.setState({ custodianProcessData: custodianArr, tempStateProcessData: custodianArr }, () => {
      this.visiableDataHandler(1);
    });
  };

  // EXPANDINGA ND COLLAPSEING BASED ON CONDITION

  expandCollpaseHandler(e) {
    if (this.state.expandCollapseKey[0] === e) {
      this.setState({ expandCollapseKey: [] });
    } else {
      this.setState({ expandCollapseKey: [e] });
    }
  }
  // PAGINATION PAGE HANDLER FUNCTION WHEN CLICK ON ANY PAGE NUMBER===================
  onChangeTablePageHandler = (page) => {
    this.setState({ currentPage: page });
    this.visiableDataHandler(page);
  };

  // FUNCTINALITY FOR HANDELING PAGINATION
  visiableDataHandler(page) {
    let lastIndex = 7 * page;
    let firstIndex = lastIndex - 7;
    let tempCustodianProcessData = JSON.parse(JSON.stringify(this.state.tempStateProcessData));
    let visData = tempCustodianProcessData.slice(firstIndex, lastIndex);
    this.setState({ custodianProcessData: visData });
  }
  // RENDER METHOD FOR HANDELING ALL UI RENDERING FOR HOME COMPONENT

  // DISPLAY NAME ON TOOLTIP
  TooltipText = (name) => {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
                {name}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  render() {
    // CREATING POLYGLOT OBJECT FOR LANGUAGE TRANSLATION===============
    var polyglot = polyglotLoader();
    // PROGRESSBAR FUNCTION FOR SHOWING PROGRESS CHART============
    const progressBar = (uploadStatus, prep, gen, run) => {
      const getStatusTemp = (type) => {
        let statusTempVariable = 0;

        switch (type) {
          case "Green":
            statusTempVariable = 3;
            break;
          case "Red":
            statusTempVariable = 2;
            break;
          case "Grey":
            statusTempVariable = 1;
            break;
          default:
            statusTempVariable = 1;
            break;
        }

        return statusTempVariable;
      };

      const uploadStatusTemp = getStatusTemp(uploadStatus);
      const processingStatusTemp = getStatusTemp(prep);
      const anaStatusTemp = getStatusTemp(gen);
      const revStatusTemp = getStatusTemp(run);

      let res = ["", "", "inprogress", "success"];
      return (
        <ul className="progressbar2 mt-4">
          <li className={res[uploadStatusTemp]}></li>
          <li className={res[processingStatusTemp]}></li>
          <li className={res[anaStatusTemp]}></li>
          <li className={res[revStatusTemp]}></li>
        </ul>
      );
    };
    // HOME COMPONENT OBJECT THIS WILL RENDER HOME PAGE RELATED DATA=====================
    var homeComponent = (
      <div className="row ml-0 m-r-0">
        {this.state.loadingData === true && <LoadingIndicator></LoadingIndicator>}

        {this.state.loadingData === false &&
        this.state.custodianProcessData &&
        this.state.custodianProcessData.length > 0 ? (
          <div className="col-md-8 p-0 home-collapse">
            <div>
              <div className="row m-l-0 m-r-0">
                {this.state.custodianProcessData && this.state.custodianProcessData.length > 1 && (
                  <span className="homeCustodianHeader m-t-fraction">
                    {this.state.tempStateProcessData.length + " Custodians"}{" "}
                  </span>
                )}
                {this.state.custodianProcessData && this.state.custodianProcessData.length === 1 && (
                  <span className="homeCustodianHeader m-t-fraction">
                    {this.state.custodianProcessData.length + " Custodian"}{" "}
                  </span>
                )}
                <div className="col-md-12 p-l-0 dfed-expand-list-cards">
                  <Accordions activeItem={this.state.expandCollapseKey} onChange={this.expandCollpaseHandler}>
                    {this.state.custodianProcessData &&
                      this.state.custodianProcessData.map((value, key) => {
                        const custodianInfo = value[1][0];
                        const isDatasetNameAvailable = custodianInfo.DT_DATASET_NAME ? true : false;
                        const tempCustodianName = custodianInfo.CRT_ALIAS;
                        return (
                          <AccordionItem itemId={key} key={key} className="mb-2">
                            <AccordionItemHeader
                              title={
                                <>
                                  {isDatasetNameAvailable === false ? (
                                    <Tooltip
                                      content={this.TooltipText(polyglot.t("No datasets"))}
                                      placement="top"
                                    >
                                      {tempCustodianName}

                                      <ButtonIcon
                                        className=" dfedCustodian"
                                        iconName={infoIcon}
                                        alt="info"
                                        ariaLabel={this.TooltipText("No datasets")}
                                      />
                                    </Tooltip>
                                  ) : (
                                    tempCustodianName
                                  )}
                                </>
                              }
                              className={`${!isDatasetNameAvailable && "no-pointer-dfedhome-accordian"}`}
                            />

                            <AccordionItemBody
                              className={`${!isDatasetNameAvailable && "display-none-dfed-home-accordian"}`}
                            >
                              <div className="row m-l-0 m-r-0  home-overflow-div" aria-hidden="true">
                                <div className="col-md-12 ">
                                  {value[1] &&
                                    value[1].map((childVal, childKey) => {
                                      const {
                                        Number_uploadStatus_colour,
                                        Number_PROCESSING_STATUS_colour,
                                        Number_ANALYSISStatus_colour,
                                        Number_ReviewSumCrtBtchStatus_colour,
                                      } = childVal;
                                      return (
                                        <div>
                                          <div className="row childDatasetFont m-l-0 m-r-0">
                                            <div className="col-md-5 mt-4 txt-overflow">
                                              {childKey === 0 ? (
                                                <div>
                                                  <p className="datasetInnerText innerPanelHeader m-b-n-6">
                                                    {polyglot.t("Dataset Name")}
                                                  </p>
                                                </div>
                                              ) : (
                                                ""
                                              )}
                                              <span className="datasetInnerText">
                                                {childVal.DT_DATASET_NAME}
                                              </span>
                                            </div>
                                            <div className="col-md-7 bubbleBox">
                                              {childKey === 0 ? (
                                                <>
                                                  <div className="mt-4">
                                                    <div className="Progress-tot-txt">
                                                      <span className="datasetInnerText innerPanelHeader2">
                                                        {polyglot.t("Upload")}
                                                      </span>
                                                      <span className="datasetInnerText innerPanelHeader2">
                                                        {polyglot.t("Process")}
                                                      </span>
                                                      <span className="datasetInnerText innerPanelHeader2">
                                                        {polyglot.t("Analysis")}
                                                      </span>
                                                      <span className="datasetInnerText innerPanelHeader2">
                                                        {polyglot.t("Review")}
                                                      </span>
                                                    </div>
                                                  </div>
                                                </>
                                              ) : (
                                                ""
                                              )}
                                              {progressBar(
                                                Number_uploadStatus_colour,
                                                Number_PROCESSING_STATUS_colour,
                                                Number_ANALYSISStatus_colour,
                                                Number_ReviewSumCrtBtchStatus_colour,
                                              )}
                                            </div>
                                            <div></div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </div>
                              </div>
                            </AccordionItemBody>
                          </AccordionItem>
                        );
                      })}
                  </Accordions>
                </div>
              </div>
              <div className="row m-t-16 m-l-n-1 m-r-0">
                {this.state.tempStateProcessData && this.state.tempStateProcessData.length > 7 && (
                  <Pagination
                    current={this.state.currentPage}
                    size="small"
                    pageSize={7}
                    total={this.state.tempStateProcessData.length}
                    onChange={this.onChangeTablePageHandler}
                  />
                )}
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* --------------set 2------------------------------------ */}

        {!(this.state.custodianProcessData.length <= 0) ? (
          <div className="col-md-4 home-summary pl-0">
            <div>
              <h5 className="homeCustodianHeader" aria-label={polyglot.t("Summary")}>
                {polyglot.t("Summary")}
              </h5>
              <div className="DF-Details-One">
                <span className="DF-user-img" aria-label={polyglot.t("Processing")}>
                  <img src={note} alt="user" className="icon-card" />
                  <h5 className="DF-User-data">{polyglot.t("Processing")}</h5>
                </span>
                <p className="DF-sub-Total">
                  <span className="DF-Tot-txt" aria-label={polyglot.t("Total runs")}>
                    {polyglot.t("Total runs")}:
                  </span>
                  <span className="DF-Tot-txt appPrimaryColor">{this.state.processingCount}</span>
                </p>
                <hr className="divider-two" />
                <div className="DF-process-analysis">
                  <div className="DF-Processing">
                    <Progress
                      type="circle"
                      percent={Math.round(
                        (this.state.processingNotStartedCount * 100) / this.state.processingCount,
                      )}
                      width={80}
                      strokeColor="#afafaf"
                    />
                    <p className="DF-Processing-txt">{polyglot.t("Not started")}</p>
                  </div>
                  <div className="DF-Processing">
                    <Progress
                      type="circle"
                      percent={Math.round(
                        (this.state.processingProgressCount * 100) / this.state.processingCount,
                      )}
                      width={80}
                      strokeColor={"orange"}
                    />
                    <p className="DF-Processing-txt">{polyglot.t("In progress")}</p>
                  </div>

                  <div className="DF-analysis">
                    <Progress
                      type="circle"
                      percent={Math.round(
                        (this.state.processingCompletedCount * 100) / this.state.processingCount,
                      )}
                      width={80}
                      strokeColor={"#175c2c"}
                    />
                    <p className="DF-Processing-txt">{polyglot.t("Completed")}</p>
                  </div>
                </div>
              </div>
              <div className="DF-Details-One">
                <span className="DF-user-img">
                  <img src={analysis} alt="user" className="icon-card" />
                  <h5 className="DF-User-data"> {polyglot.t("Analysis")}</h5>
                </span>
                <p className="DF-sub-Total">
                  <span className="DF-Tot-txt">{polyglot.t("Total runs")}:</span>
                  <span className="DF-Tot-txt appPrimaryColor">{this.state.analysisCount}</span>
                </p>
                <hr className="divider-two" />
                <div className="DF-process-analysis">
                  <div className="DF-Processing">
                    <Progress
                      type="circle"
                      percent={Math.round(
                        (this.state.analysisNotStartedCount * 100) / this.state.analysisCount,
                      )}
                      width={80}
                      strokeColor="#afafaf"
                    />
                    <p className="DF-Processing-txt">{polyglot.t("Not started")}</p>
                  </div>

                  <div className="DF-Processing">
                    <Progress
                      type="circle"
                      percent={Math.round(
                        (this.state.analysisProgressCount * 100) / this.state.analysisCount,
                      )}
                      width={80}
                      strokeColor={"orange"}
                    />
                    <p className="DF-Processing-txt">{polyglot.t("In progress")}</p>
                  </div>

                  <div className="DF-analysis">
                    <Progress
                      type="circle"
                      percent={Math.round(
                        (this.state.analysisCompletedCount * 100) / this.state.analysisCount,
                      )}
                      width={80}
                      strokeColor={"#175c2c"}
                    />
                    <p className="DF-Processing-txt">{polyglot.t("Completed")}</p>
                  </div>
                </div>
              </div>

              <div className="DF-Details-One">
                <span className="DF-user-img">
                  <img src={review} alt="user" className="icon-card" />
                  <h5 className="DF-User-data"> {polyglot.t("Review")}</h5>
                </span>
                <p className="DF-sub-Total">
                  <span className="DF-Tot-txt">{polyglot.t("Total runs")}:</span>
                  <span className="DF-Tot-txt appPrimaryColor">{this.state.reviewCount}</span>
                </p>
                <hr className="divider-two" />
                <div className="DF-process-analysis">
                  <div className="DF-Processing">
                    <Progress
                      type="circle"
                      percent={Math.round((this.state.reviewNotStartedCount * 100) / this.state.reviewCount)}
                      width={80}
                      strokeColor="#afafaf"
                    />
                    <p className="DF-Processing-txt">{polyglot.t("Not started")}</p>
                  </div>

                  <div className="DF-Processing">
                    <Progress
                      type="circle"
                      percent={Math.round((this.state.reviewProgressCount * 100) / this.state.reviewCount)}
                      width={80}
                      strokeColor={"orange"}
                    />
                    <p className="DF-Processing-txt">{polyglot.t("In progress")}</p>
                  </div>

                  <div className="DF-analysis">
                    <Progress
                      type="circle"
                      percent={Math.round((this.state.reviewCompletedCount * 100) / this.state.reviewCount)}
                      width={80}
                      strokeColor={"#175c2c"}
                    />
                    <p className="DF-Processing-txt">{polyglot.t("Completed")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        {/* --------------set 2------------------------------------ */}

        {/* --------------set 1------------------------------------ */}

        {this.state.loadingData === false && this.state.custodianProcessData.length <= 0 ? (
          <div style={{ textAlign: "center", justifyContent: "center" }}>
            <div className="row m-r-0 m-l-0" style={{ textAlign: "center", justifyContent: "center" }}>
              <div className="col-md-3"></div>
              <div className="col-md-6">&nbsp;</div>
              <div className="col-md-3"></div>
            </div>
            <div className="row m-l-0 m-r-0">&nbsp;</div>
            <div className="row titleText pt-2 m-l-0 m-r-0">
              <div className="col-md-3"></div>
              <div className="col-md-6">
                <h1 className="label-styles font-22px">{polyglot.t("Find fraud in your Project")}</h1>
              </div>
              <div className="col-md-3"></div>
            </div>
            <div className="row m-l-0 m-r-0">
              <div className="col-md-2"></div>
              <div className="col-md-8">
                <div className="m-0 description-text">{polyglot.t("create custodians_para_home")}</div>
              </div>
              <div className="col-md-2"></div>
            </div>
            <div className="row m-l-0 m-r-0">&nbsp;</div>
            <div className="row m-l-0 m-r-0">
              <div className="col-md-2"></div>
              <div className="col-md-4">
                <ButtonPrimary onClick={this.NavigateTab} ariaLabel={polyglot.t("Start your investigation")}>
                  {polyglot.t("Start your investigation")}
                </ButtonPrimary>
              </div>
              <div className="col-md-4" id="askForSupport">
                <ButtonSecondary onClick={this.goToAskForSupport} ariaLabel={polyglot.t("Ask for support")}>
                  {polyglot.t("Ask for support")}
                </ButtonSecondary>
              </div>
              <div className="col-md-2"></div>
            </div>
            <div className="row m-l-0 m-r-0 ">
              <div className="col-md-6"></div>
              <div className="col-md-4">
                <p className="paraDesc" style={{ width: `${$("#askForSupport button").width()}px` }}>
                  {polyglot.t("Request assistance_para_Home")}
                </p>
              </div>
              <div className="col-md-2"></div>
            </div>
          </div>
        ) : (
          <div></div>
        )}
        {/* --------------set 1------------------------------------ */}
      </div>
    );
    if (this.state.isViewPermission === false) {
      return <NoAccess />;
    } else {
      return homeComponent;
    }
  }
}
export default HomeComponent;
