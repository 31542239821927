import { Panel } from "appkit-react";
import React from "react";
import { useHistory } from "react-router-dom";

import ButtonCTA from "../../../Assets/Components/ButtonCTA/ButtonCTA";
import ButtonSecondaryWithIcon from "../../../Assets/Components/ButtonSecondary/ButtonSecondaryWithIcon";
import { backCircle, downloadImg } from "../../../Assets/Icons";
import ProjectSurveyService from "../../../Services/DF/ProjectSurveyService";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import Xtractor from "../shared/Xtractor";

import "../../../Assets/Css/DF/RequestParameterFile.css";
import { upperFirst } from "lodash";

// RUNS ON CLICKING ON PROCEED ON A WORKFLOW
const DownloadParameterFile = (props) => {
  const history = useHistory();
  var polyglot = polyglotLoader();
  const { next, nextPage, data } = props;
  const { workflow_id, project_id, workflow_name } = data;

  // DOWNLOAD PARAMETER FILE
  const downloadParamFile = async () => {
    try {
      // API TO DOWNLOAD A PARAMETER FILE OF A WORKFLOW
      const downloadResponse = await ProjectSurveyService.get().downloadParameterFile(
        workflow_id,
        project_id,
      );
      if (downloadResponse.CODE) {
        const buffer = Buffer.from(downloadResponse.file.buffer);
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", downloadResponse.file.fileName);
        document.body.appendChild(link);
        link.click();
        displayToastMessage(downloadResponse.MSG, "success");
        document.body.removeChild(link);
      } else {
        if (
          downloadResponse.MSG.name === "RequestError" ||
          downloadResponse.MSG.errno === "undefined" ||
          downloadResponse.MSG.errno === -4058 ||
          downloadResponse.MSG.number === 8114
        ) {
          displayToastMessage(`${polyglot.t("File not found")}!!`, "error");
        } else {
          displayToastMessage(downloadResponse.MSG, "error");
        }
      }
    } catch (error) {
      if (
        (error.message = "First argument must be a string, Buffer, ArrayBuffer, Array, or array-like object.")
      ) {
        displayToastMessage(`${polyglot.t("File not found")}!!`, "error");
      } else {
        displayToastMessage(error.message, "error");
      }
    }
    return "";
  };
  // DISPLAY DISCRIPTION
  const displayDiscription = () => {
    return polyglot.t(
      "Download your parameter file to implement it in the Xtractor. Xtractor then downloads your relevant files as a ZIP file, which you have to upload in the next step.",
    );
  };
  // go back
  const goBack = () => {
    history.goBack();
  };
  // DISPLAY DOWNLOAD OPTION TO DOWNLOAD PARAMETER FILE
  const displayDownloadOption = () => {
    return (
      <div className="f-acc-B ">
        <p className="row">
          <span>{polyglot.t("Your parameter file is ready")}</span>
          <span>
            <span className="appkiticon icon-check-mark-fill pointer" style={{ padding: "10px" }}></span>
          </span>
        </p>
        <div className="mt-4 row ">
          <ButtonSecondaryWithIcon iconName={backCircle} onClick={goBack} ariaLabel={polyglot.t("Back")}>
            {polyglot.t("Back")}
          </ButtonSecondaryWithIcon>
          {/* <div className="emptyDiv"></div> */}
          &nbsp;
          <ButtonCTA
            onClick={downloadParamFile}
            ariaLabel={polyglot.t("Download parameter file")}
            className="border-1px parameterFile"
          >
            <img src={downloadImg} alt="download" className="mr-2" />
            {polyglot.t("Download parameter file")}
          </ButtonCTA>
        </div>
      </div>
    );
  };

  return (
    <div className="row mt-3 mr-0">
      <div className="col-md-6 mb-2">
        <Panel className="padding40">
          {/* .replace(/\s/g, '') */}
          <div className="downloadPrmtrMdlHdn row">
            {polyglot.t("Request parameter file")} for {upperFirst(workflow_name)}
          </div>
          {/* <h1 className="subtitle_title_qb">{polyglot.t('Request parameter file')}</h1> */}
          <div>
            <div className="f-acc-B row">{displayDiscription()}</div>
          </div>
          {displayDownloadOption()}
        </Panel>
      </div>
      <div className="col-md-2 p-0"></div>
      <div className="col-md-4">
        <Xtractor />
      </div>
    </div>
  );
};

export default DownloadParameterFile;
