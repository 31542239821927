import React, { Component } from "react";

import "../../DFED/dropzone/Dropzone.css";
import copyImg from "../../../Assets/Icons/duplicate-outline.svg";
import fileImg from "../../../Assets/Icons/file-outline.svg";
import { polyglotLoader } from "../../../Services/Shared/Translate";

class DropzonePdf extends Component {
  constructor(props) {
    super(props);
    this.state = { hightlight: false };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }

  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array, evt);
    }
  }

  onDragOver(event) {
    event.preventDefault();
    if (this.props.disabed) return;
    this.setState({ hightlight: true });
  }

  onDragLeave(event) {
    this.setState({ hightlight: false });
  }

  onDrop(event) {
    event.preventDefault();
    if (this.props.disabed) return;
    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }

  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  render() {
    const polyglot = polyglotLoader();
    const { customDropZoneBoxStyle } = this.props;
    return (
      <button
        className={`dropZoneBox Dropzone cursor ${this.state.hightlight ? "Highlight" : ""}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        onClick={this.openFileDialog}
        style={!customDropZoneBoxStyle ? {} : customDropZoneBoxStyle}
      >
        <input
          ref={this.fileInputRef}
          className="FileInput"
          type="file"
          accept=".pdf"
          onChange={this.onFilesAdded}
        />
        <span>
          <img className="fileUploadTextRed" src={fileImg} alt="" />
          &nbsp;
          <span className="fileUploadTextRed">{polyglot.t("Select your files_dropzone")}</span>
          &nbsp;&nbsp;&nbsp;<span className="font-or">{polyglot.t("or_dropzone")}</span>
          &nbsp;&nbsp;&nbsp;
          <img className="fileUploadTextRed" src={copyImg} alt="" width={20} height={20} />
          &nbsp;
          <span className="drop-file">{polyglot.t("dragAndDrop_dropzone")}</span>
        </span>
      </button>
    );
  }
}

export default DropzonePdf;
