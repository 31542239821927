import React, { Component } from "react";
import img from "../../../Assets/Images/DFED/image-data-production.svg";
import "../../../Assets/Css/DFED/HomeComponent.css";
import "../../../Components/DFED/Review/Review.css";
import "../../DFED/Review/productionFiles.css";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import ProductionTypes from "../Review/ProductionTypes";
import ButtonPrimaryExpandable from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryExpandable";
export class ProduceFiles extends Component {
  constructor(props) {
    super();
    this.state = {
      redirectToNextPage: false,
      selectedProjectId: props.selectedProjectId,
      selectedProjectName: props.selectedProjectName,
      redirectToProductionTypes: false,
      showBackButton: props.showBackButton ? props.showBackButton : null,
    };
  }
  static contextType = DataContext;
  requestDocument = async () => {
    this.setState({
      redirectToNextPage: true,
    });
  };
  render() {
    var polyglot = polyglotLoader();
    const set1 = (
      <div className="row m-l-0 m-r-0">
        <div className="col-md-4 col-lg-3 p-0">
          <ButtonPrimaryExpandable
            onClick={this.requestDocument}
            ariaLabel={polyglot.t("Request document production")}
          >
            {polyglot.t("Request document production")}
          </ButtonPrimaryExpandable>
        </div>
        <div className=" col-md-12  div-main">
          <div className="row m-l-0 m-r-0"></div>
          <div className="row titleText pt-2">
            <div className="col-md-2"></div>
            <div className="col-md-8">
              <h1 className="Production-title-txt" aria-label={polyglot.t("Request document production")}>
                {polyglot.t("Request document production")}
              </h1>
            </div>
            <div className="col-md-2"></div>
          </div>
          <div className="row ">
            <div className="col-md-4"></div>
            <div className="col-md-4">
              <div class="sub-title">
                {polyglot.t(
                  "Map your processed datasets with your generated keyword lists and start an analysis",
                )}
                .
              </div>
            </div>
            <div className="col-md-4"></div>
          </div>
        </div>
      </div>
    );
    const set2 = (
      <ProductionTypes
        selectedProjectId={this.state.selectedProjectId}
        selectedProjectName={this.state.selectedProjectName}
        showBackButton={this.state.showBackButton}
        newProps={this.props}
      />
    );
    var newset;
    if (this.state.redirectToNextPage) {
      newset = set2;
    } else {
      newset = set1;
    }
    return newset;
  }
}
export default ProduceFiles;
