import { Button } from "appkit-react";
import PropTypes from "prop-types";
import React from "react";

const ButtonCTA = (props) => {
  const {
    size, // size can be 'sm', 'md', 'lg', 'xl'
    disabled,
    grayButton,
    type, // type can be 'submit', 'reset', 'button'
    isLoading,
    className,
    onClick,
    ariaLabel,
  } = props;
  return (
    <Button
      kind="secondary"
      size={size}
      disabled={disabled}
      gray={grayButton}
      type={type}
      isLoading={isLoading}
      className={`${className} btn-cta`}
      onClick={onClick}
      aria-label={ariaLabel}
      role="link"
    >
      {props.children}
    </Button>
  );
};

ButtonCTA.propTypes = {
  size: PropTypes.string,
  disabled: PropTypes.bool,
  grayButton: PropTypes.bool,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string,
};
ButtonCTA.defaultProps = {
  size: "lg",
  disabled: false,
  grayButton: true,
  type: "button",
  isLoading: false,
  className: "",
  children: "secondary",
  ariaLabel: "button",
};

export default ButtonCTA;
