class PerfromAssessmentService {
  constructor() {
    this.baseApiUrl = process.env.REACT_APP_SERVER_GRC_BASE_HOST;
  }

  putRecipientQuestionAnswersURL() {
    return `${this.baseApiUrl}/putRecipientQuestionAnswers`;
  }

  putRecipientQuestionAnswersReadURL() {
    return `${this.baseApiUrl}/putRecipientQuestionAnswersRead`;
  }
  updateRecipientSubmittedURL() {
    return `${this.baseApiUrl}/updateRecipientSubmitted`;
  }

  updateQuestionSkipURL() {
    return `${this.baseApiUrl}/updateQuestionSkip`;
  }
  downloadAssessmentFileURL() {
    return `${this.baseApiUrl}/downloadAssessmentFile`;
  }
}

// Enable Singleton behavior on this class
PerfromAssessmentService.__instance = null;
PerfromAssessmentService.get = () => {
  if (!PerfromAssessmentService.__instance) {
    PerfromAssessmentService.__instance = new PerfromAssessmentService();
  }
  return PerfromAssessmentService.__instance;
};

export default PerfromAssessmentService;
