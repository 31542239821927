import "./PanelWithRadio.css";
import { Panel, Radio } from "appkit-react";
import PropTypes from "prop-types";
import React from "react";

import { polyglotLoader } from "../../../../Services/Shared/Translate";

// COMPONENT WHICH HAS A CARD WITH RADIO BUTTON
const PanelWithRadio = (props) => {
  const { item, handleCheck } = props;
  var polyglot = polyglotLoader();
  // CHECK IF RADIO IS SELECTED
  const checkIsSelected = () => {
    if (item.is_uploaded === 1) {
      return true;
    }
    return false;
  };

  // HANDLE CHANGE ON SELECTION OF RADIO BUTTON
  const handleChange = (event) => {
    if (event.target.type !== "radio") {
      handleCheck(Number(!item.is_uploaded), "");
    }
  };

  return (
    <Panel>
      <div onClick={handleChange} className="choose-new-proj-card">
        <div className="row">
          <div className="col-md-10">
            <h6 className="subtitle_two_title_qb choose-new-proj-text">
              {polyglot.t("Start completely new workflow")}
            </h6>
          </div>
          <div className="col-md-2 text-align-right">
            <Radio className="radio-right-padding-0" checked={checkIsSelected()} />
          </div>
        </div>
      </div>
    </Panel>
  );
};

PanelWithRadio.propTypes = {
  item: PropTypes.object,
  handleCheck: PropTypes.func,
};

export default PanelWithRadio;
