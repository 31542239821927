import { Divider, Tooltip, Radio, Panel } from "appkit-react";
import PropTypes from "prop-types";
import React, { useState, useEffect, useContext } from "react";

import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import CustomModal from "../../../Assets/Components/Modal";
import iconinfo from "../../../Assets/Images/QueenBase/Root/icon-info.svg";
import { DFPackageInfoIconContent } from "../../../Services/Shared/Handlers";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import GetPriceInGermanFormat from "../Root/GetPriceInGermanFormat";
import displayToastMessage from "../Root/displayToastMessage";

// RENDERS DF PACKAGES ON CLIENT CREATION FORM
function DFPackagesContent({ package: item, onSelectingPackage, selectedPackage }) {
  var polyglot = polyglotLoader();
  const PlanDescription = JSON.parse(item.PlanDescription);
  // GET DESCRIPTION ICON OF PACKAGE
  const getDescriptionIcon = (descObj) => {
    if (descObj.plus === "true") {
      return "appkiticon icon-circle-plus-outline pointer cursor-default";
    }
    if (descObj.tick === "true") {
      return "appkiticon icon-check-mark-fill pointer cursor-default";
    }
    return "";
  };
  return (
    <div className="col-md-3 respo-tab">
      <div className="admin-solution-cardHeight">
        <div className="card-body Admin-ContractBorder-two pt-2 pb-4">
          <>
            <div className="col-md-10 solution-data-display">
              <h6 className="a-h4 mt-0 solution-data-plan">{item.PlanName}</h6>
            </div>

            <div className="col-md-2 solution-select-display pr-0 text-align-right">
              <Radio
                value={selectedPackage.id}
                className="transformY-30"
                checked={selectedPackage.id === item.id}
                onChange={() => onSelectingPackage(item)}
                aria-label={polyglot.t("Choose Package")}
              />
            </div>
          </>
          <Divider />
          <div className="a-mt-15 data-margin mt-2">
            <div className="row marg_t_b ml-0">
              <strong className="a-h4 pl-2">{GetPriceInGermanFormat(item.price)}</strong>&nbsp;
              <span className="per-year-package-text">{polyglot.t("Per_year")}</span>
            </div>
            <>
              {PlanDescription.map((descriptionObj, i) => {
                return (
                  <div key={i} className="row marg_t_b mr-0">
                    <span className={getDescriptionIcon(descriptionObj)}></span>
                    {/* <div className={`col-md-1 ${getDescriptionIcon(descriptionObj)}`} /> */}
                    <div className="col-md-10 f-acc-B">
                      {/* {descriptionObj.name} - {descriptionObj.value} */}
                      <div className="row">
                        <div className="col-md-11 p-0 f-acc-B break-word">
                          {descriptionObj.name}&nbsp;
                          {descriptionObj.value && "-"}&nbsp;
                          {descriptionObj.value}&nbsp;
                        </div>
                        <div className="col-md-1 p-0">
                          {descriptionObj.infoicon === "true" && (
                            <Tooltip
                              content={DFPackageInfoIconContent()}
                              placement="left"
                              className="a-md-pop"
                              tooltipTheme="light"
                            >
                              <img
                                src={iconinfo}
                                height="16px"
                                className="infoicon-solution pointer"
                                alt="info"
                                aria-label={polyglot.t("More info")}
                              />
                            </Tooltip>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </>
          </div>
        </div>
      </div>
    </div>
  );
}
// RETURNS PACKAGES OF SOLUTIONS OF STEP 3 OF CLIENT CREATION
function SolutionPackagesModal(props) {
  let {
    CloseModal,
    show,
    AddPackage,
    solutionId,
    DfPricing,
    solutionList,
    CheckSolutionPackages,
    solutionPackage,
    handleSolutionPackage,
    checkIfDFPackageIsSelected,
    customNumberFormat,
    handleSolutionPackageSingle,
    GetPriceInGermanFormat,
  } = props;
  const [selectedPackage, setSelectedPackage] = useState({});
  const [solutionName, setSolutionName] = useState("");
  // DATE FROM CONTEXT
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader();
  const language = getUserLanguage();

  // GET THE PACKAGES WHICH ARE SELECTED FOR SOLUTION ID
  const getSelectedPackageBySolutionId = () => {
    let packageWhichGotSelected = {};
    solutionPackage.forEach((packageObj) => {
      // get packages of selected solution id
      if (packageObj.solutionid === solutionId) {
        if (packageObj.isselected) {
          packageWhichGotSelected = packageObj;
        }
      }
    });
    setSelectedPackage(packageWhichGotSelected);
  };
  // get solution name to display on popup heading
  const getSolutionName = () => {
    let nameOfSelectedSolution = "";
    solutionList.forEach((solution) => {
      if (solution.id === solutionId) {
        nameOfSelectedSolution = solution.name;
      }
    });
    setSolutionName(nameOfSelectedSolution);
  };

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    getSelectedPackageBySolutionId();
    getSolutionName();
  }, []);

  // ON SELECTING A PACKAGE, SAVE IT TO THE STATE
  const onSelectingPackage = (packageObj) => {
    setSelectedPackage(packageObj);
  };

  // check if new package is selected
  const checkIfNewPackageIsSelected = () => {
    let newPackageSelected = false;
    let packageWhichGotSelected = {};
    solutionPackage.forEach((packageObj) => {
      // get packages of selected solution id
      if (packageObj.solutionid === solutionId) {
        if (packageObj.isselected) {
          packageWhichGotSelected = packageObj;
        }
      }
    });
    if (packageWhichGotSelected.id === selectedPackage.id) {
      newPackageSelected = false;
    } else {
      newPackageSelected = true;
    }
    return newPackageSelected;
  };

  // ID ADD DISABLED
  const isAddDisabled = () => {
    let isDisabled = true;
    const hasNewPackageSelected = checkIfNewPackageIsSelected();
    // if new package is selected, then disabled add button
    if (hasNewPackageSelected) {
      isDisabled = false;
    }
    return isDisabled;
  };

  // add a pacakge to solution
  const addPackageToSolution = () => {
    // if it selected solution is DF
    if (solutionId === 2) {
      handleSolutionPackage(selectedPackage, solutionId);
    }
    // if selected solution is not DF
    if (solutionId !== 2) {
      handleSolutionPackageSingle(selectedPackage, solutionId);
    }
    AddPackage(solutionId);
    clearAndCloseModal();
  };
  // clear the form state and then close the modal
  const clearAndCloseModal = () => {
    setSelectedPackage({});
    CloseModal();
  };
  // RETURNS AN ICON BASED ON THE descriptionObject
  const getDescriptionIcon = (descObj) => {
    if (descObj.plus === "true") {
      return "appkiticon icon-circle-plus-outline pointer cursor-default";
    }
    if (descObj.tick === "true") {
      return "appkiticon icon-check-mark-fill pointer cursor-default";
    }
    if (descObj.orderList === "true") {
      return "appkiticon icon-right-chevron-circle-outline bulletSpace";
    }
    return "";
  };

  // display list item with icon and text
  const displayListItem = (descriptionObj) => {
    try {
      return (
        <>
          <span className={getDescriptionIcon(descriptionObj)}></span>
          <div className="col-md-10 p-0 f-acc-B break-word">
            {descriptionObj.sideHeading === "true" ? (
              <strong>{descriptionObj.name}</strong>
            ) : (
              <>
                {descriptionObj.name}
                {descriptionObj.sup == "true" ? <sup>1</sup> : ""}
              </>
            )}
          </div>
        </>
      );
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // DISPLAY CHILD LIST
  const displayChildList = (descriptionObj) => {
    try {
      return (
        <div className="ml-4 pl-3">
          {descriptionObj.children.map((each, i) => {
            const slNo = i + 1;
            return (
              <div className="row">
                <span>{slNo}.&nbsp;</span>
                <div className="p-0 f-acc-B">{each.name}</div>
              </div>
            );
          })}
        </div>
      );
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  const getPackagesBySolutionId = () => {
    try {
      const packagesOfSolution = solutionPackage.filter(
        (each) => parseInt(each.solutionid) === parseInt(solutionId),
      );
      return packagesOfSolution;
    } catch (err) {
      displayToastMessage(err.message, "error");
      return [];
    }
  };

  if (solutionId) {
    return (
      <CustomModal
        show={show}
        onCancel={clearAndCloseModal}
        title={`${polyglot.t("Choose")} ${solutionName} ${polyglot.t("package")}`}
        Footer={[
          <ButtonPrimary
            disabled={isAddDisabled()}
            onClick={() => addPackageToSolution()}
            ariaLabel={polyglot.t("Add")}
          >
            {polyglot.t("Add")}
          </ButtonPrimary>,
        ]}
        size="x-lg"
      >
        <div className="row">
          {solutionPackage &&
            solutionPackage.map((item, i) => {
              if (solutionId === item.solutionid) {
                return (
                  <>
                    {item.solutionid === 2 && (
                      <DFPackagesContent
                        package={item}
                        key={i}
                        onSelectingPackage={onSelectingPackage}
                        selectedPackage={selectedPackage}
                      />
                    )}
                    {item.solutionid !== 2 && (
                      <div
                        className={`${
                          getPackagesBySolutionId().length > 3 ? "col-md-3" : "col-md-4"
                        } respo-tab`}
                        key={i}
                      >
                        <div className="admin-solution-cardHeight">
                          <div className={`card-body Admin-ContractBorder-two pt-2 pb-4`}>
                            <>
                              <div className="col-md-10 solution-data-display">
                                {item.solutionid == 4 && (
                                  <h6 className="a-h4 mt-0 solution-data-display heightIssue">
                                    {item.title}
                                  </h6>
                                )}
                                {item.solutionid != 4 && (
                                  <h6 className="a-h4 mt-0 solution-data-display ">{item.title}</h6>
                                )}
                              </div>
                              {item.solutionid !== 4 && (
                                <div className="col-md-2 solution-select-display pr-0 text-align-right">
                                  <Radio
                                    value={selectedPackage.id}
                                    className="transformY-30 radiocss"
                                    checked={selectedPackage.id === item.id}
                                    onChange={() => onSelectingPackage(item)}
                                    aria-label={polyglot.t("Choose Package")}
                                  />
                                </div>
                              )}
                            </>
                            <Divider />
                            <div className="a-mt-15 data-margin mt-2">
                              <div className="row marg_t_b">
                                {item.title != "Virtual Asset screening" ? (
                                  item.price === "0" ? <span className="per-year-package-text">{polyglot.t("consumption based")}</span> : <>
                                    <strong className="a-h4">
                                      {item.solutionid != 7
                                        ? GetPriceInGermanFormat(item.price)
                                        : "€" + item.price}
                                    </strong>
                                    &nbsp;
                                    <span className="per-year-package-text">{polyglot.t("Per_year")}</span>
                                  </> 
                                ) : (
                                  <strong className="a-h4">{polyglot.t(item.price)}</strong>
                                )}
                              </div>
                              {item.body.PlanDescription &&
                                item.body.PlanDescription.map((descriptionObj, i) => {
                                  return (
                                    <div key={i} className="row marg_t_b">
                                      {displayListItem(descriptionObj)}
                                      {descriptionObj.children && displayChildList(descriptionObj)}
                                    </div>
                                  );
                                })}
                            </div>
                          </div>
                        </div>
                        <div className="mb-2"></div>
                      </div>
                    )}
                  </>
                );
              }
            })}
          {solutionId == "7" && (
            <div className="container m-0 p-0 mt-4 mb-4 ml-3">
              <div className="row mr-0">
                <div className="col-md-12">
                  <Panel className="p-2">
                    <div
                      className="row margin-center-1em faq-answer"
                      dangerouslySetInnerHTML={{
                        __html: polyglot.t(
                          "Please use the 24/7 concierge service to request your individual quote. Prices may vary depending on volume and size.",
                        ),
                      }}
                    ></div>
                  </Panel>
                </div>
              </div>
            </div>
          )}
        </div>
      </CustomModal>
    );
  }
}

SolutionPackagesModal.propTypes = {
  CloseModal: PropTypes.func,
  show: PropTypes.bool,
};

export default SolutionPackagesModal;
