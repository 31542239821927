import React, { useState } from "react";
import IdleTimer from "react-idle-timer";

import IdleTimeoutAlert from "../Assets/Components/IdleTimeoutAlert";
import { expire_my_session } from "../Services/Shared/Authenticate";
import {
  startSessionTimerBeforeGeneratingJWT,
  storeLoginTimeStampBeforeJWT,
} from "../Services/Shared/LoginHandler";

const addMinsToCurrentTime = (minsToAdd) => {
  const now = new Date();
  const later = new Date(now);
  later.setMinutes(now.getMinutes() + minsToAdd);
  return later.getTime();
};

const IdleTimerContainerBeforeJWT = () => {
  const [openSessionTimeoutAlert, setOpenSessionTimeoutAlert] = useState(false);
  const [idleTimeoutTimer, setIdleTimeoutTime] = useState(null);

  const generateIdleTimer = () => {
    const idleTimeoutTimerMaxTime = addMinsToCurrentTime(2);
    setIdleTimeoutTime(idleTimeoutTimerMaxTime);
  };
  const sessionAboutToTimout = () => {
    if (startSessionTimerBeforeGeneratingJWT()) {
      if (!idleTimeoutTimer) {
        generateIdleTimer();
        setOpenSessionTimeoutAlert(true);
      }
    }
  };
  const continueSession = () => {
    storeLoginTimeStampBeforeJWT();
    setOpenSessionTimeoutAlert(false);
    setIdleTimeoutTime(null);
  };
  const sessionExpires = () => {
    expire_my_session();
  };

  return (
    <>
      <IdleTimer
        onIdle={sessionAboutToTimout}
        timeout={1000 * 60 * 1} // 1min
      />
      <IdleTimeoutAlert
        continueSession={continueSession}
        sessionExpires={sessionExpires}
        openAlert={openSessionTimeoutAlert}
        idleTimeoutTimerMaxTime={idleTimeoutTimer}
      />
    </>
  );
};

export default IdleTimerContainerBeforeJWT;
