import axios from "axios";

import { getUserIDFromSession } from "../Shared/Authenticate";
axios.defaults.baseURL = process.env.REACT_APP_SERVER_HOST;
axios.defaults.headers.post["Content-Type"] = "application/json";

export function getDatasetInfo(custodianId, projectId) {
  return axios(`/getDatasetInfo/${custodianId}/${projectId}`, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
    params: {
      custodianId,
      projectId,
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}
