import HttpClient from "../Shared/HttpClient";

class DataSet {
  constructor() {
    this.client = new HttpClient();
  }
  //Delete Subdataset or whole Dataset
  async deleteDataset(body) {
    const result = await this.client.dfedPost("/deleteDataset", body);
    return result.data;
  }
}

DataSet.__instance = null;
DataSet.get = () => {
  if (!DataSet.__instance) {
    DataSet.__instance = new DataSet();
  }
  return DataSet.__instance;
};

export default DataSet;
