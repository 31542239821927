import jwtUtil from "../../Constants/Jwt";
import HttpClient from "../Shared/HttpClient";

class ProjectSurveyService {
  constructor() {
    this.client = new HttpClient();
  }
  // API TO FETCH LIST OF ALL WORKFLOWS BY PROJECTID
  async getProjectSurveyDetails(projectId) {
    const params = { projectid: projectId };
    const result = await this.client.dfget("/getProjectSurveyDetails", params);
    return result;
  }
  // API TO CREATE A WORKFLOW BY SUBMITING FORM OBJECT
  async createProjectSurvey({ projectSurvey, alreadySelectedDFPackages }) {
    const result = await this.client.dfpost("/createProjectSurvey", {
      projectdata: projectSurvey,
      currentDFPackages: alreadySelectedDFPackages,
    });
    return result;
  }

  async updateProjectSurvey(projectData) {
    var encrypteData = jwtUtil.encrypt(projectData);
    const result = await this.client.dfpost("/updateProjectSurvey", encrypteData);
    return result;
  }

  // API TO DOWNLOAD A PARAMETER FILE OF A WORKFLOW
  async downloadParameterFile(workflowId, project_id) {
    const body = {
      workflowId,
      project_id,
    };
    const result = await this.client.dfpost("/downloadParameterFile", body);
    return result;
  }
  // API TO DOWNLOAD XTRACTOR USER MANUAL
  async downloadXtractorUserManual() {
    const result = await this.client.dfget("/downloadXtractorUserManual");
    return result;
  }

  // API TO DOWNLOAD XTRACTOR APPLICATION
  async downloadXtractorApplication() {
    const result = await this.client.dfget("/downloadXtractorApplication");
    return result;
  }

  // API TO SAVE UPLOADED FILE DETAILS OF A WORKFLOW
  async updateProcessStatusOfProjectSurvey(body) {
    const result = await this.client.dfpost("/updateProcessStatusOfProjectSurvey", body);
    return result;
  }
  async fetchWorkflowById(workflowId) {
    const params = { workflowId };
    const result = await this.client.dfget("/fetchWorkflowById", params);
    return result;
  }
  async emailToSupport(body) {
    const result = await this.client.dfpost("/emailToSupport", body);
    return result;
  }
  // API TO GET TOKEN ON DASHBOARD URL
  async getQlikDashboardURL(groupname) {
    const result = await this.client.dfget("/getQlikDashboardURL", { groupname });
    return result;
  }
  // API TO DOWNLOAD UPLOADED FILE ON WORKFLOW
  async getUserParameterFile(surveyid, project_id) {
    const result = await this.client.dfget(
      `/getUserParameterFile?surveyid=${surveyid}&project_id=${project_id}`,
    );
    return result;
  }
  // API TO GET LIST OF FINANCIAL YEARS
  async fetchFinancialYears_DF() {
    const result = await this.client.dfget("/fetchFinancialYears_DF");
    return result;
  }
  // API TO GET WORKFLOW NAME FROM ID
  async getWorkflowNameFromID(workflowId) {
    const result = await this.client.dfget(`/getWorkflowNameFromID/${workflowId}`);
    return result;
  }
}

// Enable Singleton behavior on this class
ProjectSurveyService.__instance = null;
ProjectSurveyService.get = () => {
  if (!ProjectSurveyService.__instance) {
    ProjectSurveyService.__instance = new ProjectSurveyService();
  }
  return ProjectSurveyService.__instance;
};

export default ProjectSurveyService;
