/* eslint-disable no-mixed-spaces-and-tabs */
import { Pagination } from "antd";

import { DataContext } from "../../../Services/Shared/Store";
import NoAccess from "../../../Assets/Components/NoAccess";
import { Panel, Select, SelectGroupTitle, SelectOption } from "appkit-react";
import moment from "moment";
import React, { useEffect, useState, useContext } from "react";
import DeleteProject from "./DeleteProject";
import EditProjectContainer from "./EditProjectContainer";
import ViewProject from "./ViewProject";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/QueenBase/Root/loading-spin.svg";
import { PER_PAGE_COUNT } from "../../../Constants/Constant";
import { EDIT_PROJECT_PATH } from "../../../Constants/Path";
import ProjectService from "../../../Services/QueenBase/Projects/ProjectService";
import CommonService from "../../../Services/Shared/CommonService";
import { hasViewPermission } from "../../../Services/Shared/RoleHandler";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";

const PER_PAGE = PER_PAGE_COUNT;

// RENDERS PROJECT LIST
function ProjectList(props) {
  const { getCurrentUserPermissions, getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const currentUserPermissions = getCurrentUserPermissions();
  const [showDeleteUserModal, setshowDeleteUserModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagelist, setPageList] = useState([]);
  const [projectList, setprojectList] = useState([]);
  const [searchStr, setSearch] = useState("");
  const [ProjectTodelete, setProjectTodelete] = useState({});
  const [loading, setloading] = useState(true);
  const [sortOrder, setSortOrder] = useState("Newest"); // default sort by is done based on "Newest" records
  const closeAllTheOpenedCards = () => {
    try {
      const list = [];
      let tempObj = {};
      pagelist.forEach((each) => {
        tempObj = { ...each };
        tempObj.isview = 0; // close the expanded view for all projects
        tempObj.isnote = 0; // close the expanded note for all projects
        list.push(tempObj);
      });
      setPageList(list);
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // HANDLE PAGE CHANGE ON PAGINATION
  const handlePageChange = (change) => {
    closeAllTheOpenedCards();
    setCurrentPage(change);
  };

  // format the project list as required by the screen
  const getFormattedProjectList = (initialProjectList) => {
    try {
      const requiredProjectList = [];
      let listObj = {};
      initialProjectList.forEach((each) => {
        listObj = { ...each };
        listObj.isview = 0; // isviewis used to handle the expand/collapse project feature
        requiredProjectList.push(listObj);
      });
      return requiredProjectList;
    } catch (err) {
      return initialProjectList;
    }
  };

  // FETCH PROJECTS FROM API
  const getProjectSolutionUserList = async () => {
    setloading(true);
    const res = await ProjectService.get().getProjectSolutionUserList();
    if (res.CODE) {
      res.projectlist.map((project) => {
        project.projectuserimages.map((item) => {
          if (item.PROJECTUSERIMAGE) {
            var imageBlob = CommonService.get().getBufferImageInBlob(item.PROJECTUSERIMAGE);
            var imageStr = URL.createObjectURL(imageBlob);
            item.projectuserimage = imageStr;
          }
        });
        project.solution &&
          project.solution.length > 0 &&
          project.solution.map((sol) => {
            sol.solutionuserimages &&
              sol.solutionuserimages.length > 0 &&
              sol.solutionuserimages.map((solImage) => {
                if (solImage.SOLUTIONUSERIMAGE) {
                  var imageBlob = CommonService.get().getBufferImageInBlob(solImage.SOLUTIONUSERIMAGE);
                  var imageStr = URL.createObjectURL(imageBlob);
                  solImage.solutionuserimage = imageStr;
                }
              });
          });
      });
      const formatedProjectList = getFormattedProjectList(res.projectlist);
      setprojectList(formatedProjectList);
      setPageList(formatedProjectList);
      // default sort order is "newest"
      setSortOrder("Newest");
    }

    setloading(false);
  };

  // SEARCH TEXT FUNCTIONALITY
  const onSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  // ON EDIT CLICK FUNCTIONALITY
  const onEdit = (item) => {
    const editProjectDetails = {
      selectedProject: item,
    };
    const editProjectLocation = {
      pathname: EDIT_PROJECT_PATH,
      state: JSON.stringify(editProjectDetails),
    };
    props.history.push(editProjectLocation);
  };

  // ON EXPANING A PROJECT, MAKE API CALL
  const onView = async (item, rowIndex) => {
    try {
      const list = [];
      let tempObj = {};
      pagelist.forEach((each) => {
        tempObj = { ...each };
        if (each.PROJECTID === item.PROJECTID) {
          // check if item is already expanded
          if (item.isview) {
            // collapse the expanded item
            tempObj.isview = 0;
          } else {
            // open expanded view for selected list item
            tempObj.isview = 1;
          }
        } else {
          // close the expanded view for other items in the list
          tempObj.isview = 0;
        }
        list.push(tempObj);
      });
      setPageList(list);
    } catch (error) {
      displayToastMessage(error.message, "error");
    }
  };

  // get the value of searchable keys
  const searchKeys = (row) => {
    const searchableKeys = ["NAME"];
    const keyValues = searchableKeys.map((key) => row[key]);
    return keyValues.toString().toLowerCase();
  };
  // FILTER PROJECTS BEFORE DISPLAYING PROJECTS LIST
  const filterProjects = (projects) => {
    let filteredProjects = [];
    const trimmedSearchstr = searchStr.trim();
    if (trimmedSearchstr.length > 0) {
      filteredProjects = projects.filter((r) => {
        return searchKeys(r).includes(trimmedSearchstr.toLowerCase());
      });
    } else {
      filteredProjects = [...projects];
    }
    return filteredProjects;
  };

  // VIEW PROJECTS OF CURRENT PAGE
  const getCurrentPageProjects = (filteredProjects) => {
    return filteredProjects.slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE);
  };

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    document.body.classList.remove("bg-img");
    getProjectSolutionUserList();
  }, []);

  // ON CLICKING DELETE PROJECT, VIEW A POPUP
  const deleteProjectModule = (Item) => {
    setProjectTodelete(Item);
    setshowDeleteUserModal((prev) => !prev);
  };

  // ON CLICKING EDIT, DISPLAY EDIT FORM
  const displayEditComponent = (Item, index) => {
    return (
      <div key={index}>
        <EditProjectContainer
          Item={Item}
          onEdit={onEdit}
          fetchProjectsAfterUpdate={getProjectSolutionUserList}
        />
      </div>
    );
  };

  // DISPLAY LOADING SPINNER
  const displayLoadingSpinner = () => {
    return (
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-6" style={{ textAlign: "center" }}>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
        <div className="col-md-4"></div>
      </div>
    );
  };

  // DISPLAY INITIAL HEADING
  const displayInitialHeading = () => {
    return (
      <>
        <div className="row m-0 ">
          <div className="row w-100 p-0 mr-0 ml-0">
            <div className="col-md-8 Admin-data">
              <div className="row">
                <div className="col-md-12">
                  <div className="Admin-tittle " style={{ marginLeft: 10 }}>
                    <p className="text-align-center w-100">{polyglot.t("No records found")}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const displayNoProjectHeading = () => {
    return (
      <>
        <div className="row">
          <div className="col-md-3"></div>
          <div className="col-md-6" style={{ textAlign: "center" }}>
            <div className="project_heading">
              <h1 className="headertest mt-3 mb-2">{polyglot.t("Setup a Project")}</h1>
              <p className="user-flow-text">{polyglot.t("Create a new project and add solutions.")}.</p>
            </div>
          </div>
          <div className="col-md-3"></div>
        </div>
      </>
    );
  };
  //sortOrder useEffect
  useEffect(() => {
    if (sortOrder) {
      switch (sortOrder) {
        case "A-Z":
          setPageList([
            ...pagelist.sort((a, b) => {
              return a.NAME.localeCompare(b.NAME);
            }),
          ]);
          return;
        case "Z-A":
          const filteredList = pagelist.sort((a, b) => {
            return b.NAME.localeCompare(a.NAME);
          });
          setPageList([...filteredList]);
          return;
        case "Created On":
          setPageList([
            ...pagelist.sort((a, b) => {
              return moment(a.createdon).isAfter(moment(b.createdon)) ? 0 : -1;
            }),
          ]);
          return;
        case "Newest":
          setPageList([
            ...pagelist.sort((a, b) => {
              return moment(a.createdon).isBefore(moment(b.createdon)) ? 0 : -1;
            }),
          ]);
          return;
        default:
          return;
      }
    }
  }, [sortOrder]);
  // DISPLAY SEARCH BOX
  const displaySearchBox = () => {
    return (
      <div className="col-md-12 p-0 m-b-2">
        <div>
          <div className="row m-l-0 m-r-0 filter-search-qb">
            <div className={`searchIcon`}>
              <input
                value={searchStr}
                className="SearchBar search-input"
                placeholder={polyglot.t("Search projects")}
                onChange={onSearch}
                autoComplete={"off"}
                aria-label={polyglot.t("Search projects")}
              />
            </div>
            <div className="search-sort">
              {getUserLanguage() === "ger" && (
                <Select
                  id="filter-id-project"
                  placeholder={polyglot.t("Sort")}
                  value={sortOrder}
                  onSelect={setSortOrder}
                >
                  <SelectOption key="A-Z" value="A-Z">
                    {polyglot.t("Project Name")} &nbsp;
                    <span className="appkiticon icon-arrow-up-outline"></span>
                  </SelectOption>
                  <SelectOption key="Z-A" value="Z-A">
                    {polyglot.t("Project Name")} &nbsp;
                    <span className="appkiticon icon-arrow-down-outline"></span>
                  </SelectOption>
                  <SelectOption key="Newest" value="Newest">
                    {polyglot.t("Newest")}
                  </SelectOption>
                  <SelectOption key="Created On" value="Created On">
                    {polyglot.t("Oldest")}
                  </SelectOption>
                </Select>
              )}
              {getUserLanguage() === "en" && (
                <Select
                  id="filter-id-project"
                  placeholder={polyglot.t("Sort")}
                  value={sortOrder}
                  onSelect={setSortOrder}
                >
                  <SelectOption key="A-Z" value="A-Z">
                    {polyglot.t("Project Name")} &nbsp;
                    <span className="appkiticon icon-arrow-up-outline"></span>
                  </SelectOption>
                  <SelectOption key="Z-A" value="Z-A">
                    {polyglot.t("Project Name")} &nbsp;
                    <span className="appkiticon icon-arrow-down-outline"></span>
                  </SelectOption>
                  <SelectOption key="Newest" value="Newest">
                    {polyglot.t("Newest")}
                  </SelectOption>
                  <SelectOption key="Created On" value="Created On">
                    {polyglot.t("Oldest")}
                  </SelectOption>
                </Select>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // HEADING BANNER OR THE USER LIST
  const readingBanner = () => {
    return (
      <div>
        <div className="container-fluid project_container_edit p-0">
          <div className="row view-center mr-0">
            <div className="col-md-8 p-0">
              <Panel className="a-panel-project mb-2">
                <div className="a-text-heading">
                  <div className="row">
                    <div className="col-md-3">
                      <div className="project-title-overflow project-data-desc view-center-name">
                        {polyglot.t("Project Name")}
                      </div>
                    </div>
                    <div className="col-md-3 m-auto">
                      <div className="project-title-overflow project-data-desc">
                        {polyglot.t("Start Date")}
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="project-title-overflow project-data-desc view-center-name pl-0">
                        {polyglot.t("Project Leads")}
                      </div>
                    </div>
                    <div className="col-md-2 ">
                      <div className="project-title-overflow project-data-desc view-center-name-action pl-0">
                        {polyglot.t("Action")}
                      </div>
                    </div>
                  </div>
                </div>
              </Panel>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // DISPLAY PROJECTS LIST
  const displayProjectsList = () => {
    if (hasViewPermission(currentUserPermissions)) {
      return (
        <>
          <h1 className="main_title_qb m-b-18 ">{polyglot.t("Projects List")}</h1>
          {displaySearchBox()}
          {readingBanner()}
          {filterProjects(pagelist).length > 0 && (
            <div className="container-fluid project_container_edit p-0">
              {getCurrentPageProjects(filterProjects(pagelist)).map((Item, index) => {
                return Item.isedit ? (
                  displayEditComponent(Item, index)
                ) : (
                  <ViewProject
                    key={index}
                    Item={Item}
                    index={index}
                    onEdit={onEdit}
                    onView={onView}
                    deleteProjectModule={deleteProjectModule}
                  />
                );
              })}
            </div>
          )}
          {filterProjects(pagelist).length === 0 && displayInitialHeading()}
        </>
      );
    }
    return <NoAccess />;
  };
  return (
    <div>
      <DeleteProject
        Item={ProjectTodelete}
        show={showDeleteUserModal}
        deleteProjectModule={deleteProjectModule}
        fetchProjectsAfterDelete={getProjectSolutionUserList}
        toggleDeleteAlert={setshowDeleteUserModal}
      />

      {loading && displayLoadingSpinner()}
      {!loading && pagelist.length === 0 && projectList.length > 0 && displayInitialHeading()}
      {!loading && pagelist.length === 0 && projectList.length === 0 && displayNoProjectHeading()}
      {!loading && pagelist.length > 0 && displayProjectsList()}

      <div className="pagenationstylesP">
        <>
          {hasViewPermission(currentUserPermissions) && (
            <>
              {!loading && filterProjects(pagelist).length > PER_PAGE && (
                <Pagination
                  current={currentPage}
                  size="small"
                  pageSize={PER_PAGE}
                  total={filterProjects(pagelist).length}
                  onChange={handlePageChange}
                  showSizeChanger={false}
                />
              )}
            </>
          )}
        </>
      </div>
    </div>
  );
}

export default ProjectList;
