import React from "react";

import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import * as path from "../../../../Constants/GRCAssessmentPath";

import "./index.css";
import { Panel } from "appkit-react";

import { polyglotLoader } from "../../../../Services/Shared/TranslateGRC";

const PerformHome = (props) => {
  const polyglot = polyglotLoader();
  const handleClick = () => {
    props.history.push({
      pathname: path.PERFORM_ASSESSMENT_QUESTION_PATH,
    });
  };

  // variable to store the minHeight of the container
  const containerHeight = {
    minHeight: window.innerHeight + "px",
  };

  return (
    <div className="container">
      <div className="row">
        <div style={containerHeight} className="mt-4 full-width screen-layout p-0">
          <Panel>
            <div className="p-4">
              <>
                <div className="full-width">
                  <div className="row justify-content-center">
                    <div className="col-md-8">
                      <p className="land-page-des font-weight-bold">{polyglot.t("perform_home_welcome")}</p>
                    </div>
                  </div>
                  <div className="row justify-content-center">
                    {/* <div className="col-md-1 pl-0">
                      <Tooltip placement="top" className="a-md-pop">
                        <ButtonIcon iconName={infoIcon} />
                      </Tooltip>
                    </div> */}
                    <div className="col-md-8">
                      <p className="pt-2 quest-style-land-page">
                        {polyglot.t("perform_home_instructions")}
                        <br />
                        <br />
                        {polyglot.t("perform_home_contact", { email: process.env.REACT_APP_EMAIL })}
                        <br />
                        <br />
                        {polyglot.t("perform_home_regards")}
                      </p>
                      <br />

                      <ButtonPrimary className="" onClick={(e) => handleClick(e)} ariaLabel="Start">
                        Start
                      </ButtonPrimary>
                    </div>
                  </div>
                </div>
              </>
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
};
export default PerformHome;
