import React from "react";
import Dropzone from "../../dropzone/Dropzone";
import "./MultipleCustodian.css";
import "appkit-react/style/appkit-react.default.css";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import closeIcon from "../../../../Assets/Images/DFED/icon-x.svg";
import { DataContext } from "../../../../Services/Shared/Store.js";

// imports services//
import CustodianService from "../../../../Services/DFED/CustodianService";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";
import CustomModal from "../../../../Assets/Components/Modal";
import InvalidRowsOfMultipleCustodian from "./InvalidRowsOfMultipleCustodian";
import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { downloadImg } from "../../../../Assets/Icons";
import ButtonCTA from "../../../../Assets/Components/ButtonCTA/ButtonCTA";
import Progress from "../../progress/Progress";
import { Button } from "appkit-react";

var polyglot = polyglotLoader();

// THis CLASS IS FOR HANDLING CREATE MULTIPLE CUSTODIAN WITH BULK UPLOAD//
// THIS CLASS COMPONENT CONTAINS LOGIC FOR FILE UPLOAD AND DELETE UPLOADED FILE//

class MultipleCustodian extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Files: [],
      saveButtonDisabled: true,
      CRT_PROJECT_ID: this.props.selectedProjectId,
      custodianRecords: [],
      isMultipleFiles: false,
      selectedProjectName: this.props.selectedProjectName,
      showInvalidCustodians: false,
      invalidPersonCustodians: [],
      invalidGroupCustodians: [],
      requestProgress: null,
      trigger: 0,
    };
    this.downloadTemplate = this.downloadTemplate.bind(this);
    this.uploadCustodian = this.uploadCustodian.bind(this);
    this.deleteUploadedFile = this.deleteUploadedFile.bind(this);
    this.formFileUpload = this.formFileUpload.bind(this);
  }
  static contextType = DataContext;
  // DRAG AND DROP FUNCTION WILL EXECUTE WEN WE DROP FILE AFTER DRAG//

  onDrop = (acceptedFiles, index) => {
    this.formFileUpload(acceptedFiles);
  };

  onUploadProgress = (progressEvent) => {
    let progress = (progressEvent.loaded / progressEvent.total) * 100;
    this.setState({ requestProgress: progress });
  };

  // FILE UPLOAD FUNCTIONALITY===========
  formFileUpload(acceptedFiles, index) {
    const regex = new RegExp(/(?<process>[\w\.-]*)((\.exe)|(\.js)|(\.html)|(\.asp)|(\.php))/gm);
    if (this.state.Files.length > 0) {
      displayToastMessage(polyglot.t(`You can not upload multiple files`), "error");
    } else if (
      acceptedFiles[0].type != "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      acceptedFiles[0].type != "application/vnd.ms-excel" &&
      !regex.test(acceptedFiles[0].name)
    ) {
      displayToastMessage(polyglot.t(acceptedFiles[0].name + " " + `is not a valid xlsx file`), "error");
    } else {
      this.setState({
        Files: [...this.state.Files, ...acceptedFiles],
        saveButtonDisabled: false,
      });
    }
  }
  // THIS FUNCTION IS FOR DOWNLOAD THE PREDEFINE GIVEN TEMPLATE //

  downloadTemplate(filrname) {
    const custodianService = new CustodianService();
    custodianService
      .getTemplate(filrname)
      .then((response) => {
        if (response.data) {
          var buf = Buffer.from(response.data.buffer);
          const url = window.URL.createObjectURL(new Blob([buf]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", response.data.fileName + ".xlsx"); //or any other extension
          document.body.appendChild(link);
          link.click();
        }
      })
      .catch((error) => {});
  }

  // NAVIGATE BACK TO CUSTODIAN LIST PAGE
  navigateBackToCustodianList = () => {
    const custodianService = new CustodianService();
    custodianService.getCustodianData(this.state.CRT_PROJECT_ID).then((result1) => {
      if (result1 && result1.data) {
        this.setState(
          {
            custodianRecords: result1.data,
          },
          () => {
            this.props.showCustodianList(this.state.custodianRecords);
          },
        );
      }
    });
  };
  // THIS FUNCTION IS FOR UPLOAD THAT TEMPLATE AND INSERT ALL DATA INTO DATABASE//

  uploadCustodian() {
    const { formGotClosed } = this.context;
    return new Promise((resolve, reject) => {
      const custodianService = new CustodianService();
      const formData = new FormData();
      formData.append("files", this.state.Files[0]);
      formData.append("fileName", this.state.Files[0].name);
      formData.append("file1", JSON.stringify(this.state.Files[0]));
      formData.append("projectId", this.state.CRT_PROJECT_ID);
      formData.append("selectedProjectName", this.state.selectedProjectName);
      custodianService
        .saveMultipleCustodian(formData, this.onUploadProgress)
        .then((response) => {
          const { duplicatesCount, CODE } = response.data;
          if (CODE) {
            if (
              response.data.invalidRowsOfPerson.length > 0 ||
              response.data.invalidRowsOfGroup.length > 0 ||
              duplicatesCount
            ) {
              if (response.data.invalidKeys.length > 0) {
                displayToastMessage(polyglot.t("file_uploaded_with_exceptions"), "info");
              } else if (duplicatesCount) {
                const message =
                  duplicatesCount === 1 ? "duplicated_custodians_single" : "duplicated_custodians_plural";
                displayToastMessage(polyglot.t(message, { duplicates: duplicatesCount }), "error");
              } else {
                displayToastMessage(polyglot.t("file_uploaded_with_exceptions"), "info");
              }
              this.setState({
                showInvalidCustodians: true,
                invalidPersonCustodians: response.data.invalidRowsOfPerson
                  .concat(response.data.duplicates.filter((el) => el.CRT_TYPE_ID === "1"))
                  .map((el, idx) => ({ ...el, id: idx, isAliasUnique: true })),
                invalidGroupCustodians: response.data.invalidRowsOfGroup
                  .concat(response.data.duplicates.filter((el) => el.CRT_TYPE_ID === "2"))
                  .map((el, idx) => ({ ...el, id: idx, isAliasUnique: true })),
              });
            } else {
              displayToastMessage(polyglot.t("File successfully uploaded"), "success");
              this.navigateBackToCustodianList();
            }
          } else {
            displayToastMessage(polyglot.t("Invalid File"), "error");
          }
          formGotClosed();
        })
        .catch((err) => {
          displayToastMessage(polyglot.t("Something went wrong!"), "error");
        });
    });
  }

  // RUNS WHEN COMPONENT GETS UPDATED
  componentDidUpdate(prevProps, prevState) {
    const isFormViewOnly = this.props.isCustodianEditableAfterProcessed;
    if (!isFormViewOnly && this.state.Files !== prevState.Files) {
      const { formGotOpened } = this.context;
      formGotOpened();
    }
  }
  // DELETED UPLOADED FILE FROM FRONTEND//

  deleteUploadedFile(index, data) {
    this.state.Files.splice(index, 1);
    this.setState({
      Files: [...this.state.Files],
      saveButtonDisabled: true,
    });
  }

  // CLOSE INVALID ROWS POPUP
  closeInvalidRowsPopup = () => {
    this.setState({
      showInvalidCustodians: false,
      invalidPersonCustodians: [],
      invalidGroupCustodians: [],
    });
    this.navigateBackToCustodianList();
  };

  renderProgress = () => {
    return (
      <div className="col-md-8 p-r-0">
        <div className="row">
          <div className="col-md-10" style={{ marginLeft: "10%" }}>
            <div className="ProgressWrapper" style={{ width: "200%", float: "left" }}>
              <Progress progress={this.state.requestProgress ?? 0} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  saveCustodian = () => {
    this.setState({ trigger: this.state.trigger + 1 });
  };

  clearTrigger = () => {
    this.setState({ trigger: 0 });
  };

  // RENDER FUNCTION FOR RENDERING ALL UI AND MANIPULATED STATE

  render() {
    var polyglot = polyglotLoader();
    const maxSize = 1000000;
    return (
      <div className="">
        <div className="row mt-3 fade-in-top m-l-0 m-r-0">
          <div className="col-sm-12 col-md-12 p-l-0">
            <div className="col-sm-12 col-md-12 m-t-10 pl-0 pr-0">
              <ButtonCTA
                onClick={this.downloadTemplate.bind(this, "custodian-template")}
                ariaLabel={polyglot.t("Download template")}
                className="full-width-btn border-1px"
              >
                <img src={downloadImg} alt="download" className="mr-2" />
                {polyglot.t("Download template")}
              </ButtonCTA>
            </div>
          </div>
          <div className="col-sm-12 col-md-12 p-t-10 p-b-10 p-l-0">
            <div className="file-upload-header p-b-10"></div>
            <div id="dfed-custodian-personal">
              <Dropzone
                disabled={this.props.isCustodianEditableAfterProcessed}
                onFilesAdded={(e) => this.onDrop(e)}
              ></Dropzone>
            </div>
          </div>

          <div className="col-sm-12 col-md-12 pl-0">
            <div className="fileListScrollDiv">
              {this.state.Files &&
                this.state.Files.length > 0 &&
                this.state.Files.map((acceptedFile, index) => (
                  <div className="uploadedFileList m-b-6">
                    {acceptedFile.name}
                    <div className="fileDeleteIcon">
                      <button
                        disabled={this.props.isCustodianEditableAfterProcessed}
                        onClick={this.deleteUploadedFile.bind(this, index)}
                        className="cursor"
                      >
                        <img
                          src={closeIcon}
                          alt={polyglot.t("Remove file")}
                          aria-label={polyglot.t("Remove file")}
                        />
                      </button>
                    </div>
                  </div>
                ))}
              {this.state.requestProgress && <div className="col-md-10 p-r-0">{this.renderProgress()}</div>}
            </div>
          </div>
        </div>
        <div className="row m-l-0 m-r-0">
          <div className="col-sm-12 col-md-12 pl-0">
            <ButtonPrimary
              disabled={this.state.saveButtonDisabled}
              onClick={this.uploadCustodian.bind(this)}
              ariaLabel={polyglot.t("save_custodians")}
            >
              {polyglot.t("save_custodians")}
            </ButtonPrimary>
          </div>
        </div>
        {this.props.isCustodianEditableAfterProcessed && (
          <div className="row m-l-0 m-r-0">
            <div className="file-upload-header-description f-s-14 p-b-15 text-color p-l-16">
              * You Can't update this custodian. it is already processed.
            </div>
          </div>
        )}
        <CustomModal
          show={this.state.showInvalidCustodians}
          onCancel={this.closeInvalidRowsPopup}
          Footer={[
            <Button size="lg" className="primary_button" onClick={this.saveCustodian} key={"confirmbox"}>
              {polyglot.t("save_custodians")}
            </Button>,
          ]}
          title={polyglot.t("Invalid rows of the uploaded multiple custodian file")}
          size="x-lg"
          showCloseButton
          style={{ width: "100%" }}
        >
          <InvalidRowsOfMultipleCustodian
            invalidPersonCustodians={this.state.invalidPersonCustodians}
            invalidGroupCustodians={this.state.invalidGroupCustodians}
            setGroupCustodians={(custodians) => this.setState({ invalidGroupCustodians: custodians })}
            setPersonCustodians={(custodians) => this.setState({ invalidPersonCustodians: custodians })}
            trigger={this.state.trigger}
            selectedProjectId={this.state.CRT_PROJECT_ID}
            closePopup={this.closeInvalidRowsPopup}
            clearTrigger={this.clearTrigger}
          />
        </CustomModal>
      </div>
    );
  }
}
export default MultipleCustodian;
