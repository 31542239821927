export const LOGIN_PATH = "/";

export const PERFORM_ASSESSMENT_PATH = "/PerformHome";

export const PERFORM_ASSESSMENT_AUTH_PATH = "/PerformAuth/:uid";

export const PERFORM_ASSESSMENT_QUESTION_PATH = "/PerformCategory";

export const RECIPIENT_ANSWERS_PATH = "/RecipientAnswers";

export const PERFORM_ASSESSMENT_LOGOUT_PATH = "/logout";
