import { Select, SelectOption } from "appkit-react";
import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import GRCAssessmentHeader from "./GRCAssessmentHeader";
import logo from "../../../Assets/Icons/logo.png";
import ApplicationCongif from "../../../Services/Shared/ApplicationCongif";
import { grc_check_If_User_LoggedIn } from "../../../Services/Shared/GrcAssesmentAuthenticate";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader, setCurrentLanguage } from "../../../Services/Shared/TranslateGRC";

const GRCAssessmentLoginPageHeader = (props) => {
  const { getUserLanguage, setUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage);

  const [applicationName, SetApplicationName] = useState();

  // API TO GET APPLICATION CONFIG DETAILS
  async function getApplicationConfig() {
    const config = await ApplicationCongif.get().getApplicationConfig();
    SetApplicationName(config.title);
  }
  useEffect(() => {
    getApplicationConfig();
  }, []);

  const changeLanguage = (value) => {
    setCurrentLanguage(value);
    setUserLanguage(value);
  };

  return (
    <header className="normal-header-container">
      <nav
        style={{ height: "73px" }}
        className="navbar navbar-expand-lg navbar-light white-header justify-content-between pt-0 pb-0"
      >
        <div className="a-brand-container ">
          <div className="navbar-brand" tabIndex="1">
            <img className="logoimg" alt="logo" src={logo} title="PwC - Connected Digital Services" />
          </div>
        </div>
        <div className="a-actions-container" aria-label={polyglot.t("change_language")}>
          <div id="headerdownarrow">
            {getUserLanguage() === "ger" && (
              <Select
                defaultValue={getUserLanguage()}
                onSelect={changeLanguage}
                aria-label={polyglot.t("change_language")}
              >
                <SelectOption key={1} value={"en"}>
                  EN
                </SelectOption>
                <SelectOption key={2} value={"ger"}>
                  DE
                </SelectOption>
              </Select>
            )}
            {getUserLanguage() === "en" && (
              <Select
                defaultValue={getUserLanguage()}
                onSelect={changeLanguage}
                aria-label={polyglot.t("change_language")}
              >
                <SelectOption key={1} value={"en"}>
                  EN
                </SelectOption>
                <SelectOption key={2} value={"ger"}>
                  DE
                </SelectOption>
              </Select>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

const GRCAssessmentHeaderContainer = (props) => {
  return (
    <>
      {!grc_check_If_User_LoggedIn() && <GRCAssessmentLoginPageHeader {...props} />}
      {grc_check_If_User_LoggedIn() && <GRCAssessmentHeader {...props} />}
    </>
  );
};

export default withRouter(GRCAssessmentHeaderContainer);
