/* eslint-disable react/no-unescaped-entities */
import "../../../Assets/Css/QueenBase/Users/Userstoadd.css";
import { Button, Input, Panel, Progress, Tooltip } from "appkit-react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useState, useContext } from "react";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";

import LinkText from "../../../Assets/Components/LinkText/LinkText";
import icongroup from "../../../Assets/Icons/avatar-outline.svg";
import iconDown from "../../../Assets/Images/QueenBase/Root/icon-down.svg";
import iconUp from "../../../Assets/Images/QueenBase/Root/icon-up.svg";
import UserService from "../../../Services/QueenBase/Users/UserService";
import { hasCreatePermission, hasViewPermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import GetPriceInGermanFormat from "../Root/GetPriceInGermanFormat";
import displayToastMessage from "../Root/displayToastMessage";

// RIGHT PANEL OF USER SCREEN
function Userstoadd({ userCount, tempData, fetchUserCount }) {
  const { getCurrentUserPermissions, getUserLanguage } = useContext(DataContext);
  const currentUserPermissions = getCurrentUserPermissions();
  const [toggle, setToggle] = useState(false);
  const initialValues = {
    tempCount: "",
  };
  var polyglot = polyglotLoader(getUserLanguage());
  // FORM VALIDATION TO ADD TEMPORARY USERS
  const validationSchema = Yup.object().shape({
    tempCount: Yup.number()
      .integer("Please enter a valid amount without decimal values")
      .typeError("Please enter a valid amount")
      .positive("Please enter a valid amount")
      .min(1, polyglot.t("Number of users must be grater than 1"))
      .required("Number of users is required"),
  });
  // BUY TEMPORARY USERS
  const buyTempUsers = async (values) => {
    var res = await UserService.get().buyTempUsers({
      tempCount: values.tempCount,
      price: tempData.price,
      noofmonths: tempData.noofmonths,
    });
    if (res.CODE) {
      displayToastMessage(polyglot.t(res.MSG), "success");
      fetchUserCount();
      setToggle((prev) => !prev);
    } else {
      displayToastMessage(res.MSG, "error");
    }
  };
  // GET PERCENTAGE OF AVAILABLE USERS OUT OF MAXIMUM USERS
  const getAvailableUserPercent = () => {
    const freeToAdd = Number(userCount.freeusers);
    const maxUserCount = Number(userCount.maxusercount);
    const percentOfUsersFreeToAdd = (freeToAdd * 100) / maxUserCount;
    return percentOfUsersFreeToAdd;
  };
  // TOOLTIP TO SHOW NUMBER OF USERS WHICH CAN BE CREATED
  const getTooltipContent = () => {
    if (userCount.freeusers === 0) {
      return polyglot.t("No user account available");
    }
    return polyglot.t("canAddUserCount", { feeUserCount: userCount.freeusers });
  };
  // RENDER TOOTIP CONTENT PASSED TO THIS FUNCTION
  const toolTipContent = (content) => {
    return (
      <div>
        <div className="tip-a-user">{content}</div>
      </div>
    );
  };

  // GET AVAILABLE TEMPORARY USER COUNT
  const getAvailableTemporaryUserCount = () => {
    if (userCount.maxtempusercount === 0) {
      return 0;
    }
    if (userCount.operatingtempusers || userCount.maxtempusercount) {
      return `${userCount.operatingtempusers}/${userCount.maxtempusercount}`;
    }
    return 0;
  };
  // GET NEED MORE TEMP USER TEXT
  const getNeedMoreTempUserText = () => {
    if (userCount.maxtempusercount === 0) {
      return polyglot.t("Need temporary users?");
    }
    return polyglot.t("Need more temporary users?");
  };

  return (
    <div>
      {hasViewPermission(currentUserPermissions) && (
        <>
          <Panel className="adduserpanel-three">
            <div className="card-spacing">
              <span className="group-person ">
                <img src={icongroup} alt={icongroup} />
                &nbsp;&nbsp;&nbsp;
                <span className="main_title_qb group-icon">{polyglot.t("User Details")}</span>{" "}
              </span>
            </div>
            <div className="custom-divider"></div>
            <div className="card-spacing">
              <div className="row">
                <div className="subtitle_three_title_qb_dark col-md-10">
                  {polyglot.t("Users as per the package")}
                </div>
                <div className="subtitle_three_title_qb_dark col-md-2 count-align-t">
                  <b>{userCount.usercount}</b>{" "}
                </div>
              </div>
              <div className="row">
                <div className="subtitle_three_title_qb_dark col-md-10">
                  {polyglot.t("temp_users_utilised")}
                </div>
                <div className="subtitle_three_title_qb_dark col-md-2 count-align-t">
                  <b>{userCount.operatingtempusers}</b>{" "}
                </div>
              </div>
              <div className="row">
                <div className="subtitle_three_title_qb_dark col-md-10">
                  {polyglot.t("Active temporary users")}
                </div>
                <div className="subtitle_three_title_qb_dark col-md-2 count-align-t">
                  <b>{userCount.activeTemporaryUsers}</b>{" "}
                </div>
              </div>
              <div className="row">
                <div className="subtitle_three_title_qb_dark col-md-10">
                  {polyglot.t("Reactivated temporary users")}
                </div>
                <div className="subtitle_three_title_qb_dark col-md-2 count-align-t">
                  <b>
                    {isNaN(userCount.operatingtempusers)
                      ? 0
                      : userCount.operatingtempusers - userCount.activeTemporaryUsers}
                  </b>{" "}
                </div>
              </div>
            </div>
            <div className="custom-divider"></div>
            <div className="row m-0 mt-2 mb-2">
              <div className="subtitle_three_title_qb_dark col-md-10">{polyglot.t("Total users")}</div>
              <div className="subtitle_three_title_qb_dark col-md-2 count-align-t">
                <b>
                  {isNaN(userCount.usercount) ? 0 : userCount.usercount + userCount.activeTemporaryUsers}{" "}
                </b>
              </div>
            </div>
          </Panel>
          <Panel className="adduserpanel">
            <div className="card-spacing">
              <span className="group-person">
                <img src={icongroup} alt={icongroup} />
                &nbsp;&nbsp;&nbsp;
                <span className="main_title_qb group-icon">{polyglot.t("Free user profiles")}</span>{" "}
              </span>
            </div>
            <div className="custom-divider"></div>
            <div className="card-spacing">
              <div>
                <span className="main_title_qb">
                  {userCount.freeusers}/{userCount.maxusercount}{" "}
                </span>
                &nbsp;
                <span className="subtitle_three_title_qb_dark">{polyglot.t("User accounts available")}</span>
              </div>
              <div className="cursor card-spacing padding-bar ml-0 mr-0">
                <Tooltip content={toolTipContent(getTooltipContent())} placement="top" trigger="click">
                  <Progress style={{ minwidth: "450px" }} percent={getAvailableUserPercent()} />
                </Tooltip>
              </div>
            </div>
          </Panel>
        </>
      )}
      {hasViewPermission(currentUserPermissions) && (
        <Panel className="add-userpanel-second">
          <div className="card-spacing">
            <span className="group-person">
              <img src={icongroup} alt={icongroup} />
              &nbsp;&nbsp;&nbsp;
              <span className="main_title_qb">{polyglot.t("Temporary Users")}</span>
            </span>
          </div>
          <div className="custom-divider"></div>
          <div className="card-spacing">
            <div className="main_title_qb p-0">
              <span>{getAvailableTemporaryUserCount()}</span>&nbsp;
              <span className="subtitle_three_title_qb_dark">{polyglot.t("temp_users_utilised")}</span>
            </div>
            <div>
              {hasCreatePermission(currentUserPermissions) && (
                <div
                  className="tempoarary-user-color subtitle_two_title_qb"
                  onClick={() => setToggle((prev) => !prev)}
                >
                  <LinkText ariaLabel={getNeedMoreTempUserText()}>
                    {getNeedMoreTempUserText()}&nbsp;
                    <span className="chervon-position">
                      <img alt={iconUp} className="downicon" src={toggle ? iconUp : iconDown} />
                    </span>
                  </LinkText>
                </div>
              )}
              {toggle ? (
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="float-left">
                        {polyglot.t("Cost based on your active package")}&nbsp;
                      </div>
                      <div className="bold-text float-left">'{userCount.packagename}':</div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <p>
                        <b>{GetPriceInGermanFormat(`${userCount.temporaryuserprice}`)}</b>
                        &nbsp;{polyglot.t("per user")}&nbsp;
                        {polyglot.t("Validfor", { name: userCount.noofmonths })}
                      </p>
                    </div>
                  </div>
                  <Formik
                    onSubmit={buyTempUsers}
                    initialValues={initialValues}
                    validationSchema={validationSchema}
                  >
                    {(prop) => {
                      const { values, isSubmitting, handleChange, handleSubmit, errors, touched, dirty } =
                        prop;
                      return (
                        <div>
                          <Input
                            placeholder={polyglot.t("Number of users")}
                            value={values.tempCount}
                            onChange={handleChange("tempCount")}
                            type="number"
                            aria-label={polyglot.t("Number of users")}
                          />
                          {errors.tempCount && touched.tempCount ? (
                            <div className="error">{polyglot.t(errors.tempCount)}</div>
                          ) : (
                            null &&
                            errors.tempCount && <span className="error">{polyglot.t(errors.tempCount)}</span>
                          )}
                          &nbsp;
                          <div className="user-time">
                            <div className="main_title_qb">
                              {GetPriceInGermanFormat(`${values.tempCount * userCount.temporaryuserprice}`)}
                            </div>
                            <span className="subtitle_three_title_qb_dark">
                              {polyglot.t("Validfor", {
                                name: userCount.noofmonths,
                              })}
                            </span>
                          </div>
                          <Button
                            className=" primary_button temp-user"
                            onClick={handleSubmit}
                            type="submit"
                            disabled={
                              isSubmitting ||
                              !dirty ||
                              !(Object.keys(errors).length === 0 && errors.constructor === Object)
                            }
                          >
                            {polyglot.t("Add temporary users")}
                          </Button>
                        </div>
                      );
                    }}
                  </Formik>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </Panel>
      )}
    </div>
  );
}
Userstoadd.propTypes = {
  history: PropTypes.object,
  userCount: PropTypes.object,
  tempData: PropTypes.object,
  fetchUserCount: PropTypes.func,
};
export default withRouter(Userstoadd);
