import axios from "axios";
import HttpClient from "../Shared/HttpClient";
import { check_api_error_response, getUserIDFromSession } from "../Shared/Authenticate";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_HOST;
axios.defaults.headers.post["Content-Type"] = "application/json";
export default class FastForwardService {
  savePipelineData(formData) {
    return new Promise((resolve, reject) => {
      // axios.defaults.headers.post["authorization"] = 'Bearer ' + sessionStorage.getItem('jwt')
      axios
        .post("/savePipeLine", formData, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            loggedInUserId: getUserIDFromSession(),
            "Content-Type": "multipart/form-data",
          },
        })
        .then((reply) => {
          resolve(reply);
        })
        .catch((error) => {
          const errorObj = JSON.parse(JSON.stringify(error));
          check_api_error_response(errorObj.message);
          // reject(error)
        });
    });
  }

  fetchPipeline = async (projectID) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getPipelineList/${projectID}`);
    return res;
  };
  fetchPipelineDatByPipeLineID = async (projectID, pipelineId) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getPipelineDataSetByPipelineID/${projectID}/${pipelineId}`);
    return res;
  };
  fetchPipelineKeywordlist = async (projectID, keywordListId) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/get_keywordlist/${projectID}/${keywordListId}`);
    return res;
  };
  fetchPipelinereview = async (projectID, analysisId) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/get_Pipeline_review/${projectID}/${analysisId}`);
    return res;
  };
}
