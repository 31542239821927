import "../../../../Assets/Css/QueenBase/CompanyCenter/CompanyCenter.css";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { DataContext } from "../../../../Services/Shared/Store";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import GetPriceInGermanFormat from "../../Root/GetPriceInGermanFormat";
import BasePackageDetailsPanel from "../Panels/BasePackageDetailsPanel";

function BasePackageDetailsCard({ packageInfo, onClick }) {
  const { getUserLanguage } = useContext(DataContext);
  const polyglot = polyglotLoader(getUserLanguage());

  return (
    <BasePackageDetailsPanel
      title={packageInfo.name}
      period={packageInfo.period}
      footer={[
        packageInfo?.packageUpgradeStatus === 1 ? (
          <ButtonPrimary onClick={onClick} ariaLabel={polyglot.t("View package")}>
            {polyglot.t("View package")}
          </ButtonPrimary>
        ) : null,
      ]}
    >
      <div className="users-list">
        <div className="row marg_t_b ml-0">
          <strong className="a-h4">{GetPriceInGermanFormat(packageInfo.price)}</strong>
          &nbsp;
          <span className="marginpoint35 ml-0 mb-0">{polyglot.t("Per_year")}</span>
        </div>

        <div className="row marg_t_b ml-0 mr-0">
          <span className="appkiticon icon-check-mark-fill pointer ul-icon cursor-default"></span>
          <div className="col-md-10 p-0 f-acc-B">
            <div className="row">
              <div className="col-md-12 pr-0">
                {packageInfo.noofusers}&nbsp;
                {polyglot.t("users")}
              </div>
            </div>
          </div>
        </div>
        <b>{polyglot.t("Additional users")}</b>
        <div className="row marg_t_b ml-0 mr-0">
          <span className="appkiticon icon-circle-plus-outline pointer ul-icon cursor-default"></span>
          <div className="col-md-10 p-0 f-acc-B">
            <div className="row">
              <div className="col-md-12 pr-0">
                {GetPriceInGermanFormat(packageInfo.tempuserprice.toString())} {polyglot.t("per user for")}{" "}
                {packageInfo.noofmonths}{" "}
                {packageInfo.noofmonths === 1 ? polyglot.t("month") : polyglot.t("basePackage_months")}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BasePackageDetailsPanel>
  );
}
BasePackageDetailsCard.propTypes = {
  packageInfo: PropTypes.object,
  onClick: PropTypes.func,
};
export default BasePackageDetailsCard;
