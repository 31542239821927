import React, { useContext } from "react";
import "../../../Assets/Css/CSS/Search.css";
import "../../../Assets/Css/QueenBase/Root/Header.css";
import { DataContext } from "../../../Services/Shared/Store.js";
import { Panel } from "appkit-react";
import { Checkbox } from "appkit-react";
import { polyglotLoader } from "../../../Services/Shared/Translate";

function FilterData(props) {
  const { setUserLanguage, getUserLanguage } = useContext(DataContext);
  const language = getUserLanguage();
  var polyglot = polyglotLoader();
  const { filterOptions, filterOptionOnChange, dateFilter, dateOnChange } = props;

  const TooltipProjectName = () => {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#000",
                  textTransform: "capitalize",
                }}
              >
                {"date"}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const replaceObjectFromJSONArray = (JSONArray, obj, identifierKey) => {
    const newJSONArray = JSONArray.map((each) => {
      if (each[identifierKey] === obj[identifierKey]) {
        return obj;
      }
      return each;
    });
    return newJSONArray;
  };

  const replaceFilterValue = (filterName, obj) => {
    const replacedArray = replaceObjectFromJSONArray(filterOptions[filterName], obj, "name");
    return replacedArray;
  };

  const algorithmFilterHandler_onchange = (e) => {
    const algoData = {
      name: e.target.name,
      isChecked: e.target.checked,
    };
    const algorithFilter = replaceFilterValue("algorithms", algoData);
    filterOptionOnChange("algorithms", algorithFilter);
  };
  const sourceFilterHandler = (e) => {
    const sourceData = {
      name: e.target.name,
      isChecked: e.target.checked,
    };
    const sourceFilter = replaceFilterValue("sources", sourceData);
    filterOptionOnChange("sources", sourceFilter);
  };
  const programmeFilterHandler = (e) => {
    const programmeData = {
      name: e.target.name,
      isChecked: e.target.checked,
    };
    const programmeFilter = replaceFilterValue("program", programmeData);

    filterOptionOnChange("program", programmeFilter);
  };
  const dateFilterHandler = (e) => {
    const yearData = {
      name: Number(e.target.name),
      isChecked: e.target.checked,
    };

    const yearFilter = replaceFilterValue("year", yearData);
    filterOptionOnChange("year", yearFilter);
  };
  const displayAlgorithmsFilter = () => {
    try {
      return filterOptions.algorithms.map((algorithm, index) => {
        return (
          <Checkbox
            onChange={algorithmFilterHandler_onchange}
            checked={algorithm.isChecked}
            name={algorithm.name}
          >
            <span className="rightSpace">{algorithm.name}</span>
          </Checkbox>
        );
      });
    } catch (err) {
      return [];
    }
  };
  const displaySourcesFilter = () => {
    try {
      return filterOptions.sources.map((source, index) => {
        return (
          <Checkbox onChange={sourceFilterHandler} checked={source.isChecked} name={source.name}>
            <span className="rightSpace">{source.name}</span>
          </Checkbox>
        );
      });
    } catch (err) {
      return <></>;
    }
  };
  const displayProgramFilter = () => {
    try {
      return filterOptions.program.map((program, index) => {
        return (
          <Checkbox onChange={programmeFilterHandler} checked={program.isChecked} name={program.name}>
            <span className="rightSpace">{program.name}</span>
          </Checkbox>
        );
      });
    } catch (err) {
      return <></>;
    }
  };
  const displayDateFilter = () => {
    return filterOptions.year.map((year, index) => {
      return (
        <Checkbox onChange={dateFilterHandler} checked={year.isChecked} name={year.name}>
          <span className="rightSpace">{year.name} </span>
        </Checkbox>
      );
    });
  };

  return (
    <div className="leftAlign topSpace">
      <Panel className="adduserpanel-three">
        {props.JsonArray.length > 0 && (
          <>
            <p>
              {polyglot.t("Programmes")} : {displayProgramFilter()}
            </p>
            <p>
              {polyglot.t("Sources")} &nbsp;&nbsp;&nbsp;&nbsp; :&nbsp;
              {displaySourcesFilter()}
            </p>
            <div></div>
          </>
        )}
      </Panel>
    </div>
  );
}
export default FilterData;
