import React, { useContext, useEffect, useState } from "react";
import { Pagination } from "antd";
import { ButtonGroup, ButtonGroupItem } from "appkit-react";
import { PER_PAGE_COUNT } from "../../../../Constants/Constant";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import { CustodianRow } from "./CustodianRow";
import { GroupCustodianRow } from "./GroupCustodianRow";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";
import CustodianService from "../../../../Services/DFED/CustodianService";
import UserService from "../../../../Services/QueenBase/Users/UserService";
import { DataContext } from "../../../../Services/Shared/Store";

const PER_PAGE = PER_PAGE_COUNT;
function InvalidRowsOfMultipleCustodian({
  invalidPersonCustodians: invPerson,
  invalidGroupCustodians: invGroup,
  trigger,
  selectedProjectId,
  setGroupCustodians: setInvGroup,
  setPersonCustodians: setInvPerson,
  clearTrigger,
  closePopup,
}) {
  const [invalidPersonCustodians, setPersonCustodians] = useState(invPerson)
  const [invalidGroupCustodians, setGroupCustodians] = useState(invGroup)
  const polyglot = polyglotLoader();

  const [searchStr, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedView, setSelectedView] = useState(invPerson.length === 0 ? "Group" : "Person");
  const [totalRows, setTotalRows] = useState(invPerson.length === 0 ? invGroup : invPerson);

  const [prefixes, setPrefixes] = useState([]);
  const [positions, setPositions] = useState([]);
  const [countries, setCountries] = useState([]);
  const [classifications, setClassifications] = useState([]);
  const { getUserLanguage } = useContext(DataContext);
  const lang = getUserLanguage();
  // GET CURRENT PAGE ROWS
  const getCurrentPageRows = (filteredRows) => {
    return filteredRows.slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE);
  };
  // SEARCH KEYS
  const searchKeys = (listObj) => {
    const keys = [
      "Salutation",
      "First Name",
      "Middle Name",
      "Last Name",
      "Alias",
      "Email Address",
      "Position",
      "Country",
      "Entity",
      "Department",
      "Classification",
      "General Notes",
    ];
    const keyValues = keys.map((key) => listObj[key]);
    return keyValues.toString().toLowerCase();
  };
  // FILTER ROWS
  const filterRows = () => {
    let filteredRows = [...totalRows];
    if (searchStr !== "") {
      filteredRows = totalRows.filter((r) => searchKeys(r).includes(searchStr.toLowerCase()));
    }
    return filteredRows;
  };
  // ON SEARCH
  const onSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };
  // HANDLE PAGE CHANGE
  const handlePageChange = (change) => {
    setCurrentPage(change);
  };
  // DISPLAY SEARCH BOX
  const displaySearchBox = () => {
    return (
      <div className="admin-user-search mb-4">
        <div className="col-md-6 p-0 search-result">
          <input
            value={searchStr}
            className="serachinput"
            prefix={
              <span
                className="appkiticon icon-search-outline  baseline-search-icon "
                style={{ fontSize: 16 }}
              />
            }
            placeholder={`${polyglot.t("Search")}...`}
            onChange={onSearch}
            aria-label={polyglot.t("Search")}
          />
        </div>
      </div>
    );
  };
  // CHECK AND RENDER TABLE HEADING
  const getTableHeading = () => {
    if (selectedView === "Group") {
      return (
        <tr>
          <th>{polyglot.t("name")}</th>
          <th>{polyglot.t("Alias")}<br /><span style={{color: 'red'}}>* {polyglot.t("unique_alias")}</span></th>
          <th>{polyglot.t("country")}</th>
          <th>{polyglot.t("entity")}</th>
          <th>{polyglot.t("department")}</th>
          <th>{polyglot.t("classification")}</th>
          <th>{polyglot.t("general_notes")}</th>
        </tr>
      );
    }
    return (
      <tr>
        <th>{polyglot.t("Salutation")}</th>
        <th>{polyglot.t("first_name")}</th>
        <th>{polyglot.t("middle_name")}</th>
        <th>{polyglot.t("last_name")}</th>
        <th>{polyglot.t("email")}</th>
        <th>{polyglot.t("Alias")}<br /><span style={{color: 'red'}}>* {polyglot.t("unique_alias")}</span></th>
        <th>{polyglot.t("position_custodian")}</th>
        <th>{polyglot.t("country")}</th>
        <th>{polyglot.t("entity")}</th>
        <th>{polyglot.t("department")}</th>
        <th>{polyglot.t("classification")}</th>
        <th>{polyglot.t("general_notes")}</th>
      </tr>
    );
  };

  useEffect(() => {
    const custodianService = new CustodianService();
    const userService = new UserService();

    custodianService.getCountry(lang).then((countryResult) => {
      if (countryResult && countryResult.data) {
        setCountries(countryResult.data);
      }
    });
    // get Classification
    custodianService.getClassifications().then((classificationResult) => {
      if (classificationResult && classificationResult.data) {
        setClassifications(classificationResult.data);
      }
    });
    custodianService.getPositions().then((positionResult) => {
      if (positionResult && positionResult.data) {
        setPositions(positionResult.data);
      }
    });
    // Getting prefix list from queenbase/prefixNames
    userService.getPrefixNames(lang).then((res) => {
      if (res && res.CODE == 1) {
        setPrefixes(res.prefixNames);
      }
    });
  }, []);

  const checkPersonFieldsValidation = (custodian) =>
    custodian["First Name"] &&
    custodian["Last Name"] &&
    custodian["Position"] &&
    custodian["Country"] &&
    custodian["Entity"] &&
    custodian["Department"] &&
    custodian["Alias"] &&
    custodian["Classification"];

  const saveCustodians = async () => {
    let temp = invalidPersonCustodians;
    invalidPersonCustodians.forEach(async (custodian) => {
      if(checkPersonFieldsValidation(custodian) && custodian.isAliasUnique) {
        const custodianService = new CustodianService();
        let formData = new FormData();
        const obj = {};
        obj.CRT_PREFIX_ID = prefixes.find((el) => el.name === custodian["Salutation"])?.id.toString() ?? custodian["Salutation"];
        obj.CRT_FIRST_NAME = custodian["First Name"];
        obj.CRT_MIDDLE_NAME = custodian["Middle Name"] ? custodian["Middle Name"] : null;
        obj.CRT_LAST_NAME = custodian["Last Name"];
        obj.CRT_EMAIL = custodian["Email Address"] ? custodian["Email Address"] : null;
        obj.CRT_POSITION_ID = positions.find((el) => el.value === custodian["Position"])
          ? positions.find((el) => el.value === custodian["Position"]).PS_ID
          : null;
        obj.position_Name = custodian["Position"];
        obj.CRT_COUNTRY_ID = countries.find((el) => el.label === custodian["Country"])
          ? countries.find((el) => el.label === custodian["Country"]).value
          : custodian["Country"];
        obj.CRT_ENTITY = custodian["Entity"];
        obj.CRT_DEPARTMENT = custodian["Department"];
        obj.CRT_ALIAS = custodian["Alias"];
        obj.CRT_NOTES = custodian["General Notes"] ? custodian["General Notes"] : null;
        obj.CRT_CLASSIFICATION_ID = classifications.find((el) => el.label === custodian["Classification"])
          ? Number(classifications.find((el) => el.label === custodian["Classification"]).value)
          : custodian["Classification"];
        obj.CRT_PROJECT_NAME = selectedProjectId;
        obj.CRT_PROJECT_ID = selectedProjectId;
        obj.CRT_TYPE_ID = "1";
  
        formData.append("custodianFormData", JSON.stringify(obj));

        temp = temp.filter((el) => el.id !== custodian.id);

        try {
          const result = await custodianService
          .handleCustodianFormData(formData)
            if (result.data.CODE === 0) {
              displayToastMessage(result.data.MSG, "error");
              return null;
            }
            if (temp.length === 0 && invalidGroupCustodians.length === 0) {
              displayToastMessage(polyglot.t("saved_custodians"), "success");
              closePopup();
            } else if(temp.length === 0 && invalidGroupCustodians.length > 0) {
              clearTrigger();
              setCurrentPage(1);
              setTotalRows(invalidGroupCustodians);
              setSelectedView("Group");
            }
                   
        } catch (error)  {
            displayToastMessage(error.response.data, "error");
        }
      }
    });
    setPersonCustodians(temp);
  }

  const checkGroupFieldsValidation = (custodian) =>
  custodian["Name"] &&
  custodian["Entity"] &&
  custodian["Department"] &&
  custodian["Alias"] &&
  custodian["Classification"];

  const saveGroupCustodians = async () => {
    let temp = invalidGroupCustodians;
    invalidGroupCustodians.forEach(async (custodian) => {
      if(checkGroupFieldsValidation(custodian) && custodian.isAliasUnique) {
        const custodianService = new CustodianService();
        let formData = new FormData();
        const obj = {};
        obj.CRT_FIRST_NAME = custodian["Name"];
        obj.CRT_COUNTRY_ID = countries.find((el) => el.label === custodian["Country"])
          ? countries.find((el) => el.label === custodian["Country"]).value
          : custodian["Country"];
        obj.CRT_ENTITY = custodian["Entity"];
        obj.CRT_DEPARTMENT = custodian["Department"];
        obj.CRT_ALIAS = custodian["Alias"];
        obj.CRT_NOTES = custodian["General Notes"] ? custodian["General Notes"] : null;
        obj.CRT_CLASSIFICATION_ID = classifications.find((el) => el.label === custodian["Classification"])
          ? Number(classifications.find((el) => el.label === custodian["Classification"]).value)
          : custodian["Classification"];
        obj.CRT_PROJECT_NAME = selectedProjectId;
        obj.CRT_PROJECT_ID = selectedProjectId;
        obj.CRT_TYPE_ID = "2";

        formData.append("custodianFormData", JSON.stringify(obj));
        temp = temp.filter((el) => el.id !== custodian.id);

        custodianService
          .handleCustodianFormData(formData)
          .then((result) => {
            if (result.data.CODE === 0) {
              displayToastMessage(result.data.MSG, "error");
              return null;
            }
            if (temp.length === 0 && invalidPersonCustodians.length === 0) {
              displayToastMessage(polyglot.t("saved_custodians"), "success");
              closePopup();
            } else if(temp.length === 0 && invalidPersonCustodians.length > 0) {
              clearTrigger();
              setCurrentPage(1);
              setTotalRows(invalidPersonCustodians);
              setSelectedView("Person");
            }
          })
          .catch((error) => {
            displayToastMessage(error.response.data, "error");
          });
        }
      });
    setGroupCustodians(temp);
  }

  useEffect(() => {
    if(trigger) {
      if(selectedView === "Person") {
        void saveCustodians();
      } else {
        void saveGroupCustodians();
      }
      
    }
  }, [trigger])

  // GET TABLE ROWS
  const getTableRows = (item, index) => {
    if (selectedView === "Group") {
      return (
        <GroupCustodianRow
          custodian={item}
          setCustodian={(custodian) => {
            const newArrayTotalRows = totalRows.map((item) => item.id === custodian.id ? custodian : item);
            const newArray = invalidGroupCustodians.map((item) => item.id === custodian.id ? custodian : item);
            setGroupCustodians(newArray);
            setTotalRows(newArrayTotalRows)
          }}
          key={item.id}
          initialCustodian={item}
          selectedProjectId={selectedProjectId}
          classifications={classifications}
          countries={countries}
        />
      );
    }
    
    return (
      <CustodianRow
        custodian={item}
        setCustodian={(custodian) => {
          const newArrayTotalRows = totalRows.map((item) => item.id === custodian.id ? custodian : item);
          const newArray = invalidPersonCustodians.map((item) => item.id === custodian.id ? custodian : item);
          setPersonCustodians(newArray);
          setTotalRows(newArrayTotalRows)
        }}
        key={item.id}
        initialCustodian={item}
        selectedProjectId={selectedProjectId}
        classifications={classifications}
        countries={countries}
        positions={positions}
        prefixes={prefixes}
      />
    );
  };
  return (
    <>
      {displaySearchBox()}

      <div className="col-md-4 text-align-left">
        <ButtonGroup
          defaultValue={selectedView}
          onChange={(e, v) => {
            setCurrentPage(1);
            setSelectedView(v);
            if (v === "Person") {
              setTotalRows(invalidPersonCustodians);
            }
            if (v === "Group") {
              setTotalRows(invalidGroupCustodians);
            }
          }}
          className="full-width idam-user-filter-group"
          kind="gray"
        >
          <ButtonGroupItem
            className={selectedView === "Person" ? "idam-user-filter-active" : "idam-user-filter"}
            data-value="Person"
            gray={true}
            kind="secondary"
            size="md"
          >
            {polyglot.t("Person")}
          </ButtonGroupItem>
          <ButtonGroupItem
            className={selectedView === "Group" ? "idam-user-filter-active" : "idam-user-filter"}
            data-value="Group"
            gray={true}
            kind="secondary"
            size="md"
          >
            {polyglot.t("Group")}
          </ButtonGroupItem>
        </ButtonGroup>
      </div>

      <div className="mt-3">
        <table className="table">
          {getTableHeading()}
          <tbody>
            {totalRows.length > 0 ? (
              getCurrentPageRows(filterRows())
                .sort((a, b) => b["First Name"] - a["First Name"])
                .map((item, index) => getTableRows(item, index))
            ) : (
              <tr>
                <td className="no-user-td" colSpan="12">
                  {polyglot.t("No data found")}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>

      <Pagination
        current={currentPage}
        size="small"
        pageSize={PER_PAGE}
        total={filterRows().length}
        onChange={handlePageChange}
      />
    </>
  );
}

export default InvalidRowsOfMultipleCustodian;
