import React, { useEffect, useContext } from "react";

import { SOLUTION_LIST_PATH } from "../../../Constants/Path";
import SolutionRoute from "../../../Routes/SolutionRoute";
import { DataContext } from "../../../Services/Shared/Store";

// RENDERS ON /queenbase/solutions
function SolutionBase(props) {
  // DATA FROM CONTEXT
  const { checkAndFetchRole } = useContext(DataContext);
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    const paths = ["/queenbase/solutions", "/queenbase/solutions/"];
    if (paths.includes(props.location.pathname)) {
      props.history.push(SOLUTION_LIST_PATH);
    }
  });
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    const screenName = "Solution";
    checkAndFetchRole(screenName, "Base");
  }, []);
  // RETURN SOLUTION ROUTES
  return (
    <div>
      <SolutionRoute />
    </div>
  );
}

export default SolutionBase;
