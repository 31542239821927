import { Button } from "appkit-react";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";

import PerfromAssessmentService from "./Services/PerfromAssessmentService";
import CustomModal from "../../../Assets/Components/Modal";
import { useHttpPostPerfClient } from "../../../Services/GRC/http-hook-perf-post";
import { grc_expire_my_session } from "../../../Services/Shared/GrcAssesmentAuthenticate";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";

function AssessmentSubmitionAlert({ show, close, Item }) {
  const { isLoadingPerfPost, errorPerfPost, sendRequestPerfPost, clearErrorPerfPost } =
    useHttpPostPerfClient();
  const polyglot = polyglotLoader();

  // DELETE QUESTIONNAIRE API
  const handleAssessmentSubmit = async () => {
    try {
      const putinput = {
        unique_id: Item,
      };

      const responseData = await sendRequestPerfPost(
        PerfromAssessmentService.get().updateRecipientSubmittedURL(),
        JSON.stringify(putinput),
        {
          "Content-Type": "application/json",
        },
      );
      if (responseData > 0) {
        displayToastMessage(`${polyglot.t("assessment.successfully.submited")}`, "success");
      } else {
        throw new Error(responseData);
      }
    } catch (error) {
      displayToastMessage(error.message, "error");
    }

    grc_expire_my_session();
  };

  return (
    <div>
      <CustomModal
        show={show}
        onCancel={close}
        title={polyglot.t("assessment.submit.heading")}
        Footer={[
          <Button size="lg" className="primary_button" onClick={handleAssessmentSubmit} key="1">
            {polyglot.t("Yes, Submit")}
          </Button>,
        ]}
      >
        <>
          <div className="row">
            <div className="col-md-12 word-break">{`${polyglot.t("assessment.submit.confirmation")}?`}</div>
          </div>
        </>
      </CustomModal>
    </div>
  );
}
AssessmentSubmitionAlert.propTypes = {
  history: PropTypes.object,
  show: PropTypes.bool,
  close: PropTypes.func,
  Item: PropTypes.object,
  getCategories: PropTypes.func,
};
export default withRouter(AssessmentSubmitionAlert);
