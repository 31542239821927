import React, { Component } from "react";
import Dropzone from "react-dropzone";
import "../components/dragAndDrop.css";
import fileOrange from "../../../../Assets/Images/DFED/icon-files-orange.svg";
import copyImg from "../../../../Assets/Icons/duplicate-outline.svg";
import { notification } from "antd";

class FormFileUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Files: [],
    };
  }

  onDrop = (acceptedFiles, index) => {
    if (acceptedFiles && acceptedFiles.length > 0 && acceptedFiles[0].size <= 5242880) {
      this.setState({
        Files: [...this.state.Files, ...acceptedFiles],
      });
      this.props.formFileUpload(acceptedFiles, index, this.state.Files);
    } else {
      notification.error(this.getNotificationErrorData("Sorry you can't upload file size greater than 5MB!"));
    }
  };

  getNotificationErrorData(messageData) {
    return {
      message: messageData,
      duration: 5,
      className: "errorMessageModal",
      style: {
        color: "blue !important",
      },
    };
  }

  render() {
    const maxSize = 5242880;
    return (
      <div>
        <div className="dragAndDrop">
          <Dropzone
            onDrop={(e) => this.onDrop(e, this.props.index)}
            //    className="dropzone"
            minSize={0}
            maxSize={maxSize}
            disabled={this.props.disabledTextFields}
          >
            {({ getRootProps, getInputProps, isDragActive, isDragReject, rejectedFiles }) => {
              let isFileTooLarge = false;
              if (rejectedFiles) {
                isFileTooLarge = rejectedFiles.length > 0 && rejectedFiles[0].size > maxSize;
              }
              return (
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  {!isDragActive && (
                    <div className="fileuploadText">
                      <span className="fileUploadTextRed">
                        <img className="fileUploadTextRed" src={fileOrange} alt={""} />
                        &nbsp;
                      </span>
                      <span className="fileUploadTextRed file-txt">Select Your Files</span> &nbsp;&nbsp;
                      <span> or </span> &nbsp;&nbsp;
                      <span className="fileUploadTextRed">
                        <img className="fileUploadTextRed" src={copyImg} alt={""} width={20} height={20} />
                      </span>{" "}
                      &nbsp;<span>Drag and Drop</span>
                    </div>
                  )}
                  {isDragActive && !isDragReject && "Drop it like it's hot!"}
                  {isDragReject && "File type not accepted, sorry!"}
                  {isFileTooLarge && <div className="text-danger mt-2">File is too large.</div>}
                </div>
              );
            }}
          </Dropzone>
        </div>
      </div>
    );
  }
}

export default FormFileUpload;
