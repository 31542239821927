/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useEffect, useState, useContext } from "react";

import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import httpRequest from "../../../Services/Shared/HttpClient";

import "../../../Assets/Css/QueenBase/Root/Header.css";
import "../../../Assets/Css/COMMON/Admin.css";
import "../../../Assets/Css/CSS/Search.css";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/QueenBase/Root/loading-spin.svg";
import Scoring from "./Scoring";
import SearchService from "../../../Services/CSS/SearchService";
import { DatePicker, ConfigProvider } from "antd";
import moment from "moment";
import "moment/locale/de";
import { dateformat_of_YMD } from "../../../Services/Shared/Handlers";
import { plusCircle } from "../../../Assets/Icons";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import { infoIcon } from "../../../Assets/Icons";
import ViewSDNDates from "./ViewSDNDates";
import gr from "antd/lib/locale-provider/de_DE";
import { Panel } from "appkit-react";
import CssGrid from "./CssGrid";
import Filter from "./Filter";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import ButtonSecondaryWithIcon from "../../../Assets/Components/ButtonSecondary/ButtonSecondaryWithIcon";
import calendar from "../../../Assets/Icons/calendar.svg";
import ChevronDown from "../../../Assets/Icons/down-chevron-outline.svg";
import ChevronUp from "../../../Assets/Icons/up-chevron-outline.svg";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";

// RENDERS CSS COMPONENT
function CssComponent(props) {
  const HttpRequest = new httpRequest();
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const [searchStr, setSearch] = useState("");
  const [searchData, setSerchData] = useState([]);
  const [expandHeader, setExpandHeader] = useState(false);
  const [filterOptions, setFilterOptions] = useState({});
  const [maximumDeadlineDate, setMaxDeadlineDate] = useState(null);
  const [minimumDeadlineDate, setMinDeadlineDate] = useState(null);
  const [dateFilter, setDateFilter] = useState({
    fromDate: "", // ISO date format
    toDate: "", // ISO date format
  });
  const [filteredSearchData, setFilteredSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [sourceList, setSourceList] = useState([]);
  // parameter value
  const [scoringParameter, setScoringParameter] = useState("0.70");
  const [sourcesParameter, setSourcesParameter] = useState([]);
  // search result id - the id of the search audit record
  const [searchAuditID, setsearchAuditID] = useState("");
  const [filterdata, setFilterdata] = useState(false);
  const [advancefilterdata, setAdvanceFilterdata] = useState(false);
  const [totaldata, setTotalData] = useState("");
  const [result, setResult] = useState(false);
  const [downloadButton, setdownloadButton] = useState(false);
  const [searchResultVal, setSearchResultValue] = useState({
    changeValue: "",
    dropDownValue: "",
  });
  var polyglot = polyglotLoader();
  const ToggleExpandHeader = () => {
    setExpandHeader(!expandHeader);
  };
  // SEARCH TEXT FUNCTIONALITY
  const onSearch = (e) => {
    setSearch(e.target.value);
  };
  useEffect(() => {
    setSerchData([]);
    setFilterOptions({});
    setFilterdata(false);
    setSearch("");
  }, [props.selectedProjectId]);

  const filterTheSearchData = () => {
    const filteredArray = [];

    const removeTimeFromDateAndCreateISODate = (givenDate) => {
      try {
        const jsDateFormat = dateformat_of_YMD(givenDate);
        return new Date(jsDateFormat).toISOString();
      } catch (err) {
        if (!givenDate) {
          return "";
        }
        return givenDate;
      }
    };
    //get previous date
    const prevDate = (isoDateFormat) => {
      try {
        if (isoDateFormat.length) {
          const removeDays = 1;
          const prevDate = new Date(isoDateFormat).setDate(new Date(isoDateFormat).getDate() - removeDays);
          const isoDate = new Date(prevDate).toISOString();
          return isoDate;
        }
        return ""; //if there is no date found then return string
      } catch (err) {
        return isoDateFormat;
      }
    };
    //get next date
    const nextDate = (isoDateFormat) => {
      try {
        const AddDays = 1;
        const nextDate = new Date(isoDateFormat).setDate(new Date(isoDateFormat).getDate() + AddDays);
        const isoDate = new Date(nextDate).toISOString();
        return isoDate;
      } catch (err) {
        return isoDateFormat;
      }
    };

    const selectedFromDate = prevDate(removeTimeFromDateAndCreateISODate(dateFilter.fromDate));
    const selectedToDate = nextDate(removeTimeFromDateAndCreateISODate(dateFilter.toDate));

    const isFilterKeyUnchecked = (each, searchDataKey, filterKey, parentKeyOfSearchDataKey) => {
      try {
        let isUnchecked = false;
        // validator contains the array of items received from the main search object (here it is "each" parameter)
        const validator = parentKeyOfSearchDataKey
          ? each[parentKeyOfSearchDataKey][searchDataKey]
          : each[searchDataKey];
        const isValidatorUnChecked = []; // contains boolean values of the filter(eg: program) if checked or unchecked
        // assign initial values to the isValidatorChecked
        if (searchDataKey === "program") {
          for (var i = 0; i < validator.length; i++) {
            // initial values are false
            isValidatorUnChecked.push(false);
          }
        }
        filterOptions[filterKey].forEach((item) => {
          if (searchDataKey === "program") {
            const indexOfName = validator.indexOf(item.name);
            if (indexOfName >= 0) {
              // the program name is present in the validator
              if (!item.isChecked) {
                // user has unchecked the program from filter options
                isValidatorUnChecked[indexOfName] = true;
              }
            }
            // if (validator.includes(item.name)) {
            //   if (!item.isChecked) {
            //     isUnchecked = true;
            //   }
            // }
          } else {
            if (item.name === validator) {
              if (!item.isChecked) {
                isUnchecked = true;
              }
            }
          }
        });

        // check if all the values of isValidUnchecked turned to true
        if (searchDataKey === "program") {
          const doesFalseInclude = isValidatorUnChecked.includes(false);
          if (doesFalseInclude) {
            // all the programs assigned to the record are not unchecked
          } else {
            // all the programs assigned to the record are unchekcked
            isUnchecked = true;
          }
        }
        return isUnchecked;
      } catch (err) {
        return false;
      }
    };

    const isDateFilter = (each, fromDateKey, toDateKey, parentKeyOfSearchDataKey) => {
      try {
        if (each[parentKeyOfSearchDataKey]) {
          const fromDate = removeTimeFromDateAndCreateISODate(each[parentKeyOfSearchDataKey][fromDateKey]);
          const toDate = removeTimeFromDateAndCreateISODate(each[parentKeyOfSearchDataKey][toDateKey]);
          let returnObj = null;
          // if both dates are invalid - return each
          if (new Date(fromDate) == "Invalid Date" && new Date(toDate) == "Invalid Date") {
            returnObj = { ...each };
          }
          // if from date is invalid - and to date is valid
          if (new Date(fromDate) == "Invalid Date" && new Date(toDate) !== "Invalid Date") {
            // check if from date is less than selected from date
            if (new Date(selectedToDate) >= new Date(toDate)) {
              returnObj = { ...each };
            }
          }
          // if to date is invlid - and from date is valid
          if (new Date(fromDate) !== "Invalid Date" && new Date(toDate) == "Invalid Date") {
            if (new Date(selectedFromDate) < new Date() && new Date(selectedToDate) > new Date(fromDate)) {
              returnObj = { ...each };
            }
          }
          // if fromdate and to date are valid
          if (new Date(fromDate) !== "Invalid Date" && new Date(toDate) !== "Invalid Date") {
            // //check if selected from date less than to to date
            //check if selected to date  greter than  to from date
            if (
              new Date(selectedFromDate) < new Date(toDate) &&
              new Date(selectedToDate) > new Date(fromDate)
            ) {
              returnObj = { ...each };
            }
          }

          return returnObj;
        } else {
          throw new Error("no dates fround");
        }
      } catch (err) {
        return each;
      }
    };

    const isScoringValueUpdate = (each, searchDataKey) => {
      try {
        if (each) {
          if (Number(each[searchDataKey]) >= scoringParameter) {
            return each;
          }
        }
        return null;
      } catch (err) {
        return each;
      }
    };

    searchData.forEach((each, i) => {
      // check for  source,program
      if (
        isFilterKeyUnchecked(each, "source", "sources", "searchtermdescription") || // check for source
        isFilterKeyUnchecked(each, "program", "program", "searchtermdescription") // check for program
      ) {
        // do nothing, as unchecked values are not displayed on the table
      } else if (
        // check for the rest of the filters
        // Date filter
        selectedFromDate !== "Invalid Date" ||
        (selectedToDate !== "Invalid Date" &&
          // scroing filter
          !isNaN(scoringParameter))
      ) {
        // filter one - filter the dates and return the object
        const dateFilteredObj = isDateFilter(each, "dateFrom", "dateTo", "searchtermdescription");
        // filter two - take the filtered date object and filter the scores on them and return the filtered Object
        const scoringFilteredObj = isScoringValueUpdate(dateFilteredObj, "matchingscore");
        if (scoringFilteredObj) {
          filteredArray.push(scoringFilteredObj);
        }
      }
    });
    setFilteredSearchData(filteredArray);
  };

  // RUNS ON LOAD
  useEffect(() => {
    getSourceList();
  }, []);

  // FETCH SOURCES FROM API
  async function getSourceList() {
    var res = await SearchService.get().getSourcesList();
    if (res.CODE) {
      setSourceList(res.sources);
    } else {
      displayToastMessage(polyglot.t(res.MSG), "error");
    }
  }
  useEffect(() => {
    filterTheSearchData();
  }, [filterOptions, dateFilter, scoringParameter]);

  const buildRequiredDataStructure = (arrayOfNames) => {
    return arrayOfNames.map((name) => {
      const requiredObj = {
        name: name,
        isChecked: true, // by default all the checkbosxes are ticked
      };
      return requiredObj;
    });
  };

  const getMinimiumDate = (uniqueFromDates) => {
    let listOfDates = [];
    uniqueFromDates.forEach((uniqueFromDate, index) => {
      if (uniqueFromDate) {
        listOfDates.push(new Date(uniqueFromDate));
      }
    });
    const minDate = new Date(Math.min.apply(null, listOfDates));
    return minDate;
  };

  const getMaximumDate = (uniqueToDates) => {
    if (
      uniqueToDates.some(function (i) {
        return i === null;
      }) === true
    ) {
      var maxDate = new Date().toString();
    } else {
      let listOfDates = [];
      uniqueToDates.forEach((uniqueToDate, index) => {
        if (uniqueToDate) {
          listOfDates.push(new Date(uniqueToDate));
        }
      });
      var maxDate = new Date(Math.max.apply(null, listOfDates));
    }

    return maxDate;
  };

  const getFilterOptions = (searchResults) => {
    try {
      const totalAlgorithms = searchResults.map((each) => each.algorithm);
      const uniqueAlgoriths = Array.from(new Set(totalAlgorithms)); // remove duplicate records
      const totalSources = searchResults.map((each) => each.searchtermdescription.source);
      const uniqueSources = Array.from(new Set(totalSources)); // remove duplicate records
      const totalProgram = [];
      searchResults.forEach((each) => {
        each.searchtermdescription.program.forEach((program) => {
          totalProgram.push(program);
        });
      });
      const uniqueProgram = Array.from(new Set(totalProgram)); // remove duplicate records
      const totalYears = searchResults.map((each) =>
        new Date(each.searchtermdescription.dateFrom).getFullYear(),
      );

      const score = searchResults.map((each) => each.matchingscore);
      const uniqueScore = Array.from(new Set(score)); // remove duplicate records

      const uniqueYear = Array.from(new Set(totalYears)); // remove duplicate records

      const listOfFromDates = searchResults.map((each) => {
        if (each.searchtermdescription.dateFrom) {
          return each.searchtermdescription.dateFrom;
        }
        return null;
      });
      const uniqueFromDates = Array.from(new Set(listOfFromDates)); // remove duplicate records

      const listOfToDate = searchResults.map((each) => {
        if (each.searchtermdescription.dateTo) {
          return each.searchtermdescription.dateTo;
        }
        return null;
      });
      const uniqueToDates = Array.from(new Set(listOfToDate)); // remove duplicate records

      const minFromDate = getMinimiumDate(uniqueFromDates);
      const maxToDate = getMaximumDate(uniqueToDates);

      let dateFilterObj = { ...dateFilter };
      if (minFromDate !== "Invalid Date") {
        dateFilterObj.fromDate = new Date(minFromDate).toISOString(); // assign this object to date filter
      }
      if (maxToDate !== "Invalid Date") {
        dateFilterObj.toDate = new Date(maxToDate).toISOString();
      }

      const filterOptionsObj = {
        algorithms: buildRequiredDataStructure(uniqueAlgoriths),
        sources: buildRequiredDataStructure(uniqueSources),
        program: buildRequiredDataStructure(uniqueProgram),
        year: buildRequiredDataStructure(uniqueYear),
        score: buildRequiredDataStructure(uniqueScore),
      };

      setFilterOptions(filterOptionsObj);
      setDateFilter(dateFilterObj);
    } catch (err) {
      setFilterOptions({});
    }
  };

  // FETCH SERACH DATA FROM API
  const getSubmitData = async () => {
    try {
      //if (searchStr && searchStr.match(NAME_REGEX)) {
      if (searchStr) {
        setIsLoading(true);
        const parameters = {
          scoring: scoringParameter,
          sources: sourcesParameter,
        };
        const res = await SearchService.get().getSearchResultFromFitService(searchStr, parameters);
        if (res.CODE) {
          setIsLoading(false);
          setSerchData(res.searchResult);
          getFilterOptions(res.searchResult);
          setsearchAuditID(res.searchResultID);
          setFilterdata(true);
          setAdvanceFilterdata(true);
          setTotalData(res.searchResult.length);
          setResult(true);
          if (res.searchResult.length > 0) {
            setdownloadButton(true);
          }
          if (res.MSG == "detailServiceIssue") {
            setdownloadButton(false);
            displayToastMessage(polyglot.t(res.MSG), "error");
          }
        } else {
          setIsLoading(false);
          setSerchData([]);
          setFilterOptions({});
          if (res.CODE == 0) {
            if (res.MSG == "Gateway Timeout") {
              throw new Error(polyglot.t("searchServiceIssue"));
            } else {
              throw new Error(polyglot.t(res.MSG));
            }
          } else {
            throw new Error(polyglot.t("The search contains invalid characters"));
          }
        }
      } else {
        setIsLoading(false);
        setSerchData([]);
        setFilterOptions({});
        displayToastMessage(polyglot.t("Please enter string"), "info");
      }
    } catch (err) {
      setIsLoading(false);
      displayToastMessage(polyglot.t(err.message), "error");
    }
  };

  const submitData = () => {
    //disabling button on each submit
    setdownloadButton(false);
    getSubmitData();
  };

  // on change of filter option
  const filterOptionOnChange = (filterName, value) => {
    const filterOptionsClone = { ...filterOptions };
    filterOptionsClone[filterName] = value;
    setFilterOptions(filterOptionsClone);
  };

  //on change of date filter
  const dateOnChange = (filterName, value) => {
    const dateFilterData = { ...dateFilter };
    dateFilterData[filterName] = value;
    setDateFilter(dateFilterData);
  };

  const displayResultText = () => {
    let ResultText;
    if (searchResultVal.dropDownValue === "All" || searchResultVal.dropDownValue === "") {
      ResultText = filteredSearchData.length;
    } else {
      ResultText = searchResultVal.changeValue;
    }

    return ResultText;
  };

  //on back click
  const onBackButtonClick = () => {
    props.history.push({ pathname: "/css/search" });
  };
  // DISPLAY SEARCH BOX
  const displaySearchBox = () => {
    return (
      <>
        <div className="row alignment   m-r-0 filter-search-qb d-flex">
          <div className={`searchIcon`}>
            <input
              value={searchStr}
              className="SearchBarView search-input"
              placeholder={polyglot.t("Searchdata")}
              onChange={onSearch}
              autoComplete={"off"}
              aria-label={polyglot.t("Searchdata")}
            />
          </div>
          &nbsp;
          <ButtonPrimary onClick={submitData} ariaLabel={polyglot.t("Submitdata")}>
            {polyglot.t("Submitdata")}
          </ButtonPrimary>
          &nbsp;
          <ButtonSecondaryWithIcon
            onClick={props.onBulkUploadClick}
            iconName={plusCircle}
            ariaLabel={polyglot.t("Bulk upload")}
          >
            {polyglot.t("Bulk upload")}
          </ButtonSecondaryWithIcon>
        </div>
      </>
    );
  };

  // ON CHANGING THE DATE OF A MILESTONE
  const handleChangeFromDate = (e, dateString) => {
    if (e) {
      var strDate = dateString.split("."),
        date = new Date(parseInt(strDate[2]), parseInt(strDate[1]) - 1, parseInt(strDate[0]));
      const prevDate = date.setDate(date.getDate());
      const isoDate = new Date(prevDate).toISOString();
      //check toDate
      var toDate = dateFilter.toDate.split("T")[0].split("-");
      var isoDate1 = new Date(toDate).toISOString();
      let newDate;
      if (isoDate < isoDate1) {
        newDate = isoDate;
      } else {
        newDate = isoDate1;
        displayToastMessage(polyglot.t("From date cannot be greater that To date"), "error");
      }
      dateOnChange("fromDate", newDate);
    }
  };

  // ON CHANGING THE DATE OF A MILESTONE
  const handleChangeToDate = (e, date) => {
    if (e) {
      var strDate = date.split("."),
        date = new Date(parseInt(strDate[2]), parseInt(strDate[1]) - 1, parseInt(strDate[0]));

      const nextDate = date.setDate(date.getDate());
      const isoDate = new Date(nextDate).toISOString();
      var fromDate = dateFilter.fromDate.split("T")[0].split("-");
      var isoDate1 = new Date(fromDate).toISOString();
      if (isoDate > new Date().toISOString()) {
        var toDate = new Date().toISOString();
        displayToastMessage(polyglot.t("To date cannot be greater than current date"), "error");
      } else if (isoDate <= isoDate1) {
        var toDate = new Date().toISOString();
        displayToastMessage(polyglot.t("To date cannot be before From date"), "error");
      } else {
        var toDate = isoDate;
      }
      dateOnChange("toDate", toDate);
    }
  };

  // RESTRICT END DATES AND DISPLAY ON DATE PICKER
  const restrictEndDates = (current) => {
    let startdate = dateFilter.fromDate ? dateFilter.fromDate : null;
    let customDate = null;
    if (!startdate) {
      return current && current > new Date(null);
    }
    if (startdate && !maximumDeadlineDate) {
      customDate = new Date(startdate);
      customDate.setDate(customDate.getDate() + 1);
      return current && current < customDate;
    }
    if (startdate && maximumDeadlineDate) {
      customDate = new Date(startdate);
      customDate.setDate(customDate.getDate() + 1);
      return new Date(current) && new Date(current) < new Date(maximumDeadlineDate);
    }
  };

  //downloadReport
  const downloadReport = async () => {
    try {
      const selectedSources = filterOptions.sources.filter((each) => each.isChecked);
      const selectedSourceNames = selectedSources.map((each) => each.name);
      const selectedPrograms = filterOptions.program.filter((each) => each.isChecked);
      const selectedProgramNames = selectedPrograms.map((each) => each.name);
      const parameters = {
        scoring: scoringParameter,
        sources: selectedSourceNames,
        programs: selectedProgramNames,
        dates: dateFilter,
      };
      const body = {
        parameters,
      };
      const res = await SearchService.get().downloadSearchAuditByID(searchAuditID, body);
      if (!res.CODE) {
        displayToastMessage(polyglot.t("File not found"), "error");
      } else {
        setTimeout(() => {
          var buffer = Buffer.from(res.buffer);
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", res.fileName);
          document.body.appendChild(link);
          link.click();
          displayToastMessage(polyglot.t("Downloaded successfully"), "success");
        }, 1000); // 1 sec sleep to collect Buffer properly
      }
    } catch (err) {
      displayToastMessage(polyglot.t(err.message), "error");
    }
  };
  return (
    <>
      {displaySearchBox()}
      <ViewSDNDates className="text-right sdnColor" />

      {searchData && searchData.length > 0 && (
        <>
          <p className="expand-css-pacakge">{polyglot.t("Basic filter")}</p>
          <Panel className="leftAlign a-mt-40 filterAlignment">
            <div style={{ float: "left" }}>
              <p className="">
                {polyglot.t("From Date")} : &nbsp;
                {getUserLanguage() === "en" ? (
                  <DatePicker
                    placeholder={polyglot.t("From Date")}
                    name="fromDate"
                    onChange={handleChangeFromDate}
                    format={"DD.MM.YYYY"}
                    className="antd-datepicker-custom-styles hide-datepicker-close-icon"
                    value={moment(dateFilter.fromDate)}
                    suffixIcon={<img src={calendar} width={14} height={14} />}
                  />
                ) : (
                  <ConfigProvider locale={gr}>
                    <DatePicker
                      placeholder={polyglot.t("From Date")}
                      name="fromDate"
                      onChange={handleChangeFromDate}
                      format={"DD.MM.YYYY"}
                      className="antd-datepicker-custom-styles hide-datepicker-close-icon"
                      value={moment(dateFilter.fromDate)}
                      suffixIcon={<img src={calendar} width={14} height={14} />}
                    />
                  </ConfigProvider>
                )}
                &nbsp; {polyglot.t("To Date")} :&nbsp;
                {getUserLanguage() === "en" ? (
                  <DatePicker
                    placeholder={polyglot.t("To Date")}
                    name="fromDate"
                    onChange={handleChangeToDate}
                    format={"DD.MM.YYYY"}
                    className="antd-datepicker-custom-styles hide-datepicker-close-icon"
                    value={moment(dateFilter.toDate)}
                    suffixIcon={<img src={calendar} width={14} height={14} />}
                  />
                ) : (
                  <ConfigProvider locale={gr}>
                    <DatePicker
                      disabledDate={restrictEndDates}
                      placeholder={polyglot.t("To Date")}
                      name="fromDate"
                      onChange={handleChangeToDate}
                      format={"DD.MM.YYYY"}
                      className="antd-datepicker-custom-styles hide-datepicker-close-icon"
                      value={moment(dateFilter.toDate)}
                      suffixIcon={<img src={calendar} width={14} height={14} />}
                    />
                  </ConfigProvider>
                )}
                <ButtonIcon
                  alt="More info"
                  title={polyglot.t("Filter date one day before and after")}
                  iconName={infoIcon}
                  ariaLabel={polyglot.t("Filter date one day before and after")}
                />
              </p>
            </div>

            <div style={{ float: "left" }}>
              <Scoring
                label={polyglot.t("Scoring :")}
                updateScoringParmeter={setScoringParameter}
                scoringParameter={scoringParameter}
                min={0.7}
                max={1}
                step={0.01}
              />
            </div>
            <div style={{ clear: "both" }}></div>
          </Panel>
        </>
      )}

      <div>
        {advancefilterdata && (
          <div style={{ float: "right", marginTop: "5px" }}>
            <div
              onClick={ToggleExpandHeader}
              className="expand-css-pacakge "
              aria-label={expandHeader ? polyglot.t("Advanced filter") : polyglot.t("Advanced filter")}
            >
              {expandHeader ? polyglot.t("Advanced filter") : polyglot.t("Advanced filter")}{" "}
              <img
                width={16}
                height={16}
                src={expandHeader ? ChevronUp : ChevronDown}
                alt="toggle"
                aria-label={expandHeader ? polyglot.t("compress") : polyglot.t("expand")}
              />
            </div>
          </div>
        )}
        <div style={{ clear: "both" }}></div>
      </div>
      <div className="">
        {expandHeader && (
          <Filter
            JsonArray={searchData}
            filterOptions={filterOptions}
            filterOptionOnChange={filterOptionOnChange}
            dateOnChange={dateOnChange}
            dateFilter={dateFilter}
            sourceList={sourceList}
          />
        )}
      </div>
      {result && <p style={{ textAlign: "right" }}> {polyglot.t("Indicates")}</p>}
      {isLoading && (
        <div className="container-fluid">
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      )}
      {!isLoading && (
        <div>
          <>
            <div className="downalodButton">
              <ButtonSecondary
                disabled={downloadButton == false ? true : false}
                onClick={downloadReport}
                ariaLabel={polyglot.t("Download Report")}
              >
                {polyglot.t("Download Report")}
              </ButtonSecondary>
            </div>
            <CssGrid
              JsonArray={filteredSearchData}
              searchAuditID={searchAuditID}
              setSearchResultValue={setSearchResultValue}
              getUserLanguage={getUserLanguage()}
            />
          </>
        </div>
      )}
    </>
  );
}

export default CssComponent;
