import { Select, SelectGroupTitle, SelectOption } from "appkit-react";
import PropTypes from "prop-types";
import React from "react";

import { polyglotLoader } from "../../../Services/Shared/Translate";

const SelectCustom = (props) => {
  const polyglot = polyglotLoader();

  const {
    value,
    onSelect,
    JSONArray,
    optionKey,
    optionLabel,
    multiple,
    placeholder,
    formLabel,
    name,
    showSelectAll,
  } = props;

  const checkIfUserIsDeselectingAll = (val) => {
    const totalValues = JSONArray.length;
    const selectedValues = val.length; // if user selects "select all' then, selectedValues will have "appkit-select-component-selelct-all-key" appended to the array
    if (selectedValues > totalValues) {
      // user is trying to deselect all
      return true;
    }
    // user is not deselecting all
    return false;
  };

  const checkIfSelectAllIsSelected = (val) => {
    if (showSelectAll) {
      // if user renders a select box with select all option
      if (val.includes("appkit-select-component-select-all-key")) {
        // check if user is deselecting all
        const isUserDeselectingAll = checkIfUserIsDeselectingAll(val);
        if (isUserDeselectingAll) {
          return onSelect(formLabel)({ target: { value: [] } });
        } else {
          // runs if select all is selected
          const allKeys = JSONArray.map((each) => each[optionKey]);
          return onSelect(formLabel)({ target: { value: allKeys } });
        }
      } else {
        return onSelect(formLabel)({ target: { value: val } });
      }
    } else {
      // if there is no select all option
      return onSelect(formLabel)({ target: { value: val } });
    }
  };

  return (
    <Select
      placeholder={placeholder}
      multiple={multiple}
      showSearchOnToggle
      value={value}
      onSelect={checkIfSelectAllIsSelected}
      showSelectAll={showSelectAll}
    >
      {name && <SelectGroupTitle>{polyglot.t(name)}</SelectGroupTitle>}
      {JSONArray.map((each, index) => {
        return (
          <SelectOption key={index} value={each[optionKey]}>
            {each[optionLabel]}
          </SelectOption>
        );
      })}
    </Select>
  );
};

SelectCustom.propTypes = {
  placeholder: PropTypes.string,
  multiple: PropTypes.bool,
  optionLabel: PropTypes.string,
  optionKey: PropTypes.string,
  JSONArray: PropTypes.array,
  onSelect: PropTypes.func,
  value: PropTypes.any, // should be a string or number
  formLabel: PropTypes.string,
  name: PropTypes.string,
  showSelectAll: PropTypes.bool,
};
SelectCustom.defaultProps = {
  placeholder: "Placeholder here",
  multiple: false,
  optionLabel: "LABEL", // this value refers to the lable of JSONArray
  optionKey: "KEY", // this value refers to the key of JSONArray
  JSONArray: [
    { KEY: "one", LABEL: "option one" },
    { KEY: "two", LABEL: "option two" },
    { KEY: "three", LABEL: "option three" },
  ],
  onSelect: () => {},
  value: "two", // should be a string or number
  formLabel: "form label",
  name: null,
  showSelectAll: false,
};

export default SelectCustom;
