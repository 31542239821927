import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/QueenBase/Root/loading-spin.svg";

function LoadingIndicator(props) {
  const [loading, setLoading] = useState(props.loadTime > 0);
  const [color, setColor] = useState("");

  useEffect(() => {
    async function getThemeData() {
      setColor("#ef3829");
    }
    getThemeData();

    if (loading) {
      this.timeout = setTimeout(() => {
        setLoading(false);
      }, props.loadTime);
    }
  }, []);
  let loader = <></>;
  if (!loading) {
    loader = <LoadingSpinner width={64} height={64} fill={color} />;
  }
  return loader;
}
LoadingIndicator.propTypes = {
  props: PropTypes.object,
};
export default LoadingIndicator;
