import { Panel } from "appkit-react";
import React, { useEffect, useState } from "react";

import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/GRC/loading-spin.svg";
import QuestionService from "../../../Services/GRC/QuestionService";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import RightQuestionnaireQuestion from "../Questionnaire/RightQuestionnaireQuestion";
import EmptyPanel from "../Shared/EmptyPanel";
import ErrorPanel from "../Shared/ErrorPanel";
import { GetBreadcrumHTMLByString } from "../handlers";

const RightQuestionnaireQuestionText = ({ id, setsetid }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const polyglot = polyglotLoader();

  // manage data load
  const [breadcrumString, setBreadcrumString] = useState("");
  const [questionText, setQuestionText] = useState("");

  const onLoadListData = async () => {
    try {
      const editQuestionData = await sendRequest(QuestionService.get().getEditQuestionDataURL(id));
      setBreadcrumString(editQuestionData[0][0].breadcrumString);
      setQuestionText(editQuestionData[0][0].question_text);

      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  useEffect(() => {
    onLoadListData();
  }, [sendRequest, id]);

  return (
    <div className="row p-0">
      {error && <ErrorPanel error={error} onClear={clearError} />}
      {isLoading && (
        <div>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      )}
      {questionText.length === 0 && <EmptyPanel massage={"No data found."} />}
      {!isLoading && questionText.length > 0 && (
        <div className="col-md-12">
          <GetBreadcrumHTMLByString breadcrumString={breadcrumString} />
          <hr></hr>
          <div className="custome-min-height">
            {/* <p className="p-3">
              <span className="main_title_qb1 headingtitle">Question Text: </span>
              {questionText}
            </p> */}
            <Panel className="padding-1rem mt-3">
              <RightQuestionnaireQuestion id={id} usemode={"readonly"} />
            </Panel>
          </div>
        </div>
      )}
    </div>
  );
};

export default RightQuestionnaireQuestionText;
