/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable sort-imports */
/* eslint-disable react/prop-types */
import React, { Component } from "react";
import "../../../../Assets/Css/QueenBase/Users/User.css";
import "../../../../Assets/Css/COMMON/Form.css";
import { getProjectID_Of_solution_module } from "../../../../Services/Shared/Handlers";
import { Progress } from "antd";
import $ from "jquery";
import { polyglotLoader, userLanguage } from "../../../../Services/Shared/Translate";
import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";
import RequestFormService from "../../../../Services/GI/RequestFormServices";
import CustomDropZone from "../../../DF/Start/SharedComponents/CustomDropZone";
import ButtonIcon from "../../../../Assets/Components/ButtonIcon/ButtonIcon";
import { closeIcon } from "../../../../Assets/Icons";
import DownloadTemplate from "./DownloadTemplate";
import XLSX from "xlsx";

class BulkUploadComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileDetails: "",
      isCorrectFile: false,
    };
  }
  polyglot = polyglotLoader();

  // VALIDATE UPLAODED FILE TYPE
  validateFileType = (event) => {
    /* if file input is a html input, then below
    const uploadedFiles = event.target.files[0]
    */
    /* if file input is a drag and drop, then below */
    const uploadedFiles = event[0];
    const maxFileSizeInBytes = 5000000;
    /*
      NOTE: MAX FILE SIZE ALLOWED IS 5 MB
      1 KB = 1000 Bytes 
      1 MB = 1000000 Bytes
    */
    if (!uploadedFiles) {
      $("#validateFileErrorMsg").text("");
      $("#validateFileSuccessMsg").text("");
      this.setState({ isCorrectFile: false });
      return false;
    }
    let excelFile = uploadedFiles;
    let allowType2 = "vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    let errMsg = "";
    let tempType = excelFile.type.split("/");
    $("#validateFileErrorMsg").text(errMsg);
    if (tempType[1] !== allowType2) {
      errMsg = "  " + excelFile.name + this.polyglot.t(" is not valid xlsx file type.");
      $("#validateFileErrorMsg").text(errMsg);
      this.setState({ isCorrectFile: false });
      return false;
    }
    var fileReader = new FileReader();
    const scope = this;
    fileReader.onload = function (e) {
      processExcel(e.target.result, scope);
    };
    fileReader.readAsBinaryString(excelFile);
    function processExcel(data) {
      var workbook = XLSX.read(data, { type: "binary" });

      let sheetNumber = null;
      for (let i = 0; i < workbook.SheetNames.length; i++) {
        if (workbook.SheetNames[i] === "Sheet1") {
          sheetNumber = i;
        }
      }
      if (sheetNumber === null) {
        const polyglot = polyglotLoader();
        errMsg = "  " + excelFile.name + " " + polyglot.t("is not a valid bulk upload file.");
        $("#validateFileErrorMsg").text(errMsg);
        $("#validateFileSuccessMsg").text("");
        scope.setState({
          isCorrectFile: false,
        });
        return false;
      }
      if (excelFile.size > maxFileSizeInBytes) {
        const polyglot = polyglotLoader();
        errMsg = "  " + excelFile.name + " " + polyglot.t("file size is greater than 5 MB");
        $("#validateFileErrorMsg").text(errMsg);
        $("#validateFileSuccessMsg").text("");
        scope.setState({
          isCorrectFile: false,
        });
        return false;
      }
      const polyglot = polyglotLoader();

      var selectedSheet = workbook.SheetNames[sheetNumber];

      let excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[selectedSheet]);

      if (excelRows[0] != undefined) {
        let colList = Object.keys(excelRows[0]);
        if (
          colList[0] === "ID" &&
          colList[1] === "TargetName" &&
          colList[2] === "Type" &&
          colList[3] === "DOB/DOI" &&
          colList[4] === "Company Registration Number/ Virtual Asset ID" &&
          colList[5] === "Priority" &&
          colList[6] === "Category" &&
          colList[7] === "Address" &&
          colList[8] === "Misc"
        ) {
          scope.setState(
            {
              fileDetails: excelFile,
            },
            () => {
              const polyglot = polyglotLoader();
              $("#validateFileSuccessMsg").text(polyglot.t("File Validated Sucessfully."));
            },
          );
          scope.setState({
            isCorrectFile: true,
          });
          return true;
        } else {
          errMsg = "  " + excelFile.name + polyglot.t(" is not in desired column format.");
          $("#validateFileErrorMsg").text(errMsg);
          $("#validateFileSuccessMsg").text("");
          scope.setState({
            isCorrectFile: false,
          });
          return false;
        }
      } else {
        errMsg = "  " + excelFile.name + polyglot.t(" data should not be blank");
        $("#validateFileErrorMsg").text(errMsg);
        $("#validateFileSuccessMsg").text("");
        scope.setState({
          isCorrectFile: false,
        });
        return false;
      }
    }
  };
  // VIEW TO UPLOAD FILE
  UploadFileView = () => {
    if (!this.state.fileDetails.name) {
      return (
        <div>
          <div className="Content">
            <div>
              <CustomDropZone
                onFilesAdded={this.validateFileType.bind(this)}
                customDropZoneBoxStyle={{
                  width: "100%",
                  margin: "0",
                  padding: "30px",
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  };

  // REMOVE UPLOADED FILE
  removeUplaodedFile = () => {
    $("#validateFileErrorMsg").text("");
    $("#validateFileSuccessMsg").text("");
    this.setState({
      fileDetails: "",
      isCorrectFile: false,
    });
  };
  // DISPLAY UPLOADED FILES
  displayUploadedFiles = () => {
    const fileObj = this.state.fileDetails;
    const uploadPercent = 100;
    if (fileObj.name) {
      const sizeInKB = fileObj.size / 1000;
      const sizeInMB = fileObj.size / 1024 / 1024;
      return (
        <div>
          <span>
            <b>{fileObj.name}</b>
          </span>
          <div className="row">
            <div className={"col-md-10 imageUpload"}>
              <Progress percent={Number(uploadPercent)} size="small" />
            </div>
            <div className="col-md-2 text-align-right">
              <ButtonIcon
                onClick={this.removeUplaodedFile}
                iconName={closeIcon}
                ariaLabel={this.polyglot.t("Close")}
              />
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              {sizeInMB > 1 && <p className="text-align-left">{sizeInMB.toFixed(2)} MB</p>}
              {sizeInMB < 1 && <p className="text-align-left">{sizeInKB.toFixed(2)} KB</p>}
            </div>
            <div className="col-md-6">
              {Number(uploadPercent) < 100 && Number(uploadPercent) > 0 && (
                <p className="text-align-right">{this.polyglot.t("Uploading...")}</p>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  // CHECK FORM VALIDATION
  checkFormValidation = () => {
    if (!this.state.fileDetails) {
      displayToastMessage("Please upload  template", "error");
      return false;
    } else {
      return true;
    }
  };

  getDisplayMessage = (apiResponse) => {
    let message = this.polyglot.t("File successfully uploaded");
    if (apiResponse.errorRows > 0) {
      message = this.polyglot.t("UploadedSuccessfullyWithErrors", {
        errorCount: apiResponse.errorRows,
      });
    }
    return message;
  };

  // UPDATE WORKFLOW ON SUBMITTING FORM
  handleSubmit = async (e) => {
    let data = {};
    const projectId = getProjectID_Of_solution_module();
    data.projectId = projectId;
    const FORM_NAME = "BULK_UPLOAD";
    data.FORM_NAME = FORM_NAME;
    let formData = new FormData();
    const formValidationSuccess = this.checkFormValidation();
    if (formValidationSuccess) {
      formData.append("upload_file", this.state.fileDetails);
      formData.append("upload_data", JSON.stringify(data));
      const res = await RequestFormService.get().saveBulkUploadRequest(formData);
      if (res.CODE) {
        const message = this.getDisplayMessage(res);
        const messageType = res.errorRows > 0 ? "info" : "success";
        displayToastMessage(message, messageType);
        this.props.showRequestList();
      } else {
        displayToastMessage(this.polyglot.t(res.MSG), "error");
      }
      this.props.showRequestList();
    }
  };

  render() {
    return (
      <div>
        <div className="m-t-10">
          <DownloadTemplate />
        </div>
        <div className="row mt-2">
          <div className="col-md-12 ">
            {this.UploadFileView()}
            {this.displayUploadedFiles()}
            <br />
            <span id="validateFileErrorMsg" className="errorFntClr"></span>
            <span id="validateFileSuccessMsg" className="sucessFntClr"></span>
          </div>
        </div>

        <div className="mt-3">
          <ButtonPrimary onClick={this.handleSubmit} ariaLabel={this.polyglot.t("Submit Request")}>
            {this.polyglot.t("Submit Request")}
          </ButtonPrimary>
        </div>
      </div>
    );
  }
}
export default BulkUploadComponents;
