/* eslint-disable no-mixed-spaces-and-tabs */
import { Pagination } from "antd";
import React, { useEffect, useState, useContext } from "react";

import "../../../../Assets/Css/GI/RequestList/RequestList.css";

import { Panel, Select, SelectOption } from "appkit-react";
import moment from "moment";

import RequestChartsDetails from "./RequestChartsDetails";
import ViewRequest from "./ViewRequest";
import { ReactComponent as LoadingSpinner } from "../../../../Assets/Images/QueenBase/Root/loading-spin.svg";
import { PER_PAGE_COUNT } from "../../../../Constants/Constant";
import RequestFormService from "../../../../Services/GI/RequestFormServices";
import { getProjectID_Of_solution_module } from "../../../../Services/Shared/Handlers";
import httpRequest from "../../../../Services/Shared/HttpClient";
import { DataContext } from "../../../../Services/Shared/Store";
import { polyglotLoader } from "../../../../Services/Shared/Translate";

const PER_PAGE = PER_PAGE_COUNT;

// RENDERS PROJECT LIST
function RequestList(props) {
  const { receiveTargetListCount } = props;
  const HttpRequest = new httpRequest();
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const [projectList, setprojectList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagelist, setPageList] = useState([]);
  const [searchStr, setSearch] = useState("");
  const [loading, setloading] = useState(true);
  const [sortOrder, setSortOrder] = useState(null);
  const [targetList, setTargetList] = useState([]);
  const [statusCountList, setStatusCountList] = useState([]);
  const [categoryCountList, setCategoryCountList] = useState([]);

  // HANDLE PAGE CHANGE ON PAGINATION
  const handlePageChange = (change) => {
    setCurrentPage(change);
  };

  // FETCH PROJECTS FROM API
  const getProjectSolutionUserList = async () => {
    setloading(true);
    const language = getUserLanguage();
    const projectId = getProjectID_Of_solution_module();
    const body = {
      projectId,
    };
    const res = await RequestFormService.get().fetchTargetDetails(language, body);

    if (res.CODE) {
      const sortedByLatest = res.data.targetList.sort((a, b) => {
        return moment(a.CreatedOn).isBefore(moment(b.CreatedOn)) ? 0 : -1;
      });
      setPageList(sortedByLatest);
      setTargetList(res.data.targetList);
      setStatusCountList(res.data.statusCount);
      setCategoryCountList(res.data.categoryCount);
    }
    setloading(false);
  };

  // SEARCH TEXT FUNCTIONALITY
  const onSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  const editRequest = (id) => {
    var res = pagelist.find((req) => req.ID === id);
    props.editRequest(res);
  };

  // SEARCH KEYS
  const searchKeys = (listObj) => {
    const keys = ["TargetName", "Status", "ID"];
    const keyValues = keys.map((key) => listObj[key]);
    return keyValues.toString().toLowerCase();
  };

  // FILTER PROJECTS BEFORE DISPLAYING PROJECTS LIST
  const filterProjects = (projects) => {
    let filteredProjects = [...projects];
    if (searchStr !== "") {
      filteredProjects = projects.filter((r) => searchKeys(r).includes(searchStr.toLowerCase()));
    }
    return filteredProjects;
  };

  // VIEW PROJECTS OF CURRENT PAGE
  const getCurrentPageProjects = (filteredProjects) => {
    return filteredProjects.slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE);
  };

  // RUNS WHEN WE RECEIVE DATA FROM GETTARGETLIST
  useEffect(() => {
    // pass the length of the target list to the parent component
    receiveTargetListCount(targetList.length);
  }, [targetList]);

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    getProjectSolutionUserList();
  }, []);

  // DISPLAY LOADING SPINNER
  const displayLoadingSpinner = () => {
    return (
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-6" style={{ textAlign: "center" }}>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
        <div className="col-md-4"></div>
      </div>
    );
  };

  //sortOrder useEffect
  useEffect(() => {
    if (sortOrder) {
      switch (sortOrder) {
        case "A-Z":
          setPageList([
            ...pagelist.sort((a, b) => {
              return a.TargetName.localeCompare(b.TargetName);
            }),
          ]);
          return;
        case "Z-A":
          const filteredList = pagelist.sort((a, b) => {
            return b.TargetName.localeCompare(a.TargetName);
          });
          setPageList([...filteredList]);
          return;
        case "Created On":
          setPageList([
            ...pagelist.sort((a, b) => {
              return moment(a.CreatedOn).isAfter(moment(b.CreatedOn)) ? 0 : -1;
            }),
          ]);
          return;
        case "Newest":
          setPageList([
            ...pagelist.sort((a, b) => {
              return moment(a.CreatedOn).isBefore(moment(b.CreatedOn)) ? 0 : -1;
            }),
          ]);
          return;
        default:
          return;
      }
    }
  }, [sortOrder]);
  // DISPLAY SEARCH BOX
  const displaySearchBox = () => {
    return (
      <div className="col-md-12 p-0 m-b-2">
        <div>
          <div className="row m-l-0 m-r-0 filter-search-qb">
            <div className={`searchIcon`}>
              <input
                value={searchStr}
                className="SearchBar search-input"
                placeholder={polyglot.t("Search Request")}
                onChange={onSearch}
                autoComplete={"off"}
                aria-label={polyglot.t("Search Request")}
              />
            </div>
            <div className="search-sort">
              {getUserLanguage() === "ger" && (
                <Select
                  id="filter-id-project"
                  placeholder={polyglot.t("Sort")}
                  value={sortOrder}
                  onSelect={setSortOrder}
                >
                  <SelectOption key="A-Z" value="A-Z">
                    {polyglot.t("Name Of Target")} &nbsp;
                    <span className="appkiticon icon-arrow-up-outline"></span>
                  </SelectOption>
                  <SelectOption key="Z-A" value="Z-A">
                    {polyglot.t("Name Of Target")} &nbsp;
                    <span className="appkiticon icon-arrow-down-outline"></span>
                  </SelectOption>
                  <SelectOption key="Newest" value="Newest">
                    {polyglot.t("Newest")}
                  </SelectOption>
                  <SelectOption key="Created On" value="Created On">
                    {polyglot.t("Oldest")}
                  </SelectOption>
                </Select>
              )}
              {getUserLanguage() === "en" && (
                <Select
                  id="filter-id-project"
                  placeholder={polyglot.t("Sort")}
                  value={sortOrder}
                  onSelect={setSortOrder}
                >
                  <SelectOption key="A-Z" value="A-Z">
                    {polyglot.t("Name Of Target")} &nbsp;
                    <span className="appkiticon icon-arrow-up-outline"></span>
                  </SelectOption>
                  <SelectOption key="Z-A" value="Z-A">
                    {polyglot.t("Name Of Target")} &nbsp;
                    <span className="appkiticon icon-arrow-down-outline"></span>
                  </SelectOption>
                  <SelectOption key="Newest" value="Newest">
                    {polyglot.t("Newest")}
                  </SelectOption>
                  <SelectOption key="Created On" value="Created On">
                    {polyglot.t("Oldest")}
                  </SelectOption>
                </Select>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  };

  // HEADING BANNER OR THE USER LIST
  const readingBanner = () => {
    return (
      <div>
        <div className="container-fluid project_container_edit p-0">
          <div className="row view-center-request mr-0">
            <div className="col-md-12 p-0">
              <Panel className="a-panel-project mb-2">
                <div className="a-text-heading">
                  <div className="row">
                    <div className="col-md-1">
                      <div className="project-title-overflow project-data-desc view-center-name">
                        {polyglot.t("ID")}
                      </div>
                    </div>
                    <div className="col-md-2   ">
                      <div className="project-title-overflow-heading project-data-desc view-center-name pl-0">
                        {polyglot.t("Created on")}
                      </div>
                    </div>
                    <div className="col-md-4 ">
                      <div className="project-title-overflow project-data-desc view-center-name pl-3">
                        {polyglot.t("Target name/")}
                        <br />
                        {polyglot.t("Transactions ID")}
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="project-title-overflow project-data-desc view-center-name pl-0">
                        {polyglot.t("Request Status")}
                      </div>
                    </div>
                    <div className="col-md-2 pl-2">
                      <div className="project-title-overflow project-data-desc view-center-name-action pl-0">
                        {polyglot.t("Action")}
                      </div>
                    </div>
                  </div>
                </div>
              </Panel>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // DISPLAY PROJECTS LIST
  const displayProjectsList = () => {
    return (
      <>
        <div className="mt-3 full-width m-b-2">
          <h1 className="main_title_qb  ">{polyglot.t("List of requests")}</h1>
        </div>
        {displaySearchBox()}
        <div className="row m-0">
          {/*Request List */}
          <div className="col-md-8 p-0 ">
            {readingBanner()}
            {getCurrentPageProjects(filterProjects(pagelist)).map((Item, index) => {
              return <ViewRequest key={index} Item={Item} index={index} onEdit={editRequest} />;
            })}
            <div className="pagenationstylesP">
              <>
                <>
                  <Pagination
                    current={currentPage}
                    size="small"
                    pageSize={PER_PAGE}
                    total={filterProjects(pagelist).length}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                  />
                </>
              </>
            </div>
          </div>
          {/*Request Chart Side Bar */}
          <div className="col-md-4 ">
            <RequestChartsDetails
              totalRequests={targetList}
              statusCountList={statusCountList}
              categoryCountList={categoryCountList}
            />
          </div>
        </div>
      </>
    );
  };

  return (
    <div>
      {loading && displayLoadingSpinner()}

      {!loading && pagelist.length > 0 && displayProjectsList()}
    </div>
  );
}

export default RequestList;
