import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import GRCAssessmentLoginContextProvider from "../Components/GRC/PerformAssessment/Context/GRCAssessmentLoginContext";
import PerformAuth from "../Components/GRC/PerformAssessment/Pages/PerformAuth";
import PerfLogout from "../Components/GRC/PerformAssessment/PerfLogout";
import * as path from "../Constants/GRCAssessmentPath";
import { FooterRoutes } from "./LandingRoute";

// ROUTES OF APPLICATION BEFORE LOGIN
export const GRCAsessmentRoutesBeforeLogin = () => {
  return (
    <GRCAssessmentLoginContextProvider>
      <Switch>
        <Route exact path="/" render={() => <Redirect to={path.PERFORM_ASSESSMENT_AUTH_PATH} />} />
        <Route exact path={path.PERFORM_ASSESSMENT_AUTH_PATH} component={PerformAuth} />
        <Route path={path.PERFORM_ASSESSMENT_LOGOUT_PATH} component={PerfLogout} />
        <FooterRoutes />
      </Switch>
    </GRCAssessmentLoginContextProvider>
  );
};

// export default GRCAssessmentLandingRoute;
