import "../../../Assets/Css/QueenBase/Dashboard/Dashboard.css";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import Box from "./DragThingsToBoxes/Box";
import WidgetService from "../../../Services/QueenBase/Widget/WidgetService";
import deleteImg from "../../../Assets/Images/DFED/icon-x.svg";
import BoxData from "./DragThingsToBoxes/BoxData";
import DragThingsToBoxes from "./DragThingsToBoxes/DragThingsToBoxes";
import Drilldown from "./DrillDown/";
import DrillDownChild from "./DrillDown/DrillDownChild";
import MileStoneStatusDashboard from "./MileStoneStatusDashboard";
import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import ButtonSecondaryWithIcon from "../../../Assets/Components/ButtonSecondary/ButtonSecondaryWithIcon";
import { settingsIcon, plusCircle } from "../../../Assets/Icons";
import DashboardInitialImage from "../../../Assets/Images/QueenBase/Root/image-dashboard.svg";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/QueenBase/Root/loading-spin.svg";
import httpRequest from "../../../Services/Shared/HttpClient";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";

// DASHBOARD COMPONENT
function Dashboard() {
  // DATA FROM CONTEXT
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader();
  let isMounted = true;
  const [items, setItems] = useState([]);
  const [widgetData, setwidgetData] = useState([]);
  const [getItem, setGetItem] = useState({});
  const [getTemporaryUsersItem, setGetTemporaryUsersItem] = useState({});
  const [getExpiringUsersItem, setGetExpiringUsersItem] = useState({});
  const [getProjectsItem, setGetProjectsItem] = useState({});
  const [getProjectMileStonesItem, setGetProjectMileStonesItem] = useState({});
  const [getUpcomingMilestonesItem, setGetUpcomingMilestonesItem] = useState({});
  const [getPackageConsumptionItem, setGetPackageConsumptionItem] = useState({});
  const [getInvoiceDetailsItem, setGetInvoiceDetailsItem] = useState({});
  const [getExpensiveProjectsItem, setGetExpensiveProjectsItem] = useState({});
  const [getCustodianItem, setGetCustodianItem] = useState({});
  const [getEdiscoveryDataItem, setGetEdiscoveryDataItem] = useState({});
  const [getDocumentsItem, setGetDocumentsItem] = useState({});
  const [getProcessDiagramItem, setGetProcessDiagramItem] = useState({});
  const [getQueenProgressItem, setGetQueenProgressItem] = useState({});
  const [getDataUploadItem, setGetDataUploadItem] = useState({});
  const [getTopInvoiceItem, setGetTopInvoiceItem] = useState({});
  const [getExpensiveInvoiceItem, SetGetExpensiveInvoiceItem] = useState({});
  const [getGeneralStatisticsItem, setGetGeneralStatisticsItem] = useState({});
  const [getAnalyticsViewItem, setGetAnalyticsViewItem] = useState({});
  const [closedDivIds, setClosedDivIds] = useState([]);
  const [getTotalRequests, setGetTotalRequests] = useState({});
  const [getRequestStatus, setGetRequestStatus] = useState({});

  const [packageData, setpackageData] = useState([]);
  const [isSaveAndClose, setSaveAndClose] = useState(false); // toggles the side menu
  const [isEditMode, setEditMode] = useState(false);
  const [drillDownData, setDrillData] = useState([]);
  const [solutionsData, setSolutions] = useState();
  const [childData, setChildData] = useState({});
  const HttpRequest = new httpRequest();
  const [isLoading, setIsLoading] = useState(true);
  const [isChildDataLoading, setIsChildDataLoading] = useState(false);
  const [projectUsers, setprojectUsers] = useState([]);
  const [addMoreWidget, setAddMoreWidget] = useState([{ value: null }]);
  const [addMoreWidgetEd, setAddMoreWidgetEd] = useState([{ value: null }]);
  const [addMoreWidgetDf, setAddMoreWidgetDf] = useState([{ value: null }]);
  const [droppedItems, setDroppedItems] = useState([]);
  const [queenCount, setQueenCount] = useState(0);
  const [ediscoveryCount, setEdiscoveryCount] = useState(0);
  const [dfCount, setDfCount] = useState(0);
  const [giCount, setGiCount] = useState(0);
  const [RiCount, setRiCount] = useState(0);
  const [GrcCount, setGrcCount] = useState(0);
  const [SsCount, setSsCount] = useState(0);
  const [widgetQueenSequence, setWidgetQueenSequence] = useState(4);
  const [widgetEdiscoverySequence, setWidgetEdiscoverySequence] = useState(3);
  const [widgetDfSequence, setWidgetDfSequence] = useState(3);
  const [widgetQueenData, setWidgetQueenData] = useState([]);
  const [widgetEdiscoveryData, setWidgetEdiscoveryData] = useState([]);
  const [widgetDfData, setWidgetDfData] = useState([]);
  const [widgetGiData, setWidgetGiData] = useState([]);
  const [widgetQueenSeqArray, setWidgetQueenSeqArray] = useState([]);
  const [widgetGiSeqArray, setWidgetGiSeqArray] = useState([]);
  const [widgetEdiscoverySeqArray, setWidgetEdiscoverySeqArray] = useState([]);
  const [widgetDfSeqArray, setWidgetDfSeqArray] = useState([]);
  const [isGetData, setIsGetData] = useState(false);
  const [isGetEdiscoveryData, setIsEdiscoveryGetData] = useState(false);
  const [isGetDfData, setIsDfGetData] = useState(false);
  const [isGetGiData, setIsGiGetData] = useState(false);
  const [isWidgetsSaved, setIsWidgetsSaved] = useState(true);
  const [queenSavedWidgets, setQueenSavedWidgets] = useState(false);
  const [solutionEdiscoveryData, getEdiscoverySolutions] = useState([]);
  const [solutionDfData, getDfSolutions] = useState([]);
  const [solutionGiData, getGiSolutions] = useState([]);
  const [edexists, ediscoverysolutionexists] = useState(false);
  const [dfexists, dfsolutionexists] = useState(false);
  const [giexists, gisolutionexists] = useState(false);
  const [dfProgressData, setDfProgressData] = useState([]);
  const [queenDynamicWidgets, setQueenDynamicWidgets] = useState([]);
  const [ediscoveryDynamicWidgets, setEdiscoveryDynamicWidgets] = useState([]);
  const [dfDynamicWidgets, setDfDynamicWidgets] = useState([]);
  const [giDynamicWidgets, setGiDynamicWidgets] = useState([]);
  const language = getUserLanguage();

  var seqQueenLength = 4;
  var seqEdiscoveryLength = 3;
  var seqDfLength = 3;

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    getWidgetData();
    deletePreviousWidgetData();
  }, []);
  // FETCH WIDGET DATA
  const FetchWidgetData = async (widget, wtype) => {
    var res = await WidgetService.get().getData(widget, language);
    if (wtype == 1) {
      if (widget == "regularusers") {
        setGetItem({ data: res });
      }
      if (widget == "temporaryusers") {
        setGetTemporaryUsersItem({ data: res });
      }
      if (widget == "expiringusers") {
        setGetExpiringUsersItem({ data: res });
      }
      if (widget == "projects") {
        setGetProjectsItem({ data: res });
      }
      if (widget == "projectmilestones") {
        setGetProjectMileStonesItem({ data: res });
      }
      if (widget == "upcomingmilestones") {
        setGetUpcomingMilestonesItem({ data: res });
      }
      if (widget == "packageconsumption") {
        setGetPackageConsumptionItem({ data: res });
      }
      if (widget == "invoicedetails") {
        setGetInvoiceDetailsItem({ data: res });
      }
      if (widget == "expensiveprojects") {
        setGetExpensiveProjectsItem({ data: res });
      }
      if (widget == "topinvoices") {
        setGetTopInvoiceItem({ data: res });
      }
      if (widget == "expensiveinvoices") {
        SetGetExpensiveInvoiceItem({ data: res });
      }
      setIsGetData(true);
    }
    if (wtype == 2) {
      if (widget == "custodian") {
        setGetCustodianItem({ data: res });
      }
      if (widget == "data") {
        setGetEdiscoveryDataItem({ data: res });
      }
      if (widget == "documents") {
        setGetDocumentsItem({ data: res });
      }
      if (widget == "processdiagram") {
        setGetProcessDiagramItem({ data: res });
      }
      setIsEdiscoveryGetData(true);
    }
    if (wtype == 3) {
      if (widget == "queenbaseprogress") {
        setGetQueenProgressItem({ data: res });
      }
      if (widget == "dataupload") {
        setGetDataUploadItem({ data: res });
      }
      if (widget == "generalstatistics") {
        setGetGeneralStatisticsItem({ data: res });
      }
      if (widget == "analyticsview") {
        setGetAnalyticsViewItem({ data: res });
      }
      setIsDfGetData(true);
    }
    if (wtype == 4) {
      if (widget == "totalrequests") {
        setGetTotalRequests({ data: res });
      }
      if (widget == "requeststatus") {
        setGetRequestStatus({ data: res });
      }
      setIsGiGetData(true);
    }
    return res;
  };

  // GET DF DATA
  const GetDfData = (props) => {
    // RUNS ON INITIAL LOAD WHEN GetDfData RUNS
    useEffect(() => {
      if (!isGetDfData) {
        FetchWidgetData(props.data.widget, props.type);
      }
    }, [isGetDfData]);
    if (props.data.widget) {
      if (props.data.widget == "queenbaseprogress" && getQueenProgressItem) {
        return (
          <div className={(!isSaveAndClose && !isWidgetsSaved) || isEditMode ? "col-md-9" : "col-md-9"}>
            {!isBoxDataClosed(props.data.widget) && (
              <>
                {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                  <span
                    className="cursor-pointer queenprogress-deletedashboard"
                    onClick={() => closeWidget(props.data.widget, props.data.wid)}
                  >
                    <img className="queenprogress-deleteIcon" alt={""} src={deleteImg} />
                  </span>
                )}
                <BoxData
                  language={language}
                  dataprops={getQueenProgressItem.data}
                  label={props.data.widgettitle}
                  divId={props.data.widget}
                />
              </>
            )}
          </div>
        );
      } else if (props.data.widget == "dataupload" && getDataUploadItem) {
        return (
          <div
            className={
              (!isSaveAndClose && !isWidgetsSaved) || isEditMode
                ? "col-md-3 col-sm-12 dwidget"
                : "col-md-3 col-sm-12 dwidget"
            }
          >
            {!isBoxDataClosed(props.data.widget) && (
              <>
                {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                  <>
                    <div className="align-right">
                      <span
                        className="cursor-pointer delete-icon-widget"
                        onClick={() => closeWidget(props.data.widget, props.data.wid)}
                      >
                        <img alt={""} src={deleteImg} />
                      </span>
                    </div>
                  </>
                )}
                <BoxData
                  language={language}
                  dataprops={getDataUploadItem.data}
                  label={props.data.widgettitle}
                  divId={props.data.widget}
                />
              </>
            )}
          </div>
        );
      } else if (props.data.widget == "analyticsview" && getAnalyticsViewItem) {
        return (
          <div
            className={
              (!isSaveAndClose && !isWidgetsSaved) || isEditMode
                ? "col-md-3 col-sm-12 dwidget"
                : "col-md-3 col-sm-12 dwidget"
            }
          >
            {!isBoxDataClosed(props.data.widget) && (
              <>
                {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                  <>
                    <div className="align-right">
                      <span
                        className="cursor-pointer delete-icon-widget"
                        onClick={() => closeWidget(props.data.widget, props.data.wid)}
                      >
                        <img alt={""} src={deleteImg} />
                      </span>
                    </div>
                  </>
                )}
                <BoxData
                  language={language}
                  dataprops={getAnalyticsViewItem.data}
                  label={props.data.widgettitle}
                  divId={props.data.widget}
                />
              </>
            )}
          </div>
        );
      }
    }
  };

  // GET GI DATA
  const GetGiData = (props) => {
    // RUNS ON INITIAL LOAD WHEN GetEdiscoveryData RUNS
    useEffect(() => {
      if (!isGetGiData) {
        FetchWidgetData(props.data.widget, props.type);
      }
    }, [isGetGiData]);
    if (props.data.widget) {
      if (props.data.widget == "totalrequests" && getTotalRequests) {
        return (
          !isBoxDataClosed(props.data.widget) && (
            <div className="totalreq">
              {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                <>
                  <div className="align-right">
                    <span
                      className="cursor-pointer delete-icon-widget"
                      onClick={() => closeWidget(props.data.widget, props.data.wid)}
                    >
                      <img alt={""} src={deleteImg} />
                    </span>
                  </div>
                </>
              )}
              <BoxData
                language={language}
                dataprops={getTotalRequests.data}
                label={props.data.widgettitle}
                divId={props.data.widget}
              />
            </div>
          )
        );
      }
      if (props.data.widget == "requeststatus" && getRequestStatus) {
        return (
          !isBoxDataClosed(props.data.widget) && (
            <div className="list-of-req">
              {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                <>
                  <div className="align-right">
                    <span
                      className="cursor-pointer delete-icon-widget"
                      onClick={() => closeWidget(props.data.widget, props.data.wid)}
                    >
                      <img alt={""} src={deleteImg} />
                    </span>
                  </div>
                </>
              )}
              <BoxData
                language={language}
                dataprops={getRequestStatus.data}
                label={props.data.widgettitle}
                divId={props.data.widget}
              />
            </div>
          )
        );
      }
    }
    return <></>;
  };

  // GET EDISCOVERY DATA
  const GetEdiscoveryData = (props) => {
    // RUNS ON INITIAL LOAD WHEN GetEdiscoveryData RUNS
    useEffect(() => {
      if (!isGetEdiscoveryData) {
        FetchWidgetData(props.data.widget, props.type);
      }
    }, [isGetEdiscoveryData]);
    if (props.data.widget) {
      if (props.data.widget == "custodian" && getCustodianItem) {
        return (
          !isBoxDataClosed(props.data.widget) && (
            <div className="getedisco">
              {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                <>
                  <div className="align-right">
                    <span
                      className="cursor-pointer delete-icon-widget"
                      onClick={() => closeWidget(props.data.widget, props.data.wid)}
                    >
                      <img alt={""} src={deleteImg} />
                    </span>
                  </div>
                </>
              )}
              <BoxData
                language={language}
                dataprops={getCustodianItem.data}
                label={props.data.widgettitle}
                divId={props.data.widget}
              />
            </div>
          )
        );
      } else if (props.data.widget == "data" && getEdiscoveryDataItem) {
        return (
          !isBoxDataClosed(props.data.widget) && (
            <div>
              {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                <>
                  <div className="align-right">
                    <span
                      className="cursor-pointer delete-icon-widget"
                      onClick={() => closeWidget(props.data.widget, props.data.wid)}
                    >
                      <img alt={""} src={deleteImg} />
                    </span>
                  </div>
                </>
              )}
              <BoxData
                language={language}
                dataprops={getEdiscoveryDataItem.data}
                label={props.data.widgettitle}
                divId={props.data.widget}
              />
            </div>
          )
        );
      } else if (props.data.widget == "documents" && getDocumentsItem) {
        return (
          !isBoxDataClosed(props.data.widget) && (
            <div>
              {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                <>
                  <div className="align-right">
                    <span
                      className="cursor-pointer delete-icon-widget"
                      onClick={() => closeWidget(props.data.widget, props.data.wid)}
                    >
                      <img alt={""} src={deleteImg} />
                    </span>
                  </div>
                </>
              )}
              <BoxData
                language={language}
                dataprops={getDocumentsItem.data}
                label={props.data.widgettitle}
                divId={props.data.widget}
              />
            </div>
          )
        );
      } else if (props.data.widget == "processdiagram" && getProcessDiagramItem) {
        return (
          !isBoxDataClosed(props.data.widget) && (
            <div>
              {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                <>
                  <div className="align-right">
                    <span
                      className="cursor-pointer delete-icon-widget"
                      onClick={() => closeWidget(props.data.widget, props.data.wid)}
                    >
                      <img alt={""} src={deleteImg} />
                    </span>
                  </div>
                </>
              )}
              <BoxData
                language={language}
                dataprops={getProcessDiagramItem.data}
                label={props.data.widgettitle}
                divId={props.data.widget}
              />
            </div>
          )
        );
      }
    }
  };

  // GET E-DISCOVERY EMPTY OR FILLED BOXES
  const getEdiscoveryEmptyOrFilledBoxes = (widgetEdiscoverySeqArray) => {
    if (widgetEdiscoverySeqArray.length > 0) {
      return widgetEdiscoverySeqArray.map((element, i) => (
        <GetEdiscoveryData key={i} type="2" data={element} />
      ));
    }
    return (
      <>
        <Box
          isSaveAndClose={isSaveAndClose}
          isWidgetsSaved={isWidgetsSaved}
          isEditMode={isEditMode}
          targetKey="box"
          type="2"
          sequence="1"
          jsonData={widgetData}
          handleDrop={handleDrop}
          droppedItems={(items) => checkAndSetDroppedItems(items)}
          removeDroppedItems={removeItemFromDroppedItems}
        />
        <Box
          isSaveAndClose={isSaveAndClose}
          isWidgetsSaved={isWidgetsSaved}
          isEditMode={isEditMode}
          targetKey="box"
          type="2"
          sequence="2"
          jsonData={widgetData}
          handleDrop={handleDrop}
          droppedItems={(items) => checkAndSetDroppedItems(items)}
          removeDroppedItems={removeItemFromDroppedItems}
        />
        <Box
          isSaveAndClose={isSaveAndClose}
          isWidgetsSaved={isWidgetsSaved}
          isEditMode={isEditMode}
          targetKey="box"
          type="2"
          sequence="3"
          jsonData={widgetData}
          handleDrop={handleDrop}
          droppedItems={(items) => checkAndSetDroppedItems(items)}
          removeDroppedItems={removeItemFromDroppedItems}
        />
        <Box
          isSaveAndClose={isSaveAndClose}
          isWidgetsSaved={isWidgetsSaved}
          isEditMode={isEditMode}
          targetKey="box"
          type="2"
          sequence="4"
          jsonData={widgetData}
          handleDrop={handleDrop}
          droppedItems={(items) => checkAndSetDroppedItems(items)}
          removeDroppedItems={removeItemFromDroppedItems}
        />
      </>
    );
  };

  // GET DF EMPTY OR FILLED BOXES
  const getDfEmptyOrFilledBoxes = (widgetDfSeqArray) => {
    if (widgetDfSeqArray.length > 0) {
      return widgetDfSeqArray.map((element, i) => <GetDfData key={i} type="3" data={element} />);
    }
    return (
      <>
        <Box
          isSaveAndClose={isSaveAndClose}
          isWidgetsSaved={isWidgetsSaved}
          isEditMode={isEditMode}
          targetKey="box"
          type="3"
          sequence="1"
          jsonData={widgetData}
          handleDrop={handleDrop}
          droppedItems={(items) => checkAndSetDroppedItems(items)}
          removeDroppedItems={removeItemFromDroppedItems}
        />
      </>
    );
  };

  // GET GI EMPTY OR FILLED BOXES
  const getGiEmptyOrFilledBoxes = (widgetGiSeqArray) => {
    if (widgetGiSeqArray.length > 0) {
      return widgetGiSeqArray.map((element, i) => <GetGiData key={i} type="4" data={element} />);
    }
    return (
      <>
        <Box
          language={language}
          isSaveAndClose={isSaveAndClose}
          isWidgetsSaved={isWidgetsSaved}
          isEditMode={isEditMode}
          targetKey="box"
          type="4"
          sequence="1"
          jsonData={widgetData}
          handleDrop={handleDrop}
          droppedItems={(items) => checkAndSetDroppedItems(items)}
          removeDroppedItems={removeItemFromDroppedItems}
        />
        <Box
          language={language}
          isSaveAndClose={isSaveAndClose}
          isWidgetsSaved={isWidgetsSaved}
          isEditMode={isEditMode}
          targetKey="box"
          type="4"
          sequence="2"
          jsonData={widgetData}
          handleDrop={handleDrop}
          droppedItems={(items) => checkAndSetDroppedItems(items)}
          removeDroppedItems={removeItemFromDroppedItems}
        />
      </>
    );
  };

  // GET DF DATA
  const GetData = (props) => {
    if (!isGetData) {
      FetchWidgetData(props.data.widget, props.type);
    }
    if (props.data.widget) {
      if (props.data.widget == "regularusers" && getItem) {
        if (Object.keys(getItem).length === 0) {
          FetchWidgetData(props.data.widget, props.type);
        }
        return (
          <div
            className={
              (!isSaveAndClose && !isWidgetsSaved) || isEditMode
                ? "col-md-3 col-sm-12 dwidget"
                : "col-md-3 col-sm-12 dwidget"
            }
          >
            {!isBoxDataClosed(props.data.widget) && (
              <>
                {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                  <>
                    <div className="align-right">
                      <span
                        className="cursor-pointer delete-icon-widget"
                        onClick={() => closeWidget(props.data.widget, props.data.wid)}
                      >
                        <img alt={""} src={deleteImg} />
                      </span>
                    </div>
                  </>
                )}
                <BoxData
                  language={language}
                  dataprops={getItem.data}
                  label={props.data.widgettitle}
                  divId={props.data.widget}
                />
              </>
            )}
          </div>
        );
      } else if (props.data.widget == "temporaryusers" && getTemporaryUsersItem) {
        if (Object.keys(getTemporaryUsersItem).length === 0) {
          FetchWidgetData(props.data.widget, props.type);
        }
        return (
          <div
            className={
              (!isSaveAndClose && !isWidgetsSaved) || isEditMode
                ? "col-md-3 col-sm-12 dwidget"
                : "col-md-3 col-sm-12 dwidget"
            }
          >
            {!isBoxDataClosed(props.data.widget) && (
              <>
                {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                  <>
                    <div className="align-right">
                      <span
                        className="cursor-pointer delete-icon-widget"
                        onClick={() => closeWidget(props.data.widget, props.data.wid)}
                      >
                        <img alt={""} src={deleteImg} />
                      </span>
                    </div>
                  </>
                )}
                <BoxData
                  language={language}
                  dataprops={getTemporaryUsersItem.data}
                  label={props.data.widgettitle}
                  divId={props.data.widget}
                />
              </>
            )}
          </div>
        );
      } else if (props.data.widget == "expiringusers" && getExpiringUsersItem) {
        if (Object.keys(getExpiringUsersItem).length === 0) {
          FetchWidgetData(props.data.widget, props.type);
        }
        return (
          <div
            className={
              (!isSaveAndClose && !isWidgetsSaved) || isEditMode
                ? "col-md-3 col-sm-12 dwidget"
                : "col-md-3 col-sm-12 dwidget expiringusers"
            }
          >
            {!isBoxDataClosed(props.data.widget) && (
              <>
                {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                  <>
                    <div className="align-right">
                      <span
                        className="cursor-pointer delete-icon-widget"
                        onClick={() => closeWidget(props.data.widget, props.data.wid)}
                      >
                        <img alt={""} src={deleteImg} />
                      </span>
                    </div>
                  </>
                )}
                <BoxData
                  language={language}
                  dataprops={getExpiringUsersItem.data}
                  label={props.data.widgettitle}
                  divId={props.data.widget}
                />
              </>
            )}
          </div>
        );
      } else if (props.data.widget == "projects" && getProjectsItem) {
        if (Object.keys(getProjectsItem).length === 0) {
          FetchWidgetData(props.data.widget, props.type);
        }
        return (
          <div
            className={
              (!isSaveAndClose && !isWidgetsSaved) || isEditMode
                ? "col-md-3 col-sm-12 dwidget"
                : "col-md-3 col-sm-12 dwidget"
            }
          >
            {!isBoxDataClosed(props.data.widget) && (
              <>
                {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                  <>
                    <div className="align-right">
                      <span
                        className="cursor-pointer delete-icon-widget"
                        onClick={() => closeWidget(props.data.widget, props.data.wid)}
                      >
                        <img alt={""} src={deleteImg} />
                      </span>
                    </div>
                  </>
                )}
                <BoxData
                  language={language}
                  dataprops={getProjectsItem.data}
                  label={props.data.widgettitle}
                  divId={props.data.widget}
                />
              </>
            )}
          </div>
        );
      } else if (props.data.widget == "projectmilestones" && getProjectMileStonesItem) {
        if (Object.keys(getProjectMileStonesItem).length === 0) {
          FetchWidgetData(props.data.widget, props.type);
        }
        return (
          <div
            className={
              (!isSaveAndClose && !isWidgetsSaved) || isEditMode
                ? "col-md-3 col-sm-12 dwidget"
                : "col-md-3 col-sm-12 dwidget"
            }
          >
            {!isBoxDataClosed(props.data.widget) && (
              <>
                {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                  <>
                    <div className="align-right">
                      <span
                        className="cursor-pointer delete-icon-widget"
                        onClick={() => closeWidget(props.data.widget, props.data.wid)}
                      >
                        <img alt={""} src={deleteImg} />
                      </span>
                    </div>
                  </>
                )}
                <BoxData
                  language={language}
                  dataprops={getProjectMileStonesItem.data}
                  label={props.data.widgettitle}
                  divId={props.data.widget}
                />
              </>
            )}
          </div>
        );
      } else if (props.data.widget == "upcomingmilestones" && getUpcomingMilestonesItem) {
        if (Object.keys(getUpcomingMilestonesItem).length === 0) {
          FetchWidgetData(props.data.widget, props.type);
        }
        return (
          <div
            className={
              (!isSaveAndClose && !isWidgetsSaved) || isEditMode
                ? "col-md-3 col-sm-12 dwidget"
                : "col-md-3 col-sm-12 dwidget"
            }
          >
            {!isBoxDataClosed(props.data.widget) && (
              <>
                {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                  <>
                    <div className="align-right">
                      <span
                        className="cursor-pointer delete-icon-widget"
                        onClick={() => closeWidget(props.data.widget, props.data.wid)}
                      >
                        <img alt={""} src={deleteImg} />
                      </span>
                    </div>
                  </>
                )}
                <BoxData
                  language={language}
                  dataprops={getUpcomingMilestonesItem.data}
                  label={props.data.widgettitle}
                  divId={props.data.widget}
                />
              </>
            )}
          </div>
        );
      } else if (props.data.widget == "packageconsumption" && getPackageConsumptionItem) {
        if (Object.keys(getPackageConsumptionItem).length === 0) {
          FetchWidgetData(props.data.widget, props.type);
        }
        return (
          <div
            className={
              (!isSaveAndClose && !isWidgetsSaved) || isEditMode
                ? "col-md-3 col-sm-12 dwidget"
                : "col-md-3 col-sm-12 dwidget"
            }
          >
            {!isBoxDataClosed(props.data.widget) && (
              <>
                {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                  <>
                    <div className="align-right">
                      <span
                        className="cursor-pointer delete-icon-widget"
                        onClick={() => closeWidget(props.data.widget, props.data.wid)}
                      >
                        <img alt={""} src={deleteImg} />
                      </span>
                    </div>
                  </>
                )}
                <BoxData
                  language={language}
                  dataprops={getPackageConsumptionItem.data}
                  label={props.data.widgettitle}
                  divId={props.data.widget}
                />
              </>
            )}
          </div>
        );
      } else if (props.data.widget == "invoicedetails" && getInvoiceDetailsItem) {
        if (Object.keys(getInvoiceDetailsItem).length === 0) {
          FetchWidgetData(props.data.widget, props.type);
        }
        return (
          <div
            id="invoicedetails"
            className={
              (!isSaveAndClose && !isWidgetsSaved) || isEditMode
                ? "col-md-3 col-sm-12 dwidget"
                : "col-md-3 col-sm-12 dwidget"
            }
          >
            {!isBoxDataClosed(props.data.widget) && (
              <>
                {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                  <>
                    <div className="align-right">
                      <span
                        className="cursor-pointer delete-icon-widget"
                        onClick={() => closeWidget(props.data.widget, props.data.wid)}
                      >
                        <img alt={""} src={deleteImg} />
                      </span>
                    </div>
                  </>
                )}
                <BoxData
                  language={language}
                  dataprops={getInvoiceDetailsItem.data}
                  label={props.data.widgettitle}
                  divId={props.data.widget}
                />
              </>
            )}
          </div>
        );
      } else if (props.data.widget == "expensiveprojects" && getExpensiveProjectsItem) {
        if (Object.keys(getExpensiveProjectsItem).length === 0) {
          FetchWidgetData(props.data.widget, props.type);
        }
        return (
          <div
            className={
              (!isSaveAndClose && !isWidgetsSaved) || isEditMode
                ? "col-md-3 col-sm-12 dwidget"
                : "col-md-3 col-sm-12 dwidget"
            }
          >
            {!isBoxDataClosed(props.data.widget) && (
              <>
                {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                  <>
                    <div className="align-right">
                      <span
                        className="cursor-pointer delete-icon-widget"
                        onClick={() => closeWidget(props.data.widget, props.data.wid)}
                      >
                        <img alt={""} src={deleteImg} />
                      </span>
                    </div>
                  </>
                )}
                <BoxData
                  language={language}
                  dataprops={getExpensiveProjectsItem.data}
                  label={props.data.widgettitle}
                  divId={props.data.widget}
                />
              </>
            )}
          </div>
        );
      } else if (props.data.widget == "topinvoices" && getTopInvoiceItem) {
        if (Object.keys(getTopInvoiceItem).length === 0) {
          FetchWidgetData(props.data.widget, props.type);
        }
        return (
          <div
            className={
              (!isSaveAndClose && !isWidgetsSaved) || isEditMode
                ? "col-md-3 col-sm-12 dwidget"
                : "col-md-3 col-sm-12 dwidget"
            }
          >
            {!isBoxDataClosed(props.data.widget) && (
              <>
                {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                  <>
                    <div className="align-right">
                      <span
                        className="cursor-pointer delete-icon-widget"
                        onClick={() => closeWidget(props.data.widget, props.data.wid)}
                      >
                        <img alt={""} src={deleteImg} />
                      </span>
                    </div>
                  </>
                )}
                <BoxData
                  language={language}
                  dataprops={getTopInvoiceItem.data}
                  label={props.data.widgettitle}
                  divId={props.data.widget}
                />
              </>
            )}
          </div>
        );
      } else if (props.data.widget == "expensiveinvoices" && getExpensiveInvoiceItem) {
        if (Object.keys(getExpensiveInvoiceItem).length === 0) {
          FetchWidgetData(props.data.widget, props.type);
        }
        return (
          <div
            className={
              (!isSaveAndClose && !isWidgetsSaved) || isEditMode
                ? "col-md-3 col-sm-12 dwidget"
                : "col-md-3 col-sm-12 dwidget"
            }
          >
            {!isBoxDataClosed(props.data.widget) && (
              <>
                {((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                  <>
                    <div className="align-right">
                      <span
                        className="cursor-pointer delete-icon-widget"
                        onClick={() => closeWidget(props.data.widget, props.data.wid)}
                      >
                        <img alt={""} src={deleteImg} />
                      </span>
                    </div>
                  </>
                )}
                <BoxData
                  language={language}
                  dataprops={getExpensiveInvoiceItem.data}
                  label={props.data.widgettitle}
                  divId={props.data.widget}
                />
              </>
            )}
          </div>
        );
      } else {
        return <div>{polyglot.t("loading")}</div>;
      }
    } else {
      return <div>{polyglot.t("loading")}</div>;
    }
  };

  // TOGGLE IS E-DISCOVERY GET DATA
  const toggleIsEdiscoveryGetData = (bool) => {
    setIsEdiscoveryGetData(bool);
  };
  // HANDLE DROP A WIDGET
  const handleDrop = async () => {
    var WidgetData = await WidgetService.get().getWidgetData();
    var wData = WidgetData.userData;
    const ediscoveryWidgetData = [];
    const dfWidgetData = [];
    const giWidgetData = [];
    wData.map((data) => {
      if (data.packagetype == 2) {
        ediscoveryWidgetData.push(data);
      } else if (data.packagetype == 3) {
        dfWidgetData.push(data);
      } else if (data.packagetype == 4) {
        giWidgetData.push(data);
      }
    });
    setWidgetEdiscoveryData(ediscoveryWidgetData);
    setWidgetDfData(dfWidgetData);
    setWidgetGiData(giWidgetData);
    toggleIsEdiscoveryGetData(false);
    setIsGiGetData(false); // used to enable the GI component to make an API call
  };
  // CHECK IF BOX DATA CLOSED
  const isBoxDataClosed = (divId) => {
    return false;
  };
  // CLOSE WIDGET
  const closeWidget = async (divId, wid) => {
    const closedDivIdsClone = [...closedDivIds];
    if (divId) {
      closedDivIdsClone.push(divId);
      setClosedDivIds(closedDivIdsClone);
      const droppedItemsClone = [];
      droppedItems.forEach((obj) => {
        if (obj.divId !== divId) {
          droppedItemsClone.push(obj);
        }
      });
      setDroppedItems(droppedItemsClone);
      // API TO DELETE WIDGET DATA BY PASSING WIDGETID
      await HttpRequest.post("/deleteWidgetData", { widget: divId, wid: wid });
      var WidgetData = await WidgetService.get().getWidgetData();
      var wData = WidgetData.userData;
      var newqueendata = [];
      widgetQueenData.forEach((res) => {
        if (res.widget != divId) {
          newqueendata.push(res);
        }
      });
      setWidgetQueenData(newqueendata);
      const ediscoveryWidgetData = [];
      const dfWidgetData = [];
      const giWidgetData = [];
      for (var i = 0; i < wData.length; i++) {
        if (wData[i].packagetype == 2 && wData[i].delete_widget != 1) {
          ediscoveryWidgetData.push(wData[i]);
        } else if (wData[i].packagetype == 3 && wData[i].delete_widget != 1) {
          dfWidgetData.push(wData[i]);
        } else if (wData[i].packagetype == 4 && wData[i].delete_widget != 1) {
          giWidgetData.push(wData[i]);
        }
      }
      if (ediscoveryWidgetData.length > 0) {
        setIsEdiscoveryGetData(false);
      }
      setWidgetEdiscoveryData(ediscoveryWidgetData);
      setWidgetDfData(dfWidgetData);
      setWidgetGiData(giWidgetData);
      let q_count = 0;
      let ed_count = 0;
      let df_count = 0;
      let gi_count = 0;
      droppedItemsClone.forEach((ditems) => {
        if (ditems.type == 1 || (ditems.packagetype == 1 && ditems.isSave == 1)) {
          q_count = q_count + 1;
        } else if (ditems.type == 2 || (ditems.packagetype == 2 && ditems.isSave == 1)) {
          ed_count = ed_count + 1;
        } else if (ditems.type == 3 || (ditems.packagetype == 3 && ditems.isSave == 1)) {
          df_count = df_count + 1;
        } else if (ditems.type == 4 || (ditems.packagetype == 4 && ditems.isSave == 1)) {
          gi_count = gi_count + 1;
        }
      });
      let qcount = queenDynamicWidgets.length - q_count;
      let edcount = ediscoveryDynamicWidgets.length - ed_count;
      let dfcount = dfDynamicWidgets.length - df_count;
      let gicount = giDynamicWidgets.length - gi_count;
      setQueenCount(qcount);
      setEdiscoveryCount(edcount);
      setDfCount(dfcount);
      setGiCount(gicount);
    }
  };

  // DELETE PREVIOUS WIDGET DATA
  const deletePreviousWidgetData = async () => {
    try {
      // API TO DELETE PREVIOUS WIDGET DATA
      await HttpRequest.post("/deletePreviousWidgetData");
    } catch (error) {
      displayToastMessage(polyglot.t("Something went wrong!"), "error");
    }
  };
  // GET WIDGET DATA
  const getWidgetData = async () => {
    setIsLoading(true);
    try {
      var dynamicWidgets = await WidgetService.get().getDynamicWidgets();
      var dynData = dynamicWidgets.userData;
      var queen = [];
      var ediscovery = [];
      var df = [];
      var gi = [];
      dynData.map((data) => {
        if (data.packagetype == 1) {
          queen.push(data);
        } else if (data.packagetype == 2) {
          ediscovery.push(data);
        } else if (data.packagetype == 3) {
          df.push(data);
        } else if (data.packagetype == 4) {
          gi.push(data);
        }
      });
      setQueenDynamicWidgets(queen);
      setEdiscoveryDynamicWidgets(ediscovery);
      setDfDynamicWidgets(df);
      setGiDynamicWidgets(gi);

      var WidgetData = await WidgetService.get().getWidgetData();
      var dfProgressData = await WidgetService.get().getQueenBaseProgress();
      setDfProgressData(dfProgressData);
      var wData = WidgetData.userData;
      const queenWidgetData = [];
      const ediscoveryWidgetData = [];
      const dfWidgetData = [];
      const giWidgetData = [];
      const seqQueenArray = [];
      const seqEdiscoveryArray = [];
      const seqDfArray = [];
      const seqGiArray = [];
      const isSaveData = [];
      const queensaveddata = [];
      const ediscoverysaveddata = [];
      const dfsaveddata = [];
      const gisaveddata = [];
      let qSavedCount = 0;
      let edSavedCount = 0;
      let dfSavedCount = 0;
      let giSavedCount = 0;
      wData.map((data) => {
        isSaveData.push(data.isSave);
        if (data.packagetype == 1) {
          queenWidgetData.push(data);
          seqQueenArray.push(data.sequence);
          if (data.isSave == 1 && data.delete_widget != 1) {
            setQueenSavedWidgets(true);
            qSavedCount = qSavedCount + 1;
            queensaveddata.push(data.widget);
          }
        } else if (data.packagetype == 2) {
          ediscoveryWidgetData.push(data);
          seqEdiscoveryArray.push(data.sequence);
          if (data.isSave == 1 && data.delete_widget != 1) {
            edSavedCount = edSavedCount + 1;
            ediscoverysaveddata.push(data.widget);
          }
        } else if (data.packagetype == 3) {
          dfWidgetData.push(data);
          seqDfArray.push(data.sequence);
          if (data.isSave == 1 && data.delete_widget != 1) {
            dfSavedCount = dfSavedCount + 1;
            dfsaveddata.push(data.widget);
          }
        } else if (data.packagetype == 4) {
          giWidgetData.push(data);
          seqGiArray.push(data.sequence);
          if (data.isSave == 1 && data.delete_widget != 1) {
            giSavedCount = giSavedCount + 1;
            gisaveddata.push(data.widget);
          }
        }
      });
      if (isSaveData.indexOf(1) >= 0) {
        setIsWidgetsSaved(true);
      }
      setWidgetQueenData(queenWidgetData);
      setWidgetEdiscoveryData(ediscoveryWidgetData);
      setWidgetDfData(dfWidgetData);
      setWidgetGiData(giWidgetData);
      setWidgetQueenSeqArray(seqQueenArray);
      setWidgetGiSeqArray(seqGiArray);
      setWidgetEdiscoverySeqArray(seqEdiscoveryArray);
      setWidgetDfSeqArray(seqDfArray);
      wData.map((data) => {
        data.divId = data.widget;
        return data;
      });
      let dropped_queendata = 0;
      let dropped_ediscoverydata = 0;
      let dropped_dfdata = 0;
      let dropped_gidata = 0;
      droppedItems.forEach((obj) => {
        if (
          (obj.type == 1 || obj.packagetype == 1) &&
          obj.isSave != 1 &&
          obj.delete_widget != 1 &&
          !queensaveddata.includes(obj.widget) &&
          !queensaveddata.includes(obj.divId)
        ) {
          dropped_queendata = dropped_queendata + 1;
        }
        if (
          (obj.type == 2 || obj.packagetype == 2) &&
          obj.isSave != 1 &&
          obj.delete_widget != 1 &&
          !ediscoverysaveddata.includes(obj.widget) &&
          !ediscoverysaveddata.includes(obj.divId)
        ) {
          dropped_ediscoverydata = dropped_ediscoverydata + 1;
        }
        if (
          (obj.type == 3 || obj.packagetype == 3) &&
          obj.isSave != 1 &&
          obj.delete_widget != 1 &&
          !dfsaveddata.includes(obj.widget) &&
          !dfsaveddata.includes(obj.divId)
        ) {
          dropped_dfdata = dropped_dfdata + 1;
        }
        if (
          (obj.type == 4 || obj.packagetype == 4) &&
          obj.isSave != 1 &&
          obj.delete_widget != 1 &&
          !gisaveddata.includes(obj.widget) &&
          !gisaveddata.includes(obj.divId)
        ) {
          dropped_gidata = dropped_gidata + 1;
        }
      });

      let qcount = queen.length - qSavedCount - dropped_queendata;
      let edcount = ediscovery.length - edSavedCount - dropped_ediscoverydata;
      let dfcount = df.length - dfSavedCount - dropped_dfdata;
      let gicount = gi.length - giSavedCount - dropped_gidata;
      setQueenCount(qcount);
      setEdiscoveryCount(edcount);
      setDfCount(dfcount);
      setGiCount(gicount);
      setDroppedItems(wData);
    } catch (error) {
      displayToastMessage("Something went wrong!", "error");
    }
    setIsLoading(false);
  };

  // REMOVE ITEM FROM DROPPED ITEMS
  const removeItemFromDroppedItems = (divId) => {
    const droppedItemsClone = [];
    droppedItems.forEach((obj) => {
      if (obj.divId !== divId) {
        droppedItemsClone.push(obj);
      }
    });
    let q_count = 0;
    let ed_count = 0;
    let df_count = 0;
    let gi_count = 0;
    droppedItemsClone.forEach((ditems) => {
      if (ditems.type == 1 || (ditems.packagetype == 1 && ditems.isSave == 1)) {
        q_count = q_count + 1;
      } else if (ditems.type == 2 || (ditems.packagetype == 2 && ditems.isSave == 1)) {
        ed_count = ed_count + 1;
      } else if (ditems.type == 3 || (ditems.packagetype == 3 && ditems.isSave == 1)) {
        df_count = df_count + 1;
      } else if (ditems.type == 4 || (ditems.packagetype == 4 && ditems.isSave == 1)) {
        gi_count = gi_count + 1;
      }
    });
    let qcount = queenDynamicWidgets.length - q_count;
    let edcount = ediscoveryDynamicWidgets.length - ed_count;
    let dfcount = dfDynamicWidgets.length - df_count;
    let gicount = giDynamicWidgets.length - gi_count;
    setQueenCount(qcount);
    setEdiscoveryCount(edcount);
    setDfCount(dfcount);
    setGiCount(gicount);
    setDroppedItems(droppedItemsClone);
  };

  // CHECK AND SET DROPPED ITEMS
  const checkAndSetDroppedItems = (items) => {
    const droppedItemsClone = [...droppedItems];
    droppedItemsClone.push(items[0]);
    setDroppedItems(droppedItemsClone);
    let q_count = 0;
    let ed_count = 0;
    let df_count = 0;
    let gi_count = 0;
    items.forEach((ditems) => {
      if (ditems.type == 1) {
        q_count = q_count + 1;
      } else if (ditems.type == 2) {
        ed_count = ed_count + 1;
      } else if (ditems.type == 3) {
        df_count = df_count + 1;
      } else if (ditems.type == 4) {
        gi_count = gi_count + 1;
      }
    });
    let qcount = queenCount - q_count;
    let edcount = ediscoveryCount - ed_count;
    let dfcount = dfCount - df_count;
    let gicount = giCount - gi_count;
    setQueenCount(qcount);
    setEdiscoveryCount(edcount);
    setDfCount(dfcount);
    setGiCount(gicount);
  };

  // EDIT MODE
  const editMode = () => {
    setEditMode(!isEditMode);
  };
  // CLOSE EDIT MODE
  const closeEditMode = (bool) => {
    setSaveAndClose(bool);
    setEditMode(false);
  };

  // ADD MORE
  const addMore = () => {
    const values = [...addMoreWidget];
    values.push({ value: null });
    if (widgetQueenSequence < queenDynamicWidgets.length) {
      setAddMoreWidget(values);
      var queenSequence = widgetQueenSequence + 1;
      setWidgetQueenSequence(queenSequence);
    }
  };

  // GET DASHBOARD DETAILS
  const getDashboard = async (language) => {
    setIsLoading(true);
    try {
      /*
			PASSING language AND RECEIVES
			project, users and solutions details
			*/
      let data = await HttpRequest.get(`/getDashboardDetails/${language}`);
      if (isMounted) {
        setDrillData(data.DrillDown);
        setSolutions(data.solutions);
        setprojectUsers(data.projectUsers);
      }
    } catch (error) {
      displayToastMessage(polyglot.t("Something went wrong!"), "error");
    }
    setIsLoading(false);
  };

  // GET DATA OF THE SELECTED DATA TYPE PRESENT ON THE DONUT GRAPH
  const FetchchildData = async (data) => {
    setChildData({});
    if (data.label) {
      let ChildData = [];
      if (data.count > 0) {
        setIsChildDataLoading(true);
        /*
				API TO GET DATA OF SELECTED TYPE BY PASSING solutiontype
				*/
        ChildData = await HttpRequest.get(`/getChildData/${data.solutiontype}`);
        setIsChildDataLoading(false);
      }
      data.childData = ChildData;
      setChildData(data);
    }
  };

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    getDashboard(language);
    setChildData({});
    getWidgetData();
    return () => {
      isMounted = false;
    };
  }, [language]);

  // DISPLAY WIDGET BOXES
  const displayWidgetBoxes = () => {
    const sequenceArray = [];
    const sequenceNumsOfWidgets = [];
    const totalWidgetSequence = Array.from(Array(queenDynamicWidgets.length), (e, i) => i + 1);
    widgetQueenData &&
      widgetQueenData.forEach((element) => {
        sequenceNumsOfWidgets.push(element.sequence);
      });
    totalWidgetSequence.forEach((num) => {
      if (sequenceNumsOfWidgets.indexOf(num) >= 0) {
        let obj = widgetQueenData.filter((element) => element.sequence === num)[0];
        sequenceArray.push(obj);
      } else {
        sequenceArray.push(false);
      }
    });
    return sequenceArray.map((element, i) => {
      if ((element && element.delete_widget != 1) || (element && element.isSave == 1)) {
        return <GetData key={i} type="1" data={element} />;
      } else {
        return (
          <Box
            key={i}
            isSaveAndClose={isSaveAndClose}
            isWidgetsSaved={isWidgetsSaved}
            isEditMode={isEditMode}
            targetKey="box"
            type="1"
            sequence={i + 1}
            jsonData={widgetData}
            droppedItems={(items) => checkAndSetDroppedItems(items)}
            handleDrop={handleDrop}
            closeWidget={closeWidget}
            items={items}
            removeDroppedItems={removeItemFromDroppedItems}
          />
        );
      }
    });
  };

  // DISPLAY E-DISCOVERY WIDGET BOXES
  const displayEdiscoveryWidgetBoxes = () => {
    const sequenceArray = [];
    const sequenceNumsOfWidgets = [];
    var newarray = [];
    const totalWidgetSequence = Array.from(Array(ediscoveryDynamicWidgets.length), (e, i) => i + 1);
    newarray = widgetEdiscoveryData;
    newarray &&
      newarray.forEach((element) => {
        if (element) {
          sequenceNumsOfWidgets.push(element.sequence);
        }
      });
    totalWidgetSequence.forEach((num) => {
      if (sequenceNumsOfWidgets.indexOf(num) >= 0) {
        let obj = newarray.filter((element) => (element && element.sequence) === num)[0];
        sequenceArray.push(obj);
      } else {
        sequenceArray.push(false);
      }
    });
    return (
      <div className={(!isSaveAndClose && !isWidgetsSaved) || isEditMode ? "row" : "row"}>
        {sequenceArray.map((element, i) => {
          if (
            (element && edexists && element.delete_widget != 1) ||
            (element && !edexists && element.isSave == 1)
          ) {
            return (
              <div key={i} className="col-md-3">
                <GetEdiscoveryData key={i} type="2" data={element} />
              </div>
            );
          } else {
            return (
              <Box
                key={i}
                isSaveAndClose={isSaveAndClose}
                isWidgetsSaved={isWidgetsSaved}
                isEditMode={isEditMode}
                targetKey="box"
                type="2"
                sequence={i + 1}
                jsonData={widgetData}
                handleDrop={handleDrop}
                droppedItems={(items) => checkAndSetDroppedItems(items)}
                removeDroppedItems={removeItemFromDroppedItems}
              />
            );
          }
        })}
      </div>
    );
  };

  // DISPLAY DF WIDGET BOXES
  const displayDfWidgetBoxes = () => {
    const sequenceArray = [];
    const sequenceNumsOfWidgets = [];
    const totalWidgetSequence = Array.from(Array(dfDynamicWidgets.length), (e, i) => i + 1);
    var newarray = [];
    newarray = widgetDfData;
    newarray &&
      newarray.forEach((element) => {
        if (element) {
          sequenceNumsOfWidgets.push(element.sequence);
        }
      });
    totalWidgetSequence.forEach((num) => {
      if (sequenceNumsOfWidgets.indexOf(num) >= 0) {
        let obj = newarray.filter((element) => (element && element.sequence) === num)[0];
        sequenceArray.push(obj);
      } else {
        sequenceArray.push(false);
      }
    });
    return sequenceArray.map((element, i) => {
      if (element && element.delete_widget != 1) {
        return <GetDfData key={i} type="3" data={element} />;
      } else {
        return (
          <Box
            key={i}
            isSaveAndClose={isSaveAndClose}
            isWidgetsSaved={isWidgetsSaved}
            isEditMode={isEditMode}
            targetKey="box"
            type="3"
            sequence={i + 1}
            jsonData={widgetData}
            handleDrop={handleDrop}
            droppedItems={(items) => checkAndSetDroppedItems(items)}
            removeDroppedItems={removeItemFromDroppedItems}
          />
        );
      }
    });
  };

  // DISPLAY GI WIDGET BOXES
  const displayGiWidgetBoxes = () => {
    const sequenceArray = [];
    const sequenceNumsOfWidgets = [];
    const totalWidgetSequence = Array.from(Array(giDynamicWidgets.length), (e, i) => i + 1);
    var newarray = [];
    newarray = widgetGiData;
    newarray &&
      newarray.forEach((element) => {
        if (element) {
          sequenceNumsOfWidgets.push(element.sequence);
        }
      });
    totalWidgetSequence.forEach((num) => {
      if (sequenceNumsOfWidgets.indexOf(num) >= 0) {
        let obj = newarray.filter((element) => (element && element.sequence) === num)[0];
        sequenceArray.push(obj);
      } else {
        sequenceArray.push(false);
      }
    });
    return sequenceArray.map((element, i) => {
      if (element && element.delete_widget != 1) {
        return (
          <div key={i} className="col-md-3 gi-box-data">
            <GetGiData key={i} type="4" data={element} />
          </div>
        );
      } else {
        return (
          <Box
            key={i}
            language={language}
            isSaveAndClose={isSaveAndClose}
            isWidgetsSaved={isWidgetsSaved}
            isEditMode={isEditMode}
            targetKey="box"
            type="4"
            sequence={i + 1}
            jsonData={widgetData}
            handleDrop={handleDrop}
            droppedItems={(items) => checkAndSetDroppedItems(items)}
            removeDroppedItems={removeItemFromDroppedItems}
          />
        );
      }
    });
  };

  if (isLoading) {
    return (
      <div className="container-fluid">
        <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
      </div>
    );
  }
  if (solutionsData && drillDownData) {
    return (
      <>
        <div>
          <div className="dBoard_container m-l-1 p-0">
            <div className="row mr-0">
              <div className="col-md-2">
                <div
                  className={
                    (!isSaveAndClose && !isWidgetsSaved) || isEditMode
                      ? "col-md-12 dashboard-container-main sidebar"
                      : "hidesidebar"
                  }
                >
                  <DragThingsToBoxes
                    setEditMode={setEditMode}
                    setSaveAndClose={closeEditMode}
                    setwidgetData={setwidgetData}
                    droppedItems={droppedItems}
                    queenCount={queenCount}
                    ediscoveryCount={ediscoveryCount}
                    dfCount={dfCount}
                    RiCount={RiCount}
                    GrcCount={GrcCount}
                    SsCount={SsCount}
                    giCount={giCount}
                    queenDynamicWidgets={queenDynamicWidgets}
                    ediscoveryDynamicWidgets={ediscoveryDynamicWidgets}
                    dfDynamicWidgets={dfDynamicWidgets}
                    giDynamicWidgets={giDynamicWidgets}
                    solutionsData={solutionsData}
                  />
                </div>
              </div>
              <div
                className={
                  (!isSaveAndClose && !isWidgetsSaved) || isEditMode
                    ? "col-md-10 mt-3 dashboard-container"
                    : "col-md-12 mt-3 dashboard-container"
                }
              >
                <div className=" ">
                  <div className="row dd-header-title">
                    <div className="col-md-6">
                      <h1 className="main_title_qb">{polyglot.t("My Dashboard")}</h1>
                    </div>
                    <div className="col-md-6">
                      {!isEditMode && (
                        <div className="text-align-right">
                          <ButtonSecondaryWithIcon
                            showIcon
                            onClick={editMode}
                            ariaLabel={polyglot.t("Customise widget")}
                            iconName={settingsIcon}
                            size="sm"
                            alt={polyglot.t("Customise widget")}
                          >
                            {polyglot.t("Customise widget")}
                          </ButtonSecondaryWithIcon>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="display-flex">
                    <>
                      <Drilldown
                        drillDownData={drillDownData}
                        FetchchildData={FetchchildData}
                        childItems={childData}
                        giDynamicWidgets={giDynamicWidgets}
                        ediscoveryDynamicWidgets={ediscoveryDynamicWidgets}
                        dfDynamicWidgets={dfDynamicWidgets}
                      />

                      <MileStoneStatusDashboard projectUsers={projectUsers} />
                    </>
                  </div>
                </div>
                <div className="row">
                  <div className="container-fluid mt-4 dashboard-fix-right">
                    <div className="row">
                      <div className={!isEditMode ? "boxes my-widget-aftersave" : "boxes my-widget"}>
                        {widgetQueenSeqArray.length > 0 && queenSavedWidgets && displayWidgetBoxes()}
                        {(widgetQueenSeqArray.length == 0 || !queenSavedWidgets) && (
                          <div>
                            <Box
                              isSaveAndClose={isSaveAndClose}
                              isWidgetsSaved={isWidgetsSaved}
                              isEditMode={isEditMode}
                              targetKey="box"
                              type="1"
                              sequence="1"
                              jsonData={widgetData}
                              handleDrop={handleDrop}
                              droppedItems={(items) => checkAndSetDroppedItems(items)}
                              removeDroppedItems={removeItemFromDroppedItems}
                            />
                            <Box
                              isSaveAndClose={isSaveAndClose}
                              isWidgetsSaved={isWidgetsSaved}
                              isEditMode={isEditMode}
                              targetKey="box"
                              type="1"
                              sequence="2"
                              jsonData={widgetData}
                              handleDrop={handleDrop}
                              droppedItems={(items) => checkAndSetDroppedItems(items)}
                              removeDroppedItems={removeItemFromDroppedItems}
                            />
                            <Box
                              isSaveAndClose={isSaveAndClose}
                              isWidgetsSaved={isWidgetsSaved}
                              isEditMode={isEditMode}
                              targetKey="box"
                              type="1"
                              sequence="3"
                              jsonData={widgetData}
                              handleDrop={handleDrop}
                              droppedItems={(items) => checkAndSetDroppedItems(items)}
                              removeDroppedItems={removeItemFromDroppedItems}
                            />
                            <Box
                              isSaveAndClose={isSaveAndClose}
                              isWidgetsSaved={isWidgetsSaved}
                              isEditMode={isEditMode}
                              targetKey="box"
                              type="1"
                              sequence="4"
                              jsonData={widgetData}
                              handleDrop={handleDrop}
                              droppedItems={(items) => checkAndSetDroppedItems(items)}
                              removeDroppedItems={removeItemFromDroppedItems}
                            />
                          </div>
                        )}
                        <div className="add-more-widgets queen-add-more">
                          {addMoreWidget.map((field, idx) => {
                            if (idx > 0) {
                              return (
                                <div key={idx} className="queen">
                                  <Box
                                    isSaveAndClose={isSaveAndClose}
                                    isWidgetsSaved={isWidgetsSaved}
                                    isEditMode={isEditMode}
                                    type="1"
                                    targetKey="box"
                                    sequence={seqQueenLength + idx}
                                    jsonData={widgetData}
                                    handleDrop={handleDrop}
                                    droppedItems={(items) => checkAndSetDroppedItems(items)}
                                    removeDroppedItems={removeItemFromDroppedItems}
                                  />
                                </div>
                              );
                            }
                          })}
                        </div>
                      </div>

                      {(widgetQueenSeqArray.length == 0 || !queenSavedWidgets) &&
                        widgetQueenSequence < queenDynamicWidgets.length &&
                        ((!isSaveAndClose && !isWidgetsSaved) || isEditMode) && (
                          <div className="col-md-3">
                            <ButtonPrimaryWithIcon
                              onClick={addMore}
                              iconName={plusCircle}
                              ariaLabel={polyglot.t("Add more widgets")}
                            >
                              {polyglot.t("Add More")}
                            </ButtonPrimaryWithIcon>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
                <div
                  className={(!isSaveAndClose && !isWidgetsSaved) || isEditMode ? "row anotherBox" : "row"}
                >
                  <div className="container-fluid mt-4">
                    {Object.keys(childData).length > 0 && (
                      <DrillDownChild
                        dfsolutionexists={dfsolutionexists}
                        ediscoverysolutionexists={ediscoverysolutionexists}
                        gisolutionexists={gisolutionexists}
                        getDfSolutions={getDfSolutions}
                        getGiSolutions={getGiSolutions}
                        getEdiscoverySolutions={getEdiscoverySolutions}
                        setpackageData={setpackageData}
                        data={childData}
                        isChildDataLoading={isChildDataLoading}
                        solutionsData={solutionsData}
                        toggleIsEdiscoveryGetData={toggleIsEdiscoveryGetData}
                      />
                    )}
                    <div>
                      {packageData == 1 && (
                        <div>
                          {(widgetEdiscoverySeqArray.length > 0 || edexists) &&
                            packageData == 1 &&
                            childData &&
                            childData.label == "solutions" &&
                            displayEdiscoveryWidgetBoxes()}
                          {widgetEdiscoverySeqArray.length == 0 &&
                            packageData == 1 &&
                            childData &&
                            childData.label == "solutions" &&
                            !edexists && (
                              <div className="row">
                                {getEdiscoveryEmptyOrFilledBoxes(widgetEdiscoverySeqArray)}
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                    <div>
                      {childData.childData &&
                        solutionsData.length > 0 &&
                        packageData &&
                        packageData == 2 &&
                        childData &&
                        childData.label == "solutions" && (
                          <>
                            <div className="row">
                              {dfProgressData && (
                                <div className="col-md-9">
                                  <BoxData
                                    language={language}
                                    dataprops={dfProgressData}
                                    label="Queen Base Progress"
                                    divId="queenbaseprogress"
                                  />
                                </div>
                              )}
                              {(widgetDfSeqArray.length > 0 || dfexists) && displayDfWidgetBoxes()}
                              {widgetDfSeqArray.length == 0 &&
                                !dfexists &&
                                getDfEmptyOrFilledBoxes(widgetDfSeqArray)}
                            </div>
                          </>
                        )}
                    </div>
                    <div
                      className={
                        (!isSaveAndClose && !isWidgetsSaved) || isEditMode
                          ? "add-more-widgets-eDiscovery"
                          : "add-more-widgets-eDiscovery add-more-eDiscovery-saveclose"
                      }
                    >
                      {addMoreWidgetEd.map((field, idx) => {
                        if (idx > 0) {
                          return (
                            <div key={idx} className="add-more-widgets-eDiscovery">
                              {packageData == 1 && (
                                <Box
                                  isSaveAndClose={isSaveAndClose}
                                  isWidgetsSaved={isWidgetsSaved}
                                  isEditMode={isEditMode}
                                  targetKey="box"
                                  type="2"
                                  sequence={seqEdiscoveryLength + idx}
                                  jsonData={widgetData}
                                  handleDrop={handleDrop}
                                  droppedItems={(items) => checkAndSetDroppedItems(items)}
                                  removeDroppedItems={removeItemFromDroppedItems}
                                />
                              )}
                            </div>
                          );
                        }
                      })}
                      {addMoreWidgetDf.map((field, idx) => {
                        if (idx > 0) {
                          return (
                            <div className="df">
                              {packageData != 1 && (
                                <Box
                                  isSaveAndClose={isSaveAndClose}
                                  isWidgetsSaved={isWidgetsSaved}
                                  isEditMode={isEditMode}
                                  targetKey="box"
                                  type="3"
                                  sequence="2"
                                  jsonData={widgetData}
                                  droppedItems={(items) => checkAndSetDroppedItems(items)}
                                  removeDroppedItems={removeItemFromDroppedItems}
                                />
                              )}
                            </div>
                          );
                        }
                      })}
                    </div>

                    <div>
                      {childData.childData &&
                        solutionsData.length > 0 &&
                        packageData &&
                        packageData == 4 &&
                        childData &&
                        childData.label == "solutions" && (
                          <>
                            <div className="row my-widget">
                              {(widgetGiSeqArray.length > 0 || giexists) && displayGiWidgetBoxes()}
                              {widgetGiSeqArray.length == 0 &&
                                !giexists &&
                                getGiEmptyOrFilledBoxes(widgetGiSeqArray)}
                            </div>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  return (
    <>
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6" style={{ textAlign: "center" }}>
          <img className="project_initial_image" src={DashboardInitialImage} alt="asserts" />
        </div>
        <div className="col-md-3"></div>
      </div>
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6" style={{ textAlign: "center" }}>
          <div className="project_heading">
            <p className="headertest mt-3 mb-2">{polyglot.t("Dashboard")}</p>
            <p className="user-flow-text">{polyglot.t("No data found")}.</p>
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </>
  );
}
Dashboard.propTypes = {
  data: PropTypes.object,
};
export default Dashboard;
