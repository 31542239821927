import { Accordions, AccordionItem, AccordionItemHeader, AccordionItemBody } from "appkit-react";
import React from "react";

import CustomModal from "../../../../Assets/Components/Modal";
import { polyglotLoader } from "../../../../Services/Shared/Translate";

function ProcessGridViewPopup(props) {
  const { show, toggleViewModal, formBody } = props;
  const polyglot = polyglotLoader();
  const handleCancel = () => {
    const body = {};
    toggleViewModal(body);
  };

  return (
    <CustomModal
      show={show}
      key={formBody.id}
      onCancel={handleCancel}
      title={`${polyglot.t("Details for request ID")}: ${formBody.id}`}
      Footer={[]}
      showCloseButton
    >
      <>
        <div className="row">
          <div className="f-acc-B col-md-6">{polyglot.t("Created on")} :</div>
          <div className="f-acc-B col-md-6 count-align-t">
            <b>{formBody.requestedOn}</b>
          </div>
        </div>
        <div className="row">
          <div className="f-acc-B col-md-6">{polyglot.t("Created by")} :</div>
          <div className="f-acc-B col-md-6 count-align-t">
            <b>{formBody.viewCreaterName}</b>
          </div>
        </div>
        <div className="row">
          <div className="f-acc-B col-md-6">{polyglot.t("Status")} :</div>
          <div className="f-acc-B col-md-6 count-align-t">
            <b>{formBody.statusValue}</b>
          </div>
        </div>
        <div className="row">
          <div className="f-acc-B col-md-6">{polyglot.t("Entities")} :</div>
          <div className="f-acc-B col-md-6 count-align-t">
            <b>{formBody.viewEntities}</b>
          </div>
        </div>
        <div className="row">
          <div className="f-acc-B col-md-6">{polyglot.t("Date range")} :</div>
          <div className="f-acc-B col-md-6 count-align-t">
            <b>
              {formBody.viewTimeScopeFrom} - {formBody.viewTimeScopeTo}
            </b>
          </div>
        </div>
        <div className="accordian-div">
          <Accordions className="" kind="transparent">
            <AccordionItem itemId={"Groups"}>
              <AccordionItemHeader title={polyglot.t("Groups")} />
              <AccordionItemBody>
                {formBody.viewGroupName &&
                  formBody.viewGroupName.map((e, i) => {
                    return (
                      <>
                        <div className="row">
                          <div className="f-acc-B col-md-6">{e.grp}:</div>
                          <div className="f-acc-B col-md-6 count-align-t">{e.target.join()}</div>
                        </div>
                      </>
                    );
                  })}
              </AccordionItemBody>
            </AccordionItem>
          </Accordions>
        </div>
        <div className="row">
          <div className="f-acc-B col-md-6">{polyglot.t("Generate query")} :</div>
          <div className="f-acc-B col-md-6 count-align-t">
            <b>{formBody.viewGenerateQuery}</b>
          </div>
        </div>
        <div className="row">
          <div className="f-acc-B col-md-6">{polyglot.t("Tableau Url")} :</div>
          <div className="f-acc-B col-md-6 count-align-t">
            <b>{formBody.viewTableauUrl}</b>
          </div>
        </div>
      </>
    </CustomModal>
  );
}

export default ProcessGridViewPopup;
