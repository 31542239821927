/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable no-cond-assign */
/* eslint-disable no-constant-condition */
import "./Xtractor.css";
import React from "react";

import ButtonPrimaryExpandable from "../../../../Assets/Components/ButtonPrimary/ButtonPrimaryExpandable";
import LinkText from "../../../../Assets/Components/LinkText/LinkText";
import ProjectSurveyService from "../../../../Services/DF/ProjectSurveyService";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";

// COMPONENT TO DOWNLOAD LATEST XTRACTOR
const Xtractor = () => {
  const polyglot = polyglotLoader();

  // DOWNLOAD THE XTRACTOR MANUAL
  const downloadXtractorManual = async () => {
    try {
      // API TO DOWNLOAD XTRACTOR USER MANUAL
      ProjectSurveyService.get()
        .downloadXtractorUserManual()
        .then((downloadResponse) => {
          var buffer = Buffer.from(downloadResponse.buffer);
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", downloadResponse.fileName);
          document.body.appendChild(link);
          link.click();
          displayToastMessage(polyglot.t("Downloaded successfully"), "success");
        })
        .catch((error) => {
          if (
            (error.message =
              "First argument must be a string, Buffer, ArrayBuffer, Array, or array-like object.")
          ) {
            displayToastMessage(polyglot.t("No file found!!"), "error");
          } else {
            displayToastMessage(error.message, "error");
          }
        });
    } catch (error) {
      displayToastMessage(error.message, "error");
    }
  };
  // DOWNLAOD XTRACTOR APPLICATION
  const downloadXtractorApplication = async () => {
    try {
      // API TO DOWNLOAD XTRACTOR APPLICATION
      ProjectSurveyService.get()
        .downloadXtractorApplication()
        .then((downloadResponse) => {
          var buffer = Buffer.from(downloadResponse.buffer);
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", downloadResponse.fileName);
          document.body.appendChild(link);
          link.click();
          displayToastMessage(polyglot.t("Downloaded successfully"), "success");
        })
        .catch((error) => {
          if (
            (error.message =
              "First argument must be a string, Buffer, ArrayBuffer, Array, or array-like object.")
          ) {
            displayToastMessage("No file found!!", "error");
          } else {
            displayToastMessage(error.message, "error");
          }
        });
    } catch (error) {
      displayToastMessage(error.message, "error");
    }
  };
  return (
    <>
      <ButtonPrimaryExpandable
        onClick={downloadXtractorApplication}
        className="download-xtractor-button"
        ariaLabel={polyglot.t("Download the latest PwC Xtractor")}
      >
        {polyglot.t("Download the latest PwC Xtractor")}
        <br />
        {polyglot.t("Version")}: 5.1
      </ButtonPrimaryExpandable>
      <div className="df-xt-data">
        <div className="f-acc-B">
          {" "}
          <b>{polyglot.t("Installing and using PwC Xtractor")}</b> <br />
          {polyglot.t(
            "We’ve put together some detailed installation instructions which you can download here:",
          )}
          &nbsp;
          <LinkText onClick={downloadXtractorManual} ariaLabel={polyglot.t("download instructions")}>
            <u>{polyglot.t("download instructions")}</u>
          </LinkText>
        </div>
        <br />
        <div>
          <b>{polyglot.t("The data extraction process includes the following steps:")}</b>
          <br />
          <ul>
            <ol className="updatedText-Color">
              1.&nbsp;{polyglot.t("Installing extraction tool Xtract on your SAP system")}
            </ol>
            <ol className="updatedText-Color">
              2.&nbsp;{polyglot.t("Running Xtract with the provided parameter file")}
            </ol>
            <ol className="updatedText-Color">
              3.&nbsp;{polyglot.t("Upload extracted data (zip file) onto CDS platform")}
            </ol>
          </ul>
        </div>
      </div>
    </>
  );
};

export default Xtractor;
