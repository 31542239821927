import React, { Component } from "react";
import { withRouter } from "react-router-dom";
import FastforwardButton from "../DFED/FastForward";
import { getProjectInfo } from "../../Services/DFED/ProjectService";
import ProjectService from "../../Services/QueenBase/Projects/ProjectService";
import CustodianProcessDetails from "../../Services/DFED/HomeService";
import displayToastMessage from "../QueenBase/Root/displayToastMessage";
import "../../Assets/Css/QueenBase/Root/Login.css";
import { checkPermission } from "../../utility/checkPermission";
import commonFunctionForCallService from "../../utility/commonFunctionForCallService";
import * as module_Solution_Name from "../../Constants/moduleName";
import { polyglotLoader } from "../../Services/Shared/Translate";
import { Select } from "antd";
import LinkText from "../../Assets/Components/LinkText/LinkText";
import {
  getProjectID_Of_solution_module,
  getSolutionID_Of_solution_module,
  openExternalUrl,
  setSolutionID_Of_solution_module,
} from "../../Services/Shared/Handlers";
import { DFED_HOME, VIEW_PROJECT_PATH } from "../../Constants/Path";
import SolutionService from "../../Services/QueenBase/Solutions/SolutionService";
import { DataContext } from "../../Services/Shared/Store";
import ConfirmBoxExitForm from "../../Assets/Components/ConfirmBoxExitForm";

const { Option } = Select;

const custodianProcessDetails = new CustodianProcessDetails();
//  THIS NAVABR CLASS IS RESPONSIBLE FOR HANDLING ALL REQUIRE FUNCTIONALITY OFNAVBAR REALTED
class Navbar extends Component {
  constructor(props) {
    super();
    this.state = {
      // selectedComponent: Home,
      selectedComponentName: "",
      projects: [],
      selectedProject:
        props.location.state && props.location.state.selectedProjectId
          ? props.location.state.selectedProjectId.toString()
          : "",
      getTabs: [],
      selectedProjectName: "",
      rolePermission: {},
      expandHeader: false,
      solutionsList: [],
      selectedSolution: getSolutionID_Of_solution_module(),
      openConfirmBox: false,
      selectedSolutionToRedirect: {},
      redirectFromSamePage: false,
    };
  }
  projectId = getProjectID_Of_solution_module();
  solutionID = getSolutionID_Of_solution_module();

  static contextType = DataContext;

  // GET SOLUTIONS OF THE PROJECT
  getSolutionsOfTheProject = async () => {
    try {
      const APIResponse = await ProjectService.get().getSolutionNameFromProjectID(this.projectId);
      if (APIResponse.CODE) {
        const { projectName, solutions } = APIResponse.data;
        this.setState({ solutionsList: solutions, selectedProjectName: projectName });
      } else {
        displayToastMessage(APIResponse.MSG, "error");
      }
    } catch (error) {
      displayToastMessage(error.message, "error");
    }
  };

  checkProjectDetailsAndRun = async () => {
    this.setState({ selectedProject: this.projectId });
    this.getSolutionsOfTheProject(); // fetch solutions by project id
  };

  // GET PATH OF THE SELECTED SOLUTION
  getPathOfSelectedSolution = (solutionId) => {
    try {
      const routePath = this.state.solutionsList.find((solution) => solution.id === solutionId).routePath;
      return routePath;
    } catch (error) {
      displayToastMessage(error.message, "error");
      return "";
    }
  };

  componentDidMount() {
    if (this.props.location.state && this.props.location.state.selectedProjectId) {
      sessionStorage.setItem("newSelecedProjectID", this.props.location.state.selectedProjectId);
    }
    this.checkProjectDetailsAndRun();
  }

  // GO BACK TO APPLICATION HOME
  goBackToQueenbase = () => {
    this.props.history.push(VIEW_PROJECT_PATH);
  };

  componentDidUpdate(prevProps, prevState) {
    // if solutionsList gets updated, then run the below condition
    if (prevState.solutionsList !== this.state.solutionsList) {
      if (this.state.solutionsList.length > 0) {
        const solutionIDs = this.state.solutionsList.map((each) => Number(each.id));
        if (solutionIDs.includes(Number(this.state.selectedSolution))) {
          // then this solution is part of the selected project
          this.getMenuTabs();
          this.getApplicationRolesWithPermissionForFastForward();
        } else {
          this.goBackToQueenbase();
        }
      }
    }
  }

  // GETTING ALL MENU TABS DYNAMICALLY
  async getMenuTabs() {
    let tabs = await custodianProcessDetails.getMenu();
    if (tabs && tabs.data && tabs.data.length > 0) {
      this.setState({
        getTabs: tabs.data,
      });
    } else {
      let Msg = "No Data";
      displayToastMessage(Msg, "error");
    }
  }

  // REDIRECTING TO PIPELINE PAGE AFTER CHECKING PERMISSION
  openPipeline = () => {
    if (checkPermission(this.state.rolePermission, module_Solution_Name.FAST_FORWARD_MODULE, "View")) {
      this.props.history.push({
        pathname: "/dfed/Fastforward",
        state: { selectedProjectId: this.state.selectedProject },
      });
    } else {
      displayToastMessage(
        this.polyglot.t("Unauthorize access ! you are not allowed to perform this action"),
        "error",
      );
    }
  };

  closeFormAndRedirect = () => {
    const { formGotClosed } = this.context;
    this.toggleConfirmBox();
    // REDIRECT FUNCTION FOR NAV OPTIONS
    this.redirectionOnClosingConfirmBox();
    // REDIRECT FUNCTION FOR SOLUTION SELECTION
    if (this.state.selectedSolutionToRedirect.url) {
      this.redirectToSolutionOnClosingConfirmBox();
    }
    formGotClosed();
  };

  checkAndSwitchState = (url) => {
    const currentUrl = this.props.history.location.pathname;
    const redirectFromSamePage = url === currentUrl;

    if (redirectFromSamePage) {
      this.setState((prevState) => ({ redirectFromSamePage: !prevState.redirectFromSamePage }));
    }
  };

  // redirect to selected nav option on closing conform box
  redirectionOnClosingConfirmBox = () => {
    try {
      const url = this.getUrlByTabName(this.state.selectedNavTav);
      this.checkAndSwitchState(url);

      this.props.history.push({
        pathname: url,
        state: {
          selectedProjectId: this.state.selectedProject,
          selectedProjectName: this.state.selectedProjectName,
          redirectFromSameUrl: this.state.redirectFromSamePage,
        },
      });
      this.setState({ selectedNavTav: null });
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // redirect to selected solution on closing confirm box
  redirectToSolutionOnClosingConfirmBox = () => {
    try {
      this.props.history.push({
        pathname: this.state.selectedSolutionToRedirect.url,
        state: { selectedProjectId: this.projectId },
      });
      if (this.state.selectedSolutionToRedirect.id) {
        setSolutionID_Of_solution_module(this.state.selectedSolutionToRedirect.id);
      }
      this.setState({ selectedSolutionToRedirect: {} });
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  toggleConfirmBox = () => {
    this.setState({
      openConfirmBox: !this.state.openConfirmBox,
    });
  };

  // HANDLING COMPONENT WHEN CLICK ON MENU TABS

  SelectingComponentHandler = (e) => {
    try {
      const { isFormOpen } = this.context;
      if (isFormOpen) {
        this.toggleConfirmBox();
        if (e && e !== "") {
          this.setState({
            selectedNavTav: e,
          });
        } else {
          this.setState({
            selectedNavTav: this.state.selectedComponentName,
          });
        }
      } else {
        if (e && e !== "") {
          this.setState({
            selectedNavTav: e,
          });
        } else {
          if (this.state.selectedComponentName) {
            this.setState(
              {
                selectedNavTav: this.state.selectedComponentName,
              },
              () => {
                this.props.history.push({
                  pathname: this.props.location.pathname,
                  state: {
                    selectedProjectId: this.state.selectedProject,
                    selectedProjectName: this.state.selectedProjectName,
                  },
                });
              },
            );
          } else {
            // this.props.history.push({ pathname: this.props.location.pathname, state: { selectedProjectId: this.state.selectedProject, selectedProjectName: this.state.selectedProjectName } })
          }
        }

        switch (e) {
          case "Home":
            this.props.history.push({
              pathname: "/dfed/home",
              state: {
                selectedProjectId: this.state.selectedProject,
                selectedProjectName: this.state.selectedProjectName,
              },
            });
            break;
          case "Custodians":
            this.props.history.push({
              pathname: "/dfed/custodian",
              state: {
                selectedProjectId: this.state.selectedProject,
                selectedProjectName: this.state.selectedProjectName,
              },
            });
            break;
          case "Keywords":
            this.props.history.push({
              pathname: "/dfed/keywords",
              state: {
                selectedProjectId: this.state.selectedProject,
                selectedProjectName: this.state.selectedProjectName,
              },
            });
            break;
          case "Profiles":
            this.props.history.push({
              pathname: "/dfed/profileList",
              state: { selectedProjectId: this.state.selectedProject, fromTabNavigation: null },
            });
            break;
          case "Analysis":
            this.props.history.push({
              pathname: "/dfed/analysis",
              state: {
                selectedProjectId: this.state.selectedProject,
                selectedProjectName: this.state.selectedProjectName,
              },
            });
            break;
          case "Processing":
            this.props.history.push({
              pathname: "/dfed/processing",
              state: {
                selectedProjectId: this.state.selectedProject,
                selectedProjectName: this.state.selectedProjectName,
              },
            });
            break;
          case "Review":
            this.props.history.push({
              pathname: "/dfed/reviewdetails",
              state: {
                selectedProjectId: this.state.selectedProject,
                selectedProjectName: this.state.selectedProjectName,
              },
            });
            break;
          case "Admin":
            this.props.history.push({
              pathname: "/dfed/admin",
              state: {
                selectedProjectId: this.state.selectedProject,
                selectedProjectName: this.state.selectedProjectName,
              },
            });
            break;
          case "Production":
            this.props.history.push({
              pathname: "/dfed/production",
              state: {
                selectedProjectId: this.state.selectedProject,
                selectedProjectName: this.state.selectedProjectName,
              },
            });
            break;
          default:
            break;
        }
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
    // this.setState({ selectedComponentName: e })
  };

  checkProjectAvailable = (element) => {
    let newId = sessionStorage.getItem("newSelecedProjectID");
    return element.projectid === Number(newId);
  };

  // CHOOSE SOLUTION ON NAVBAR
  changeSolutionHandler = (value) => {
    try {
      const { isFormOpen } = this.context;
      const initialPathOfTheSelectedSolution = this.getPathOfSelectedSolution(value);
      if (isFormOpen) {
        // form is in open state, hence user cannot navigate to other screens
        this.toggleConfirmBox();
        const solutionBody = {
          url: initialPathOfTheSelectedSolution,
          id: value,
        };
        this.setState({ selectedSolutionToRedirect: solutionBody });
      } else {
        // REDIRECT TO THE SELECTED SOLUTION
        if (initialPathOfTheSelectedSolution !== "") {
          setSolutionID_Of_solution_module(value);
          // navigate to the solution screen
          this.props.history.push({
            pathname: initialPathOfTheSelectedSolution,
            state: { selectedProjectId: this.projectId },
          });
        }
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // GETTING ROLES FOR THAT NAVBAR COMPONENT==================
  getApplicationRolesWithPermissionForFastForward = async () => {
    let selectedProjectId = this.state.selectedProject;
    let permissionData = await commonFunctionForCallService.commonFunctionForCallService(
      module_Solution_Name.DFED_SOLUTION,
      selectedProjectId,
      module_Solution_Name.FAST_FORWARD_MODULE,
    );
    if (permissionData) {
      this.setState({
        rolePermission: permissionData,
      });
    }
  };

  expandHeaderFunc = () => {
    this.setState((prev) => ({
      expandHeader: !prev.expandHeader,
    }));
  };

  getUrlByTabName = (tabName) => {
    try {
      let tabURL = "";
      switch (tabName) {
        case "Home":
          tabURL = "/dfed/home";
          break;
        case "Custodians":
          tabURL = "/dfed/custodian";
          break;
        case "Keywords":
          tabURL = "/dfed/keywords";
          break;
        case "Profiles":
          tabURL = "/dfed/profileList";
          break;
        case "Analysis":
          tabURL = "/dfed/analysis";
          break;
        case "Processing":
          tabURL = "/dfed/processing";
          break;
        case "Review":
          tabURL = "/dfed/reviewdetails";
          break;
        case "Admin":
          tabURL = "/dfed/admin";
          break;
        case "Production":
          tabURL = "/dfed/production";
          break;
        default:
          break;
      }
      return tabURL;
    } catch (err) {
      displayToastMessage(err.message, "error");
      return "";
    }
  };

  // REDIRECT TO SELECTED OPTION
  redirection = (tabName) => {
    const { isFormOpen } = this.context;
    if (isFormOpen) {
      // form is in open state, hence user cannot navigate to other screens
      this.toggleConfirmBox();
      this.setState({ selectedNavTav: tabName });
    } else {
      // const url = this.getUrlByTabName(tabName)
      // props.history.push({ pathname: url, state: { selectedProjectId: this.projectId, selectedProjectName: this.state.selectedProjectName } });
      // this.setState({ selectedNavTav: null });
      this.SelectingComponentHandler(tabName);
    }
  };

  // RENDER METHOD THAT WILL RENDER UI

  render() {
    var polyglot = polyglotLoader();
    let url = this.props.location.pathname.toLowerCase();
    var selectedHeader = "";

    if (url.includes("home")) {
      selectedHeader = "Home";
    } else if (url.includes("custodian")) {
      selectedHeader = "Custodians";
    } else if (url.includes("keywords")) {
      selectedHeader = "Keywords";
    } else if (url.includes("profile")) {
      selectedHeader = "Profiles";
    } else if (url.includes("analysis")) {
      selectedHeader = "Analysis";
    } else if (url.includes("processing")) {
      selectedHeader = "Processing";
    } else if (url.includes("review")) {
      selectedHeader = "Review";
    } else if (url.includes("reviewselection")) {
      selectedHeader = "ReviewSelection";
    } else if (url.includes("production")) {
      selectedHeader = "Production";
    } else if (url.includes("fastforward")) {
      selectedHeader = "Home";
    } else if (url.includes("support")) {
      selectedHeader = "";
    } else if (url.includes("review")) {
      selectedHeader = "ReviewSelectionScreen";
    } else {
      selectedHeader = "Admin";
    }

    if (this.state.selectedComponentName !== selectedHeader) {
      this.setState({ selectedComponentName: selectedHeader });
    }

    return (
      <nav className="navbar navbar-expand-lg navbar-light white-header" id="top-navbar">
        <div className="col-xs-12 col-sm-6 col-lg-2 select-box-navbar">
          {this.state.selectedProject !== "" &&
          this.state.selectedProject &&
          this.state.solutionsList.length > 0 ? (
            <Select
              className="full-width"
              defaultValue={Number(this.state.selectedSolution)}
              onChange={this.changeSolutionHandler}
              aria-label={polyglot.t("choose solution")}
              aria-activedescendant={null}
              aria-owns={null}
              aria-controls={null}
              aria-expanded={false}
              showSearch
              filterOption={(input, option) => {
                return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
              }}
            >
              {this.state.solutionsList &&
                this.state.solutionsList.map((each) => (
                  <Option key={each.id} value={each.id} aria-label={each.name}>
                    {each.name}
                  </Option>
                ))}
            </Select>
          ) : null}
        </div>
        <p className="my-auto ml-4 font-weight-bold">{this.state.selectedProjectName}</p>
        <button
          className="navbar-toggler"
          type="button"
          onClick={this.expandHeaderFunc}
          data-bs-toggle="collapse"
          data-bs-target="#df-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className={`collapse navbar-collapse justify-content-center ${this.state.expandHeader && "show"}`}
        >
          <div className="d-flex justify-content-center">
            <ul className="navbar justify-content-between mb-0">
              {this.state.getTabs.map((item, index) => {
                let className = "link";
                if (this.state.selectedComponentName === item.TabName) {
                  className += " current nav-link-current";
                }
                return (
                  <React.Fragment key={index + 1}>
                    <li key={item.ID} className="nav-link">
                      <LinkText
                        className={`${className} no-underline`}
                        onClick={() => this.redirection(item.TabName)}
                        ariaLabel={polyglot.t(item.TabName)}
                        href="javascript:;"
                      >
                        {" "}
                        {polyglot.t(item.TabName)}
                      </LinkText>
                    </li>
                    <li key={item.ID} className="nav-item"></li>
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
          <div className="col-xs-12 col-sm-6 col-lg-2 select-box-navbar">
            <div>
              {this.state.selectedComponentName === "Home" &&
                checkPermission(
                  this.state.rolePermission,
                  module_Solution_Name.FAST_FORWARD_MODULE,
                  "View",
                ) && (
                  <FastforwardButton
                    selectedProjectId={this.state.selectedProject}
                    name={polyglot.t("Fast forward")}
                    onClick={this.openPipeline}
                  />
                )}
            </div>
          </div>
        </div>

        <ConfirmBoxExitForm
          show={this.state.openConfirmBox}
          heading={polyglot.t("Cancel update")}
          cancel={this.toggleConfirmBox}
          proceed={this.closeFormAndRedirect}
        />
      </nav>
    );
  }
}
export default withRouter(Navbar);
