import React, { Component } from "react";
import "../../Assets/Css/DFED/Home.css";
import ModuleRoute from "../../Routes/ModuleRoute";
import Navbar from "./TopNavigation";
import NeedHelpHome from "./Support/NeedHelpHome";

// THIS CLASS IS RESPNSIBLE FOR HANDLING AND RENDER ALL DFED COMPONENT

class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight,
    };
    this.callResize = this.callResize.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.callResize);
  }

  callResize() {
    this.setState({
      height: window.innerHeight,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.callResize);
  }

  // RENDER ALL DFED SCREEN

  render() {
    const dfedContainerHeight = {
      minHeight: this.state.height + "px",
    };
    return (
      <div>
        <div>
          <Navbar></Navbar>
        </div>
        <div
          className="row bg-home container-fluid m-l-0 m-r-0 screen-layout p-f"
          style={{ minHeight: "56vh" }}
        >
          <div className="col-md-1 m-r-minusPoint5"></div>
          <div className="col-md-10 pl-0 m-l-0-4" style={{ minHeight: "60vh" }}>
            <ModuleRoute TopNavSelectedHandler={this.TopNavSelectedHandler} />
          </div>
        </div>
        <div className="row charBotRow m-l-0 m-r-0">
          <div className="col-md-4"></div>
          <div className="col-md-4"></div>
          <div className=" col-md-4 needHelpCard">
            <NeedHelpHome props={this.props} />
          </div>
        </div>
      </div>
    );
  }
}
export default Main;
