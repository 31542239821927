/* eslint-disable no-mixed-spaces-and-tabs */
import React from "react";

import CreateQuestion from "./CreateQuestion";
import QuestionButtons from "./QuestionButtons";
/* import LeftPanel from '../Shared/LeftPanel'; */
import "../../../Assets/Css/GRC/Questionnaire.css";

function QuestionContainer(props) {
  return (
    <div className="row grc-content">
      <div className="col-md-2half c-left-panel">LeftPanel</div>

      <div className="col-md-9half c-right-panel">
        <div className="mt-3">
          <QuestionButtons />
          <div className="project_container m-l-1">
            <CreateQuestion />
          </div>
        </div>
      </div>
    </div>
  );
}

export default QuestionContainer;
