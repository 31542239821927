import { Divider, Panel } from "appkit-react";
import PropTypes from "prop-types";
import React from "react";

function AnalysisReviewCard(props) {
  const { title, RightElement } = props;
  return (
    <>
      <Panel
        title={title}
        renderRight={() => {
          return RightElement();
        }}
        className="full-width"
      >
        <Divider />
        {props.children}
      </Panel>
    </>
  );
}

AnalysisReviewCard.propTypes = {
  title: PropTypes.string,
  RightElement: PropTypes.func,
};
AnalysisReviewCard.defaultProps = {
  title: "Title",
  RightElement: () => <>React node will appear here</>,
};

export default AnalysisReviewCard;
