/* eslint-disable no-mixed-spaces-and-tabs */
import {
  Input,
  TextArea,
  Accordions,
  AccordionItem,
  AccordionItemHeader,
  AccordionItemBody,
} from "appkit-react";
import { TextEditor } from "appkit-react-editor";
import { EditorState, convertToRaw, convertFromRaw } from "draft-js";
import React, { useEffect, useState } from "react";

import CustomModal from "../../../Assets/Components/Modal";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import DependancyQuestion from "../Question/DependancyQuestionFolder/DependancyQuestion";
import EditDependencyQuestion from "../Question/DependancyQuestionFolder/EditDependencyQuestion";
import { checkIfNumberIsDecimal } from "../handlers";

const AnswerBox = ({ answerIndex, answer, isReadonly, answerTypeId, handleChanges }) => {
  const polyglot = polyglotLoader();
  const [editorState, setEditorState] = useState(() =>
    answer.editor_raw_state
      ? EditorState.createWithContent(convertFromRaw(JSON.parse(answer.editor_raw_state)))
      : EditorState.createEmpty(),
  );

  if (isReadonly || answerTypeId === 5) {
    return (
      <div className="readonly-answer-block" dangerouslySetInnerHTML={{ __html: answer.answer_text }}></div>
    );
  }

  const defaultProps = {
    placeholder: polyglot.t("answer_of_your_quest"),
    className: "Notes question-txt-area",
    "aria-label": polyglot.t("answer_of_your_quest"),
  };

  return (
    <TextEditor
      {...defaultProps}
      editorProps={{
        editorState,
      }}
      toolbar={{
        controls: [["Bold", "Italic"]],
      }}
      onChange={(htmlContent, state) => {
        handleChanges(
          JSON.stringify(convertToRaw(state.getCurrentContent())),
          answerIndex,
          "editor_raw_state",
        );
        handleChanges(htmlContent, answerIndex, "answer_text");
        setEditorState(state);
      }}
    />
  );
};

const EditAnswerBlock = ({
  setAnswerdata,
  answers,
  categoryid,
  previous_ans_Id,
  disableAnswerTypes,
  questId,
  usemode,
  answerTypeId,
}) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [expandCollapseKey, setExpandCollapseKey] = React.useState(["answer-block1"]);

  const polyglot = polyglotLoader();
  // modal
  const [showModal, setShowModal] = useState(false);

  const [questionDescription, setQuestionDescription] = useState("");
  const [currentquestion, setCurrentQuestion] = useState(0);
  const [chieldCateqoriesByRoot, setChieldCateqoriesByRoot] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [scoringError, setScoringError] = useState("");
  const [weightageError, setWeightageError] = useState("");
  const [dependencyMode, setDependencyMode] = useState("null");
  const [answerId, setAnswerId] = useState("");
  const [save, setSave] = useState(false);
  const [dependencyQuestionId, setDependencyQuestionId] = useState(0);
  // VALIDATION OF SCORING FIELD
  const validationOfScoring = (value) => {
    try {
      let validationSuccessful = false;
      let errorMsg = "";
      // should not consider a decimal value
      if (checkIfNumberIsDecimal(Number(value))) {
        errorMsg = "Scoring cannot be in decimal value";
      }
      // min = 1, max = no limit
      if (Number(value) < 1) {
        errorMsg = "Scoring cannot be less than one";
      }
      setScoringError(errorMsg);
      if (errorMsg === "") {
        // validation successful
        validationSuccessful = true;
      } else {
        validationSuccessful = false;
      }
      return validationSuccessful;
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // VALIDATION OF WEIGHTAGE FIELD
  const validationOfWeightage = (value) => {
    try {
      let validationSuccessful = false;
      let errorMsg = "";
      // min = 0.1, max = no limit
      if (Number(value) < 0.1) {
        errorMsg = "Weightage cannot be less than 0.1";
      }
      setWeightageError(errorMsg);
      if (errorMsg === "") {
        // validation successful
        validationSuccessful = true;
      } else {
        validationSuccessful = false;
      }
      return validationSuccessful;
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // VALIDATION OF ANSWER TEXT
  const validationOfAnswerText = (value) => {
    try {
      let validationSuccessful = false;
      // restrict the user with max of 2000 char limit
      if (value.length > 2000) {
        // do not update the value
        validationSuccessful = false;
      } else {
        validationSuccessful = true;
      }
      return validationSuccessful;
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // VALIDATION OF ADDITIONAL ANSWER TEXT
  const validationOfAdditionalAnswerText = (value) => {
    try {
      let validationSuccessful = false;
      // restrict the user with max of 2000 char limit
      if (value.length > 2000) {
        // do not update the value
        validationSuccessful = false;
      } else {
        validationSuccessful = true;
      }
      return validationSuccessful;
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // updates the answer data
  const updateAnswerData = (e, index, field) => {
    try {
      const updateData = answers.map((item, i) => (i === index ? Object.assign(item, { [field]: e }) : item));

      setAnswerdata(updateData);
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  const handleChanges = (data, index, field) => {
    switch (field) {
      case "editor_raw_state":
        updateAnswerData(data, index, field);
        break;
      case "answer_text":
        if (validationOfAnswerText(data)) {
          updateAnswerData(data, index, field);
        } else {
          // validation of answer_text is failed
        }
        break;
      case "additional_answer_text":
        if (validationOfAdditionalAnswerText(data)) {
          updateAnswerData(data, index, field);
        } else {
          // validation of additional_answer_text is failed
        }
        break;
      case "scoring":
        // validation of scoring
        updateAnswerData(data, index, field);
        if (validationOfScoring(data)) {
          // validation of scoring is successful and is valid
        } else {
          // validation of scoring is failed
          updateAnswerData(true, index, "hasError");
        }
        break;
      case "weightage":
        // validation of weightage
        updateAnswerData(data, index, field);
        if (validationOfWeightage(data)) {
          // validation of weightage is successful and is valid
        } else {
          // validation of weightage is failed
          updateAnswerData(true, index, "hasError");
        }
        break;
      default:
        updateAnswerData(data, index, field);
    }
    // all the validations are successfull, then update hasError to false
    const scoringValue = answers[index].scoring;
    const weightageValue = answers[index].weightage;
    if (validationOfScoring(scoringValue) && validationOfWeightage(weightageValue)) {
      updateAnswerData(false, index, "hasError");
    }
  };
  const test = (id) => {
    setCurrentQuestion(id);
  };
  const openModal = (cIndex, dependencyId) => {
    setCurrentIndex(cIndex);

    setShowModal(true);
    if (dependencyId > 0) {
      setDependencyQuestionId(dependencyId);
    }
  };
  const handlerAddDependancy = () => {
    //e.preventDefault();
    setShowModal(false);

    // update handle change
    const updateData = answers.map((item, i) =>
      i === currentIndex ? Object.assign(item, { ["dependency_question_id"]: currentquestion }) : item,
    );

    setAnswerdata(updateData);
  };

  const updateQuestionDescription = (description, questionid) => {
    setQuestionDescription(description);
    setCurrentQuestion(questionid);
  };

  // used to update the state value to display the selected dependency question
  useEffect(() => {
    if (currentIndex >= 0) {
      const dependency_question_id = answers[currentIndex]["dependency_question_id"];
      // used to display the selected dependency question id
      setCurrentQuestion(dependency_question_id);
    }
  }, [currentIndex]);

  useEffect(() => {
    if (save && currentquestion > 0) {
      handlerAddDependancy();
      setSave(false);
    }
  }, [save, currentquestion]);
  // EXPANDINGA ND COLLAPSEING BASED ON CONDITION
  const expandCollpaseHandler = (e) => {
    if (expandCollapseKey[0] === e) {
      setExpandCollapseKey([]);
    } else {
      setExpandCollapseKey([e]);
    }
  };

  const setmodal = () => {
    setShowModal(false);
  };
  return (
    <>
      <div className="overflow-auto answers-block-accordian">
        <Accordions activeItem={expandCollapseKey} onChange={expandCollpaseHandler}>
          {answers
            .filter((answer) => answer.answer_text !== "not applicable")
            .map((answer, answerIndex) => {
              const isReadonly = usemode === "readonly" || disableAnswerTypes.includes(answer.answer_text);
              return (
                <AccordionItem itemId={`answer-block${answerIndex + 1}`} key={answer.id}>
                  <AccordionItemHeader title={`Answer ${answerIndex + 1}`} />
                  <AccordionItemBody>
                    <div className="ans-block mb-5">
                      <div className="row mt-4">
                        <div className="col-md-6">
                          <AnswerBox
                            isReadonly={isReadonly}
                            answerIndex={answerIndex}
                            answer={answer}
                            answerTypeId={answerTypeId}
                            handleChanges={handleChanges}
                          />

                          {/* IF FIELD IS EDITABLE, THEN SHOW THE BELOW div */}
                          {!isReadonly && (
                            <div>
                              {/* MAX NUMBER OF CHARACTERS ALLOWED IS 2000 */}
                              {answer.answer_text.length}/2000
                            </div>
                          )}
                        </div>
                        <div className="col-md-6">
                          <TextArea
                            placeholder={polyglot.t("additional_answer_information_texts")}
                            className="Notes question-txt-area"
                            aria-label={polyglot.t("additional_answer_information_texts")}
                            onChange={(e) => {
                              handleChanges(e, answerIndex, "additional_answer_text");
                            }}
                            value={answer.additional_answer_text}
                            disabled={isReadonly}
                          />
                          {/* MAX NUMBER OF CHARACTERS ALLOWED IS 2000 */}
                          {!isReadonly && <div>{answer.additional_answer_text.length}/2000</div>}
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-4">
                          {/* <ButtonSecondaryWithIcon
                          onClick={() => openModal(answerIndex)}
                          ariaLabel={polyglot.t("add_dependancy_question")}
                          iconName={plusCircle}
                          className="button-expandable input-field-height"
                        >
                          {polyglot.t("add_dependancy_question")}
                        </ButtonSecondaryWithIcon> */}
                        </div>
                        <div className="col-md-4"></div>
                        <div className="col-md-4">
                          <Input
                            type="number"
                            inputBoxSize="sm"
                            placeholder="Weightage *"
                            disabled={isReadonly}
                            onChange={(e) => {
                              handleChanges(e, answerIndex, "weightage");
                            }}
                            value={answer.weightage.toString()}
                          />
                          {answer.hasError && weightageError.length > 0 && (
                            <div className="error">{weightageError}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </AccordionItemBody>
                </AccordionItem>
              );
            })}
        </Accordions>
      </div>

      <div className="add-dependancy-q-modal">
        {dependencyMode === "add" ? (
          <CustomModal
            show={showModal}
            onCancel={() => setShowModal(false)}
            title={polyglot.t("add_dependancy_question")}
            size="x-lg"
            dontshowButton={true}
            /*  Footer={[
          <>
            <ButtonPrimary
             
              ariaLabel={polyglot.t("Add")}
              onClick={(e) => {
                handlerAddDependancy(e)}}
            >
              {polyglot.t("Add")}
            </ButtonPrimary>
          </>,
        ]} */
          >
            <div className="row">
              <div className="col-md-12" id="grc-modal-popup">
                <DependancyQuestion
                  setmodal={setmodal}
                  setSave={setSave}
                  categoryId={categoryid}
                  test={test}
                  setCurrentQuestion={setCurrentQuestion}
                  answers={answers}
                  previous_ans_Id={previous_ans_Id}
                  answerId={currentIndex}
                  questId={questId}
                />
              </div>
            </div>
          </CustomModal>
        ) : (
          <CustomModal
            className="testModal"
            show={showModal}
            onCancel={() => setShowModal(false)}
            title={
              usemode && usemode == "readonly"
                ? polyglot.t("view_dependancy_question")
                : polyglot.t("edit_dependancy_question")
            }
            size="x-lg"
            dontshowButton={true}
          >
            <div className="row">
              <div className="col-md-12" id="grc-modal-popup">
                {dependencyQuestionId > 0 && (
                  <EditDependencyQuestion
                    dependencyQuestionId={dependencyQuestionId}
                    currentquestion={currentquestion}
                    setmodal={setmodal}
                    setSave={setSave}
                    categoryId={categoryid}
                    test={test}
                    setCurrentQuestion={setCurrentQuestion}
                    answers={answers}
                    previous_ans_Id={previous_ans_Id}
                    answerId={currentIndex}
                    questId={questId}
                    usemode={usemode}
                  />
                )}
              </div>
            </div>
          </CustomModal>
        )}
      </div>
      {/*
      PREVIOUSLY DEPENDENT QUESTION BLOCK FOR REFERENCE

      <CustomModal
          show={showModal}
          onCancel={() => setShowModal(false)}
          title={polyglot.t("add_dependancy_question")}
          Footer={[
            <>
              <ButtonPrimary
                // if currentquestion is not given, ten disabled
                disabled={currentquestion ? false : true}
                ariaLabel={polyglot.t("Add")}
                onClick={(e) => handlerAddDependancy(e)}
              >
                {polyglot.t("Add")}
              </ButtonPrimary>
            </>,
          ]}
        >
          <div className="row">
            <div className="col-md-12">
              {error && <ErrorPanel error={error} onClear={clearError} />}
              {isLoading && (
                <div>
                  <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
                </div>
              )}
              {chieldCateqoriesByRoot.length === 0 && (
                <EmptyPanel massage={"No question found."} />
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="question-list questionslist mt-4">
                {chieldCateqoriesByRoot && chieldCateqoriesByRoot.length > 0 && (
                  <RadioGroup aria-label="Dependancy Question" name="redio-grp"
                    value={parseInt(currentquestion)}
                    onChange={(e) => {
                      const questionId = e.target.value;
                      const questionText = chieldCateqoriesByRoot.find(each => each.id === parseInt(questionId))['question_text'];
                      return updateQuestionDescription(
                        questionText,
                        questionId
                      );
                    }}
                  >
                    {chieldCateqoriesByRoot.map((question, idx) => {
                      return (
                          <Radio
                            value={question.id}
                            aria-label={question.question_text}
                            className="dep-questions"
                          >
                            {question.question_text &&
                              (question.question_text.length > 40
                                ? question.question_text.substring(0, 41) +
                                  "..."
                                : question.question_text)}
                          </Radio>
                      );
                    })}
                  </RadioGroup>
                )}
              </div>
            </div>
          </div>
        </CustomModal> */}
      {/* </div> */}
    </>
  );
};

/* AnswerBlock.propTypes = {
  handleChange: PropTypes.func,
}; */
export default EditAnswerBlock;
