import { getCurrentLanguage } from "../../../Services/Shared/Translate";

// PASS PRICE AMOUNT TO THIS FUNCTION AND YOU WILL RECEIVE PRICE FORMAT AS PER THE CURRENT LANGUAGE
function GetPriceInGermanFormat(priceStr, hideCurrencySymbol, showWithDecimal) {
  try {
    const getPriceNumberFromString = (priceStr) => {
      const priceInNumber = priceStr.split(",").join("");
      return Number(priceInNumber);
    };
    const getCurrencyDetails = (priceWithLogo, language) => {
      let currencyLogo;
      if (language === "en") {
        currencyLogo = priceWithLogo[0];
      }
      if (language === "ger") {
        currencyLogo = priceWithLogo[priceWithLogo.length - 1];
      }
      const amount = priceWithLogo.replace(currencyLogo, "").trim();
      return { currencyLogo, amount };
    };
    let priceInNumber;
    if (!isNaN(priceStr)) {
      /* priceStr is a number */
      priceInNumber = Number(priceStr);
    } else if (priceStr.includes(",")) {
      priceInNumber = getPriceNumberFromString(priceStr);
    }
    const currentLanguage = getCurrentLanguage();
    const englishPrice = priceInNumber.toLocaleString("en-US", {
      currency: "EUR",
      style: "currency",
      currencyDisplay: "symbol",
      minimunFractionDigits: 2,
    });
    const germanPrice = priceInNumber.toLocaleString("de-GE", {
      currency: "EUR",
      style: "currency",
      currencyDisplay: "symbol",
      minimunFractionDigits: 2,
    });
    if (currentLanguage === "en") {
      const { currencyLogo, amount } = getCurrencyDetails(englishPrice, "en");
      const englishPriceWithoutDecimal = amount.split(".")[0];
      const currencyAlphabet = !hideCurrencySymbol ? currencyLogo : "";
      let generatedPrice = amount;
      if (!showWithDecimal) {
        generatedPrice = englishPriceWithoutDecimal;
      }
      return `${currencyAlphabet}${generatedPrice}`;
    } else if (currentLanguage === "ger") {
      const { currencyLogo, amount } = getCurrencyDetails(germanPrice, "ger");
      const germanPriceWithoutDecimal = amount.split(",")[0];
      const currencyAlphabet = !hideCurrencySymbol ? currencyLogo : "";
      let generatedPrice = amount;
      if (!showWithDecimal) {
        generatedPrice = germanPriceWithoutDecimal;
      }
      return `${generatedPrice} ${currencyAlphabet}`;
    }
  } catch (err) {
    return "0";
  }
}

export default GetPriceInGermanFormat;
