import React, { Fragment } from "react";

import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import { backCircle } from "../../../Assets/Icons";

class PrivacyPolicyComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      userLanguage: "en",
      isBackEnable: true,
    };

    this.callResize = this.callResize.bind(this);
  }
  componentDidMount() {
    if (sessionStorage.length > 0) {
      this.setState({
        isBackEnable: true,
      });
    } else {
      this.setState({
        isBackEnable: false,
      });
    }
    this.getUserLanguage();
    window.addEventListener("resize", this.callResize);
  }

  callResize() {
    this.setState({
      height: window.innerHeight,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.callResize);
  }
  goBack() {
    this.props.history.goBack();
  }

  componentDidUpdate() {
    this.getUserLanguage();
  }

  async getUserLanguage() {
    let userLanguage = await sessionStorage.getItem("USER_LANGUAGE");
    if (this.state.userLanguage !== userLanguage)
      this.setState({
        userLanguage: userLanguage,
      });
  }

  async isBackAllowed() {
    if (sessionStorage.length === 0) {
      return false;
    } else {
      return true;
    }
  }
  render() {
    const footerTermsAndContainerHeight = {
      minHeight: this.state.height + "px",
    };
    return (
      <Fragment>
        {this.state.userLanguage !== "ger" && (
          <div className="row m-0">
            <div className="col-md-1"></div>

            <div style={footerTermsAndContainerHeight} className="col-md-10 m-t-20 p-l-0">
              <div className="row m-0">
                <div className=" col-md-8 header-credit-page p-l-1-p m-b-0 ">{"Privacy policy"} </div>
                <div className="col-md-4 p-0 float-right m-t-7">
                  {this.state.isBackEnable && (
                    <ButtonPrimaryWithIcon
                      onClick={this.goBack.bind(this)}
                      iconName={backCircle}
                      ariaLabel={"Back"}
                    >
                      {"Back"}
                    </ButtonPrimaryWithIcon>
                  )}
                </div>
              </div>
              <div>
                <div
                  style={{
                    boxSizing: "border-box",
                    padding: "0px",
                    marginTop: "7px",
                    border: "0px",
                    verticalAlign: "baseline",
                    color: "rgb(45, 45, 45)",
                    fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                    fontSize: "16px",
                    fontVariantLigatures: "normal",
                    fontVariantCaps: "normal",
                    fontWeight: 400,
                    letterSpacing: "normal",
                    orphans: 2,
                    textAlign: "start",
                    textIndent: "0px",
                    textTransform: "none",
                    whiteSpace: "normal",
                    widows: 2,
                    wordSpacing: "0px",
                    WebkitTextStrokeWidth: "0px",
                    textDecorationStyle: "initial",
                    textDecorationColor: "initial",
                  }}
                >
                  <section className=" page-section  videobghost " data-parallax="true" data-wcmmode="false">
                    <div
                      className="parsys sectionpar"
                      style={{
                        boxSizing: "border-box",
                        margin: "0px auto",
                        border: "0px",
                        verticalAlign: "baseline",
                      }}
                    >
                      <div
                        className="parsys_column pwccol1-longform "
                        style={{
                          boxSizing: "border-box",
                          padding: "0px",
                          border: "0px",
                          verticalAlign: "baseline",
                          display: "table",
                          position: "relative",
                          tableLayout: "fixed",
                        }}
                      >
                        <div className="parsys_column pwccol1-longform-c0">
                          <div className="text parbase section">
                            <div className="text-component " data-emptytext="Edit Text">
                              <div className="text parbase section">
                                <div className="text-component " data-emptytext="Edit Text">
                                  <h2
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "0px 0px 20px",
                                      fontFamily: "PwC Helvetica Neue",
                                      fontWeight: "500",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "16px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    Privacy Statement according to Art. 13, 14 EU General Data Protection
                                    Regulation (GDPR)
                                  </h2>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-10px 0px 20px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "14px",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    The following privacy statement is intended to explain to you in a clear,
                                    transparent and understandable way how your personal data is processed by
                                    us while you use platform Connected Digital Services. However, if you have
                                    any questions of understanding or other queries about data protection at
                                    PwC, you are welcome to contact the data protection officer for your
                                    territory as outlined in the Data Controller statement. Connected Digital
                                    Services is a platform that allows clients to initiate projects on their
                                    own, supported by PwC’s methodology and the expertise embedded in our
                                    digital products, making processes easy to navigate.
                                  </p>
                                  <h3
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "20px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.75rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      1. Controller
                                    </b>
                                  </h3>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "0px 0px 20px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Controller within the meaning of Art. 4 (7) GDPR for the processing of
                                    your personal data is:
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-8px 0px 20px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    <b>PricewaterhouseCoopers GmbH Wirtschaftsprüfungsgesellschaft</b>
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    Friedrich-Ebert-Anlage 35-37
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    60327 Frankfurt am Main
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    Email:&nbsp;
                                    <a
                                      className="footerAnchor"
                                      href="mailto:webkontakt_anfragen@pwc.com"
                                      target="_blank"
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        backgroundColor: "transparent",
                                        color: "inherit",
                                        textDecoration: "none",
                                        borderWidth: "0px 0px 1px",
                                        borderTopStyle: "initial",
                                        borderRightStyle: "initial",
                                        borderBottomStyle: "",
                                        borderLeftStyle: "initial",
                                        borderTopColor: "initial",
                                        borderRightColor: "initial",
                                        borderBottomColor: "rgb(125, 125, 125)",
                                        borderLeftColor: "initial",
                                        borderImage: "initial",
                                        verticalAlign: "baseline",
                                        outline: "0px",
                                        fontWeight: "bold",
                                        fontSize: "inherit",
                                      }}
                                      rel="noreferrer"
                                    >
                                      webkontakt_anfragen@pwc.com
                                    </a>
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      <br
                                        style={{
                                          boxSizing: "border-box",
                                          padding: "0px",
                                          margin: "0px",
                                        }}
                                      />
                                    </b>
                                    Switchboard: +49 69 9585-0
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    Fax: +49 69 9585-1000
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "0px 0px 20px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    as far as the processing activities described in clauses 4 to 5 are
                                    concerned.
                                  </p>

                                  <h3
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "20px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.75rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      2. Data Protection Officer
                                    </b>
                                  </h3>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "0px 0px 20px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    PwC has appointed a Data Protection Officer in accordance with Art. 37
                                    GDPR. You can contact the data protection officer of PwC, Dr. Tobias
                                    Gräber, at the following contact details:
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-8px 0px 20px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    E-mail:&nbsp;
                                    <a
                                      className="footerAnchor"
                                      href="mailto:webkontakt_anfragen@pwc.com"
                                      target="_blank"
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        backgroundColor: "transparent",
                                        color: "inherit",
                                        textDecoration: "none",
                                        borderWidth: "0px 0px 1px",
                                        borderTopStyle: "initial",
                                        borderRightStyle: "initial",
                                        borderBottomStyle: "",
                                        borderLeftStyle: "initial",
                                        borderTopColor: "initial",
                                        borderRightColor: "initial",
                                        borderBottomColor: "rgb(125, 125, 125)",
                                        borderLeftColor: "initial",
                                        borderImage: "initial",
                                        verticalAlign: "baseline",
                                        outline: "0px",
                                        fontWeight: "bold",
                                        fontSize: "inherit",
                                      }}
                                      rel="noreferrer"
                                    >
                                      DE_Datenschutz@pwc.com <br />
                                    </a>
                                    Telephone: +49 69 9585-0
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-8px 0px 20px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Postal address:
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-8px 0px 20px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    PricewaterhouseCoopers GmbH WPG
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    Dr Tobias Gräber, Data Protection Officer
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    Friedrich-Ebert-Anlage 35-37
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    60327 Frankfurt am Main
                                  </p>

                                  <h3
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "20px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.75rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      3. Data subject rights/your rights under data protection law
                                    </b>
                                  </h3>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    You have the following rights under applicable data protection law with
                                    respect to personal data concerning you.
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-8px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      Right of access:
                                    </b>{" "}
                                    You can request access from us at any time about whether and which
                                    personal data we store about you. The provision of information is free of
                                    charge for you. The right to access does not exist or exists only to a
                                    limited extent if and insofar as information requiring secrecy would be
                                    disclosed by the information, e.g. information subject to professional
                                    secrecy.
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-8px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      Right to rectification:
                                    </b>{" "}
                                    If your personal data stored by us is incorrect or incomplete, you have
                                    the right to demand that we correct this data at any time.
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-8px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      Right to erasure:
                                    </b>{" "}
                                    You have the right to request PwC to erase your personal data if and to
                                    the extent that the data is no longer needed for the purposes for which it
                                    was collected or, if the processing is based on your consent, you have
                                    withdrawn your consent. In this case, we must stop processing your
                                    personal data and remove it from our IT systems and databases.
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-8px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    A right to erasure does not exist insofar as
                                  </p>
                                  <ul
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px 0px 0px 30px",
                                      margin: "0px 0px 10px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      listStyle: "none",
                                      display: "block",
                                    }}
                                  >
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      the data may not be erased due to a legal obligation or must be
                                      processed due to a legal obligation;
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      the data processing is necessary for the assertion, exercise or defense
                                      of legal claims.
                                    </li>
                                  </ul>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      Right to restriction of processing:
                                    </b>{" "}
                                    You have the right to request that we restrict the processing of your
                                    personal data.
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "-8px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      Right to data portability:
                                    </b>{" "}
                                    You have the right to receive from us the data you have provided in a
                                    structured, common and machine-readable format, as well as the right to
                                    have this data transferred to another controller. This right only exists
                                    if
                                  </p>
                                  <ul
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px 0px 0px 30px",
                                      margin: "0px 0px 10px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      listStyle: "none",
                                      display: "block",
                                    }}
                                  >
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      you have provided us with the data on the basis of consent or on the
                                      basis of a contract concluded with you;
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      the processing is carried out with the aid of automated procedures.
                                    </li>
                                  </ul>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "15px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      padding: "5px",
                                      border: "2px solid black",
                                      backgroundColor: "lightgray",
                                      color: "black",
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      Right to object to processing:
                                    </b>{" "}
                                    If the processing of your data is based on Art. 6 (1) (f) GDPR, you may
                                    object to the processing at any time.
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "-8px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    All of the data subject rights described above may be asserted against PwC
                                    by sending your specific request to the following contact information:
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "-8px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    <b>By E-Mail:&nbsp;</b>
                                    <a
                                      className="footerAnchor"
                                      href="mailto:webkontakt_anfragen@pwc.com"
                                      target="_blank"
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        backgroundColor: "transparent",
                                        color: "blue",
                                        textDecoration: "none",
                                        borderWidth: "0px 0px 1px",
                                        borderTopStyle: "initial",
                                        borderRightStyle: "initial",
                                        borderBottomStyle: "",
                                        borderLeftStyle: "initial",
                                        borderTopColor: "initial",
                                        borderRightColor: "initial",
                                        borderBottomColor: "rgb(125, 125, 125)",
                                        borderLeftColor: "initial",
                                        borderImage: "initial",
                                        verticalAlign: "baseline",
                                        outline: "0px",
                                        fontWeight: "bold",
                                        fontSize: "inherit",
                                        display: "block",
                                      }}
                                      rel="noreferrer"
                                    >
                                      DE_Datenschutz@pwc.com
                                    </a>
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "-8px 0px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    <b>By mail:</b>
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "-8px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    PricewaterhouseCoopers GmbH WPG
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    Dr Tobias Gräber, Data Protection Officer
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    Friedrich-Ebert-Anlage 35-37
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    60327 Frankfurt am Main
                                  </p>
                                  <h3
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "40px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.75rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      4. Right of appeal to a data protection supervisory authority
                                    </b>
                                  </h3>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    In accordance with Art. 77 GDPR, you have the right to lodge a complaint
                                    with a data protection supervisory authority if you believe that the
                                    processing of your personal data violates data protection law.
                                  </p>
                                  <h3
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "40px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.75rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    5. Description of the data processing and legal bases for the processing
                                  </h3>
                                  <h4
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "40px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.5rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    5.1 Recipients of personal data
                                  </h4>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    In order to fulfill the processing purposes listed below, a data transfer
                                    to third parties also takes place. This may also include the transfer of
                                    personal data to other European and non-European countries and the storage
                                    of personal data also outside the EU or the European Economic Area (EEA).
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Recipients bound by instructions
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    We share your data with service providers bound by instructions, both
                                    within the PwC network and with other third parties, such as IT service
                                    providers, who support us in our activities, e.g. in the context of the
                                    administration and maintenance of the websites and the associated systems
                                    and/or for other internal or administrative purposes.
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    PwC is a member of the global PwC network, which consists of the
                                    individual legally independent PwC firms. In the course of our activities,
                                    we use other German or foreign PwC network companies as network-internal
                                    IT service providers bound by instructions, which provide services of
                                    operation, maintenance and care of the IT systems as well as applications
                                    used by the PwC network companies. In particular, this is PwC IT Services
                                    Ltd. based in the United Kingdom (UK).
                                    <br />
                                    <br />
                                    If we pass on data to service providers bound by instructions, we do not
                                    require a separate legal basis for this.
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-10px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    You can find out more information about the cloud service providers used
                                    by PwC at the following link:&nbsp;
                                    <a
                                      className="footerAnchor"
                                      href="https://www.pwc.de/externe-dienstleister"
                                      target="_blank"
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        backgroundColor: "transparent",
                                        color: "blue",
                                        textDecoration: "none",
                                        borderWidth: "0px 0px 1px",
                                        borderTopStyle: "initial",
                                        borderRightStyle: "initial",
                                        borderBottomStyle: "",
                                        borderLeftStyle: "initial",
                                        borderTopColor: "initial",
                                        borderRightColor: "initial",
                                        borderBottomColor: "rgb(125, 125, 125)",
                                        borderLeftColor: "initial",
                                        borderImage: "initial",
                                        verticalAlign: "baseline",
                                        outline: "0px",
                                        fontSize: "inherit",
                                      }}
                                      rel="noreferrer"
                                    >
                                      www.pwc.de/externe-dienstleister.
                                    </a>
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Independent recipients
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    In addition, in individual cases we pass on your data both within the PwC
                                    network and to other third parties who use your data on their own
                                    responsibility. For example, in individual cases we also transfer personal
                                    data to other companies in the PwC network, in order to support and
                                    improve the effectiveness of our business processes (including coordinated
                                    marketing activities).
                                    <br />
                                    <br />
                                    In addition, in individual cases, we also disclose your data to other
                                    third parties, such as public authorities, courts or other bodies, if we
                                    are required by law or by official or court order of an EU member state to
                                    disclose personal data to these bodies. These bodies also use the data on
                                    their own responsibility.
                                    <br />
                                    <br />
                                    Insofar as you have explicitly consented, Art. 6 para. 1 lit. a) GDPR is
                                    the legal basis for the data transfer. Insofar as there is a legal
                                    obligation to disclose the data, Art. 6 para. 1 lit. c) GDPR is the legal
                                    basis for the data transfer. If, on the other hand, the disclosure is
                                    necessary for the fulfillment of a contractual or pre-contractual measure
                                    with you as a natural person, Art. 6 para. 1 lit. b) GDPR is the legal
                                    basis for the data transfer. Otherwise, the transfer is based on our
                                    legitimate interests and the legal basis is Art. 6 para. 1 lit f) GDPR; we
                                    and the other companies in the PwC network have an interest in making our
                                    work processes efficient and sharing business processes within the PwC
                                    network for this purpose.
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Data transfer to recipients in third countries outside the EU/EEA
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    To the extent that any of the above-mentioned data transfers are made to a
                                    recipient outside the EEA (to so-called "third countries"), an adequate
                                    level of data protection for the foreign transfer is ensured through
                                    appropriate security measures.
                                    <br />
                                    <br />
                                    For data transfers within the PwC network, the PwC network companies have,
                                    among other things, concluded an internal data protection agreement which
                                    provides for compliance with the EU standard contractual clauses of the EU
                                    Commission within the meaning of Art. 46 para. 2 lit. c GDPR for data
                                    transfers of personal data from EU/EEA countries to PwC network companies
                                    outside the EU/EEA.
                                    <br />
                                    <br />
                                    To the extent that you have any questions regarding such data protection
                                    agreements based on the EU standard contractual clauses or you would like
                                    to have more information regarding further security mechanisms and
                                    security measures for the transfer of data to third countries, please feel
                                    free to contact our data protection officer, e.g. at
                                    DE_Datenschutz@pwc.com.
                                  </p>
                                  <h4
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "40px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.5rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    5.2 Processing of personal data accessing and using Connected Digital
                                    Services
                                  </h4>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    When you access the platform, we collect the data that is technically
                                    necessary to display Connected Digital Services to you. This is the
                                    following personal data, which is automatically transmitted to our server
                                    by your browser:
                                  </p>
                                  <ul
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px 0px 0px 30px",
                                      margin: "0px 0px 10px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      listStyle: "none",
                                      display: "block",
                                    }}
                                  >
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      IP address (and location based on IP address)
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Date and time of your request/call
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Time zone difference from Greenwich Mean Time (GMT)
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Content of the request (information about which specific page you
                                      accessed)
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Access status/http status code
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Website, from which the request was initiated
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Browser (information about the browser you are using)
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Operating system and its interface (operating system of the computer you
                                      use to access (Name of the technology))
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Language and version of the browser software
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Device identifier (this is the unique number of your device, e.g. UDID,
                                      DeviceID, IMEI)
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Network subscriber number (IMSI)
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Mobile phone number (MSISDN)
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      MAC address for WiFi usage
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Name of the mobile device
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      E-mail address
                                    </li>
                                  </ul>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    platform cannot be accessed and offered to users without the use of this
                                    data; there is a legitimate interest in making the call-up and use of
                                    platform technically possible. In addition, the data is stored in order to
                                    technically secure platform. The responsible party has an interest in the
                                    security and availability of platform. The processing of this personal
                                    data is based on Art. 6 para. 1 lit. f) DSGVO.
                                    <br />
                                    <br />
                                    The above enlisted data is stored for 90 days and then deleted.
                                    <br />
                                    <br />
                                    Data is only transferred abroad to countries in the European Union or the
                                    European Economic Area.
                                  </p>
                                  <h4
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "40px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.5rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    5.3 Registration and Login
                                  </h4>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    The access and use of our platform is generally not possible without
                                    registration and login. Some functionalities of the technology platform
                                    can only be used after registration.
                                    <br />
                                    <br />
                                    The registration is carried out by PwC as part of the Connected Digital
                                    Services onboarding process. Your PwC contact will provide you with the
                                    access data. Self-registration is not possible.
                                    <br />
                                    <br />
                                    The two-factor authentication (2FA) is used for logins in the application.
                                    2FA is a security function that, in addition to the actual login data,
                                    uses a combination of two different factors to ensure proof of your
                                    identity and guarantee the protection of your data. When registering, you
                                    can choose between the mobile phone number or e-mail. When logging in,
                                    after entering the login data, a verification code is then sent via the
                                    chosen path, which must be entered in the login mask for authentication.
                                    <br />
                                    <br />
                                    During registration and login, PwC processes the following information:
                                  </p>
                                  <ul
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px 0px 0px 30px",
                                      margin: "0px 0px 10px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      listStyle: "none",
                                      display: "block",
                                    }}
                                  >
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Email address
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Name
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Mobile phone number
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Password
                                    </li>
                                  </ul>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    This personal data is processed on the basis of Art. 6 para. 1 c) in
                                    connection with Art. 32 GDPR. All data will be erased by us after
                                    termination of the contractual relationship. Insofar as legal storage
                                    obligations exist, the data will be stored for the duration of the legal
                                    retention period.
                                  </p>
                                  <h4
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "40px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.5rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    5.4 Data processing when using Connected Digital Services
                                  </h4>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    You have a business relationship with PwC if either you or your employer
                                    is a client of PwC. We use Connected Digital Services to provide our
                                    contractually agreed services. In addition to this data privacy notice,
                                    the "Privacy Policy PricewaterhouseCoopers GmbH
                                    Wirtschaftsprüfungsgesellschaft" applies, which was provided prior to our
                                    engagement. The "Privacy Policy PricewaterhouseCoopers GmbH
                                    Wirtschaftsprüfungsgesellschaft" describes how PwC processes personal data
                                    within the scope of various engagements e.g. tax consultancy, legal
                                    consultancy or management consultancy/auditing services or other
                                    assignments. The "Privacy Policy PricewaterhouseCoopers GmbH
                                    Wirtschaftsprüfungsgesellschaft" can be accessed again at the following
                                    link:
                                  </p>
                                  <a
                                    className="footerAnchor"
                                    href="https://www.pwc.de/en/privacy-policy-for-clients/privacy-policy-pricewaterhousecoopers-gmbh.html"
                                    target="_blank"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px",
                                      backgroundColor: "transparent",
                                      color: "blue",
                                      textDecoration: "none",
                                      borderWidth: "0px 0px 1px",
                                      borderTopStyle: "initial",
                                      borderRightStyle: "initial",
                                      borderBottomStyle: "",
                                      borderLeftStyle: "initial",
                                      borderTopColor: "initial",
                                      borderRightColor: "initial",
                                      borderBottomColor: "rgb(125, 125, 125)",
                                      borderLeftColor: "initial",
                                      borderImage: "initial",
                                      verticalAlign: "baseline",
                                      outline: "0px",
                                      fontSize: "inherit",
                                    }}
                                    rel="noreferrer"
                                  >
                                    https://www.pwc.de/en/privacy-policy-for-clients/privacy-policy-pricewaterhousecoopers-gmbh.html
                                  </a>
                                  <h4
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "40px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.5rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    5.5 Contact form and contact by e-mail
                                  </h4>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    We offer a contact form on the platform so that you can contact us with
                                    questions about PwC, the platform and other inquiries. In addition, you
                                    can contact us by e-mail.
                                    <br />
                                    <br />
                                    When you contact us via the contact form or by e-mail, the data you
                                    provide (in particular your e-mail address, your first and last name and
                                    the text of your inquiry as well as any other information you have
                                    provided in the contact form or by e-mail) will be stored by us in order
                                    to process your inquiry and answer your questions.
                                    <br />
                                    <br />
                                    The data processing is justified according to Art. 6 para. 1 lit. f) GDPR.
                                    We have an interest in contacting you via the platform in response to your
                                    inquiry. Insofar as your request is aimed at the fulfillment of a
                                    contractual or pre-contractual measure with you as a natural person, Art.
                                    6 para. 1 lit. b) GDPR is the legal basis for the data processing.
                                    <br />
                                    <br />
                                    We will erase the data generated in the course of your inquiry/contact as
                                    soon as it is no longer required for the processing of your inquiry.
                                    Insofar as legal storage obligations exist, the data will be stored for
                                    the duration of the legally prescribed storage obligation.
                                    <br />
                                    <br />
                                    The use of the contact form is completely voluntary for you and not a
                                    prerequisite for the use of the platform.
                                  </p>
                                  <h4
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "40px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.5rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    5.6 Cookies
                                  </h4>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    In order to make visiting our platform attractive and to enable the use of
                                    certain functions, we use cookies on our platform. These are small text
                                    files that are stored on your terminal device. Some of the cookies we use
                                    are deleted after the end of the browser session, i.e. after you close
                                    your browser (so-called session cookies). Other cookies remain on your
                                    terminal device and enable us or our partner companies to recognize your
                                    browser on your next visit (so-called persistent cookies).
                                    <br />
                                    <br />
                                    You can find more information about the cookies used in the{" "}
                                    <span
                                      className="footerAnchor"
                                      onClick={() => this.props.history.push("/cookies")}
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        backgroundColor: "transparent",
                                        color: "blue",
                                        textDecoration: "none",
                                        borderWidth: "0px 0px 1px",
                                        borderTopStyle: "initial",
                                        borderRightStyle: "initial",
                                        borderBottomStyle: "",
                                        borderLeftStyle: "initial",
                                        borderTopColor: "initial",
                                        borderRightColor: "initial",
                                        borderBottomColor: "rgb(125, 125, 125)",
                                        borderLeftColor: "initial",
                                        borderImage: "initial",
                                        verticalAlign: "baseline",
                                        outline: "0px",
                                        fontSize: "inherit",
                                      }}
                                    >
                                      Cookie Notice.
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        )}
        {this.state.userLanguage === "ger" && (
          <div className="row m-0">
            <div className="col-md-1"></div>

            <div style={footerTermsAndContainerHeight} className="col-md-10 m-t-20 p-l-0">
              <div className="row m-0">
                <div className=" col-md-8 header-credit-page p-l-1-p m-b-0 ">{"Datenschutzerklärung"} </div>
                <div className="col-md-4 p-0 float-right m-t-7">
                  {this.state.isBackEnable && (
                    <ButtonPrimaryWithIcon
                      onClick={this.goBack.bind(this)}
                      iconName={backCircle}
                      ariaLabel={"Zurück"}
                    >
                      {"Zurück"}
                    </ButtonPrimaryWithIcon>
                  )}
                </div>
              </div>

              <div>
                <div
                  style={{
                    boxSizing: "border-box",
                    padding: "0px",
                    marginTop: "7px",
                    border: "0px",
                    verticalAlign: "baseline",
                    color: "rgb(45, 45, 45)",
                    fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                    fontSize: "16px",
                    fontVariantLigatures: "normal",
                    fontVariantCaps: "normal",
                    fontWeight: 400,
                    letterSpacing: "normal",
                    orphans: 2,
                    textAlign: "start",
                    textIndent: "0px",
                    textTransform: "none",
                    whiteSpace: "normal",
                    widows: 2,
                    wordSpacing: "0px",
                    WebkitTextStrokeWidth: "0px",
                    textDecorationStyle: "initial",
                    textDecorationColor: "initial",
                  }}
                >
                  <section
                    className=" page-section page-section--col-white     videobghost "
                    data-parallax="true"
                    data-wcmmode="false"
                  >
                    <div
                      className="parsys sectionpar"
                      style={{
                        boxSizing: "border-box",
                        margin: "0px auto",
                        border: "0px",
                        verticalAlign: "baseline",
                      }}
                    >
                      <div
                        className="parsys_column pwccol1-longform "
                        style={{
                          boxSizing: "border-box",
                          padding: "0px",
                          border: "0px",
                          verticalAlign: "baseline",
                          display: "table",
                          position: "relative",
                          tableLayout: "fixed",
                        }}
                      >
                        <div className="parsys_column pwccol1-longform-c0">
                          <div className="text parbase section">
                            <div className="text-component " data-emptytext="Edit Text">
                              <div className="text parbase section">
                                <div className="text-component " data-emptytext="Edit Text">
                                  <h2
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "0px 0px 20px",
                                      fontFamily: "PwC Helvetica Neue",
                                      fontWeight: "500",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "16px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    Datenschutzhinweise nach Art. 13, 14 EU Datenschutzgrundverordnung (DSGVO)
                                  </h2>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-10px 0px 20px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "14px",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Die nachfolgenden Datenschutzhinweise sollen Ihnen verständlich,
                                    transparent und übersichtlich erläutern, wie Ihre personenbezogenen Daten
                                    bei der Nutzung durch unsere Plattform Connected Digital Services
                                    verarbeitet werden. Sollten Sie dennoch Verständnisfragen oder sonstige
                                    Rückfragen zum Datenschutz bei PwC haben, können Sie sich gerne über die
                                    untenstehenden Kontaktdaten an uns wenden.
                                    <br />
                                    <br />
                                    Connected Digital Services ist eine Plattform, die es Mandanten
                                    ermöglicht, Projekte selbständig zu initiieren, unterstützt durch die
                                    Methodik und das Fachwissen von PwC, das in unsere digitalen Produkte
                                    eingebettet ist und bei der Navigation durch den Prozess hilft.
                                  </p>
                                  <h3
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "20px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.75rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      1. Verantwortlicher
                                    </b>
                                  </h3>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "0px 0px 20px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Der Verantwortliche im Sinne von Art. 4 Nr. 7 DSGVO für die Verarbeitung
                                    Ihrer personenbezogenen Daten ist:
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-8px 0px 20px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    <b>PricewaterhouseCoopers GmbH Wirtschaftsprüfungsgesellschaft</b>
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    Friedrich-Ebert-Anlage 35-37
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    60327 Frankfurt am Main
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    Email:&nbsp;
                                    <a
                                      className="footerAnchor"
                                      href="mailto:webkontakt_anfragen@pwc.com"
                                      target="_blank"
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        backgroundColor: "transparent",
                                        color: "inherit",
                                        textDecoration: "none",
                                        borderWidth: "0px 0px 1px",
                                        borderTopStyle: "initial",
                                        borderRightStyle: "initial",
                                        borderBottomStyle: "",
                                        borderLeftStyle: "initial",
                                        borderTopColor: "initial",
                                        borderRightColor: "initial",
                                        borderBottomColor: "rgb(125, 125, 125)",
                                        borderLeftColor: "initial",
                                        borderImage: "initial",
                                        verticalAlign: "baseline",
                                        outline: "0px",
                                        fontWeight: "bold",
                                        fontSize: "inherit",
                                      }}
                                      rel="noreferrer"
                                    >
                                      webkontakt_anfragen@pwc.com
                                    </a>
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      <br
                                        style={{
                                          boxSizing: "border-box",
                                          padding: "0px",
                                          margin: "0px",
                                        }}
                                      />
                                    </b>
                                    Telefonzentrale: +49 69 9585-0
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    Fax: +49 69 9585-1000
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "0px 0px 20px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    soweit es sich um die in den Ziffern 4 bis 5 beschriebenen Verarbeitungen
                                    handelt.
                                  </p>

                                  <h3
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "20px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.75rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      2. Datenschutzbeauftragter
                                    </b>
                                  </h3>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "0px 0px 20px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    PwC hat einen Datenschutzbeauftragten gemäß Art. 37 DSGVO benannt. Sie
                                    können den Datenschutzbeauftragten der PwC, Dr. Tobias Gräber, unter den
                                    folgenden Kontaktdaten erreichen:
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-8px 0px 20px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    E-Mail:&nbsp;
                                    <a
                                      className="footerAnchor"
                                      href="mailto:webkontakt_anfragen@pwc.com"
                                      target="_blank"
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        backgroundColor: "transparent",
                                        color: "inherit",
                                        textDecoration: "none",
                                        borderWidth: "0px 0px 1px",
                                        borderTopStyle: "initial",
                                        borderRightStyle: "initial",
                                        borderBottomStyle: "",
                                        borderLeftStyle: "initial",
                                        borderTopColor: "initial",
                                        borderRightColor: "initial",
                                        borderBottomColor: "rgb(125, 125, 125)",
                                        borderLeftColor: "initial",
                                        borderImage: "initial",
                                        verticalAlign: "baseline",
                                        outline: "0px",
                                        fontWeight: "bold",
                                        fontSize: "inherit",
                                      }}
                                      rel="noreferrer"
                                    >
                                      DE_Datenschutz@pwc.com <br />
                                    </a>
                                    Telefon: +49 69 9585-0
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-8px 0px 20px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Adresse für postalische Kontaktaufnahme:
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-8px 0px 20px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    PricewaterhouseCoopers GmbH WPG
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    Dr Tobias Gräber, Datenschutzbeauftragter
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    Friedrich-Ebert-Anlage 35-37
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    60327 Frankfurt am Main
                                  </p>

                                  <h3
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "20px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.75rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      3. Betroffenenrechte/Ihre Rechte aus dem Datenschutzrecht
                                    </b>
                                  </h3>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Sie haben folgende Rechte nach dem geltenden Datenschutzrecht hinsichtlich
                                    der Sie betreffenden personenbezogenen Daten.
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-8px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      Recht auf Auskunft:
                                    </b>{" "}
                                    Sie können jederzeit von uns Auskunft darüber verlangen, ob und welche
                                    personenbezogenen Daten wir über Sie speichern. Die Auskunftserteilung ist
                                    für Sie kostenfrei. Das Recht auf Auskunft besteht nicht oder nur
                                    eingeschränkt, wenn und soweit durch die Auskunft geheimhaltungsbedürftige
                                    Informationen offenbart würden, bspw. Informationen, die einem
                                    Berufsgeheimnis unterliegen.
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-8px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      Recht auf Berichtigung:
                                    </b>{" "}
                                    Wenn Ihre personenbezogenen Daten, die bei uns gespeichert sind, unrichtig
                                    oder unvollständig sind, haben Sie das Recht, von uns jederzeit die
                                    Berichtigung dieser Daten zu verlangen.
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-8px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      Recht auf Löschung:
                                    </b>{" "}
                                    Sie haben das Recht, von PwC die Löschung Ihrer personenbezogenen Daten zu
                                    verlangen, wenn und soweit die Daten für die Zwecke, für die sie erhoben
                                    wurden, nicht mehr benötigt werden oder, wenn die Verarbeitung auf Ihrer
                                    Einwilligung beruht, Sie Ihre Einwilligung widerrufen haben. In diesem
                                    Fall müssen wir die Verarbeitung Ihrer personenbezogenen Daten einstellen
                                    und diese aus unseren IT-Systemen und Datenbanken entfernen.
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-8px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Ein Recht auf Löschung besteht nicht, soweit
                                  </p>
                                  <ul
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px 0px 0px 30px",
                                      margin: "0px 0px 10px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      listStyle: "none",
                                      display: "block",
                                    }}
                                  >
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      die Daten aufgrund einer gesetzlichen Pflicht nicht gelöscht werden
                                      dürfen oder aufgrund einer gesetzlichen Pflicht verarbeitet werden
                                      müssen;
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      die Datenverarbeitung erforderlich ist zur Geltendmachung, Ausübung oder
                                      Verteidigung von Rechtsansprüchen.
                                    </li>
                                  </ul>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      Recht auf Einschränkung der Verarbeitung:
                                    </b>{" "}
                                    Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
                                    personenbezogenen Daten von uns zu verlangen.
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "-8px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      Recht auf Datenübertragbarkeit:
                                    </b>{" "}
                                    Sie haben das Recht, von uns die von Ihnen bereitgestellten Daten in einem
                                    strukturierten, gängigen und maschinenlesbaren Format zu erhalten sowie
                                    das Recht, dass diese Daten an einen anderen Verantwortlichen übermittelt
                                    werden. Dieses Recht besteht nur, wenn
                                  </p>
                                  <ul
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px 0px 0px 30px",
                                      margin: "0px 0px 10px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      listStyle: "none",
                                      display: "block",
                                    }}
                                  >
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Sie uns die Daten auf der Grundlage einer Einwilligung oder aufgrund
                                      eines mit Ihnen abgeschlossenen Vertrages zur Verfügung gestellt haben;
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      die Verarbeitung mithilfe automatisierter Verfahren erfolgt.
                                    </li>
                                  </ul>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "15px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      padding: "5px",
                                      border: "2px solid black",
                                      backgroundColor: "lightgray",
                                      color: "black",
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      Recht auf Widerspruch gegen die Verarbeitung:
                                    </b>{" "}
                                    Wenn die Verarbeitung Ihrer Daten auf der Grundlage des Art. 6 Abs. 1 lit.
                                    f) DSGVO erfolgt, können Sie jederzeit Widerspruch gegen die Verarbeitung
                                    einlegen.
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "-8px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Alle der oben beschriebenen Betroffenenrechte können Sie gegenüber PwC
                                    geltend machen, wenn Sie Ihr konkretes Begehren an die folgenden
                                    Kontaktdaten richten:
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "-8px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    <b>Per E-Mail:&nbsp;</b>
                                    <a
                                      className="footerAnchor"
                                      href="mailto:webkontakt_anfragen@pwc.com"
                                      target="_blank"
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        backgroundColor: "transparent",
                                        color: "blue",
                                        textDecoration: "none",
                                        borderWidth: "0px 0px 1px",
                                        borderTopStyle: "initial",
                                        borderRightStyle: "initial",
                                        borderBottomStyle: "",
                                        borderLeftStyle: "initial",
                                        borderTopColor: "initial",
                                        borderRightColor: "initial",
                                        borderBottomColor: "rgb(125, 125, 125)",
                                        borderLeftColor: "initial",
                                        borderImage: "initial",
                                        verticalAlign: "baseline",
                                        outline: "0px",
                                        fontWeight: "bold",
                                        fontSize: "inherit",
                                        display: "block",
                                      }}
                                      rel="noreferrer"
                                    >
                                      DE_Datenschutz@pwc.com
                                    </a>
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "-8px 0px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    <b>Per Post:</b>
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "-8px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    PricewaterhouseCoopers GmbH WPG
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    Dr Tobias Gräber, Datenschutzbeauftragter
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    Friedrich-Ebert-Anlage 35-37
                                    <br
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                      }}
                                    />
                                    60327 Frankfurt am Main
                                  </p>
                                  <h3
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "40px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.75rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    <b
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        fontWeight: "bold",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                      }}
                                    >
                                      4. Beschwerderecht bei einer Datenschutzaufsichtsbehörde
                                    </b>
                                  </h3>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Sie haben nach Maßgabe des Art. 77 DSGVO das Recht auf Beschwerde bei
                                    einer Datenschutzaufsichtsbehörde, wenn Sie der Ansicht sind, dass die
                                    Verarbeitung Ihrer personenbezogenen Daten gegen das Datenschutzrecht
                                    verstößt.
                                  </p>
                                  <h3
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "40px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.75rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    5. Beschreibung der Datenverarbeitungen und Rechtsgrundlagen für die
                                    Verarbeitung
                                  </h3>
                                  <h4
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "40px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.5rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    5.1 Empfänger der Daten
                                  </h4>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Zur Erfüllung der nachstehend aufgezählten Verarbeitungszwecke findet auch
                                    ein Datentransfer an Dritte statt. Dies kann auch die Übermittlung von
                                    personenbezogenen Daten in das europäische und außereuropäische Ausland
                                    beinhalten und die Speicherung von Daten auch außerhalb der EU bzw. des
                                    Europäischen Wirtschaftsraums (EWR).
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Weisungsgebundene Empfänger
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Wir geben Ihre Daten an weisungsgebundene Dienstleister sowohl innerhalb
                                    des PwC-Netzwerks als auch gegenüber sonstigen Dritten, wie z.B.
                                    IT-Dienstleistern weiter, die uns bei unserem Tätigwerden, z.B. im Rahmen
                                    der Verwaltung und Pflege der Webseiten und den damit verbundenen Systemen
                                    und/oder für andere interne oder administrative Zwecke unterstützen.
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    PwC ist Mitglied des globalen PwC-Netzwerks, das aus den einzelnen
                                    rechtlich selbständigen und unabhängigen PwC-Gesellschaften besteht. Wir
                                    nutzen im Rahmen unserer Tätigkeit weitere deutsche oder ausländische
                                    PwC-Netzwerkgesellschaften als weisungsgebundene netzwerkinterne
                                    IT-Dienstleister, die Leistungen des Betriebs, der Wartung und Pflege der
                                    von den PwC-Netzwerkgesellschaften genutzten IT-Systeme und Applikationen
                                    erbringen. Dies ist insbesondere die PwC IT Services Ltd. mit Sitz im
                                    Vereinigten Königreich (UK).
                                    <br />
                                    <br />
                                    Wenn wir Daten an weisungsgebundene Dienstleister weitergeben, benötigen
                                    wir dafür keine gesonderte Rechtsgrundlage.
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px",
                                      margin: "-10px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Nähere Informationen zu den von PwC eingesetzten Cloud-Dienste-Anbietern
                                    können Sie unter folgendem Link abfragen:
                                    <a
                                      className="footerAnchor"
                                      href="https://www.pwc.de/externe-dienstleister"
                                      target="_blank"
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        backgroundColor: "transparent",
                                        color: "blue",
                                        textDecoration: "none",
                                        borderWidth: "0px 0px 1px",
                                        borderTopStyle: "initial",
                                        borderRightStyle: "initial",
                                        borderBottomStyle: "",
                                        borderLeftStyle: "initial",
                                        borderTopColor: "initial",
                                        borderRightColor: "initial",
                                        borderBottomColor: "rgb(125, 125, 125)",
                                        borderLeftColor: "initial",
                                        borderImage: "initial",
                                        verticalAlign: "baseline",
                                        outline: "0px",
                                        fontSize: "inherit",
                                      }}
                                      rel="noreferrer"
                                    >
                                      www.pwc.de/externe-dienstleister.
                                    </a>
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Eigenverantwortliche Empfänger
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Darüber hinaus geben wir Ihre Daten im Einzelfall sowohl innerhalb des
                                    PwC-Netzwerks, als auch an sonstige Dritten weiter, welche Ihre Daten
                                    eigenverantwortlich nutzen. Bspw. übermitteln wir im Einzelfall
                                    personenbezogene Daten zur Unterstützung und Effektivierung unserer
                                    Geschäftsprozesse (einschließlich koordinierter Marketingaktivität) auch
                                    an andere Unternehmen aus dem PwC-Netzwerk.
                                    <br />
                                    <br />
                                    Darüber hinaus geben wir Ihre Daten im Einzelfall auch an andere Dritte
                                    weiter, wie z.B. Behörden, Gerichte oder andere Stellen, wenn wir
                                    gesetzlich oder aufgrund behördlicher oder gerichtlicher Anordnung eines
                                    EU-Mitgliedsstaates zur Herausgabe von personenbezogenen Daten an diese
                                    Stellen verpflichtet sind. Diese Stellen nutzen die Daten ebenfalls
                                    eigenverantwortlich.
                                    <br />
                                    <br />
                                    Soweit Sie explizit eingewilligt haben, ist Art. 6 Abs. 1 lit. a) DSGVO
                                    Rechtsgrundlage für die Datenübermittlung. Soweit eine gesetzliche
                                    Verpflichtung zur Offenlegung der Daten besteht, ist Art. 6 Abs. 1 lit. c)
                                    DSGVO Rechtsgrundlage für die Datenübermittlung. Wenn die Offenlegung
                                    dagegen zur Erfüllung einer vertraglichen- oder vorvertraglichen Maßnahme
                                    mit Ihnen als natürliche Person erforderlich ist, ist Art. 6 Abs. 1 lit.
                                    b) DSGVO Rechtsgrundlage für die Datenübermittlung. Andernfalls basiert
                                    die Übermittlung auf unseren berechtigten Interessen und die
                                    Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO; wir und die anderen
                                    Unternehmen des PwC-Netzwerks haben ein Interesse daran, unsere
                                    Arbeitsprozesse effizient zu gestalten und Geschäftsprozesse dazu
                                    innerhalb des PwC-Netzwerks aufzuteilen.
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      textDecoration: "underline",
                                    }}
                                  >
                                    Datentransfer an Empfänger in Drittländern außerhalb EU/EWR
                                  </p>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Soweit einer der o.g. Datentransfers an einen Empfänger außerhalb des EWR
                                    erfolgt (in sog. „Drittländer“), wird ein angemessenes Datenschutzniveau
                                    für die Auslandsübermittlung durch geeignete Sicherheitsmaßnahmen
                                    gewährleistet.
                                    <br />
                                    <br />
                                    Für Datentransfers innerhalb des PwC-Netzwerks haben die
                                    PwC-Netzwerkgesellschaften unter anderem einen internen Datenschutzvertrag
                                    abgeschlossen, der für den Datentransfer von personenbezogenen Daten aus
                                    EU/EWR-Staaten zu PwC-Netzwerkgesellschaften außerhalb EU/EWR die
                                    Einhaltung der EU-Standardvertragsklauseln der EU-Kommission im Sinne des
                                    Art. 46 Abs. 2 lit. c) DSGVO vorsieht.
                                    <br />
                                    <br />
                                    Soweit Sie Fragen zu solchen Datenschutzverträgen auf Basis der
                                    EU-Standardvertragsklauseln haben oder Sie gerne mehr Informationen zu
                                    weiteren Sicherheitsmechanismen und Sicherheitsmaßnahmen für den
                                    Datentransfer in Drittländer hätten, können Sie sich gerne an unseren
                                    Datenschutzbeauftragten wenden und diesen z.B. unter
                                    DE_Datenschutz@pwc.com kontaktieren.
                                  </p>
                                  <h4
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "40px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.5rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    5.2 Verarbeitung personenbezogener Daten bei Aufrufen und Nutzung der
                                    Connected Digital Services
                                  </h4>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Wenn Sie unser/e Plattform aufrufen, erheben wir die Daten, die technisch
                                    notwendig sind, um Ihnen Connected Digital Services anzuzeigen. Es handelt
                                    sich dabei um die folgenden personenbezogenen Daten, die von Ihrem Browser
                                    automatisch an unseren Server übermittelt werden:
                                  </p>
                                  <ul
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px 0px 0px 30px",
                                      margin: "0px 0px 10px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      listStyle: "none",
                                      display: "block",
                                    }}
                                  >
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      IP-Adresse (und Standort basierend auf der IP-Adresse)
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Datum und Uhrzeit Ihrer Anfrage/Ihres Aufrufs
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Zeitzonendifferenz zur Greenwich Mean Time (GMT)
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Inhalt der Anfrage (Information, welche konkrete Seite von Ihnen
                                      aufgerufen wurde)
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Zugriffsstatus/http-Statuscode
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Website, von der die Anfrage kam
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Browser (Informationen zu dem von Ihnen genutzten Browser)
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Betriebssystem und dessen Oberfläche (Betriebssystem des von Ihnen zum
                                      Aufruf (Name der Technologie) genutzten Rechners)
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Sprache und Version der Browsersoftware
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Gerätekennzeichnung (dies ist die eindeutige Nummer Ihres Endgeräts,
                                      z.B. UDID, DeviceID, IMEI)
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Nummer des Netzteilnehmers (IMSI)
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Mobilfunknummer (MSISDN)
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      MAC-Adresse für WLAN-Nutzung
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Name des mobilen Endgeräts
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      E-Mail-Adresse
                                    </li>
                                  </ul>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Die Verarbeitung dieser personenbezogenen Daten erfolgt auf Grundlage des
                                    Art. 6 Abs. 1 lit. f) DSGVO. Ohne die Verwendung dieser Daten kann die
                                    Plattform nicht aufgerufen und den Nutzern angeboten werden, es besteht
                                    ein berechtigtes Interesse daran, Aufruf und Nutzung Plattform technisch
                                    zu ermöglichen. Zudem werden die Daten gespeichert, um Plattform technisch
                                    abzusichern. Der Verantwortliche hat ein Interesse an der Sicherheit und
                                    Verfügbarkeit dieser Plattform.
                                    <br />
                                    <br />
                                    Die oben genannten Daten werden für 90 Tage gespeichert und dann gelöscht.
                                    <br />
                                    <br />
                                    Eine Datenübermittlung ins Ausland erfolgt nur in Länder der Europäischen
                                    Union bzw. des Europäischen Wirtschaftsraums.
                                  </p>
                                  <h4
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "40px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.5rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    5.3 Registrierung und Anmeldung
                                  </h4>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Der Aufruf und die Nutzung unserer Plattform ist grundsätzlich ohne
                                    Registrierung und Anmeldung nicht möglich. Einige Funktionalitäten der
                                    Plattform können jedoch nur nach einer Registrierung genutzt werden.
                                    <br />
                                    <br />
                                    Die Registrierung erfolgt durch PwC im Rahmen des Onboarding zu Connected
                                    Digital Services. Die Zugangsdaten werden Ihnen von Ihrem PwC-Kontakt
                                    mitgeteilt. Eine selbstständige Registrierung ist nicht möglich.
                                    <br />
                                    <br />
                                    Für Anmeldungen in der Anwendung wird die sogenannte
                                    Zwei-Faktor-Authentisierung (2FA) verwendet. Bei der 2FA handelt es sich
                                    um eine Sicherheitsfunktion, die zusätzlich zu den eigentlichen
                                    Anmeldedaten, mittels einer Kombination zweier unterschiedlicher Faktoren,
                                    den Nachweis Ihrer Identität sicherstellt und den Schutz Ihrer Daten
                                    gewährleistet. Bei der Registrierung können Sie zwischen der
                                    Mobilfunknummer oder E-Mail wählen. Bei der Anmeldung wird nach Eingabe
                                    der Anmeldedaten anschließend ein Verifizierungscode über den gewählten
                                    Weg gesendet, der in der Anmeldemaske zur Authentifizierung einzugeben
                                    ist.
                                    <br />
                                    <br />
                                    Bei der Registrierung und Anmeldung verarbeitet PwC die folgenden
                                    Informationen:
                                    <br />
                                    <br />
                                  </p>
                                  <ul
                                    style={{
                                      boxSizing: "border-box",
                                      textAlign: "justify",
                                      padding: "0px 0px 0px 30px",
                                      margin: "0px 0px 10px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      listStyle: "none",
                                      display: "block",
                                    }}
                                  >
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      E-Mail-Adresse
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Name
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Mobilfunknummer
                                    </li>
                                    <li
                                      className="f-t-s-14 t-a-j "
                                      style={{
                                        boxSizing: "border-box",
                                        textAlign: "justify",
                                        padding: "0px",
                                        margin: "0px 0px 25px",
                                        border: "0px",
                                        verticalAlign: "baseline",
                                        fontSize: "1.125rem",
                                        lineHeight: "1.7",
                                      }}
                                    >
                                      Passwort
                                    </li>
                                  </ul>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Die Verarbeitung dieser personenbezogenen Daten erfolgt auf Grundlage von
                                    Art. 6 Abs. 1 lit. c) i. V. m. Art. 32 DSGVO. Sämtliche Daten werden nach
                                    Beendigung des Vertragsverhältnisses von uns gelöscht. Soweit gesetzliche
                                    Aufbewahrungspflichten bestehen, werden die Daten für die Dauer der
                                    gesetzlich vorgeschriebenen Aufbewahrungspflicht gespeichert.
                                  </p>
                                  <h4
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "40px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.5rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    5.4 Datenverarbeitung bei Nutzung der Connected Digital Services
                                  </h4>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Sie stehen in einer geschäftlichen Beziehung mit PwC, wenn entweder Sie
                                    selbst oder Ihr Arbeitgeber Vertragspartner von PwC ist. Connected Digital
                                    Services nutzen wir zur Erbringung unserer vertraglich vereinbarten
                                    Leistungen. Hierzu gelten in Ergänzung zu diesen vorliegenden
                                    Datenschutzhinweisen die “Datenschutzhinweise im Rahmen der Leistungen von
                                    PwC”, welche vor unserer Beauftragung übermittelt wurden. Die
                                    “Datenschutzhinweise im Rahmen der Leistungen von PwC” beschreiben, wie
                                    PwC personenbezogene Daten im Rahmen der Durchführung von Dienstleistungen
                                    der Steuerberatung, Rechtsberatung oder Unternehmensberatung /
                                    Wirtschaftsprüfung oder sonstigen Aufträgen verarbeitet. Die
                                    “Datenschutzhinweise im Rahmen der Leistungen von PwC” können Sie nochmals
                                    unter folgendem Link abrufen:
                                  </p>
                                  <a
                                    className="footerAnchor"
                                    href="https://www.pwc.de/de/datenschutzerklaerung-fuer-mandanten/datenschutzhinweise-pricewaterhousecoopers-g
                                          mbh-wirtschaftspruefungsgesellschaft.html"
                                    target="_blank"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px",
                                      backgroundColor: "transparent",
                                      color: "blue",
                                      textDecoration: "none",
                                      borderWidth: "0px 0px 1px",
                                      borderTopStyle: "initial",
                                      borderRightStyle: "initial",
                                      borderBottomStyle: "",
                                      borderLeftStyle: "initial",
                                      borderTopColor: "initial",
                                      borderRightColor: "initial",
                                      borderBottomColor: "rgb(125, 125, 125)",
                                      borderLeftColor: "initial",
                                      borderImage: "initial",
                                      verticalAlign: "baseline",
                                      outline: "0px",
                                      fontSize: "inherit",
                                    }}
                                    rel="noreferrer"
                                  >
                                    https://www.pwc.de/de/datenschutzerklaerung-fuer-mandanten/datenschutzhinweise-pricewaterhousecoopers-gmbh-wirtschaftspruefungsgesellschaft.html
                                  </a>
                                  <h4
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "40px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.5rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    5.5 Kontaktformular und Kontaktaufnahme per E-Mail
                                  </h4>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Wir bieten innerhalb unserer Plattform ein Kontaktformular an, damit Sie
                                    sich mit Fragen zu PwC, unserer Plattform und sonstigen Anfragen an uns
                                    wenden können. Darüber hinaus können Sie per E-Mail mit uns Kontakt
                                    aufnehmen.
                                    <br />
                                    <br />
                                    Bei Ihrer Kontaktaufnahme mit uns über das Kontaktformular oder per E-Mail
                                    werden die von Ihnen mitgeteilten Daten (insbesondere Ihre E-Mail-Adresse,
                                    Ihren Vor- und Nachnamen und den Text Ihrer Anfrage sowie ggf. weitere
                                    Angaben, die sie im Kontaktformular oder per E-Mail gemacht haben), von
                                    uns gespeichert, um Ihre Anfrage zu bearbeiten und Ihre Fragen zu
                                    beantworten.
                                    <br />
                                    <br />
                                    Die Datenverarbeitung ist gem. Art. 6 Abs. 1 lit. f) DSGVO gerechtfertigt.
                                    Wir haben ein Interesse daran, auf Ihre Anfrage hin, mit Ihnen über
                                    Plattform in Kontakt zu treten. Soweit Ihre Anfrage auf die Erfüllung
                                    einer vertraglichen oder vorvertraglichen Maßnahme mit Ihnen als
                                    natürliche Person abzielt, ist Art. 6 Abs. 1 lit. b) DSGVO Rechtsgrundlage
                                    für die Datenverarbeitung.
                                    <br />
                                    <br />
                                    Die im Rahmen Ihrer Anfrage/Kontaktaufnahme anfallenden Daten werden von
                                    uns gelöscht, sobald diese für die Bearbeitung Ihrer Anfrage nicht mehr
                                    erforderlich sind. Soweit gesetzliche Aufbewahrungspflichten bestehen,
                                    werden die Daten für die Dauer der gesetzlich vorgeschriebenen
                                    Aufbewahrungspflicht gespeichert. Die Nutzung des Kontaktformulars ist für
                                    Sie vollständig freiwillig und nicht Voraussetzung für die Nutzung der/s
                                    Plattform.
                                  </p>
                                  <h4
                                    className="f-t-s-16 p-l-1-p"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "40px 0px 20px",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                      fontWeight: "bold",
                                      lineHeight: "1.3em",
                                      color: "rgb(45, 45, 45)",
                                      fontSize: "1.5rem",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                    }}
                                  >
                                    5.6 Cookies
                                  </h4>
                                  <p
                                    className="f-t-s-14 p-l-1-p t-a-j"
                                    style={{
                                      boxSizing: "border-box",
                                      padding: "0px",
                                      margin: "0px 0px 35px",
                                      border: "0px",
                                      verticalAlign: "baseline",
                                      fontSize: "1.125rem",
                                      lineHeight: "1.7",
                                      fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                                    }}
                                  >
                                    Um den Besuch unserer Plattform attraktiv zu gestalten und die Nutzung
                                    bestimmter Funktionen zu ermöglichen, verwenden wir auf unserer Plattform
                                    Cookies. Hierbei handelt es sich um kleine Textdateien, die auf Ihrem
                                    Endgerät abgelegt werden. Einige der von uns verwendeten Cookies werden
                                    nach dem Ende der Browser-Sitzung, also nach Schließen Ihres Browsers,
                                    wieder gelöscht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf
                                    Ihrem Endgerät und ermöglichen uns oder unseren Partnerunternehmen, Ihren
                                    Browser beim nächsten Besuch wiederzuerkennen (sog. persistente Cookies).
                                    <br />
                                    <br />
                                    Nähere Informationen zu den eingesetzten Cookies finden Sie in der{" "}
                                    <span
                                      className="footerAnchor"
                                      onClick={() => this.props.history.push("/cookies")}
                                      style={{
                                        boxSizing: "border-box",
                                        padding: "0px",
                                        margin: "0px",
                                        backgroundColor: "transparent",
                                        color: "blue",
                                        textDecoration: "none",
                                        borderWidth: "0px 0px 1px",
                                        borderTopStyle: "initial",
                                        borderRightStyle: "initial",
                                        borderBottomStyle: "",
                                        borderLeftStyle: "initial",
                                        borderTopColor: "initial",
                                        borderRightColor: "initial",
                                        borderBottomColor: "rgb(125, 125, 125)",
                                        borderLeftColor: "initial",
                                        borderImage: "initial",
                                        verticalAlign: "baseline",
                                        outline: "0px",
                                        fontSize: "inherit",
                                      }}
                                    >
                                      Cookie Notice.
                                    </span>
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default PrivacyPolicyComponent;
