import React from "react";
import "./NeedHelpCard.css";
import { getDFClasses } from "./handler";
import SupportService from "../../../Services/DFED/SupportService";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import CustomModal from "../../../Assets/Components/Modal";
import LinkText from "../../../Assets/Components/LinkText/LinkText";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import { closeIcon } from "../../../Assets/Icons";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import { DataContext } from "../../../Services/Shared/Store.js";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";

const supportService = new SupportService();
// NEED HELP CARD COMPONENT FOR DISPLAYING ALL CONTENT IN NEED HELP BASED ON MODULE

export default class NeedHelpCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: true,
      selectedProjectId: null,
      moduleFAQ: [],
      visibleFAQsModel: false,
      modelBodyContent: [],
      modalTitel: null,
      openConfirmBox: false, // this value handles the confirm box of exit form
    };
    this.goToAskForSupport = this.goToAskForSupport.bind(this);
    this.onNeedHelpClick = this.onNeedHelpClick.bind(this);
  }
  static contextType = DataContext;
  componentDidMount() {
    this.getSupportedService();

    if (this.props.props.location.state) {
      this.setState({
        selectedProjectId: this.props.props.location.state.selectedProjectId,
      });
    } else {
      this.setState({ selectedProjectId: null });
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.language !== this.props.language) {
      this.getSupportedService();
    }
  }
  // GET FAQ DETAILS FROM API BASED ON LANGUAGE
  // CALLING API OR GETTING CONTENT FROM BACKEND BASED ON MDULE
  async getSupportedService() {
    const language = this.props.language;
    let supportInfo = await supportService.FAQService(language);
    let path = this.props.props.location;
    let url = path.pathname;
    let data;
    if (supportInfo && supportInfo.data) {
      data = supportInfo.data;
      if (url.includes("/dfed")) {
        this.setState({ moduleFAQ: data.dfed });
      } else if (url.includes("/queenbase")) {
        this.setState({ moduleFAQ: data.queenBase });
      } else if (url.includes("/df")) {
        this.setState({ moduleFAQ: data.df });
      } else if (url.includes("/css")) {
        this.setState({ moduleFAQ: data.css });
      } else if (url.includes("/gi")) {
        this.setState({ moduleFAQ: data.gi });
      }
    }
  }
  // CLOSE THE NEEDHELPCARD SECTION
  onNeedHelpClick() {
    this.props.onNeedHelpClick();
  }
  // CLOSE FORM AND NAVIGATE TO VIEW PROJECTS
  closeFormAndRedirect = () => {
    const { formGotClosed } = this.context;
    this.toggleConfirmBox();
    this.goToAskForSupport();
    formGotClosed();
  };

  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  toggleConfirmBox = () => {
    this.setState({
      openConfirmBox: !this.state.openConfirmBox,
    });
  };

  // CHECK FORM STATUS AND REDIRECT
  checkFormStatusAndRedirect = () => {
    const { isFormOpen } = this.context;
    if (isFormOpen) {
      // form is in open state, hence user cannot navigate to other screens
      this.toggleConfirmBox();
    } else {
      this.goToAskForSupport();
    }
  };

  // REDIRECTING TO ASK FOR SUPPORT PAGE
  goToAskForSupport = () => {
    let previousURl = "";
    let path = this.props.props.location;
    let url = path.pathname;
    if (url.includes("/df/home")) {
      url = "/df/home";
    } else if (url.includes("/df/upload")) {
      url = "/df/upload";
    } else if (url.includes("/df/dashboard")) {
      url = "/df/dashboard";
    }
    //for GIBase
    if (url.includes("/gi/home")) {
      url = "/gi/home";
    } else if (url.includes("/gi/admin")) {
      url = "/gi/admin";
    }
    // for QueenBase
    else if (url.includes("/queenbase/solutions/solution")) {
      url = "/queenbase/solutions/solution";
    } else if (url.includes("/queenbase/projects/viewprojects")) {
      url = "/queenbase/projects/viewprojects";
    } else if (url.includes("/queenbase/users")) {
      url = "/queenbase/users";
    } else if (url.includes("/queenbase/mydashboard")) {
      url = "/queenbase/mydashboard";
    } else if (url.includes("/queenbase/companycenter")) {
      url = "/queenbase/companycenter";
    } else if (url.includes("/queenbase/admin/view") || url.includes("/queenbase/admin/invoice")) {
      url = "/queenbase/admin/view";
    }

    switch (url) {
      //For DFED
      case "/dfed/home":
        previousURl = "Home";
        break;
      case "/dfed/custodian":
        previousURl = "Custodians";
        break;
      case "/dfed/keywords":
        previousURl = "Keywords";
        break;
      case "/dfed/profileList":
        previousURl = "Profiles";
        break;
      case "/dfed/analysis":
        previousURl = "Analysis";
        break;
      case "/dfed/processing":
        previousURl = "Processing";
        break;
      case "/dfed/production":
        previousURl = "Production";
        break;
      case "/dfed/reviewdetails":
        previousURl = "Review";
        break;
      case "/dfed/admin":
        previousURl = "Admin";
        break;
      //For DF
      case "/df/home":
        previousURl = "DF_Home";
        break;
      case "/df/upload":
        previousURl = "DF_Upload";
        break;
      case "/df/dashboard":
        previousURl = "DF_Dashboard";
        break;
      //For CSS
      case "/css/history":
        previousURl = "CSS_Home";
        break;
      case "/css/search":
        previousURl = "CSS_Search";
        break;
      case "/css/admin":
        previousURl = "CSS_Admin";
        break;
      //For QueenBase
      case "/queenbase/solutions/solution":
        previousURl = "Queenbase_Solutions";
        break;
      case "/queenbase/projects/viewprojects":
        previousURl = "Queenbase_Projects";
        break;
      case "/queenbase/users":
        previousURl = "Queenbase_Users";
        break;
      case "/queenbase/mydashboard":
        previousURl = "Queenbase_Myrelationship";
        break;
      case "/queenbase/companycenter":
        previousURl = "Queenbase_Companycenter";
        break;
      case "/queenbase/admin/view":
        previousURl = "Queenbase_Admin";
        break;
      default:
        if (url.includes("/dfed")) {
          previousURl = "Home";
        } else if (url.includes("/queenbase")) {
          previousURl = "Queenbase_Solutions";
        } else if (url.includes("/df")) {
          previousURl = "DF_Home";
        } else if (url.includes("/gi")) {
          previousURl = "GI_Home";
        } else if (url.includes("/css")) {
          previousURl = "CSS_Home";
        }
    }

    if (url.includes("/dfed")) {
      this.props.props.history.push({
        pathname: "/dfed/support",
        state: { selectedProjectId: this.state.selectedProjectId },
      });
    } else if (url.includes("/queenbase")) {
      let path = `/queenbase/support`;
      this.props.props.history.push({ pathname: path });
    } else if (url.includes("/gi")) {
      this.props.props.history.push({
        pathname: "/gi/support",
        state: { selectedProjectId: this.state.selectedProjectId },
      });
    } else if (url.includes("/css")) {
      this.props.props.history.push({
        pathname: "/css/support",
        state: { selectedProjectId: this.state.selectedProjectId },
      });
    } else {
      let path = "/df/support";
      this.props.props.history.push({
        pathname: path,
        state: { selectedProjectId: this.state.selectedProjectId },
      });
    }

    localStorage.setItem("previousComponent", previousURl);
  };

  showModal = (modelContent, modalName) => {
    this.setState(
      {
        visibleFAQsModel: true,
        modelBodyContent: modelContent,
        modalTitel: modalName,
      },
      () => {},
    );
  };
  // CLOSE POPUP RELATED TO FAQ QUESTION
  handleConfirm = (e) => {
    this.setState({
      visibleFAQsModel: false,
    });
  };

  render() {
    const { openFrom } = this.props;
    const polyglot = polyglotLoader();
    return (
      <div className="card cardDiv scale-in-br">
        <div className="card-header chatBotHeader d-flex justify-content-between">
          <h5 className="chatBotHeader vertical-center">
            {polyglot.t("Hilfe")}
            {/* {JSON.parse(sessionStorage.getItem("user")).firstname} */}

            {/* <span className={`cursor-pointer ${getDFClasses(openFrom, 'pointer')}  needHelpCard`} style={{ color: '#ffffff', float: 'right' }} onClick={this.onNeedHelpClick}>X</span> */}
          </h5>
          <ButtonIcon
            className="need-help-close-button"
            onClick={this.onNeedHelpClick}
            iconName={closeIcon}
          />
        </div>
        <div className="card-body DF-support-Div">
          <div className={`row ${getDFClasses(openFrom, "row")} innerRow m-l-0 m-r-0`}>
            <div className="innerCard">
              <div className={`row ${getDFClasses(openFrom, "row")} textRowDiv m-l-0 m-r-0`}>
                <div className="f-s-12 p-l-9 p-r-9 p-t-3">
                  {polyglot.t(
                    "In case  you have any questions regarding the use of Client operated Professional service platform, we are here for you.",
                  )}
                </div>
                <br />
                <div className="f-s-12 p-l-9 p-r-9 p-t-3">
                  {polyglot.t(
                    "You can find some answers to common questions in the FAQ session below. If you can't find the answer you are looking for, do not hesitate to contact us using the contact form.",
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={`row ${getDFClasses(openFrom, "row")} innerRow m-r-0 m-l-0`}>
            <div className="innerCard p-b-0">
              <div className={`row ${getDFClasses(openFrom, "row")} textRowDiv m-l-0 m-r-0`}>
                <div className="f-s-13 p-l-9  p-t-3 f-w-600 p-r-9">
                  {polyglot.t("Frequently asked questions")}
                </div>
                <hr />
                {this.state.moduleFAQ.map((item, index) => {
                  return (
                    <div className="questionCategories" key={index}>
                      <div
                        className="f-s-12 cursor p-l-11 marginpoint25"
                        onClick={() => this.showModal(item.questions, item.Name)}
                      >
                        <LinkText ariaLabel={polyglot.t(item.Name)}>{polyglot.t(item.Name)}</LinkText>
                      </div>
                      {/* Note: do not show horizontal line on the last FAQ */}
                      {index + 1 !== this.state.moduleFAQ.length && <hr />}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className={`row ${getDFClasses(openFrom, "row")} innerRow pt-3  m-r-0 m-l-0`}>
            <div className={`row ${getDFClasses(openFrom, "row")} textRowDiv m-l-0 m-r-0 askForSupport`}>
              <ButtonSecondary
                className="ask-for-support-btn"
                onClick={this.checkFormStatusAndRedirect}
                ariaLabel={polyglot.t("Ask for support")}
              >
                {polyglot.t("Ask for support")}
              </ButtonSecondary>
            </div>
          </div>
        </div>

        <CustomModal
          // className="form-modal"
          show={this.state.visibleFAQsModel}
          onCancel={this.handleConfirm}
          title={this.state.modalTitel}
          // footer={[<Button key="Back" onClick={this.handleConfirm}>{polyglot.t("Close")}</Button>]}
          Footer={[]}
          showCloseButton={true}
        >
          <div className="row m-l-0 m-r-0 " id="needHelpModel">
            <div className="col-12">
              <div>
                <div className="flex-fill">
                  {this.state.modelBodyContent.map((question, index) => {
                    return (
                      <div key={index}>
                        <div className="row f-s-12 p-r-9 f-w-600 break-word">{question.question}</div>
                        <div
                          className="row f-s-12 p-r-9 break-word faq-answer"
                          dangerouslySetInnerHTML={{ __html: question.answer }}
                        ></div>
                        <br></br>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </CustomModal>

        {/* This confirm box will open if the form in the application is in open state */}
        <ConfirmBoxExitForm
          show={this.state.openConfirmBox}
          heading={"Cancel update"}
          cancel={this.toggleConfirmBox}
          proceed={this.closeFormAndRedirect}
        />
      </div>
    );
  }
}
