import React, { useContext } from "react";

import { DataContext } from "../../../Services/Shared/Store";
import ChatComponent from "../Support/Chat";
import NeedHelpCard from "../Support/NeedHelpCard";
// NEED HELP MAIN CLASS RESPONSIBLE FOR RENDERING ALL NEED HELP RELATED UI AND RENDERFROM HERE================

let NeedHelpHome = (props) => {
  const { getUserLanguage } = useContext(DataContext);
  let [showPopUp, setShowPopUp] = React.useState(false);
  // TOGGLE OPEN NEEDHELP BUTTON
  let onNeedHelpClick = () => {
    setShowPopUp(!showPopUp);
  };
  return (
    <div>
      <ChatComponent openFrom={props.openFrom} onNeedHelpClick={onNeedHelpClick} />
      {showPopUp && (
        <NeedHelpCard
          props={props.props}
          openFrom={props.openFrom}
          onNeedHelpClick={onNeedHelpClick}
          language={getUserLanguage()}
        />
      )}
    </div>
  );
};
export default NeedHelpHome;
