import React, { useState, useEffect } from "react";

import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/GRC/loading-spin.svg";
import HomeService from "../../../Services/GRC/HomeService";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import EmptyPanel from "../Shared/EmptyPanel";
import ErrorPanel from "../Shared/ErrorPanel";
import "./index.css";
import HomeTop from "./HomeTop";
import HomeListViewContainer from "./HomeListViewContainer";
import HomeGridViewContainer from "./HomeGridViewContainer";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";

/**
 * home
 * @param {*} props
 * @returns
 */

const Home = (props) => {
  const polyglot = polyglotLoader();
  const [toggleGridview, settoggleGridview] = useState(true);
  const [toggleListview, settoggleListview] = useState(false);
  const [loadCategory, setloadCategory] = useState([]);
  const [searchStr, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  /// FETCH THE LIST OF ALL QUESTIONNAIRES
  const getCategories = async () => {
    try {
      const responseData = await sendRequest(HomeService.get().getHomeCategoryURL());
      setloadCategory(responseData);
    } catch (err) {
      displayToastMessage(polyglot.t(err.message), "error");
    }
  };

  useEffect(() => {
    getCategories();
  }, [sendRequest]);

  const handleNavigateToGridView = (e) => {
    e.preventDefault();
    settoggleGridview(true);
    settoggleListview(false);
  };
  const handleNavigateToListView = (e) => {
    e.preventDefault();
    settoggleGridview(false);
    settoggleListview(true);
  };

  /**
   * passing input text as a search param.
   *
   * @param {string} e
   */
  const onSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  return (
    <>
      <div className="grchome">
        <div className="row mr-0 justify-content-start p-t-b-10 containerhomebutn">
          <HomeTop />
        </div>
        <div className="row justify-content-start p-0">
          <div className="col-md-4 pl-0">
            <h1 className="main_title_qb headingtitle">{polyglot.t("list_of_questionnaire")}</h1>
          </div>
          <div className="col-md-8 m-r-2">
            <div className="row float-right grcicon">
              <ul className="list-inline justify-content-end">
                <li className="list-inline-item pt-0 pr-1 pb-0 pl-0" title="Table view">
                  <ButtonIcon
                    onClick={(e) => {
                      handleNavigateToListView(e);
                    }}
                    ariaLabel="Table view"
                    title="Table view"
                  >
                    <span className="appkiticon icon-list-view-outline" />
                  </ButtonIcon>
                </li>
                <li className="list-inline-item pt-0 pr-1 pb-0 pl-0" title="Grid view">
                  <ButtonIcon
                    onClick={(e) => {
                      handleNavigateToGridView(e);
                    }}
                    ariaLabel="Grid view"
                    title="Grid view"
                  >
                    <span className="appkiticon icon-grid-view-outline" />
                  </ButtonIcon>
                </li>
                {/* HIDE SEARCH BOX IN LIST VIEW */}
                {!toggleListview && (
                  <li className="list-inline-item pt-0 pr-1 pb-0 pl-0">
                    <div className={`searchIcon`}>
                      <input
                        value={searchStr}
                        className="grc-home-SearchBar"
                        placeholder={polyglot.t("search")}
                        onChange={onSearch}
                        autoComplete={"off"}
                        aria-label={polyglot.t("search")}
                      />
                    </div>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>

        {isLoading && (
          <div className="row justify-content-center">
            <div>
              <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
            </div>
          </div>
        )}

        {error && (
          <div className="row justify-content-start mr-0 search_box_home p-t-b-10">
            <ErrorPanel error={error} onClear={clearError} />
          </div>
        )}
        {!isLoading && loadCategory.length === 0 && (
          <div className="m-l-minus-point8rem mt-3 ml-n3 mr-3">
            <EmptyPanel massage={"No data found."} />
          </div>
        )}
        {!isLoading && loadCategory.length > 0 && toggleGridview && (
          <div className="row justify-content-start mr-0 search_box_home p-t-b-10">
            <HomeGridViewContainer
              grid_data={loadCategory}
              searchStr={searchStr}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              getCategories={getCategories}
            />
          </div>
        )}
        {!isLoading && loadCategory.length > 0 && toggleListview && (
          <div className="row justify-content-start mr-0 search_box_home p-t-b-10">
            <HomeListViewContainer table_data={loadCategory} getCategories={getCategories} />
          </div>
        )}
      </div>
    </>
  );
};

export default Home;
