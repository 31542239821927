import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import * as path from "../../../../Constants/GRCAssessmentPath";
import "./index.css";

import Loader from "../../../../Assets/Images/DFED/icon-loader.svg";
import displayToastMessage from "../../../../Components/QueenBase/Root/displayToastMessage";
import RecipientService from "../../../../Services/GRC/RecipientService";
import { useHttpClient } from "../../../../Services/GRC/http-hook";
import { polyglotLoader } from "../../../../Services/Shared/TranslateGRC";
import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import AssessmentCompletion from "../AssessmentCompletion";
import PerformInvalid from "../PerformInvalid";

import OtpInput from "react-otp-input";

const OTPBox_Styles = {
  height: "35px",
  width: "32px",
  backgroundColor: "#dedede",
  border: "none",
  fontWeight: "bold",
};

const PerformAuth = (props) => {
  const polyglot = polyglotLoader();
  const { isLoading, sendRequest } = useHttpClient();
  const uid = props.match.params.uid;

  const [OTPContent, setOTPContent] = useState();
  const [isVerificationStarted, setIsVerificationStarted] = useState(false);
  const [userOTP, setUserOTP] = useState("");

  const postinput = {
    email: "", //values.clientEmail,
    unique_id: uid,
  };

  /**
   * authorize the user with GUID.
   */
  const getAutherisationDetails = async () => {
    try {
      const response = await sendRequest(
        RecipientService.get().postRecipientAuthURL(),
        "POST",
        JSON.stringify(postinput),
        {
          "Content-Type": "application/json",
        },
      );

      // OTP ===0 is done assessment | OTP ===-1 Expired | OTP ===-2 Othere
      if (response.OTP > 0) {
        setOTPContent(response);
      } else if (response.OTP === 0) {
        setOTPContent(response);
      }
    } catch (err) {}
  };

  const getTokenAutherisation = async () => {
    try {
      const postauthinput = {
        email: "",
        unique_id: uid,
      };

      const response = await sendRequest(
        RecipientService.get().postTokenAuthURL(),
        "POST",
        JSON.stringify(postauthinput),
        {
          "Content-Type": "application/json",
        },
      );
      sessionStorage.setItem("grc_recipient_selectedProject", response.projectId);
      sessionStorage.setItem("grc_recipient_jwt", response.jwt);
    } catch (err) {}
  };

  const loginServiceGenerateLoginOTP = async () => {
    const uid = props.match.params.uid;

    if (uid) {
      getAutherisationDetails();
    } else {
      // navigate to no access screen
      props.location.push();
    }
  };
  useEffect(() => {
    loginServiceGenerateLoginOTP();
  }, [sendRequest]);

  const captureOTPInputs = async (event) => {
    if (event.keyCode === 13) {
      verifyOtp();
    }
  };

  const verifyOtp = async () => {
    setIsVerificationStarted(true);
    if (parseInt(userOTP) === parseInt(OTPContent.OTP)) {
      await getTokenAutherisation();
      sessionStorage.setItem("grc_recipient_user_email", OTPContent.Email);
      sessionStorage.setItem("grc_recipient_user_uid", uid);
      setIsVerificationStarted(false);
      // call auth for jwt token

      props.history.push(path.PERFORM_ASSESSMENT_PATH);
    } else {
      displayToastMessage(polyglot.t("OTP did not matched."), "error");
      setIsVerificationStarted(false);
    }
  };

  const displayOTPForm = () => {
    return (
      <div>
        <div>
          <h6>{polyglot.t("otpAuthentication")}</h6>
          <div className="max-width-fit-content">
            {polyglot.t("we’ve sent you an email with a verification code.")}
          </div>
          <div className="otp-input-align" onKeyDown={captureOTPInputs}>
            <OtpInput
              inputStyle={OTPBox_Styles}
              onChange={(otp) => setUserOTP(otp)}
              numInputs={6}
              value={userOTP}
              shouldAutoFocus={true}
              data-testid="otp-input"
              separator={
                <span
                  style={{
                    marginLeft: "20px",
                  }}
                />
              }
            />
          </div>
          {!isVerificationStarted && (
            <div
              className="row"
              style={{
                marginBottom: "20px",
                marginLeft: "-5PX",
              }}
            >
              <div className="col-md-5" data-testid="submit-otp-wrapper">
                <ButtonPrimary onClick={verifyOtp} ariaLabel={polyglot.t("submit.otp")}>
                  {polyglot.t("submit.otp")}
                </ButtonPrimary>
              </div>
            </div>
          )}
        </div>
        {isVerificationStarted && (
          <p
            style={{
              fontSize: "10px",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            {polyglot.t("Checking your code")} <img width="40px" src={Loader} alt="loading..." />
          </p>
        )}
      </div>
    );
  };

  const id = uid;
  const pattern = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return (
    <div className={`${pattern.test(id) !== true ? "" : OTPContent && OTPContent.OTP === 0 ? "" : "bg-img"}`}>
      {OTPContent && OTPContent.OTP === 0 && pattern.test(id) === true && <AssessmentCompletion />}
      {!isLoading && pattern.test(id) === true && OTPContent && OTPContent.OTP !== 0 && (
        <div className="login-bg-image">
          <div className="container">
            <div className="login-form">
              <div className="d-flex">
                <div className="card bg-light margin-0-auto min-width-25">
                  <div className="card-header header_login Header_resposnive_login">
                    <h1 className="headerText_Login">{`${polyglot.t("welcome")}`}</h1>
                  </div>
                  <div className="card-body user-login">{displayOTPForm()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {pattern.test(id) !== true && <PerformInvalid />}
    </div>
  );
};
export default withRouter(PerformAuth);
