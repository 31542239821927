import "../../../Assets/Css/QueenBase/Projects/ProjectSelection.css";
import PropTypes from "prop-types";
import React from "react";

import CustomModal from "../../../Assets/Components/Modal";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import UserFormContainer from "../Users/UserFormContainer";

// USER CAN BE ADDED FROM PROJECT FORM
const AddUserFromProject = (props) => {
  var polyglot = polyglotLoader();

  // DATA FROM PROPS
  const { isOpen, close, getAllUsers, userCount } = props;

  // NAVIGATE BACK TO PROJECT FORM
  const navigateBack = () => {
    getAllUsers();
    close(false);
  };

  return (
    <CustomModal
      show={isOpen}
      onCancel={() => close(false)}
      title={polyglot.t("Add a new user")}
      Footer={[]}
      hideFooter={true}
    >
      <UserFormContainer navigateBack={navigateBack} userCount={userCount} />
    </CustomModal>
  );
};
AddUserFromProject.propTypes = {
  isOpen: PropTypes.bool,
  close: PropTypes.func,
  getAllUsers: PropTypes.func,
  userCount: PropTypes.object,
};
export default AddUserFromProject;
