import HttpClient from "../../Shared/HttpClient";
import jwtUtil from "../../../Constants/Jwt";

class WidgetService {
  constructor() {
    this.client = new HttpClient();
  }

  // API TO GET WIDGET DATA
  async getWidgetData(data) {
    var widgetdata = jwtUtil.encrypt(data);
    const result = await this.client.get("/getWidgetData", widgetdata);
    return result;
  }

  async getData(widget, language = "en") {
    if (widget == "regularusers") {
      var result = this.getRegularUsersList();
    }
    if (widget == "temporaryusers") {
      var result = this.getTemporaryUsersList();
    }
    if (widget == "expiringusers") {
      var result = this.getExpiringUsersList();
    }
    if (widget == "projects") {
      var result = this.getDashboardProjectsList();
    }
    if (widget == "projectmilestones") {
      var result = this.getProjectMilestones();
    }
    if (widget == "upcomingmilestones") {
      var result = this.getUpcomingMilestones();
    }
    if (widget == "packageconsumption") {
      var result = this.getPackageConsumption();
    }
    if (widget == "invoicedetails") {
      var result = this.getInvoiceDetails();
    }
    if (widget == "expensiveprojects") {
      var result = this.getExpensiveProjects();
    }
    if (widget == "custodian") {
      var result = this.getCustodian();
    }
    if (widget == "data") {
      var result = this.getEdiscoveryData();
    }
    if (widget == "documents") {
      var result = this.getDocuments();
    }
    if (widget == "processdiagram") {
      var result = this.getProcessData();
    }
    if (widget == "queenbaseprogress") {
      var result = this.getQueenBaseProgress();
    }
    if (widget == "dataupload") {
      var result = this.getDataUpload();
    }
    if (widget == "topinvoices") {
      var result = this.getTopInvoiceDetails();
    }
    if (widget == "expensiveinvoices") {
      var result = this.getExpensiveInvoices();
    }
    if (widget == "projectwiseinvoice") {
      var invoice = this.getProjectWiseInvoices();
      var projects = this.FetchAllProjects();
      var result = {
        invoicedetails: invoice,
        projects: projects,
      };
    }
    if (widget == "generalstatistics") {
      var result = this.getGeneralStatistics();
    }
    if (widget == "analyticsview") {
      var result = this.getAnalyticsView();
    }
    if (widget == "totalrequests") {
      var result = this.fetchTotalRequestsForDashboard(language);
    }
    if (widget == "requeststatus") {
      var result = this.requestStatusForDashboard(language);
    }
    return result;
  }
  // API TO GET REGULAR USER LIST BY PASSING USER COUNT
  async getRegularUsersList() {
    const result = await this.client.get("/getRegularUsersList");
    var res = result.userData.usercount + " / " + result.userData.maxusercount;
    return res;
  }

  // GET TEMPORARY USERS LIST BY PASSING USER COUNT
  async getTemporaryUsersList() {
    const result = await this.client.get("/getTemporaryUsersList");
    var res = result.userData.operatingtempusers + " / " + result.userData.maxtempusercount;
    return res;
  }

  // API TO GET EXISTING USERS LIST
  async getExpiringUsersList() {
    const result = await this.client.get("/getExpiringUsersList");
    return result;
  }

  // API TO GET PROJECTS LIST OF DASHBOARD
  async getDashboardProjectsList() {
    const result = await this.client.get("/getDashboardProjectsList");
    return result;
  }

  // API TO GET PROJECT MILESTONES
  async getProjectMilestones() {
    const result = await this.client.get("/getProjectMilestones");
    return result;
  }

  // API TO GET UPCOMING MILESTONES
  async getUpcomingMilestones() {
    const result = await this.client.get("/getUpcomingMilestones");
    return result;
  }

  // API TO GET PACKAGE CONSUMPTION
  async getPackageConsumption() {
    const result = await this.client.get("/getPackageConsumption");
    return result;
  }

  // API TO GET INVOICE DETAILS
  async getInvoiceDetails() {
    const result = await this.client.get("/getInvoiceDetails");
    return result;
  }

  // API TO GET EXPENSIVE PROJECTS
  async getExpensiveProjects() {
    const result = await this.client.get("/getExpensiveProjects");
    return result;
  }

  // API TO GET CUSTODIAN DETAILS
  async getCustodian(data) {
    const result = await this.client.get("/getCustodian");
    return result.userData.recordsets[0][0].CUSTODIANCOUNT;
  }

  // API TO GET EDISCOVERY DATA
  async getEdiscoveryData(data) {
    const result = await this.client.get("/getEdiscoveryData");
    return result.userData;
  }

  // API TO GET DOCUMENTS
  async getDocuments(data) {
    const result = await this.client.get("/getDocuments");
    return result.userData.recordsets[2][0].DocsToBeReviewed;
  }

  // API TO GET PROCESS DATA
  async getProcessData(data) {
    const result = await this.client.get("/getProcessData");
    return result;
  }

  // API TO GET QUEENBASE PROGRESS
  async getQueenBaseProgress(data) {
    const result = await this.client.get("/getQueenBaseProgress");
    return result;
  }

  // API TO GET DATA UPLOAD
  async getDataUpload(data) {
    var result = await this.client.get("/getDataUpload");
    return result.userData[0].Total;
  }

  // API TO GET TOP INVOICE DETAILS
  async getTopInvoiceDetails(data) {
    const result = await this.client.get("/getTopInvoiceDetails");
    return result;
  }

  // API TO GET EXPENSIVE INVOICES
  async getExpensiveInvoices(data) {
    const result = await this.client.get("/getExpensiveInvoices");
    return result;
  }

  fetchTotalRequestsForDashboard = async (language) => {
    const result = await this.client.post(`/fetchTotalRequestsForDashboard`, { language });
    return result;
  };

  requestStatusForDashboard = async (language) => {
    const result = await this.client.post(`/requestStatusForDashboard`, { language });
    return result;
  };

  // API TO GET DYNAMIC WIDGETS
  async getDynamicWidgets() {
    const result = await this.client.get("/getDynamicWidgets");
    return result;
  }
}
WidgetService.__instance = null;
WidgetService.get = () => {
  if (!WidgetService.__instance) {
    WidgetService.__instance = new WidgetService();
  }
  return WidgetService.__instance;
};

export default WidgetService;
