import React from "react";

import { polyglotLoader } from "../../../Services/Shared/Translate";

// ERROR BOUNDARY COMPONENT
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  // CATCH THE ERROR IF ANY
  componentDidCatch(error, errorInfo) {
    this.setState({
      error,
      errorInfo,
    });
  }

  render() {
    const polyglot = polyglotLoader();
    if (this.state.error) {
      return (
        <div>
          <h2>
            {polyglot.t("This is Error Boundary - You are seeing this because 'Something went wrong'.")}
          </h2>
          <details style={{ whiteSpace: "pre-wrap" }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state.errorInfo.componentStack}
          </details>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

// ERROR MESSAGES WHICH ARE SENT HERE WILL BE DISPLAYED ON THE BROWSER ERROR SCREEN
export const throwERROR = (msg) => {
  if (msg !== "") {
    throw new Error(msg);
  }
  return <div />;
};
