import React, { Component } from "react";
import ReactTable from "react-table";
import "react-table/react-table.css";
import "../Shared/button/button.css";
import withFixedColumns from "react-table-hoc-fixed-columns";
import "react-table-hoc-fixed-columns/lib/styles.css";
import "../../../Components/DFED/Review/Review.css";
import img_pdf from "../../../Assets/Images/DFED/icon-review-archive.svg";
import img_native from "../../../Assets/Images/DFED/icon-review-file.svg";
import img_pdf_native from "../../../Assets/Images/DFED/icon-review-files.svg";
import img_orange from "../../../Assets/Images/DFED/icon-download_orange.svg";
import img_white from "../../../Assets/Images/DFED/icon-download_white.svg";
import imgasc from "../../../Assets/Icons/sorting-ascendant-outline.svg";
import SearchBar from "../.././DFED/Shared/components/FormSearchBar";
import { Input } from "reactstrap";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import "./ReviewTabularData.css";
import $ from "jquery";
import BackButton from "../Shared/button/BackButton";
import ProductionTypes from "../Review/ProductionTypes";
import ProductionDataTable from "../Review/productionDataTable";
import imgloader from "../../../Assets/Images/DFED/icon-review-loader.svg";
import {
  ReviewProdctionFinalResult,
  ReviewProductionFinalResultById,
  getReviewInfo,
  getProductionCountInfo,
  downloadSingleFileService,
  downloadMultipleFileService,
} from "../../../Services/DFED/ReviewService";
import ButtonPrimaryExpandable from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryExpandable";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import { downloadIcon } from "../../../Assets/Icons";
const ReactTableFixedColumns = withFixedColumns(ReactTable);

export class ReviewTabularData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showBackButton: props.showBackButton ? props.showBackButton : null,
      redirectToNextPage: false,
      redirectToPreviousPage: false,
      downloadPopup: false,
      reqIdDownload: [],
      req_id: props.req_id,
      selectedProjectId: props.location ? props.location.state.selectedProjectId : props.selectedProjectId,
      productionResult: [],
      productionResultId: [],
      reviewCounts: [],
      selectedFilterType: "CUSTODIAN",
      search_text: null,
      productionResultId1: [],
      filterOptions: [
        { filter_value: "CUSTODIAN", filter_label: "Custodian", key_id: 1 },
        { filter_value: "FILE_NAME_OR_EMAIL", filter_label: "File", key_id: 2 },
      ],
      singleDownloadPathPDF: "",
      singleDownloadPathNATIVE: "",
      pdf_name: "",
      native_name: "",
      foldername: "",
      docid: "",
      isSelectAll: false,
      listOfSelectedData: "",
      countListOfSelectedData: "",
      isEnableMultiSelect: false,
      productionFilesCounts: "",
      marginTopVal: "",
    };
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.setWrapperRefSingleSelect = this.setWrapperRefSingleSelect.bind(this);
    this.divCssStyle = this.divCssStyle.bind(this);
  }
  static contextType = DataContext;
  setWrapperRef(node) {
    this.wrapperRef = node;
  }
  setWrapperRefSingleSelect(node) {
    this.wrapperRefSingle = node;
  }
  divCssStyle() {
    return {
      top: -343,
    };
  }
  onBackButtonClick() {
    this.setState({
      redirectToPreviousPage: true,
    });
  }
  newProduceFiles = () => {
    this.setState({
      redirectToNextPage: true,
    });
  };
  componentDidMount() {
    this.ReviewProductionResult();
    this.getReviewDetails();
    this.getProductionCount();
    this.ProductionFinalResultById();
    document.addEventListener("mousedown", this.hndleClkOutSideMultiSelect);
    document.addEventListener("mousedown", this.hndleClkOutSideSingleSelect);
  }
  componenetWillUnmount() {
    document.removeEventListener("mousedown", this.hndleClkOutSideMultiSelect);
    document.removeEventListener("mousedown", this.hndleClkOutSideSingleSelect);
  }

  hndleClkOutSideMultiSelect = (event) => {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({
        isEnableMultiSelect: false,
      });
    }
  };
  hndleClkOutSideSingleSelect = (event) => {
    if (
      this.wrapperRefSingle &&
      !this.wrapperRefSingle.contains(event.target) &&
      event.target.className !== "customeImgCls"
    ) {
      this.setState({
        downloadPopup: false,
      });
    }
  };
  //get production files count
  async getProductionCount() {
    // let productionInfo = await getProductionCountInfo(this.state.selectedProjectId)
    // if (productionInfo && productionInfo.data) {
    //   this.setState({
    //     productionFilesCounts: productionInfo.data[0].producedfiles
    //   })
    // }
  }
  //get review page count details
  async getReviewDetails() {
    let reviewInfo = await getReviewInfo(this.state.selectedProjectId);
    if (reviewInfo && reviewInfo.data) {
      this.setState({
        reviewCounts: reviewInfo.data[0],
      });
    }
  }
  //get production results
  async ReviewProductionResult() {
    let productionResult = await ReviewProdctionFinalResult(this.state.req_id, this.state.selectedProjectId);

    if (productionResult && productionResult.data) {
      productionResult.data.forEach(function (a) {
        a.PWC_DOCUMENT_DATE_TIME = a.PWC_DOCUMENT_DATE_TIME.substring(0, 10);
        a.isChecked = false;
      });
      this.setState({
        productionResult: productionResult.data,
        productionResult1: productionResult.data,
        productionFilesCounts: productionResult.data.length,
      });
    }
  }
  //get production results by the report status
  async ProductionFinalResultById() {
    let productionResultId = await ReviewProductionFinalResultById(this.state.selectedProjectId);
    if (productionResultId && productionResultId.data) {
      productionResultId.data.forEach(function (a) {
        a.REVIEW_UPLOADED_ON = a.REVIEW_UPLOADED_ON.substring(0, 10);
      });
      this.setState({
        productionResultId: productionResultId.data,
      });
    }
  }
  // Filter code start from here
  onChange(event) {
    this.setState({
      selectedFilterType: event.target.value,
    });
  }
  // Download Functionality Popup
  onClickDownloadSelected = (rowIndex, row, e) => {
    let bodyReact = document.body.getBoundingClientRect();
    let eleReact = document.getElementById(rowIndex + "_mycustomId").getBoundingClientRect();
    let diffRect = eleReact.top - bodyReact.top;
    diffRect = diffRect - 700;
    $(".downloadAlign").css({ top: diffRect + "px" });
    this.setState({
      reqIdDownload: this.state.productionResult[rowIndex].Req_ID,
      downloadPopup: true,
      singleDownloadPathPDF: row.original.PDF_PATH,
      singleDownloadPathNATIVE: row.original.NATIVE_PATH,
      pdf_name: row.original.PDFS,
      native_name: row.original.NATIVES,
      foldername: row.original.FOLDER_NAME,
      docid: row.original.DOC_ID,
    });
  };
  onSearch(label, value) {
    let tempCustodianRecords = JSON.parse(JSON.stringify(this.state.productionResult));
    if (this.state.selectedFilterType === "FILE_NAME_OR_EMAIL") {
      let CustodianFilterdList =
        tempCustodianRecords &&
        tempCustodianRecords.filter((el) =>
          el["FILE_NAME_OR_EMAIL"].toLowerCase().includes(value.toLowerCase()),
        );
      this.setState({
        productionResult1: CustodianFilterdList,
      });
    } else if (this.state.selectedFilterType === "CUSTODIAN") {
      let CustodianFilterdList =
        tempCustodianRecords &&
        tempCustodianRecords.filter((el) => el["CUSTODIAN"].toLowerCase().includes(value.toLowerCase()));
      this.setState({
        productionResult1: CustodianFilterdList,
      });
    }
  }
  singleDownloadFunction = async (e) => {
    let downloadType = e.currentTarget.getAttribute("data-downloadtype");
    let pathList = [];
    let selectFolder = "";
    this.setState({
      downloadPopup: false,
    });
    if (downloadType === "pdf") {
      pathList.push({
        pdf: this.state.singleDownloadPathPDF,
        pdfname: this.state.pdf_name,
        foldername: this.state.foldername,
        docid: this.state.docid,
      });
      selectFolder = "pdf";
    } else if (downloadType === "native") {
      pathList.push({
        native: this.state.singleDownloadPathNATIVE,
        nativename: this.state.native_name,
        foldername: this.state.foldername,
        docid: this.state.docid,
      });
      selectFolder = "native";
    } else if (downloadType === "both") {
      selectFolder = "";
      pathList.push({
        pdf: this.state.singleDownloadPathPDF,
        native: this.state.singleDownloadPathNATIVE,
        pdfname: this.state.pdf_name,
        nativename: this.state.native_name,
        foldername: this.state.foldername,
        docid: this.state.docid,
      });
      selectFolder = "both";
    }
    let anaKeyData = await downloadSingleFileService(pathList, selectFolder);
    if (anaKeyData && anaKeyData.status === 200) {
      if (anaKeyData.data) {
        const buffer = Buffer.from(anaKeyData.data);
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.download = pathList[0].docid + ".zip";
        link.target = "_blank";
        link.setAttribute("type", "hidden");
        link.click();
        link.remove();
      } else {
        //   alert("Error fetching analysis details.")
      }
    } else {
      // alert("Error while sending request.")
    }
  };
  enableMultiSelection = (e) => {
    this.setState({
      isEnableMultiSelect: true,
      downloadPopup: false,
    });
  };
  multiDownloadFunction = async (e) => {
    let downloadType = e.currentTarget.getAttribute("data-multidownloadtype");
    let selectFolder = "";
    if (downloadType === "pdf_m") {
      selectFolder = "pdf";
    } else if (downloadType === "native_m") {
      selectFolder = "native";
    } else if (downloadType === "both_m") {
      selectFolder = "both";
    }
    this.setState({
      isEnableMultiSelect: false,
    });
    let selectedData = this.state.listOfSelectedData;
    let updateSelectedDataWithPath = [];
    selectedData.forEach((ele) => {
      let tempData = {
        docid: ele.DOC_ID,
        native: ele.NATIVE_PATH,
        pdf: ele.PDF_PATH,
        pdfname: ele.PDF_NAME,
        nativename: ele.NATIVE_NAME,
        foldername: ele.FOLDER_NAME,
      };
      updateSelectedDataWithPath.push(tempData);
    });
    let anaKeyData = await downloadMultipleFileService(updateSelectedDataWithPath, selectFolder);
    if (anaKeyData && anaKeyData.status === 200) {
      if (anaKeyData.data) {
        const buffer = Buffer.from(anaKeyData.data);
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.download = updateSelectedDataWithPath[0].docid + ".zip";
        link.target = "_blank";
        link.setAttribute("type", "hidden");
        link.click();
        link.remove();
      } else {
        //   alert("Error fetching analysis details.")
      }
    } else {
      // alert("Error while sending request.")
    }
  };
  onChangeAll = (e) => {
    this.setState(
      {
        isSelectAll: e.target.checked,
      },
      () => {
        let arr = [];
        this.state.productionResult1.forEach((element) => {
          element.isChecked = this.state.isSelectAll;
          arr.push(element);
        });
        this.setState({
          productionResult1: arr,
        });
        if (this.state.isSelectAll === false) {
          this.setState({
            listOfSelectedData: "",
            countListOfSelectedData: 0,
          });
        } else {
          let arr2 = [];
          this.state.productionResult1.forEach((element) => {
            let tempD = {
              DOC_ID: element.DOC_ID,
              NATIVE_PATH: element.NATIVE_PATH,
              PDF_PATH: element.PDF_PATH,
              PDF_NAME: element.PDFS,
              NATIVE_NAME: element.NATIVES,
              FOLDER_NAME: element.FOLDER_NAME,
            };
            arr2.push(tempD);
          });
          this.setState({
            listOfSelectedData: arr2,
          });
        }
      },
    );
  };

  onChangeSingle = (e, row) => {
    let listOfSelectedDataState = this.state.listOfSelectedData;
    let isExistsInList = listOfSelectedDataState.includes(row.DOC_ID);
    if (e.target.checked === true) {
      if (isExistsInList) {
      } else {
        let singleTempData = {
          DOC_ID: row.DOC_ID,
          NATIVE_PATH: row.NATIVE_PATH,
          PDF_PATH: row.PDF_PATH,
          PDF_NAME: row.PDFS,
          NATIVE_NAME: row.NATIVES,
          FOLDER_NAME: row.FOLDER_NAME,
        };
        let tempData = [...this.state.listOfSelectedData, singleTempData];
        this.setState(
          {
            listOfSelectedData: tempData,
          },
          () => {
            this.setState({
              countListOfSelectedData: this.state.listOfSelectedData.length,
            });
          },
        );
      }
    } else {
      let removeFrmList = this.state.listOfSelectedData.filter((d) => {
        return d.DOC_ID !== row.DOC_ID;
      });
      this.setState(
        {
          listOfSelectedData: removeFrmList,
        },
        () => {
          this.setState({
            countListOfSelectedData: this.state.listOfSelectedData.length,
          });
        },
      );
    }
    let arr = [];
    this.state.productionResult1.forEach((element) => {
      if (element.DOC_ID === row.DOC_ID) {
        element.isChecked = e.target.checked;
      }
      arr.push(element);
    });
    this.setState({
      productionResult1: arr,
    });
  };

  render() {
    var polyglot = polyglotLoader();
    const set1 = (
      <div>
        <div>
          <div className="row m-l-0 m-r-0">
            <div className="col-md-4 col-lg-3 p-l-0 p-r-20">
              <ButtonPrimaryExpandable
                onClick={this.newProduceFiles}
                ariaLabel={polyglot.t("Request document production")}
              >
                {polyglot.t("Request document production")}
              </ButtonPrimaryExpandable>
            </div>
            <div className="col-md-4 col-lg-3 p-l-0 p-r-20">
              <BackButton
                onBackButtonClick={this.onBackButtonClick}
                buttonText={polyglot.t("Back")}
              ></BackButton>
            </div>
          </div>

          <br />
          <div>
            <h5 aria-label={polyglot.t("List of produced files")}>{polyglot.t("List of produced files")}</h5>
          </div>
          <div className="row m-l-0 m-r-0">
            <SearchBar
              nameOfSearch={""}
              customClass={"SearchBar"}
              id="search"
              aria-label="Search for"
              name="search_text"
              val={this.state.search_text}
              onChange={this.onSearch.bind(this)}
            />

            <Input
              type="select"
              aria-label="Select Filter"
              name="select"
              className="review-searchFilter cursor-pointer"
              id="exampleSelect"
              onChange={(event) => this.onChange(event)}
            >
              {this.state.filterOptions.map((filterOptionsData) => {
                return (
                  <option key={filterOptionsData.key_id} value={filterOptionsData.filter_value}>
                    {filterOptionsData.filter_label}
                  </option>
                );
              })}
            </Input>
            <span className="downloadIcons pointerCls customeCls">
              {this.state.countListOfSelectedData > 0 || this.state.isSelectAll ? (
                <img
                  src={img_orange}
                  id="downloadIcon"
                  alt="imgwhite"
                  onClick={(e) => this.enableMultiSelection(e)}
                />
              ) : (
                <img src={img_white} id="downloadIcon" alt="imgwhite" />
              )}
            </span>

            {this.state.isEnableMultiSelect ? (
              <div className="reviewDownloadCss">
                <div className="multiDwnlodFolderSelection" ref={this.setWrapperRef}>
                  <p
                    className="pointerCls"
                    data-multidownloadtype="pdf_m"
                    onClick={(e) => this.multiDownloadFunction(e)}
                  >
                    <span>
                      <img src={img_native} id="" alt="imgnative" />
                    </span>
                    &nbsp; {polyglot.t("Only PDFs")}
                  </p>
                  <p
                    className="pointerCls"
                    data-multidownloadtype="native_m"
                    onClick={(e) => this.multiDownloadFunction(e)}
                  >
                    <span>
                      <img src={img_pdf_native} id="" alt="imgpdf_native" />
                    </span>
                    &nbsp;{polyglot.t("Only Natives")}
                  </p>
                  <p
                    className="pointerCls"
                    data-multidownloadtype="both_m"
                    onClick={(e) => this.multiDownloadFunction(e)}
                  >
                    <span>
                      <img src={img_pdf} id="" alt="imgpdf" />
                    </span>
                    &nbsp; {polyglot.t("PDFs & Natives")}{" "}
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <br />
        <div className="col-md-12 table review-upload-after">
          {this.state.productionResultId.length > 0 ? (
            <ReactTableFixedColumns
              data={this.state.productionResult1}
              getTdProps={() => ({ style: { textAlign: "center" } })}
              columns={[
                {
                  Header: (
                    <div>
                      <span>
                        <input
                          type="checkbox"
                          aria-label={polyglot.t("Custodian")}
                          className="cursor-pointer"
                          defaultChecked={this.state.isSelectAll}
                          onChange={(e) => this.onChangeAll(e)}
                        />
                        <span className="checkboxText">
                          {polyglot.t("Custodian")}
                          <img width={16} height={16} src={imgasc} id="reviewIcon" alt="img_asc" />
                        </span>
                      </span>
                    </div>
                  ),
                  width: 200,
                  fixed: "left",
                  Cell: (row) => (
                    <span className="DF-Data-Details">
                      <input
                        aria-label={row.original.CUSTODIAN}
                        type="checkbox"
                        className="cursor-pointer"
                        defaultChecked={row.original.isChecked}
                        onChange={(e) => this.onChangeSingle(e, row.original)}
                      />
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      {row.original.CUSTODIAN}
                    </span>
                  ),
                },
                {
                  Header: "",
                  accessor: "Req_ID",
                  width: 80,
                  fixed: "right",
                  Cell: (row) => (
                    <div>
                      <span
                        className="individualDownload pointerCls"
                        id={row.viewIndex + "_mycustomId"}
                        onClick={(e) => this.onClickDownloadSelected(row.viewIndex, row, e)}
                      >
                        <ButtonIcon
                          alt="download"
                          iconImg={img_orange}
                          ariaLabel={polyglot.t("Download")}
                          title={polyglot.t("Download")}
                        />
                      </span>
                    </div>
                  ),
                },
                {
                  Header: polyglot.t("DocID"),
                  accessor: "DOC_ID",
                  width: 220,
                },
                {
                  Header: polyglot.t("File name - Email Subject"),
                  accessor: "FILE_NAME_OR_EMAIL",
                  width: 200,
                },
                {
                  Header: polyglot.t("Original Folder"),
                  accessor: "ORGINAL_FOLDER_PATH",
                  width: 200,
                },
                {
                  Header: polyglot.t("Page Count"),
                  accessor: "PWC_PAGE_COUNT",
                  width: 150,
                },
                {
                  Header: polyglot.t("Parent/Child"),
                  accessor: "PARENT_CHILD",
                  width: 150,
                },
                {
                  Header: polyglot.t("Pwc Document Date and Time"),
                  accessor: "PWC_DOCUMENT_DATE_TIME",
                  width: 250,
                },
                {
                  Header: polyglot.t("Pwc Data Set"),
                  accessor: "PWC_DATA_SET",
                  width: 200,
                },
                {
                  Header: polyglot.t("Pwc Source Time Zone"),
                  accessor: "PWC_SOURCE_TIME_ZONE",
                  width: 200,
                },
              ]}
              defaultPageSize={5}
              className="-striped -highlight"
            />
          ) : (
            <div className="loaderfixed">
              <img src={imgloader} id="review" alt="img_loader" />
            </div>
          )}
        </div>
        <div>
          {this.state.downloadPopup ? (
            <div ref={this.setWrapperRefSingleSelect} className="downloadAlign topCssStyle boolVisible">
              <p
                className="downloadText singleDownload pointerCls"
                data-downloadtype="pdf"
                onClick={(e) => this.singleDownloadFunction(e)}
              >
                <span>
                  <img src={img_native} id="fileIcon" alt="imgnative" data-downloadtype="pdf" />
                </span>
                &nbsp; {polyglot.t("Only PDFs")}
              </p>
              <p
                className="downloadText singleDownload pointerCls"
                data-downloadtype="native"
                onClick={(e) => this.singleDownloadFunction(e)}
              >
                <span>
                  <img src={img_pdf_native} id="fileIcon" alt="imgpdfnative" data-downloadtype="native" />
                </span>
                &nbsp; {polyglot.t("Only Natives")}
              </p>
              <p
                className="downloadText singleDownload pointerCls"
                data-downloadtype="both"
                onClick={(e) => this.singleDownloadFunction(e)}
              >
                <span>
                  <img src={img_pdf} id="fileIcon" alt="imgpdf" data-downloadtype="both" />
                </span>
                &nbsp; {polyglot.t("PDFs & Natives")}{" "}
              </p>
            </div>
          ) : (
            ""
          )}
          <br></br>
        </div>
      </div>
    );
    const set2 = (
      <ProductionTypes
        selectedProjectId={this.state.selectedProjectId}
        productionResult={this.state.productionResultId}
        showBackButton={this.state.showBackButton}
        newProps={this.props.newProps}
      />
    );
    const set3 = (
      <ProductionDataTable
        selectedProjectId={this.state.selectedProjectId}
        productionResult={this.state.productionResultId}
        showBackButton={this.state.showBackButton}
        newProps={this.props.newProps}
      />
    );
    var newset;
    if (this.state.redirectToNextPage) {
      newset = set2;
    } else if (this.state.redirectToPreviousPage) {
      newset = set3;
    } else {
      newset = set1;
    }

    return newset;
  }
}
export default ReviewTabularData;
