import Polygot from "node-polyglot";

import StorageKeys from "./SessionKeys";
import StorageService from "./StorageService";
import english from "../../Languages/grc.en.json";
import german from "../../Languages/grc.gr.json";

export let userLanguage = () => StorageService.get(StorageKeys.USERLANGUAGE);
if (!userLanguage) {
  StorageService.set(StorageKeys.USERLANGUAGE, "en");
  userLanguage = "en";
}
const requiredObj = userLanguage !== "en" ? "de" : {};
export const getCurrentLanguage = () => StorageService.get(StorageKeys.USERLANGUAGE) || "en";
export const setCurrentLanguage = (lang) => StorageService.set(StorageKeys.USERLANGUAGE, lang);
export const translate = (key) => requiredObj[key] || key;
export const polyglotLoader = (lang) => {
  const currentLanguage = lang || getCurrentLanguage();
  return new Polygot({ phrases: currentLanguage === "en" ? english : german });
};
