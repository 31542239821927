import React, { useState, useEffect } from "react";

import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/GRC/loading-spin.svg";
import RecipientService from "../../../Services/GRC/RecipientService";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import EmptyPanel from "../Shared/EmptyPanel";
import ErrorPanel from "../Shared/ErrorPanel";
import "./Pages/index.css";
import { GetBreadcrumHTMLByString } from "../handlers";

const RecipientResponseText = ({ id }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [categoryData, setcategoryData] = useState({});

  useEffect(() => {
    const getSelectedQuestionaries = async () => {
      try {
        const responseData = await sendRequest(RecipientService.get().getTextDataURL(id));
        setcategoryData(responseData);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      } catch (err) {
        console.log(err);
      }
    };
    getSelectedQuestionaries();
  }, [sendRequest, id]);
  return (
    <div className="row p-0">
      {error && <ErrorPanel error={error} onClear={clearError} />}
      {isLoading && (
        <div>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      )}
      {!isLoading && categoryData.length === 0 && <EmptyPanel massage={"No data found."} />}
      {!isLoading && categoryData && (
        <div className="col-md-12">
          <GetBreadcrumHTMLByString breadcrumString={categoryData.breadcrum} />
          <hr></hr>
          <div className="custome-min-height">
            <p className="p-3">
              <span className="main_title_qb headingtitle">Description :</span>
              {categoryData.description}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
export default RecipientResponseText;
