import React, { Component } from "react";
import $ from "jquery";
import {
  Select,
  SelectOption,
  Input,
  Button,
  Accordions,
  AccordionItem,
  AccordionItemHeader,
  AccordionItemBody,
} from "appkit-react";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "antd/dist/antd.css";
import { editReviewRunMapService, getreviewdetailsGridDetails } from "../../../../Services/DFED/AdminService";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import { DataContext } from "../../../../Services/Shared/Store.js";
import XLSX from "xlsx";
import displayToastMessage from "../../../../Components/QueenBase/Root/displayToastMessage";
import fastForwardImg from "../../../../Assets/Images/DFED/icon-fast-forward-red.svg";
import CustomModal from "../../../../Assets/Components/Modal";
import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { URL_REGEX } from "../../../../Constants/regex";
import { getReviewDetailsById } from "../../../../Services/DFED/ReviewService";

$.DataTable = require("datatables.net-dt");
$.fn.DataTable.ext.errMode = "none";
require("datatables.net-buttons-dt");
const jzip = require("jszip");
require("datatables.net-buttons/js/buttons.html5.min");
window.JSZip = jzip;

const STATUS_NOT_STARTED = "1";
const STATUS_IN_PROGRESS = "2";
const STATUS_COMPLETED = "3";
// THIS CLASS IS RESPONSIBLE FOR HANDLING ADMIN REVIEW TAB FUNCTIONALITY=======================
class ReviewGrid extends Component {
  polyglot = polyglotLoader();
  constructor(props) {
    super(props);
    this.state = {
      projectid: this.props.projectid,
      projectname: this.props.projectname,
      ANALYSIS_ID: "",
      RUNTHROUGH_ID: "",
      REVIEW_NAME: "",
      ANALYSIS_NAME: "",
      CUSTODIAN_ID: "",
      reviewGridData: [],
      visible: false,
      tabValue: "1",
      selectedEditId: "",
      editId: "",
      editedDataTransferStatus: null,
      editedReviewStatus: null,
      editedBatchStatus: null,
      editedCreateCodingStatus: null,
      editedAnalyzedDocument: "",
      editedDocumentProduced: "",
      fileDetails: "",
      technicalfileDetails: "",
      isCorrectFile: false,
      fastForwardId: null,
      visibleViewModal: false,
      reviewName: "",
      reviewDetails: [],
      reviewPlatformURL: "",
      showError_reviewPlatformURL: "",
    };
  }
  static contextType = DataContext;
  // FILE TYPE VALIDATION
  validateFileType = (event) => {
    if (!event.target.files[0]) {
      $("#anaKeyWedExcelTypeErrreview").text("");
      $("#anaKeyWedExcelTypeSucessfullreview").text("");
      this.setState({ isCorrectFile: false });
      return false;
    }
    let excelFile = event.target.files[0];
    let allowType2 = "vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    let errMsg = "";
    let tempType = excelFile.type.split("/");

    $("#anaKeyWedExcelTypeErrreview").text(errMsg);
    if (tempType[1] !== allowType2) {
      errMsg = "  " + excelFile.name + this.polyglot.t(" is not valid xlsx file type.");
      $("#anaKeyWedExcelTypeErrreview").text(errMsg);
      this.setState({ isCorrectFile: false });
      return false;
    }
    var fileReader = new FileReader();
    const scope = this;
    fileReader.onload = function (e) {
      processExcel(e.target.result, scope);
    };
    fileReader.readAsBinaryString(excelFile);
    function processExcel(data) {
      const polyglot = polyglotLoader();
      var workbook = XLSX.read(data, { type: "binary" });
      let sheetNumber = null;
      for (let i = 0; i < workbook.SheetNames.length; i++) {
        if (workbook.SheetNames[i] === "ReviewResults") {
          sheetNumber = i;
        }
      }
      if (sheetNumber === null) {
        errMsg = "  " + excelFile.name + polyglot.t(" has not valid sheet name.");
        $("#anaKeyWedExcelTypeErrreview").text(errMsg);
        scope.setState({
          isCorrectFile: false,
        });
        return false;
      }
      var selectedSheet = workbook.SheetNames[sheetNumber];
      let excelRows = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[selectedSheet]);
      let colList = Object.keys(excelRows[0]);
      if (colList[0] === "Custodian" && colList[6] === "Record Count") {
        scope.setState(
          {
            fileDetails: excelFile,
          },
          () => {
            $("#anaKeyWedExcelTypeSucessfullreview").text(polyglot.t("File Validate Sucessfully."));
            setTimeout(function () {
              $("#anaKeyWedExcelTypeSucessfullreview").text("");
            }, 2500);
          },
        );
        scope.setState({
          isCorrectFile: true,
        });
        return true;
      } else {
        errMsg = "  " + excelFile.name + polyglot.t(" is not in desired column format.");
        $("#anaKeyWedExcelTypeErrreview").text(errMsg);
        scope.setState({
          isCorrectFile: false,
        });
        return false;
      }
    } //OVER: PROCESS EXCLE
  };
  technicalGridUploadHnadler = async (e) => {
    if (e) {
      this.setState(
        {
          technicalfileDetails: e.target.files[0],
        },
        () => {
          const polyglot = polyglotLoader();
          $("#anaTechnicalReviewExcelTypeSuccessfullview").text(polyglot.t("File Validate Sucessfully."));
          setTimeout(function () {
            $("#anaTechnicalReviewExcelTypeSuccessfullview").text("");
          }, 2500);
        },
      );
    }
  };

  validateTechnicalReport = (e) => {
    const uploadedFile = e.target.files[0];
    if (!uploadedFile) {
      $("#anaTechnicalReviewExcelTypeErrreview").text("");
      $("#anaTechnicalReviewExcelTypeSuccessfullview").text("");
      this.setState({ technicalfileDetails: "" });
      return false;
    }
    let allowType2 = "vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    let errMsg = "";
    let tempType = uploadedFile.type.split("/");
    $("#anaTechnicalReviewExcelTypeErrreview").text(errMsg);
    if (tempType[1] !== allowType2) {
      errMsg = "  " + uploadedFile.name + this.polyglot.t(" is not valid xlsx file type.");
      $("#anaTechnicalReviewExcelTypeErrreview").text(errMsg);
      this.setState({ technicalfileDetails: "" });
      return false;
    } else {
      this.technicalGridUploadHnadler(e);
    }
  };
  // LIFE CYCLE METHOD FOR GETTING REVIEW DATA====================
  componentDidMount() {
    this.getReviewDetails();
  }
  //get review grid details
  async getReviewDetails() {
    let reviewInfo = await getreviewdetailsGridDetails(this.state.projectid);
    if (reviewInfo && reviewInfo.data) {
      this.setState(
        {
          reviewGridData: reviewInfo.data,
        },
        () => {
          this.dataStructureForReviewGrid(this);
        },
      );
    }
  }
  // DATASET ON CHANGE EVENT======================

  prepareDataTransferOnChng = (value) => {
    const reviewStatus = { editedDataTransferStatus: value };

    if (value !== STATUS_COMPLETED) {
      reviewStatus.editedReviewStatus = STATUS_NOT_STARTED;
      reviewStatus.editedBatchStatus = STATUS_NOT_STARTED;
    }

    this.setState(reviewStatus);
  };
  // REVIEW STATUS ON CHANGE EVENT====================
  prepareReviewStatusOnChng = (value) => {
    const reviewStatus = { editedReviewStatus: value };

    if (value !== STATUS_COMPLETED && this.state.editedBatchStatus === STATUS_COMPLETED) {
      reviewStatus.editedBatchStatus = STATUS_IN_PROGRESS;
    }

    this.setState(reviewStatus);
  };
  createBatchStatusOnChng = (value) => {
    this.setState({ editedBatchStatus: value });
  };
  // REPORT STATUS CHANGE EVENt======================
  reportCreateCodingStatusOnChng = (value) => {
    const reviewStatus = { editedCreateCodingStatus: value };

    if (value !== STATUS_COMPLETED) {
      reviewStatus.fileDetails = "";
      reviewStatus.technicalfileDetails = "";
      reviewStatus.isCorrectFile = false;
    }

    this.setState(reviewStatus);
  };
  // DOCUMENT ON CHANGE EVENT======================

  analyzedDocumentOnChng = (e) => {
    const value = e;
    if (e) {
      this.setState({
        editedAnalyzedDocument: value,
      });
    }
  };
  documentProducedOnChng = (e) => {
    const value = e;
    if (e) {
      this.setState({
        editedDocumentProduced: value,
      });
    }
  };
  dommanipulation = () => {};
  // SHOW MODAL FUNCTIONALITY======================
  showModal = (
    status_id,
    status_id1,
    status_id2,
    status_id3,
    id,
    analysisId,
    runthroughId,
    review_name,
    analysis_name,
    custodianId,
    fastForwardId,
    editedAnalyzedDocument,
    editedDocumentProduced,
    reviewPlatformURL,
  ) => {
    this.setState(
      {
        selectedEditId: id,
        ANALYSIS_ID: analysisId,
        CUSTODIAN_ID: custodianId,
        RUNTHROUGH_ID: runthroughId,
        REVIEW_NAME: review_name,
        ANALYSIS_NAME: analysis_name,
        visible: true,
        editedDataTransferStatus: String(status_id),
        editedReviewStatus: String(status_id1),
        editedBatchStatus: String(status_id2),
        editedCreateCodingStatus: String(status_id3),
        fastForwardId: fastForwardId ? fastForwardId : null,
        editedAnalyzedDocument: editedAnalyzedDocument,
        editedDocumentProduced: editedDocumentProduced,
        reviewPlatformURL,
      },
      () => {},
    );
  };
  // HANDLE CANCEL FUNCTIONALITY===========================
  handleCancel = (e) => {
    this.setState({
      visible: false,
      fileDetails: "",
      technicalfileDetails: "",
      editedAnalyzedDocument: "",
      editedDocumentProduced: "",
    });
    if (this.reviewGridFile && this.technicalGridFile) {
      this.reviewGridFile.value = "";
      this.technicalGridFile.value = "";
    }
    $("#anaKeyWedExcelTypeErrreview").text("");
    $("#anaKeyWedExcelTypeSucessfullreview").text("");
    $("#anaTechnicalReviewExcelTypeErrreview").text("");
    $("#anaTechnicalReviewExcelTypeSuccessfullview").text("");
  };

  // HANDLE CONFIRM FUNCTIONALITY==========================
  handleConfirm = async (e) => {
    let editId = "";
    let isIntrruptEdit = false;
    if (this.state.selectedEditId && this.state.selectedEditId !== "") {
      editId = this.state.selectedEditId;
    } else {
      isIntrruptEdit = true;
    }
    if (isIntrruptEdit === false) {
      let data = {
        PROJECT_ID: this.state.projectid,
        projectname: this.state.projectname,
        ANALYSIS_ID: this.state.ANALYSIS_ID,
        CUSTODIAN_ID: this.state.CUSTODIAN_ID,
        REVIEW_NAME: this.state.REVIEW_NAME,
        RUNTHROUGH_ID: this.state.RUNTHROUGH_ID,
        ANALYSIS_NAME: this.state.ANALYSIS_NAME,
        editId: editId,
        ANALYSED_DOCUMENTS: this.state.editedAnalyzedDocument,
        CREATE_BATCHES_STATUS: this.state.editedBatchStatus,
        CREATE_CODING_STATUS: this.state.editedCreateCodingStatus,
        DOCUMENTS_PRODUCED: this.state.editedDocumentProduced,
        PREPARE_VIEWS_STATUS: this.state.editedReviewStatus,
        TRANSFER_DATA_STATUS: this.state.editedDataTransferStatus,
        review_platform_url: this.state.reviewPlatformURL,
      };
      let formData = {};
      formData = new FormData();
      if (
        this.state.fileDetails !== "" &&
        this.state.technicalfileDetails !== "" &&
        this.state.editedAnalyzedDocument !== "" &&
        this.state.editedDocumentProduced !== ""
      ) {
        formData.append("review_File", this.state.fileDetails);
        formData.append("technical_File", this.state.technicalfileDetails);
        formData.append("doc_Data", JSON.stringify(data));
        formData.append("fastForwardId", this.state.fastForwardId);
      } else {
        formData.append("doc_Data", JSON.stringify(data));
        formData.append("fastForwardId", this.state.fastForwardId);
      }
      let isEditReview = await editReviewRunMapService(formData);
      if (isEditReview && isEditReview.hasOwnProperty("status")) {
        if (parseInt(isEditReview.status) === 200) {
          displayToastMessage(this.polyglot.t("Review status has been updated"), "success");
          this.getReviewDetails();
          if (this.reviewGridFile && this.technicalGridFile) {
            this.reviewGridFile.value = "";
            this.technicalGridFile.value = "";
          }
        }
      } else {
        //alert("Error while Edit request.")
      }
      this.setState({
        visible: false,
        fileDetails: "",
        technicalfileDetails: "",
        editedAnalyzedDocument: "",
        editedDocumentProduced: "",
      });
    } else {
      //alert("Error while validating your input.")
    }
  };
  onTabChange(e) {
    this.setState({
      tabValue: e.target.getAttribute("data-value"),
    });
  }
  // CREATING DATA FOR SHOWING REVIEW TABULAR FORMAT====================
  dataStructureForReviewGrid = () => {
    var tempData = this.state.reviewGridData;
    // return false;
    var dataForTbl = [];
    tempData.forEach((ele1) => {
      let editedDataTransferStatus;
      if (
        ele1.TRANSFERDATASTATUS === "0" ||
        ele1.TRANSFERDATASTATUS === STATUS_NOT_STARTED ||
        ele1.TRANSFERDATASTATUS === null ||
        ele1.TRANSFERDATASTATUS === ""
      ) {
        editedDataTransferStatus = this.polyglot.t("Not Started");
      } else if (ele1.TRANSFERDATASTATUS === STATUS_IN_PROGRESS) {
        editedDataTransferStatus = this.polyglot.t("In Progress");
      } else {
        editedDataTransferStatus = this.polyglot.t("Completed");
      }
      let editedReviewStatus;
      if (
        ele1.PREPAREVIEWSSTATUS === "0" ||
        ele1.PREPAREVIEWSSTATUS === STATUS_NOT_STARTED ||
        ele1.PREPAREVIEWSSTATUS === null ||
        ele1.PREPAREVIEWSSTATUS === ""
      ) {
        editedReviewStatus = this.polyglot.t("Not Started");
      } else if (ele1.PREPAREVIEWSSTATUS === STATUS_IN_PROGRESS) {
        editedReviewStatus = this.polyglot.t("In Progress");
      } else {
        editedReviewStatus = this.polyglot.t("Completed");
      }
      let editedBatchStatus;
      if (
        ele1.CREATEBATCHESSTATUS === "0" ||
        ele1.CREATEBATCHESSTATUS === STATUS_NOT_STARTED ||
        ele1.CREATEBATCHESSTATUS === null ||
        ele1.CREATEBATCHESSTATUS === ""
      ) {
        editedBatchStatus = this.polyglot.t("Not Started");
      } else if (ele1.CREATEBATCHESSTATUS === STATUS_IN_PROGRESS) {
        editedBatchStatus = this.polyglot.t("In Progress");
      } else {
        editedBatchStatus = this.polyglot.t("Completed");
      }
      let editedCreateCodingStatus;
      if (
        ele1.CREATECODINGSTATUS === "0" ||
        ele1.CREATECODINGSTATUS === STATUS_NOT_STARTED ||
        ele1.CREATECODINGSTATUS === null ||
        ele1.CREATECODINGSTATUS === ""
      ) {
        editedCreateCodingStatus = this.polyglot.t("Not Started");
      } else if (ele1.CREATECODINGSTATUS === STATUS_IN_PROGRESS) {
        editedCreateCodingStatus = this.polyglot.t("In Progress");
      } else {
        editedCreateCodingStatus = this.polyglot.t("Completed");
      }
      let editedAnalyzedDocument;
      if (ele1.ANALYSEDDOCUMENTS === "0" || ele1.ANALYSEDDOCUMENTS === null) {
        editedAnalyzedDocument = "";
      } else {
        editedAnalyzedDocument = ele1.ANALYSEDDOCUMENTS;
      }
      let editedDocumentProduced;
      if (ele1.DOCUMENTSPRODUCED === "0" || ele1.DOCUMENTSPRODUCED === null) {
        editedDocumentProduced = "";
      } else {
        editedDocumentProduced = ele1.DOCUMENTSPRODUCED;
      }

      const reviewPlatformURL = ele1.REVIEW_PLATFORM_URL ? ele1.REVIEW_PLATFORM_URL : "";

      dataForTbl.push([
        ele1.REVIEW_NAME, //0
        editedDataTransferStatus, //1
        editedReviewStatus, //2
        editedBatchStatus, //3
        editedCreateCodingStatus, //4
        ele1.review_Id, //5
        ele1.ANALYSIS_ID, //6
        ele1.RUNTHROUGH_ID, //7
        ele1.REVIEW_UPLOADED_ON, //8
        ele1.ANALYSIS_NAME, //9
        ele1.CUSTODIAN_ID, //10
        ele1.PIPELINE_ID, //11
        editedAnalyzedDocument, //12
        editedDocumentProduced, //13
        reviewPlatformURL, //14
      ]);
    }); //OVER : MAIN FOREACH
    // return false;
    this.drawReviewGrid(this, dataForTbl);
  };
  // ---------------Header Names-----------------
  drawReviewGrid = (temp1, dataForTbl) => {
    temp1.$el = $(temp1.el);
    // let pageLength = 7;
    var disText = "";
    var tabledt = temp1.$el.DataTable({
      lengthMenu: [5, 10, 15, 20],
      sDom: "lfrtp",
      oLanguage: {
        emptyTable: `${this.polyglot.t("No data available for this project")}`,
        sLengthMenu: `${this.polyglot.t("Show")} <select class="tabFocus">
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="-1">${this.polyglot.t("All")}</option>
        </select> ${this.polyglot.t("entries")}
        `,
        sSearch: `${this.polyglot.t("Search")}`,
      },
      columns: [
        {
          title: this.polyglot.t("Review Name"),
          mRender: function (data, type, row) {
            if (row[11]) {
              return (
                data +
                '&nbsp&nbsp<img role="presentation" class="adminProcessingFromFastForward" src="' +
                fastForwardImg +
                '" />'
              );
            } else {
              return data;
            }
          },
        },
        { title: this.polyglot.t("Data Transfer Status") },
        { title: this.polyglot.t("Prepare Review Status") },
        { title: this.polyglot.t("Create Batch Status") },
        { bVisible: false, searchable: false },
        { bVisible: false, searchable: false },
        { bVisible: false, searchable: false },
        { bVisible: false, searchable: false },
        { bVisible: false, searchable: false },
        { bVisible: false, searchable: false },
        { bVisible: false, searchable: false },
        { bVisible: false, searchable: false },
        { bVisible: false, searchable: false },
        { bVisible: false, searchable: false },
        {
          title: this.polyglot.t("Action"),
          class: "actionSortingRemove",
          data: null,
          mRender: (data, type, row) => {
            return (
              '<button aria-label="' +
              this.polyglot.t("edit") +
              '"  class=" appkiticon icon-edit-outline classprocessing_edit analysisGridEditIcon review_editer_edit" id="reviewgrid_*_*' +
              row[5] +
              "*_*" +
              row[1] +
              "*_*" +
              row[2] +
              "*_*" +
              row[3] +
              "*_*" +
              row[4] +
              "*_*" +
              row[6] +
              "*_*" +
              row[7] +
              "*_*" +
              row[0] +
              "*_*" +
              row[9] +
              "*_*" +
              row[10] +
              "*_*" +
              row[11] +
              "*_*" +
              row[12] +
              "*_*" +
              row[13] +
              "*_*" +
              row[14] +
              '" data-id="' +
              row[5] +
              '"> </button> &nbsp; &nbsp;' +
              '<button aria-label="' +
              this.polyglot.t("view") +
              '" class="appkiticon icon-view-fill analysisGridViewIcon editer_view"' +
              'id="view_model_' +
              row[5] +
              "_" +
              row[0] +
              '"> </button>'
            );
          },
        },
      ],
      data: dataForTbl,
      order: [[8, "desc"]],
      lenghtType: "simple",
      searching: true,
      destroy: true,
      language: {
        info: "_PAGE_ of _PAGES_",
        paginate: {
          firt: "<<",
          previous: '<i_ngcontent-c10="" class="appkiticon icon-left-chevron-fill"> </i>',
          next: '<i_ngcontent-c10="" class="appkiticon icon-right-chevron-fill"> </i>',
          last: ">>",
        },
      },
      stateSave: true,
      drawCallback: function () {
        if (dataForTbl.length <= 0) {
          $(".dataTables_paginate").hide();
        } else {
          $(".dataTables_paginate").show();
        }
      }, // OVER : drawback function
    }); // OVER : Data Table
    tabledt.search("").draw();

    var tempReactThis = this;
    $(document).on("click", "[id*='reviewgrid_']", function () {
      const polyglot = polyglotLoader();
      let fastForwardId = null;
      let idData = this.id.split("*_*");
      let selectedId = idData[1];
      let editedDataTransferStatus = idData[2];
      let editedReviewStatus = idData[3];
      let editedBatchStatus = idData[4];
      let editedCreateCodingStatus = idData[5];
      let analysisId = idData[6];
      let runthroughId = idData[7];
      let review_name = idData[8];
      let analysis_name = idData[9];
      let custodianId = idData[10];
      if (idData[11]) {
        fastForwardId = idData[11];
      } else {
        fastForwardId = null;
      }
      let editedAnalyzedDocument = idData[12];
      let editedDocumentProduced = idData[13];
      const reviewPlatformURL = idData[14];

      let forId = [polyglot.t("Not Started"), polyglot.t("In Progress"), polyglot.t("Completed")];
      tempReactThis.showModal(
        forId.indexOf(editedDataTransferStatus) + 1,
        forId.indexOf(editedReviewStatus) + 1,
        forId.indexOf(editedBatchStatus) + 1,
        forId.indexOf(editedCreateCodingStatus) + 1,
        selectedId,
        analysisId,
        runthroughId,
        review_name,
        analysis_name,
        custodianId,
        fastForwardId,
        editedAnalyzedDocument,
        editedDocumentProduced,
        reviewPlatformURL,
      );
    });
    var tempReacThis = this;
    $(document).on("click", "[id*='view_model_']", function () {
      let idData = this.id.split("_");
      tempReacThis.showViewModal(tempReacThis, idData[2], idData[3]);
    });
    disText = $("#analysisGridTable").text();
    var pagNum = "";
    pagNum = disText.replace(pagNum, "<span>" + pagNum + "</span>");
    disText = disText.replace(pagNum, "<span>" + pagNum + "</span>");
  }; // OVER : Draw Grid

  isSaveDisabled = () => {
    // if there is error on review platform url
    if (this.state.showError_reviewPlatformURL.length > 0) {
      return true;
    }

    if (Number(this.state.editedCreateCodingStatus)) {
      return false;
    }
    return true;
  };

  showViewModal = (temthis, id, reviewName) => {
    this.setState(
      {
        selectedEditId: id,
        visibleViewModal: true,
        reviewName: reviewName,
      },
      () => {
        this.getSelectedReviewDetails(this);
      },
    );
  };
  // GETTING REVIEW DETAILS BASED ON REVIEW ID=================
  getSelectedReviewDetails = async () => {
    const polyglot = polyglotLoader();
    try {
      let reviewId = "";
      let isProceed = false;
      if (this.state.selectedEditId !== "") {
        reviewId = this.state.selectedEditId;
        isProceed = true;
      }
      if (isProceed) {
        const body = {
          reviewId: reviewId,
        };
        let apiResponse = await getReviewDetailsById(body);
        if (apiResponse.data.CODE) {
          const reviewDetails = apiResponse.data.details;
          this.setState({ reviewDetails });
        } else {
          displayToastMessage(polyglot.t(apiResponse.data.MSG), "error");
        }
      } else {
        displayToastMessage(polyglot.t("Something went wrong while fetching review details"), "error");
      }
    } catch (err) {
      displayToastMessage(polyglot.t(err.message), "error");
    }
  };
  // CLOSE AND CANCEL FUNCTIONALITY================

  handleViewCancel = (e) => {
    this.setState({
      visibleViewModal: false,
      reviewDetails: [],
    });
  };

  // save review platform on change
  reviewPlatformChange = (e) => {
    const value = e;
    // check the URL format
    if (value.match(URL_REGEX)) {
      // if text matches the regex url
      this.setState({
        reviewPlatformURL: value,
        showError_reviewPlatformURL: "",
      });
    } else {
      this.setState({
        reviewPlatformURL: value,
        showError_reviewPlatformURL: "Pleae enter a valid URL",
      });
    }
  };

  /**
   *
   * @param {string} id
   */
  getCustodianById(id) {
    return this.state.reviewDetails.custodians.find(({ CUSTODIAN_ID: custodianId }) => id === custodianId);
  }
  /**
   *
   * @param {string} id
   */
  getDatasetById(id) {
    return this.state.reviewDetails.datasets.find(({ DATASET_ID: datasetId }) => id === datasetId);
  }

  // EXPAND COLLAPSE HANDLER FUNCTIONALTY================
  render() {
    const isSaveDisabled = this.isSaveDisabled();
    return (
      <div className="container analysisGridContainer p-0">
        <div className="row analysisGridRow">
          <div className="col-md-12 DFED-Review-admin">
            <table
              id="analysisGridTable"
              className="display table-header-focus"
              width="100%"
              ref={(el) => (this.el = el)}
            />
          </div>
        </div>
        <div className="viewModelDiv">
          <CustomModal
            show={this.state.visibleViewModal}
            onCancel={this.handleViewCancel}
            title={`${this.polyglot.t("Review details of : ")} ${this.state.reviewName}`}
            footer={[]}
            showCloseButton={true}
            size="sm"
          >
            <>
              <div className="modal-txt-open">
                {this.state.reviewDetails && (
                  <Accordions key={1} className="mb-2">
                    <AccordionItem itemId={1} key={1}>
                      <AccordionItemHeader title="Analysis" />
                      <AccordionItemBody>
                        {this.state.reviewDetails.info ? (
                          <div>{this.state.reviewDetails.info.ANALYSIS_NAME}</div>
                        ) : (
                          <div>No Analysis</div>
                        )}
                      </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem itemId={2} key={2}>
                      <AccordionItemHeader title="Custodian" />
                      <AccordionItemBody>
                        {this.state.reviewDetails.custodians ? (
                          this.state.reviewDetails.custodians.map((childData) => {
                            return (
                              <div>
                                {childData.CRT_FIRST_NAME} {childData.CRT_LAST_NAME}
                              </div>
                            );
                          })
                        ) : (
                          <div>No Custodian</div>
                        )}
                      </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem itemId={3} key={3}>
                      <AccordionItemHeader title="Datasets" />
                      <AccordionItemBody>
                        {this.state.reviewDetails.custodians ? (
                          this.state.reviewDetails.custodians.map(
                            ({
                              CRT_FIRST_NAME: custodianName,
                              CRT_LAST_NAME: custodianLastName,
                              datasets,
                            }) => (
                              <div>
                                {custodianName} {custodianLastName}: {datasets.join(", ")}
                              </div>
                            ),
                          )
                        ) : (
                          <div>No Datasets</div>
                        )}
                      </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem itemId={4} key={4}>
                      <AccordionItemHeader title="Keywords" />
                      <AccordionItemBody>
                        {this.state.reviewDetails.keywords ? (
                          this.state.reviewDetails.keywords.map((childData) => {
                            return <div>{childData.KEYWORD}</div>;
                          })
                        ) : (
                          <div>No Keywords</div>
                        )}
                      </AccordionItemBody>
                    </AccordionItem>
                  </Accordions>
                )}
              </div>
            </>
          </CustomModal>
        </div>
        <div>
          <CustomModal
            show={this.state.visible}
            onCancel={this.handleCancel}
            title={this.polyglot.t("Update review status")}
            Footer={[
              <>
                {isSaveDisabled ? (
                  <ButtonPrimary disabled ariaLabel={this.polyglot.t("Save")}>
                    {this.polyglot.t("Save")}
                  </ButtonPrimary>
                ) : (
                  <ButtonPrimary onClick={this.handleConfirm} ariaLabel={this.polyglot.t("Save")}>
                    {this.polyglot.t("Save")}
                  </ButtonPrimary>
                )}
              </>,
            ]}
            size="sm"
          >
            <div className="row mrgnBtm10">
              <div className="col-md-4 labelDiv">
                <span className="editTest-align">{this.polyglot.t("Data Transfer Status :")}</span>
              </div>
              <div className="col-md-8 labelDiv padding-top-point5">
                <Select
                  value={this.state.editedDataTransferStatus}
                  showSearchOnToggle={true}
                  onSelect={this.prepareDataTransferOnChng.bind(this)}
                  className="admin-editinputboxes_align "
                >
                  <SelectOption key={"prepKeyStus_" + 1} value={"1"}>
                    {this.polyglot.t("Not Started")}
                  </SelectOption>
                  <SelectOption key={"prepKeyStus_" + 2} value={"2"}>
                    {this.polyglot.t("In Progress")}
                  </SelectOption>
                  <SelectOption key={"prepKeyStus_" + 3} value={"3"}>
                    {this.polyglot.t("Completed")}
                  </SelectOption>
                </Select>
              </div>
            </div>
            <div className="row mrgnBtm10">
              <div className="col-md-4 labelDiv">
                <span className="editTest-align">{this.polyglot.t("Prepare Review Status :")}</span>
              </div>
              <div className="col-md-8 labelDiv padding-top-point5">
                <Select
                  value={this.state.editedReviewStatus}
                  showSearchOnToggle={true}
                  onSelect={this.prepareReviewStatusOnChng.bind(this)}
                  className="admin-editinputboxes_align "
                  disabled={this.state.editedDataTransferStatus !== STATUS_COMPLETED}
                >
                  <SelectOption key={"prepKeyStus_" + 1} value={"1"}>
                    {this.polyglot.t("Not Started")}
                  </SelectOption>
                  <SelectOption key={"prepKeyStus_" + 2} value={"2"}>
                    {this.polyglot.t("In Progress")}
                  </SelectOption>
                  <SelectOption key={"prepKeyStus_" + 3} value={"3"}>
                    {this.polyglot.t("Completed")}
                  </SelectOption>
                </Select>
              </div>
            </div>
            <div className="row mrgnBtm10">
              <div className="col-md-4 labelDiv">
                <span className="editTest-align">{this.polyglot.t("Create Batch Status :")}</span>
              </div>
              <div className="col-md-8 labelDiv padding-top-point5">
                <Select
                  value={this.state.editedBatchStatus}
                  showSearchOnToggle={true}
                  onSelect={this.createBatchStatusOnChng.bind(this)}
                  className="admin-editinputboxes_align "
                  disabled={this.state.editedDataTransferStatus !== STATUS_COMPLETED}
                >
                  <SelectOption key={"prepKeyStus_" + 1} value={"1"}>
                    {this.polyglot.t("Not Started")}
                  </SelectOption>
                  <SelectOption key={"prepKeyStus_" + 2} value={"2"}>
                    {this.polyglot.t("In Progress")}
                  </SelectOption>
                  {this.state.editedReviewStatus === "3" && (
                    <SelectOption key={"prepKeyStus_" + 3} value={"3"}>
                      {this.polyglot.t("Completed")}
                    </SelectOption>
                  )}
                </Select>
              </div>
            </div>
            {this.state.editedCreateCodingStatus === STATUS_COMPLETED ? (
              <div className="row mrgnBtm10">
                <div className="col-md-4 labelDiv">
                  <span className="editTest-align">{this.polyglot.t("Analyzed Document :")}</span>
                </div>
                <div className="col-md-8 labelDiv padding-top-point8 ">
                  <Input
                    type="number"
                    inputBoxSize="sm"
                    placeholder={this.polyglot.t("Analyzed Document")}
                    value={this.state.editedAnalyzedDocument}
                    onChange={this.analyzedDocumentOnChng.bind(this)}
                    aria-label={this.polyglot.t("Analyzed Document")}
                  />
                </div>
              </div>
            ) : (
              <div />
            )}
            {this.state.editedCreateCodingStatus === STATUS_NOT_STARTED && (
              <div className="row mrgnBtm10">
                <div className="col-md-4 labelDiv">
                  <span className="editTest-align">{`${this.polyglot.t("Review platform URL")}:`}</span>
                </div>
                <div className="col-md-8 labelDiv padding-top-point8">
                  <Input
                    inputBoxSize="sm"
                    type="text"
                    placeholder={this.polyglot.t("Review platform URL")}
                    name={this.polyglot.t("Review platform URL")}
                    id="reviewPlatformURL"
                    value={this.state.reviewPlatformURL}
                    onChange={this.reviewPlatformChange}
                    aria-label={this.polyglot.t("Review platform URL")}
                  />
                  <span id="showError" className="errorFntClr">
                    {this.polyglot.t(this.state.showError_reviewPlatformURL)}
                  </span>
                </div>
              </div>
            )}
          </CustomModal>
        </div>
      </div>
    );
  }
}
export default ReviewGrid;
