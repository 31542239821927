import { Panel } from "appkit-react";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { ReactComponent as LoadingSpinner } from "../../../../Assets/Images/GRC/loading-spin.svg";
import RecipientService from "../../../../Services/GRC/RecipientService";
import { useHttpClient } from "../../../../Services/GRC/http-hook";
import { useHttpPostPerfClient } from "../../../../Services/GRC/http-hook-perf-post";
import { polyglotLoader } from "../../../../Services/Shared/TranslateGRC";
import ErrorPanel from "../../Shared/ErrorPanel";
import RecipientCategoryText from "../RecipientCategoryText";
import RecipientQuesAnswers from "../RecipientQuesAnswers";
import RecipientQuestionTree from "../RecipientQuestionTree";
import "./index.css";

const RecipientAssessment = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient("r");
  const { isLoadingPerfPost, errorPerfPost, sendRequestPerfPost, clearErrorPerfPost } =
    useHttpPostPerfClient();
  const [recipientAssessment, setRecipientAssessment] = useState([]);
  const [loadComponent, setloadComponent] = useState("");
  const [setid, setsetid] = useState(0);
  const [totalQuestions, setTotalQuestions] = useState([]);
  const [submittedId, setSubmittedId] = useState();
  const [categoryPath, setcategoryPath] = useState("");
  const [index, setIndex] = useState("");
  const [ansCountVal, setAnsCountVal] = useState(0);
  const [apiValue, setApiValue] = useState(true);
  const mapA = {};
  const rootsA = [];
  const polyglot = polyglotLoader();
  const getSelectedQuestionaries = async () => {
    try {
      const uid = sessionStorage.getItem("grc_recipient_user_uid"); //"CD6E3A25-9E17-4A08-97FF-64CA0DFE8A70";
      const postData = {
        unique_id: uid,
      };

      const responseData = await sendRequestPerfPost(
        RecipientService.get().postRecipientAssessmentsURL(),
        JSON.stringify(postData),
        {
          "Content-Type": "application/json",
        },
      );
      if (responseData) {
        const list = responseData[0];
        setTotalQuestions(responseData[1]);
        setsetid(responseData[0][0].value);
        list.forEach((v, i) => {
          mapA[v.value] = i;
          list[i].children = [];
        });

        list.forEach((v1, i) => {
          v1.tagLebel !== 0 ? list[mapA[v1.tagLebel]].children.push(v1) : rootsA.push(v1);
        });
        setRecipientAssessment(rootsA);
      }
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getSelectedQuestionaries();
  }, [sendRequest, submittedId]);

  function handleContainerLoad(e, v, t, quesIndex) {
    setloadComponent(t);
    setsetid(v);
    setIndex(quesIndex);

    setApiValue(true);
  }
  function handleContainer(rightPanelClick) {
    setApiValue(rightPanelClick);
  }
  function trst(d) {
    setAnsCountVal(d);
    //getSelectedQuestionaries();
  }
  const onChange = (currentNode, selectedNodes) => {
    setcategoryPath(currentNode.tagClassName);
  };

  return (
    <div className="row m-0">
      <div className="col-md-3 col-sm-12 pl-0">
        {error && <ErrorPanel error={error} onClear={clearError} />}
        {isLoading && (
          <div>
            <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
          </div>
        )}
        {!isLoading && recipientAssessment && (
          <Panel className="panel h-100">
            <h5>{polyglot.t("perform_assessment")}</h5>
            <hr />
            <RecipientQuestionTree
              data={recipientAssessment}
              handleChanged={handleContainerLoad}
              totalQuestions={totalQuestions}
              setid={setid}
              onChange={onChange}
              loadtype={loadComponent}
            />
          </Panel>
        )}
      </div>

      <div className="col-md-9 usertoadd-responsive p-0 pr-3 mt-3">
        <Panel className="panel  answercontainer">
          {loadComponent === "ques" ? (
            <RecipientQuesAnswers
              questionIndex={index}
              id={setid}
              totalQuestions={totalQuestions}
              /* setSubmittedId={setSubmittedId}*/
              setsetid={setsetid}
              setApiValue={handleContainer}
              param1={apiValue}
            />
          ) : (
            <RecipientCategoryText id={setid} />
          )}
        </Panel>
      </div>
    </div>
  );
};
export default withRouter(RecipientAssessment);
