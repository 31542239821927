import React from "react";
import Dropzone from "../../dropzone/Dropzone";
import "../../../DFED/Custodian/personalCustodian/personalCustodian.css";
import "appkit-react/style/appkit-react.default.css";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";
import closeIcon from "../../../../Assets/Images/DFED/icon-x.svg";
import download from "../../../../Assets/Images/DFED/icon-download.svg";
import { checkIfGIFileIsValid } from "../../../../Services/Shared/Handlers";
// THIS CLASS IS RESPONSIBLE FOR CREATING PERSONAL CUSTODIAN AND EDITING OF PERSONAL CUSTODIAN

class FileUpload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        CRT_FILE: [],
      },
      files: [],
      fileTypeValidation: null,
      Magicnumber: null,
      filesize: null,
    };
    this.formFileUpload = this.formFileUpload.bind(this);
    this.deleteUploadedFile = this.deleteUploadedFile.bind(this);
  }

  polyglot = polyglotLoader();
  // LIFECYCLE METHOD RESPONSIBLE FOR GETTING ALL DATA AND CALLING API SERVICE=================
  componentDidMount() {
    if (!Array.isArray(this.props.files)) {
      const parseFiles = this.props.files ? JSON.parse(this.props.files) : [];
      const fileArr = parseFiles.map((each) => each.Filename);
      let tempFileArr = [];
      if (fileArr && fileArr.length > 0) {
        for (let i = 0; i < fileArr.length; i++) {
          let obj = { name: fileArr[i] };
          tempFileArr.push(obj);
        }

        this.setState((prevState) => {
          return {
            formData: {
              ...prevState.formData,
              CRT_FILE: tempFileArr,
            },
          };
        });
      }
    }
  }
  // FILE DROP FUNCTIONALLY AFTER DRAG
  onDrop = async (acceptedFiles, index) => {
    this.formFileUpload(acceptedFiles);
    let GIFileIsValid = await checkIfGIFileIsValid(acceptedFiles);
    this.setState({
      Magicnumber: GIFileIsValid,
    });
  };
  // FILE UPLOAD FUNCTIONALITY=====
  formFileUpload(data) {
    //file validation
    let filevalidation;
    var filePath = data[0].name;
    var allowedExtensions = /(\.jpg|.jpeg|.png|.pdf|.xls|.xlsx|.doc|.docx|.ppt|pptx|.zip)$/i;
    if (!allowedExtensions.exec(filePath)) {
      filevalidation = false;
    } else {
      filevalidation = true;
    }

    let obj = {
      name: data[0].name,
    };
    if (
      this.state.formData.CRT_FILE &&
      this.state.formData.CRT_FILE.length < 5 &&
      filevalidation == true &&
      this.state.Magicnumber != null &&
      data[0].size < 5242880
    ) {
      this.setState((prevState) => ({
        formData: {
          ...this.state.formData,
          CRT_FILE: [...this.state.formData["CRT_FILE"], obj],
        },
        files: [...this.state.files, data[0]],
        fileTypeValidation: filevalidation,
        filesize: data[0].size,
      }));
    } else {
      this.setState({
        formData: {
          ...this.state.formData,
        },
        fileTypeValidation: filevalidation,
        filesize: data[0].size,
      });
    }
  }

  // DELETED UPLOADED FILE FROM FRONTEND===================
  deleteUploadedFile(index, data) {
    this.state.formData.CRT_FILE.splice(index, 1);
    this.state.files.splice(index, 1);
    this.setState({
      formData: {
        ...this.state.formData,
      },
      files: this.state.files,
    });
  }

  // runs when the component got updated
  componentDidUpdate(prevProp, prevState) {
    const { handleChange, fieldName, updateUploadedFiles } = this.props;
    // if CRT_FILE got updated, then update the parent forrmik value
    if (prevState.formData.CRT_FILE !== this.state.formData.CRT_FILE) {
      handleChange(fieldName)({
        target: { value: this.state.formData.CRT_FILE },
      });
      updateUploadedFiles(this.state.files);
    }
  }

  // RENDER FUNCTION FOR PERSON CUSTODIAN=============
  render() {
    return (
      <>
        <div className="col-md-12 form-right-space">
          <div className="file-upload-header" aria-label={this.polyglot.t("Upload additional information")}>
            {this.polyglot.t("Upload additional information")}
          </div>
          <div
            className="file-upload-header-description p-t-5 p-b-15"
            aria-label={this.polyglot.t("(E.g. employee contract, consent, etc.)")}
          >
            {this.polyglot.t("(E.g. employee contract, consent, etc.)")}
          </div>
          <div
            className="file-upload-header-description f-s-14 p-b-15 text-color"
            aria-label={this.polyglot.t(
              "Note: Maximum number of files that can be uploaded is 5 (Max size : 5 MB each) ",
            )}
          >
            {this.polyglot.t(
              "Note: Maximum number of files that can be uploaded is 5 (Max size : 5 MB each) ",
            )}
          </div>
          <div id="dfed-custodian-personal">
            <Dropzone onFilesAdded={(e) => this.onDrop(e)}></Dropzone>
          </div>
        </div>

        <div className="col-md-12 form-right-space mt-2">
          <div className="">
            {this.state.formData.CRT_FILE &&
              this.state.formData.CRT_FILE.length > 0 &&
              this.state.formData.CRT_FILE.map((acceptedFile, index) => (
                <div className="uploadedFileList m-b-6" key={index}>
                  {acceptedFile.name}&nbsp;&nbsp;
                  {this.props.isEdit == false && (
                    <div className="fileDeleteIcon">
                      <button onClick={this.deleteUploadedFile.bind(this, index)} className="cursor">
                        <img
                          src={closeIcon}
                          alt={this.polyglot.t("Remove file")}
                          aria-label={this.polyglot.t("Remove file")}
                        />
                      </button>
                    </div>
                  )}
                </div>
              ))}
          </div>
          {this.state.formData.CRT_FILE && this.state.formData.CRT_FILE.length === 5 && (
            <div
              className="text-danger"
              aria-label={this.polyglot.t(
                "Maximum file upload limit is  5 you crossed that limit. sorry you can't upload more.",
              )}
            >
              {this.polyglot.t(
                "Maximum file upload limit is  5 you crossed that limit. sorry you can't upload more.",
              )}
            </div>
          )}

          {!this.state.Magicnumber && this.state.fileTypeValidation != null && (
            <div
              className="text-danger"
              aria-label={this.polyglot.t("Only png,jpeg,pdf,zip,xlsx,ppt these extension allowed")}
            >
              {this.polyglot.t("Only png,jpeg,pdf,zip,xlsx,ppt these extension allowed")}
            </div>
          )}
          {this.state.filesize != null && this.state.filesize > 5242880 && (
            <div
              className="text-danger"
              aria-label={this.polyglot.t("Maximum file upload size is 5 MB only")}
            >
              {this.polyglot.t("Maximum file upload size is 5 MB only")}
            </div>
          )}
        </div>
      </>
    );
  }
}
export default FileUpload;
