import { Button } from "appkit-react";
import React from "react";

import CustomModal from "../../../Assets/Components/Modal";
import { polyglotLoader } from "../../../Services/Shared/Translate";

// RENDERS ON CLOSING THE PROJECT FORM
const ConfirmBox = (props) => {
  // DATA FROM PROPS
  const { show, heading, cancel, proceed } = props;

  const polyglot = polyglotLoader();

  return (
    <CustomModal
      show={show}
      onCancel={cancel}
      title={polyglot.t(heading)}
      Footer={[
        <Button size="lg" className="primary_button" onClick={proceed} key={"foter_c"}>
          {polyglot.t("Yes")}
        </Button>,
      ]}
    >
      <div className="word-break">
        {polyglot.t("Any unsaved changes will be lost, are you sure you want to proceed?")}
      </div>
    </CustomModal>
  );
};

export default ConfirmBox;
