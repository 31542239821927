/* eslint-disable no-mixed-spaces-and-tabs */
import { Input, TextArea, Select, SelectOption, SelectGroupTitle, Checkbox } from "appkit-react";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";

import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import SelectCustom from "../../../../Assets/Components/Select";
import QuestionService from "../../../../Services/GRC/QuestionService";
import { useHttpClient } from "../../../../Services/GRC/http-hook";
import { useHttpPostClient } from "../../../../Services/GRC/http-hook-post";
import { polyglotLoader } from "../../../../Services/Shared/TranslateGRC";
import { questionnaireValidationSchema } from "../../../../utility/textFieldValidation";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";
import { AnswerType } from "../AnswerType";
import DependancyAnswerBlock from "../DependancyQuestionFolder/DependancyAnswerBlock";

const DependancyQuestion = ({
  categoryId,
  setCurrentQuestion,
  setlatestId,
  mode,
  test,
  answers,
  previous_ans_Id,
  answerId,
  setSave,
  setmodal,
  currentquestion,
  questId,
}) => {
  const polyglot = polyglotLoader();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { isLoadingPost, errorPost, sendRequestPost, clearErrorPost } = useHttpPostClient();
  // manage data load
  const [answerTypes, setAnswertypes] = useState([]);
  const [disabledAnswerTypes, setDisabledAnswerTypes] = useState([]);
  const [assessmentTypes, setAssessmenttypes] = useState([]);
  const [tagList, setTags] = useState([]);
  // set custome answers
  const [customCount, setCustomCount] = useState(2);
  const [answersdata, setAnswerdata] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagsData, settagsData] = useState([]);

  let newArr = [];
  const isAddmode = true;

  const setAnsType = (value) => {
    const selectedAnswertypes = answerTypes.filter((item) => item.id === value)[0];

    if (selectedAnswertypes.options === "values") {
      const elments = selectedAnswertypes.values.split(selectedAnswertypes.config);
      for (let k = 0; k < elments.length; k++) {
        newArr.push({
          id: k,
          answer_text: elments[k],
          additional_answer_text: "",
          scoring: 0,
          weightage: 0,
          dependency_question_id: 0,
          readonly: selectedAnswertypes.is_readonly,
          hasError: false, // checks if the row has any error or not
        });
      }
    } else if (selectedAnswertypes.options === "freebox") {
      newArr.push({
        id: 1,
        answer_text: "",
        additional_answer_text: "",
        scoring: 0,
        weightage: 0,
        dependency_question_id: 0,
        readonly: selectedAnswertypes.is_readonly,
        hasError: false, // checks if the row has any error or not
      });
    } else if (selectedAnswertypes.options === "renge") {
      for (let i = 0; i < customCount; i++) {
        newArr.push({
          id: i,
          answer_text: "",
          additional_answer_text: "",
          scoring: 0,
          weightage: 0,
          dependency_question_id: 0,
          readonly: selectedAnswertypes.is_readonly,
          hasError: false, // checks if the row has any error or not
        });
      }
    }

    setAnswerdata(newArr);
  };
  const decrementCount = (e, min) => {
    e.preventDefault();
    if (customCount > min) {
      const count = customCount - 1;
      setCustomCount(count);
      // to do update setAnswerdata()
      answersdata.pop();
      setAnswerdata(answersdata);
    }
  };
  const incrementCount = (e, max) => {
    e.preventDefault();
    if (customCount < max) {
      const count = customCount + 1;
      setCustomCount(count);

      let newAnstwerTemplate = {
        id: answersdata.length + 1,
        answer_text: "",
        additional_answer_text: "",
        scoring: 0,
        weightage: 0,
        dependency_question_id: 0,
        readonly: false,
        hasError: false, // checks if the row has any error or not
      };
      setAnswerdata([...answersdata, newAnstwerTemplate]);
    }
  };

  const getDisabledAnswertypes = (answerTypeList) => {
    let newAnswerTypelist = answerTypeList.filter((e) => e.options === "values");
    let disableList = [];
    newAnswerTypelist.forEach((element) => {
      disableList.push(element.values.split(","));
    });
    return [...new Set(disableList.flat())];
  };

  useEffect(() => {
    const onLoadCategories = async () => {
      try {
        const assessmentTypeList = await sendRequest(QuestionService.get().getAssessmentTypesURL());
        setAssessmenttypes(assessmentTypeList);

        const answerTypeList = await sendRequest(QuestionService.get().getAnswerTypeURL());
        setAnswertypes(answerTypeList);
        setDisabledAnswerTypes(getDisabledAnswertypes(answerTypeList));

        const responseTagsData = await sendRequest(QuestionService.get().getTagsURL());

        setTags(responseTagsData);
      } catch (err) {
        alert(err);
      }
    };
    onLoadCategories();
  }, [sendRequest]);
  const onChange = (currentNode, selectedNodes) => {
    setSelectedTags(selectedNodes);
  };

  const getInitialValues = () => ({
    question_text: "",
    addcomment: false,
    commentmandatory: false,
    addfile: false,
    fileuploadmandatory: false,
    assessmentid: "",
    answertypeid: "",
    not_applicable: false,
    scoring_top: "",
  });
  // reset additional form fields
  const resetAdditionalFormFields = () => {
    try {
      // empty tags feild
      settagsData([]);
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  const handlerQuestionSubmit = async (values, { setSubmitting, setErrors, setStatus, resetForm }) => {
    setSave(true);
    const errorsInAnswerData = answersdata.filter((each) => each.hasError === true);
    if (errorsInAnswerData.length > 0) {
      displayToastMessage(
        "Please resolve all the errors present in answer boxes before saving the question",
        "error",
      );
    }
    const createdBy = JSON.parse(sessionStorage.getItem("user")).userid;
    if (isAddmode && errorsInAnswerData.length === 0) {
      let id = answers.filter((item, index) => {
        if (index === answerId) return item;
      });
      const uniqueNumber = new Date().getTime();

      let notApplicableArr = {
        id: answers.length,
        answer_text: "not applicable",
        additional_answer_text: "",
        scoring: 0,
        weightage: 0,
        dependency_question_id: 0,
        readonly: true,
        hasError: false,
      };
      let finalArray = answers;
      if (values.not_applicable) {
        finalArray = answers.concat(notApplicableArr);
      }

      const postinput = {
        id: uniqueNumber,
        parent_id: categoryId,
        question_text: values.question_text,
        assessment_type_id: values.assessmentid,
        answer_type_id: values.answertypeid,
        previous_answer_type_id: previous_ans_Id,
        is_add_comment: values.addcomment,
        is_comment_mandatory: values.commentmandatory,
        created_by: createdBy,
        is_add_file: values.addfile,
        is_file_mandatory: values.fileuploadmandatory,
        not_applicable: values.not_applicable,
        scoring_top: values.scoring_top,
        question_type_id: 1, // if question add through category then 1,if direct add 0
        selected_tags: tagsData.toString(),
        selected_answers: JSON.stringify(finalArray),
        language_id: 1, // 1=>english
        root_question_id: questId,
        root_question_answer_id: id[0].id, //newData(answers)
      };
      // when no question id is present

      let ar = [];
      const arr = JSON.parse(sessionStorage.getItem("depenent_quest_Id"));
      ar.concat(arr);
      try {
        arr.map((item, i) =>
          i === answerId ? Object.assign(item, { ["dependency_question"]: postinput }) : item,
        );
        sessionStorage.setItem("depenent_quest_Id", JSON.stringify(arr));
      } catch (err) {
        console.log("err", err);
      }
      resetForm({});
      resetAdditionalFormFields();
      setmodal(false);
      setAnswerdata([]);
      test(uniqueNumber);
      setlatestId(uniqueNumber);
    }
  };
  const onChangeTags =
    (formField) =>
    ({ target }) => {
      const data = target.value;
      settagsData(data);
    };

  return (
    <>
      <div className="row">
        <div className="col-md-12 p-0">
          <h5 className="headertest p-l-1rem">{mode === "add" && polyglot.t("create_question")}</h5>
          <div className="a-py-10 border-top a-border-light a-mt-10 category-divider"></div>
        </div>
      </div>
      <div className="row"></div>
      {answerTypes.length > 0 && (
        <Formik
          initialValues={getInitialValues()}
          onSubmit={handlerQuestionSubmit}
          validationSchema={questionnaireValidationSchema}
        >
          {(props) => {
            const { values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting, dirty } =
              props;
            return (
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <TextArea
                      placeholder={`${polyglot.t("description.label")}*`}
                      value={values.question_text}
                      onChange={handleChange("question_text")}
                      className="Notes proj-notes-txt-area"
                      aria-label={polyglot.t("description.label")}
                    />
                    {/* MAX NUMBER OF CHARACTERS ALLOWED IS 2000 */}
                    <div>{values.question_text.length}/2000</div>
                    {(errors.question_text && touched.question_text) || dirty ? (
                      <div className="error">
                        {errors.question_text ? polyglot.t(errors.question_text) : null}
                      </div>
                    ) : null}
                    <br />
                  </div>
                  <div className="noofcharacters"></div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <SelectCustom
                      onSelect={onChangeTags}
                      placeholder={polyglot.t("select_tags")}
                      multiple
                      showSelectAll
                      value={tagsData}
                      JSONArray={tagList}
                      optionKey="value"
                      optionLabel="label"
                      formLabel="tags"
                    />
                  </div>
                </div>
                <div className="row p-t-1point25rem">
                  <div className="col-md-7">
                    <div className="row input-field-height">
                      <div className="col-md-6 m-auto m-l-0">
                        <Checkbox
                          name="addcomment"
                          checked={values.addcomment}
                          onChange={handleChange("addcomment")}
                        >
                          Add comment field
                        </Checkbox>
                      </div>
                      {values.addcomment && (
                        <div className="col-md-6 m-auto m-l-0">
                          <Checkbox
                            name="commentmandatory"
                            checked={values.commentmandatory}
                            onChange={handleChange("commentmandatory")}
                          >
                            Comment mandatory
                          </Checkbox>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-5">
                    <Select
                      className=""
                      placeholder={`${polyglot.t("type_of_assessment")} *`}
                      value={values.assessmentid}
                      onSelect={(value) => handleChange("assessmentid")({ target: { value } })}
                    >
                      <SelectGroupTitle>{polyglot.t("assessment.type")}</SelectGroupTitle>
                      {assessmentTypes.map((assessmentType, index) => {
                        return (
                          <SelectOption key={index + 1} value={assessmentType.id}>
                            {assessmentType.name}
                          </SelectOption>
                        );
                      })}
                    </Select>
                    {touched.assessmentid ? (
                      <span className="error">{errors.assessmentid}</span>
                    ) : (
                      null && errors.assessmentid && <span className="error">{errors.assessmentid}</span>
                    )}
                  </div>
                </div>
                <div className="row p-t-1point25rem">
                  <div className="col-md-7">
                    <div className="row input-field-height">
                      <div className="col-md-6 m-auto m-l-0">
                        <Checkbox name="addfile" checked={values.addfile} onChange={handleChange("addfile")}>
                          Add file
                        </Checkbox>
                      </div>
                      {values.addfile && (
                        <div className="col-md-6 m-auto m-l-0">
                          <Checkbox
                            name="fileuploadmandatory"
                            checked={values.fileuploadmandatory}
                            onChange={handleChange("fileuploadmandatory")}
                          >
                            File upload mandatory
                          </Checkbox>
                        </div>
                      )}
                    </div>
                  </div>
                  <AnswerType
                    answerTypes={answerTypes}
                    customCount={customCount}
                    polyglot={polyglot}
                    touched={touched}
                    decrementCount={decrementCount}
                    errors={errors}
                    handleChange={handleChange}
                    incrementCount={incrementCount}
                    setAnsType={setAnsType}
                    values={values}
                  />
                </div>
                <div className="row p-t-1point25rem">
                  <div className="col-md-7">
                    <div className="row input-field-height">
                      <div className="col-md-6 m-auto m-l-0">
                        <Checkbox
                          name="not_applicable"
                          checked={values.not_applicable}
                          onChange={handleChange("not_applicable")}
                        >
                          Not applicable
                        </Checkbox>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <Input
                      type="number"
                      inputBoxSize="sm"
                      placeholder="Scoring *"
                      onChange={handleChange("scoring_top")}
                      onKeyPress={(e) => {
                        const regex = /[0-9]/;
                        // PREVENT ANY THING IF IT IS NOT 0-9
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      value={values.scoring_top}
                    />
                    {errors.scoring_top && touched.scoring_top ? (
                      <div className="error">{polyglot.t(errors.scoring_top)}</div>
                    ) : null}
                  </div>
                </div>

                <div className="row p-t-1point25rem">
                  <div className="col-md-12">
                    {answersdata.length > 0 && (
                      <>
                        <DependancyAnswerBlock
                          setAnswerdata={setAnswerdata}
                          answers={answersdata}
                          disableAnswerTypes={disabledAnswerTypes}
                          categoryid={categoryId}
                          answerTypeId={values.answertypeid}
                        />
                      </>
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10">
                    <ButtonPrimary
                      className="custome-pos float-right"
                      onClick={handleSubmit}
                      ariaLabel={polyglot.t("save_dependency")}
                      disabled={
                        isSubmitting ||
                        !dirty ||
                        !(Object.keys(errors).length === 0 && errors.constructor === Object)
                      }
                    >
                      {polyglot.t("save_dependency")}
                    </ButtonPrimary>
                  </div>
                  <div className="col-md-2">
                    <ButtonPrimary
                      className="custome-pos float-right"
                      onClick={setmodal}
                      ariaLabel={polyglot.t("cancel")}
                    >
                      {polyglot.t("cancel")}
                    </ButtonPrimary>
                  </div>
                </div>
                <div className="col-md-12 edit-clientform-button p-0">
                  <div className="d-flex justify-content-between"></div>
                </div>
              </div>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default DependancyQuestion;
