import React, { useState } from "react";

import CategoryAddTreeQuestions from "./CategoryAddTreeQuestions";
import "./index.css";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import { plusCircle } from "../../../Assets/Icons";
import editImg from "../../../Assets/Icons/pencil-outline.svg";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";

const CategoryAddTree = ({ data = [], handleChanged, setid, mode, getCategories }) => {
  return (
    <div className="d-tree p-0 ">
      <ul className="d-flex d-tree-container flex-column p-0 ">
        {data &&
          data.map((tree, i) => (
            <TreeNode
              key={i}
              node={tree}
              handleChanged={handleChanged}
              setid={setid}
              mode={mode}
              getCategories={getCategories}
            />
          ))}
      </ul>
    </div>
  );
};

const polyglot = polyglotLoader();
const TreeNode = ({ node, handleChanged, setid, mode, getCategories }) => {
  const [childVisible, setChildVisiblity] = useState(true);

  const hasChild = node.children ? true : false;
  const loadIcon = () => {
    if (node.depth == 2) {
      return (
        <span
          className="appkiticon icon-news-outline  custom-grc-icon"
          onClick={(e) => setChildVisiblity((v) => !v)}
        />
      );
    } else {
      return (
        <span
          className="appkiticon icon-folder-closed-outline custom-grc-icon"
          onClick={(e) => setChildVisiblity((v) => !v)}
        />
      );
    }
  };

  return (
    <li className={`d-tree-node  ${node.depth === 0 ? "padding-zero" : ""}`} key={node.value}>
      <div className="d-flex justify-content-between ">
        <div className={`col d-tree-head p-0  ${childVisible ? "selected-node" : ""}`}>
          {node.depth === 2 ? (
            <>
              <div className="m-auto m-l-0 display-inline-flex w-55">
                {loadIcon()}
                <label title={node.label} htmlFor={node.label} className="ml-1 p-0 hide-long-text">
                  <span label={node.label} id={node.label} className="question_create_Cat_Q">
                    {node.label}
                  </span>
                </label>
              </div>
              <div className="display-inline icon-style">
                <ButtonIcon
                  name={node.value}
                  onClick={(e) => {
                    handleChanged(e, node.value, node.depth, node.tagClassName, "text", "add");
                  }}
                  iconName={plusCircle}
                  ariaLabel={polyglot.t("Close")}
                />
                {mode === "edit" && (
                  <ButtonIcon
                    imgWidth={16}
                    name={node.value}
                    onClick={(e) => {
                      handleChanged(e, node.value, node.depth, node.tagClassName, "text", "edit");
                    }}
                    iconImg={editImg}
                  />
                )}
              </div>

              <CategoryAddTreeQuestions
                parentId={node.value}
                handleChanged={handleChanged}
                mode={mode}
                getCategories={getCategories}
              />
            </>
          ) : (
            <>
              {loadIcon()}
              <div
                key={node.value}
                className={`d-inline custome-tree-view-title tree-top-padding ${
                  childVisible ? "selected-node" : ""
                }`}
              >
                {node.value !== undefined && (
                  <>
                    <label title={node.label} htmlFor={node.label} className="ml-1 p-0 hide-long-text">
                      <span label={node.label} id={node.label} className="question_create_Cat_Q">
                        {node.label}
                      </span>
                    </label>

                    <div className="display-inline icon-style">
                      <ButtonIcon
                        name={node.value}
                        onClick={(e) => {
                          handleChanged(e, node.value, node.depth, node.tagClassName, "text", "add");
                        }}
                        iconName={plusCircle}
                        ariaLabel={polyglot.t("Close")}
                      />
                      {mode === "edit" && (
                        <ButtonIcon
                          imgWidth={16}
                          name={node.value}
                          onClick={(e) => {
                            handleChanged(e, node.value, node.depth, node.tagClassName, "text", "edit");
                          }}
                          iconImg={editImg}
                        />
                      )}
                    </div>
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {hasChild && childVisible && (
        <div className="d-tree-content ">
          <ul className="d-flex d-tree-container flex-column">
            <CategoryAddTree
              data={node.children}
              handleChanged={handleChanged}
              setid={setid}
              mode={mode}
              getCategories={getCategories}
            />
          </ul>
        </div>
      )}
    </li>
  );
};
export default CategoryAddTree;
