import { Radio } from "appkit-react";
import $ from "jquery";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";

import httpRequest from "../../../../Services/Shared/HttpClient";
import displayToastMessage from "../../Root/displayToastMessage";

// DASHBOARD SOLUTIONS
function DashboardSolutions(props) {
  const [selectedSolution, setselectedSolution] = useState(
    props.solutionsData.length > 0 ? props.solutionsData[0].solutionid : 0,
  );
  const HttpRequest = new httpRequest();
  // HANDLE SOLUTION CHANGE
  const handleSolutionChange = (e, value) => {
    setselectedSolution(value);
    props.setSolution(value);
    getSolutionsData(value);
    props.toggleIsEdiscoveryGetData(false);
  };
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    getSolutionsData(1);
    const parent = document.getElementsByClassName("queen-container")[0];
    let Scroll = document.getElementsByClassName("scrollTo");
    if (Scroll.length) {
      $(parent).animate(
        {
          scrollTop: Scroll[0].getBoundingClientRect().y - 100,
        },
        "slow",
      );
    }
  }, []);

  // GET SOLUTIONS DATA
  const getSolutionsData = async (value) => {
    try {
      // API TO GET WIDGET DATA OF SOLUTIONS
      var WidgetData = await HttpRequest.post("/getSolutionsWidgetData", { widget: value });
      var getEdiscoverySolutions = [];
      var getDfSolutions = [];
      var getGiSolutions = [];
      for (var i = 0; i < WidgetData.length; i++) {
        if (WidgetData[i].packagetype == 2 && WidgetData[i].delete_widget != 1) {
          props.ediscoverysolutionexists(true);
          getEdiscoverySolutions.push(WidgetData[i]);
        } else if (WidgetData[i].packagetype == 3 && WidgetData[i].delete_widget != 1) {
          props.dfsolutionexists(true);
          getDfSolutions.push(WidgetData[i]);
        } else if (WidgetData[i].packagetype == 4 && WidgetData[i].delete_widget != 1) {
          props.gisolutionexists(true);
          getGiSolutions.push(WidgetData[i]);
        }
      }
      props.getEdiscoverySolutions(getEdiscoverySolutions);
      props.getDfSolutions(getDfSolutions);
      props.getGiSolutions(getGiSolutions);
    } catch (error) {
      displayToastMessage("Something went wrong!", "error");
    }
  };

  return (
    <div className="row">
      <div className="">
        <div className="dashboard-solutions">
          {props.solutionsData.map((packagedata, index) => {
            return (
              <Radio
                key={index}
                value={packagedata.solutionid}
                onChange={handleSolutionChange}
                checked={selectedSolution == packagedata.solutionid}
              >
                {packagedata.solutionName}
              </Radio>
            );
          })}
        </div>
        {/* <SelectedSolution selectedSolution={selectedSolution} solutionsData={props.solutionsData} /> */}
      </div>
    </div>
  );
}

DashboardSolutions.propTypes = {
  solutionsData: PropTypes.array,
};

export default DashboardSolutions;
