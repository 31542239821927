import React, { Component } from "react";
import "./AddAnalysis.css";
import "../../DFED/Custodian/custodianList/custodianList.css";
import AddAnalysisInitPage from "./AddAnalysisInitPage";
import AnalysisList from "./AnalysisList";
import AddNewAnalysis from "./AddNewAnalysis";
import RunAnalysis from "./RunAnalysis";
import AnalysisReview from "./AnalysisReview";
import AnalysisService from "../../../../src/Services/DFED/AnalysisService";
import OutlineButton from "../Shared/button/OutlineButton";
import { Pagination } from "antd";
import { Input } from "reactstrap";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import { checkPermission } from "../../../utility/checkPermission";
import commonFunctionForCallService from "../../../utility/commonFunctionForCallService";
import * as module_Solution_Name from "../../../Constants/moduleName";
import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import { Select, SelectGroupTitle, SelectOption } from "appkit-react";
import moment from "moment";

const analysisService = new AnalysisService();
var component;
var languageChange = "en";
// ADD ANALYSIS COMPONENT FOR ADDING NEW ANALYSIS DATA==========================

class AddAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      componentName: "AnalysisList",
      component: "",
      data: [],
      lengthOfVisData: 0,
      filteredData: [],
      visibleData: [],
      selectedAnalysisData: {},
      toNewReview: false,
      currentPage: 1,
      dataForRunThrough: [],
      forBackfromKeyAdd: false,
      search_text: "",
      filterOptions: [],
      rolePermission: {},
      isViewPermission: true,
      showAddAnalysisButton: false,
    };
  }
  static contextType = DataContext;
  // LIFE CYCLE METHOD FOR GETTING ALL ANALYSIS DATA CREATED
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.getApplicationRolesWithPermission()) {
      if (localStorage.getItem("HideResfromKeyProp") === "true") {
        this.setState({ forBackfromKeyAdd: this.props.location.state });
        localStorage.setItem("HideResfromKeyProp", "false");
      } else {
        this.setState({ forBackfromKeyAdd: false });
      }
      if (localStorage.getItem("fromKeyWords") === "true") {
        localStorage.setItem("fromKeyWords", "false");
      }
      this.getAnalysisService();
    }
  }
  // GETTING ROLES AND PERMISSION FOR ANALYSIS COMPONENT==================
  getApplicationRolesWithPermission = async () => {
    let selectedProjectId = this.props.location.state.selectedProjectId;
    let rolePermission = await commonFunctionForCallService.commonFunctionForCallService(
      module_Solution_Name.DFED_SOLUTION,
      selectedProjectId,
      module_Solution_Name.ANALYSIS_MODULE,
    );
    if (rolePermission) {
      this.setState(
        {
          rolePermission: rolePermission,
        },
        () => {
          let tempPermission = checkPermission(
            this.state.rolePermission,
            module_Solution_Name.ANALYSIS_MODULE,
            "Create",
          );
          this.setState({
            showAddAnalysisButton: tempPermission,
          });
        },
      );
    }
    return rolePermission;
  };
  // BACK BUTTON FUNCTIONALITY FOR COMMING BACK FROM REVIEW SCREEN===================

  comeBackFromReview = () => {
    localStorage.setItem("HideResfromKeyProp", "false");
    this.componentHandler("AnalysisList");
    this.setState({ toNewReview: false, currentPage: 1 });
    // to make from disable disable
    this.getAnalysisService();
  };
  // GETTING ANALYSIS REVIEW DATA======================
  async getAnalysisService() {
    await analysisService
      .AnalysisSummary(Number(this.props.location.state.selectedProjectId))
      .then((analysInfo) => {
        if (analysInfo && analysInfo.data) {
          this.setState(
            {
              data: analysInfo.data,
              lengthOfVisData: analysInfo.data.length,
              filteredData: analysInfo.data,
            },
            () => {
              this.visibleDataHandler(analysInfo.data, 1);
            },
          );
        }
      })
      .catch((err) => {
        if (err && err.status === 403) {
          this.setState({
            isViewPermission: false,
          });
        }
      });

    this.changeComponent();
  }
  // REDIRECTING TO RUNANALYSIS SCREEN===================
  goToRunThrough(data) {
    this.setState({ selectedAnalysisData: data }, () => this.componentHandler("RunAnalysis"));
  }
  // CHECK IF ANALYSIS AND CUSTODIAN RECORDS ARE AVAILABLE
  checkIfAnalysisCustodianRecordsAreAvailable = async () => {
    try {
      const projectID = this.props.location.state.selectedProjectId;
      const apiResponse = await analysisService.getAnalysisCustodianInfo(projectID);
      if (apiResponse && apiResponse.data && apiResponse.data.length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
  componentHandler = async (arg) => {
    var polyglot = polyglotLoader();
    if (arg === "AddNewAnalysis") {
      const isAvailable = await this.checkIfAnalysisCustodianRecordsAreAvailable();
      if (isAvailable) {
        // ROLES API
        this.getApplicationRolesWithPermission();
        this.setState({ componentName: arg }, () => {
          this.changeComponent();
        });
      } else {
        displayToastMessage(
          polyglot.t("At least one processed dataset is required to add an analysis"),
          "error",
        );
      }
    } else {
      this.setState({ componentName: arg }, () => {
        this.changeComponent();
      });
    }
  };
  newRunThrough = (arg, dat, id, name) => {
    let data = { id: id, data: dat, nme: name };
    this.setState({ toNewReview: true, dataForRunThrough: data }, () => {
      this.componentHandler(arg);
    });
  };
  // PAGINATION PAGE CHANGE HANDLER==================
  onChangeTablePageHandler = (page) => {
    this.setState({ currentPage: page });
    this.visibleDataHandler(this.state.filteredData, page);
  };
  // SEARCH FUNCTIONALITY OF ANALYSIS=====================
  onSearch = (e) => {
    var list = this.state.data.filter((item) =>
      item.ANALYSIS_NAME.toLowerCase().includes(e.target.value.toLowerCase()),
    );
    this.setState({ filteredData: list, lengthOfVisData: list.length, currentPage: 1 }, () => {
      this.visibleDataHandler(this.state.filteredData, 1);
    });
  };
  visibleDataHandler(data, page) {
    let lastIndex = 4 * page;
    let firstIndex = lastIndex - 4;
    let visData = data.slice(firstIndex, lastIndex);
    this.setState({ visibleData: visData }, () => {
      this.changeComponent();
    });
  }
  // SORT BY FUNCTIONALITY ON DIFFERENT TYPE===================
  onChangeSortBy = (event) => {
    switch (event) {
      case "Z-A":
        var filteredList = this.state.filteredData.sort((a, b) => {
          if (a.ANALYSIS_NAME.toLowerCase() < b.ANALYSIS_NAME.toLowerCase()) {
            return 1;
          }
          if (a.ANALYSIS_NAME.toLowerCase() > b.ANALYSIS_NAME.toLowerCase()) {
            return -1;
          }
          return 0;
        });
        this.setState(
          {
            filteredData: filteredList,
            selectedSortBy: event,
          },
          () => {
            this.visibleDataHandler(this.state.filteredData, 1);
          },
        );
        return;
      case "A-Z":
        var filteredList = this.state.filteredData.sort((a, b) => {
          if (a.ANALYSIS_NAME.toLowerCase() > b.ANALYSIS_NAME.toLowerCase()) {
            return 1;
          }
          if (a.ANALYSIS_NAME.toLowerCase() < b.ANALYSIS_NAME.toLowerCase()) {
            return -1;
          }
          return 0;
        });
        this.setState(
          {
            filteredData: filteredList,
            selectedSortBy: event,
          },
          () => {
            this.visibleDataHandler(this.state.filteredData, 1);
          },
        );
        return;
      case "Newest":
        var filteredList = this.state.filteredData.sort((a, b) => {
          return moment(a.CREATED_ON).isBefore(moment(b.CREATED_ON)) ? 0 : -1;
        });
        this.setState(
          {
            filteredData: filteredList,
            selectedSortBy: event,
          },
          () => {
            this.visibleDataHandler(this.state.filteredData, 1);
          },
        );
        return;
      case "Oldest":
        var filteredList = this.state.filteredData.sort((a, b) => {
          return moment(b.CREATED_ON).isBefore(moment(a.CREATED_ON)) ? 0 : -1;
        });
        this.setState(
          {
            filteredData: filteredList,
            selectedSortBy: event,
          },
          () => {
            this.visibleDataHandler(this.state.filteredData, 1);
          },
        );
      default:
        return;
    }
  };
  // DYNAMIC CHANGE COMPONENT BASED ON CONDITION FUNCTIONALITY========================
  changeComponent = () => {
    this.getApplicationRolesWithPermission();

    var polyglot = polyglotLoader();
    if (this.state.componentName === "AnalysisList") {
      component = (
        <div>
          <div className="row col-lg-10 pl-0">
            <div className="col-md-3 col-lg-3">
              <ButtonPrimaryWithIcon
                onClick={() => this.componentHandler("AddNewAnalysis")}
                ariaLabel={polyglot.t("Add analysis")}
              >
                {polyglot.t("Add analysis")}
              </ButtonPrimaryWithIcon>
            </div>
            {!checkPermission(this.state.rolePermission, module_Solution_Name.REVIEW_MODULE, "Create") && (
              <div className="col-md-3 col-lg-3">
                <OutlineButton
                  name={polyglot.t("Start a Review")}
                  onClick={() =>
                    this.props.history.push({
                      pathname: "/dfed/reviewdetails",
                      state: {
                        selectedProjectId: this.props.location.state.selectedProjectId,
                      },
                    })
                  }
                />
              </div>
            )}
            <div className="col-md-9"></div>
          </div>

          <div className="ans-init-div">
            {this.state.data.length === 0 && this.state.isViewPermission ? (
              <AddAnalysisInitPage />
            ) : (
              // ---------------------for multiple list----------------
              <div>
                <div className="row m-t-21 m-l-0 m-r-0">
                  <div className="col-md-6 tex-ali-processing p-l-0 p-r-0">
                    <h1 className="main_title_qb m-b-21 text-align-left" style={{ fontSize: "22px" }}>
                      {this.state.lengthOfVisData}{" "}
                      {this.state.lengthOfVisData > 1 ? polyglot.t("Analyses") : polyglot.t("Analysis")}
                    </h1>
                  </div>
                </div>

                <div className="row m-l-0 m-r-0 filter-search-qb">
                  <div className={`searchIcon`}>
                    <Input
                      className="SearchBar search-input"
                      aria-label={polyglot.t("Search for analysis list")}
                      placeholder={polyglot.t("Search for analysis list")}
                      value={this.state.query}
                      onChange={this.onSearch}
                    />
                  </div>

                  <div className="search-sort ">
                    <Select
                      id="custodian-sort-id"
                      placeholder={polyglot.t("Sort")}
                      onSelect={(event) => this.onChangeSortBy(event)}
                      value={this.state.selectedSortBy}
                    >
                      <SelectOption key="A-Z" value="A-Z">
                        {polyglot.t("Analysis name")} &nbsp;
                        <span className="appkiticon icon-arrow-up-outline"></span>
                      </SelectOption>
                      <SelectOption key="Z-A" value="Z-A">
                        {polyglot.t("Analysis name")} &nbsp;
                        <span className="appkiticon icon-arrow-down-outline"></span>
                      </SelectOption>
                      <SelectOption key="Newest" value="Newest">
                        {polyglot.t("Newest")}
                      </SelectOption>
                      <SelectOption key="Oldest" value="Oldest">
                        {polyglot.t("Oldest")}
                      </SelectOption>
                    </Select>
                  </div>
                </div>
                <div className="row m-t-8">
                  <div className="col-md-12 col-lg-8">
                    {this.state.visibleData.map((data) => {
                      return (
                        <div className="row margin-top-plus-0-8 m-l-0 m-r-0">
                          <div
                            className="col-md-12 p-0 analysis-review-collapse"
                            // onClick={() => this.goToRunThrough(data)}
                          >
                            <AnalysisList data={data} goToRunThrough={() => this.goToRunThrough(data)} />
                          </div>
                        </div>
                      );
                    })}
                    <div className="mt-4 tex-ali-l m-l-n-1">
                      {this.state.lengthOfVisData > 4 && (
                        <Pagination
                          current={this.state.currentPage}
                          size="small"
                          pageSize={4}
                          total={this.state.lengthOfVisData}
                          onChange={(page) => this.onChangeTablePageHandler(page)}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-12 col-lg-4 tex-ali-l">
                    <div>
                      <p className="para-txt">
                        {polyglot.t(
                          "You can apply your keyword lists and analyse the documents within this section. On the right, you can consult and rerun the already present Analysis jobs",
                        )}
                        .
                      </p>
                      <p className="para-txt">
                        {polyglot.t("If you want to create a new analysis, please click on ")}
                        <span className="para-head">{polyglot.t("Add analysis")}.</span>
                        {" " +
                          polyglot.t(
                            "To view the results of a finished analysis, please select it from the list. You may push the results to review afterwards",
                          )}
                        .
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      );
      this.setState({ component: component });
    } else if (this.state.componentName === "RunAnalysis") {
      if (
        this.state.selectedAnalysisData.REPORT_GENERATED_STATUS === "3" &&
        this.state.componentName === "RunAnalysis"
      ) {
        component = (
          <AnalysisReview
            location={this.props.location.state.selectedProjectId}
            goBackFunction={this.comeBackFromReview}
            data={this.state.selectedAnalysisData}
            history={this.props.history}
            goToAddNewAnalysis={this.newRunThrough}
          />
        );
        this.setState({ component: component });
      } else {
        component = (
          <RunAnalysis
            goBackFunction={this.comeBackFromReview}
            data={this.state.selectedAnalysisData}
            BackToReview={this.changeComponent}
          />
        );
        this.setState({ component: component });
      }
    } else if (this.state.componentName === "AddNewAnalysis") {
      component = (
        <AddNewAnalysis
          location={this.props.location.state.selectedProjectId}
          locationStateForRunData={this.state.forBackfromKeyAdd}
          projectID={this.props.location.state.selectedProjectId}
          history={this.props.history}
          goBack={this.comeBackFromReview}
          fromReview={this.state.toNewReview}
          dataForRunThrough={this.state.dataForRunThrough}
        />
      );
      this.setState({ component: component });
    }
  };

  render() {
    let language = this.context;

    if (language.userLanguage !== languageChange) {
      languageChange = language.userLanguage;
      this.componentDidMount();
    }

    return (
      <div style={{ width: "100%" }}>
        <div>{this.state.component}</div>
      </div>
    );
  }
}
export default AddAnalysis;
