import CommonService from "../Shared/CommonService";

class QuestionnaireService {
  constructor() {
    this.baseApiUrl = CommonService.get().getGRCBaseApiUrl();
  }
  getCategorieswithDepthURL() {
    return `${this.baseApiUrl}/getCategorieswithDepth`;
  }
  getCategoriesSideBarURL() {
    return `${this.baseApiUrl}/getCategoriesSideBar`;
  }
  getQuestionsbyParentURL(depth, parentId) {
    return `${this.baseApiUrl}/getQuestionsbyParent/${depth}/${parentId}`;
  }
}

// Enable Singleton behavior on this class
QuestionnaireService.__instance = null;
QuestionnaireService.get = () => {
  if (!QuestionnaireService.__instance) {
    QuestionnaireService.__instance = new QuestionnaireService();
  }
  return QuestionnaireService.__instance;
};

export default QuestionnaireService;
