/* eslint-disable no-mixed-spaces-and-tabs */
import "react-app-polyfill/ie11";
import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import "./App.css";
import { Banner } from "appkit-react";
import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";

import { GRCAssessmentApplication } from "./AppLayouts";
import ErrorBoundary from "./Assets/Components/ErrorBoundary";
import FooterComponent from "./Components/QueenBase/Root/FooterComponent";
import HeaderContainer from "./Components/QueenBase/Root/HeaderContainer";
import { QUEEN_BASE_PATH } from "./Constants/Path";
import LandingRoute, { RoutesBeforeLogin } from "./Routes/LandingRoute";
import { check_If_User_LoggedIn } from "./Services/Shared/Authenticate";
import { DataContext, Provider } from "./Services/Shared/Store";
import { polyglotLoader } from "./Services/Shared/Translate";
import IdleTimerContainer from "./utility/IdleTimerContainer";
import IdleTimerContainerBeforeJWT from "./utility/IdleTimerContainerBeforeJWT";

function useCaptureEvents() {
  useEffect(() => {
    // capture hash change
    function hashChanged(event) {
      const applicationInitialPath = `${process.env.REACT_APP_PROJECT_NAME}${QUEEN_BASE_PATH}`;
      // if application's initial path is same as the path from the even, then run history.back
      if (applicationInitialPath === event.target.location.pathname) {
        // go back function
        window.history.back();
      }
    }
    // Bind the event listener
    window.addEventListener("popstate", hashChanged);
    return () => {
      // Unbind the event listener on clean up
      window.addEventListener("popstate", hashChanged);
    };
  }, []);
}

const AppWrapper = () => {
  const { resetSessionTimer, userLanguage } = useContext(DataContext);
  const [showBanner, setShowBanner] = useState(false);
  const polyglot = polyglotLoader();

  useCaptureEvents();
  useEffect(() => {
    let jwtRefreshSession;
    if (check_If_User_LoggedIn()) {
      if (jwtRefreshSession) {
        clearInterval(jwtRefreshSession);
      }
      jwtRefreshSession = setInterval(() => {
        resetSessionTimer();
      }, 2500);
    }
    return () => clearInterval(jwtRefreshSession);
  });
  // on the initial load, check if the browser is supported
  useEffect(() => {
    var browser = get_browser();
    var IsSupported = isSupported(browser);
    if (IsSupported) {
      setShowBanner(false);
    } else {
      setShowBanner(true);
    }
  }, []);

  return (
    <>
      {showBanner && (
        <Banner
          content={
            <span>
              {polyglot.t(
                "Your browser is not supported, we strongly recommend using Connected Digital Services Platform with Google Chrome Browser for better experience",
              )}
              .
            </span>
          }
          closeable={true}
          onClose={() => setShowBanner(false)}
        />
      )}
      <ErrorBoundary>
        <>
          <IdleTimerContainerBeforeJWT />
          <IdleTimerContainer />
          <BrowserRouter basename={process.env.REACT_APP_PROJECT_NAME}>
            <div style={{ width: "100%", height: "100%", position: "relative" }}>
              <div
                id="header"
                style={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "73px",
                  right: 0,
                  zIndex: 1,
                }}
              >
                <HeaderContainer />
              </div>
              <div
                id="content"
                style={{
                  position: "absolute",
                  top:
                    document.getElementsByClassName("nav").length || document.getElementById("top-navbar")
                      ? "114px"
                      : "73px",
                  bottom: "67px",
                  right: 0,
                  left: 0,
                  overflow: "auto",
                  zIndex: 0,
                }}
              >
                <RoutesBeforeLogin />
                <LandingRoute userLanguage={userLanguage} />
              </div>
              <div
                id="footer"
                style={{
                  position: "absolute",
                  bottom: 0,
                  height: "67px",
                  left: 0,
                  right: 0,
                  overflow: "hidden",
                  zIndex: 1,
                }}
              >
                <FooterComponent />
              </div>
            </div>
          </BrowserRouter>
        </>
      </ErrorBoundary>
    </>
  );
};

function get_browser() {
  var ua = navigator.userAgent,
    tem,
    M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if (/trident/i.test(M[1])) {
    tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
    return { name: "IE", version: tem[1] || "" };
  }
  if (M[1] === "Chrome") {
    tem = ua.match(/\bOPR\/(\d+)/);
    if (tem != null) {
      return { name: "Opera", version: tem[1] };
    }
  }
  if (window.navigator.userAgent.indexOf("Edge") > -1) {
    tem = ua.match(/\Edge\/(\d+)/);
    if (tem != null) {
      return { name: "Edge", version: tem[1] };
    }
  }
  M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, "-?"];
  if ((tem = ua.match(/version\/(\d+)/i)) != null) {
    M.splice(1, 1, tem[1]);
  }
  return {
    name: M[0],
    version: +M[1],
  };
}

function isSupported(browser) {
  var supported = false;
  if (browser.name === "Chrome" && browser.version >= 48) {
    supported = true;
  } else if (browser.name === "MSIE" || browser.name === "IE") {
    supported = false;
  } else if (browser.name === "Edge") {
    supported = false;
  } else if (browser.name === "Firefox") {
    supported = true;
  }
  return supported;
}

const isGrcEnabled = process.env.REACT_APP_GRC_ENABLED === "true";
function App() {
  if (isGrcEnabled) {
    const isGrcPathname = window.location.pathname.includes(process.env.REACT_APP_GRC_ASSESSMENT_APPLICATION);
    if (isGrcPathname) {
      return (
        <Provider>
          <GRCAssessmentApplication />
        </Provider>
      );
    }
  }

  return (
    <Provider>
      <AppWrapper />
    </Provider>
  );
}

export default App;
