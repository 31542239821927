import React, { useContext, useState, useEffect } from "react";

import InvoiceForm from "./InvoiceForm";
import ListOfInvoice from "./ListOfInvoice";
import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";
import { plusCircle, backCircle } from "../../../Assets/Icons";
import * as path from "../../../Constants/Path";
import AdminService from "../../../Services/QueenBase/Admin/AdminServices";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";

// CLIENT INVOICE LIST
function ClientInvoiceList(props) {
  // DATA FROM CONTEXT
  const { getUserLanguage, isFormOpen, formGotClosed } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const [invoiceForm, setInvoiceForm] = useState(false);
  const [invoiceFormShow, setInvoiceFormShow] = useState(false);
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [status, setStatus] = useState([]);
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [addform, setAddForm] = useState(false);
  const [openConfirmBox, setOpenConfirmBox] = useState(false); // this value handles the confirm box of exit form
  const [clickedButtonName, setClickedButtonName] = useState("");

  // GO BACK TO CLIENT

  const goBackToClient = () => {
    setAddForm(false);
    props.history.push(path.VIEW_ADMIN);
  };

  const checkGotoClient = () => {
    if (isFormOpen) {
      toggleConfirmBox();
      setClickedButtonName("GoToClient");
    } else {
      goBackToClient();
    }
  };

  const checkOnAddInvoice = () => {
    if (isFormOpen) {
      toggleConfirmBox();
      setClickedButtonName("addInvoice");
    } else {
      showForm();
    }
  };

  // SHOW INVOICE FORM
  const showForm = () => {
    setInvoiceForm((prev) => !prev);
    setAddForm(true);
  };
  // RUNS WHEN SHOW IS UPDATED
  // useEffect(() => {
  // 	if (addform) {
  // 		// form is in open state
  // 		formGotOpened()
  // 	} else {
  // 		// form is not in open state
  // 		formGotClosed()
  // 	}
  // }, [addform])
  // GET CLIENT INVOICING DETAILS
  async function ClientInvoicingDetails() {
    var res = await AdminService.get().getClientInvoicingDetails();
    setClients(res.client);
    setProjects(res.project);
    setStatus(res.status);
  }
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    ClientInvoicingDetails();
  }, []);

  // check if form is empty before going back
  const checkIfFormIsEmptyAndGoback = () => {
    if (isFormOpen) {
      // show confirm box
      toggleConfirmBox();
      setClickedButtonName("goBack");
    } else {
      showForm();
    }
  };
  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  const toggleConfirmBox = () => {
    setOpenConfirmBox(!openConfirmBox);
  };
  // CLOSE FORM AND NAVIGATE TO VIEW PROJECTS
  const closeFormAndRedirect = () => {
    toggleConfirmBox();
    redirectionOnClosingConfirmBox();
    formGotClosed();
  };
  // redirect on closing conform box
  const redirectionOnClosingConfirmBox = () => {
    switch (clickedButtonName) {
      case "GoToClient":
        goBackToClient();
        break;
      case "addInvoice":
        showForm();
        break;
      case "goBack":
        showForm();
      default:
        return null;
    }
    setClickedButtonName("");
  };

  return (
    <>
      <div className="mt-3">
        <div className="row col-lg-10 m-l-1 m-b-18 pl-0">
          <div className="col-md-3 col-lg-3 pl-0">
            <ButtonSecondary onClick={checkGotoClient} ariaLabel={polyglot.t("Goto Client")}>
              {polyglot.t("Goto Client")}
            </ButtonSecondary>
          </div>
          <div className="col-md-3 col-lg-3">
            {invoiceForm ? (
              <ButtonPrimaryWithIcon
                iconName={backCircle}
                alt="back"
                onClick={checkIfFormIsEmptyAndGoback}
                ariaLabel={polyglot.t("Back")}
              >
                {polyglot.t("Back")}
              </ButtonPrimaryWithIcon>
            ) : (
              <ButtonPrimaryWithIcon
                onClick={checkOnAddInvoice}
                iconName={plusCircle}
                ariaLabel={polyglot.t("Add Invoice")}
              >
                {polyglot.t("Add Invoice")}
              </ButtonPrimaryWithIcon>
            )}
          </div>
        </div>

        {invoiceForm ? (
          <InvoiceForm showForm={showForm} clients={clients} projects={projects} status={status} />
        ) : (
          <ListOfInvoice clients={clients} />
        )}
        <ConfirmBoxExitForm
          show={openConfirmBox}
          heading={"Cancel update"}
          cancel={toggleConfirmBox}
          proceed={closeFormAndRedirect}
        />
      </div>
    </>
  );
}

export default ClientInvoiceList;
