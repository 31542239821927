import React from "react";
import { FormGroup, Input } from "reactstrap";
import "./FormField.css";

class FormFieldComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      val: props.val,
    };
  }

  onChange(event) {
    this.setState({
      val: event.target.value,
    });
    this.props.onChangeFormField(this.props.name, event.target.value);
  }

  render() {
    return (
      <FormGroup>
        {this.props.isPersonAliasNameField && this.props.isPersonAliasNameField === true && (
          <Input
            disabled={this.props.disabledTextFields}
            className={
              this.props.customClassName
                ? "customInpputBox singleBorderInput"
                : "customInpputBox bg-color-input disabledInput"
            }
            type="text"
            name={this.props.name}
            id={this.props.id}
            placeholder={this.props.placeholder}
            value={this.props.val}
            onChange={(event) => this.onChange(event)}
            {...this.props}
          />
        )}
        {!this.props.isPersonAliasNameField && (
          <Input
            disabled={this.props.disabledTextFields}
            style={{ cursor: "notAllowed" }}
            className={
              this.props.customClassName
                ? "customInpputBox singleBorderInput"
                : "customInpputBox bg-color-input"
            }
            type="text"
            name={this.props.name}
            id={this.props.id}
            placeholder={this.props.placeholder}
            value={this.props.val}
            onChange={(event) => this.onChange(event)}
            {...this.props}
          />
        )}
      </FormGroup>
    );
  }
}

export default FormFieldComponent;
