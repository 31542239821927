module.exports = {
  customNumberFormat: function (number, isDecimal) {
    try {
      if (number) {
        let newNumber = parseFloat(number);
        if (isDecimal) {
          return newNumber.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
        }
        // THis code for no decimal point
        else {
          if (sessionStorage.getItem("USER_LANGUAGE") === "en") {
            return newNumber.toLocaleString("en-EN");
          } else if (sessionStorage.getItem("USER_LANGUAGE") === "ger") {
            return newNumber.toLocaleString("de-DE");
          }
        }
      }
      return 0;
    } catch (err) {
      return 0;
    }
  },

  checkPermission: function (ModuleName, action) {
    try {
      let permission = JSON.parse(sessionStorage.getItem("permission"));
      //let moduleIndex = permission.findIndex(element=>element.ModuleName=== ModuleName)
      // if(moduleIndex !==-1){
      if (permission.Permission.project1[0].permission.includes(action)) {
        return true;
      } else {
        return false;
      }
      // }else{
      // 	return false
      // }
    } catch (err) {
      return false;
    }
  },
};
