import React, { Fragment } from "react";

import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import { backCircle } from "../../../Assets/Icons";

class DisclaimerComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      userLanguage: "en",
      isBackEnable: true,
    };
    this.callResize = this.callResize.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.length > 0) {
      this.setState({
        isBackEnable: true,
      });
    } else {
      this.setState({
        isBackEnable: false,
      });
    }
    window.scrollTo(0, 0);
    this.getUserLanguage();
    window.addEventListener("resize", this.callResize);
  }

  callResize() {
    this.setState({
      height: window.innerHeight,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.callResize);
  }
  goBack() {
    this.props.history.goBack();
  }

  componentDidUpdate() {
    this.getUserLanguage();
  }

  async getUserLanguage() {
    let userLanguage = await sessionStorage.getItem("USER_LANGUAGE");
    if (this.state.userLanguage !== userLanguage)
      this.setState({
        userLanguage: userLanguage,
      });
  }

  render() {
    const footerTermsAndContainerHeight = {
      minHeight: this.state.height + "px",
    };
    return (
      <Fragment>
        {this.state.userLanguage !== "ger" && (
          <div className="row m-0">
            <div className="col-md-1"></div>

            <div style={footerTermsAndContainerHeight} className="col-md-10 m-t-20  m-b-20 p-l-0">
              <div className="row m-0">
                <div className=" col-md-8 header-credit-page p-l-1-p m-b-0 ">{"Disclaimer"} </div>
                <div className="col-md-4 p-0 float-right m-t-7">
                  {this.state.isBackEnable && (
                    <ButtonPrimaryWithIcon
                      iconName={backCircle}
                      onClick={this.goBack.bind(this)}
                      ariaLabel={"Back"}
                    >
                      {"Back"}
                    </ButtonPrimaryWithIcon>
                  )}
                </div>
              </div>
              <div>
                <p
                  className="t-a-j f-t-s-14 p-l-1-p"
                  style={{
                    boxSizing: "border-box",
                    padding: "0px",
                    margin: "7px 0px 35px",
                    border: "0px",
                    verticalAlign: "baseline",
                    fontSize: "1.125rem",
                    lineHeight: "1.7",
                    fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                    color: "rgb(45, 45, 45)",
                    fontStyle: "normal",
                    fontVariantLigatures: "normal",
                    fontVariantCaps: "normal",
                    fontWeight: 400,
                    letterSpacing: "normal",
                    orphans: 2,
                    textAlign: "start",
                    textIndent: "0px",
                    textTransform: "none",
                    whiteSpace: "normal",
                    widows: 2,
                    wordSpacing: "0px",
                    WebkitTextStrokeWidth: "0px",
                    textDecorationStyle: "initial",
                    textDecorationColor: "initial",
                  }}
                >
                  The information contained in this Website is for general guidance on matters of interest,
                  and intended for the personal use of the reader only. PricewaterhouseCoopers GmbH
                  Wirtschaftsprüfungsgesellschaft ("PwC") assumes liability for the content of its websites in
                  accordance with legal provisions. The information is selected with due care. We have made
                  every attempt to ensure that the information contained in this Site has been obtained and
                  arranged with due care.
                </p>
                <p
                  className="t-a-j f-t-s-14 p-l-1-p"
                  style={{
                    boxSizing: "border-box",
                    padding: "0px",
                    margin: "-8px 0px 35px",
                    border: "0px",
                    verticalAlign: "baseline",
                    fontSize: "1.125rem",
                    lineHeight: "1.7",
                    fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                    color: "rgb(45, 45, 45)",
                    fontStyle: "normal",
                    fontVariantLigatures: "normal",
                    fontVariantCaps: "normal",
                    fontWeight: 400,
                    letterSpacing: "normal",
                    orphans: 2,
                    textAlign: "start",
                    textIndent: "0px",
                    textTransform: "none",
                    whiteSpace: "normal",
                    widows: 2,
                    wordSpacing: "0px",
                    WebkitTextStrokeWidth: "0px",
                    textDecorationStyle: "initial",
                    textDecorationColor: "initial",
                  }}
                >
                  PwC accepts no liability for any actions taken as response hereto. The information is
                  provided on the understanding that the authors and publishers are not herein engaged in
                  rendering legal, accounting, tax or other professional advice or services. As such, it
                  should not be used as a substitute for consultation. PwC reserves the right to change or
                  update the information, products or services offered on this website at any time without
                  prior notice.
                </p>
                <p
                  className="t-a-j f-t-s-14 p-l-1-p"
                  style={{
                    boxSizing: "border-box",
                    padding: "0px",
                    margin: "-8px 0px 35px",
                    border: "0px",
                    verticalAlign: "baseline",
                    fontSize: "1.125rem",
                    lineHeight: "1.7",
                    fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                    color: "rgb(45, 45, 45)",
                    fontStyle: "normal",
                    fontVariantLigatures: "normal",
                    fontVariantCaps: "normal",
                    fontWeight: 400,
                    letterSpacing: "normal",
                    orphans: 2,
                    textAlign: "start",
                    textIndent: "0px",
                    textTransform: "none",
                    whiteSpace: "normal",
                    widows: 2,
                    wordSpacing: "0px",
                    WebkitTextStrokeWidth: "0px",
                    textDecorationStyle: "initial",
                    textDecorationColor: "initial",
                  }}
                >
                  Neither the information in this Website nor any further informational material sent to you
                  on request shall be deemed to establish a contractual relationship between PwC and yourself.
                </p>
                <p
                  className="t-a-j f-t-s-14 p-l-1-p"
                  style={{
                    boxSizing: "border-box",
                    padding: "0px",
                    margin: "-8px 0px 35px",
                    border: "0px",
                    verticalAlign: "baseline",
                    fontSize: "1.125rem",
                    lineHeight: "1.7",
                    fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                    color: "rgb(45, 45, 45)",
                    fontStyle: "normal",
                    fontVariantLigatures: "normal",
                    fontVariantCaps: "normal",
                    fontWeight: 400,
                    letterSpacing: "normal",
                    orphans: 2,
                    textAlign: "start",
                    textIndent: "0px",
                    textTransform: "none",
                    whiteSpace: "normal",
                    widows: 2,
                    wordSpacing: "0px",
                    WebkitTextStrokeWidth: "0px",
                    textDecorationStyle: "initial",
                    textDecorationColor: "initial",
                  }}
                >
                  This website contains links to other websites maintained by third parties. &nbsp;Such
                  third-party websites are not necessarily presented in a frame on this website. PwC has no
                  control or influence over the content of such websites of third parties. The information,
                  commodities and services provided on such sites have neither been checked nor approved by
                  PwC in any way. Also, PwC does not issue any advertisement on such third-party websites.
                  References and links to third-party websites do not mean that PwC adopts the content behind
                  the reference or link as its own. Therefore, PwC does not assume, for whatever legal reason,
                  any responsibility for the content of the websites of third parties.
                </p>
                <p
                  className="t-a-j f-t-s-14 p-l-1-p"
                  style={{
                    boxSizing: "border-box",
                    padding: "0px",
                    margin: "-8px 0px 35px",
                    border: "0px",
                    verticalAlign: "baseline",
                    fontSize: "1.125rem",
                    lineHeight: "1.7",
                    fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                    color: "rgb(45, 45, 45)",
                    fontStyle: "normal",
                    fontVariantLigatures: "normal",
                    fontVariantCaps: "normal",
                    fontWeight: 400,
                    letterSpacing: "normal",
                    orphans: 2,
                    textAlign: "start",
                    textIndent: "0px",
                    textTransform: "none",
                    whiteSpace: "normal",
                    widows: 2,
                    wordSpacing: "0px",
                    WebkitTextStrokeWidth: "0px",
                    textDecorationStyle: "initial",
                    textDecorationColor: "initial",
                  }}
                >
                  Use of the Internet is at the user's own risk. In particular, PwC is not liable for the
                  technical failure of the Internet or access to the Internet.
                </p>
                <p
                  className="t-a-j f-t-s-14 p-l-1-p"
                  style={{
                    boxSizing: "border-box",
                    padding: "0px",
                    margin: "-8px 0px 0px",
                    border: "0px",
                    verticalAlign: "baseline",
                    fontSize: "1.125rem",
                    lineHeight: "1.7",
                    fontFamily: '"PwC Helvetica Neue", Arial, sans-serif',
                    color: "rgb(45, 45, 45)",
                    fontStyle: "normal",
                    fontVariantLigatures: "normal",
                    fontVariantCaps: "normal",
                    fontWeight: 400,
                    letterSpacing: "normal",
                    orphans: 2,
                    textAlign: "start",
                    textIndent: "0px",
                    textTransform: "none",
                    whiteSpace: "normal",
                    widows: 2,
                    wordSpacing: "0px",
                    WebkitTextStrokeWidth: "0px",
                    textDecorationStyle: "initial",
                    textDecorationColor: "initial",
                  }}
                >
                  Any relationship arising out of this information shall be governed exclusively by the laws
                  of Germany. The exclusive place of jurisdiction for any action or other legal proceedings
                  against PwC arising out of, or in connection with, the information contained in this Site
                  shall be Germany.
                </p>
              </div>
            </div>
          </div>
        )}
        {this.state.userLanguage === "ger" && (
          <div className="row m-0">
            <div className="col-md-1"></div>

            <div style={footerTermsAndContainerHeight} className="col-md-10 m-t-20  m-b-20 p-l-0">
              <div className="row m-0">
                <div className=" col-md-8 header-credit-page p-l-1-p m-b-0 ">{"Disclaimer"} </div>
                <div className="col-md-4 p-0 float-right m-t-7">
                  {this.state.isBackEnable && (
                    <ButtonPrimaryWithIcon
                      onClick={this.goBack.bind(this)}
                      iconName={backCircle}
                      ariaLabel={"Zurück"}
                    >
                      {"Zurück"}
                    </ButtonPrimaryWithIcon>
                  )}
                </div>
              </div>
              <div>
                <p className="t-a-j f-t-s-14 p-l-1-p" style={{ marginTop: "7px" }}>
                  Die Informationen auf dieser Website sind allgemeiner Art und dienen lediglich dazu, die
                  PricewaterhouseCoopers GmbH Wirtschaftsprüfungsgesellschaft („PwC“) dem interessierten
                  Internetnutzer vorzustellen. PwC übernimmt die Haftung für die Inhalte seiner Webseiten
                  gemäß den gesetzlichen Bestimmungen. Die Zusammenstellung der Informationen erfolgt mit der
                  gebotenen Sorgfalt.
                </p>
                <p className="t-a-j f-t-s-14 p-l-1-p">
                  <br />
                </p>
                <p className="t-a-j f-t-s-14 p-l-1-p">
                  Für Entscheidungen, die der Verwender auf Grund der vorgenannten Informationen trifft,
                  übernehmen wir keine Verantwortung. Wir weisen darauf hin, dass der vorliegende Inhalt weder
                  eine individuelle rechtliche, buchführungstechnische, steuerliche noch eine sonstige
                  fachliche Auskunft oder Empfehlung darstellt und nicht geeignet ist, eine individuelle
                  Beratung durch fachkundige Personen unter Berücksichtigung der konkreten Umstände des
                  Einzelfalles zu ersetzen. PwC behält sich das Recht vor, die auf dieser Website angebotenen
                  Informationen, Produkte oder Dienstleistungen ohne gesonderte Ankündigung jederzeit zu
                  verändern oder zu aktualisieren.
                </p>
                <p className="t-a-j f-t-s-14 p-l-1-p">
                  <br />
                </p>
                <p className="t-a-j f-t-s-14 p-l-1-p">
                  Diese Website enthält Verweise (Links) auf Websites, die von Dritten unterhalten werden.
                  Derartige Websites Dritter werden nicht notwendigerweise in einem Frame auf dieser Website
                  präsentiert. PwC hat keinerlei Kontrolle oder Einfluss über die hinter dem Link liegenden
                  Inhalte der Websites Dritter. Verweise und Links auf Websites Dritter bedeuten nicht, dass
                  sich PwC die hinter dem Verweis oder Link liegenden Inhalte zu eigen macht. Für
                  rechtswidrige, fehlerhafte oder unvollständige Inhalte und für Schäden, die aufgrund der
                  Nutzung von einem hinter dem Link liegenden Inhalt verursacht worden sind, haftet PwC daher
                  nicht.
                </p>
                <p className="t-a-j f-t-s-14 p-l-1-p">
                  <br />
                </p>
                <p className="t-a-j f-t-s-14 p-l-1-p">
                  Die Nutzung des Internets erfolgt auf eigene Gefahr des Nutzers. PwC haftet vor allem nicht
                  für den technisch bedingten Ausfall des Internets bzw. des Zugangs zum Internet.
                </p>
                <p className="t-a-j f-t-s-14 p-l-1-p">
                  <br />
                </p>
                <p className="t-a-j f-t-s-14 p-l-1-p">
                  Für gegebenenfalls bestehende oder künftig entstehende Rechtsverhältnisse ist ausschließlich
                  deutsches Recht anwendbar und sind nur deutsche Gerichte zuständig.
                </p>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default DisclaimerComponent;
