/* eslint-disable no-mixed-spaces-and-tabs */
import { Panel, Tooltip, Tag } from "appkit-react";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { withRouter } from "react-router-dom";

import RequestDetails from "./RequestDetails";
import ButtonIcon from "../../../../Assets/Components/ButtonIcon/ButtonIcon";
import { infoIcon } from "../../../../Assets/Icons";
import downloadImg from "../../../../Assets/Icons/download-light-outline.svg";
import edit from "../../../../Assets/Icons/pencil-outline.svg";
import RequestFormService from "../../../../Services/GI/RequestFormServices";
import { getDisplayDate } from "../../../../Services/Shared/Handlers";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";

import "../../../../Assets/Css/GI/RequestList/ViewRequest.css";

// VIEW REQUEST COMPONENT
function ViewRequest({ history, Item, index, onEdit }) {
  var polyglot = polyglotLoader();
  const [showInfo, setShowInfo] = useState(false);
  // VIEW REQUEST NAME ON HOVER
  const TooltipRequestName = (Item) => {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#000",
                  textTransform: "capitalize",
                }}
              >
                {Item.TargetName}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  // VIEW REQUEST NAME ON HOVER
  const TooltipRequestID = (Item) => {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#000",
                  textTransform: "capitalize",
                }}
              >
                {Item.ID}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  // TOGGLE WORKFLOW INFO
  const toggleInfo = () => {
    setShowInfo((prev) => !prev);
  };

  // DOWNLOAD  FILE ON CLICK
  const downloadFilePath = async (filePath, fileName) => {
    try {
      // API TO DOWNLOAD  FILE
      const downloadResponse = await RequestFormService.get().downloadRequestFile(fileName, filePath);
      if (downloadResponse.CODE) {
        const buffer = Buffer.from(downloadResponse.file.buffer);
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", downloadResponse.file.fileName);
        document.body.appendChild(link);
        link.click();
        displayToastMessage(downloadResponse.MSG, "success");
        document.body.removeChild(link);
      } else {
        if (downloadResponse.MSG.errno === -4058) {
          displayToastMessage(`${polyglot.t("File not found")}!!`, "error");
        } else {
          displayToastMessage(downloadResponse.MSG, "error");
        }
      }
    } catch (error) {
      if (
        (error.message = "First argument must be a string, Buffer, ArrayBuffer, Array, or array-like object.")
      ) {
      } else {
        displayToastMessage(error.message, "error");
      }
    }
  };

  // STATUS TAG
  const statusTag = () => {
    if (Item.Status === polyglot.t("Client requested")) {
      return <Tag className="request_status_client">{polyglot.t("Client requested")}</Tag>;
    }
    if (Item.Status === polyglot.t("Processing")) {
      return <Tag className="request_status_processing">{polyglot.t("Processing")}</Tag>;
    }
    if (Item.Status === polyglot.t("Information requested")) {
      return <Tag className="request_status_client">{polyglot.t("Information requested")}</Tag>;
    }
    if (Item.Status === polyglot.t("Report available")) {
      return <Tag className="df-pfa">{polyglot.t("Report available")}</Tag>;
    }
  };
  // ACTIONS
  const actions = () => {
    if (Item.Status === polyglot.t("Client requested") || Item.Status === polyglot.t("Processing")) {
      return (
        <>
          <ButtonIcon
            alt="More info"
            iconName={infoIcon}
            title={polyglot.t("More info")}
            onClick={toggleInfo}
            ariaLabel={polyglot.t("More info")}
          />
        </>
      );
    }
    if (Item.Status === polyglot.t("Report available")) {
      return (
        <>
          <ButtonIcon
            alt="More info"
            iconName={infoIcon}
            title={polyglot.t("More info")}
            onClick={toggleInfo}
            ariaLabel={polyglot.t("More info")}
          />
          <ButtonIcon
            alt="download"
            iconImg={downloadImg}
            imgWidth={16}
            size={16}
            onClick={() => downloadFilePath(Item.FinalReportPath, Item.FinalReportName)}
            ariaLabel={polyglot.t("Download")}
          />
        </>
      );
    }
    if (Item.Status === polyglot.t("Information requested")) {
      return (
        <>
          <ButtonIcon
            alt="More info"
            iconName={infoIcon}
            title={polyglot.t("More info")}
            onClick={toggleInfo}
            ariaLabel={polyglot.t("More info")}
          />
          <ButtonIcon
            onClick={() => onEdit(Item.ID)}
            iconImg={edit}
            imgWidth={16}
            alt="edit"
            ariaLabel={polyglot.t("Edit project")}
          />
        </>
      );
    }
  };

  return (
    <div>
      {
        <div className="row view-center mr-0">
          <div className="col-md-12 p-0">
            <Panel className="a-panel-project">
              <div className="a-text-heading  a-mb-10">
                <div className="row">
                  <div className="col-md-1 ">
                    <Tooltip content={TooltipRequestID(Item)} placement="top">
                      <div className="project-title-overflow f-acc view-center-name project-name viewRequest">
                        {Item.ID}
                      </div>
                    </Tooltip>
                  </div>

                  <div className="col-md-2 m-auto">
                    <div>{getDisplayDate(Item.CreatedOn)}</div>
                  </div>
                  <div className="col-md-4 m-auto">
                    <Tooltip content={TooltipRequestName(Item)} placement="top">
                      <div className="project-title-overflow f-acc view-center-name project-name">
                        {Item.TargetName}
                      </div>
                    </Tooltip>
                  </div>
                  <div className="col-md-3 m-auto">
                    <div className="view-center-imssage">{statusTag()}</div>
                  </div>
                  <div className="col-md-2 m-auto">
                    <div className="row ">{actions()}</div>
                  </div>
                </div>
              </div>
            </Panel>
            {showInfo && <RequestDetails show={showInfo} item={Item} toggleInfo={toggleInfo} />}
          </div>
        </div>
      }
    </div>
  );
}
ViewRequest.propTypes = {
  Item: PropTypes.object,
  index: PropTypes.number,
  history: PropTypes.object,
};

export default withRouter(ViewRequest);
