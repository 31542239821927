import React, { useState, useEffect } from "react";

import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/GRC/loading-spin.svg";
import RecipientService from "../../../Services/GRC/RecipientService";
import { useHttpRemoteClient } from "../../../Services/GRC/http-hook-remote";
import EmptyPanel from "../Shared/EmptyPanel";
import ErrorPanel from "../Shared/ErrorPanel";
import "./Pages/index.css";
import { GetBreadcrumHTMLByString } from "../handlers";

const RecipientCategoryText = ({ id }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpRemoteClient();
  const [categoryData, setcategoryData] = useState({});

  useEffect(() => {
    const getSelectedQuestionaries = async () => {
      try {
        const responseData = await sendRequest(RecipientService.get().getTextDataPerfAssessmentURL(id));
        setcategoryData(responseData);
      } catch (err) {
        console.log(err);
      }
    };
    if (id > 0) {
      getSelectedQuestionaries();
    }
  }, [sendRequest, id]);
  return (
    <div className="row justify-content-start p-0">
      {error && <ErrorPanel error={error} onClear={clearError} />}
      {isLoading && (
        <div>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      )}

      {categoryData.length === 0 && <EmptyPanel massage={"No data found."} />}
      {!isLoading && categoryData && (
        <div className="col-md-12 p-2">
          <GetBreadcrumHTMLByString breadcrumString={categoryData.breadcrum} />
          <hr className="ruler" style={{ marginLeft: "25px" }} />
          {/*  <h5 className="text-custome text-custome-header">{categoryData.name}</h5> */}

          <div className="text-custome">{categoryData.description}</div>
        </div>
      )}
    </div>
  );
};
export default RecipientCategoryText;
