import React from "react";

import { polyglotLoader } from "../../../Services/Shared/Translate";

// THIS COMPONENT IS USED TO DISPLAY SUCCESSFULL LOGOUT BY THE USER.
const PerfLogout = () => {
  const polyglot = polyglotLoader();
  return (
    <>
      <div className="row mt-5" style={{ marginRight: "0px" }}>
        <div className="col-md-3"></div>
        <div className="col-md-6 " style={{ textAlign: "center" }}>
          <span className="appkiticon icon-confirmed-outline no-access-icon" />
          <p className="noaccess_header mt-3 ">{polyglot.t("You have been successfully logged out")}!!</p>
        </div>
        <div className="col-md-3"></div>
      </div>
    </>
  );
};

export default PerfLogout;
