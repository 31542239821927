import axios from "axios";
import HttpClient from "../Shared/HttpClient";
axios.defaults.baseURL = process.env.REACT_APP_SERVER_HOST;
axios.defaults.headers.post["Content-Type"] = "application/json";

export default class AnalysisService {
  getAnalysisCustodianRecords = async (projectId) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/AnalysisInfo/${projectId}`);
    return res;
  };

  getKeyWords = async (id) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getKeyWords/${id}`);
    return res;
  };

  AnalysisDetails = async (id, projectId) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getAnalysisDetails/${id}/${projectId}`);
    return res;
  };

  AnalysisDetailsById = async (analysisId) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getAnalysisDetailsById/${analysisId}`);
    return res;
  };

  AnalysisSummary = async (id) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getAnalysisSummary/${id}`);
    return res;
  };

  saveNewAnalysis = async (processingData, permissionData) => {
    const client = new HttpClient();
    const res = await client.dfedPost("/saveNewAnalysis", {
      body: { processingData: processingData, permission: permissionData },
    });
    return res;
  };

  saveNewRunThrough = async (processingData) => {
    const client = new HttpClient();
    const res = await client.dfedPost("/saveNewRunThrough", {
      body: {
        processingData: processingData,
      },
    });
    return res;
  };
  getTemplate = async (path) => {
    const client = new HttpClient();
    const res = await client.dfedPost("/downloadKeywordDocument", {
      body: {
        filePath: path,
      },
    });
    return res;
  };

  fromReviewData = async (projectId, AnalysisID, RunThroughId) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/fromReviewData/${projectId}/${AnalysisID}/${RunThroughId}`);
    return res;
  };

  fromReviewData_right = async (projectId, AnalysisID, RunThroughId) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/fromReviewDataRight/${projectId}/${AnalysisID}/${RunThroughId}`);
    return res;
  };

  getAnalysisCustodianInfo = async (projectId) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/AnalysisCustodianInfo/${projectId}`);
    return res;
  };
}
