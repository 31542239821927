import "./PanelWithRadio.css";
import { Radio } from "appkit-react";
import PropTypes from "prop-types";
import React from "react";

import { polyglotLoader } from "../../../../Services/Shared/Translate";

// COMPONENT WHICH HAS A CARD WITH RADIO BUTTON
const PanelWithRadioData = (props) => {
  const { item, handleCheck } = props;
  var polyglot = polyglotLoader();
  // CHECK IF RADIO IS SELECTED
  const checkIsSelected = () => {
    if (item.is_uploaded != 1) {
      return true;
    }
    return false;
  };
  // HANDLE CHANGE ON SELECTION OF RADIO BUTTON
  const handleChange = (event) => {
    if (event.target.type !== "radio") {
      handleCheck(Number(!item.is_uploaded), "");
    }
  };

  return (
    <>
      <div onClick={handleChange}>
        <div className="row">
          <div className="col-md-10">
            <h6 className="subtitle_two_title_qb choose-new-proj-text">
              {polyglot.t("Use existing workflow data")}
            </h6>
          </div>
          <div className="col-md-2 text-align-right">
            <Radio className="radio-right-padding-0" checked={checkIsSelected()} />
          </div>
        </div>
      </div>
    </>
  );
};

PanelWithRadioData.propTypes = {
  item: PropTypes.object,
  handleCheck: PropTypes.func,
};

export default PanelWithRadioData;
