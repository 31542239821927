import React, { Component } from "react";
import $ from "jquery";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import { DataContext } from "../../../../Services/Shared/Store.js";
import {
  Select,
  SelectOption,
  Accordions,
  AccordionItem,
  AccordionItemHeader,
  AccordionItemBody,
} from "appkit-react";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "antd/dist/antd.css";
import displayToastMessage from "../../../../Components/QueenBase/Root/displayToastMessage";
import {
  editAnalysisRunMapService,
  uploadAnalysisGridFile,
  getPwcAdminAnalysisUploadedFile,
} from "../../../../Services/DFED/AdminService";
import XLSX from "xlsx";
import fastForwardImg from "../../../../Assets/Images/DFED/icon-fast-forward-red.svg";
import CustomModal from "../../../../Assets/Components/Modal";
import { getAnslysisKeywordDetails } from "../../../../Services/DFED/AdminService";
import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import AnalysisService from "../../../../Services/DFED/AnalysisService";
import { getDisplayDate } from "../../../../Services/Shared/Handlers";

const analysisService = new AnalysisService();

$.DataTable = require("datatables.net-dt");
$.fn.DataTable.ext.errMode = "none";
require("datatables.net-buttons-dt");
const jzip = require("jszip");
require("datatables.net-buttons/js/buttons.html5.min");
window.JSZip = jzip;

const STATUS_NOT_STARTED = "1";
const STATUS_IN_PROGRESS = "2";
const STATUS_COMPLETED = "3";
// ADMIN ANALYSIS COMPONENT RESPONSIBLE FOR HANDLING ALL ADMIN ANALYSIS RELATED FUNCTIONALITY====================
class AnalysisGrid extends Component {
  polyglot = polyglotLoader();
  constructor(props) {
    super(props);
    this.state = {
      analysisGridData: [],
      visible: false,
      visibleViewModal: false,
      tabValue: "1",
      selectedEditId: "",
      editedPrepareKeywordStatus: null,
      editedRunningKeywordStatus: null,
      editedReportGenStatus: null,
      editedDocCount: "",
      editedReviewHrs: "",
      editedTotalHitCnt: "",
      fileDetails: "",
      individualAnalysisData: null,
      expandCollapseKey: [],
      selectedDataRunThruId: "",
      selectedDataAnalysisId: "",
      selectedAnalysisName: "",
      isFileValidate: null,
      isCorrectFile: false,
      pipeLineID: null,
      selectedProjectId: this.props.selectedProjectId,
      analysisName: "",
      originalUploadedFileName: "",
      isAnalysisAlreadyInUse: null,
    };
    this.expandCollpaseHandler = this.expandCollpaseHandler.bind(this);
  }
  static contextType = DataContext;
  // FETCHING DATA FOR ANALYSIS TABLE IN ADMIN TAB=========

  dataForAnalysisGrid = async () => {
    let isInterruptProcess = false;
    if (isInterruptProcess === false) {
      await getAnslysisKeywordDetails(parseInt(this.state.selectedProjectId))
        .then((anaKeyData) => {
          if (anaKeyData && anaKeyData.data) {
            if (anaKeyData.data && anaKeyData.data.length > 0) {
              this.setState(
                {
                  analysisGridData: anaKeyData.data,
                },
                () => {
                  this.dataStructureForAnalysisGrid(this);
                  this.dommanipulation(this);
                },
              );
            } else if (anaKeyData.data && anaKeyData.data.length === 0) {
              this.setState(
                {
                  analysisGridData: [],
                },
                () => {
                  this.dataStructureForAnalysisGrid(this);
                  this.dommanipulation(this);
                },
              );
            }
          }
        })
        .catch((err) => {
          displayToastMessage(this.polyglot.t("Something went wrong!"), "error");
        });
    }
  };

  // LIFE CYCLE METHOD FOR GETTING ALL ADMIN ANALYSIS DATA===================
  componentDidMount() {
    this.dataForAnalysisGrid(this);
  }

  getAnalysisUploadedFile = async () => {
    const uploadedFileInfo = await getPwcAdminAnalysisUploadedFile(this.state.selectedEditId);
    const isAnalysisAlreadyInUse = uploadedFileInfo?.data[0].isAnalysisAlreadyInUse;
    let originalUploadedFileName = "";

    if (uploadedFileInfo?.data[0].TOTAL_HIT_COUNT || uploadedFileInfo?.data[0].FILE_LOCATION) {
      const splitedFileLocation = uploadedFileInfo.data[0].FILE_LOCATION.split("/");
      const fileName = splitedFileLocation[splitedFileLocation.length - 1];
      originalUploadedFileName = fileName.split("_").slice(1).join("_");
    }

    this.setState({ originalUploadedFileName, isAnalysisAlreadyInUse });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.visible !== this.state.visible) {
      this.setState({ originalUploadedFileName: "" });
      this.getAnalysisUploadedFile(this);
    }
  }
  // KEYWORD SELECT BOX ON CHANGE=================
  prepareKeywordOnChng = (value) => {
    const analysisStatus = { editedPrepareKeywordStatus: value };
    if (value !== STATUS_COMPLETED) {
      analysisStatus.editedRunningKeywordStatus = STATUS_NOT_STARTED;
      analysisStatus.editedReportGenStatus = STATUS_NOT_STARTED;
    }
    this.setState(analysisStatus);
  };
  // RUNNING KEYWORD ON CHANGE EVENT====================
  runningKeywrdOnChng = (value) => {
    const analysisStatus = { editedRunningKeywordStatus: value };

    if (value !== STATUS_COMPLETED) {
      analysisStatus.editedReportGenStatus = STATUS_NOT_STARTED;
    }

    this.setState(analysisStatus);
  };
  // REPORT GENERATION ON CHANGE EVENT HANDLER==================
  reportGenStatusOnChng = (value) => {
    const analysisStatus = { editedReportGenStatus: value };

    if (value !== STATUS_COMPLETED) {
      analysisStatus.fileDetails = "";
      analysisStatus.isCorrectFile = false;
    }

    this.setState(analysisStatus);
  };

  // FILE UPLOAD VALIDATION =========
  analysisGridUploadHandler = (event) => {
    const excelFile = event.target.files[0];
    const isValidFileType = this.validateFileType(excelFile);
    const isValidFileStructure = this.validateFileStructure(excelFile);

    if (isValidFileType && isValidFileStructure) {
      const fileReader = new FileReader();
      fileReader.onload = (e) => {
        const binaryArray = e.target.result;
        const analysis1WebSheetData = this.openExcelSheet(binaryArray, excelFile, "Analysis 1 (web)");

        let totalHitCount;
        let reviewHrs;

        analysis1WebSheetData.map((data) => {
          const sheetValuesArr = Object.values(data);
          if (sheetValuesArr.includes("Totals")) {
            totalHitCount = sheetValuesArr[4];
            reviewHrs = Math.ceil(sheetValuesArr[4] / 50);

            this.setState({
              editedTotalHitCnt: totalHitCount,
              editedReviewHrs: reviewHrs,
            });
          }
        });

        const analysis1SheetData = this.openExcelSheet(binaryArray, excelFile, "Analysis 1");

        let documentCount = 0;
        analysis1SheetData.map((data) => {
          const sheetValuesArr = Object.values(data);
          if (
            sheetValuesArr.includes("Total Unique top-level documents (material)") ||
            sheetValuesArr.includes(
              "Total non-unique attachments/descendants for unique top-level documents  (material)",
            )
          ) {
            documentCount += sheetValuesArr[1];

            this.setState({
              editedDocCount: documentCount,
            });
          }
        });
      };
      fileReader.readAsBinaryString(excelFile);
    }
  };

  openExcelSheet = (data, excelFile, sheetName) => {
    var workbook = XLSX.read(data, { type: "binary" });
    const sheetNumber = this.validateSheetName(excelFile, workbook, sheetName);
    var selectedSheet = workbook.SheetNames[sheetNumber];
    let rowList = XLSX.utils.sheet_to_row_object_array(workbook.Sheets[selectedSheet]);
    return rowList;
  };

  // VALIDATING FILE TYPE ===================
  validateFileType = (excelFile) => {
    if (!excelFile) {
      $("#anaKeyWedExcelTypeErr").text("");
      $("#anaKeyWedExcelTypeSucessfull").text("");
      this.setState({ isCorrectFile: false });
      return false;
    }
    let allowType2 = "vnd.openxmlformats-officedocument.spreadsheetml.sheet";
    let errMsg = "";
    let tempType = excelFile.type.split("/");

    $("#anaKeyWedExcelTypeErr").text(errMsg);
    if (tempType[1] !== allowType2) {
      errMsg = "  " + excelFile.name + this.polyglot.t(" is not valid xlsx file type.");
      $("#anaKeyWedExcelTypeErr").text(errMsg);
      this.setState({ isFileValidate: false, isCorrectFile: false });
      return false;
    }
    return true;
  };

  validateFileStructure = (excelFile) => {
    let errMsg = "";
    const polyglot = polyglotLoader();

    var fileReader = new FileReader();
    fileReader.onload = (e) => {
      const binaryArray = e.target.result;
      const isValidAnalysis1WebSheet = this.validateAnalysis1WebSheet(binaryArray, excelFile);
      const isValidAnalysis1Sheet = this.validateAnalysis1Sheet(binaryArray, excelFile);

      if (
        isValidAnalysis1WebSheet.headers &&
        isValidAnalysis1WebSheet.values &&
        isValidAnalysis1Sheet.headers &&
        isValidAnalysis1Sheet.values
      ) {
        this.setState(
          {
            fileDetails: excelFile,
          },
          () => {
            $("#anaKeyWedExcelTypeSucessfull").text(polyglot.t("File Validate Sucessfully."));
            setTimeout(function () {
              $("#anaKeyWedExcelTypeSucessfull").text("");
            }, 2500);
          },
        );
        this.setState({
          isCorrectFile: true,
        });
      } else if (!isValidAnalysis1WebSheet.headers) {
        errMsg =
          "  " + excelFile.name + polyglot.t(" does not have desired format on 'Analysis 1 (web)' sheet.");
        $("#anaKeyWedExcelTypeErr").text(errMsg);
        this.setState({
          isCorrectFile: false,
        });
      } else if (!isValidAnalysis1Sheet.headers) {
        errMsg = "  " + excelFile.name + polyglot.t(" does not have desired format on 'Analysis 1' sheet.");
        $("#anaKeyWedExcelTypeErr").text(errMsg);
        this.setState({
          isCorrectFile: false,
        });
      } else if (!isValidAnalysis1WebSheet.values) {
        errMsg =
          "  " + excelFile.name + polyglot.t(" does not have needed value on 'Analysis 1 (web)' sheet.");
        $("#anaKeyWedExcelTypeErr").text(errMsg);
        this.setState({
          isCorrectFile: false,
        });
      } else if (!isValidAnalysis1Sheet.values) {
        errMsg = "  " + excelFile.name + polyglot.t(" does not have needed value on 'Analysis 1' sheet.");
        $("#anaKeyWedExcelTypeErr").text(errMsg);
        this.setState({
          isCorrectFile: false,
        });
      }
    };
    fileReader.readAsBinaryString(excelFile);
    return true;
  };

  validateSheetName = (excelFile, workbook, sheetName) => {
    let errMsg = "";
    const polyglot = polyglotLoader();
    let sheetNumber = null;

    for (let i = 0; i < workbook.SheetNames.length; i++) {
      if (workbook.SheetNames[i] === sheetName) {
        sheetNumber = i;
        return sheetNumber;
      }
    }

    if (sheetNumber === null) {
      errMsg =
        "  " +
        excelFile.name +
        polyglot.t(" has not '") +
        sheetName +
        polyglot.t("' sheet. Please, check sheet names.");
      $("#anaKeyWedExcelTypeErr").text(errMsg);
      this.setState({
        isCorrectFile: false,
      });
      return false;
    }
  };

  validateAnalysis1WebSheet = (data, excelFile) => {
    const rowList = this.openExcelSheet(data, excelFile, "Analysis 1 (web)");
    const colList = Object.keys(rowList[0]);

    if (
      colList[0] === "__EMPTY" &&
      colList[1] === "Search Term Responsive documents" &&
      colList[2] === "Family Counts for search term responsive documents" &&
      colList[3] === "__EMPTY_1" &&
      colList[4] === "__EMPTY_2" &&
      rowList[0][colList[0]] === "Search Term" &&
      rowList[0][colList[1]] === "Top level documents and attachments / descendants" &&
      rowList[0][colList[2]] === "Top Level" &&
      rowList[0][colList[3]] === "Attachments / descendants (non-unique)" &&
      rowList[0][colList[4]] === "Top level + attachments / descendants"
    ) {
      let isValidValue = { headers: true, values: false };
      rowList.map((data) => {
        const sheetValuesArr = Object.values(data);
        if (sheetValuesArr.includes("Totals") && sheetValuesArr[4] > 0) {
          isValidValue = { headers: true, values: true };
        }
      });

      return isValidValue;
    } else {
      return { headers: false, values: false };
    }
  };

  validateAnalysis1Sheet = (data, excelFile) => {
    const rowList = this.openExcelSheet(data, excelFile, "Analysis 1");
    const colList = Object.keys(rowList[0]);

    if (
      colList[0] === "Report Title" &&
      rowList[0][colList[0]] === "Processing application" &&
      rowList[1][colList[0]] === "Index type" &&
      rowList[2][colList[0]] === "Case deduplication" &&
      rowList[3][colList[0]] === "Scope Criteria" &&
      rowList[4][colList[0]] === "Total Unique top-level documents (material)" &&
      rowList[5][colList[0]] ===
        "Total non-unique attachments/descendants for unique top-level documents  (material)" &&
      rowList[6][colList[0]] === "Search Scope" &&
      rowList[7][colList[0]] === "Search Criteria"
    ) {
      const areValidValues = [];
      rowList.map((data) => {
        const sheetValuesArr = Object.values(data);
        const columnName = sheetValuesArr[0];
        const columnValue = sheetValuesArr[1];
        if (
          (columnName === "Total Unique top-level documents (material)" && columnValue > 0) ||
          (columnName ===
            "Total non-unique attachments/descendants for unique top-level documents  (material)" &&
            columnValue >= 0)
        ) {
          areValidValues.push(true);
        }
      });

      if (!areValidValues.length || !(areValidValues[0] && areValidValues[1])) {
        return { headers: true, values: false };
      } else {
        return { headers: true, values: true };
      }
    } else {
      return { headers: false, values: false };
    }
  };

  dommanipulation = () => {};
  // SHOW MODAL FOR CHANGING STATUS=====================
  showModal = (
    temthis,
    id,
    dataRunThruId,
    analysisId,
    tempAnalysisNm,
    prepKeywrdStus,
    runKeyWrdStus,
    reportGenStus,
    pipeLineID,
    editedDocCount,
    editedReviewHrs,
    editedTotalHitCnt,
  ) => {
    let forId = [
      this.polyglot.t("Not Started"),
      this.polyglot.t("In Progress"),
      this.polyglot.t("Completed"),
    ];
    this.setState(
      {
        selectedEditId: id,
        selectedDataRunThruId: dataRunThruId,
        selectedDataAnalysisId: analysisId,
        selectedAnalysisName: tempAnalysisNm,
        visible: true,
        pipeLineID: pipeLineID,
        editedPrepareKeywordStatus: (forId.indexOf(prepKeywrdStus) + 1).toString(),
        editedRunningKeywordStatus: (forId.indexOf(runKeyWrdStus) + 1).toString(),
        editedReportGenStatus: (forId.indexOf(reportGenStus) + 1).toString(),
        editedDocCount: editedDocCount,
        editedReviewHrs: editedReviewHrs,
        editedTotalHitCnt: editedTotalHitCnt,
      },
      () => {},
    );
  };
  // CLOSE FUNCTIONALITY====================
  handleCancel = (e) => {
    this.setState({
      visible: false,
      fileDetails: "",
      editedDocCount: "",
      editedReviewHrs: "",
      editedTotalHitCnt: "",
    });
    if (this.fileUpload) {
      this.fileUpload.value = "";
    }
    $("#anaKeyWedExcelTypeErr").text("");
    $("#anaKeyWedExcelTypeSucessfull").text("");
  };
  // HANDLE CONFIRM FUNCTIONALITY======================
  handleConfirm = async (e) => {
    let editId = "";
    let isIntrruptEdit = false;
    const projectName = this.props.selectedProjectName
    if (this.state.selectedEditId && this.state.selectedEditId !== "") {
      editId = this.state.selectedEditId;
    } else {
      isIntrruptEdit = true;
      alert("Error No Id select for edit.");
    }
    if (isIntrruptEdit === false) {
      const formData = new FormData();
      let tempFileName = "";
      let tempFilePath = "";
      if (
        this.state.fileDetails &&
        (this.state.fileDetails.length > 0 || this.state.fileDetails !== undefined) &&
        this.state.isCorrectFile === true
      ) {
        formData.append("analysisGridFileUpload", this.state.fileDetails);
        formData["analysisGridFileUpload"] = this.state.fileDetails;
        let tempID = Date.now();
        tempFileName = tempID + "_" + this.state.fileDetails.name;
        formData.append("newFileName", tempFileName);
        formData["newFileName"] = tempFileName;
        formData.append("analysisName", this.state.selectedAnalysisName);
        formData["analysisName"] = this.state.selectedAnalysisName;
        formData.append("prjName", projectName);
        formData["prjName"] = projectName;
        tempFilePath = String(this.state.selectedAnalysisName + "/KeyHitCountReport/" + tempFileName);
        if (this.state.selectedDataRunThruId === "" || this.state.selectedDataAnalysisId === "") {
          alert("Error while getting analysis and runthrough details from data table.");
        } else {
          formData.append("runthruId", this.state.selectedDataRunThruId);
          formData["runthruId"] = this.state.selectedDataRunThruId;

          formData.append("analysisId", this.state.selectedDataAnalysisId);
          formData["analysisId"] = this.state.selectedDataAnalysisId;

          let isUploadFile = await uploadAnalysisGridFile(formData);
          if (isUploadFile === false) {
            alert("Error while uploading keyword hit count report ");
          }
        }
      }

      const file_location = projectName + "/Analysis/" + tempFilePath;
      const data = {
        PREPARE_KEYWORD_STATUS: this.state.editedPrepareKeywordStatus,
        RUNNING_KEYWORD_STATUS: this.state.editedRunningKeywordStatus,
        REPORT_GENERATED_STATUS: this.state.editedReportGenStatus,
        DOCUMENT_COUNT:
          this.state.editedReportGenStatus === STATUS_COMPLETED ? this.state.editedDocCount : null,
        REVIEW_HOURS:
          this.state.editedReportGenStatus === STATUS_COMPLETED ? this.state.editedReviewHrs : null,
        TOTAL_HIT_COUNT:
          this.state.editedReportGenStatus === STATUS_COMPLETED ? this.state.editedTotalHitCnt : null,
        FILE_LOCATION:
          this.state.editedReportGenStatus === STATUS_COMPLETED && this.state.fileDetails !== ""
            ? file_location
            : null,
      };
      const isEditArtm = await editAnalysisRunMapService(
        data,
        editId,
        this.state.pipeLineID,
        this.state.selectedDataAnalysisId,
        this.state.selectedProjectId,
      );
      if (isEditArtm && isEditArtm.hasOwnProperty("status")) {
        if (isEditArtm.status && parseInt(isEditArtm.status) === 200) {
          displayToastMessage(this.polyglot.t("Analysis status has been updated"), "success");
          this.dataForAnalysisGrid(this);
          if (this.fileUpload) {
            this.fileUpload.value = "";
          }
        }
      } else {
        alert("Error while Edit request.");
      }
      this.setState({
        visible: false,
        fileDetails: "",
        editedDocCount: "",
        editedReviewHrs: "",
        editedTotalHitCnt: "",
      });
    } else {
      alert("Error while validating your input.");
    }
    $("#anaKeyWedExcelTypeErr").text("");
    $("#anaKeyWedExcelTypeSucessfull").text("");
  };
  // SHOW MODAL FUNCTIONALITY=====================

  showViewModal = (temthis, id, analysisname) => {
    this.setState(
      {
        selectedEditId: id,
        visibleViewModal: true,
        analysisName: analysisname,
      },
      () => {
        this.getAnalysisDetailsByRunThroughId(id);
      },
    );
  };
  // GETTING ANALYSIS DETAILS BASED ON ANALYSIS ID=================
  getAnalysisDetailsByRunThroughId = async (analysisId) => {
    const analysisDetails = await analysisService.AnalysisDetailsById(analysisId);
    this.analysisDataGridFilter(analysisDetails.data);
  };
  // FILTER FUNCTIONALITY FOR ANALYSIS LIST=====================

  analysisDataGridFilter = (individualAnalysisData) => {
    this.setState({ individualAnalysisData });
  };
  // CLOSE AND CANCEL FUNCTIONALITY================

  handleViewCancel = (e) => {
    this.setState({
      visibleViewModal: false,
      individualAnalysisData: null,
    });
  };
  // EXPAND COLLAPSE HANDLER FUNCTIONALTY================

  expandCollpaseHandler(key) {
    let tempKey = key[key.length - 1];
    key = tempKey;
    this.setState({
      expandCollapseKey: tempKey,
    });
  }
  // ON TAB CHANGE FUNCTIONALITY======================

  onTabChange(e) {
    this.setState({
      tabValue: e.target.getAttribute("data-value"),
    });
  }
  // CREATING AND ARRANGING DATA FOR SHOWING IN DATA TABLE FORMAT==================
  dataStructureForAnalysisGrid = () => {
    var tempData = this.state.analysisGridData;
    var dataForTbl = [];
    tempData.forEach((ele1) => {
      let tempPreparedKeyWordStatus;
      if (
        ele1.prepareKeywordStatus === "0" ||
        ele1.prepareKeywordStatus === STATUS_NOT_STARTED ||
        ele1.prepareKeywordStatus === null
      ) {
        tempPreparedKeyWordStatus = this.polyglot.t("Not Started");
      } else if (ele1.prepareKeywordStatus === STATUS_IN_PROGRESS) {
        tempPreparedKeyWordStatus = this.polyglot.t("In Progress");
      } else {
        tempPreparedKeyWordStatus = this.polyglot.t("Completed");
      }
      let tempRunningKeywordStatus;
      if (
        ele1.runningKeywordStatus === "0" ||
        ele1.runningKeywordStatus === STATUS_NOT_STARTED ||
        ele1.runningKeywordStatus === null
      ) {
        tempRunningKeywordStatus = this.polyglot.t("Not Started");
      } else if (ele1.runningKeywordStatus === STATUS_IN_PROGRESS) {
        tempRunningKeywordStatus = this.polyglot.t("In Progress");
      } else {
        tempRunningKeywordStatus = this.polyglot.t("Completed");
      }
      let tempReportGeneratedStatus;
      if (
        ele1.reportGeneratedStatus === "0" ||
        ele1.reportGeneratedStatus === STATUS_NOT_STARTED ||
        ele1.reportGeneratedStatus === null
      ) {
        tempReportGeneratedStatus = this.polyglot.t("Not Started");
      } else if (ele1.reportGeneratedStatus === STATUS_IN_PROGRESS) {
        tempReportGeneratedStatus = this.polyglot.t("In Progress");
      } else {
        tempReportGeneratedStatus = this.polyglot.t("Completed");
      }
      let editedDocCount;
      if (ele1.DOCUMENT_COUNT === null || ele1.DOCUMENT_COUNT === "0") {
        editedDocCount = "";
      } else {
        editedDocCount = ele1.DOCUMENT_COUNT;
      }
      let editedReviewHrs;
      if (ele1.REVIEW_HOURS === null || ele1.REVIEW_HOURS === "0") {
        editedReviewHrs = "";
      } else {
        editedReviewHrs = ele1.REVIEW_HOURS;
      }
      let editedTotalHitCnt;
      if (ele1.TOTAL_HIT_COUNT === null || ele1.TOTAL_HIT_COUNT === "0") {
        editedTotalHitCnt = "";
      } else {
        editedTotalHitCnt = ele1.TOTAL_HIT_COUNT;
      }
      dataForTbl.push([
        ele1.ANALYSIS_NAME,
        // NOTE: Hidden runthrough column
        ele1.runthrough,
        ele1.keywordListName,
        tempPreparedKeyWordStatus,
        tempRunningKeywordStatus,
        tempReportGeneratedStatus,
        ele1.armId,
        ele1.analysisId,
        ele1.pipeLineId,
        ele1.REQUESTED_ON,
        editedDocCount,
        editedReviewHrs,
        editedTotalHitCnt,
      ]);
    }); //OVER : MAIN FOREACH
    this.drawAnalysisGrid(this, dataForTbl);
    //clear out Search text field
    $("#analysisGridTable_filter input").val("");
    $("#analysisGridTable_filter input").trigger("click");
  };
  drawAnalysisGrid = (temp1, dataForTbl) => {
    temp1.$el = $(temp1.el);
    var disText = "";
    var tabledt = temp1.$el.DataTable({
      lengthMenu: [5, 10, 15, 20],
      oLanguage: {
        emptyTable: `${this.polyglot.t("No data available for this project")}`,
        sLengthMenu: `${this.polyglot.t("Show")} <select class="tabFocus">
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="-1">${this.polyglot.t("All")}</option>
        </select> ${this.polyglot.t("entries")}
        `,
        sSearch: `${this.polyglot.t("Search")}`,
      },

      columns: [
        {
          title: this.polyglot.t("Analysis Name"),
          mRender: function (data, type, row) {
            if (row[8]) {
              return (
                data +
                '&nbsp&nbsp<img role="presentation" class="adminProcessingFromFastForward" src="' +
                fastForwardImg +
                '" />'
              );
            } else {
              var data1 =
                "<Tooltip title = " +
                data +
                ' placement="top"><div class="project-title-overflow" style="width:120px">' +
                data +
                "</div></Tooltip>";
              return data1;
            }
          },
        },
        // NOTE: Hidden runthrough column
        { title: this.polyglot.t("Run Through Name"), bVisible: false },
        { title: this.polyglot.t("Keyword List Name") },
        { title: this.polyglot.t("Prepare Keyword") },
        { title: this.polyglot.t("Running Keyword") },
        { bVisible: false, searchable: false },
        { bVisible: false, searchable: false },
        {
          title: this.polyglot.t("Action"),
          class: "actionSortingRemove",
          data: null,
          width: 98,
          mRender: (data, type, row) => {
            return (
              '<button aria-label="' +
              this.polyglot.t("edit") +
              '" class="appkiticon icon-edit-outline editer_edit analysisGridEditIcon" id="editer_' +
              row[6] +
              '" data-runThrId="' +
              row[6] +
              '" data-analysisId="' +
              row[7] +
              '" data-analysisName="' +
              row[0] +
              '" data-prepKeywrdStus="' +
              row[3] +
              '" data-runKeyWrdStus="' +
              row[4] +
              '" data-reportGenStus="' +
              row[5] +
              '" data-pipeLineID="' +
              row[8] +
              '" data-editedDocCount="' +
              row[10] +
              '" data-editedReviewHrs="' +
              row[11] +
              '" data-editedTotalHitCnt="' +
              row[12] +
              '"  >' +
              "</button> &nbsp; &nbsp;" +
              '<button aria-label="' +
              this.polyglot.t("view") +
              '" class="appkiticon icon-view-fill analysisGridViewIcon editer_view"' +
              'id="view_model_' +
              row[7] +
              "_" +
              row[0] +
              '"> </button>'
            );
          },
        },
        { bVisible: false, searchable: false },
        { bVisible: false, searchable: false },
        { bVisible: false, searchable: false },
        { bVisible: false, searchable: false },
        { bVisible: false, searchable: false },
        { bVisible: false, searchable: false },
      ],
      order: [[9, "desc"]],
      data: dataForTbl,
      lenghtType: "simple",
      searching: true,
      destroy: true,
      language: {
        info: "_PAGE_ of _PAGES_",
        paginate: {
          firt: "<<",
          previous: '<i_ngcontent-c10="" class="appkiticon icon-left-chevron-fill"> </i>',
          next: '<i_ngcontent-c10="" class="appkiticon icon-right-chevron-fill"> </i>',
          last: ">>",
        },
      },
      stateSave: true,
      drawCallback: function () {
        if (dataForTbl.length <= 0) {
          $(".dataTables_paginate").hide();
        } else {
          $(".dataTables_paginate").show();
        }
      }, // OVER : drawback function
    }); // OVER : Data Table
    tabledt.search("").draw();

    var tempReactThis = this;
    $(document).on("click", "[id*='editer_']", function () {
      let tempAnalysisNm = $(this).attr("data-analysisName");
      tempReactThis.showModal(
        tempReactThis,
        this.id.substring(7),
        $(this).attr("data-runThrId"),
        $(this).attr("data-analysisId"),
        tempAnalysisNm,
        $(this).attr("data-prepKeywrdStus"),
        $(this).attr("data-runKeyWrdStus"),
        $(this).attr("data-reportGenStus"),
        $(this).attr("data-pipeLineId"),
        $(this).attr("data-editedDocCount"),
        $(this).attr("data-editedReviewHrs"),
        $(this).attr("data-editedTotalHitCnt"),
      );
    });
    var tempReacThis = this;
    $(document).on("click", "[id*='view_model_']", function () {
      let idData = this.id.split("_");

      tempReacThis.showViewModal(tempReacThis, idData[2], idData[3]);
    });
    disText = $("#analysisGridTable").text();
    var pagNum = "";
    pagNum = disText.replace(pagNum, "<span>" + pagNum + "</span>");
    disText = disText.replace(pagNum, "<span>" + pagNum + "</span>");
  }; // OVER : Draw Grid
  isSaveDisabled = () => {
    if (Number(this.state.editedReportGenStatus) !== 3) return false;

    return !(
      this.state.originalUploadedFileName !== "" ||
      (this.state.fileDetails !== "" && this.state.isCorrectFile)
    );
  };

  getCustodianNameById(custodianId) {
    const { CRT_FIRST_NAME, CRT_LAST_NAME } = this.state.individualAnalysisData.custodians.find(
      (custodian) => custodian.CRT_ID === custodianId,
    );
    return `${CRT_FIRST_NAME} ${CRT_LAST_NAME || ""}`;
  }

  // RENDER METHOD FOR ADMIN ANALYSIS======================
  render() {
    return (
      <div className="container analysisGridContainer p-0">
        <div className="row analysisGridRow">
          <div className="col-md-12 DFED-analysis-admin">
            <table
              id="analysisGridTable"
              className="display table-header-focus"
              width="100%"
              ref={(el) => (this.el = el)}
            />
          </div>
        </div>
        <div className="editModelDiv">
          <CustomModal
            show={this.state.visible}
            onCancel={this.handleCancel}
            title={this.polyglot.t("Update analysis status")}
            Footer={[
              <ButtonPrimary
                disabled={this.isSaveDisabled()}
                onClick={this.handleConfirm}
                ariaLabel={this.polyglot.t("Save")}
              >
                {this.polyglot.t("Save")}
              </ButtonPrimary>,
            ]}
            size="sm"
          >
            <>
              <div className="row mrgnBtm10">
                <div className="col-md-4 labelDiv">
                  <span className="editTest-align">{this.polyglot.t("Prepare keyword status :")}</span>
                </div>
                <div className="col-md-8 labelDiv padding-top-point5">
                  <Select
                    placeholder={this.polyglot.t("Prepare Keyword status")}
                    value={this.state.editedPrepareKeywordStatus}
                    showSearchOnToggle={true}
                    onSelect={this.prepareKeywordOnChng.bind(this)}
                    className="admin-editinputboxes_align "
                    disabled={this.state.isAnalysisAlreadyInUse}
                  >
                    <SelectOption key={"prepKeyStus_" + 1} value={"1"}>
                      {this.polyglot.t("Not Started")}
                    </SelectOption>
                    <SelectOption key={"prepKeyStus_" + 2} value={"2"}>
                      {this.polyglot.t("In Progress")}
                    </SelectOption>
                    <SelectOption key={"prepKeyStus_" + 3} value={"3"}>
                      {this.polyglot.t("Completed")}
                    </SelectOption>
                  </Select>
                </div>
              </div>
              <div className="row mrgnBtm10">
                <div className="col-md-4 labelDiv">
                  <span className="editTest-align">{this.polyglot.t("Running keyword status :")}</span>
                </div>
                <div className="col-md-8 labelDiv padding-top-point5">
                  <Select
                    placeholder={this.polyglot.t("Running Keyword status")}
                    value={this.state.editedRunningKeywordStatus}
                    showSearchOnToggle={true}
                    onSelect={this.runningKeywrdOnChng.bind(this)}
                    className="admin-editinputboxes_align "
                    disabled={
                      this.state.isAnalysisAlreadyInUse ||
                      this.state.editedPrepareKeywordStatus !== STATUS_COMPLETED
                    }
                  >
                    <SelectOption key={"prepKeyStus_" + 1} value={"1"}>
                      {this.polyglot.t("Not Started")}
                    </SelectOption>
                    <SelectOption key={"prepKeyStus_" + 2} value={"2"}>
                      {this.polyglot.t("In Progress")}
                    </SelectOption>
                    <SelectOption key={"prepKeyStus_" + 3} value={"3"}>
                      {this.polyglot.t("Completed")}
                    </SelectOption>
                  </Select>
                </div>
              </div>
              <div className="row mrgnBtm10">
                <div className="col-md-4 labelDiv">
                  <span className="editTest-align">{this.polyglot.t("Report generated status:")}</span>
                </div>
                <div className="col-md-8 labelDiv padding-top-point5">
                  <Select
                    placeholder={this.polyglot.t("Running generated status")}
                    value={this.state.editedReportGenStatus}
                    showSearchOnToggle={true}
                    onSelect={this.reportGenStatusOnChng.bind(this)}
                    className="admin-editinputboxes_align "
                    disabled={
                      this.state.isAnalysisAlreadyInUse ||
                      !(
                        this.state.editedPrepareKeywordStatus === STATUS_COMPLETED &&
                        this.state.editedRunningKeywordStatus === STATUS_COMPLETED
                      )
                    }
                  >
                    <SelectOption key={"prepKeyStus_" + 1} value={"1"}>
                      {this.polyglot.t("Not Started")}
                    </SelectOption>
                    <SelectOption key={"prepKeyStus_" + 2} value={"2"}>
                      {this.polyglot.t("In Progress")}
                    </SelectOption>
                    <SelectOption key={"prepKeyStus_" + 3} value={"3"}>
                      {this.polyglot.t("Completed")}
                    </SelectOption>
                  </Select>
                </div>
              </div>
              {this.state.originalUploadedFileName && !this.state.isAnalysisAlreadyInUse && (
                <div className="row mrgnBtm10">
                  <div className="col-md-4 labelDiv">
                    <span className="editTest-align">{this.polyglot.t("Previous file uploaded")}:</span>
                  </div>
                  <span className="col-md-8 labelDiv sucessFntClr">
                    {this.state.originalUploadedFileName}
                  </span>
                </div>
              )}
              {this.state.editedReportGenStatus === STATUS_COMPLETED && !this.state.isAnalysisAlreadyInUse && (
                <div className="row mrgnBtm10">
                  <div className="col-md-4 labelDiv">
                    <span className="editTest-align">{this.polyglot.t("File Upload :")}</span>
                  </div>
                  <div className="col-md-8 labelDiv padding-top-point5">
                    <input
                      placeholder="Input"
                      name="analysisGridFile"
                      className="admin-editinputboxes_align cursor"
                      id="analysisGridFileId"
                      type="file"
                      ref={(ref) => (this.fileUpload = ref)}
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                      onChange={this.analysisGridUploadHandler.bind(this)}
                      aria-label={this.polyglot.t("File Upload :")}
                    />
                    <br />
                    <span id="anaKeyWedExcelTypeErr" className="errorFntClr"></span>
                    <span id="anaKeyWedExcelTypeSucessfull" className="sucessFntClr"></span>
                  </div>
                </div>
              )}
            </>
          </CustomModal>
        </div>
        <div className="viewModelDiv">
          <CustomModal
            show={this.state.visibleViewModal}
            onCancel={this.handleViewCancel}
            title={this.polyglot.t("Analysis details of: ") + this.state.analysisName}
            footer={[]}
            showCloseButton={true}
            size="sm"
          >
            <div className="modal-txt-open">
              {this.state.individualAnalysisData && (
                <>
                  <span className="analysis-label-binding">
                    {this.polyglot.t("Created on")}:{" "}
                    {getDisplayDate(this.state.individualAnalysisData.analysis.CREATED_ON)}
                  </span>
                  <Accordions key={1} className="mb-2">
                    <AccordionItem itemId={"1"} key={1}>
                      <AccordionItemHeader title="Custodians" />
                      <AccordionItemBody>
                        {this.state.individualAnalysisData.custodians.length > 0 ? (
                          this.state.individualAnalysisData.custodians
                            .map(({ CRT_FIRST_NAME, CRT_LAST_NAME }) => `${CRT_FIRST_NAME} ${CRT_LAST_NAME || ""}`)
                            .join(", ")
                        ) : (
                          <div>No custodian</div>
                        )}
                      </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem itemId={"2"} key={2}>
                      <AccordionItemHeader title="Datasets" />
                      <AccordionItemBody>
                        {this.state.individualAnalysisData.datasets.length > 0
                          ? this.state.individualAnalysisData.datasets.map(
                              ({ CUSTODIAN_ID, DT_DATASET_NAME, DT_ID }) => (
                                <div key={`${CUSTODIAN_ID}-${DT_ID}`}>
                                  Custodian: {this.getCustodianNameById(CUSTODIAN_ID)} | Dataset:{" "}
                                  {DT_DATASET_NAME}
                                </div>
                              ),
                            )
                          : "No datasets list"}
                      </AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem itemId={"3"} key={3}>
                      <AccordionItemHeader title="Keywords" />
                      <AccordionItemBody>
                        {this.state.individualAnalysisData.keywords.length > 0
                          ? this.state.individualAnalysisData.keywords
                              .map(({ LIST_NAME }) => LIST_NAME)
                              .join(", ")
                          : "No Keyword list"}
                      </AccordionItemBody>
                    </AccordionItem>
                  </Accordions>
                </>
              )}
            </div>
          </CustomModal>
        </div>
      </div>
    );
  }
}
export default AnalysisGrid;
