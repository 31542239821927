import HttpClient from "../../Shared/HttpClient";

class PermissionService {
  constructor() {
    this.client = new HttpClient();
  }

  async getPermissionList(moduleName, projectID, solutionName) {
    const result = await this.client.get(`/getPermissionList/${solutionName}/${projectID}/${moduleName}`);
    return result;
  }
}
// Enable Singleton behavior on this class
PermissionService.__instance = null;
PermissionService.get = () => {
  if (!PermissionService.__instance) {
    PermissionService.__instance = new PermissionService();
  }
  return PermissionService.__instance;
};

export default PermissionService;
