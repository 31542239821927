import { Panel } from "appkit-react";
import React, { useEffect, useState } from "react";

import CategoryAddTree from "./CategoryAddTree";
import CreateForm from "./CreateForm";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import ButtonSecondaryWithIcon from "../../../Assets/Components/ButtonSecondary/ButtonSecondaryWithIcon";
import { backCircle, uploadIcon, downloadIcon, infoIcon } from "../../../Assets/Icons";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/GRC/loading-spin.svg";
import * as path from "../../../Constants/Path";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import CreateQuestion from "../Question/CreateQuestion";
import "./index.css";
import CategoryService from "../../../Services/GRC/CategoryService";
import RightQuestionnaireQuestion from "../Questionnaire/RightQuestionnaireQuestion";
import ErrorPanel from "../Shared/ErrorPanel";

const FIRST_IN_DEPTH = 0;

const Category = (props) => {
  const polyglot = polyglotLoader();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [loadCategory, setloadCategory] = useState([]);
  const [categoryType, setcategoryType] = useState(0);
  const [categoryId, setcategoryId] = useState(props.location.state.id);
  const [categoryPath, setcategoryPath] = useState("");
  const [categoryMode, setCategoryMode] = useState(() => props.location.state.mode || "add");
  const [latestId, setlatestId] = useState(0);
  const [latestRootId, setlatestRootId] = useState(props.location.state.id);
  const [selectedQuestionIdToEdit, setSelectedQuestionIdToEdit] = useState(null);
  const [setid, setsetid] = useState(1);
  const [fromMode, setfromMode] = useState(() => props.location.state.mode || "add");

  const mode = props.location.state.mode;

  // update form mode on load of the page based on the location's state
  useEffect(() => {
    const categoryModeByLocation = props.location.state.mode ? props.location.state.mode : "add";
    setCategoryMode(categoryModeByLocation);
    const categoryIdByLocation = props.location.state.id ? props.location.state.id : 0;
    setlatestId(categoryIdByLocation);
    setlatestRootId(categoryIdByLocation);
  }, [props.location.state.mode, props.location.state.id]);

  const map = {};
  const roots = [];

  const onLoadCategories = async () => {
    try {
      if (latestRootId === 0) {
        return;
      }
      const responseData = await sendRequest(CategoryService.get().getCurrentCategoryURL(latestRootId));

      responseData.forEach((v, i) => {
        map[v.value] = i;
        responseData[i].children = [];
      });

      responseData.forEach((v) =>
        v.tagLebel !== 0 ? responseData[map[v.tagLebel]].children.push(v) : roots.push(v),
      );
      setloadCategory(roots);
      if (categoryId && roots && roots[0] && mode === "edit") {
        handleContainerLoad(null, roots[0].value, FIRST_IN_DEPTH, roots[0].label, "text", mode);
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  useEffect(() => {
    onLoadCategories();
  }, [sendRequest, latestId]);

  const loadRightPanels = () => {
    switch (categoryType) {
      case 3:
        return (
          <>
            {categoryMode === "add" && (
              <CreateQuestion
                categoryId={categoryId}
                categoryPath={categoryPath}
                mode={categoryMode}
                setlatestId={setlatestId}
              />
            )}
            {categoryMode === "edit" && <RightQuestionnaireQuestion id={selectedQuestionIdToEdit} />}
          </>
        );
      default:
        return (
          <CreateForm
            categoryType={categoryType}
            categoryId={categoryId}
            categoryPath={categoryPath}
            setlatestId={setlatestId}
            mode={mode}
            setlatestRootId={setlatestRootId}
            categoryMode={categoryMode}
            incrementCategoryTypeByOne={incrementCategoryTypeByOne}
            getCurrentCategoryURL={onLoadCategories}
            fromMode={fromMode}
          />
        );
    }
    /*  return <CreateQuestion categoryId={91} />; */
  };
  const handleBack = (e) => {
    e.preventDefault();
    props.history.push({
      pathname: path.CREATE_GRC_HOME,
    });
  };

  const incrementCategoryTypeByOne = (body) => {
    try {
      const currentCategoryType = Number(categoryType);
      if (mode === "edit") {
        setcategoryType(currentCategoryType);
      } else {
        setcategoryType(currentCategoryType + 1);
      }
      setcategoryId(body.categoryIdNumber);
      let categoryPathClone;
      if (categoryPath.length === 0) {
        categoryPathClone = body.name;
      } else {
        categoryPathClone = categoryPath + " > " + body.name;
      }
      setcategoryPath(categoryPathClone);
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  function handleContainerLoad(e, id, depth, lnk, tagname, modetype) {
    setsetid(id);

    setfromMode(modetype);
    setCategoryMode(modetype);
    setcategoryId(id);

    setcategoryPath(lnk);
    // "The user aborted a request."
    if (modetype === "edit") {
      setcategoryType(depth);
      if (tagname === "ques") {
        const questionID = id;
        setSelectedQuestionIdToEdit(questionID);
      }
    } else {
      setcategoryType(depth + 1);
    }
  }

  return (
    <div className="row m-0">
      <div className="col-md-3 col-sm-12 pl-0">
        <Panel className="panel ">
          {loadCategory.length > 0 && (
            <div className="row pb-2 block sr-questionaries1">
              <div className="col-md-12">
                <h4 className="mt-3">{loadCategory.length > 0 && "List of items"}</h4>
                {error && <ErrorPanel error={error} onClear={clearError} />}
                {isLoading && (
                  <div>
                    <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
                  </div>
                )}
                {!isLoading && loadCategory.length > 0 && (
                  <>
                    <CategoryAddTree
                      data={loadCategory}
                      handleChanged={handleContainerLoad}
                      setid={setid}
                      mode={mode}
                      getCategories={onLoadCategories}
                    />
                  </>
                )}
              </div>
            </div>
          )}
          <div className="row d-flex justify-content-center pb-2 block">
            <div className="col-md-12 mt-3">
              <div className="w-90 dotted-border-b7b7b7 padding-1rem">
                <p>
                  <ButtonIcon
                    iconName={infoIcon}
                    ariaLabel={polyglot.t("info")}
                    className="m-l-minus-point8rem"
                  />
                  <b>{polyglot.t("note")}:</b>
                </p>
                <p>{polyglot.t("category.note")}</p>
              </div>
            </div>
          </div>
        </Panel>
      </div>
      <div className="col-md-9 usertoadd-responsive p-0 mt-3 pr-3">
        <div className="row m-l-0 m-r-0">
          <div className="col-md-6 p-0 ">
            <ButtonPrimaryWithIcon
              iconName={uploadIcon}
              alt={"upload"}
              ariaLabel={polyglot.t("upload")}
              className="custome-pos float-left mr-2"
            >
              {polyglot.t("upload")}
            </ButtonPrimaryWithIcon>
            <ButtonSecondaryWithIcon
              iconName={downloadIcon}
              alt={"download"}
              ariaLabel={polyglot.t("download.label")}
              className="custome-pos float-left"
            >
              {polyglot.t("download.label")}
            </ButtonSecondaryWithIcon>
          </div>
          <div className="col-md-6 p-0">
            <ButtonSecondaryWithIcon
              iconName={backCircle}
              alt={"back"}
              ariaLabel={polyglot.t("back")}
              onClick={(e) => handleBack(e)}
              className="custome-pos float-right"
            >
              {polyglot.t("back")}
            </ButtonSecondaryWithIcon>
          </div>
        </div>

        <Panel className="padding-1rem mt-3">{loadRightPanels()}</Panel>
      </div>
    </div>
  );
};
export default Category;
