import { Button } from "appkit-react";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { withRouter } from "react-router-dom";

import CustomModal from "../../../Assets/Components/Modal";
import * as path from "../../../Constants/Path";
import UserService from "../../../Services/QueenBase/Users/UserService";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";
// POPUP TO DELETE A USER
function DeleteUser({
  user,
  show,
  cancelUserModal,
  history,
  fetchUserAfterDelete,
  fetchUserCountAfterDelete,
}) {
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  // HIT DELTE USER API
  const deleteUser = async (userid, firstname, email) => {
    var res = await UserService.get().deleteUser({ userid, firstname, email });
    if (res.CODE) {
      displayToastMessage(res.MSG, "success");
      cancelUserModal(userid, firstname);
      history.push(path.USER_PATH);
      fetchUserAfterDelete();
      fetchUserCountAfterDelete();
    } else {
      displayToastMessage(res.MSG, "error");
    }
  };
  return (
    <div>
      <CustomModal
        show={show}
        onCancel={() => cancelUserModal(user.userid, user.username)}
        title={polyglot.t("Delete user")}
        Footer={[
          <Button
            size="lg"
            className="primary_button"
            onClick={() => deleteUser(user.userid, user.username, user.email)}
            key={"delete_user"}
          >
            {polyglot.t("Yes, delete user")}
          </Button>,
        ]}
        style={{ width: 600 }}
      >
        <>
          <div className="row">
            <div className="col-md-12 break-word">
              <p>
                {polyglot.t(
                  "The user will no longer have access to any of the projects or the portal itself",
                )}
              </p>
            </div>
          </div>
          <div className="user-description-two p-0 break-word">
            {polyglot.t("Are you sure you want to continue deleting the user: ")}
            <strong>{user.username}</strong>?
          </div>
        </>
      </CustomModal>
    </div>
  );
}
DeleteUser.propTypes = {
  user: PropTypes.object,
  show: PropTypes.bool,
  cancelUserModal: PropTypes.func,
  history: PropTypes.object,
  buttonClick: PropTypes.func,
  fetchUserAfterDelete: PropTypes.func,
  fetchUserCountAfterDelete: PropTypes.func,
};

export default withRouter(DeleteUser);
