import React from "react";

import { rightChevronImg } from "../../../Assets/Icons";

/**
 * this takes string from api as an argument and returns HTML as a breadcrumb.
 * @param {string} param0
 * @returns  formated HTML
 *
 */
export const GetPerformAssessmentBreadcrum = ({ breadcrumString }) => {
  try {
    const listOfSteps = breadcrumString.split(">");
    const isStepActive = (total, currentIndex) => {
      if (total === currentIndex + 1) {
        return true;
      } else {
        return false;
      }
    };
    const displayRightArrow = (total, currentIndex) => {
      if (total === currentIndex + 1) {
        return "";
      } else {
        return <img src={rightChevronImg} alt="right arrow" />;
      }
    };
    return (
      <div className="display-inline-flex category-breadcrum-box-text ml-2">
        {listOfSteps.map((step, index) => {
          return (
            <div key={index}>
              <b
                className={`padding-point5rem ${
                  isStepActive(listOfSteps.length, index) && "active-breadcrum"
                }`}
              >
                {step}
              </b>
              {displayRightArrow(listOfSteps.length, index)}&nbsp;
            </div>
          );
        })}
      </div>
    );
  } catch (err) {
    return <></>;
  }
};
