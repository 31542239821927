import PropTypes from "prop-types";
import React from "react";

function LinkText(props) {
  const { href, className, onClick, ariaLabel } = props;
  return (
    <a href={href} className={`${className} link-text`} onClick={onClick} aria-label={ariaLabel} role="link">
      {props.children}
    </a>
  );
}

LinkText.propTypes = {
  href: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string,
};
LinkText.defaultProps = {
  className: "",
  children: "Link text",
  ariaLabel: "link",
};

export default LinkText;
