import React, { useState, useContext } from "react";

import { withRouter } from "react-router-dom";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import { DataContext } from "../../../Services/Shared/Store";
import "../../../Assets/Css/GRC/RightPanel.css";
import "../RecipientResponse/Pages/index.css";
import EmptyPanel from "../Shared/EmptyPanel";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import AssessmentService from "../../../Services/GRC/AssessmentService";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";

const LeftRecipient = ({ assessments, clickEvent }) => {
  const [searchStr, setSearch] = useState("");
  const { sendRequest } = useHttpClient();
  const { getUserLanguage } = useContext(DataContext);

  const polyglot = polyglotLoader(getUserLanguage());

  // SEARCH TEXT FUNCTIONALITY
  const onSearch = (e) => {
    setSearch(e.target.value);
  };

  // FILTER ASSESSMENT BEFORE DISPLAYING ASSESSMENT LIST
  const filterAssessment = (assessments) => {
    let filteredAssessment = [...assessments];
    if (searchStr !== "") {
      filteredAssessment = assessments.filter((r) => r.name.toLowerCase().includes(searchStr.toLowerCase()));
    }
    return filteredAssessment;
  };

  const handleExportAssessment = async () => {
    try {
      const data = await sendRequest(AssessmentService.get().exportAssessmentURL());
      if (!data.CODE) {
        displayToastMessage(polyglot.t("File not available"), "error");
      } else {
        setTimeout(() => {
          const buffer = Buffer.from(data.buffer);
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", data.fileName);
          document.body.appendChild(link);
          link.click();
          displayToastMessage(polyglot.t("Downloaded successfully"), "success");
        }, 1000); // 1 sec sleep to collect Buffer properly
      }
    } catch (err) {
      displayToastMessage(polyglot.t(err.message), "error");
    }
  };

  //return list of assessment
  return (
    <>
      <div className="col-md-12 p-0 m-b-2 py-3">
        <div>
          <div className="row m-l-0 m-r-0 filter-search-qb">
            <div className="left-panel-search-icon">
              <input
                value={searchStr}
                className="left-panel-search-box"
                placeholder={polyglot.t("search")}
                onChange={onSearch}
                autoComplete={"off"}
                aria-label={polyglot.t("search")}
              />
            </div>
            <h1 className="main_title_qb recipientHeading mt-2 ">{polyglot.t("list_of_assessments")}</h1>
          </div>
          <hr className="just-ruler" />
        </div>
      </div>
      {assessments.length === 0 && <EmptyPanel massage={"No data found."} />}
      {filterAssessment(assessments)?.map((item, i) => {
        return (
          <ul key={i} className="a-list">
            <li className="a-list-item">
              <span className="appkiticon icon-check-mark-fill pointer-solution"></span>

              <div
                key={i}
                className="project-title-overflow f-acc pointer"
                onClick={(e) => clickEvent(e, item)}
              >
                {item.name}
              </div>
            </li>
          </ul>
        );
      })}
      <div className="row justify-content-end p-0 pr-3">
        <div className="col-md-5 justify-content-between pl-0 custome-left">
          <ButtonPrimary onClick={handleExportAssessment} ariaLabel={polyglot.t("export_all")}>
            {polyglot.t("export_all")}
          </ButtonPrimary>
        </div>
      </div>
    </>
  );
};
export default withRouter(LeftRecipient);
