import React, { Component } from "react";
import "../../../Assets/Css/DFED/HomeComponent.css";
import ProductionDataTable from "../Review/productionDataTable";
import ProductionFiles from "../Review/ProductionFiles";
import { ReviewProductionFinalResultById } from "../../../Services/DFED/ReviewService";
import NoAccess from "../../../Assets/Components/NoAccess";
import commonFunctionForCallService from "../../../utility/commonFunctionForCallService";
import * as module_Solution_Name from "../../../Constants/moduleName";
// THIS CLASS IS RESPONSIBLE FOR HANDLING PRODUCTION REQUEST CREATION AND LISTING OF PRODUCTION
class Production extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productionResult: props.location.state.productionResult,
      selectedProjectId: props.location ? props.location.state.selectedProjectId : props.selectedProjectId,
      selectedProjectName: sessionStorage.getItem("selectedProjectName"),
      showBackButton: props.location.state.showBackButton ? props.location.state.showBackButton : null,
      isViewPermission: true,
      rolePermission: {},
    };
  }
  async ReviewProductionResult(tempSelectedProjectId) {
    await ReviewProductionFinalResultById(tempSelectedProjectId)
      .then((productionResult) => {
        if (productionResult && productionResult.data) {
          productionResult.data.forEach(function (a) {
            a.REVIEW_UPLOADED_ON = a.REVIEW_UPLOADED_ON.substring(0, 10);
          });
          this.setState({
            productionResult: productionResult.data,
          });
        }
      })
      .catch((error) => {
        if (error && error.status) {
          this.setState({
            isViewPermission: false,
          });
        }
      });
  }
  // GETTING ROLE FOR THIS CLASS==================
  getApplicationRolesWithPermission = async () => {
    let selectedProjectId = this.state.selectedProjectId;
    let rolePermission = await commonFunctionForCallService.commonFunctionForCallService(
      module_Solution_Name.DFED_SOLUTION,
      selectedProjectId,
      module_Solution_Name.PRODUCTION_MODULE,
    );
    if (rolePermission) {
      this.setState({
        rolePermission: rolePermission,
      });
    }
  };
  // life cycle METHOD FOR GETTING PRODUCTION DATA======================

  componentDidMount() {
    this.ReviewProductionResult(this.state.selectedProjectId);
    this.getApplicationRolesWithPermission();
  }
  // RENDER METHOD FOR CREATING RENDER AND RENDERING ALL UI RELATED THINGS=========================

  render() {
    return (
      <div>
        {(this.state.isViewPermission && this.state.productionResult && this.state.productionResult.length) >
        0 ? (
          <ProductionDataTable
            selectedProjectId={this.state.selectedProjectId}
            selectedProjectName={this.state.selectedProjectName}
            productionResult={this.state.productionResult}
            showBackButton={this.state.showBackButton}
            newProps={this.props}
          />
        ) : this.state.isViewPermission ? (
          <ProductionFiles
            selectedProjectId={this.state.selectedProjectId}
            selectedProjectName={this.state.selectedProjectName}
            showBackButton={this.state.showBackButton}
            newProps={this.props}
          />
        ) : (
          <NoAccess />
        )}
      </div>
    );
  }
}
export default Production;
