import { Button } from "appkit-react";
import PropTypes from "prop-types";
import React from "react";

const ButtonPrimaryExpandable = (props) => {
  const {
    size, // size can be 'sm', 'md', 'lg', 'xl'
    disabled,
    grayButton,
    type, // type can be 'submit', 'reset', 'button'
    isLoading,
    className,
    onClick,
    iconName,
    iconImg,
    alt,
    showIcon,
    ariaLabel,
  } = props;

  const displayIcon = () => {
    return (
      <>
        {iconName && <span alt={alt} className={`appkiticon ${iconName}`} aria-label={ariaLabel}></span>}
        {iconImg && (
          <>
            <img alt={alt} src={iconImg} aria-label={ariaLabel} />
            &nbsp;
          </>
        )}
      </>
    );
  };

  return (
    <Button
      kind="primary"
      size={size}
      disabled={disabled}
      gray={grayButton}
      type={type}
      isLoading={isLoading}
      className={`${className} button-expandable`}
      onClick={onClick}
      aria-label={ariaLabel}
      role="link"
    >
      {showIcon && displayIcon()}
      {props.children}
    </Button>
  );
};

ButtonPrimaryExpandable.propTypes = {
  size: PropTypes.string,
  disabled: PropTypes.bool,
  grayButton: PropTypes.bool,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  iconName: PropTypes.string,
  iconImg: PropTypes.string,
  alt: PropTypes.string,
  showIcon: PropTypes.bool,
  ariaLabel: PropTypes.string,
};
ButtonPrimaryExpandable.defaultProps = {
  size: "lg",
  disabled: false,
  grayButton: false,
  type: "button",
  isLoading: false,
  className: "",
  children: "primary button",
  alt: "image",
  showIcon: false,
  ariaLabel: "button",
};

export default ButtonPrimaryExpandable;
