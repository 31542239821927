import { Panel, Input } from "appkit-react";
import moment from "moment";
import React, { useState, useEffect } from "react";

import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { ReactComponent as LoadingSpinner } from "../../../../Assets/Images/GRC/loading-spin.svg";
import displayToastMessage from "../../../../Components/QueenBase/Root/displayToastMessage";
import { CREATE_ASSESSMENT_PATH } from "../../../../Constants/Path";
import AssessmentService from "../../../../Services/GRC/AssessmentService";
import { useHttpClient } from "../../../../Services/GRC/http-hook";
import { useHttpPostClient } from "../../../../Services/GRC/http-hook-post";
import { polyglotLoader } from "../../../../Services/Shared/TranslateGRC";
import LeftPanelQuestionaris from "../LeftPanelQuestionaris";
import ShareQuestionTree from "../ShareQuestionTree";
import ErrorPanel from "../../Shared/ErrorPanel";
import "./index.css";
import { getProjectID_Of_solution_module } from "../../../../Services/Shared/Handlers";

import { CalendarPicker } from "appkit-react-datepicker";

/**
 * Created assessment are share from this page
 *
 * @param {props} props
 * @returns
 */
const RecipientAssessmentShare = (props) => {
  const selectedId = props.location.state.id;
  const polyglot = polyglotLoader();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { isLoadingPost, errorPost, sendRequestPost, clearErrorPost } = useHttpPostClient();
  const [prewviewAssessment, setPrewviewAssessment] = useState([]);
  const [dueDate, setDueDate] = useState();

  const handleChangedMaster = (e, type) => {
    for (let master_level = 0; master_level < prewviewAssessment.length; master_level++) {
      // set master text
      const questions_master = document.getElementById(prewviewAssessment[master_level].value + "_0");
      questions_master.value = e;

      let node = prewviewAssessment[master_level];

      if (node.children.length > 0) {
        for (let c_depth_Index = 0; c_depth_Index < node.children.length; c_depth_Index++) {
          // id => 1_1 => rowId (value) + depth (depth)
          // get all children and assign current root value

          if (node.depth === 0) {
            const category_id = `${node.children[c_depth_Index].value}_${node.children[c_depth_Index].depth}`;
            // document.getElementById(`${category_id}`).value = e;
            // get children
            if (node.children[c_depth_Index].children.length > 0) {
              for (
                let sc_depth_Index = 0;
                sc_depth_Index < node.children[c_depth_Index].children.length;
                sc_depth_Index++
              ) {
                const cat_sub_category_id = `${node.children[c_depth_Index].children[sc_depth_Index].value}_${node.children[c_depth_Index].children[sc_depth_Index].depth}`;
                // document.getElementById(`${cat_sub_category_id}`).value = e;
              }
            }
          }
          if (node.depth === 1) {
            const sub_category_id = `${node.children[c_depth_Index].value}_${node.children[c_depth_Index].depth}`;
            // document.getElementById(`${sub_category_id}`).value =
            // e.target.value;
          }
        }
      }
    }
  };

  const handleChanged = (node, e, type) => {
    e.preventDefault();

    const currentvalue = type === "master" ? e : e.target.value;
    //1. check children count if > 0 loop
    if (node.children.length > 0) {
      for (let c_depth_Index = 0; c_depth_Index < node.children.length; c_depth_Index++) {
        // id => 1_1 => rowId (value) + depth (depth)
        // get all children and assign current root value

        if (node.depth === 0) {
          const category_id = `${node.children[c_depth_Index].value}_${node.children[c_depth_Index].depth}`;
          // document.getElementById(`${category_id}`).value = currentvalue;
          // get children
          if (node.children[c_depth_Index].children.length > 0) {
            for (
              let sc_depth_Index = 0;
              sc_depth_Index < node.children[c_depth_Index].children.length;
              sc_depth_Index++
            ) {
              const cat_sub_category_id = `${node.children[c_depth_Index].children[sc_depth_Index].value}_${node.children[c_depth_Index].children[sc_depth_Index].depth}`;
              // document.getElementById(`${cat_sub_category_id}`).value =
              // currentvalue;
            }
          }
        }
        if (node.depth === 1) {
          const sub_category_id = `${node.children[c_depth_Index].value}_${node.children[c_depth_Index].depth}`;
          // document.getElementById(`${sub_category_id}`).value = e.target.value;
        }
      }
    }
  };

  const restrictStartDates = (current) => {
    let yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    return current && current < yesterday;
  };

  const handleChangeDueDate = (e, dateString) => {
    if (e) {
      setDueDate(e);
    }
  };

  const getSelectedQuestionaries = async () => {
    try {
      const responseData = await sendRequest(AssessmentService.get().getAssessmentPreviewByIdURL(selectedId));
      const list = responseData;

      const mapA = {};
      const rootsA = [];
      list.forEach((v, i) => {
        mapA[v.value] = i;
        list[i].children = [];
      });

      list.forEach((v1, i) => {
        v1.tagLebel !== 0 ? list[mapA[v1.tagLebel]].children.push(v1) : rootsA.push(v1);
      });
      setPrewviewAssessment(rootsA);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSelectedQuestionaries();
  }, [sendRequest]);
  const handleShareAssessmentQuestions = async () => {
    if (dueDate === undefined || dueDate == null) {
      displayToastMessage("set due date", "error");
      return;
    }
    let getCategories = [];
    let selectedMails = "";
    let elem = document.getElementsByClassName("getcategoriessh");

    for (let k = 0; k < elem.length; k++) {
      if (elem[k].value) {
        getCategories = [...getCategories, elem[k].id];
        selectedMails = [...selectedMails, elem[k].value];
      }
    }

    // 1. getCategories get all have level 2 in 1_1 format
    // 2. get text from there
    let temp_arr = [];
    let subcategoryItems = [];
    subcategoryItems.length = 0;
    for (let index_cat = 0; index_cat < getCategories.length; index_cat++) {
      const get_mailIdTextBoxs = getCategories[index_cat];
      const mailIdTextBoxCategoryId = get_mailIdTextBoxs.split("_")[0];
      const mailIdTextBoxDepth = get_mailIdTextBoxs.split("_")[1];

      // if (mailIdTextBoxDepth == 2) {
      const get_mailids = document.getElementById(`${get_mailIdTextBoxs}`).value;

      subcategoryItems.push({
        mails: get_mailids,
        id: mailIdTextBoxCategoryId,
      });
      // }
    }

    //step-2
    // if get multiple mail for a single sub cat convert them into rows
    let mailItems = [];
    for (let index_mail = 0; index_mail < subcategoryItems.length; index_mail++) {
      if (subcategoryItems[index_mail].mails.indexOf(",") > -1) {
        // set multiple mail ids
        let mail_arr = subcategoryItems[index_mail].mails.split(/\s*,\s*/);
        for (let im = 0; im < mail_arr.length; im++) {
          // email validator
          /* if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail_arr[im]))
          {
            displayToastMessage(
              polyglot.t("email is not valid"),
              "error"
            );
            return;
          } */
          mailItems.push({
            mail: mail_arr[im],
            id: subcategoryItems[index_mail].id,
          });
        }
      } else {
        // email validator
        /* if(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(subcategoryItems[index_mail].mails))
        {
          displayToastMessage(
            polyglot.t("email is not valid"),
            "error"
          );
          return;
        } */
        mailItems.push({
          mail: subcategoryItems[index_mail].mails,
          id: subcategoryItems[index_mail].id,
        });
      }
    }

    // step 3
    // group by mail

    let mailItemIds = [];
    for (let index_mail_ids = 0; index_mail_ids < mailItems.length; index_mail_ids++) {
      let item = mailItems[index_mail_ids];
      let findIndex = mailItemIds.findIndex((x) => x.email === item.mail);

      if (findIndex === -1) {
        //insert
        mailItemIds.push({ email: item.mail, ids: [item.id] });
      } else {
        //update
        let findItem = mailItemIds.find((x) => x.email === item.mail);
        findItem.ids = [...findItem.ids, item.id];
      }
    }

    // step 4 : generate json
    let mailData = [];
    for (let index_mail_data = 0; index_mail_data < mailItemIds.length; index_mail_data++) {
      let tempData = [];
      tempData.length = 0;
      const catData = mailItemIds[index_mail_data].ids;

      if (typeof catData === "object") {
        for (let ic = 0; ic < catData.length; ic++) {
          tempData.push({
            id: parseInt(catData[ic]),
            level: 2,
            comment: "comment 3",
          });
        }
      } else {
        tempData.push({
          id: parseInt(catData),
          level: 2,
          comment: "comment 1",
        });
      }
      //tempData.push({ id: 4, level: 2, comment: "test 4" });
      mailData.push({
        email: mailItemIds[index_mail_data].email,
        category: tempData,
      });
    }

    const validateEmail = (mail) => {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return true;
      }
      return false;
    };

    const t = groupBy(mailItems, "mail");

    const selectedCategories = getCategories.length > 0 ? getCategories.join(",") : "";
    // Validating all email address
    mailData.forEach((md) => {
      if (!validateEmail(md.mail)) {
        // displayToastMessage('Invalid email id', 'error');
        return;
      }
    });

    const createdBy = JSON.parse(sessionStorage.getItem("user")).userid;
    const projectId = getProjectID_Of_solution_module();

    const postinput = {
      assessment_id: selectedId,
      created_by: createdBy,
      due_date: moment(dueDate).format("MM/DD/YYYY"),
      mail_json: JSON.stringify(mailData),
      projectId: projectId,
    };

    console.log("postinput", postinput);

    const response = await sendRequestPost(
      AssessmentService.get().postAssessmentRecipientsURL(),
      JSON.stringify(postinput),
      {
        "Content-Type": "application/json",
      },
    );

    // if success
    if (response > 0) {
      displayToastMessage(polyglot.t("assessment shared to recipients successfully"), "success");
      //go to next : home
      props.history.push({
        pathname: CREATE_ASSESSMENT_PATH,
      });
    }
  };
  const groupBy = (xs, key) => {
    return xs.reduce((rv, x) => {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {});
  };
  return (
    <div className="row m-0">
      <div className="col-md-3 pl-0 mb-2">
        <Panel className="panel h-100">
          <LeftPanelQuestionaris selectedId={selectedId} />
        </Panel>
      </div>

      <div className="col-md-9 usertoadd-responsive p-0 mt-3 pr-3">
        <Panel className="panel p-0">
          <div className="row pl-0 mb-3 mr-0 ml-0">
            <div className="col-md-12 p-0">
              <div className="card">
                <div className="card-header">Assessment Name: {props.location.state.title}</div>
                {error && <ErrorPanel error={error} onClear={clearError} />}
                {isLoading && (
                  <div>
                    <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
                  </div>
                )}
                {!isLoading && prewviewAssessment && (
                  <div>
                    <div className="row pt-2 pb-2">
                      <div className="col-md-1"></div>
                      <div className="col-md-2"></div>
                      <div className="col-md-4">
                        <Input
                          id="txt_root_mail"
                          placeholder="mail id"
                          onChange={(e) => handleChangedMaster(e, "master")}
                          type="mail"
                          autoComplete="off"
                          style={{ paddingLeft: "0.75rem" }}
                        />
                        <span className="pl-3">Use comma to seperate email addresses</span>
                      </div>
                      <div className="col-md-4">
                        <CalendarPicker
                          placeholder={`${polyglot.t("Due date")} *`}
                          name="dueDate"
                          onChange={handleChangeDueDate}
                          minDate={new Date(new Date().valueOf() - 1000 * 3600 * 24)}
                          format={"dd.mm.yyyy"}
                          className="antd-datepicker-custom-styles"
                          value={dueDate}
                        />
                        {/* <DatePicker
                          style={{ width: "96%" }}
                          disabledDate={restrictStartDates}
                          placeholder={`${polyglot.t("Due date")} *`}
                          onChange={handleChangeDueDate}
                          defaultPickerValue={moment}
                          format={"MM/DD/YYYY"}
                          className="antd-datepicker-custom-styles"
                        /> */}
                      </div>
                      <div className="col-md-1"></div>
                    </div>

                    <ShareQuestionTree data={prewviewAssessment} handleChanged={handleChanged} />
                  </div>
                )}
                <div className="card-footer bg-transparent">
                  <ButtonPrimary
                    className="custome-pos"
                    onClick={(e) => handleShareAssessmentQuestions(e)}
                    ariaLabel={polyglot.t("save")}
                  >
                    {polyglot.t("save")}
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};
export default RecipientAssessmentShare;
