import "../../../Assets/Css/QueenBase/CompanyCenter/CompanyCenter.css";
import { Pagination } from "antd";
import {
  Select,
  SelectOption,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Tooltip,
} from "appkit-react";
import moment from "moment";
import React, { useContext, useState, useEffect } from "react";

import InvoiceGrid from "./InvoiceGrid";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import download from "../../../Assets/Images/DFED/icon-download.svg";
import { PER_PAGE_COUNT } from "../../../Constants/Constant";
import CompanyCenterService from "../../../Services/QueenBase/CompanyCenter.js/CompanyCenterService";
import UserService from "../../../Services/QueenBase/Users/UserService";
import { capitaliseFirstLetter } from "../../../Services/Shared/Handlers";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { customNumberFormat } from "../../../utility/CustomNumberFormat";
import GetPriceInGermanFormat from "../Root/GetPriceInGermanFormat";
import displayToastMessage from "../Root/displayToastMessage";

const PER_PAGE = PER_PAGE_COUNT;

// CLIENT INVOICING COMPONENT
function ClientInvoicing() {
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const [projectId, setProjectId] = useState(null); //Select box id
  const [defaultdata, setDefaultData] = useState([]); //to get default data  from db as there is only projectid
  const [projectList, setProjectList] = useState([]); //list of projects from getProjectSolutionUserList
  const [show, setShow] = useState(false);
  const [searchStr, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowForPrev, setSelectedRowForPrev] = useState();
  const [isTechnicalClient, setIsTechnicalClient] = useState(false);

  // HANDLE PAGE CHANGE ON PAGINATION
  const handlePageChange = (change) => {
    setCurrentPage(change);
  };

  //list of invoices based on Selection of Projects
  const listOfInvoices = async (projectId) => {
    var result = await CompanyCenterService.get().getInvoicingdetails(projectId);
    if (result) {
      setProjectList(result.projects);
      setDefaultData(result.projectsList);
    } else {
      return result;
    }
  };

  // RUNS ON LOAD AND WHEN PROJECTID CHANGES
  useEffect(() => {
    listOfInvoices(projectId);
  }, [projectId]);

  useEffect(() => {
    const checkIsTechnicalClient = async () => {
      const res = await UserService.get().getUserProfile();
      if (res.CODE) {
        const { isTechnicalClient } = res.userData;
        setIsTechnicalClient(isTechnicalClient);
      } else {
        displayToastMessage(res.MSG, "error");
      }
    };

    checkIsTechnicalClient();
  }, []);

  // HANDLE SEARCH INPUT
  const onSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };
  // CLOSE INVOICE DETAILS POPUP
  const closeModel = (r) => {
    setShow((prev) => !prev);
  };
  // DOWNLOAD INVOICE API
  const downloadInvoice = async (r) => {
    try {
      const downloadResponse = await CompanyCenterService.get().downloadInvoice(r.invoice_attachment); //invoice_attachment
      const buffer = Buffer.from(downloadResponse.file.buffer);
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", downloadResponse.file.fileName);
      document.body.appendChild(link);
      link.click();
      displayToastMessage(polyglot.t("Invoice has been downloded"), "success");
    } catch (error) {
      if (
        (error.message = "First argument must be a string, Buffer, ArrayBuffer, Array, or array-like object.")
      ) {
        displayToastMessage(`${polyglot.t("File not found")}!!`, "error");
      } else {
        displayToastMessage(error.message, "error");
      }
    }
    return "";
  };

  // FILTER INVOICES BASED ON SEARCH STRING
  const filterItems = () => {
    let filteredItems = [...defaultdata];
    if (searchStr !== "") {
      filteredItems = defaultdata.filter((r) => r.client_invoice_ref.includes(searchStr));
    }
    return filteredItems;
  };
  // GET INVOICE DETAILS BASED ON THE PAGE
  const getCurrentPageProjects = (defaultdata) => {
    return defaultdata.slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE);
  };
  // PROJECT SELECTION HEADING ABOVE INVOICE TABLE
  const projectSelection = () => {
    return (
      <div className="col-md-12">
        <div className="row mb-2">
          <div className="col-md-6">
            <div className="row m-l-0 m-r-0">
              <div className="project-invoice-select">
                <Select
                  id="project-select-i"
                  placeholder={polyglot.t("Project")}
                  value={projectId}
                  onSelect={setProjectId}
                  defaultValue=""
                >
                  <label>{polyglot.t("List of Projects")}</label>
                  <SelectOption value="">{polyglot.t("All Projects")}</SelectOption>
                  {projectList &&
                    projectList.map((r, index) => {
                      return (
                        <SelectOption key={index + 1} value={r.project_id}>
                          {r.Project}
                        </SelectOption>
                      );
                    })}
                </Select>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // DISPLAY FULL INVOICE
  const fullInvoiceDisplay = (r) => {
    return (
      <div>
        <Modal
          id="model-custom-body"
          className="baseline-modal-showcase"
          visible={show}
          onCancel={closeModel}
        >
          <ModalHeader>
            <div className="a-modal-example-header user-model-header">
              <div className="modal-title-left-cc">
                <div className="a-h3">{polyglot.t("Invoice Details")}</div>
              </div>
            </div>
          </ModalHeader>

          <ModalBody>
            <div className="user-description-cc">
              {r && (
                <div>
                  <div className="row">
                    <div className="disp-text col-md-6">{polyglot.t("Project Name")}</div>
                    <div className="disp-text col-md-6 count-align-t">
                      {" "}
                      <b>{r.Project}</b>
                    </div>
                  </div>
                  <div className=" row">
                    <div className="disp-text col-md-6">{polyglot.t("Reference")}</div>
                    <div className="disp-text col-md-6 count-align-t">
                      {" "}
                      <b>{r.client_invoice_ref}</b>
                    </div>
                  </div>
                  <div className=" row">
                    <div className="disp-text col-md-6">{polyglot.t("Description")}</div>
                    <div className="disp-text col-md-6 count-align-t">
                      {" "}
                      <b>{r.invoice_description}</b>
                    </div>
                  </div>
                  <div className=" row">
                    <div className="disp-text col-md-6">{polyglot.t("Amount")}</div>
                    <div className="disp-text col-md-6 count-align-t">
                      {" "}
                      <b>{GetPriceInGermanFormat(customNumberFormat(r.invoice_amount))}</b>
                    </div>
                  </div>
                  <div className=" row">
                    <div className="disp-text col-md-6">{polyglot.t("CreatedDate")}</div>
                    <div className="disp-text col-md-6 count-align-t">
                      {" "}
                      <b>{moment(r.created_at).format("DD.MM.YYYY")}</b>
                    </div>
                  </div>
                  <div className=" row">
                    <div className="disp-text col-md-6">{polyglot.t("DueDate")}</div>
                    <div className="disp-text col-md-6 count-align-t">
                      {" "}
                      <b>{moment(r.due_date).format("DD.MM.YYYY")}</b>
                    </div>
                  </div>
                  <div className=" row">
                    <div className="disp-text col-md-6">{polyglot.t("Status")}</div>
                    <div className="disp-text col-md-6 count-align-t">
                      {" "}
                      <b>{polyglot.t(r.Status)}</b>
                    </div>
                  </div>
                  <div className=" row">
                    <div className="disp-text col-md-6">{polyglot.t("TimeRange")}</div>
                    <div className="disp-text col-md-6 count-align-t">
                      {" "}
                      <b>{r.time_range}</b>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter style={{ backgroundcolor: "red" }} id="model-button-alignment">
            <div className="flex-fill help-text">
              <div className="test">
                <Button size="lg" className="prime-button-two" onClick={() => closeModel(r)}>
                  {polyglot.t("Cancel")}
                </Button>
                &nbsp;&nbsp;&nbsp;
                <Button size="lg" className="prime-button" onClick={() => downloadInvoice(r)}>
                  {polyglot.t("Download invoice")}
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  };
  // SHOW INVOICE DETAILS POPUP
  const showModel = (row) => {
    setSelectedRowForPrev(row);
    setShow((prev) => !prev);
  };
  // TOOLTIP OF DESCRIPTION
  const TooltipUserName = (r) => {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
                {capitaliseFirstLetter(r.invoice_description)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  // TOOLTIP ON TIMERANGE
  const TooltipTimerange = (r) => {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
                {capitaliseFirstLetter(r.time_range)}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  // DISPLAY INVOICE TABLE
  // First Character Uppercase in description===================

  // const firstCharUpperDescription =(description)=>{
  //     return description.charAt(0).toUpperCase() + description.slice(1)
  // }

  const invoicetable = () => {
    const list = filterItems(defaultdata);
    return (
      <div className="invoicetable">
        <table className="table table-margin">
          <thead>
            <tr>
              <th>
                <strong>{polyglot.t("Invoice ID")}</strong>
              </th>
              <th>
                <strong>{polyglot.t("Project")}</strong>
              </th>
              <th>
                <strong>{polyglot.t("Descriptions")}</strong>
              </th>
              <th>
                <strong>{polyglot.t("Amount")}</strong>
              </th>
              <th>
                <strong>{polyglot.t("Invoice Date")}</strong>
              </th>
              <th>
                <strong>{polyglot.t("Due Date")}</strong>
              </th>
              <th>
                <strong>{polyglot.t("Status")}</strong>
              </th>
              <th>
                <strong>{polyglot.t("Time Range")}</strong>
              </th>
              <th>
                <strong>{polyglot.t("Action")}</strong>
              </th>
            </tr>
          </thead>
          {getCurrentPageProjects(list).length > 0 ? (
            getCurrentPageProjects(list).map((r, index) => {
              return (
                <thead key={index + 1}>
                  <tr key={index + 1}>
                    <td>{r.client_invoice_ref}</td>
                    <td className="invoice-prj-name">{r.Project}</td>
                    <td
                      className="table-td-cc"
                      className="cc-i-view-text cc-i-data-desc view-center-name data-align-time invoice-timerange"
                    >
                      <Tooltip content={TooltipUserName(r)} placement="top">
                        <div className="cc-i-view-text">{capitaliseFirstLetter(r.invoice_description)}</div>
                      </Tooltip>
                    </td>
                    <td>{GetPriceInGermanFormat(customNumberFormat(r.invoice_amount, true), false, true)}</td>
                    <td>{moment(r.invoice_date).format("DD.MM.YYYY")}</td>
                    <td>{moment(r.due_date).format("DD.MM.YYYY")}</td>
                    <td>{polyglot.t(r.Status)}</td>
                    <td
                      className="table-td-cc"
                      className="cc-i-view-text cc-i-data-desc view-center-name data-align-time invoice-timerange"
                    >
                      <Tooltip content={TooltipTimerange(r)} placement="top">
                        <div className="cc-i-view-text">{r.time_range}</div>
                      </Tooltip>
                    </td>
                    <td className="cc-i-view-text cc-i-data-desc view-center-name data-align-time invoice-timerange">
                      {/* <div  title="View more" onClick={() => showModel(r)} className="appkiticon icon-vertical-more-fill cursor" ></div> */}
                      <div
                        className="cursor text-center"
                        title={polyglot.t("Download invoice")}
                        onClick={() => downloadInvoice(r)}
                      >
                        <ButtonIcon
                          iconImg={download}
                          alt="download"
                          ariaLabel={polyglot.t("Download invoice")}
                          title={polyglot.t("Download invoice")}
                        />
                      </div>
                    </td>
                  </tr>
                </thead>
              );
            })
          ) : (
            <tr>
              <td className="no-user-td" colSpan="12">
                <strong>{polyglot.t("No Invoice Found")}</strong>
              </td>
            </tr>
          )}
        </table>
        <>
          {list.length > PER_PAGE && (
            <Pagination
              current={currentPage}
              size="small"
              pageSize={PER_PAGE}
              total={list.length}
              onChange={handlePageChange}
            />
          )}
        </>
      </div>
    );
  };

  return (
    !isTechnicalClient && (
      <div>
        <div className="package-title mt-3 main_title_qb">{polyglot.t("List of Invoices")}</div>
        <div>
          {projectSelection()}
          <InvoiceGrid projectsList={defaultdata} />
          {fullInvoiceDisplay(selectedRowForPrev)}
        </div>
      </div>
    )
  );
}

export default ClientInvoicing;
