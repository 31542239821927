import displayToastMessage from "../../Components/QueenBase/Root/displayToastMessage";
import { LOGIN_PATH } from "../../Constants/GRCAssessmentPath";
import LoginService from "../QueenBase/Root/LoginService";

export const grc_logout_on_intrusion = async () => {
  /* sessionStorage.clear();
	localStorage.clear(); */
  sessionStorage.removeItem();
  window.location.href = `${process.env.REACT_APP_PROJECT_NAME}${LOGIN_PATH}`;
};
export const grc_expire_my_session = async () => {
  try {
    console.log("kj", grc_check_If_User_LoggedIn());
    const uid = grc_getUserIdFromSession();
    if (grc_check_If_User_LoggedIn()) {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = `${process.env.REACT_APP_GRC_ASSESSMENT_APPLICATION}/logout`;
    } else {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = `${process.env.REACT_APP_GRC_ASSESSMENT_APPLICATION}/PerformAuth/${uid}`;
    }
  } catch (err) {
    displayToastMessage(err.message, "error");
  }
};

export const grc_check_api_error_response = (errorMsg) => {
  if (errorMsg === "Request failed with status code 401") {
    console.log("Your Jwt is expired. Please login again");
    grc_expire_my_session();
  }
};

export const grc_getUserEmailFromSession = () => {
  const userEmail = sessionStorage.getItem("grc_recipient_user_email");
  return userEmail;
};

export const grc_getUserIdFromSession = () => {
  const userId = sessionStorage.getItem("grc_recipient_user_uid");
  return userId;
};
// CALL API TO REFRESH JWT TOKEN
export const refresh_jwt_token = async () => {
  try {
    const refreshedJwtToken = await LoginService.get().refreshJWT();
    if (refreshedJwtToken.token) {
      sessionStorage.removeItem("jwt");
      sessionStorage.setItem("jwt", refreshedJwtToken.token);
      return "SUCCESS";
    } else {
      grc_expire_my_session();
      return "FAILED";
    }
  } catch (err) {
    grc_expire_my_session();
    return "FAILED";
  }
};

export const grc_check_If_User_LoggedIn = () => {
  const userEmail = grc_getUserEmailFromSession();
  if (userEmail) {
    return true;
  }
  return false;
};
