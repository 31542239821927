import PropTypes from "prop-types";
import React from "react";

import ProjectList from "./ProjectList";
import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import { plusCircle } from "../../../Assets/Icons";
import { CREATE_PROJECT_PATH } from "../../../Constants/Path";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import useHasPermission from "../../../Services/Shared/hasPermission";

// RENDERS ON /queenbase/projects/viewprojects
// RETURNS ON PROJECT LIST
function ProjectSelection(props) {
  var polyglot = polyglotLoader();

  // NAVIGATE TO CREATE PROJECT FORM
  const addProject = () => {
    props.history.push({
      pathname: CREATE_PROJECT_PATH,
    });
  };
  return (
    <div>
      <div className="mt-3">
        <div className="m-l-1 m-b-18">
          {useHasPermission("Create") && (
            <ButtonPrimaryWithIcon
              iconName={plusCircle}
              onClick={addProject}
              ariaLabel={polyglot.t("Create project")}
            >
              {polyglot.t("Create project")}
            </ButtonPrimaryWithIcon>
          )}
        </div>
        <div className="project_container m-l-1">
          <ProjectList {...props} />
        </div>
      </div>
    </div>
  );
}
ProjectSelection.propTypes = {
  history: PropTypes.object,
};
export default ProjectSelection;
