import React, { Component } from "react";

import ModuleRoute from "../../Routes/DFModuleRoutes";
import NeedHelpHome from "../DFED/Support/NeedHelpHome";

import "../../Assets/Css/DF/Home.css";
import "../../Assets/Css/DF/Main.css";
import NavBar from "./NavBar";

// THE INITIAL COMPONENT WHICH RENDERS FOR DF MODULE
class Main extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight,
    };
    this.callResize = this.callResize.bind(this);
  }

  // ON CLICKING ON NEED HELP
  onNeedHelpClick = () => {
    if (this.state.showChatBotCard) {
      this.setState({
        showChatBotCard: false,
      });
    } else {
      this.setState({
        showChatBotCard: true,
      });
    }
  };
  // RUNS ON INITIAL LOAD
  componentDidMount() {
    window.addEventListener("resize", this.callResize);
  }

  // RESIZE THE CONTAINER HEIGHT
  callResize() {
    this.setState({
      height: window.innerHeight,
    });
  }
  // RUNS WHEN COMPONENT GOT UNMOUNTED
  componentWillUnmount() {
    window.removeEventListener("resize", this.callResize);
  }

  // RNDERS THE HTML OF THE COMPONENT
  render() {
    const dfContainerHeight = {
      minHeight: this.state.height + "px",
    };
    return (
      <div>
        <NavBar />
        <div>
          <div className="container col-md-10 screen-layout p-0" style={{ minHeight: "56vh" }}>
            <ModuleRoute />
          </div>
          <NeedHelpHome props={this.props} openFrom="DF" />
        </div>
      </div>
    );
  }
}

export default Main;
