import HttpClient from "../Shared/HttpClient";
import jwtUtil from "../../Constants/Jwt";
class SearchService {
  constructor() {
    this.client = new HttpClient();
  }

  // API TO FETCH SEARCH RESULTS FROM FIT SERVICE
  // NOTE: values will be in the below format
  // parameters = {
  // 	scoring: Number(),
  // 	sources: ['source1', 'source2']
  // }
  async getSearchResultFromFitService(searchString, parameters) {
    const project_id = sessionStorage.getItem("selectedProject");
    const body = {
      searchString, // receives a string
      parameters, // object
      project_id,
    };
    const encryptedBody = jwtUtil.encrypt(body);
    const result = await this.client.cssPost(`/getSearchResultFromFitService`, encryptedBody);
    return result;
  }

  async saveBulkUploadRequest(formData) {
    const result = await this.client.cssPost(`/saveBulkUploadData`, formData, {
      "Content-Type": "multipart/form-data",
    });
    return result;
  }

  // API TO FETCH Sources DETAILS
  async getSourcesList() {
    const result = await this.client.cssGet(`/getSourcesList`);
    return result;
  }

  // API TO FETCH Last Updated Date DETAILS
  async getLastUpdatedDate() {
    const result = await this.client.cssGet(`/getLastUpdatedDate`);
    return result;
  }
  // API TO FETCH Status DETAILS
  async fetchStatus() {
    const result = await this.client.cssGet(`/getStatusList`);
    return result;
  }

  // API TO FETCH  DETAILS
  async getDetailsData(searchUniqueID, searchResultID) {
    const result = await this.client.cssGet(`/getDetailsByID/${searchResultID}/${searchUniqueID}`);
    return result;
  }

  // API TO FETCH  AUDIT DETAILS
  async getAuditDetails(project_id) {
    const result = await this.client.cssPost(`/getAuditRecords`, { project_id });
    return result;
  }
  // API TO FETCH  ADMIN DETAILS
  async getBulkUploadRecords(project_id) {
    const result = await this.client.cssPost(`/getBulkUploadRecords`, { project_id });
    return result;
  }

  // API TO DOWNLOAD THE SELECTED SEARCH AUDIT
  async downloadSearchAuditByID(searchAuditID, body) {
    const result = await this.client.cssPost(`/downloadSearchAuditByID/${searchAuditID}`, body);
    return result;
  }
  // API TO FETCH TEMPLATE
  fetchTemplate = async (templateType) => {
    const result = await this.client.cssGet(`/downloadBulkUploadTemplate`, { templateType });
    return result;
  };
  // API TO DOWNLOAD USER UPLOADED FILE
  async downloadUserUploadedFile(body) {
    const result = await this.client.cssPost(`/downloadUserUploadedFile`, body);
    return result;
  }
  // API TO DOWNLOAD ADMIN UPLOADED FILE
  async downloadAdminUploadedFile(body) {
    const result = await this.client.cssPost(`/downloadAdminUploadedFile`, body);
    return result;
  }
  // API WHERE ADMIN WILL UPDATE THE RECORD
  async updateSearchAuditByAdmin(formData) {
    const result = await this.client.cssPost(`/updateSearchAuditByAdmin`, formData, {
      "Content-Type": "multipart/form-data",
    });
    return result;
  }
  // CHECK EXISTING NAME
  async checkRequestNameIfExists(body) {
    const result = await this.client.cssPost(`/checkRequestNameIfExists`, body);
    return result;
  }
}

SearchService.__instance = null;
SearchService.get = () => {
  if (!SearchService.__instance) {
    SearchService.__instance = new SearchService();
  }
  return SearchService.__instance;
};

export default SearchService;
