import "../../DFED/upload/Upload.css";
import "../../DFED/Shared/components/FormField.css";
import "../../DFED/Review/DocumentList/DocumentList.css";
import "./sharedStyles.css";
import PropTypes from "prop-types";
import React from "react";

import DropzonePdf from "./DropZonePdf";

const CustomDropZonePdf = (props) => {
  const { onFilesAdded, customDropZoneBoxStyle } = props;

  return (
    <DropzonePdf onFilesAdded={onFilesAdded} customDropZoneBoxStyle={customDropZoneBoxStyle} accept="pdf" />
  );
};

CustomDropZonePdf.propTypes = {
  onFilesAdded: PropTypes.any,
  customDropZoneBoxStyle: PropTypes.any,
};

export default CustomDropZonePdf;
