import { Select, SelectOption } from "appkit-react";
import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import Header from "./Header";
import logo from "../../../Assets/Icons/logo.png";
import * as path from "../../../Constants/Path";
import ApplicationCongif from "../../../Services/Shared/ApplicationCongif";
import { check_If_User_LoggedIn } from "../../../Services/Shared/Authenticate";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader, setCurrentLanguage } from "../../../Services/Shared/Translate";

const LoginPageHeader = (props) => {
  const { getUserLanguage, setUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage);

  const [applicationName, SetApplicationName] = useState();

  // API TO GET APPLICATION CONFIG DETAILS
  async function getApplicationConfig() {
    const config = await ApplicationCongif.get().getApplicationConfig();
    SetApplicationName(config.title);
  }
  useEffect(() => {
    getApplicationConfig();
  }, []);

  const home = () => {
    props.history.push(path.LOGIN_PATH);
    // window.location.reload()
  };
  const changeLanguage = (value) => {
    setCurrentLanguage(value);
    setUserLanguage(value);
  };
  const captureHomeKeyUp = (event) => {
    if (event.keyCode === 13) {
      return home();
    }
  };
  return (
    <header className="normal-header-container">
      <nav className="navbar navbar-expand-lg navbar-light white-header justify-content-between pt-0 pb-0">
        <div className="a-brand-container cursor" onKeyUp={captureHomeKeyUp} onClick={home}>
          <a className="navbar-brand" tabIndex="1" onClick={home}>
            <img className="logoimg" alt="logo" src={logo} title="PwC - Connected Digital Services" />
          </a>
        </div>
        <div className="a-actions-container" aria-label={polyglot.t("Change language")}>
          <div id="headerdownarrow">
            {getUserLanguage() === "ger" && (
              <Select
                defaultValue={getUserLanguage()}
                onSelect={changeLanguage}
                aria-label={polyglot.t("Change language")}
              >
                <SelectOption key={1} value={"en"}>
                  EN
                </SelectOption>
                <SelectOption key={2} value={"ger"}>
                  DE
                </SelectOption>
              </Select>
            )}
            {getUserLanguage() === "en" && (
              <Select
                defaultValue={getUserLanguage()}
                onSelect={changeLanguage}
                aria-label={polyglot.t("Change language")}
              >
                <SelectOption key={1} value={"en"}>
                  EN
                </SelectOption>
                <SelectOption key={2} value={"ger"}>
                  DE
                </SelectOption>
              </Select>
            )}
          </div>
        </div>
      </nav>
    </header>
  );
};

const HeaderContainer = (props) => {
  return (
    <>
      {!check_If_User_LoggedIn() && <LoginPageHeader {...props} />}
      {check_If_User_LoggedIn() && <Header {...props} />}
    </>
  );
};

export default withRouter(HeaderContainer);
