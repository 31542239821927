import React from "react";
import CommonButton from "../Shared/button/CommonButton";
import pipeline from "../../../Assets/Images/DFED/image-pipeline.svg";
import plus from "../../../Assets/Images/DFED/icon-add-plus.svg";
import newIcon from "../../../Assets/Images/DFED/icon-break.svg";
import redIcon from "../../../Assets/Images/DFED/icon-break-red.svg";
import "./fastforward.css";
import "../../../Assets/Css/DFED/Admin.css";
import "../../../Components/DFED/ReviewSelection/ReviewSelection.css";
import cancel from "../../../Assets/Images/DFED/icon-x.svg";
import BackButton from "../Shared/button/BackButton";
import { Select, SelectOption, Input } from "appkit-react";
import DualboxComponent from "../../DFED/Shared/DualboxComponent";
import ProcessingService from "../../../Services/DFED/ProcessingService";
import AnalysisService from "../../../Services/DFED/AnalysisService";
import "../../DFED/Processing/Processing.css";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import Pipeline from "./Pipeline";
import FastForwardService from "../../../Services/DFED/FastForwardService";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import NoAccess from "../../../Assets/Components/NoAccess";
import commonFunctionForCallService from "../../../utility/commonFunctionForCallService";
import * as module_Solution_Name from "../../../Constants/moduleName";
import { checkPermission } from "../../../utility/checkPermission";
import {
  TextFieldLengthValidation,
  TextFieldLengthRegexValidation,
} from "../../../utility/textFieldValidation";
import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import { plusCircle, closeIcon, play_outline_Icon } from "../../../Assets/Icons";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import ConfirmBox from "./ConfirmBoxPipeLine";

const analysisService = new AnalysisService();
var polyglot = polyglotLoader();
class Fastforward extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPipeline: false,
      defaultActivePanel: "1",
      showCollectionData: false,
      showBackButton: false,
      showProfileData: false,
      propfileDropdownValue: [],
      selectedProfile: [],
      visibleTermsModel: false,
      selectedData: props.location.state.data,
      selectedProjectId: props.location.state.selectedProjectId,
      ReviewSelectionResult: [],
      keywordsData: [],
      custodianData: [],
      datasetData: [],
      ReviewSelectionKeywordList: [],
      addedReviewName: "",
      profileName: "Choose Processing",
      keywordName: "Choose KeywordList",
      showKeywordData: false,
      showPipelineList: true,
      reviewGridData: [],
      keywordDropdownValue: [],
      selectedKeyword: [],
      dataCustodian: "Choose Data",
      breakConditionProfile: "No break",
      keywordBreak: "No break",
      reviewBreak: "No break",
      numberOfCustodian: [],
      pipelineTitle: "",
      pipelineStart: "Start",
      isEnable: false,
      reviewName: "",
      showReviewData: false,
      arrayCustodian: [],
      pipelineList: [],
      selectedKeyworIdFromDropDown: "",
      selectedProfileIdFromDropDown: "",
      rightSideProcessingCustodianOriginal: [],
      leftSideAvailableCustodian: [],
      rolePermissionForFastForWard: {},
      isViewPermission: true,
      textFieldLengthError: false,
      textFieldCharError: false,
    };
    this.checkPipelineFormField = this.checkPipelineFormField.bind(this);
  }
  static contextType = DataContext;
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getApplicationRolesWithPermission();
    this.getPipeline();
    this.getProfile();
    this.getKeyWords();
    this.getKeyWordlist();
  }
  checkIfFormIsEmpty = (prevState) => {
    try {
      let isFormEmpty = true;
      if (
        prevState.pipelineTitle !== this.state.pipelineTitle ||
        prevState.showReviewData !== this.state.showReviewData ||
        prevState.showKeywordData !== this.state.showKeywordData ||
        prevState.showCollectionData !== this.state.showCollectionData ||
        prevState.showProfileData !== this.state.showProfileData
      ) {
        isFormEmpty = false;
      } else {
        isFormEmpty = true;
      }
      return isFormEmpty;
    } catch (err) {
      displayToastMessage(err.message, "error");
      return true;
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (!this.checkIfFormIsEmpty(prevState)) {
      const { formGotOpened } = this.context;
      formGotOpened();
    }
  }
  getApplicationRolesWithPermission = async () => {
    let selectedProjectId = this.state.selectedProjectId;
    let permissionData = await commonFunctionForCallService.commonFunctionForCallService(
      module_Solution_Name.DFED_SOLUTION,
      selectedProjectId,
      module_Solution_Name.FAST_FORWARD_MODULE,
    );
    if (permissionData) {
      this.setState({
        rolePermissionForFastForWard: permissionData,
      });
    }
  };
  getProfile() {
    const processingService = new ProcessingService();
    processingService
      .getProfile(this.state.selectedProjectId)
      .then((result) => {
        if (result && result.data && result.data.length > 0) {
          this.setState(
            {
              propfileDropdownValue: result.data,
            },
            () => {},
          );
        }
      })
      .catch((err) => {});
  }
  getKeyWords = () => {
    analysisService
      .getKeyWords(this.state.selectedProjectId)
      .then((result) => {
        if (result.data && result.data.length > 0) {
          this.setState({
            keywordDropdownValue: result.data,
          });
        } else {
          return null;
        }
      })
      .catch((err) => {});
  };
  //get keywordlist data
  getKeyWordlist = (keywordId) => {
    if (keywordId) {
      const fastForwardService = new FastForwardService();
      fastForwardService
        .fetchPipelineKeywordlist(this.state.selectedProjectId, keywordId)
        .then((result) => {
          if (result.data && result.data.length > 0) {
            this.setState({
              ReviewSelectionKeywordList: JSON.parse(result.data[0].KEYWORD),
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  onChangeKeywords(data) {
    this.setState({ keywordsData: data }, () => {
      this.setState({
        isEnable: this.checkPipelineFormField() ? true : false,
      });
    });
  }
  custodianDataSelection = (arg, leftSideAvailableCustodian, rightSideProcessingCustodianOriginal) => {
    this.setState(
      {
        showCollectionData: false,
        dataCustodian: "custodians",
        arrayCustodian: arg,
        leftSideAvailableCustodian: leftSideAvailableCustodian,
        rightSideProcessingCustodianOriginal: rightSideProcessingCustodianOriginal,
      },
      () => {
        this.setState({
          isEnable: this.checkPipelineFormField() ? true : false,
        });
      },
    );
  };
  // CHECK IF CUSTODIAN RECORDS ARE AVAILABLE
  checkIfCustodianRecordsAreAvailable = async () => {
    try {
      const processingService = new ProcessingService();
      let selectedProjectId = this.state.selectedProjectId;
      const apiResponse = await processingService.getProcessingCustodianRecords(selectedProjectId);
      if (apiResponse && apiResponse.data && apiResponse.data.length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
  addPipeline = async () => {
    var polyglot = polyglotLoader();
    const isAvailable = await this.checkIfCustodianRecordsAreAvailable();
    if (isAvailable) {
      this.setState({
        showPipeline: true,
        showPipelineList: false,
        showBackButton: true,
        pipelineTitle: "",
        selectedKeyworIdFromDropDown: "",
        keywordsData: [],
        selectedProfileIdFromDropDown: "",
        arrayCustodian: [],
        showReviewData: false,
        addedReviewName: "",
        profileName: "Choose Processing",
        keywordName: "Choose KeywordList",
        rightSideProcessingCustodianOriginal: [],
        leftSideAvailableCustodian: [],
        openConfirmBox: false,
      });
    } else {
      displayToastMessage(polyglot.t("Please add datasets before adding a new pipeline"), "error");
    }
  };
  reviewTitle = (e) => {
    this.setState({
      reviewName: e,
    });
  };
  getPipeline() {
    const fastForwardService = new FastForwardService();
    let projectID = this.state.selectedProjectId;
    fastForwardService.fetchPipeline(projectID).then((res) => {
      if (res && res.data && res.data.length > 0) {
        this.setState({
          pipelineList: res.data,
          showPipelineList: true,
        });
      }
    });
  }
  pipelineStart = () => {
    const { formGotClosed } = this.context;
    if (this.state.arrayCustodian.length === 0) {
      this.setState({ error: polyglot.t("Please select custodian"), isEnable: false });
      return;
    }
    if (this.state.arrayCustodian.length === 0) {
      this.setState({ error: polyglot.t("Please select profile"), isEnable: false });
      return;
    }
    if (this.state.pipelineTitle === "") {
      this.setState({ error: polyglot.t("Please select custodian"), isEnable: false });
      return;
    }

    let pipeLineneObj = {
      pipeline_name: this.state.pipelineTitle,
      break_analysis: this.state.keywordBreak === "Break" ? 0 : 1,
      break_review: this.state.reviewBreak === "Break" ? 0 : 1,
      keyword_id:
        this.state.selectedKeyworIdFromDropDown && this.state.selectedKeyworIdFromDropDown !== ""
          ? this.state.selectedKeyworIdFromDropDown
          : null,
      project_id: this.state.selectedProjectId,
      review_status: this.state.reviewBreak === "Break" ? 1 : 2,
      processing_status: 2,
      analysis_status: this.state.keywordBreak === "Break" ? 1 : 2,
      REVIEW_NAME: this.state.addedReviewName,
      REVIEW_SUB_KEYWORD_LIST_ID: this.state.keywordsData,
      selectedProfileId: this.state.selectedProfileIdFromDropDown,
    };
    const fastForwardService = new FastForwardService();
    let formData = new FormData();
    formData.append("pipelineData", JSON.stringify(pipeLineneObj));
    formData.append("processingData", JSON.stringify(this.state.arrayCustodian));
    formData.append("selectedProjectId", this.state.selectedProjectId);
    fastForwardService
      .savePipelineData(formData)
      .then((result) => {
        this.getPipeline();
        if (result) {
          displayToastMessage(polyglot.t("Pipeline initiated successfully"), "success");
          formGotClosed();
          this.setState({
            arrayCustodian: result.data,
            showBackButton: false,
            keywordBreak: "Break",
            reviewBreak: "Break",
            textFieldCharError: false,
          });
        }
      })
      .catch((err) => {
        alert(err);
      });
  };
  chooseProfileHandler = () => {
    this.setState({
      showProfileData: true,
      showCollectionData: false,
      showKeywordData: false,
      showReviewData: false,
    });
  };
  newProfilelist = () => {
    this.props.history.push({
      pathname: "/dfed/profileList",
      state: { selectedProjectId: this.state.selectedProjectId, isFromCreateNewList: true },
    });
  };
  chooseReviewHandler = () => {
    this.setState({
      showProfileData: false,
      showCollectionData: false,
      showKeywordData: false,
      showReviewData: true,
    });
  };
  pipelineTitleChange = (e) => {
    if (!TextFieldLengthValidation(e.target.value)) {
      this.setState({ textFieldLengthError: true });
    } else {
      if (!TextFieldLengthRegexValidation(e.target.value) && e.target.value !== "") {
        displayToastMessage(polyglot.t("name_validaton"), "error");

        this.setState({
          isEnable: false,
          pipelineTitle: e.target.value,
          textFieldLengthError: false,
          textFieldCharError: true,
        });
      } else {
        this.setState(
          {
            pipelineTitle: e.target.value,
            addedReviewName: e.target.value,
            textFieldLengthError: false,
            textFieldCharError: false,
          },
          () => {
            this.setState({
              isEnable: this.checkPipelineFormField() ? true : false,
            });
          },
        );
      }
    }
  };
  profileRedIcon = () => {
    this.setState({
      breakConditionProfile: "",
    });
  };

  profileNewIcon = () => {
    this.setState({
      breakConditionProfile: "Break",
    });
  };

  backButton = () => {
    this.setState({
      showBackButton: false,
      showPipelineList: true,
      showPipeline: false,
    });
  };

  keywordRedIcon = () => {
    this.setState(
      {
        keywordBreak: "No break",
      },
      () => {
        this.setState({
          isEnable: this.checkPipelineFormField() ? true : false,
        });
      },
    );
  };
  keywordNewIcon = () => {
    this.setState(
      {
        keywordBreak: "Break",
        showKeywordData: false,
        selectedKeyworIdFromDropDown: "",
        keywordName: "Choose KeywordList",
        keywordsData: [],
        addedReviewName: "",
        reviewBreak: "Break",
      },
      () => {
        this.setState({
          isEnable: this.checkPipelineFormField() ? true : false,
        });
      },
    );
  };
  reviewRedIcon = () => {
    this.setState(
      {
        reviewBreak: "No break",
        addedReviewName: this.state.pipelineTitle,
      },
      () => {
        this.setState({
          isEnable: this.checkPipelineFormField() ? true : false,
        });
      },
    );
  };
  reviewNewIcon = () => {
    this.setState(
      {
        reviewBreak: "Break",
        keywordsData: [],
        addedReviewName: "",
      },
      () => {
        this.setState({
          isEnable: this.checkPipelineFormField() ? true : false,
        });
      },
    );
  };
  chooseKeywordHandler = () => {
    this.setState(
      {
        showKeywordData: true,
        showCollectionData: false,
        showProfileData: false,
        showReviewData: false,
      },
      () => {
        this.setState({
          isEnable: this.checkPipelineFormField() ? true : false,
        });
      },
    );
  };
  newKeywordlist = () => {
    this.props.history.push({
      pathname: "/dfed/keywords",
      state: { selectedProjectId: this.state.selectedProjectId, isFromCreateNewList: true },
    });
  };
  collectioHandler = () => {
    this.setState({
      showCollectionData: true,
      showKeywordData: false,
      showProfileData: false,
      showReviewData: false,
    });
  };
  onChangeProfile(profileID) {
    let selectedProfile = "";
    let selectedProfileIdFromDropDown = "";

    let selectedProfileIndex = this.state.propfileDropdownValue.findIndex(function (value) {
      return value.value === profileID;
    });
    if (selectedProfileIndex !== -1) {
      selectedProfile = this.state.propfileDropdownValue[selectedProfileIndex].label;
      selectedProfileIdFromDropDown = this.state.propfileDropdownValue[selectedProfileIndex].value;
    }
    this.setState(
      {
        profileName: selectedProfile,
        showProfileData: false,
        selectedProfileIdFromDropDown: selectedProfileIdFromDropDown,
      },
      () => {
        this.setState({
          isEnable: this.checkPipelineFormField() ? true : false,
        });
      },
    );
  }
  onChangeKeyword(keyWordId) {
    let selectedKewordName = "";
    // FIND INDEX FOR KEYWORD LIST
    let selectedKeywordIndex = this.state.keywordDropdownValue.findIndex(function (value) {
      return value.ID === keyWordId;
    });

    if (selectedKeywordIndex !== -1) {
      selectedKewordName = this.state.keywordDropdownValue[selectedKeywordIndex].LIST_NAME;
    }
    if (keyWordId === "-1") {
      selectedKewordName = "Boost it with AIRO";
    }
    if (keyWordId === "-2") {
      selectedKewordName = "Without keyword list";
    }
    if (keyWordId == "-1" || keyWordId == "-2") {
      this.setState(
        {
          keywordName: selectedKewordName,
          showKeywordData: false,
          selectedKeyworIdFromDropDown: keyWordId,
          keywordsData: keyWordId,
        },
        () => {
          this.setState({
            isEnable: this.checkPipelineFormField() ? true : false,
          });
        },
      );
    } else {
      this.setState(
        {
          keywordName: selectedKewordName,
          showKeywordData: false,
          selectedKeyworIdFromDropDown: keyWordId,
          keywordsData: [],
        },
        () => {
          this.setState({
            isEnable: this.checkPipelineFormField() ? true : false,
          });
        },
      );
    }
    this.getKeyWordlist(keyWordId);
  }
  cancel = () => {
    this.setState({
      showReviewData: false,
    });
  };
  getNotificationErrorData(messageData) {
    return {
      message: messageData,
      duration: 5,
      className: "errorMessageModal",
      style: {
        color: "blue !important",
      },
    };
  }
  reviewnameOnChng = (e) => {
    if (e) {
      this.setState(
        {
          addedReviewName: e,
        },
        () => {
          this.setState({
            isEnable: this.checkPipelineFormField() ? true : false,
          });
        },
      );
    }
  };
  onChangeCustodian(data) {
    this.setState({ custodianData: data }, () => {
      this.setState({
        isEnable: this.checkPipelineFormField() ? true : false,
      });
      this.ReviewSelectionDataInfo();
    });
  }
  onChangeDataset(data) {
    this.setState({ datasetData: data, isEnable: this.checkPipelineFormField() ? true : false }, () => {});
  }
  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  toggleConfirmBox = () => {
    this.setState({
      openConfirmBox: true,
    });
  };
  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  ConfirmBox = () => {
    this.setState({
      openConfirmBox: false,
    });
  };

  checkPipelineFormField() {
    if (this.state.textFieldCharError) {
      return false;
    }
    if (this.state.keywordBreak === "No break") {
      if (this.state.reviewBreak === "No break") {
        if (this.state.selectedKeyworIdFromDropDown) {
          if (
            this.state.arrayCustodian &&
            this.state.arrayCustodian.length > 0 &&
            this.state.pipelineTitle !== "" &&
            this.state.selectedKeyworIdFromDropDown &&
            this.state.selectedProfileIdFromDropDown !== "" &&
            this.state.selectedProfileIdFromDropDown &&
            this.state.selectedProfileIdFromDropDown !== "" &&
            this.state.keywordsData &&
            this.state.keywordsData.length > 0
          ) {
            return true;
          } else {
            return false;
          }
        }
      } else {
        if (this.state.selectedKeyworIdFromDropDown) {
          if (
            this.state.arrayCustodian &&
            this.state.arrayCustodian.length > 0 &&
            this.state.pipelineTitle !== "" &&
            this.state.selectedKeyworIdFromDropDown &&
            this.state.selectedProfileIdFromDropDown !== "" &&
            this.state.selectedProfileIdFromDropDown &&
            this.state.selectedProfileIdFromDropDown !== ""
          ) {
            return true;
          } else {
            return false;
          }
        } else {
          return false;
        }
      }
    }

    if (this.state.keywordBreak === "Break") {
      if (
        this.state.arrayCustodian &&
        this.state.arrayCustodian.length > 0 &&
        this.state.pipelineTitle !== "" &&
        this.state.selectedProfileIdFromDropDown &&
        this.state.selectedProfileIdFromDropDown !== ""
      ) {
        return true;
      } else {
        return false;
      }
    }
  }
  render() {
    let polyglot = polyglotLoader();
    let pipelineHome = (
      <div className="container-fluid p-0">
        <h5>{polyglot.t("Create a process pipeline")}</h5>
        <div className="row m-l-0 m-r-0 m-b-18">
          {checkPermission(
            this.state.rolePermissionForFastForWard,
            module_Solution_Name.FAST_FORWARD_MODULE,
            "Create",
          ) && (
            <>
              {!this.state.showBackButton && (
                <div className="col-md-3 pl-0">
                  <ButtonPrimaryWithIcon
                    iconName={plusCircle}
                    onClick={this.addPipeline}
                    ariaLabel={polyglot.t("Add a pipeline")}
                  >
                    {polyglot.t("Add a pipeline")}
                  </ButtonPrimaryWithIcon>
                </div>
              )}
            </>
          )}

          {this.state.showBackButton ? (
            <div className="col-md-3 pl-0">
              <span>
                {" "}
                <BackButton onBackButtonClick={this.backButton} buttonText={polyglot.t("Back")}></BackButton>
              </span>
            </div>
          ) : (
            ""
          )}
        </div>
        {this.state.pipelineList.length === 0 && this.state.showPipeline === false ? (
          <div>
            <div className="row">
              <div className=" col-md-4"></div>
              <div className=" col-md-4 pipeline-image mt-5">
                <img className="image-pipeline" src={pipeline} alt="edit_icon" />
              </div>

              <div className=" col-md-4"></div>
            </div>
            <div className="pipeline-dashboard">
              <h5>{polyglot.t("Automate by defining a pipeline")}</h5>
            </div>
            <div className="pipeline-dashboard">
              <p className="description-text-pipeline">
                {polyglot.t("Create a process pipeline outlining the approach at each stage.")}
                <br />
                {polyglot.t("Once you've started the process, just sit back and wait for the")}
                <br />
                {polyglot.t("email confirming your review is finished.")}
              </p>
              <br />
            </div>
          </div>
        ) : (
          <div>
            {this.state.pipelineList && this.state.pipelineList.length > 0 && this.state.showPipelineList ? (
              <div>
                <Pipeline
                  pipelineList={this.state.pipelineList}
                  selectedProjectId={this.state.selectedProjectId}
                />
              </div>
            ) : (
              <div>
                {this.state.showPipeline ? (
                  <div>
                    <div className="mt-4">
                      <p className="pl-0 description-text-pipeline">
                        {polyglot.t(
                          "To use a pipeline, please link datasets to custodians, create the necessary processing profiles and set up the right keyword lists.",
                        )}
                        <br />
                        {polyglot.t(
                          "Once you’ve configured the individual steps, go to the relevant pipeline tab and click on 'Start'. We’ll send you an email once the pipeline is complete.",
                        )}
                      </p>
                    </div>
                    <div className="col-md-12">
                      <div>
                        <div className="row head-row-border">
                          <div className="col-md-3 upload-box-col p-0">
                            <input
                              value={this.state.pipelineTitle}
                              type="text"
                              aria-label={polyglot.t("Enter Pipeline name")}
                              onChange={(e) => this.pipelineTitleChange(e)}
                              placeholder={polyglot.t("Enter Pipeline name")}
                              className="p-0  font20Helve pipeline-input des-col pipeline-input ans-name a-input-text"
                              id="pipelineNameID"
                              autoFocus
                            />
                            {this.state.textFieldLengthError ? (
                              <p className="txt-field-error m-0 pt-14 pl-3">
                                {polyglot.t("Maximum character length is 40")}
                              </p>
                            ) : null}
                            {this.state.pipelineTitle === "" ? null : (
                              <div style={{ width: 150, display: "none" }} className="tex-ali-l">
                                <CommonButton
                                  name={"Start processing"}
                                  height={30}
                                  fontSize={12}
                                ></CommonButton>
                              </div>
                            )}
                          </div>
                          <div className="col-md-6"></div>
                        </div>
                      </div>
                      {/* } */}

                      <div className="row m-t-16 fastfwd-create-form-flex">
                        <div className="col-md-2 button-fast-forward p-l-0">
                          <p>
                            <span>{polyglot.t("Collection")}</span>
                            <span className="m-1">
                              <img src={plus} alt="plus" />
                            </span>
                          </p>
                          <div>
                            <div>
                              <button className="pipeline-button" onClick={this.collectioHandler}>
                                {this.state.arrayCustodian.length > 0 && this.state.arrayCustodian.length}{" "}
                                &nbsp;
                                {this.state.dataCustodian === "Choose Data"
                                  ? polyglot.t("Choose data")
                                  : polyglot.t("datasets")}
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-2 button-fast-forward">
                          <p>
                            <span>{polyglot.t("Processing")}</span>
                            <span className="m-1">
                              <img src={plus} alt="plus" />
                            </span>
                          </p>

                          <div>
                            <button className="pipeline-button" onClick={this.chooseProfileHandler}>
                              {this.state.profileName === "Choose Processing"
                                ? polyglot.t("Choose profile")
                                : this.state.profileName}
                            </button>
                          </div>
                          {this.state.keywordBreak === "Break" && (
                            <p className="pipeline-break">
                              <img src={redIcon} alt="redIcon" onClick={this.keywordRedIcon} />
                            </p>
                          )}
                          {this.state.keywordBreak === "No break" && (
                            <p className="pipeline-break">
                              <img src={newIcon} alt="newIcon" onClick={this.keywordNewIcon} />
                            </p>
                          )}
                          <p
                            className={
                              this.state.keywordBreak === "Break"
                                ? "fastforward-break m-r-minus-13"
                                : "fastforward-break"
                            }
                          >
                            {this.state.keywordBreak === "Break"
                              ? polyglot.t("Break")
                              : polyglot.t("No break")}
                          </p>
                        </div>
                        <div className="col-md-2 button-fast-forward">
                          <p>
                            <span>{polyglot.t("Analysis")}</span>
                            <span className="m-1">
                              <img src={plus} alt="plus" />
                            </span>
                          </p>

                          {this.state.keywordBreak === "Break" && (
                            <div>
                              <button className="pipeline-button no-pointer">
                                {this.state.keywordName === "Choose KeywordList"
                                  ? polyglot.t("Choose keyword list")
                                  : this.state.keywordName}
                              </button>
                            </div>
                          )}
                          {this.state.keywordBreak === "No break" && (
                            <div>
                              <button className="pipeline-button" onClick={this.chooseKeywordHandler}>
                                {this.state.keywordName === "Choose KeywordList"
                                  ? polyglot.t("Choose keyword list")
                                  : this.state.keywordName}
                              </button>
                            </div>
                          )}

                          {this.state.reviewBreak === "Break" && (
                            <p className="pipeline-break">
                              <img
                                className="break-border"
                                src={redIcon}
                                alt="redIcon"
                                onClick={this.reviewRedIcon}
                              />
                            </p>
                          )}
                          {this.state.reviewBreak === "No break" && (
                            <p className="pipeline-break">
                              <img
                                className="break-border"
                                src={newIcon}
                                alt="newIcon"
                                onClick={this.reviewNewIcon}
                              />
                            </p>
                          )}
                          <p
                            className={
                              this.state.reviewBreak === "Break"
                                ? "fastforward-break m-r-minus-13"
                                : "fastforward-break"
                            }
                          >
                            {this.state.reviewBreak === "Break"
                              ? polyglot.t("Break")
                              : polyglot.t("No break")}
                          </p>
                        </div>

                        <div className="col-md-2 button-fast-forward ">
                          <p>
                            <span>{polyglot.t("Review")}</span>
                          </p>
                          {(this.state.keywordBreak === "Break" || this.state.reviewBreak === "Break") && (
                            <div>
                              <button className="pipeline-button no-pointer">{polyglot.t("Review")}</button>
                            </div>
                          )}
                          {this.state.keywordBreak === "No break" && this.state.reviewBreak === "No break" && (
                            <div>
                              <button className="pipeline-button" onClick={this.chooseReviewHandler}>
                                {polyglot.t("Review")}
                              </button>
                            </div>
                          )}
                        </div>
                        <div className="col-md-2 button-fast-forward pipeline-red-start">
                          <ButtonPrimaryWithIcon
                            disabled={!this.state.isEnable}
                            onClick={this.toggleConfirmBox}
                            iconName={this.state.pipelineStart === "Start" && play_outline_Icon}
                            ariaLabel={polyglot.t("Start")}
                          >
                            {polyglot.t("Start")}
                          </ButtonPrimaryWithIcon>
                        </div>
                      </div>
                      <ConfirmBox
                        show={this.state.openConfirmBox}
                        heading={polyglot.t("Pipeline confirmation")}
                        cancel={this.ConfirmBox}
                        proceed={this.pipelineStart}
                      />
                    </div>
                    {this.state.showCollectionData ? (
                      <div className="col-md-12 mt-4 p-l-0">
                        <DualboxComponent
                          selectedProjectId={this.state.selectedProjectId}
                          custodianData={this.custodianDataSelection}
                          rightSideProcessingCustodianOriginal={
                            this.state.rightSideProcessingCustodianOriginal
                          }
                          leftSideAvailableCustodian={this.state.leftSideAvailableCustodian}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.showProfileData ? (
                      <div className="row">
                        <div className="col-md-2"></div>
                        <div id="selectProfile1" className="col-md-3 select-profile-fastforward">
                          <Select
                            className="bg-white-select"
                            aria-label={polyglot.t("Processing profile")}
                            placeholder={polyglot.t("Processing profile")}
                            onSelect={(e) => this.onChangeProfile(e)}
                            value={this.state.selectedProfileIdFromDropDown}
                            style={{ marginTop: 30 }}
                          >
                            <hr className="" />
                            {this.state.propfileDropdownValue &&
                              this.state.propfileDropdownValue.length > 0 &&
                              this.state.propfileDropdownValue.map((data) => {
                                return (
                                  <SelectOption key={data.value} aria-label={data.value} value={data.value}>
                                    {data.label}
                                  </SelectOption>
                                );
                              })}
                          </Select>
                        </div>
                        <div className="col-md-7"></div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.showKeywordData ? (
                      <div>
                        <div className="row">
                          <div className="col-md-4"></div>
                          <div id="selectKywordFastForward1" className="col-md-3 select-keyword-fastforward ">
                            <Select
                              placeholder={polyglot.t("Choose keyword list...")}
                              onSelect={(e) => this.onChangeKeyword(e)}
                              style={{
                                backgroundColor: "#f2f2f2",
                                marginTop: 30,
                              }}
                              value={this.state.selectedKeyworIdFromDropDown}
                            >
                              <SelectOption value={"-2"}>{polyglot.t("Without keyword list")}</SelectOption>
                              <SelectOption value={"-1"}>{polyglot.t("Boost it with AIRO")}</SelectOption>
                              <hr className="" />
                              {this.state.keywordDropdownValue &&
                                this.state.keywordDropdownValue.length > 0 &&
                                this.state.keywordDropdownValue.map((data) => {
                                  return (
                                    <SelectOption key={data.ID} value={data.ID}>
                                      {data.LIST_NAME}
                                    </SelectOption>
                                  );
                                })}
                            </Select>
                          </div>
                          <div className="col-md-5"></div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                    {this.state.showReviewData ? (
                      <div>
                        <div className="row mt-4">
                          <div className="col-md-6 p-0"></div>
                          <div className="col-md-5 p-0 review-panel" id="review-panel">
                            <div className="a-panel a-p-10 m-r-27" style={{ marginTop: 5 }}>
                              <div className="row">
                                <div className="col-md-5"></div>
                                <div className="col-md-7 text-align-right">
                                  <ButtonIcon
                                    alt="cancel"
                                    onClick={this.cancel}
                                    iconName={closeIcon}
                                    ariaLabel={polyglot.t("Cancel")}
                                    title={polyglot.t("Cancel")}
                                  />
                                </div>
                              </div>
                              <div className="row mrgnBtm10">
                                <div className="col-md-4 labelDiv  p-t-13">
                                  <span className="editTest-align">{polyglot.t("Review Name :")}</span>
                                </div>
                                <div className="col-md-8 mb-2 admin-editinputboxes_align">
                                  <Input
                                    className="review-text-box"
                                    aria-label={polyglot.t("Enter review name")}
                                    placeholder={polyglot.t("Enter review name")}
                                    value={this.state.addedReviewName}
                                    onChange={this.reviewnameOnChng.bind(this)}
                                    disabled={true}
                                  />
                                </div>
                              </div>
                              <div className="row mrgnBtm10">
                                <div className="col-md-4 labelDiv p-t-13">
                                  <span className="editTest-align">{polyglot.t("Keyword :")}</span>
                                </div>
                                <div className="col-md-8  ">
                                  {this.state.ReviewSelectionKeywordList &&
                                  this.state.ReviewSelectionKeywordList.length > 0 ? (
                                    <Select
                                      onSelect={(e) => this.onChangeKeywords(e)}
                                      placeholder={polyglot.t("Select keywords")}
                                      multiple
                                      showSelectAll
                                      value={this.state.keywordsData}
                                    >
                                      {this.state.ReviewSelectionKeywordList &&
                                        this.state.ReviewSelectionKeywordList[0].B.map((data, index) => {
                                          return (
                                            <SelectOption key={index} value={data.ID}>
                                              {data.KEYWORD}
                                            </SelectOption>
                                          );
                                        })}
                                    </Select>
                                  ) : this.state.selectedKeyworIdFromDropDown == "-2" ? (
                                    <div className="pipeLineKeyword">
                                      {" "}
                                      {polyglot.t("Without keyword list")}{" "}
                                    </div>
                                  ) : this.state.selectedKeyworIdFromDropDown == "-1" ? (
                                    <div className="pipeLineKeyword">
                                      {" "}
                                      {polyglot.t("Boost it with AIRO")}{" "}
                                    </div>
                                  ) : (
                                    <div className="pipeLineKeyword"> {polyglot.t("No data found")} </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        )}
      </div>
    );

    if (this.state.isViewPermission === false) {
      return <NoAccess />;
    } else {
      return pipelineHome;
    }
  }
}
export default Fastforward;
