import { Button } from "appkit-react";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";

import CustomModal from "../../../Assets/Components/Modal";
import httpRequest from "../../../Services/Shared/HttpClient";
import { polyglotLoader, userLanguage } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";

// POPUP WHICH SHOWS ON CLICKING ON DELETE PROJECT ICON
function DeleteProject({ show, deleteProjectModule, Item, fetchProjectsAfterDelete, toggleDeleteAlert }) {
  var polyglot = polyglotLoader();
  const HttpRequest = new httpRequest();

  // DELETE PROJECT API
  /* PASSING: projectid: string */
  const OnDelete = async (ProjectTodelete) => {
    try {
      const body = {
        projectid: ProjectTodelete.PROJECTID,
      };
      let data = await HttpRequest.post("/DeleteProject", body);
      if (data.CODE === 1) {
        toggleDeleteAlert(false);
        fetchProjectsAfterDelete();
        displayToastMessage(
          `${polyglot.t("Project")} '${ProjectTodelete.NAME}' ${polyglot.t("deleted successfully")}`,
          "success",
        );
      }
    } catch (error) {
      displayToastMessage(error, "error");
    }
  };

  return (
    <div>
      <CustomModal
        show={show}
        onCancel={() => deleteProjectModule(Item)}
        title={polyglot.t("Delete project")}
        Footer={[
          <Button size="lg" className="primary_button" onClick={() => OnDelete(Item)} key="1">
            {polyglot.t("Yes,delete the Project")}
          </Button>,
        ]}
      >
        <>
          <div className="row">
            <div className="col-md-12">
              <p>
                {polyglot.t(
                  "All data related to the project will be deleted and will no longer be available",
                )}
                .
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 word-break">
              {userLanguage() === "en" && (
                <>
                  {polyglot.t("Are you sure you want to continue to delete the project")}{" "}
                  <strong>{Item.NAME}</strong>?
                </>
              )}
              {userLanguage() === "ger" && (
                <>
                  {polyglot.t("delete_project_text")} <strong>{Item.NAME}</strong>{" "}
                  {polyglot.t("fortfahren wollen")}?
                </>
              )}
            </div>
          </div>
        </>
      </CustomModal>
    </div>
  );
}
DeleteProject.propTypes = {
  history: PropTypes.object,
  show: PropTypes.bool,
  deleteProjectModule: PropTypes.func,
  Item: PropTypes.object,
  fetchProjectsAfterDelete: PropTypes.func,
  toggleDeleteAlert: PropTypes.func,
};
export default withRouter(DeleteProject);
