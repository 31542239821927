import { Panel, Tooltip } from "appkit-react";
import PropTypes from "prop-types";
import React from "react";
import { Input } from "reactstrap";

import { polyglotLoader } from "../../../Services/Shared/Translate";

function KeywordCard(props) {
  const polyglot = polyglotLoader();
  const { LIST_NAME, genExtra, editMode, updateKeywordNameOnEdit, updatedKeywordName } = { ...props };

  // VIEW KEYWORD NAME ON HOVER
  const TooltipName = (LIST_NAME) => {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#000",
                  textTransform: "capitalize",
                }}
              >
                {LIST_NAME}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const changeKeywordName = (e) => {
    updateKeywordNameOnEdit(e.target.value);
  };

  return (
    <Panel className="a-panel-project">
      <div className="a-text-heading  a-mb-10">
        <div className="row">
          <div className="col-md-3 ">
            {editMode && (
              <div className="view-center-name">
                <Input
                  type="text"
                  className=""
                  value={updatedKeywordName}
                  aria-label={updatedKeywordName}
                  onChange={changeKeywordName}
                  autoFocus
                  placeholder={polyglot.t("Keyword name")}
                />
              </div>
            )}
            {!editMode && (
              <Tooltip content={TooltipName(LIST_NAME)} placement="top">
                <div className="project-title-overflow f-acc view-center-name project-name">{LIST_NAME}</div>
              </Tooltip>
            )}
          </div>
          <div className="col-md-3 m-auto"></div>
          <div className="col-md-3 m-auto"></div>
          <div className="col-md-3 m-auto">{genExtra}</div>
        </div>
        {props.children}
      </div>
    </Panel>
  );
}

KeywordCard.propTypes = {
  LIST_NAME: PropTypes.string,
  editMode: PropTypes.bool,
  updatedKeywordName: PropTypes.string,
};

KeywordCard.defaultProps = {
  LIST_NAME: "keyword name",
  editMode: false,
  updatedKeywordName: "",
};

export default KeywordCard;
