import { ButtonGroup, ButtonGroupItem } from "appkit-react";
import PropTypes from "prop-types";
import React from "react";

import displayToastMessage from "../../../Components/QueenBase/Root/displayToastMessage";
import { polyglotLoader } from "../../../Services/Shared/Translate";

const ButtonGroupCustom = (props) => {
  const polyglot = polyglotLoader();
  const {
    value,
    onChange,
    className,
    kind,
    tabs,
    size, // sm, md, lg, xl
    ariaLabel,
  } = props;

  const checkAndChange = (event, value) => {
    try {
      if (value) {
        onChange(event, value);
      } else {
        // do not change the value as there is not value present
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  return (
    <ButtonGroup
      value={value}
      onChange={checkAndChange}
      className={className}
      kind={kind}
      size={size}
      aria-label={ariaLabel}
    >
      {tabs.map((tab, index) => {
        return (
          <ButtonGroupItem
            data-value={tab.value}
            gray={true}
            // kind="secondary" // give "gray" to get a gray styled buttons
            size={size}
            aria-label={tab.name}
            role="link"
            disabled={tab.disabled}
            key={index + 1}
          >
            {polyglot.t(tab.name)}
          </ButtonGroupItem>
        );
      })}
    </ButtonGroup>
  );
};

ButtonGroupCustom.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  kind: PropTypes.string,
  tabs: PropTypes.array,
  size: PropTypes.string,
  ariaLabel: PropTypes.string,
};
ButtonGroupCustom.defaultProps = {
  value: "one",
  className: "",
  kind: "gray",
  tabs: [
    { value: "one", name: "tab one" },
    { value: "two", name: "tab two" },
  ],
  size: "md",
  ariaLabel: "button",
};

export default ButtonGroupCustom;
