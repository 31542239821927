import React, { Component } from "react";
import { Checkbox } from "appkit-react";
import { postDocProduction } from "../../../../Services/DFED/ReviewService";
import Dropzone from "../../dropzone/Dropzone";
import "./DocumentList.css";
import "../../Processing/Processing.css";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import { DataContext } from "../../../../Services/Shared/Store.js";
import check from "../../../../Assets/Images/DFED/icon-check-2.svg";
import displayToastMessage from "../../../../Components/QueenBase/Root/displayToastMessage";
import commonFunctionForCallService from "../../../../utility/commonFunctionForCallService";
import * as module_Solution_Name from "../../../../Constants/moduleName";
import { checkPermission } from "../../../../utility/checkPermission";
import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonIcon from "../../../../Assets/Components/ButtonIcon/ButtonIcon";
import { closeIcon } from "../../../../Assets/Icons";
import { STATUS_NOT_STARTED } from "../../../../Constants/Constant";

var polyglot = polyglotLoader();
export class DocumentList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showFiles: false,
      showProgress: false,
      production_file_type: props.reviewFiles,
      search_type: props.searchType,
      selectedFile: "",
      selectedProjectId: props.selectedProjectId,
      selectedProjectName: props.selectedProjectName,
      noteText: [],
      files: [],
      size: "",
      nextPage: "",
      rolePermission: {},
    };
    this.closeReview = this.closeReview.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
  }
  static contextType = DataContext;
  onFilesAdded(files) {
    let fileObj = files[0];
    let kb_data = fileObj.size;
    let isFileValid = files[0].name.indexOf(".txt");
    if (isFileValid > 0) {
      this.setState({
        files: fileObj,
        size: kb_data,
        showProgress: true,
      });
    } else {
      displayToastMessage(polyglot.t("Invalid File Extension.please upload .txt file."), "error");
    }
  }
  closeReview() {
    this.setState({
      showProgress: false,
      files: "",
    });
  }

  // CONVERTING FILE SIZE ON STANDARD UNIT
  bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.state.files.name) {
      const { formGotOpened } = this.context;
      formGotOpened();
    }
    if (prevProps.searchType !== this.props.searchType) {
      this.setState({
        search_type: this.props.searchType,
      });
    }
  }
  //insert the doc production details
  requestButton = () => {
    if (checkPermission(this.state.rolePermission, module_Solution_Name.PRODUCTION_MODULE, "Create")) {
      let productionData = {
        PROJECT_ID: this.state.selectedProjectId,
        REVIEW_SEARCH_TYPE: this.state.search_type,
        CLIENT_FILE_NAME: this.state.files.name,
        REVIEW_FILE_SIZE: this.state.files.size,
        selectedProjectName: this.state.selectedProjectName,
        CLIENT_FILE_TYPE: this.state.noteText,
        REVIEW_STATUS: STATUS_NOT_STARTED,
      };
      let formData = new FormData();
      formData.append("reviewFile", this.state.files);
      formData.append("reviewFileName", this.state.files.name);
      formData.append("docData", JSON.stringify(productionData));
      postDocProduction(formData).then((res) => {
        this.props.docNav({ isFileUploaded: true });
      });
    } else {
      displayToastMessage(
        polyglot.t("Unauthorize access ! you are not allowed to perform this action"),
        "error",
      );
    }
  };
  onChangeNote(arg) {
    let data = this.state.noteText;
    if (data.includes(arg)) {
      data.pop(arg);
      this.setState({ noteText: data });
    } else {
      data.push(arg);
      this.setState({ noteText: data });
    }
  }
  componentDidMount() {
    this.getApplicationRolesWithPermission();
  }

  getApplicationRolesWithPermission = async () => {
    let selectedProjectId = this.state.selectedProjectId;
    let rolePermission = await commonFunctionForCallService.commonFunctionForCallService(
      module_Solution_Name.DFED_SOLUTION,
      selectedProjectId,
      module_Solution_Name.PRODUCTION_MODULE,
    );
    if (rolePermission) {
      this.setState({
        rolePermission: rolePermission,
      });
    }
  };

  render() {
    return (
      <div className="criteriaAlign">
        <div className="col-md-12"></div>
        <div className="row m-l-0 m-r-0">
          <div className="col-md-12">
            <br />
            <div className="col-sm-12 col-md-12 p-t-12 p-b-10  subtitle_three_title_qb_dark">
              {polyglot.t("A list of unique document (their IDs) which should be produced. Therefore")}
              &nbsp;
              <b>{polyglot.t("upload a text file with all doc IDs")}&nbsp;</b>
              {polyglot.t("you want to be produced.")}
            </div>
            <br />
            {this.state.showProgress ? null : (
              <div className="row mr-0 ml-0">
                <div className="col-md-12 p-t-12 mt-2 p-b-10 reviewfiles upload-review-data">
                  <Dropzone customDropZoneBoxStyle={{ width: "100%" }} onFilesAdded={this.onFilesAdded} />
                </div>
              </div>
            )}
            {this.state.showProgress ? (
              <div className="row childDatasetsDiv mr-0 ml-0">
                <div className="col-sm-3 col-md-3 upload-textfile p-l-2-1-p">
                  <p className="DF-Upload-File-name">{this.state.files.name}</p>
                  <p className="DF-Upload-File-size">{this.bytesToSize(this.state.size)}</p>
                </div>
                <div className="col-sm-8 col-md-8 p-t-9 progressingStatusDiv mt-2">
                  <div className="progress progressBarDiv">
                    <div className="progressStatus3"></div>
                  </div>
                  <span className="processingstatusText-two">
                    {" "}
                    {polyglot.t("Uploaded")} &nbsp;
                    <img src={check} id="review" alt="check_upload" />
                  </span>
                </div>
                <div className="col-sm-1 col-md-1">
                  <ButtonIcon
                    alt="cancel"
                    iconName={closeIcon}
                    onClick={this.closeReview}
                    ariaLabel={polyglot.t("Cancel")}
                    title={polyglot.t("Cancel")}
                  />
                </div>
              </div>
            ) : null}
            <br />
            <div className="col-sm-12 col-md-12 p-t-19 mt-2 p-b-10 check review-chk">
              {this.state.production_file_type &&
                this.state.production_file_type.length > 0 &&
                this.state.production_file_type.map((data) => {
                  return (
                    <Checkbox
                      className="mt-2"
                      name="checked"
                      key={data}
                      value={data.ID}
                      onChange={(e) => this.onChangeNote(data.ID)}
                    >
                      {polyglot.t(data.Type)}{" "}
                    </Checkbox>
                  );
                })}
              <br />
            </div>
          </div>
        </div>
        <br />
        {this.state.files.name ? (
          <div className="commomReviewBtn">
            <div className="col-sm-12 col-md-12">
              <ButtonPrimary onClick={this.requestButton} ariaLabel={polyglot.t("Request production")}>
                {polyglot.t("Request production")}
              </ButtonPrimary>
            </div>
          </div>
        ) : (
          <div className="commomReviewBtn">
            <div className="col-sm-12 col-md-12">
              <ButtonPrimary
                onClick={this.requestButton}
                disabled
                ariaLabel={polyglot.t("Request production")}
              >
                {polyglot.t("Request production")}
              </ButtonPrimary>
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default DocumentList;
