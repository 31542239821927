import { Avatar, Panel } from "appkit-react";
import $ from "jquery";
import PropTypes from "prop-types";
import React, { useEffect } from "react";

import icongroup from "../../../../Assets/Icons/avatar-outline.svg";
import { ReactComponent as LoadingSpinner } from "../../../../Assets/Images/QueenBase/Root/loading-spin.svg";
import CommonService from "../../../../Services/Shared/CommonService";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
// USERS COMPONENT
function Users(props) {
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    const parent = document.getElementsByClassName("queen-container")[0];
    let Scroll = document.getElementsByClassName("scrollTo");
    if (Scroll.length) {
      $(parent).animate(
        {
          scrollTop: Scroll[0].getBoundingClientRect().y - 100,
        },
        "slow",
      );
    }
  }, []);

  var polyglot = polyglotLoader();
  if (props.data.length > 0) {
    return (
      <div className="row">
        {props.data.map((each, index) => {
          let userImage;
          if (each.image) {
            userImage = URL.createObjectURL(CommonService.get().getBufferImageInBlob(each.image.data));
          }
          return (
            <div key={index} className="col-md-4 users-content">
              <Panel className="user-panels">
                <div className="q-project-header">
                  <Avatar>
                    <img
                      style={{ borderRadius: "100%", height: "30px" }}
                      src={each.image ? userImage : icongroup}
                      alt="user"
                    />
                  </Avatar>
                  <p> {each.label}</p>
                </div>
                <label className="dd-label">{polyglot.t("Projects")}</label>
                <div className="dd-users">
                  {JSON.parse(each.UserProjects) ? (
                    JSON.parse(each.UserProjects).length > 0 ? (
                      JSON.parse(each.UserProjects).map((each, i) => {
                        return (
                          <p className="dd-each-user" key={i}>
                            {each.name}
                          </p>
                        );
                      })
                    ) : (
                      <p>{polyglot.t("No projects")}</p>
                    )
                  ) : (
                    <p>{polyglot.t("No projects")}</p>
                  )}
                </div>
              </Panel>
            </div>
          );
        })}
      </div>
    );
  } else if (props.data.length == 0) {
    return (
      <div className="row">
        <div className="col-md-12">
          <Panel>
            <div className="noprojects-dashboard">
              <strong>{polyglot.t("No users")}</strong>
            </div>
          </Panel>
        </div>
      </div>
    );
  } else {
    return (
      <div className="row dd-columns">
        <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
      </div>
    );
  }
}

Users.propTypes = {
  data: PropTypes.array,
};

export default Users;
