import React from "react";
import Select, { components } from "react-select";
import "./FormField.css";
import { polyglotLoader } from "../../../../Services/Shared/Translate";

const Option = (props) => {
  return (
    <components.Option {...props}>
      <div>{props.data.label}</div>
      {!props.hideLabel && <div style={{ fontSize: 12 }}>{props.data.subLabel}</div>}
    </components.Option>
  );
};

class FormSingleSelectWithDescription extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      val: this.props.preFillSelectWithDescription
        ? this.props.preFillSelectWithDescription.classificationData
        : "",
    };
  }
  polyglot = polyglotLoader();

  handleChange = (newValue, actionMeta) => {
    this.setState({
      val: newValue,
    });

    if (newValue && newValue.value) {
      this.props.onChangeFormField(this.props.name, newValue.value);
    }
  };

  render() {
    const classificationOptions = this.props.classificationOptions;
    if (classificationOptions) {
      classificationOptions.map((option) => {
        option.label = this.polyglot.t(option.label);
        option.subLabel = this.polyglot.t(option.subLabel);
        return option;
      });
    }
    return (
      <div>
        <Select
          isDisabled={this.props.disabledTextFields}
          classNamePrefix="selectWithDescription"
          className="selectDescription"
          value={this.state.val}
          placeholder={this.props.placeholder}
          onChange={this.handleChange}
          options={classificationOptions}
          components={(props) => Option({ ...props, hideLabel: this.props.hideLabel })}
          {...this.props}
        />
      </div>
    );
  }
}

export default FormSingleSelectWithDescription;
