import React, { useContext, useEffect } from "react";

import NoAccess from "../../../Assets/Components/NoAccess";
import { getProjectID_Of_solution_module } from "../../../Services/Shared/Handlers.js";
import { hasViewPermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store.js";

import Upload from "./index.js";

// RENDERS ON PATH /df/upload
export default (props) => {
  // FETCH DATA FROM CONTEXT
  const { checkAndFetchRole, getCurrentUserPermissions, loadingRoles } = useContext(DataContext);
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    const projectId = getProjectID_Of_solution_module();
    const screenName = "Upload";
    checkAndFetchRole(screenName, "Connected Financial Analytics", projectId);
  }, []);
  const currentUserPermissions = getCurrentUserPermissions();
  return (
    <>
      {/* RENDER COMPONENT IF USER HAS PERMISSION TO VIEW */}
      {hasViewPermission(currentUserPermissions) && <Upload {...props} />}
      {/* SHOW 'NO ACCESS' IF USER DOES NOT HAVE PERMISSION TO VIEW */}
      {!loadingRoles && !hasViewPermission(currentUserPermissions) && <NoAccess />}
    </>
  );
};
