import "../../../../Assets/Css/QueenBase/CompanyCenter/CompanyCenter.css";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { DataContext } from "../../../../Services/Shared/Store";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import PackageDetailsHeader from "../Headers/PackageDetailsHeader";
import PackageDetailsPanel from "../Panels/PackageDetailsPanel";

function PackageDetailsCard({
  packageName,
  packagePeriod,
  packagePrice,
  planDescription,
  packageUpgradeStatus,
  onClick,
  hasEditPermission,
  isDfSolution = false,
}) {
  const { getUserLanguage } = useContext(DataContext);
  const polyglot = polyglotLoader(getUserLanguage());

  return (
    <PackageDetailsHeader packageName={packageName} packagePeriod={packagePeriod}>
      <PackageDetailsPanel
        packagePrice={packagePrice}
        planDescription={planDescription}
        isDfSolution={isDfSolution}
      />
      {packageUpgradeStatus !== 1 ? (
        <div className="row m-0 p-0 mb-4 m-l--3"></div>
      ) : (
        <div className="row m-0 p-0 mb-4 m-l--3">
          {hasEditPermission && (
            <ButtonPrimary onClick={onClick} ariaLabel={polyglot.t("View package")}>
              {polyglot.t("View package")}
            </ButtonPrimary>
          )}
        </div>
      )}
    </PackageDetailsHeader>
  );
}
PackageDetailsCard.propTypes = {
  packageName: PropTypes.string,
  packagePeriod: PropTypes.string,
  packagePrice: PropTypes.string,
  planDescription: PropTypes.array,
  packageUpgradeStatus: PropTypes.bool,
  onClick: PropTypes.func,
  hasEditPermission: PropTypes.bool,
  isDfSolution: PropTypes.bool,
};
export default PackageDetailsCard;
