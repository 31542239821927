import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "../../../Assets/Css/DFED/Profile.css";
import { Select, SelectOption, Tooltip } from "appkit-react";
import $ from "jquery";
import BackButton from "../Shared/button/BackButton";
import iconinfo from "../../../Assets/Images/QueenBase/Root/icon-info.svg";
import {
  getAllProfilesService,
  custodianIsExistForProfileService,
  profileIsExistForProfileService,
  pushToProcessing,
  profileByIdService,
  isProfileInProcessService,
} from "../../../Services/DFED/ProfileService";
import { polyglotLoader, userLanguage } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import NoAccess from "../../../Assets/Components/NoAccess";
import commonFunctionForCallService from "../../../utility/commonFunctionForCallService";
import * as module_Solution_Name from "../../../Constants/moduleName";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import { checkPermission } from "../../../utility/checkPermission";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import LoadingIndicator from "../../QueenBase/Root/LoadingIndicator";

// THIS CALSS IS REPONSILBE FOR ALL PROFILE WHICH IS ALREADY CREATED========================

class ProfileList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileListData: [],
      isNewProfile: false,
      processExistingProfile: false,
      selectedProfile: "",
      loadingData: true,
      selectedProfileName: "",
      isDefaultProfile: false,
      noOfCustodians: 0,
      noOfDatasets: 0,
      noOfProfiles: 0,
      getDataSets: [],
      isViewProfile: false,
      custodianName: "",
      custodianId: 0,
      dataSetName: "",
      isShowRunProcessing: false,
      isBackBtn: false,
      onChangeProjectId: props.location.state.selectedProjectId,
      isFromCreateNewList: false,
      isProfileExists: false,
      allDatasetsNameForDisplay: [],
      dropdownOpen: false,
      fromTabNavigation:
        props.location.state && props.location.state.fromTabNavigation
          ? props.location.state.fromTabNavigation
          : null,
      isViewPermission: true,
      rolePermission: {},
    };
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
    this.toggleDropDown = this.toggleDropDown.bind(this);
  }
  static contextType = DataContext;
  polyglot = polyglotLoader();
  // LIFE CYCLE METHOD FOR GETTING ALL PROFILE DATA====================

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.location.state !== undefined) {
      if (this.props.location.state.hasOwnProperty("custodainName")) {
        this.setState({
          custodianName: this.props.location.state.custodainName,
        });
      }
      if (this.props.location.state.hasOwnProperty("custodianId")) {
        this.setState({
          custodianId: this.props.location.state.custodianId,
        });
      }
      if (this.props.location.state.hasOwnProperty("datasetsName")) {
        this.setState({ dataSetName: this.props.location.state.datasetsName });
      }
      if (this.props.location.state.isFromCreateNewList) {
        this.setState({ isFromCreateNewList: true });
      }
    }
    this.getApplicationRolesWithPermission(this);
    this.countTables(this);
    this.getallDatasetsNameForDisplay();

    if (this.props.location.state.allDatasetsNameForDisplay) {
      this.setState({ allDatasetsNameForDisplay: this.props.location.state.allDatasetsNameForDisplay });
    }
  }
  // BACK BUTTON FUNCTIONALITY====================
  onBackButtonClick() {
    this.setState({ isBackBtn: true });
  }
  // COUNT TABLES+======================
  countTables = async () => {
    this.custodianCountAndExitance(this);
    this.profileCountAndExitance(this);
  };
  // CUSTODIAN COUNT CHECK FUNCTIONALITY===================

  custodianCountAndExitance = async () => {
    let custodianIds = await custodianIsExistForProfileService();
    if (custodianIds && custodianIds.status === 200) {
      this.setState({ noOfCustodians: custodianIds.data.isExists }, () => {
        this.makeDatasetsTabls(this);
      });
    }
  };
  // PROFILE COUNT CHECK FUNCTIONALITY=====================

  profileCountAndExitance = async () => {
    let selectedProjectId = this.state.onChangeProjectId;
    let profileCount = await profileIsExistForProfileService(selectedProjectId);
    if (profileCount && profileCount.status === 200) {
      this.setState({ noOfProfiles: profileCount.data.isExists }, () => {
        this.makeDatasetsTabls(this);
      });
    }
  };

  // CREATING DATASETS TABLE======================
  makeDatasetsTabls = () => {
    let dataSet = [];
    if (this.state.noOfCustodians === 0) {
    }
    if (this.state.noOfProfiles === 0) {
      dataSet.push({ Title: "No Profile exist, Please Create a new Profile", Count: null });
    }
    if (this.state.noOfProfiles !== 0) {
      dataSet.push({ Title: "Number of Profiles", Count: this.state.noOfProfiles });
    }

    this.setState({ getDataSets: dataSet });
  };

  // GETTING ALL PROFILES+=======================
  getAllProfiles = async () => {
    let selectedProjectId = this.state.onChangeProjectId;
    await getAllProfilesService(selectedProjectId)
      .then((profileList) => {
        if (profileList) {
          this.state.loadingData = false;
          var tempProfileList = [];
          if (checkPermission(this.state.rolePermission, module_Solution_Name.PROFILE_MODULE, "Create")) {
            tempProfileList.push({ PR_ID: "0", PR_NAME: "Create new profile" });
          }
          profileList.data.forEach((element) => {
            var tempObj = { PR_ID: element.PR_ID, PR_NAME: element.PR_NAME };
            tempProfileList.push(tempObj);
          });
          this.setState({ profileListData: tempProfileList });
          //this.setState({ profileListData: tempProfileList }, () => { this.addLineToDropDown(this); });
        }
      })
      .catch((err) => {
        if (err && err.status === 403) {
          this.setState({
            isViewPermission: false,
          });
        }
      });
  };

  // GETTING ALL RLES AND PERMISSION============================
  getApplicationRolesWithPermission = async () => {
    let selectedProjectId = this.state.onChangeProjectId;
    let rolePermission = await commonFunctionForCallService.commonFunctionForCallService(
      module_Solution_Name.DFED_SOLUTION,
      selectedProjectId,
      module_Solution_Name.PROFILE_MODULE,
    );
    if (rolePermission) {
      this.setState(
        {
          rolePermission: rolePermission,
        },
        () => {
          this.getAllProfiles(this);
        },
      );
    }
  };
  // PROFILE HANDLER+==============================

  profileHandler = (val) => {
    let profileId = parseInt(val.split("_")[0]);
    let profileName = val.split("_")[1].toString();
    if (profileId === 0) {
      this.setState({ isNewProfile: true });
    } else {
      this.setState(
        {
          selectedProfile: profileId,
          selectedProfileName: profileName,
          isShowRunProcessing:
            this.state.allDatasetsNameForDisplay && this.state.allDatasetsNameForDisplay.length > 0
              ? true
              : false,
        },
        () => {
          this.isProfileInProcess(this);
        },
      );
    }
  };
  // GET PROFILE BY ID=================
  getProfileByIdService = async () => {
    let profileData = await profileByIdService(this.state.selectedProfile);
    if (profileData && profileData.status === 200) {
    }
  };

  buttonHandler = (val) => {
    this.addDataToProcessing(this);
  };
  // ADD PROCESSING FROM PROFILE PAGE=======================

  addDataToProcessing = async () => {
    sessionStorage.removeItem("dsets");
    sessionStorage.removeItem("custo_Name");
    sessionStorage.removeItem("custodianId");
    let isInterruptProcess = false;
    if (this.state.custodianId === 0) {
      displayToastMessage(this.polyglot.t("Can not process, System need Custodian details."), "error");
      isInterruptProcess = true;
    }
    if (
      this.state.selectedProfile === 0 ||
      this.state.selectedProfile === "" ||
      this.state.selectedProfile === null
    ) {
      displayToastMessage(this.polyglot.t("Please select Profile details."), "error");
      isInterruptProcess = true;
    }
    // let projectId = $('select.selectStyle :selected').val();
    let projectId = this.props.location.state.selectedProjectId;
    if (projectId === 0 || projectId === "" || projectId === null) {
      displayToastMessage(this.polyglot.t("Can not process, System need Project details."), "error");
      isInterruptProcess = true;
    }
    if (isInterruptProcess === false) {
      let tempData = {
        PROJECT_ID: projectId,
        CUSTODIAN_ID: this.state.custodianId,
        DATASET_ID: sessionStorage.getItem("datasetIDs"), //@to do which dataset need to proceeed that need to decide
        PROFILE_ID: this.state.selectedProfile,
        COLLECTION_STATUS: 1,
        PROCESSING_STATUS: 1,
        PROCESS_START_AT: new Date(),
        PROCESS_START_BY: "testUser",
      };
      let processingData = await pushToProcessing(tempData);
      if (processingData.status === 200) {
        this.setState({
          processExistingProfile: true,
        });
      } else {
        let statusType = "Error";
        if (processingData.status === 500) statusType = "Internal Server Error";
        displayToastMessage(statusType + this.polyglot.t("while processing Profile data."), "error");
      }
    } else {
      displayToastMessage(
        this.polyglot.t(
          "Can not process, System need All details, related to project, custodian and profile.",
        ),
        "error",
      );
    }
  };

  viewProfileHandler = (val) => {
    this.setState({ isViewProfile: true });
  };
  // CHECKING IS PROFILE IS IN PROCESS+===========================

  isProfileInProcess = async () => {
    let profileData = await isProfileInProcessService(this.state.selectedProfile);
    if (profileData && profileData.status === 200) {
      let isDefault = "";
      if (this.state.selectedProfileName === "Default") {
        isDefault = true;
      } else {
        isDefault = false;
      }
      if (profileData.data && profileData.data.length === 0) {
        let tempisProfileExistsVal = false;
        if (isDefault) {
          tempisProfileExistsVal = true;
        }
        this.setState({
          isProfileExists: tempisProfileExistsVal,
        });
      } else {
        this.setState({
          isProfileExists: true,
        });
      }
    } else {
      displayToastMessage(this.polyglot.t("Error while fetching profile details."), "error");
    }
  };

  hideProfileHandler = (val) => {
    this.setState({ isViewProfile: false });
  };

  fastForwardButton = () => {
    this.props.history.push({
      pathname: "/dfed/Fastforward",
      state: { selectedProjectId: this.state.onChangeProjectId },
    });
  };

  onTabChange = (e) => {
    this.setState({
      tabValue: e.target.getAttribute("data-value"),
    });
  };
  // Function for getting all  datasets name from storage to display
  getallDatasetsNameForDisplay() {
    let allDatasetsNameForDisplay = JSON.parse(sessionStorage.getItem("dsets"));
    let custodianName = sessionStorage.getItem("custo_Name");
    let custodianId = sessionStorage.getItem("custodianId");
    //===== THIS IS FOR HANDELING DATASETS NAME AND CUSTODIAN NAME WHEN WE ENTER PROFILE PAGE VIA DIFFERENT WAY========//
    if (this.state.fromTabNavigation) {
      this.setState({
        allDatasetsNameForDisplay: allDatasetsNameForDisplay,
        custodianName: custodianName,
        custodianId: custodianId,
      });
    } else {
      this.setState({
        allDatasetsNameForDisplay: null,
        custodianName: "",
        custodianId: 0,
      });
    }
  }

  toggleDropDown() {
    this.setState({
      dropdownOpen: !this.state.dropdownOpen,
    });
  }
  // GETTING CONTENT FOR DATASETS========================
  getContentDatasets() {
    return (
      <div>
        <table>
          <tbody>
            {this.state.allDatasetsNameForDisplay &&
              this.state.allDatasetsNameForDisplay.map((parentDataset, index) => {
                return (
                  <tr key={index}>
                    <td
                      style={{
                        textAlign: "left",
                        fontWeight: "bold",
                        color: "#000",
                        textTransform: "capitalize",
                      }}
                    >
                      {index + 1}.&nbsp;{parentDataset.dataset}
                    </td>
                  </tr>
                );
              })}
          </tbody>
        </table>
      </div>
    );
  }
  // ALL DATASETS IN DROPDOWN====================
  showAllDatasetsNameWithDropdown() {
    return (
      <Tooltip content={this.getContentDatasets()} placement="right">
        <img src={iconinfo} className="customTooltip transformY-minus160 mt-0" alt="iconinfo" />
      </Tooltip>
    );
  }
  render() {
    var polyglot = polyglotLoader();
    const dropDownTital = polyglot.t("Choose the processing profile");
    var profileHome = (
      <div>
        <div className="p-0">
          <div className="row m-l-0 m-r-0">
            <div className="col-lg-3 col-md-4 p-l-0">
              {this.state.allDatasetsNameForDisplay && this.state.allDatasetsNameForDisplay.length > 0 && (
                <BackButton
                  onBackButtonClick={this.onBackButtonClick}
                  buttonText={polyglot.t("Back")}
                ></BackButton>
              )}
            </div>
          </div>
          <div
            className={
              this.state.allDatasetsNameForDisplay && this.state.allDatasetsNameForDisplay.length > 0
                ? "row m-l-0 m-r-0 m-t-1-7"
                : "row m-l-0 m-r-0 "
            }
          >
            <div className="col-md-12 p-0">
              <div className="profileCounting">
                {this.state.noOfProfiles !== 0 ? (
                  <h1 className="profile-count">
                    {this.state.noOfProfiles + 1}
                    {polyglot.t("Profiles")}
                  </h1>
                ) : (
                  <h1 className="profile-count">
                    {1} {polyglot.t("Profile")}
                  </h1>
                )}
              </div>
            </div>
          </div>
          <div className="row m-l-0 m-r-0 margin-minus-6-p">
            <div className="col-md-8">
              <div className="row form-group textLeft">
                <div className="datasetsListDropdown">
                  <span className="drpDwnTitleFirstPart secnPrtTxt">
                    {this.state.dataSetName !== "" ? dropDownTital + "" : dropDownTital}
                  </span>
                  {this.state.allDatasetsNameForDisplay && this.state.allDatasetsNameForDisplay.length > 0 && (
                    <span className="drpDwnTitleFirstSecondPart">
                      <span className="secnPrtTxt ">&nbsp;{polyglot.t("for dataset")}</span>
                      <span className="secnPrtDSNm">{this.showAllDatasetsNameWithDropdown()}</span>
                    </span>
                  )}
                </div>
              </div>
              <div class="row form-group textLeft m-t-5-p profile-list-dropdown">
                {this.state.profileListData.length > 0 ? (
                  <Select
                    aria-label={polyglot.t("Processing profile")}
                    role="presentation"
                    placeholder={polyglot.t("Processing profile")}
                    showSearchOnToggle={true}
                    onSelect={this.profileHandler.bind(this)}
                    className="profileListDropDown"
                    data-testid="profileListDropDown"
                  >
                    {this.state.profileListData.map((value, key) => {
                      if (key === 0) {
                        return (
                          <SelectOption key={key} value={value.PR_ID + "_" + value.PR_NAME}>
                            <span className="create-profile">{polyglot.t("Create new profile list")}</span>
                            <hr />
                          </SelectOption>
                        );
                      } else {
                        return (
                          <SelectOption key={key} value={value.PR_ID + "_" + value.PR_NAME}>
                            {value.PR_NAME}
                          </SelectOption>
                        );
                      }
                    })}
                  </Select>
                ) : (
                  <div> {polyglot.t("No data found.")} </div>
                )}
              </div>
            </div>
            <div className="col-md-4">
              {/* <span className="processing-lable-txt">
                {polyglot.t("Processing profile")}
              </span> */}
              <p
                className="profile-desc-txt"
                aria-label={polyglot.t(
                  "You need to choose a processing profile so that we know how to process your datasets. You can use the default system profile, or create your own.",
                )}
              >
                {polyglot.t(
                  "You need to choose a processing profile so that we know how to process your datasets. You can use the default system profile, or create your own.",
                )}{" "}
                <br />
              </p>
              <div>
                <p className="para-txt">
                  {polyglot.t("With the help_para")}
                  <span className="para-head" aria-label={polyglot.t("Create new profile")}>
                    {polyglot.t("Create new profile")}.
                  </span>
                </p>
                <p
                  className="para-txt"
                  aria-label={polyglot.t(
                    "Once created, you can view and/or edit a profile by selecting it from the dropdown menu and clicking ",
                  )}
                >
                  {polyglot.t(
                    "Once created, you can view and/or edit a profile by selecting it from the dropdown menu and clicking ",
                  )}
                  <span className="para-head" aria-label={polyglot.t("View/Edit Profile")}>
                    {polyglot.t("View/Edit Profile")}
                  </span>
                  <span>{" " + polyglot.t("at the bottom")}.</span>
                </p>
              </div>
            </div>
          </div>
          <div className="row profileListButtonRow">
            {this.state.allDatasetsNameForDisplay ? (
              <div className="col-md-4 col-lg-3">
                <div className="textLeft">
                  <ButtonPrimary
                    onClick={this.state.isShowRunProcessing === true ? this.buttonHandler : ""}
                    ariaLabel={polyglot.t("Start processing")}
                    disabled={this.state.isShowRunProcessing === true ? false : true}
                  >
                    {polyglot.t("Start processing")}
                  </ButtonPrimary>
                </div>
              </div>
            ) : null}
            {this.state.isFromCreateNewList && (
              <div className="col-md-1 col-lg-3">
                <div className="textLeft">
                  <span>
                    {" "}
                    <BackButton
                      onBackButtonClick={this.fastForwardButton}
                      buttonText=" Fastforward"
                    ></BackButton>
                  </span>
                </div>
              </div>
            )}
            <div className={"col-md-4 col-lg-3"}>
              {this.state.selectedProfile ? (
                <div className="textLeft">
                  <ButtonPrimary
                    onClick={this.viewProfileHandler}
                    ariaLabel={polyglot.t("View/Edit Profile")}
                  >
                    {polyglot.t("View/Edit Profile")}
                  </ButtonPrimary>
                </div>
              ) : null}
            </div>
          </div>
          <div className="row m-l-0 m-r-0"></div>
          <div className="row m-l-0 m-r-0">
            <div className="col-md-12">
              {this.state.isViewProfile && this.state.isProfileExists === true ? (
                <Redirect
                  push
                  from={"../Components/Profile/ProfileList/"}
                  to={{
                    pathname: "/dfed/Profile/",
                    state: {
                      profileId: this.state.selectedProfile,
                      selectedProjectId: this.state.onChangeProjectId,
                      isProfileExists: this.state.isProfileExists,
                    },
                  }}
                ></Redirect>
              ) : null}
              {this.state.isViewProfile && this.state.isProfileExists === false ? (
                <Redirect
                  push
                  from={"../Components/Profile/ProfileList/"}
                  to={{
                    pathname: "/dfed/EditProfile/",
                    state: {
                      profileId: this.state.selectedProfile,
                      selectedProjectId: this.state.onChangeProjectId,
                      isProfileExists: this.state.isProfileExists,
                      allDatasetsNameForDisplay: this.state.allDatasetsNameForDisplay,
                      custodianId: this.state.custodianId,
                    },
                  }}
                ></Redirect>
              ) : null}
              {this.state.isNewProfile ? (
                <Redirect
                  push
                  from={"../Components/Profile/ProfileList/"}
                  to={{
                    pathname: "/dfed/Profile/",
                    state: {
                      isNewProfile: true,
                      selectedProjectId: this.state.onChangeProjectId,
                      custodainName: this.props.location.state.custodainName,
                    },
                  }}
                ></Redirect>
              ) : null}
              {this.state.processExistingProfile ? (
                <Redirect
                  push
                  from={"../Components/Processing/ProfileList/" + 1}
                  to={{
                    pathname: "/dfed/processing/",
                    state: {
                      selectedProfileId: this.state.selectedProfile,
                      selectedProjectId: this.state.onChangeProjectId,
                    },
                  }}
                ></Redirect>
              ) : null}

              {this.state.isDefaultProfile ? (
                <Redirect
                  push
                  from={"../Components/Profile/ProfileList/"}
                  to={{
                    pathname: "/dfed/Profile/",
                    state: {
                      profileDetails: [
                        {
                          id: this.state.selectedProfile,
                          name: "Default",
                          selectedProjectId: this.state.onChangeProjectId,
                        },
                      ],
                    },
                  }}
                ></Redirect>
              ) : null}
            </div>
          </div>
          {this.state.isBackBtn === true ? (
            <Redirect
              push
              from={"../Components/Profile/ProfileList/"}
              to={{
                pathname: "/dfed/custodian",
                state: { selectedProjectId: this.state.onChangeProjectId },
              }}
            ></Redirect>
          ) : null}
        </div>
      </div>
    );

    if (this.state.isViewPermission === false) {
      return <NoAccess />;
    } else {
      return (
        <>
          {this.state.loadingData === true && <LoadingIndicator></LoadingIndicator>}
          {this.state.loadingData === false && profileHome}
        </>
      );
    }
  }

  // addLineToDropDown = () => {
  //   $("li[value='0_Create new profile']").css("color", "#2d2d2d");
  //   $("li[value='0_Create new profile']").css("height", "-1%");
  //   $("li[value='0_Create new profile']").css("margin-bottom", "2%");
  //   $("li[value='0_Create new profile']").css("font-weight", "bold");
  //   $("li[value='0_Create new profile']").after("<hr/>");
  //   $("li[value$='_Default']").css("margin-top", "3%");
  // }
}
export default ProfileList;
