/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable sort-imports */
/* eslint-disable react/prop-types */
import "../../../Assets/Css/QueenBase/Users/User.css";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Checkbox, Input, Select, SelectGroupTitle, SelectOption } from "appkit-react";
import { Formik } from "formik";
import CreatableSelect from "react-select/creatable";
import * as Yup from "yup";
import iconinfo from "../../../Assets/Images/QueenBase/Root/icon-info.svg";
import { polyglotLoader, userLanguage } from "../../../Services/Shared/Translate";
import {
  NAME_REGEX,
  ONLY_CHAR_REGEX,
  FIELD_LENGTH_REGEX,
  PHONE_REGEX,
  FIELD_REGEX,
} from "../../../Constants/regex";
import { DataContext } from "../../../Services/Shared/Store";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import displayToastMessage from "../Root/displayToastMessage";
import UserService from "../../../Services/QueenBase/Users/UserService";

// USER FORM USED TO CREATE A USER
const UserForm = (props) => {
  const { getUserLanguage, formGotClosed, formGotOpened } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const {
    onSubmit,
    countries,
    formError,
    positions,
    setCustomselect,
    setCustomcreate,
    userCount,
    disableSubmit,
    setDisableSubmit,
    prefix,
    roles,
  } = props;
  const initialValues = {
    prefixID: "",
    firstname: "",
    lastname: "",
    email: "",
    department: "",
    phone: "",
    entity: "",
    positionid: "",
    countryid: 0,
    roleID: "",
    istemp: userCount.maxusercount === userCount.usercount ? 1 : 0,
  };
  const [isLoading, setIsLoading] = useState(true);
  const [tempUserVisible, setTempUserVisible] = useState(true);
  const [errorToggle, setErrorToggle] = useState(false);
  const formRef = useRef();

  // VALIDATION OF THE USER FORM
  const validationSchema = Yup.object().shape({
    prefixID: Yup.string().required("* Salutation is required"),
    firstname: Yup.string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t(polyglot.t("field_validaton")))
      .required("First Name is required")
      .trim(),
    lastname: Yup.string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t("field_validaton"))
      .required("Last Name is required")
      .trim(),
    email: Yup.string().email(polyglot.t("Invalid email")).required("Email is Required"),
    positionid: Yup.string().required("Position is required"),
    countryid: Yup.number().min(1, "* Country is required"),
    entity: Yup.string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t("field_validaton"))
      .required("Organisation is required")
      .trim(),
    department: Yup.string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t("field_validaton"))
      .required("Department is required")
      .trim(),
    phone: Yup.string()
      .matches(PHONE_REGEX, "Invalid phone number")
      .min(5, "Phone number must be at least 5 characters")
      .max(20, "Phone number must be at most 20 characters")
      .required(polyglot.t("Phone Number is required")),
  });

  // CREATE OR SELECT A POSITION
  const selectcreate = (value, actionMeta) => {
    if (actionMeta.action === "select-option") {
      // CHECK IF EXISTING POSITION IS CHOOSEN
      setCustomselect(value.value);
      setErrorToggle(false);
    } else if (actionMeta.action === "create-option") {
      // IF NEW POSITION HAS TO BE CREATED AND CHECKS FOR SPECIAL CHARACTERS
      if (value.label.match(ONLY_CHAR_REGEX)) {
        setCustomcreate(value.label);
      } else {
        setErrorToggle(true);
      }
    }
  };
  setDisableSubmit(errorToggle);
  // IF USER BEING CREATED IS TEMPORARY
  const tempusermanagement = () => {
    var istempvalidation = false;
    if (userCount.maxusercount === userCount.usercount) {
      istempvalidation = true;
    }
    return istempvalidation;
  };

  useEffect(() => {
    const checkIsTechnicalClient = async () => {
      const res = await UserService.get().getUserProfile();
      if (res.CODE) {
        const { isTechnicalClient } = res.userData;
        if (!isTechnicalClient) {
          formRef.current.setFieldValue("roleID", 4);
        } else {
          setTempUserVisible(false);
        }
        setIsLoading(false);
      } else {
        displayToastMessage(res.MSG, "error");
      }
    };

    checkIsTechnicalClient();
  }, []);

  return (
    <Formik
      onSubmit={onSubmit}
      initialValues={initialValues}
      validationSchema={validationSchema}
      innerRef={formRef}
    >
      {(prop) => {
        const { values, handleChange, handleSubmit, errors, touched, dirty, setFieldValue } = prop;
        // FORMIK FORM - HANDLE FORMOPEN OR CLOSE STATUS
        const updateFormStatusBasedOnDirty = () => {
          if (dirty) {
            formGotOpened();
          }
          if (!dirty) {
            formGotClosed();
          }
        };
        setTimeout(() => {
          updateFormStatusBasedOnDirty();
        }, 0);
        return (
          <div>
            <div className="row mt-1">
              <div className="col-md-3">
                {getUserLanguage() === "en" && (
                  <>
                    <Select
                      placeholder={polyglot.t("Salutation *")}
                      value={values.prefixID}
                      onSelect={(value) => handleChange("prefixID")({ target: { value } })}
                      aria-label={polyglot.t("Salutation *")}
                    >
                      {prefix.map((prefix, index) => {
                        return (
                          <SelectOption key={index} value={prefix.id}>
                            {polyglot.t(prefix.name)}
                          </SelectOption>
                        );
                      })}
                    </Select>
                  </>
                )}
                {getUserLanguage() === "ger" && (
                  <>
                    <Select
                      placeholder={polyglot.t("Salutation *")}
                      value={values.prefixID}
                      onSelect={(value) => handleChange("prefixID")({ target: { value } })}
                      aria-label={polyglot.t("Salutation *")}
                    >
                      {prefix.map((prefix, index) => {
                        return (
                          <SelectOption key={index} value={prefix.id}>
                            {polyglot.t(prefix.name)}
                          </SelectOption>
                        );
                      })}
                    </Select>
                  </>
                )}
                {errors.prefixID && touched.prefixID ? (
                  <div className="error">{polyglot.t(errors.prefixID)}</div>
                ) : null}
                <br />
              </div>
              <div className="col-md-3 ">
                <Input
                  inputBoxSize="sm"
                  placeholder={polyglot.t("First name *")}
                  value={values.firstname}
                  onChange={handleChange("firstname")}
                  aria-label={polyglot.t("first name of the user")}
                />
                {errors.firstname && touched.firstname ? (
                  <div className="error">{polyglot.t(errors.firstname)}</div>
                ) : null}
                <br />
              </div>
              <div className="col-md-6">
                <Input
                  inputBoxSize="sm"
                  placeholder={polyglot.t("Last Name *")}
                  value={values.lastname}
                  onChange={handleChange("lastname")}
                  aria-label={polyglot.t("last name of the user")}
                />
                {errors.lastname && touched.lastname ? (
                  <div className="error">{polyglot.t(errors.lastname)}</div>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Input
                  inputBoxSize="sm"
                  placeholder={polyglot.t("Email *")}
                  value={values.email}
                  onChange={handleChange("email")}
                  aria-label={polyglot.t("email id")}
                />
                {errors.email && touched.email ? (
                  <div className="error">{polyglot.t(errors.email)}</div>
                ) : (
                  null &&
                  formError === "email exists" && <span className="error">{polyglot.t(formError)}</span>
                )}
              </div>
              <div className="col-md-6 " id="CreatableSelect">
                {positions.length === 0 && (
                  <CreatableSelect
                    placeholder={polyglot.t("Position *")}
                    options={[{ label: polyglot.t("Loading...") }]}
                    classNamePrefix="custom-creatable-select"
                    aria-label={polyglot.t("loading")}
                  />
                )}
                {positions.length > 0 && (
                  <CreatableSelect
                    placeholder={polyglot.t("Position *")}
                    value={values.positionid}
                    onInputChange={(value, actionMeta) => {
                      handleChange("newposition")({ target: { value } });
                      selectcreate(value, actionMeta);
                    }}
                    onChange={(value, actionMeta) => {
                      handleChange("positionid")({ target: { value } });
                      selectcreate(value, actionMeta);
                    }}
                    options={positions}
                    classNamePrefix="custom-creatable-select"
                    aria-label={polyglot.t("position")}
                  />
                )}

                {errors.positionid && touched.positionid ? (
                  <div className="error">{polyglot.t(errors.positionid)}</div>
                ) : (
                  null && errors.positionid && <span className="error">{polyglot.t(errors.positionid)}</span>
                )}
                {errorToggle ? (
                  <div className="error">{polyglot.t("* Special character not allowed")}</div>
                ) : (
                  ""
                )}
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                {countries.length === 0 && (
                  <Select
                    placeholder={
                      getUserLanguage() === "en" ? polyglot.t("Country *") : polyglot.t("Country *")
                    }
                    aria-label={polyglot.t("loading")}
                  >
                    <SelectGroupTitle>
                      {getUserLanguage() === "en"
                        ? polyglot.t("Country_noStar")
                        : polyglot.t("Country_noStar")}{" "}
                    </SelectGroupTitle>
                    <SelectOption>
                      {getUserLanguage() === "en" ? polyglot.t("Loading...") : polyglot.t("Loading...")}
                    </SelectOption>
                  </Select>
                )}
                {countries.length > 0 && (
                  <Select
                    placeholder={
                      getUserLanguage() === "en" ? polyglot.t("Country *") : polyglot.t("Country *")
                    }
                    value={values.countryid}
                    showSearchOnToggle={true}
                    onSelect={(value) => handleChange("countryid")({ target: { value } })}
                    aria-label={
                      getUserLanguage() === "en" ? polyglot.t("Country_noStar") : polyglot.t("Country_noStar")
                    }
                  >
                    <SelectGroupTitle>
                      {getUserLanguage() === "en"
                        ? polyglot.t("Country_noStar")
                        : polyglot.t("Country_noStar")}{" "}
                    </SelectGroupTitle>
                    {countries.map((country, index) => {
                      if (index < 1)
                        return (
                          <SelectOption key={index + 1} value={country.countryid}>
                            {country.name}
                          </SelectOption>
                        );
                    })}
                    <hr className="" />
                    {countries.map((country, index) => {
                      if (index > 0)
                        return (
                          <SelectOption key={index + 1} value={country.countryid}>
                            {country.name}
                          </SelectOption>
                        );
                    })}
                  </Select>
                )}

                {errors.countryid && touched.countryid ? (
                  <div className="error">
                    {getUserLanguage() === "en"
                      ? polyglot.t("Country is required")
                      : polyglot.t("Country is required")}
                  </div>
                ) : (
                  null &&
                  errors.countryid && (
                    <span className="error">
                      {getUserLanguage() === "en"
                        ? polyglot.t("Country is required")
                        : polyglot.t("Country is required")}
                    </span>
                  )
                )}
                <br />
              </div>

              <div className="col-md-6">
                <Input
                  inputBoxSize="sm"
                  placeholder={polyglot.t("Organisation")}
                  value={values.entity}
                  onChange={handleChange("entity")}
                  aria-label={polyglot.t("Organisation")}
                />
                {errors.entity && touched.entity ? (
                  <div className="error">{polyglot.t(errors.entity)}</div>
                ) : null}
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <Input
                  inputBoxSize="sm"
                  placeholder={polyglot.t("Department*")}
                  value={values.department}
                  onChange={handleChange("department")}
                  aria-label={polyglot.t("Department")}
                />
                {errors.department && touched.department ? (
                  <div className="error">{polyglot.t(errors.department)}</div>
                ) : null}
              </div>
              <div className="col-md-6">
                <Input
                  inputBoxSize="sm"
                  placeholder={polyglot.t("PhoneNumber*")}
                  value={values.phone}
                  onChange={handleChange("phone")}
                  aria-label={polyglot.t("Phone number")}
                />
                {errors.phone && touched.phone ? (
                  <div className="error">
                    {getUserLanguage() === "en"
                      ? polyglot.t("Phone Number is required")
                      : polyglot.t("Phone Number is required")}
                  </div>
                ) : (
                  null &&
                  errors.phone && (
                    <span className="error">
                      {getUserLanguage() === "en"
                        ? polyglot.t("Phone Number is required")
                        : polyglot.t("Phone Number is required")}
                    </span>
                  )
                )}
              </div>
            </div>
            <br />
            <div className="row">
              <div className="col-md-6">
                {roles?.length > 0 && !isLoading && (
                  <Select
                    placeholder={polyglot.t("Role")}
                    value={values.roleID}
                    defaultValue={values.roleID}
                    onSelect={(value) => {
                      handleChange("roleID")({ target: { value } });
                      setTempUserVisible(value === 4);
                      if (value != 4) {
                        setFieldValue("istemp", 0);
                      }
                    }}
                    aria-label={polyglot.t("Role")}
                    disabled={tempusermanagement()}
                  >
                    {roles.map(({ id, displayname }) => {
                      return (
                        <SelectOption key={id} value={id}>
                          {displayname}
                        </SelectOption>
                      );
                    })}
                  </Select>
                )}
              </div>
            </div>
            <br />
            {!isLoading && tempUserVisible && (
              <div className="col-md-8">
                <span className="user-checkbox">
                  <Checkbox
                    disabled={tempusermanagement()}
                    key={values.istemp}
                    onChange={handleChange("istemp")}
                    checked={values.istemp ? true : false}
                    aria-label={polyglot.t("check if temporary user")}
                  >
                    <span className="check-active f-acc-B">{polyglot.t("Temporary  user")}</span>
                  </Checkbox>
                </span>
              </div>
            )}
            <div className="row mt-2">
              <div className="col-md-1">
                <img src={iconinfo} alt="info" />
              </div>
              <div className="col-md-11 pl-0 break-word">
                <div className="information-txt subtitle_three_title_qb_dark">
                  {polyglot.t(
                    "To provide permissions to the user please go to the relevant project and add permissions",
                  )}
                </div>
              </div>
            </div>

            <div className="mt-3">
              <ButtonPrimary
                onClick={handleSubmit}
                disabled={disableSubmit}
                ariaLabel={polyglot.t("Save user")}
              >
                {polyglot.t("Save user")}
              </ButtonPrimary>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default UserForm;
