import React from "react";

import { polyglotLoader } from "./Translate";
import displayToastMessage from "../../Components/QueenBase/Root/displayToastMessage";

export const percentageUsed = (VolumeUsed, TotalVolume) => {
  if (VolumeUsed && TotalVolume) {
    return (VolumeUsed * 100) / TotalVolume;
  } else {
    return 0;
  }
};

export const ConvertGBtoTB = (GBamount) => {
  const getPriceNumberFromString = (GBamount) => {
    const GBInNumber = GBamount.split(",").join("");
    return Number(GBInNumber);
  };
  let GBInNumber;
  if (GBamount.includes(",")) {
    GBInNumber = getPriceNumberFromString(GBamount);
  } else {
    GBInNumber = Number(GBamount);
  }
  /* Note: open it if we need size in TB format
	if(GBInNumber >1000){
		const TB = (GBInNumber/1000).toFixed(1) ;
		return `${TB} TB`;
	}
	*/
  return `${GBamount} GB`;
};

// GET DISPLAY DATE TO SHOW ON SCREEN
export const getDisplayDate = (ISODate) => {
  try {
    let date = new Date(ISODate).getDate();
    if (isNaN(date)) {
      // if date is NaN then return "Date is not available"
      return "";
    } else {
      if (date.toString().length === 1) {
        date = `0${date}`;
      }
      let month = new Date(ISODate).getMonth() + 1;
      if (month.toString().length === 1) {
        month = `0${month}`;
      }
      const year = new Date(ISODate).getFullYear();
      return `${date}.${month}.${year}`;
    }
  } catch (err) {
    return "Please provide the date in ISO format";
  }
};

// TOOLTIP CONTENT OF DF PACKAGE
export const DFPackageInfoIconContent = () => {
  const polyglot = polyglotLoader();
  return (
    <>
      <div className="a-h5 a-text-dark text-left">{polyglot.t("Example")}:</div>
      <div className="a-text-dark text-left">
        <b>a) {polyglot.t("Data for 3 company codes and 1 year")}</b>
      </div>
      <div className="font-weight-normal">
        &nbsp;&nbsp;&nbsp;&nbsp;
        {polyglot.t("Years of data across company codes - 3")}
      </div>
      <div className="a-text-dark text-left" style={{ marginTop: 10 }}>
        <b>b) {polyglot.t("Data for 2 company codes and 2 years")}</b>
      </div>
      <div className="font-weight-normal">
        &nbsp;&nbsp;&nbsp;&nbsp;
        {polyglot.t("Years of data across company codes - 4")}
      </div>
    </>
  );
};

// OPEN EXTERNAL URL
export const openExternalUrl = (url) => {
  window.open(url);
};
// CAPITALISE FIRST LETTER OF A STRING
export const capitaliseFirstLetter = (str) => {
  try {
    return str.charAt(0).toUpperCase() + str.slice(1);
  } catch (err) {
    return `Please provide a string instead of ${typeof str}`;
  }
};
// GET DATE FORMAT IN YYYY-MM-DD
export const dateformat_of_YMD = (jsDate) => {
  try {
    const y = new Date(jsDate).getFullYear();
    const m = new Date(jsDate).getMonth() + 1;
    const d = new Date(jsDate).getDate();
    const dateString = `${y}-${m}-${d}`;
    return dateString;
  } catch (err) {
    return "Please provide the javascript date";
  }
};

// BEFORE NAVIGATING TO SOLUTION SCREEN FROM PROJECTS BELOW FUNCTION IS CALLED
export const setProjectID_Of_solution_module = (projectId) => {
  sessionStorage.setItem("selectedProject", projectId);
};
// Get selected project id from session storage
export const getProjectID_Of_solution_module = () => {
  const projectID = sessionStorage.getItem("selectedProject");
  return projectID;
};
// SET PROJECT NAME IN SESSION STORAGE
export const setProjectName_Of_solution_module = (projectName) => {
  sessionStorage.setItem("selectedProjectName", projectName);
};
// GET PROJECT NAME FROM SESSION STORAGE
export const getProjectName_Of_solution_module = () => {
  const projectName = sessionStorage.getItem("selectedProjectName");
  return projectName;
};

// GET STROKE COLOR BASED ON THE PERCENTAGE
export const getStrokeColorByPercentageUsed = (percentReceived) => {
  try {
    const percent = Number(percentReceived);
    let strokeColor = "";
    if (percent > 100) {
      // over used
      strokeColor = "#AA2417";
    }
    if (percent < 100) {
      // not yet completely used
      strokeColor = "#008000";
    }
    return strokeColor;
  } catch (err) {
    return "";
  }
};

// CHECK IF IMAGE HEADER IS VALID BASED ON THE MAGIC NUMBERS
export const checkIfImageIsValid = async (imageFile) => {
  try {
    const blob = imageFile[0]; // See step 1 above
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(blob);
    const fileHeader = await new Promise((resolve) => {
      fileReader.onloadend = function (e) {
        const arr = new Uint8Array(e.target.result).subarray(0, 4);
        let header = "";
        for (var i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        resolve(header);
      };
    });
    let isImageValid = false;
    const imageMagicNumbers = [
      { headerCode: "89504e47", type: "image/png" },
      { headerCode: "ffd8ffe1", type: "image/jpg" },
      { headerCode: "ffd8ffe8", type: "image/jpeg" },
      { headerCode: "ffd8ffe0", type: "image/JPG" }, // this header is observed when a screenshot from snipping tool is uploaded
    ];
    imageMagicNumbers.forEach((each) => {
      if (each.headerCode === fileHeader) {
        isImageValid = true;
      }
    });
    return isImageValid;
  } catch (err) {
    return false; // not a valid file
  }
};
// CHECK FOR VALID PARANTHESIS
export const checkForValidParanthesisOnString = (text) => {
  try {
    const returnBody = {
      isValid: false,
      message: "",
    };
    /*
			sample valid text with brackets are as below
			1. "" = true
			2. "()" = true
			3. "()[]{}" = true
			4. "{[]}" = true
		*/
    const mapBrackets = {
      ")": "(", // closing : opening
      "}": "{",
      "]": "[",
    };
    const allOpeningBrackets = Object.values(mapBrackets).map((val) => val);
    const allClosingBrackets = Object.keys(mapBrackets).map((val) => val);
    // run the validation if brackets are present in the text
    let runValidation = false;
    allOpeningBrackets.forEach((bracket) => {
      if (text.includes(bracket)) {
        runValidation = true;
      }
    });
    allClosingBrackets.forEach((bracket) => {
      if (text.includes(bracket)) {
        runValidation = true;
      }
    });
    if (runValidation) {
      let stack = [];
      const indexesOfBrackets = [];
      for (let i = 0; i < text.length; i++) {
        // if text has any opening brackets, then push the bracket into the stack
        if (allOpeningBrackets.includes(text[i])) {
          stack.push(text[i]);
        } else if (stack[stack.length - 1] === mapBrackets[text[i]]) {
          // if the stack has the opening bracket and matches with the closing bracket then pop the bracket out of stack
          stack.pop();
        }
        // capture position index of all brackets
        allOpeningBrackets.forEach((bracket) => {
          if (text[i] === bracket) {
            const indexOfBracket = i;
            indexesOfBrackets.push(indexOfBracket);
          }
        });
        allClosingBrackets.forEach((bracket) => {
          if (text[i] === bracket) {
            const indexOfBracket = i;
            indexesOfBrackets.push(indexOfBracket);
          }
        });
      }
      if (indexesOfBrackets.length > 1) {
        indexesOfBrackets.reduce((previousValue, currentValue, currentIndex, array) => {
          // check if there is text in between the brackets
          const isTextThereBetweenTwoBrackets = currentValue - array[currentIndex - 1] === 1 ? false : true;
          if (!isTextThereBetweenTwoBrackets) {
            // the string is not valid
            stack.push(false);
          }
        });
      }
      // if there are odd number of brackets, then the string is invalid
      if (indexesOfBrackets.length % 2 > 0) {
        // the string is not valid
        stack.push(false);
      }

      if (stack.length > 0) {
        // the string is not valid
        returnBody.isValid = false;
        returnBody.message = "Please enter a valid string";
      } else {
        // the string is valid
        returnBody.isValid = true;
        returnBody.message = "";
      }
    } else {
      // the string does not have any brackets in it
      returnBody.isValid = true;
      returnBody.message = "";
    }
    return returnBody;
  } catch (err) {
    return {
      isValid: false,
      message: err.message,
    };
  }
};
// CHECK FOR VALID PARANTHESIS
export const checkForValidQuotationsOnString = (text) => {
  try {
    const returnBody = {
      isValid: false,
      message: "",
    };
    /*
			sample text with valid quotes are as below
			1. "apple" = true
			2. 'apple' = true
			3. "it's an apple" = true
			4. 'it's an apple' = false
		*/
    const doubleQuote = '"';
    let stack_doublequotes = [];
    for (let i = 0; i < text.length; i++) {
      // if text has any opening quotes, then push the quote into the stack_doublequotes
      if (text[i] === doubleQuote) {
        // stack will have the index of the double quotes present in the string
        stack_doublequotes.push(i);
      }
    }

    // results of the check is saved in here
    const stack = [];
    // check for sequencial order of the indexes present in the stack_doublequotes
    if (stack_doublequotes.length > 1) {
      stack_doublequotes.reduce((previousValue, currentValue, currentIndex, array) => {
        // check if there is text in between the quotes
        const isTextThereBetweenTwoQuotes = currentValue - array[currentIndex - 1] === 1 ? false : true;
        if (!isTextThereBetweenTwoQuotes) {
          // the string is not valid
          stack.push(false);
        }
        return array[currentIndex - 1]; // returns the previousValue
      });
    }
    // if there are odd number of double quotes, then the string is invalid
    if (stack_doublequotes.length % 2 > 0) {
      // the string is not valid
      stack.push(false);
    }

    if (stack.length > 0) {
      // the string is not valid
      returnBody.isValid = false;
      returnBody.message = "Please enter a valid string";
    } else {
      // the string is valid
      returnBody.isValid = true;
      returnBody.message = "";
    }
    return returnBody;
  } catch (err) {
    return {
      isValid: false,
      message: err.message,
    };
  }
};

// SPLIT THE STRING BY COMMA AND RETURN THE ARRAY
export const splitStringByComma = (str) => {
  try {
    const arr = str.split(",");
    return arr;
  } catch (err) {
    return str;
  }
};

// Clear selected project id from session storage
export const clearProjectID_Of_solution_module = () => {
  sessionStorage.removeItem("selectedProject");
};
// SET SOLUTION ID OF A SOLUTION MODULE
export const setSolutionID_Of_solution_module = (solutionId) => {
  sessionStorage.setItem("selectedSolutionID", solutionId);
};
// Get selected solution id from session storage
export const getSolutionID_Of_solution_module = () => {
  const solutionID = sessionStorage.getItem("selectedSolutionID");
  return solutionID;
};
// Clear selected solution id from session storage
export const clearSolutionID_Of_solution_module = () => {
  sessionStorage.removeItem("selectedSolutionID");
};

// GET SELECTED TEXT FOR SELECT BOX
export const getSelectedTextForMultiSelectBox = (activeItemsLength, polyglot) => {
  return `${polyglot.t("number_of_items_selected", { number: activeItemsLength })}`;
};

// CHECK IF XLSX FILE IS VALID BASED ON MAGIC NUMBERS
export const checkIfXlsxIsValid = async (givenFile) => {
  try {
    const blob = givenFile[0];
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(blob);
    const fileHeader = await new Promise((resolve) => {
      fileReader.onloadend = function (e) {
        const arr = new Uint8Array(e.target.result).subarray(0, 4);
        let header = "";
        for (var i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        resolve(header);
      };
    });
    let isXlsxValid = false;
    const xlsxMagicNumbers = [
      { headerCode: "d0cf11e0", type: "xls" },
      { headerCode: "504b34", type: "xlsx" },
    ];
    xlsxMagicNumbers.forEach((each) => {
      if (each.headerCode === fileHeader) {
        isXlsxValid = true;
      }
    });
    return isXlsxValid;
  } catch (err) {
    return false; // not a valid file
  }
};

// CHECK FOR VALID PARANTHESIS
export const checkForValidSingleQuotationsOnString = (text) => {
  try {
    const returnBody = {
      isValid: false,
      message: "",
    };
    /*
			sample text with valid quotes are as below
			1. "apple" = true
			2. 'apple' = true
			3. "it's an apple" = true
			4. 'it's an apple' = false
		*/
    const singleQuote = "'";
    let stack_singlequotes = [];
    for (let i = 0; i < text.length; i++) {
      // if text has any opening quotes, then push the quote into the singleQuote
      if (text[i] === singleQuote) {
        // stack will have the index of the double quotes present in the string
        stack_singlequotes.push(i);
      }
    }

    // results of the check is saved in here
    const stack = [];
    // check for sequencial order of the indexes present in the stack_singlequotes
    if (stack_singlequotes.length > 1) {
      stack_singlequotes.reduce((previousValue, currentValue, currentIndex, array) => {
        // check if there is text in between the quotes
        const isTextThereBetweenTwoQuotes = currentValue - array[currentIndex - 1] === 1 ? false : true;
        if (!isTextThereBetweenTwoQuotes) {
          // the string is not valid
          stack.push(false);
        }
        return array[currentIndex - 1]; // returns the previousValue
      });
    }
    // if there are odd number of double quotes, then the string is invalid
    if (stack_singlequotes.length % 2 > 0) {
      // the string is not valid
      stack.push(false);
    }

    if (stack.length > 0) {
      // the string is not valid
      returnBody.isValid = false;
      returnBody.message = "Please enter a valid string";
    } else {
      // the string is valid
      returnBody.isValid = true;
      returnBody.message = "";
    }
    return returnBody;
  } catch (err) {
    return {
      isValid: false,
      message: err.message,
    };
  }
};

// CHECK IF TWO ARRAYS ARE EQUAL
export const areArraysEqual = (array1, array2) => {
  try {
    let didArrayLengthMatch = false;
    let didValuesMatch = false;
    // if these 2 arrays are valid then then the below
    if (Array.isArray(array1) && Array.isArray(array2)) {
      if (array1.length === array2.length) {
        didArrayLengthMatch = true;
      }
    } else {
      throw new Error("Invalid parameters");
    }
    if (didArrayLengthMatch) {
      didValuesMatch = array1.every((val, i) => val === array2[i]);
    }
    return didValuesMatch;
  } catch (err) {
    displayToastMessage(err.message, "error");
    return false;
  }
};

// CHECK IF GI FILE IS VALID BASED ON THE MAGIC NUMBERS
export const checkIfGIFileIsValid = async (imageFile) => {
  try {
    const blob = imageFile[0]; // See step 1 above
    const fileReader = new FileReader();
    fileReader.readAsArrayBuffer(blob);
    const fileHeader = await new Promise((resolve) => {
      fileReader.onloadend = function (e) {
        const arr = new Uint8Array(e.target.result).subarray(0, 4);
        let header = "";
        for (var i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        resolve(header);
      };
    });
    let isImageValid = false;
    const imageMagicNumbers = [
      { headerCode: "89504e47", type: "image/png" },
      { headerCode: "ffd8ffe1", type: "image/jpg" },
      { headerCode: "ffd8ffe8", type: "image/jpeg" },
      { headerCode: "ffd8ffe0", type: "image/JPG" },
      { headerCode: "443a5c70", type: "image/JPG" },
      { headerCode: "d0cf11e0", type: "xls" },
      { headerCode: "504b34", type: "xlsx" }, //docs,pptx header include in xlsx header
      { headerCode: "25504446", type: "application/pdf" },
    ];
    imageMagicNumbers.forEach((each) => {
      if (each.headerCode === fileHeader) {
        isImageValid = true;
      }
    });
    return isImageValid;
  } catch (err) {
    return false; // not a valid file
  }
};
