import CommonService from "../Shared/CommonService";

class CategoryService {
  constructor() {
    this.baseApiUrl = CommonService.get().getGRCBaseApiUrl();
  }

  getCurrentCategoryURL(latestRootId) {
    return `${this.baseApiUrl}/getCurrentCategory/${latestRootId}`;
  }
  getCategoryURL(categoryId) {
    return `${this.baseApiUrl}/getCategory/${categoryId}`;
  }
  postExistCategoryURL() {
    return `${this.baseApiUrl}/postExistCategory`;
  }
  postCategoryURL() {
    return `${this.baseApiUrl}/postCategory`;
  }
}

// Enable Singleton behavior on this class
CategoryService.__instance = null;
CategoryService.get = () => {
  if (!CategoryService.__instance) {
    CategoryService.__instance = new CategoryService();
  }
  return CategoryService.__instance;
};

export default CategoryService;
