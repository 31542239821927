// CHECK IF LOGGEDIN USER HAS CREATE PERMISSION
export const hasCreatePermission = (roleArray) => {
  let canCreate = false;
  if (roleArray) {
    roleArray.forEach((role) => {
      if (role === "Create") {
        canCreate = true;
      }
    });
    return canCreate;
  }
  return canCreate;
};
// CHECK IF LOGGEDIN USER HAS EDIT PERMISSION
export const hasEditPermission = (roleArray) => {
  let canEdit = false;
  if (roleArray) {
    roleArray.forEach((role) => {
      if (role === "Edit") {
        canEdit = true;
      }
    });
    return canEdit;
  }
  return canEdit;
};
// CHECK IF LOGGEDIN USER HAS DELETE PERMISSION
export const hasDeletePermission = (roleArray) => {
  let canDelete = false;
  if (roleArray) {
    roleArray.forEach((role) => {
      if (role === "Delete") {
        canDelete = true;
      }
    });
    return canDelete;
  }
  return canDelete;
};
// CHECK IF LOGGEDIN USER HAS VIEW PERMISSION
export const hasViewPermission = (roleArray) => {
  let canView = false;
  if (roleArray) {
    roleArray.forEach((role) => {
      if (role === "View") {
        canView = true;
      }
    });
    return canView;
  }
  return canView;
};
