import HttpClient from "../../Shared/HttpClient";

class NavBarService {
  constructor() {
    this.client = new HttpClient();
  }
  // API TO GET TAB NAMES OF QUEENBASE MODULE OF LOGGEDIN USER
  async getQueenNavBar() {
    const result = await this.client.get("/getQueenNavBar");
    return result;
  }

  // API TO GET TAB NAMES OF SOLUTION MODULE
  async getSolutionNavBar(solutionName) {
    const result = await this.client.get(`/getSolutionNavBar/${solutionName}`);
    return result;
  }
}

NavBarService.__instance = null;
NavBarService.get = () => {
  if (!NavBarService.__instance) {
    NavBarService.__instance = new NavBarService();
  }
  return NavBarService.__instance;
};

export default NavBarService;
