class CommonService {
  isAuthenticated() {
    if (sessionStorage.getItem("jwt")) {
      return true;
    } else {
      return false;
    }
  }
  isFirst() {
    if (sessionStorage.getItem("jwt")) {
      return sessionStorage.getItem("isFirst") === "1";
    } else {
      return false;
    }
  }

  getAuthBaseApiUrl() {
    return process.env.REACT_APP_SERVER_HOST_BASE;
  }

  getBaseApiUrl() {
    return process.env.REACT_APP_SERVER_QUEEN_BASE_HOST;
  }

  getDFBaseApiUrl() {
    return process.env.REACT_APP_SERVER_DF_BASE_HOST;
  }
  getGIBaseApiUrl() {
    return process.env.REACT_APP_SERVER_GI_BASE_HOST;
  }

  getCSSBaseApiUrl() {
    return process.env.REACT_APP_SERVER_CSS_BASE_HOST;
  }

  getRIBaseApiUrl() {
    return process.env.REACT_APP_SERVER_RI_BASE_HOST;
  }
  getCSSBaseApiUrl() {
    return process.env.REACT_APP_SERVER_CSS_BASE_HOST;
  }
  getGRCBaseApiUrl() {
    return process.env.REACT_APP_SERVER_GRC_BASE_HOST;
  }
  getGRCBaseRemoteApiUrl() {
    return process.env.REACT_APP_SERVER_GRC_BASE_REMOTE_HOST;
  }
  getCustomisedDate(date) {
    let today = new Date(date);
    today = new Date(today.getFullYear(), today.getMonth(), today.getDate());
    return today;
  }
  getBufferImageInBlob(image) {
    var imageBlog = new Blob([Buffer.from(image)]);
    return imageBlog;
  }
  base64toarraybuffer(str) {
    var binaryString = window.atob(str);
    var len = binaryString.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binaryString.charCodeAt(i);
    }
    return bytes;
  }

  async getBufferImage(image) {
    var res = Buffer.from(image.data);
    return res;
  }

  titleCase(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map(function (word) {
        return word.charAt(0).toUpperCase() + word.slice(1);
      })
      .join(" ");
  }
}

// Enable Singleton behavior on this class
CommonService.__instance = null;
CommonService.get = () => {
  if (!CommonService.__instance) {
    CommonService.__instance = new CommonService();
  }
  return CommonService.__instance;
};

export default CommonService;
