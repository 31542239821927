import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import "../../../Assets/Css/DFED/Profile.css";
import {
  Tabs,
  Tab,
  Button,
  Alert,
  Accordions,
  AccordionItem,
  AccordionItemHeader,
  AccordionItemBody,
} from "appkit-react";
import {
  GetProfileName,
  RadioView,
  CheckBoxView,
  TabView1,
  TabView2,
  TabView3,
  TabView5,
} from "./Views/EditView";
import {
  allProfilesWithDetailsByProjectId,
  getProfilesProcess,
  getProfileAnalysisLanguage,
  getProfilesMailFileEncoding,
  getProfilesFileTypeService,
  getProfilesMimeTypeService,
  getProfilesOcrPDFService,
  getProfilesOcrImageService,
  getProfilesOcrQualityService,
  editProfileService,
  getProfilesOcrRotationService,
  getProfilesOcrTimeOutService,
  getProfilesGenTimezoneService,
  profileByIdService,
  uploadNsfCsv,
} from "../../../Services/DFED/ProfileService";
import { FormErrors } from "./Views/FormErrors";
import $ from "jquery";
import displayToastMessage from "../../../Components/QueenBase/Root/displayToastMessage";
import BackButton from "../Shared/button/BackButton";
import { polyglotLoader, getCurrentLanguage } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import TabViewOcr from "./Views/TabViewOcr";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { splitStringByComma } from "../../../Services/Shared/Handlers";
import { isEqual } from "lodash";
// THIS CLASS IS RESPONSIBLE FOR EDITING THE CREATED PROFILE=============================
export class EditProfile extends Component {
  constructor(props) {
    super(props);
    var polyglot = polyglotLoader();
    this.state = {
      radioViewData: [],
      checkBoxViewData: [
        {
          id: 1,
          title: polyglot.t("Recover deleted emails"),
          isDisabled: false,
          isChecked: false,
          attr: "recoverDeleteEmail",
          subTitle: polyglot.t(
            "Extracts deleted files from PST, OST and EDB mailboxes at a lower level, allowing access to slack space items",
          ),
          name: "recoverDelEmail",
        },
        {
          id: 2,
          title: polyglot.t("Recover deleted files"),
          isDisabled: false,
          isChecked: false,
          attr: "recoverDeleteFile",
          subTitle: polyglot.t("Recovers deleted files from disk images"),
          name: "recoverDelFile",
        },
      ],
      timeZoneData: [],
      analysisLanguage: [],
      ocrAnalysisLanguage: [],
      tab1FirstCheckboxList: [
        {
          id: 1,
          name: "isStemming",
          attr: "isStemming",
          title: polyglot.t("Stemming"),
          isDisabled: false,
          isChecked: false,
          subTitle: polyglot.t("English only"),
        },
        {
          id: 2,
          name: "isExactQueries",
          attr: "isExactQueries",
          title: polyglot.t("Exact queries"),
          isDisabled: false,
          isChecked: false,
          subTitle: polyglot.t("Takes longer"),
        },
        {
          id: 3,
          name: "isDetectFace",
          attr: "isDetectFace",
          title: polyglot.t("Detect faces"),
          isDisabled: false,
          isChecked: false,
          subTitle: polyglot.t("Detect faces in photos"),
        },
      ],
      tab1MiddleCheckboxList: [
        {
          id: 1,
          name: "selectedGenSkinToneAnaysis",
          attr: "selectedGenSkinToneAnaysis",
          title: polyglot.t("Skin tone analysis"),
          isDisabled: false,
          isChecked: false,
          subTitle: polyglot.t("Takes longer"),
        },
      ],
      tab1SecondCheckboxList: [
        {
          id: 1,
          title: polyglot.t("Add BCC (Email Digest)"),
          name: "isBCC",
          attr: "isBCC",
          isDisabled: false,
          isChecked: false,
          subTitle: polyglot.t("Include BCC in Email MD5 calculation?"),
        },
        {
          id: 2,
          title: polyglot.t("Add Communication Date (Email Digest)"),
          name: "isAddCommuDate",
          attr: "isAddCommuDate",
          isDisabled: false,
          isChecked: false,
          subTitle: polyglot.t("Include Communication Date in Email MD5 calculation?"),
        },
      ],
      tab2NSFEncoding: [],
      tab3FileType: [],
      tab4MimeType: [],
      tab5Pdf: [],
      tab5Images: [],
      tab5ImagesType: [],
      tab5ExcludeType: [
        {
          id: 1,
          attr: "isExcludeEncItem",
          title: polyglot.t("Exclude encrypted items"),
          name: "isExcludeEncItem",
          isDisabled: false,
          isChecked: false,
        },
        {
          id: 2,
          attr: "isExcludeCorruptedItem",
          name: "isExcludeCorruptedItem",
          title: polyglot.t("Exclude corrupted items"),
          isDisabled: false,
          isChecked: false,
        },
      ],
      tab5TextSection: [
        { id: 1, title: polyglot.t("Replace Text") },
        { id: 2, title: polyglot.t("Append Text") },
      ],
      ocrClearCacheData: [
        {
          id: 1,
          name: "isOcrClearCache",
          attr: "isOcrClearCache",
          title: polyglot.t("Clear OCR cache on completion"),
          isDisabled: false,
          isChecked: false,
          subTitle: polyglot.t("Clear OCR cache on completion"),
        },
      ],
      tab5Quality: [],
      tab5Rotation: [],
      tab5TimeOut: [],
      expanded: false,
      toggleClass: "hideClass",
      profileName: "",
      selectedProcess: 0,
      recoverDeleteEmail: 0,
      recoverDeleteFile: 0,
      selectedTimeZone: 0,
      selectedGenAnalysisLan: 0,
      isStemming: 0,
      isExactQueries: 0,
      isDetectFace: 0,
      selectedGenCarvingBlock: 0,
      selectedGenDigestSize: 0,
      selectedGenSkinToneAnaysis: 0,
      isBCC: 0,
      isExactQuery: 0,
      isAddCommuDate: 0,
      nsfCredentialsName: 0,
      nsfFileEncoding: 0,
      selectedFileType: [],
      selectedMimeType: [],
      ocrPDFSelectedVal: 0,
      ocrSpecificImage: [],
      ocrNonSpecificImage: 0,
      isExcludeEncItem: 0,
      isExcludeCorruptedItem: 0,
      ocrModifyText: 0,
      ocrQuality: 0,
      ocrRation: 0,
      ocrTimeOutVal: 0,
      isOcrClearCache: 0,
      ocrLanguageVal: 0,
      profileSave: "",
      profileSaveMsg: "",
      redirectAfterProfileSave: false,
      formErrors: {
        profileNameErr: "",
        processTypeErr: "",
        timeZoneErr: "",
        analysisLanguageErr: "",
        NSFFileEncError: "",
        NSFFileError: "",
        ocrImgErr: "",
        ocrQualityErr: "",
        ocrRotationErr: "",
        ocrLanguageErr: "",
      },
      formValid: false,
      viewProfileId: 0,
      isViewProfile: false,
      isNewProfile: false,
      isBackBtn: false,
      isCheckedOcrCache: false,
      selectedProjectId: props.location.state.selectedProjectId,
      isProfileExistsInProcess: true,
      selectedAccordianTab: ["0"],
      apiProfileData: [],
    };
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
  }
  // LIFE CYCLE METHOD FOR GETTING ALL DATA RELATED TO PROFILE COMPONENT=========================

  polyglot = polyglotLoader();
  static contextType = DataContext;

  componentDidMount = () => {
    const { getLanguageKeyAsPerAPI } = this.context;
    const lang = getLanguageKeyAsPerAPI();
    if (this.props.location.state !== undefined) {
      // Note: if there is a profileid
      if (this.props.location.state.profileId) {
        if (this.props.location.state.hasOwnProperty("profileId")) {
          this.setState(
            {
              viewProfileId: this.props.location.state.profileId,
            },
            () => {
              // this.getAllProfileProcessType();
              // this.getProfileAllLanguage();
              // this.getProfileAllMailFileEncoding();
              // this.getProfileAllFileType();
              // this.getProfileAllMimeType();
              // this.getProfileAllOcrPdfType();
              // this.getProfileAllOcrImagesType();
              // this.getProfileAllOcrQualityType();
              // this.getProfileAllOcrRotation();
              // this.getProfileAllOcrTimeOut();
              // this.getProfileAllGenTimezone();
              this.getProfileByIdService(this);
            },
          );
        }
        if (this.props.location.state.hasOwnProperty("isProfileExists")) {
          if (this.props.location.state.isProfileExists === false) {
          }
          this.setState({
            isProfileExistsInProcess: this.props.location.state.isProfileExists,
          });
        }
      } else {
        // NOTE: IF profileid is not there then go back
        // go back function
        window.history.back();
      }
    }
    // ALL REQUIRED FUNCTION FOR GETTING DATA================================
    this.getAllProfileProcessType();
    this.getProfileAllLanguage(lang);
    this.getProfileAllMailFileEncoding();
    this.getProfileAllFileType();
    this.getProfileAllMimeType();
    this.getProfileAllOcrPdfType();
    this.getProfileAllOcrImagesType();
    this.getProfileAllOcrQualityType();
    this.getProfileAllOcrRotation();
    this.getProfileAllOcrTimeOut();
    this.getProfileAllGenTimezone();
    this.getProfileByIdService(this);
    // this.commonDomManipulationJQ();
  };

  onBackButtonClick() {
    this.setState({ isBackBtn: true });
  }

  // GETTING PROFILE BY ID
  getProfileByIdService = async () => {
    let newProfile = false;
    const defineProfileMasterId = this.state.viewProfileId;
    const profileData = defineProfileMasterId && (await profileByIdService(defineProfileMasterId));

    if (profileData.status === 200) {
      if (newProfile === true) {
        let fileType = profileData.data[0]["PR_PROCESS_FILE_TYPE"].split(",");
        for (let j = 0; j < fileType.length; j++) {
          this.fileTypeHandler("", fileType[j]);
        }

        let ocrImgType = profileData.data[0]["PR_OCR_IMG"].split(",");
        for (let j = 0; j < ocrImgType.length; j++) {
          if (parseInt(ocrImgType[j]) === 6 || parseInt(ocrImgType[j]) === 7) {
            this.ocrSpecificImageHandler("", ocrImgType[j]);
          }
        }
        this.setState(
          (state, props) => ({
            recoverDeleteEmail: profileData.data[0]["PR_RECOVER_DELETED_EMAILS"],
            recoverDeleteFile: profileData.data[0]["PR_RECOVER_DELETED_FILES"],
            isExcludeEncItem: profileData.data[0]["PR_OCR_EXCLUDE_ENCR_ITEMS"],
            isExcludeCorruptedItem: profileData.data[0]["PR_OCR_EXCLUDE_CORRUPTED_ITEMS"],
          }),
          () => {},
        );
      }
      let genLanguage =
        profileData.data[0] && profileData.data[0]["PR_OCR_LANGUAGE"]
          ? profileData.data[0]["PR_OCR_LANGUAGE"].split(",")
          : "";
      this.state.ocrAnalysisLanguage.forEach((element) => {
        let temp = genLanguage.includes(element.ID);
        if (temp === true) {
          element.isDisabled = true;
          element.isChecked = true;
        }
      });

      this.state.checkBoxViewData.forEach((ele) => {
        ele.isDisabled = true;
        if (ele.attr === "recoverDeleteEmail") {
          ele.isChecked =
            profileData.data[0] && profileData.data[0]["PR_RECOVER_DELETED_EMAILS"]
              ? profileData.data[0]["PR_RECOVER_DELETED_EMAILS"]
              : "";
        } else {
          ele.isChecked =
            profileData.data[0] && profileData.data[0]["PR_RECOVER_DELETED_FILES"]
              ? profileData.data[0]["PR_RECOVER_DELETED_FILES"]
              : "";
        }
      });

      this.state.tab1FirstCheckboxList.forEach((ele) => {
        ele.isDisabled = true;
        if (ele.attr === "isStemming") {
          ele.isChecked =
            profileData.data[0] && profileData.data[0]["PR_STEMMING"]
              ? profileData.data[0]["PR_STEMMING"]
              : "";
        } else if (ele.attr === "isExactQueries") {
          ele.isChecked =
            profileData.data[0] && profileData.data[0]["PR_EXACT_QUERIES"]
              ? profileData.data[0]["PR_EXACT_QUERIES"]
              : "";
        } else {
          ele.isChecked =
            profileData.data[0] && profileData.data[0]["PR_DETECT_FACES"]
              ? profileData.data[0]["PR_DETECT_FACES"]
              : "";
        }
      });

      this.state.tab1MiddleCheckboxList[0].isChecked =
        profileData.data[0] && profileData.data[0]["PR_SKIN_TONE_ANALYSIS"]
          ? profileData.data[0]["PR_SKIN_TONE_ANALYSIS"]
          : "";
      this.state.tab1MiddleCheckboxList[0].isDisabled = true;

      this.state.tab1SecondCheckboxList.forEach((ele) => {
        ele.isDisabled = true;
        if (ele.attr === "isBCC") {
          ele.isChecked =
            profileData.data[0] && profileData.data[0]["PR_ADD_BCC"] ? profileData.data[0]["PR_ADD_BCC"] : "";
        } else {
          ele.isChecked =
            profileData.data[0] && profileData.data[0]["PR_ADD_COMMUNICATION_DATE"]
              ? profileData.data[0]["PR_ADD_COMMUNICATION_DATE"]
              : "";
        }
      });

      this.state.tab5ExcludeType.forEach((ele) => {
        ele.isDisabled = true;
        if (ele.attr === "isExcludeEncItem") {
          ele.isChecked =
            profileData.data[0] && profileData.data[0]["PR_OCR_EXCLUDE_ENCR_ITEMS"]
              ? profileData.data[0]["PR_OCR_EXCLUDE_ENCR_ITEMS"]
              : "";
        } else {
          ele.isChecked =
            profileData.data[0] && profileData.data[0]["PR_OCR_EXCLUDE_CORRUPTED_ITEMS"]
              ? profileData.data[0]["PR_OCR_EXCLUDE_CORRUPTED_ITEMS"]
              : "";
        }
      });

      let fileType =
        profileData.data[0] && profileData.data[0]["PR_PROCESS_FILE_TYPE"]
          ? profileData.data[0]["PR_PROCESS_FILE_TYPE"].split(",")
          : "";
      for (let j = 0; j < fileType.length; j++) {
        this.fileTypeHandler("", fileType[j]);
      }
      let tempStateTab3 = JSON.parse(JSON.stringify(this.state.tab3FileType));
      for (let j = 0; j < tempStateTab3.length; j++) {
        let temp = fileType.includes(tempStateTab3[j].ID);
        if (temp === true) {
          tempStateTab3[j].isDisabled = true;
          tempStateTab3[j].isChecked = true;
        }
      }

      let mimeType =
        profileData.data[0] && profileData.data[0]["PR_PROCESS_MIME_TYPE"]
          ? profileData.data[0]["PR_PROCESS_MIME_TYPE"].split(",")
          : "";
      let tempStateTab4MimeType = JSON.parse(JSON.stringify(this.state.tab4MimeType));
      for (let j = 0; j < tempStateTab4MimeType.length; j++) {
        let temp = mimeType.includes(tempStateTab4MimeType[j].ID);
        if (temp === true) {
          tempStateTab4MimeType[j].isDisabled = true;
          tempStateTab4MimeType[j].isChecked = true;
        }
      }

      let ocrImgType =
        profileData.data[0] && profileData.data[0]["PR_OCR_IMG"]
          ? profileData.data[0]["PR_OCR_IMG"].split(",")
          : "";
      for (let j = 0; j < ocrImgType.length; j++) {
        if (parseInt(ocrImgType[j]) === 6 || parseInt(ocrImgType[j]) === 7) {
          this.ocrSpecificImageHandler("", ocrImgType[j]);
        }
      }

      let nonSpecificImg = "";
      this.state.tab5Images.forEach((element) => {
        let temp = ocrImgType.includes(element.ID);
        if (temp === true) {
          nonSpecificImg = element.ID;
        }
      });

      let tempStatetab5ImagesType = JSON.parse(JSON.stringify(this.state.tab5ImagesType));
      for (let j = 0; j < tempStatetab5ImagesType.length; j++) {
        let temp = ocrImgType.includes(tempStatetab5ImagesType[j].ID);
        if (temp === true) {
          tempStatetab5ImagesType[j].isDisabled = true;
          tempStatetab5ImagesType[j].isChecked = true;
        }
      }

      let tempStatetab5ExcludeType = JSON.parse(JSON.stringify(this.state.tab5ExcludeType));
      for (let j = 0; j < tempStatetab5ExcludeType.length; j++) {
        tempStatetab5ExcludeType[j].isDisabled = true;
        if (tempStatetab5ExcludeType[j].attr === "isExcludeEncItem") {
          tempStatetab5ExcludeType[j].isChecked =
            profileData.data[0] && profileData.data[0]["PR_OCR_EXCLUDE_ENCR_ITEMS"]
              ? profileData.data[0]["PR_OCR_EXCLUDE_ENCR_ITEMS"]
              : "";
        } else {
          tempStatetab5ExcludeType[j].isChecked =
            profileData.data[0] && profileData.data[0]["PR_OCR_EXCLUDE_CORRUPTED_ITEMS"]
              ? profileData.data[0]["PR_OCR_EXCLUDE_CORRUPTED_ITEMS"]
              : "";
        }
      }

      let tempStateOcrClearCacheData = JSON.parse(JSON.stringify(this.state.ocrClearCacheData));
      for (let j = 0; j < tempStateOcrClearCacheData.length; j++) {
        tempStateOcrClearCacheData[j].isDisabled = true;
        if (tempStateOcrClearCacheData[j].attr === "isOcrClearCache") {
          tempStateOcrClearCacheData[j].isChecked =
            profileData.data[0] && profileData.data[0]["PR_OCR_CACHE_CLEAR"]
              ? profileData.data[0]["PR_OCR_CACHE_CLEAR"]
              : false;
        }
      }

      let ocrCache = false;
      if (profileData.data[0] && profileData.data[0]["PR_OCR_CACHE_CLEAR"] === true) {
        ocrCache = true;
      }

      let tempPrname = "";
      if (newProfile === true) {
        tempPrname = "";
      } else {
        tempPrname =
          profileData.data[0] && profileData.data[0]["PR_NAME"] ? profileData.data[0]["PR_NAME"] : "";
      }

      if (profileData && profileData.data[0]) {
        this.setState(
          (state, props) => ({
            profileName: tempPrname,
            selectedProcess: profileData.data[0]["PR_PROCESS_TYPE"],
            selectedGenCarvingBlock: profileData.data[0]["PR_CARVING_BLOCK_SIZE"],
            selectedGenDigestSize: profileData.data[0]["PR_MAX_DIGEST_SIZE"],
            selectedTimeZone: profileData.data[0]["PR_GENERAL_TIME_ZONE"],
            selectedGenAnalysisLan: splitStringByComma(profileData.data[0]["PR_ANALYSIS_LANGUAGE"]),
            nsfCredentialsName: profileData.data[0]["PR_NSF_CREDENTIALS_FILE"],
            nsfFileEncoding: profileData.data[0]["PR_NSF_CREDENTIALS_FILE_ENCODING"],
            ocrQuality: profileData.data[0]["PR_QUALITY"],
            ocrRation: profileData.data[0]["PR_ROTATION"],
            ocrPDFSelectedVal: profileData.data[0]["PR_OCR_PDF"],
            ocrNonSpecificImage: nonSpecificImg,
            ocrModifyText: profileData.data[0]["PR_TEXT_MANIPULATION"],
            ocrTimeOutVal: profileData.data[0]["PR_OCR_TIMEOUT_VAL"],
            ocrLanguageVal: splitStringByComma(profileData.data[0]["PR_OCR_LANGUAGE"]),
            isCheckedOcrCache: ocrCache,
            tab1FirstCheckboxList: this.state.tab1FirstCheckboxList,
            tab3FileType: tempStateTab3,
            tab4MimeType: tempStateTab4MimeType,
            tab5ImagesType: tempStatetab5ImagesType,
            tab5ExcludeType: tempStatetab5ExcludeType,
            ocrClearCacheData: tempStateOcrClearCacheData,
            recoverDeleteEmail: profileData.data[0]["PR_RECOVER_DELETED_EMAILS"],
            recoverDeleteFile: profileData.data[0]["PR_RECOVER_DELETED_FILES"],
            isExcludeEncItem: profileData.data[0]["PR_OCR_EXCLUDE_ENCR_ITEMS"],
            isExcludeCorruptedItem: profileData.data[0]["PR_OCR_EXCLUDE_CORRUPTED_ITEMS"],
            apiProfileData: profileData.data[0],
          }),
          () => {
            this.getCheckedOcrModifyText(this);
          },
        );
      }
    }
  };

  getCheckedOcrModifyText = () => {
    $(".ocrModifyTextClass[value=" + encodeURI(this.state.ocrModifyText) + "]").prop("checked", true);
  };
  nsfCsvFileUploadHnadler = async (e) => {
    if (this.validatensfCsvFileType(e)) {
      this.setState({
        csvFileDetails: e[0],
      });
    }
  };

  // VALIDATING UPLOADED FILE====================
  validatensfCsvFileType = (event) => {
    let csvFile = event[0];
    let allowType2 = "vnd.ms-excel";
    let tempType;
    if (csvFile && csvFile.type) {
      tempType = csvFile.type.split("/");
    }
    if (tempType && tempType[1] !== allowType2) {
      displayToastMessage(this.polyglot.t(`${csvFile.name} is not valid csv type.`), "error");
      return false;
    }
    return true;
  };

  // FORM FIELD ON CHANGE FUNCTIONALITY==================
  formChangeHandler = async (attr, event, isCheckBox = false) => {
    let tempValue = "";
    if (event.hasOwnProperty("target")) {
      tempValue = event.target.value;
      if (isCheckBox === true) {
        if (event.target.dataset.checkboxname === "recoverCheckBoxList") {
          let checkboxViewData = this.state.checkBoxViewData;
          checkboxViewData.forEach((ele) => {
            if (ele.name === event.target.name) {
              ele.isChecked = event.target.checked;
            }
          });
          this.setState({ checkboxViewData: checkboxViewData });
        } else if (event.target.dataset.checkboxname === "genTabFirstCheckBoxList") {
          let tab1FirstCheckboxList = this.state.tab1FirstCheckboxList;
          tab1FirstCheckboxList.forEach((ele) => {
            if (ele.name === event.target.name) {
              ele.isChecked = event.target.checked;
            }
          });
          this.setState({ tab1FirstCheckboxList: tab1FirstCheckboxList });
        } else if (event.target.dataset.checkboxname === "genTabMiddleCheckBoxList") {
          let tab1MiddleCheckboxList = this.state.tab1MiddleCheckboxList;
          tab1MiddleCheckboxList.forEach((ele) => {
            if (ele.name === event.target.name) {
              ele.isChecked = event.target.checked;
            }
          });
          this.setState({ tab1MiddleCheckboxList: tab1MiddleCheckboxList });
        } else if (event.target.dataset.checkboxname === "genTabSecondCheckBoxList") {
          let tab1SecondCheckboxList = this.state.tab1SecondCheckboxList;
          tab1SecondCheckboxList.forEach((ele) => {
            if (ele.name === event.target.name) {
              ele.isChecked = event.target.checked;
            }
          });
          this.setState({ tab1SecondCheckboxList: tab1SecondCheckboxList });
        } else if (event.target.dataset.checkboxname === "fileTypeTabCheckBoxList") {
          let tab3FileType = this.state.tab3FileType;
          tab3FileType.forEach((ele) => {
            if (ele.name === event.target.name) {
              ele.isChecked = event.target.checked;
            }
          });
          this.setState({ tab3FileType: tab3FileType });
        } else if (event.target.dataset.checkboxname === "mimeTypeTabCheckBoxList") {
          let tab4MimeType = this.state.tab4MimeType;
          tab4MimeType.forEach((ele) => {
            if (ele.name === event.target.name) {
              ele.isChecked = event.target.checked;
            }
          });
          this.setState({ tab4MimeType: tab4MimeType });
        } else if (event.target.dataset.checkboxname === "ocrTabExcludeCheckBoxList") {
          let tab5ExcludeType = this.state.tab5ExcludeType;
          tab5ExcludeType.forEach((ele) => {
            if (ele.name === event.target.name) {
              ele.isChecked = event.target.checked;
            }
          });
          this.setState({ tab5ExcludeType: tab5ExcludeType });
        } else if (event.target.dataset.checkboxname === "ocrTabSpecificImgCheckBoxList") {
          let tab5ImagesType = this.state.tab5ImagesType;
          tab5ImagesType.forEach((ele) => {
            if (ele.name === event.target.name) {
              ele.isChecked = event.target.checked;
            }
          });
          this.state.tab5ImagesType = tab5ImagesType;
          // this.setState({tab5ImagesType:tab5ImagesType})
        } else if (event.target.dataset.checkboxname === "ocrTabClearCacheCheckBoxList") {
          let ocrClearCacheData = this.state.ocrClearCacheData;
          ocrClearCacheData.forEach((ele) => {
            if (ele.name === event.target.name) {
              ele.isChecked = event.target.checked;
            }
          });
          this.state.ocrClearCacheData = ocrClearCacheData;
        }
        tempValue = event.target.checked;
      }
    } else {
      tempValue = event;
    }
    if (attr === "ocrPDFSelectedVal") {
      this.state.ocrPDFSelectedVal = tempValue;
      return;
    } else if (attr === "ocrNonSpecificImage") {
      this.state.ocrNonSpecificImage = tempValue;
      return;
    } else if (attr === "ocrModifyText") {
      this.state.ocrModifyText = tempValue;
      return;
    }
    this.setState({ [attr]: tempValue }, () => {});
  };

  // HANDLEING FILE TYPE=====================
  fileTypeHandler = (event, a) => {
    a = parseInt(a);
    let { selectedFileType } = this.state;
    if (selectedFileType.includes(a)) {
      selectedFileType = selectedFileType.filter((item) => item !== a);
    } else if (!isNaN(a)) {
      selectedFileType.push(a);
    }
    this.setState(
      {
        selectedFileType,
      },
      () => {
        this.updateFileTypeStatus();
      },
    );
  };
  // UPDATING FILE TYPE STATUS===================
  updateFileTypeStatus = () => {
    let selectedFileTypeState = this.state.selectedFileType;
    let tab3FileType = this.state.tab3FileType;
    tab3FileType.forEach((element) => {
      let tempCheck = selectedFileTypeState.includes(parseInt(element.ID));
      if (tempCheck) {
        element.isDisabled = true;
        element.isChecked = true;
      } else {
        element.isDisabled = false;
        element.isChecked = false;
      }
    });
    this.setState((state, props) => ({
      tab3FileType: this.state.tab3FileType,
    }));
  };
  // UPDATE FILE TYPE STATUS=================
  updateFileTypeStatus = () => {
    let selectedFileTypeState = this.state.selectedFileType;
    let tab3FileType = this.state.tab3FileType;
    tab3FileType.forEach((element) => {
      let tempCheck = selectedFileTypeState.includes(parseInt(element.ID));
      if (tempCheck) {
        element.isDisabled = true;
        element.isChecked = true;
      } else {
        element.isDisabled = false;
        element.isChecked = false;
      }
    });
    this.setState((state, props) => ({
      tab3FileType: this.state.tab3FileType,
    }));
  };

  // HANDLEING MIME TYPE INPUT===========
  mimeTypeHandler = (event, a) => {
    a = parseInt(a);
    let { selectedMimeType } = this.state;
    if (selectedMimeType.includes(a)) {
      selectedMimeType = selectedMimeType.filter((item) => item !== a);
    } else {
      selectedMimeType.push(a);
    }
    this.setState(
      {
        selectedMimeType,
      },
      () => {
        this.updateMimeTypeStatus();
      },
    );
  };

  // UPDATE MIME TYPE STATUS=======================
  updateMimeTypeStatus = () => {
    let selectedMimeTypeState = this.state.selectedMimeType;
    let tab4MimeType = this.state.tab4MimeType;
    tab4MimeType.forEach((element) => {
      let tempCheck = selectedMimeTypeState.includes(parseInt(element.ID));
      if (tempCheck) {
        element.isDisabled = true;
        element.isChecked = true;
      } else {
        element.isDisabled = false;
        element.isChecked = false;
      }
    });
    this.setState((state, props) => ({
      tab4MimeType: this.state.tab4MimeType,
    }));
  };

  // OCR SPECIFIC IMAGE===================
  ocrSpecificImageHandler = (event, a) => {
    a = parseInt(a);
    let { ocrSpecificImage } = this.state;
    if (ocrSpecificImage.includes(a)) {
      ocrSpecificImage = ocrSpecificImage.filter((item) => item !== a);
    } else {
      ocrSpecificImage.push(a);
    }
    this.setState(
      {
        ocrSpecificImage,
      },
      () => {
        this.updateOcrSpecificImageStatus();
      },
    );
  };

  // UPDATE OCR SPECIFIC IMAGE STATUS====================
  updateOcrSpecificImageStatus = () => {
    let ocrSpecificImage = this.state.ocrSpecificImage;
    let tab5ImagesType = this.state.tab5ImagesType;
    tab5ImagesType.forEach((element) => {
      let tempCheck = ocrSpecificImage.includes(parseInt(element.ID));
      if (tempCheck) {
        element.isDisabled = true;
        element.isChecked = true;
      } else {
        element.isDisabled = false;
        element.isChecked = false;
      }
    });
    this.setState((state, props) => ({
      tab5ImagesType: this.state.tab5ImagesType,
    }));
  };
  // GETTING ALL PROCESS TYPE DATA===================

  getAllProfileProcessType = async () => {
    let profileProcessType = await getProfilesProcess();
    if (profileProcessType.status === 200) {
      this.setState({ radioViewData: profileProcessType.data });
    }
  };

  // GETTING ALL LANGUAGE======================
  getProfileAllLanguage = async (lang) => {
    let profileLanguage = await getProfileAnalysisLanguage(lang);
    if (profileLanguage.status === 200) {
      let languageData = [];
      let ocrLanguageData = [];
      profileLanguage.data.forEach((element) => {
        languageData.push({ ID: element.id, language: element.language });
        ocrLanguageData.push({
          ID: element.id,
          language: element.language,
          isChecked: false,
          isDisabled: false,
        });
      });
      var temp = { ID: "2", language: "French" };
      var temp1 = { ID: "3", language: "English" };
      languageData.push(temp);
      languageData.push(temp1);

      this.setState({
        analysisLanguage: languageData,
        ocrAnalysisLanguage: ocrLanguageData,
      });
    }
  };

  // GETTING ALL MAIL FILE ENCODING TYPE======================
  getProfileAllMailFileEncoding = async () => {
    let fileEncoding = await getProfilesMailFileEncoding();
    if (fileEncoding.status === 200) {
      this.setState({ tab2NSFEncoding: fileEncoding.data });
    }
  };

  // GETTING ALL PROFILE FILE TYPE=====================
  getProfileAllFileType = async () => {
    let fileType = await getProfilesFileTypeService();
    if (fileType.status === 200) {
      let tempData = [];
      fileType.data.forEach((element) => {
        tempData.push({
          ID: element.ID,
          FILE_TYPE: element.FILE_TYPE,
          isDisabled: false,
          isChecked: false,
          name: element.FILE_TYPE,
        });
      });
      this.setState({ tab3FileType: tempData });
    }
  };
  // GETTING ALL PROFILE MIME TYPE===================

  getProfileAllMimeType = async () => {
    let mimeType = await getProfilesMimeTypeService();
    if (mimeType.status === 200) {
      let tempData = [];
      mimeType.data.forEach((element) => {
        tempData.push({
          ID: element.ID,
          MIME_TYPE: element.MIME_TYPE,
          name: element.MIME_TYPE,
          isDisabled: false,
          isChecked: false,
        });
      });
      this.setState({ tab4MimeType: tempData });
    }
  };
  // GETTING PROFILE OCR-PDF TYPE==================

  getProfileAllOcrPdfType = async () => {
    let pdfType = await getProfilesOcrPDFService();
    if (pdfType.status === 200) {
      this.setState({ tab5Pdf: pdfType.data });
    }
  };
  // GET PROFILE OCR IMAGE TYPE===================

  getProfileAllOcrImagesType = async () => {
    let imagesType = await getProfilesOcrImageService();
    if (imagesType.status === 200) {
      let specificImage = [];
      let nonSpecificImg = [];
      imagesType.data.forEach((element) => {
        if (element.CATEGORY === "Specific types") {
          specificImage.push(element);
        } else {
          nonSpecificImg.push(element);
        }
      });
      this.setState({ tab5Images: nonSpecificImg });

      var tempSpecificImg = [];
      specificImage.forEach((element) => {
        var temp1 = {
          ID: element.ID,
          CATEGORY: element.CATEGORY,
          name: element.SUB_CATEGORY,
          SUB_CATEGORY: element.SUB_CATEGORY,
          TYPE: element.TYPE,
          isDisabled: false,
          isChecked: false,
        };
        tempSpecificImg.push(temp1);
      });
      this.setState({ tab5ImagesType: tempSpecificImg });
    }
  };
  // GET PROFILE OCR QUALITY TYPE=====================

  getProfileAllOcrQualityType = async () => {
    let qualityType = await getProfilesOcrQualityService();
    if (qualityType.status === 200) {
      this.setState({ tab5Quality: qualityType.data });
    }
  };
  // GET PROFILE OCR ROTATION DATA==================

  getProfileAllOcrRotation = async () => {
    let rotationList = await getProfilesOcrRotationService();
    if (rotationList.status === 200) {
      this.setState({ tab5Rotation: rotationList.data });
    }
  };
  // GETTING PROFILE OCR TIMEOUT==========================

  getProfileAllOcrTimeOut = async () => {
    let timeOut = await getProfilesOcrTimeOutService();
    if (timeOut.status === 200) {
      this.setState({ tab5TimeOut: timeOut.data });
    }
  };
  // GET PROFILE ALL GEN TIME ZONE=====================
  getProfileAllGenTimezone = async () => {
    let timeZone = await getProfilesGenTimezoneService();
    if (timeZone.status === 200) {
      this.setState({ timeZoneData: timeZone.data });
    }
  };

  // HANDLEING SHOW MORE=================
  handleShowMore = () => {
    this.setState({ expanded: !this.state.expanded });
    let tempClass = "hideClass";
    if (this.state.toggleClass === "hideClass") tempClass = "showClass";
    this.setState({ toggleClass: tempClass });
  };
  // HANDLE SUBMIT========================

  handleSubmit = async (event) => {
    event.preventDefault();
    const tempNonSpecVal = parseInt(event.target.ocrNonSpecificImg.value);
    let tempOcrImg =
      tempNonSpecVal === 8 ? [tempNonSpecVal, ...this.state.ocrSpecificImage] : [tempNonSpecVal];
    tempOcrImg = tempOcrImg
      .map((item) => parseInt(item))
      .filter((item) => !isNaN(item))
      .sort();
    const tempTimeZone = parseInt(this.state.selectedTimeZone);
    const tempOcrTimeOutVal = parseInt(event.target.ocrTimeoutValue.value);
    let tempSelectedLAnguage = this.state.selectedGenAnalysisLan;
    const formErrors = { ...this.state.formErrors };
    formErrors.profileNameErr = "";
    formErrors.processTypeErr = "";
    formErrors.timeZoneErr = "";
    formErrors.analysisLanguageErr = "";
    formErrors.NSFFileError = "";
    formErrors.ocrImgErr = "";
    formErrors.ocrQualityErr = "";
    formErrors.ocrRotationErr = "";
    formErrors.ocrLanguageErr = "";

    let isInterruptExecution = false;
    let tempSelectedOCRLAnguage = [];
    let prName = this.state.profileName;
    let processType = 0;
    if (
      event.target !== null &&
      (event.target.processType.value === "" ||
        event.target.processType.value === 0 ||
        event.target.processType.value === undefined)
    ) {
      formErrors.processTypeErr = this.polyglot.t("Select any Process type");
      isInterruptExecution = true;
    } else {
      if (event.target === null) {
        processType = this.state.selectedProcess;
      } else {
        processType = parseInt(event.target.processType.value);
      }
    }

    let genCarvBlock = 0;
    if (event.target !== null && event.target.genCarvingBlock) {
      if (event.target.genCarvingBlock.value !== "" || event.target.genCarvingBlock.value !== 0) {
        genCarvBlock = parseInt(event.target.genCarvingBlock.value);
      }
    } else {
      if (this.state.selectedGenCarvingBlock !== 0 || this.state.selectedGenCarvingBlock !== "") {
        genCarvBlock = this.state.selectedGenCarvingBlock;
      }
    }

    let getMaxDigSize = 0;
    if (
      event.target !== null &&
      (event.target.maxDigestSize.value !== "" || event.target.maxDigestSize.value !== 0)
    ) {
      getMaxDigSize = parseInt(event.target.maxDigestSize.value);
    } else {
      if (this.state.selectedGenDigestSize !== 0 || this.state.selectedGenDigestSize !== "") {
        getMaxDigSize = this.state.selectedGenDigestSize;
      }
    }

    let ocrTxModiVal = 0;
    if (
      event.target !== null &&
      (event.target.ocrTextModify.value !== "" || event.target.ocrTextModify.value !== 0)
    ) {
      ocrTxModiVal = parseInt(event.target.ocrTextModify.value);
    } else {
      if (this.state.ocrModifyText !== 0 || this.state.ocrModifyText !== "") {
        ocrTxModiVal = this.state.ocrModifyText;
      }
    }

    if (tempTimeZone === "" || tempTimeZone === 0) {
      formErrors.timeZoneErr = this.polyglot.t("Select Time Zone");
      isInterruptExecution = true;
    }
    if (tempSelectedLAnguage === "" || parseInt(tempSelectedLAnguage) === 0) {
      formErrors.analysisLanguageErr = this.polyglot.t("Select Analysis Language");
      isInterruptExecution = true;
    }
    let formData = new FormData();
    let tempNsfCsvFileName = "";
    if (
      this.state.csvFileDetails &&
      (this.state.csvFileDetails.length > 0 || this.state.csvFileDetails !== undefined)
    ) {
      formData.append("nsfCredentialsFile", this.state.csvFileDetails);
      formData["nsfCredentialsFile"] = this.state.csvFileDetails;
      tempNsfCsvFileName = this.state.csvFileDetails.name;

      let isUploadNsfCsv = await uploadNsfCsv(formData);
      if (isUploadNsfCsv === false) {
        isInterruptExecution = true;
        formErrors.NSFFileError = this.polyglot.t("Error while uploading NSF credentials file");
      }
    }
    if (this.state.nsfFileEncoding === "" || parseInt(this.state.nsfFileEncoding) === 0) {
      formErrors.NSFFileEncError = this.polyglot.t("Select File Encoding Type");
      isInterruptExecution = true;
    }
    if (tempOcrImg.length === 1 && tempOcrImg[0] === 8) {
      formErrors.ocrImgErr = this.polyglot.t("Select a Specific Type of OCR Images");
      isInterruptExecution = true;
    }
    if (this.state.ocrQuality === "" || parseInt(this.state.ocrQuality) === 0) {
      formErrors.ocrQualityErr = this.polyglot.t("Select OCR Quality");
      isInterruptExecution = true;
    }
    if (this.state.ocrRation === "" || parseInt(this.state.ocrRation) === 0) {
      formErrors.ocrRotationErr = this.polyglot.t("Select Rotation");
      isInterruptExecution = true;
    }

    if (this.state.ocrLanguageVal === "" || parseInt(this.state.ocrLanguageVal) === 0) {
      formErrors.ocrLanguageErr = this.polyglot.t("Select Language");
      isInterruptExecution = true;
    } else {
      if (typeof this.state.ocrLanguageVal === "string") {
        [this.state.ocrLanguageVal].forEach((element) => {
          if (element !== "" && element !== isNaN) {
            tempSelectedOCRLAnguage.push(parseInt(element));
          }
        });
      } else {
        this.state.ocrLanguageVal.forEach((element) => {
          if (element !== "" && element !== isNaN) {
            tempSelectedOCRLAnguage.push(parseInt(element));
          }
        });
      }
    }
    let projectId = this.props.location.state.selectedProjectId;
    if (projectId === 0 || projectId === "" || projectId === null) {
      displayToastMessage(this.polyglot.t("Can not process, System need Project details."), "error");
      isInterruptExecution = true;
    }
    this.setState({ formErrors });

    tempSelectedOCRLAnguage = tempSelectedOCRLAnguage
      .map((item) => parseInt(item))
      .filter((item) => !isNaN(item))
      .sort();

    const tempSelectedFileType = this.state.selectedFileType
      .map((item) => parseInt(item))
      .filter((item) => !isNaN(item))
      .sort();

    if (isInterruptExecution === false) {
      var data = {
        PR_PROCESS_TYPE: parseInt(processType),
        PR_RECOVER_DELETED_EMAILS: this.state.recoverDeleteEmail,
        PR_RECOVER_DELETED_FILES: this.state.recoverDeleteFile,
        PR_GENERAL_TIME_ZONE: parseInt(tempTimeZone),
        PR_ANALYSIS_LANGUAGE: parseInt(tempSelectedLAnguage),
        PR_STEMMING: Boolean(this.state.isStemming),
        PR_EXACT_QUERIES: Boolean(this.state.isExactQueries),
        PR_DETECT_FACES: Boolean(this.state.isDetectFace),
        PR_CARVING_BLOCK_SIZE: parseInt(genCarvBlock),
        PR_SKIN_TONE_ANALYSIS: Boolean(this.state.selectedGenSkinToneAnaysis),
        PR_MAX_DIGEST_SIZE: parseInt(getMaxDigSize),
        PR_ADD_BCC: Boolean(this.state.isBCC),
        PR_ADD_COMMUNICATION_DATE: Boolean(this.state.isAddCommuDate),
        PR_NSF_CREDENTIALS_FILE: tempNsfCsvFileName,
        PR_NSF_CREDENTIALS_FILE_ENCODING: parseInt(this.state.nsfFileEncoding),
        PR_PROCESS_FILE_TYPE: tempSelectedFileType.join(),
        PR_PROCESS_MIME_TYPE: this.state.selectedMimeType.join(),
        PR_OCR_PDF: parseInt(this.state.ocrPDFSelectedVal),
        PR_OCR_IMG: tempOcrImg.join(),
        PR_OCR_EXCLUDE_ENCR_ITEMS: this.state.isExcludeEncItem,
        PR_OCR_EXCLUDE_CORRUPTED_ITEMS: this.state.isExcludeCorruptedItem,
        PR_TEXT_MANIPULATION: parseInt(ocrTxModiVal),
        PR_QUALITY: parseInt(this.state.ocrQuality),
        PR_ROTATION: parseInt(this.state.ocrRation),
        PR_OCR_TIMEOUT_VAL: String(tempOcrTimeOutVal),
        PR_OCR_CACHE_CLEAR: event.target.isOcrClearCache.checked,
        PR_OCR_LANGUAGE: tempSelectedOCRLAnguage.join(),
        PR_PROJECT_ID: parseInt(projectId),
        PR_CREATED_ON: new Date(),
        PR_NAME: prName,
      };

      let { data: profilesList } = await allProfilesWithDetailsByProjectId(projectId);

      const newProfile = this.checkIfProfileHasUniqueSettings(data, profilesList);

      if (!newProfile.hasUniqueSettings) {
        if (newProfile.profileName === data.PR_NAME) {
          const profileSaveMsg = this.polyglot.t("You have not changed any settings in your profile.");
          displayToastMessage(profileSaveMsg, "error");
          let temp = this;
          setTimeout(function () {
            temp.setState({
              profileSave: "",
              profileSaveMsg: "",
              redirectAfterProfileSave: true,
            });
          }, 1500);
          return;
        }

        const profileSaveMsg =
          this.polyglot.t("After edition your profile has the same settings as: ") +
          newProfile.profileName +
          this.polyglot.t(". Your profile is not updated.");
        displayToastMessage(profileSaveMsg, "error");
        let temp = this;
        setTimeout(function () {
          temp.setState({
            profileSave: "",
            profileSaveMsg: "",
            redirectAfterProfileSave: true,
          });
        }, 1500);
        return;
      }

      const isPostedProfile = await editProfileService(data, this.state.viewProfileId);

      if (parseInt(isPostedProfile?.status) === 200) {
        const profileSaveMsg = this.polyglot.t("Profile save successfully...");
        displayToastMessage(profileSaveMsg, "success");
        let temp = this;
        setTimeout(function () {
          temp.setState({
            profileSave: "",
            profileSaveMsg: "",
            redirectAfterProfileSave: true,
          });
        }, 1500);
      } else {
        this.setState({
          profileSave: "warning",
          profileSaveMsg: this.polyglot.t("Something went wrong while inserting profile data."),
        });
        let temp = this;
        setTimeout(function () {
          temp.setState({
            profileSave: "",
            profileSaveMsg: "",
          });
        }, 1500);
      } // ELSE OVER
      // IF OVER : has own property "statue"
    } // IF OVER : is interrupt execution == false
  };

  checkIfProfileHasUniqueSettings = (newProfileData, profilesListData) => {
    const newProfile = { ...newProfileData };
    const profilesList = [...profilesListData];

    const newProfileObjToArray = Object.entries(newProfile);
    const newProfileFiltered = newProfileObjToArray.filter(
      ([key, value]) =>
        key !== "PR_ANALYSIS_LANGUAGE" &&
        key !== "PR_CREATED_BY" &&
        key !== "PR_CREATED_ON" &&
        key !== "PR_NAME" &&
        key !== "PR_PROJECT_ID",
    );

    let newProfileSettings = Object.fromEntries(newProfileFiltered);

    newProfileSettings.PR_OCR_IMG = newProfileSettings.PR_OCR_IMG.toString();
    newProfileSettings.PR_OCR_LANGUAGE = newProfileSettings.PR_OCR_LANGUAGE.toString();
    newProfileSettings.PR_PROCESS_FILE_TYPE = newProfileSettings.PR_PROCESS_FILE_TYPE.toString();
    newProfileSettings.PR_PROCESS_MIME_TYPE = newProfileSettings.PR_PROCESS_MIME_TYPE.toString();

    for (let i = 0; i < profilesList.length; i++) {
      const profileObjToArray = Object.entries(profilesList[i]);
      const profileFiltered = profileObjToArray.filter(
        ([key, value]) =>
          key !== "PR_ANALYSIS_LANGUAGE" &&
          key !== "PR_CREATED_BY" &&
          key !== "PR_CREATED_ON" &&
          key !== "PR_NAME" &&
          key !== "PR_ID" &&
          key !== "PR_PROJECT_ID" &&
          key !== "PR_INACTIVE_ON",
      );

      const profileSettings = Object.fromEntries(profileFiltered);

      let isNewProfileUnique = !isEqual(newProfileSettings, profileSettings);

      if (!isNewProfileUnique) {
        return { hasUniqueSettings: isNewProfileUnique, profileName: profilesList[i].PR_NAME };
      }
    }
    return { hasUniqueSettings: true };
  };

  // ACCORDIAN SHOW AND HIDE FUNCTIONALITY====================
  openAccordation = (e) => {
    if (this.state.selectedAccordianTab[0] === e) {
      this.setState({ selectedAccordianTab: [] });
    } else {
      this.setState({ selectedAccordianTab: [e] });
    }
  };
  // RENDER FUNCTION FOR PROFILE EDIT=========================

  render() {
    var polyglot = polyglotLoader();
    const recoveryStyle = { marginTop: "0" };
    const contentText = polyglot.t(
      "Global deduplication means that only one copy of the same email received by various custodians is available for review.",
    );
    let contentText2 = polyglot.t(
      "De-duplication per custodian means that copies of the same email within the mailbox of 1 custodian is only available for review once, but that this email would also be present in the mailboxes of other recipients.",
    );
    return (
      <div>
        <div className="row m-l-0 m-r-0">
          <div className="col-md-12">
            {this.state.redirectAfterProfileSave ? (
              <Redirect
                push
                from={"../Components/DFED/Profile/Profile/"}
                to={{
                  pathname: "/dfed/profileList",
                  state: {
                    saveMsg: polyglot.t("Profile saved successfully"),
                    selectedProjectId: this.state.selectedProjectId,
                    allDatasetsNameForDisplay: this.props.location.state.allDatasetsNameForDisplay,
                    custodianId: this.props.location.state.custodianId,
                    fromTabNavigation: true,
                  },
                }}
              ></Redirect>
            ) : null}
            {this.state.profileSave === "success" ? (
              <div>
                <Alert
                  className="a-example-alert"
                  duration={3000}
                  status={this.state.profileSave}
                  message={this.state.profileSaveMsg}
                  // closeable={true}
                  // showDefaultIcon={true}
                />
              </div>
            ) : null}
            {this.state.profileSave === "warning" ? (
              <div>
                <Alert
                  className="a-example-alert"
                  duration={3000}
                  status={this.state.profileSave}
                  message={this.state.profileSaveMsg}
                  // closeable={true}
                  // showDefaultIcon={true}
                />
              </div>
            ) : null}
          </div>
        </div>
        <form onSubmit={this.handleSubmit.bind(this)}>
          <div>
            <h1 className=" crtPrTitle"> {polyglot.t("Processing profile")} </h1>
            <div className="row m-l-0 m-r-0 m-t-10">
              <div className="col-md-8 p-0 profileEdit">
                <div className="profileNameComponent">
                  <GetProfileName
                    isViewProfile={this.state.isViewProfile}
                    changed={this.formChangeHandler}
                    attr="profileName"
                    error={this.state.formErrors.profileNameErr}
                    profileVal={this.state.profileName}
                  />
                  <FormErrors formErrors={this.state.formErrors.profileNameErr} />
                </div>
                <div>
                  <div className="processDtSetHed">
                    <p>{polyglot.t("How should we process dataset?")} </p>
                  </div>
                  <RadioView
                    radioViewData={this.state.radioViewData}
                    changed={this.formChangeHandler}
                    attr="selectedProcess"
                    processVal={this.state.selectedProcess}
                    isViewProfile={this.state.isViewProfile}
                    isNewProfile={this.state.isNewProfile}
                  />
                  <FormErrors formErrors={this.state.formErrors.processTypeErr} />
                </div>
                <div className="recoveryComponenet" style={recoveryStyle}>
                  <div className="processDtSetHed">
                    {" "}
                    <p>{polyglot.t("Recovery settings")}</p>{" "}
                  </div>
                  <CheckBoxView
                    checkBoxViewData={this.state.checkBoxViewData}
                    changed={this.formChangeHandler}
                    attr="recoverySelection"
                    deletedEmailsVal="recoverDeleteEmail"
                    deletedFilesVal="recoverDeleteFile"
                    isViewProfile={this.state.isViewProfile}
                  />
                </div>
                <div className="horizontalLine">
                  {" "}
                  <hr />{" "}
                </div>
                <div className="advancedSetting">
                  <div className={"more " + this.state.toggleClass}>
                    <div className="tabContainer">
                      <Tabs defaultValue="1" className="a-tab-item-padding-10">
                        <Tab
                          value="1"
                          label={polyglot.t("General")}
                          id="genTabTitle"
                          aria-label={polyglot.t("General")}
                        >
                          <TabView1
                            timeZoneData={this.state.timeZoneData}
                            analysisLanguage={this.state.analysisLanguage}
                            tab1FirstCheckboxList={this.state.tab1FirstCheckboxList}
                            tab1MiddleCheckboxList={this.state.tab1MiddleCheckboxList}
                            tab1SecondCheckboxList={this.state.tab1SecondCheckboxList}
                            attrTimeZone="selectedTimeZone"
                            attrGenAnaLan="selectedGenAnalysisLan"
                            attrGenCarvingBlock="selectedGenCarvingBlock"
                            attrGenMaxDigestSize="selectedGenDigestSize"
                            changed={this.formChangeHandler}
                            timeZoneError={this.state.formErrors.timeZoneErr}
                            analysisLanError={this.state.formErrors.analysisLanguageErr}
                            isViewProfile={this.state.isViewProfile}
                            selectedTimeZone={this.state.selectedTimeZone}
                            selectedGenAnalysisLan={this.state.selectedGenAnalysisLan}
                            carvingBlockSizeView={this.state.selectedGenCarvingBlock}
                            maxDigestSizeView={this.state.selectedGenDigestSize}
                          />
                        </Tab>
                        <Tab
                          value="3"
                          label={polyglot.t("File types")}
                          id="fileTypeTabTitle"
                          aria-label={polyglot.t("File types")}
                        >
                          <TabView3
                            tab3FileType={this.state.tab3FileType}
                            fileTypeChanged={this.fileTypeHandler}
                            isViewProfile={this.state.isViewProfile}
                          />
                        </Tab>
                        <Tab value="5" label={polyglot.t("OCR")} id="ocrTabTitle">
                          {this.state.apiProfileData && (
                            <TabViewOcr
                              tab5Pdf={this.state.tab5Pdf}
                              tab5Images={this.state.tab5Images}
                              tab5ImagesType={this.state.tab5ImagesType}
                              tab5ExcludeType={this.state.tab5ExcludeType}
                              tab5TextSection={this.state.tab5TextSection}
                              tab5Quality={this.state.tab5Quality}
                              tab5Rotation={this.state.tab5Rotation}
                              ocrClearCacheData={this.state.ocrClearCacheData}
                              tab5TimeOut={this.state.tab5TimeOut}
                              tab5AnalysisLanguage={this.state.ocrAnalysisLanguage}
                              tab5PDFAttr="ocrPDFSelectedVal"
                              ocrSpecificImageChange={this.ocrSpecificImageHandler}
                              tab5NonSpecificImgAttr="ocrNonSpecificImage"
                              changed={this.formChangeHandler}
                              ocrQualityAttr="ocrQuality"
                              ocrRationAttr="ocrRation"
                              ocrTimeOutAttr="ocrTimeOutVal"
                              ocrClearCacheAttr="isOcrClearCache"
                              ocrLanguageAttr="ocrLanguageVal"
                              ocrImgErr={this.state.formErrors.ocrImgErr}
                              ocrQualityErr={this.state.formErrors.ocrQualityErr}
                              ocrRotationErr={this.state.formErrors.ocrRotationErr}
                              ocrLanguageErr={this.state.formErrors.ocrLanguageErr}
                              isViewProfile={this.state.isViewProfile}
                              ocrQuality={this.state.ocrQuality}
                              ocrRation={this.state.ocrRation}
                              ocrLanguageVal={this.state.ocrLanguageVal}
                              selectedPdfVal={this.state.ocrPDFSelectedVal}
                              selectedNoneImgVal={this.state.ocrNonSpecificImage}
                              selectedCcrModifyText={this.state.ocrModifyText}
                              tab5ModifyTextAttr="ocrModifyText"
                              selectedOcrTimeoutVal={this.state.ocrTimeOutVal}
                              apiProfileData={this.state.apiProfileData}
                            />
                          )}
                        </Tab>
                      </Tabs>
                      <div className="horizontalLine">
                        {" "}
                        <hr />{" "}
                      </div>
                    </div>
                  </div>
                  <a className="showHideLink" onClick={this.handleShowMore.bind(this)}>
                    {this.state.expanded ? (
                      <span>{polyglot.t("Hide advanced options")}</span>
                    ) : (
                      <span>{polyglot.t("Show advanced options")}</span>
                    )}
                  </a>
                </div>
              </div>
              <div className="col-md-4">
                <div className="duplicationTextDiv"></div>
                <div>
                  <p className="para-txt">
                    {polyglot.t(
                      "Please provide a meaningful name to the Processing Profile. The name must be different from the already existing profiles",
                    )}
                    .
                  </p>
                  <p className="para-txt">
                    {polyglot.t(
                      "You can select from two different types of Deduplication (see details below)",
                    )}
                    .
                  </p>
                  <p className="para-txt">
                    {polyglot.t(
                      "After applying the Recovery settings, you can define further configurations by clicking ",
                    )}
                    {getCurrentLanguage() === "ger" ? null : (
                      <span className="para-head"> {polyglot.t("Show advanced options")}</span>
                    )}{" "}
                    &nbsp;
                    {polyglot.t("klicken")}
                  </p>
                  <p className="para-txt">
                    {polyglot.t("Once you are done, please click on ")}
                    <span className="para-head"> {polyglot.t("Save Profile")}</span>
                    {" " + polyglot.t("to store the profile to your project")}.
                  </p>
                </div>
                <p>
                  <div className="rightSectionHeading pb-2">{polyglot.t("What is deduplication?")} </div>
                  <span className="profileRightSection">
                    {" "}
                    {polyglot.t(
                      "Deduplication is a technique for eliminating duplicate copies of repeating data.",
                    )}{" "}
                  </span>
                </p>
                <div className="">
                  <Accordions
                    onChange={this.openAccordation}
                    activeItem={this.state.selectedAccordianTab}
                    multiOpen={true}
                  >
                    <AccordionItem itemId="0">
                      <AccordionItemHeader title={polyglot.t("Global deduplication")} />
                      <AccordionItemBody className="profileRhtAccordinoTxt">{contentText}</AccordionItemBody>
                    </AccordionItem>
                    <AccordionItem itemId="1">
                      <AccordionItemHeader title={polyglot.t("Custodian deduplication")} />
                      <AccordionItemBody className="profileRhtAccordinoTxt">{contentText2}</AccordionItemBody>
                    </AccordionItem>
                  </Accordions>
                </div>
              </div>
            </div>
            <div className="row m-l-0 m-r-0">
              <div className="col-md-4 col-lg-3 lastSection p-0">
                <BackButton
                  className="crtPrfBackBtn"
                  onBackButtonClick={this.onBackButtonClick}
                  buttonText={polyglot.t("Back")}
                ></BackButton>
              </div>
              <div className="col-md-4 lastSection">
                {this.state.isViewProfile === false ? (
                  <div>
                    <ButtonPrimary type="submit" ariaLabel={polyglot.t("Save profile")}>
                      {polyglot.t("Save profile")}
                    </ButtonPrimary>
                  </div>
                ) : (
                  <div> </div>
                )}
              </div>
            </div>
          </div>
        </form>
        {this.state.isBackBtn === true ? (
          <Redirect
            push
            from={"../Components/Profile/Profile/"}
            to={{
              pathname: "/dfed/profileList",
              state: {
                selectedProjectId: this.state.selectedProjectId,
                allDatasetsNameForDisplay: this.props.location.state.allDatasetsNameForDisplay,
                custodianId: this.props.location.state.custodianId,
              },
            }}
          ></Redirect>
        ) : null}
      </div>
    );
  }
}

export default EditProfile;
