/* eslint-disable quotes */
import "../../../Assets/Css/QueenBase/Root/NavBar.css";
import PropTypes from "prop-types";
import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import displayToastMessage from "./displayToastMessage";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";
import LinkText from "../../../Assets/Components/LinkText/LinkText";
import NavBarService from "../../../Services/QueenBase/NavBar.js/NavBarService";
import { DataContext } from "../../../Services/Shared/Store.js";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { clearSessionStorage_Of_DF_module } from "../../DF/handlers";

function NavBar(props) {
  const { isFormOpen, formGotClosed } = useContext(DataContext);
  const [dblabel, setDbLabel] = useState([]);
  const [openConfirmBox, setOpenConfirmBox] = useState(false); // this value handles the confirm box of exit form
  const [selectedNav, setSelectedNav] = useState({});

  // GET NAVIGATION BAR DETAILS FROM API
  const getNavBar = async () => {
    try {
      var res = await NavBarService.get().getQueenNavBar();
      if (res.CODE === 0) {
        displayToastMessage(res.MSG, "error");
      } else {
        setDbLabel(res);
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // CLOSE FORM AND NAVIGATE TO VIEW PROJECTS
  const closeFormAndRedirect = () => {
    toggleConfirmBox();
    redirectionOnClosingConfirmBox();
    formGotClosed();
  };

  var polyglot = polyglotLoader();
  useEffect(() => {
    getNavBar();
    clearSessionStorage_Of_DF_module();
  }, []);
  const [navOptions] = useState([
    {
      url: "/queenbase/solutions/solution",
      label: polyglot.t("Solutions"),
      name: "solutions",
      dbTabName: "Solutions",
    },
    {
      url: "/queenbase/projects/viewprojects",
      label: polyglot.t("Projects"),
      name: "projects",
      dbTabName: "Projects",
    },
    {
      url: "/queenbase/users",
      label: polyglot.t("Users"),
      name: "users",
      dbTabName: "Users",
    },
    {
      url: "/queenbase/mydashboard",
      label: polyglot.t("My Dashboard"),
      name: "mydashboard",
      dbTabName: "My Dashboard",
    },
    {
      url: "/queenbase/companycenter/view",
      label: polyglot.t("Company Center"),
      name: "companycenter",
      dbTabName: "Company Center",
    },
    {
      url: "/queenbase/admin/view",
      label: polyglot.t("PwC Admin"),
      name: "admin",
      dbTabName: "PwC Admin",
    },
  ]);
  const redirection = (data) => {
    try {
      if (Object.keys(data).length > 0) {
        if (isFormOpen) {
          // form is in open state, hence user cannot navigate to other screens
          toggleConfirmBox();
          setSelectedNav(data);
        } else {
          props.history.push(data.url);
          setSelectedNav({});
        }
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // redirect on closing conform box
  const redirectionOnClosingConfirmBox = () => {
    try {
      props.history.push(selectedNav.url);
      setSelectedNav({});
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  const toggleConfirmBox = () => {
    setOpenConfirmBox(!openConfirmBox);
  };
  // WHEN NAV DETAILS ARE RECEIVED, NAVIGATE TO THE INITIAL URL OF THE NAVBAR
  useEffect(() => {
    const availableTabNames = dblabel.map((tab) => {
      return navOptions.find((obj) => tab.TabName === obj.dbTabName).url;
    });
    const currentNav = window.location.href;
    let isCurrentURLInAvailableTabNames = false;
    availableTabNames.forEach((tab) => {
      if (currentNav.includes(tab)) {
        isCurrentURLInAvailableTabNames = true;
      }
    });
    if (!isCurrentURLInAvailableTabNames) {
      if (dblabel.length > 0) {
        const initialTab = dblabel[0].TabName;
        const initialURL = navOptions.find((r) => r.dbTabName === initialTab).url;
        props.history.push(initialURL);
      }
    }
  }, [dblabel]);
  return (
    <>
      <nav className="nav d-flex justify-content-center navbar-light white-header" data-testid="navbar">
        <div className="d-flex">
          <ul className="navbar justify-content-between mb-0 p-0">
            {dblabel.map((data, i) => {
              let className = "link";
              const currentNav = navOptions.find((r) => r.dbTabName === data.TabName);
              if (currentNav) {
                if (props.location.pathname.includes(currentNav.name)) {
                  className += " current nav-link-current";
                }
                return (
                  <React.Fragment key={"navbar" + i}>
                    <li key={"nav" + i} className="nav-link" onClick={() => redirection(currentNav)}>
                      <LinkText
                        className={`${className} no-underline`}
                        onClick={() => redirection(currentNav)}
                        ariaLabel={polyglot.t(data.TabName)}
                        href="javascript:;"
                      >
                        {polyglot.t(data.TabName)}
                      </LinkText>
                    </li>
                    <li key={"strip" + i} className="nav-item"></li>
                  </React.Fragment>
                );
              }
            })}
          </ul>
        </div>
      </nav>
      <ConfirmBoxExitForm
        show={openConfirmBox}
        heading={"Cancel update"}
        cancel={toggleConfirmBox}
        proceed={closeFormAndRedirect}
      />
    </>
  );
}
NavBar.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};
export default withRouter(NavBar);
