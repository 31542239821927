import React, { useState } from "react";

import ProcessGrid from "./Views/ProcessGrid";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { getProjectID_Of_solution_module } from "../../../Services/Shared/Handlers";
import { polyglotLoader } from "../../../Services/Shared/Translate";

// ADMIN SCREEN OF GI
function Admin(props) {
  const polyglot = polyglotLoader();
  const projectId = getProjectID_Of_solution_module();
  const [currentTab, setCurrentTab] = useState("Process");

  // CHANGE TAB VIEW
  const changeAdminTabView = (value) => {
    setCurrentTab(value);
  };

  return (
    <div className="adminContainer">
      <div className="DF-mt-3 m-l-1-15">
        <div className="col-md-12 pl-0">
          {currentTab === "Process" && (
            <div>
              <ButtonPrimary ariaLabel={polyglot.t("Process")}>{polyglot.t("Process")}</ButtonPrimary>
              <ProcessGrid projectid={projectId}></ProcessGrid>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default Admin;
