import React, { useState, useContext, useEffect } from "react";
import { DatePicker, ConfigProvider } from "antd";
import moment from "moment";
import gr from "antd/lib/locale-provider/de_DE";

import "moment/locale/de";
import { Input, Select, SelectGroupTitle, SelectOption, Panel } from "appkit-react";
import { Formik } from "formik";
import * as Yup from "yup";

import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";
import calendar from "../../../Assets/Icons/calendar.svg";
import download from "../../../Assets/Images/DFED/icon-download.svg";
import { PRICE_REGEX, FIELD_REGEX } from "../../../Constants/regex";
import AdminService from "../../../Services/QueenBase/Admin/AdminServices";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import CustomDropZonePdf from "../../QueenBase/Shared/CustomeDropZonePdf";
import displayToastMessage from "../Root/displayToastMessage";
// EDIT INVOICE FORM
function EditInvoiceForm(props, history) {
  const { showForm, onEdit, r, isEdit, onClose, fetchClientsAfterEdit } = props;
  const { getUserLanguage, isFormOpen, formGotOpened, formGotClosed } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const [fileObj, setFileObj] = useState({});
  const [clients, setClients] = useState([]);
  const [projects, setProjects] = useState([]);
  const [status, setStatus] = useState([]);
  const [openConfirmBox, setOpenConfirmBox] = useState(false); // this value handles the confirm box of exit form
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    clientid: r.clientid,
    projectid: r.projectid,
    clientInvoiceRef: r.client_invoice_ref,
    description: r.invoice_description,
    invoiceAmount: r.invoice_amount,
    invoiceDate: moment(r.invoice_date),
    dueDate: moment(r.due_date),
    timeRange: r.time_range,
    invoiceStatusId: r.ID,
  };

  // VALIDATION SCHEMA FOR EDIT CLIENT
  const validationSchema = Yup.object().shape({
    clientid: Yup.number().min(1, "ClientName is required"),
    projectid: Yup.number().nullable(),
    clientInvoiceRef: Yup.string().required("Client Invoice Reference is required"),
    description: Yup.string()
      .matches(FIELD_REGEX, polyglot.t("field_validaton"))
      .max(100, "Maximum character length is 100")
      .required("Description is required")
      .trim(),
    invoiceAmount: Yup.string()
      .matches(PRICE_REGEX, "The price should be up to 2 decimal places only")
      .required("Amount in is required"),
    invoiceDate: Yup.date().required("InvoiceDate is required"),
    dueDate: Yup.date().required("DueDate is required"),
    timeRange: Yup.string()
      .matches(FIELD_REGEX, polyglot.t("field_validaton"))
      .required("TimeRange is required")
      .trim(),
    invoiceStatusId: Yup.number().min(1, "Status is required"),
  });
  // GET CLIENT INVOICING DETAILS
  async function ClientInvoicingDetails() {
    var res = await AdminService.get().getClientInvoicingDetails();
    setClients(res.client);
    setProjects(res.project);
    setStatus(res.status);
  }
  // ASSIGN DATA TO FORM
  const assignDataToForm = () => {
    const strArr = r.invoice_attachment.split("/");
    const filename = strArr[strArr.length - 1];
    if (filename !== "undefined") {
      /*
        Note: this condition is to satisfy the old records which were created with filename = "undefined"
      */
      const fileobject = {};
      fileobject.name = filename;
      setFileObj(fileobject);
    }
  };
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    window.scrollTo(0, 0);
    ClientInvoicingDetails();
    assignDataToForm();
  }, []);
  //DownloadInvoice
  const downloadClientInvoice = async (r) => {
    try {
      const downloadResponse = await AdminService.get().downloadClientInvoice(r.invoice_attachment); //invoice_attachment
      const buffer = Buffer.from(downloadResponse.file.buffer);
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", downloadResponse.file.fileName);
      document.body.appendChild(link);
      link.click();
      displayToastMessage(polyglot.t("Invoice has been downloded"), "success");
    } catch (error) {
      if (
        (error.message = "First argument must be a string, Buffer, ArrayBuffer, Array, or array-like object.")
      ) {
        displayToastMessage(`${polyglot.t("File not found")}!!`, "error");
      } else {
        displayToastMessage(error.message, "error");
      }
    }
    return "";
  };
  // CHECK FORM VALIDATION
  const checkFormValidation = (statusId) => {
    const isFilePresent = Object.keys(fileObj).length > 0;
    if (statusId < 3 && isFilePresent) return true;

    if (statusId < 3 && !isFilePresent) {
      displayToastMessage("It is mandatory to upload a file", "error");
      return false;
    }
    if (statusId === 3 || statusId === 4) return true;
  };

  // SUBMIT FORM TO UPDATE INVOICE
  const updateInvoice = async (invoice) => {
    setIsLoading(true);
    const modifiedFormObj = { ...invoice };
    // change invoiceAmount to type Number from string
    modifiedFormObj.invoiceAmount = Number(invoice.invoiceAmount);
    let uploadedFile = {};
    let uploadedFileName = fileObj.name;
    const formValidationSuccess = checkFormValidation(invoice.invoiceStatusId);
    if (Object.keys(fileObj).length > 0) {
      if (formValidationSuccess) {
        uploadedFile = fileObj.file;
      }
    }
    if (formValidationSuccess) {
      const formData = new FormData();
      invoice.invoice_id = r.invoice_id;
      formData.append(
        "upload_data",
        JSON.stringify({
          projectid: modifiedFormObj.projectid,
          clientInvoiceRef: modifiedFormObj.clientInvoiceRef,
          invoiceStatusId: modifiedFormObj.invoiceStatusId,
          clientid: modifiedFormObj.clientid,
        }),
      );
      // formData.append("upload_data", JSON.stringify(modifiedFormObj));
      // formData.append("uploaded_file", uploadedFile);
      // formData.append("uploaded_file_name", uploadedFileName);
      const res = await AdminService.get().updateInvoice(formData);
      if (res.CODE) {
        displayToastMessage(polyglot.t("invoice_update", "success"));
        fetchClientsAfterEdit();
        formGotClosed();
      } else {
        displayToastMessage(res.MSG, "error");
      }
    }
    setIsLoading(false);
  };
  // INVOICE UPLOADED
  const invoiceUploaded = async (files, event) => {
    setFileObj({
      file: event.target.files[0],
      name: event.target.files[0].name,
      size: event.target.files[0].size,
    });
  };
  // UPLOAD INVOICE FILE
  const UploadInvoiceFile = () => {
    if (!fileObj.name) {
      return (
        <div>
          <div className="Content">
            <div>
              <CustomDropZonePdf
                onFilesAdded={invoiceUploaded}
                customDropZoneBoxStyle={{
                  width: "100%",
                  margin: "0",
                  padding: "30px",
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  };
  // FILE NAME
  const filename = () => {
    const strArr = r.invoice_attachment.split("/");
    const filename = strArr[strArr.length - 1];
    if (filename === "undefined") {
      return <div />;
    } else {
      return (
        <div className="Admin-tittle-stastic-previous cursor ">
          <strong>{polyglot.t("Previous file uploaded")}</strong>:&nbsp;{filename}&nbsp;
          <ButtonIcon
            iconImg={download}
            alt="download"
            onClick={() => downloadClientInvoice(r)}
            title={polyglot.t("Download invoice")}
            ariaLabel={polyglot.t("Download invoice")}
          />
        </div>
      );
    }
  };
  // check if form is empty before canceling the form
  const checkIfFormIsEmptyAndGoback = () => {
    if (isFormOpen) {
      // show confirm box
      toggleConfirmBox();
    } else {
      onClose(r.client_invoice_ref);
    }
  };
  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  const toggleConfirmBox = () => {
    setOpenConfirmBox(!openConfirmBox);
  };
  // CLOSE FORM AND NAVIGATE TO VIEW PROJECTS
  const closeFormAndRedirect = () => {
    toggleConfirmBox();
    redirectionOnClosingConfirmBox();
    formGotClosed();
  };
  // redirect on closing conform box
  const redirectionOnClosingConfirmBox = () => {
    onClose(r.client_invoice_ref);
  };
  return (
    <div>
      <div className="mt-3 m-l-1-3">
        <Panel className="panel add-user a-panel-user">
          <div className="row">
            <div className="col-md-11 ">
              <h5 className="headertest mb-4">{polyglot.t("Edit invoice")}</h5>
            </div>
          </div>
          <Formik onSubmit={updateInvoice} initialValues={initialValues} validationSchema={validationSchema}>
            {(prop) => {
              const { values, handleChange, handleSubmit, errors, touched, dirty } = prop;
              // FORMIK FORM - HANDLE FORMOPEN OR CLOSE STATUS
              const updateFormStatusBasedOnDirty = () => {
                if (dirty) {
                  formGotOpened();
                }
                if (!dirty) {
                  formGotClosed();
                }
              };
              updateFormStatusBasedOnDirty();
              const restrictStartDates = (current) => {
                const enddate = values.dueDate ? values.dueDate : "";
                let customDate = new Date(enddate);
                customDate.setDate(customDate.getDate());
                return current && current > customDate;
              };
              const restrictEndDates = (current) => {
                let startdate = values.invoiceDate ? values.invoiceDate : null;
                if (startdate) {
                  let customDate = new Date(startdate);
                  customDate.setDate(customDate.getDate());
                  return current && current < customDate;
                }
              };
              return (
                <div>
                  <div className="row mt-1">
                    <div className="col-md-6 ">
                      {clients && clients.length > 0 && (
                        <Select
                          placeholder={polyglot.t("Select client")}
                          value={values.clientid}
                          onSelect={(value) => handleChange("clientid")({ target: { value } })}
                          aria-label={polyglot.t("Select client")}
                          disabled
                        >
                          <SelectGroupTitle>{polyglot.t("Clients")} </SelectGroupTitle>
                          {clients.map((r, index) => {
                            return (
                              <SelectOption key={index + 1} value={r.clientid}>
                                {r.clientname}
                              </SelectOption>
                            );
                          })}
                        </Select>
                      )}
                      {errors.clientid && touched.clientid ? (
                        <div className="error">{polyglot.t(errors.clientid)}</div>
                      ) : (
                        null &&
                        errors.clientid && <span className="error">{polyglot.t(errors.clientid)}</span>
                      )}
                    </div>
                    <div className="col-md-6">
                      {projects && projects.length > 0 && (
                        <Select
                          placeholder={polyglot.t("Select project")}
                          value={values.projectid}
                          onSelect={(value) => handleChange("projectid")({ target: { value } })}
                          aria-label={polyglot.t("Select project")}
                          disabled
                        >
                          <SelectGroupTitle>{polyglot.t("Projects")} </SelectGroupTitle>
                          {projects
                            .filter((r) => r.clientid == values.clientid)
                            .map((r, index) => {
                              return (
                                <SelectOption key={index + 1} value={r.projectid}>
                                  {r.name}
                                </SelectOption>
                              );
                            })}
                        </Select>
                      )}
                      {errors.projectid && touched.projectid ? (
                        <div className="error">{polyglot.t(errors.projectid)}</div>
                      ) : (
                        null &&
                        errors.projectid && <span className="error">{polyglot.t(errors.projectid)}</span>
                      )}
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        inputBoxSize="sm"
                        placeholder={polyglot.t("Client Invoice Reference*")}
                        value={values.clientInvoiceRef}
                        onChange={handleChange("clientInvoiceRef")}
                        disabled
                        aria-label={polyglot.t("Client Invoice Reference*")}
                      />
                      {errors.clientInvoiceRef && touched.clientInvoiceRef ? (
                        <div className="error">{polyglot.t(errors.clientInvoiceRef)}</div>
                      ) : (
                        null &&
                        errors.clientInvoiceRef && (
                          <span className="error">{polyglot.t(errors.clientInvoiceRef)}</span>
                        )
                      )}
                    </div>
                    <div className="col-md-6 ">
                      {status && status.length > 0 && (
                        <Select
                          placeholder={polyglot.t("Invoice Status")}
                          value={values.invoiceStatusId}
                          onSelect={(value) => handleChange("invoiceStatusId")({ target: { value } })}
                          aria-label={polyglot.t("Invoice Status")}
                        >
                          <SelectGroupTitle>{polyglot.t("Status")} </SelectGroupTitle>
                          {status.map((r, index) => {
                            return (
                              <SelectOption key={index + 1} value={r.ID}>
                                {r.STATUS}
                              </SelectOption>
                            );
                          })}
                        </Select>
                      )}
                      {errors.invoiceStatusId && touched.invoiceStatusId ? (
                        <div className="error">{polyglot.t(errors.invoiceStatusId)}</div>
                      ) : (
                        null &&
                        errors.invoiceStatusId && (
                          <span className="error">{polyglot.t(errors.invoiceStatusId)}</span>
                        )
                      )}
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        inputBoxSize="sm"
                        placeholder={polyglot.t("Invoice Amount*")}
                        value={values.invoiceAmount.toString()}
                        onChange={handleChange("invoiceAmount")}
                        aria-label={polyglot.t("Invoice Amount*")}
                        disabled
                      />
                      {errors.invoiceAmount && touched.invoiceAmount ? (
                        <div className="error">{polyglot.t(errors.invoiceAmount)}</div>
                      ) : (
                        null &&
                        errors.invoiceAmount && (
                          <span className="error">{polyglot.t(errors.invoiceAmount)}</span>
                        )
                      )}
                    </div>
                    <div className="col-md-6">
                      <Input
                        inputBoxSize="sm"
                        placeholder={polyglot.t("Time Range*")}
                        value={values.timeRange}
                        onChange={handleChange("timeRange")}
                        aria-label={polyglot.t("Time Range*")}
                        disabled
                      />
                      {errors.timeRange && touched.timeRange ? (
                        <div className="error">{polyglot.t(errors.timeRange)}</div>
                      ) : (
                        null &&
                        errors.timeRange && <span className="error">{polyglot.t(errors.timeRange)}</span>
                      )}
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6" id="datepicker-admin">
                      {getUserLanguage() === "ger" ? (
                        <ConfigProvider locale={gr}>
                          <DatePicker
                            disabledDate={restrictStartDates}
                            placeholder={polyglot.t("Invoice Date *")}
                            value={values.invoiceDate ? moment(values.invoiceDate) : null}
                            onChange={(value) =>
                              handleChange("invoiceDate")({
                                target: { value: value || "" },
                              })
                            }
                            format={"DD.MM.YYYY"}
                            className="antd-datepicker-custom-styles full-width"
                            suffixIcon={<img src={calendar} width={14} height={14} />}
                            disabled
                          />
                        </ConfigProvider>
                      ) : (
                        <DatePicker
                          disabledDate={restrictStartDates}
                          placeholder={polyglot.t("Invoice Date *")}
                          value={values.invoiceDate ? moment(values.invoiceDate) : null}
                          onChange={(value) =>
                            handleChange("invoiceDate")({
                              target: { value: value || "" },
                            })
                          }
                          format={"DD.MM.YYYY"}
                          className="antd-datepicker-custom-styles full-width"
                          suffixIcon={<img src={calendar} width={14} height={14} />}
                          disabled
                        />
                      )}
                      {errors.invoiceDate && touched.invoiceDate ? (
                        <div className="error">{polyglot.t(errors.invoiceDate)}</div>
                      ) : null}
                    </div>
                    <div className="col-md-6" id="datepicker-admin">
                      {getUserLanguage() === "ger" ? (
                        <ConfigProvider locale={gr}>
                          <DatePicker
                            disabledDate={restrictEndDates}
                            placeholder={polyglot.t("Invoice Due Date *")}
                            value={values.dueDate ? moment(values.dueDate) : null}
                            onChange={(value) =>
                              handleChange("dueDate")({
                                target: { value: value || "" },
                              })
                            }
                            format={"DD.MM.YYYY"}
                            className="antd-datepicker-custom-styles full-width"
                            suffixIcon={<img src={calendar} width={14} height={14} />}
                            disabled
                          />
                        </ConfigProvider>
                      ) : (
                        <DatePicker
                          disabledDate={restrictEndDates}
                          placeholder={polyglot.t("Invoice Due Date *")}
                          value={values.dueDate ? moment(values.dueDate) : null}
                          onChange={(value) =>
                            handleChange("dueDate")({
                              target: { value: value || "" },
                            })
                          }
                          format={"DD.MM.YYYY"}
                          className="antd-datepicker-custom-styles full-width"
                          suffixIcon={<img src={calendar} width={14} height={14} />}
                          disabled
                        />
                      )}
                      {errors.dueDate && touched.dueDate ? (
                        <div className="error">{polyglot.t(errors.dueDate)}</div>
                      ) : null}
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        inputBoxSize="sm"
                        placeholder={polyglot.t("Description_star")}
                        value={values.description}
                        onChange={handleChange("description")}
                        aria-label={polyglot.t("Description_star")}
                        disabled
                      />
                      {errors.description && touched.description ? (
                        <div className="error">{polyglot.t(errors.description)}</div>
                      ) : null}
                      <br />
                    </div>
                  </div>
                  <div>{filename()}</div>
                  <div>{UploadInvoiceFile()}</div>
                  <br />
                  <div className="col-md-12 edit-clientform-button">
                    <div className="row justify-content-between">
                      <ButtonSecondary onClick={checkIfFormIsEmptyAndGoback} ariaLabel={polyglot.t("Cancel")}>
                        {polyglot.t("Cancel")}
                      </ButtonSecondary>
                      <ButtonPrimary
                        onClick={handleSubmit}
                        ariaLabel={polyglot.t("Save Invoice")}
                        disabled={isLoading}
                      >
                        {polyglot.t("Save Invoice")}
                      </ButtonPrimary>
                    </div>
                  </div>
                </div>
              );
            }}
          </Formik>
        </Panel>
      </div>
      <ConfirmBoxExitForm
        show={openConfirmBox}
        heading={"Cancel update"}
        cancel={toggleConfirmBox}
        proceed={closeFormAndRedirect}
      />
    </div>
  );
}

export default EditInvoiceForm;
