import React from "react";
import "../../../Assets/Css/DFED/ChartBoard.css";
import { getDFClasses } from "./handler";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
// NEED HELP COMPONENT FOR HANDLING NEED HELP RELATED STUFF

export default class ChatComponent extends React.Component {
  constructor(props) {
    super(props);
    this.onNeedHelpClick = this.onNeedHelpClick.bind(this);
  }

  onNeedHelpClick() {
    this.props.onNeedHelpClick();
  }

  // NEED HELP RENDER METHOD FOR RENDERING UI

  render() {
    const polyglot = polyglotLoader();
    const { openFrom } = this.props;
    return (
      <div className="chart-board-container ">
        <button
          id="chart-board"
          className={`cursor-pointer ${getDFClasses(openFrom, "pointer")}`}
          onClick={this.onNeedHelpClick}
        >
          {" "}
          {polyglot.t("Need help?")}
        </button>
      </div>
    );
  }
}
