import PropTypes from "prop-types";
import React, { createContext, useEffect, useState, useContext } from "react";

import { throwERROR } from "../../../../Assets/Components/ErrorBoundary";
import SurveyService from "../../../../Services/DF/SurveyService";
import { getProjectID_Of_solution_module } from "../../../../Services/Shared/Handlers";
import { DataContext } from "../../../../Services/Shared/Store.js";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
export const StartContext = createContext();

export const projectSurveyInitialObj = {
  project_id: "",
  survey_id: "",
  is_existing_project: 0,
  selected_workflow_id: "",
  workflow_name: "",
  financial_year: "",
  financial_year_start_date: "",
  financial_year_end_date: "",
  time_scope_from: "",
  time_scope_to: "",
  company_codes: [],
  is_uploaded: 1,
  uploaded_file: "",
  uploaded_file_path: "",
  created_by: null,
  updated_by: null,
  parameter_file_status: 1, //default value
};

// DATA OF WORKFLOW FORM IS STORED IN THIS CONTEXT PROVIDER AND IS PROVIDED ACROSS THE FORM WIZARD OF "StartDFProject"
const StartContextProvider = (props) => {
  const [surveys, setSurveys] = useState([]);
  const [projectSurvey, setProjectSurvey] = useState(projectSurveyInitialObj);
  const [project_id, setProjectID] = useState(getProjectID_Of_solution_module());
  const [projectSurveysByProjectID, setProjectSurveysByProjectID] = useState([]);
  const [showERROR, setERROR] = useState(false);
  const [DFCurrentPackages, setDFCurrentPackages] = useState([]);
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader();
  const language = getUserLanguage();
  // FETCH SURVEY DETAILS ON LOAD
  const fetchSurveys = async (language) => {
    // API TO FETCH SURVEY DETAILS
    const surveyAPIResponse = await SurveyService.get().getSurveyDataRecords(language);
    if (!surveyAPIResponse) {
      setERROR(true);
    }
    setSurveys(surveyAPIResponse);
  };
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    fetchSurveys(language);
  }, [language]);

  // FUNCTION TO UPDATE PROJECT SURVEY OBJECT
  const updateProjectSurvey = (key, value) => {
    const projectSurveyClone = { ...projectSurvey };
    projectSurveyClone[key] = value;
    setProjectSurvey(projectSurveyClone);
  };
  // UPDATE FINANCIAL YEAR DATES OF PROJECT SURVEY OBJECT
  const updateProjectSurveyFYDates = (start, end, year) => {
    const projectSurveyClone = { ...projectSurvey };
    projectSurveyClone.financial_year_start_date = start;
    projectSurveyClone.financial_year_end_date = end;
    projectSurveyClone.financial_year = year;
    setProjectSurvey(projectSurveyClone);
  };
  // UPDATE TIMESCOPE DATES OF PROJECT SURVEY OBJECT
  const updateProjectSurveyFromToDates = (fromDate, toDate) => {
    const projectSurveyClone = projectSurvey;
    projectSurveyClone.time_scope_from = fromDate;
    projectSurveyClone.time_scope_to = toDate;
    setProjectSurvey({ ...projectSurveyClone });
  };
  // UPDATE SELECTED WORKFLOW ID OF PROJECT SURVEY OBJECT
  const updateProjectSurveyIsUploadedSelectedProject = (isUploaded, selectedProject) => {
    const projectSurveyClone = projectSurvey;
    projectSurveyClone.is_uploaded = isUploaded;
    projectSurveyClone.selected_workflow_id = selectedProject;
    setProjectSurvey({ ...projectSurveyClone });
  };
  // UPDATE COMPLETE WORKFLOW RECORD OF PROJECT SURVEY OBJECT
  const updateWorkflowRecord = (workflowItem) => {
    const projectSurveyClone = { ...projectSurvey };
    const isEmpty = Object.keys(workflowItem).length === 0 ? true : false;
    const name = isEmpty ? "" : workflowItem.workflow_name;
    const fy = isEmpty ? "" : workflowItem.financial_year;
    const from = isEmpty ? "" : workflowItem.time_scope_from;
    const to = isEmpty ? "" : workflowItem.time_scope_to;
    const compCodes = isEmpty ? [] : workflowItem.company_codes.split(",");
    const fy_startDate = isEmpty ? "" : workflowItem.financial_year_start_date;
    const fy_endDate = isEmpty ? "" : workflowItem.financial_year_end_date;

    projectSurveyClone.workflow_name = name;
    projectSurveyClone.financial_year = fy;
    projectSurveyClone.time_scope_from = from;
    projectSurveyClone.time_scope_to = to;
    projectSurveyClone.company_codes = compCodes;
    projectSurveyClone.financial_year_start_date = fy_startDate;
    projectSurveyClone.financial_year_end_date = fy_endDate;
    setProjectSurvey(projectSurveyClone);
  };
  // RESET PROJECT SURVEY OBJECT
  const resetProjectSurvey = () => {
    setProjectSurvey(projectSurveyInitialObj);
  };

  // PROVIDES TO OTHER COMPONENTS WHICH CALL FOR THIS CONTEXT
  const providerValue = {
    projectSurvey,
    updateProjectSurvey,
    setProjectSurvey, // should be used only by \Components\DF\Home.js & DF\Upload\Home.js,
    setProjectID, // should only be used by \Components\DF\Home.js
    projectSurveysByProjectID, // currently used only by \Components\DF\Upload\index.js
    setProjectSurveysByProjectID, // used by \Components\DF\Upload\index.js
    project_id, // used in \Components\DF\Start\ProvideData.js & ChooseProject.js
    surveys,
    updateProjectSurveyFromToDates,
    updateProjectSurveyIsUploadedSelectedProject,
    updateWorkflowRecord,
    resetProjectSurvey,
    updateProjectSurveyFYDates,
    setDFCurrentPackages,
    DFCurrentPackages,
  };

  // IF ERROR, SHOW ERROR BOUNDARY
  if (showERROR) {
    throwERROR("Network Error!!!");
  }
  return <StartContext.Provider value={{ ...providerValue }}>{props.children}</StartContext.Provider>;
};

StartContextProvider.propTypes = {
  children: PropTypes.any,
};

export default StartContextProvider;
