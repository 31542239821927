import { Avatar, Tooltip } from "appkit-react";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import icongroup from "../../../Assets/Icons/avatar-outline.svg";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import GetPriceInGermanFormat from "../Root/GetPriceInGermanFormat";
import displayToastMessage from "../Root/displayToastMessage";
// STEP 5 OF CLIENT CREATION
function ConfirmClientCreation({ ClientFormData, totalOfSelectedDFPackages, roles }) {
  // DATA FROM CONTEXT
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const [show, setShow] = useState(false);
  const [basePackage, setBasicPackage] = useState({});
  const [ClientDetails, setClientDetails] = useState({});
  const [activeSolutions, SetActiveSolutions] = useState([]);
  const [users, SetUsers] = useState([]);

  // THIS FUNCTION IS USED TO EXPAND ALL THE SOLUTIONS ON THE UI
  const openAllSolutions = (jsonArray) => {
    const tempArray = [];
    let tempObj = {};
    jsonArray.forEach((each) => {
      tempObj = { ...each };
      tempObj.open = 1;
      tempArray.push(tempObj);
    });
    return tempArray;
  };
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    if (ClientFormData) {
      let basePackage = ClientFormData.basicPackage.filter((r) => r.isselected === 1);
      setBasicPackage(basePackage.length > 0 ? basePackage[0] : {});
      let activesolutions = ClientFormData.solutionList.filter((r) => r.isselected === 1);
      const activeSolutionsToExpand = openAllSolutions(activesolutions);
      SetActiveSolutions(activeSolutionsToExpand);
      SetUsers(ClientFormData.UsersList);
      let ClientDetails = {
        ClientName: ClientFormData.ClientName,
        ClientPhone: ClientFormData.ClientPhone,
        Clientcountryid: ClientFormData.Clientcountryid,
        ClientAddress: ClientFormData.ClientAddress,
        ClientContactPerson: ClientFormData.ClientContactPerson,
        ClientDepartment: ClientFormData.ClientDepartment,
        ClientEntity: ClientFormData.ClientEntity,
        Clientemail: ClientFormData.Clientemail,
      };
      setClientDetails(ClientDetails);
    }
  }, [ClientFormData]);

  // TOOLTIP OF CLIENT EMAIL
  const Tooltipdata = (ClientDetails) => {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td style={{ textAlign: "center", fontWeight: "bold", color: "#000" }}>
                {ClientDetails.Clientemail}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  // GET ROLE NAME
  const getRoleName = (id) => {
    try {
      let role = roles.filter((r) => r.id === id);
      const roleName = role[0].rolename;
      switch (roleName) {
        // rename "client admin" to "PwC Client admin"
        case "Client admin":
          return "PwC client admin";
        default:
          return roleName;
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  return (
    <div className="Admin-PannelPadding-cc">
      <h1 className="main_title_qb  m-l-1-3">{polyglot.t("Summary")}</h1>
      <div className="expandedView">
        <div className="row fadeIn mb-4">
          <div className="col-md-12">
            <div className="client-details-card">
              <div className="row">
                <div className="col-md-4">
                  <div className="row">
                    <p className="col-md-5 subtitle_three_title_qb_dark">
                      {polyglot.t("Client Name_nostar")}{" "}
                    </p>
                    <p className="col-md-5  subtitle_three_title_qb_dark">{ClientDetails.ClientName}</p>
                  </div>
                  <div className="row">
                    <p className="col-md-5 subtitle_three_title_qb_dark">{polyglot.t("Contact Person")} </p>
                    <p className="col-md-5 subtitle_three_title_qb_dark">
                      {ClientDetails.ClientContactPerson}
                    </p>
                  </div>
                  <Tooltip content={Tooltipdata(ClientDetails)} placement="top">
                    <div className="row">
                      <p className="col-md-5 subtitle_three_title_qb_dark">{polyglot.t("C-Email")}</p>
                      <p className="col-md-6 subtitle_three_title_qb_dark clientemail">
                        {ClientDetails.Clientemail}
                      </p>
                    </div>
                  </Tooltip>
                  <div className="row">
                    <p className="col-md-5 subtitle_three_title_qb_dark">{polyglot.t("C-PhoneNumber")}</p>
                    <p className="col-md-5 subtitle_three_title_qb_dark">{ClientDetails.ClientPhone}</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <p className="col-md-4 subtitle_three_title_qb_dark">{polyglot.t("C-Organisation")}</p>
                    <p className="col-md-5 subtitle_three_title_qb_dark">{ClientDetails.ClientEntity}</p>
                  </div>
                  <div className="row">
                    <p className="col-md-4 subtitle_three_title_qb_dark">{polyglot.t("C-Department")}</p>
                    <p className="col-md-5 subtitle_three_title_qb_dark">{ClientDetails.ClientDepartment}</p>
                  </div>
                  <div className="row">
                    <p className="col-md-4 subtitle_three_title_qb_dark">{polyglot.t("Contact Address")} </p>
                    <p className="col-md-5 subtitle_three_title_qb_dark">{ClientDetails.ClientAddress}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="main_title_qb  m-l-1-3">{polyglot.t("Selected Basic Package")}</div>
        <div className="basic-package-container m-l-1-3 mt-3 mb-3">
          <div className="subtitle_three_title_qb_dark">{basePackage.name} &nbsp;</div>
          <p className="badge-styling-package ml-2 mr-4">
            <span className="badge status-badge-active-price">
              {basePackage.price ? GetPriceInGermanFormat(basePackage.price) : 0}
            </span>
          </p>
          <div>
            <Avatar>
              <img
                style={{ borderRadius: "100%", height: "20px", margin: "6px" }}
                alt="user.i"
                src={icongroup}
              />
            </Avatar>
            <span>{basePackage.noofusers}</span>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div className="main_title_qb  m-l-1-3">{polyglot.t("Selected Solutions")}</div>
        </div>
        <div className="gridRow-cc Admin-Package-grid mt-3 mb-4">
          {activeSolutions.map((each, index) => {
            return (
              <div key={index} className="Admin-data-confirmclient p-3">
                <div className="Admin-active-time selected-package-header p-0">{each.name}</div>
                <div className="d-flex mt-2">
                  <strong>
                    {each.id === 2
                      ? each.packages.map((EachPackage, index) => {
                          return <>{`${EachPackage.PlanName}`}</>;
                        })
                      : `${each.package.title}`}
                  </strong>
                  <div className="text-right full-width">
                    <span className="badge status-badge-active-price">
                      {each.id === 2
                        ? GetPriceInGermanFormat(`${totalOfSelectedDFPackages(each.id)}`)
                        : GetPriceInGermanFormat(each.package.price)}
                    </span>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="m-l-1-3">
        <div className="invoicetable">
          <table className="table">
            <tr className="subtitle_two_title_qb">
              <th>{polyglot.t("First name")}</th>
              <th>{polyglot.t("Last name")}</th>
              <th>{polyglot.t("Email")}</th>
              <th>{polyglot.t("Entity_nostar")}</th>
              <th>{polyglot.t("Department_noStar")}</th>
              <th>{polyglot.t("Phone")}</th>
              <th>{polyglot.t("Role")}</th>
            </tr>
            <tbody>
              {users.length > 0
                ? users.map((list, item) => {
                    return (
                      <tr key={item}>
                        <td className="subtitle_three_title_qb_dark">{list.UserFirstName}</td>
                        <td className="subtitle_three_title_qb_dark">{list.UserLastName}</td>
                        <td className="subtitle_three_title_qb_dark">{list.Useremail}</td>
                        <td className="subtitle_three_title_qb_dark">{list.UserEntity}</td>
                        <td className="subtitle_three_title_qb_dark">{list.UserDepartment}</td>
                        <td className="subtitle_three_title_qb_dark">{list.UserPhone}</td>
                        <td className="subtitle_three_title_qb_dark">
                          {polyglot.t(getRoleName(list.userroleid))}
                        </td>
                      </tr>
                    );
                  })
                : null}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
}

ConfirmClientCreation.propTypes = {
  ClientFormData: PropTypes.object,
  countries: PropTypes.array,
  position: PropTypes.array,
  UsersList: PropTypes.array,
  ClientName: PropTypes.string,
  ClientPhone: PropTypes.string,
  Clientcountryid: PropTypes.string,
  ClientAddress: PropTypes.string,
  ClientContactPerson: PropTypes.string,
  ClientDepartment: PropTypes.string,
  ClientEntity: PropTypes.string,
  Clientemail: PropTypes.string,
  totalOfSelectedDFPackages: PropTypes.func,
};

export default ConfirmClientCreation;
