import "../../../Assets/Css/QueenBase/Root/Alert.css";
import { PopAlert } from "appkit-react";
const displayToastMessage = (message, type) => {
  return PopAlert({
    oneLineDisplay: false,
    duration: 5000,
    status: type,
    message: message,
    closeable: false,
    className: "a-popup-alert alert-popup-top appkit-animation animated bounceIn",
  });
};

export default displayToastMessage;
