import React from "react";
import "./FormField.css";
import Select from "react-select";

class FormSingleSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      val: this.props.preFillSelectWithDescription ? this.props.preFillSelectWithDescription.countryData : "",
      colourOptions: this.props.options,
    };
  }

  onChange(event) {
    this.setState({
      val: event.target.value,
    });
    this.props.onChangeFormField(this.props.name, event.target.value);
  }

  getDropDownOptionList(optionData) {
    let items = [];
    for (let i = 0; i < optionData.length; i++) {
      items.push(
        <option key={optionData[i].key_id} value={optionData[i].key_id}>
          {optionData[i].key_label}
        </option>,
      );
      //here I will be creating my options dynamically based on
      //what props are currently passed to the parent component
    }
    return items;
  }

  getInputValue(inputValue) {
    return inputValue;
    // if(inputValue && inputValue === ""){
    //     return this.state.val
    // }
    // else{
    //     return inputValue
    // }
  }

  handleChange = (newValue, actionMeta) => {
    this.setState({
      val: newValue,
    });

    if (newValue && newValue.value) {
      this.props.onChangeFormField(this.props.name, newValue.value);
    }
  };

  render() {
    return (
      // <FormGroup>
      //     <Input data-live-search="true" data-live-search-style="startsWith"  className="selectpicker customInpputBox bg-color-input" type="select" value={this.getInputValue(this.props.val)} placeholder={this.props.placeholder}   name={this.props.name} id={this.props.id} onChange={event=>this.onChange(event)} >
      //          <option className="dropdownMenu"  disabled selected hidden>{this.props.placeholder}</option>
      //         {this.props.countryOptions && this.getDropDownOptionList(this.props.countryOptions)}
      //         {this.props.classificationOptions && this.props.classificationOptions.length>0 && this.getDropDownOptionList(this.props.classificationOptions)}
      //         {this.props.positionOptions && this.props.positionOptions.length>0 && this.getDropDownOptionList(this.props.positionOptions)}
      //     </Input>
      // </FormGroup>
      <div>
        <Select
          isDisabled={this.props.disabledTextFields}
          classNamePrefix="selectWithDescription"
          className="selectDescription"
          placeholder={this.props.placeholder}
          value={this.state.val}
          onChange={this.handleChange}
          options={this.props.options}
          {...this.props}
        />
      </div>
    );
  }
}

export default FormSingleSelect;
