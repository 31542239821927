import React, { useContext } from "react";

import Admin from "./Admin";
import NoAccess from "../../../Assets/Components/NoAccess";
import { hasViewPermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";

// RENDERS ON /queenbase/admin/VIEW
const AdminContainer = (props) => {
  // DATA FROM CONTEXT
  const { getCurrentUserPermissions } = useContext(DataContext);
  const currentUserPermissions = getCurrentUserPermissions();
  return (
    <>
      {/* RETURNS COMPONENT WHEN USER HAS VIEW PERMISSION */}
      {hasViewPermission(currentUserPermissions) && <Admin {...props} />}
      {/* RETURNS 'NO ACCESS' IF USER DOES NOT HAVE VIEW PERMISSION */}
      {!hasViewPermission(currentUserPermissions) && <NoAccess />}
    </>
  );
};

export default AdminContainer;
