import React from "react";

import { polyglotLoader } from "../Services/Shared/Translate";

export const PER_PAGE_COUNT = 5;
export const GRC_HOME_PER_PAGE = 9;

export const MAX_ASSESSMENT_NAME_LENGTH = 40;
export const MAX_CATEGORY_NAME_LENGTH = 60;
export const MAX_DESCRIPTION_LENGTH = 2000;

export const STATUS_NOT_STARTED = 1;

export const CUSTOME_ANSWER_TYPE = 4;
export const CUSTODIAN_TYPE = { GROUP: 2 };
export const SOLUTION_IDS_WITH_PERIOD_FILTER = [1, 2];

export const monthValidator = (value) => {
  var polyglot = polyglotLoader();
  const month = value;
  if (month > 1) {
    return <div>{polyglot.t("per_months", { name: month })}</div>;
  } else {
    return <div>{polyglot.t("Per month")}</div>;
  }
};

export const yearValidator = (value) => {
  var polyglot = polyglotLoader();
  const year = value;
  if (year > 1) {
    return <div>{polyglot.t("per_years", { name: year })}</div>;
  } else {
    return <div>{polyglot.t("Per year")}</div>;
  }
};

export const daysValidator = (value) => {
  var polyglot = polyglotLoader();
  const days = value;
  if (days !== 1) {
    return <>{polyglot.t("days_ago", { name: days })}</>;
  } else {
    return <>{polyglot.t("day_ago", { name: days })}</>;
  }
};
