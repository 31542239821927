import React, { Component } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "antd/dist/antd.css";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import "../../../Assets/Css/CSS/Search.css";
import CustomModal from "../../../Assets/Components/Modal";
import CssDetailsList from "./CssDetailsList";
import { Accordions, AccordionItem, AccordionItemHeader, AccordionItemBody, Tooltip } from "appkit-react";
import { getProjectID_Of_solution_module, getDisplayDate } from "../../../Services/Shared/Handlers";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
$.DataTable = require("datatables.net-dt");
$.fn.DataTable.ext.errMode = "none";
require("datatables.net-buttons-dt");
const jzip = require("jszip");
require("datatables.net-buttons/js/buttons.html5.min");
window.JSZip = jzip;

class CssGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showViewModal: false,
      selectedRowBody: {},
    };
  }

  static contextType = DataContext;
  polyglot = polyglotLoader();
  // RUNS ON INITIAL LOAD
  componentDidMount() {
    this.dataStructureForCSSGrid(this);
  }
  componentDidUpdate(prevProps, prevState) {
    this.polyglot = polyglotLoader(this.props.getUserLanguage);
    // draw the table, if the data changes or if the language changes
    if (
      prevProps.JsonArray !== this.props.JsonArray ||
      prevProps.getUserLanguage !== this.props.getUserLanguage
    ) {
      this.dataStructureForCSSGrid(this);
    }
  }

  // TOGGLE VIEW MODAL FORM
  toggleViewModal = (formBody) => {
    const currentShowViewModal = this.state.showViewModal;

    this.setState({
      showViewModal: !currentShowViewModal,
      selectedRowBody: formBody,
    });
  };

  // show view modal on click of eye icon
  showViewModal = (formBody) => {
    // const currentShowViewModal = this.state.showViewModal;

    this.setState({
      showViewModal: true,
      selectedRowBody: formBody,
    });
  };

  updateSearchResultVal = (changeValue, dropDownValue) => {
    let dropDownText;
    if (dropDownValue === "-1") {
      dropDownText = "All";
    } else {
      dropDownText = dropDownValue;
    }
    const body = {
      changeValue: changeValue,
      dropDownValue: dropDownText,
    };
    this.props.setSearchResultValue(body);
  };

  //  CREATE DATA STRUCTURE FOR UPLOAD TABLE
  dataStructureForCSSGrid = () => {
    var tempData = this.props.JsonArray;

    var dataForTbl = [];
    var i = 0;
    tempData.forEach((ele1) => {
      i++;

      //date format
      if (ele1?.searchtermdescription?.dateFrom) {
        const FromDateTime = ele1?.searchtermdescription.dateFrom;
        var fromDate = FromDateTime.split(" ")[0];
      } else {
        var fromDate = "";
      }
      if (ele1?.searchtermdescription?.dateTo) {
        const ToDateTime = ele1?.searchtermdescription.dateTo;
        var toDate = ToDateTime.split(" ")[0];
      } else {
        var toDate = "Current *";
      }
      var num = parseFloat(ele1.matchingscore).toFixed(2);

      dataForTbl.push([
        i,
        ele1?.searchtermdescription?.unique_id,
        num,
        ele1.highlighteddocument,
        ele1?.searchtermdescription?.source,
        ele1?.searchtermdescription?.type,
        ele1?.searchtermdescription?.program,
        fromDate,
        toDate,
        ele1.searchterm,
        ele1.matchingword,
      ]);
    });
    this.drawProcessGrid(this, dataForTbl);
  };
  // DRAW PROCESS GRID
  drawProcessGrid = (temp1, dataForTbl) => {
    temp1.$el = $(temp1.el);
    var disText = "";
    temp1.$el.DataTable({
      responsive: true,
      lengthMenu: [5, 10, 15, 20],
      oLanguage: {
        emptyTable: `${this.polyglot.t("No records found")}`,
        sLengthMenu: `${this.polyglot.t("Show")} <select class="tabFocus" id="showResultSelectBox">
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option selected value="-1">All</option>
        </select> ${this.polyglot.t("entries")}
        `,
        sSearch: `${this.polyglot.t("Search")}`,
      },
      columns: [
        { title: this.polyglot.t("IDs") },
        { title: this.polyglot.t("List ID") },
        { title: this.polyglot.t("Matching Score") },
        {
          title: this.polyglot.t("List Entry"),
          mRender: (data, type, row) => {
            const hoghlightText = row[3].replace(/<\/?span[^>]*>/g, "");
            return '<div class="td-width-listentry" title="' + hoghlightText + '">' + row[3] + "</div>";
          },
        },
        {
          title: this.polyglot.t("Source"),
        },
        { title: this.polyglot.t("SearchType") },
        { title: this.polyglot.t("Program") },
        { title: this.polyglot.t("First Date") },
        { title: this.polyglot.t("Last Date") },
        {
          title: this.polyglot.t("Actiondata"),
          class: "actionSortingRemove",
          data: null,
          class: "lastCol",
          width: 98,
          mRender: (data, type, row) => {
            return (
              '<button aria-label="' +
              this.polyglot.t("view") +
              '" class="appkiticon icon-view-fill analysisGridViewIcon editer_view"' +
              'id="view_model_' +
              row[1] +
              "_" +
              row[4] +
              "_" +
              row[5] +
              "_" +
              row[6] +
              "_" +
              row[9] +
              "_" +
              row[10] +
              "_" +
              row[0] +
              '" data-id="' +
              row[6] +
              '"> </button>'
            );
          },
        },
      ],
      sDom: "<li>frtp",
      data: dataForTbl,
      lenghtType: "simple",
      lengthChange: "true",
      searching: false,
      destroy: true,
      scroller: false,
      mark: true,
      language: {
        infoEmpty: "",
        info:
          `${this.polyglot.t("Showing Results")}` +
          ": _START_ " +
          `${this.polyglot.t("to")}` +
          " _END_ " +
          `${this.polyglot.t("of")}` +
          " _TOTAL_",
        paginate: {
          firt: "<<",
          previous: '<i_ngcontent-c10="" class="appkiticon icon-left-chevron-fill"> </i>',
          next: '<i_ngcontent-c10="" class="appkiticon icon-right-chevron-fill"> </i>',
          last: ">>",
          lengthMenu: "_MENU_",
        },
      },

      stateSave: true,
      drawCallback: function (settings, total, data) {
        if (dataForTbl.length <= 0) {
          $(".dataTables_paginate").hide();
        } else {
          $(".dataTables_paginate").show();
        }
      }, // OVER : drawback function
    });

    var tempReactThis = this;

    //css grid update select value
    $(document).on("change", "[id*='showResultSelectBox']", function () {
      const updatedValue = $("#processGridTable tr").length - 1;
      const dropDownValue = this.value;
      tempReactThis.updateSearchResultVal(updatedValue, dropDownValue);
    });

    $(function () {
      const updatedValue = $("#processGridTable tr").length - 1;
      const dropDownValue = this.value;
      tempReactThis.updateSearchResultVal(updatedValue, dropDownValue);
    });

    $(document).on("click", "[id*='view_model_']", function () {
      let idData = this.id.split("_");
      const body = {
        Id: idData[2],
        source: idData[3],
        type: idData[4],
        program: idData[5],
        searchterm: idData[6],
        matchingword: idData[7],
      };
      tempReactThis.showViewModal(body);
    });

    disText = $("#processGridTable").text();
    var pagNum = "";
    pagNum = disText.replace(pagNum, "<span>" + pagNum + "</span>");
    disText = disText.replace(pagNum, "<span>" + pagNum + "</span>");
  };

  render() {
    return (
      <div className="processGridContainer">
        <div className="row">
          <div className="col-md-12 searchData" id="datatable-keytable">
            <table
              id="processGridTable"
              className="display table-header-focus processGrid sortingalignment textColor"
              width="100%"
              ref={(el) => (this.el = el)}
            />
          </div>
        </div>
        <div className="viewModelDiv">
          {this.state.showViewModal && (
            <CssDetailsList
              show={this.state.showViewModal}
              toggleViewModal={this.toggleViewModal}
              formBody={this.state.selectedRowBody}
              searchAuditID={this.props.searchAuditID}
            />
          )}
        </div>
      </div>
    );
  }
}
export default CssGrid;
