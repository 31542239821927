import CommonService from "../Shared/CommonService";
import HttpClient from "../Shared/HttpClient";
class AssessmentService {
  constructor() {
    this.client = new HttpClient();
    this.baseApiUrl = CommonService.get().getGRCBaseApiUrl();
  }

  getAssessmentURL() {
    return `${this.baseApiUrl}/getAssessments`;
  }
  getAssessmentCategoriesURL() {
    return `${this.baseApiUrl}/getCategoriesAssessment`;
  }
  getAssessmentCategoriesByIdURL(selectedId) {
    return `${this.baseApiUrl}/getAassessmentCategories/${selectedId}`;
  }
  getAssessmentPreviewByIdURL(selectedId) {
    return `${this.baseApiUrl}/getAssessmentPreview/${selectedId}`;
  }
  getAassessmentPreviewQuestionsByIdURL(assessment_id, subcategory_id) {
    return `${this.baseApiUrl}/getAassessmentPreviewQuestions/${assessment_id}/${subcategory_id}`;
  }
  getAassermentQuestionariesURL(selectedId) {
    return `${this.baseApiUrl}/getAassermentQuestionaries/${selectedId}`;
  }
  getAassessmentQuestionsURL(parentId) {
    return `${this.baseApiUrl}/getAassessmentQuestions/${parentId}`;
  }
  postAssessmentQuestionCountURL() {
    return `${this.baseApiUrl}/postAssessmentQuestionCount`;
  }
  postAssessmentSubmitURL() {
    return `${this.baseApiUrl}/postAssessmentSubmit`;
  }
  async postAssessmentQuestionCountURL(ClientData) {
    const result = await this.client.grcpost("/postAssessmentQuestionCount", ClientData);
    return result;
  }
  getAssessmentsByProjectURL(projectid, userid) {
    return `${this.baseApiUrl}/getAssessmentsByProject/${projectid}/${userid}`;
  }

  postAssessmentURL() {
    return `${this.baseApiUrl}/postAssessment`;
  }
  postAssessmentQurstionsURL() {
    return `${this.baseApiUrl}/postAssessmentQurstions`;
  }
  postAssessmentRecipientsURL() {
    return `${this.baseApiUrl}/postAssessmentRecipients`;
  }
  postExistAssessmentURL() {
    return `${this.baseApiUrl}/postExistAssessment`;
  }
  exportAssessmentURL() {
    return `${this.baseApiUrl}/exportAssessment`;
  }
}

// Enable Singleton behavior on this class
AssessmentService.__instance = null;
AssessmentService.get = () => {
  if (!AssessmentService.__instance) {
    AssessmentService.__instance = new AssessmentService();
  }
  return AssessmentService.__instance;
};

export default AssessmentService;
