/* eslint-disable indent */
import React from "react";

import { getProjectID_Of_solution_module } from "../../../Services/Shared/Handlers";
import WorkflowList from "../Upload/WorkflowList";

// RENDERS LIST OF WORKFLOWS
const DashboardHome = (props) => {
  const project_id = getProjectID_Of_solution_module();

  return (
    <div className="DF-mt-3 m-l-1-15">
      <WorkflowList projectId={project_id} tabName={"Dashboard"} />
    </div>
  );
};

export default DashboardHome;
