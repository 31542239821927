import axios from "axios";
import jwtUtil from "../../../Constants/Jwt";
import HttpClient from "../../Shared/HttpClient";
import CommonService from "../../Shared/CommonService";
class LoginService {
  constructor() {
    this.client = new HttpClient();
  }

  // API TO GENERATE OTP ON RECEIVING LOGIN ID AND PASSWORD
  generateLoginOTP = (loginData) => {
    var logindata = jwtUtil.encrypt(loginData);
    return new Promise(async (resolve, reject) => [
      axios.post(process.env.REACT_APP_SERVER_HOST_BASE + "/generateLoginOTP", logindata).then((reply) => {
        resolve(reply.data);
      }),
    ]);
  };
  // API TO VERIFY OTP ENTERED BY THE USER
  verifyLoginOTP = (requestBody) => {
    const encryptedCreds = jwtUtil.encrypt(requestBody.credentials);
    const logindata = {
      otp: requestBody.otp,
      credentials: encryptedCreds,
    };
    return new Promise(async (resolve, reject) => [
      axios.post(process.env.REACT_APP_SERVER_HOST_BASE + "/verifyLoginOTP", logindata).then((reply) => {
        resolve(reply.data);
      }),
    ]);
  };
  // API TO FETCH CLIENT DETAILS OF THE USERID
  fetchClientListByUserId = (requestBody) => {
    const encryptedCreds = jwtUtil.encrypt(requestBody);
    const logindata = {
      credentials: encryptedCreds,
    };
    return new Promise(async (resolve, reject) => [
      axios
        .post(process.env.REACT_APP_SERVER_HOST_BASE + "/fetchClientListByUserId", logindata)
        .then((reply) => {
          resolve(reply.data);
        }),
    ]);
  };

  // API TO LOGIN USER AFTER VERIFYING ENTERED VALID OTP
  handleLoginAuthenticate = (loginData) => {
    var logindata = jwtUtil.encrypt(loginData);
    return new Promise(async (resolve, reject) => {
      axios
        .post(process.env.REACT_APP_SERVER_HOST_BASE + "/loginAuthenticate", logindata)
        .then(async (reply) => {
          if (reply.data.CODE) {
            const returnData = { ...reply.data };
            let image;
            if (reply.data.image) {
              // var image = URL.createObjectURL(CommonService.get().getBufferImageInBlob(reply.data.image),{autoRevoke: false})
              var blobImage = CommonService.get().getBufferImageInBlob(reply.data.image);
              image = await new Promise((resolve) => {
                var reader = new FileReader();
                reader.readAsDataURL(blobImage);
                reader.onloadend = function () {
                  var base64data = reader.result;
                  resolve(base64data);
                };
              });
              returnData.userImage = image;
            }
            resolve(returnData);
          } else {
            resolve(reply.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
  // API TO SEND A NEW PASSWORD TO USER IF CLICKED ON FORGOT PASSWORD
  forgotPassword = (clientUserId) => {
    return new Promise(async (resolve, reject) => [
      axios.post(process.env.REACT_APP_SERVER_HOST_BASE + "/forgotpassword", clientUserId).then((reply) => {
        resolve(reply.data);
      }),
    ]);
  };
  // API TO UPDATE PASSWORD
  async updatePassword(passwordData) {
    var passwordData = jwtUtil.encrypt(passwordData);
    const result = await this.client.post("/updatePassword", passwordData);
    if (result.CODE) {
      return true;
    } else {
      return result.MSG;
    }
  }

  // API TO REFRESH JWT TOKEN
  async refreshJWT() {
    const result = await this.client.authGet("/refreshJWT");
    return result;
  }

  // API TO HIT IDAM LOGIN
  async idamAuthenticate() {
    const result = await this.client.authGet("/idamAuthenticate");
    return result;
  }

  // API TO LOGIN A USER BASED ON THE IDAM DETAILS
  loginAuthenticateOnIDAMLogin = (loginData) => {
    var logindata = jwtUtil.encrypt(loginData);
    return new Promise(async (resolve, reject) => {
      axios
        .post(process.env.REACT_APP_SERVER_HOST_BASE + "/loginAuthenticateOnIDAMLogin", logindata)
        .then(async (reply) => {
          if (reply.data.CODE) {
            const returnData = { ...reply.data };
            let image;
            if (reply.data.image) {
              // var image = URL.createObjectURL(CommonService.get().getBufferImageInBlob(reply.data.image),{autoRevoke: false})
              var blobImage = CommonService.get().getBufferImageInBlob(reply.data.image);
              image = await new Promise((resolve) => {
                var reader = new FileReader();
                reader.readAsDataURL(blobImage);
                reader.onloadend = function () {
                  var base64data = reader.result;
                  resolve(base64data);
                };
              });
              returnData.userImage = image;
            }
            resolve(returnData);
          } else {
            resolve(reply.data);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  // CHECK IF CODE IS VALID ON IDAM LOGIN
  checkIfCodeIsValid = (userid, code) => {
    const body = {
      userid,
      code,
    };
    return new Promise(async (resolve, reject) => [
      axios.post(process.env.REACT_APP_SERVER_HOST_BASE + "/checkIfCodeIsValid", body).then((reply) => {
        resolve(reply.data);
      }),
    ]);
  };
  // LOGOUT CURRENT USER
  async logoutCurrentUser() {
    const result = await this.client.authGet("/logoutCurrentUser");
    return result;
  }
}

// Enable Singleton behavior on this class
LoginService.__instance = null;
LoginService.get = () => {
  if (!LoginService.__instance) {
    LoginService.__instance = new LoginService();
  }
  return LoginService.__instance;
};

export default LoginService;
