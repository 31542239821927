import axios from "axios";
import { useState, useCallback, useRef, useEffect } from "react";

import { getUserIDFromSession } from "../Shared/Authenticate";

export const useHttpPutClient = () => {
  const [isLoadingPut, setIsLoading] = useState(false);
  const [errorPut, setError] = useState();

  const activeHttpRequests = useRef([]);
  const generateHeadersModules = () => {
    return {
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      project_id: sessionStorage.getItem("selectedProject"),
      loggedInUserId: getUserIDFromSession(),
    };
  };
  const sendRequestPut = useCallback(async (url, body = null) => {
    setIsLoading(true);
    const httpAbortCtrl = new AbortController();
    activeHttpRequests.current.push(httpAbortCtrl);

    try {
      const response = await axios.post(url, body, {
        headers: generateHeadersModules(),
      });
      const responseData = response.data;

      activeHttpRequests.current = activeHttpRequests.current.filter((reqCtrl) => reqCtrl !== httpAbortCtrl);
      setIsLoading(false);
      return responseData;
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
      throw err;
    }
  }, []);

  const clearErrorPut = () => {
    setError(null);
  };

  useEffect(() => {
    return () => {
      activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort());
    };
  }, []);

  return { isLoadingPut, errorPut, sendRequestPut, clearErrorPut };
};
