import { Panel } from "appkit-react";
import React from "react";

import moon from "../../../Assets/Icons/moon-outline.svg";
import clock from "../../../Assets/Images/QueenBase/Root/icon-clock.svg";
import { polyglotLoader } from "../../../Services/Shared/Translate";

const UserScreenLegend = () => {
  const polyglot = polyglotLoader();
  return (
    <div className="container m-0 p-0 mt-4 mb-4">
      <div className="row mr-0">
        <div className="col-md-5">
          <Panel className="p-2">
            <div className="row margin-center-1em">
              <div className="col-md-2">
                <img width={16} height={16} src={moon} alt="moonuser" />
              </div>
              <div className="col-md-10">{polyglot.t("Temporary user")}</div>
            </div>
            <div className="row margin-center-1em">
              <div className="col-md-2">
                <img src={clock} alt="clocksuer" />
              </div>
              <div className="col-md-10">{polyglot.t("Deactivated")}</div>
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
};

export default UserScreenLegend;
