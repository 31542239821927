import { NAME_REGEX, FIELD_LENGTH_REGEX } from "../../Constants/regex";

export const textFieldValidation = (value) => {
  try {
    let isValueValid = true;
    const isStringValid = NAME_REGEX.test(value);
    if (!isStringValid) {
      isValueValid = false;
    }
    return isValueValid;
  } catch (err) {
    return false;
  }
};

// To verify correct validation of special chars
export const fieldValidation = (value) => {
  try {
    let isValueValid = true;
    const isStringValid = FIELD_LENGTH_REGEX.test(value);
    if (!isStringValid) {
      isValueValid = false;
    }
    return isValueValid;
  } catch (err) {
    return false;
  }
};
