import { DatePicker, ConfigProvider } from "antd";

import { getUserIDFromSession } from "../../../Services/Shared/Authenticate";
import { fieldValidation } from "../../../Services/Shared/formHandlers";
import moment from "moment";
import gr from "antd/lib/locale-provider/de_DE";
import { Input, Panel, Select, SelectOption, SelectGroupTitle } from "appkit-react";
import { Button, ModalBody, ModalHeader } from "appkit-react";

import "moment/locale/de";
import { hasEditPermission, hasDeletePermission } from "../../../Services/Shared/RoleHandler";

import $ from "jquery";
import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";

import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import CustomModal from "../../../Assets/Components/Modal";
import { deleteFill, saveIcon, closeIcon } from "../../../Assets/Icons";
import calendar from "../../../Assets/Icons/calendar.svg";
import editlogo from "../../../Assets/Icons/pencil-outline.svg";
import flag from "../../../Assets/Images/QueenBase/Root/flag-outline.svg";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/QueenBase/Root/loading-spin.svg";
import PermissionService from "../../../Services/QueenBase/Permission/PermissionService";
import { getDisplayDate } from "../../../Services/Shared/Handlers";
import httpRequest from "../../../Services/Shared/HttpClient";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader, userLanguage } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";

// MILESTONE STATUS DASHBOARD
function MileStoneStatusDashboard(props) {
  const [show, setShow] = useState(false);
  const [milestonesData, setMilestones] = useState([]);
  const [tempMilestonesData, setTempMilestones] = useState([]);
  const [selectedProject, SetProjects] = useState({});
  const [MilestoneStatus, SetMainMilestoneStatus] = useState();
  const [refreshKey, SetRefreshKey] = useState(0);
  const [milestonetobedeleted, setMilestonetobedeleted] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isMilestoneDelete, setIsMilestoneDelete] = useState(false);
  const [userRoles, setUserRoles] = useState([]);
  const userid = getUserIDFromSession();
  const HttpRequest = new httpRequest();
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage);

  // GET CURRENT USER PERMISSIONS
  const getCurrentUserPermissions = () => {
    if (userRoles.length > 0) {
      return userRoles[0].Permission;
    } else {
      return [];
    }
  };
  // GET PERMISSION BASED ON PROJECT ID
  const getProjectPermissions = (projectId) => {
    try {
      return userRoles[0].PermissionByProject[projectId];
    } catch (err) {
      return [];
    }
  };
  const currentUserPermissions = getCurrentUserPermissions();

  const Deadline_status = {
    Today: { style: "d-mildstone-status-failed", label: "Today" },
    Over: { style: "d-mildstone-status-overdue", label: "Over due" },
    Up: { style: "d-mildstone-status-upcoming", label: "In " },
  };

  // DELETE MILESTONE
  const deleteMilestone = async (milestonetobedeleted) => {
    try {
      /*
      API TO DELETE MILESTONE BY PASSING
      projectID and projectmilestoneid
      */
      await HttpRequest.post("/deleteMilestone", {
        projectmilestoneid: milestonetobedeleted.milestoneid,
        ProjectID: selectedProject.ProjectID,
      });
      await getMilestones();
      SetRefreshKey((prev) => prev + 1);
      setShow((prev) => !prev);
      setIsMilestoneDelete(false);
      displayToastMessage(
        polyglot.t("milestone_delete", { name: milestonetobedeleted.milestonename }),
        "success",
      );
    } catch (error) {
      displayToastMessage(polyglot.t(polyglot.t("Something went wrong!")), "error");
    }
  };

  // GET MILESTONE DETAILS
  const getMilestones = async () => {
    setIsLoading(true);
    try {
      // API TO GET MILESTONE DETAILS
      let milestone = await HttpRequest.get("/getMilestones");
      const milestoneArray =
        milestone && milestone[0][0] && milestone[0][0].MilestoneInfo
          ? JSON.parse(milestone[0][0].MilestoneInfo)
          : [];
      const projectsArray =
        milestone &&
        milestone[0] &&
        milestone[0][0].MilestoneInfo &&
        milestone[0][0].MilestoneInfo[0] &&
        milestone[0][0].MilestoneInfo[0]
          ? JSON.parse(milestone[0][0].MilestoneInfo)[0]
          : [];
      setMilestones(milestoneArray);
      setTempMilestones(milestoneArray);
      if (Object.keys(selectedProject).length > 0) {
        let SelectProject = milestoneArray.filter((r) => r.ProjectID === selectedProject.ProjectID);
        if (SelectProject[0]) {
          SetProjects(SelectProject[0]);
        } else {
          SetProjects(projectsArray);
        }
      } else {
        SetProjects(projectsArray);
      }
    } catch (error) {
      displayToastMessage(polyglot.t(polyglot.t("Something went wrong!")), "error");
    }
    setIsLoading(false);
  };

  // SET PROJECT
  const SetProject = (value) => {
    let SelectedProject = milestonesData.filter((each) => {
      return each.ProjectID == value;
    });
    SetProjects(SelectedProject[0]);
  };

  // CLOSE DELETE MILESTONE POPUP
  const CloseModal = () => {
    setShow((prev) => !prev);
  };

  // GET STATUS OF MILESTONE
  const getMilestoneStatus = async () => {
    setIsLoading(true);
    try {
      // API TO GET STATUS OF MILESTONE
      const MilestoneStatus = await HttpRequest.get("/getMilestoneStatus");
      SetMainMilestoneStatus(MilestoneStatus);
    } catch (error) {
      displayToastMessage(polyglot.t(polyglot.t("Something went wrong!")), "error");
    }
    setIsLoading(false);
  };

  // UPDATE MILESTONE OF A PROJECT
  const UpdateMilestone = async (Item) => {
    if (Item.isEdit == 1) {
      if (fieldValidation(Item.milestonename) && Item.milestonename) {
        if (Item.deadline != "1970-01-01") {
          try {
            Item.ProjectID = selectedProject.ProjectID;
            // API TO UPDATE MILESTONE OF A PROJECT
            await HttpRequest.post("/UpdateProjectSolutionMilestone", Item);
            let milestones = milestonesData;
            milestones.map((each) => {
              if (each.Solution) {
                each.Solution.map((obj) => {
                  obj.M.map((milestone) => {
                    if (Object.keys(milestone).length > 0) {
                      if (milestone.milestoneid == Item.milestoneid) {
                        milestone.isEdit = milestone.isEdit === 1 ? 0 : 1;
                        SetProjects(each);
                      } else {
                        milestone.isEdit = 0;
                      }
                    }
                  });
                });
              }
            });
            getMilestones();
            displayToastMessage(polyglot.t("milestone updated successfully"), "success");
          } catch (error) {
            displayToastMessage(polyglot.t("Something went wrong!"), "error");
          }
        } else {
          displayToastMessage(polyglot.t("Please enter valid date !!"), "error");
        }
      } else {
        displayToastMessage(polyglot.t("Please enter valid Milestone name !"), "error");
      }
    }
  };

  // EDIT MODE MILESTONE
  const EditModeMilestone = (Item, ProjectID) => {
    let CanEdit = true;
    if (props.projectUsers.length > 0) {
      if (CanEdit) {
        let tempPrjectMilestoneData = JSON.parse(JSON.stringify(tempMilestonesData));
        let Projectmilestones = JSON.parse(JSON.stringify(milestonesData));
        Projectmilestones.map((each) => {
          if (each.Solution) {
            each.Solution.map((obj) => {
              obj.M.map((milestone) => {
                if (Object.keys(milestone).length > 0) {
                  if (milestone.milestoneid == Item.milestoneid) {
                    milestone.isEdit = milestone.isEdit === 1 ? 0 : 1;
                    SetProjects(each);
                  } else {
                    milestone.isEdit = 0;
                  }
                }
              });
            });
          }
        });

        if (Item.isEdit === 1) {
          tempPrjectMilestoneData.map((each) => {
            if (each.Solution) {
              each.Solution.map((obj) => {
                obj.M.map((milestone) => {
                  if (Object.keys(milestone).length > 0) {
                    if (milestone.milestoneid == Item.milestoneid) {
                      SetProjects(each);
                    } else {
                      milestone.isEdit = 0;
                    }
                  }
                });
              });
            }
          });

          setMilestones(tempPrjectMilestoneData);
        } else {
          setMilestones(Projectmilestones);
        }
      } else {
        displayToastMessage(polyglot.t("You do not have access to edit this milestone"), "error");
      }
    }
  };

  // SET MILESTONE STATUS
  const SetMilestoneStatus = (value, Item) => {
    let Projectmilestones = [...milestonesData];
    Projectmilestones.map((each) => {
      if (each.Solution) {
        each.Solution.map((obj) => {
          obj.M.map((milestone) => {
            if (milestone.milestoneid == Item.milestoneid) {
              milestone.Status = value;
            }
          });
        });
      }
    });
    setMilestones(Projectmilestones);
  };

  $(document).on("click", "[id*='edit-milestone-input']", function () {
    var input = $(this);
    var len = input.val().length;
    input.focus();
    input[0].setSelectionRange(len, len);
  });

  // HANDLE MILESTONE CHANGE
  const _handleMilestoneChange = (e, Item) => {
    let Projectmilestones = [...milestonesData];
    Projectmilestones.map((each) => {
      if (each.Solution) {
        each.Solution.map((obj) => {
          obj.M.map((milestone) => {
            if (milestone.milestoneid == Item.milestoneid) {
              milestone.milestonename = e;
            }
          });
        });
      }
    });
    setMilestones(Projectmilestones);
  };

  // ON MILESTONE DEADLINE CHAGNE
  const onMilestoneDeadlineChange = (e, Item) => {
    let Projectmilestones = [...milestonesData];
    Projectmilestones.map((each) => {
      if (each.Solution) {
        each.Solution.map((obj) => {
          obj.M.map((milestone) => {
            if (milestone.milestoneid == Item.milestoneid) {
              let date = new Date(e);
              milestone.deadline = date.toISOString().split("T")[0];
            }
          });
        });
      }
    });
    setMilestones(Projectmilestones);
  };

  // OPEN DELETE MILESTONE
  const OpendeleteMilestone = (milestone) => {
    let CanEdit = true;
    if (CanEdit) {
      setIsMilestoneDelete(true);
      setMilestonetobedeleted(milestone);
      setShow((prev) => !prev);
    } else {
      displayToastMessage(polyglot.t("You do not have access to delete this milestone"), "error");
    }
  };

  // GET PERMISSION LIST OF THIS SCREEN
  const checkAndFetchRole = async (screen_name, solution_name, projectId) => {
    try {
      const roleRes = await PermissionService.get().getPermissionList(screen_name, projectId, solution_name);
      setUserRoles(roleRes);
    } catch (err) {
      displayToastMessage(err, "error");
    }
  };
  // FETCH ROLES ON INITIAL LOAD
  useEffect(() => {
    const screenName = "Project";
    const projectId = selectedProject.ProjectID;
    if (projectId) {
      checkAndFetchRole(screenName, "Base", projectId);
    }
  }, [selectedProject.ProjectID]);

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    getMilestones();
    getMilestoneStatus();
  }, []);

  const today = new Date();

  // FILTER DATES
  const filterDates = (current) => {
    const enddate = selectedProject.enddate ? selectedProject.enddate : "";
    let customEndDate = new Date(enddate);
    customEndDate.setDate(customEndDate.getDate());
    let startdate = selectedProject.startdate ? selectedProject.startdate : moment().startOf("day");
    let customStartDate = new Date(startdate);
    customStartDate.setDate(customStartDate.getDate());
    return (current && current < customStartDate) || (current && current > customEndDate);
  };

  if (isLoading) {
    return (
      <div className="container-fluid">
        <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
      </div>
    );
  }

  if (
    milestonesData &&
    milestonesData.length > 0 &&
    selectedProject &&
    Object.keys(selectedProject).length > 0
  ) {
    return (
      <>
        {isMilestoneDelete && (
          <CustomModal
            show={show}
            onCancel={() => CloseModal()}
            title={polyglot.t("Delete Project Milestone")}
            Footer={[
              <Button
                size="lg"
                className="primary_button"
                onClick={() => deleteMilestone(milestonetobedeleted)}
              >
                {polyglot.t("Yes, delete Milestone")}
              </Button>,
            ]}
          >
            <div className="user-description p-0 break-word">
              {userLanguage() === "en" && (
                <>
                  {polyglot.t("Are you sure you want to delete the project milestone")}
                  &nbsp;
                  <b>{milestonetobedeleted ? milestonetobedeleted.milestonename : null}</b>?
                </>
              )}
              {userLanguage() === "ger" && (
                <>
                  {polyglot.t("delete_milestone_text")}{" "}
                  <b>{milestonetobedeleted ? milestonetobedeleted.milestonename : null}</b>{" "}
                  {polyglot.t("löschen möchten")}?
                </>
              )}
            </div>
          </CustomModal>
        )}
        <Panel className="Milestone-Queen-tot">
          <div className="row dd-milestones-header">
            <div style={{ display: "flex" }}>
              <img alt="glas" src={flag} className="d-icons flagIcon" />
              <p className="d-title">{polyglot.t("Milestones")}</p>
            </div>
            <Select
              className="d-title p-0"
              placeholder={polyglot.t("select project")}
              showSearchOnToggle={true}
              defaultValue={selectedProject.ProjectID}
              onSelect={(value) => SetProject(value)}
              aria-label={polyglot.t("select project")}
            >
              {milestonesData.map((eachProject, index) => {
                return (
                  <SelectOption key={index} value={eachProject.ProjectID}>
                    {eachProject.Project}
                  </SelectOption>
                );
              })}
            </Select>
          </div>
          <table className="table">
            <thead>
              <tr>
                <th className="Queen-Mile">
                  <b>{polyglot.t("Milestone")}</b>
                </th>
                <th className="Queen-Mile">
                  <b>{polyglot.t("Deadline")}</b>
                </th>
                <th className="Queen-Mile">
                  <b>{polyglot.t("Solution")}</b>
                </th>
                <th className="Queen-Mile">
                  <b>{polyglot.t("Status")}</b>
                </th>
              </tr>
            </thead>
            <tbody>
              {Object.keys(selectedProject).length > 0 ? (
                selectedProject.Solution.map((each) => {
                  return each.M.map((obj, i) => {
                    if (Object.keys(obj).length > 0 && !obj.MI_IsDeleted) {
                      let date = new Date(obj.deadline);
                      let Status, daysDiff;
                      if (today.toLocaleString().split(",")[0] == date.toLocaleString().split(",")[0]) {
                        Status = Deadline_status.Today;
                      } else if (today > date) {
                        Status = Deadline_status.Over;
                        const diffTime = Math.abs(date - today);
                        daysDiff = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                        Status.display = `${daysDiff} ${polyglot.t("daysAgo")}`;
                      } else if (today < date) {
                        Status = Deadline_status.Up;
                        const diffTime = Math.abs(date - today);
                        daysDiff = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
                        Status.display = `${Status.label} ${daysDiff} ${polyglot.t("days_only")}`;
                      }
                      if (obj.isEdit == 1 && Object.keys(obj).length > 0) {
                        return (
                          <tr key={i}>
                            <td>
                              <Input
                                className="edit-milestone-input"
                                id={"edit-milestone-input" + i}
                                placeholder={polyglot.t("Milestone name")}
                                onChange={(e) => _handleMilestoneChange(e, obj)}
                                value={obj.milestonename}
                                aria-label={polyglot.t("Milestone name")}
                              />
                            </td>
                            <td id="datepicker_align">
                              {getUserLanguage() === "ger" ? (
                                <ConfigProvider locale={gr}>
                                  <DatePicker
                                    className="antd-datepicker-custom-styles height2rem"
                                    disabledDate={filterDates}
                                    placeholder={polyglot.t("Milestone deadline")}
                                    value={moment(new Date(obj.deadline), "yyyy-mm-dd")}
                                    onChange={(e) => onMilestoneDeadlineChange(e, obj)}
                                    format={"DD.MM.YYYY"}
                                    suffixIcon={<img src={calendar} width={14} height={14} />}
                                  />
                                </ConfigProvider>
                              ) : (
                                <DatePicker
                                  className="antd-datepicker-custom-styles height2rem"
                                  disabledDate={filterDates}
                                  placeholder={polyglot.t("Milestone deadline")}
                                  value={moment(new Date(obj.deadline), "yyyy-mm-dd")}
                                  onChange={(e) => onMilestoneDeadlineChange(e, obj)}
                                  format={"DD.MM.YYYY"}
                                  suffixIcon={<img src={calendar} width={14} height={14} />}
                                />
                              )}
                            </td>
                            <td>{each.SolutionName}</td>
                            <td
                              className={`d-edit-select-status ${
                                obj.status === "failed"
                                  ? "d-mildstone-status-failed"
                                  : "d-mildstone-status-achieved"
                              }`}
                            >
                              <Select
                                placeholder={polyglot.t("Status")}
                                showSearchOnToggle={true}
                                defaultValue={each.M[0].Status}
                                onSelect={(value) => SetMilestoneStatus(value, obj)}
                                aria-label={polyglot.t("Status")}
                              >
                                {MilestoneStatus.map((s, i) => {
                                  return (
                                    <SelectOption key={i} value={s.Status}>
                                      {s.Status == "InProgress"
                                        ? polyglot.t("In progress")
                                        : polyglot.t(s.Status)}
                                    </SelectOption>
                                  );
                                })}
                              </Select>
                            </td>
                            <td>
                              <ButtonIcon
                                iconName={saveIcon}
                                alt="save"
                                onClick={() => UpdateMilestone(obj)}
                                ariaLabel={polyglot.t("save milestone")}
                                title={polyglot.t("Save milestone")}
                              />
                            </td>
                            <td>
                              <ButtonIcon
                                iconName={closeIcon}
                                alt="close"
                                onClick={() => EditModeMilestone(obj, selectedProject.ProjectID)}
                                ariaLabel={polyglot.t("Close")}
                                title={polyglot.t("Close")}
                              />
                            </td>
                          </tr>
                        );
                      } else {
                        if (obj.milestonename) {
                          return (
                            <tr key={i}>
                              <td>{obj && obj.milestonename ? obj.milestonename : ""}</td>
                              <td>
                                {getDisplayDate(date)} <br />
                                {obj && obj.Status != "Achieved" && (
                                  <span className={Status && Status.style ? Status.style : ""}>
                                    {Status && Status.display
                                      ? Status.display
                                      : "Status" && Status.label
                                      ? Status.label
                                      : ""}
                                  </span>
                                )}
                              </td>
                              <td>{each.SolutionName}</td>
                              <td
                                className={
                                  obj && obj.Status === "Failed"
                                    ? "d-mildstone-status-failed"
                                    : "d-mildstone-status-achieved"
                                }
                              >
                                {obj && obj.Status
                                  ? obj.Status == "InProgress"
                                    ? polyglot.t("In progress")
                                    : polyglot.t(obj.Status)
                                  : ""}
                              </td>
                              <td>
                                {hasEditPermission(currentUserPermissions) &&
                                  hasEditPermission(getProjectPermissions(selectedProject.ProjectID)) && (
                                    <ButtonIcon
                                      imgWidth={16}
                                      iconImg={editlogo}
                                      onClick={() => EditModeMilestone(obj, selectedProject.ProjectID)}
                                      alt="edit"
                                      ariaLabel={polyglot.t("edit milestone")}
                                      title={polyglot.t("Edit milestone")}
                                    />
                                  )}
                              </td>

                              <td>
                                {hasDeletePermission(currentUserPermissions) &&
                                  hasEditPermission(getProjectPermissions(selectedProject.ProjectID)) && (
                                    <ButtonIcon
                                      iconName={deleteFill}
                                      alt="delete"
                                      onClick={() => OpendeleteMilestone(obj)}
                                      ariaLabel={polyglot.t("delete milestone")}
                                      title={polyglot.t("Delete milestone")}
                                    />
                                  )}
                              </td>
                            </tr>
                          );
                        }
                      }
                    }
                    if (Object.keys(obj).length == 0) {
                      return (
                        <tr key={i}>
                          <td colSpan="6" align="center">
                            {polyglot.t("No Milestones for")} {each.SolutionName}
                          </td>
                        </tr>
                      );
                    }
                  });
                })
              ) : (
                <tr>
                  <td colSpan="4" align="center">
                    {polyglot.t("No Milestones")}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </Panel>
      </>
    );
  } else {
    return (
      <div className="display-contents">
        <Panel className="Milestone-Queen-tot center">
          <span className="row">
            <img src={flag} className="d-icons" />
            <p className="d-title">{polyglot.t("Milestones")}</p>
          </span>
          <p align="center">{polyglot.t("No projects, please create one")}</p>
        </Panel>
      </div>
    );
  }
}

MileStoneStatusDashboard.propTypes = {
  milestonesData: PropTypes.array,
  OpendeleteMilestone: PropTypes.func,
  EditModeMilestone: PropTypes.func,
  UpdateMilestone: PropTypes.func,
  SetMilestoneStatus: PropTypes.func,
  onMilestoneDeadlineChange: PropTypes.func,
  selectedProject: PropTypes.object,
  SetProject: PropTypes.func,
};

export default MileStoneStatusDashboard;
