import React, { useState, useEffect } from "react";

import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/GRC/loading-spin.svg";
import AssessmentService from "../../../Services/GRC/AssessmentService";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import ErrorPanel from "../Shared/ErrorPanel";
import QuestionTags from "../Shared/QuestionTags";
import "./index.css";

const AssessmentQuestionPreview = ({ parentId, selectedId, setQuestionCount }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);

  useEffect(() => {
    const getSelectedQuestionaries = async () => {
      try {
        const responseData = await sendRequest(
          AssessmentService.get().getAassessmentPreviewQuestionsByIdURL(selectedId, parentId),
        );
        if (responseData) {
          setAssessmentQuestions(responseData);
          setQuestionCount(responseData.length);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getSelectedQuestionaries();
  }, [sendRequest]);
  return (
    <>
      {error && <ErrorPanel error={error} onClear={clearError} />}
      {isLoading && (
        <div>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      )}
      {!isLoading && assessmentQuestions.length > 0 && (
        <div>
          {assessmentQuestions.map((item, i) => {
            return (
              <div key={item.value} className="border border-dark p-b-2 m-b-2">
                <p className="ques_style" style={{ overflow: "hidden" }}>
                  <span>
                    <span
                      className="appkiticon icon-circle-checkmark-outline custom-grc-icon"
                      fontSize="small"
                    />{" "}
                    {i + 1}.
                  </span>
                  {item.label}
                </p>
                <QuestionTags key={i} ids={item.value} />
              </div>
            );
          })}
        </div>
      )}
    </>
  );
};
export default AssessmentQuestionPreview;
