import React from "react";
import Dropzone from "../../dropzone/Dropzone";
import "./personalCustodian.css";
import { Avatar, Button, Input, Select, SelectGroupTitle, SelectOption } from "appkit-react";
import FormFieldComponent from "../../Shared/components/FormFieldComponent";
import FormTextAreaComponent from "../../Shared/components/FormTextAreaComponent";
import FormSingleSelect from "../../Shared/components/FormSingleSelect";
import FormSingleSelectAutoComplete from "../../Shared/components/FormSingleSelectAutoComplete";
import FormSingleSelectWithDescription from "../../Shared/components/FormSingleSelectWithDescription";
import "appkit-react/style/appkit-react.default.css";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import { DataContext } from "../../../../Services/Shared/Store.js";
import { Form } from "antd";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";
import UserService from "../../../../Services/QueenBase/Users/UserService";
import CustodianService from "../../../../Services/DFED/CustodianService";
import {
  TextFieldLengthValidation,
  TextFieldLengthRegexFieldValidation,
} from "../../../../utility/textFieldValidation";
import closeIcon from "../../../../Assets/Images/DFED/icon-x.svg";
import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import download from "../../../../Assets/Images/DFED/icon-download.svg";
import Progress from "../../progress/Progress";

// THIS CLASS IS RESPONSIBLE FOR CREATING PERSONAL CUSTODIAN AND EDITING OF PERSONAL CUSTODIAN

class PersonalCustodianComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        CRT_TYPE_ID: this.props.custodianTypeID ? this.props.custodianTypeID : null,
        CRT_FIRST_NAME: "",
        CRT_MIDDLE_NAME: "",
        CRT_LAST_NAME: "",
        CRT_EMAIL: "",
        CRT_POSITION_ID: "",
        CRT_COUNTRY_ID: "",
        CRT_ENTITY: "",
        CRT_DEPARTMENT: "",
        CRT_ALIAS: "",
        CC_CLASSIFICATION_TYPE: "",
        CRT_CLASSIFICATION_ID: "",
        CRT_NOTES: "",
        CRT_FILE: [],
        position_Name: null,
        CRT_PROJECT_ID: this.props.selectedProjectId,
        CRT_PROJECT_NAME: this.props.selectedProjectName,
        textFieldLengthError: false,
        textFieldLengthError1: true,
        textFieldLengthError2: true,
        CRT_PREFIX_ID: "",
      },
      saveButtonDisabled:
        this.props.isCustodianEditableAfterProcessed === false && this.props.editableFormData !== null
          ? false
          : true,
      custodianRecords: null,
      CountryList: null,
      ClassificationList: null,
      positionList: null,
      positionSelectValue: null,
      files: [],
      filesize: null,
      prefix: [],
      userLanguage: "en",
      requestProgress: null,
    };

    this.onChangeFormField = this.onChangeFormField.bind(this);
    this.saveFormData = this.saveFormData.bind(this);
    this.formFileUpload = this.formFileUpload.bind(this);
    this.deleteUploadedFile = this.deleteUploadedFile.bind(this);
    this.downloadCustodianById = this.downloadCustodianById.bind(this);
  }
  static contextType = DataContext;
  polyglot = polyglotLoader();

  // LIFECYCLE METHOD RESPONSIBLE FOR GETTING ALL DATA AND CALLING API SERVICE=================
  componentDidMount() {
    let selectedPosition = null;
    const custodianService = new CustodianService();
    const userService = new UserService();
    const { getLanguageKeyAsPerAPI } = this.context;
    const lang = getLanguageKeyAsPerAPI();
    custodianService.getCountry(lang).then((countryResult) => {
      if (countryResult && countryResult.data) {
        this.setState(
          {
            CountryList: countryResult.data,
          },
          () => {},
        );
      }
    });
    // get Classification
    custodianService.getClassifications().then((classificationResult) => {
      if (classificationResult && classificationResult.data) {
        this.setState(
          {
            ClassificationList: classificationResult.data,
          },
          () => {},
        );
      }
    });
    custodianService.getPositions().then((positionResult) => {
      if (positionResult && positionResult.data) {
        const positionsList = positionResult.data;
        this.setState(
          {
            positionList: positionsList,
          },
          () => {},
        );
      }
    });
    // Getting prefix list from queenbase/prefixNames
    userService.getPrefixNames(lang).then((res) => {
      if (res && res.CODE == 1) {
        const prefixList = res.prefixNames;
        this.setState(
          {
            prefix: prefixList,
          },
          () => {},
        );
      }
    });

    if (this.props.editableFormData) {
      var fileArr = this.props.editableFormData[0].files
        ? this.props.editableFormData[0].files.split(";")
        : null;
      let tempFileArr = [];
      if (fileArr && fileArr.length > 0) {
        for (let i = 0; i < fileArr.length; i++) {
          let obj = { name: fileArr[i] };
          tempFileArr.push(obj);
        }
      }
      const requiredFormData = { ...this.props.editableFormData[0] };
      requiredFormData.CRT_FILE = tempFileArr;
      // if the props preFillSelectWithDescription value has position name in it, then apply it to the exisiting state
      if (this.props.preFillSelectWithDescription.positionData.length > 0) {
        const alreadySelectedPosition = this.props.preFillSelectWithDescription.positionData[0];
        requiredFormData.position_Name = alreadySelectedPosition.value;
      }

      this.setState(
        {
          formData: requiredFormData,
          positionSelectValue: selectedPosition,
        },
        () => {
          if (this.checkFieldValidation()) {
            this.setState({
              saveButtonDisabled: false,
            });
          }
        },
      );
    }
    this.getUserLanguage();
  }

  async getUserLanguage() {
    let userLanguage = await sessionStorage.getItem("USER_LANGUAGE");
    if (this.state.userLanguage !== userLanguage)
      this.setState({
        userLanguage: userLanguage,
      });
  }

  // RUNS WHEN COMPONENT GETS UPDATED
  componentDidUpdate(prevProps, prevState) {
    if (prevState.formData.CRT_ALIAS !== this.state.formData.CRT_ALIAS) {
      this.checkIsAliasUnique(this.state.formData).then((isAliasUnique) => {
        this.setState({ textFieldAliasError: !isAliasUnique });
      });
    }

    if (prevState.textFieldAliasError !== this.state.textFieldAliasError) {
      this.setState({
        saveButtonDisabled: this.state.textFieldAliasError || !this.checkFieldValidation(),
      });
    }

    this.getUserLanguage();
    const isFormViewOnly = this.props.isCustodianEditableAfterProcessed;
    if (!isFormViewOnly && this.state.formData !== prevState.formData && "CRT_ID" in prevState.formData) {
      const { formGotOpened } = this.context;
      formGotOpened();
    }
    // Getting prefix list from queenbase/prefixNames

    if (this.state.userLanguage !== prevState.userLanguage) {
      const userService = new UserService();
      const { getLanguageKeyAsPerAPI } = this.context;
      const lang = getLanguageKeyAsPerAPI();
      userService.getPrefixNames(lang).then((res) => {
        if (res && res.CODE == 1) {
          const prefixList = res.prefixNames;
          this.setState(
            {
              prefix: prefixList,
            },
            () => {},
          );
        }
      });
    }
  }

  onChangeCountry = (value) => {
    const form = { ...this.state.formData };
    form.CRT_COUNTRY_ID = value;
    this.setState({
      formData: form,
    });
  };
  onChangeSalutation = (value) => {
    const form = { ...this.state.formData };
    form.CRT_PREFIX_ID = value;
    this.setState({
      formData: form,
    });
  };

  // GET ID OF SELECTED POSITION LIST
  getIDOfSelectedPosition = (positionList, positionName) => {
    try {
      const selectedPosition = positionList.find((each) => each.value === positionName);
      const positionID = selectedPosition.PS_ID;
      return positionID;
    } catch (err) {
      displayToastMessage(err.message, "error");
      return null;
    }
  };

  // FORM FILED ONCHANGE EVENT=====================
  onChangeFormField(label, value, id) {
    let val = value;
    let name = label;
    if (label === "CRT_POSITION_ID") {
      if (id === "create-option") {
        this.setState(
          (prevState) => {
            return {
              formData: {
                ...prevState.formData,
                position_Name: value,
                CRT_POSITION_ID: null,
              },
            };
          },
          () => {
            if (this.checkFieldValidation()) {
              this.setState({
                saveButtonDisabled: false,
              });
            } else {
              this.setState({
                saveButtonDisabled: true,
              });
            }
          },
        );
      } else if (id === "select-option") {
        this.setState(
          (prevState) => {
            const idOfSelectedPosition = this.getIDOfSelectedPosition(this.state.positionList, value);
            return {
              formData: {
                ...prevState.formData,
                CRT_POSITION_ID: idOfSelectedPosition,
                position_Name: value,
              },
            };
          },
          () => {
            if (this.checkFieldValidation()) {
              this.setState({
                saveButtonDisabled: false,
              });
            } else {
              this.setState({
                saveButtonDisabled: true,
              });
            }
          },
        );
      }
    }
    if (label === "CRT_FIRST_NAME") {
      if (TextFieldLengthValidation(val)) {
        this.setState(({ formData }) => {
          const { CRT_MIDDLE_NAME, CRT_LAST_NAME, CRT_ALIAS } = formData;
          const newCustodianAlias = `${val}${CRT_MIDDLE_NAME ? " " + CRT_MIDDLE_NAME : ""} ${CRT_LAST_NAME}`;
          return {
            formData: {
              ...formData,
              CRT_ALIAS: this.props.editableFormData === null ? newCustodianAlias : CRT_ALIAS,
            },
          };
        });
        this.setState({ textFieldLengthError: false });
      } else {
        this.setState({ textFieldLengthError: true });
      }
    }
    if (label === "CRT_LAST_NAME") {
      if (TextFieldLengthValidation(val)) {
        this.setState(({ formData }) => {
          const { CRT_FIRST_NAME, CRT_MIDDLE_NAME, CRT_ALIAS } = formData;
          const newCustodianAlias = `${CRT_FIRST_NAME}${CRT_MIDDLE_NAME ? " " + CRT_MIDDLE_NAME : ""} ${val}`;
          return {
            formData: {
              ...formData,
              CRT_ALIAS: this.props.editableFormData === null ? newCustodianAlias : CRT_ALIAS,
            },
          };
        });
        this.setState({ textFieldLengthErrorL: false });
      } else {
        this.setState({ textFieldLengthErrorL: true });
      }
    }

    if (!id) {
      if (label === "CRT_MIDDLE_NAME") {
        if (TextFieldLengthValidation(val)) {
          this.setState(
            (prevState) => {
              return {
                formData: {
                  ...prevState.formData,
                  [name]: val,
                },
              };
            },
            () => {
              if (this.checkFieldValidation()) {
                this.setState({
                  saveButtonDisabled: false,
                });
              } else {
                this.setState({
                  saveButtonDisabled: true,
                });
              }
            },
          );
          this.setState({ textFieldLengthErrorM: false });
        } else {
          this.setState({ textFieldLengthErrorM: true });
        }
      } else {
        if (TextFieldLengthValidation(val)) {
          this.setState(
            (prevState) => {
              return {
                formData: {
                  ...prevState.formData,
                  [name]: val,
                },
              };
            },
            () => {
              if (this.checkFieldValidation()) {
                this.setState({
                  saveButtonDisabled: false,
                });
              } else {
                this.setState({
                  saveButtonDisabled: true,
                });
              }
            },
          );
        }
      }
    }
  }

  checkIsAliasUnique = async (currentFormData) => {
    const currentAlias = currentFormData?.CRT_ALIAS;
    const custodianId = currentFormData?.CRT_ID;

    let selectedProjectId = this.props.selectedProjectId;
    const custodianService = new CustodianService();

    const allCustodians = await custodianService.getCustodianData(selectedProjectId);
    let custodiansList = allCustodians.data;

    if (custodianId) {
      custodiansList = custodiansList.filter((data) => Number(data.CRT_ID) !== Number(custodianId));
    }

    return !custodiansList.some(({ CRT_ALIAS: custodianAlias }) => custodianAlias === currentAlias);
  };

  // CHECKING FORM FILED IS VALID OR NOT(CHECKING FILLED OR NOT )
  checkFieldValidation() {
    let isPositionFieldEntered = false;
    // if new position is entered
    if (this.state.formData.CRT_POSITION_ID === null || this.state.formData.CRT_POSITION_ID === "") {
      if (
        this.state.formData.position_Name &&
        this.state.formData.position_Name !== null &&
        this.state.formData.position_Name !== ""
      ) {
        isPositionFieldEntered = true;
      } else {
        isPositionFieldEntered = false;
      }
    }
    // if existing position is selected
    if (this.state.formData.CRT_POSITION_ID !== null && this.state.formData.CRT_POSITION_ID !== "") {
      if (
        this.state.formData.position_Name &&
        this.state.formData.position_Name !== null &&
        this.state.formData.position_Name !== ""
      ) {
        isPositionFieldEntered = true;
      } else {
        isPositionFieldEntered = false;
      }
    }

    if (
      this.state.formData.CRT_CLASSIFICATION_ID !== "" &&
      this.state.formData.CRT_CLASSIFICATION_ID !== null &&
      this.state.formData.CRT_FIRST_NAME !== "" &&
      this.state.formData.CRT_FIRST_NAME !== null &&
      this.state.formData.CRT_LAST_NAME !== "" &&
      this.state.formData.CRT_LAST_NAME !== null &&
      this.state.formData.CRT_ALIAS !== "" &&
      this.state.formData.CRT_ALIAS !== null &&
      !this.state.textFieldAliasError &&
      this.props.isCustodianEditableAfterProcessed === false &&
      isPositionFieldEntered &&
      this.state.formData.CRT_DEPARTMENT !== null &&
      this.state.formData.CRT_DEPARTMENT !== "" &&
      this.state.formData.CRT_COUNTRY_ID !== null &&
      this.state.formData.CRT_COUNTRY_ID !== "" &&
      this.state.formData.CRT_ENTITY !== null &&
      this.state.formData.CRT_ENTITY !== ""
    ) {
      return true;
    } else {
      return false;
    }
  }

  // LOGIC FOR VALIDATE EMAIL, FIRST AND LAST NAME IS ENTERED BY USER, TO CHECK REGEX, TO AVOID SPECIAL CHARACTERS EXCEPTS UNDERSCORE AND SPACE
  saveFormData() {
    var mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    var textFormat = `^[a-zA-Z0-9 _]{1,40}$`;
    let isValidationSuccessful = false;

    if (
      this.state.formData.CRT_FIRST_NAME != "" &&
      this.state.formData.CRT_LAST_NAME != "" &&
      this.state.formData.CRT_FIRST_NAME !== null &&
      this.state.formData.CRT_LAST_NAME !== null
    ) {
      if (this.state.formData.CRT_EMAIL !== "" && this.state.formData.CRT_EMAIL !== null) {
        if (
          TextFieldLengthRegexFieldValidation(this.state.formData.CRT_FIRST_NAME) &&
          TextFieldLengthRegexFieldValidation(this.state.formData.CRT_LAST_NAME)
          // &&
          // this.state.formData.CRT_EMAIL.match(mailformat)
        ) {
          if (this.state.formData.CRT_EMAIL.match(mailformat)) {
            isValidationSuccessful = true;
          } else {
            displayToastMessage(this.polyglot.t("Invalid email"), "error");
          }
        } else {
          isValidationSuccessful = false;
          displayToastMessage(this.polyglot.t("field_validaton"), "error");
        }
      } else {
        // FORM IS VALID EVEN IF EMAIL IS NOT ENTERED BY THE USER
        isValidationSuccessful = true;
      }
    }
    // middlename
    if (this.state.formData.CRT_MIDDLE_NAME != "" && this.state.formData.CRT_MIDDLE_NAME != null) {
      if (!TextFieldLengthRegexFieldValidation(this.state.formData.CRT_MIDDLE_NAME)) {
        isValidationSuccessful = false;
        displayToastMessage(this.polyglot.t("field_validaton"), "error");
      }
    }
    // CRT_ENTITY
    if (
      this.state.formData.CRT_ENTITY != "" &&
      this.state.formData.CRT_ENTITY != null &&
      !TextFieldLengthRegexFieldValidation(this.state.formData.CRT_ENTITY)
    ) {
      isValidationSuccessful = false;
      displayToastMessage(this.polyglot.t("field_validaton"), "error");
    }
    // CRT_DEPARTMENT
    if (
      this.state.formData.CRT_DEPARTMENT != "" &&
      this.state.formData.CRT_DEPARTMENT != null &&
      !TextFieldLengthRegexFieldValidation(this.state.formData.CRT_DEPARTMENT)
    ) {
      isValidationSuccessful = false;
      displayToastMessage(this.polyglot.t("field_validaton"), "error");
    }
    // CRT_NOTES
    if (
      this.state.formData.CRT_NOTES != "" &&
      this.state.formData.CRT_NOTES != null &&
      !TextFieldLengthRegexFieldValidation(this.state.formData.CRT_NOTES)
    ) {
      isValidationSuccessful = false;
      displayToastMessage(this.polyglot.t("field_validaton"), "error");
    }
    // CRT_ALIAS
    if (
      this.state.formData.CRT_ALIAS != "" &&
      this.state.formData.CRT_ALIAS != null &&
      !TextFieldLengthRegexFieldValidation(this.state.formData.CRT_ALIAS)
    ) {
      isValidationSuccessful = false;
      displayToastMessage(this.polyglot.t("field_validaton"), "error");
    }
    if (isValidationSuccessful) {
      this.savePersonCustodianData();
    }
  }
  // SAVING THE CUSTODIAN
  onUploadProgress = (progressEvent) => {
    let progress = (progressEvent.loaded / progressEvent.total) * 100;
    this.setState({ requestProgress: progress });
  };

  savePersonCustodianData() {
    const { formGotClosed } = this.context;
    const selectedProjectId = this.props.selectedProjectId;
    const custodianService = new CustodianService();
    let formData = new FormData();
    for (let i = 0; i < this.state.files.length; i++) {
      formData.append("file", this.state.files[i]);
      formData.append("fileName", this.state.files[i].name);
    }

    formData.append("custodianFormData", JSON.stringify(this.state.formData));
    formData.append("projectName", this.props.selectedProjectName);

    custodianService
      .handleCustodianFormData(formData, this.onUploadProgress)
      .then((result) => {
        if (result.data.CODE === 0) {
          displayToastMessage(result.data.MSG, "error");
          return null;
        }
        if (result && result.data && result.data.exist) {
          displayToastMessage(
            this.polyglot.t("Custodian with same Alias is already exist Please enter different name"),
            "error",
          );
        } else {
          displayToastMessage(
            this.polyglot.t(`Custodian ${this.props.editableFormData ? "updated" : "created"} successfully`),
            "success",
          );

          custodianService.getCustodianData(selectedProjectId).then((result) => {
            if (result && result.data) {
              this.setState(
                {
                  custodianRecords: result.data,
                },
                () => {
                  this.props.showCustodianList(this.state.custodianRecords);
                },
              );
            }
          });
        }
        formGotClosed();
      })
      .catch((error) => {
        displayToastMessage(error.response.data, "error");
      });
  }
  // FILE DROP FUNCTIONALLY AFTER DRAG
  onDrop = (acceptedFiles, index) => {
    this.formFileUpload(acceptedFiles);
  };
  // FILE UPLOAD FUNCTIONALITY=====
  formFileUpload(data) {
    if (data.length > 0) {
      let obj = {
        name: data[0].name,
      };
      const regex = new RegExp(/(?<process>[\w\.-]*)((\.exe)|(\.js)|(\.html)|(\.asp)|(\.php))/gm);
      if (
        this.state.formData.CRT_FILE &&
        this.state.formData.CRT_FILE.length < 5 &&
        data[0].size < 104857600 &&
        !regex.test(data[0].name)
      ) {
        this.setState((prevState) => ({
          formData: {
            ...this.state.formData,
            CRT_FILE: [...this.state.formData["CRT_FILE"], obj],
          },
          files: [...this.state.files, data[0]],
          filesize: data[0].size,
        }));
      } else {
        displayToastMessage(data[0].name + " " + `is not a valid file`, "error");
        this.setState({
          formData: {
            ...this.state.formData,
          },
          filesize: data[0].size,
        });
      }
    }
  }

  // Download Files of Custodian
  downloadCustodianById(downloadIndex, cid, data) {
    let metadata;
    this.state.formData.CRT_FILE.map((fileName, index) => {
      var fname = fileName.name.split(",")[1];
      fname = fname.split(".")[1];
      if (cid == index) {
        metadata = {
          fileExtension: fname,
          projectName: this.props.selectedProjectName,
          CRT_ALIAS: this.state.formData.CRT_ALIAS,
          CRT_ID: this.state.formData.CRT_ID,
          CRT_TYPE_ID: this.state.formData.CRT_TYPE_ID,
          CRT_FILE: fileName.name,
        };
      }
    });

    const custodianService = new CustodianService();
    custodianService
      .downloadCustodianById(metadata)
      .then((response) => {
        if (response.data) {
          if (response.data.CODE === 1) {
            displayToastMessage(this.polyglot.t(`${response.data.MSG}`), "success"); // Downloaded successfully, translation added
            const buffer = Buffer.from(response.data.buffer);
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", response.data.fileName);
            document.body.appendChild(link);
            link.click();
          } else {
            displayToastMessage(this.polyglot.t(`${response.data.MSG}`), "error");
          }
        }
      })
      .catch((error) => {});
  }

  // DELETED UPLOADED FILE FROM FRONTEND===================
  deleteUploadedFile(filedata, index, data) {
    this.state.formData.CRT_FILE.splice(index, 1);
    this.state.files.splice(index, 1);
    this.setState({
      formData: {
        ...this.state.formData,
      },
      files: this.state.files,
    });
  }

  // CHECK AND DISABLE ALIAS FIELD
  isAliasFieldDisabled = () => {
    let isDisabled = false;
    if (this.props.isCustodianEditableAfterProcessed) {
      isDisabled = true;
    }
    return isDisabled;
  };

  // TEXT FIELD DISABLE IF ALREADY PROCESSED.
  textFieldDIsableHandler = () => {
    if (
      this.props.isCustodianEditableAfterProcessed === true
      // && this.props.editableFormData !== null
    ) {
      return true;
    } else {
      return false;
    }
  };

  renderProgress = () => {
    return (
      <div className="col-md-8 p-r-0">
        <div className="row">
          <div className="col-md-10" style={{ marginLeft: "10%" }}>
            <div className="ProgressWrapper" style={{ width: "200%", float: "left" }}>
              <Progress progress={this.state.requestProgress ?? 0} />
            </div>
          </div>
        </div>
      </div>
    );
  };
  // RENDER FUNCTION FOR PERSON CUSTODIAN=============
  render() {
    const polyglot = polyglotLoader();
    return (
      <div className="">
        <Form>
          <div className="row m-l-0 m-r-0">
            <div className="col-sm-12 col-md-12 inputFieldGroup p-b-5">
              <span>{polyglot.t("Person")}</span>
            </div>
            <div className="col-sm-12 col-md-6">
              {this.state.textFieldLengthError ? (
                <p aria-label={polyglot.t("Maximum character length is 40")} className="txt-field-error m-0 ">
                  {polyglot.t("Maximum character length is 40")}
                </p>
              ) : null}
            </div>
            <div className="col-sm-12 col-md-6">
              {this.state.textFieldLengthErrorL ? (
                <p className="txt-field-error m-0 " aria-label={polyglot.t("Maximum character length is 40")}>
                  {polyglot.t("Maximum character length is 40")}
                </p>
              ) : null}
            </div>
            <div className="col-sm-12 col-md-3">
              {this.state.userLanguage === "en" && (
                <>
                  <Select
                    disabled={this.textFieldDIsableHandler()}
                    preFillSelectWithDescription={this.props.preFillSelectWithDescription}
                    className="update-position"
                    placeholder={polyglot.t("Salutation")}
                    aria-label={polyglot.t("Salutation")}
                    value={Number(this.state.formData.CRT_PREFIX_ID).toString()}
                    id="salutation"
                    name="CRT_PREFIX_ID"
                    onSelect={(value) => this.onChangeSalutation(value)}
                  >
                    {this.state.prefix.map((prefix, index) => {
                      return (
                        <SelectOption key={index} value={Number(prefix.id).toString()}>
                          {polyglot.t(prefix.name)}
                        </SelectOption>
                      );
                    })}
                  </Select>
                </>
              )}
              {this.state.userLanguage === "ger" && (
                <>
                  <Select
                    disabled={this.textFieldDIsableHandler()}
                    preFillSelectWithDescription={this.props.preFillSelectWithDescription}
                    className="update-position"
                    placeholder={polyglot.t("Salutation")}
                    aria-label={polyglot.t("Salutation")}
                    value={Number(this.state.formData.CRT_PREFIX_ID).toString()}
                    id="salutation"
                    name="CRT_PREFIX_ID"
                    onSelect={(value) => this.onChangeSalutation(value)}
                  >
                    {this.state.prefix.map((prefix, index) => {
                      return (
                        <SelectOption key={index} value={Number(prefix.id).toString()}>
                          {polyglot.t(prefix.name)}
                        </SelectOption>
                      );
                    })}
                  </Select>
                </>
              )}
            </div>
            <div className="col-sm-12 col-md-3">
              <FormFieldComponent
                aria-label={polyglot.t("First name *")}
                disabledTextFields={this.textFieldDIsableHandler()}
                placeholder={polyglot.t("First name *")}
                id="fname"
                name="CRT_FIRST_NAME"
                val={this.state.formData.CRT_FIRST_NAME}
                onChangeFormField={this.onChangeFormField.bind(this)}
              ></FormFieldComponent>
            </div>
            <div className="col-sm-12 col-md-6">
              <FormFieldComponent
                disabledTextFields={this.textFieldDIsableHandler()}
                aria-label={polyglot.t("Last name *")}
                placeholder={polyglot.t("Last name *")}
                id="lname"
                name="CRT_LAST_NAME"
                val={this.state.formData.CRT_LAST_NAME}
                onChangeFormField={this.onChangeFormField.bind(this)}
              ></FormFieldComponent>
            </div>
            <div className="col-sm-12 col-md-6">
              {this.state.textFieldLengthErrorM ? (
                <p className="txt-field-error m-0 " aria-label={polyglot.t("Maximum character length is 40")}>
                  {polyglot.t("Maximum character length is 40")}
                </p>
              ) : null}
            </div>
            <div className="col-sm-12 col-md-6"></div>
            <div className="col-sm-12 col-md-6">
              <FormFieldComponent
                disabledTextFields={this.textFieldDIsableHandler()}
                placeholder={polyglot.t("Middle name")}
                aria-label={polyglot.t("Middle name")}
                id="mName"
                name="CRT_MIDDLE_NAME"
                val={
                  this.state.formData.CRT_MIDDLE_NAME === null ||
                  this.state.formData.CRT_MIDDLE_NAME == "null"
                    ? ""
                    : this.state.formData.CRT_MIDDLE_NAME
                }
                onChangeFormField={this.onChangeFormField.bind(this)}
              ></FormFieldComponent>
            </div>
            <div className="col-sm-12 col-md-6">
              <FormFieldComponent
                disabledTextFields={this.textFieldDIsableHandler()}
                placeholder={polyglot.t("Email")}
                aria-label={polyglot.t("Email")}
                id="email"
                name="CRT_EMAIL"
                val={this.state.formData.CRT_EMAIL}
                onChangeFormField={this.onChangeFormField.bind(this)}
              ></FormFieldComponent>
            </div>
            {this.state.positionList && this.state.positionList.length > 0 && (
              <div
                className={`col-sm-12 col-md-6 ${this.textFieldDIsableHandler() ? "div-is-disabled" : ""}`}
              >
                <FormSingleSelectAutoComplete
                  fillKey="positionData"
                  disabledTextFields={this.textFieldDIsableHandler()}
                  placeholder={polyglot.t("Position *")}
                  aria-label={polyglot.t("Position *")}
                  preFillSelectWithDescription={this.props.preFillSelectWithDescription}
                  positionSelectValue={this.state.positionSelectValue}
                  name="CRT_POSITION_ID"
                  options={this.state.positionList}
                  onChangeFormField={this.onChangeFormField.bind(this)}
                  isClearable={false}
                />
              </div>
            )}
            <br></br>
            <br></br>

            <div className="col-sm-12 col-md-12 inputFieldGroup m-t-28 p-b-5">
              <span aria-label={polyglot.t("Location")}>{polyglot.t("Location")}</span>
            </div>
            {this.state.CountryList && this.state.CountryList.length > 0 && (
              <div className="col-sm-12 col-md-6">
                <FormSingleSelectAutoComplete
                  fillKey="countryData"
                  disabledTextFields={this.textFieldDIsableHandler()}
                  placeholder={this.polyglot.t("Country *")}
                  aria-label={this.polyglot.t("Country *")}
                  preFillSelectWithDescription={this.props.preFillSelectWithDescription}
                  positionSelectValue={Number(this.state.formData.CRT_COUNTRY_ID)}
                  name="CRT_COUNTRY_ID"
                  options={this.state.CountryList}
                  onChangeFormField={(_, value) => this.onChangeCountry(value)}
                  isClearable={false}
                />
              </div>
            )}

            <div className="col-sm-12 col-md-6">
              <FormFieldComponent
                role="presentation"
                disabledTextFields={this.textFieldDIsableHandler()}
                placeholder={polyglot.t("Entity *")}
                aria-label={polyglot.t("Entity *")}
                id="entity"
                name="CRT_ENTITY"
                val={this.state.formData.CRT_ENTITY}
                onChangeFormField={this.onChangeFormField.bind(this)}
              ></FormFieldComponent>
            </div>
            <div className="col-sm-12 col-md-6">
              <FormFieldComponent
                disabledTextFields={this.textFieldDIsableHandler()}
                placeholder={polyglot.t("Department*")}
                aria-label={polyglot.t("Department*")}
                id="department"
                name="CRT_DEPARTMENT"
                val={this.state.formData.CRT_DEPARTMENT}
                onChangeFormField={this.onChangeFormField.bind(this)}
              ></FormFieldComponent>
            </div>

            <div className="col-sm-12 col-md-12 inputFieldGroup m-t-28 p-b-5" role="presentation">
              <span role="presentation" aria-label={polyglot.t("General")}>
                {polyglot.t("General")}
              </span>
            </div>
            <div className="col-sm-12">
              {this.state.textFieldAliasError ? (
                <p className="txt-field-error m-0 " aria-label={polyglot.t("Alias should be unique")}>
                  {polyglot.t("Alias should be unique")}
                </p>
              ) : null}
            </div>
            <div className="col-sm-12 col-md-6">
              <FormFieldComponent
                disabledTextFields={this.isAliasFieldDisabled()}
                placeholder="Alias *"
                aria-label="Alias *"
                id="alias"
                name="CRT_ALIAS"
                val={this.state.formData.CRT_ALIAS}
                onChangeFormField={this.onChangeFormField.bind(this)}
              ></FormFieldComponent>
            </div>
            <div className={`col-sm-12 col-md-6 ${this.textFieldDIsableHandler() ? "div-is-disabled" : ""}`}>
              <FormSingleSelectWithDescription
                disabledTextFields={this.textFieldDIsableHandler()}
                preFillSelectWithDescription={this.props.preFillSelectWithDescription}
                aria-label={polyglot.t("Classification *")}
                placeholder={polyglot.t("Classification *")}
                id="classifications"
                val={this.state.formData.CRT_CLASSIFICATION_ID}
                name="CRT_CLASSIFICATION_ID"
                onChangeFormField={this.onChangeFormField.bind(this)}
                classificationOptions={this.state.ClassificationList}
              />
            </div>

            <div className="col-sm-12 col-md-12">
              <FormTextAreaComponent
                disabledTextFields={this.textFieldDIsableHandler()}
                placeholder={polyglot.t("Notes_custodian")}
                aria-label={polyglot.t("Notes_custodian")}
                id="notes"
                name="CRT_NOTES"
                val={this.state.formData.CRT_NOTES}
                onChangeFormField={this.onChangeFormField.bind(this)}
              ></FormTextAreaComponent>
            </div>

            <div className="col-sm-12 col-md-12 p-t-10 p-b-10">
              <div className="file-upload-header" aria-label={polyglot.t("Upload additional information")}>
                {polyglot.t("Upload additional information")}
              </div>
              <div
                className="file-upload-header-description p-t-5 p-b-15"
                aria-label={polyglot.t("(E.g. employee contract, consent, etc.)")}
              >
                {polyglot.t("(E.g. employee contract, consent, etc.)")}
              </div>
              <div
                className="file-upload-header-description f-s-14 p-b-15 text-color"
                aria-label={polyglot.t(
                  "Note: Maximum number of files that can be uploaded is 5 (Max size : 100 MB each) ",
                )}
              >
                {polyglot.t(
                  "Note: Maximum number of files that can be uploaded is 5 (Max size : 100 MB each) ",
                )}
              </div>

              <div id="dfed-custodian-personal">
                <Dropzone
                  onFilesAdded={(e) => this.onDrop(e)}
                  disabled={this.textFieldDIsableHandler()}
                ></Dropzone>
              </div>
            </div>
            <div className="col-sm-12 col-md-12">
              <div className="">
                {this.state.formData.CRT_FILE &&
                  this.state.formData.CRT_FILE.length > 0 &&
                  this.state.formData.CRT_FILE.map((acceptedFile, index) => (
                    <div className="uploadedFileList m-b-6">
                      {acceptedFile.name.split(",").length > 1
                        ? acceptedFile.name.split(",")[1]
                        : acceptedFile.name}
                      &nbsp;&nbsp;
                      {/* Condition for, if files is no avalible in formdata, disable the download icon */}
                      {this.state.formData.files && this.state.formData.files !== "undefined" ? (
                        <img
                          src={download}
                          alt={download}
                          key={index}
                          onClick={this.downloadCustodianById.bind(
                            this,
                            acceptedFile.name.split(",")[0],
                            index,
                          )}
                          className=" cursor"
                        ></img>
                      ) : null}
                      <div className="fileDeleteIcon">
                        <button
                          disabled={this.textFieldDIsableHandler()}
                          onClick={this.deleteUploadedFile.bind(this, acceptedFile.name.split(",")[0], index)}
                          className="cursor"
                        >
                          <img
                            src={closeIcon}
                            alt={this.polyglot.t("Remove file")}
                            aria-label={this.polyglot.t("Remove file")}
                          />
                        </button>
                      </div>
                    </div>
                  ))}
                {this.state.requestProgress && <div className="col-md-10 p-r-0">{this.renderProgress()}</div>}
              </div>

              {this.state.formData.CRT_FILE && this.state.formData.CRT_FILE.length === 5 && (
                <div
                  className="text-danger"
                  aria-label={this.polyglot.t(
                    "Maximum file upload limit is  5 you crossed that limit. sorry you can't upload more.",
                  )}
                >
                  {this.polyglot.t(
                    "Maximum file upload limit is  5 you crossed that limit. sorry you can't upload more.",
                  )}
                </div>
              )}

              {this.state.filesize != null && this.state.filesize > 104857600 && (
                <div
                  className="text-danger"
                  aria-label={this.polyglot.t("Maximum file upload size is 100 MB only")}
                >
                  {this.polyglot.t("Maximum file upload size is 100 MB only")}
                </div>
              )}
            </div>
          </div>
          &nbsp;
          <div className="row m-l-0 m-r-0 ">
            <div className="col-sm-12 col-md-12">
              <ButtonPrimary
                disabled={this.state.saveButtonDisabled}
                onClick={this.saveFormData.bind(this)}
                ariaLabel={this.polyglot.t("Save custodian")}
              >
                {this.polyglot.t("Save custodian")}
              </ButtonPrimary>
            </div>
          </div>
          {this.props.isCustodianEditableAfterProcessed && (
            <div className="row m-l-0 m-r-0">
              <div
                className="text-danger p-l-16 f-s-16"
                aria-label={this.polyglot.t("* You Can't update this custodian. it is already processed.")}
              >
                {this.polyglot.t("* You Can't update this custodian. it is already processed.")}
              </div>
            </div>
          )}
        </Form>
      </div>
    );
  }
}
export default PersonalCustodianComponent;
