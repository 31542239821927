import React from "react";

import "./AddAnalysis.css";
import { polyglotLoader } from "../../../Services/Shared/Translate";
// ANALYSIS INITIAL PAGE THIS CLASS IS RESPONSIBLE FOR RENDERING ANALYSIS COMPONENT===============
const AddAnalysisInitPage = (props) => {
  // const {userLanguage} = useContext(DataContext);
  var polyglot = polyglotLoader();
  return (
    <div>
      <div className="row titleText pt-2">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <h1 className="main_title_qb" aria-label={polyglot.t("Start your Analysis")}>
            {polyglot.t("Start your Analysis")}{" "}
          </h1>
        </div>
        <div className="col-md-3"></div>
      </div>

      <div className="row ">
        <div className="col-md-2"></div>
        <div className="col-md-8">
          <div className="m-0 description-text">{polyglot.t("Map you_para_AddAnalysisinitPage")}</div>
        </div>
        <div className="col-md-2"></div>
      </div>
    </div>
  );
};

export default AddAnalysisInitPage;
