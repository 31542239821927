import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";

import CreateProject from "./CreateProject";
import NoAccess from "../../../Assets/Components/NoAccess";
import ProjectService from "../../../Services/QueenBase/Projects/ProjectService";
import { hasEditPermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";

// CONTAINER TO RENDER EDIT PROJECT FORM
const EditProjectFormContainer = (props) => {
  const { selectedProject } = JSON.parse(props.location.state);

  const Item = selectedProject;

  const initialProjectFormObj = {
    step1: {},
    step2: {},
    step3: {},
    step4: {},
  };

  const [projectFormObj, setProjectFormObj] = useState(initialProjectFormObj);
  const [viewForm, setViewForm] = useState(false);

  /* GET PROJECT FORM DATA BY PROJECT ID
	PASSING:
	projectid: string
	*/
  const fetchProjectFromByProjectId = async () => {
    const fetchedProjectForm = await ProjectService.get().getProjectFormDataById(Item.PROJECTID);
    setProjectFormObj({ ...fetchedProjectForm });
    setViewForm(true);
  };
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    // api to fetch project details and pass it to create project
    fetchProjectFromByProjectId();
  }, []);

  // DISPLAY PROJECT FORM
  const displayForm = () => {
    if (viewForm) {
      return <CreateProject selectedProject={Item} projectFormObj={projectFormObj} />;
    }
  };

  return <>{displayForm()}</>;
};
EditProjectFormContainer.propTypes = {
  Item: PropTypes.object,
};

const EditProjectContainer = (props) => {
  const { getCurrentUserPermissions } = useContext(DataContext);
  const currentUserPermissions = getCurrentUserPermissions();
  return (
    <>
      {hasEditPermission(currentUserPermissions) && <EditProjectFormContainer {...props} />}
      {!hasEditPermission(currentUserPermissions) && <NoAccess />}
    </>
  );
};

export default EditProjectContainer;
