import "../../../../Assets/Css/QueenBase/CompanyCenter/CompanyCenter.css";
import { Divider, Panel } from "appkit-react";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import { DataContext } from "../../../../Services/Shared/Store";
import { polyglotLoader } from "../../../../Services/Shared/Translate";

function PackageConsumptionHeader({ children }) {
  const { getUserLanguage } = useContext(DataContext);
  const polyglot = polyglotLoader(getUserLanguage());

  return (
    <Panel className="packageWidth cc-panel-content-data ">
      <p className="mt-3 subtitle_two_title_qb">{polyglot.t("Package Consumption")} </p>
      <Divider />
      <div className="Box-Progress packageconsumption-progress">
        <div className="Box-Data-Progress">{children}</div>
      </div>
    </Panel>
  );
}
PackageConsumptionHeader.propTypes = {
  children: PropTypes.element,
};
export default PackageConsumptionHeader;
