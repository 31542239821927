import React, { useState, useEffect } from "react";

import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/GRC/loading-spin.svg";
import RecipientService from "../../../Services/GRC/RecipientService";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import ErrorPanel from "../Shared/ErrorPanel";
import "./Pages/index.css";

const RecipientResponseQuestionPreview = ({ parentId, handleChanged, totalQuestions, setid, uid }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);

  const isAnswered = (parentId, questionStatusData) => {
    const statusValue = questionStatusData.find((x) => parseInt(x.question_id) === parseInt(parentId));
    return statusValue.status;
  };
  useEffect(() => {
    const getSelectedQuestionaries = async () => {
      try {
        const responseData = await sendRequest(
          RecipientService.get().getRecipientAassessmentQuestionsByUidURL(parentId, uid),
        );
        if (responseData) {
          setAssessmentQuestions(responseData);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getSelectedQuestionaries();
  }, [sendRequest]);

  return (
    <>
      {error && <ErrorPanel error={error} onClear={clearError} />}
      {isLoading && (
        <div>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      )}

      {!isLoading &&
        assessmentQuestions &&
        assessmentQuestions.map((item, i) => {
          return (
            <div
              key={item.value}
              className="p-0"
              onClick={(e) => {
                handleChanged(e, item.value, "ques", i);
              }}
            >
              <p className="question_text_custome">
                {item && (
                  <span
                    fontSize="small"
                    className={` appkiticon icon-circle-checkmark-fill custom-grc-icon ${
                      item.skip === -1 ? "" : item.skip === 1 ? "partial-icon" : "green-icon"
                    }`}
                  />
                )}
                {parseInt(item.value) === parseInt(setid) ? (
                  <span className="active-question">Question {i + 1}</span>
                ) : (
                  <span className="quest_padd">Question {i + 1}</span>
                )}
              </p>
            </div>
          );
        })}
    </>
  );
};
export default RecipientResponseQuestionPreview;
