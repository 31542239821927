import React, { useState, useContext, useEffect } from "react";
import { DatePicker, ConfigProvider } from "antd";
import moment from "moment";
import gr from "antd/lib/locale-provider/de_DE";

import "moment/locale/de";
import { Input, Select, SelectGroupTitle, SelectOption, Panel } from "appkit-react";
import { Formik } from "formik";
import * as Yup from "yup";

import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";
import { closeIcon, deleteFill } from "../../../Assets/Icons";
import calendar from "../../../Assets/Icons/calendar.svg";
import { PRICE_REGEX, FIELD_REGEX } from "../../../Constants/regex";
import AdminService from "../../../Services/QueenBase/Admin/AdminServices";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import CustomDropZonePdf from "../../QueenBase/Shared/CustomeDropZonePdf";
import displayToastMessage from "../Root/displayToastMessage";
// INVOICE FORM
function InvoiceForm(props, history) {
  const { showForm, clients, projects, status, onEdit, item, isEdit } = props;
  // DATA FROM CONTEXT
  const { getUserLanguage, isFormOpen, formGotClosed, formGotOpened } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const [fileObj, setFileObj] = useState({});
  const [openConfirmBox, setOpenConfirmBox] = useState(false); // this value handles the confirm box of exit form
  const [isLoading, setIsLoading] = useState(false);
  const initialValues = {
    clientid: 0,
    projectid: null,
    clientInvoiceRef: "",
    description: "",
    invoiceAmount: "",
    invoiceDate: "",
    dueDate: "",
    timeRange: "",
    invoiceStatusId: 0,
  };
  // VALIDATION SCHEMA FOR INVOICE FORM
  const validationSchema = Yup.object().shape({
    clientid: Yup.number().min(1, "ClientName is required"),
    projectid: Yup.number().nullable(),
    clientInvoiceRef: Yup.string()
      .matches(FIELD_REGEX, polyglot.t("field_validaton"))
      .required("Client Invoice Reference is required")
      .trim(),
    description: Yup.string()
      .matches(FIELD_REGEX, polyglot.t("field_validaton"))
      .max(100, "Maximum character length is 100")
      .required("Description is required")
      .trim(),
    invoiceAmount: Yup.string()
      .matches(PRICE_REGEX, "The price should be up to 2 decimal places only")
      .required("Invoice amount is required"),
    invoiceDate: Yup.date().required("InvoiceDate is required"),
    dueDate: Yup.date().required("Invoice DueDate is required"),
    timeRange: Yup.string()
      .matches(FIELD_REGEX, polyglot.t("field_validaton"))
      .max(50, "Maximum character length is 50")
      .required("Time range is required")
      .trim(),
    invoiceStatusId: Yup.number().min(1, "Invoice status is required"),
  });
  let isCreateForm = true;
  if (item) {
    isCreateForm = false;
  }

  // RUNS ON INITIAL LOAD
  useEffect(() => isEdit, []);

  // CHECK FORM VALIDATION
  const checkFormValidation = (statusId) => {
    const isFilePresent = Object.keys(fileObj).length > 0;
    if (statusId < 3 && isFilePresent) return true;

    if (statusId < 3 && !isFilePresent) {
      displayToastMessage("It is mandatory to upload a file", "error");
      return false;
    }
    if (statusId === 3 || statusId === 4) return true;
  };
  // SUBMIT FORM TO CREATE AN INVOICE
  const CreateInvoice = async (invoice) => {
    setIsLoading(true);
    const modifiedFormObj = { ...invoice };
    // change invoiceAmount to type Number from string
    modifiedFormObj.invoiceAmount = Number(invoice.invoiceAmount);
    const formValidationSuccess = checkFormValidation(modifiedFormObj.invoiceStatusId);
    if (formValidationSuccess) {
      const formData = new FormData();
      let uploadedFile = {};
      if (Object.keys(fileObj).length > 0) {
        uploadedFile = fileObj.file;
      }
      formData.append("upload_data", JSON.stringify(modifiedFormObj));
      formData.append("uploaded_file", uploadedFile);
      const res = await AdminService.get().createInvoice(formData);
      if (res.CODE) {
        displayToastMessage(polyglot.t("invoice_created", "success"));
        showForm();
        formGotClosed();
      } else {
        displayToastMessage(polyglot.t("invoice_creation_failed"), "error");
      }
    }
    setIsLoading(false);
  };

  // INVOICE UPLOADED
  const invoiceUploaded = async (files, event) => {
    setFileObj({
      file: event.target.files[0],
      name: event.target.files[0].name,
      size: event.target.files[0].size,
    });
  };
  // UPLOAD INVOICE FILE
  const UploadInvoiceFile = () => {
    if (!fileObj.name) {
      return (
        <CustomDropZonePdf
          onFilesAdded={invoiceUploaded}
          customDropZoneBoxStyle={{
            width: "100%",
            margin: "0",
            padding: "30px",
          }}
        />
      );
    }
  };
  // REMOVE FILE OBJECT
  const removeFileObj = () => {
    setFileObj({});
  };
  // check if form is empty before canceling the form
  const checkIfFormIsEmptyAndGoback = () => {
    if (isFormOpen) {
      // show confirm box
      toggleConfirmBox();
    } else {
      showForm();
    }
  };
  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  const toggleConfirmBox = () => {
    setOpenConfirmBox(!openConfirmBox);
  };
  // CLOSE FORM AND NAVIGATE TO VIEW PROJECTS
  const closeFormAndRedirect = () => {
    toggleConfirmBox();
    redirectionOnClosingConfirmBox();
    formGotClosed();
  };
  // redirect on closing conform box
  const redirectionOnClosingConfirmBox = () => {
    showForm();
  };
  // DISPLAY UPLOADED FILES
  const displayUploadedFiles = () => {
    if (fileObj.name) {
      return (
        <div className="col-md-12">
          <div className="row file-upload-box">
            <div className="col-md-12">
              <>
                <b>{fileObj.name}</b>
                <ButtonIcon
                  iconName={deleteFill}
                  alt="delete"
                  onClick={removeFileObj}
                  title={polyglot.t("Delete invoice")}
                  ariaLabel={polyglot.t("Delete invoice")}
                />
              </>
            </div>
          </div>
        </div>
      );
    }
  };
  return (
    <div>
      <div className="AddInvoicePannel">
        <Panel className="panel add-user a-panel-user">
          <div className="row">
            <div className="col-md-11 ">
              <h5 className="headertest mb-4">{polyglot.t("Add a new Invoice")}</h5>
            </div>
            <div className="col-md-1 invoice-close">
              <ButtonIcon
                iconName={closeIcon}
                onClick={checkIfFormIsEmptyAndGoback}
                alt="close"
                title={polyglot.t("Close")}
                ariaLabel={polyglot.t("Close")}
              />
            </div>
          </div>

          <Formik onSubmit={CreateInvoice} initialValues={initialValues} validationSchema={validationSchema}>
            {(prop) => {
              const { values, handleChange, handleSubmit, errors, touched, dirty } = prop;
              // FORMIK FORM - HANDLE FORMOPEN OR CLOSE STATUS
              const updateFormStatusBasedOnDirty = () => {
                if (dirty) {
                  formGotOpened();
                }
                if (!dirty) {
                  formGotClosed();
                }
              };
              updateFormStatusBasedOnDirty();
              const restrictStartDates = (current) => {
                const enddate = values.dueDate ? values.dueDate : "";
                let customDate = new Date(enddate);
                customDate.setDate(customDate.getDate());
                return current && current > customDate;
              };
              const restrictEndDates = (current) => {
                let startdate = values.invoiceDate ? values.invoiceDate : null;
                if (startdate) {
                  let customDate = new Date(startdate);
                  customDate.setDate(customDate.getDate());
                  return current && current < customDate;
                }
              };
              return (
                <div>
                  <div className="row mt-1">
                    <div className="col-md-6 ">
                      {getUserLanguage() == "ger" && clients && clients.length > 0 && (
                        <Select
                          placeholder={polyglot.t("Select client")}
                          value={values.clientid}
                          onSelect={(value) => handleChange("clientid")({ target: { value } })}
                          aria-label={polyglot.t("Select client")}
                        >
                          <SelectGroupTitle>{polyglot.t("Clients")} </SelectGroupTitle>
                          {clients.map((r, index) => {
                            return (
                              <SelectOption key={index + 1} value={r.clientid}>
                                {r.clientname}
                              </SelectOption>
                            );
                          })}
                        </Select>
                      )}
                      {getUserLanguage() == "en" && clients && clients.length > 0 && (
                        <Select
                          placeholder={polyglot.t("Select client")}
                          value={values.clientid}
                          onSelect={(value) => handleChange("clientid")({ target: { value } })}
                          aria-label={polyglot.t("Select client")}
                        >
                          <SelectGroupTitle>{polyglot.t("Clients")} </SelectGroupTitle>
                          {clients.map((r, index) => {
                            return (
                              <SelectOption key={index + 1} value={r.clientid}>
                                {r.clientname}
                              </SelectOption>
                            );
                          })}
                        </Select>
                      )}
                      {errors.clientid && touched.clientid ? (
                        <div className="error">{polyglot.t(errors.clientid)}</div>
                      ) : (
                        null &&
                        errors.clientid && <span className="error">{polyglot.t(errors.clientid)}</span>
                      )}
                    </div>
                    <div className="col-md-6">
                      {getUserLanguage() === "ger" && (
                        <>
                          {projects && projects.length > 0 && (
                            <Select
                              placeholder={polyglot.t("Select project")}
                              value={values.projectid}
                              onSelect={(value) =>
                                handleChange("projectid")({
                                  target: { value },
                                })
                              }
                              aria-label={polyglot.t("Select project")}
                            >
                              <SelectGroupTitle>{polyglot.t("Projects")} </SelectGroupTitle>
                              {projects
                                .filter((r) => values.clientid == r.clientid)
                                .map((r, index) => {
                                  return (
                                    <SelectOption key={index + 1} value={r.projectid}>
                                      {r.name}
                                    </SelectOption>
                                  );
                                })}
                            </Select>
                          )}
                        </>
                      )}
                      {getUserLanguage() === "en" && (
                        <>
                          {projects && projects.length > 0 && (
                            <Select
                              placeholder={polyglot.t("Select project")}
                              value={values.projectid}
                              onSelect={(value) =>
                                handleChange("projectid")({
                                  target: { value },
                                })
                              }
                              aria-label={polyglot.t("Select project")}
                            >
                              <SelectGroupTitle>{polyglot.t("Projects")} </SelectGroupTitle>
                              {projects
                                .filter((r) => values.clientid == r.clientid)
                                .map((r, index) => {
                                  return (
                                    <SelectOption key={index + 1} value={r.projectid}>
                                      {r.name}
                                    </SelectOption>
                                  );
                                })}
                            </Select>
                          )}
                        </>
                      )}
                      {errors.projectid && touched.projectid ? (
                        <div className="error">{polyglot.t(errors.projectid)}</div>
                      ) : (
                        null &&
                        errors.projectid && <span className="error">{polyglot.t(errors.projectid)}</span>
                      )}
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        inputBoxSize="sm"
                        placeholder={polyglot.t("Client Invoice Reference*")}
                        value={values.clientInvoiceRef}
                        onChange={handleChange("clientInvoiceRef")}
                      />
                      {errors.clientInvoiceRef && touched.clientInvoiceRef ? (
                        <div className="error">{polyglot.t(errors.clientInvoiceRef)}</div>
                      ) : (
                        null &&
                        errors.clientInvoiceRef && (
                          <span className="error">{polyglot.t(errors.clientInvoiceRef)}</span>
                        )
                      )}
                    </div>
                    <div className="col-md-6 ">
                      {getUserLanguage() === "ger" && (
                        <>
                          {status && status.length > 0 && (
                            <Select
                              placeholder={polyglot.t("Invoice Status_star")}
                              value={values.invoiceStatusId}
                              onSelect={(value) =>
                                handleChange("invoiceStatusId")({
                                  target: { value },
                                })
                              }
                              aria-label={polyglot.t("Invoice Status_star")}
                            >
                              <SelectGroupTitle>{polyglot.t("Status")} </SelectGroupTitle>
                              {status.map((r, index) => {
                                return (
                                  <SelectOption key={index + 1} value={r.ID}>
                                    {r.STATUS}
                                  </SelectOption>
                                );
                              })}
                            </Select>
                          )}
                        </>
                      )}
                      {getUserLanguage() === "en" && (
                        <>
                          {status && status.length > 0 && (
                            <Select
                              placeholder={polyglot.t("Invoice Status_star")}
                              value={values.invoiceStatusId}
                              onSelect={(value) =>
                                handleChange("invoiceStatusId")({
                                  target: { value },
                                })
                              }
                              aria-label={polyglot.t("Invoice Status_star")}
                            >
                              <SelectGroupTitle>{polyglot.t("Status")} </SelectGroupTitle>
                              {status.map((r, index) => {
                                return (
                                  <SelectOption key={index + 1} value={r.ID}>
                                    {r.STATUS}
                                  </SelectOption>
                                );
                              })}
                            </Select>
                          )}
                        </>
                      )}
                      {errors.invoiceStatusId && touched.invoiceStatusId ? (
                        <div className="error">{polyglot.t(errors.invoiceStatusId)}</div>
                      ) : (
                        null &&
                        errors.invoiceStatusId && (
                          <span className="error">{polyglot.t(errors.invoiceStatusId)}</span>
                        )
                      )}
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6">
                      <Input
                        inputBoxSize="sm"
                        placeholder={polyglot.t("Invoice Amount*")}
                        value={values.invoiceAmount}
                        onChange={handleChange("invoiceAmount")}
                        aria-label={polyglot.t("Invoice Amount*")}
                      />
                      {errors.invoiceAmount && touched.invoiceAmount ? (
                        <div className="error">{polyglot.t(errors.invoiceAmount)}</div>
                      ) : (
                        null &&
                        errors.invoiceAmount && (
                          <span className="error">{polyglot.t(errors.invoiceAmount)}</span>
                        )
                      )}
                    </div>
                    <div className="col-md-6">
                      <Input
                        inputBoxSize="sm"
                        placeholder={polyglot.t("Time Range*")}
                        value={values.timeRange}
                        onChange={handleChange("timeRange")}
                      />
                      {errors.timeRange && touched.timeRange ? (
                        <div className="error">{polyglot.t(errors.timeRange)}</div>
                      ) : (
                        null &&
                        errors.timeRange && <span className="error">{polyglot.t(errors.timeRange)}</span>
                      )}
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-6" id="datepicker-admin">
                      {getUserLanguage() === "ger" ? (
                        <ConfigProvider locale={gr}>
                          <DatePicker
                            disabledDate={restrictStartDates}
                            placeholder={polyglot.t("Invoice Date *")}
                            value={values.invoiceDate ? moment(values.invoiceDate) : ""}
                            onChange={(value) =>
                              handleChange("invoiceDate")({
                                target: { value: value || "" },
                              })
                            }
                            format={"DD.MM.YYYY"}
                            className="antd-datepicker-custom-styles full-width"
                            suffixIcon={<img src={calendar} width={14} height={14} />}
                          />
                        </ConfigProvider>
                      ) : (
                        <DatePicker
                          disabledDate={restrictStartDates}
                          placeholder={polyglot.t("Invoice Date *")}
                          value={values.invoiceDate ? moment(values.invoiceDate) : ""}
                          onChange={(value) =>
                            handleChange("invoiceDate")({
                              target: { value: value || "" },
                            })
                          }
                          format={"DD.MM.YYYY"}
                          className="antd-datepicker-custom-styles full-width"
                          suffixIcon={<img src={calendar} width={14} height={14} />}
                        />
                      )}

                      {errors.invoiceDate && touched.invoiceDate ? (
                        <div className="error">{polyglot.t(errors.invoiceDate)}</div>
                      ) : (
                        ""
                      )}
                    </div>
                    <div className="col-md-6" id="datepicker-admin">
                      {getUserLanguage() === "ger" ? (
                        <ConfigProvider locale={gr}>
                          <DatePicker
                            disabledDate={restrictEndDates}
                            placeholder={polyglot.t("Invoice Due Date *")}
                            value={values.dueDate ? moment(values.dueDate) : ""}
                            onChange={(value) =>
                              handleChange("dueDate")({
                                target: { value: value || "" },
                              })
                            }
                            format={"DD.MM.YYYY"}
                            suffixIcon={<img src={calendar} width={14} height={14} />}
                            className="antd-datepicker-custom-styles full-width"
                          />
                        </ConfigProvider>
                      ) : (
                        <DatePicker
                          disabledDate={restrictEndDates}
                          placeholder={polyglot.t("Invoice Due Date *")}
                          value={values.dueDate ? moment(values.dueDate) : ""}
                          onChange={(value) =>
                            handleChange("dueDate")({
                              target: { value: value || "" },
                            })
                          }
                          format={"DD.MM.YYYY"}
                          className="antd-datepicker-custom-styles full-width"
                          suffixIcon={<img src={calendar} width={14} height={14} />}
                        />
                      )}
                      {errors.dueDate && touched.dueDate ? (
                        <div className="error">{polyglot.t(errors.dueDate)}</div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <br />
                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        inputBoxSize="sm"
                        placeholder={polyglot.t("Description_star")}
                        value={values.description}
                        onChange={handleChange("description")}
                        aria-label={polyglot.t("Description_star")}
                      />
                      {errors.description && touched.description ? (
                        <div className="error">{polyglot.t(errors.description)}</div>
                      ) : null}
                      <br />
                    </div>
                  </div>
                  <div>
                    {UploadInvoiceFile()}
                    {displayUploadedFiles()}
                  </div>

                  <br />
                  <ButtonPrimary
                    onClick={handleSubmit}
                    ariaLabel={polyglot.t("Save Invoice")}
                    disabled={isLoading}
                  >
                    {polyglot.t("Save Invoice")}
                  </ButtonPrimary>
                </div>
              );
            }}
          </Formik>
        </Panel>
      </div>
      <ConfirmBoxExitForm
        show={openConfirmBox}
        heading={"Cancel update"}
        cancel={toggleConfirmBox}
        proceed={closeFormAndRedirect}
      />
    </div>
  );
}

export default InvoiceForm;
