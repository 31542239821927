import "../../../Assets/Css/QueenBase/CompanyCenter/CompanyCenter.css";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import PackageConsumptionCard from "./Cards/PackageConsumptionCard";
import PackageDetailsCard from "./Cards/PackageDetailsCard";
import SolutionHeader from "./Headers/SolutionHeader";
import { SOLUTION_IDS_WITH_PERIOD_FILTER } from "../../../Constants/Constant";
import * as path from "../../../Constants/Path";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";

function CompanyPackages({ solution, solutionList, history, dfedPackageConsumptionDetails }) {
  const { getUserLanguage } = useContext(DataContext);
  const polyglot = polyglotLoader(getUserLanguage());
  const defaultPeriod = dfedPackageConsumptionDetails[dfedPackageConsumptionDetails.length - 1].period;
  const [dfedPackagePeriod, setDfedPackagePeriod] = useState(defaultPeriod);
  const getPackageInfoByPeriod = () =>
    dfedPackageConsumptionDetails.filter((dfedPackage) => dfedPackage.period === dfedPackagePeriod)[0];
  const [dfedPackageInfo, setDfedPackageInfo] = useState(getPackageInfoByPeriod());

  const navigate = (id) => {
    history.push({
      pathname: path.SOLUTION_REVIEW_PATH,
      state: {
        id,
        solutionList,
        solution: solutionList.find((r) => r.solutionid === id),
      },
    });
  };

  useEffect(() => {
    setDfedPackageInfo(getPackageInfoByPeriod());
  }, [dfedPackagePeriod]);

  const startDate = solution.createddate[0].split("T")[0];
  const endDate = solution.enddate.split("T")[0];
  const packagePeriod = `${startDate} to ${endDate}`;
  return (
    <SolutionHeader
      solutionName={polyglot.t("your_package", { name: solution.NAME })}
      selectOptions={dfedPackageConsumptionDetails}
      onSelect={(period) => setDfedPackagePeriod(period)}
      selectedPeriod={dfedPackagePeriod}
      hasFilter={SOLUTION_IDS_WITH_PERIOD_FILTER.includes(solution.solutionid)}
    >
      <div className="row">
        <div className="col-lg-4 col-sm-12">
          {solution.solutionid === 1 ? (
            <PackageDetailsCard
              packageName={solution.title}
              packagePeriod={dfedPackagePeriod}
              packagePrice={dfedPackageInfo.packagePlanDetails.price}
              packageUpgradeStatus={solution.packageUpgradeStatus}
              planDescription={dfedPackageInfo.packagePlanDetails.planDescription}
              onClick={() => navigate(solution.solutionid)}
            />
          ) : (
            <PackageDetailsCard
              packageName={solution.title}
              packagePeriod={packagePeriod}
              packagePrice={solution.price}
              packageUpgradeStatus={solution.packageUpgradeStatus}
              planDescription={solution.body.PlanDescription}
              onClick={() => navigate(solution.solutionid)}
            />
          )}
        </div>
        <div className="col-lg-4 col-sm-12">
          <PackageConsumptionCard packageInfo={dfedPackageInfo} solutionId={solution.solutionid} />
        </div>
      </div>
    </SolutionHeader>
  );
}
CompanyPackages.propTypes = {
  history: PropTypes.object,
  solution: PropTypes.object,
  solutionList: PropTypes.array,
  dfedPackageConsumptionDetails: PropTypes.array,
};
export default withRouter(CompanyPackages);
