/* eslint-disable no-mixed-spaces-and-tabs */
import { Input, TextArea, Select, SelectOption, SelectGroupTitle, Checkbox } from "appkit-react";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";

import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import SelectCustom from "../../../../Assets/Components/Select";
import QuestionService from "../../../../Services/GRC/QuestionService";
import { useHttpClient } from "../../../../Services/GRC/http-hook";
import { useHttpPostClient } from "../../../../Services/GRC/http-hook-post";
import { useHttpPutClient } from "../../../../Services/GRC/http-hook-put";
import { polyglotLoader } from "../../../../Services/Shared/TranslateGRC";
import { questionnaireValidationSchema } from "../../../../utility/textFieldValidation";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";
import { AnswerType } from "../AnswerType";
import EditDependencyAnswerBlock from "../DependancyQuestionFolder/EditDependencyAnswerBlock";

const EditDependencyQuestion = ({
  categoryId,
  currentquestion,
  setCurrentQuestion,
  setlatestId,
  questId,
  mode,
  test,
  answers,
  previous_ans_Id,
  answerId,
  setmodal,
  setSave,
  dependencyQuestionId,
  usemode,
}) => {
  const polyglot = polyglotLoader();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { isLoadingPut, errorPut, sendRequestPut, clearErrorPut } = useHttpPutClient();
  const { isLoadingPost, errorPost, sendRequestPost, clearErrorPost } = useHttpPostClient();
  // manage data load
  const [answerTypes, setAnswertypes] = useState([]);
  const [disabledAnswerTypes, setDisabledAnswerTypes] = useState([]);
  const [assessmentTypes, setAssessmenttypes] = useState([]);
  const [tagList, setTags] = useState([]);
  // set custome answers
  const [customCount, setCustomCount] = useState(2);
  const [answersdata, setAnswerdata] = useState([]);
  const [selectedTags, setSelectedTags] = useState([]);
  const [tagsData, settagsData] = useState([]);
  const [resp, setResponse] = useState("");

  const initialValuesOfForm = {
    question_text: "",
    addcomment: false,
    commentmandatory: false,
    addfile: false,
    fileuploadmandatory: false,
    assessmentid: "",
    answertypeid: "",
    not_applicable: false,
    scoring_top: "",
  };

  const [initialFormValues, setInitialFormValues] = useState(initialValuesOfForm);

  let newArr = [];
  const isAddmode = true;

  const setAnsType = (value) => {
    const selectedAnswertypes = answerTypes.filter((item) => item.id === value)[0];

    newArr.length = 0;

    if (selectedAnswertypes.options === "values") {
      const elments = selectedAnswertypes.values.split(selectedAnswertypes.config);
      for (let k = 0; k < elments.length; k++) {
        newArr.push({
          id: k,
          answer_text: elments[k],
          additional_answer_text: "",
          scoring: 0,
          weightage: 0,
          dependency_question_id: 0,
          readonly: selectedAnswertypes.is_readonly,
          hasError: false, // checks if the row has any error or not
        });
      }
    } else if (selectedAnswertypes.options === "freebox") {
      newArr.push({
        id: 1,
        answer_text: "",
        additional_answer_text: "",
        scoring: 0,
        weightage: 0,
        dependency_question_id: 0,
        readonly: selectedAnswertypes.is_readonly,
        hasError: false, // checks if the row has any error or not
      });
    } else if (selectedAnswertypes.options === "renge") {
      for (let i = 0; i < customCount; i++) {
        newArr.push({
          id: i,
          answer_text: "",
          additional_answer_text: "",
          scoring: 0,
          weightage: 0,
          dependency_question_id: 0,
          readonly: selectedAnswertypes.is_readonly,
          hasError: false, // checks if the row has any error or not
        });
      }
    }
    setAnswerdata(newArr);
  };
  const decrementCount = (e, min) => {
    e.preventDefault();
    if (customCount > min) {
      const count = customCount - 1;
      setCustomCount(count);
      // to do update setAnswerdata()
      answersdata.pop();
      setAnswerdata(answersdata);
    }
  };
  const incrementCount = (e, max) => {
    e.preventDefault();
    if (customCount < max) {
      const count = customCount + 1;
      setCustomCount(count);

      let newAnstwerTemplate = {
        id: answersdata.length + 1,
        answer_text: "",
        additional_answer_text: "",
        scoring: 0,
        weightage: 0,
        dependency_question_id: 0,
        readonly: false,
        hasError: false, // checks if the row has any error or not
      };
      setAnswerdata([...answersdata, newAnstwerTemplate]);
    }
  };
  const getDisabledAnswertypes = (answerTypeList) => {
    let newAnswerTypelist = answerTypeList.filter((e) => e.options === "values");
    let disableList = [];
    newAnswerTypelist.forEach((element) => {
      disableList.push(element.values.split(","));
    });
    return [...new Set(disableList.flat())];
  };

  const onLoadCategories = async () => {
    try {
      const assessmentTypeList = await sendRequest(QuestionService.get().getAssessmentTypesURL());
      setAssessmenttypes(assessmentTypeList);

      const answerTypeList = await sendRequest(QuestionService.get().getAnswerTypeURL());
      setAnswertypes(answerTypeList);
      setDisabledAnswerTypes(getDisabledAnswertypes(answerTypeList));

      const responseTagsData = await sendRequest(QuestionService.get().getTagsURL());

      setTags(responseTagsData);
      //getquestion
      const editDependencyQuestionData = await sendRequest(
        QuestionService.get().getEditDependencyQuestionDataURL(dependencyQuestionId),
      );

      let selectedTags = [];
      selectedTags.length = 0;
      if (editDependencyQuestionData[2].length > 0) {
        selectedTags = editDependencyQuestionData[2].map((e) => {
          return e.id;
        });
      }
      settagsData(selectedTags);
      // seteditDependencyQuestionData(editDependencyQuestionData);

      //setCategoryId(editDependencyQuestionData[0][0].parent_id);
      setAnswerdata(editDependencyQuestionData[1]);

      setInitialFormValues(null);
      let initialValuesOfEditForm = {};
      initialValuesOfEditForm.id = editDependencyQuestionData[0][0].id;

      initialValuesOfEditForm.question_text = editDependencyQuestionData[0][0].question_text;
      initialValuesOfEditForm.addcomment = editDependencyQuestionData[0][0].is_add_comment;
      initialValuesOfEditForm.commentmandatory = editDependencyQuestionData[0][0].is_comment_mandatory;
      initialValuesOfEditForm.addfile = editDependencyQuestionData[0][0].is_add_file;
      initialValuesOfEditForm.not_applicable = editDependencyQuestionData[0][0].not_applicable;
      initialValuesOfEditForm.scoring_top = editDependencyQuestionData[0][0].score
        ? editDependencyQuestionData[0][0].score
        : "";
      initialValuesOfEditForm.fileuploadmandatory = editDependencyQuestionData[0][0].is_file_mandatory;
      initialValuesOfEditForm.assessmentid = editDependencyQuestionData[0][0].assessment_type_id;
      initialValuesOfEditForm.answertypeid = editDependencyQuestionData[0][0].answer_type_id;

      setInitialFormValues(initialValuesOfEditForm);
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    onLoadCategories();
  }, [sendRequest, dependencyQuestionId]);
  const onChange = (currentNode, selectedNodes) => {
    setSelectedTags(selectedNodes);
  };

  const getInitialValues = () => ({
    question_text: "",
    addcomment: false,
    commentmandatory: false,
    addfile: false,
    fileuploadmandatory: false,
    assessmentid: "",
    answertypeid: "",
    not_applicable: false,
    scoring_top: "",
  });

  // reset additional form fields
  const resetAdditionalFormFields = () => {
    try {
      // empty tags feild
      settagsData([]);
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  const handlerQuestionSubmit = async (values, { setSubmitting, setErrors, setStatus, resetForm }) => {
    setSave(true);
    const errorsInAnswerData = answersdata.filter((each) => each.hasError === true);
    if (errorsInAnswerData.length > 0) {
      displayToastMessage(
        "Please resolve all the errors present in answer boxes before saving the question",
        "error",
      );
    }
    const createdBy = JSON.parse(sessionStorage.getItem("user")).userid;
    if (isAddmode && errorsInAnswerData.length === 0) {
      let idObjects = answers.filter((item, index) => {
        if (index === answerId) return item;
      });
      let finalanswerdata = idObjects;
      if (!values.not_applicable) {
        finalanswerdata = idObjects.filter((e) => e.answer_text != "not applicable");
        // setAnswerdata(finalanswerdata)
      } else {
        let cntNA = idObjects.find((e) => e.answer_text === "not applicable");
        if (!cntNA) {
          let notApplicableArr = {
            id: answersdata.length,
            answer_text: "not applicable",
            additional_answer_text: "",
            scoring: 0,
            weightage: 0,
            dependency_question_id: 0,
            readonly: true,
            hasError: false,
          };
          finalanswerdata = idObjects.concat(notApplicableArr);
        }
      }
      const postinput = {
        id: dependencyQuestionId,
        parent_id: categoryId,
        question_text: values.question_text,
        assessment_type_id: values.assessmentid,
        answer_type_id: values.answertypeid,
        previous_answer_type_id: previous_ans_Id,
        is_add_comment: values.addcomment,
        is_comment_mandatory: values.commentmandatory,
        created_by: createdBy,
        is_add_file: values.addfile,
        is_file_mandatory: values.fileuploadmandatory,
        not_applicable: values.not_applicable,
        scoring_top: values.scoring_top,
        question_type_id: 1, // if question add through category then 1,if direct add 0
        selected_tags: tagsData.toString(),
        selected_answers: JSON.stringify(finalanswerdata),
        language_id: 1, // 1=>english
        root_question_id: questId,
        root_question_answer_id: idObjects[0].id, // newData(answers)
      };
      // if dpndcy ques id then edit(put api) else add(post api)

      let ar = [];
      const arr = JSON.parse(sessionStorage.getItem("depenent_quest_Id"));
      ar.concat(arr);
      try {
        arr.map((item, i) =>
          i === answerId ? Object.assign(item, { ["dependency_question"]: postinput }) : item,
        );
        sessionStorage.setItem("depenent_quest_Id", JSON.stringify(arr));
      } catch (err) {
        console.log("arr", err);
      }

      resetForm({});
      resetAdditionalFormFields();
      setAnswerdata([]);
    }
  };
  const onChangeTags =
    (formField) =>
    ({ target }) => {
      const data = target.value;
      settagsData(data);
    };

  return (
    <>
      <div className="row">
        <div className="col-md-12 p-0">
          <h5 className="headertest p-l-1rem">{mode === "add" && polyglot.t("create_question")}</h5>
          <div className="a-py-10 border-top a-border-light a-mt-10 category-divider"></div>
        </div>
      </div>
      <div className="row"></div>
      {answerTypes.length > 0 && initialFormValues && !isLoading && (
        <Formik
          initialValues={initialFormValues}
          onSubmit={handlerQuestionSubmit}
          validationSchema={questionnaireValidationSchema}
        >
          {(props) => {
            const { values, handleChange, handleBlur, handleSubmit, errors, touched, isSubmitting, dirty } =
              props;
            return (
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <TextArea
                      placeholder={`${polyglot.t("description.label")}*`}
                      value={values.question_text}
                      onChange={handleChange("question_text")}
                      className="Notes proj-notes-txt-area"
                      aria-label={polyglot.t("description.label")}
                      disabled={usemode && usemode == "readonly" ? true : false}
                    />
                    {/* MAX NUMBER OF CHARACTERS ALLOWED IS 2000 */}
                    {usemode && usemode == "readonly" ? null : <div>{values.question_text.length}/2000</div>}
                    {(errors.question_text && touched.question_text) || dirty ? (
                      <div className="error">
                        {errors.question_text ? polyglot.t(errors.question_text) : null}
                      </div>
                    ) : null}
                    <br />
                  </div>
                  <div className="noofcharacters"></div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <SelectCustom
                      onSelect={onChangeTags}
                      disabled={usemode && usemode == "readonly" ? true : false}
                      placeholder={
                        usemode && usemode == "readonly" ? polyglot.t("no_tags") : polyglot.t("select_tags")
                      }
                      multiple
                      showSelectAll
                      value={tagsData}
                      JSONArray={tagList}
                      optionKey="value"
                      optionLabel="label"
                      formLabel="tags"
                    />
                  </div>
                </div>
                <div className="row p-t-1point25rem">
                  <div className="col-md-7">
                    <div className="row input-field-height">
                      <div className="col-md-6 m-auto m-l-0">
                        <Checkbox
                          name="addcomment"
                          checked={values.addcomment}
                          disabled={usemode && usemode == "readonly" ? true : false}
                          onChange={handleChange("addcomment")}
                        >
                          Add comment field
                        </Checkbox>
                      </div>
                      {values.addcomment && (
                        <div className="col-md-6 m-auto m-l-0">
                          <Checkbox
                            name="commentmandatory"
                            checked={values.commentmandatory}
                            disabled={usemode && usemode == "readonly" ? true : false}
                            onChange={handleChange("commentmandatory")}
                          >
                            Comment mandatory
                          </Checkbox>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-5">
                    <Select
                      className=""
                      placeholder={`${polyglot.t("type_of_assessment")} *`}
                      disabled={usemode && usemode == "readonly" ? true : false}
                      value={values.assessmentid}
                      onSelect={(value) => handleChange("assessmentid")({ target: { value } })}
                    >
                      <SelectGroupTitle>{polyglot.t("assessment.type")}</SelectGroupTitle>
                      {assessmentTypes.map((assessmentType, index) => {
                        return (
                          <SelectOption key={index + 1} value={assessmentType.id}>
                            {assessmentType.name}
                          </SelectOption>
                        );
                      })}
                    </Select>
                    {touched.assessmentid ? (
                      <span className="error">{errors.assessmentid}</span>
                    ) : (
                      null && errors.assessmentid && <span className="error">{errors.assessmentid}</span>
                    )}
                  </div>
                </div>
                <div className="row p-t-1point25rem">
                  <div className="col-md-7">
                    <div className="row input-field-height">
                      <div className="col-md-6 m-auto m-l-0">
                        <Checkbox
                          name="addfile"
                          disabled={usemode && usemode == "readonly" ? true : false}
                          checked={values.addfile}
                          onChange={handleChange("addfile")}
                        >
                          Add file edit
                        </Checkbox>
                      </div>
                      {values.addfile && (
                        <div className="col-md-6 m-auto m-l-0">
                          <Checkbox
                            name="fileuploadmandatory"
                            disabled={usemode && usemode == "readonly" ? true : false}
                            checked={values.fileuploadmandatory}
                            onChange={handleChange("fileuploadmandatory")}
                          >
                            File upload mandatory
                          </Checkbox>
                        </div>
                      )}
                    </div>
                  </div>
                  <AnswerType
                    answerTypes={answerTypes}
                    customCount={customCount}
                    polyglot={polyglot}
                    touched={touched}
                    decrementCount={decrementCount}
                    errors={errors}
                    handleChange={handleChange}
                    incrementCount={incrementCount}
                    setAnsType={setAnsType}
                    values={values}
                    disabled={usemode === "readonly"}
                  />
                </div>
                <div className="row p-t-1point25rem">
                  <div className="col-md-7">
                    <div className="row input-field-height">
                      <div className="col-md-6 m-auto m-l-0">
                        <Checkbox
                          name="not_applicable"
                          disabled={usemode && usemode == "readonly" ? true : false}
                          checked={values.not_applicable}
                          onChange={handleChange("not_applicable")}
                        >
                          Not applicable
                        </Checkbox>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <Input
                      type="number"
                      inputBoxSize="sm"
                      placeholder="Scoring *"
                      disabled={usemode && usemode == "readonly" ? true : false}
                      onChange={handleChange("scoring_top")}
                      onKeyPress={(e) => {
                        const regex = /[0-9]/;
                        // PREVENT ANY THING IF IT IS NOT 0-9
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      value={values.scoring_top}
                    />
                    {errors.scoring_top && touched.scoring_top ? (
                      <div className="error">{polyglot.t(errors.scoring_top)}</div>
                    ) : null}
                  </div>
                </div>

                <div className="row p-t-1point25rem">
                  <div className="col-md-12">
                    {answersdata.length > 0 && (
                      <EditDependencyAnswerBlock
                        setAnswerdata={setAnswerdata}
                        answers={answersdata}
                        disableAnswerTypes={disabledAnswerTypes}
                        categoryid={categoryId}
                        usemode={usemode}
                        answerTypeId={values.answertypeid}
                      />
                    )}
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-10">
                    {usemode && usemode == "readonly" ? null : (
                      <ButtonPrimary
                        className="custome-pos float-right"
                        onClick={handleSubmit}
                        ariaLabel={polyglot.t("save_dependency")}
                        disabled={
                          isSubmitting ||
                          !dirty ||
                          !(Object.keys(errors).length === 0 && errors.constructor === Object)
                        }
                      >
                        {polyglot.t("save_dependency")}
                      </ButtonPrimary>
                    )}
                  </div>
                  <div className="col-md-2">
                    <ButtonPrimary
                      className="custome-pos float-right"
                      onClick={setmodal}
                      ariaLabel={polyglot.t("cancel")}
                    >
                      {polyglot.t("cancel")}
                    </ButtonPrimary>
                  </div>
                </div>
                <div className="col-md-12 edit-clientform-button p-0">
                  <div className="d-flex justify-content-between"></div>
                </div>
              </div>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default EditDependencyQuestion;
