import { Panel } from "appkit-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import LeftQuestionnaireTree from "./LeftQuestionnaireTree";
import RightQuestionnaireQuestionText from "./RightQuestionnaireQuestionText";
import RightQuestionnaireText from "./RightQuestionnaireText";
import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import { uploadIcon, downloadIcon } from "../../../Assets/Icons";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/GRC/loading-spin.svg";
import QuestionnaireService from "../../../Services/GRC/QuestionnaireService";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import EmptyPanel from "../Shared/EmptyPanel";
import ErrorPanel from "../Shared/ErrorPanel";
import "../../../Assets/Css/GRC/style.css";
import "./index.css";
const Questionnaire = () => {
  const polyglot = polyglotLoader();
  const history = useHistory();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const [loadCategory, setloadCategory] = useState([]);
  const [categoryType, setcategoryType] = useState(1);
  const [categoryId, setcategoryId] = useState(0);
  const [categoryPath, setcategoryPath] = useState("");
  const [latestId, setlatestId] = useState(0);
  const [setid, setsetid] = useState(1);
  const [loadComponent, setloadComponent] = useState("");
  const [loadFilterCategory, setloadFilterCategory] = useState([]);

  const goBack = () => {
    history.goBack();
  };
  const map = {};
  const roots = [];
  const getCategories = async () => {
    try {
      const responseData = await sendRequest(QuestionnaireService.get().getCategorieswithDepthURL());

      if (responseData.length > 0) {
        setcategoryPath(responseData[0].label);
        setcategoryId(responseData[0].value);
        setsetid(responseData[0].value);

        responseData.forEach((v, i) => {
          map[v.value] = i;
          responseData[i].children = [];
        });

        responseData.forEach((v) =>
          v.tagLebel !== 0 ? responseData[map[v.tagLebel]].children.push(v) : roots.push(v),
        );
        setloadCategory(roots);
        setloadFilterCategory(roots);
        //to do
        //setsetid();
      } else {
        setsetid();
      }
    } catch (err) {}
  };

  useEffect(() => {
    let c = true;
    if (c) {
      getCategories();
    }
    return () => {
      c = false;
    };
  }, [sendRequest]);

  const onChange = (currentNode, selectedNodes) => {
    setcategoryPath(currentNode.tagClassName);
    setcategoryId(currentNode.value);
    setcategoryType(currentNode._depth + 1);
  };
  function handleContainerLoad(e, v, controlType) {
    setloadComponent(controlType);
    setsetid(v);
    setcategoryId(v);
  }
  /**
   * passing input text as a search param.
   *
   * @param {string} e
   */
  const onSearch = (e) => {
    let serchValue = e.target.value.trim() || "";
    let searchResponse = applySearchFilter(loadCategory, serchValue);
    setloadFilterCategory(searchResponse);
  };

  /**
   * response row if matched
   *
   * @param {arrey} row
   * @returns if matched with search text
   */
  const searchKeys = (row) => {
    const searchableKeys = ["label"];
    const keyValues = searchableKeys.map((key) => row[key]);
    return keyValues.toString();
  };

  /**
   * return the result base on search text
   *
   * @param {arrey} listOfItems
   * @param {string} serchValue
   * @returns filter result
   */
  const applySearchFilter = (listOfItems, serchValue) => {
    let filteredItems = [...listOfItems];
    const trimmedsearchStr = serchValue;
    if (trimmedsearchStr.length > 0) {
      filteredItems = listOfItems.filter((r) => {
        return searchKeys(r).includes(trimmedsearchStr.toLowerCase());
      });
    }
    return filteredItems;
  };

  return (
    <div className="row m-0">
      <div className="col-md-3 col-sm-12 pl-0">
        <Panel className="panel top-spacing sr-questionaries">
          <div className="row pb-2 block">
            <div className="col-md-12">
              <div className="left-panel-search-icon top-margin">
                <input
                  className="left-panel-search-box"
                  placeholder={polyglot.t("search")}
                  aria-label={polyglot.t("search")}
                  onChange={onSearch}
                  autoComplete={"off"}
                />
              </div>
            </div>
          </div>
          <div className="row mt-1 pb-2 block">
            <div className="col-md-12 ml-n3">
              <h4 className="left-panel-heading ml-3 listOf">{polyglot.t("List of items")}</h4>
              <hr className="quest-ruler"></hr>
              {error && <ErrorPanel error={error} onClear={clearError} />}
              {isLoading && (
                <div>
                  <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
                </div>
              )}
              {!isLoading && loadCategory.length === 0 && <EmptyPanel massage={"No data found."} />}
              {!isLoading && loadCategory && (
                <>
                  <LeftQuestionnaireTree
                    data={loadFilterCategory}
                    handleChanged={handleContainerLoad}
                    setid={setid}
                    component={loadComponent}
                    onChange={onChange}
                  />
                </>
              )}
            </div>
          </div>
          <div className="row d-flex justify-content-center mt-1 pb-2 block">
            <div className="col-md-12"></div>
          </div>
        </Panel>
      </div>
      <div className="col-md-9 usertoadd-responsive p-0 mt-3 pr-3">
        <div className="row ml-0 mr-0 pb-3 pt-2">
          <div className="col-md-8 float-left p-0">
            <ButtonPrimaryWithIcon
              iconName={uploadIcon}
              alt={"upload"}
              ariaLabel={polyglot.t("upload")}
              className="mr-2"
            >
              {polyglot.t("upload")}
            </ButtonPrimaryWithIcon>
            <ButtonPrimaryWithIcon
              iconName={downloadIcon}
              alt={"download"}
              ariaLabel={polyglot.t("download.label")}
              className="mr-2"
            >
              {polyglot.t("download.label")}
            </ButtonPrimaryWithIcon>
          </div>
          {/* <div className="col-md-4 float-right p-0">
            <ButtonSecondaryWithIcon
              iconName={backCircle}
              alt={"back"}
              ariaLabel={polyglot.t("back")}
              onClick={goBack}
            >
              {polyglot.t("back")}
            </ButtonSecondaryWithIcon>
          </div> */}
        </div>

        <Panel className="panel p-0">
          {!isLoading && loadCategory.length === 0 && <EmptyPanel massage={"No data found."} />}
          {loadComponent === "ques"
            ? !isLoading &&
              loadCategory.length > 0 && <RightQuestionnaireQuestionText id={setid} setsetid={setsetid} />
            : !isLoading && loadCategory.length > 0 && <RightQuestionnaireText id={setid} />}
        </Panel>
      </div>
    </div>
  );
};
export default Questionnaire;
