import "../../../Assets/Css/QueenBase/Root/NavBar.css";
import "../../../Assets/Css/COMMON/NavBar.css";
import { Select } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import LinkText from "../../../Assets/Components/LinkText/LinkText";
import {
  CREATE_QUESTIONNAIRE_PATH,
  CREATE_GRC_HOME,
  VIEW_PROJECT_PATH,
  CREATE_CATEGORY_PATH,
  ASSESSMENT_CREATE_PATH,
  CREATE_ASSESSMENT_PATH,
  ASSESSMENT_VIEW_PATH,
  RECIPIENT_RESPONSE_PATH,
  ASSESSMENT_SELECTQUESTIONS_PATH,
  ASSESSMENT_PREVIEW_PATH,
  ASSESSMENT_RECIPIENT_SHARE_PATH,
} from "../../../Constants/Path.js";
import NavBarService from "../../../Services/QueenBase/NavBar.js/NavBarService";
import ProjectService from "../../../Services/QueenBase/Projects/ProjectService";
import SolutionService from "../../../Services/QueenBase/Solutions/SolutionService";
import {
  setProjectID_Of_solution_module,
  getProjectID_Of_solution_module,
  getSolutionID_Of_solution_module,
  setSolutionID_Of_solution_module,
} from "../../../Services/Shared/Handlers";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";

const { Option } = Select;

// THIS COMPONENT IS THE NAVIGATION BAR OF DF
function NavBar(props) {
  const [dblabel, setDbLabel] = useState([]);
  const polyglot = polyglotLoader();
  const projectId = getProjectID_Of_solution_module();
  const solutionID = getSolutionID_Of_solution_module();
  const [projectName, setProjectName] = useState("");
  const [expandHeader, setExpandHeader] = useState(false);
  const [solutionsList, setSolutionsList] = useState([]);
  const [selectedSolution, setSelectedSolution] = useState(solutionID);
  // GET NAVBAR DETAILS OF DF MODULE
  const getNavBar = async () => {
    // API TO GET TAB NAMES OF DF MODULE OF LOGGEDIN USER
    try {
      var res = await NavBarService.get().getSolutionNavBar("GRC");
      if (res.CODE) {
        setDbLabel(res.list);
      } else {
        displayToastMessage(res.MSG, "error");
      }
    } catch (err) {
      displayToastMessage(polyglot.t("Something went wrong!"), "error");
    }
  };

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    getNavBar();
  }, []);
  const navOptions = [
    {
      url: CREATE_GRC_HOME,
      label: polyglot.t("home"),
      name: "home",
      dbTabName: "Home",
    },
    {
      url: CREATE_QUESTIONNAIRE_PATH,
      label: polyglot.t("questionnaire.label"),
      name: "questionnaire",
      dbTabName: "Questionnaire",
    },
    {
      url: CREATE_ASSESSMENT_PATH,
      label: polyglot.t("client_assessment"),
      name: "client-assessment",
      dbTabName: "Client Assessment",
    },
    {
      url: RECIPIENT_RESPONSE_PATH,
      label: polyglot.t("recipient_response"),
      name: "recipient_response",
      dbTabName: "Client Response",
    },

    {
      url: "/grc/GRCAdmin/grcadmin",
      label: polyglot.t("assessment.admin"),
      name: "assessment-admin",
      dbTabName: "GRC Admin",
    },
  ];

  // GO BACK TO APPLICATION HOME
  const goBackToQueenbase = () => {
    props.history.push(VIEW_PROJECT_PATH);
  };

  // GET SOLUTIONS OF THE PROJECT
  const getSolutionsOfTheProject = async () => {
    try {
      const APIResponse = await ProjectService.get().getSolutionNameFromProjectID(projectId);
      if (APIResponse.CODE) {
        const { projectName: currentProjectName, solutions } = APIResponse.data;
        setSolutionsList(solutions);
        setProjectName(currentProjectName);
      } else {
        displayToastMessage(APIResponse.MSG, "error");
      }
    } catch (error) {
      displayToastMessage(error.message, "error");
    }
  };

  // GET SOLUTION ID BASED ON THE ROUTE PATH
  const getSolutionIDByRoutePath = async () => {
    try {
      const routePath = CREATE_GRC_HOME;
      const APIResponse = await SolutionService.get().getSolutionIDFromRoutePath(routePath);
      if (APIResponse.CODE) {
        setSelectedSolution(APIResponse.solutionID);
        setSolutionID_Of_solution_module(APIResponse.solutionID);
      } else {
        displayToastMessage(APIResponse.MSG, "error");
      }
    } catch (error) {
      displayToastMessage(error.message, "error");
    }
  };

  // GET PATH OF THE SELECTED SOLUTION
  const getPathOfSelectedSolution = (solutionId) => {
    try {
      const routePath = solutionsList.find((solution) => solution.id === solutionId).routePath;
      return routePath;
    } catch (error) {
      displayToastMessage(error.message, "error");
      return "";
    }
  };

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    // GO BACK TO APPLICATION HOME IF THERE IS NO PROJECT ID ASSIGNED
    if (!projectId) {
      goBackToQueenbase();
    } else {
      getSolutionsOfTheProject(); // fetch solutions by project id
      //getSolutionIDByRoutePath(); // fetch solution id based on the route path
      setProjectID_Of_solution_module(projectId);
    }
  }, []);

  // check if this solution is available present in the solution list
  useEffect(() => {
    // if solution is not present in the solution list, then navigate back to the queenbase
    if (solutionsList.length > 0) {
      const solutionIDs = solutionsList.map((each) => Number(each.id));
      if (solutionIDs.includes(Number(selectedSolution))) {
        // then this solution is part of the selected project
      } else {
        goBackToQueenbase();
      }
    }
  }, [solutionsList]);

  // REDIRECT TO SELECTED OPTION
  const redirection = (data) => {
    props.history.push({ pathname: data.url, state: { selectedProjectId: projectId } });
  };

  // CHECK THE URL TO GET THE CURRENT ACTIVE TAB
  const internalURLString = () => {
    // if url has /createcategory then highlight "questionnaire" tab
    if (props.location.pathname.includes(CREATE_CATEGORY_PATH)) {
      return "questionnaire";
    }
    // if url has CREATE_ASSESSMENT_PATH && ASSESSMENT_CREATE_PATH then highlight "client-assessment" tab
    if (
      props.location.pathname.includes(CREATE_ASSESSMENT_PATH) ||
      props.location.pathname.includes(ASSESSMENT_CREATE_PATH) ||
      props.location.pathname.includes(ASSESSMENT_VIEW_PATH) ||
      props.location.pathname.includes(ASSESSMENT_SELECTQUESTIONS_PATH) ||
      props.location.pathname.includes(ASSESSMENT_PREVIEW_PATH) ||
      props.location.pathname.includes(ASSESSMENT_RECIPIENT_SHARE_PATH)
    ) {
      return "client-assessment";
    }
    if (props.location.pathname.includes(RECIPIENT_RESPONSE_PATH)) {
      return "recipient_response";
    }
    return props.location.pathname;
  };

  // CHOOSE A SOLUTION ON NAVBAR
  const changeSolutionHandler = (value) => {
    const initialPathOfTheSelectedSolution = getPathOfSelectedSolution(value);
    if (initialPathOfTheSelectedSolution !== "") {
      setSolutionID_Of_solution_module(value);
      // navigate to the solution screen
      props.history.push({
        pathname: initialPathOfTheSelectedSolution,
        state: { selectedProjectId: projectId },
      });
    }
  };
  const expandHeaderFunc = () => {
    setExpandHeader((prev) => !prev);
  };

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light white-header nav" id="top-navbar">
        <div className="col-xs-12 col-sm-6 col-lg-2 select-box-navbar">
          <div className="">
            {solutionsList.length > 0 ? (
              <Select
                className="full-width"
                dropdownClassName="solution-redirect-select-box"
                value={Number(selectedSolution)}
                onChange={changeSolutionHandler}
                aria-label={polyglot.t("choose solution")}
                aria-activedescendant={null}
                aria-owns={null}
                aria-controls={null}
                aria-expanded={false}
                showSearch
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {solutionsList.map((each) => (
                  <Option key={each.id} value={each.id} aria-selected={selectedSolution === each.id}>
                    {each.name}
                  </Option>
                ))}
              </Select>
            ) : null}
          </div>
        </div>
        <p className="my-auto ml-4 font-weight-bold">{projectName}</p>
        <button
          className="navbar-toggler"
          type="button"
          onClick={expandHeaderFunc}
          data-bs-toggle="collapse"
          data-bs-target="#df-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse justify-content-center ${expandHeader && "show"}`}>
          <div className="d-flex justify-content-center topnav-centered">
            <ul className="navbar justify-content-between mb-0">
              {dblabel.map((data, index) => {
                let className = "link";
                const currentNav = navOptions.find((r) => r.dbTabName === data.TabName);
                if (internalURLString().includes(currentNav.name)) {
                  className += " current nav-link-current";
                }
                return (
                  <React.Fragment key={index + 1}>
                    <li key={index} className="nav-link">
                      <LinkText
                        className={`${className} no-underline`}
                        onClick={() => redirection(currentNav)}
                        ariaLabel={polyglot.t(data.TabName)}
                        href="javascript:;"
                      >
                        {polyglot.t(data.TabName)}
                      </LinkText>
                    </li>
                    <li className="nav-item"></li>
                  </React.Fragment>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
}
NavBar.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};
export default withRouter(NavBar);
