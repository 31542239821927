import axios from "axios";

import { expire_my_session, getUserIDFromSession, logout_on_intrusion } from "./Authenticate";
import CommonService from "./CommonService";
import ErrorHandler from "./ErrorHandler";
import displayToastMessage from "../../Components/QueenBase/Root/displayToastMessage";

const ErrorObj = (errorMsg) => {
  return {
    CODE: 0,
    MSG: errorMsg,
  };
};

export default class HttpClient {
  baseApiUrl = CommonService.get().getBaseApiUrl();
  authBaseApiUrl = CommonService.get().getAuthBaseApiUrl();
  dfBaseApiUrl = CommonService.get().getDFBaseApiUrl();
  giBaseApiUrl = CommonService.get().getGIBaseApiUrl();
  cssBaseApiUrl = CommonService.get().getCSSBaseApiUrl();
  riBaseApiUrl = CommonService.get().getRIBaseApiUrl();
  grcBaseApiUrl = CommonService.get().getGRCBaseApiUrl();

  generateHeaders(cancelRequestCallback) {
    const CancelToken = axios.CancelToken;
    return {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        project_id: sessionStorage.getItem("selectedProject") || "",
        loggedInUserId: getUserIDFromSession(),
      },
      cancelToken: new CancelToken(function executor(c) {
        // an executor function receives a cancel function as a parameter
        if (typeof cancelRequestCallback === "function") {
          cancelRequestCallback(c);
        }
      }),
    };
  }

  generateHeadersModules() {
    return {
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      project_id: sessionStorage.getItem("selectedProject"),
      loggedInUserId: getUserIDFromSession(),
    };
  }
  generateHeadersWithParams(head) {
    return {
      headers: {
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        loggedInUserId: getUserIDFromSession(),
        head,
      },
    };
  }
  //get api common function
  async callGetAPI(baseApi, params, cancelRequestCallback) {
    try {
      const CancelToken = axios.CancelToken;
      if (new Boolean(new URL(baseApi))) {
        const result = await axios({
          url: baseApi,
          headers: this.generateHeadersModules(),
          params: params,
          cancelToken: new CancelToken(function executor(c) {
            // an executor function receives a cancel function as a parameter
            if (typeof cancelRequestCallback === "function") {
              cancelRequestCallback(c);
            }
          }),
        });
        if (result.data) {
          if (result.data.isIntrusion) {
            logout_on_intrusion();
            return result.data;
          } else {
            return result.data;
          }
        } else {
          return {
            CODE: 0,
            MSG: "something went wrong",
          };
        }
      } else {
        throw new Error("Url is not valid");
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.message === "socket hang up") {
          window.location.reload();
        }
        if (error.response.data.code === "ECONNREFUSED") {
          return ErrorObj("Connection refused, kindly contact administrator");
        }
        if (error.response.data.code === "ECONNRESET") {
          return ErrorObj("Trying to connect, please try again");
        } else {
          return ErrorObj(error.response.data.message);
        }
      } else {
        return ErrorObj(error.message);
      }
    }
  }
  //post api common function
  async callPostAPI(baseApi, data, head) {
    try {
      const result = await axios.post(baseApi, data, {
        headers: {
          ...this.generateHeadersModules(),
          ...head,
        },
      });
      if (result.data) {
        if (result.data.isIntrusion) {
          logout_on_intrusion();
          return result.data;
        } else {
          return result.data;
        }
      } else {
        return {
          CODE: 0,
          MSG: "something went wrong",
        };
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.message === "socket hang up") {
          window.location.reload();
        }
        if (error.response.data.code === "ECONNREFUSED") {
          return ErrorObj("Connection refused, kindly contact administrator");
        }
        if (error.response.data.code === "ECONNRESET") {
          return ErrorObj("Trying to connect, please try again");
        } else {
          return ErrorObj(error.response.data.message);
        }
      } else {
        return ErrorObj(error.message);
      }
    }
  }
  async get(url, head, cancelRequestCallback) {
    const baseapi = this.baseApiUrl;
    const BaseApi = baseapi + url;
    return await this.callGetAPI(BaseApi, head, cancelRequestCallback);
  }
  async dfedGet(url, head) {
    try {
      const result = await axios(process.env.REACT_APP_SERVER_HOST + url, this.generateHeaders());
      if (result.data) {
        if (result.data.isIntrusion) {
          logout_on_intrusion();
          return result;
        } else {
          return result;
        }
      } else {
        return {
          data: {
            CODE: 0,
            MSG: "something went wrong",
          },
        };
      }
    } catch (error) {
      return Promise.reject(ErrorHandler.get().customErrorHandler(error.response));
    }
  }

  async post(url, data, head) {
    try {
      const result = await axios.post(this.baseApiUrl + url, data, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          loggedInUserId: getUserIDFromSession(),
          ...head,
        },
      });
      if (result.data) {
        if (result.data.isIntrusion) {
          logout_on_intrusion();
          return result.data;
        } else {
          return result.data;
        }
      } else {
        return {
          CODE: 0,
          MSG: "something went wrong",
        };
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.message === "socket hang up") {
          window.location.reload();
        }
        if (error.response.data.code === "ECONNREFUSED") {
          return ErrorObj("Connection refused, kindly contact administrator");
        }
        if (error.response.data.code === "ECONNRESET") {
          return ErrorObj("Trying to connect, please try again");
        } else {
          return ErrorObj(error.response.data.message);
        }
      } else {
        return ErrorObj(error.message);
      }
    }
  }
  async dfedPost(url, data, head, onUploadProgress) {
    try {
      const result = await axios.post(process.env.REACT_APP_SERVER_HOST + url, data, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          loggedInUserId: getUserIDFromSession(),
          ...head,
        },
        onUploadProgress,
      });
      if (result.data.isIntrusion) {
        logout_on_intrusion();
        return result;
      } else {
        return result;
      }
    } catch (error) {
      // const errorObj = JSON.parse(JSON.stringify(error))
      // check_api_error_response(errorObj.message)
      // return Promise.reject(error)
      return Promise.reject(ErrorHandler.get().customErrorHandler(error.response));
    }
  }

  async dfedPut(url, data, head) {
    try {
      const result = await axios.put(process.env.REACT_APP_SERVER_HOST + url, data, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          head,
          loggedInUserId: getUserIDFromSession(),
        },
      });
      if (result.data.isIntrusion) {
        logout_on_intrusion();
        return result;
      } else {
        return result;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async update(url, data) {
    const result = await axios.put(this.baseApiUrl + url, data, this.generateHeaders());
    return result;
  }

  async delete(url, data) {
    let result;
    if (!data) {
      result = await axios.delete(this.baseApiUrl + url, this.generateHeaders());
    } else {
      result = axios({
        method: "delete",
        url: this.baseApiUrl + url,
        data: data,
        headers: this.generateHeaders().headers,
      });
    }
    return result;
  }

  async postblob(url, data) {
    const result = await axios.post(
      this.baseApiUrl + url,
      data,
      { responseType: "blob" },
      this.generateHeaders(),
    );
    return result;
  }

  /*DF Function*/

  async dfget(url, params) {
    const BaseApi = this.dfBaseApiUrl + url;
    return await this.callGetAPI(BaseApi, params);
  }

  async dfpost(url, data, head) {
    const BaseApi = this.dfBaseApiUrl + url;
    return await this.callPostAPI(BaseApi, data, head);
  }
  async giget(url, params) {
    try {
      const result = await axios(this.giBaseApiUrl + url, {
        params: params,
        headers: this.generateHeadersModules(),
      });
      if (result.data) {
        if (result.data.isIntrusion) {
          logout_on_intrusion();
          return result.data;
        } else {
          return result.data;
        }
      } else {
        return {
          CODE: 0,
          MSG: "something went wrong",
        };
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.message === "socket hang up") {
          window.location.reload();
        }
        if (error.response.data.code === "ECONNREFUSED") {
          return ErrorObj("Connection refused, kindly contact administrator");
        }
        if (error.response.data.code === "ECONNRESET") {
          return ErrorObj("Trying to connect, please try again");
        } else {
          return ErrorObj(error.response.data.message);
        }
      } else {
        return ErrorObj(error.message);
      }
    }
  }
  /* CSS Function */
  async cssGet(url, params) {
    const BaseApi = this.cssBaseApiUrl + url;
    return await this.callGetAPI(BaseApi, params);
  }
  async gipost(url, data, head) {
    try {
      const result = await axios.post(this.giBaseApiUrl + url, data, {
        headers: {
          ...this.generateHeadersModules(),
          ...head,
        },
      });
      if (result.data) {
        if (result.data.isIntrusion) {
          logout_on_intrusion();
          return result.data;
        } else {
          return result.data;
        }
      } else {
        return {
          CODE: 0,
          MSG: "something went wrong",
        };
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.message === "socket hang up") {
          window.location.reload();
        }
        if (error.response.data.code === "ECONNREFUSED") {
          return ErrorObj("Connection refused, kindly contact administrator");
        }
        if (error.response.data.code === "ECONNRESET") {
          return ErrorObj("Trying to connect, please try again");
        } else {
          return ErrorObj(error.response.data.message);
        }
      } else {
        return ErrorObj(error.message);
      }
    }
  }

  async cssPost(url, data, head) {
    const BaseApi = this.cssBaseApiUrl + url;
    return await this.callPostAPI(BaseApi, data, head);
  }

  /* Auth Functions */

  async authGet(url, params) {
    try {
      const result = await axios(this.authBaseApiUrl + url, {
        params: params,
        ...this.generateHeaders(),
      });
      if (result.data) {
        if (result.data.isIntrusion) {
          logout_on_intrusion();
          return result.data;
        } else {
          return result.data;
        }
      } else {
        return {
          CODE: 0,
          MSG: "something went wrong",
        };
      }
    } catch (error) {
      return {
        CODE: 0,
        MSG: error.message,
      };
    }
  }

  /* RI Functions */
  async riGet(url, head) {
    try {
      const result = await axios(this.riBaseApiUrl + url, this.generateHeaders());
      if (result.data) {
        if (result.data.isIntrusion) {
          logout_on_intrusion();
          return result.data;
        } else {
          return result.data;
        }
      } else {
        return {
          CODE: 0,
          MSG: "something went wrong",
        };
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.message === "socket hang up") {
          window.location.reload();
        }
        if (error.response.data.message === "invalid token") {
          displayToastMessage("Sorry, invalid token", "error");
          expire_my_session();
        }
        if (error.response.data.code === "ECONNREFUSED") {
          return ErrorObj("Connection refused, kindly contact administrator");
        }
        if (error.response.data.code === "ECONNRESET") {
          return ErrorObj("Trying to connect, please try again");
        } else {
          return ErrorObj(error.response.data.message);
        }
      } else {
        return ErrorObj(error.message);
      }
    }
  }
  async ripost(url, data, head) {
    try {
      const result = await axios.post(this.riBaseApiUrl + url, data, {
        headers: this.generateHeadersModules(),
      });
      if (result.data) {
        if (result.data.isIntrusion) {
          logout_on_intrusion();
          return result.data;
        } else {
          return result.data;
        }
      } else {
        return {
          CODE: 0,
          MSG: "something went wrong",
        };
      }
    } catch (error) {
      if (error.response) {
        if (error.response.data.message === "socket hang up") {
          window.location.reload();
        }
        if (error.response.data.code === "ECONNREFUSED") {
          return ErrorObj("Connection refused, kindly contact administrator");
        }
        if (error.response.data.code === "ECONNRESET") {
          return ErrorObj("Trying to connect, please try again");
        } else {
          return ErrorObj(error.response.data.message);
        }
      } else {
        return ErrorObj(error.message);
      }
    }
  }

  async grcpost(url, data, head) {
    try {
      const result = await axios.post(
        this.grcBaseApiUrl + url,
        { selected_questions: data },
        this.generateHeaders(),
      );
      if (result.data) {
        return result.data;
      } else {
        return 0;
      }
    } catch (error) {
      return ErrorObj(error.message);
    }
  }
}
