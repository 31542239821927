import React, { useEffect, useState } from "react";

import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/GRC/loading-spin.svg";
import QuestionService from "../../../Services/GRC/QuestionService";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import EmptyPanel from "../Shared/EmptyPanel";
import ErrorPanel from "../Shared/ErrorPanel";
import { GetBreadcrumHTMLByString } from "../handlers";

import "./Pages/index.css";

const AssessmentViewAnswers = ({ id, setsetid }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const polyglot = polyglotLoader();
  const [editQuestionData, setEditQuestionData] = useState([]);
  const [categoryPath, setCategoryPath] = useState("");

  const getCategoriesAssessment = async () => {
    try {
      const editQuestionDataLoad = await sendRequest(QuestionService.get().getEditQuestionDataURL(id));
      setEditQuestionData(editQuestionDataLoad[0][0]);
      setCategoryPath(editQuestionDataLoad[0][0].breadcrumString);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getCategoriesAssessment();
  }, [sendRequest, id]);

  return (
    <div className="row p-0 mr-3">
      {error && <ErrorPanel error={error} onClear={clearError} />}
      {isLoading && (
        <div>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      )}
      {!editQuestionData && <EmptyPanel massage={"No data found."} />}
      {!isLoading && editQuestionData && (
        <div className="col-md-12 mx-3">
          {categoryPath && <GetBreadcrumHTMLByString breadcrumString={categoryPath} />}
          <hr></hr>
          <div className="custome-min-height">
            <p className="p-3">
              <span className="main_title_qb headingtitle">Question Text :</span>
              {editQuestionData.question_text}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssessmentViewAnswers;
