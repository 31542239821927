// CHECK IF STEP 1 IS VALID FOR POJECT FORM
export const isStep1Valid = (formObj) => {
  let isFormValid = true;
  if (formObj.invalidProjectName !== "") {
    isFormValid = false;
  }
  if (formObj.currentUserNotPresent !== "") {
    isFormValid = false;
  }
  return isFormValid;
};
