import ProtectedRoute from "./ProtectedRoute";
import React from "react";
import { Switch } from "react-router-dom";
import LoadingIndicator from "../Components/QueenBase/Root/LoadingIndicator";
import Loadable from "react-loadable";
import { DFED_HOME } from "../Constants/Path";
import HomeComponent from "../Components/DFED/Home";
import KeywordsList from "../Components/DFED/Keywords/KeywordsList";
import Admin from "../Components/DFED/Admin/Admin";
import ProfileList from "../Components/DFED/Profile/ProfileList";
import Profile from "../Components/DFED/Profile/Profile";
import EditProfile from "../Components/DFED/Profile/EditProfile";
import Processing from "../Components/DFED/Processing/Processing";
import AddAnalysis from "../Components/DFED/Analysis/AddAnalysis";
import Review from "../Components/DFED/Review/Review";
import ReviewSelectionScreen from "../Components/DFED/Review/ReviewSelectionScreen";
import ReviewSelection from "../Components/DFED/ReviewSelection/ReviewSelection";
import AddReviewSelection from "../Components/DFED/ReviewSelection/AddReviewSelection";
import Production from "../Components/DFED/Production/Production";
import Fastforward from "../Components/DFED/Fastforward/fastforward";
import DFEDSupport from "../Components/DFED/Support/Support";
import CustodianComponent from "../Components/DFED/Custodian/CustodianComponent";

// THIS CODE IS MODIFIED BY SHEKHAR DUE TO FIxed ISSUES WITH PROJECT SELECTION REFLECTION DATA

function ModuleRoute() {
  return (
    <div>
      <Switch>
        <ProtectedRoute
          path={DFED_HOME}
          // component={Loadable({
          // 	loader: () => import("../Components/DFED/Home"),
          // 	loading: LoadingIndicator
          // })}
          component={HomeComponent}
        />
        <ProtectedRoute path="/dfed/custodian" component={CustodianComponent} />
        <ProtectedRoute
          path="/dfed/support"
          // component={Loadable({
          // 	loader: () => import("../Components/DFED/Support/Support"),
          // 	loading: LoadingIndicator
          // })}
          component={DFEDSupport}
        />

        <ProtectedRoute
          path="/dfed/keywords"
          // component={Loadable({
          // 	loader: () => import("../Components/DFED/Keywords/KeywordsList"),
          // 	loading: LoadingIndicator
          // })}
          component={KeywordsList}
        />
        <ProtectedRoute
          path="/dfed/admin"
          // component={Loadable({
          // 	loader: () => import("../Components/DFED/Admin/Admin"),
          // 	loading: LoadingIndicator
          // })}
          component={Admin}
        />
        <ProtectedRoute
          path="/dfed/profileList"
          // component={Loadable({
          // 	loader: () => import("../Components/DFED/Profile/ProfileList"),
          // 	loading: LoadingIndicator
          // })}
          component={ProfileList}
        />
        <ProtectedRoute
          path="/dfed/profile"
          // component={Loadable({
          // 	loader: () => import("../Components/DFED/Profile/Profile"),
          // 	loading: LoadingIndicator
          // })}
          component={Profile}
        />
        <ProtectedRoute
          path="/dfed/EditProfile"
          // component={Loadable({
          // 	loader: () => import("../Components/DFED/Profile/EditProfile"),
          // 	loading: LoadingIndicator
          // })}
          component={EditProfile}
        />
        <ProtectedRoute
          exact
          path="/dfed/processing"
          // component={Loadable({
          // 	loader: () => import("../Components/DFED/Processing/Processing"),
          // 	loading: LoadingIndicator
          // })}
          component={Processing}
        />
        <ProtectedRoute
          exact
          path="/dfed/Analysis"
          // component={Loadable({
          // 	loader: () => import("../Components/DFED/Analysis/AddAnalysis"),
          // 	loading: LoadingIndicator
          // })}
          component={AddAnalysis}
        />
        <ProtectedRoute
          exact
          path="/dfed/reviewdetails"
          // component={Loadable({
          // 	loader: () => import("../Components/DFED/Review/Review"),
          // 	loading: LoadingIndicator
          // })}
          component={Review}
        />
        <ProtectedRoute exact path="/dfed/reviewselection" component={ReviewSelection} />
        <ProtectedRoute
          exact
          path="/dfed/addreviewselection"
          // component={Loadable({
          // 	loader: () => import("../Components/DFED/ReviewSelection/AddReviewSelection"),
          // 	loading: LoadingIndicator
          // })}
          component={AddReviewSelection}
        />
        <ProtectedRoute
          exact
          path="/dfed/Production"
          // component={Loadable({
          // 	loader: () => import("../Components/DFED/Production/Production"),
          // 	loading: LoadingIndicator
          // })}
          component={Production}
        />
        <ProtectedRoute
          exact
          path="/dfed/Fastforward"
          // component={Loadable({
          // 	loader: () => import("../Components/DFED/Fastforward/fastforward"),
          // 	loading: LoadingIndicator
          // })}
          component={Fastforward}
        />
        <ProtectedRoute
          component={Loadable({
            loader: () => import("../Assets/Components/NotFound/index"),
            loading: LoadingIndicator,
          })}
        />
      </Switch>
    </div>
  );
}

export default ModuleRoute;
