import React from "react";
import DragDropContainer from "./DragDropContainer";
import DropTarget from "./DropTarget";

// BOX ITEM COMPONENT
export default class BoxItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSaveAndClose: false,
      isWidgetsSaved: false,
      isEditMode: false,
    };
  }

  // COMPONENT WILL RENDER WHEN PROPS ARE RECEIVED
  componentWillReceiveProps(props) {
    this.setState({
      isSaveAndClose: props.isSaveAndClose,
      isWidgetsSaved: props.isWidgetsSaved,
      isEditMode: props.isEditMode,
    });
  }

  // HANDLE DROP
  handleDrop = (e) => {
    e.stopPropagation();
    this.props.swap(e.dragData.index, this.props.index, e.dragData);
    e.containerElem.style.visibility = "hidden";
  };

  // DELETE DRAGGED WIDGET
  deleteMe = () => {
    this.props.kill(this.props.uid);
  };

  // RENDER HTML FOR THIS COMPONENT
  render() {
    /*
        Notice how these are wrapped in a DragDropContainer (so you can drag them) AND
        in a DropTarget (enabling you to rearrange items in the box by dragging them on
        top of each other)
      */

    return (
      <span>
        <div
          className={
            (!this.state.isSaveAndClose && !this.state.isWidgetsSaved) || this.state.isEditMode
              ? this.props.divId == "queenbaseprogress"
                ? "queenbaseprogress_box_item_component"
                : "box_item_component"
              : this.props.divId == "queenbaseprogress"
              ? "qprogress"
              : "hide_sidebar_box_item_component"
          }
        >
          <DragDropContainer
            targetKey="boxItem"
            dragData={{ label: this.props.children, index: this.props.index }}
            onDrop={this.deleteMe}
            disappearDraggedElement={true}
            dragHandleClassName="grabber"
            sequence={this.props.sequence}
          >
            <DropTarget onHit={this.handleDrop} targetKey="boxItem">
              <div className="outer">
                <div className="item">{this.props.children}</div>
              </div>
            </DropTarget>
          </DragDropContainer>
        </div>
      </span>
    );
  }
}
