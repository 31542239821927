import React, { useState } from "react";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import Accordion from "../../../../Assets/Icons/up-chevron-outline.svg";
import chevron from "../../../../Assets/Icons/down-chevron-outline.svg";
import { Divider } from "appkit-react";
import Boxable from "./Boxable";
import "../../../../Assets/Css/QueenBase/Dashboard/DragThingsToBoxes.css";
import httpRequest from "../../../../Services/Shared/HttpClient";
import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonIcon from "../../../../Assets/Components/ButtonIcon/ButtonIcon";

// WIDGET CARD COMPONENT
const WidgetCard = (props) => {
  const { droppedItems } = props;
  const [isHide, setIsHide] = useState(true);
  const [isView, setIsView] = useState(false);

  var polyglot = polyglotLoader();
  // TOGGLE SIDE WIDGET PANEL
  const toggleHide = () => {
    setIsHide(!isHide);
    setIsView(!isView);
  };
  // IS WIDGET ALREADY DROPPED
  const isAlreadyDropped = (divId) => {
    let alreadyDropped = false;
    droppedItems
      .filter((obj) => obj !== undefined)
      .forEach((item) => {
        if (item.divId === divId && item.delete_widget != 1) {
          alreadyDropped = true;
        }
      });
    return alreadyDropped;
  };
  return (
    <>
      <div className="col-md-4 center-image">
        <ButtonIcon
          imgWidth={16}
          iconImg={isView ? Accordion : chevron}
          alt={"accordion"}
          onClick={toggleHide}
          ariaLabel={isView ? polyglot.t("expand") : polyglot.t("compress")}
          title={isView ? polyglot.t("Expand") : polyglot.t("Collapse")}
        />
      </div>

      {!isHide && (
        <>
          {props.type == "1" && props.queenDynamicWidgets && props.queenDynamicWidgets.length > 0 && (
            <div className="widgetlist">
              {props.queenDynamicWidgets.map((data, idx) => {
                if (!isAlreadyDropped(data.widget)) {
                  return (
                    <Boxable
                      key={idx}
                      targetKey="box"
                      divId={data.widget}
                      label={data.widgettitle}
                      title={data.widgettitle}
                      type={data.packagetype}
                      wid={data.ID}
                    />
                  );
                }
              })}
            </div>
          )}
          {props.type == 2 && props.ediscoveryDynamicWidgets && props.ediscoveryDynamicWidgets.length > 0 && (
            <div className="widgetlist">
              {props.ediscoveryDynamicWidgets.map((data, idx) => {
                if (!isAlreadyDropped(data.widget)) {
                  return (
                    <Boxable
                      key={idx}
                      targetKey="box"
                      divId={data.widget}
                      label={data.widgettitle}
                      title={data.widgettitle}
                      type={data.packagetype}
                      wid={data.ID}
                    />
                  );
                }
              })}
            </div>
          )}

          {props.type == 3 && props.dfDynamicWidgets && props.dfDynamicWidgets.length > 0 && (
            <div className="widgetlist">
              {props.dfDynamicWidgets.map((data, idx) => {
                if (!isAlreadyDropped(data.widget)) {
                  return (
                    <Boxable
                      key={idx}
                      targetKey="box"
                      divId={data.widget}
                      label={data.widgettitle}
                      title={data.widgettitle}
                      type={data.packagetype}
                      wid={data.ID}
                    />
                  );
                }
              })}
            </div>
          )}

          {props.type == 5 && props.giDynamicWidgets && props.giDynamicWidgets.length > 0 && (
            <div className="widgetlist">
              {props.giDynamicWidgets.map((data, idx) => {
                if (!isAlreadyDropped(data.widget)) {
                  return (
                    <Boxable
                      key={idx}
                      targetKey="box"
                      divId={data.widget}
                      label={data.widgettitle}
                      title={data.widgettitle}
                      type={data.packagetype}
                      wid={data.ID}
                    />
                  );
                }
              })}
            </div>
          )}
        </>
      )}
    </>
  );
};
// DRAG THINGS TO BOXES
export default class DragThingsToBoxes extends React.Component {
  constructor(props) {
    super(props);
    this.HttpRequest = new httpRequest();
    this.state = {
      items: [],
      isLoaded: false,
      jsonData: [],
      queenDynamicWidgets: [],
    };
  }

  // RUNS WHEN THIS COMPONENT WILL RECEIVE PROPS
  componentWillReceiveProps(props) {
    this.setState({
      queenDynamicWidgets: props.queenDynamicWidgets,
    });
  }

  // RUNS WHEN COMPONENT LOADS
  componentDidMount() {}

  // TOGGLE SAVE AND CLOSE
  toggleSaveAndClose = async () => {
    this.props.setSaveAndClose(!this.isClose);
    this.props.setEditMode(false);
    // API TO SAVE WIDGET DATA
    await this.HttpRequest.post("/saveWidgetData", { issave: 1 });
  };

  // DISPLAY DATA
  displayData = (data) => {
    return data.map((data) => {
      return (
        <div class="col-md-5">
          <li key={data.name}>{data.name}</li>
        </div>
      );
    });
  };

  // RENDERS THE HTML OF THE COMPONENT
  render() {
    var polyglot = polyglotLoader();
    return (
      <div className="sidebar-content">
        <div className="createsavebutton">
          <ButtonPrimary onClick={this.toggleSaveAndClose} ariaLabel={polyglot.t("Save & Close")}>
            {polyglot.t("Save & Close")}
          </ButtonPrimary>
        </div>
        <h5 className="package-title">{polyglot.t("Widgets")}</h5>
        <p className="cc-temp-text">{polyglot.t("Drag and drop widgets onto the dashboard")} </p>
        <div className="widgetheadings">
          <div className="row widgetcontent">
            <div className="col-md-8 paddingLeft-zero pr-0">
              <span className="widgettitle">{polyglot.t("Connected Digital Services")} </span>
              <span className="widgetcount">({this.props.queenCount})</span>
            </div>
            <WidgetCard
              type="1"
              droppedItems={this.props.droppedItems}
              queenDynamicWidgets={this.props.queenDynamicWidgets}
              ediscoveryDynamicWidgets={this.props.ediscoveryDynamicWidgets}
              dfDynamicWidgets={this.props.dfDynamicWidgets}
              giDynamicWidgets={this.props.giDynamicWidgets}
            />
          </div>
          <Divider className="divider solu-divider" />
          {this.props.solutionsData &&
            this.props.solutionsData.length > 0 &&
            this.props.solutionsData.map((solution, i) => {
              return (
                <span key={i}>
                  <div className="row widgetcontent">
                    <div className="col-md-8 paddingLeft-zero pr-0">
                      <span className="widgettitle">{solution.solutionName}</span>
                      <span className="widgetcount">
                        ({solution.solutionid == 1 && this.props.ediscoveryCount}
                        {solution.solutionid == 2 && this.props.dfCount}
                        {solution.solutionid == 4 && this.props.giCount}
                        {solution.solutionid == 3 && this.props.RiCount}
                        {solution.solutionid == 6 && this.props.SsCount}
                        {solution.solutionid == 7 && this.props.GrcCount})
                      </span>
                    </div>
                    <WidgetCard
                      type={
                        solution.solutionid == 1
                          ? "2"
                          : solution.solutionid == 2
                          ? "3"
                          : solution.solutionid == 4
                          ? "5"
                          : ""
                      }
                      droppedItems={this.props.droppedItems}
                      queenDynamicWidgets={this.props.queenDynamicWidgets}
                      ediscoveryDynamicWidgets={this.props.ediscoveryDynamicWidgets}
                      dfDynamicWidgets={this.props.dfDynamicWidgets}
                      giDynamicWidgets={this.props.giDynamicWidgets}
                    />
                  </div>
                  <Divider className="divider solu-divider" />
                </span>
              );
            })}

          {this.state.jsonData.length === 0 ? [] : this.displayData(this.state.jsonData)}
        </div>
      </div>
    );
  }
}
