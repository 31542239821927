// library imports//
import React from "react";
import {
  ButtonGroup,
  ButtonGroupItem,
  Accordions,
  AccordionItem,
  AccordionItemHeader,
  AccordionItemBody,
} from "appkit-react";

// file imports//
import "../../../App.css";
import "../../../Assets/Css/DFED/Custodians.css";
import "./custodianComponent.css";
import PersonalCustodianComponent from "./personalCustodian/PersonalCustodianComponent";
import GroupCustodianComponent from "./groupCustodian/GroupCustodianComponent";
import CustodianListComponent from "./custodianList/CustodianListComponent";
import MultipleCustodian from "./multipleCustodian/MultipleCustodianComponent";
import GlobalCustodian from "./globalCustodian/GlobalCustodianComponent";
import AddDatasets from "../DataSets/AddDataSets";
import Custodians from "./CustodainHome";
import OutlineButton from "../Shared/button/OutlineButton";
import CustodianService from "../../../Services/DFED/CustodianService";
import deleteImg from "../../../Assets/Images/DFED/icon-x.svg";
import BackButton from "../Shared/button/BackButton";
import TermsAndConditions from "../DataSets/TermsAndConditions";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import LoadingIndicator from "../../QueenBase/Root/LoadingIndicator";
import NoAccess from "../../../Assets/Components/NoAccess";
import { checkPermission } from "../../../utility/checkPermission";
import commonFunctionForCallService from "../../../utility/commonFunctionForCallService";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import * as module_Solution_Name from "../../../Constants/moduleName";
import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import { plusCircle, backCircle, closeIcon } from "../../../Assets/Icons";
import ButtonSecondaryWithIcon from "../../../Assets/Components/ButtonSecondary/ButtonSecondaryWithIcon";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import ButtonGroupCustom from "../../../Assets/Components/ButtonGroupCustom/ButtonGroupCustom";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";
import {
  getProjectID_Of_solution_module,
  getProjectName_Of_solution_module,
} from "../../../Services/Shared/Handlers";
// THIS CLASS IS RESPONSIBLE FOR HANDLING ALL CUSTODIAN RELATED OPERATION CREATING OF DIFFERENT TYPE OF CUSTODIAN AND UPDATE THAT CUSTODIAN

const initialFormTabs = [
  { value: "Person Custodian", name: "Person Custodian", disabled: false, crtTypeId: 1 },
  { value: "Group Custodian", name: "Group Custodian", disabled: false, crtTypeId: 2 },
  { value: "Multiple Custodian", name: "Multiple Custodian", disabled: false, crtTypeId: 3 },
  { value: "Global Custodian", name: "Global Custodian", disabled: false, crtTypeId: 4 },
];

class CustodianComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showCustodian: false,
      showPersonalCustodianForm: false,
      showGroupCustodianForm: false,
      caretClassName: "caretUp",
      custodianFormClass: "",
      custodianDropdownData: [
        { id: 1, value: "+ Create Person Custodian" },
        { id: 2, value: "+ Create Group Custodian" },
        { id: 3, value: "+ Upload CSV for multiple Custodian" },
      ],
      showCustodianListComponent: false,
      custodianRecords: null,
      custodianRecordsById: null,
      showImageDiv: true,
      selectedName: "",
      custodianTerms: false,
      preFillSelectWithDescription: null,
      custodianTypeID: null,
      personTabClass: "personTabClass",
      groupTabClass: "groupTabClass",
      CountryList: null,
      ClassificationList: null,
      positionList: null,
      showMultipleCustodianForm: false,
      selectedProjectId: getProjectID_Of_solution_module(),
      showGlobalCustodianForm: false,
      defaultHighlightClass: "custodianGroupButton ",
      custodianMetaDataForAccordian: [
        {
          custodianName: "Person",
          metaText: "The ownership of data can be linked to a single individual",
        },
        {
          custodianName: "Group",
          metaText:
            "The ownership of data can be linked to a group of individuals(example:project team,business unit,department,etc.)",
        },
        {
          custodianName: "Multiple",
          metaText: "Functionality to perform a mass upload of custodian entries via a xlsx - file",
        },
        {
          custodianName: "Global",
          metaText: "The ownership of data cannot be linked to a single or group of individuals",
        },
      ],
      showTermsAndCondition: false,
      checkBox: false,
      loadingData: true,
      selectedProjectName: getProjectName_Of_solution_module(),
      defaultCustodianTabClass: "custodianGroupButton",
      disableCustodianTab1: "",
      disableCustodianTab2: "",
      disableCustodianTab3: "",
      disableCustodianTab4: "",
      selectedAccordianTab: [0],
      isCustodianEditableAfterProcessed: false,
      rolePermission: {},
      isViewPermission: true,
      CRT_Type_ID_of_form: 0,
      formTabs: initialFormTabs,
      openConfirmBox: false,
      selectedTab: "Person Custodian",
    };
    this.onClick = this.onClick.bind(this);
    this.closeCustodian = this.closeCustodian.bind(this);
    this.showCustodianForm = this.showCustodianForm.bind(this);
    this.showCustodianList = this.showCustodianList.bind(this);
    this.editCustodian = this.editCustodian.bind(this);
    this.deleteCustodian = this.deleteCustodian.bind(this);
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
  }
  static contextType = DataContext;
  // LIFE CYCLE METHOD FOR GETTING ALL API SERVICE AND ALL DATA RELATEDTO CUSTODIAN============

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getAllRequiredDataRelatedCustodian(this.state.selectedProjectId);
    this.getApplicationRolesWithPermission();
  }
  /* FETCHING DATA FOR GLOBAL CUSTODIAN FOR SPECIFIC PROJECT,
   WITH THE HELP OF THIS WE CAN DECIDE IF WE CAN CREATE GLOBAL CUSTODIAN OR NOT FOR THAT PROJECT*/
  getGlobalCustodianDuplicateValiadtionHandler = () => {
    const custodianService = new CustodianService();
    custodianService.getGlobalCustodianDuplicat(this.state.selectedProjectId).then((result) => {
      if (result && result.data) {
        let response = result.data;
        if (response[0].count !== 0) {
          this.setState({ disableCustodianTab4: "disableTab", showGlobalTxt: true });
        } else {
          this.setState({ disableCustodianTab4: "", showGlobalTxt: false });
        }
      }
    });
  };

  //==================== COMMON FUNCTION FOR FETCH DATA IN BOTH LIFECYCLE METHOD WRITTEN BY SHEKHAR PLEASE DONT CHANGE ASK BEFORE CHANGE====================
  getAllRequiredDataRelatedCustodian(projectId) {
    const custodianService = new CustodianService();
    let selectedProjectId = projectId;
    let showImageDiv = false;
    custodianService
      .getCustodianData(selectedProjectId)
      .then((result) => {
        if (result && result.status === 200 && result.data) {
          if (result.data.length > 0) {
            showImageDiv = false;
          } else showImageDiv = true;
          this.setState(
            {
              custodianRecords: result.data,
              showCustodianListComponent: true,
              showImageDiv: showImageDiv,
              loadingData: false,
            },
            () => {},
          );
        }
      })
      .catch((err) => {
        if (err.status === 403) {
          this.setState({
            loadingData: false,
            isViewPermission: false,
          });
        }
      });
    const { getLanguageKeyAsPerAPI } = this.context;
    const lang = getLanguageKeyAsPerAPI();
    custodianService.getCountry(lang).then((countryResult) => {
      if (countryResult && countryResult.data) {
        this.setState(
          {
            CountryList: countryResult.data,
          },
          () => {},
        );
      }
    });
    // get Classification
    custodianService.getClassifications().then((classificationResult) => {
      if (classificationResult && classificationResult.data) {
        this.setState(
          {
            ClassificationList: classificationResult.data,
          },
          () => {},
        );
      }
    });
    // GETTING POSITON=============
    custodianService.getPositions().then((positionResult) => {
      if (positionResult && positionResult.data) {
        const positionsList = positionResult.data;
        this.setState(
          {
            positionList: positionsList,
          },
          () => {},
        );
      }
    });
  }
  // GETTING ALL THE ROLES PERMISSION REALATED TO THIS CLASS=================

  getApplicationRolesWithPermission = async () => {
    let selectedProjectId = this.state.selectedProjectId;
    let rolePermission = await commonFunctionForCallService.commonFunctionForCallService(
      module_Solution_Name.DFED_SOLUTION,
      selectedProjectId,
      module_Solution_Name.CUSTODIAN_MODULE,
    );
    if (rolePermission) {
      this.setState({
        rolePermission: rolePermission,
      });
    }
  };

  // ACCORDIAN OPEN AND CLOSE FUNCTION==================
  openAccordation = (e) => {
    if (this.state.selectedAccordianTab[0] === e) {
      this.setState({ selectedAccordianTab: [] });
    } else {
      this.setState({ selectedAccordianTab: [e] });
    }
  };
  // RENDERING OR NAVIGATE ASK FOR SUPPORT PAGE==============
  goToAskForSupport = () => {
    this.props.history.push("/dfed/support");
    localStorage.setItem("previousComponent", "Custodians");
  };

  // reset custodian form after clicking on add custodian
  resetCustodianForm = () => {
    this.setState({
      CRT_Type_ID_of_form: 0,
      selectedTab: "Person Custodian",
      // formTabs: initialFormTabs
    });

    // Based on Custodian Data, checking global custodian is avalible, if - disable or enable
    let datal = [...this.state.formTabs];
    let frmdata = this.state.custodianRecords.findIndex(
      (r) => parseInt(r.CRT_TYPE_ID) === this.state.formTabs[3].crtTypeId,
    );
    if (frmdata !== -1) {
      datal[3].disabled = true;
      this.setState({ formTabs: datal });
    }
  };

  // ONCLICK FUNCTION FOR ADD CUSTODIAN

  onClick() {
    var polyglot = polyglotLoader();
    if (checkPermission(this.state.rolePermission, module_Solution_Name.CUSTODIAN_MODULE, "Create")) {
      this.closeCustodian();
      this.setState({
        showTermsAndCondition: true,
        showCustodianListComponent: false,
        showImageDiv: false,
        showPersonalCustodianForm: true,
        defaultHighlightClass: "custodianGroupButton defaultHighlightClass",
        custodianRecordsById: null,
        isCustodianEditableAfterProcessed: false,
      });
      this.resetCustodianForm();
    } else {
      displayToastMessage(
        polyglot.t("Unauthorize access ! you are not allowed to perform this action"),
        "error",
      );
    }
  }

  //  HANDLING TERMS AND CONDITION BEFORE CREATING CUSTODIAN FUNCTIONALITY===================
  checkBoxHandler = (e) => {
    this.getGlobalCustodianDuplicateValiadtionHandler();
    this.setState({
      checkBox: !this.state.checkBox,
      showCustodian: true,
      showCustodianListComponent: false,
      showImageDiv: false,
      showPersonalCustodianForm: true,
      defaultHighlightClass: "custodianGroupButton t-l-b-r-4 b-l-b-r-4 defaultHighlightClass",
      showTermsAndCondition: false,
      disableCustodianTab1: "",
      disableCustodianTab2: "",
      disableCustodianTab3: "",
      disableCustodianTab4: "",
      isCustodianEditableAfterProcessed: false,
    });
  };
  // SHOWING CUSTODIAN FORM BASED ON DIFFERENT CONDITION

  showCustodianForm(value, id) {
    let selectedOption = value;
    if (selectedOption === "Person Custodian") {
      this.setState({
        showPersonalCustodianForm: true,
        showGroupCustodianForm: false,
        custodianFormClass: "custodian-transition-delay",
        custodianTypeID: 1,
        preFillSelectWithDescription: null,
        custodianRecordsById: null,
        showMultipleCustodianForm: false,
        showGlobalCustodianForm: false,
        defaultHighlightClass: "custodianGroupButton t-l-b-r-4 b-l-b-r-4",
        selectedAccordianTab: [0],
        selectedTab: value,
      });
    }
    if (selectedOption === "Group Custodian") {
      this.setState({
        showGroupCustodianForm: true,
        showPersonalCustodianForm: false,
        custodianFormClass: "custodian-transition-delay",
        custodianTypeID: 2,
        preFillSelectWithDescription: null,
        custodianRecordsById: null,
        showMultipleCustodianForm: false,
        showGlobalCustodianForm: false,
        defaultHighlightClass: "custodianGroupButton t-l-b-r-4 b-l-b-r-4",
        selectedAccordianTab: [1],
        selectedTab: value,
      });
    }
    if (selectedOption === "Multiple Custodian") {
      this.setState({
        showMultipleCustodianForm: true,
        showGroupCustodianForm: false,
        showPersonalCustodianForm: false,
        showGlobalCustodianForm: false,
        custodianFormClass: "custodian-transition-delay",
        custodianTypeID: 3,
        preFillSelectWithDescription: null,
        custodianRecordsById: null,
        defaultHighlightClass: "custodianGroupButton t-l-b-r-4 b-l-b-r-4",
        selectedAccordianTab: [2],
        selectedTab: value,
      });
    }
    if (selectedOption === "Global Custodian") {
      this.setState({
        showGlobalCustodianForm: true,
        showMultipleCustodianForm: false,
        showGroupCustodianForm: false,
        showPersonalCustodianForm: false,
        custodianFormClass: "custodian-transition-delay",
        custodianTypeID: 3,
        preFillSelectWithDescription: null,
        custodianRecordsById: null,
        defaultHighlightClass: "custodianGroupButton t-l-b-r-4 b-l-b-r-4",
        selectedAccordianTab: [3],
        selectedTab: value,
      });
    }
  }

  // CLOSE CUSTODIAN FORM FUNCTION, AFTER CLICKING ON THIS RENDER LIST OF CUSTODIAN PAGE=============
  closeCustodian() {
    if (this.state.showCustodian) {
      this.setState({
        showGlobalCustodianForm: false,
        showMultipleCustodianForm: false,
        showCustodian: false,
        showPersonalCustodianForm: false,
        showGroupCustodianForm: false,
        showCustodianListComponent: true,
        showImageDiv: this.state.custodianRecords && this.state.custodianRecords.length > 0 ? false : true,
        preFillSelectWithDescription: null,
        custodianRecordsById: null,
        checkBox: !this.state.checkBox,
        showTermsAndCondition: false,
        isCustodianEditableAfterProcessed: false,
        formTabs: initialFormTabs, // initialise the form
        selectedAccordianTab: [0],
      });
    } else {
      this.setState({
        showGlobalCustodianForm: false,
        showMultipleCustodianForm: false,
        showCustodian: false,
        showPersonalCustodianForm: false,
        showGroupCustodianForm: false,
        showCustodianListComponent: true,
        showImageDiv: this.state.custodianRecords && this.state.custodianRecords.length > 0 ? false : true,
        preFillSelectWithDescription: null,
        custodianRecordsById: null,
        checkBox: !this.state.checkBox,
        showTermsAndCondition: false,
        isCustodianEditableAfterProcessed: false,
        formTabs: initialFormTabs, // initialise the form tabs
        selectedAccordianTab: [0],
      });
    }
  }
  //  logic for show custodianList screen
  showCustodianList(custodianRecords) {
    this.setState({
      custodianRecords: custodianRecords,
      showCustodian: false,
      showCustodianListComponent: true,
      showPersonalCustodianForm: false,
      showGroupCustodianForm: false,
      showImageDiv: false,
      showMultipleCustodianForm: false,
      showGlobalCustodianForm: false,
      checkBox: !this.state.checkBox,
      showTermsAndCondition: false,
      formTabs: initialFormTabs, // initialise the form tabs
    });
  }
  // EDIT CUSTODIAN FUNCTIONALITY ===================

  editCustodian(CRT_id, crtTypeId, lockStatus) {
    const custodianService = new CustodianService();
    custodianService.getCustodianById(CRT_id).then((result) => {
      if (result && result.data && result.data.length > 0) {
        let classificationArr = this.state.ClassificationList;
        let CustodianFilterdClassification =
          classificationArr &&
          classificationArr.filter((el) => {
            if (result.data[0].CRT_CLASSIFICATION_ID) {
              return el.value === result.data[0].CRT_CLASSIFICATION_ID;
            } else {
              return null;
            }
          });
        let countryArr = this.state.CountryList;

        var CustodianFilterdCountry =
          countryArr &&
          countryArr.filter((el) => {
            if (result.data[0].CRT_COUNTRY_ID) {
              return Number(el.value) === Number(result.data[0].CRT_COUNTRY_ID);
            } else {
              return null;
            }
          });

        custodianService.getPositions().then((positionResult) => {
          if (positionResult && positionResult.data) {
            const positionsList = positionResult.data;
            this.setState(
              {
                positionList: positionsList,
              },
              () => {
                let positionArr = this.state.positionList;
                let CustodianFilterdPosition =
                  positionArr &&
                  positionArr.filter((el) => {
                    if (result.data[0].CRT_POSITION_ID) {
                      return el.PS_ID === result.data[0].CRT_POSITION_ID;
                    } else {
                      return null;
                    }
                  });
                let obj = {
                  classificationData: CustodianFilterdClassification,
                  positionData: CustodianFilterdPosition,
                  countryData: CustodianFilterdCountry,
                };

                this.setState(
                  {
                    custodianRecordsById: result.data,
                    showPersonalCustodianForm: parseInt(crtTypeId) === 1 ? true : false,
                    showGroupCustodianForm: parseInt(crtTypeId) === 2 ? true : false,
                    showMultipleCustodianForm: parseInt(crtTypeId) === 3 ? true : false,
                    showGlobalCustodianForm: parseInt(crtTypeId) === 4 ? true : false,
                    showCustodianListComponent: false,
                    showCustodian: true,
                    showImageDiv: false,
                    preFillSelectWithDescription: obj,
                    checkBox: !this.state.checkBox,
                    showTermsAndCondition: false,
                    disableCustodianTab1: parseInt(crtTypeId) === 1 ? "enablePerosnTab" : "disableTab",
                    disableCustodianTab2: parseInt(crtTypeId) === 2 ? "enablePerosnTab" : "disableTab",
                    disableCustodianTab3: parseInt(crtTypeId) === 3 ? "enablePerosnTab" : "disableTab",
                    disableCustodianTab4: parseInt(crtTypeId) === 4 ? "enablePerosnTab" : "disableTab",
                    isCustodianEditableAfterProcessed: lockStatus === 1 ? true : false,
                  },
                  () => {},
                );
              },
            );
          }
        });

        // assign crt type id to state
        this.setState({
          CRT_Type_ID_of_form: parseInt(crtTypeId),
          selectedTab: initialFormTabs.filter((e) => e.crtTypeId == crtTypeId)[0].value,
          selectedAccordianTab: [crtTypeId - 1],
        });
        this.disableOtherTabs(parseInt(crtTypeId));
      }
    });
  }
  // DELETE CUSTODIAN FUNCTIONALITY==================

  deleteCustodian(CRT_id) {
    let showImageDiv = false;
    const custodianService = new CustodianService();
    custodianService.deleteCustodianById(CRT_id, this.state.selectedProjectId).then((result) => {
      if (result && result.status === 200) {
        let selectedProjectId = this.state.selectedProjectId;
        if (selectedProjectId) {
          custodianService.getCustodianData(selectedProjectId).then((resultData) => {
            if (resultData && resultData.data.length > 0) showImageDiv = false;
            else showImageDiv = true;
            this.setState(
              {
                custodianRecords: resultData.data,
                showCustodianListComponent: true,
                showGroupCustodianForm: false,
                showImageDiv: showImageDiv,
                showMultipleCustodianForm: false,
                showGlobalCustodianForm: false,
              },
              () => {},
            );
          });
        }
      }
    });
  }
  // RENDER CUSTODIAN TERM AND CONDITION PAGE=========================

  goToCustodianTerms = (name) => {
    this.setState({ selectedName: name, custodianTerms: true, showCustodianListComponent: false });
  };
  // RENDER CUSTODIAN LIST PAGE===================
  goToCustodianList = () => {
    this.setState({ custodianTerms: false, showCustodianListComponent: true });
    window.location.reload();
  };

  // BACK BUTTON CLICK FUNCTIONALITY ON CUSTODIAN FORM, IT WILL RENDER AGAIN LIST PAGE.===============

  onBackButtonClick() {
    if (this.state.showCustodian) {
      this.setState({
        showCustodian: false,
        showPersonalCustodianForm: false,
        showGroupCustodianForm: false,
        showCustodianListComponent: true,
        showImageDiv: this.state.custodianRecords && this.state.custodianRecords.length > 0 ? false : true,
        preFillSelectWithDescription: null,
        custodianRecordsById: null,
        showMultipleCustodianForm: false,
        showGlobalCustodianForm: false,
        showTermsAndCondition: false,
        checkBox: !this.state.checkBox,
        isCustodianEditableAfterProcessed: false,
        formTabs: initialFormTabs, // initialise the form tabs
        selectedTab: "Person Custodian",
      });
    }
  }

  checkAndGoBack = () => {
    try {
      const { isFormOpen } = this.context;
      if (isFormOpen) {
        this.toggleConfirmBox();
      } else {
        this.onBackButtonClick();
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // enable or disable tabs
  disableOtherTabs = (crtTypeId) => {
    const formTabs = [];
    let formTab = {};
    this.state.formTabs.forEach((item) => {
      formTab = { ...item };
      if (item.crtTypeId !== crtTypeId) {
        formTab.disabled = true;
      }
      formTabs.push(formTab);
    });
    this.setState({
      formTabs,
    });
  };

  // get active tab
  getActiveTab = () => {
    let activeTabName;
    if (this.state.CRT_Type_ID_of_form === 0) {
      activeTabName = this.state.formTabs[0].name;
    }
    if (this.state.CRT_Type_ID_of_form > 0) {
      this.state.formTabs.forEach((item) => {
        if (item.crtTypeId === this.state.CRT_Type_ID_of_form) {
          // check if tab is disabled
          if (!item.disabled) {
            activeTabName = item.name;
          }
        }
      });
    }
    return activeTabName;
  };

  // Close and redirect form
  closeFormAndRedirect = () => {
    try {
      const { formGotClosed } = this.context;
      // close the popup
      this.toggleConfirmBox();
      // mark the form is closed on the store
      formGotClosed();
      // close custodian form
      this.closeCustodian();
      // go back functionality
      this.onBackButtonClick();
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  toggleConfirmBox = () => {
    this.setState({
      openConfirmBox: !this.state.openConfirmBox,
    });
  };

  // check and close the form
  checkAndCloseForm = () => {
    try {
      const { isFormOpen, formGotClosed } = this.context;
      if (isFormOpen) {
        // values of the form got changed - then show confirm box
        this.setState({ openConfirmBox: true });
      } else {
        // if form is not considered as open(values of the form are not changed yet), then close the form
        this.closeCustodian();
        formGotClosed();
        this.setState({ openConfirmBox: false });
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // RENDER FUNCTION FOR CUSTODIAN, IT WILL RENDER ALL FOUR TYPES OF CUSTODIAN FORM BASED ON CLICK EVENT==============
  render() {
    var polyglot = polyglotLoader();
    var custodianTermAndConditionComponent = (
      <TermsAndConditions checkBoxHandler={this.checkBoxHandler} closeCustodian={this.closeCustodian} />
    );
    var custodianHome = (
      <div>
        <div className="row customButton-row-div col-md-10 pl-0">
          <div className="col-lg-3 col-md-3">
            {!this.state.showCustodian &&
              this.state.loadingData === false &&
              checkPermission(this.state.rolePermission, module_Solution_Name.CUSTODIAN_MODULE, "Create") && (
                <ButtonPrimaryWithIcon
                  iconName={plusCircle}
                  onClick={this.onClick}
                  ariaLabel={polyglot.t("Add Custodian")}
                >
                  {polyglot.t("Add Custodian")}
                </ButtonPrimaryWithIcon>
              )}
            {this.state.showCustodian && (
              <div className="col-md-12 col-lg-12 p-l-0">
                <ButtonPrimaryWithIcon
                  onClick={this.checkAndGoBack}
                  iconName={backCircle}
                  ariaLabel={polyglot.t("Back")}
                >
                  {polyglot.t("Back")}
                </ButtonPrimaryWithIcon>
              </div>
            )}
          </div>
          <div className="col-lg-3 col-md-3 ">
            {!this.state.showCustodian &&
              !checkPermission(
                this.state.rolePermission,
                module_Solution_Name.PROCESSING_MODULE,
                "Create",
              ) && (
                <OutlineButton
                  name={polyglot.t("Create a Profile")}
                  onClick={() =>
                    this.props.history.push({
                      pathname: "/dfed/profileList",
                      state: { selectedProjectId: this.state.selectedProjectId },
                    })
                  }
                />
              )}
          </div>
          <div className="col-lg-4 col-md-4 " />
        </div>
        {this.state.showImageDiv && this.state.loadingData === false && <Custodians></Custodians>}
        {this.state.loadingData === true && <LoadingIndicator></LoadingIndicator>}
        {this.state.showCustodian && (
          <div className="row fade-in-top m-l-0 m-r-0">
            <div className="col-lg-7 col-md-7 dropDownBoxDiv divCustodianSelection DF-Cust-sel">
              <div className="row crossIcon cust-cross">
                <div className="">
                  <ButtonIcon
                    alt="close"
                    iconName={closeIcon}
                    onClick={this.checkAndCloseForm}
                    ariaLabel={polyglot.t("Close")}
                    title={polyglot.t("Close")}
                  />
                </div>
              </div>

              <div className="row m-l-0 m-r-0">
                <h1
                  className="custodian-header-text p-l-0 mb-2"
                  aria-label={polyglot.t("Choose custodian options")}
                >
                  {polyglot.t("Choose custodian options")}
                </h1>
              </div>
              <div className="row m-t-14">
                <div className="tabDiv choose-cust-tab">
                  <ButtonGroupCustom
                    value={this.state.selectedTab}
                    onChange={(e, v) => {
                      this.showCustodianForm(v);
                    }}
                    tabs={this.state.formTabs}
                  />
                </div>
                <div className="file-upload-header-description f-t-14 p-l-16 text-color mt-1">
                  {this.state.showGlobalTxt
                    ? "* " + polyglot.t("Global custodian can be added only once per project.")
                    : null}
                </div>

                {this.state.showPersonalCustodianForm && (
                  <div className="personalCustodianContent mt-4 fade-in-top">
                    <PersonalCustodianComponent
                      custodianTypeID={1}
                      preFillSelectWithDescription={this.state.preFillSelectWithDescription}
                      editableFormData={this.state.custodianRecordsById}
                      showCustodianList={this.showCustodianList.bind(this)}
                      positionList={this.state.positionList}
                      ClassificationList={this.state.ClassificationList}
                      CountryList={this.state.CountryList}
                      selectedProjectId={this.state.selectedProjectId}
                      selectedProjectName={this.state.selectedProjectName}
                      isCustodianEditableAfterProcessed={this.state.isCustodianEditableAfterProcessed}
                    />
                  </div>
                )}
                {this.state.showGroupCustodianForm && (
                  <div className="mt-4 fade-in-top">
                    <GroupCustodianComponent
                      custodianTypeID={2}
                      preFillSelectWithDescription={this.state.preFillSelectWithDescription}
                      editableFormData={this.state.custodianRecordsById}
                      showCustodianList={this.showCustodianList.bind(this)}
                      positionList={this.state.positionList}
                      ClassificationList={this.state.ClassificationList}
                      CountryList={this.state.CountryList}
                      selectedProjectId={this.state.selectedProjectId}
                      selectedProjectName={this.state.selectedProjectName}
                      isCustodianEditableAfterProcessed={this.state.isCustodianEditableAfterProcessed}
                    ></GroupCustodianComponent>
                  </div>
                )}
                {this.state.showMultipleCustodianForm && (
                  <div className=" m-t-10  p-l-16">
                    <MultipleCustodian
                      selectedProjectId={this.state.selectedProjectId}
                      isCustodianEditableAfterProcessed={this.state.isCustodianEditableAfterProcessed}
                      showCustodianList={this.showCustodianList.bind(this)}
                      selectedProjectName={this.state.selectedProjectName}
                    ></MultipleCustodian>
                  </div>
                )}
                {this.state.showGlobalCustodianForm && (
                  <div className=" m-t-10">
                    <GlobalCustodian
                      custodianTypeID={4}
                      preFillSelectWithDescription={this.state.preFillSelectWithDescription}
                      editableFormData={this.state.custodianRecordsById}
                      showCustodianList={this.showCustodianList.bind(this)}
                      positionList={this.state.positionList}
                      ClassificationList={this.state.ClassificationList}
                      CountryList={this.state.CountryList}
                      selectedProjectId={this.state.selectedProjectId}
                      selectedProjectName={this.state.selectedProjectName}
                      isCustodianEditableAfterProcessed={this.state.isCustodianEditableAfterProcessed}
                    ></GlobalCustodian>
                  </div>
                )}
              </div>
            </div>
            <div className="col-lg-1 col-md-1"></div>
            <div className="col-lg-4 col-md-4">
              <div>
                <p className="para-txt">{polyglot.t("Please fill the para_custodians")}</p>
              </div>
              <div className="cust-right-div-text-title mb-2">
                {" "}
                <b>{polyglot.t("Who are the Custodians?")}</b>
              </div>
              <div className="cust-right-div-text">
                {polyglot.t(
                  "A Custodian is a person (or group of persons like a department) having administrative control of the documents or electronic files that will be uploaded.",
                )}
              </div>
              <div className="row m-t-13 ml-0">
                <div className="col-md-12 col-sm-12 p-l-0 p-r-0">
                  <Accordions
                    onChange={this.openAccordation}
                    activeItem={this.state.selectedAccordianTab}
                    multiOpen={true}
                  >
                    {this.state.custodianMetaDataForAccordian.map((obj, index) => {
                      return (
                        <AccordionItem itemId={index} key={index}>
                          <AccordionItemHeader
                            title={<div className="acordian-head p-0">{polyglot.t(obj.custodianName)}</div>}
                          />
                          <AccordionItemBody className="profileRhtAccordinoTxt">
                            <div className="acordian-body"> {polyglot.t(obj.metaText)}</div>
                          </AccordionItemBody>
                        </AccordionItem>
                      );
                    })}
                  </Accordions>
                </div>
              </div>
            </div>
          </div>
        )}

        {this.state.custodianRecords &&
          this.state.custodianRecords.length > 0 &&
          this.state.showCustodianListComponent && (
            <CustodianListComponent
              addDataBtn={this.goToCustodianTerms}
              editCustodian={this.editCustodian.bind(this)}
              deleteCustodian={this.deleteCustodian.bind(this)}
              custodianRecords={this.state.custodianRecords}
              rolePermission={this.state.rolePermission}
            ></CustodianListComponent>
          )}
        {/* show confirm box if the form is open */}
        {this.state.openConfirmBox && (
          <ConfirmBoxExitForm
            show={this.state.openConfirmBox}
            heading={"Cancel update"}
            cancel={this.toggleConfirmBox}
            proceed={this.closeFormAndRedirect}
          />
        )}
      </div>
    );

    var returningObj;

    if (this.state.custodianTerms) {
      returningObj = (
        <AddDatasets
          goToCustodianList={this.goToCustodianList}
          addDataBtn={this.goToCustodianTerms}
          props={this.props}
          selectedName={this.state.selectedName}
          selectedProjectId={this.state.selectedProjectId}
        />
      );
    } else if (this.state.showTermsAndCondition) {
      returningObj = custodianTermAndConditionComponent;
    } else if (this.state.isViewPermission) {
      returningObj = custodianHome;
    } else {
      returningObj = <NoAccess />;
    }
    return returningObj;
  }
}

export default CustodianComponent;
