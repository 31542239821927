import React, { Component } from "react";
import "./Dropzone.css";
import fileImg from "../../../Assets/Icons/file-outline.svg";
import copyImg from "../../../Assets/Icons/duplicate-outline.svg";
import { polyglotLoader } from "../../../Services/Shared/Translate";

// DROPZONE TO UPLAOD FILES
class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = { hightlight: false };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }
  polyglot = polyglotLoader();

  // OPEN FILE DIALOG WHERE USER CAN SELECT A FILE FROM BROWSER
  openFileDialog() {
    if (this.props.disabled) return;
    this.fileInputRef.current.click();
  }

  // RUNS WHEN USER ADDS A FILE
  onFilesAdded(evt) {
    if (this.props.disabled) return;
    const files = evt.target.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array, evt);
    }
  }

  // RUNS ON DRAG OVER
  onDragOver(event) {
    event.preventDefault();
    if (this.props.disabed) return;
    this.setState({ hightlight: true });
  }

  // RUNS ON LEAVING THE DRAG
  onDragLeave(event) {
    this.setState({ hightlight: false });
  }

  // RUNS ON DROPPING THE FILE
  onDrop(event) {
    event.preventDefault();
    if (this.props.disabed) return;
    const files = event.dataTransfer.files;
    if (this.props.onFilesAdded) {
      const array = this.fileListToArray(files);
      this.props.onFilesAdded(array);
    }
    this.setState({ hightlight: false });
  }

  // CONVERT LIST OF FILES TO AN ARRAY
  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  render() {
    const polyglot = polyglotLoader();
    const { customDropZoneBoxStyle } = this.props;
    return (
      <button
        className={`dropZoneBox Dropzone cursor full-width ${this.state.hightlight ? "Highlight" : ""}`}
        onDragOver={this.onDragOver}
        onDragLeave={this.onDragLeave}
        onDrop={this.onDrop}
        accept="application/*, text/*"
        onClick={this.openFileDialog}
        style={!customDropZoneBoxStyle ? {} : customDropZoneBoxStyle}
      >
        <input ref={this.fileInputRef} className="FileInput" type="file" onChange={this.onFilesAdded} />
        <span className="font-size14">
          <img className="fileUploadTextRed" src={fileImg} alt="" />
          &nbsp;
          <span className="fileUploadTextRed">{polyglot.t("Select your files_dropzone")}</span>
          &nbsp;&nbsp;&nbsp;<span className="font-or">{polyglot.t("or_dropzone")}</span>
          &nbsp;&nbsp;&nbsp;
          <img className="fileUploadTextRed" src={copyImg} alt="" width={20} height={20} />
          &nbsp;
          <span className="drop-file">{polyglot.t("dragAndDrop_dropzone")}</span>
        </span>
      </button>
    );
  }
}

export default Dropzone;
