import React, { useEffect, useState } from "react";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import { GetPerformAssessmentBreadcrum } from "./PerformHandlers";
import RecipientService from "../../../Services/GRC/RecipientService";
import { useHttpRemoteClient } from "../../../Services/GRC/http-hook-remote";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { useHttpPostPerfClient } from "../../../Services/GRC/http-hook-perf-post";
import PerfromAssessmentService from "./Services/PerfromAssessmentService";
import { Formik } from "formik";
import { Input, TextArea, Tooltip } from "appkit-react";
import CustomDropZone from "../../../Assets/Components/CustomDropZone/CustomDropZone";
import { infoIcon, deleteFill } from "../../../Assets/Icons";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/GRC/loading-spin.svg";
import ErrorPanel from "../Shared/ErrorPanel";
import EmptyPanel from "../Shared/EmptyPanel";
import "./Pages/index.css";
import AssessmentSubmitionAlert from "./AssessmentSubmitionAlert";
import { downloadImg } from "../../../Assets/Icons";
import { FILE_EXTENSIONS } from "../../../Constants/regex";

/**
 *
 * @param {*} param0
 * @returns
 */
const RecipientQuesAnswers = ({ id, setsetid, setApiValue, param1 }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpRemoteClient();
  const { sendRequestPerfPost } = useHttpPostPerfClient();

  const [loadAnswer, setloadAnswer] = useState([]);
  const [breadcrumHeading, setBreadcrumHeading] = useState({});
  const [loadQuestion, setloadQuestion] = useState("");
  const [dependencyQuestionId, setDependencyQuestionId] = useState(0);

  const initialValuesOfForm = {
    comment: "",
  };

  const [initialFormValues, setInitialFormValues] = useState(initialValuesOfForm);
  const [isCommentMandatory, setIsCommentMandatory] = useState(false);
  const [isFileMandatory, setIsFileMadatory] = useState(false);
  const [directionValue, setDirectionValue] = useState(0);
  const [submittedAnswers, setSubmittedAnswers] = useState(0);
  const [freeTextValue, setFreeTextValue] = useState(0);
  const [answerType, setAnswerType] = useState();
  const [loadingDone, setLoadingDone] = useState(false);
  const [questionId, setQuestionId] = useState();
  const [assessmentToSubmit, setAssessmentToSubmit] = useState();
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [performAssessmentFiles, setPerformAssessmentFiles] = useState([]);

  const [isLastQuestion, setIsLastQuestion] = useState(0);
  const [queSequence, setQueSequence] = useState(0);
  const [allAnswered, setAllAnswered] = useState(0);

  const polyglot = polyglotLoader();
  const uniqueId = sessionStorage.getItem("grc_recipient_user_uid");

  const getCategoriesAssessment = async (enteredQuestionId) => {
    let validQuestionId = enteredQuestionId;
    try {
      setLoadingDone(false);

      if (isNaN(validQuestionId) && questionId) {
        validQuestionId = questionId;
      }
      setsetid(validQuestionId);
      setQuestionId(validQuestionId);

      const responseData = await sendRequest(
        RecipientService.get().getRecipientQuestionAnswersPerformURL(
          validQuestionId,
          uniqueId,
          dependencyQuestionId,
        ),
      );

      const recipientQuestionData = responseData[0][0];
      const {
        parent_id,
        answer_type_id,
        is_comment_mandatory,
        is_file_mandatory,
        is_last_index,
        q_sequence,
        all_answered,
      } = recipientQuestionData;
      const recipientAnswerData = responseData[1];
      const { comment_text } = recipientAnswerData[0];
      const recipientFiles = responseData[3];

      const { breadcrum } = await sendRequest(RecipientService.get().getTextDataPerfAssessmentURL(parent_id));
      setBreadcrumHeading(
        `${breadcrum} > Question `, // use this after Questio --> ${questionIndex}
      );

      setAnswerType(answer_type_id);
      setIsCommentMandatory(is_comment_mandatory);
      setIsFileMadatory(is_file_mandatory);

      const initialValuesOfEditForm = {};
      initialValuesOfEditForm.comment = comment_text;
      setInitialFormValues(initialValuesOfEditForm);

      const previousAssessmentFiles = recipientFiles.map((fileName) => ({
        preview: "",
        raw: "",
        name: fileName,
        isValidFile: true,
        isFileUploaded: true,
      }));

      setPerformAssessmentFiles(previousAssessmentFiles);

      if (responseData.length > 0) {
        setloadQuestion(recipientQuestionData);
        setloadAnswer(recipientAnswerData);
        setIsLastQuestion(is_last_index);
        setQueSequence(q_sequence);
        setAllAnswered(all_answered);
      }
      const idsAnswer = recipientAnswerData.find((t) => parseInt(t.question_answer) === 1);

      if (typeof idsAnswer !== "undefined" && idsAnswer !== null) {
        setSubmittedAnswers(idsAnswer.id);
      } else {
        setSubmittedAnswers(0);
      }

      setLoadingDone(true);
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * save/update data of the form
   */
  const handleAnswerSubmit = async (values) => {
    const formData = new FormData();

    const recipientAnswerData = {
      uniqueId,
      questionId,
      answerId: submittedAnswers,
      commentText: values.comment ? values.comment : "",
      recipientFiles: performAssessmentFiles,
      isSkip: 0,
      dependencyQuestionId,
      direction: directionValue, //0, // 1 => back direction| 0 => next direction
    };

    performAssessmentFiles.forEach((file) => {
      if (file?.raw !== "") {
        formData.append("files[]", file.raw);
      }
    });
    formData.append("data", JSON.stringify(recipientAnswerData));

    const [[{ question_id, IS_DQ }]] = await sendRequestPerfPost(
      PerfromAssessmentService.get().putRecipientQuestionAnswersURL(),
      formData,
      { "Content-Type": "multipart/form-data" },
    );
    setApiValue(false);

    if (parseInt(IS_DQ) === 1) {
      setDependencyQuestionId(question_id);
      setsetid(questionId);
      setQuestionId(questionId);
    } else {
      setDependencyQuestionId(0);
      setsetid(parseInt(question_id));
      setQuestionId(parseInt(question_id));
    }

    if (loadAnswer.length > 0) {
      if (answerType === 5 && !freeTextValue)
        return displayToastMessage(polyglot.t("assessment.error.answer"), "warning");
      if (answerType !== 5 && !submittedAnswers)
        return displayToastMessage(polyglot.t("assessment.error.answer"), "warning");
    }

    const commentIsMissing = !recipientAnswerData.commentText && isCommentMandatory;
    const fileIsMissing = !recipientAnswerData.recipientFiles.length && isFileMandatory;
    const commentAndFileAreMissing = commentIsMissing && fileIsMissing;

    if (commentAndFileAreMissing)
      return displayToastMessage(polyglot.t("assessment.error.commentAndFile"), "warning");
    if (commentIsMissing) return displayToastMessage(polyglot.t("assessment.error.comment"), "warning");
    if (fileIsMissing) return displayToastMessage(polyglot.t("assessment.error.file"), "warning");
  };
  useEffect(() => {
    if (param1) {
      setDependencyQuestionId(0);
    }
    getCategoriesAssessment(id);
  }, [sendRequest, id, dependencyQuestionId]);

  /**
   * this event fires when user selects an option.
   * @param {*event} e
   * @param {*data} i
   */
  const handleSetAnswer = (e, i) => {
    e.preventDefault();
    setSubmittedAnswers(i.id);
  };
  const handlerFreeTextAnswer = (e) => {
    setFreeTextValue(e);
  };

  const handleFile = (e) => {
    const uploadedFiles = e;
    const verifiedFiles = [...performAssessmentFiles];

    uploadedFiles.forEach((file) => {
      const { name: fileName, size: fileSize } = file;
      let isValidFile = true;

      if (fileName && !fileName.match(FILE_EXTENSIONS)) {
        isValidFile = false;
        displayToastMessage(polyglot.t("assessment.file.extensionsError"), "error");
      }

      const fileSizeInMB = fileSize / 1028 / 1028;
      if (fileSizeInMB > 25) {
        isValidFile = false;
        displayToastMessage(polyglot.t("assessment.file.sizeLimitError"), "error");
      }

      const isFileAlreadyExist = verifiedFiles.some((file) => file.name === fileName);

      if (!isFileAlreadyExist) {
        verifiedFiles.push({
          preview: URL.createObjectURL(file),
          raw: file,
          name: fileName,
          isValidFile,
          isFileUploaded: false,
        });
      } else {
        displayToastMessage(polyglot.t("fileAlreadyUploaded"), "info");
      }
    });

    const validFileList = verifiedFiles.filter((file) => file.isValidFile);
    setPerformAssessmentFiles(validFileList);
  };
  const TooltipTextbox = (item) => {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#000",
                  overflowWwrap: "break-word",
                }}
                className="tooptipdata"
              >
                {item}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  /**
   * checks the file name is present or not and then
   * @returns displays the file name after it is uploaded in the custom drop zone can.
   * also provides a way to delete the file.
   */
  const DisplayUploadedFiles = () => (
    <div className="col-md-12">
      {performAssessmentFiles.map(({ name, preview, isFileUploaded }) => (
        <div key={preview} className="row file-upload-box p-0 mb-2">
          <span style={{ marginRight: "10px" }}>
            <b>{name}</b>
          </span>
          {isFileUploaded && (
            <ButtonIcon
              iconImg={downloadImg}
              alt="download"
              onClick={() => downloadTemplate(name)}
              title={polyglot.t("download.file")}
              ariaLabel={polyglot.t("download.file")}
            />
          )}
          <ButtonIcon
            iconName={deleteFill}
            alt="delete"
            onClick={() => removeFileHandler(name)}
            title={polyglot.t("delete.file")}
            ariaLabel={polyglot.t("delete.file")}
          />
        </div>
      ))}
    </div>
  );

  // THIS FUNCTION IS FOR DOWNLOAD THE FILE //
  async function downloadTemplate(fileName) {
    const fileToDownload = { fileName, uniqueId, questionId };

    const response = await sendRequestPerfPost(
      PerfromAssessmentService.get().downloadAssessmentFileURL(),
      JSON.stringify(fileToDownload),
      {
        "Content-Type": "application/json",
      },
    );

    if (response) {
      const buf = Buffer.from(response.buffer);
      const url = window.URL.createObjectURL(new Blob([buf]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", response.fileName); //or any other extension
      document.body.appendChild(link);
      link.click();
    }
  }

  /**
   * clears the state for file
   */
  const removeFileHandler = (uniqueFileName) => {
    const uploadedFiles = [...performAssessmentFiles];
    const updatedFileList = uploadedFiles.filter(({ name }) => name !== uniqueFileName);
    setPerformAssessmentFiles(updatedFileList);
  };

  const handleCloserConfirmBox = () => {
    try {
      // close delete confirm box
      setOpenConfirmBox(false);
      setAssessmentToSubmit();
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  /**
   * posts the final assessment submission to the api
   * @param {uniqueId}
   * @returns: posts the uniqueId to the api
   */
  const handlePostSubmit = async () => {
    try {
      // open delete confirm box
      setOpenConfirmBox(true);
      setAssessmentToSubmit(uniqueId);
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  return (
    <>
      {error && <ErrorPanel error={error} onClear={clearError} />}
      {isLoading && (
        <div>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      )}
      {loadAnswer.length === 0 && <EmptyPanel massage={"No data found."} />}
      {!isLoading && loadAnswer.length > 0 && initialFormValues && (
        <>
          {/* header section load breadcame */}
          <div className="row mt-3">
            <div className="col-md-12 p-0 border-bottom">
              <GetPerformAssessmentBreadcrum breadcrumString={breadcrumHeading} />
            </div>
          </div>

          {/* Question text area here */}
          <div className="row mt-3 padding_1rem">
            {loadQuestion && (
              <div className="col-md-12 p-0 border-bottom">
                <p className="p-0 m-0 font-weight-bold">{polyglot.t("question")}:</p>

                <label className="question-txt-area">{loadQuestion.question_text}</label>
              </div>
            )}
          </div>

          {/* form section here */}
          <div className="row mt-3 padding_1rem">
            <div className="col-md-12">
              {loadingDone && initialFormValues && (
                <Formik initialValues={initialFormValues} onSubmit={handleAnswerSubmit}>
                  {function Render(props) {
                    const { values, handleChange, handleSubmit, errors, touched } = props;
                    return (
                      <>
                        <div className="row mt-4">
                          <div className="col-md-12 p-0">{polyglot.t("assessment.answerHeading")}:</div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-9">
                            {loadAnswer.map((item, i) => (
                              <div className="row pt-2" key={i}>
                                <div className="col-md-11 w-98 p-0">
                                  {/*  Answer type 5 for Free textbox */}
                                  {answerType === 5 ? (
                                    <Input
                                      className="btn btn-out-as text-left  w-100"
                                      readonly={false}
                                      type={"number"}
                                      id={item.id}
                                      onChange={(e) => {
                                        handlerFreeTextAnswer(e, item);
                                      }}
                                      value={freeTextValue}
                                    />
                                  ) : (
                                    <>
                                      <label
                                        id={item.id}
                                        className={`${
                                          submittedAnswers === item.id
                                            ? "default-selected-ans"
                                            : "not-selected-ans"
                                        } btn btn-out-as text-left  w-100`}
                                        onClick={(value) => {
                                          handleSetAnswer(value, item);
                                        }}
                                        dangerouslySetInnerHTML={{ __html: item.answer_text }}
                                      ></label>
                                    </>
                                  )}
                                </div>
                                <div className="col-md-1 w-2 p-0 info-bttn">
                                  {item.additional_answer_text.length > 0 && (
                                    <Tooltip
                                      content={TooltipTextbox(item.additional_answer_text)}
                                      placement="top"
                                      className="a-md-pop"
                                    >
                                      <ButtonIcon iconName={infoIcon} />
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="col-md-3">
                            {loadQuestion.is_add_comment && (
                              <>
                                <TextArea
                                  placeholder={`${polyglot.t("comment")} ${isCommentMandatory ? "*" : ""}`}
                                  value={values.comment}
                                  onChange={handleChange("comment")}
                                  className="custome-question-txt-area"
                                  aria-label={polyglot.t("comment")}
                                />
                                <div className="noofcharacters p-0">{values.comment.length}/2000</div>
                                {errors.comment && touched.comment ? (
                                  <div className="error">{errors.comment}</div>
                                ) : null}
                              </>
                            )}
                          </div>
                        </div>
                        {loadQuestion.is_add_file && (
                          <div className="row mt-4">
                            <div className="col-md-12 p-0">
                              <div className="upload-profile-photo-wrapper dummy-drop">
                                <CustomDropZone
                                  onFilesAdded={handleFile}
                                  customDropZoneBoxStyle={{
                                    width: "100%",
                                    margin: "0 0 10px 0",
                                    padding: "30px",
                                  }}
                                  accept={
                                    "application/pdf,application/vnd.ms-excel,text/plain,application/msword"
                                  }
                                />
                                <DisplayUploadedFiles />
                              </div>
                              <p className="ImageContent-two">
                                <b>
                                  {(isFileMandatory ? "* " : "") + polyglot.t("assessment.file.sizeLimit")}
                                </b>
                                <br />
                                <b>
                                  {(isFileMandatory ? "* " : "") + polyglot.t("assessment.file.extensions")}
                                </b>
                              </p>
                            </div>
                          </div>
                        )}
                        <div className="row mt-3">
                          <div className="col-md-4 p-0">
                            {Boolean(queSequence) && (
                              <ButtonPrimary
                                className="mr-1"
                                onClick={(e) => {
                                  handleSubmit(e);
                                  setDirectionValue(1);
                                }}
                                name="back"
                                ariaLabel={polyglot.t("back")}
                              >
                                {polyglot.t("back")}
                              </ButtonPrimary>
                            )}
                          </div>
                          <div className="col-md-4 p-0">
                            <Tooltip
                              content={TooltipTextbox(polyglot.t("assessment.submit.error"))}
                              placement="top"
                              className={allAnswered ? "hide" : ""}
                            >
                              <ButtonPrimary
                                className="ml-1"
                                onClick={handlePostSubmit}
                                ariaLabel={polyglot.t("submit.label")}
                                disabled={!allAnswered}
                              >
                                {polyglot.t("submit.label")}
                              </ButtonPrimary>
                            </Tooltip>
                          </div>
                          <div className="col-md-4 float-right">
                            <ButtonPrimary
                              onClick={(e) => {
                                handleSubmit(e);
                                document.getElementById("content").scrollTop = 0;
                                setDirectionValue(0);
                              }}
                              ariaLabel={polyglot.t("next")}
                              name="next"
                            >
                              {isLastQuestion ? polyglot.t("save") : polyglot.t("next")}
                            </ButtonPrimary>
                          </div>
                        </div>
                      </>
                    );
                  }}
                </Formik>
              )}
            </div>
          </div>
        </>
      )}
      <AssessmentSubmitionAlert
        Item={assessmentToSubmit}
        show={openConfirmBox}
        close={handleCloserConfirmBox}
      />
    </>
  );
};

export default RecipientQuesAnswers;
