import $ from "jquery";
import React, { useEffect, useState } from "react";
import { withRouter, useHistory } from "react-router-dom";

import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";

import "antd/dist/antd.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { PER_PAGE_COUNT } from "../../../Constants/Constant";
import { ASSESSMENT_VIEW_PATH } from "../../../Constants/Path";
import "./index.css";
$.DataTable = require("datatables.net-dt");

const PER_PAGE = PER_PAGE_COUNT;

const AssessmentListView = ({ grid_data = [] }) => {
  const polyglot = polyglotLoader();
  const history = useHistory();
  const [dataForTableView, setDataForTableView] = useState([]);
  const CONST_DESC_SHORT_LENGTH = 16;

  const drawDataTable = () => {
    const data = dataForTableView;
    const table = $("#processGridTable").DataTable({
      lengthMenu: [5, 10, 15, 20],
      sDom: "lfrtp",
      Language: {
        emptyTable: `${polyglot.t("no_data_available")}`,
        sLengthMenu: `${polyglot.t("show")} <select class="tabFocus">
                    <option value="5">5</option>
                    <option value="10">10</option>
                    <option value="15">15</option>
                    <option value="20">20</option>
                    <option value="-1">${polyglot.t("all")}</option>
                    </select> ${polyglot.t("entries")}
                    `,
        sSearch: `${polyglot.t("search")}`,
      },
      columns: [
        { bVisible: false, searchable: false },
        {
          title: polyglot.t("Id"),
          width: "10%",
          mRender: (data, type, row) => {
            return "" + row[0];
          },
        },
        {
          title: polyglot.t("assessment.name"),
          width: "25%",
          mRender: (data, type, row) => {
            return "" + row[1];
          },
        },
        {
          title: polyglot.t("industry"),
          width: "22%",
          orderable: false,
          mRender: (data, type, row) => {
            return "" + row[2].length > CONST_DESC_SHORT_LENGTH
              ? row[2].substring(0, CONST_DESC_SHORT_LENGTH) + "..."
              : row[2];
          },
        },

        {
          title: polyglot.t("description.label"),
          width: "35%",
          class: "td-width",
        },

        {
          title: polyglot.t("actions"),
          orderable: false,
          className: "details-control",
          data: null,
          width: "1%",
          mRender: (data, type, row) => {
            return (
              ' </button>  <button aria-label="' +
              polyglot.t("view") +
              '" class="appkiticon icon-view-fill mr-5 analysisGridViewIcon editer_view" id="view_datasets_' +
              row[0] +
              '">  </button>'
            );
          },
        },
        // {
        //   className: "details-control",
        //   orderable: false,
        //   data: null,
        //   searchable: false,
        //   defaultContent: "",
        //   width: "80"
        // },
      ],
      order: [[0, "desc"]],
      data: data,
      destroy: true,
      lengthType: "simple",
      language: {
        searchPlaceholder: polyglot.t("search"),
        search: "",
        info: "_PAGE_ of _PAGES_",
        paginate: {
          firt: "<<",
          previous: '<i_ngcontent-c10="" class="appkiticon icon-left-chevron-fill"> </i>',
          next: '<i_ngcontent-c10="" class="appkiticon icon-right-chevron-fill"> </i>',
          last: ">>",
        },
      },
      info: false,
      stateSave: false,
      drawCallback: function () {
        if (data.length <= 0) {
          $(".dataTables_paginate").hide();
        } else {
          $(".dataTables_paginate").show();
        }
      }, // OVER : drawback function
    });
    // Add event listener for opening and closing details
    $("#processGridTable tbody").on("click", "td.details-control", function () {
      let tr = $(this).closest("tr");
      let row = table.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass("shown");
      } else {
        // Open this row
        row.child(format(row.data())).show();
        tr.addClass("shown");
      }
    });

    $(document).on("click", "[id*='view_datasets_']", function () {
      const idData = this.id.split("_");
      const body = {
        id: idData[2],
      };
      history.push({ pathname: `${ASSESSMENT_VIEW_PATH}/${body.id}` });
    });
  };
  const prepareTableData = () => {
    const data = grid_data.map((obj) => Object.values(obj));
    setDataForTableView(data);
  };

  const format = (d) => {
    if (d !== undefined) {
      const assessmentDescription = d[4];
      const assessmentName = d[1];
      return `<div className='row'><p><strong>${assessmentName} Description:</strong> ${assessmentDescription} </p></div>`;
    } else {
      return "<div>Loading...</div>";
    }
    // `d` is the original data object for the row
  };
  useEffect(() => {
    prepareTableData();
  }, [grid_data]);
  useEffect(() => {
    drawDataTable();
  }, [dataForTableView]);
  return (
    <div className="processGridContainer">
      <div
        className="col-md-12 testassmnt DF-process-admin dataTables_length searchData"
        id="datatable-keytable"
      >
        <table
          id="processGridTable"
          className="display table-header-focus processGrid sortingalignment"
          width="100%"
        />
      </div>
    </div>
  );
};
export default withRouter(AssessmentListView);
