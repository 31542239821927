import React from "react";

import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/QueenBase/Root/loading-spin.svg";

import "../../../Assets/Css/GRC/Questionnaire.css";
import { Panel } from "appkit-react";
import $ from "jquery";

import "antd/dist/antd.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { PER_PAGE_COUNT } from "../../../Constants/Constant";

import { withRouter } from "react-router-dom";

import { useHttpClient } from "../../../Services/GRC/http-hook";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";

$.DataTable = require("datatables.net-dt");

const PER_PAGE = PER_PAGE_COUNT;

const AssessmentGridView = ({ grid_data }, props) => {
  //loadCategory
  const polyglot = polyglotLoader();

  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  // const [loadCategory, setloadCategory] = useState([]);
  /* const handleCreateQuestionnaire = () => {
    props.history.push({
      pathname: path.ASSESSMENT_CREATE_PATH,
    });
  }; */

  // DISPLAY LOADING SPINNER
  const displayLoadingSpinner = () => {
    return (
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-6" style={{ textAlign: "center" }}>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
        <div className="col-md-4"></div>
      </div>
    );
  };

  const displayQuestionnaireList = () => {
    return (
      <div className="container">
        <div className="mt-3">
          <div className="m-l-1 m-b-18">
            <div className="row">
              <div className="col-lg-6 col-md-6 d-flex justify-content-between pl-0 pr-0">
                {/* <ButtonPrimaryWithIcon
                  iconName={plusCircle}
                  onClick={handleCreateQuestionnaire}
                  ariaLabel={polyglot.t("create")}
                >
                  {polyglot.t("create")}
                </ButtonPrimaryWithIcon> */}
              </div>
              <div className="col-lg-6 col-md-6 text-right"></div>
            </div>
          </div>
        </div>

        {/* <div className="row customButton-row-div m-l-0 m-r-0">
          <div className="col-lg-4 col-md-4 pl-0">
            <h1 className="main_title_qb ">
              {polyglot.t("list_of_assessments")}
            </h1>
          </div>
          <div className="col-lg-8 col-md-8 m-r-2">
            <div className="row float-right">
              <ul className="nav list-inline justify-content-end">
                <li className="list-inline-item pt-0 pr-1 pb-0 pl-0">
                  <TableView
                    className="cursor-pointer"
                    onClick={navigateToListView}
                  />
                </li>
                <li className="list-inline-item pt-0 pr-1 pb-0 pl-0">
                  <GridView
                    className="cursor-pointer"
                    onClick={navigateToGridView}
                  />
                </li>
                <li className="list-inline-item pt-0 pr-1 pb-0 pl-0">
                  <input
                    className="grc-SearchBar search-input"
                    placeholder={polyglot.t("search")}
                    aria-label={polyglot.t("search")}
                  />
                </li>
              </ul>
            </div>
          </div>
        </div> */}
        <div className="row mt-4 text-center">
          {grid_data &&
            grid_data.map((item) => {
              return (
                <div className="col-md-4 mb-3">
                  <Panel className="card-body-df df-respo grid-contents">
                    <div className="row m-l-0 m-r-0 mb-2">
                      <div className="col-sm-8 col-md-8 text-left q-heading">{item.name}</div>
                      <div className="col-sm-4 col-md-4 p-t-9 text-right grid-edit-icons"></div>
                    </div>
                    <hr className="label-divider mb-3" />
                    <div className="row mb-3 m-l-0 m-r-0">
                      <div className="col-sm-4 col-md-4 text-left q-title">Name:</div>
                      <div className="col-sm-8 col-md-8 p-t-9 q-description">{item.name}</div>
                    </div>
                    <div className="row mb-4 m-l-0 m-r-0">
                      <div className="col-sm-4 col-md-4 text-left q-title">Description:</div>
                      <div className="col-sm-8 col-md-8 p-t-9 q-description">{item.description}</div>
                    </div>
                    <div className="row mb-4 m-l-0 m-r-0">
                      <div className="col-sm-4 col-md-4 text-left q-title">Industry:</div>
                      <div className="col-sm-8 col-md-8 p-t-9 q-description">{item.industry}</div>
                    </div>
                  </Panel>
                </div>
              );
            })}
        </div>
      </div>
    );
  };

  return (
    <div>
      {/* {loading && displayLoadingSpinner()} */}
      {displayQuestionnaireList()}
    </div>
  );
};

export default withRouter(AssessmentGridView);
