import { Modal } from "antd";
import PropTypes from "prop-types";
import React from "react";

import "./index.css";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import ButtonSecondary from "../ButtonSecondary/ButtonSecondary";

const CustomModal = (props) => {
  const polyglot = polyglotLoader();
  const { show, onCancel, title, size, showCloseButton, hideFooter, showContinueButton, runContinue, style } =
    props;
  const Footer = props.Footer ? props.Footer : [];

  const getWidth = () => {
    switch (size) {
      case "x-lg": {
        return "85%";
      }
      case "lg": {
        return 1200;
      }
      case "md": {
        return 800;
      }
      case "sm": {
        return 630; // observed it to be the size used in dfed screens
      }
      default: {
        return 520;
      }
    }
  };
  const getCancelButton = () => {
    if (showCloseButton) {
      return (
        <ButtonSecondary onClick={onCancel} ariaLabel={polyglot.t("Close")}>
          {polyglot.t("Close")}
        </ButtonSecondary>
      );
    }
    if (showContinueButton) {
      return (
        <ButtonSecondary onClick={runContinue} ariaLabel={polyglot.t("Continue")}>
          {polyglot.t("Continue")}
        </ButtonSecondary>
      );
    }
    return (
      <ButtonSecondary onClick={onCancel} ariaLabel={polyglot.t("Cancel")}>
        {polyglot.t("Cancel")}
      </ButtonSecondary>
    );
  };
  const getFooter = () => {
    if (hideFooter) {
      return [];
    }
    return [
      <div className="flex-fill custom-modal-footer" key={1}>
        {getCancelButton()}
        {Footer.map((element) => element)}
      </div>,
    ];
  };

  return show ? (
    <Modal
      visible={show}
      onCancel={onCancel}
      title={title}
      footer={getFooter()}
      bodyStyle={{
        height: "auto",
        display: "inline-table",
        width: "100%",
        ...style,
      }}
      maskClosable={false}
      width={props?.style?.width ?? getWidth()}
    >
      <div className="modal-details-body">{props.children}</div>
    </Modal>
  ) : null;
};

CustomModal.propTypes = {
  show: PropTypes.bool,
  onCancel: PropTypes.func,
  title: PropTypes.string,
  Footer: PropTypes.array,
  size: PropTypes.string,
  showCloseButton: PropTypes.bool,
  hideFooter: PropTypes.bool,
  showContinueButton: PropTypes.bool,
  runContinue: PropTypes.func,
};
CustomModal.defaultProps = {
  show: false,
  title: "",
  Footer: [],
  size: "",
  showCloseButton: false,
  hideFooter: false,
  showContinueButton: false,
  runContinue: () => {},
};

export default CustomModal;
