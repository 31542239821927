import jwtUtil from "../../../Constants/Jwt";
import CommonService from "../../../Services/Shared/CommonService";
import HttpClient from "../../Shared/HttpClient";

class UserService {
  constructor() {
    this.client = new HttpClient();
  }

  // GET USER DETAILS WHO GOT LOGGEDIN FOR THE FIRST TIME
  async getFirstTimeUserInfo() {
    const result = await this.client.get("/getFirstTimeUserInfo");
    return result;
  }
  // API TO GET COUNT OF MULTIPLE USER SCREEN PARAMETERS
  async getUserCount() {
    const result = await this.client.get("/getUserCount");
    return result;
  }

  // API TO GET SUFFIX
  async getPrefixNames(language) {
    const result = await this.client.get(`/fetchPrefixNames?language=${language}`);
    return result;
  }
  // API TO UPDATE THE NON IDAM USER DETAILS WHO GOT LOGGEDIN FOR THE FIRST TIME
  async updateFirstTimeUserInfo(data) {
    const result = await this.client.post("/updateFirstTimeUserInfo", data, {
      "Content-Type": "multipart/form-data",
    });
    if (result.CODE) {
      var image = CommonService.get().getBufferImageInBlob(result.image);
      const returnData = { ...result };
      const Image = await new Promise((resolve) => {
        var reader = new FileReader();
        reader.readAsDataURL(image);
        reader.onloadend = function () {
          var base64data = reader.result;
          resolve(base64data);
        };
      });
      returnData.userImage = Image;
      return returnData;
    } else {
      return result;
    }
  }

  // GET LIST OF POSITIONS AND COUNTRIES
  async getPositionCountry() {
    const result = await this.client.get("/getPositionCountry");
    return result;
  }

  // API TO FETCH USER IMAGES
  async getUserList_Images(cancelRequestCallback) {
    const head = {};
    const result = await this.client.get("/getUserList_images", head, cancelRequestCallback);
    return result;
  }

  // API TO FETCH USERS LIST WITHOUT IMAGES
  async getUserList_withoutImages() {
    const result = await this.client.get("/getUserList_withoutImages");
    return result;
  }

  // API TO FETCH PWC USERS LIST WITHOUT IMAGES
  async getPwCUserList_withoutImages() {
    const result = await this.client.get("/getPwCUserList_withoutImages");
    return result;
  }

  async getListOfAvailableAdmins() {
    const result = await this.client.get("/getListOfAvailableAdmins");
    return result;
  }

  async addClientAdmins(adminsData) {
    const result = await this.client.post("/addClientAdmins", adminsData);
    return result;
  }

  async getUserList() {
    const result = await this.client.get("/getUserList");
    return result;
  }
  // API TO DELETE USER
  async deleteUser(userData) {
    const result = await this.client.post("/deleteUser", userData);
    return result;
  }

  // API TO ACTIVATE USER
  async activateUser(userData) {
    const result = await this.client.post("/activateUser", userData);
    return result;
  }
  // API TO UPDATE USER
  async updateUser(userData) {
    var encrypteData = jwtUtil.encrypt(userData);
    const result = await this.client.post("/updateUser", encrypteData);
    return result;
  }

  // API TO CREATE USER
  async createUser(userData) {
    var encrypteData = jwtUtil.encrypt(userData);
    const result = await this.client.post("/createUser", encrypteData);
    return result;
  }

  // API TO BUY TEMPORARY USERS
  async buyTempUsers(tempData) {
    var encrypteData = jwtUtil.encrypt(tempData);
    const result = await this.client.post("/buyTempUsers", encrypteData);
    return result;
  }
  // API TO GET LOGGEDIN USER PROFILE DETAILS
  async getUserProfile() {
    const result = await this.client.get("/getUserProfile");
    return result;
  }
  /*
		API TO UPDATE USER PROFILE
		PASSING: FORM OBJECT
	*/
  async userProfileUpdate(userData) {
    const result = await this.client.post("/userProfileUpdate", userData, {
      "Content-Type": "multipart/form-data",
    });
    if (result.CODE) {
      let imageStr = "";
      if (result.image) {
        const imageBlob = CommonService.get().getBufferImageInBlob(result.image);
        imageStr = URL.createObjectURL(imageBlob);
      }
      const userObj = { ...result };
      delete userObj.jwt;
      sessionStorage.setItem("user", JSON.stringify(userObj));
      result.profileImage = imageStr;
      return result;
    } else {
      return result;
    }
  }
}

// Enable Singleton behavior on this class
UserService.__instance = null;
UserService.get = () => {
  if (!UserService.__instance) {
    UserService.__instance = new UserService();
  }
  return UserService.__instance;
};

export default UserService;
