import React from "react";
import {} from "appkit-react";
import { Select, Panel, SelectOption, Tooltip } from "appkit-react";
import moment from "moment";
import { Pagination } from "antd";

import SearchBar from "../../Shared/components/FormSearchBar";
import userImg from "../../../../Assets/Icons/person-outline.svg";
import editImg from "../../../../Assets/Icons/pencil-outline.svg";
import groupImg from "../../../../Assets/Icons/person-outline.svg";
import eyeIcon from "../../../../Assets/Icons/view-outline.svg";
import { deleteFill } from "../../../../Assets/Icons";
import CustomModal from "../../../../Assets/Components/Modal";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import { DataContext } from "../../../../Services/Shared/Store.js";
import { checkPermission } from "../../../../utility/checkPermission";
import * as module_Solution_Name from "../../../../Constants/moduleName";
import ButtonIcon from "../../../../Assets/Components/ButtonIcon/ButtonIcon";
import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";

import "./custodianList.css";

const TooltipText = (text) => {
  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#000",
                textTransform: "capitalize",
              }}
            >
              {text}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

// CUSTODIAN LIST CLASS FOR DISPLAYING LIST OF CREATED CUSTODIAN FOR PARTICULAR PROJECT==================

class CustodianList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      search_text: null,
      custodianRecords: [],
      backupCustodianRecords: [],
      filterOptions: [
        {
          filter_value: "CRT_ALIAS",
          filter_label_Dropdown: "Alias",
          filter_label: "alias",
          key_id: 5,
        },
        {
          filter_value: "CC_CLASSIFICATION_TYPE",
          filter_label_Dropdown: "Classification",
          filter_label: "classification",
          key_id: 1,
        },
        {
          filter_value: "C_NAME",
          filter_label_Dropdown: "Country_noStar",
          filter_label: "country",
          key_id: 3,
        },
        {
          filter_value: "CRT_DEPARTMENT",
          filter_label_Dropdown: "Department_noStar",
          filter_label: "department",
          key_id: 2,
        },
        {
          filter_value: "CRT_ENTITY",
          filter_label_Dropdown: "Entity_nostar",
          filter_label: "entity",
          key_id: 4,
        },
      ],
      selectedFilterType: "CRT_ALIAS", // on filter, default value is alias
      currentPage: 1,
      pageSize: 4,
      filterLabel: "custodian",
      visible: false,
      filterBarText: "",
      rolePermission: {},
      selectedProjectId: this.props.selectedProjectId,
      custodianRecordsLength: 0,
      filteredCustodianRecords: [],
      visableData: [],
      selectedSortBy: "A-Z", // sortby select box has the "Alias A-Z", i.e. alphabetical order by default as per issue number: 171
    };
    this.editCustodian = this.editCustodian.bind(this);
    this.deleteCustodian = this.deleteCustodian.bind(this);
    this.filterList = this.filterList.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.getCustodianCount = this.getCustodianCount.bind(this);
  }

  static contextType = DataContext;
  // LIFE CYCLE METHOD FOR FETCH DATA
  componentDidMount() {
    this.setState(
      {
        custodianRecords: this.props.custodianRecords,
        custodianRecordsLength: this.props.custodianRecords.length,
        filteredCustodianRecords: this.props.custodianRecords,
        backupCustodianRecords: this.props.custodianRecords,
      },
      () => {
        this.visiableDataHandler(this.props.custodianRecords, 1);
      },
    );
  }
  // EDIT FUNCTIONALITY FOR PARTICULAR CUSTODIAN

  editCustodian(crtId, crtTypeId, lockStatus) {
    this.props.editCustodian(crtId, crtTypeId, lockStatus);
  }
  // DELETE CUSTODIAN FUNCTIONALITY

  deleteCustodian(crtId) {
    this.setState({ visible: true, crtId: crtId, selectedProjectId: this.props.selectedProjectId });
  }
  // CONFIRMATION BEFORE DELETE FUNCTIONALITY
  conformDeleteCustodian = () => {
    this.props.deleteCustodian(this.state.crtId);
    this.setState({ visible: false });
  };
  // FILTER CUSTODIAN LIST=================

  filterList(event) {
    this.props.filterCustodian(event);
  }
  // PAGINATION PAGE CHANGE FUNCTIONALITY=================
  onChangeTablePageHandler = (page) => {
    this.setState({ currentPage: page });
    this.visiableDataHandler(this.state.filteredCustodianRecords, page);
  };
  // HANDLING PAGINATION FUNCTIONALITY

  visiableDataHandler(data, page) {
    let lastIndex = this.state.pageSize * page;
    let firstIndex = lastIndex - this.state.pageSize;
    let visData = data.slice(firstIndex, lastIndex);
    this.setState({ visableData: visData }, () => {
      // this.onSearch('',this.state.filterBarText)
    });
  }
  // SEARCH FUNCTIONALITY FOR CUSTODIAN ====================

  onSearch(label, value) {
    let tempCustodianRecords = JSON.parse(JSON.stringify(this.state.backupCustodianRecords));
    if (this.state.selectedFilterType) {
      if (value === "" || value === null) {
        this.setState(
          {
            custodianRecords: this.state.backupCustodianRecords,
            filterBarText: value,
            filteredCustodianRecords: this.state.backupCustodianRecords,
            custodianRecordsLength: this.state.backupCustodianRecords.length,
            currentPage: 1,
          },
          () => {
            this.visiableDataHandler(this.state.filteredCustodianRecords, 1);
          },
        );
      } else {
        let CustodianFilterdList =
          tempCustodianRecords &&
          tempCustodianRecords.filter((el) => {
            if (el[this.state.selectedFilterType] !== null && el[this.state.selectedFilterType] !== "") {
              return el[this.state.selectedFilterType].toLowerCase().includes(value.toLowerCase());
            } else {
              return el[this.state.selectedFilterType];
            }
          });
        this.setState(
          {
            custodianRecords: CustodianFilterdList,
            filterBarText: value,
            filteredCustodianRecords: CustodianFilterdList,
            custodianRecordsLength: CustodianFilterdList.length,
            currentPage: 1,
          },
          () => {
            this.visiableDataHandler(this.state.filteredCustodianRecords, 1);
          },
        );
      }
    } else {
      if (value === "" || value === null) {
        this.setState(
          {
            custodianRecords: this.state.backupCustodianRecords,
            filterBarText: value,
            filteredCustodianRecords: this.state.backupCustodianRecords,
            custodianRecordsLength: this.state.backupCustodianRecords.length,
            currentPage: 1,
          },
          () => {
            this.visiableDataHandler(this.state.filteredCustodianRecords, 1);
          },
        );
      } else {
        let CustodianFilterdList =
          tempCustodianRecords &&
          tempCustodianRecords.filter((el) => el["CRT_ALIAS"].toLowerCase().includes(value.toLowerCase()));
        this.setState(
          {
            custodianRecords: CustodianFilterdList,
            filterBarText: value,
            filteredCustodianRecords: CustodianFilterdList,
            custodianRecordsLength: CustodianFilterdList.length,
            currentPage: 1,
          },
          () => {
            this.visiableDataHandler(this.state.filteredCustodianRecords, 1);
          },
        );
      }
    }
  }
  // CUSTODIAN FILTER BY ONCHANGE EVENT FUNCTIONALITY

  onChange(event) {
    const value = event;
    let filterArray = this.state.filterOptions;
    let filter = filterArray.filter((item) => item.filter_value === value);

    this.setState({
      selectedFilterType: value,
      filterLabel: filter[0].filter_label,
    });
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };
  // LIFE CYCLE METHOD FOR HANDLING WHEN EVER PROPS CHANGED=================
  componentWillReceiveProps(nextProps) {
    if (
      (nextProps.custodianRecords && nextProps.custodianRecords.length) !== this.props.custodianRecords.length
    ) {
      this.setState(
        {
          custodianRecords: nextProps.custodianRecords,
          custodianRecordsLength: nextProps.custodianRecords.length,
          filteredCustodianRecords: nextProps.custodianRecords,
          backupCustodianRecords: nextProps.custodianRecords,
        },
        () => {
          this.visiableDataHandler(nextProps.custodianRecords, this.state.currentPage);
        },
      );
    }
  }
  // GET CUSTODIAN COUNT============

  getCustodianCount(custodianCount) {
    var polyglot = polyglotLoader();
    let tempCustodianCount = parseInt(custodianCount);
    if (tempCustodianCount === 0) {
      return (
        <h1 className="main_title_qb m-b-21" aria-label={polyglot.t("Custodians")}>
          {tempCustodianCount} {polyglot.t("Custodian")}{" "}
        </h1>
      );
    }

    if (tempCustodianCount === 1) {
      return (
        <h1 className="main_title_qb m-b-21" aria-label={polyglot.t("Custodians")}>
          {tempCustodianCount} {polyglot.t("Custodian")}{" "}
        </h1>
      );
    }
    if (tempCustodianCount > 0 && tempCustodianCount > 1) {
      return (
        <h1 className="main_title_qb m-b-21" aria-label={polyglot.t("Custodians")}>
          {tempCustodianCount} {polyglot.t("Custodians")}{" "}
        </h1>
      );
    }
  }
  // SORT BY FUNCTIONALITY ON DIFFERENT TYPE===================
  onChangeSortBy = (event) => {
    switch (event) {
      case "Z-A":
        var filteredList = this.state.filteredCustodianRecords.sort((a, b) => {
          if (a.CRT_ALIAS < b.CRT_ALIAS) {
            return 1;
          }
          if (a.CRT_ALIAS > b.CRT_ALIAS) {
            return -1;
          }
          return 0;
        });
        this.setState(
          {
            filteredCustodianRecords: filteredList,
            selectedSortBy: event,
          },
          () => {
            this.visiableDataHandler(this.state.filteredCustodianRecords, 1);
          },
        );
        return;
      case "A-Z":
        var filteredList = this.state.filteredCustodianRecords.sort((a, b) => {
          if (a.CRT_ALIAS > b.CRT_ALIAS) {
            return 1;
          }
          if (a.CRT_ALIAS < b.CRT_ALIAS) {
            return -1;
          }
          return 0;
        });
        this.setState(
          {
            filteredCustodianRecords: filteredList,
            selectedSortBy: event,
          },
          () => {
            this.visiableDataHandler(this.state.filteredCustodianRecords, 1);
          },
        );
        return;
      case "Newest":
        var filteredList = this.state.filteredCustodianRecords.sort((a, b) => {
          return moment(a.CRT_UPDATED_ON).isBefore(moment(b.CRT_UPDATED_ON)) ? 0 : -1;
        });
        this.setState(
          {
            filteredCustodianRecords: filteredList,
            selectedSortBy: event,
          },
          () => {
            this.visiableDataHandler(this.state.filteredCustodianRecords, 1);
          },
        );
        return;
      case "Oldest":
        var filteredList = this.state.filteredCustodianRecords.sort((a, b) => {
          return moment(b.CRT_UPDATED_ON).isBefore(moment(a.CRT_UPDATED_ON)) ? 0 : -1;
        });
        this.setState(
          {
            filteredCustodianRecords: filteredList,
            selectedSortBy: event,
          },
          () => {
            this.visiableDataHandler(this.state.filteredCustodianRecords, 1);
          },
        );
      default:
        return;
    }
  };
  // get filter label by value
  getFilterLabelByValue = () => {
    try {
      const filterOption = this.state.filterOptions.find(
        (each) => each.filter_value === this.state.selectedFilterType,
      );
      const filterLabel = filterOption.filter_label;
      return filterLabel;
    } catch (err) {
      return "";
    }
  };
  // RENDER METHOD FOR RENDERING CUSTODIAN LIST============

  render() {
    var polyglot = polyglotLoader();
    return (
      <React.Fragment>
        <div className="fluid-container">
          <div className="row custodianCount m-l-0 m-r-0">
            {this.getCustodianCount(this.state.custodianRecordsLength)}
          </div>
          <div className="row m-l-0 m-r-0 filter-search-custodian search-sort-custodian">
            <SearchBar
              role="search"
              aria-label={`Search for ${polyglot.t(this.getFilterLabelByValue())}`}
              placeholder={`${polyglot.t(`Search for`)} ${polyglot.t(this.getFilterLabelByValue())}`}
              nameOfSearch={this.state.filterLabel}
              customClass={"SearchBar"}
              id="search"
              name="search_text"
              val={this.state.search_text}
              onChange={this.onSearch.bind(this)}
            />
            <Select
              id="custodian-filter-id"
              placeholder={polyglot.t("Filter")}
              onSelect={(event) => this.onChange(event)}
              value={this.state.selectedFilterType}
            >
              {this.state.filterOptions.map((filterOptionsData) => {
                return (
                  <SelectOption key={filterOptionsData.key_id} value={filterOptionsData.filter_value}>
                    {polyglot.t(filterOptionsData.filter_label_Dropdown)}
                  </SelectOption>
                );
              })}
            </Select>

            <div className="search-sort">
              <Select
                id="custodian-sort-id"
                placeholder={polyglot.t("Sort")}
                onSelect={(event) => this.onChangeSortBy(event)}
                value={this.state.selectedSortBy}
              >
                <SelectOption key="A-Z" value="A-Z">
                  {polyglot.t("Alias")} &nbsp;
                  <span className="appkiticon icon-arrow-up-outline"></span>
                </SelectOption>
                <SelectOption key="Z-A" value="Z-A">
                  {polyglot.t("Alias")} &nbsp;
                  <span className="appkiticon icon-arrow-down-outline"></span>
                </SelectOption>
                <SelectOption key="Newest" value="Newest">
                  {polyglot.t("Newest")}
                </SelectOption>
                <SelectOption key="Oldest" value="Oldest">
                  {polyglot.t("Oldest")}
                </SelectOption>
              </Select>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-md-12 col-lg-8">
              {this.state.visableData &&
                this.state.visableData.map((e, key) => {
                  // GROUP: has only first name (and this value is equal to his Alias)
                  // PERSON: first name and last name are required but middle name - not
                  const custodianName = e.CRT_ALIAS;
                  return (
                    <div className="container-fluid p-0 mb-3" key={key}>
                      <Panel className="a-panel-dfed">
                        <div className="a-text-heading">
                          <div className="row">
                            <div className="col-md-9 m-auto">
                              <div className="row">
                                <div className="col-md-1">
                                  {e.CRT_TYPE_ID === "1" && (
                                    <img className="userIcon" alt={""} src={userImg} />
                                  )}
                                  {(e.CRT_TYPE_ID === "2" || e.CRT_TYPE_ID === "4") && (
                                    <img className="userIcon" alt={""} src={groupImg} />
                                  )}
                                </div>
                                <div className="col-md-6">
                                  <div className="custodianListDivName">
                                    <Tooltip content={TooltipText(custodianName)} placement="right">
                                      <div className=" card-name-overflow">{custodianName}</div>
                                    </Tooltip>
                                  </div>
                                </div>
                                <div className="col-md-3">
                                  <span className="custodianDivClassification">
                                    {polyglot.t(e.CC_CLASSIFICATION_TYPE)}
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-3 m-auto">
                              <div className="row d-flex justify-content-around">
                                {checkPermission(
                                  this.props.rolePermission,
                                  module_Solution_Name.CUSTODIAN_MODULE,
                                  "Create",
                                ) && (
                                  <ButtonPrimary
                                    className="custodianListDivButton"
                                    ariaLabel={polyglot.t("Add data")}
                                    onClick={() => this.props.addDataBtn(e)}
                                  >
                                    {polyglot.t("Add data")}
                                  </ButtonPrimary>
                                )}
                                {checkPermission(
                                  this.props.rolePermission,
                                  module_Solution_Name.CUSTODIAN_MODULE,
                                  "Edit",
                                ) && (
                                  <ButtonIcon
                                    onClick={this.editCustodian.bind(
                                      this,
                                      e.CRT_ID,
                                      e.CRT_TYPE_ID,
                                      e.LOCK_STATUS,
                                    )}
                                    imgWidth={24}
                                    iconImg={e.LOCK_STATUS === 1 ? eyeIcon : editImg}
                                    alt={e.LOCK_STATUS === 1 ? "view" : "edit"}
                                    title={e.LOCK_STATUS === 1 ? polyglot.t("View") : polyglot.t("Edit")}
                                  />
                                )}
                                {checkPermission(
                                  this.props.rolePermission,
                                  module_Solution_Name.CUSTODIAN_MODULE,
                                  "Delete",
                                ) && (
                                  <ButtonIcon
                                    disabled={e.LOCK_STATUS && e.LOCK_STATUS === 1 ? true : false}
                                    onClick={this.deleteCustodian.bind(this, e.CRT_ID)}
                                    iconName={deleteFill}
                                    alt="delete"
                                    title={polyglot.t("Delete")}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </Panel>
                    </div>
                  );
                })}
              {this.state.custodianRecordsLength &&
              this.state.custodianRecordsLength > 4 &&
              this.state.custodianRecordsLength !== 0 ? (
                <div className="row m-t-20 m-l-n-1">
                  <Pagination
                    current={this.state.currentPage}
                    size="small"
                    pageSize={this.state.pageSize}
                    total={this.state.custodianRecordsLength}
                    onChange={this.onChangeTablePageHandler}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-12 col-lg-4 p-t-25">
              <p className="para-txt">
                {polyglot.t("If you want to create a new Custodian, please click on")}{" "}
                <span className="para-head">{polyglot.t("Add Custodian")}</span>.
              </p>
              <p className="para-txt">
                {polyglot.t("You can add data by uploading new datasets per custodian by clicking on the")}{" "}
                <span className="para-head">{polyglot.t("Add data")}</span>
                {" " + polyglot.t("button in the panel. Indicated by icons, you can either")}
                <span> {" " + polyglot.t("edit")}</span>
                {" " + polyglot.t("the Custodian or")}
                <span className="para-head"> {" " + polyglot.t("delete")}</span>
                {" " + polyglot.t("them including their corresponding Datasets.")}
              </p>
            </div>
          </div>
          <CustomModal
            show={this.state.visible}
            onCancel={this.handleCancel}
            title={polyglot.t("Confirmation")}
            Footer={[
              <button
                type="button"
                className="a-btn primary_button a-btn-primary a-btn-lg"
                onClick={this.conformDeleteCustodian}
              >
                {" "}
                {polyglot.t("Confirm")}{" "}
              </button>,
            ]}
          >
            <div className="row mrgnBtm10">
              <div className="col-md-12">
                <div className="break-word">
                  {polyglot.t(
                    "When deleting a custodian, all the datasets that are linked to it are also going to be deleted, are you sure you want to delete?",
                  )}
                </div>
              </div>
            </div>
          </CustomModal>
        </div>
      </React.Fragment>
    );
  }
}
export default CustodianList;
