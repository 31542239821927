/* eslint-disable no-unused-vars */
//Admin UserCreation
import UserService from "../../../Services/QueenBase/Users/UserService";
import { getClientAdminRoleFromRoles } from "./handler";
import { Pagination } from "antd";
import { Checkbox, Input, Select, SelectGroupTitle, SelectOption } from "appkit-react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import CreatableSelect from "react-select/creatable";
import * as Yup from "yup";

import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import CustomModal from "../../../Assets/Components/Modal";
import { deleteFill } from "../../../Assets/Icons";
import editImg from "../../../Assets/Icons/pencil-outline.svg";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/QueenBase/Root/loading-spin.svg";
import { PER_PAGE_COUNT } from "../../../Constants/Constant";
import { PHONE_REGEX, FIELD_LENGTH_REGEX } from "../../../Constants/regex";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";

const PER_PAGE = PER_PAGE_COUNT;
// step 4 of client creation
function AdminUserCreation(props) {
  let { countries, position, UsersList, ClientFormData, roles } = props;
  // DATA FROM CONTEXT
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const [show, setShow] = useState(false);
  const [SelectExisitingUserModel, setSelectExisitingUserModel] = useState(false);
  const [selectexisitinguser, setSelectexisitinguser] = useState([]);
  const [prefix, setPrefix] = useState([]);

  const language = getUserLanguage();
  // FETCH PRIORITY FROM API
  async function getPrefix(language) {
    var res = await UserService.get().getPrefixNames(language);
    if (res.CODE) {
      setPrefix(res.prefixNames);
    } else {
      displayToastMessage(polyglot.t(res.MSG), "error");
    }
  }
  // get role id of client sponser
  const getRoleIDOfClientSponser = () => {
    let roleID;
    roles.forEach((role) => {
      if (role.role === "ClientSponser") {
        roleID = role.id;
      }
    });
    return roleID;
  };
  // get role id of client admin
  const getRoleIDOfClientAdmin = () => {
    let roleID;
    roles.forEach((role) => {
      if (role.role === "ClientAdmin") {
        roleID = role.id;
      }
    });
    return roleID;
  };

  const initialValuesOfClientSponsorForm = {
    UserFirstName: "",
    UserLastName: "",
    Useremail: "",
    UserDepartment: "",
    UserPhone: "",
    UserEntity: "",
    position: "",
    positionId: {},
    userroleid: getRoleIDOfClientSponser(), // client sponser role
    Usercountryid: 0,
    istemp: 0,
    prefixID: "",
  };
  const [initialValues, setInitialValues] = useState(initialValuesOfClientSponsorForm);
  const [editClientSponsorForm, setEditClientSponsorForm] = useState({ rowId: 0, isFormOpen: false });
  // VALIDATION SCHEMA OF CLIENT SPONSER FORM
  const validationSchema = Yup.object().shape({
    prefixID: Yup.string().required(polyglot.t("* Salutation is required")),
    UserFirstName: Yup.string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t("field_validaton"))
      .required("First Name is required"),
    UserLastName: Yup.string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t("field_validaton"))
      .required(polyglot.t("Last Name is required")),
    Useremail: Yup.string().email("Invalid email").required("Email is Required"),
    position: Yup.string().required(polyglot.t("Position is required")),
    positionId: Yup.object(),
    userroleid: Yup.number().min(1, polyglot.t("role is required")),
    Usercountryid: Yup.number().min(1, polyglot.t("Country is required")),
    UserEntity: Yup.string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t("field_validaton"))
      .required(polyglot.t("Organisation is required")),
    UserDepartment: Yup.string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t("field_validaton"))
      .required(polyglot.t("Department is required")),
    UserPhone: Yup.string()
      .matches(PHONE_REGEX, polyglot.t("Invalid phone number"))
      .min(5, polyglot.t("Phone number must be at least 5 characters"))
      .max(20, polyglot.t("Phone number must be at most 20 characters"))
      .required(polyglot.t("Phone Number is required")),
  });
  const [checkedUserIds, setCheckedUserIds] = useState([]);
  const [loading, setloading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagelist, setPageList] = useState([]);
  const [searchStr, setSearch] = useState("");

  // GET ROLE NAME
  const getRoleName = (id) => {
    let role = roles.filter((r) => r.id === id);
    if (role[0].id === 8) {
      //display Client admin as PwC client admin
      return "PwC client admin";
    }
    return role[0].rolename;
  };
  // CHECK IF USERS CAN BE ADDED
  const checkIfUsersCanBeAdded = () => {
    const usersToAdd = ClientFormData.package.noofusers;
    const clientSponserRoleID = getRoleIDOfClientSponser();
    const addedClientSponsers = UsersList.filter((u) => u.userroleid === clientSponserRoleID).length;
    if (usersToAdd > addedClientSponsers) {
      return true;
    }
    return false;
  };
  // RESET CLIENT SPONSOR FORM
  const resetClientSponsorForm = () => {
    setInitialValues(initialValuesOfClientSponsorForm);
    setEditClientSponsorForm({ rowId: 0, isFormOpen: false });
  };
  // SHOW ADD CLIENT SPONSER USER POPUP
  const showModel = () => {
    const canUsersBeAdded = checkIfUsersCanBeAdded();
    if (canUsersBeAdded) {
      setShow((prev) => !prev);
      resetClientSponsorForm();
    } else {
      displayToastMessage(
        `${polyglot.t("All_USER")} ${ClientFormData.package.noofusers} ${
          ClientFormData.package.noofusers === 1 ? polyglot.t("userno") : polyglot.t("usersno")
        }${polyglot.t("package_validation")}`,
        "error",
      );
    }
  };
  // SHOW EXISTING USERS POPUP
  const showExisitingModel = () => {
    setSelectExisitingUserModel((prev) => !prev);
  };
  // CREATE CLIENT SPONSER
  const onUserCreate = async (values) => {
    let formObj = { ...values };
    formObj.rowId = UsersList.length + 1;
    const result = [...UsersList, formObj];
    setShow(false);
    resetClientSponsorForm();
    await props.updateClientFormData("UsersList", result);
  };
  // UPDATE CLIENT SPONSER
  const onUserUpdate = async (values) => {
    let formObj = { ...values };
    const result = [];
    let tempObj = {};
    UsersList.forEach((each, i) => {
      if (each.rowId === formObj.rowId) {
        tempObj = { ...formObj };
      } else {
        tempObj = { ...each };
      }
      result.push(tempObj);
    });
    props.updateClientFormData("UsersList", result);
    setShow(false);
    resetClientSponsorForm();
  };

  // CLOSE EXISITING USERS POPUP
  const showExisitingcloseModel = () => {
    setSelectExisitingUserModel((prev) => !prev);
    setSearch("");
    setCurrentPage(1);
  };
  // CLOSE ADD CLIENT SPONSER FORM
  const closeModel = () => {
    setShow((prev) => !prev);
    resetClientSponsorForm();
  };

  //UserList api from UserServices
  const getUserList = async () => {
    var res = await UserService.get().getPwCUserList_withoutImages();
    if (res.CODE) {
      const userListRes = res.userList;
      let roleID = getRoleIDOfClientAdmin();
      userListRes.map((u) => {
        u.userroleid = roleID; // existing users are always client admin
        u.role = roleID; // existing users are always client admin
        return u;
      });
      setSelectexisitinguser(userListRes);
      setPageList(res.userList);
    } else {
      displayToastMessage(res.MSG, "error");
    }
  };
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    window.scrollTo(0, 0);
    getUserList();
  }, []);

  // HANDLE USER CHECK
  const handleUserCheck = (userid) => {
    if (checkedUserIds.indexOf(userid) === -1) {
      setCheckedUserIds([...checkedUserIds, userid]);
    } else {
      setCheckedUserIds(checkedUserIds.filter((r) => r !== userid));
    }
  };
  // ASSIGN TO CHECKED USER IDS FROM USERS LIST
  const assignToCheckedUserIdsFromUsersList = (remainingUsers) => {
    const checkedids = [];
    remainingUsers.forEach((user) => {
      if (user.userid) {
        checkedids.push(user.userid);
      }
    });
    setCheckedUserIds(checkedids);
  };
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    assignToCheckedUserIdsFromUsersList(UsersList);
  }, []);

  useEffect(() => {
    setSelectexisitinguser((prevSelectedExistingUser) => {
      const checkedUsers = prevSelectedExistingUser.filter((u) => checkedUserIds.includes(u.userid));
      const restOfUsers = prevSelectedExistingUser.filter((u) => !checkedUserIds.includes(u.userid));
      // PUT CHACKED USERS ON TOP OF THE LIST
      return [...checkedUsers, ...restOfUsers]
    })
  }, [checkedUserIds.length]);

  useEffect(() => {
    getPrefix(language);
  }, [language]);
  // HANDLE EDIT CLIENT SPONSOR
  const handleEditClientSponsor = (index) => {
    const initialValuesOfForm = UsersList.find((each, i) => i === index);
    setInitialValues(initialValuesOfForm);
    // open edit form popup
    setShow(true);
    setEditClientSponsorForm({ rowId: initialValuesOfForm.rowId, isFormOpen: true });
  };
  // HANDLE DELETE TABLE DATA
  const handleDeleteTableData = (index) => {
    const remainingUsers = UsersList.filter((r, i) => i !== index);
    props.updateClientFormData("UsersList", remainingUsers);
    assignToCheckedUserIdsFromUsersList(remainingUsers);
  };
  // SELECT A USER
  const selectauser = () => {
    const checkedUsers = selectexisitinguser.filter((u) => checkedUserIds.includes(u.userid));
    const allUsers = UsersList.filter((user) => !user.userid);
    checkedUsers.map((u) => {
      const obj = {
        UserFirstName: u.firstname,
        UserLastName: u.lastname,
        Useremail: u.email,
        UserEntity: u.entity,
        UserDepartment: u.department,
        UserPhone: u.phone,
        userroleid: u.userroleid,
        istemp: 0,
        position: u.positionname,
        userid: u.userid,
        prefixID: u.prefixID,
        Usercountryid: u.countryid,
      };
      allUsers.push(obj);
    });

    let isSubmitValid = true;
    let areAllRolesSelected = true;
    allUsers.forEach((user) => {
      if (!user.userroleid) {
        areAllRolesSelected = false;
      }
    });
    if (checkedUsers.length === 0) {
      isSubmitValid = 0 ? 0 : 1;
    }
    if (!areAllRolesSelected) {
      displayToastMessage(polyglot.t("Choose a role on selected users"), "error");
      isSubmitValid = false;
    }
    if (isSubmitValid) {
      props.updateClientFormData("UsersList", allUsers);
      showExisitingcloseModel();
    }
  };

  // HANDLE PAGE CHANGE
  const handlePageChange = (change) => {
    setCurrentPage(change);
  };
  // SEARCH KEYS
  const searchKeys = (listObj) => {
    const keys = ["firstname", "lastname", "email", "department", "positionname"];
    const keyValues = keys.map((key) => listObj[key]);
    return keyValues.toString().toLowerCase();
  };
  // FILTER USERS
  const filterUsers = (selectexisitinguser) => {
    let filteredUsers = [...selectexisitinguser];
    if (searchStr !== "") {
      filteredUsers = selectexisitinguser.filter((r) => searchKeys(r).includes(searchStr.toLowerCase()));
    }
    return filteredUsers;
  };
  // GET CURRENT PAGE USERS
  const getCurrentPageUsers = (filteredUsers) => {
    return filteredUsers.slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE);
  };

  // ON SEARCH
  const onSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };
  // DISPLAY SEARCH BOX
  const displaySearchBox = () => {
    return (
      <div className="mb-4">
        <div className="col-md-6 p-0 search-result">
          <div className="row m-l-0 m-r-0 filter-search-qb">
            <div className="searchIcon oval-border">
              <input
                value={searchStr}
                className="oval-search-bar search-input"
                prefix={
                  <span
                    className="appkiticon icon-search-outline  baseline-search-icon "
                    style={{ fontSize: 16 }}
                  />
                }
                placeholder={polyglot.t("Search Users")}
                onChange={onSearch}
                aria-label={polyglot.t("Search Users")}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // EXISTING USER POPUP
  const ExisitingUserModel = () => {
    return (
      <div>
        <CustomModal
          show={SelectExisitingUserModel}
          onCancel={showExisitingcloseModel}
          title={polyglot.t("Select existing admin users")}
          Footer={[
            <ButtonPrimary onClick={() => selectauser()} ariaLabel={polyglot.t("Select users")}>
              {polyglot.t("Select users")}
            </ButtonPrimary>,
          ]}
          size="lg"
        >
          <>
            {displaySearchBox()}
            <div>
              <table className="table">
                <tr>
                  <th></th>
                  <th>
                    <b>{polyglot.t("First name")}</b>
                  </th>
                  <th>
                    <b>{polyglot.t("Last name")}</b>
                  </th>
                  <th>
                    <b>{polyglot.t("Email")}</b>
                  </th>
                  <th>
                    <b>{polyglot.t("Organisation_noStar")}</b>
                  </th>
                  <th>
                    <b>{polyglot.t("Department_noStar")}</b>
                  </th>
                  <th>
                    <b>{polyglot.t("Position_noStar")}</b>
                  </th>
                  <th>
                    <b>{polyglot.t("Role")}</b>
                  </th>
                </tr>
                <tbody>
                  {selectexisitinguser.length > 0 ? (
                    getCurrentPageUsers(filterUsers(selectexisitinguser))
                      .map((list, item) => {
                        return (
                          <tr key={item}>
                            <td className="txt-name-check">
                              <Checkbox
                                checked={checkedUserIds.indexOf(list.userid) !== -1}
                                onChange={() => handleUserCheck(list.userid)}
                                aria-label={polyglot.t("choose user")}
                              />
                            </td>
                            <td className="txt-name-cell">{list.firstname}</td>
                            <td className="txt-name-cell">{list.lastname}</td>
                            <td className="txt-name-cell">{list.email}</td>
                            <td className="txt-name-cell">{list.entity}</td>
                            <td className="txt-name-cell">{list.department}</td>
                            <td className="txt-name-cell">{list.positionname}</td>
                            <td className="txt-name-cell">
                              {roles.length > 0 && (
                                <>
                                  {getClientAdminRoleFromRoles(roles).map((role, index) => {
                                    return <div key={index}>{polyglot.t(getRoleName(role.id))}</div>;
                                  })}
                                </>
                              )}
                            </td>
                          </tr>
                        );
                      })
                  ) : (
                    <tr>
                      <td className="no-user-td" colSpan="12">
                        No Users
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="border-color-active">
              <Pagination
                current={currentPage}
                size="small"
                pageSize={PER_PAGE}
                total={filterUsers(pagelist).length}
                onChange={handlePageChange}
              />
            </div>
          </>
        </CustomModal>
      </div>
    );
  };

  // Submit clinet sponsor form
  const submitClientSponsorForm = (values) => {
    if (editClientSponsorForm.isFormOpen) {
      return onUserUpdate(values);
    } else {
      return onUserCreate(values);
    }
  };

  // CLIENT SPONSER FORM
  const modelForm = () => {
    const currentTs = new Date().getTime();
    if (countries && countries.length > 0) {
      return (
        <div key={currentTs}>
          <CustomModal
            show={show}
            onCancel={closeModel}
            title={polyglot.t("Add client sponser to the system")}
            Footer={[]}
            hideFooter={true}
            size="lg"
          >
            <Formik
              enableReinitialize
              onSubmit={submitClientSponsorForm}
              initialValues={initialValues}
              validationSchema={validationSchema}
            >
              {(prop) => {
                const { values, handleChange, handleSubmit, errors, touched, resetForm } = prop;
                const handleModalClose = () => {
                  resetForm();
                  showModel();
                };
                // to check if value is already added to the key
                const checkIfValueThereInUserList = (key, value) => {
                  let valueAlreadyPresent = false;
                  const lowerCaseValue = value.toLowerCase();
                  UsersList.forEach((user) => {
                    if (typeof user[key] === "string") {
                      let lowerCaseString = user[key].toLowerCase();
                      if (lowerCaseString === lowerCaseValue) {
                        // ignore the check in edit form
                        if (user.rowId !== editClientSponsorForm.rowId) {
                          valueAlreadyPresent = true;
                        }
                      }
                    }
                  });
                  return valueAlreadyPresent;
                };

                const isUserNameExists = checkIfValueThereInUserList("UserFirstName", values.UserFirstName);
                const isUserLastNameExists = checkIfValueThereInUserList("UserLastName", values.UserLastName);
                const isEmailExists = checkIfValueThereInUserList("Useremail", values.Useremail);

                if (isEmailExists) {
                  errors.Useremail = "Email already exists";
                }

                return (
                  <div>
                    <>
                      <div>
                        <div className="row">
                          <div className="col-md-3">
                            {getUserLanguage() === "en" && (
                              <>
                                <Select
                                  placeholder={polyglot.t("Salutation *")}
                                  value={values.prefixID}
                                  onSelect={(value) =>
                                    handleChange("prefixID")({
                                      target: { value },
                                    })
                                  }
                                >
                                  {prefix.map((prefix, index) => {
                                    return (
                                      <SelectOption key={index} value={prefix.id}>
                                        {polyglot.t(prefix.name)}
                                      </SelectOption>
                                    );
                                  })}
                                </Select>
                              </>
                            )}
                            {getUserLanguage() === "ger" && (
                              <>
                                <Select
                                  placeholder={polyglot.t("Salutation *")}
                                  value={values.prefixID}
                                  onSelect={(value) =>
                                    handleChange("prefixID")({
                                      target: { value },
                                    })
                                  }
                                >
                                  {prefix.map((prefix, index) => {
                                    return (
                                      <SelectOption key={index} value={prefix.id}>
                                        {polyglot.t(prefix.name)}
                                      </SelectOption>
                                    );
                                  })}
                                </Select>
                              </>
                            )}
                            {errors.prefixID && touched.prefixID ? (
                              <div className="error">{polyglot.t(errors.prefixID)}</div>
                            ) : null}
                            <br />
                          </div>
                          <div className="col-md-3 ">
                            <Input
                              inputBoxSize="sm"
                              placeholder={polyglot.t("First name *")}
                              value={values.UserFirstName}
                              onChange={handleChange("UserFirstName")}
                              aria-label={polyglot.t("First name *")}
                            />
                            {errors.UserFirstName && touched.UserFirstName ? (
                              <div className="error">{polyglot.t(errors.UserFirstName)}</div>
                            ) : null}
                            <br />
                          </div>
                          <div className="col-md-6 ">
                            <Input
                              inputBoxSize="sm"
                              placeholder={polyglot.t("Last name *")}
                              value={values.UserLastName}
                              onChange={handleChange("UserLastName")}
                              aria-label={polyglot.t("Last name *")}
                            />
                            {errors.UserLastName && touched.UserLastName ? (
                              <div className="error">{errors.UserLastName}</div>
                            ) : null}
                            <br />
                          </div>
                          <div className="col-md-6">
                            <Input
                              inputBoxSize="sm"
                              placeholder={polyglot.t("Email*")}
                              value={values.Useremail}
                              onChange={handleChange("Useremail")}
                              aria-label={polyglot.t("Email*")}
                            />
                            {errors.Useremail && touched.Useremail ? (
                              <div className="error">{polyglot.t(errors.Useremail)}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6" id="CreatableSelect">
                            {position.length === 0 && (
                              <CreatableSelect
                                placeholder={polyglot.t("Position *")}
                                options={[{ label: polyglot.t("Loading...") }]}
                                classNamePrefix="custom-creatable-select"
                                aria-label={polyglot.t("Position *")}
                              />
                            )}
                            {position.length > 0 && (
                              <CreatableSelect
                                placeholder={polyglot.t("Position *")}
                                onInputChange={(value, actionMeta) => {
                                  if (actionMeta.action === "select-option") {
                                    handleChange("position")(value.name);
                                    handleChange("positionId")({
                                      target: { value },
                                    });
                                  } else if (actionMeta.action === "create-option") {
                                    handleChange("position")(value.label);
                                    handleChange("positionId")({
                                      target: { value },
                                    });
                                  }
                                }}
                                onChange={(value, actionMeta) => {
                                  if (actionMeta.action === "select-option") {
                                    handleChange("position")(value.name);
                                    handleChange("positionId")({
                                      target: { value },
                                    });
                                  } else if (actionMeta.action === "create-option") {
                                    handleChange("position")(value.label);
                                    handleChange("positionId")({
                                      target: { value },
                                    });
                                  }
                                }}
                                options={position}
                                classNamePrefix="custom-creatable-select"
                                aria-label={polyglot.t("Position *")}
                                value={Object.keys(values.positionId).length > 0 && values.positionId}
                              />
                            )}
                            {errors.position && touched.position ? (
                              <div className="error">{errors.position}</div>
                            ) : (
                              null && errors.position && <span className="error">{errors.position}</span>
                            )}
                            <br />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-6">
                            {countries.length > 0 && (
                              <Select
                                placeholder={polyglot.t("Country *")}
                                value={values.Usercountryid || ""}
                                showSearchOnToggle={true}
                                onSelect={(value) =>
                                  handleChange("Usercountryid")({
                                    target: { value },
                                  })
                                }
                                aria-label={polyglot.t("Country *")}
                              >
                                <SelectGroupTitle>{polyglot.t("Country")}</SelectGroupTitle>
                                {countries.map((country, index) => {
                                  if (index < 1)
                                    return (
                                      <SelectOption key={index + 1} value={country.countryid}>
                                        {country.name}
                                      </SelectOption>
                                    );
                                })}
                                <hr className="" />
                                {countries.map((country, index) => {
                                  if (index > 0)
                                    return (
                                      <SelectOption key={index + 1} value={country.countryid}>
                                        {country.name}
                                      </SelectOption>
                                    );
                                })}
                              </Select>
                            )}
                            {errors.Usercountryid && touched.Usercountryid ? (
                              <div className="error">{errors.Usercountryid}</div>
                            ) : (
                              null &&
                              errors.Usercountryid && <span className="error">{errors.Usercountryid}</span>
                            )}
                            <br />
                          </div>
                          <div className="col-md-6 ">
                            <Input
                              inputBoxSize="sm"
                              placeholder={polyglot.t("Organisation")}
                              value={values.UserEntity}
                              onChange={handleChange("UserEntity")}
                              aria-label={polyglot.t("Organisation")}
                            />
                            {errors.UserEntity && touched.UserEntity ? (
                              <div className="error">{errors.UserEntity}</div>
                            ) : null}
                            <br />
                          </div>
                          <div className="col-md-6">
                            <Input
                              inputBoxSize="sm"
                              placeholder={polyglot.t("Department *")}
                              value={values.UserDepartment}
                              onChange={handleChange("UserDepartment")}
                              aria-label={polyglot.t("Department *")}
                            />
                            {errors.UserDepartment && touched.UserDepartment ? (
                              <div className="error">{errors.UserDepartment}</div>
                            ) : null}
                          </div>
                          <div className="col-md-6">
                            <Input
                              inputBoxSize="sm"
                              placeholder={polyglot.t("PhoneNumber*")}
                              value={values.UserPhone}
                              onChange={handleChange("UserPhone")}
                              aria-label={polyglot.t("PhoneNumber*")}
                            />
                            {errors.UserPhone && touched.UserPhone ? (
                              <div className="error">{errors.UserPhone}</div>
                            ) : null}
                          </div>
                        </div>
                        <br />
                        <div className="row">
                          <div className="col-md-6">
                            {roles.length > 0 && (
                              <>
                                {roles.map((role) => {
                                  if (values.userroleid === role.id) {
                                    return (
                                      <Input
                                        inputBoxSize="sm"
                                        placeholder={polyglot.t("Role")}
                                        value={polyglot.t(role.rolename)}
                                        onChange={handleChange("UserDepartment")}
                                        disabled
                                        aria-label={polyglot.t("UserDepartment")}
                                      />
                                    );
                                  }
                                })}
                              </>
                            )}
                            {errors.userroleid && touched.userroleid ? (
                              <div className="error">{errors.userroleid}</div>
                            ) : (
                              null && errors.userroleid && <span className="error">{errors.userroleid}</span>
                            )}
                            <br />
                          </div>
                          <br />
                        </div>
                      </div>
                    </>
                    <>
                      <div className="flex-fill help-text">
                        <div>
                          <ButtonSecondary onClick={handleModalClose} ariaLabel={polyglot.t("Cancel")}>
                            {polyglot.t("Cancel")}
                          </ButtonSecondary>
                          &nbsp;
                          <ButtonPrimary
                            onClick={handleSubmit}
                            ariaLabel={
                              editClientSponsorForm.isFormOpen
                                ? polyglot.t("Update Client Sponsor")
                                : polyglot.t("Create Client Sponsor")
                            }
                          >
                            {editClientSponsorForm.isFormOpen
                              ? polyglot.t("Update Client Sponsor")
                              : polyglot.t("Create Client Sponsor")}
                          </ButtonPrimary>
                        </div>
                      </div>
                    </>
                  </div>
                );
              }}
            </Formik>
          </CustomModal>
        </div>
      );
    } else return <LoadingSpinner width={64} height={64} fill={"#ef3829"} />;
  };

  return (
    <div className="user-container pl-0">
      <div className="row m-auto">
        <h1 className="col-md-4 main_title_qb m-l-1-2 ">{polyglot.t("Add users")}</h1>
        <div className="col-md-4 button-align">
          <ButtonPrimary onClick={showModel} ariaLabel={polyglot.t("Add client sponser")}>
            {polyglot.t("Add client sponser")}
          </ButtonPrimary>
        </div>
        <div className="col-md-4 add-user-admin p-r-1">
          <ButtonPrimary onClick={showExisitingModel} ariaLabel={polyglot.t("Add PwC client admin")}>
            {polyglot.t("Add PwC client admin")}
          </ButtonPrimary>
        </div>
      </div>
      <div className="a-modal-example-header">
        <div className="admin-user-list">
          <div className="invoicetable">
            <table className="table">
              <tr className="subtitle_two_title_qb">
                <th>{polyglot.t("First name")}</th>
                <th>{polyglot.t("Last name")}</th>
                <th>{polyglot.t("Email")}</th>
                <th>{polyglot.t("Organisation_noStar")}</th>
                <th>{polyglot.t("Department_noStar")}</th>
                <th>{polyglot.t("Phone")}</th>
                <th>{polyglot.t("Role")}</th>
                <th>{polyglot.t("Action")}</th>
              </tr>
              <tbody>
                {UsersList.length > 0 ? (
                  UsersList.map((list, index) => {
                    return (
                      <tr key={index}>
                        <td className="subtitle_three_title_qb_dark align-middle">{list.UserFirstName}</td>
                        <td className="subtitle_three_title_qb_dark align-middle">{list.UserLastName}</td>
                        <td className="subtitle_three_title_qb_dark align-middle">{list.Useremail}</td>
                        <td className="subtitle_three_title_qb_dark align-middle">{list.UserEntity}</td>
                        <td className="subtitle_three_title_qb_dark align-middle">{list.UserDepartment}</td>
                        <td className="subtitle_three_title_qb_dark align-middle">{list.UserPhone}</td>
                        <td className="subtitle_three_title_qb_dark align-middle">
                          {polyglot.t(getRoleName(list.userroleid))}
                        </td>
                        <td className="d-flex">
                          <ButtonIcon
                            iconName={deleteFill}
                            onClick={() => handleDeleteTableData(index)}
                            alt="delete"
                            title={polyglot.t("Delete")}
                            ariaLabel={polyglot.t("delete")}
                          />
                          {/* show edit only for client sponsor record */}
                          {list.rowId && (
                            <ButtonIcon
                              imgWidth={16}
                              iconImg={editImg}
                              onClick={() => handleEditClientSponsor(index)}
                              alt="edit"
                              title={polyglot.t("Edit")}
                              ariaLabel={polyglot.t("edit")}
                            />
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td className="no-user-td" colSpan="12">
                      {polyglot.t("No users")}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {show && modelForm()}
      {ExisitingUserModel()}
    </div>
  );
}
AdminUserCreation.propTypes = {
  history: PropTypes.object,
  countries: PropTypes.array,
  position: PropTypes.array,
  updateClientFormData: PropTypes.func,
  UsersList: PropTypes.array,
  setCustomcreate: PropTypes.func,
  setCustomselect: PropTypes.func,
};
export default AdminUserCreation;
