import "../../../Assets/Css/DF/Solutions/Solution.css";
import "../Start/ChooseSurvey.css";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import { StartContext } from "./Contexts/StartContext";
import CardWithRadio from "./SharedComponents/CardWithRadio";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { polyglotLoader } from "../../../Services/Shared/Translate";
// CHOOSE A SURVEY ON STEP 1 OF WORKFLOW FORM
const ChooseSurvey = (props) => {
  // DATA FROM PROPS
  const { next, nextPage } = props;
  // DATA FROM CONTEXT
  const { projectSurvey, surveys, updateProjectSurvey } = useContext(StartContext);
  var polyglot = polyglotLoader();

  // DISPLAY SURVEYS CARDS
  const displaySurveys = () => {
    if (surveys.length > 0) {
      return surveys.map((survey, i) => (
        <CardWithRadio
          item={survey}
          key={i}
          selectedItem={projectSurvey.survey_id}
          handleCheck={updateProjectSurvey}
        />
      ));
    }
  };

  // CHECK IF THE BUTTON IS DISABLED
  const isDisabled = () => {
    return projectSurvey.survey_id === "" ? true : false;
  };

  return (
    <div aria-modal="true">
      <h1
        className="main_title_qb"
        tabIndex="-1"
        aria-hidden="false"
        style={{ marginTop: "5%", textAlign: "center" }}
      >
        {polyglot.t("How would you like to start?")}
      </h1>
      <div className="ml-auto a-font-12 a-opacity-80">
        <div className="f-acc-B DF-review-content" style={{ textAlign: "center" }}>
          {polyglot.t(
            "To give you a better workflow and better guidance, please tell us what your survey is based on.",
          )}
        </div>
      </div>

      <div className="row mt-5 text-center">{displaySurveys()}</div>

      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6 df-button-style">
          <ButtonPrimary
            onClick={() => next(nextPage)}
            disabled={isDisabled()}
            ariaLabel={polyglot.t("Choose my data source")}
          >
            {polyglot.t("Choose my data source")}
          </ButtonPrimary>
        </div>
        <div className="col-md-3"></div>
      </div>
    </div>
  );
};

ChooseSurvey.propTypes = {
  next: PropTypes.any,
  nextPage: PropTypes.any,
};

export default ChooseSurvey;
