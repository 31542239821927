import React from "react";
import { Switch } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import EditProjectContainer from "../Components/QueenBase/Projects/EditProjectContainer";
import ProjectFormContainer from "../Components/QueenBase/Projects/ProjectFormContainer";
import ProjectSelect from "../Components/QueenBase/Projects/ProjectSelection";
import * as path from "../Constants/Path";

// ROUTES OF PROJECT SCREENS
function ProjectRoute() {
  return (
    <div>
      <Switch>
        <ProtectedRoute path={path.VIEW_PROJECT_PATH} component={ProjectSelect} />
        <ProtectedRoute path={path.CREATE_PROJECT_PATH} component={ProjectFormContainer} />
        <ProtectedRoute path={path.EDIT_PROJECT_PATH} component={EditProjectContainer} />
      </Switch>
    </div>
  );
}

export default ProjectRoute;
