import React, { Component } from "react";
import { Input } from "reactstrap";
import "../Review/ReviewSelectionScreen.css";
import right from "../../../Assets/Images/DFED/icon-chevron-right-black.svg";
import "../Analysis/CommonStyles.css";
import { getreviewdetailsGridDetails } from "../../../../src/Services/DFED/AdminService";
import AnalysisService from "../../../../src/Services/DFED/AnalysisService";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import { Pagination } from "antd";
import NoAccess from "../../../Assets/Components/NoAccess";
import OutlineButton from "../Shared/button/OutlineButton";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";

const analysisService = new AnalysisService();

export class ReviewSelectionScreen extends Component {
  constructor(props) {
    super();
    this.state = {
      reviewGridData: [],
      selectedProjectId: props.location ? props.location.state.selectedProjectId : props.selectedProjectId,
      selectedProjectName: sessionStorage.getItem("selectedProjectName"),
      analysis_name: "",
      TECHNICAL_REPORT_FILENAME: "",
      REVIEW_NAME: "",
      currentPage: 1,
      visibleData: [],
      loader: false,
      isViewPermission: true,
      backupReviewData: [],
      completedAnalysisLst: [],
    };
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }
  static contextType = DataContext;
  componentDidMount() {
    window.scrollTo(0, 0);
    this.getReviewDetails(this.state.selectedProjectId);
    this.AnalysisLstData(this.state.selectedProjectId);
  }

  //get review grid details
  async getReviewDetails(tempSelectedProjectId) {
    getreviewdetailsGridDetails(tempSelectedProjectId)
      .then((reviewInfo) => {
        if (reviewInfo && reviewInfo.data) {
          this.setState(
            {
              loader: true,
              reviewGridData: reviewInfo.data,
              backupReviewData: reviewInfo.data,
            },
            () => {
              this.visibleDataHandler(reviewInfo.data, 1);
            },
          );
        }
      })
      .catch((err) => {
        if (err.status === 403) {
          this.setState({
            isViewPermission: false,
          });
        }
      });
  }
  async AnalysisLstData(tempSelectedProjectId) {
    await analysisService
      .AnalysisSummary(Number(tempSelectedProjectId))
      .then((analysInfo) => {
        function getCompletedAnalysisList(jsonList) {
          return jsonList.filter((each) => {
            if (
              each.PREPARE_KEYWORD_STATUS === "3" &&
              each.REPORT_GENERATED_STATUS === "3" &&
              each.RUNNING_KEYWORD_STATUS === "3"
            ) {
              return each;
            }
          });
        }
        const completedAnalysisList = getCompletedAnalysisList(analysInfo.data);
        this.setState({
          completedAnalysisLst: completedAnalysisList,
        });
      })
      .catch((err) => {
        if (err && err.status === 403) {
          this.setState({
            isViewPermission: false,
          });
        }
      });
  }

  progressBar = (prep, gen) => {
    let res = ["", "", "inprogress", "success"];
    return (
      <ul className="review_progressbar">
        <li className={res["3"]}></li>
        <li className={res[prep]}></li>
        <li className={res[gen]}></li>
      </ul>
    );
  };
  onBackButtonClick = (review_name, analysisName, fileName, REVIEW_PLATFORM_URL) => {
    this.props.history.push({
      pathname: "/dfed/reviewdetails",
      state: {
        selectedProjectId: this.state.selectedProjectId,
        selectedProjectName: this.state.selectedProjectName,
        analysis_name: analysisName,
        TECHNICAL_REPORT_FILENAME: fileName,
        REVIEW_NAME: review_name,
        REVIEW_PLATFORM_URL,
      },
    });
  };
  onChangeTablePageHandler = (page) => {
    this.setState({ currentPage: page });
    this.visibleDataHandler(this.state.reviewGridData, page);
  };

  // open add review screen
  pushToReview = () => {
    this.props.history.push({
      pathname: "/dfed/addreviewselection",
      state: {
        selectedProjectId: this.state.selectedProjectId,
      },
    });
  };

  // check if user can create a review
  checkIfUserCanCreateReview = () => {
    const polyglot = polyglotLoader();
    if (this.state.completedAnalysisLst.length === 0) {
      displayToastMessage(
        polyglot.t("Atleast one analysis request has to be completed to create a review request"),
        "error",
      );
    } else {
      this.pushToReview();
    }
  };

  visibleDataHandler(data, page) {
    let lastIndex = 6 * page;
    let firstIndex = lastIndex - 6;
    let visData = data ? data.slice(firstIndex, lastIndex) : [];
    this.setState({ visibleData: visData });
  }
  onSearch = (e) => {
    let tempCustodianProcessData = JSON.parse(JSON.stringify(this.state.backupReviewData));
    var list = tempCustodianProcessData.filter(
      (item) => item.REVIEW_NAME.toLowerCase().search(e.target.value.toLowerCase()) !== -1,
    );
    this.setState({ visibleData: list }, () => {
      this.visibleDataHandler(list, 1);
    });
  };

  render() {
    var polyglot = polyglotLoader();
    var reviewHome = (
      <div>
        <div className="row col-lg-10 pl-0">
          <div className="col-md-3 col-lg-3">
            <ButtonPrimaryWithIcon
              onClick={this.checkIfUserCanCreateReview}
              ariaLabel={polyglot.t("Add Review")}
            >
              {polyglot.t("Add Review")}
            </ButtonPrimaryWithIcon>
          </div>

          <div className="col-md-9">
            <OutlineButton
              name={polyglot.t("Add production request")}
              onClick={() =>
                this.props.history.push({
                  pathname: "/dfed/production",
                  state: { selectedProjectId: this.props.location.state.selectedProjectId },
                })
              }
            />
          </div>
        </div>

        {this.state.reviewGridData && this.state.reviewGridData.length > 0 && (
          <div className="row m-t-21 m-l-0 m-r-0">
            <div className="col-md-6 tex-ali-processing p-l-0 p-r-0">
              <h1 className="main_title_qb m-b-21">
                {this.state.reviewGridData.length} {polyglot.t("Review list")}
              </h1>
            </div>
          </div>
        )}
        {this.state.reviewGridData && this.state.reviewGridData.length > 0 && (
          <div className="row m-l-0 m-r-0">
            <div className="col-md-3 p-0 search-analysis m-b-15 margin-top-plus-0-3">
              <Input
                className="analysis-search"
                aria-label={polyglot.t("Search for review")}
                placeholder={polyglot.t("Search for review")}
                value={this.state.query}
                onChange={this.onSearch.bind(this)}
              />
            </div>
            <div className="col-md-3"></div>
          </div>
        )}

        <div className="row review_liat-name m-l-0 m-r-0  p-0">
          <div className="col-md-12 col-lg-8 pl-0 p-r-10 p-t-4">
            {this.state.visibleData.length > 0 ? (
              this.state.visibleData &&
              this.state.visibleData.length > 0 &&
              this.state.visibleData.map((data, index) => {
                return (
                  <div className="review_ans-lst-div">
                    <div className="row m-l-0 m-r-0 ">
                      <div className=" col-md-4">
                        <label className="list-name">{data.REVIEW_NAME}</label>
                      </div>
                      <div className="  col-md-1 p-0 tex-ali-c show-in-mob">
                        <img src={right} alt="wait" className="pt-10" />
                      </div>
                      <div className="col-md-4"></div>

                      <div className="col-md-3 p-0">
                        {this.progressBar(data.PREPAREVIEWSSTATUS, data.CREATEBATCHESSTATUS)}
                      </div>

                      <div className=" col-md-1 p-0 tex-ali-c hide-in-mob">
                        <ButtonIcon
                          iconImg={right}
                          alt="expand"
                          ariaLabel={polyglot.t("expand")}
                          onClick={(e) =>
                            this.onBackButtonClick(
                              data.REVIEW_NAME,
                              data.ANALYSIS_NAME,
                              data.TECHNICAL_REPORT_FILENAME,
                              data.REVIEW_PLATFORM_URL,
                            )
                          }
                          title={polyglot.t("Expand")}
                        />
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className=" col-md-12  div-main">
                <div className="row ">
                  <div className="col-md-11 review_norecords">
                    <h1 className="norecordsfound">
                      {this.state.loader ? polyglot.t("No records found") : ""}
                    </h1>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div className="col-md-12 col-lg-4 p-l-20">
            <div>
              <p className="para-txt">
                {polyglot.t(
                  "Please consult the list of all created Reviews and their corresponding stage. By clicking on a specific Review, you will receive additional information.",
                )}
              </p>
            </div>
          </div>
          <div className="mt-4 tex-ali-l m-l-n-1">
            {this.state.reviewGridData.length > 6 && (
              <Pagination
                current={this.state.currentPage}
                size="small"
                pageSize={6}
                total={this.state.reviewGridData.length}
                onChange={(page) => this.onChangeTablePageHandler(page)}
              />
            )}
          </div>
        </div>
      </div>
    );
    if (this.state.isViewPermission === false) {
      return <NoAccess />;
    } else {
      return reviewHome;
    }
  }
}
export default ReviewSelectionScreen;
