import React from "react";
import Loadable from "react-loadable";
import LoadingIndicator from "../Components/QueenBase/Root/LoadingIndicator";
import ProtectedRoute from "./ProtectedRoute";
import { Switch } from "react-router-dom";
import HomeContainer from "../Components/CSS/Main/HomeContainer";
import AdminContainer from "../Components/CSS/Admin/AdminContainer";
import SearchContainer from "../Components/CSS/Home/SearchContainer";
import { CSS_ADMIN, CSS_HOME, CSS_SEARCH, CSS_SUPPORT } from "../Constants/Path";

// ROUTES OF CSS MODULE
function CSSModuleRoutes() {
  return (
    <Switch>
      <ProtectedRoute path={CSS_HOME} component={HomeContainer} />
      <ProtectedRoute path={CSS_SEARCH} component={SearchContainer} />
      <ProtectedRoute path={CSS_ADMIN} component={AdminContainer} />
      <ProtectedRoute
        path={CSS_SUPPORT}
        component={Loadable({
          loader: () => import("../Components/DFED/Support/Support"),
          loading: LoadingIndicator,
        })}
      />
    </Switch>
  );
}

export default CSSModuleRoutes;
