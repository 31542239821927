import "../../../../Assets/Css/QueenBase/CompanyCenter/CompanyCenter.css";
import { Progress, Tooltip } from "appkit-react";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import iconinfo from "../../../../Assets/Images/QueenBase/Root/icon-info.svg";
import { getStrokeColorByPercentageUsed } from "../../../../Services/Shared/Handlers";
import { DataContext } from "../../../../Services/Shared/Store";
import { polyglotLoader } from "../../../../Services/Shared/Translate";

function DataProcessed({ percentageUsed, label, title }) {
  const { getUserLanguage } = useContext(DataContext);
  const polyglot = polyglotLoader(getUserLanguage());

  return (
    <>
      <div className="row alignItemsCenter">
        <div className={`col-md-10 f-acc-B pr-0 ${percentageUsed > 100 ? "data-overused" : ""}`}>
          <Progress
            showInfo={false}
            percent={percentageUsed.toFixed(2)}
            width={40}
            strokeColor={getStrokeColorByPercentageUsed(percentageUsed)}
          />
        </div>
        <div className="col-md-2 p-0 ant-progress-text company-center-progress-text">
          {percentageUsed >= 100 ? (
            <Tooltip
              content={polyglot.t("Data is overused")}
              placement="top"
              className="a-md-pop"
              tooltipTheme="light"
            >
              <img src={iconinfo} height="16px" alt={polyglot.t("More info")} />
            </Tooltip>
          ) : (
            `${percentageUsed.toFixed(2)}%`
          )}
        </div>
      </div>
      <div className="row">
        <p className="col-md-6">{title}</p>
        <p className="col-md-6 text-align-right">{label}</p>
      </div>
    </>
  );
}

DataProcessed.propTypes = {
  percentageUsed: PropTypes.number,
  label: PropTypes.string,
  title: PropTypes.string,
};

export default DataProcessed;
