import axios from "axios";
import { useState, useCallback, useRef, useEffect } from "react";

export const useHttpPostPerfClient = () => {
  const [isLoadingPerfPost, setIsLoading] = useState(false);
  const [errorPerfPost, setError] = useState();

  const activeHttpRequests = useRef([]);
  const generateHeadersModules = () => {
    return {
      Authorization: "Bearer " + sessionStorage.getItem("grc_recipient_jwt"),
      project_id: sessionStorage.getItem("grc_recipient_selectedProject"),
      loggedInUserId: sessionStorage.getItem("grc_recipient_user_uid"),
    };
  };
  const sendRequestPerfPost = useCallback(async (url, body = null, head = {}) => {
    setIsLoading(true);
    const httpAbortCtrl = new AbortController();
    activeHttpRequests.current.push(httpAbortCtrl);

    try {
      const response = await axios.post(url, body, {
        headers: { ...generateHeadersModules(), ...head },
      }); /* .then((res) => {          
          response =res.data;
      }) */

      const responseData = response.data;

      activeHttpRequests.current = activeHttpRequests.current.filter((reqCtrl) => reqCtrl !== httpAbortCtrl);

      setIsLoading(false);
      return responseData;
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
      throw err;
    }
  }, []);

  const clearErrorPerfPost = () => {
    setError(null);
  };

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort());
    };
  }, []);

  return { isLoadingPerfPost, errorPerfPost, sendRequestPerfPost, clearErrorPerfPost };
};
