import CommonService from "../Shared/CommonService";

class RecipientService {
  constructor() {
    this.baseApiUrl = CommonService.get().getGRCBaseApiUrl();
  }

  getAssessmentTypesURL() {
    return `${this.baseApiUrl}/getAssessmentTypes`;
  }
  postRecipientAssessmentsURL() {
    return `${this.baseApiUrl}/postRecipientAssessment`;
  }
  postRecipientAuthURL() {
    return `${this.baseApiUrl}/postRecipientAuth`;
  }
  getRecipientAassessmentQuestionsURL(parent_id) {
    return `${this.baseApiUrl}/getRecipientAassessmentQuestions/${parent_id}`;
  }
  getRecipientAassessmentQuestionsByProjectURL(parent_id, project_id, assessmentId) {
    return `${this.baseApiUrl}/getRecipientAassessmentQuestionsByProject/${parent_id}/${project_id}/${assessmentId}`;
  }
  getRecipientAassessmentQuestionsByUidURL(parent_id, uid) {
    return `${this.baseApiUrl}/getRecipientAassessmentQuestionsByUID/${parent_id}/${uid}`;
  }
  getTextDataURL(id) {
    return `${this.baseApiUrl}/getTextData/${id}`;
  }
  getTextDataPerfAssessmentURL(id) {
    return `${this.baseApiUrl}/getTextDataPerfAssessment/${id}`;
  }
  getRecipientQuestionAnswersPerformURL(questionId, uniqueId, dependencyQuestionId) {
    return `${this.baseApiUrl}/getRecipientQuestionPerformAnswers/${questionId}/${uniqueId}/${dependencyQuestionId}`;
  }
  getRecipientResponseQuestionAnswersURL(id, uid, dependency_question_id) {
    return `${this.baseApiUrl}/getRecipientResponseQuestionPerformAnswers/${id}/${uid}/${dependency_question_id}`;
  }
  getRecipientResponseAssessmentURL(assessment_id) {
    return `${this.baseApiUrl}/getRecipientResponseAssessment/${assessment_id}`;
  }
  postTokenAuthURL() {
    return `${this.baseApiUrl}/postTokenAuth`;
  }
}

// Enable Singleton behavior on this class
RecipientService.__instance = null;
RecipientService.get = () => {
  if (!RecipientService.__instance) {
    RecipientService.__instance = new RecipientService();
  }
  return RecipientService.__instance;
};

export default RecipientService;
