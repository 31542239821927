import React, { useContext, useEffect } from "react";

import RequestComponent from "./RequestComponent";
import NoAccess from "../../../Assets/Components/NoAccess";
import ProtectedRoute from "../../../Routes/ProtectedRoute";
import { getProjectID_Of_solution_module } from "../../../Services/Shared/Handlers";
import { hasViewPermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";

// RENDERS ON PATH /gi/home
export default (props) => {
  // FETCH DATA FROM CONTEXT
  const { checkAndFetchRole, getCurrentUserPermissions } = useContext(DataContext);
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    const projectId = getProjectID_Of_solution_module();
    const screenName = "Home";
    checkAndFetchRole(screenName, "Connected Global Intelligence", projectId);
  }, []);
  const currentUserPermissions = getCurrentUserPermissions();
  return (
    <>
      {/* RENDER PATH IF USER HAS PERMISSION TO VIEW */}
      {hasViewPermission(currentUserPermissions) && (
        <ProtectedRoute
          path="/"
          // component={Loadable({ loader: () => import('./RequestComponent'), loading: LoadingIndicator })}
          component={RequestComponent}
        />
      )}
      {/* SHOW 'NO ACCESS' IF USER DOES NOT HAVE PERMISSION TO VIEW */}
      {!hasViewPermission(currentUserPermissions) && <NoAccess />}
    </>
  );
};
