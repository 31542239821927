import { Panel, Progress } from "appkit-react";
import React, { useState, useEffect } from "react";

import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { ReactComponent as LoadingSpinner } from "../../../../Assets/Images/GRC/loading-spin.svg";
import displayToastMessage from "../../../../Components/QueenBase/Root/displayToastMessage";
import { ASSESSMENT_RECIPIENT_SHARE_PATH } from "../../../../Constants/Path";
import AssessmentService from "../../../../Services/GRC/AssessmentService";
import { useHttpClient } from "../../../../Services/GRC/http-hook";
import { useHttpPostClient } from "../../../../Services/GRC/http-hook-post";
import { polyglotLoader } from "../../../../Services/Shared/TranslateGRC";
import ErrorPanel from "../../Shared/ErrorPanel";
import LeftPanelQuestionaris from "../LeftPanelQuestionaris";
import PreviewQuestionTree from "../PreviewQuestionTree";
import "./index.css";

/**
 * Preview the assessment before the final submit
 *
 * @param {props} props
 * @returns
 */
const Preview = (props) => {
  const selectedId = props.location.state.id;
  const polyglot = polyglotLoader();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { isLoadingPost, errorPost, sendRequestPost, clearErrorPost } = useHttpPostClient();
  const [prewviewAssessment, setPrewviewAssessment] = useState([]);

  useEffect(() => {
    const getSelectedQuestionaries = async () => {
      try {
        const responseData = await sendRequest(
          AssessmentService.get().getAssessmentPreviewByIdURL(selectedId),
        );
        const list = responseData;

        const mapA = {};
        const rootsA = [];
        list.forEach((v, i) => {
          mapA[v.value] = i;
          list[i].children = [];
        });

        list.forEach((v1, i) => {
          v1.tagLebel !== 0 ? list[mapA[v1.tagLebel]].children.push(v1) : rootsA.push(v1);
        });
        setPrewviewAssessment(rootsA);
      } catch (err) {
        console.log(err);
      }
    };
    getSelectedQuestionaries();
  }, [sendRequest]);
  const handlePreviewAssessmentQuestions = async (e) => {
    e.preventDefault();
    // update submit  for assessment
    try {
      const response = await sendRequestPost(
        AssessmentService.get().postAssessmentSubmitURL(selectedId),
        JSON.stringify({ assessment_id: selectedId }),
        {
          "Content-Type": "application/json",
        },
      );

      if (response > 0) {
        displayToastMessage(polyglot.t("Assessment added successfully"), "success");
      }
    } catch (err) {
      console.log(err);
    }

    props.history.push({
      pathname: ASSESSMENT_RECIPIENT_SHARE_PATH,
      state: { mode: "add", id: selectedId, title: props.location.state.title },
    });
  };
  return (
    <div className="row m-0">
      <div className="col-md-3 pl-0 mb-2">
        <Panel className="panel h-100">
          <LeftPanelQuestionaris selectedId={selectedId} />
        </Panel>
      </div>

      <div className="col-md-9 usertoadd-responsive p-0 mt-3 pr-3">
        <div className="row">
          <div className="col-md-12 grc-assment-progressbar">
            <Progress percent={100} indicators={["Create", "Set Questions", "Preview"]} />
          </div>
        </div>

        <Panel className="panel p-0">
          <div className="row pl-0 mb-3 mr-0 ml-0">
            <div className="col-md-12 p-0">
              <div className="card">
                <div className="card-header">
                  <span className="font-weight-bold">Assessment :</span>
                  {props.location.state !== undefined ? props.location.state.title : ""}
                </div>
                {error && <ErrorPanel error={error} onClear={clearError} />}
                {isLoading && (
                  <div>
                    <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
                  </div>
                )}
                {!isLoading && prewviewAssessment && (
                  <PreviewQuestionTree data={prewviewAssessment} selectedId={selectedId} />
                )}
                <div className="card-footer bg-transparent">
                  <ButtonPrimary
                    className="custome-pos"
                    onClick={(e) => handlePreviewAssessmentQuestions(e)}
                    ariaLabel={polyglot.t("Confirm")}
                  >
                    {polyglot.t("Confirm")}
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default Preview;
