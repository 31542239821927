import React, { Component } from "react";
import "antd/dist/antd.css";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import { DataContext } from "../../../../Services/Shared/Store.js";
import {
  getdataForProcessingAdmin,
  postProcessAdminDetails,
  getProcessingDatasetsDetailsAdmin,
} from "../../../../Services/DFED/AdminService";
import { Select, SelectOption, Button, Input } from "appkit-react";
import fastForwardImg from "../../../../Assets/Images/DFED/icon-fast-forward-red.svg";
import CustomModal from "../../../../Assets/Components/Modal";
import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { profileByIdService, profileDetailsById } from "../../../../Services/DFED/ProfileService";
$.DataTable = require("datatables.net-dt");

const STATUS_NOT_STARTED = "1";
const STATUS_IN_PROGRESS = "2";
const STATUS_COMPLETED = "3";
// ADMIN PROCESSING TAB CLASS RESPONSIBLE FOR HANDLING ALL PROCESSING RELATED FUNCTIONALITY======================
class UpdateProcessingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      processData: [],
      tableData: [],
      editColIndex: [],
      visiableData: [],
      selectedEditId: null,
      visible: false,
      collectionStatus: null,
      processingStatus: null,
      dataSetDetails: null,
      visible_1: false,
      projectid: this.props.projectid,
      fromFastfarword: null,
      processingtime: "",
    };
  }
  static contextType = DataContext;
  polyglot = polyglotLoader();
  // LIFE CYCLE METHOD FOR GETTING ALL PROCESSING DATA=====================
  componentDidMount() {
    this.dataForProcessingGrid();
    this.getProcessData();
  }
  // MODAL CANCLE FUNCTIONALITY========================
  handleCancel = (e) => {
    this.setState({ visible: false, visible_1: false });
  };
  // GETTING DATA FOR PROCESSING TABLE========================
  dataForProcessingGrid = async () => {
    let tableData = await getdataForProcessingAdmin(this.state.projectid);
    if (tableData && tableData.status === 200) {
      if (tableData.data && tableData.data.length > 0) {
        var dataForTbl = [];
        tableData.data.forEach((ele1) => {
          let processingStatus;
          if (
            ele1.PROCESSING_STATUS === "0" ||
            ele1.PROCESSING_STATUS === STATUS_NOT_STARTED ||
            ele1.PROCESSING_STATUS === null
          ) {
            processingStatus = this.polyglot.t("Not Started");
          } else if (ele1.PROCESSING_STATUS === STATUS_IN_PROGRESS) {
            processingStatus = this.polyglot.t("In Progress");
          } else {
            processingStatus = this.polyglot.t("Completed");
          }

          let collectionStatus;
          if (
            ele1.COLLECTION_STATUS === "0" ||
            ele1.COLLECTION_STATUS === STATUS_NOT_STARTED ||
            ele1.COLLECTION_STATUS === null
          ) {
            collectionStatus = this.polyglot.t("Not Started");
          } else if (ele1.COLLECTION_STATUS === STATUS_IN_PROGRESS) {
            collectionStatus = this.polyglot.t("In Progress");
          } else {
            collectionStatus = this.polyglot.t("Completed");
          }
          dataForTbl.push([
            ele1.ID,
            ele1.CustodianName,
            ele1.DataSetName,
            ele1.Profile,
            collectionStatus,
            processingStatus,
            ele1.DATASET_ID,
            ele1.PIPELINE_ID,
            ele1.PROCESSING_UPDATED_ON,
            ele1.PROCESSING_TIME,
            ele1.ProfileID,
          ]);
          // dataForTbl.push([ele1.ANALYSIS_NAME,tempCustodianName, ele1.datasetNm,ele1.runthrough, processingStatus, tempRunningKeywordStatus, ele1.armId])
          // });
        }); //OVER : MAIN FOREACH
        this.setState({ tableData: dataForTbl }, () => this.getProcessData());
        //clear out Search text field
        $("#example_filter input").val("");
        $("#example_filter input").trigger("click");
      } else {
        // alert("Error featching analysis details.")
      }
    } else {
      // alert("Error while sending request.")
    }
  };

  // CREATING AND GETTING ALL DATA FOR SHOW IN TABLE=======================
  getProcessData() {
    var data = this.state.tableData.map((obj) => Object.values(obj));
    var tabledt = $("#example").DataTable({
      lengthMenu: [5, 10, 15, 20],
      sDom: "lfrtp",
      oLanguage: {
        emptyTable: `${this.polyglot.t("No data available for this project")}`,
        sLengthMenu: `${this.polyglot.t("Show")} <select class="tabFocus">
                <option value="5">5</option>
                <option value="10">10</option>
                <option value="15">15</option>
                <option value="20">20</option>
                <option value="-1">${this.polyglot.t("All")}</option>
                </select> ${this.polyglot.t("entries")}
                `,
        sSearch: `${this.polyglot.t("Search")}`,
      },
      columns: [
        { bVisible: false, searchable: false },
        {
          title: this.polyglot.t("Custodian Name"),
          mRender: function (data, type, row) {
            if (row[7]) {
              return (
                data +
                '&nbsp&nbsp<img role="presentation" class="adminProcessingFromFastForward" src="' +
                fastForwardImg +
                '" />'
              );
            } else {
              return data;
            }
          },
        },
        { title: this.polyglot.t("Dataset Name") },
        {
          title: this.polyglot.t("Profile name"),
          class: "actionSortingRemove",
          data: null,
          mRender: (_, __, row) =>
            row[3] +
            '  <button aria-label="' +
            this.polyglot.t("download") +
            '" class="appkiticon icon-download-outline analysisGridDownloadIcon classprocessing_download" id="download_' +
            row[10] +
            '" data-id="' +
            row[10] +
            '">  </button>',
        },
        { title: this.polyglot.t("Collection Status") },
        { title: this.polyglot.t("Processing status") },
        {
          title: this.polyglot.t("Action"),
          class: "actionSortingRemove",
          data: null,
          mRender: (data, type, row) => {
            return (
              '<button aria-label="' +
              this.polyglot.t("edit") +
              '" class="appkiticon icon-edit-outline analysisGridEditIcon classprocessing_edit" id="processing_' +
              row[0] +
              "_" +
              row[4] +
              "_" +
              row[5] +
              "_" +
              row[7] +
              "_" +
              row[9] +
              '" data-id="' +
              row[0] +
              '">  </button> &nbsp; &nbsp; <button aria-label="' +
              this.polyglot.t("view") +
              '" class="appkiticon icon-view-fill analysisGridViewIcon editer_view" id="view_datasets_' +
              row[6] +
              '">  </button>'
            );
          },
        },
        { bVisible: false, searchable: false },
        { bVisible: false, searchable: false },
        { bVisible: false, searchable: false },
      ],
      order: [[8, "asc"]],
      data: data,
      destroy: true,
      lengthType: "simple",
      language: {
        info: "_PAGE_ of _PAGES_",
        paginate: {
          firt: "<<",
          previous: '<i_ngcontent-c10="" class="appkiticon icon-left-chevron-fill"> </i>',
          next: '<i_ngcontent-c10="" class="appkiticon icon-right-chevron-fill"> </i>',
          last: ">>",
        },
      },
      stateSave: true,
      drawCallback: function () {
        if (data.length <= 0) {
          $(".dataTables_paginate").hide();
        } else {
          $(".dataTables_paginate").show();
        }
      }, // OVER : drawback function
    }); //.draw(true); // OVER : data tbale
    tabledt.search("").draw();
    var tempReactThis_1 = this;

    $(document).on("click", "[id*='processing_']", function () {
      const polyglot = polyglotLoader();
      let idData = this.id.split("_");
      let selectedId = idData[1];
      let collectionStatus = idData[2];
      let processingStatus = idData[3];
      let processingTime = idData[5];
      let fromFastfarword = null;
      if (idData[4]) {
        fromFastfarword = idData[4];
      }
      let forId = [polyglot.t("Not Started"), polyglot.t("In Progress"), polyglot.t("Completed")];
      tempReactThis_1.showModal(
        forId.indexOf(collectionStatus) + 1,
        forId.indexOf(processingStatus) + 1,
        selectedId,
        fromFastfarword,
        processingTime,
      );
    });

    $(document).on("click", "[id*='view_datasets_']", function () {
      let idData = this.id.split("_");
      let selectedId = idData[2];
      console.log("clickk");
      tempReactThis_1.getProcessingDatasetsDetails(String(selectedId));
    });

    $(document).on("click", "[id*='download_']", async function () {
      let idData = this.id.split("_");
      let profileData = await profileDetailsById(idData[1]);

      if (profileData && profileData.status === 200) {
        const getValue = (itemValue, key) => {
          if (key === "adv_ocr_text") {
            switch (itemValue) {
              case 1:
                return "Replace";
              case 2:
                return "Append";
              default:
                return "Replace";
            }
          }
          return itemValue;
        };

        const convertArrayToObject = (array, key) => ({
          [key]: [...new Set(array.reduce((obj, item) => [...obj, getValue(item[key], key)], []))].reduce(
            (acc, curr, _, arr) => (arr.length > 1 && acc ? `${acc}, ${curr}` : curr),
            "",
          ),
        });
        const element = document.createElement("a");
        const data = Object.keys(profileData.data[0]).reduce(
          (ob, key) => ({ ...ob, ...convertArrayToObject(profileData.data, key) }),
          {},
        );

        const profileDetails = {};
        let projectName;
        let profileName;

        for (const [key, val] of Object.entries(data)) {
          const keywordSplited = key.split("_");

          if (keywordSplited[0] === "adv" && !profileDetails["advancedOptions"])
            profileDetails["advancedOptions"] = {};

          if (key === "deduplication") {
            profileDetails[key] = val.split(" ")[0];
          } else if (keywordSplited[1] === "general") {
            if (!profileDetails["advancedOptions"]["general"])
              profileDetails["advancedOptions"]["general"] = {};

            profileDetails["advancedOptions"]["general"][keywordSplited[2]] = val;
          } else if (keywordSplited[1] === "fileTypes") {
            profileDetails["advancedOptions"]["fileTypes"] = val ? val : "None";
          } else if (keywordSplited[1] === "ocr") {
            if (!profileDetails["advancedOptions"]["ocr"]) profileDetails["advancedOptions"]["ocr"] = {};

            profileDetails["advancedOptions"]["ocr"][keywordSplited[2]] = val;
          } else if (!profileName && key === "PROFILE_NAME") {
            profileName = data[key];
          } else if (!projectName && key === "PROJECT_NAME") {
            projectName = data["PROFILE_NAME"] === "Default" ? "All" : data[key];
          } else {
            profileDetails[key] = val;
          }
        }

        const file = new Blob([JSON.stringify(profileDetails)], { type: "text/json" });
        element.href = URL.createObjectURL(file);
        element.download = `${projectName}_${profileName}.json`;
        document.body.appendChild(element); // Required for this to work in FireFox
        element.click();
      }
      //TODO implement downloading profile informations, after backend prepare service to do it
    });
  }
  // GET DATASETS DETAING BASED ON PARTICULAR ID====================
  getProcessingDatasetsDetails = async (selectedId) => {
    let tempReactThis_1 = this;
    let tableData = await getProcessingDatasetsDetailsAdmin(selectedId, this.state.projectid);
    if (tableData && tableData.status === 200) {
      if (tableData.data && tableData.data.length > 0) {
        tempReactThis_1.showModal_1(tableData.data);
      }
    }
    // tempReactThis_1.showModal(forId.indexOf(collectionStatus) + 1, forId.indexOf(processingStatus) + 1, selectedId);
  };
  // SHOW MODAL WHEN WE CLCIK ON VIEW,EDIT====================

  showModal = (collection_id, processing_id, selectedId, fromFastfarword, processingTime) => {
    this.setState(
      {
        selectedEditId: selectedId,
        visible: true,
        collectionStatus: String(collection_id),
        processingStatus: String(processing_id),
        fromFastfarword: fromFastfarword,
        processingtime: processingTime,
      },
      () => {},
    );
  };
  showModal_1 = (data) => {
    this.setState(
      {
        dataSetDetails: data,
        visible_1: true,
      },
      () => {},
    );
  };
  // HANDLE CONFIRM BUTTON FUNCTIONALITY=====================
  handleConfirm = async (e) => {
    await postProcessAdminDetails(
      Number(this.state.collectionStatus),
      Number(this.state.processingStatus),
      this.state.selectedEditId,
      this.state.fromFastfarword,
      this.state.projectid,
      this.state.processingtime,
    );
    this.dataForProcessingGrid();
    this.handleCancel();
    //   this.setState({collectionStatus:null,processingStatus:null})
  };
  collectionStatusOnChng = (value) => {
    const processingStatus = { collectionStatus: value };
    if (value !== STATUS_COMPLETED) {
      processingStatus.processingStatus = STATUS_NOT_STARTED;
    }
    this.setState(processingStatus);
  };
  processingStatusOnChng = (value) => {
    this.setState({ processingStatus: value });
  };
  processingtimeOnChng = (value) => {
    this.setState({
      processingtime: value,
    });
  };
  // RENDER METHOD FOR ADMIN PROCESSING.======================
  // CONVERTING FILE SIZE ON STANDARD UNIT
  bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };
  render() {
    return (
      <div className="container analysisGridContainer">
        <div className="row analysisGridRow">
          <div className="col-md-12 DFED-Review-admin-two pl-0">
            <table id="example" className="display table-header-focus" width="100%" />
          </div>
        </div>
        <div></div>
        <CustomModal
          show={this.state.visible}
          size="md"
          onCancel={this.handleCancel}
          title={this.polyglot.t("Update processing status")}
          Footer={[
            <ButtonPrimary onClick={this.handleConfirm} ariaLabel={this.polyglot.t("Save")}>
              {this.polyglot.t("Save")}
            </ButtonPrimary>,
          ]}
        >
          <>
            <div className="row mrgnBtm10">
              <div className="col-md-5 labelDiv">
                <span className="editTest-align_processing">{this.polyglot.t("Collection status :")}</span>
              </div>
              <div className="col-md-7 labelDiv padding-top-point5">
                <Select
                  placeholder="Collection status"
                  showSearchOnToggle={true}
                  value={this.state.collectionStatus}
                  onSelect={this.collectionStatusOnChng.bind(this)}
                  className="admin-editinputboxes_align "
                >
                  <SelectOption key={"prepKeyStus_" + 1} value={"1"}>
                    {this.polyglot.t("Not Started")}
                  </SelectOption>
                  <SelectOption key={"prepKeyStus_" + 2} value={"2"}>
                    {this.polyglot.t("In Progress")}
                  </SelectOption>
                  <SelectOption key={"prepKeyStus_" + 3} value={"3"}>
                    {this.polyglot.t("Completed")}
                  </SelectOption>
                </Select>
              </div>
            </div>

            <div className="row mrgnBtm10">
              <div className="col-md-5 labelDiv">
                <span className="editTest-align_processing">{this.polyglot.t("Processing status :")}</span>
              </div>
              <div className="col-md-7 labelDiv padding-top-point5">
                <Select
                  placeholder="Processing status"
                  showSearchOnToggle={true}
                  value={this.state.processingStatus}
                  onSelect={this.processingStatusOnChng.bind(this)}
                  className="admin-editinputboxes_align "
                  disabled={this.state.collectionStatus !== "3"}
                >
                  <SelectOption key={"prepKeyStus_" + 1} value={"1"}>
                    {this.polyglot.t("Not Started")}
                  </SelectOption>
                  <SelectOption key={"prepKeyStus_" + 2} value={"2"}>
                    {this.polyglot.t("In Progress")}
                  </SelectOption>
                  <SelectOption key={"prepKeyStus_" + 3} value={"3"}>
                    {this.polyglot.t("Completed")}
                  </SelectOption>
                </Select>
              </div>
            </div>

            {this.state.collectionStatus === STATUS_COMPLETED &&
              (this.state.processingStatus === STATUS_NOT_STARTED ||
                this.state.processingStatus === STATUS_IN_PROGRESS) && ( //collection is completed and processing status is inprogress or not started then progress time will show
                <div className="row mrgnBtm10">
                  <div className="col-md-5 labelDiv">
                    <span className="editTest-align_processing">
                      {this.polyglot.t("Estimated processing time (In hours):")}
                    </span>
                  </div>
                  <div className="col-md-7">
                    <Input
                      placeholder={this.polyglot.t("Estimated processing time")}
                      type="number"
                      aria-label={this.polyglot.t("Estimated processing time")}
                      value={this.state.processingtime}
                      onChange={this.processingtimeOnChng.bind(this)}
                    />
                  </div>
                </div>
              )}
          </>
        </CustomModal>

        <CustomModal
          show={this.state.visible_1}
          onCancel={this.handleCancel}
          title={this.polyglot.t("Dataset details")}
          Footer={[]}
          showCloseButton={true}
          size="sm"
        >
          <>
            <div className="row mrgnBtm10">
              <div className="col-md-5">
                <span className="editTest-align_processing">{this.polyglot.t("File name")}</span>
              </div>
              <div className="col-md-4">
                <span className="editTest-align_processing">{this.polyglot.t("File size")}</span>
              </div>
              <div className="col-md-3">
                <span className="editTest-align_processing">{this.polyglot.t("MD5 value")}</span>
              </div>
            </div>
            {this.state.dataSetDetails &&
              this.state.dataSetDetails.map((item) => {
                return (
                  <div className="row ">
                    <div className="col-md-5">{item.FILE_NAME}</div>
                    <div className="col-md-4">{this.bytesToSize(item.FILE_SIZE)}</div>
                    <div className="col-md-3">{item.FILE_MD5_VALUE}</div>
                  </div>
                );
              })}
          </>
        </CustomModal>
      </div>
    );
  }
}
export default UpdateProcessingPage;
