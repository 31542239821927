/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react-hooks/exhaustive-deps */
import "../../../Assets/Css/QueenBase/CompanyCenter/CompanyCenter.css";
import { Divider } from "appkit-react";
import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";

import ClientInvoicing from "./ClientInvoicing";
import CompanyPackages from "./CompanyPackages";
import CompanyPackagesBase from "./CompanyPackagesBase";
import CompanyPackagesDF from "./CompanyPackagesDF";
import NoDataMsg from "./NoDataMsg";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/QueenBase/Root/loading-spin.svg";
import CompanyCenterService from "../../../Services/QueenBase/CompanyCenter.js/CompanyCenterService";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";

// COMPANY CENTER INITIAL SCREEN
function CompanyCenter({ history }) {
  const [baseList, setBaseList] = useState([]);
  const [solutionList, setSolutionList] = useState([]);
  const [tempData, setTempData] = useState({});
  const [loading, setloading] = useState(true);
  const [DFPackageConsumptionDetails, setDFPackageConsumptionDetails] = useState([]);
  const [DFEDPackageConsumptionDetails, setDFEDPackageConsumptionDetails] = useState([]);
  const { getUserLanguage } = useContext(DataContext);
  const polyglot = polyglotLoader(getUserLanguage());
  const language = getUserLanguage();

  const getDFPackage = async () => {
    try {
      const res = await CompanyCenterService.get().getDFPackageConsumptionDetails();
      if (res.CODE) {
        setDFPackageConsumptionDetails(res.data);
      } else {
        displayToastMessage(res.MSG, "error");
      }
    } catch (error) {
      displayToastMessage(error, "error");
    }
  };

  const getDFEDPackageConsumptionDetails = async () => {
    try {
      const res = await CompanyCenterService.get().getDFEDPackageConsumptionDetails();
      if (res.CODE) {
        setDFEDPackageConsumptionDetails(res.data);
      } else {
        displayToastMessage(res.MSG, "error");
      }
    } catch (error) {
      displayToastMessage(error, "error");
    }
  };

  const getBasePackage = async () => {
    try {
      const res = await CompanyCenterService.get().getBasePackageConsumptionDetails(language);
      const baseList = res.baseList;
      if (baseList.length) {
        setBaseList(baseList);
        setTempData({
          tempCount: null,
          price: baseList[0]?.temporaryuserprice,
          noofmonths: baseList[0]?.noofmonths,
        });
      } else {
        displayToastMessage(res.MSG, "error");
      }
    } catch (error) {
      displayToastMessage(error, "error");
    }
  };

  const getCompanyCenterList = async (language) => {
    const { CODE, MSG, solutionList } = await CompanyCenterService.get().getCompanyCenterList(language);
    if (CODE) {
      if (solutionList.length) {
        setSolutionList(solutionList);
      } else {
        displayToastMessage(polyglot.t("No solutions available"), "info");
      }
    } else {
      displayToastMessage(MSG, "error");
    }
  };

  useEffect(() => {
    async function fetchData() {
      setloading(true);
      await getCompanyCenterList(language);
      await getBasePackage(language);
      await getDFPackage();
      await getDFEDPackageConsumptionDetails();
      setloading(false);
    }
    void fetchData();
  }, [language]);

  if (loading) {
    return (
      <div className="container-fluid">
        <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
      </div>
    );
  }
  const displayCompanyPackages = () => {
    return solutionList.map((eachSolution, i) => {
      if (eachSolution.solutionid === 2) {
        return (
          <CompanyPackagesDF
            key={"cs_" + i}
            solution={eachSolution}
            solutionList={solutionList}
            dfPackageConsumptionDetails={DFPackageConsumptionDetails}
            history={history}
          />
        );
      } else {
        return (
          <React.Fragment key={i + 1}>
            <CompanyPackages
              solution={eachSolution}
              dfedPackageConsumptionDetails={DFEDPackageConsumptionDetails}
              solutionList={solutionList}
              history={history}
            />
          </React.Fragment>
        );
      }
    });
  };

  return (
    <div className="nav_company m-l-1">
      <div>
        <div className="col-md-10 p-l-0">
          {!loading && baseList.length ? (
            <CompanyPackagesBase
              history={history}
              basePackageConsumptionDetails={baseList}
              tempData={tempData}
            />
          ) : (
            <NoDataMsg solutionName={polyglot.t("Base Package")} />
          )}
          <br />
          <Divider className="cc-divider" />
          <div className=" package-title mt-3 main_title_qb">{polyglot.t("Solutions")}</div>
          {displayCompanyPackages()}
          <div className="marginTopMinus10"></div>
          <br />
          <Divider className="cc-divider" />
          <div>
            <ClientInvoicing />
          </div>
        </div>
      </div>
    </div>
  );
}
CompanyCenter.propTypes = {
  history: PropTypes.object,
};

export default CompanyCenter;
