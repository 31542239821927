import "../../DFED/Review/Review.css";
import { Carousel } from "antd";
import React, { useRef, useContext, useEffect } from "react";

import ChooseProject from "./ChooseProject";
import ChooseSurvey from "./ChooseSurvey";
import { StartContext } from "./Contexts/StartContext";
import RequestParameterFile from "./RequestParameterFile";
import SolutionService from "../../../Services/QueenBase/Solutions/SolutionService";
import { getProjectID_Of_solution_module } from "../../../Services/Shared/Handlers";
import { DataContext } from "../../../Services/Shared/Store";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";

// WORKFLOW FORM HAS MULTIPLE STEPS
const StartDFProject = () => {
  const { getUserLanguage } = useContext(DataContext);
  const { projectSurvey, setProjectSurvey, setDFCurrentPackages, DFCurrentPackages } =
    useContext(StartContext);

  const language = getUserLanguage();
  const projectId = getProjectID_Of_solution_module();

  // FETCH PACKAGE DETAILS OF DF
  const GetDfPackages = async () => {
    try {
      const solutionObj = {
        solutionId: 2,
        language,
      };
      const data = await SolutionService.get().getDfSolutionPackages(solutionObj);
      if (data.CODE === 0) {
        displayToastMessage(data.MSG, "error");
      } else {
        const initalDFPackages = [];
        let obj = {};
        data.forEach((r) => {
          obj = { ...r };
          obj.isHide = true;
          initalDFPackages.push(obj);
        });
        setDFCurrentPackages(initalDFPackages);
      }
    } catch (error) {
      displayToastMessage(error, "error");
    }
  };

  useEffect(() => {
    // if DFCurrentPackages are not present, then call the API to fetch them
    if (DFCurrentPackages.length === 0) {
      const projectSurveyClone = { ...projectSurvey };
      projectSurveyClone.project_id = projectId;
      setProjectSurvey(projectSurveyClone);
      GetDfPackages();
    }
  }, []);

  const slider = useRef();

  const next = (pageNum) => {
    slider.current.goTo(pageNum);
  };
  const goBack = () => {
    slider.current.prev();
  };

  return (
    <div className="container">
      <Carousel
        effect="fade"
        dots={false}
        ref={(ref) => {
          slider.current = ref;
        }}
      >
        {/* STEP 1 */}
        <div>
          <ChooseSurvey next={next} nextPage={1} />
        </div>
        {/* STEP 2 */}
        <div>
          <ChooseProject next={next} goBack={goBack} nextPage={2} />
        </div>
        {/* STEP 3 */}
        <div>
          <RequestParameterFile next={next} goBack={goBack} nextPage={3} />
        </div>
      </Carousel>
    </div>
  );
};

export default StartDFProject;
