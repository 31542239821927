import CommonService from "../Shared/CommonService";

class HomeService {
  constructor() {
    this.baseApiUrl = CommonService.get().getGRCBaseApiUrl();
  }

  getHomeCategoryURL() {
    return `${this.baseApiUrl}/getCategoriesHome`;
  }
  deleteCategoryURL(id) {
    return `${this.baseApiUrl}/deleteCategory/${id}`;
  }
  deleteQuestionURL(id) {
    return `${this.baseApiUrl}/deleteQuestion/${id}`;
  }
  transferQuestionnaire(questionnaireId) {
    return `${this.baseApiUrl}/transferQuestionnaire/${questionnaireId}`;
  }
}

// Enable Singleton behavior on this class
HomeService.__instance = null;
HomeService.get = () => {
  if (!HomeService.__instance) {
    HomeService.__instance = new HomeService();
  }
  return HomeService.__instance;
};

export default HomeService;
