import React, { Fragment } from "react";
import { cookieData, cookieData_german } from "../../../Json/creditContent.json";
import { Pagination } from "antd";
import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import { backCircle } from "../../../Assets/Icons";

class CookieSettingComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      licenseData: "",
      currentPage: 1,
      pageSize: 8,
      visableData: [],
      height: window.innerHeight,
      isBackEnable: true,
      userLanguage: "en",
    };
    this.callResize = this.callResize.bind(this);
    this.goBack = this.goBack.bind(this);
  }

  componentDidMount() {
    if (sessionStorage.length > 0) {
      this.setState({
        isBackEnable: true,
      });
    } else {
      this.setState({
        isBackEnable: false,
      });
    }
    window.scrollTo(0, 0);
    window.addEventListener("resize", this.callResize);
    const userLanguage = sessionStorage.getItem("USER_LANGUAGE") || "en";
    this.setState({ userLanguage });
    if (userLanguage === "en") {
      this.visiableDataHandler(cookieData, 1);
    }
    if (userLanguage === "ger") {
      this.visiableDataHandler(cookieData_german, 1);
    }
  }
  onChangeTablePageHandler = (page) => {
    const userLanguage = sessionStorage.getItem("USER_LANGUAGE") || "en";
    this.setState({ currentPage: page });
    if (userLanguage === "en") {
      this.visiableDataHandler(cookieData, page);
    }
    if (userLanguage === "ger") {
      this.visiableDataHandler(cookieData_german, page);
    }
  };

  visiableDataHandler = (data, page) => {
    const userLanguage = sessionStorage.getItem("USER_LANGUAGE") || "en";
    let lastIndex = this.state.pageSize * page;
    let firstIndex = lastIndex - this.state.pageSize;
    let visData = data.slice(firstIndex, lastIndex);
    this.setState({
      visableData: visData,
      userLanguage,
    });
  };
  callResize() {
    this.setState({
      height: window.innerHeight,
    });
  }
  componentWillUnmount() {
    window.removeEventListener("resize", this.callResize);
  }
  goBack() {
    this.props.history.goBack();
  }

  resetCurrentPage = () => {
    this.setState({ currentPage: 1 });
  };

  componentDidUpdate(prevProps, prevState) {
    const userLanguage = sessionStorage.getItem("USER_LANGUAGE") || "en";
    if (userLanguage !== prevState.userLanguage) {
      if (userLanguage === "en") {
        this.visiableDataHandler(cookieData, 1);
        this.resetCurrentPage();
      }
      if (userLanguage === "ger") {
        this.visiableDataHandler(cookieData_german, 1);
        this.resetCurrentPage();
      }
    }
  }

  render() {
    const footerTermsAndContainerHeight = {
      minHeight: this.state.height + "px",
    };
    const userLanguage = sessionStorage.getItem("USER_LANGUAGE") || "en";
    return (
      <Fragment>
        {userLanguage === "en" && (
          <div className="row m-0">
            <div className="col-md-1"></div>

            <div style={footerTermsAndContainerHeight} className="col-md-10 m-t-20 p-l-0">
              <div className="row m-0">
                <div className=" col-md-8 header-credit-page p-l-1-p m-b-0 ">{"Cookies"} </div>
                <div className="col-md-4 p-0 float-right m-t-7">
                  {this.state.isBackEnable && (
                    <ButtonPrimaryWithIcon
                      onClick={this.goBack.bind(this)}
                      iconName={backCircle}
                      ariaLabel={"Back"}
                    >
                      {"Back"}
                    </ButtonPrimaryWithIcon>
                  )}
                </div>
              </div>
              <div className="dis-st-f">
                <p className="footer-link-description-paragraph p-l-1-p m-t-7">
                  {
                    "Cookies are small text files that are placed on your computer by the websites that you visit. They are widely used in order to make websites work, or work more efficiently, as well as to provide information to the owners of the site. The use of cookies is now standard for most websites. If you are uncomfortable with the use of cookies, you can manage and control them through your browser, including removing cookies by deleting them from your ‘browser history’ (cache) when you leave the site."
                  }
                </p>
                <h3 className="header-credit-page p-l-1-p">
                  <b> {"Types of cookies:"}</b>
                </h3>
                <p className="footer-link-description-paragraph p-l-1-p">
                  {
                    "'Session' cookies remain in your browser during your browser session only, ie until you leave the website."
                  }
                </p>
                <p className="footer-link-description-paragraph p-l-1-p">
                  {"'Persistent' cookies remain in your browser after the session (unless deleted by you)"}.
                </p>
                <p className="footer-link-description-paragraph p-l-1-p">
                  {
                    "'Performance' cookies collect information about your use of the website, such as webpages visited and any error messages; they do not collect personally identifiable information, and the information collected is aggregated such that it is anonymous.Performance cookies are used to improve how a website works."
                  }
                </p>
                <p className="footer-link-description-paragraph p-l-1-p">
                  {
                    "'Functionality' cookies allow the website to remember any choices you make about the website (such as changes to text size, customised pages) or enable services such as commenting on a blog."
                  }
                </p>
                <h3 className="header-credit-page p-l-1-p">
                  <b>{"Use of cookies by PwC:"}</b>
                </h3>
                <p className="footer-link-description-paragraph p-l-1-p">
                  {"The following table explains the way in which we use cookies on this website."}
                </p>
                <div className="row m-l-0 p-l-1-p m-r-0 ">
                  <table className="table table-hover table-white">
                    <thead>
                      <tr>
                        <th className="credit-table-header" scope="col">
                          {"Cookie"}
                        </th>
                        <th className="credit-table-header" scope="col">
                          {"Name"}
                        </th>
                        <th className="credit-table-header" scope="col">
                          {"Purpose"}
                        </th>
                        <th className="credit-table-header" scope="col">
                          {"Type"}
                        </th>
                        <th className="credit-table-header" scope="col">
                          {"Duration"}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.visableData &&
                        this.state.visableData.length > 0 &&
                        this.state.visableData.map((cookie, index) => {
                          return (
                            <tr key={index}>
                              <th className="credit-table-row-data">{cookie.cookie}</th>
                              <th className="credit-table-row-data">{cookie.name}</th>
                              <th className="credit-table-row-data">{cookie.purpose}</th>
                              <th className="credit-table-row-data">{cookie.type}</th>
                              <th className="credit-table-row-data">{cookie.duration}</th>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="row m-b-20 m-l-0 p-l-3">
                  <div className="col-md-12 p-l-0">
                    <Pagination
                      current={this.state.currentPage}
                      size="small"
                      pageSize={this.state.pageSize}
                      total={cookieData.length}
                      onChange={this.onChangeTablePageHandler}
                    />
                  </div>
                </div>
                <div className="row m-l-0 ">
                  <p className="footer-link-description-paragraph p-l-1-p">
                    The legal basis for the use of technically necessary cookies is Section 25 par. 2 number 2
                    of the German Telecommunication-Telemedia- Act ("TTDSG") and on the basis of Art. 6 par. 1
                    f) GDPR to protect our legitimate interests. Our legitimate interests here lie in
                    particular in being able to provide you with a technically optimized and user-friendly
                    website designed to meet your needs, as well as to ensure the security of our systems.
                  </p>
                </div>
                <div className="row m-l-0 ">
                  <p className="footer-link-description-paragraph p-l-1-p">
                    {
                      "By using this website, you agree that we can place these cookies on your computer/device for the above purposes."
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
        {userLanguage === "ger" && (
          <div className="row m-0">
            <div className="col-md-1"></div>

            <div style={footerTermsAndContainerHeight} className="col-md-10 m-t-20 p-l-0">
              <div className="row m-0">
                <div className=" col-md-8 header-credit-page p-l-1-p m-b-0 ">{"Cookies"} </div>
                <div className="col-md-4 p-0 float-right m-t-7">
                  {this.state.isBackEnable && (
                    <ButtonPrimaryWithIcon
                      onClick={this.goBack.bind(this)}
                      iconName={backCircle}
                      ariaLabel={"Zurück"}
                    >
                      {"Zurück"}
                    </ButtonPrimaryWithIcon>
                  )}
                </div>
              </div>
              <div className="dis-st-f">
                <p className="footer-link-description-paragraph p-l-1-p m-t-7">{`Cookies sind kleine Textdateien mit Konfigurationsinformationen, die bei Aufrufen unserer Webseite von unseren Webservern an Ihren Browser gesandt und von diesem auf Ihren Rechner für einen späteren Abruf bereitgehalten werden. Mithilfe von Cookies kann unsere Webseite Informationen aus Ihrem Browser abrufen oder speichern. Hierbei kann es sich um Informationen über Sie, Ihre Einstellungen oder Ihr Gerät handeln. Meist werden sie verwendet, um die erwartungsgemäße Funktion der Website zu gewährleisten. In der Regel identifizieren diese Informationen Sie nicht direkt. Sie können Ihnen aber ein personalisierteres Web-Erlebnis bieten.
Wenn Sie mit der Verwendung von Cookies nicht einverstanden sind, können Sie diese über Ihren Browser verwalten, steuern und entfernen, indem Sie sie nach dem Verlassen der Webseite aus Ihrem Browserverlauf (Cache) löschen.`}</p>
                <h3 className="header-credit-page p-l-1-p">
                  <b> {"Arten von Cookies:"}</b>
                </h3>
                <p className="footer-link-description-paragraph p-l-1-p">
                  {
                    "'Session' -Cookies verbleiben nur während Ihrer Browsersitzung in Ihrem Browser, d.h. bis Sie die Webseite verlassen."
                  }
                </p>
                <p className="footer-link-description-paragraph p-l-1-p">
                  {
                    "'Dauerhafte' Cookies verbleiben nach der Sitzung in Ihrem Browser (sofern sie nicht von Ihnen gelöscht wurden)"
                  }
                  .
                </p>
                <p className="footer-link-description-paragraph p-l-1-p">
                  {
                    "'Leistungs' -Cookies sammeln Informationen über Ihre Nutzung der Webseite, z.B. besuchte Webseiten und Fehlermeldungen. Sie sammeln keine personenbezogenen Daten und die gesammelten Informationen werden anonym zusammengefasst. Leistungscookies werden verwendet, um die Funktionsweise von Webseiten zu verbessern."
                  }
                </p>
                <p className="footer-link-description-paragraph p-l-1-p">
                  {
                    "'Funktions' -Cookies erlauben es der Webseite, sich an alle Entscheidungen zu erinnern, die Sie bezüglich der Webseite getroffen haben (z.B. Änderung der Textgröße, angepasste Seiten), oder Dienste wie das Kommentieren eines Blogs zu aktivieren."
                  }
                </p>
                <h3 className="header-credit-page p-l-1-p">
                  <b>{"Verwendung von Cookies durch PwC:"}</b>
                </h3>
                <p className="footer-link-description-paragraph p-l-1-p">
                  {"In der folgenden Tabelle wird erläutert, wie wir Cookies auf dieser Webseite verwenden."}
                </p>
                <div className="row m-l-0 p-l-1-p m-r-0 ">
                  <table className="table table-hover table-white">
                    <thead>
                      <tr>
                        <th className="credit-table-header" scope="col">
                          {"Cookie"}
                        </th>
                        <th className="credit-table-header" scope="col">
                          {"Name"}
                        </th>
                        <th className="credit-table-header" scope="col">
                          {"Verwendungszweck"}
                        </th>
                        <th className="credit-table-header" scope="col">
                          {"Art"}
                        </th>
                        <th className="credit-table-header" scope="col">
                          {"Dauer"}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.visableData &&
                        this.state.visableData.length > 0 &&
                        this.state.visableData.map((cookie, index) => {
                          return (
                            <tr key={index}>
                              <th className="credit-table-row-data">{cookie.cookie}</th>
                              <th className="credit-table-row-data">{cookie.name}</th>
                              <th className="credit-table-row-data">{cookie.purpose}</th>
                              <th className="credit-table-row-data">{cookie.type}</th>
                              <th className="credit-table-row-data">{cookie.duration}</th>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                </div>
                <div className="row m-b-20 m-l-0 p-l-3">
                  <div className="col-md-12 p-l-0">
                    <Pagination
                      current={this.state.currentPage}
                      size="small"
                      pageSize={this.state.pageSize}
                      total={cookieData_german.length}
                      onChange={this.onChangeTablePageHandler}
                    />
                  </div>
                </div>
                <div className="row m-l-0 ">
                  <p className="footer-link-description-paragraph p-l-1-p">
                    Rechtsgrundlage für die Nutzung technisch erforderlicher Cookies ist § 25 Abs. 2 Nr. 2
                    TTDSG (“Telekommunikations-Telemediendatenschutz-Gesetz”) bzw. auf Grundlage des Art. 6
                    Abs. 1 lit. f DSGVO zur Wahrung unserer berechtigten Interessen. Unsere berechtigten
                    Interessen liegen hierbei insbesondere darin, Ihnen eine technisch optimierte sowie
                    benutzerfreundlich und bedarfsgerecht gestaltete Website zur Verfügung stellen zu können
                    sowie die Sicherheit unserer Systeme zu gewährleisten.
                  </p>
                </div>
                <div className="row m-l-0 ">
                  <p className="footer-link-description-paragraph p-l-1-p">
                    {
                      "Durch die Nutzung dieser Webseite erklären Sie sich damit einverstanden, dass wir diese Cookies für die oben genannten Zwecke auf Ihrem Computer / Gerät platzieren können."
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </Fragment>
    );
  }
}

export default CookieSettingComponent;
