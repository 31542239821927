import { Pagination } from "antd";
import React, { useState } from "react";

import { ViewClientDetailsUser } from "./ViewClientDetailsUser";
import ButtonGroupCustom from "../../../Assets/Components/ButtonGroupCustom/ButtonGroupCustom";
import { PER_PAGE_COUNT } from "../../../Constants/Constant";
import { polyglotLoader } from "../../../Services/Shared/Translate";

const PER_PAGE = PER_PAGE_COUNT;

// VIEW CLIENT DETAILS
const ViewClientDetails = ({ clientRow, listOfUsers, acivateUser }) => {
  var polyglot = polyglotLoader();

  const [searchStr, setSearch] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedView, setSelectedView] = useState("all");

  // ON SEARCH FUNCTION
  const onSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  // SEARCH KEYS
  const searchKeys = (listObj) => {
    const keys = ["firstname", "lastname", "email"];
    const keyValues = keys.map((key) => listObj[key]);
    return keyValues.toString();
  };
  // HANDLE PAGE CHANGE
  const handlePageChange = (change) => {
    setCurrentPage(change);
  };

  // GET CURRENT PAGE ITEMS
  const getCurrentPageItems = (jsonArray) => {
    return jsonArray.slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE);
  };
  // GET LIST BY VIEW
  const getListByView = (jsonArray) => {
    switch (selectedView) {
      case "Active": {
        return jsonArray.filter((userObj) => userObj.IDAMID);
      }
      case "Inactive": {
        return jsonArray.filter((userObj) => !userObj.IDAMID);
      }
      default:
        return jsonArray;
    }
  };
  // FILTER BY SEARCH
  const filterBySearch = (jsonArray) => {
    let filteredItems = [...jsonArray];
    if (searchStr !== "") {
      filteredItems = jsonArray.filter((r) => searchKeys(r).includes(searchStr.toLowerCase()));
    }
    const listBasedOnView = getListByView(filteredItems);
    return listBasedOnView;
  };

  const activate = (user) => {
    acivateUser(clientRow, user);
  };

  // DISPLAY USERS LIST
  const displayUsersList = () => {
    return (
      <>
        <div className="row mb-1">
          <div className="col-md-3">
            <b>{polyglot.t("Name")}</b>
          </div>
          <div className="col-md-3">
            <b>{polyglot.t("Email")}</b>
          </div>
          <div className="col-md-3 text-align-center">
            <b>{polyglot.t("Status")}</b>
          </div>
          <div className="col-md-3 text-align-center" />
        </div>

        {filterBySearch(listOfUsers).length > 0 && (
          <>
            {getCurrentPageItems(filterBySearch(listOfUsers)).map((userItem, index) => (
              <ViewClientDetailsUser key={index} activate={activate} userItem={userItem} />
            ))}
          </>
        )}
        {filterBySearch(listOfUsers).length === 0 && (
          <div className="mb-1 text-align-center">{polyglot.t("No users found")}</div>
        )}
      </>
    );
  };

  // DISPLAY PAGINATION
  const displayPagination = () => {
    return (
      <div className="border-color-active">
        <Pagination
          current={currentPage}
          size="small"
          pageSize={PER_PAGE}
          total={filterBySearch(listOfUsers).length}
          onChange={handlePageChange}
          className="margin-left-minus-point5"
        />
      </div>
    );
  };

  return (
    <>
      <div className="border-top a-border-light"></div>
      {listOfUsers.length > 0 && (
        <div className="row user-list-container">
          <div className="col-md-5">
            <div className="row m-l-0 m-r-0 filter-search-qb">
              <div className="searchIcon oval-border">
                <input
                  value={searchStr}
                  className="oval-search-bar search-input"
                  placeholder={`${polyglot.t("Search")}...`}
                  onChange={onSearch}
                  aria-label={polyglot.t("Search")}
                />
              </div>
            </div>
          </div>
          <div className="col-md-3"></div>
          <div className="col-md-4 text-align-right">
            <ButtonGroupCustom
              value={selectedView}
              onChange={(e, v) => {
                setCurrentPage(1);
                setSelectedView(v);
              }}
              tabs={[
                { value: "all", name: "All users" },
                { value: "Active", name: "Active" },
                { value: "Inactive", name: "Inactive" },
              ]}
            />
          </div>
        </div>
      )}
      <div className="user-list-container">
        {listOfUsers.length > 0 && displayUsersList()}
        {listOfUsers.length === 0 && (
          <div className="mb-1 text-align-center">{polyglot.t("No users found")}</div>
        )}
        {listOfUsers.length > 0 && filterBySearch(listOfUsers).length > PER_PAGE && displayPagination()}
      </div>
      <div className="mb-3"></div>
    </>
  );
};

export default ViewClientDetails;
