import React, { useContext } from "react";
import { BrowserRouter } from "react-router-dom";

import NoAccess from "./Assets/Components/NoAccess";
import GRCAssessmentHeaderContainer from "./Components/GRC/PerformAssessment/GRCAssessmentHeaderContainer";
import FooterComponent from "./Components/QueenBase/Root/FooterComponent";
import HeaderContainer from "./Components/QueenBase/Root/HeaderContainer";
import { GRCAsessmentRoutesBeforeLogin } from "./Routes/GRCAssessmentLandingRoute";
import GRCAssessmentModuleRoutes from "./Routes/GRCAssessmentModuleRoutes";
import LandingRoute, { RoutesBeforeLogin } from "./Routes/LandingRoute";
import { DataContext } from "./Services/Shared/Store";
import IdleTimerContainer from "./utility/IdleTimerContainer";
import IdleTimerContainerBeforeJWT from "./utility/IdleTimerContainerBeforeJWT";
import PerformAssessmentIdleTimerContainer from "./utility/PerformAssessmentIdleTimerContainer";

function ConnectedDigitalServices() {
  const { userLanguage } = useContext(DataContext);
  return (
    <>
      <IdleTimerContainerBeforeJWT />
      <IdleTimerContainer />
      <BrowserRouter basename={process.env.REACT_APP_PROJECT_NAME}>
        <HeaderContainer />
        <RoutesBeforeLogin />
        <LandingRoute userLanguage={userLanguage} />
        <FooterComponent />
      </BrowserRouter>
    </>
  );
}

export function GRCAssessmentApplication() {
  const { userLanguage } = useContext(DataContext);
  return (
    <>
      {/* <IdleTimerContainerBeforeJWT /> */}
      {/* <IdleTimerContainer /> */}
      <PerformAssessmentIdleTimerContainer />
      <BrowserRouter basename={process.env.REACT_APP_GRC_ASSESSMENT_APPLICATION}>
        <div style={{ width: "100%", height: "100%", position: "relative" }}>
          <div
            id="header"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              zIndex: 1,
              height: "73px",
            }}
          >
            <GRCAssessmentHeaderContainer />
          </div>
          <div
            id="content"
            style={{
              position: "absolute",
              top:
                document.getElementsByClassName("nav").length || document.getElementById("top-navbar")
                  ? "114px"
                  : "73px",
              bottom: "67px",
              right: 0,
              left: 0,
              overflow: "auto",
              zIndex: 0,
            }}
          >
            <GRCAsessmentRoutesBeforeLogin />
            <GRCAssessmentModuleRoutes userLanguage={userLanguage} />
          </div>
          <div
            id="footer"
            style={{
              position: "absolute",
              bottom: 0,
              height: "67px",
              left: 0,
              right: 0,
              overflow: "hidden",
              zIndex: 1,
            }}
          >
            <FooterComponent />
          </div>
        </div>
      </BrowserRouter>
    </>
  );
}

function AppLayouts() {
  const renderApplication = () => {
    try {
      if (window.location.pathname.includes(process.env.REACT_APP_GRC_ASSESSMENT_APPLICATION)) {
        return <GRCAssessmentApplication />;
      }
      return <ConnectedDigitalServices />;
    } catch (err) {
      return <NoAccess />;
    }
  };

  return <>{renderApplication()}</>;
}

export default AppLayouts;
