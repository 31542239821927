import React, { useEffect, useState } from "react";

import NavBar from "./NavBar";
import MessageOfTheDay from "../../../Assets/Components/MessageOfTheDay";
import QueenBaseRoute from "../../../Routes/QueenBaseRoute";
import { userLanguage } from "../../../Services/Shared/Translate";
import NeedHelpHome from "../../DFED/Support/NeedHelpHome";

function Home(props) {
  const [windowHeight, setWindowHeight] = useState(0);
  // CODE FOR CALCULATE HEIGHT BASEON SCREEN SIZE===
  let resizeWindw = () => {
    setWindowHeight(window.innerHeight);
  };
  useEffect(() => {
    resizeWindw();
    window.addEventListener("resize", resizeWindw);
    return () => window.removeEventListener("resize", resizeWindw);
  }, []);

  return (
    <div>
      <NavBar userlanguage={userLanguage} />
      <div>
        {/* DISPLAY MESSAGE BAR */}
        <MessageOfTheDay />
        <div className="container col-md-10 screen-layout p-0" style={{ minHeight: "56vh" }}>
          <QueenBaseRoute />
        </div>
        <NeedHelpHome props={props} />
      </div>
    </div>
  );
}

export default Home;
