// sorts the display date for jquery datatable
// NOTE: this file works if display date is in DD.MM.YYYY format
/*
    How to use.
    1. Import this file to the file where jquery data table is used
    2. add type: 'date-de' to the desired date column
        Eg: 
        columns: [
            { title: this.polyglot.t("ID") },
            { title: this.polyglot.t("Bulk upload on"), type: 'date-de' },
        ]
    3. That's it you are ready to go.
*/

import $ from "jquery";
$.extend($.fn.dataTableExt.oSort, {
  "date-de-pre": function (a) {
    if (a == null || a === "") {
      return 0;
    }
    var deDatea = a.split(".");
    return (deDatea[2] + deDatea[1] + deDatea[0]) * 1;
  },

  "date-de-asc": function (a, b) {
    return a < b ? -1 : a > b ? 1 : 0;
  },

  "date-de-desc": function (a, b) {
    return a < b ? 1 : a > b ? -1 : 0;
  },
});
