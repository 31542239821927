import React from "react";
import { FormGroup, Input } from "reactstrap";
import "./FormField.css";

class FormTextAreaComponent extends React.Component {
  onChange(event) {
    this.setState({
      val: event.target.value,
    });
    this.props.onChangeFormField(this.props.name, event.target.value);
  }

  render() {
    return (
      <FormGroup>
        <Input
          disabled={this.props.disabledTextFields}
          className="customInpputBox customTextArea bg-color-input"
          type="textarea"
          name={this.props.name}
          id={this.props.id}
          value={this.props.val}
          placeholder={this.props.placeholder}
          onChange={(event) => this.onChange(event)}
          {...this.props}
        />
      </FormGroup>
    );
  }
}

export default FormTextAreaComponent;
