import "../../../../Assets/Css/QueenBase/CompanyCenter/CompanyCenter.css";
import { Divider, Panel } from "appkit-react";
import PropTypes from "prop-types";
import React from "react";

function PackageDetailsHeader({ packageName, packagePeriod, children }) {
  return (
    <Panel className="cc-panel-content-data">
      <div className="col-md-12 p-0">
        <div className="row">
          <div className="col-md-12 ">
            <div className="cc-title pl-0 pr-0 ">
              <label className=" subtitle_two_title_qb ">{packageName}</label>
              <label className="packageDetailsCard">{packagePeriod}</label>
            </div>
          </div>
        </div>
      </div>
      <Divider />
      {children}
    </Panel>
  );
}
PackageDetailsHeader.propTypes = {
  packageName: PropTypes.string,
  packagePeriod: PropTypes.string,
  children: PropTypes.element,
};
export default PackageDetailsHeader;
