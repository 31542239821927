import "../../../../Assets/Css/QueenBase/CompanyCenter/CompanyCenter.css";
import { Tooltip } from "appkit-react";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import iconinfo from "../../../../Assets/Images/QueenBase/Root/icon-info.svg";
import { DFPackageInfoIconContent } from "../../../../Services/Shared/Handlers";
import { DataContext } from "../../../../Services/Shared/Store";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import GetPriceInGermanFormat from "../../Root/GetPriceInGermanFormat";
import displayToastMessage from "../../Root/displayToastMessage";

function PackageDetailsPanel({ packagePrice, planDescription, isDfSolution }) {
  const { getUserLanguage } = useContext(DataContext);
  const polyglot = polyglotLoader(getUserLanguage());

  const getDescriptionIcon = (descObj) => {
    if (descObj.plus === "true") {
      return "appkiticon icon-circle-plus-outline pointer cursor-default";
    } else if (descObj.tick === "true") {
      return "appkiticon icon-check-mark-fill pointer cursor-default";
    } else {
      return "";
    }
  };

  const displayListItem = (descriptionObj) => {
    try {
      return (
        <>
          <span className={getDescriptionIcon(descriptionObj)}></span>
          <div className="col-md-10 p-0 f-acc-B">
            {descriptionObj.sideHeading === "true" ? (
              <strong>{descriptionObj.name}</strong>
            ) : (
              <>{descriptionObj.name}</>
            )}
          </div>
        </>
      );
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  const displayChildList = (descriptionObj) => {
    try {
      return (
        <div className="ml-4 pl-3">
          {descriptionObj.children.map((each, i) => {
            const slNo = i + 1;
            return (
              <div className="row" key={slNo}>
                <span>{slNo}.&nbsp;</span>
                <div className="p-0 f-acc-B">{each.name}</div>
              </div>
            );
          })}
        </div>
      );
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  return (
    <div className="users-list">
      <ul>
        <li>
          <strong className="a-h4">{GetPriceInGermanFormat(packagePrice)}</strong>
          &nbsp;
          <span className="marginpoint35 ml-0 mb-0">{polyglot.t("Per_year")}</span>
        </li>
      </ul>
      {isDfSolution
        ? planDescription.length &&
          planDescription.map((descriptionObj, i) => (
            <div key={i} className="row marg_t_b ml-0">
              <span className={getDescriptionIcon(descriptionObj)} />
              <div className="col-md-10 f-acc-B pr-0">
                {descriptionObj.name}&nbsp;
                {descriptionObj.value && "-"}&nbsp;
                {descriptionObj.value}&nbsp;
              </div>
              <div className="col-md-1 p-0 cursor appkiticon pointer pr-0 df-info-card-tooltip">
                {descriptionObj.infoicon === "true" && (
                  <Tooltip
                    content={DFPackageInfoIconContent()}
                    placement="top"
                    className="a-md-pop"
                    tooltipTheme="light"
                  >
                    <img src={iconinfo} height="16px" alt={polyglot.t("More info")} />
                  </Tooltip>
                )}
              </div>
            </div>
          ))
        : planDescription.length &&
          planDescription.map((descriptionObj, i) => {
            return (
              <div key={i} className="row marg_t_b ml-0">
                {displayListItem(descriptionObj)}
                {descriptionObj.children && displayChildList(descriptionObj)}
              </div>
            );
          })}
    </div>
  );
}
PackageDetailsPanel.propTypes = {
  packagePrice: PropTypes.string,
  planDescription: PropTypes.array,
  isDfSolution: PropTypes.bool,
};
export default PackageDetailsPanel;
