module.exports.encrypt = function (jwtObj) {
  var ctyJwt = {};
  try {
    for (var key in jwtObj) {
      var text = jwtObj[key];
      if (typeof text === "string") {
        var text = Buffer.from(jwtObj[key]).toString("base64");
      }
      ctyJwt[key] = text;
    }
    return ctyJwt;
  } catch (e) {
    return null;
  }
};
