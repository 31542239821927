import { Panel } from "appkit-react";
import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import { ReactComponent as LoadingSpinner } from "../../../../Assets/Images/GRC/loading-spin.svg";
import RecipientService from "../../../../Services/GRC/RecipientService";
import { useHttpPostClient } from "../../../../Services/GRC/http-hook-post";
import ErrorPanel from "../../Shared/ErrorPanel";
import RecipientResponseQuesAnswers from "../RecipientResponseQuesAnswers";
import RecipientResponseQuestionTree from "../RecipientResponseQuestionTree";
import RecipientResponseText from "../RecipientResponseText";
import "./index.css";

const RecipientResponseDetails = (props) => {
  const uid = props.match.params.uid;

  const { isLoadingPost, errorPost, sendRequestPost, clearErrorPost } = useHttpPostClient();
  const [recipientAssessment, setRecipientAssessment] = useState([]);
  const [loadComponent, setloadComponent] = useState("");
  const [setid, setsetid] = useState(1);
  const [totalQuestions, setTotalQuestions] = useState([]);
  const [index, setIndex] = useState("");
  const [submittedId, setSubmittedId] = useState();
  const [apiValue, setApiValue] = useState(true);
  const mapA = {};
  const rootsA = [];

  useEffect(() => {
    const getSelectedQuestionaries = async () => {
      try {
        const postData = {
          unique_id: uid,
        };

        const responseData = await sendRequestPost(
          RecipientService.get().postRecipientAssessmentsURL(),
          JSON.stringify(postData),
          {
            "Content-Type": "application/json",
          },
        );
        const list = responseData[0];
        setTotalQuestions(responseData[1]);
        list.forEach((v, i) => {
          mapA[v.value] = i;
          list[i].children = [];
        });

        list.forEach((v1, i) => {
          v1.tagLebel !== 0 ? list[mapA[v1.tagLebel]].children.push(v1) : rootsA.push(v1);
        });
        setRecipientAssessment(rootsA);
      } catch (err) {
        console.log(err);
      }
    };
    getSelectedQuestionaries();
  }, [sendRequestPost, submittedId]);

  function handleContainerLoad(e, v, t, quesIndex) {
    console.log(e, v, t, quesIndex);
    setIndex(quesIndex);
    setloadComponent(t);
    setsetid(v);
    setApiValue(true);
  }
  function handleContainer(rightPanelClick) {
    setApiValue(rightPanelClick);
  }
  return (
    <div className="row m-0">
      <div className="col-md-3 pl-0 mb-2">
        {errorPost && <ErrorPanel error={errorPost} onClear={clearErrorPost} />}
        {isLoadingPost && (
          <div>
            <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
          </div>
        )}
        {!isLoadingPost && recipientAssessment && (
          <Panel className="panel h-100">
            <div className="pt-4 bottom-margin">
              <span className="heading">Assessment</span>
            </div>
            <hr className="recipient-ruler" />

            <RecipientResponseQuestionTree
              data={recipientAssessment}
              handleChanged={handleContainerLoad}
              totalQuestions={totalQuestions}
              setid={setid}
              uid={uid}
            />
          </Panel>
        )}
      </div>

      <div className="col-md-9 usertoadd-responsive p-0 mt-3">
        <Panel className="panel box-height-800">
          {loadComponent === "ques" ? (
            <RecipientResponseQuesAnswers
              questionIndex={index}
              id={setid}
              setsetid={setsetid}
              setApiValue={handleContainer}
              uid={uid}
            />
          ) : (
            <RecipientResponseText id={setid} />
          )}
        </Panel>
      </div>
    </div>
  );
};

export default withRouter(RecipientResponseDetails);
