import axios from "axios";

import SessionKeys from "./SessionKeys";
import SessionService from "./StorageService";
import HttpClient from "../Shared/HttpClient";

class ApplicationCongif {
  constructor() {
    this.client = new HttpClient();
  }

  // API TO GET APPLICATION CONFIG DETAILS
  async getApplicationConfig() {
    const currentConfig = SessionService.get(SessionKeys.APP_CONFIG);
    if (!currentConfig) {
      const res = await axios.get(process.env.REACT_APP_SERVER_HOST_BASE + "/getApplicationConfig");
      SessionService.set(SessionKeys.APP_CONFIG, JSON.stringify(res.data.data));
      return res.data.data;
    }
    return JSON.parse(currentConfig);
  }

  getApplicationName() {
    const data = SessionService.get(SessionKeys.APP_CONFIG);
    if (data) {
      return JSON.parse(data).title;
    }
    return null;
  }
}
// Enable Singleton behavior on this class
ApplicationCongif.__instance = null;
ApplicationCongif.get = () => {
  if (!ApplicationCongif.__instance) {
    ApplicationCongif.__instance = new ApplicationCongif();
  }
  return ApplicationCongif.__instance;
};

export default ApplicationCongif;
