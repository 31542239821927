import React, { Component } from "react";
import { Checkbox, TextArea } from "appkit-react";
import "./Criteria.css";
import "../DocumentList/DocumentList.css";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import { DataContext } from "../../../../Services/Shared/Store.js";
import imgloader from "../../../../Assets/Images/DFED/icon-review-loader.svg";
import {
  postProduction,
  getReviewTagsInfo,
  getProductionRequestData,
} from "../../../../Services/DFED/ReviewService";
import { Select, SelectOption } from "appkit-react";
import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { getSelectedTextForMultiSelectBox } from "../../../../Services/Shared/Handlers";

export class Criteria extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productionResult: props.productionResult,
      production_file_type: props.reviewFiles,
      search_type: props.searchType,
      custodians: [],
      datasets: [],
      analysis: [],
      selectedDatasetsId: [],
      selectedAnalysisId: [],
      selectedTagsId: [],
      reviewTagName: [],
      custodianNames: [],
      datasetNames: [],
      noteText: [],
      textArea: "",
      isEnable: false,
      selectedProjectId: props.selectedProjectId,
      isWithAnalysis: true,
    };
  }
  static contextType = DataContext;
  componentDidMount() {
    this.getProductionRequestData();
    this.getReviewTags();
  }
  // get review tag names
  async getReviewTags() {
    const { data: reviewTagName } = await getReviewTagsInfo();
    this.setState({ reviewTagName });
  }

  async getProductionRequestData() {
    const { data: productionRequestData } = await getProductionRequestData(this.state.selectedProjectId);
    this.setState({ ...productionRequestData });
  }
  // RUNS WHEN COMPONENT GETS UPDATED
  componentDidUpdate(prevProps, prevState) {
    if (
      this.state.textArea != prevState.textArea ||
      this.state.selectedTagsId.length > 0 ||
      this.state.selectedDatasetsId.length > 0 ||
      this.state.selectedAnalysisId.length > 0
    ) {
      const { formGotOpened } = this.context;
      formGotOpened();
    }
  }

  createCustomId(...ids) {
    return ids.join("-");
  }

  getDatasetById(id) {
    return this.state.datasets.find(({ DT_ID }) => Number(DT_ID) === Number(id));
  }

  getDatasetNameById(id) {
    const dataset = this.getDatasetById(id);
    return dataset.DT_DATASET_NAME;
  }

  getAnalysisById(id) {
    return this.state.analysis.find(({ ANALYSIS_ID }) => Number(ANALYSIS_ID) === Number(id));
  }

  getAnalysisNameById(id) {
    const analysis = this.getAnalysisById(id);
    return analysis.ANALYSIS_NAME;
  }

  getCustodianById(id) {
    return this.state.custodians.find(({ CRT_ID }) => Number(CRT_ID) === Number(id));
  }

  getCustodianNameById(id) {
    const custodian = this.getCustodianById(id);
    return custodian.CRT_ALIAS;
  }

  getAnalysis() {
    return this.state.analysis.map((data) => ({
      ...data,
      id: this.createCustomId(data.ANALYSIS_ID, data.CRT_ID),
    }));
  }

  getDatasets() {
    if (this.state.selectedAnalysisId.length === 0) {
      return this.state.datasets;
    }
    return this.state.datasets
      .filter(({ ANALYSIS_ID, CRT_ID }) =>
        this.state.selectedAnalysisId.includes(this.createCustomId(ANALYSIS_ID, CRT_ID)),
      )
      .map((data) => ({ ...data, id: this.createCustomId(data.DT_ID, data.ANALYSIS_ID, data.CRT_ID) }));
  }

  getDatasetsWithoutAnalysis() {
    return this.state.datasets
      .filter(
        ({ DT_ID, CRT_ID }, index, self) =>
          self.findIndex((data) => data.DT_ID === DT_ID && data.CRT_ID === CRT_ID) === index,
      )
      .map((data) => ({ ...data, id: this.createCustomId(data.DT_ID, data.ANALYSIS_ID, data.CRT_ID) }));
  }

  onChangeAnalysis(selectedAnalysisId) {
    if (this.state.selectedDatasetsId.length > 0) {
      const selectedDatasetsId = this.state.selectedDatasetsId.filter((datasetId) =>
        selectedAnalysisId.some((analysisId) => datasetId === this.createCustomId(datasetId, analysisId)),
      );
      this.onChangeDataset(selectedDatasetsId);
    }
    this.setState({ selectedAnalysisId });
  }

  onChangeDataset(selectedDatasetsId) {
    this.setState({ selectedDatasetsId });
  }

  onChangeTags(selectedTagsId) {
    this.setState({ selectedTagsId });
  }

  onChangeNote(arg) {
    let data = this.state.noteText;
    if (data.includes(arg)) {
      data.pop(arg);
      this.setState({ noteText: data });
    } else {
      data.push(arg);
      this.setState({ noteText: data });
    }
  }

  TextAreaHandler = (e) => {
    this.setState({ textArea: e });
  };
  //inserting  criteria related production details
  requestButton = () => {
    const isWithAnalysis = this.state.isWithAnalysis;
    const selectedTagsId = this.state.selectedTagsId.join();
    // ADD VALIDATION, IF CUSTODIAN IS PICKED WITHOUT PICKING DATASET OR ANALYSIS
    const DETAILS = this.state.selectedDatasetsId.map((id) => {
      const [datasetId, analysisId, custodianId] = id.split("-").map(Number);
      return {
        analysisId: isWithAnalysis ? analysisId : null,
        datasetId,
        custodianId,
      };
    });
    const productionData = {
      PROJECT_ID: this.state.selectedProjectId,
      REVIEW_SEARCH_TYPE: this.state.search_type,
      DETAILS,
      REVIEW_TAG_ID: selectedTagsId,
      REVIEW_NOTE: this.state.textArea,
      REVIEW_FILE_TYPE: this.state.noteText,
      REVIEW_STATUS: "1",
    };
    postProduction(productionData).then((res) => {
      this.props.docNav({ isFileUploaded: true });
    });
  };

  getDatasetSelectText(CRT_ID, ANALYSIS_ID) {
    const custodianText = `Custodian: ${this.getCustodianNameById(CRT_ID)}`;
    if (!this.state.isWithAnalysis) {
      return custodianText;
    }
    return `${custodianText} | Analysis: ${this.getAnalysisNameById(ANALYSIS_ID)}`;
  }
  render() {
    const polyglot = polyglotLoader();
    return (
      <div className="criteriaAlign">
        <div className="row m-l-0 m-r-0">
          <div className="col-md-12">
            <br />
            <div className="col-sm-12 col-md-12 p-t-12 mt-2 p-b-10 subtitle_three_title_qb_dark ">
              {polyglot.t(
                "Select specific criteria for document production or describe your own criteria in the notes field.",
              )}
            </div>
            {this.state.isWithAnalysis && (
              <div className="col-sm-12 col-md-12 p-t-12 mt-2  p-b-10">
                {this.getAnalysis().length > 0 ? (
                  <Select
                    placeholder={polyglot.t("Select analysis")}
                    multiple
                    showSelectAll
                    showSearchOnList
                    value={this.state.selectedAnalysisId}
                    onSelect={(e) => this.onChangeAnalysis(e)}
                    customSelectAllStringOnToggle={polyglot.t("All selected")}
                    customSelectedCountStringGenerator={(activeItemsLength) =>
                      getSelectedTextForMultiSelectBox(activeItemsLength, polyglot)
                    }
                  >
                    {this.getAnalysis().map(({ id, ANALYSIS_NAME, CRT_ID }) => {
                      return (
                        <SelectOption key={id} value={id}>
                          Custodian: {this.getCustodianNameById(CRT_ID)} | Analysis: {ANALYSIS_NAME}
                        </SelectOption>
                      );
                    })}
                  </Select>
                ) : (
                  <div> {polyglot.t("No analysis found")} </div>
                )}
              </div>
            )}
            <div className="col-sm-12 col-md-12 p-t-12 mt-2 p-b-10">
              {this.getDatasetsWithoutAnalysis().length > 0 ? (
                <Select
                  placeholder={polyglot.t("Select dataset")}
                  multiple
                  showSelectAll
                  showSearchOnList
                  value={this.state.selectedDatasetsId}
                  disabled={this.state.isWithAnalysis && this.state.selectedAnalysisId.length === 0}
                  onSelect={(e) => this.onChangeDataset(e)}
                  customSelectAllStringOnToggle={polyglot.t("All selected")}
                  customSelectedCountStringGenerator={(activeItemsLength) =>
                    getSelectedTextForMultiSelectBox(activeItemsLength, polyglot)
                  }
                >
                  {(this.state.isWithAnalysis ? this.getDatasets() : this.getDatasetsWithoutAnalysis()).map(
                    ({ id, DT_DATASET_NAME, CRT_ID, ANALYSIS_ID }) => {
                      return (
                        <SelectOption key={id} value={id}>
                          {this.getDatasetSelectText(CRT_ID, ANALYSIS_ID)} | Dataset: {DT_DATASET_NAME}
                        </SelectOption>
                      );
                    },
                  )}
                </Select>
              ) : (
                <div> {polyglot.t("No dataset found")} </div>
              )}
            </div>
            <div className="col-sm-12 col-md-12 p-t-12 mt-2 p-b-10">
              {this.state.reviewTagName.length > 0 ? (
                <Select
                  placeholder={polyglot.t("Select tags")}
                  multiple
                  showSelectAll
                  onSelect={(e) => this.onChangeTags(e)}
                  customSelectAllStringOnToggle={polyglot.t("All selected")}
                  customSelectedCountStringGenerator={(activeItemsLength) =>
                    getSelectedTextForMultiSelectBox(activeItemsLength, polyglot)
                  }
                >
                  {this.state.reviewTagName.map((data) => {
                    return (
                      <SelectOption key={data.label} value={data.value}>
                        {polyglot.t(data.label)}
                      </SelectOption>
                    );
                  })}
                </Select>
              ) : (
                <div>
                  {" "}
                  <img src={imgloader} className="criteria-loader" alt="img_loader" />
                  {polyglot.t("No tags found")}{" "}
                </div>
              )}
            </div>
            <div className="col-sm-12 col-md-12 p-t-19 mt-2 p-b-10">
              <TextArea
                aria-label={polyglot.t("Notes *")}
                placeholder={polyglot.t("Notes *")}
                className="fix-textarea"
                onChange={this.TextAreaHandler}
                value={this.state.textArea}
              />
            </div>
            <div className="col-sm-12 col-md-12 p-t-19 mt-2 p-b-10 review-chk">
              <Checkbox
                className="mt-2"
                value={!this.state.isWithAnalysis}
                onChange={() => this.setState({ isWithAnalysis: !this.state.isWithAnalysis })}
              >
                {polyglot.t("without_analysis")}
              </Checkbox>
              {this.state.production_file_type &&
                this.state.production_file_type.length > 0 &&
                this.state.production_file_type.map((data) => {
                  return (
                    <Checkbox
                      className="mt-2"
                      key={data.ID}
                      value={data.ID}
                      onChange={(e) => this.onChangeNote(data.ID)}
                    >
                      {polyglot.t(data.Type)}{" "}
                    </Checkbox>
                  );
                })}
            </div>
          </div>
        </div>
        <br />
        <div className="commomReviewBtn">
          <div className="col-sm-12 col-md-12">
            <ButtonPrimary
              onClick={this.requestButton}
              disabled={
                this.state.selectedDatasetsId.length === 0 ||
                this.state.selectedTagsId.length === 0 ||
                this.state.textArea.length === 0 ||
                (this.state.isWithAnalysis && this.state.selectedAnalysisId.length === 0)
              }
              ariaLabel={polyglot.t("Request production")}
            >
              {polyglot.t("Request production")}
            </ButtonPrimary>
          </div>
        </div>
      </div>
    );
  }
}
export default Criteria;
