import React from "react";
import "appkit-react/style/appkit-react.default.css";
import Download from "../../../Assets/Images/DFED/icon-download.svg";
import DownloadButton from "../../DFED/Shared/button/DownloadButton";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import { downloadIcon } from "../../../Assets/Icons";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import SearchService from "../../../Services/CSS/SearchService";

var polyglot = polyglotLoader();
class DownloadTemplate extends React.Component {
  constructor(props) {
    super(props);
    this.downloadTemplate = this.downloadTemplate.bind(this);
  }

  // DOWNLOAD PARAMETER FILE ON CLICK
  downloadTemplate = async (templateType) => {
    try {
      // API TO DOWNLOAD A PARAMETER FILE OF A WORKFLOW
      const downloadResponse = await SearchService.get().fetchTemplate(templateType);

      if (downloadResponse.CODE) {
        const buffer = Buffer.from(downloadResponse.file.buffer);
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", downloadResponse.file.fileName);
        document.body.appendChild(link);
        link.click();
        displayToastMessage(downloadResponse.MSG, "success");
        document.body.removeChild(link);
      } else {
        if (downloadResponse.MSG.errno === -4058) {
          displayToastMessage(`${polyglot.t("File not found")}!!`, "error");
        } else {
          displayToastMessage(downloadResponse.MSG, "error");
        }
      }
    } catch (error) {
      if (
        (error.message = "First argument must be a string, Buffer, ArrayBuffer, Array, or array-like object.")
      ) {
      } else {
        displayToastMessage(error.message, "error");
      }
    }
  };

  render() {
    const polyglot = polyglotLoader();
    const templates = ["xlsx", "csv"];

    return (
      <div className="d-flex flex-row justify-content-between">
        {templates.map((templateType) => (
          <div
            key={templateType}
            className="col-sm-6 col-md-6 pl-0 pr-0"
            onClick={() => this.downloadTemplate(templateType)}
          >
            <ButtonSecondary className="full-width-btn">
              <i className={`appkiticon ${downloadIcon}`} alt="download" />
              {templateType.toUpperCase()} - {polyglot.t("Download template")}
            </ButtonSecondary>
          </div>
        ))}
      </div>
    );
  }
}
export default DownloadTemplate;
