import PermissionService from "../Services/Shared/PermissionService";

//================ COMMON FUNCTION FOR CALLING FRONTEND PERMISSION SERVICE FROM ALL SOLUTION DF, DFED, BASE=======
// THIS FUNCTION WILL TAKE 3 PARAMETER @solutionName=DF/E-Discovery/BASE, @projectID= selectedProjectID, @moduleName=TAB NAME From navigation bar

export default {
  async commonFunctionForCallService(solutionName, selectedProjectId, moduleName) {
    try {
      var res = await PermissionService.get().getPermissionList(solutionName, selectedProjectId, moduleName);
      if (res && res.data) {
        return res.data[0];
      }
    } catch (err) {
      return err;
    }
  },
};
