import React, { useContext, useEffect } from "react";

import NoAccess from "../../../Assets/Components/NoAccess";
import { hasViewPermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";

import Dashboard from "./index";

// RENDERS ON /queenbase/mydashboard
const DashboardContainer = () => {
  // DATA FROM CONTEXT
  const { checkAndFetchRole, getCurrentUserPermissions } = useContext(DataContext);
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    const screenName = "Dashboard";
    checkAndFetchRole(screenName, "Base");
  }, []);
  const currentUserPermissions = getCurrentUserPermissions();
  return (
    <>
      {/* RENDER COMPONENT IF USER HAS VIEW PERMISSION */}
      {hasViewPermission(currentUserPermissions) && <Dashboard />}
      {/* RENDERS 'NO ACCESS' IF USER HAS VIEW PERMISSION */}
      {!hasViewPermission(currentUserPermissions) && <NoAccess />}
    </>
  );
};

export default DashboardContainer;
