import "../../../Assets/Css/QueenBase/CompanyCenter/CompanyCenter.css";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import PackageConsumptionCard from "./Cards/PackageConsumptionCard";
import PackageDetailsCard from "./Cards/PackageDetailsCard";
import SolutionHeader from "./Headers/SolutionHeader";
import * as path from "../../../Constants/Path";
import { hasEditPermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";

function CompanyPackagesDF({ solution, solutionList, history, dfPackageConsumptionDetails }) {
  const { getCurrentUserPermissions, getUserLanguage } = useContext(DataContext);
  const polyglot = polyglotLoader(getUserLanguage());
  const currentUserPermissions = getCurrentUserPermissions();
  const defaultPeriod = dfPackageConsumptionDetails[dfPackageConsumptionDetails.length - 1].period;
  const [dfPackagePeriod, setDfPackagePeriod] = useState(defaultPeriod);
  const getPackageInfoByPeriod = () =>
    dfPackageConsumptionDetails.filter((dfPackage) => dfPackage.period === dfPackagePeriod)[0];
  const [dfPackageInfo, setDfPackageInfo] = useState(getPackageInfoByPeriod());

  const navigate = (id) => {
    return history.push({
      pathname: path.SOLUTION_REVIEW_PATH,
      state: {
        id,
        solutionList,
        solution: solutionList.find((r) => r.solutionid === id),
      },
    });
  };

  useEffect(() => {
    setDfPackageInfo(getPackageInfoByPeriod());
  }, [dfPackagePeriod]);

  return (
    <SolutionHeader
      solutionName={polyglot.t("your_package", { name: solution.NAME })}
      selectOptions={dfPackageConsumptionDetails}
      onSelect={(period) => setDfPackagePeriod(period)}
      selectedPeriod={dfPackagePeriod}
      hasFilter
    >
      <div className="row">
        <div className="col-lg-4 col-sm-12">
          <PackageDetailsCard
            packageName={solution.title}
            packagePeriod={dfPackagePeriod}
            packagePrice={solution.price}
            packageUpgradeStatus={solution.packageUpgradeStatus}
            planDescription={dfPackageInfo.planDescription}
            onClick={() => navigate(solution.solutionid)}
            hasEditPermission={hasEditPermission(currentUserPermissions)}
            isDfSolution
          />
        </div>
        <div className="col-lg-4 col-sm-12">
          <PackageConsumptionCard packageInfo={dfPackageInfo} solutionId={solution.solutionid} />
        </div>
      </div>
    </SolutionHeader>
  );
}
CompanyPackagesDF.propTypes = {
  solution: PropTypes.object,
  history: PropTypes.object,
  solutionList: PropTypes.array,
  dfPackageConsumptionDetails: PropTypes.array,
};
export default withRouter(CompanyPackagesDF);
