import React, { Component } from "react";
import Dropzone from "../dropzone/Dropzone";
import "./Upload.css";
import Progress from "../progress/Progress";
import $ from "jquery";
import { v4 as uuidv4 } from "uuid";
import axios from "axios";
import FormFieldComponent from "../Shared/components/FormFieldComponent";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import "../../DFED/Shared/components/dragAndDrop";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import { Button, Tooltip } from "appkit-react";

import { Prompt } from "react-router-dom";
import { getUserIDFromSession } from "../../../Services/Shared/Authenticate";
import CustomModal from "../../../Assets/Components/Modal";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import { closeIcon } from "../../../Assets/Icons";
import { TextFieldLengthRegexValidation } from "../../../utility/textFieldValidation";

const TooltipText = (text) => {
  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#000",
                textTransform: "capitalize",
              }}
            >
              {text}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

var polyglot = polyglotLoader();
// UPLOAD CLASS FOR HANDLING DATASETS AND FILE UPLOAD FOR CUSTODIAN

class Upload extends Component {
  constructor(props) {
    super(props);

    this.state = {
      propsUploadData: this.props.UploadData,
      propsdatasetName: this.props.datasetName,
      propsfinalData: this.props.finalList,
      files: this.props.finalList || [],
      uploading: false,
      uploadNotStarted: true,
      uploadProgress: {},
      successfullUploaded: false,
      checkFileAftrAddDS: [],
      counter: 0,
      isUploadButtonDisable: this.props.finalList.length > 0 ? false : true,
      allDataSetDetail: this.props.dataSetDetails,
      md5InputText: [[]],
      visible: false,
      dataInE: "",
      ID: "",
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.uploadFiles = this.uploadFiles.bind(this);
    this.sendRequest = this.sendRequest.bind(this);
    this.renderActions = this.renderActions.bind(this);
    this.checkDatasetsAvailable = this.checkDatasetsAvailable.bind(this);
    this.onChangeFormField = this.onChangeFormField.bind(this);
  }
  static contextType = DataContext;

  fileReaderHandler(file) {
    this.props.fileReaderHandler(file, this.props.index);
  }

  componentWillReceiveProps() {
    this.setState({ propsdatasetName: this.props.datasetName });
    this.uploadButtonClick();
  }

  componentDidMount() {
    this.uploadButtonClick();
  }
  // componentWillUnmount
  onFilesAdded(files) {
    // CODE FOR CHECKING MIMETYPE IF MIME TYPE IS BLANK NO NEED TO UPLOAD FILE
    const nonValidFileExtensions = [".exe", ".msi", ".dll", ".bat", ".sh", ".bash"];
    const { index } = this.props;

    if (!files || files.length === 0) {
      return displayToastMessage("Invalid File MIME Type !  please upload valid file!", "error");
    }

    const isInvalidFileExtension = (fileName) => nonValidFileExtensions.some((ext) => fileName.includes(ext));

    const getFileDetails = (file) => {
      const datasetID = index !== undefined ? index : 0;
      const dGuid = uuidv4();
      const projectId = this.props.dsprojectProps.state.selectedProjectId;
      const projectName = this.props.dsprojectProps.state.selectedProjectName;

      return {
        datasetId: datasetID,
        datasetName: this.state.propsdatasetName?.[datasetID]?.[0] ?? null,
        dguid: dGuid,
        fileName: file.name,
        fileMimeType: file.type,
        fileSize: file.size,
        custodianId: this.state.propsUploadData.CRT_ID,
        custodianAlias: this.state.propsUploadData.CRT_ALIAS,
        projectId: projectId,
        projectName: projectName,
        newMd5Value: "",
      };
    };

    const updateMd5InputText = (fileDetails) => {
      const newMd5Entry = { md5Value: "", fileID: fileDetails.dguid };
      const updatedMd5InputText = [...this.state.md5InputText];

      if (updatedMd5InputText[index]) {
        updatedMd5InputText[index].push(newMd5Entry);
      } else {
        updatedMd5InputText.push([newMd5Entry]);
      }

      this.setState({ md5InputText: updatedMd5InputText });
    };

    for (const file of files) {
      if (index === undefined) continue;

      const fileDetails = getFileDetails(file);

      if (isInvalidFileExtension(file.name)) {
        return displayToastMessage(
          polyglot.t("Invalid File Extension !  please do not upload .exe,.msi,.dll,.bat,.sh,.bash !"),
          "error",
        );
      } else if (file.size > 10737418240) {
        return displayToastMessage(
          polyglot.t("Please check total file size before uploading it should not exceed 10 GB!"),
          "error",
        );
      }

      updateMd5InputText(fileDetails);
      Object.assign(file, fileDetails);
    }

    this.setState(
      (prevState) => ({
        files: prevState.files.concat(files),
        isUploadButtonDisable: false,
      }),
      () => {
        this.uploadButtonClick();
        this.fileReaderHandler(files);
      },
    );
  }

  async uploadFiles() {
    this.setState({
      uploadProgress: {},
      uploading: true,
      uploadNotStarted: false,
    });

    try {
      const promises = this.props.finalList.map((file) => this.sendRequest(file));
      await Promise.all(promises);
      this.setState({ successfullUploaded: true, uploading: false }, () => this.renderActions());
    } catch (errorText) {
      console.log("File upload error: ", errorText);
      displayToastMessage(polyglot.t(errorText), "error");
      this.setState({ successfullUploaded: false, uploading: false });
    }
  } // OVER : upload Files

  // AFTER UPLOADING FILE SENDING REQUEST TO BACKEND==================
  sendRequest(file) {
    return new Promise((resolve, reject) => {
      const req = new XMLHttpRequest();
      req.upload.addEventListener("progress", (event) => {
        if (event.lengthComputable) {
          const copy = { ...this.state.uploadProgress };
          copy[file.name] = {
            state: "pending",
            percentage: (event.loaded / event.total) * 100,
          };
          this.setState({ uploadProgress: copy });
        }
      });

      req.upload.addEventListener("load", (event) => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "done", percentage: 100 };
        this.setState({ uploadProgress: copy });
      });

      req.upload.addEventListener("error", (event) => {
        const copy = { ...this.state.uploadProgress };
        copy[file.name] = { state: "error", percentage: 0 };
        this.setState({ uploadProgress: copy });
        reject(req.response);
      });

      req.onreadystatechange = function () {
        if (req.readyState === XMLHttpRequest.DONE) {
          if (req.status === 200) {
            resolve(req.response);
          } else {
            console.error("Invalid response code:", req.status);
            reject(req.responseText);
          }
        }
      };

      const user = {
        DT_CUSTODIAN_ID: this.state.propsUploadData.CRT_ID,
        DT_PROJECT_ID: this.props.dsprojectProps.state.selectedProjectId,
        DT_PROJECT_NAME: this.props.dsprojectProps.state.selectedProjectName,
        DT_DATASET_NAME_new: this.state.propsdatasetName[0][0],
        CRT_ALIAS: this.state.propsUploadData.CRT_ALIAS,
        CLIENT_ID: "1",
        USERID: "1",
      };
      const generalInfo = {
        CRT_ALIAS: this.state.propsUploadData.CRT_ALIAS,
        DT_PROJECT_NAME: this.props.dsprojectProps.state.selectedProjectName,
        DT_CUSTODIAN_ID: this.state.propsUploadData.CRT_ID,
        DT_PROJECT_ID: this.props.dsprojectProps.state.selectedProjectId,
      };
      const formData = new FormData();
      let tempArr = this.state.propsdatasetName;
      let finalListTotal = [];
      for (let i = 0; i < tempArr.length; i++) {
        for (let j = 0; j < tempArr[i][1].length; j++) {
          finalListTotal = finalListTotal.concat({
            ParentDataSetName: tempArr[i][0],
            SubDataSetName: tempArr[i][1][j].name,
            SubDataSetNameSize: tempArr[i][1][j].size,
            SubDataSetNameType: tempArr[i][1][j].type,
            FileArr: tempArr[i][1][j],
          });
        }
      }

      formData.append("files", file);
      formData.append("file1", JSON.stringify(file));
      formData.append("filesToBeUploaded", JSON.stringify(user));
      let tempFinalList = this.props.finalList;
      for (let i = 0; i < tempFinalList.length; i++) {
        for (let j = 0; j < this.state.md5InputText.length; j++) {
          for (let k = 0; k < this.state.md5InputText[j].length; k++) {
            if (tempFinalList[i].dguid === this.state.md5InputText[j][k].fileID) {
              tempFinalList[i].md5Value = this.state.md5InputText[j][k].md5Value;
            }
          }
        }
      }
      formData.append("filesToBeUploadedTEMP", tempFinalList);
      req.open("POST", process.env.REACT_APP_SERVER_HOST + "/datasetUpload");
      req.setRequestHeader("Access-Control-Allow-Origin", "*");
      req.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("jwt"));
      req.setRequestHeader("loggedInUserId", getUserIDFromSession());
      req.setRequestHeader("generalInfo", JSON.stringify(generalInfo));
      req.setRequestHeader("userInfo", JSON.stringify(user));
      req.send(formData);
    });
  }
  // CHECK DATASETS ALREDY THERE OR NOT WITH SAME NAME
  checkDatasetsAvailable() {
    let showErrorMessage = false;
    let showErrorMessageForDataSetsBlank = false;
    let showDatsetsInvalidMessage = false;
    for (let j = 0; j < this.state.propsdatasetName.length; j++) {
      if (this.props.dataSetDetails && this.props.dataSetDetails.length) {
        for (let i = 0; i < this.props.dataSetDetails.length; i++) {
          // CHECKING DATASETS NAME IS VALID OR NOT=========================
          if (
            !TextFieldLengthRegexValidation(this.state.propsdatasetName[j][0]) &&
            this.state.propsdatasetName[j][0] !== ""
          ) {
            showDatsetsInvalidMessage = true;
            break;
          }
          if (
            this.props.dataSetDetails[i].dataset.toLowerCase() ===
            this.state.propsdatasetName[j][0].toLowerCase()
          ) {
            showErrorMessage = true;
            break;
          }
        }
      }
      if (this.state.propsdatasetName[j][0] === "" || this.state.propsdatasetName[j][0] === null) {
        showErrorMessageForDataSetsBlank = true;
      }
    }
    // CUSTOM MESSGAE FOR DIFFERENT CONDITION==========================
    if (showErrorMessage) {
      return {
        message: "Dataset already exists with same name, please use a different name and try again!",
        value: true,
      };
    } else if (showErrorMessageForDataSetsBlank) {
      return {
        message: "Dataset name cannot be blank !",
        value: true,
      };
    } else if (showDatsetsInvalidMessage) {
      return {
        message: "Please check your dataset name, following special characters are allowed. ()[]@,.;_- !",
        value: true,
      };
    } else {
      return {
        value: false,
      };
    }
  }
  // CHECKING MD5 VALUE IS FILEED OR NOT

  checkIsBlankMd5Value() {
    let isMd5ValueBlank = false;
    for (let j = 0; j < this.state.md5InputText.length; j++) {
      for (let k = 0; k < this.state.md5InputText[j].length; k++) {
        if (
          this.state.md5InputText[j][k].md5Value === "" ||
          this.state.md5InputText[j][k].md5Value === null
        ) {
          isMd5ValueBlank = true;
          break;
        }
      }
    }
    if (isMd5ValueBlank) {
      return true;
    } else {
      return false;
    }
  }

  conformremoveFileFromList = (e, id) => {
    this.setState({ visible: true, dataInE: e, ID: id });
  };

  removeFileFromList = (e, id) => {
    e = this.state.dataInE;
    id = this.state.ID;
    let tempMd5ValueArr = this.state.md5InputText;
    let filtered = this.state.files.filter(function (el) {
      return el.dguid !== e;
    });
    let filteredMd5Value = tempMd5ValueArr[this.props.index].filter(function (el) {
      return el.fileID !== e;
    });
    tempMd5ValueArr[this.props.index] = filteredMd5Value;
    let datasetTobeRemoved = this.props.datasetName[this.props.index];
    let arrIndexToBeRemoved = 0;
    for (let eachfileInDataSet = 1; eachfileInDataSet < datasetTobeRemoved.length; eachfileInDataSet++) {
      if (datasetTobeRemoved[eachfileInDataSet][0].dguid === e) {
        arrIndexToBeRemoved = eachfileInDataSet;
        break;
      }
    }
    this.setState(
      {
        files: filtered,
        md5InputText: tempMd5ValueArr,
        visible: false,
        isUploadButtonDisable: filtered.length > 0 ? false : true,
      },
      () => {
        this.uploadButtonClick();
        this.fileReaderHandler(filtered);
      },
    );
    this.props.removeFileDataSet(arrIndexToBeRemoved, this.props.index, e);
  };

  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  renderProgress(file) {
    const uploadProgress = this.state.uploadProgress[file.name];
    if (this.state.uploading || this.state.successfullUploaded || this.state.uploadNotStarted) {
      return (
        <div className="col-md-8 p-r-0">
          <div className="row">
            <div className="col-md-10" style={{ marginLeft: "-12%" }}>
              <div className="ProgressWrapper" style={{ width: "200%", float: "left" }}>
                <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
              </div>
            </div>
          </div>
        </div>
      );
    }
  }

  renderActions() {
    if (this.state.successfullUploaded) {
      let counter = this.state.counter + 1;
      this.setState({ counter: counter });
      if (this.state.counter !== undefined) {
        $(".customButton").attr("disabled", true);
        $(".customButton").removeClass("defaultButtonBackgroundDatasets");
        const formData = new FormData();
        formData.append("files1", JSON.stringify(this.props.finalList));
        formData.append("files", JSON.stringify(this.props.finalList));
        axios("/saveUpload", {
          method: "POST",
          mode: "no-cors",
          headers: {
            "Access-Control-Allow-Origin": "*",
            // 'Accept': '*/*',
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            loggedInUserId: getUserIDFromSession(),
          },
          data: formData,
        }).then((_res) => {
          this.props.resetDataSet();
          this.setState({
            files: [],
            successfullUploaded: false,
            propsdatasetName: [],
            counter: 0,
            uploadNotStarted: true,
            uploading: false,
            uploadProgress: {},
            isUploadButtonDisable: true,
            md5InputText: [[]],
          });
        });
      }
    }
  }

  uploadButtonClick = () => {
    if ($(".customButton").length === 0) {
      $(".appendableClass").append(
        '<button disabled="true" class="customButton processUploadedDatasetButton a-btn button-expandable a-btn-primary a-btn-lg" id="BtnUpload">' +
          polyglot.t("Upload waiting datasets") +
          "</button>",
      );
    }
    if (!this.state.isUploadButtonDisable) {
      $(".customButton").removeAttr("disabled");
      $(".customButton").addClass("processUploadedDatasetButton a-btn a-btn-primary");
      $(".customButton").addClass(
        "defaultButtonBackgroundDatasets cursor-pointer processUploadedDatasetButtonColorWhite",
      );
    } else {
      $(".customButton").attr("disabled", true);
      $(".customButton").addClass("processUploadedDatasetButton a-btn a-btn-primary");
      $(".customButton").removeClass(
        "defaultButtonBackgroundDatasets cursor-pointer processUploadedDatasetButtonColorWhite",
      );
    }
    $(".customButton").unbind("click");
    $(".customButton").click(() => {
      // code for checking duplicate Datasets By S

      let checkDuplicateDatasets = this.checkDatasetsAvailable();
      if (checkDuplicateDatasets.value) {
        displayToastMessage(polyglot.t(checkDuplicateDatasets.message), "error");
      } else {
        let checkTotalFileSize = this.checkFileSizeBeforUpload();
        if (checkTotalFileSize > 10737418240) {
          displayToastMessage(
            polyglot.t("Please check total file size before uploading it should not exceed 10 GB!"),
            "error",
          );
        } else {
          displayToastMessage(
            polyglot.t("Upload submitted. Please do not leave this page untill the upload is finished."),
            "success",
          );
          this.uploadFiles();
        }
      }
    });
  };

  onChangeFormField(id, dguid, label, value) {
    try {
      let tempMd5InputText = this.state.md5InputText;
      if (this.state.md5InputText[0].length > 0) {
        tempMd5InputText[this.props.index][id].md5Value = value;
        tempMd5InputText[this.props.index][id].fileID = dguid;
      } else {
        tempMd5InputText.length = 0;
        tempMd5InputText.push([{ md5Value: value, fileID: dguid }]);
      }
      this.setState(
        (prevState) => ({
          md5InputText: tempMd5InputText,
          isUploadButtonDisable: false,
        }),
        () => {
          this.uploadButtonClick();
        },
      );
    } catch (ex) {}
  }

  getStateValue(index) {
    if (
      this.state.md5InputText &&
      this.state.md5InputText[this.props.index] &&
      this.state.md5InputText[this.props.index][index]
    ) {
      return this.state.md5InputText[this.props.index][index].md5Value;
    } else {
      return "";
    }
  }
  checkFileSizeBeforUpload() {
    let finalFileList = this.state.files;
    const finalSize = finalFileList.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.size;
    }, 0);
    return finalSize;
  }

  onDrop = (acceptedFiles, index) => {
    this.onFilesAdded(acceptedFiles);
  };
  render() {
    return (
      <div>
        <div className="">
          <div className="ContentFileU">
            <Dropzone onFilesAdded={(e) => this.onDrop(e, this.props.index)}></Dropzone>
          </div>
          <div className="Actions"></div>
        </div>
        <div className="row m-l-0 m-r-0">
          <div className="Files">
            {this.state.files.map((file, index) => {
              return (
                <div key={file.dguid} className="row m-l-0 m-r-0 p-t-8">
                  <div className="col-md-3 m-point2-zero">
                    <Tooltip content={TooltipText(file.name)} placement="right" trigger="click">
                      <div className="name-overflow small-bold-txt">{file.name}</div>
                    </Tooltip>
                  </div>
                  <div className="col-md-6 p-r-0 p-l-0">
                    <div className="row">
                      <div className="col-md-10 p-r-0">{this.renderProgress(file)}</div>
                      <div className="col-md-2 p-l-0 p-r-0" id="deleteImg">
                        <ButtonIcon
                          alt="delete"
                          title={polyglot.t("Delete")}
                          onClick={this.conformremoveFileFromList.bind(this, file.dguid)}
                          iconName={closeIcon}
                          ariaLabel={polyglot.t("Delete")}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-3">
                    <FormFieldComponent
                      customClassName="singleBorderInput"
                      placeholder="Enter MD5"
                      id="md5Input"
                      name="MD5_Text"
                      val={this.getStateValue(index)}
                      onChangeFormField={this.onChangeFormField.bind(this, index, file.dguid)}
                    ></FormFieldComponent>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <CustomModal
          show={this.state.visible}
          onCancel={this.handleCancel}
          title={polyglot.t("Confirmation")}
          Footer={[
            <Button size="lg" className="primary_button" onClick={this.removeFileFromList} key="1">
              {polyglot.t("Confirm")}
            </Button>,
          ]}
        >
          <>{polyglot.t("Are you sure you want to delete the dataset from the project?")}</>
        </CustomModal>

        <Prompt
          when={this.state.uploading}
          message={polyglot.t(
            "Upload submitted. Please do not leave this page untill the upload is finished.",
          )}
        />
      </div>
    );
  }
}
export default Upload;
