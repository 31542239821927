import HttpClient from "../Shared/HttpClient";

class RequestFormService {
  constructor() {
    this.client = new HttpClient();
  }

  // SAVE A REQUEST FILLED IN THE FORM
  saveRequestForm = async (formData) => {
    const client = new HttpClient();
    const res = await client.gipost("/saveRequest", formData, { "Content-Type": "multipart/form-data" });
    return res;
  };

  // EDIT A REQUEST FILLED IN THE FORM
  editRequestForm = async (formData) => {
    const client = new HttpClient();
    const res = await client.gipost("/editRequest", formData, { "Content-Type": "multipart/form-data" });
    return res;
  };
  // SAVE  BULK UPLOAD REQUEST
  saveBulkUploadRequest = async (formData) => {
    const client = new HttpClient();
    const res = await client.gipost("/saveBulkUploadRequest", formData, {
      "Content-Type": "multipart/form-data",
    });
    return res;
  };

  // API TO FETCH STATUS
  fetchStatus = async (language) => {
    const result = await this.client.giget(`/fetchStatus/${language}`);
    return result;
  };

  // API TO FETCH Category Details
  fetchCategoryDetails = async (language) => {
    const result = await this.client.giget(`/fetchCategoryDetails/${language}`);
    return result;
  };

  // API TO UPDATE THE REQUEST FORM
  updateTargetDetails = async (body) => {
    const result = await this.client.gipost("/updateTargetDetails", body, {
      "Content-Type": "multipart/form-data",
    });
    return result;
  };
  // API TO FETCH Priority DETAILS
  async getPriorityData(language) {
    const result = await this.client.giget(`/fetchPriority/${language}`);
    return result;
  }
  // API TO FETCH Category DETAILS
  async getCategoryData(language) {
    const result = await this.client.giget(`/fetchCategories/${language}`);
    return result;
  }

  // API TO FETCH REQUESTS CREATED
  fetchTargetDetails = async (language, body) => {
    const result = await this.client.gipost(`/fetchTargetDetails`, { ...body, language });
    return result;
  };

  // API TO FETCH TEMPLATE
  fetchTemplate = async () => {
    const result = await this.client.giget(`/downloadBulkUploadTemplate`);
    return result;
  };

  // API TO DOWNLOAD SPECIFIC FILE
  async downloadRequestFile(fileName, filePath) {
    const body = {
      fileName,
      filePath,
    };
    const result = await this.client.gipost("/downloadTargetFile", body);
    return result;
  }
}

// Enable Singleton behavior on this class
RequestFormService.__instance = null;
RequestFormService.get = () => {
  if (!RequestFormService.__instance) {
    RequestFormService.__instance = new RequestFormService();
  }
  return RequestFormService.__instance;
};

export default RequestFormService;
