//Admin Client Creation Form
import { Input, Select, SelectGroupTitle, SelectOption } from "appkit-react";
import React, { useContext, useState } from "react";

import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/QueenBase/Root/loading-spin.svg";
import AdminService from "../../../Services/QueenBase/Admin/AdminServices";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";

// STEP 1 OF CLIENT CREATION FORM
function ClientCreationForm(props) {
  let { countries, entityTypes } = props;
  const [clientNameError, setClientNameError] = useState(null);
  // DATA FROM CONTEXT
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  // CHECK IF CLIENT NAME IS ALREADY EXISTS
  const CheckClientName = async (name) => {
    var result = await AdminService.get().CheckClientName(name);
    if (result.data > 0) {
      setClientNameError(polyglot.t("client name already exisits"));
    } else {
      setClientNameError(null);
    }
  };
  // HANDLE CLIENT NAME CHANGE
  const handleClientNameChange = (value) => {
    CheckClientName(value);
    handleChange("ClientName")({ target: { value } });
  };
  const { values, handleChange, errors, touched } = props;

  if (countries.length > 0 && entityTypes.length > 0) {
    return (
      <div>
        <div className="container add-client">
          <div className="row">
            <div className="col-md-6">
              <h1 className="main_title_qb p-0">{polyglot.t("Add a new client to the system")}</h1>
            </div>
          </div>
          <div className="row mt-1">
            <div className="col-md-6 ">
              <Input
                inputBoxSize="sm"
                placeholder={polyglot.t("Client name")}
                value={values.ClientName}
                onChange={handleClientNameChange}
                aria-label={polyglot.t("Client name")}
              />
              {errors.ClientName && touched.ClientName ? (
                <div className="error">{polyglot.t(errors.ClientName)}</div>
              ) : null}
              {clientNameError && <div className="error">{clientNameError}</div>}
              <br />
            </div>
            <div className="col-md-6">
              <Input
                inputBoxSize="sm"
                placeholder={polyglot.t("Email*")}
                value={values.Clientemail}
                onChange={(value) => handleChange("Clientemail")({ target: { value } })}
                aria-label={polyglot.t("Email*")}
              />
              {errors.Clientemail && touched.Clientemail ? (
                <div className="error">{polyglot.t(errors.Clientemail)}</div>
              ) : null}
            </div>
          </div>
          <div className="row mt-1">
            <div className="col-md-6 ">
              <Input
                inputBoxSize="sm"
                placeholder={polyglot.t("Organisation")}
                value={values.ClientEntity}
                onChange={(value) => handleChange("ClientEntity")({ target: { value } })}
                aria-label={polyglot.t("Organisation")}
              />
              {errors.ClientEntity && touched.ClientEntity ? (
                <div className="error">{polyglot.t(errors.ClientEntity)}</div>
              ) : null}
              <br />
            </div>
            <div className="a-dropdown a-dropdown-default col-md-6">
              {getUserLanguage() === "en" && (
                <>
                  {entityTypes.length > 0 && (
                    <Select
                      placeholder={polyglot.t("Entity Type")}
                      value={values.ClientEntityid}
                      showSearchOnToggle={true}
                      onSelect={(value) => handleChange("ClientEntityid")({ target: { value } })}
                      aria-label={polyglot.t("Entity Type")}
                    >
                      <SelectGroupTitle>{polyglot.t("Entity type_noStart")}</SelectGroupTitle>
                      {entityTypes.map((entity, index) => {
                        return (
                          <SelectOption key={index} value={entity.EntityID}>
                            {entity.EntityName}
                          </SelectOption>
                        );
                      })}
                    </Select>
                  )}
                </>
              )}
              {getUserLanguage() === "ger" && (
                <>
                  {entityTypes.length > 0 && (
                    <Select
                      placeholder={polyglot.t("Entity Type")}
                      value={values.ClientEntityid}
                      showSearchOnToggle={true}
                      onSelect={(value) => handleChange("ClientEntityid")({ target: { value } })}
                      aria-label={polyglot.t("Entity Type")}
                    >
                      <SelectGroupTitle>{polyglot.t("Entity type_noStart")}</SelectGroupTitle>
                      {entityTypes.map((entity, index) => {
                        return (
                          <SelectOption key={index} value={entity.EntityID}>
                            {entity.EntityName}
                          </SelectOption>
                        );
                      })}
                    </Select>
                  )}
                </>
              )}
              {errors.ClientEntityid && touched.ClientEntityid ? (
                <div className="error">{polyglot.t(errors.ClientEntityid)}</div>
              ) : null}
              <br />
            </div>
          </div>
          <div className="row mt-1">
            <div className="col-md-6 ">
              <Input
                inputBoxSize="sm"
                placeholder={polyglot.t("ContactPerson ")}
                value={values.ClientContactPerson}
                onChange={(value) => handleChange("ClientContactPerson")({ target: { value } })}
                aria-label={polyglot.t("ContactPerson ")}
              />
              {errors.ClientContactPerson && touched.ClientContactPerson ? (
                <div className="error">{polyglot.t(errors.ClientContactPerson)}</div>
              ) : null}
              <br />
            </div>
            <div className="col-md-6">
              <Input
                inputBoxSize="sm"
                placeholder={polyglot.t("PhoneNumber*")}
                value={values.ClientPhone}
                onChange={(value) => handleChange("ClientPhone")({ target: { value } })}
                aria-label={polyglot.t("PhoneNumber*")}
              />
              {errors.ClientPhone && touched.ClientPhone ? (
                <div className="error">{polyglot.t(errors.ClientPhone)}</div>
              ) : null}
            </div>
          </div>
          <div className="row">
            <div className="col-md-6">
              <Input
                inputBoxSize="sm"
                placeholder={polyglot.t("Department*")}
                value={values.ClientDepartment}
                onChange={(value) => handleChange("ClientDepartment")({ target: { value } })}
                aria-label={polyglot.t("Department*")}
              />
              {errors.ClientDepartment && touched.ClientDepartment ? (
                <div className="error">{polyglot.t(errors.ClientDepartment)}</div>
              ) : null}
            </div>
            <div className="a-dropdown a-dropdown-default col-md-6">
              {countries.length > 0 && getUserLanguage() === "en" && (
                <Select
                  placeholder={polyglot.t("Country *")}
                  value={values.Clientcountryid}
                  showSearchOnToggle={true}
                  onSelect={(value) => handleChange("Clientcountryid")({ target: { value } })}
                  aria-label={polyglot.t("Country *")}
                >
                  <SelectGroupTitle>{polyglot.t("Country")}</SelectGroupTitle>
                  {countries.map((country, index) => {
                    if (index < 1)
                      return (
                        <SelectOption key={index + 1} value={country.countryid}>
                          {country.name}
                        </SelectOption>
                      );
                  })}
                  <hr className="" />
                  {countries.map((country, index) => {
                    if (index > 0)
                      return (
                        <SelectOption key={index + 1} value={country.countryid}>
                          {country.name}
                        </SelectOption>
                      );
                  })}
                </Select>
              )}
              {countries.length > 0 && getUserLanguage() === "ger" && (
                <Select
                  placeholder={polyglot.t("Country *")}
                  value={values.Clientcountryid}
                  showSearchOnToggle={true}
                  onSelect={(value) => handleChange("Clientcountryid")({ target: { value } })}
                  aria-label={polyglot.t("Country *")}
                >
                  <SelectGroupTitle>{polyglot.t("Country")}</SelectGroupTitle>
                  {countries.map((country, index) => {
                    if (index < 1)
                      return (
                        <SelectOption key={index + 1} value={country.countryid}>
                          {country.name}
                        </SelectOption>
                      );
                  })}
                  <hr className="" />
                  {countries.map((country, index) => {
                    if (index > 0)
                      return (
                        <SelectOption key={index + 1} value={country.countryid}>
                          {country.name}
                        </SelectOption>
                      );
                  })}
                </Select>
              )}

              {errors.Clientcountryid && touched.Clientcountryid ? (
                <div className="error">
                  {getUserLanguage() === "en"
                    ? polyglot.t("Country is required")
                    : polyglot.t("Country is required")}
                </div>
              ) : null}
              <br />
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <Input
                inputBoxSize="sm"
                placeholder={polyglot.t("Client Address*")}
                value={values.ClientAddress}
                onChange={(value) => handleChange("ClientAddress")({ target: { value } })}
                aria-label={polyglot.t("Client Address*")}
              />
              {errors.ClientAddress && touched.ClientAddress ? (
                <div className="error">{polyglot.t(errors.ClientAddress)}</div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  } else return <LoadingSpinner width={64} height={64} fill={"#ef3829"} />;
}
export default ClientCreationForm;
