/* eslint-disable no-mixed-spaces-and-tabs */
import "../../../Assets/Css/QueenBase/Root/UserProfile.css";
import "../../../Assets/Css/QueenBase/Root/UpdateUser.css";
import { Avatar, Input, Select, SelectGroupTitle, SelectOption } from "appkit-react";
import { Formik } from "formik";
import { startCase } from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";

import { LoginContext } from "./LoginContext";
import displayToastMessage from "./displayToastMessage";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import * as path from "../../../Constants/Path";
import { ONLY_CHAR_REGEX } from "../../../Constants/regex";
import UserService from "../../../Services/QueenBase/Users/UserService";
import CommonService from "../../../Services/Shared/CommonService";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
function UpdateUser(props) {
  const { userLanguage, fetchCountriesList } = useContext(DataContext);
  const { loginResponse, successful_FirstUser_Login } = useContext(LoginContext);
  const { firstname, jwt, userid } = loginResponse;
  var polyglot = polyglotLoader();
  const [open, setOpen] = useState(false);
  const [countries, setCountries] = useState([]);
  const [positions, setPositions] = useState([]);
  const [user, setUser] = useState([]);
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [image, setImage] = useState({
    preview: "",
    raw: "",
  });
  const [originalImagePreview, setOriginalImgPreview] = useState("");
  const [originalImageRaw, setOriginalImgRaw] = useState("");
  const getInitialValues = () => ({
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    positionid: user.positionid,
    countryid: user.countryid,
    entity: user.entity,
    department: user.department,
    phone: user.phone,
  });

  const validationSchema = Yup.object().shape({
    firstname: Yup.string()
      .matches(ONLY_CHAR_REGEX, polyglot.t("text field should not contain any special characters"))
      .required(polyglot.t("First Name is required")),
    lastname: Yup.string()
      .matches(ONLY_CHAR_REGEX, polyglot.t("text field should not contain any special characters"))
      .required(polyglot.t("Last Name is required")),
    email: Yup.string().email("Invalid email").required(polyglot.t("Email is Required")),
    positionid: Yup.number().min(1, "Position required"),
    countryid: Yup.number().min(1, "Country required"),
    entity: Yup.string()
      .matches(ONLY_CHAR_REGEX, polyglot.t("text field should not contain any special characters"))
      .required(polyglot.t("Organisation is required")),
    department: Yup.string()
      .matches(ONLY_CHAR_REGEX, polyglot.t("text field should not contain any special characters"))
      .required(polyglot.t("Department is required")),
    phone: Yup.number().required(polyglot.t("number is required")),
  });
  // GET USER DETAILS WHO GOT LOGGEDIN FOR THE FIRST TIME
  async function getFirstTimeUserInfo() {
    sessionStorage.setItem("jwt", jwt);
    const userObj = { userid };
    sessionStorage.setItem("user", JSON.stringify(userObj));
    const res = await UserService.get().getFirstTimeUserInfo();
    sessionStorage.removeItem("jwt");
    sessionStorage.removeItem("user");
    if (res.CODE) {
      setPositions(res.positions);
      setUser({
        firstname: res.userData.firstname,
        countryid: res.userData.countryid,
        email: res.userData.email,
        entity: res.userData.entity,
        lastname: res.userData.lastname,
        phone: res.userData.phone,
        positionid: res.userData.positionid,
        department: res.userData.department,
      });
      setIsUserLoaded(true);
      if (res.image) {
        var imageBlob = await CommonService.get().getBufferImageInBlob(res.image);
        var imageStr = URL.createObjectURL(imageBlob);
        setImage({
          preview: imageStr,
          raw: JSON.stringify({
            data: res.image.data,
          }),
        });
        setOriginalImgPreview(imageStr);
        setOriginalImgRaw(
          JSON.stringify({
            data: res.image.data,
          }),
        );
      } else {
        setImage({
          preview: "",
          raw: "",
        });
        setOriginalImgPreview("");
        setOriginalImgRaw("");
      }
    } else {
      displayToastMessage(res.MSG, "error");
    }
  }
  useEffect(() => {
    if (loginResponse.CODE) {
      setOpen(true);
      getFirstTimeUserInfo();
    } else {
      props.history.push(path.LOGIN_PATH);
    }
  }, []);

  // get countries from api on language change
  const getCountries = async () => {
    const countriesList = await fetchCountriesList();
    setCountries(countriesList);
  };
  // Runs when user language got updated
  useEffect(() => {
    getCountries();
  }, [userLanguage]);
  // UPDATE USER DETAILS
  const updateUser = async (values) => {
    var formData = new FormData();
    formData.append("file", image.raw);
    formData.append("user", JSON.stringify(values));
    sessionStorage.setItem("jwt", jwt);
    const userObj = { userid };
    sessionStorage.setItem("user", JSON.stringify(userObj));
    const res = await UserService.get().updateFirstTimeUserInfo(formData);
    if (res.CODE) {
      sessionStorage.removeItem("user");
      successful_FirstUser_Login(res);
      props.history.push(path.SOLUTION_LIST_PATH);
    } else {
      displayToastMessage(res.MSG, "error");
    }
  };
  const handleFile = (e) => {
    var fileInput = document.getElementsByName("myfile");
    var filePath = fileInput[0].value;
    var allowedExtensions = /(\.jpg|.jpeg|.png)$/i;

    if (filePath !== "") {
      if (!allowedExtensions.exec(filePath)) {
        displayToastMessage(
          polyglot.t("Please upload file having extensions .jpg or .png or .jpeg only"),
          "info",
        );
        fileInput[0].value = "";
        return false;
      }
    }

    if (fileInput[0].files[0]) {
      var fileSize = fileInput[0].files[0].size / 1028 / 1028; //size in MB
      if (fileSize <= 2) {
        setImage({
          preview: URL.createObjectURL(e.target.files[0]),
          raw: e.target.files[0],
        });
      } else {
        displayToastMessage(polyglot.t("image size cannot exceed 2 MB"), "error");
      }
    }
  };

  const errorLoadingImage = () => {
    displayToastMessage(
      polyglot.t("Please upload file having extensions .jpg or .png or .jpeg only"),
      "info",
    );
    // If error found on the image, then set the original image as the raw
    setImage({
      preview: "",
      raw: originalImageRaw,
    });
  };

  const displayImageUpload = () => {
    return (
      <div className="upload-avtar-top mb-4">
        <div className="col-md-2 update-profile-pic">
          {originalImagePreview ? "" : <div className="appkiticon icon-camera-outline cameraIcon "></div>}
          <Avatar id={originalImagePreview ? "upload-avtar-img-data" : "upload-avtar-img"} size="large">
            {
              <img
                alt="flag"
                className={image.preview ? "change-avatar-image-data" : "change-avatar-image"}
                src={image.preview ? image.preview : originalImagePreview}
                onError={errorLoadingImage}
              />
            }
          </Avatar>
        </div>
        <div className="col-md-10 upload-profile-pic">
          <div className="upload-profile-photo-wrapper">
            <input type="file" name="myfile" onChange={handleFile} accept="image/jpeg,image/x-png" />
            <button className="upload-profile-image-button">{polyglot.t("Upload Image")}</button>
          </div>
          <p className="ImageContent-two">
            <b>{polyglot.t("Maximum upload file size:2 MB")}</b>
          </p>
        </div>
      </div>
    );
  };
  const displayUserForm = () => {
    if (isUserLoaded) {
      return (
        <Formik onSubmit={updateUser} initialValues={getInitialValues()} validationSchema={validationSchema}>
          {(prop) => {
            const { values, handleChange, handleSubmit, errors, touched } = prop;
            return (
              <div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <Input
                      inputBoxSize="sm"
                      placeholder={polyglot.t("First name *")}
                      className="Box"
                      value={values.firstname}
                      onChange={handleChange("firstname")}
                      aria-label={polyglot.t("first name of the user")}
                    />
                    {errors.firstname && touched.firstname ? (
                      <span className="error">{errors.firstname}</span>
                    ) : null}
                  </div>
                  <div className="col-md-6">
                    <Input
                      inputBoxSize="sm"
                      placeholder={polyglot.t("Last Name *")}
                      className="Box"
                      value={values.lastname}
                      onChange={handleChange("lastname")}
                      aria-label={polyglot.t("last name of the user")}
                    />
                    {errors.lastname && touched.lastname ? (
                      <span className="error">{errors.lastname}</span>
                    ) : null}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <Select
                      placeholder={polyglot.t("Position_noStar")}
                      className="update-position"
                      value={values.positionid}
                      onSelect={(value) => handleChange("positionid")({ target: { value } })}
                      aria-label={polyglot.t("Position_noStar")}
                    >
                      <SelectGroupTitle>{polyglot.t("Position_noStar")}</SelectGroupTitle>
                      {positions.length > 0 &&
                        positions.map((position, index) => {
                          return (
                            <SelectOption key={index} value={position.positionid}>
                              {position.name}
                            </SelectOption>
                          );
                        })}
                    </Select>
                    {errors.positionid && touched.positionid ? (
                      <span className="error">{errors.positionid}</span>
                    ) : (
                      null && errors.positionid && <span className="error">{errors.positionid}</span>
                    )}
                  </div>
                  <div className="col-md-6">
                    <Select
                      className="update-position"
                      placeholder={polyglot.t("Country *")}
                      value={values.countryid}
                      onSelect={(value) => handleChange("countryid")({ target: { value } })}
                      aria-label={polyglot.t("Country_noStar")}
                    >
                      <SelectGroupTitle>{polyglot.t("Country_noStar")}</SelectGroupTitle>
                      {countries.map((country, index) => {
                        if (index < 1)
                          return (
                            <SelectOption key={index + 1} value={country.countryid}>
                              {polyglot.t(country.name)}
                            </SelectOption>
                          );
                      })}
                      <hr className="" />
                      {countries.map((country, index) => {
                        if (index > 0)
                          return (
                            <SelectOption key={index + 1} value={country.countryid}>
                              {polyglot.t(country.name)}
                            </SelectOption>
                          );
                      })}
                    </Select>
                    {errors.countryid && touched.countryid ? (
                      <span className="error">{errors.countryid}</span>
                    ) : (
                      null && errors.countryid && <span className="error">{errors.countryid}</span>
                    )}
                  </div>
                </div>
                <div className="row mb-3">
                  <div className="col-md-6">
                    <Input
                      inputBoxSize="sm"
                      placeholder={polyglot.t("Organisation")}
                      className="Box"
                      value={values.entity}
                      onChange={handleChange("entity")}
                      aria-label={polyglot.t("Organisation")}
                    />
                    {errors.entity && touched.entity ? <span className="error">{errors.entity}</span> : null}
                  </div>
                  <div className="col-md-6">
                    <Input
                      inputBoxSize="sm"
                      placeholder={polyglot.t("Department*")}
                      className="Box"
                      value={values.department}
                      onChange={handleChange("department")}
                      aria-label={polyglot.t("Department*")}
                    />
                    {errors.department && touched.department ? (
                      <span className="error">{errors.department}</span>
                    ) : null}
                  </div>
                </div>
                <div className="row ">
                  <div className="col-md-6">
                    <Input
                      inputBoxSize="sm"
                      placeholder={polyglot.t("PhoneNumber*")}
                      className="Box"
                      value={values.phone}
                      onChange={handleChange("phone")}
                      aria-label={polyglot.t("Phone number")}
                    />
                    {errors.phone && touched.phone ? <span className="error">{errors.phone}</span> : null}
                  </div>
                  <div className="col-md-6"></div>
                </div>
                <div className="row">
                  <div className="col-md-10">
                    <p className="updload-text-desc">
                      {polyglot.t(
                        "Your phone number is required for our two-factor authentication, to ensure your account remains secure.",
                      )}
                    </p>
                  </div>
                  <div className="col-md-2"></div>
                </div>
                <div className="row my-2">
                  <div className="col-md-5">
                    <ButtonPrimary
                      onClick={handleSubmit}
                      type="submit"
                      ariaLabel={polyglot.t("Save Information")}
                    >
                      {polyglot.t("Save Information")}
                    </ButtonPrimary>
                  </div>
                  <div className="col-md-7"></div>
                </div>
              </div>
            );
          }}
        </Formik>
      );
    }
  };

  return (
    <div className="login-bg-image">
      {open && (
        <div className="container mb-5" style={{ minHeight: "740px" }}>
          <div className="col-md-3" />
          <div className="col-md-7 ScreenFit-qb-user">
            <div className="backgroud-color-align">
              <div className="card-header header_login Header_resposnive_login">
                <h1 className="headerText_Login">
                  {`${startCase(firstname)}, ${polyglot.t("Welcome to Queen")}`}
                </h1>
              </div>
              <div className="card-body-update-user">
                <span className="font-weight-medium text-nowrap user-label">
                  {polyglot.t("Since this is your first login, we need some information from you.")}
                </span>
                <br />
                {displayImageUpload()}
                {displayUserForm()}
              </div>
            </div>
          </div>
          <div className="col-md-2" />
        </div>
      )}
    </div>
  );
}

UpdateUser.propTypes = {
  history: PropTypes.object,
};

export default withRouter(UpdateUser);
