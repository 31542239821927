import { Panel } from "appkit-react";
import $ from "jquery";
import PropTypes from "prop-types";
import React, { useEffect, useContext } from "react";

import { DataContext } from "../../../../Services/Shared/Store";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import { customNumberFormat } from "../../../../utility/CustomNumberFormat";
import GetPriceInGermanFormat from "../../Root/GetPriceInGermanFormat";

// PROJECTS COMPONENT
function Projects(props) {
  // DATA FROM CONTEXT
  const { getUserLanguage } = useContext(DataContext);
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    const parent = document.getElementsByClassName("queen-container")[0];
    let Scroll = document.getElementsByClassName("scrollTo");
    if (Scroll.length) {
      $(parent).animate(
        {
          scrollTop: Scroll[0].getBoundingClientRect().y - 100,
        },
        "slow",
      );
    }
  }, []);

  var polyglot = polyglotLoader();
  const language = getUserLanguage();
  if (props.data.length > 0) {
    return (
      <div className="row ">
        {props.data.map((each, index) => {
          return (
            <div key={index} className="col-md-4 projects-content">
              <Panel className="project-panels-dashboard" tabIndex="0">
                <div className="dd-columns-data">
                  <div>
                    <label className="dd-label">{polyglot.t("Project Name: ")} </label>
                    <p className="pname">{each.label}</p>
                    <label className="dd-label">{polyglot.t("Solutions Subscribed")}</label>
                    <div className="q-milestones-header">
                      {each.solutions ? (
                        each.solutions.length > 0 ? (
                          each.solutions.map((each, i) => {
                            return <p key={i}>{each.solutionName}</p>;
                          })
                        ) : (
                          <p>{polyglot.t("No solutions")}</p>
                        )
                      ) : (
                        <p>{polyglot.t("No solutions")}</p>
                      )}
                    </div>
                    <label className="dd-label">{polyglot.t("Users")}</label>
                    <div className="dd-users ">
                      {JSON.parse(each.users) ? (
                        JSON.parse(each.users).length > 0 ? (
                          JSON.parse(each.users).map((each, i) => {
                            return (
                              <p className="dd-each-user" key={i}>
                                {each.UserName}
                              </p>
                            );
                          })
                        ) : (
                          <p>{polyglot.t("No users")}</p>
                        )
                      ) : (
                        <p>{polyglot.t("No users")}</p>
                      )}
                    </div>
                    <label className="dd-label">{polyglot.t("Invoice")}</label>
                    {each.invoices != null && JSON.parse(each.invoices).length > 0 && (
                      <span>
                        <div className="dd-invoicedetails">
                          <div className="dd-invoiceid">
                            <b>ID:</b> {JSON.parse(each.invoices)[0].last_invoice_id}
                          </div>

                          <div className="dd-invoice-amount">
                            <b>{polyglot.t("Total Amount")}:</b>{" "}
                            {GetPriceInGermanFormat(
                              customNumberFormat(JSON.parse(each.invoices)[0].total_amount),
                            ) == "€NaN" ||
                            GetPriceInGermanFormat(
                              customNumberFormat(JSON.parse(each.invoices)[0].total_amount),
                            ) == "NaN € €"
                              ? language == "en"
                                ? "€0"
                                : "0 €"
                              : GetPriceInGermanFormat(
                                  customNumberFormat(JSON.parse(each.invoices)[0].total_amount, true),
                                  false,
                                  true,
                                )}
                          </div>
                        </div>
                      </span>
                    )}
                    {(each.invoices == null || JSON.parse(each.invoices).length == 0) && (
                      <div className="noinvoice">{polyglot.t("No invoice added")}</div>
                    )}
                  </div>
                </div>
              </Panel>
            </div>
          );
        })}
      </div>
    );
  }
  return (
    <div className="row">
      <div className="col-md-12">
        <Panel>
          <div className="noprojects-dashboard">
            <strong>{polyglot.t("No projects, please create one")}</strong>
          </div>
        </Panel>
      </div>
    </div>
  );
}

Projects.propTypes = {
  data: PropTypes.array,
};

export default Projects;
