import React from "react";
import { Switch } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import BasePackageUpgradation from "../Components/QueenBase/CompanyCenter/BasePackageUpgradation";
import CompanyCenterContainer from "../Components/QueenBase/CompanyCenter/CompanyCenterContainer";
import * as path from "../Constants/Path";

// ROUTES OF COMPANY CENTER SCREENS
function CompanyCentreRoute() {
  return (
    <div>
      <Switch>
        <ProtectedRoute path={path.COMPANY_CENTER_VIEW_PATH} component={CompanyCenterContainer} />
        <ProtectedRoute path={path.BASEPACKAGEUPGRADATION_PATH} component={BasePackageUpgradation} />
      </Switch>
    </div>
  );
}

export default CompanyCentreRoute;
