import "../../DFED/Review/Review.css";
import { Col, Row } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import DownloadParameterFile from "./DownloadParameterFile";
import ProvideData from "./ProvideData";
import { getProjectID_Of_solution_module } from "../../../Services/Shared/Handlers";
import { getWorkflowID_Of_DF_module, getWorkflowName_Of_DF_module } from "../handlers";

const FormLayout = (props) => {
  return (
    <Row gutter={24} style={{ marginTop: "5%" }}>
      <Col className="gutter-row" span={4}></Col>
      <Col className="gutter-row" span={16}>
        {props.children}
      </Col>
      <Col span={4}></Col>
    </Row>
  );
};

FormLayout.propTypes = {
  children: PropTypes.any,
};

// FORM TO UPLOAD DATA ON A WORKFLOW
const FormContainer = (props) => {
  const [currentPage, setCurrentPage] = useState(0);

  // BACK TO UPLOAD SCREEN
  const backToUpload = () => {
    props.history.push(`/df/upload/view`);
  };

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    const workflow_id = getWorkflowID_Of_DF_module();
    if (!workflow_id) {
      backToUpload();
    }
  });

  // RUNS WHEN STATE IS RECEIVED ON LOCATION
  useEffect(() => {
    const locationState = props.location.state;
    if (locationState) {
      if (locationState.showUploadScreen) {
        setCurrentPage(1);
      }
    }
  }, [props.location.state]);

  // NEXT STEP
  const next = (pageNum) => {
    setCurrentPage(pageNum);
  };
  // PREVIOUS STEP
  const goBack = () => {
    setCurrentPage(0);
  };

  const downloadScreenProps = {
    workflow_id: getWorkflowID_Of_DF_module(),
    project_id: getProjectID_Of_solution_module(),
    workflow_name: getWorkflowName_Of_DF_module(),
  };

  const provideDataProps = {
    workflow_id: getWorkflowID_Of_DF_module(),
    backToUpload,
    project_id: getProjectID_Of_solution_module(),
    workflow_name: getWorkflowName_Of_DF_module(),
  };

  return (
    <div className="">
      {/* STPE 1 TO DOWNLOAD PARAMTER FILE */}
      <div className="p-0 m-l-1-15">
        {currentPage === 0 && <DownloadParameterFile next={next} nextPage={1} data={downloadScreenProps} />}
      </div>
      {/* STEP 2 TO UPLOAD FILE TO THE DB ON THE RESPECTIVE WORKFLOW */}
      <div>{currentPage === 1 && <ProvideData data={provideDataProps} goBack={goBack} {...props} />}</div>
    </div>
  );
};

export default FormContainer;
