import React, { useState, useEffect } from "react";

import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/GRC/loading-spin.svg";
import QuestionService from "../../../Services/GRC/QuestionService";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import EmptyPanelTags from "../Shared/EmptyPanelTags";

const QuestionTags = ({ ids }) => {
  const { isLoading, sendRequest } = useHttpClient();
  const [questionTags, setQuestionTags] = useState([]);

  const getTagsByQuestionId = async () => {
    try {
      const responseQuestionTags = await sendRequest(QuestionService.get().getTagsByQuestionIdURL(ids));

      setQuestionTags(responseQuestionTags[0]);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (ids !== 0) {
      getTagsByQuestionId();
    }
    //eslint-disable-next-line
  }, [sendRequest]);
  return (
    <>
      {isLoading && (
        <div>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      )}
      {questionTags.length === 0 && <EmptyPanelTags massage={"Tags: no tags selected"} />}
      {!isLoading && questionTags.length > 0 && (
        <div className="p-1 alert-secondary">
          <span>Tags :</span>
          {questionTags.map((item, i) => {
            return (
              <span className="p-2">
                {item.tag}
                {questionTags.length === i + 1 ? "" : ","}
              </span>
            );
          })}
        </div>
      )}
    </>
  );
};
export default QuestionTags;
