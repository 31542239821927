import React, { useEffect, useContext, useState } from "react";

import SolutionService from "../../../Services/QueenBase/Solutions/SolutionService.js";
import { DataContext } from "../../../Services/Shared/Store";
import displayToastMessage from "../Root/displayToastMessage";
import ReviewSolution from "../Solutions/ReviewSolution";

// SIGN PROJECT COMPONENT IN PROJECT FORM
const SignContractInProjectForm = (props) => {
  const { solutionId, hideSignContract } = props;
  const { getUserLanguage } = useContext(DataContext);

  const [solutionList, setSolutionList] = useState([]);
  const [loading, setloading] = useState(true);

  const language = getUserLanguage();

  // GET SOLUTION LIST
  const getSolutionList = async () => {
    setloading(true);
    var res = await SolutionService.get().getSolutionList(language);
    if (res.CODE) {
      setSolutionList(res.solutionList);
    } else {
      displayToastMessage(res.MSG, "error");
    }
    setloading(false);
  };
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    getSolutionList();
  }, []);
  return (
    <>
      {!loading && (
        <ReviewSolution
          location={{
            state: {
              id: solutionId,
              solutionList,
              solution: solutionList.find((r) => r.id === solutionId),
            },
          }}
          fromPage="PROJECT_FORM"
          hideSignContract={hideSignContract}
        />
      )}
    </>
  );
};

export default SignContractInProjectForm;
