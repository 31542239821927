import { Tag } from "antd";
import PropTypes from "prop-types";
import React from "react";

import LinkText from "../../../Assets/Components/LinkText/LinkText";
import CustomModal from "../../../Assets/Components/Modal";
import ProjectSurveyService from "../../../Services/DF/ProjectSurveyService";
import { getDisplayDate, capitaliseFirstLetter } from "../../../Services/Shared/Handlers";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import { getFileNameFromPath } from "../handlers";
import "./ProgressCard.css";

// WORKFLOW DETAILS SHOWN ON A POPUP
const WorkflowDetails = (props) => {
  var polyglot = polyglotLoader();
  const { show, item, toggleInfo } = props;

  // DOWNLOAD PARAMETER FILE ON CLICK
  const downloadParamFile = async () => {
    try {
      // API TO DOWNLOAD A PARAMETER FILE OF A WORKFLOW
      const downloadResponse = await ProjectSurveyService.get().downloadParameterFile(
        item.project_survey_id,
        item.project_id,
      ); //workflow_id, project_id
      if (downloadResponse.CODE) {
        const buffer = Buffer.from(downloadResponse.file.buffer);
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", downloadResponse.file.fileName);
        document.body.appendChild(link);
        link.click();
        displayToastMessage(downloadResponse.MSG, "success");
        document.body.removeChild(link);
      } else {
        if (downloadResponse.MSG.errno === -4058) {
          displayToastMessage(`${polyglot.t("File not found")}!!`, "error");
        } else {
          displayToastMessage(downloadResponse.MSG, "error");
        }
      }
    } catch (error) {
      if (
        (error.message = "First argument must be a string, Buffer, ArrayBuffer, Array, or array-like object.")
      ) {
      } else {
        displayToastMessage(error.message, "error");
      }
    }
  };
  // DOWNLOAD UPLOADED FILE ON CLICK
  const downloadUplodedFile = async () => {
    try {
      // API TO DOWNLOAD UPLOADED FILE ON WORKFLOW
      const downloadfile = await ProjectSurveyService.get().getUserParameterFile(
        item.project_survey_id,
        item.project_id,
      ); //workflow_id, project_id
      if (downloadfile.CODE) {
        const buffer = Buffer.from(downloadfile.file.buffer);
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", downloadfile.file.fileName);
        document.body.appendChild(link);
        link.click();
        displayToastMessage(downloadfile.MSG, "success");
        document.body.removeChild(link);
      } else {
        if (downloadfile.error.errno === -4058) {
          displayToastMessage(`${polyglot.t("File not found")}!!`, "error");
        } else {
          displayToastMessage(`${polyglot.t("File not found")}!!`, "error");
        }
      }
    } catch (error) {
      if (
        (error.message = "First argument must be a string, Buffer, ArrayBuffer, Array, or array-like object.")
      ) {
      } else {
        displayToastMessage(error.message, "error");
      }
    }
  };

  // GET TITLE OF SURVEY NAME
  const GetTitle = (title) => {
    const resetTitle = title.replace("Start ", "");
    const displayTitle = capitaliseFirstLetter(resetTitle);
    return polyglot.t(displayTitle);
  };

  return (
    <CustomModal
      show={show}
      onCancel={toggleInfo}
      title={`${polyglot.t("Workflow name")}: ${item.workflow_name}`}
      Footer={[]}
      showCloseButton={true}
    >
      <div className=" row">
        <div className="f-acc-B col-md-6">{polyglot.t("Survey type")}:</div>
        <div className="f-acc-B col-md-6 count-align-t">
          {" "}
          <b>{GetTitle(item.title)}</b>
        </div>
      </div>
      <div className="row">
        <div className="f-acc-B col-md-6">{polyglot.t("Timescope from")}:</div>
        <div className="f-acc-B col-md-6 count-align-t">
          <b>{getDisplayDate(item.time_scope_from)}</b>{" "}
        </div>
      </div>
      <div className="row">
        <div className="f-acc-B col-md-6">{polyglot.t("Timescope to")}:</div>
        <div className="f-acc-B col-md-6 count-align-t">
          <b>{getDisplayDate(item.time_scope_to)}</b>{" "}
        </div>
      </div>
      <div className="row">
        <div className="f-acc-B col-md-6">{polyglot.t("Company codes")}:</div>
        <div className="f-acc-B col-md-6 count-align-t">
          {item.company_codes.split(",").map((code, i) => {
            return (
              <Tag className="mr-0 marginL-2" key={i}>
                {code}
              </Tag>
            );
          })}
        </div>
      </div>
      <div className="row">
        <div className="f-acc-B col-md-6">{polyglot.t("Financial year")}:</div>
        <div className="f-acc-B col-md-6 count-align-t">
          <b>{item.financial_year} </b>
        </div>
      </div>
      <div className="row">
        <div className="f-acc-B col-md-6">{polyglot.t("Financial year start date")}:</div>
        <div className="f-acc-B col-md-6 count-align-t">
          <b>{getDisplayDate(item.financial_year_start_date)} </b>
        </div>
      </div>
      <div className="row">
        <div className="f-acc-B col-md-6">{polyglot.t("Financial year end date")}:</div>
        <div className="f-acc-B col-md-6 count-align-t">
          <b>{getDisplayDate(item.financial_year_end_date)} </b>
        </div>
      </div>
      <div className="row">
        <div className="f-acc-B col-md-4">{polyglot.t("Parameter File")}:</div>
        {item.parameter_file_path && item.parameter_file_path !== "" ? (
          <div className="f-acc-B col-md-8 count-align-t">
            <LinkText onClick={downloadParamFile} ariaLabel={getFileNameFromPath(item.parameter_file_path)}>
              {getFileNameFromPath(item.parameter_file_path)}
            </LinkText>
          </div>
        ) : (
          <div className="f-acc-B col-md-8 count-align-t cursor-notallowed">
            <div>
              <b>{polyglot.t("File not available")}</b>
            </div>
          </div>
        )}
      </div>
      <div className="row">
        <div className="f-acc-B col-md-4">{polyglot.t("Uploaded data")}:</div>
        {item.uploaded_file && item.uploaded_file !== "" ? (
          <div className="f-acc-B col-md-8 count-align-t">
            <LinkText onClick={downloadUplodedFile} ariaLabel={item.uploaded_file}>
              {item.uploaded_file}
            </LinkText>
          </div>
        ) : (
          <div className="f-acc-B col-md-8 count-align-t cursor-notallowed">
            <div>
              <b>{polyglot.t("File not available")}</b>
            </div>
          </div>
        )}
      </div>
    </CustomModal>
  );
};

WorkflowDetails.propTypes = {
  show: PropTypes.bool,
  toggleInfo: PropTypes.func,
  item: PropTypes.object,
};

export default WorkflowDetails;
