import ErrorPanel from "../Shared/ErrorPanel";

import { Pagination } from "antd";
import { Panel } from "appkit-react";
import React, { useState, useEffect, useContext } from "react";
import { withRouter } from "react-router-dom";

import ViewResponse from "./ViewResponse";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/GRC/loading-spin.svg";
import { PER_PAGE_COUNT } from "../../../Constants/Constant";
import * as path from "../../../Constants/Path";
import RecipientService from "../../../Services/GRC/RecipientService";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import { getDisplayDate, capitaliseFirstLetter } from "../../../Services/Shared/Handlers";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import "../../../Assets/Css/GRC/RightPanel.css";
import EmptyPanel from "../Shared/EmptyPanel";

const PER_PAGE = PER_PAGE_COUNT;
//right panel of client response page
const RightPanel = ({ assessment, history }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [recipient, setRecipient] = useState([]);
  const [recipientdata, setRecipientData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagelist, setPageList] = useState([]);
  const { getUserLanguage } = useContext(DataContext);
  const polyglot = polyglotLoader(getUserLanguage());

  const getAssessment = async () => {
    if (assessment !== undefined) {
      try {
        const responseData = await sendRequest(
          RecipientService.get().getRecipientResponseAssessmentURL(assessment.id),
        );
        setRecipient(responseData);
        setRecipientData(responseData);
        setPageList(responseData);
      } catch (err) {
        console.log("err", err);
      }
    }
  };
  //this api is use to get assessment data
  useEffect(() => {
    getAssessment();
  }, [sendRequest, assessment]);

  //redirect to view page
  const handleClick = (item) => {
    history.push({
      pathname: `${path.RECIPIENT_RESPONSE_DETAILS_PATH}/${item.uniqueId}`,
      state: { uid: item.uniqueId },
    });
  };

  // HANDLE PAGE CHANGE ON PAGINATION
  const handlePageChange = (change) => {
    setCurrentPage(change);
  };

  // VIEW RESPONSE OF CURRENT PAGE
  const getCurrentPageRESPONSE = (recipientdata) => {
    return recipientdata.slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE);
  };

  // HEADING BANNER OR THE RESPONSE LIST
  const readingBanner = () => {
    return (
      <div>
        <div className="container-fluid right_panel p-0">
          <div className="row ml-0 mr-0">
            <div className="col-md-12 p-0  m-b-2">
              <Panel className="a-panel-project ">
                <div className="a-text-heading">
                  <div className="row">
                    <div className="col-md-3">
                      <div className=" project-data-desc recipientTable view-center-name">
                        {polyglot.t("recipient")}
                      </div>
                    </div>
                    <div className="col-md-3 m-auto">
                      <div className=" project-data-desc">{polyglot.t("comment")}</div>
                    </div>
                    <div className="col-md-2">
                      <div className=" project-data-desc view-center-name pl-0">
                        {polyglot.t("questions")}
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className=" project-data-desc view-center-name pl-0">{polyglot.t("status")}</div>
                    </div>
                    <div className="col-md-2 ">
                      <div className=" project-data-desc view-center-name-action pl-0">
                        {polyglot.t("action")}
                      </div>
                    </div>
                  </div>
                </div>
              </Panel>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // DISPLAY RESPONSE LIST
  const displayRecipientList = () => {
    return (
      <>
        <div className="row  resposeHeader">
          <div className="col-md-8 p-0 ">
            <h1 className="main_title_qb  ">{capitaliseFirstLetter(assessment.name)}</h1>
          </div>
          <div className="col-md-4  mt-1 resposeHeaderRight">
            <div className="project-data-desc  ">{polyglot.t("due_date")} :</div> &nbsp;{" "}
            <div className="view-center-imssage mt-1"> {getDisplayDate(assessment.due_date)}</div>
          </div>
        </div>

        <div className="row m-0">
          {/*Recipient List */}
          <div className="col-md-12 p-0 ">
            {readingBanner()}
            {getCurrentPageRESPONSE(pagelist).map((Item, index) => {
              return <ViewResponse key={index} Item={Item} index={index} handleClick={handleClick} />;
            })}
            <div className="pagenationstylesP">
              <>
                <>
                  <Pagination
                    current={currentPage}
                    size="small"
                    pageSize={PER_PAGE}
                    total={pagelist.length}
                    onChange={handlePageChange}
                    showSizeChanger={false}
                  />
                </>
              </>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    // <div className="col-md-50 p-0">
    <div>
      {error && <ErrorPanel error={error} onClear={clearError} />}
      {isLoading && (
        <div>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      )}
      {recipient.length === 0 && <EmptyPanel massage={"No data found."} />}
      {!isLoading && recipient.length > 0 && displayRecipientList()}
    </div>
    // </div>
  );
};

export default withRouter(RightPanel);
