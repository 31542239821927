/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/jsx-key */
import "react-multi-carousel/lib/styles.css";
import "../../../Assets/Css/QueenBase/Solutions/Solution.css";
import { Divider, List, ListItem } from "appkit-react";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import * as path from "../../../Constants/Path";
import { Carousal } from "../../../Json/solutions.json";
import SolutionService from "../../../Services/QueenBase/Solutions/SolutionService.js";
import { getDisplayDate } from "../../../Services/Shared/Handlers";
import { DataContext } from "../../../Services/Shared/Store.js";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage.js";

// SOLUTION COMPONENT WHICH DISPLAYS SOLUTION CARDS
function Solution(props) {
  const [carousal] = useState(Carousal);
  const [MainsolutionList, setMainSolutionList] = useState([]);
  const [solutionList, setSolutionList] = useState([]);
  const [loading, setloading] = useState(true);

  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader();
  // NAVIGATE TO PROJECTS SCREEN
  const redirectToProjects = () => {
    props.history.push(path.PROJECT_PATH);
  };

  const language = getUserLanguage();
  // FETCH SOLUTIONS BASED ON THE LANGUAGE
  const getSolutionList = async (language) => {
    setloading(true);
    var res = await SolutionService.get().getSolutionList(language);
    if (res.CODE) {
      let FilteredSolutions = res.solutionList.filter((r) => {
        return r.isenabled == true;
      });
      await setSolutionList(res.solutionList);
      await setMainSolutionList(res.solutionList);
    } else {
      displayToastMessage(res.MSG, "error");
    }
    setloading(false);
  };

  // FETCH SOLUTIONS ON INITIAL LOAD
  useEffect(() => {
    document.body.classList.remove("bg-img");
    getSolutionList(language);
  }, [language]);

  // NAVIGATE TO REVIEW SOLUTION SCREEN - TO SIGN A PACKAGE FOR A GIVEN SOLUTION
  const navigate = (id) => {
    props.history.push({
      pathname: path.SOLUTION_REVIEW_PATH,
      state: {
        id,
        solutionList,
        solution: solutionList.find((r) => r.id === id),
      },
    });
  };

  // GET FILTERED SOLUTIONS BY NAME
  const getFilteredSolutionsByName = () => {
    let seenNames = {};
    const duplicateSolutions = [];
    const filteredJSON = solutionList.filter((solution) => {
      if (solution.id in seenNames) {
        duplicateSolutions.push(solution);
        return false;
      } else {
        seenNames[solution.id] = true;
        return true;
      }
    });
    return { filteredJSON, duplicates: duplicateSolutions };
  };
  // SOLUTION LIST BY NAME TO DISPLAY SOLUTIONS
  const solutionListByName = () => {
    const { filteredJSON, duplicates } = getFilteredSolutionsByName();
    let solutionListByName = [];
    let solutionContent = {};

    if (duplicates.length === 0) {
      solutionListByName = [...filteredJSON];
    } else {
      filteredJSON.forEach((solution) => {
        solutionContent = { ...solution };
        solutionContent.duplicatePackageDetails = [];
        duplicates.forEach((duplicate) => {
          if (duplicate.id === solution.id) {
            solutionContent.isDuplicateAvailable = true;
            solutionContent.duplicatePackageDetails.push(duplicate);
          }
        });
        solutionListByName.push(solutionContent);
      });
    }
    return solutionListByName;
  };
  // ACTIVATED PACKAGE OF THE SOLUTION IS RETURNED FROM THIS FUNCTION
  const displayModuleCount = (solution) => {
    if (solution.id === 2 && solution.isenabled) {
      const selectedPackage = JSON.parse(solution.packagetitle);
      return selectedPackage[0].PlanName;
    } else if (solution.id === 4) {
      return getDisplayDate(solution.createddate) + " - " + getDisplayDate(solution.enddate);
    } else {
      return solution.activatedPackage;
    }
  };
  // CUSTOM RIGH ARROW OF CAROUSEL
  const CustomRightArrow = ({ onClick }) => {
    return (
      <div>
        <ButtonIcon
          onClick={() => onClick()}
          className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right arrowColor"
          iconName="right-arrow"
          alt="right-arrow"
          ariaLabel={polyglot.t("Scroll for more")}
          title={polyglot.t("Scroll for more")}
        >
          <span className="tooltiptext">{polyglot.t("Scroll for more")}</span>
        </ButtonIcon>
      </div>
    );
  };
  // CUSTOM LEFT ARROW OF CAROUSEL
  const CustomLeftArrow = ({ onClick }) => {
    return (
      <div>
        <ButtonIcon
          onClick={() => onClick()}
          className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left arrowColor"
          iconName="left-arrow"
          alt="left-arrow"
          ariaLabel={polyglot.t("Scroll for more")}
          title={polyglot.t("Scroll for more")}
        >
          <span className="tooltiptext">{polyglot.t("Scroll for more")}</span>
        </ButtonIcon>
      </div>
    );
  };

  return (
    <div className="mt-3">
      <div className="m-l-1 m-b-18">
        <ButtonPrimary onClick={redirectToProjects} ariaLabel={polyglot.t("Go to projects")}>
          {polyglot.t("Go to projects")}
        </ButtonPrimary>
      </div>
      <h1 className="main_title_qb m-l-1 m-b-18">{polyglot.t("kit")}</h1>
      <div className="cardWrapper col-md-12">
        {solutionList.length > 0 &&
          solutionListByName()
            .sort((a, b) => a.availablitystatus - b.availablitystatus)
            .map((solution, index) => {
              return (
                <div
                  key={index}
                  className="card cardStyle solutionCardStyle"
                  style={{
                    borderTop: "5px solid",
                    borderTopColor: solution.BODY.cardColor,
                  }}
                >
                  <div className="card-body divrelative">
                    <div className="subtitle_title_qb">{solution.name}</div>
                    <div className="col-md-12 badge-test-sub">
                      <div className="row">
                        <div className="col-md-4 pl-0">
                          <div className="badge-styling">
                            {solution.availablitystatus ? (
                              solution.isenabled ? (
                                <span className="badge status-badge-active-solution ">
                                  {polyglot.t("Activated")}
                                </span>
                              ) : (
                                <span className=" badge status-badge-inactive">{polyglot.t("Inactive")}</span>
                              )
                            ) : (
                              <span className=" badge status-badge-inactive ">
                                {polyglot.t("Unavailable")}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-8 module-align pr-0">
                          {displayModuleCount(solution) ? (
                            displayModuleCount(solution)
                          ) : (
                            <div className="color-transparent">{polyglot.t("No package available")}</div>
                          )}
                        </div>
                        <div className="col-md-12 module-align pr-0">
                          {solution.isenabled && solution.id != 4 ? (
                            getDisplayDate(solution.createddate) + " - " + getDisplayDate(solution.enddate)
                          ) : (
                            <div className="color-transparent">{polyglot.t("No package available")}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <Divider />
                    <p className="f-acc-B break-word">{solution.BODY.body.title}</p>
                    <div className="row scrollable-text-solution-card">
                      <div className="col-md-12 ">
                        {solution.BODY.body.data.map((itemdesc, item) => {
                          return (
                            <div className="row margin-point25" key={item}>
                              <List key={item.id}>
                                <ListItem key={item.id}>
                                  <span className="appkiticon icon-check-mark-fill pointer-solution"></span>
                                  <span className="f-acc-B"> {itemdesc.Description}</span>
                                </ListItem>
                              </List>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                    {solution.availablitystatus && (
                      <div className="p-l-0 m-b-8 divabsolute">
                        <ButtonSecondary
                          onClick={() => navigate(solution.id)}
                          ariaLabel={solution.BODY.button}
                        >
                          {solution.BODY.button}
                        </ButtonSecondary>
                      </div>
                    )}
                  </div>
                </div>
              );
            })}
        <br />
      </div>
    </div>
  );
}

Solution.propTypes = {
  history: PropTypes.object,
};
export default Solution;
