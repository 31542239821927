import "../../../DFED/upload/Upload.css";
import "../../../DFED/Shared/components/FormField.css";
import "../../../DFED/Review/DocumentList/DocumentList.css";
import "./sharedStyles.css";
import PropTypes from "prop-types";
import React from "react";

import Dropzone from "../../../DFED/dropzone/Dropzone";

// CUSTOM DROP ZONE USED TO UPLOAD FILES
const CustomDropZone = (props) => {
  const { onFilesAdded, customDropZoneBoxStyle } = props;
  return <Dropzone onFilesAdded={onFilesAdded} customDropZoneBoxStyle={customDropZoneBoxStyle} />;
};

CustomDropZone.propTypes = {
  onFilesAdded: PropTypes.any,
  customDropZoneBoxStyle: PropTypes.any,
};

export default CustomDropZone;
