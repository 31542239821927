import React from "react";
import deleteImg from "../../../../Assets/Images/DFED/icon-x.svg";
import DropTarget from "./DropTarget";
import BoxItem from "./BoxItem";
import httpRequest from "../../../../Services/Shared/HttpClient";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import WidgetService from "../../../../Services/QueenBase/Widget/WidgetService";
import BoxData from "./BoxData";
import displayToastMessage from "../../Root/displayToastMessage";
var shortid = require("shortid");

// BOX COMPONENT
export default class Box extends React.Component {
  constructor(props) {
    super(props);
    this.HttpRequest = new httpRequest();
    this.state = {
      items: [],
      isSaveAndClose: false,
      isWidgetsSaved: false,
      isEditMode: false,
      packageData: 2,
      type: props.type,
      language: props.language,
    };
  }

  // RUNS ON RECEIVING PROPS
  componentWillReceiveProps(props) {
    this.setState({
      isSaveAndClose: props.isSaveAndClose,
      isWidgetsSaved: props.isWidgetsSaved,
      isEditMode: props.isEditMode,
      language: props.language,
    });
  }

  // HANDLE DROP
  handleDrop = async (e) => {
    var polyglot = polyglotLoader();
    this.setState({ e });
    let items = this.state.items.slice();
    if (items.length === 0 && e.dragData.type == this.props.type) {
      var res = await WidgetService.get().getData(e.divId, this.props.language);
      items.push({
        divId: e.divId,
        type: e.dragData.type,
        label: e.label,
        wid: e.wid,
        uid: shortid.generate(),
        dataprops: res,
      });
      this.setState({ items: items });
      this.props.droppedItems(items);
      // API TO SAVE WIDGET DATA
      await this.HttpRequest.post("/insertWidgetData", {
        widget: items[0].divId,
        sequence: this.props.sequence,
        widgettitle: items[0].label,
        type: this.props.type,
        wid: items[0].wid,
      });
      this.props.handleDrop();
    } else {
      let msg = polyglot.t("Please drop the widget into the right placeholder");
      displayToastMessage(msg, "error");
    }
  };

  // HANDLE DROP
  handleDrop = async (e) => {
    var polyglot = polyglotLoader();
    this.setState({ e });
    let items = this.state.items.slice();
    if (items.length === 0) {
      var res = await WidgetService.get().getData(e.divId, this.props.language);
      items.push({
        divId: e.divId,
        type: e.dragData.type,
        label: e.label,
        wid: e.wid,
        uid: shortid.generate(),
        dataprops: res,
      });
      this.setState({ items: items });
      this.props.droppedItems(items);
      // API TO SAVE WIDGET DATA
      await this.HttpRequest.post("/insertWidgetData", {
        widget: items[0].divId,
        sequence: this.props.sequence,
        widgettitle: items[0].label,
        type: this.props.type,
        wid: items[0].wid,
      });
      this.props.handleDrop();
    } else {
      let msg = polyglot.t("Please drop the widget into the right placeholder");
      displayToastMessage(msg, "error");
    }
  };

  // CLOSE WIDGET
  closeWidget = async () => {
    const { e } = this.state;
    let items = this.state.items.slice();
    items.pop({ uid: shortid.generate() });
    this.setState({ items: items });
    this.props.removeDroppedItems(e.divId);
    // API TO DELETE WIDGET DATA BY PASSING WIDGETID
    await this.HttpRequest.post("/deleteWidgetData", { widget: e.divId, wid: e.wid });
  };

  // FUNCTION TO SWAP FROM WIDGET PANEL TO BOX
  swap = (fromIndex, toIndex, dragData) => {
    let items = this.state.items.slice();
    const item = {
      divId: dragData.divId,
      label: dragData.label,
      uid: shortid.generate(),
      dataprops: this.props.jsonData,
    };
    items.splice(toIndex, 0, item);
    this.setState({ items: items });
  };

  // FUNCTION TO DELETE BOX DATA
  kill = (uid) => {
    let items = this.state.items.slice();
    const index = items.findIndex((item) => {
      return item.uid == uid;
    });
    if (index !== -1) {
      items.splice(index, 1);
    }
    this.setState({ items: items });
  };

  // RETURNS HTML ON RENDER
  render() {
    var polyglot = polyglotLoader();
    const { type, sequence } = this.props;
    const { items } = this.state;
    return (
      <div className="col-md-3 dwidget">
        <DropTarget
          onHit={this.handleDrop}
          targetKey={this.props.targetKey}
          dropData={{ name: this.props.name }}
        >
          <DropTarget onHit={this.handleDrop} targetKey="boxItem" dropData={{ name: this.props.name }}>
            <div className="cursor-pointer">
              {items.length == 0 && (
                <div className="widgetcontainer padding-1em">
                  <div className="wcontent">
                    {polyglot.t(
                      "Add new widgets to your dashboard. Just drag and drop from the list on the left.",
                    )}
                  </div>
                </div>
              )}
              {items.map((item, index) => {
                return (
                  <span
                    key={index}
                    sequence={this.props.sequence}
                    className={item.divId == "queenbaseprogress" ? "queenbaseprogress-box" : ""}
                  >
                    <BoxItem
                      isSaveAndClose={this.state.isSaveAndClose}
                      isWidgetsSaved={this.state.isWidgetsSaved}
                      isEditMode={this.state.isEditMode}
                      key={item.uid}
                      sequence={this.props.sequence}
                      divId={item.divId}
                      uid={item.uid}
                      kill={this.kill}
                      index={index}
                      swap={this.swap}
                    >
                      {(!this.state.isSaveAndClose || this.state.isEditMode) && (
                        <span className="cursor-pointer deletebox" onClick={this.closeWidget}>
                          <img className="deleteIcon" alt={""} src={deleteImg} />
                        </span>
                      )}
                      <BoxData
                        language={item.language}
                        items={items}
                        dataprops={item.dataprops}
                        label={item.label}
                        divId={item.divId}
                      />
                    </BoxItem>
                  </span>
                );
              })}
            </div>
          </DropTarget>
        </DropTarget>
      </div>
    );
  }
}
