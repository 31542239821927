import "../../Assets/Css/QueenBase/Root/NavBar.css";
//import '../../Assets/Css/COMMON/NavBar.css';
import { Select } from "antd";
import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";
import { withRouter } from "react-router-dom";

import ConfirmBoxExitForm from "../../Assets/Components/ConfirmBoxExitForm";
import LinkText from "../../Assets/Components/LinkText/LinkText";
import { CSS_ADMIN, CSS_HOME, CSS_SEARCH, VIEW_PROJECT_PATH } from "../../Constants/Path";
import NavBarService from "../../Services/QueenBase/NavBar.js/NavBarService";
import ProjectService from "../../Services/QueenBase/Projects/ProjectService";
import {
  setProjectID_Of_solution_module,
  getProjectID_Of_solution_module,
  getSolutionID_Of_solution_module,
  setSolutionID_Of_solution_module,
} from "../../Services/Shared/Handlers";
import { DataContext } from "../../Services/Shared/Store";
import { polyglotLoader } from "../../Services/Shared/Translate";

import displayToastMessage from "../QueenBase/Root/displayToastMessage";

const { Option } = Select;

// THIS COMPONENT IS THE NAVIGATION BAR OF DF
function NavBar(props) {
  const { isFormOpen, formGotClosed } = useContext(DataContext);

  const [dblabel, setDbLabel] = useState([]);
  const polyglot = polyglotLoader();
  const projectId = getProjectID_Of_solution_module();
  const solutionID = getSolutionID_Of_solution_module();
  const [projectName, setProjectName] = useState("");
  const [expandHeader, setExpandHeader] = useState(false);
  const [solutionsList, setSolutionsList] = useState([]);
  const selectedSolution = solutionID;
  const [openConfirmBox, setOpenConfirmBox] = useState(false); // this value handles the confirm box of exit form
  const [selectedNav, setSelectedNav] = useState({});
  const [selectedSolutionToRedirect, setSelectedSolutionToRedirect] = useState({});
  // GET NAVBAR DETAILS OF DF MODULE
  const getNavBar = async () => {
    // API TO GET TAB NAMES OF DF MODULE OF LOGGEDIN USER
    try {
      var res = await NavBarService.get().getSolutionNavBar("CSS");
      if (res.CODE) {
        setDbLabel(res.list);
      } else {
        displayToastMessage(res.MSG, "error");
      }
    } catch (err) {
      displayToastMessage(polyglot.t("Something went wrong!"), "error");
    }
  };

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    getNavBar();
  }, []);
  const navOptions = [
    { url: CSS_HOME, label: "History", name: "history", dbTabName: "History" },
    { url: CSS_SEARCH, label: "Search", name: "search", dbTabName: "Search" },
    { url: CSS_ADMIN, label: "Admin", name: "admin", dbTabName: "Admin" },
  ];

  // GO BACK TO APPLICATION HOME
  const goBackToQueenbase = () => {
    props.history.push(VIEW_PROJECT_PATH);
  };

  // GET SOLUTIONS OF THE PROJECT
  const getSolutionsOfTheProject = async () => {
    try {
      const APIResponse = await ProjectService.get().getSolutionNameFromProjectID(projectId);
      if (APIResponse.CODE) {
        const { projectName: currentProjectName, solutions } = APIResponse.data;
        setSolutionsList(solutions);
        setProjectName(currentProjectName);
      } else {
        displayToastMessage(APIResponse.MSG, "error");
      }
    } catch (error) {
      displayToastMessage(error.message, "error");
    }
  };

  // GET PATH OF THE SELECTED SOLUTION
  const getPathOfSelectedSolution = (solutionId) => {
    try {
      const routePath = solutionsList.find((solution) => solution.id === solutionId).routePath;
      return routePath;
    } catch (error) {
      displayToastMessage(error.message, "error");
      return "";
    }
  };

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    // GO BACK TO APPLICATION HOME IF THERE IS NO PROJECT ID ASSIGNED
    if (!projectId) {
      goBackToQueenbase();
    } else {
      getSolutionsOfTheProject(); // fetch solutions by project id
      setProjectID_Of_solution_module(projectId);
    }
  }, []);

  // check if this solution is available present in the solution list
  useEffect(() => {
    // if solution is not present in the solution list, then navigate back to the queenbase
    if (solutionsList.length > 0) {
      const solutionIDs = solutionsList.map((each) => Number(each.id));
      if (solutionIDs.includes(Number(selectedSolution))) {
        // then this solution is part of the selected project
      } else {
        goBackToQueenbase();
      }
    }
  }, [solutionsList]);

  // check if the tab is available and render the URL
  const checkIfTheTabIsAvailableBeforeRedirection = () => {
    try {
      const availableTabNames = dblabel.map((tab) => {
        return navOptions.find((obj) => tab.TabName === obj.dbTabName).url;
      });
      const currentNav = window.location.href;
      let isCurrentURLInAvailableTabNames = false;
      availableTabNames.forEach((tab) => {
        if (currentNav.includes(tab)) {
          isCurrentURLInAvailableTabNames = true;
        }
      });
      if (!isCurrentURLInAvailableTabNames) {
        if (dblabel.length > 0) {
          const initialTab = dblabel[0].TabName;
          const initialURL = navOptions.find((r) => r.dbTabName === initialTab).url;
          props.history.push(initialURL);
        }
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  useEffect(() => {
    checkIfTheTabIsAvailableBeforeRedirection();
  }, [dblabel]);

  // CLOSE FORM AND NAVIGATE TO VIEW PROJECTS
  const closeFormAndRedirect = () => {
    toggleConfirmBox();
    if (selectedSolutionToRedirect.url) {
      // user has selected a solution when form is open
      // REDIRECTFUNCTION FOR SOLUTION SELECTION
      redirectToSolutionOnClosingConfirmBox();
    }
    if (selectedNav.url) {
      redirectionOnClosingConfirmBox();
    }
    formGotClosed();
  };

  // REDIRECT TO SELECTED OPTION
  const redirection = (data) => {
    if (isFormOpen) {
      // form is in open state, hence user cannot navigate to other screens
      toggleConfirmBox();
      setSelectedNav(data);
    } else {
      props.history.push({ pathname: data.url, state: { selectedProjectId: projectId } });
      setSelectedNav({});
    }
  };
  // redirect on closing conform box
  const redirectionOnClosingConfirmBox = () => {
    try {
      props.history.push({ pathname: selectedNav.url, state: { selectedProjectId: projectId } });
      setSelectedNav({});
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // redirect to selected solution on closing confirm box
  const redirectToSolutionOnClosingConfirmBox = () => {
    try {
      props.history.push({
        pathname: selectedSolutionToRedirect.url,
        state: { selectedProjectId: projectId },
      });
      if (selectedSolutionToRedirect.id) {
        setSolutionID_Of_solution_module(selectedSolutionToRedirect.id);
      }
      setSelectedSolutionToRedirect({});
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  const toggleConfirmBox = () => {
    setOpenConfirmBox(!openConfirmBox);
  };

  // CHECK THE URL TO GET THE CURRENT ACTIVE TAB
  const internalURLString = () => {
    if (props.location.pathname.includes("start")) {
      return "upload";
    }
    return props.location.pathname;
  };

  // CHOOSE A SOLUTION ON NAVBAR
  const changeSolutionHandler = (value) => {
    try {
      const initialPathOfTheSelectedSolution = getPathOfSelectedSolution(value);
      if (isFormOpen) {
        // form is in open state, hence user cannot navigate to other screens
        toggleConfirmBox();
        const solutionBody = {
          url: initialPathOfTheSelectedSolution,
          id: value,
        };
        setSelectedSolutionToRedirect(solutionBody);
      } else {
        // REDIRECT TO THE SELECTED SOLUTION
        if (initialPathOfTheSelectedSolution !== "") {
          setSolutionID_Of_solution_module(value);
          // navigate to the solution screen
          props.history.push({
            pathname: initialPathOfTheSelectedSolution,
            state: { selectedProjectId: projectId },
          });
        }
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  const expandHeaderFunc = () => {
    setExpandHeader((prev) => !prev);
  };
  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-light white-header" id="top-navbar">
        <div className="col-xs-12 col-sm-6 col-lg-2 select-box-navbar">
          <div className="">
            {solutionsList.length > 0 ? (
              <Select
                className="full-width"
                dropdownClassName="solution-redirect-select-box"
                value={Number(selectedSolution)}
                onChange={changeSolutionHandler}
                aria-label={polyglot.t("choose solution")}
                aria-activedescendant={null}
                aria-owns={null}
                aria-controls={null}
                aria-expanded={false}
                showSearch
                filterOption={(input, option) => {
                  return option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
              >
                {solutionsList.map((each) => (
                  <Option key={each.id} value={each.id} aria-selected={selectedSolution === each.id}>
                    {each.name}
                  </Option>
                ))}
              </Select>
            ) : null}
          </div>
        </div>
        <p className="my-auto ml-4 font-weight-bold">{projectName}</p>
        <button
          className="navbar-toggler"
          type="button"
          onClick={expandHeaderFunc}
          data-bs-toggle="collapse"
          data-bs-target="#df-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse justify-content-center ${expandHeader && "show"}`}>
          <div className="col-xs-12 col-sm-12 solution-nav-links solution-nav-links-center">
            <div className="d-flex justify-content-center topnav-centered">
              <ul className="navbar justify-content-between mb-0">
                {dblabel.map((data, i) => {
                  let className = "link";
                  const currentNav = navOptions.find((r) => r.dbTabName === data.TabName);
                  if (internalURLString().includes(currentNav.name)) {
                    className += " current nav-link-current";
                  }
                  return (
                    <React.Fragment key={i}>
                      <li className="nav-link">
                        <LinkText
                          className={`${className} no-underline`}
                          onClick={() => redirection(currentNav)}
                          ariaLabel={polyglot.t(data.TabName)}
                          href="javascript:;"
                        >
                          {polyglot.t(data.TabName)}
                        </LinkText>
                      </li>
                      <li className="nav-item"></li>
                    </React.Fragment>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
        <ConfirmBoxExitForm
          show={openConfirmBox}
          heading={"Cancel update"}
          cancel={toggleConfirmBox}
          proceed={closeFormAndRedirect}
        />
      </nav>
    </>
  );
}
NavBar.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};
export default withRouter(NavBar);
