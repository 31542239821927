import React, { Component } from "react";
import ModuleRoute from "../../Routes/CSSModuleRoutes";
import NeedHelpHome from "../DFED/Support/NeedHelpHome";
import NavBar from "./NavBar";

// THE INITIAL COMPONENT WHICH RENDERS FOR GI MODULE
class CssMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight,
    };
    this.callResize = this.callResize.bind(this);
  }

  // ON CLICKING ON NEED HELP
  onNeedHelpClick = () => {
    if (this.state.showChatBotCard) {
      this.setState({
        showChatBotCard: false,
      });
    } else {
      this.setState({
        showChatBotCard: true,
      });
    }
  };

  componentDidMount() {
    window.addEventListener("resize", this.callResize);
  }

  callResize() {
    this.setState({
      height: window.innerHeight,
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.callResize);
  }

  render() {
    const dfedContainerHeight = {
      minHeight: this.state.height + "px",
    };
    return (
      <div>
        <NavBar />
        <div>
          <div className="container col-md-10 screen-layout p-0" style={{ minHeight: "58vh" }}>
            <ModuleRoute />
          </div>
          <NeedHelpHome props={this.props} openFrom="DF" />
        </div>
      </div>
    );
  }
}

export default CssMain;
