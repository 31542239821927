import "../../../Assets/Css/QueenBase/CompanyCenter/CompanyCenter.css";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";
import * as Yup from "yup";

import BasePackageDetailsCard from "./Cards/BasePackageDetailsCard";
import BasePackageTemporaryUsersCard from "./Cards/BasePackageTemporaryUsersCard";
import SolutionHeader from "./Headers/SolutionHeader";
import * as path from "../../../Constants/Path";
import CompanyCenterService from "../../../Services/QueenBase/CompanyCenter.js/CompanyCenterService";
import { hasCreatePermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";

const initialValues = {
  tempCount: "",
};

function CompanyPackagesBase({ history, basePackageConsumptionDetails, tempData }) {
  const { getUserLanguage, getCurrentUserPermissions } = useContext(DataContext);
  const currentUserPermissions = getCurrentUserPermissions();
  const polyglot = polyglotLoader(getUserLanguage());
  const defaultPeriod = basePackageConsumptionDetails[basePackageConsumptionDetails.length - 1].period;
  const [basePackagePeriod, setBasePackagePeriod] = useState(defaultPeriod);
  const getPackageInfoByPeriod = () =>
    basePackageConsumptionDetails.filter((basePackage) => basePackage.period === basePackagePeriod)[0];
  const [basePackageInfo, setBasePackageInfo] = useState(getPackageInfoByPeriod());

  const navigate = () => {
    history.push({
      pathname: path.BASEPACKAGEUPGRADATION_PATH,
      state: basePackageConsumptionDetails,
    });
  };

  useEffect(() => {
    setBasePackageInfo(getPackageInfoByPeriod());
  }, [basePackagePeriod]);

  const validationSchema = Yup.object().shape({
    tempCount: Yup.number()
      .integer("Please enter a valid number")
      .typeError("Please enter a valid number")
      .positive("Please enter a valid number")
      .min(1, polyglot.t("Number of users must be grater than 1"))
      .required(polyglot.t("Number of users is required")),
  });

  const buyTempUsersInCompanyCenter = async (values) => {
    const res = await CompanyCenterService.get().buyTempUsersInCompanyCenter({
      tempCount: values.tempCount,
      price: tempData.price,
      noofmonths: tempData.noofmonths,
    });
    if (res.CODE) {
      displayToastMessage(res.MSG, "success");
      history.push(path.COMPANY_CENTER_PATH);
    } else {
      displayToastMessage(res.MSG, "error");
    }
  };

  return (
    <SolutionHeader
      solutionName={polyglot.t("Connected Digital Services packages")}
      selectOptions={basePackageConsumptionDetails}
      onSelect={(period) => setBasePackagePeriod(period)}
      selectedPeriod={basePackagePeriod}
      hasFilter
      isBase
    >
      <div>
        <div className="row m-0">
          <div className="col-lg-4 col-sm-12 pl-0 mb-2 pr-0 margin-right-15">
            <BasePackageDetailsCard packageInfo={basePackageInfo} onClick={navigate} />
          </div>
          {basePackagePeriod === defaultPeriod && (
            <div className="col-lg-4 col-sm-12 pl-0 mb-2 pr-0 margin-right-15">
              <BasePackageTemporaryUsersCard
                packageInfo={basePackageInfo}
                onSubmit={buyTempUsersInCompanyCenter}
                hasCreatePermission={hasCreatePermission(currentUserPermissions)}
                initialValues={initialValues}
                validationSchema={validationSchema}
              />
            </div>
          )}
        </div>
      </div>
    </SolutionHeader>
  );
}
CompanyPackagesBase.propTypes = {
  history: PropTypes.object,
  basePackageConsumptionDetails: PropTypes.array,
  tempData: PropTypes.object,
};
export default withRouter(CompanyPackagesBase);
