/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/display-name */
import { Checkbox } from "appkit-react";
import PropTypes from "prop-types";
import React, { forwardRef, useContext, useEffect, useImperativeHandle, useState } from "react";

import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/QueenBase/Root/loading-spin.svg";
import ProjectService from "../../../Services/QueenBase/Projects/ProjectService";
import { DataContext } from "../../../Services/Shared/Store.js";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";

// STEP 2 OF PROJECT FORM - CHOOSE A SOLUTION
const ProjectSolution = forwardRef((props, ref) => {
  const { getUserLanguage } = useContext(DataContext);
  const [isLoading, setIsLoading] = useState(false);
  const langauge = getUserLanguage();
  const {
    activatedSolutionList,
    pendingSolutionList,
    notActivatedSolutionList,
    selectedSolutions,
    setFetchedData,
    projectSolutionFieldChange,
    selectedNotActivatedSolutions,
  } = props;
  var polyglot = polyglotLoader();
  // IMPERATIVE HANDLER TO HANDLE FUNCTIONS OF THIS COMPONENT
  useImperativeHandle(ref, () => ({
    // VALIDATE STEP 2 OF PROJECT FORM
    async validate() {
      let solutionsselected = activatedSolutionList.filter((r) => {
        return isSolutionSelected(selectedSolutions, r.id);
      });
      let NotActivesolutionsselected = notActivatedSolutionList.filter((r) => {
        return isSolutionSelected(selectedNotActivatedSolutions, r.id);
      });
      if (solutionsselected.length > 0 && NotActivesolutionsselected.length == 0) {
        return true;
      } else {
        return false;
      }
    },
  }));
  // FILTER AND RETURN NOT ACTIVATED SOLUTIONS
  const filterNotActivatedSolutionsByAvailabilityStatus = (notActivatedSolutionList) => {
    const notActivatedSolutions = [];
    notActivatedSolutionList.forEach((obj) => {
      if (obj.availablitystatus) {
        notActivatedSolutions.push(obj);
      }
    });
    return notActivatedSolutions;
  };
  /* GET ACTIVATED AND NOT ACTIVATED SOLUTIONS BASED ON THE LANGUAGE */
  const getProjectSolutions = async (langauge) => {
    try {
      setIsLoading(true);
      var res = await ProjectService.get().getProjectSolutions(langauge);
      setIsLoading(false);
      if (res.CODE) {
        const notActivatedSolutionsList = filterNotActivatedSolutionsByAvailabilityStatus(
          res.notActivatedSolutionList,
        );
        setFetchedData("getProjectSolutions", {
          activatedSolutionList: res.activatedSolutionList,
          notActivatedSolutionList: notActivatedSolutionsList,
          pendingSolutionList: res.pendingSolutionList,
        });
      } else {
        displayToastMessage(res.MSG, "error");
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // SELECT NOT ACTIVATED SOLUTION
  const selectNotActivatedSolution = async (item) => {
    projectSolutionFieldChange("selectedNotActivatedSolutions", item.id);
  };

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    getProjectSolutions(langauge);
  }, [langauge]);

  // CHECK IF SOLUTION IS SELECTED BY THE USER
  const isSolutionSelected = (JSONArray, solutionId) => {
    let isSelected = false;
    if (JSONArray.indexOf(solutionId) >= 0) {
      isSelected = true;
    }
    return isSelected;
  };

  const signContract = {
    solutionid: selectedNotActivatedSolutions.length > 0 ? selectedNotActivatedSolutions[0] : null,
    isSignContract: selectedNotActivatedSolutions.length > 0 ? 1 : 0,
  };

  // FILTER SOLUTIONS AND RETURNS THE SOLUTIONS WITH NO DUPLICATE SOLUTION NAME
  const filterSolutions = () => {
    const uniqueSolutions = [];
    let uniqueObj = {};
    activatedSolutionList.forEach((item) => {
      const solutionTitle = item.name;
      uniqueObj[solutionTitle] = item;
    });
    Object.keys(uniqueObj).map((k) => {
      uniqueSolutions.push(uniqueObj[k]);
    });
    return uniqueSolutions;
  };

  return (
    <div>
      <div className="project-solution-pods">
        {isLoading && (
          <LoadingSpinner
            width={44}
            height={44}
            fill={"#ef3829"}
            style={{ display: "flex", marginTop: 0, marginLeft: "47%" }}
          />
        )}
        {!isLoading && (
          <>
            <div className="row">
              <div className="col-md-12 ">
                <h6 className="subtitle_two_title_qb">{polyglot.t("Activated solutions")}</h6>
              </div>
              {filterSolutions().length > 0 &&
                filterSolutions().map((item, index) => {
                  return (
                    <div className="col-md-5 solution_boxes" key={index}>
                      <div className="cardHeight">
                        <div
                          className="card-body BorderStyleEDiscovery"
                          style={{ borderLeft: `4px solid ${item.body.cardColor}` }}
                        >
                          <div className="row">
                            <div className="col-md-10">
                              <p className="sub-text-project-solution">{item.name}</p>
                            </div>
                            <div className="col-md-2">
                              <Checkbox
                                className="a-login-radio"
                                checked={isSolutionSelected(selectedSolutions, item.id)}
                                onClick={() => projectSolutionFieldChange("selectedSolutions", item.id)}
                                aria-label="choose solution"
                              ></Checkbox>
                            </div>
                          </div>

                          <p className="card-text project-solution-desc-text">
                            {item.body.body.projecttitle}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <br />
            {pendingSolutionList.length > 0 ? (
              <div>
                <div className="row">
                  <p className="project-solution-subtext-two">{polyglot.t("Pending Solutions")}</p>
                </div>
                <div className="row">
                  {pendingSolutionList.map((item, index) => {
                    return (
                      <div className="col-md-5 solution_boxes" key={index}>
                        <div className="cardHeight">
                          <div className="card-body BorderStyleEDiscovery">
                            <div className="row">
                              <div className="col-md-10">
                                <p className="sub-text-project-solution">{item.name}</p>
                              </div>
                              <div className="col-md-2">
                                <Checkbox
                                  className="a-login-radio"
                                  checked={item.isselected}
                                  aria-label="choose solution"
                                  disabled // as there is no functionality assigned on selecting the checkbox
                                ></Checkbox>
                              </div>
                            </div>

                            <p className="card-text project-solution-desc-text">
                              {item.body.body.projecttitle}
                            </p>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <br />
              </div>
            ) : (
              ""
            )}

            {notActivatedSolutionList.length > 0 && (
              <div className="row">
                <p className="project-solution-subtext-two">{polyglot.t("Not activated")}</p>
              </div>
            )}
            <div className="row">
              {notActivatedSolutionList.length > 0 &&
                notActivatedSolutionList.map((item, index) => {
                  return (
                    <div className="col-md-5 solution_boxes" key={index}>
                      <div className="cardHeight">
                        <div className="card-body BorderStyleContract">
                          <div className="row">
                            <div className="col-md-10">
                              <p className="sub-text-two">{item.name}</p>
                            </div>
                            <div className="col-md-2">
                              {/* we are using checkbox instead of radio because user should uncheck if required */}
                              <Checkbox
                                className="a-login-radio"
                                checked={isSolutionSelected(selectedNotActivatedSolutions, item.id)}
                                onClick={() => selectNotActivatedSolution(item, index)}
                                aria-label="choose solution"
                              ></Checkbox>
                            </div>
                          </div>
                          <p className="card-text project-solution-desc-text-two">
                            {item.body.body.projecttitle}
                          </p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              {signContract.isSignContract ? (
                <div className="col-md-8 mt-4">
                  <span className="sub-text-three">{polyglot.t("Activate a solution")}</span>
                  <div className="TersmConditions">
                    {polyglot.t(
                      "You need to agree to the Terms & Conditions and sign the contract before you can activate this solution.",
                    )}{" "}
                  </div>
                  <br />
                  <ButtonSecondary
                    onClick={() => props.navSign(signContract)}
                    ariaLabel={polyglot.t("Sign contract")}
                  >
                    {polyglot.t("Sign contract")}
                  </ButtonSecondary>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="row">
              <br />
              <br />
            </div>
          </>
        )}
      </div>
    </div>
  );
});
ProjectSolution.propTypes = {
  activatedSolutionList: PropTypes.array,
  pendingSolutionList: PropTypes.array,
  notActivatedSolutionList: PropTypes.array,
  selectedSolutions: PropTypes.array,
  setFetchedData: PropTypes.func,
  projectSolutionFieldChange: PropTypes.func,
  selectedNotActivatedSolutions: PropTypes.array,
  navSign: PropTypes.func,
};
export default ProjectSolution;
