export const getDFClasses = (openFrom, key) => {
  if (openFrom === "DF") {
    switch (key) {
      case "row":
        return "df-row";
      case "pointer":
        return "df-cursor";
      default:
        return "";
    }
  }
  return "";
};
