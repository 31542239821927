import React from "react";

const ClientQuestions = () => {
  return (
    <div className="row grc-content">
      <div className="col-md-2half c-left-panel">LeftPanel</div>

      <div className="col-md-9half c-right-panel">
        <div className="mt-3">
          <div className="project_container m-l-1">CreateQuestion</div>
        </div>
      </div>
    </div>
  );
};

export default ClientQuestions;
