import React, { Component } from "react";
import $ from "jquery";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "../../../../Assets/Css/RI/ProcessGrid.css";
import { polyglotLoader, getCurrentLanguage } from "../../../../Services/Shared/Translate";
import { DataContext } from "../../../../Services/Shared/Store.js";
import { getDisplayDate } from "../../../../Services/Shared/Handlers";
import ProcessGridEdit from "./ProcessGridEdit";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";
import ProcessGridViewPopup from "./ProcessGridViewPopup";
import AdminServiceRI from "../../../../Services/RI/AdminService";
import CommonService from "../../../../Services/Shared/CommonService";

$.DataTable = require("datatables.net-dt");
$.fn.DataTable.ext.errMode = "none";
require("../../Date-de");

require("datatables.net-buttons-dt");
const jzip = require("jszip");
require("datatables.net-buttons/js/buttons.html5.min");
window.JSZip = jzip;

// PROCESS TAB OF ADMIN SCREEN OF DF MODULE
class ProcessGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      targetList: [],
      showEditModal: false,
      showViewModal: false,
      selectedRowBody: {},
      loading: true,
      currentid: null,
    };
  }
  static contextType = DataContext;
  polyglot = polyglotLoader();
  language = getCurrentLanguage();
  // RUNS ON INITIAL LOAD
  componentDidMount() {
    this.getTargetList();
  }
  // GET PROCESS DETAILS
  getTargetList = async () => {
    const polyglot = polyglotLoader();
    // API TO GET TARGET LIST WHICH ARE DISPLAYED ON PROCESS SCREEN - ADMIN TAB
    try {
      const res = await AdminServiceRI.get().getAllRequests();

      if (res.CODE) {
        this.setState({
          targetList: res.list,
          loading: false,
        });
        this.dataStructureForProcessGrid(this);
      } else {
        displayToastMessage(polyglot.t(res.MSG), "error");
      }
    } catch (err) {
      displayToastMessage(polyglot.t(err.message), "error");
    }
  };

  // SHOW EDIT MODAL FROM
  showEditModal = (formBody) => {
    this.setState({
      showEditModal: true,
      selectedRowBody: formBody,
    });
  };

  // TOGGLE EDIT MODAL FORM
  toggleEditModal = (formBody) => {
    this.setState((prevState) => {
      return {
        showEditModal: !prevState.showEditModal,
        selectedRowBody: formBody,
      };
    });
  };

  // SHOW VIEW MODAL FROM
  showViewModal = (formBody) => {
    this.setState({
      showViewModal: true,
      selectedRowBody: formBody,
    });
  };
  // TOGGLE VIEW MODAL FORM
  toggleViewModal = (formBody) => {
    this.setState((prevState) => {
      return {
        showViewModal: !prevState.showViewModal,
        selectedRowBody: formBody,
        currentid: formBody.ID,
      };
    });
  };

  statusTag = (status, statusText) => {
    if (status === 1) {
      return `<span class="a-tag request_status_client a-tag-md"><span class="a-tag-content">${statusText}</span></span>`;
    }
    if (status === 2) {
      return `<span class="a-tag ri-dc a-tag-md"><span class="a-tag-content">${statusText}</span></span>`;
    }
    if (status === 3) {
      return `<span class="a-tag request_status_processing a-tag-md"><span class="a-tag-content">${statusText}</span></span>`;
    }
    if (status === 4) {
      return `<span class="a-tag df-pfa a-tag-md"><span class="a-tag-content">${statusText}</span></span>`;
    }
  };

  // CREATE DATA STRUCTURE FOR UPLOAD TABLE
  dataStructureForProcessGrid = () => {
    var tempData = this.state.targetList;
    if (this.state.loading === true) {
      return false;
    }
    var dataForTbl = [];
    tempData.forEach((ele1) => {
      // get requested on date
      const requestedOn = getDisplayDate(ele1.createdOn);
      const dateFrom = ele1.timeScopeFrom !== null ? getDisplayDate(ele1.timeScopeFrom) : "";
      const dateTo = ele1.timeScopeTo !== null ? getDisplayDate(ele1.timeScopeTo) : "";

      dataForTbl.push([
        ele1.ID,
        requestedOn,
        ele1.createdBy,
        ele1.status,
        ele1.value,
        ele1.entities,
        ele1.tableauURL,
        ele1.generateQueryStatus,
        ele1.query,
        ele1.groupName,
        ele1.createrName,
        ele1.initialRun,
        ele1.scheduleDownload,
        dateFrom,
        dateTo,
      ]);
    });
    this.drawProcessGrid(this, dataForTbl);
  };
  // DRAW PROCESS GRID
  drawProcessGrid = (temp1, dataForTbl) => {
    temp1.$el = $(temp1.el);
    var disText = "";
    temp1.$el.DataTable({
      lengthMenu: [5, 10, 15, 20],
      sDom: "lfrtp",
      oLanguage: {
        emptyTable: `${this.polyglot.t("No records found")}`,
        sLengthMenu: `${this.polyglot.t("Show")} <select class="tabFocus">
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="-1">All</option>
        </select> ${this.polyglot.t("entries")}
        `,
        sSearch: `${this.polyglot.t("Search")}`,
      },
      columns: [
        { title: this.polyglot.t("Request ID") },
        { title: this.polyglot.t("Created on"), type: "date-de" },
        {
          title: this.polyglot.t("Created by"),
          mRender: (data, type, row) => {
            return CommonService.get().titleCase(row[10]);
          },
        },
        {
          title: this.polyglot.t("Status"),
          mRender: (data, type, row) => {
            return this.statusTag(row[3], row[4]);
          },
        },
        {
          title: this.polyglot.t("Actions"),
          class: "actionSortingRemove",
          data: null,
          mRender: (data, type, row) => {
            let dstatus = "";
            if (row[8] && row[3] >= 3) {
              dstatus =
                ' <button aria-label="' +
                this.polyglot.t("Download query") +
                '" class="appkiticon icon-download-outline processGridViewIcon editer_view1"  id="downloadRow_' +
                row[0] +
                '"></button>';
            }
            return (
              '<button aria-label="' +
              this.polyglot.t("edit") +
              '" class="appkiticon icon-edit-outline classprocess_edit processGridEditIcon classprocess_"  id="procesdata`@*' +
              row[0] +
              "`@*" +
              row[3] +
              "`@*" +
              row[4] +
              "`@*" +
              row[5] +
              "`@*" +
              row[6] +
              "`@*" +
              row[7] +
              "`@*" +
              row[11] +
              "`@*" +
              row[12] +
              '" data-id="' +
              row[0] +
              '">  </button>&nbsp; &nbsp; <button aria-label="' +
              this.polyglot.t("view") +
              '" class="appkiticon icon-view-outline processGridViewIcon editer_view1" id="viewRow_' +
              row[0] +
              "_" +
              row[3] +
              "_" +
              row[4] +
              "_" +
              row[1] +
              '"></button> </button>&nbsp; &nbsp; ' +
              dstatus
            );
          },
        },
      ],
      data: dataForTbl,
      lenghtType: "simple",
      searching: true,
      destroy: true,
      order: [0, "desc"],
      language: {
        infoEmpty: "",
        info: "_PAGE_ of _PAGES_",
        paginate: {
          firt: "<<",
          previous: '<i_ngcontent-c10="" class="appkiticon icon-left-chevron-fill"> </i>',
          next: '<i_ngcontent-c10="" class="appkiticon icon-right-chevron-fill"> </i>',
          last: ">>",
        },
      },
      stateSave: true,
      drawCallback: function () {
        if (dataForTbl.length <= 0) {
          $(".dataTables_paginate").hide();
        } else {
          $(".dataTables_paginate").show();
        }
      }, // OVER : drawback function
    });
    var tempReactThis = this;
    $(document).on("click", "[id*='procesdata`@*']", function () {
      const idData = this.id.split("`@*");
      const selectedRow = tempReactThis.state.targetList.find((ele) => Number(ele.ID) === Number(idData[1]));
      let jsonGrpName = null;
      if (selectedRow) {
        jsonGrpName = JSON.parse(selectedRow.groupName);
      }

      const body = {
        id: idData[1],
        status: idData[2],
        statusValue: idData[3],
        entities: idData[4].split(","),
        tableauURL: idData[5],
        generateQueryStatus: idData[6],
        groupName: jsonGrpName,
        /**
         * InitialRun true means as soon as the .gz or .pkl file in foler it will start the initial scripts
         */
        initialRun: idData[7] == "true" ? "true" : "false",
        /**
         * scheduleDownload true means nightly cron will run update script for this requestid
         */
        scheduleDownload: idData[8] == "true" ? "true" : "false",
      };
      tempReactThis.showEditModal(body);
    });
    $(document).on("click", "[id*='viewRow_']", function () {
      const idData = this.id.split("_");
      const dtbl = dataForTbl.find((ele) => ele[0] == idData[1]);
      let jsonGrpName = null;
      if (dtbl[9]) {
        jsonGrpName = JSON.parse(dtbl[9]);
      }

      const body = {
        id: idData[1],
        status: idData[2],
        statusValue: idData[3],
        requestedOn: idData[4],
        viewEntities: dtbl[5],
        viewGenerateQuery: dtbl[7] ? "Yes" : "No",
        viewTableauUrl: dtbl[6],
        viewGroupName: jsonGrpName,
        viewCreaterName: CommonService.get().titleCase(dtbl[10]),
        viewTimeScopeFrom: dtbl[13],
        viewTimeScopeTo: dtbl[14],
      };

      tempReactThis.showViewModal(body);
    });

    $(document).on("click", "[id*='downloadRow_']", function (e) {
      e.stopImmediatePropagation();
      const idData = this.id.split("_");
      const id = idData[1];
      const newdbtl = temp1.state.targetList.find((e) => e.ID == id);
      const jsonQuery = JSON.parse(newdbtl.query);
      let finalQuery = "";
      jsonQuery.forEach((e) => {
        finalQuery += e + "\n";
      });
      const filename = "query_" + id + ".txt";
      const element = document.createElement("a");
      element.setAttribute("href", "data:text/plain:charset=utf-8," + encodeURIComponent(finalQuery));
      element.setAttribute("download", filename);
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    });
    disText = $("#processGridTable").text();
    var pagNum = "";
    pagNum = disText.replace(pagNum, "<span>" + pagNum + "</span>");
    disText = disText.replace(pagNum, "<span>" + pagNum + "</span>");
  };

  // runs when ever the state value gets updated
  componentDidUpdate(prevProps, prevState) {
    // when selectedRowBody gets updated, then run the below function
    if (prevState.targetList !== this.state.targetList) {
      this.dataStructureForProcessGrid(this);
    }
  }

  render() {
    return (
      <div className="container processGridContainer">
        <div className="row">
          <div className="col-md-12 DF-process-admin">
            <table
              id="processGridTable"
              className="display table-header-focus processGrid"
              width="100%"
              ref={(el) => (this.el = el)}
            />
          </div>
        </div>
        <ProcessGridEdit
          show={this.state.showEditModal}
          toggleEditModal={this.toggleEditModal}
          formBody={this.state.selectedRowBody}
          getTargetList={this.getTargetList}
        />
        <ProcessGridViewPopup
          key={this.state.currentid}
          show={this.state.showViewModal}
          toggleViewModal={this.toggleViewModal}
          formBody={this.state.selectedRowBody}
        />
      </div>
    );
  }
}
export default ProcessGrid;
