import React from "react";
import { Accordions, AccordionItem, AccordionItemHeader, AccordionItemBody } from "appkit-react";
import { Collapse } from "antd";
import newIcon from "../../../Assets/Images/DFED/icon-break.svg";
import redIcon from "../../../Assets/Images/DFED/icon-break-red.svg";
import "./fastforward.css";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import { Tooltip } from "appkit-react";
import { Pagination } from "antd";
import FastForwardService from "../../../Services/DFED/FastForwardService";
import { DownOutlined, RightOutlined } from "@ant-design/icons";
const { Panel } = Collapse;
class Pipeline extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pipelineList: this.props.pipelineList,
      defaultActivePanel: [],
      breakConditionProfile: "Break",
      selectedProjectId: props.selectedProjectId,
      keywordBreak: "Break",
      reviewBreak: "Break",
      keywordName: "Choose KeywordList",
      showKeywordData: false,
      keywordDropdownValue: [],
      currentPage: 1,
      createdPipeLineDataSets: [],
    };
    this.getPipelineDataByPipelineId = this.getPipelineDataByPipelineId.bind(this);
  }
  static contextType = DataContext;
  openAccrdion = (idx) => {
    if (this.state.defaultActivePanel[0] === idx) {
      this.setState({
        cflag: false,
        defaultActivePanel: [],
      });
    } else {
      this.setState({
        defaultActivePanel: [idx],
      });
    }
  };

  newKeywordlist = () => {
    this.props.history.push({
      pathname: "/dfed/keywords",
      state: { selectedProjectId: this.state.selectedProjectId },
    });
  };

  // ================= COMMENTED BY SHEKHAR PLZ ASK BEFORE REMOVE===============
  // static getDerivedStateFromProps(nextProps, prevState) {
  //   if (nextProps.pipelineList.length > prevState.pipelineList.length) {
  //     return {
  //       pipelineList: nextProps.pipelineList,

  //     }
  //   } else {
  //     return null;
  //   }

  // }

  componentDidMount() {
    this.setState(
      {
        pipelineList: this.props.pipelineList,
      },
      () => {
        this.visiableDataHandler(1);
      },
    );
  }

  profileRedIcon = () => {
    this.setState({
      breakConditionProfile: "No-Break",
    });
  };

  profileNewIcon = () => {
    this.setState({
      breakConditionProfile: "Break",
    });
  };

  keywordRedIcon = () => {
    this.setState({
      keywordBreak: "No-Break",
    });
  };
  keywordNewIcon = () => {
    this.setState({
      keywordBreak: "Break",
    });
  };
  reviewRedIcon = () => {
    this.setState({
      reviewBreak: "No-Break",
    });
  };
  reviewNewIcon = () => {
    this.setState({
      reviewBreak: "Break",
    });
  };
  // CODE FOR SHOWING TOOLTIP WITH ALL DATASET FOR THAT PIPELINE ID====

  getContentDatasets(value) {
    var polyglot = polyglotLoader();
    if (value && value.length > 0) {
      return (
        <div className="tooltipcustomClass">
          {value &&
            value.map((parentDataset, index) => {
              return (
                <div className="datasetNameDiv  p-l-11" key={index}>
                  <label style={{ color: "red" }} className="f-s-12 datasetTextIssue">
                    {parentDataset.DT_DATASET_NAME}{" "}
                  </label>
                  {index !== value.length - 1 && <hr></hr>}
                </div>
              );
            })}
        </div>
      );
    } else {
      return (
        <div className="datasetNameDiv  p-l-11">
          <label style={{ color: "red" }} className="f-s-12">
            {polyglot.t("No Data")}{" "}
          </label>
        </div>
      );
    }
  }

  // CODE FOR ONCHANGE EVENT ON PAGINATION==========
  onChangeTablePageHandler = (page) => {
    this.setState({ currentPage: page });
    this.visiableDataHandler(page);
  };

  visiableDataHandler(page) {
    let lastIndex = 7 * page;
    let firstIndex = lastIndex - 7;
    let tempCustodianProcessData = this.props.pipelineList;
    let visData = tempCustodianProcessData.slice(firstIndex, lastIndex);
    this.setState({ pipelineList: visData });
  }

  // CODE FOR FETCH DATASET FROM BACKEND FOR PARTICULAR PIPELINE ID WHEN WE HOVER ON THAT DATASET====
  getPipelineDataByPipelineId(pipelineId) {
    const fastForwardService = new FastForwardService();
    let projectID = this.state.selectedProjectId;
    fastForwardService.fetchPipelineDatByPipeLineID(projectID, pipelineId).then((res) => {
      if (res && res.data && res.data.length > 0) {
        this.setState({
          createdPipeLineDataSets:
            res.data[0].DataSet && JSON.parse(res.data[0].DataSet)[0]
              ? JSON.parse(res.data[0].DataSet)[0].datasettracker
              : [],
        });
      }
    });
  }

  render() {
    var polyglot = polyglotLoader();
    return (
      <div>
        <div className="col-md-12 pl-0 dfed-expand-list-cards">
          {this.state.pipelineList.map((value, index) => {
            return (
              <div key={index}>
                <Accordions
                  className="mt-2 mb-2 ml-0"
                  activeItem={this.state.defaultActivePanel}
                  onChange={this.openAccrdion}
                >
                  <AccordionItem itemId={index} key={index}>
                    <AccordionItemHeader title={value.PIPELINE_NAME} />
                    <AccordionItemBody>
                      <div>
                        <div className="row mt-2">
                          <div className="col-md-3">
                            <div>
                              <div>
                                <div className="t-a-c p-b-4">{polyglot.t("Uploaded")}</div>
                                <Tooltip
                                  content={this.getContentDatasets(this.state.createdPipeLineDataSets)}
                                  placement="right"
                                >
                                  <div>
                                    <button
                                      className="pipeline-button"
                                      style={{
                                        backgroundColor: "#006652",
                                        color: "#FFFFFF",
                                      }}
                                      onMouseOver={this.getPipelineDataByPipelineId.bind(
                                        this,
                                        value.PIPELINE_ID,
                                      )}
                                    >
                                      <div className="transformY-minus10">{polyglot.t("Dataset")}</div>
                                    </button>
                                  </div>
                                </Tooltip>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-3">
                            <div className="t-a-c p-b-4">{polyglot.t("Processing")}</div>
                            {value.PROCESSING_STATUS && parseInt(value.PROCESSING_STATUS) === 3 && (
                              <div>
                                <button
                                  className="pipeline-button"
                                  style={{
                                    backgroundColor: "#006652",
                                    color: "#FFFFFF",
                                  }}
                                >
                                  <div className="transformY-minus10">{value.PR_NAME}</div>
                                </button>
                              </div>
                            )}
                            {value.PROCESSING_STATUS && parseInt(value.PROCESSING_STATUS) === 2 && (
                              <div>
                                <button
                                  className="pipeline-button"
                                  style={{
                                    backgroundColor: "#E0301E",
                                    color: "#FFFFFF",
                                  }}
                                >
                                  <div className="transformY-minus10">{value.PR_NAME}</div>
                                </button>
                              </div>
                            )}
                            {value.PROCESSING_STATUS && parseInt(value.PROCESSING_STATUS) === 1 && (
                              <div>
                                <button
                                  className="pipeline-button"
                                  style={{
                                    backgroundColor: "#7D7D7D",
                                    color: "#FFFFFF",
                                  }}
                                >
                                  <div className="transformY-minus10">{value.PR_NAME}</div>
                                </button>
                              </div>
                            )}

                            {value.BREAK_ANALYSIS === false && (
                              <p className="pipeline-break">
                                <img src={redIcon} alt="redIcon" />
                              </p>
                            )}
                            {value.BREAK_ANALYSIS === true && (
                              <p className="pipeline-break">
                                <img src={newIcon} alt="newIcon" />
                              </p>
                            )}
                            <p className="fastforward-break m-r-minus-13">
                              {value.BREAK_ANALYSIS === false ? polyglot.t("Break") : polyglot.t("No-Break")}
                            </p>
                          </div>
                          <div className="col-md-3 ">
                            <div className="t-a-c p-b-4">{polyglot.t("Analysing")}</div>
                            {value.ANALYSIS_STATUS && parseInt(value.ANALYSIS_STATUS) === 3 && (
                              <div>
                                <button
                                  className="pipeline-button"
                                  style={{
                                    backgroundColor: "#006652",
                                    color: "#ffff",
                                  }}
                                >
                                  <div className="transformY-minus10">{value.LIST_NAME}</div>
                                </button>
                              </div>
                            )}
                            {value.ANALYSIS_STATUS && parseInt(value.ANALYSIS_STATUS) === 2 && (
                              <div>
                                <button
                                  className="pipeline-button"
                                  style={{
                                    backgroundColor: "#E0301E",
                                    color: "#FFFFFF",
                                  }}
                                >
                                  <div className="transformY-minus10">{value.LIST_NAME}</div>
                                </button>
                              </div>
                            )}
                            {value.ANALYSIS_STATUS && parseInt(value.ANALYSIS_STATUS) === 1 && (
                              <div>
                                <button
                                  className="pipeline-button"
                                  style={{
                                    backgroundColor: "#464646",
                                    color: "#FFFFFF",
                                  }}
                                >
                                  <div className="transformY-minus10">{polyglot.t("KeywordList")}</div>
                                </button>
                              </div>
                            )}
                            {value.BREAK_REVIEW === false && (
                              <p className="pipeline-break">
                                <img className="break-border" src={redIcon} alt="redIcon" />
                              </p>
                            )}
                            {value.BREAK_REVIEW === true && (
                              <p className="pipeline-break">
                                <img className="break-border" src={newIcon} alt="newIcon" />
                              </p>
                            )}
                            <p className="fastforward-break m-r-minus-13">
                              {value.BREAK_REVIEW === false ? polyglot.t("Break") : polyglot.t("No-Break")}
                            </p>
                          </div>
                          <div className="col-md-3 ">
                            <div className="t-a-c p-b-4">{polyglot.t("Review")}</div>
                            {value.REVIEW_STATUS && parseInt(value.REVIEW_STATUS) === 3 && (
                              <div>
                                <button
                                  className="pipeline-button"
                                  style={{
                                    backgroundColor: "#006652",
                                    color: "#ffff",
                                  }}
                                >
                                  <div className="transformY-minus10">{polyglot.t("Review")}</div>
                                </button>
                              </div>
                            )}
                            {value.REVIEW_STATUS && parseInt(value.REVIEW_STATUS) === 2 && (
                              <div>
                                <button
                                  className="pipeline-button"
                                  style={{
                                    backgroundColor: "#E0301E",
                                    color: "#ffff",
                                  }}
                                >
                                  <div className="transformY-minus10">{polyglot.t("Review")}</div>
                                </button>
                              </div>
                            )}
                            {value.REVIEW_STATUS && parseInt(value.REVIEW_STATUS) === 1 && (
                              <div>
                                <button
                                  className="pipeline-button"
                                  style={{
                                    backgroundColor: "#464646",
                                    color: "#ffff",
                                  }}
                                >
                                  <div className="transformY-minus10">{polyglot.t("Review")}</div>
                                </button>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </AccordionItemBody>
                  </AccordionItem>
                </Accordions>
              </div>
            );
          })}
        </div>
        <div className="row m-t-16 m-l-n-1 m-r-0">
          {this.props.pipelineList && this.props.pipelineList.length > 7 && (
            <Pagination
              current={this.state.currentPage}
              size="small"
              pageSize={7}
              total={this.props.pipelineList.length}
              onChange={this.onChangeTablePageHandler}
            />
          )}
        </div>
      </div>
    );
  }
}

export default Pipeline;
