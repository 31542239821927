import React from "react";
import { Switch } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import Support from "../Components/DFED/Support/Support";
import AdminIndex from "../Components/QueenBase/Admin";
import CompanyCenterContainer from "../Components/QueenBase/CompanyCenter/index";
import DashboardContainer from "../Components/QueenBase/Dashboard/DashboardContainer";
import project from "../Components/QueenBase/Projects/Project";
import solutionBase from "../Components/QueenBase/Solutions/SolutionBase";
import users from "../Components/QueenBase/Users/Users";
import * as path from "../Constants/Path";

// ROUTES OF QUEENBASE SCREENS
function QueenBaseRoute() {
  return (
    <div>
      <Switch>
        <ProtectedRoute path={path.PROJECT_PATH} component={project} />
        <ProtectedRoute path={path.SOLUTION_PATH} component={solutionBase} />
        <ProtectedRoute path={path.USER_PATH} component={users} />
        <ProtectedRoute path={path.DASHBOARD_PATH} component={DashboardContainer} />
        <ProtectedRoute path={path.COMPANY_CENTER_PATH} component={CompanyCenterContainer} />
        <ProtectedRoute path={path.ADMIN_PATH} component={AdminIndex} />
        <ProtectedRoute path={path.SUPPORT_PATH} component={Support} />
      </Switch>
    </div>
  );
}

export default QueenBaseRoute;
