import React from "react";
import Dropzone from "../../dropzone/Dropzone";
import "./GlobalCustodianComponent.css";
import FormFieldComponent from "../../Shared/components/FormFieldComponent";
import FormTextAreaComponent from "../../Shared/components/FormTextAreaComponent";
import FormSingleSelectWithDescription from "../../Shared/components/FormSingleSelectWithDescription";
import "appkit-react/style/appkit-react.default.css";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import CustodianService from "../../../../Services/DFED/CustodianService";
import closeIcon from "../../../../Assets/Images/DFED/icon-x.svg";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";
import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import download from "../../../../Assets/Images/DFED/icon-download.svg";
import { DataContext } from "../../../../Services/Shared/Store.js";
import { TextFieldLengthRegexFieldValidation } from "../../../../utility/textFieldValidation";
import Progress from "../../progress/Progress";

// GLOBAL CUSTODIAN CLASS HANDLING CREATING OF GLOBAL CUSTODIAN AND EDITING OF GLOBAL CUSTODIAN===============
class GlobalCustodianComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        CRT_TYPE_ID: this.props.custodianTypeID ? this.props.custodianTypeID : null,
        CRT_FIRST_NAME: "",
        CRT_MIDDLE_NAME: "",
        CRT_LAST_NAME: "",
        CRT_EMAIL: "",
        CRT_POSITION_ID: "",
        CRT_COUNTRY_ID: "",
        CRT_ENTITY: "",
        CRT_DEPARTMENT: "",
        CRT_ALIAS: "Global",
        CC_CLASSIFICATION_TYPE: "",
        CRT_CLASSIFICATION_ID: "",
        CRT_NOTES: "",
        CRT_FILE: [],
        position_Name: null,
        CRT_PROJECT_ID: this.props.selectedProjectId,
        CRT_PROJECT_NAME: this.props.selectedProjectName,
      },
      saveButtonDisabled:
        this.props.isCustodianEditableAfterProcessed === false && this.props.editableFormData !== null
          ? false
          : true,
      custodianRecords: null,
      CountryList: null,
      ClassificationList: null,
      positionList: null,
      positionSelectValue: null,
      files: [],
      filesize: null,
      requestProgress: null,
    };
    this.onChangeFormField = this.onChangeFormField.bind(this);
    this.saveFormData = this.saveFormData.bind(this);
    this.formFileUpload = this.formFileUpload.bind(this);
    this.downloadCustodianById = this.downloadCustodianById.bind(this);
  }
  static contextType = DataContext;
  polyglot = polyglotLoader();

  // LIFECYCLE METHOD FOR FETCHING DATA FOR GLOBAL CUSTODIAN FORM

  componentDidMount() {
    const custodianService = new CustodianService();
    custodianService.getClassifications().then((classificationResult) => {
      if (classificationResult && classificationResult.data) {
        this.setState(
          {
            ClassificationList: classificationResult.data,
          },
          () => {},
        );
      }
    });

    if (this.props.editableFormData) {
      var fileArr = this.props.editableFormData[0].files
        ? this.props.editableFormData[0].files.split(";")
        : null;
      let tempFileArr = [];
      if (fileArr && fileArr.length > 0) {
        for (let i = 0; i < fileArr.length; i++) {
          let obj = { name: fileArr[i] };
          tempFileArr.push(obj);
        }
      }
      this.setState(
        {
          formData: this.props.editableFormData[0],
        },
        () => {
          if (this.checkFieldValidation()) {
            this.setState({
              saveButtonDisabled: false,
            });
          }
        },
      );
      this.setState((prevState) => {
        return {
          formData: {
            ...prevState.formData,
            CRT_FILE: tempFileArr,
          },
        };
      });
    }
  }

  // RUNS WHEN COMPONENT GETS UPDATED
  componentDidUpdate(prevProps, prevState) {
    const isFormViewOnly = this.props.isCustodianEditableAfterProcessed;
    if (!isFormViewOnly && this.state.formData !== prevState.formData && "CRT_ID" in prevState.formData) {
      const { formGotOpened } = this.context;
      formGotOpened();
    }
  }
  // THIS FUNCTION IS FOR HANDLING ALL THE FILED CHANGE EVENT IN FORM================

  onChangeFormField(label, value, id) {
    let val = value;
    let name = label;
    if (id === "manualInput") {
      if (label === "CRT_POSITION_ID") {
        this.setState((prevState) => {
          return {
            formData: {
              ...prevState.formData,
              position_Name: value,
            },
          };
        });
      }
    }
    if (label === "CRT_FIRST_NAME") {
      this.setState((prevState) => {
        return {
          formData: {
            ...prevState.formData,
            CRT_ALIAS: val + " " + prevState.formData.CRT_LAST_NAME,
          },
        };
      });
    }
    if (label === "CRT_LAST_NAME") {
      this.setState((prevState) => {
        return {
          formData: {
            ...prevState.formData,
            CRT_ALIAS: prevState.formData.CRT_FIRST_NAME + " " + val,
          },
        };
      });
    }
    if (label === "CRT_DEPARTMENT") {
      this.setState((prevState) => {
        return {
          formData: {
            ...prevState.formData,
            CRT_ALIAS: prevState.formData.CRT_ENTITY + " " + val,
          },
        };
      });
    }
    if (label === "CRT_ENTITY") {
      this.setState((prevState) => {
        return {
          formData: {
            ...prevState.formData,
            CRT_ALIAS: val + " " + prevState.formData.CRT_DEPARTMENT,
          },
        };
      });
    }

    if (!id && label !== "CRT_ALIAS") {
      this.setState(
        (prevState) => {
          return {
            formData: {
              ...prevState.formData,
              [name]: val,
            },
          };
        },
        () => {
          if (this.checkFieldValidation()) {
            this.setState({
              saveButtonDisabled: false,
            });
          } else {
            this.setState({
              saveButtonDisabled: true,
            });
          }
        },
      );
    }
  }

  // Download Files of custodian
  downloadCustodianById(downloadIndex, cid, data) {
    let metadata;
    this.state.formData.CRT_FILE.map((fileName, index) => {
      var fname = fileName.name.split(",")[1];
      fname = fname.split(".")[1];
      if (cid == index) {
        metadata = {
          fileExtension: fname,
          projectName: this.props.selectedProjectName,
          CRT_ALIAS: this.state.formData.CRT_ALIAS,
          CRT_ID: this.state.formData.CRT_ID,
          CRT_TYPE_ID: this.state.formData.CRT_TYPE_ID,
          CRT_FILE: fileName.name,
        };
      }
    });

    const custodianService = new CustodianService();
    custodianService
      .downloadCustodianById(metadata)
      .then((response) => {
        if (response.data) {
          if (response.data.CODE === 1) {
            displayToastMessage(this.polyglot.t(`${response.data.MSG}`), "success"); // Downloaded successfully, translation added
            const buffer = Buffer.from(response.data.buffer);
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", response.data.fileName);
            document.body.appendChild(link);
            link.click();
          } else {
            displayToastMessage(this.polyglot.t(`${response.data.MSG}`), "error");
          }
        }
      })
      .catch((error) => {});
  }

  // DROPE FUNCTIONALITY FOR FILE DRAG AND DROP====
  onDrop = (acceptedFiles, index) => {
    this.formFileUpload(acceptedFiles);
  };
  // FILE UPLOAD FUNCTIONALITY
  formFileUpload(data) {
    if (data.length > 0) {
      let obj = {
        name: data[0].name,
      };

      if (
        this.state.formData.CRT_FILE &&
        this.state.formData.CRT_FILE.length < 5 &&
        data[0].size < 104857600
      ) {
        this.setState({
          formData: {
            ...this.state.formData,
            CRT_FILE: [...this.state.formData["CRT_FILE"], obj],
          },
          files: [...this.state.files, data[0]],
          filesize: data[0].size,
        });
      } else {
        this.setState({
          formData: {
            ...this.state.formData,
          },
          filesize: data[0].size,
        });
      }
    }
  }
  // DELETE FILE UPLOAD FUNCTIONALITY
  deleteUploadedFile(filedata, index, data) {
    this.state.formData.CRT_FILE.splice(index, 1);
    this.state.files.splice(index, 1);
    this.setState({
      formData: {
        ...this.state.formData,
      },
      files: this.state.files,
    });
  }
  // VALIDATING THE FIELD====================
  checkFieldValidation() {
    if (
      this.state.formData.CRT_CLASSIFICATION_ID !== "" &&
      this.state.formData.CRT_CLASSIFICATION_ID !== null &&
      this.state.formData.CRT_ALIAS !== "" &&
      this.state.formData.CRT_ALIAS !== null &&
      this.props.isCustodianEditableAfterProcessed === false
    ) {
      return true;
    } else {
      return false;
    }
  }

  // CHECK AND DISABLE ALIAS FIELD
  isAliasFieldDisabled = () => {
    let isDisabled = false;
    if (this.props.isCustodianEditableAfterProcessed) {
      isDisabled = true;
    }
    return isDisabled;
  };

  // INPUT FIELD DISABLE IF DATASET ALREADY PROCESSED FOR THIS CUSTODIAN===========

  textFieldDIsableHandler = () => {
    if (this.props.isCustodianEditableAfterProcessed === true) {
      return true;
    } else {
      return false;
    }
  };

  onUploadProgress = (progressEvent) => {
    let progress = (progressEvent.loaded / progressEvent.total) * 100;
    this.setState({ requestProgress: progress });
  };

  //  SAVE FORM DATA FUNCTIONALITY======================
  saveFormData() {
    const { formGotClosed } = this.context;

    if (
      this.state.formData.CRT_NOTES != "" &&
      this.state.formData.CRT_NOTES != "" &&
      !TextFieldLengthRegexFieldValidation(this.state.formData.CRT_NOTES)
    ) {
      displayToastMessage(this.polyglot.t("field_validaton"), "error");
    } else {
      // Logic for split First Name and Last Name Based on Alias//
      if (parseInt(this.state.formData.CRT_TYPE_ID) === 4) {
        let nameArry = this.state.formData.CRT_ALIAS.split(" ");
        let tempFirstName = nameArry[0] ? nameArry[0] : null;
        let tempLastName = nameArry.length >= 2 ? nameArry[1] : "";
        this.setState(
          (prevState) => {
            return {
              formData: {
                ...prevState.formData,
                CRT_FIRST_NAME: tempFirstName,
                CRT_LAST_NAME: tempLastName,
              },
            };
          },
          () => {
            let selectedProjectId = this.props.selectedProjectId;
            const custodianService = new CustodianService();
            let formData = new FormData();
            for (let i = 0; i < this.state.files.length; i++) {
              formData.append("file", this.state.files[i]);
              formData.append("fileName", this.state.files[i].name);
            }
            formData.append("custodianFormData", JSON.stringify(this.state.formData));
            formData.append("projectName", this.props.selectedProjectName);
            custodianService.handleCustodianFormData(formData, this.onUploadProgress).then((result) => {
              if (result && result.data.exist) {
                displayToastMessage(
                  this.polyglot.t("Custodian with same Alias is already exist Please enter different name"),
                  "error",
                );
              } else {
                if (this.props.editableFormData) {
                  displayToastMessage(this.polyglot.t("Custodian updated successfully"), "success");
                } else {
                  displayToastMessage(this.polyglot.t("Custodian created successfully"), "success");
                }
                custodianService.getCustodianData(selectedProjectId).then((result1) => {
                  if (result1 && result1.data) {
                    this.setState(
                      {
                        custodianRecords: result1.data,
                      },
                      () => {
                        this.props.showCustodianList(this.state.custodianRecords);
                      },
                    );
                  }
                });
              }
              formGotClosed();
            });
          },
        );
      }
    }
  }

  renderProgress = () => {
    return (
      <div className="col-md-8 p-r-0">
        <div className="row">
          <div className="col-md-10" style={{ marginLeft: "10%" }}>
            <div className="ProgressWrapper" style={{ width: "200%", float: "left" }}>
              <Progress progress={this.state.requestProgress ?? 0} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // RENDER FUNCTION FOR GLOBAL CUSTODIAN================
  render() {
    var polyglot = polyglotLoader();
    return (
      <div>
        <div className="row fade-in-top m-l-0 m-r-0">
          <div
            className="col-sm-12 col-md-12 inputFieldGroup m-t-28 p-b-5"
            aria-label={polyglot.t("General")}
          >
            <span>{polyglot.t("General")}</span>
          </div>
          <div className="col-sm-12 col-md-6">
            <FormFieldComponent
              disabledTextFields={this.isAliasFieldDisabled()}
              aria-label={polyglot.t("Alias *")}
              placeholder={polyglot.t("Alias *")}
              id="alias"
              name="CRT_ALIAS"
              val={this.state.formData.CRT_ALIAS}
              onChangeFormField={this.onChangeFormField.bind(this)}
            ></FormFieldComponent>
          </div>

          <div className="col-sm-12 col-md-6">
            <FormSingleSelectWithDescription
              disabledTextFields={this.textFieldDIsableHandler()}
              preFillSelectWithDescription={this.props.preFillSelectWithDescription}
              aria-label={polyglot.t("Classification *")}
              placeholder={polyglot.t("Classification *")}
              id="classifications"
              val={this.state.formData.CRT_CLASSIFICATION_ID}
              name="CRT_CLASSIFICATION_ID"
              onChangeFormField={this.onChangeFormField.bind(this)}
              classificationOptions={this.state.ClassificationList}
            />
          </div>
          <div className="col-sm-12 col-md-12">
            <FormTextAreaComponent
              disabledTextFields={this.textFieldDIsableHandler()}
              aria-label={polyglot.t("Notes_custodian")}
              role="presentation"
              placeholder={polyglot.t("Notes_custodian")}
              id="notes"
              name="CRT_NOTES"
              val={this.state.formData.CRT_NOTES}
              onChangeFormField={this.onChangeFormField.bind(this)}
            ></FormTextAreaComponent>
          </div>
          <div className="col-sm-12 col-md-12 p-t-10 p-b-10">
            <div className="file-upload-header" aria-label={polyglot.t("Upload additional information")}>
              {polyglot.t("Upload additional information")}
            </div>
            <div
              className="file-upload-header-description p-t-5 p-b-15"
              aria-label={polyglot.t("(E.g. employee contract, consent, etc.)")}
            >
              {polyglot.t("(E.g. employee contract, consent, etc.)")}
            </div>
            <div
              className="file-upload-header-description f-t-14 p-b-15 text-color"
              role="none"
              aria-label={polyglot.t(
                "Note: Maximum number of files that can be uploaded is 5 (Max size : 100 MB each)",
              )}
            >
              {polyglot.t("Note: Maximum number of files that can be uploaded is 5 (Max size : 100 MB each)")}
            </div>
            <div id="dfed-custodian-personal">
              <Dropzone
                disabled={this.props.isCustodianEditableAfterProcessed}
                onFilesAdded={(e) => this.onDrop(e)}
              ></Dropzone>
            </div>
          </div>
          <div className="col-sm-12 col-md-12">
            <div className="">
              {this.state.formData.CRT_FILE &&
                this.state.formData.CRT_FILE.length > 0 &&
                this.state.formData.CRT_FILE.map((acceptedFile, index) => (
                  <div className="uploadedFileList m-b-6">
                    {acceptedFile.name.split(",").length > 1
                      ? acceptedFile.name.split(",")[1]
                      : acceptedFile.name}
                    &nbsp;&nbsp;
                    {/* Condition for, if files is no avalible in formdata, disable the download icon */}
                    {this.state.formData.files && this.state.formData.files !== "undefined" ? (
                      <img
                        src={download}
                        alt={download}
                        key={index}
                        onClick={this.downloadCustodianById.bind(
                          this,
                          acceptedFile.name.split(",")[0],
                          index,
                        )}
                        className=" cursor"
                      ></img>
                    ) : null}
                    <div className="fileDeleteIcon">
                      <button
                        disabled={this.textFieldDIsableHandler()}
                        onClick={this.deleteUploadedFile.bind(this, acceptedFile.name.split(",")[0], index)}
                        className="cursor"
                      >
                        <img
                          src={closeIcon}
                          alt={this.polyglot.t("Remove file")}
                          aria-label={this.polyglot.t("Remove file")}
                        />
                      </button>
                    </div>
                  </div>
                ))}
              {this.state.requestProgress && <div className="col-md-10 p-r-0">{this.renderProgress()}</div>}
            </div>
            {this.state.formData.CRT_FILE && this.state.formData.CRT_FILE.length === 5 && (
              <div
                className="file-upload-header-description f-t-14 p-b-15 text-color"
                aria-label={polyglot.t(
                  "Maximum file upload limit is  5 you crossed that limit. sorry you can't upload more.",
                )}
              >
                {polyglot.t(
                  "Maximum file upload limit is  5 you crossed that limit. sorry you can't upload more.",
                )}
              </div>
            )}
            {this.state.filesize != null && this.state.filesize > 104857600 && (
              <div
                className="text-danger"
                aria-label={this.polyglot.t("Maximum file upload size is 100 MB only")}
              >
                {this.polyglot.t("Maximum file upload size is 100 MB only")}
              </div>
            )}
          </div>
        </div>
        &nbsp;
        <div className="row m-l-0 m-r-0">
          <div className="col-sm-6 col-md-6 p-l-15">
            <ButtonPrimary
              disabled={this.state.saveButtonDisabled}
              onClick={this.saveFormData.bind(this)}
              ariaLabel={this.polyglot.t("Save custodian")}
            >
              {this.polyglot.t("Save custodian")}
            </ButtonPrimary>
          </div>
        </div>
        {this.props.isCustodianEditableAfterProcessed && (
          <div className="row m-l-0 m-r-0">
            <div
              className="file-upload-header-description f-s-14 p-b-15 text-color p-l-16"
              aria-label={polyglot.t("* You Can't update this custodian. it is already processed.")}
            >
              {polyglot.t("* You Can't update this custodian. it is already processed.")}
            </div>
          </div>
        )}
      </div>
    );
  }
}
export default GlobalCustodianComponent;
