import PropTypes from "prop-types";
import React, { useContext, useEffect } from "react";

import WorkflowList from "./WorkflowList";
import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import { plusCircle } from "../../../Assets/Icons";
import { getProjectID_Of_solution_module } from "../../../Services/Shared/Handlers";
import { hasCreatePermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { StartContext, projectSurveyInitialObj } from "../Start/Contexts/StartContext";

const UploadHome = (props) => {
  const { getCurrentUserPermissions } = useContext(DataContext);
  const currentUserPermissions = getCurrentUserPermissions();
  const { setProjectID, setProjectSurvey } = useContext(StartContext);
  var polyglot = polyglotLoader();
  const projectId = getProjectID_Of_solution_module();

  // RUNS ON INITIAL LAOD
  useEffect(() => {
    const projectSurvey = { ...projectSurveyInitialObj }; // projectSurvey will be replaced with API data
    projectSurvey.project_id = projectId;

    setProjectSurvey(projectSurvey);
  }, [projectId]);

  // START WORKFLOW
  const letsStart = () => {
    props.history.push({ pathname: "/df/start", state: { selectedProjectId: projectId } });
    setProjectID(projectId);
  };

  return (
    <div className="m-l-1-15">
      <div className="row m-0">
        <div className="col-md-3 DF-mt-3 p-0 m-b-18">
          {hasCreatePermission(currentUserPermissions) && (
            <ButtonPrimaryWithIcon
              onClick={letsStart}
              iconName={plusCircle}
              ariaLabel={polyglot.t("Add a workflow")}
            >
              {polyglot.t("Add a workflow")}
            </ButtonPrimaryWithIcon>
          )}
        </div>
      </div>
      <WorkflowList projectId={projectId} tabName={"Upload"} />
    </div>
  );
};

UploadHome.propTypes = {
  location: PropTypes.object,
};

export default UploadHome;
