import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";

class BulkUploadFileValidation {
  constructor() {
    this.polyglot = polyglotLoader();
  }

  transformToArrFromCsvFormat = (csvRows, type) => {
    let formattedArr;
    if (type === "keys") {
      formattedArr = csvRows
        .map((row) => Object.keys(row))
        .map((row) => {
          if (Number(row[0])) return row[0];
          return row[0].split("|").map((cell) => cell.replaceAll('"', "").trim());
        });
    }

    if (type === "values") {
      formattedArr = csvRows
        .map((row) => Object.values(row))
        .map((row) => {
          if (Number(row[0])) return row[0];
          return row[0].split("|").map((cell) => cell.replaceAll('"', "").trim());
        });
    }

    return formattedArr;
  };

  getTrimmedLowerCaseWord(value) {
    let formattedValue = value.toString().replace(/\s+/g, "").toLowerCase();

    if (formattedValue.includes("_") && formattedValue.split("_")[0] === "businesspartner") {
      formattedValue = formattedValue.split("_")[0];
    }

    return formattedValue;
  }

  headerCheck(fileRows) {
    try {
      let headerRowIndex;
      let headerColumnIndex;
      let headerObjKey;
      let headerErrMsg;

      const arrOfValues = fileRows.map((row) => Object.values(row));
      const arrOfKeys = fileRows.map((row) => Object.keys(row));

      const columns = [...new Set(arrOfKeys.flat(1))];

      //check if Business Partner header is in the first row of the file
      const columnIndexes = [];
      columns.map((column, columnIndex) => {
        const trimmedLowerCasedWord = this.getTrimmedLowerCaseWord(column);
        if (trimmedLowerCasedWord === "businesspartner") columnIndexes.push(columnIndex);
      });

      //check if Business Partner header exists in the file
      const arrOfColumnIndexes = arrOfValues
        .map((row, rowIndex) =>
          row.reduce((accumulator, currentValue, columnIndex) => {
            const trimmedLowerCasedWord = this.getTrimmedLowerCaseWord(currentValue);

            if (trimmedLowerCasedWord === "businesspartner") {
              accumulator.push(columnIndex);
              headerRowIndex = rowIndex;
            }

            return accumulator;
          }, []),
        )
        .filter((arr) => arr.length)
        .flat(1);

      if (
        arrOfColumnIndexes.length > 1 ||
        (arrOfColumnIndexes.length >= 1 && columnIndexes.length >= 1) ||
        columnIndexes.length > 1
      ) {
        headerErrMsg = "The file should contain only one 'Business Partner' header";
      } else if (!arrOfColumnIndexes.length && !columnIndexes.length) {
        headerErrMsg = "The 'Business Partner' header is required";
      }

      if (columnIndexes.length === 1 && !headerRowIndex) {
        headerColumnIndex = columnIndexes[0];
        headerRowIndex = 0;
        headerObjKey = columns[headerColumnIndex];
      } else {
        headerColumnIndex = arrOfColumnIndexes[0];
        headerObjKey = arrOfKeys[headerRowIndex][headerColumnIndex];
      }

      return { headerRowIndex, headerColumnIndex, headerErrMsg, headerObjKey };
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  }

  csvHeaderCheck(csvRows) {
    try {
      let headerErrMsg;
      let arrOfKeys = csvRows.map((row) => Object.keys(row));

      const csvColumns = [...new Set(...arrOfKeys)];

      if (!csvColumns[0].includes("|")) return { excelValidationType: true };

      const arrOfValues = this.transformToArrFromCsvFormat(csvRows, "values");
      arrOfKeys = this.transformToArrFromCsvFormat(csvRows, "keys");

      const columns = arrOfKeys[0];
      const columnIndexes = [];
      columns.map((column, columnIndex) => {
        const trimmedLowerCasedWord = this.getTrimmedLowerCaseWord(column);
        if (trimmedLowerCasedWord === "businesspartner") columnIndexes.push(columnIndex);
      });

      if (columnIndexes.length > 1) {
        headerErrMsg = "The file should contain only one 'Business Partner' header";
      } else if (!columnIndexes.length) {
        headerErrMsg = "The 'Business Partner' header is required";
      }

      const headerColumnIndex = columnIndexes[0];

      return { headerColumnIndex, headerErrMsg, arrOfValues };
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  }
}

export default BulkUploadFileValidation;
