import { RadioGroup, Radio } from "appkit-react";
import PropTypes from "prop-types";
import React from "react";

function RadioGroupCustom(props) {
  const { defaultValue, onChange, options, value } = props;
  return (
    <RadioGroup defaultValue={defaultValue} onChange={onChange} value={value}>
      {options.map((option) => {
        return (
          <Radio value={option.value} disabled={option.disabled} aria-label={option.name}>
            {option.name}
          </Radio>
        );
      })}
    </RadioGroup>
  );
}

RadioGroupCustom.propTypes = {
  defaultValue: PropTypes.string,
  options: PropTypes.array,
};
RadioGroupCustom.defaultValue = {
  defaultValue: "one",
  options: [
    { value: "one", name: "Option one", disabled: false },
    { value: "two", name: "Option two", disabled: false },
    { value: "three", name: "Option three", disabled: false },
  ],
};

export default RadioGroupCustom;
