//Next and Previous Button
import PropTypes from "prop-types";
import React from "react";

import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import { polyglotLoader } from "../../../Services/Shared/Translate";

// RENDERS THE PREVIOUS AND NEXT BUTTON ON PROJECT FORM
const PrevNextButton = (props) => {
  var polyglot = polyglotLoader();
  // DATA FROM PROPS
  const { currentPage, handlePrev, handleNext, addProject, isEdit, disableNext } = props;
  return (
    <>
      <div className="a-py-10 border-top a-border-light a-mt-10 project-divider"></div>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-4 m-auto">
            {currentPage === 1 ? (
              ""
            ) : (
              <div className="text-left">
                <ButtonSecondary onClick={handlePrev} ariaLabel={polyglot.t("Back")}>
                  {polyglot.t("Back")}
                </ButtonSecondary>
              </div>
            )}
          </div>
          <div className="col-md-4" id="projectaddscreen1">
            {/* Note: removed appkit's pagination dots */}
          </div>
          {isEdit ? (
            <div className="col-md-4 m-auto">
              {currentPage === 4 ? (
                <div className="text-right">
                  <ButtonPrimary
                    onClick={addProject}
                    disabled={disableNext}
                    ariaLabel={polyglot.t("Update project")}
                  >
                    {polyglot.t("Update project")}
                  </ButtonPrimary>
                </div>
              ) : (
                <div className="text-right">
                  <ButtonPrimary onClick={handleNext} disabled={disableNext} ariaLabel={polyglot.t("Next")}>
                    {polyglot.t("Next")}
                  </ButtonPrimary>
                </div>
              )}
            </div>
          ) : (
            <div className="col-md-4 m-auto">
              {currentPage === 4 ? (
                <div className="text-right">
                  <ButtonPrimary
                    onClick={addProject}
                    disabled={disableNext}
                    ariaLabel={polyglot.t("Create project")}
                  >
                    {polyglot.t("Create project")}
                  </ButtonPrimary>
                </div>
              ) : (
                <div className="text-right">
                  <ButtonPrimary onClick={handleNext} disabled={disableNext} ariaLabel={polyglot.t("Next")}>
                    {polyglot.t("Next")}
                  </ButtonPrimary>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};
PrevNextButton.propTypes = {
  history: PropTypes.object,
  currentPage: PropTypes.number,
  handlePrev: PropTypes.func,
  handleNext: PropTypes.any,
  addProject: PropTypes.func,
  isEdit: PropTypes.bool,
  disableNext: PropTypes.bool,
};
export default PrevNextButton;
