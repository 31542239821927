import { Select, SelectOption, SelectGroupTitle } from "appkit-react";
import React from "react";

import minusIcon from "../../../Assets/Icons/circle-minus-outline.svg";
import plusIcon from "../../../Assets/Icons/circle-plus-outline.svg";
import { CUSTOME_ANSWER_TYPE } from "../../../Constants/Constant";

export const AnswerType = ({
  values,
  setAnsType,
  handleChange,
  errors,
  decrementCount,
  incrementCount,
  customCount,
  polyglot,
  answerTypes,
  touched,
  disabled = false,
}) => {
  const isCustomeAnswerType = values.answertypeid === CUSTOME_ANSWER_TYPE;
  const AnswerTypeOption = ({ answerType }) => (
    <div className="display-contents answer-types-right">
      <div className="font-size-point8rem">
        <img
          src={minusIcon}
          width={24}
          height={24}
          onClick={(e) => decrementCount(e, answerType.config.split(",")[0])}
        />
        <span className="custom-count">{customCount}</span>
        <img
          src={plusIcon}
          width={24}
          height={24}
          onClick={(e) => incrementCount(e, answerType.config.split(",")[1])}
        />
      </div>
    </div>
  );

  return (
    <div className={isCustomeAnswerType ? "answer-types col-md-5" : "col-md-5"}>
      <div className={isCustomeAnswerType ? "answer-types-left" : ""}>
        <Select
          placeholder="Answer type *"
          disabled={disabled}
          onSelect={(value) => {
            setAnsType(value);
            handleChange("answertypeid")({ target: { value } });
          }}
          value={values.answertypeid}
        >
          <SelectGroupTitle>{polyglot.t("answer_type")}</SelectGroupTitle>
          {answerTypes.map(({ id, type }) => (
            <SelectOption key={id} value={id} selectedDisplay={type}>
              {type}
            </SelectOption>
          ))}
        </Select>
        {touched.answertypeid ? <span className="error">{errors.answertypeid}</span> : null}
      </div>
      {isCustomeAnswerType && (
        <AnswerTypeOption answerType={answerTypes.find((el) => el.id === CUSTOME_ANSWER_TYPE)} />
      )}
    </div>
  );
};
