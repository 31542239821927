import axios from "axios";
import HttpClient from "../Shared/HttpClient";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_HOST;
// axios.defaults.headers.common['Authorization'] = "JWT_TOKEN";  // Yet to complete
axios.defaults.headers.post["Content-Type"] = "application/json";

export default class SupportService {
  SupportedService = async (moduleName) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getSupportTypes/${moduleName}`);
    return res;
  };
  FAQService = async (language) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getFAQquestions?language=${language}`);
    return res;
  };

  insertSupportNotes = async (data, solutionName) => {
    const client = new HttpClient();
    const res = await client.dfedPost("/insertSupportNotes", {
      body: {
        supportData: data,
        solutionName: solutionName,
      },
    });
    return res;
  };
}
