import HttpClient from "../Shared/HttpClient";

class SurveyService {
  constructor() {
    this.client = new HttpClient();
  }

  // API TO FETCH SURVEY DETAILS
  async getSurveyDataRecords(language) {
    const result = await this.client.dfget(`/getSurveyDataRecords?language=${language}`);
    return result;
  }
}

// Enable Singleton behavior on this class
SurveyService.__instance = null;
SurveyService.get = () => {
  if (!SurveyService.__instance) {
    SurveyService.__instance = new SurveyService();
  }
  return SurveyService.__instance;
};

export default SurveyService;
