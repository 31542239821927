import React, { memo } from "react";

import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import { GetBreadcrumHTMLByString } from "../handlers";

function HeaderSection({ cond, categoryPath, fromMode }) {
  const polyglot = polyglotLoader();

  if (cond === 1) {
    return (
      <>
        <div className="row">
          <div className="col-md-12 p-0">
            <h5 className="headertest p-l-1rem ml-3 mt-2">
              {fromMode === "add" ? polyglot.t("create_category") : polyglot.t("edit_category")}
            </h5>
            <div className="a-py-10 border-top a-border-light a-mt-10 category-divider"></div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-12">
            <div className="category-breadcrumb-box input-field-height">
              <GetBreadcrumHTMLByString breadcrumString={categoryPath} />
            </div>

            <br />
          </div>
        </div>
      </>
    );
  } else if (cond === 2) {
    return (
      <>
        <div className="row">
          <div className="col-md-12 p-0">
            <h5 className="headertest p-l-1rem ml-3 mt-2">
              {fromMode === "add" ? polyglot.t("create_sub_category") : polyglot.t("edit_sub_category")}
            </h5>
            <div className="a-py-10 border-top a-border-light a-mt-10 category-divider"></div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-12">
            <div className="category-breadcrumb-box input-field-height">
              <GetBreadcrumHTMLByString breadcrumString={categoryPath} />
            </div>

            <br />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="row">
          <div className="col-md-12 p-0">
            <h5 className="headertest p-l-1rem ml-3 mt-2">
              {fromMode === "add" ? polyglot.t("create_questionaries") : polyglot.t("edit_questionaries")}
            </h5>
            <div className="border-top a-border-light mx-2 my-1"></div>
          </div>
        </div>
        <div className="row mt-1">
          <div className="col-md-12"></div>
        </div>
      </>
    );
  }
}

export default memo(HeaderSection);
