//Admin Screens to see the list of clients and Button to Create NewClient
import "../../../Assets/Css/QueenBase/Admin/Admin.css";
import { Pagination } from "antd";
import { Checkbox, Button, Tooltip } from "appkit-react";
import PropTypes from "prop-types";
import React, { useState, useContext, useEffect } from "react";

import ClientCreation from "./ClientCreation";
import ClientList from "./ClientList";
import MessageOfTheDayForm from "./MessageOfTheDayForm";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import CustomModal from "../../../Assets/Components/Modal";
import { PER_PAGE_COUNT } from "../../../Constants/Constant";
import * as path from "../../../Constants/Path";

import AdminService from "../../../Services/QueenBase/Admin/AdminServices";
import UserService from "../../../Services/QueenBase/Users/UserService";
import { hasCreatePermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { plusCircle, backCircle } from "../../../Assets/Icons";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";
import displayToastMessage from "../Root/displayToastMessage";
// ADMIN COMPONENT
function Admin(props) {
  // DATA FROM CONTEXT
  const { getCurrentUserPermissions, getUserLanguage, formGotClosed, isFormOpen } = useContext(DataContext);
  const polyglot = polyglotLoader(getUserLanguage());
  const currentUserPermissions = getCurrentUserPermissions();
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isClientFormEmpty, setIsClientFormEmpty] = useState(true);
  const [showMODForm, setShowMODForm] = useState(false);
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const [dummy, setDummy] = useState("");
  const [isTechnicalClient, setIsTechnicalClient] = useState(false);
  const [selectExisitingAdminModel, setSelectExisitingAdminModel] = useState(false);
  const [searchStr, setSearchStr] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [selectExisitingAdmin, setSelectExisitingAdmin] = useState([]);
  const [checkedAdminIds, setCheckedAdminIds] = useState([]);
  const [pageList, setPageList] = useState([]);
  const [roles, setRoles] = useState([]);
  const [listOfUsersOfClient, setListOfUsersOfClient] = useState([]);
  const [clientID, setClientID] = useState(null);
  const [disableCreateClient, setDisableCreateClient] = useState(false);
  // TOGGLE CREATE CLIENT FORM
  const closeShow = () => {
    setShow((prev) => !prev);
  };
  // TOGGLE CONFIRM BOX BEFORE CLOSING CLIENT CREATION FORM
  const openModal = () => {
    if (isClientFormEmpty) {
      BackToAdmin();
    } else {
      setShowModal(true);
    }
  };

  useEffect(() => {
    getNumberOfAvailableSDs()
    checkIsTechnicalClient();
    getRoles();
    window.scrollTo(0, 0);
  }, []);

  const checkIsTechnicalClient = async () => {
    const res = await UserService.get().getUserProfile();
    if (res.CODE) {
      const { clientid, isTechnicalClient } = res.userData;
      setIsTechnicalClient(isTechnicalClient);
      setClientID(clientid);
      !isTechnicalClient && getAdminsList();
    } else {
      displayToastMessage(res.MSG, "error");
    }
  };

  const getNumberOfAvailableSDs = async () => {
    const {numberOfAvailableSDs} = await AdminService.get().getNumberOfAvailableSDs();

    if (!numberOfAvailableSDs) {
      setDisableCreateClient(true)
    }
  }
  useEffect(() => {
    setSelectExisitingAdmin((prevSelectedExistingAdmin) => {
      const checkedUsers = prevSelectedExistingAdmin.filter((u) => checkedAdminIds.includes(u.userid));
      const restOfUsers = prevSelectedExistingAdmin.filter((u) => !checkedAdminIds.includes(u.userid));
      // PUT CHACKED USERS ON TOP OF THE LIST
      return [...checkedUsers, ...restOfUsers]
    })
  }, [checkedAdminIds.length]);

  const fetchClientDetailsById = async (clientid) => {
    try {
      const apiResponse = await AdminService.get().fetchClientDetailsById(clientid);
      if (apiResponse.CODE) {
        setListOfUsersOfClient(apiResponse.usersList);
        return "success";
      }
    } catch (err) {
      displayToastMessage(err.MSG, "error");
    }
  };

  const acivateUser = async (clientObj, userObj) => {
    const apiBody = {
      clientId: clientObj.clientid,
      clientUserMappingID: userObj.clientUserMappingID,
      userId: userObj.ClientUserID,
      email: userObj.email,
    };
    try {
      // API TO ACTIVATE IDAM ON A USER BY PASSING AN OBJECT
      const apiResponse = await AdminService.get().activateIDAMonUser(apiBody);
      if (apiResponse.CODE) {
        setListOfUsersOfClient((prevList) =>
          prevList.map((client) =>
            client.ClientUserID === userObj.ClientUserID ? { ...client, IDAMID: apiResponse.IDAMID } : client,
          ),
        );
        displayToastMessage(apiResponse.MSG, "success");
      }
    } catch (err) {
      displayToastMessage(err.MSG, "error");
    }
  };

  // BACK TO ADMIN
  const BackToAdmin = () => {
    setShow(false);
    setShowModal(false);
    setIsClientFormEmpty(true);
    formGotClosed();
  };
  const isFormEmpty = (values) => {
    let formIsEmpty = [];
    Object.values(values).forEach((value) => {
      if (value === "" || !value) {
        formIsEmpty.push(true);
      } else {
        formIsEmpty.push(false);
      }
    });
    if (formIsEmpty.includes(false)) {
      setIsClientFormEmpty(false);
    }
  };

  // CLOSE FORM AND NAVIGATE TO VIEW PROJECTS
  const closeFormAndRedirect = () => {
    toggleConfirmBox();
    formGotClosed();
    //redirectToInvoice();
    if (dummy === 2) {
      redirectToInvoice();
    } else if (dummy === 3) {
      toggleMessageOfTheDayForm();
    } else if (dummy === 1) {
      openModal();
    }
  };

  // REDIRECT TO INVOICE
  const redirectToInvoice = () => {
    props.history.push(path.VIEW_ADMIN_INVOICE);
  };

  const toggleConfirmBox = () => {
    setOpenConfirmBox(!openConfirmBox);
  };

  // toggle message of the day form
  const toggleMessageOfTheDayForm = () => {
    setShowMODForm((prev) => !prev);
  };

  const checkFormStatusAndRedirect = () => {
    setDummy(2);
    if (isFormOpen) {
      // form is in open state, hence user cannot navigate to other screens
      toggleConfirmBox();
    } else {
      redirectToInvoice();
    }
  };
  const checkFormStatusOpen = () => {
    setDummy(3);
    if (isFormOpen) {
      // form is in open state, hence user cannot navigate to other screens
      toggleConfirmBox();
    } else {
      toggleMessageOfTheDayForm();
    }
  };
  const checkOp = () => {
    setDummy(1);
    if (!show) {
      if (isFormOpen) {
        toggleConfirmBox();
      } else {
        closeShow();
      }
    } else {
      openModal();
    }
  };

  const getRoles = async () => {
    const res = await AdminService.get().GetRoles();
    setRoles(res.data);
  };

  const getRoleName = (id) => {
    const role = roles.filter((r) => r.id === id);
    return role[0].rolename;
  };

  const getAdminsList = async () => {
    const res = await UserService.get().getListOfAvailableAdmins();

    if (res.CODE) {
      setSelectExisitingAdmin(res.userList);
      setPageList(res.userList);
    } else {
      displayToastMessage(res.MSG, "error");
    }
  };

  const handleAdminCheck = (adminid) => {
    if (checkedAdminIds.indexOf(adminid) === -1) {
      setCheckedAdminIds([...checkedAdminIds, adminid]);
    } else {
      setCheckedAdminIds(checkedAdminIds.filter((r) => r !== adminid));
    }
  };

  const showExisitingModel = () => {
    setSelectExisitingAdminModel((prev) => !prev);
  };

  const showExisitingCloseModel = () => {
    setSelectExisitingAdminModel((prev) => !prev);
    setCheckedAdminIds([]);
    setSearchStr("");
    setCurrentPage(1);
  };

  const selectAdmin = async () => {
    const checkedAdmins = selectExisitingAdmin.filter((u) => checkedAdminIds.includes(u.userid));
    const obj = { admins: checkedAdmins };
    const res = await UserService.get().addClientAdmins(obj);

    if (res.CODE) {
      displayToastMessage(polyglot.t("Client Admins were added successfully"), "success");
    } else {
      displayToastMessage(res.MSG, "error");
    }

    showExisitingCloseModel();
    getAdminsList();
    fetchClientDetailsById(clientID);
  };

  const onSearch = (e) => {
    setSearchStr(e.target.value);
    setCurrentPage(1);
  };

  const displaySearchBox = () => {
    return (
      <div className="mb-4">
        <div className="col-md-6 p-0 search-result">
          <div className="row m-l-0 m-r-0 filter-search-qb">
            <div className="searchIcon oval-border">
              <input
                value={searchStr}
                className="oval-search-bar search-input"
                prefix={
                  <span
                    className="appkiticon icon-search-outline  baseline-search-icon "
                    style={{ fontSize: 16 }}
                  />
                }
                placeholder={polyglot.t("Search Admins")}
                onChange={onSearch}
                aria-label={polyglot.t("Search Admins")}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  const handlePageChange = (change) => {
    setCurrentPage(change);
  };

  const searchKeys = (listObj) => {
    const keys = ["firstname", "lastname", "email", "department", "positionname"];
    const keyValues = keys.map((key) => listObj[key]);
    return keyValues.toString().toLowerCase();
  };

  const filterAdmins = (selectExisitingAdmin) => {
    let filteredAdmins = [...selectExisitingAdmin];
    if (searchStr !== "") {
      filteredAdmins = selectExisitingAdmin.filter((r) => searchKeys(r).includes(searchStr.toLowerCase()));
    }
    return filteredAdmins;
  };

  const getCurrentPageAdmins = (filteredAdmins) => {
    return filteredAdmins.slice((currentPage - 1) * PER_PAGE_COUNT, currentPage * PER_PAGE_COUNT);
  };

  const ExisitingAdminModel = () => {
    return (
      <div>
        <CustomModal
          show={selectExisitingAdminModel}
          onCancel={showExisitingCloseModel}
          title={polyglot.t("Select existing Admins")}
          Footer={[
            <ButtonPrimary onClick={() => selectAdmin()} ariaLabel={polyglot.t("Select Admins")}>
              {polyglot.t("Select Admins")}
            </ButtonPrimary>,
          ]}
          size="lg"
        >
          <>
            {displaySearchBox()}
            <div>
              <table className="table">
                <tr>
                  <th></th>
                  <th>
                    <b>{polyglot.t("First name")}</b>
                  </th>
                  <th>
                    <b>{polyglot.t("Last name")}</b>
                  </th>
                  <th>
                    <b>{polyglot.t("Email")}</b>
                  </th>
                  <th>
                    <b>{polyglot.t("Organisation_noStar")}</b>
                  </th>
                  <th>
                    <b>{polyglot.t("Department_noStar")}</b>
                  </th>
                  <th>
                    <b>{polyglot.t("Position_noStar")}</b>
                  </th>
                  <th>
                    <b>{polyglot.t("Role")}</b>
                  </th>
                </tr>
                <tbody>
                  {selectExisitingAdmin.length > 0 ? (
                    getCurrentPageAdmins(filterAdmins(selectExisitingAdmin))
                      .map((list, item) => {
                        return (
                          <tr key={item}>
                            <td className="txt-name-check">
                              <Checkbox
                                checked={checkedAdminIds.indexOf(list.userid) !== -1}
                                onChange={() => handleAdminCheck(list.userid)}
                                aria-label={polyglot.t("choose admin")}
                              />
                            </td>
                            <td className="txt-name-cell">{list.firstname}</td>
                            <td className="txt-name-cell">{list.lastname}</td>
                            <td className="txt-name-cell">{list.email}</td>
                            <td className="txt-name-cell">{list.entity}</td>
                            <td className="txt-name-cell">{list.department}</td>
                            <td className="txt-name-cell">{list.positionname}</td>
                            <td className="txt-name-cell">{getRoleName(list.RoleID)}</td>
                          </tr>
                        );
                      })
                  ) : (
                    <tr>
                      <td className="no-user-td" colSpan="12">
                        No Users
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
            <div className="border-color-active">
              <Pagination
                current={currentPage}
                size="small"
                pageSize={PER_PAGE_COUNT}
                total={filterAdmins(pageList).length}
                onChange={handlePageChange}
              />
            </div>
          </>
        </CustomModal>
      </div>
    );
  };

  return (
    <div className="">
      {
        <CustomModal
          show={showModal}
          onCancel={() => setShowModal(false)}
          title={polyglot.t("Cancel create client")}
          Footer={[
            <Button size="lg" className="primary_button" onClick={BackToAdmin} key={"button_yes"}>
              {polyglot.t("Yes")}
            </Button>,
          ]}
        >
          <>{polyglot.t("Any unsaved changes will be lost, will you like to proceed ?")}</>
        </CustomModal>
      }
      <div className=" mt-3">
        <div className="row col-lg-10 m-l-1 m-b-18 pl-0">
          {hasCreatePermission(currentUserPermissions) && (
            <div className="col-md-3 col-lg-3 pl-0 ">
              {selectExisitingAdmin.length ? (
                <ButtonPrimaryWithIcon
                  iconName={plusCircle}
                  onClick={showExisitingModel}
                  alt={"add"}
                  ariaLabel={polyglot.t("Add Client Admin")}
                >
                  {polyglot.t("Add Client Admin")}
                </ButtonPrimaryWithIcon>
              ) : (
                isTechnicalClient && (
                  <Tooltip content={<div>{polyglot.t("sharedDrives.noAvailable")}</div>} placement="top" className={disableCreateClient ? "" : "hide"}>
                    <ButtonPrimaryWithIcon
                      iconName={show ? backCircle : plusCircle}
                      onClick={checkOp}
                      alt={show ? "back" : "add"}
                      ariaLabel={show ? polyglot.t("Back to admin") : polyglot.t("Add Client")}
                      disabled={disableCreateClient}
                    >
                      {show ? polyglot.t("Back to admin") : polyglot.t("Add Client")}
                    </ButtonPrimaryWithIcon>
                  </Tooltip>
                )
              )}
            </div>
          )}
          <div className="col-md-3 col-lg-3 pl-0 addInvoice">
            {!show && (
              <ButtonSecondary
                onClick={checkFormStatusAndRedirect}
                ariaLabel={polyglot.t("Go to Invoice")}
                disabled={isTechnicalClient}
              >
                {polyglot.t("Go to Invoice")}
              </ButtonSecondary>
            )}
          </div>
          <div className="col-md-3 col-lg-3 pl-0">
            {!show && (
              <ButtonSecondary
                onClick={checkFormStatusOpen}
                ariaLabel={polyglot.t("Add message of the day")}
                className="button-expandable"
              >
                {polyglot.t("Add message of the day")}
              </ButtonSecondary>
            )}
          </div>
        </div>

        {show ? (
          <ClientCreation checkOp={openModal} history={props.history} isFormEmpty={isFormEmpty} />
        ) : (
          <ClientList
            listOfUsersOfClient={listOfUsersOfClient}
            fetchClientDetailsById={fetchClientDetailsById}
            acivateUser={acivateUser}
          />
        )}

        {/* show message of the day form */}
        <MessageOfTheDayForm
          showMODForm={showMODForm}
          toggleMessageOfTheDayForm={toggleMessageOfTheDayForm}
        />
      </div>
      <ConfirmBoxExitForm
        show={openConfirmBox}
        heading={"Cancel update"}
        cancel={toggleConfirmBox}
        proceed={closeFormAndRedirect}
      />
      {ExisitingAdminModel()}
    </div>
  );
}
Admin.propTypes = {
  history: PropTypes.object,
};
export default Admin;
