import "../../../Assets/Css/DF/Solutions/Solution.css";
import "../../DF/Start/ChooseSurvey.css";
import React, { useContext } from "react";
import { useHistory } from "react-router-dom";

import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";
import { plusCircle } from "../../../Assets/Icons";
import { getProjectID_Of_solution_module } from "../../../Services/Shared/Handlers";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import BulkUploadComponents from "../Home/BulkUpload";
import CssComponent from "../Home/CssComponent";
import "../../../Assets/Css/CSS/Search.css";

import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
// CHOOSE A SURVEY ON STEP 1 OF WORKFLOW FORM
const ChooseSurvey = (props) => {
  const history = useHistory();
  const [showSingleSearchButton, setshowSingleSearchButton] = React.useState(false);
  const [showBulkUploadButton, setshowBulkUploadButton] = React.useState(true);
  const [showSingleComponent, setShowSingleComponent] = React.useState(true);
  const [showBulkUpload, setShowBulkUpload] = React.useState(false);
  const [openConfirmBox, setOpenConfirmBox] = React.useState(false);
  const { isFormOpen, formGotClosed } = useContext(DataContext);

  // BULK UPLOAD
  const onBulkUploadClick = () => {
    setShowSingleComponent(false);
    setShowBulkUpload(true);
    setshowBulkUploadButton(false);
    setshowSingleSearchButton(true);
  };
  // SINGLE SEARCH
  const onSingleSearchClick = () => {
    setShowSingleComponent(true);
    setShowBulkUpload(false);
    setshowBulkUploadButton(true);
    setshowSingleSearchButton(false);
  };

  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  const toggleConfirmBox = () => {
    setOpenConfirmBox(!openConfirmBox);
  };

  // CLOSE FORM AND NAVIGATE TO VIEW PROJECTS
  const closeFormAndRedirect = () => {
    toggleConfirmBox();
    formGotClosed();
    onSingleSearchClick();
  };

  // START CREATING A WORKFLOW
  const onClick = () => {
    props.history.push({ pathname: "/css/history" });
  };
  var polyglot = polyglotLoader();

  const checkAndGoToSingleSearch = () => {
    try {
      if (!isFormOpen) {
        onSingleSearchClick();
      } else {
        toggleConfirmBox();
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  return (
    <div>
      <div className="row mt-3">
        {showSingleSearchButton && (
          <div className="col-md-8 d-flex ">
            <ButtonPrimaryWithIcon
              onClick={checkAndGoToSingleSearch}
              iconName={plusCircle}
              ariaLabel={polyglot.t("Single search")}
            >
              {polyglot.t("Single search")}
            </ButtonPrimaryWithIcon>
          </div>
        )}
      </div>
      {showSingleComponent && (
        <CssComponent
          selectedProjectId={getProjectID_Of_solution_module()}
          onBulkUploadClick={onBulkUploadClick}
        />
      )}
      {showBulkUpload && (
        <div className="">
          <div className="col-md-2"></div>
          <div className="col-md-12">
            <BulkUploadComponents history={history} />
          </div>
        </div>
      )}
      <ConfirmBoxExitForm
        show={openConfirmBox}
        heading={"Cancel update"}
        cancel={toggleConfirmBox}
        proceed={closeFormAndRedirect}
      />
    </div>
  );
};

export default ChooseSurvey;
