export const HOME_MODULE = "Home";
export const CUSTODIAN_MODULE = "Custodian";
export const PROCESSING_MODULE = "Processing";
export const KEYWORD_MODULE = "Keyword";
export const PROFILE_MODULE = "Profile";
export const ANALYSIS_MODULE = "Analysis";
export const REVIEW_MODULE = "Review";
export const PRODUCTION_MODULE = "Production";
export const FAST_FORWARD_MODULE = "Fast_Forward";
export const ADMIN_PROCESSING_MODULE = "Processing_Admin";
export const ADMIN_ANALYSIS_MODULE = "Analysis_Admin";
export const ADMIN_REVIEW_MODULE = "Review_Admin";
export const ADMIN_PRODUCTION_MODULE = "Production_Admin";

//====================== SOLUTION NAME STATRT FORM HERE===================
export const DFED_SOLUTION = "Connected eDiscovery";
export const DF_SOLUTION = "DF";
export const BASE_SOLUTION = "BASE";
