/* eslint-disable no-mixed-spaces-and-tabs */
import "../../../Assets/Css/DF/RequestParameterFile.css";
import { DatePicker } from "antd";
import { Panel } from "appkit-react";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";

import { StartContext } from "./Contexts/StartContext";
import RequestParameterForm from "./RequestParameterForm";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import AlertTriangle from "../../../Assets/Images/DF/icon-alert-triangle.svg";
import ProjectSurveyService from "../../../Services/DF/ProjectSurveyService";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import Xtractor from "../shared/Xtractor";

// FILL THE FORM ON STEP 3 OF WORKFLOW FORM
const RequestParameterFile = (props) => {
  const { next, goBack, nextPage } = props;
  const { formGotClosed } = useContext(DataContext);
  const { projectSurvey, project_id, resetProjectSurvey, DFCurrentPackages } = useContext(StartContext);
  const [isHideParameters, setHideParameters] = useState(false);
  const [isDownloadReady, setIsDownloadReady] = useState(false);
  const [disableSubmit, setDisableSubmit] = useState(false);
  var polyglot = polyglotLoader();
  // REQUEST PARAMETER FILE
  const requestParamFile = async () => {
    setDisableSubmit(true);
    const alreadySelectedDFPackages = [];
    let packageObj = {};
    DFCurrentPackages.forEach((currentPackage) => {
      packageObj = {};
      if (currentPackage.isSelected === 1) {
        packageObj.PlanID = currentPackage.PlanID;
        alreadySelectedDFPackages.push(packageObj);
      }
    });
    try {
      // API TO CREATE A WORKFLOW BY SUBMITING FORM OBJECT
      const APIresponse = await ProjectSurveyService.get().createProjectSurvey({
        projectSurvey,
        alreadySelectedDFPackages,
      });
      if (APIresponse.CODE) {
        setHideParameters(true);
        displayToastMessage(polyglot.t(APIresponse.MSG), "success");
        resetProjectSurvey();
        formGotClosed(); // update the store saying that the form got closed
      } else {
        displayToastMessage(APIresponse.MSG, "error");
        setDisableSubmit(false);
      }
    } catch (error) {
      displayToastMessage(error.message, "error");
    }
  };

  // NAVIGATE TO DF HOME
  const navigateToHome = () => {
    props.history.push(`/df/home`);
  };

  // DISPLAY WORKFLOW FORM
  const displayParameterFields = () => {
    const { RangePicker } = DatePicker;
    if (!isHideParameters) {
      return (
        <RequestParameterForm
          requestParamFile={requestParamFile}
          goBack={goBack}
          project_id={project_id}
          disableSubmit={disableSubmit}
        />
      );
    }
  };

  // DISPALY BACK TO HOME CARD
  const displayBackToHome = () => {
    if (isHideParameters) {
      return (
        <div>
          <div className="row">
            <div className="col-md-1">
              <div className="exclamation-img-center-req-parameterFile">
                <img width="30px" src={AlertTriangle} alt="Alert-Message" />
              </div>
            </div>
            <div className="col-md-11">
              {polyglot.t(
                "Once the parameter file has been uploaded by the Admin, you would be allowed to proceed further with the workflow",
              )}
              .
            </div>
          </div>
          <div className="col-md-3 mt-4 pl-0">
            <ButtonPrimary onClick={navigateToHome} ariaLabel={polyglot.t("Back to Home")}>
              {polyglot.t("Back to Home")}
            </ButtonPrimary>
          </div>
        </div>
      );
    }
  };

  // DISPLAY DISCRIPTION
  const displayDiscription = () => {
    if (isHideParameters) {
      return polyglot.t(
        "Your parameter file will now be generated with your entered data. As soon as the file is ready for download, we will contact you by email.",
      );
    }
    if (isDownloadReady) {
      return polyglot.t(
        "Download your parameter file to implement it in the Xtractor. Xtractor then downloads your relevant files as a ZIP file, which you have to upload in the next step.",
      );
    }
    return polyglot.t(
      "The parameter file is needed so that our Xtractor knows exactly what to download from your system. Before requesting the file, you’ll need to enter the time period and company codes in question.",
    );
  };

  return (
    <div className="wrkflowdrpdwn">
      <div className="row mt-5">
        <div className="col-md-8 mb-2">
          <Panel className="p-4">
            <h1 className="subtitle_title_qb m-b-1">{polyglot.t("Request parameter file")}</h1>
            <div className="f-acc-14 m-b-3">{displayDiscription()}</div>
            {displayParameterFields()}
            {displayBackToHome()}
          </Panel>
        </div>
        <div className="col-md-4">
          <Xtractor />
        </div>
      </div>
    </div>
  );
};

RequestParameterFile.propTypes = {
  history: PropTypes.object,
  next: PropTypes.any,
  nextPage: PropTypes.any,
};

export default withRouter(RequestParameterFile);
