import React from "react";
import { Switch } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import ReviewSolutionContainer from "../Components/QueenBase/Solutions/ReviewSolutionContainer";
import SolutionHomeContainer from "../Components/QueenBase/Solutions/SolutionHomeContainer";
import * as path from "../Constants/Path";
// ROUTES OF DF SOLUTION SCREENS
function SolutionRoute() {
  return (
    <div>
      <Switch>
        <ProtectedRoute path={path.SOLUTION_LIST_PATH} component={SolutionHomeContainer} />
        <ProtectedRoute path={path.SOLUTION_REVIEW_PATH} component={ReviewSolutionContainer} />
      </Switch>
    </div>
  );
}

export default SolutionRoute;
