import { Input } from "appkit-react";
import React, { useState } from "react";

import "./index.css";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";

const ShareQuestionTree = ({ data = [], handleChanged }) => {
  const polyglot = polyglotLoader();
  return (
    <div className="container p-0 m-0">
      {data.map((tree, i) => (
        <TreeNode node={tree} handleChanged={handleChanged} key={i} />
      ))}
    </div>
  );
};
const TreeNode = ({ node, handleChanged }) => {
  const [childVisible, setChildVisiblity] = useState(true);

  const CurrentHandler = () => {
    setChildVisiblity((v) => !v);
  };
  const hasChild = node.children ? true : false;
  const loadIcon = () => {
    if (node.depth == 2) {
      return (
        <span
          className="appkiticon icon-news-outline  custom-grc-icon"
          onClick={(e) => setChildVisiblity((v) => !v)}
        />
      );
    } else {
      return (
        <span
          className="appkiticon icon-folder-closed-outline custom-grc-icon"
          onClick={(e) => setChildVisiblity((v) => !v)}
        />
      );
    }
  };

  return (
    <>
      <div key={node.value} className="row mt-1 row-custome">
        <div className={`col-md-12 p-0 ${childVisible ? "selected-node" : ""}`}>
          {node.depth === 2 ? (
            <>
              <div
                key={node.value}
                className={`row pl-2 border-bottom border-dark font-weight-bold row-custome ${
                  childVisible ? "selected-node" : ""
                }`}
              >
                <div className="col-md-1"> {loadIcon()}</div>
                <div className="col-md-2  questionaire_text">
                  {node.label}({node.question_count})
                </div>
                <div className="col-md-4">
                  <input
                    id={`${node.value}_${node.depth}`}
                    className={`form-control getcategoriessh subquessh-${node.value}`}
                    inputboxsize="md"
                    placeholder="mail id"
                    disabled
                    onChange={(e) => handleChanged(node, e, "chield")}
                    type="mail"
                  ></input>
                </div>
                <div className="col-md-4">
                  <Input inputboxsize="md" disabled placeholder="comment" />
                </div>
                <div className="col-md-1"></div>
              </div>
            </>
          ) : (
            <>
              <div
                key={node.value}
                className={`row pl-2 border-bottom border-dark font-weight-bold row-custome ${
                  childVisible ? "selected-node" : ""
                }`}
              >
                <div className="col-md-1"> {loadIcon()}</div>
                <div className="col-md-2 questionaire_text">{node.label}</div>
                <div className="col-md-4">
                  <input
                    id={`${node.value}_${node.depth}`}
                    inputboxsize="md"
                    placeholder="mail id"
                    disabled
                    onChange={(e) => handleChanged(node, e, "chield")}
                    type="mail"
                    className={`form-control getcategoriessh subquessh-${node.value}`}
                    autoComplete="off"
                  />
                </div>
                <div className="col-md-4">
                  <Input inputboxsize="md" disabled placeholder="comment" />
                </div>
                <div className="col-md-1">
                  {/* <ExpandMoreIcon
                    className="d-inline"
                    onClick={(e) => setChildVisiblity((v) => !v)}
                  /> */}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {hasChild && childVisible && (
        <div className="row mt-1 div_p_l row-custome">
          <ShareQuestionTree data={node.children} handleChanged={handleChanged} />
        </div>
      )}
    </>
  );
};
export default ShareQuestionTree;
