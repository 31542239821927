import React from "react";

import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import { polyglotLoader } from "../../../Services/Shared/Translate";

export const ViewClientDetailsUser = ({ userItem, activate }) => {
  const polyglot = polyglotLoader();

  const handleClick = () => {
    activate(userItem);
  };

  return (
    <div className="row mb-1">
      <div
        className="col-md-3 project-title-overflow margin-point5em"
        title={`${userItem.firstname} ${userItem.lastname}`}
      >
        {userItem.firstname} {userItem.lastname}
      </div>
      <div className="col-md-3 project-title-overflow margin-point5em" title={userItem.email}>
        {userItem.email}
      </div>
      <div className="col-md-3 text-align-center margin-point5em">
        {userItem.IDAMID ? polyglot.t("Active") : polyglot.t("Inactive")}
      </div>
      <div className="col-md-3">
        {userItem.IDAMID ? (
          <ButtonSecondary ariaLabel={polyglot.t("Activated")} disabled>
            {polyglot.t("Activated")}
          </ButtonSecondary>
        ) : (
          <ButtonSecondary onClick={handleClick} ariaLabel={polyglot.t("Click to activate")}>
            {polyglot.t("Click to activate")}
          </ButtonSecondary>
        )}
      </div>
    </div>
  );
};
