import { Input } from "appkit-react";
import React, { useEffect, useState } from "react";
import CustodianService from "../../../../Services/DFED/CustodianService";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";
import FormSingleSelectAutoComplete from "../../Shared/components/FormSingleSelectAutoComplete";
import FormSingleSelectWithDescription from "../../Shared/components/FormSingleSelectWithDescription";

const nonRequiredKeys = ["Country", "General Notes"];

export const GroupCustodianRow = ({
  initialCustodian,
  custodian,
  setCustodian,
  selectedProjectId,
  countries,
  classifications,
}) => {
  const polyglot = polyglotLoader();
  const [errors, setErrors] = useState({
    ["Name"]: initialCustodian["Name"] ? null : polyglot.t("required_field"),
    ["Entity"]: initialCustodian["Entity"] ? null : polyglot.t("required_field"),
    ["Alias"]: initialCustodian["Alias"] ? null : polyglot.t("required_field"),
    ["Department"]: initialCustodian["Department"] ? null : polyglot.t("required_field"),
    ["Alias"]: initialCustodian["Alias"] ? null : polyglot.t("required_field"),
    ["Classification"]: initialCustodian["Classification"] ? null : polyglot.t("required_field"),
  });
  const [isAliasUnique, setIsAliasUnique] = useState(true);

  const handleChange = (value, key) => {
    if (value) {
      setCustodian({ ...custodian, [key]: value });
      setErrors({ ...errors, [key]: null });
    } else {
      setCustodian({ ...custodian, [key]: value });
      setErrors({ ...errors, [key]: nonRequiredKeys.includes(key) ? null : polyglot.t("required_field") });
    }

    if (key === "Alias") {
      void checkIsAliasUnique(value);
    }
  };

  const checkIsAliasUnique = async (currentAlias) => {
    const custodianService = new CustodianService();

    const allCustodians = await custodianService.getCustodianData(selectedProjectId);
    let custodiansList = allCustodians.data;

    setCustodian({ ...custodian, ["Alias"]: currentAlias, isAliasUnique: !custodiansList.some(({ CRT_ALIAS: custodianAlias }) => custodianAlias === currentAlias) });

    setIsAliasUnique(
      !custodiansList.some(({ CRT_ALIAS: custodianAlias }) => custodianAlias === currentAlias),
    );
  };

  useEffect(() => {
    if (selectedProjectId) checkIsAliasUnique(custodian["Alias"]);
    Object.keys(custodian).map((key) => {
      if (!custodian[key])
        setErrors({ ...errors, [key]: nonRequiredKeys.includes(key) ? null : polyglot.t("required_field") });
    });
  }, [selectedProjectId]);

  return (
    <tr>
      <td>
        <Input value={custodian["Name"]} onChange={(e) => handleChange(e, "Name")} />
        {errors["Name"] ? (
          <p className="txt-field-error m-0 " aria-label={errors["Name"]}>
            {errors["Name"]}
          </p>
        ) : null}
      </td>
      <td>
        <Input value={custodian["Alias"]} onChange={(e) => handleChange(e, "Alias")} />
        {!isAliasUnique ? (
          <p className="txt-field-error m-0 " aria-label={polyglot.t("unique_alias")}>
            {polyglot.t("unique_alias")}
          </p>
        ) : null}
        {isAliasUnique && errors["Alias"] ? (
          <p className="txt-field-error m-0 " aria-label={polyglot.t("Alias")}>
            {errors["Alias"]}
          </p>
        ) : null}
      </td>
      <td>
        {countries.length > 0 && (
          <FormSingleSelectAutoComplete
            fillKey="countryData"
            preFillSelectWithDescription={{
              countryData: [
                countries.find((el) => el.label === custodian["Country"])
                  ? countries.find((el) => el.label === custodian["Country"])
                  : custodian["Country"],
              ],
            }}
            name="CRT_COUNTRY_ID"
            options={countries}
            onChangeFormField={(_, value) => handleChange(value, "Country")}
            isClearable={false}
          />
        )}
      </td>
      <td>
        <Input value={custodian["Entity"]} onChange={(e) => handleChange(e, "Entity")} />
        {errors["Entity"] ? (
          <p className="txt-field-error m-0 " aria-label={errors["Entity"]}>
            {errors["Entity"]}
          </p>
        ) : null}
      </td>
      <td>
        <Input value={custodian["Department"]} onChange={(e) => handleChange(e, "Department")} />
        {errors["Department"] ? (
          <p className="txt-field-error m-0 " aria-label={errors["Department"]}>
            {errors["Department"]}
          </p>
        ) : null}
      </td>
      <td>
        {classifications.length > 0 && (
          <FormSingleSelectWithDescription
            id="classifications"
            preFillSelectWithDescription={{
              classificationData: classifications.find((el) => el.label === custodian["Classification"])
                ? classifications.find((el) => el.label === custodian["Classification"])
                : custodian["Classification"],
            }}
            name="CRT_CLASSIFICATION_ID"
            onChangeFormField={(_, value) => handleChange(value, "Classification")}
            classificationOptions={classifications}
            hideLabel
          />
        )}
        {errors["Classification"] ? (
          <p className="txt-field-error m-0 " aria-label={errors["Classification"]}>
            {errors["Classification"]}
          </p>
        ) : null}
      </td>
      <td>
        <Input value={custodian["General Notes"]} onChange={(e) => handleChange(e, "General Notes")} />
      </td>
    </tr>
  );
};
