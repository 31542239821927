import { FloatingActionButton } from "appkit-react/lib/Button";
import PropTypes from "prop-types";
import React from "react";

const ButtonIcon = (props) => {
  const {
    size, // size can be 'sm', 'md', 'lg', 'xl'
    disabled,
    grayButton,
    type, // type can be 'submit', 'reset', 'button'
    isLoading,
    className,
    onClick,
    iconImg,
    iconName,
    alt,
    title,
    showIcon,
    ariaLabel,
    imgWidth,
  } = props;

  const displayIcon = () => {
    return (
      <>
        {iconName && (
          <span alt={alt} className={`appkiticon ${iconName}`} aria-label={ariaLabel} role="link"></span>
        )}
        {iconImg && (
          <>
            <img alt={alt} width={imgWidth} src={iconImg} aria-label={ariaLabel} />
            &nbsp;
          </>
        )}
      </>
    );
  };

  return (
    <FloatingActionButton
      kind="secondary"
      size={size}
      disabled={disabled}
      gray={grayButton === 1 ? 1 : 0}
      type={type}
      isLoading={isLoading}
      className={`${className}`}
      onClick={onClick}
      title={title}
      aria-label={ariaLabel}
      role="link"
    >
      {showIcon && displayIcon()}
      {props.children}
    </FloatingActionButton>
  );
};

ButtonIcon.propTypes = {
  size: PropTypes.string,
  disabled: PropTypes.bool,
  grayButton: PropTypes.bool,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  iconImg: PropTypes.string,
  iconName: PropTypes.string,
  alt: PropTypes.string,
  title: PropTypes.string,
  showIcon: PropTypes.bool,
  ariaLabel: PropTypes.string,
  imgWidth: PropTypes.string,
};
ButtonIcon.defaultProps = {
  size: "lg",
  disabled: false,
  grayButton: false,
  type: "button",
  isLoading: false,
  className: "",
  iconImg: null,
  iconName: "",
  alt: "image",
  title: "",
  showIcon: true,
  ariaLabel: "icon",
  imgWidth: "auto",
};

export default ButtonIcon;
