import React, { Component } from "react";
import PropTypes from "prop-types";
import "./Dropzone.css";
import fileImg from "../../../Assets/Icons/file-outline.svg";
import copyImg from "../../../Assets/Icons/duplicate-outline.svg";
import { polyglotLoader } from "../../../Services/Shared/Translate";

// DROPZONE TO UPLAOD FILES
class Dropzone extends Component {
  constructor(props) {
    super(props);
    this.state = { hightlight: false };
    this.fileInputRef = React.createRef();

    this.openFileDialog = this.openFileDialog.bind(this);
    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.onDragOver = this.onDragOver.bind(this);
    this.onDragLeave = this.onDragLeave.bind(this);
    this.onDrop = this.onDrop.bind(this);
  }
  polyglot = polyglotLoader();

  // OPEN FILE DIALOG WHERE USER CAN SELECT A FILE FROM BROWSER
  openFileDialog() {
    if (!this.props.disabled) {
      this.fileInputRef.current.click();
    }
  }

  // RUNS WHEN USER ADDS A FILE
  onFilesAdded(evt) {
    if (!this.props.disabled) {
      const files = evt.target.files;
      if (this.props.onFilesAdded) {
        const array = this.fileListToArray(files);
        this.props.onFilesAdded(array, evt);
      }
      //empty the value of file input
      this.fileInputRef.current.value = null;
    }
  }

  // RUNS ON DRAG OVER
  onDragOver(event) {
    if (!this.props.disabed) {
      event.preventDefault();
      this.setState({ hightlight: true });
    }
  }

  // RUNS ON LEAVING THE DRAG
  onDragLeave(event) {
    if (!this.props.disabed) {
      this.setState({ hightlight: false });
    }
  }

  // RUNS ON DROPPING THE FILE
  onDrop(event) {
    if (!this.props.disabed) {
      event.preventDefault();
      const files = event.dataTransfer.files;
      if (this.props.onFilesAdded) {
        const array = this.fileListToArray(files);
        this.props.onFilesAdded(array);
      }
      this.setState({ hightlight: false });
    }
  }

  // CONVERT LIST OF FILES TO AN ARRAY
  fileListToArray(list) {
    const array = [];
    for (var i = 0; i < list.length; i++) {
      array.push(list.item(i));
    }
    return array;
  }

  showDropzoneArea = () => {
    const polyglot = polyglotLoader();
    if (this.props.isSingleFileUpload) {
      return (
        <span className="font-size14">
          <img className="fileUploadTextRed" src={fileImg} alt="" />
          &nbsp;
          <span className="fileUploadTextRed">{polyglot.t("Select your file_dropzone")}</span>
          &nbsp;&nbsp;&nbsp;
          <span className="font-or">{polyglot.t("or_dropzone")}</span>
          &nbsp;&nbsp;&nbsp;
          <img className="fileUploadTextRed" src={copyImg} alt="" width={20} height={20} />
          &nbsp;
          <span className="drop-file">{polyglot.t("dragAndDrop_dropzone")}</span>
        </span>
      );
    }
    return (
      <span className="font-size14">
        <img className="fileUploadTextRed" src={fileImg} alt="" />
        &nbsp;
        <span className="fileUploadTextRed">{polyglot.t("Select your files_dropzone")}</span>
        &nbsp;&nbsp;&nbsp;
        <span className="font-or">{polyglot.t("or_dropzone")}</span>
        &nbsp;&nbsp;&nbsp;
        <img className="fileUploadTextRed" src={copyImg} alt="" width={20} height={20} />
        &nbsp;
        <span className="drop-file">{polyglot.t("dragAndDrop_dropzone")}</span>
      </span>
    );
  };

  buttonHandlers(disabled) {
    if (disabled) {
      return {};
    }
    return {
      onDragOver: this.onDragOver,
      onDragLeave: this.onDragLeave,
      onDrop: this.onDrop,
      onClick: this.openFileDialog,
    };
  }

  render() {
    const { customDropZoneBoxStyle, accept, disabled } = this.props;
    return (
      <button
        className={`dropZoneBox Dropzone cursor full-width ${this.state.hightlight ? "Highlight" : ""}`}
        {...this.buttonHandlers(disabled)}
        accept={accept}
        style={!customDropZoneBoxStyle ? {} : customDropZoneBoxStyle}
        disabled={disabled}
      >
        <input
          ref={this.fileInputRef}
          className="FileInput"
          multiple
          type="file"
          disabled={disabled}
          onChange={disabled ? undefined : this.onFilesAdded}
        />
        {this.showDropzoneArea()}
      </button>
    );
  }
}

Dropzone.propTypes = {
  accept: PropTypes.string,
  customDropZoneBoxStyle: PropTypes.any,
  isSingleFileUpload: PropTypes.bool,
};
Dropzone.defaultProps = {
  accept: "application/*, text/*",
};

export default Dropzone;
