import React from "react";
import Keyword from "../../../Assets/Images/DFED/Keyword.svg";
import "./Keywords.css";
import BackButton from "../Shared/button/BackButton";
import OutlineButton from "../Shared/button/OutlineButton";
import success from "../../../Assets/Images/DFED/success.svg";
import { Radio, RadioGroup, Input, Tooltip } from "appkit-react";
import KeywordService from "../../../Services/DFED/KeywordService";
import error from "../../../Assets/Images/DFED/exclamation-mark.svg";
import DownloadKeyWord from "./DownloadKeywordList";
import Dropzone from "../dropzone/Dropzone";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import "../../../Assets/Css/QueenBase/Root/Login.css";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import UpdateKeyword from "./UpdateKeyword";
import { checkPermission } from "../../../utility/checkPermission";
import commonFunctionForCallService from "../../../utility/commonFunctionForCallService";
import * as module_Solution_Name from "../../../Constants/moduleName";
import NoAccess from "../../../Assets/Components/NoAccess";
import { validateKeyword } from "./KeywordValidation";
import { TextFieldLengthValidation } from "../../../utility/textFieldValidation";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { plusCircle, closeIcon, deleteFill, backCircle } from "../../../Assets/Icons";
import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import AnalysisService from "../../../../src/Services/DFED/AnalysisService";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";

const analysisService = new AnalysisService();
const polyglot = polyglotLoader();

// THIS KEYWORD LIST CLASS IS HANDELING ALL KEYWORD CREATION EDIT<DEKETE OPERATIOn

const keywordService = new KeywordService();
const TooltipText = (text) => {
  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#000",
                textTransform: "capitalize",
              }}
            >
              {text}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
class KeywordsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showKeyword: false,
      showUploadKeyword: false,
      showWriteKeyword: false,
      keywordTitle: "",
      keywordList: "",
      includesArray: [],
      isEnableButton: false,
      isFromCreateNewList: false,
      uploadKeywordList: [],
      activeIndex: -1,
      writeKeywordlist: [{ keyword: "", isValid: false }],
      showIcon: false,
      error: "",
      fileName: "",
      imgSrc: [],
      fileError: "",
      keywordColumn: [],
      fileFlag: false,
      isEnable: false,
      fileKeywordsList: [],
      extractionComplete: false,
      showUpdateComponent: true,
      showErrorIcon: false,
      updateKeywords: [],
      fileDetails: [],
      showBackButton: false,
      selectedProjectId: props.location.state.selectedProjectId,
      selectedProjectName: sessionStorage.getItem("selectedProjectName"),
      rolePermission: {},
      rolePermissionForAnalysis: {},
      isViewPermission: true,
      textFieldLengthError: false,
      openConfirmBox: false,
      errorMsg: [],
      closeConfirmBoxAndNavigateTo: "",
    };
    var dataIndex;
    this.addKeywordHandler = this.addKeywordHandler.bind(this);
    this.getList = this.getList.bind(this);
  }

  static contextType = DataContext;

  getList() {
    this.props.history.push({
      pathname: "/dfed/keywords",
      state: {
        selectedProjectId: this.state.selectedProjectId,
        selectedProjectName: sessionStorage.getItem("selectedProjectName"),
      },
    });
  }
  // ADD KEYWORD FUNCTIONALITY=====================
  addKeywords = () => {
    this.setState({
      showKeyword: true,
      showUpdateComponent: false,
      keywordTitle: "",
      keywordList: "",
      showUploadKeyword: false,
      showWriteKeyword: false,
      fileKeywordsList: [],
      uploadKeywordList: [],
      writeKeywordlist: [{ keyword: "" }],
      showIcon: false,
      showBackButton: true,
      isEnable: false,
    });
  };
  templateDownload = () => {
    this.props.downloadTemplate();
  };

  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  toggleConfirmBox = () => {
    this.setState({
      openConfirmBox: !this.state.openConfirmBox,
    });
  };
  // check and close the form
  checkAndCloseForm = () => {
    try {
      const { isFormOpen, formGotClosed } = this.context;
      if (isFormOpen) {
        // values of the form got changed - then show confirm box
        this.setState({ openConfirmBox: true });
      } else {
        // if form is not considered as open(values of the form are not changed yet), then close the form
        this.cancel();
        formGotClosed();
        this.setState({ openConfirmBox: false });
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // Close and redirect form
  closeFormAndRedirect = () => {
    try {
      const { formGotClosed } = this.context;
      // close the popup
      this.toggleConfirmBox();
      // mark the form is closed on the store
      formGotClosed();
      switch (this.state.closeConfirmBoxAndNavigateTo) {
        case "Analysis":
          // got analysis screen
          this.goToAnalysis();
          break;
        default:
          // close keyword form
          this.cancel();
      }
      this.setState({
        closeConfirmBoxAndNavigateTo: "",
      });
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  cancel = () => {
    this.setState({
      showKeyword: false,
      showUpdateComponent: true,
      showBackButton: false,
    });
  };
  // LIFECYCLE METHOD FOR HANDLING API SERVICE===================

  componentDidMount() {
    window.scrollTo(0, 0);
    this.getApplicationRolesWithPermission();
    this.getApplicationRolesWithPermissionForAnalysis();
    this.getKeywordList();
    if (this.props.location.state.isFromCreateNewList) {
      this.setState({ isFromCreateNewList: true });
    }
  }

  // ========CODE FOR GET ROLES======

  getApplicationRolesWithPermission = async () => {
    let selectedProjectId = this.props.location.state.selectedProjectId;
    let rolePermission = await commonFunctionForCallService.commonFunctionForCallService(
      module_Solution_Name.DFED_SOLUTION,
      selectedProjectId,
      module_Solution_Name.KEYWORD_MODULE,
    );
    if (rolePermission) {
      this.setState({
        rolePermission: rolePermission,
      });
    }
  };
  // GET ROLES FOR ANALYSIS
  getApplicationRolesWithPermissionForAnalysis = async () => {
    let selectedProjectId = this.state.selectedProjectId;
    let rolePermission = await commonFunctionForCallService.commonFunctionForCallService(
      module_Solution_Name.DFED_SOLUTION,
      selectedProjectId,
      module_Solution_Name.ANALYSIS_MODULE,
    );
    if (rolePermission) {
      this.setState({
        rolePermissionForAnalysis: rolePermission,
      });
    }
  };
  // GETTING KEYWORD LIST FROM API================

  async getKeywordList(projectid) {
    let selectedProjectId = this.state.selectedProjectId;
    if (projectid) {
      selectedProjectId = projectid;
    }
    const keywordService = new KeywordService();
    await keywordService
      .getKeywordData(selectedProjectId)
      .then((result) => {
        if (result && result.data && result.data.length > 0) {
          this.setState({
            updateKeywords: result.data,
            showUpdateComponent: true,
            showKeyword: false,
            showBackButton: false,
            fileKeywordsList: [],
            uploadKeywordList: [],
            isViewPermission: true,
          });
        }
      })
      .catch((error) => {
        if (error.status === 403) {
          this.setState({
            isViewPermission: false,
          });
        }
      });
  }

  onSelect = (e, value) => {
    e.preventDefault();
    if (value === "uploadRadio") {
      this.setState({
        keywordList: e.target.value,
        showWriteKeyword: false,
        showUploadKeyword: true,
        error: "",
        writeKeywordlist: [{ keyword: "" }],
      });
    }
    if (value === "writeRadio") {
      this.setState({
        keywordList: e.target.value,
        showUploadKeyword: false,
        showWriteKeyword: true,
        error: "",
        fileKeywordsList: [],
      });
    }
  };

  // TITLE CHANGE FUNCTIONALITY=====================
  titleChange = (e) => {
    const { formGotOpened } = this.context;
    formGotOpened();
    if (TextFieldLengthValidation(e)) {
      this.setState(
        {
          keywordTitle: e,
          textFieldLengthError: false,
        },
        this.checkIfSaveOnUploadIsValid,
      );
    } else {
      this.setState({ textFieldLengthError: true });
    }
  };
  // DELETE FUNCTIONALITY FOR UPLOADED FILE============

  deleteUploadedFile(idx, data) {
    this.state.uploadKeywordList.splice(idx, 1);
    this.setState({
      uploadKeywordList: [...this.state.uploadKeywordList],
      fileKeywordsList: [],
      showIcon: false,
      showErrorIcon: false,
    });
  }

  // WRITE KEYWORD FUNCTIONALITY=======================
  addWriteKeyword = (idx) => (evt) => {
    const { formGotOpened } = this.context;
    formGotOpened();
    const isKeywordValid = validateKeyword(evt);
    if (isKeywordValid) {
      const newWriteKeywordlist =
        this.state.writeKeywordlist &&
        this.state.writeKeywordlist.map((keyword, sidx) => {
          if (idx !== sidx) return keyword;
          return { ...keyword, keyword: evt, isValid: true };
        });
      this.setState({
        writeKeywordlist: newWriteKeywordlist,
        error: "",
        activeIndex: idx,
        showIcon: true,
        fileFlag: false,
        showErrorIcon: false,
        isEnable: true,
      });
    } else {
      const newWriteKeywordlist =
        this.state.writeKeywordlist &&
        this.state.writeKeywordlist.map((keyword, sidx) => {
          if (idx !== sidx) return keyword;
          return { ...keyword, keyword: evt, isValid: false };
        });
      this.setState({
        writeKeywordlist: newWriteKeywordlist,
        activeIndex: idx,
        isEnable: false,
        showErrorIcon: true,
        showIcon: false,
      });
    }
  };
  // ADD KEYWORD HANDLER FUNCTION

  addKeywordHandler = () => {
    let obj =
      this.state.writeKeywordlist && this.state.writeKeywordlist.length > 0
        ? this.state.writeKeywordlist[this.state.writeKeywordlist.length - 1]
        : null;
    if (obj && obj.keyword === "") {
      var polyglot = polyglotLoader();
      displayToastMessage(polyglot.t("Please enter right Keyword"), "error");
      return;
    }
    this.setState({
      writeKeywordlist: this.state.writeKeywordlist.concat([{ keyword: "", isValid: false }]),
      showIcon: false,
    });
  };

  // REMOVE KEYWORD FUNCTIONALITY==============
  removeKeyword = (idx) => () => {
    const updatedWriteKeywordList = this.state.writeKeywordlist.filter((s, sidx) => idx !== sidx);
    this.setState({
      writeKeywordlist: updatedWriteKeywordList,
    });
  };
  checkIfSaveOnUploadIsValid = () => {
    try {
      const listOfValidKeywords = this.state.fileKeywordsList.map((each) => each.isFileDataValid);
      let isSubmitEnabled = false;
      if (listOfValidKeywords.includes(false) || this.state.keywordTitle.length < 1) {
        // there are invalid keywords in the list
        isSubmitEnabled = false;
      } else {
        isSubmitEnabled = true;
      }
      this.setState({
        isEnable: isSubmitEnabled,
      });
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  removeFileKeyword = (idx) => () => {
    const updatedKeywordList = this.state.fileKeywordsList.filter((s, sidx) => idx !== sidx);
    this.setState(
      {
        fileKeywordsList: updatedKeywordList,
      },
      this.checkIfSaveOnUploadIsValid,
    );
  };

  /**
   * @typedef ValidatedKeyword
   * @type {object}
   * @property {string} keyword - keyword name.
   * @property {boolean} isFileDataValid
   */

  /**
   *
   * @param {File} file
   * @returns {Promise<ValidatedKeyword[]>}
   */
  readAndValidateKeywordsFromTextFile(file) {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.addEventListener("error", reject);
      fileReader.addEventListener("load", (event) => {
        /**
         * @type {string}
         */
        const fileKeywordsText = event.target.result;
        const keywords = fileKeywordsText
          .split("\n")
          .map((keyword) => keyword.trim())
          .filter((keyword) => keyword !== "");
        const validatedKeywords = keywords.map((keyword) => ({
          keyword,
          isFileDataValid: validateKeyword(keyword),
        }));
        resolve(validatedKeywords);
      });
      fileReader.readAsText(file, "UTF-8");
    });
  }

  // FILE UPLOAD FUNCTIONALITY=====================

  fileUpload = async (event) => {
    const { formGotOpened } = this.context;
    formGotOpened();

    /**
     * @type {File} - first uploaded file
     */
    const currentFile = event[0];
    const extension = currentFile.name.split(".").pop();

    if (extension !== "txt") {
      alert("Please select correct template file");

      this.setState({
        uploadKeywordList: [],
      });
      return;
    }

    const validatedKeywords = await this.readAndValidateKeywordsFromTextFile(currentFile);
    const hasValidationError = validatedKeywords.some(({ isFileDataValid }) => !isFileDataValid);

    this.setState({
      fileKeywordsList: validatedKeywords,
      isEnable: !hasValidationError,
      uploadKeywordList: [currentFile],
      fileName: currentFile.name,
      fileFlag: true,
    });
  };

  // CHECK IF ANALYSIS AND CUSTODIAN RECORDS ARE AVAILABLE
  checkIfAnalysisCustodianRecordsAreAvailable = async () => {
    try {
      const projectID = this.props.location.state.selectedProjectId;
      const apiResponse = await analysisService.getAnalysisCustodianRecords(projectID);
      if (apiResponse && apiResponse.data && apiResponse.data.length > 0) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  // REDIRECTION TO ANALYSIS PAGE AFTER KEYWORD SUCCESSFULLY CREATED
  goToAnalysis = async () => {
    const polyglot = polyglotLoader();
    // below if condition runs on clicking "Back to run-through" button
    if (this.props.location.state.analysisNameFromReview) {
      this.props.history.push({
        pathname: "/dfed/analysis",
        state: {
          selectedProjectId: this.props.location.state.selectedProjectId,
          leftPalalData_fro_key: this.props.location.state.leftPalalData,
          rightPalalData_fro_key: this.props.location.state.rightPalalData,
          analysisNameFromReview_fro_key: this.props.location.state.analysisNameFromReview,
          runthruoghFromReview_fro_key: this.props.location.state.runthruoghFromReview,
        },
      });
    } else {
      // before redirecting, check if analysis custodian records are available
      const isAvailable = await this.checkIfAnalysisCustodianRecordsAreAvailable();
      if (isAvailable) {
        this.props.history.push({
          pathname: "/dfed/analysis",
          state: {
            selectedProjectId: this.props.location.state.selectedProjectId,
          },
        });
      }
      if (!isAvailable) {
        displayToastMessage(
          polyglot.t("At least one processed dataset is required to add an analysis"),
          "error",
        );
      }
    }
    localStorage.setItem("fromKeyWords", "true");
  };

  checkAndGoToAnalysis = () => {
    try {
      const { isFormOpen } = this.context;
      if (!isFormOpen) {
        this.goToAnalysis();
      } else {
        this.toggleConfirmBox();
        this.setState({
          closeConfirmBoxAndNavigateTo: "Analysis",
        });
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // UPDATING THE KEYWORD FILE

  updateFileKeywords = (idx, value, enteredText) => {
    const updatedArray = this.state.fileKeywordsList;
    const isFileDataValid = validateKeyword(enteredText);
    updatedArray[idx] = { keyword: enteredText, isFileDataValid };
    this.setState({
      fileKeywordsList: updatedArray,
      activeIndex: idx,
    });

    if (isFileDataValid) {
      this.setState({
        showIcon: true,
        showErrorIcon: false,
        isEnable: true,
      });
    } else {
      this.setState({
        showIcon: false,
        showErrorIcon: true,
        isEnable: false,
      });
    }
  };

  getWriteKeyWordListAsPerAPI = () => {
    try {
      const requiredList = [];
      let requiredObj = {};
      this.state.writeKeywordlist.forEach((each) => {
        requiredObj = {};
        requiredObj.keyword = each.keyword;
        requiredObj.LIST_ID = each.LIST_ID;
        requiredList.push(requiredObj);
      });
      return requiredList;
    } catch (err) {
      displayToastMessage(err.message, "error");
      return [];
    }
  };

  // KEYWORD SAVE FUNCTIONALITY=============

  SaveKeyword = () => {
    const { formGotClosed } = this.context;
    let projectId = sessionStorage.getItem("newSelecedProjectID");
    if (projectId === 0 || projectId === "" || projectId === null) {
    }

    if (this.state.keywordTitle === "") {
      displayToastMessage("Please Enter Keyword List Title", "error");
      return;
    }
    if (this.state.keywordList === "") {
      displayToastMessage("Please Select Keyword List", "error");
      return;
    }
    if (this.state.uploadKeywordList.length === 0 && this.state.keywordList === "uploadRadio") {
      displayToastMessage("Please Select Your File or Drag and Drop", "error");
      return;
    }
    if (this.state.keywordList === "writeRadio" && this.state.writeKeywordlist) {
      let obj = this.state.writeKeywordlist[this.state.writeKeywordlist.length - 1];
      if (obj.keyword === "") {
        displayToastMessage("Please Type The Keyword", "error");
        return;
      }
    }
    var date = new Date();
    var DATE = date.toISOString();
    let keyword = [];
    let keywordlist = [];
    let formData = new FormData();
    keyword.push({
      LIST_NAME: this.state.keywordTitle,
      FILE_NAME: this.state.fileName,
      FILE_PATH: this.state.fileName,
      PROJECT_ID: parseInt(projectId),
      CREATED_ON: DATE,
      project_name: this.state.selectedProjectName,
    });

    if (this.state.keywordList === "uploadRadio") {
      delete keyword.writeKeyword;
      const embededKeywordList = [];
      this.state.fileKeywordsList.forEach((x) => {
        if (x.isFileDataValid) {
          embededKeywordList.push({ keyword: x.keyword });
        }
      });
      keywordlist.push({ keywordList: embededKeywordList });
    }
    if (this.state.keywordList === "writeRadio") {
      delete keyword.UploadKeyword;
      delete keyword.FileName;
      const writeKeywordList = this.getWriteKeyWordListAsPerAPI();
      keywordlist.push({ keywordList: writeKeywordList });
    }

    const lowerCaseKeywordsArr = keywordlist[0].keywordList.map((item) => item.keyword.toLowerCase());
    if (lowerCaseKeywordsArr.length !== new Set(lowerCaseKeywordsArr).size) {
      displayToastMessage(polyglot.t("Duplicated keywords"), "error");
      return;
    }

    formData.append("keywordFile", this.state.uploadKeywordList[0]);
    formData.append("keywordFileName", this.state.uploadKeywordList[0]?.name);
    formData.append("keyword", JSON.stringify(keyword));
    formData.append("keywordList", JSON.stringify(keywordlist));
    keywordService.addKeywordList(formData).then((res) => {
      var polyglot = polyglotLoader();
      if (res.data === "Keyword List Name Already Exist") {
        let Msg = polyglot.t("Keyword List Name Already Exist");
        displayToastMessage(Msg, "error");
      } else if (res.data.CODE || res.data === true) {
        this.setState({
          // empty the keyword title
          keywordTitle: "",
        });
        this.getKeywordList();
        let Msg = polyglot.t("Keyword List is created");
        displayToastMessage(Msg, "success");
        // close the form
        formGotClosed();
      } else {
        let Msg = polyglot.t("Keyword List is not created");
        displayToastMessage(Msg, "error");
      }
    });
  };
  // BACK BUTTON FUNCTIONALITY=====================
  backButton = () => {
    const { isFormOpen } = this.context;
    if (!isFormOpen) {
      // form is not in open state
      if (this.state.updateKeywords.length === 0) {
        this.setState({ showKeyword: false });
      }
      this.setState({
        showUpdateComponent: true,
        showBackButton: false,
      });
    } else {
      // form is not in closed state
      this.toggleConfirmBox();
    }
  };
  fastForwardButton = () => {
    this.props.history.push({
      pathname: "/dfed/Fastforward",
      state: { selectedProjectId: this.state.selectedProjectId },
    });
  };
  // used to display the error message on why the keyword is not valid
  displayKeywordTextErrorDetails = () => {
    try {
      return (
        <div style={{ textAlign: "left", fontWeight: "bold", color: "#000" }}>
          <table>
            <tbody>
              <tr>
                <td>Please consider the below parameters to enter a valid keyword</td>
              </tr>
              <tr>
                <td>You can use alphabets or numbers to create a valid keyword name</td>
              </tr>
              <tr>
                <td>If you choose to use any other characters, then please follow the below conditions</td>
              </tr>
            </tbody>
          </table>
          <ul>
            <li>a) There should be text inside brackets</li>
            <li>b) Opened quotations must be closed</li>
            <li>
              c) Special characters like '!', '@', '#', '$', '%', '^', '{"&"}', '=', ':', '{"<"}
              ', '{">"}', '/' are not allowed
            </li>
          </ul>
        </div>
      );
    } catch (err) {
      return TooltipText(err.message);
    }
  };
  // RENDER FUNCTIONALITY FOR RENDER UI OF KEYWORD+=====================
  render() {
    var polyglot = polyglotLoader();
    var keyWordComponent = (
      <div>
        <div>
          <div className="row col-md-10 pl-0">
            {checkPermission(this.state.rolePermission, module_Solution_Name.KEYWORD_MODULE, "Create") &&
              !this.state.showBackButton && (
                <div className="col-md-3 col-lg-3">
                  <ButtonPrimaryWithIcon
                    iconName={plusCircle}
                    onClick={this.addKeywords}
                    ariaLabel={polyglot.t("Add Keyword List")}
                  >
                    {polyglot.t("Add Keyword List")}
                  </ButtonPrimaryWithIcon>
                </div>
              )}
            {/* show back button */}
            {this.state.showBackButton && (
              <div className="col-md-3 col-lg-3">
                <ButtonPrimaryWithIcon
                  onClick={this.backButton}
                  iconName={backCircle}
                  ariaLabel={polyglot.t("Back")}
                >
                  {polyglot.t("Back")}
                </ButtonPrimaryWithIcon>
              </div>
            )}
            <div className="col-md-3 col-lg-3">
              {this.props.location.state.analysisNameFromReview ? (
                <span onClick={this.checkAndGoToAnalysis}>
                  <OutlineButton
                    name={"Back to run-through" + this.props.location.state.runthruoghFromReview}
                  />
                </span>
              ) : (
                checkPermission(
                  this.state.rolePermissionForAnalysis,
                  module_Solution_Name.ANALYSIS_MODULE,
                  "Create",
                ) &&
                !this.state.showBackButton && (
                  <span onClick={this.checkAndGoToAnalysis}>
                    <OutlineButton name={polyglot.t("Start an Analysiskl")} />
                  </span>
                )
              )}
            </div>
            {this.state.showBackButton ? (
              <div className="col-md-4 col-lg-3" />
            ) : (
              <div className="col-md-4 col-lg-3 ">
                {this.state.isFromCreateNewList && (
                  <span>
                    {" "}
                    <BackButton
                      onBackButtonClick={this.fastForwardButton}
                      buttonText={polyglot.t(" Fastforward")}
                    ></BackButton>
                  </span>
                )}
              </div>
            )}
          </div>
          {this.state.updateKeywords &&
          this.state.updateKeywords.length === 0 &&
          this.state.showKeyword === false ? (
            <div>
              <div className="keyword-dashboard mt-2">
                <h1 className="main_title_qb">{polyglot.t("Prepare your Analysis")}</h1>
              </div>
              <div className="keyword-dashboard mt-2">
                <div className="sub-title">
                  {polyglot.t("Generate Keyword list to map it to")}
                  <br></br>
                  {polyglot.t("your datasets start an analysis")}
                </div>
              </div>
            </div>
          ) : (
            <div>
              {this.state.showUpdateComponent &&
              this.state.updateKeywords &&
              this.state.updateKeywords.length > 0 &&
              this.state.showKeyword === true &&
              this.state.isViewPermission === true &&
              this.state.showBackButton === false ? (
                <div>
                  <UpdateKeyword
                    afterSaveGolist={this.getList}
                    updateKeywords={this.state.updateKeywords}
                    projectId={this.state.selectedProjectId}
                    projectName={this.state.selectedProjectName}
                    getKeyword={(projectid) => this.getKeywordList(projectid)}
                  />
                </div>
              ) : (
                <div>
                  <div>
                    {this.state.showUpdateComponent && this.state.isViewPermission === true ? (
                      <UpdateKeyword
                        afterSaveGolist={this.getList}
                        updateKeywords={this.state.updateKeywords}
                        projectId={this.state.selectedProjectId}
                        projectName={this.state.selectedProjectName}
                        getKeyword={(projectid) => this.getKeywordList(projectid)}
                      />
                    ) : (
                      <div>
                        <div className=" keyword-panel">
                          {this.state.showKeyword ? (
                            <div className="row">
                              <div className="col-md-12  Keyword-template">
                                <div className="col-md-8 keyword-create-panel p-0">
                                  <div className="a-panel a-p-10 a-shadow-sm m-t-5">
                                    <div className="row m-l-0 m-r-0">
                                      <div
                                        className="co1-md-3 pl-2"
                                        style={{
                                          paddingBottom: "14px",
                                          width: "33%",
                                        }}
                                      >
                                        <Input
                                          className="tile-key-word a-input-text ans-name p-l-0 p-r-0"
                                          id="profileNameId"
                                          autoFocus
                                          aria-label={polyglot.t("Enter Keyword List Name...")}
                                          placeholder={polyglot.t("Enter Keyword List Name...")}
                                          onChange={this.titleChange}
                                          value={this.state.keywordTitle}
                                        />
                                        {this.state.textFieldLengthError ? (
                                          <p className="txt-field-error m-0 pt-1">
                                            {polyglot.t("Maximum character length is 40")}
                                          </p>
                                        ) : null}
                                        {/* <span className="tile-key-word">Enter KeywordList Name</span> */}
                                      </div>
                                      <div className="col-md-1 mt-1">
                                        {/* <img src={edit} alt="edit" /> */}
                                      </div>
                                      <div className="col-md-7 text-align-right">
                                        <ButtonIcon
                                          iconName={closeIcon}
                                          alt="cancel"
                                          onClick={this.checkAndCloseForm}
                                          ariaLabel={polyglot.t("Cancel")}
                                          title={polyglot.t("Cancel")}
                                        />
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="keyword-error float-right">{this.state.error}</div>
                                    <div className="keyword-checkbox">
                                      <RadioGroup
                                        aria-label={this.state.keywordList}
                                        value={this.state.keywordList}
                                        onChange={this.onSelect}
                                      >
                                        <Radio
                                          value="uploadRadio"
                                          aria-label={polyglot.t("Upload Keyword List")}
                                        >
                                          {polyglot.t("Upload Keyword List")}
                                        </Radio>
                                        {this.state.showUploadKeyword ? (
                                          <div className="col-md-11">
                                            {this.state.fileKeywordsList.length > 0 ? (
                                              <div>
                                                <div className="row m-l-0 m-r-0">
                                                  <div className="col-md-11">
                                                    <ul className="list-group upload-list">
                                                      {this.state.uploadKeywordList &&
                                                        this.state.uploadKeywordList.map(
                                                          (acceptedFile, idx) => {
                                                            return (
                                                              <div>
                                                                <li
                                                                  key={idx}
                                                                  className="list-group-item list-group-item-secondary upload-key-file"
                                                                >
                                                                  <span>{acceptedFile.name}</span>
                                                                  <ButtonIcon
                                                                    iconName={deleteFill}
                                                                    alt="delete uploaded file"
                                                                    onClick={this.deleteUploadedFile.bind(
                                                                      this,
                                                                      idx,
                                                                    )}
                                                                    ariaLabel={polyglot.t(
                                                                      "delete uploaded file",
                                                                    )}
                                                                    title={polyglot.t("Delete")}
                                                                  />
                                                                </li>
                                                              </div>
                                                            );
                                                          },
                                                        )}
                                                    </ul>
                                                  </div>
                                                </div>
                                                <div className="col-md-12  mt-2 keyword-text keyword-lock">
                                                  {this.state.fileKeywordsList.map((value, idx) => {
                                                    return (
                                                      <div className="row upload-key-bg mb-2" key={idx}>
                                                        <div className="col-md-8">
                                                          <Input
                                                            key={idx}
                                                            value={value.keyword}
                                                            aria-label={value.keyword}
                                                            autoFocus
                                                            onChange={(e) =>
                                                              this.updateFileKeywords(idx, value.keyword, e)
                                                            }
                                                          />
                                                        </div>
                                                        <div className="col-md-1">
                                                          <ButtonIcon
                                                            iconName={closeIcon}
                                                            alt="delete"
                                                            onClick={this.removeFileKeyword(idx)}
                                                            ariaLabel={polyglot.t("delete")}
                                                            title={polyglot.t("Delete")}
                                                          />
                                                        </div>
                                                        {(this.state.activeIndex === idx &&
                                                          this.state.showIcon) ||
                                                        value.isFileDataValid === true ? (
                                                          <div className="col-md-1 sucess-check transformY-minus40">
                                                            <img src={success} alt="success" />
                                                          </div>
                                                        ) : null}
                                                        {(this.state.activeIndex === idx &&
                                                          this.state.showErrorIcon) ||
                                                        value.isFileDataValid === false ? (
                                                          <Tooltip
                                                            content={
                                                              this.state.errorMsg &&
                                                              this.state.errorMsg.length != 0
                                                                ? TooltipText(
                                                                    this.state.errorMsg[
                                                                      this.state.errorMsg.length - 1
                                                                    ],
                                                                  )
                                                                : this.displayKeywordTextErrorDetails()
                                                            }
                                                            placement="top"
                                                          >
                                                            <div className="col-md-1 sucess-check mt-0 transform-35">
                                                              <img
                                                                className="keyword-img-font"
                                                                src={error}
                                                                alt="error"
                                                              />
                                                            </div>
                                                          </Tooltip>
                                                        ) : null}
                                                      </div>
                                                    );
                                                  })}
                                                </div>
                                              </div>
                                            ) : (
                                              <div>
                                                <div className="col-md-9 ">
                                                  <Dropzone
                                                    onFilesAdded={this.fileUpload.bind(this)}
                                                    // accept="image/png, image/gif"
                                                    // accept="application/zip"
                                                    // accept="application/json"
                                                    disabled={
                                                      this.state.uploading || this.state.successfullUploaded
                                                    }
                                                  />
                                                </div>
                                              </div>
                                            )}
                                          </div>
                                        ) : null}
                                        <Radio value="writeRadio">{polyglot.t("Write Keyword List")}</Radio>
                                        {this.state.showWriteKeyword ? (
                                          <div className="col-md-12">
                                            <div className="row m-l-0 m-r-0">
                                              <div className="col-md-11 mt-3 keyword-lock">
                                                {this.state.writeKeywordlist &&
                                                  this.state.writeKeywordlist.map((keyword, idx) => (
                                                    <div key={idx}>
                                                      <div className="row key-word-bg">
                                                        <div className="keyword-number">{idx + 1}.</div>
                                                        <div className="col-md-8">
                                                          <Input
                                                            type="text"
                                                            key={idx}
                                                            className="mt-2 ans-name"
                                                            value={keyword.keyword}
                                                            aria-label={keyword.keyword}
                                                            onChange={this.addWriteKeyword(idx)}
                                                            autoFocus
                                                            placeholder={polyglot.t("Keyword name")}
                                                          />
                                                        </div>
                                                        <div className="keyword-close">
                                                          <ButtonIcon
                                                            iconName={deleteFill}
                                                            alt="delete"
                                                            onClick={this.removeKeyword(idx)}
                                                            ariaLabel={polyglot.t("delete")}
                                                            title={polyglot.t("Delete")}
                                                          />
                                                        </div>
                                                        {keyword.isValid ? (
                                                          <div className="col-md-1 sucess-check">
                                                            <img src={success} alt="success" />
                                                          </div>
                                                        ) : null}
                                                        {!keyword.isValid && keyword.keyword.length > 0 && (
                                                          <Tooltip
                                                            content={
                                                              this.state.errorMsg &&
                                                              this.state.errorMsg.length != 0
                                                                ? TooltipText(
                                                                    this.state.errorMsg[
                                                                      this.state.errorMsg.length - 1
                                                                    ],
                                                                  )
                                                                : this.displayKeywordTextErrorDetails()
                                                            }
                                                            placement="top"
                                                          >
                                                            <div className="col-md-1 sucess-check margin-top-15">
                                                              <img
                                                                className="keyword-img-font"
                                                                src={error}
                                                                alt="error"
                                                              />
                                                            </div>
                                                          </Tooltip>
                                                        )}
                                                      </div>
                                                    </div>
                                                  ))}
                                              </div>

                                              <div className="col-md-1 keyword-add-list">
                                                <ButtonIcon
                                                  onClick={this.addKeywordHandler}
                                                  iconName={plusCircle}
                                                  ariaLabel={polyglot.t("Add keyword")}
                                                  title={polyglot.t("Add keyword")}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        ) : null}
                                      </RadioGroup>
                                    </div>
                                    <div className="col-md-4 mt-4 mb-3">
                                      <ButtonPrimary
                                        disabled={!this.state.isEnable}
                                        onClick={this.SaveKeyword}
                                        ariaLabel={polyglot.t("Save Keyword list")}
                                      >
                                        {polyglot.t("Save Keyword list")}
                                      </ButtonPrimary>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-4 download-component">
                                  <div className="pl-3">
                                    <p className="para-txt">
                                      {polyglot.t(
                                        "Please provide a meaningful name to the Keyword list. The name must be different from the already existing lists",
                                      )}
                                      .
                                    </p>
                                    <p className="para-txt mb-1">
                                      {polyglot.t("You have two options to provide the keywords:")}
                                    </p>
                                    <p className="para-txt mb-1">
                                      {polyglot.t(
                                        "1. Upload a text file (UTF-8 encoding), where each line represents search term.",
                                      )}
                                    </p>
                                    <p className="para-txt">
                                      {polyglot.t("2. Write the keywords manually into the list")}.
                                    </p>
                                    <p className="para-txt">
                                      {polyglot.t(
                                        "Once you've added all the desired keywords, please click on ",
                                      )}
                                      <span className="para-head">{polyglot.t("Save Keyword list")}</span>
                                      {" " + polyglot.t("to store it")}.
                                    </p>
                                  </div>
                                  <DownloadKeyWord />
                                </div>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        {/* show confirm box if the form is open */}
        {this.state.openConfirmBox && (
          <ConfirmBoxExitForm
            show={this.state.openConfirmBox}
            heading={"Cancel update"}
            cancel={this.toggleConfirmBox}
            proceed={this.closeFormAndRedirect}
          />
        )}
      </div>
    );

    if (this.state.isViewPermission === false) {
      return <NoAccess />;
    } else {
      return keyWordComponent;
    }
  }
}
export default KeywordsList;
