import "../../../../Assets/Css/QueenBase/CompanyCenter/CompanyCenter.css";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import DataProcessed from "./DataProcessed";
import { percentageUsed } from "../../../../Services/Shared/Handlers";
import { DataContext } from "../../../../Services/Shared/Store";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import GetPriceInGermanFormat from "../../Root/GetPriceInGermanFormat";
import PackageConsumptionHeader from "../Headers/PackageConsumptionHeader";

function PackageConsumptionCard({ packageInfo, solutionId }) {
  const { getUserLanguage } = useContext(DataContext);
  const polyglot = polyglotLoader(getUserLanguage());

  let fileSizeLabel;
  let processingRunsLabel;
  let analysisLabel;
  let productionRunsLabel;

  let companyCodesLabel;
  let workflowsLabel;

  const dfedData = [];
  const dfData = [];

  if (solutionId === 1) {
    const {
      totalFileSizeUsed: { fileSizeUsed, fileSizeFormat: fileSize },
      packagePlanDetails: {
        dataProcessingLimit: { limit, fileSizeFormat },
        processingRunsLimit,
        analysesRunsLimit,
        productionRunsLimit,
      },
      totalProcessingRuns,
      totalAnalyses,
      totalProductionRuns,
    } = packageInfo;

    fileSizeLabel = `${fileSizeUsed ? GetPriceInGermanFormat(fileSizeUsed, true) : 0} ${fileSize} / ${
      limit ? GetPriceInGermanFormat(limit, true) : 0
    } ${fileSizeFormat}`;
    processingRunsLabel = `${totalProcessingRuns ? GetPriceInGermanFormat(totalProcessingRuns, true) : 0} / ${
      processingRunsLimit ? GetPriceInGermanFormat(processingRunsLimit, true) : 0
    }`;
    analysisLabel = `${totalAnalyses ? GetPriceInGermanFormat(totalAnalyses, true) : 0} / ${
      analysesRunsLimit ? GetPriceInGermanFormat(analysesRunsLimit, true) : 0
    }`;
    productionRunsLabel = `${totalProductionRuns ? GetPriceInGermanFormat(totalProductionRuns, true) : 0} / ${
      productionRunsLimit ? GetPriceInGermanFormat(productionRunsLimit, true) : 0
    }`;

    const data = [
      {
        percentageUsed: percentageUsed(fileSizeUsed ? fileSizeUsed.toFixed(1) : 0, limit ? limit : 0),
        label: fileSizeLabel,
        title: polyglot.t("Data processed"),
      },
      {
        percentageUsed: percentageUsed(totalProcessingRuns, processingRunsLimit),
        label: processingRunsLabel,
        title: polyglot.t("Processing runs"),
      },
      {
        percentageUsed: percentageUsed(
          totalAnalyses ? totalAnalyses : 0,
          analysesRunsLimit ? analysesRunsLimit : 0,
        ),
        label: analysisLabel,
        title: polyglot.t("Analyses"),
      },
      {
        percentageUsed: percentageUsed(
          totalProductionRuns ? totalProductionRuns : 0,
          productionRunsLimit ? productionRunsLimit : 0,
        ),
        label: productionRunsLabel,
        title: polyglot.t("Production runs"),
      },
    ];

    dfedData.push(...data);
  } else if (solutionId === 2) {
    const {
      sumOfYearsOfDataAccrossCompanyCodes: sumOfYears,
      packagePlanDetails: { yearsOfDataAccrossCompanyCodes: yearsOfData, numberOfWorkflows },
      totalNumberOfWorkflows: totalWorkflows,
    } = packageInfo;

    companyCodesLabel = `${sumOfYears ? GetPriceInGermanFormat(sumOfYears, true) : 0} / ${
      yearsOfData ? GetPriceInGermanFormat(yearsOfData, true) : 0
    }`;
    workflowsLabel = `${totalWorkflows ? GetPriceInGermanFormat(totalWorkflows, true) : 0} / ${
      numberOfWorkflows ? GetPriceInGermanFormat(numberOfWorkflows, true) : 0
    }`;

    const data = [
      {
        percentageUsed: percentageUsed(sumOfYears ? sumOfYears : 0, yearsOfData ? yearsOfData : 0),
        label: companyCodesLabel,
        title: polyglot.t("Data processed"),
      },
      {
        percentageUsed: percentageUsed(
          totalWorkflows ? totalWorkflows : 0,
          numberOfWorkflows ? numberOfWorkflows : 0,
        ),
        label: workflowsLabel,
        title: polyglot.t("Workflows"),
      },
    ];

    dfData.push(...data);
  }

  return (
    <PackageConsumptionHeader>
      {solutionId === 1 ? (
        <div>
          {dfedData.map(({ title, label, percentageUsed }) => (
            <DataProcessed key={title} title={title} label={label} percentageUsed={percentageUsed} />
          ))}
        </div>
      ) : solutionId === 2 ? (
        <div>
          {dfData.map(({ title, label, percentageUsed }) => (
            <DataProcessed key={title} title={title} label={label} percentageUsed={percentageUsed} />
          ))}
        </div>
      ) : (
        polyglot.t("No data available")
      )}
    </PackageConsumptionHeader>
  );
}
PackageConsumptionCard.propTypes = {
  packageInfo: PropTypes.object,
  solutionId: PropTypes.number,
};
export default PackageConsumptionCard;
