/* eslint-disable no-mixed-spaces-and-tabs */
import "../../../Assets/Css/QueenBase/Root/UserProfile.css";
import "../../../Assets/Css/QueenBase/Root/Login.css";
import { Avatar, Input, Select, SelectGroupTitle, SelectOption } from "appkit-react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import icongroup from "../../../Assets/Icons/avatar-outline.svg";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";
import CustomDropZone from "../../../Assets/Components/CustomDropZone/CustomDropZone";
import { closeIcon } from "../../../Assets/Icons";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/QueenBase/Root/loading-spin.svg";
import UserService from "../../../Services/QueenBase/Users/UserService";
import CommonService from "../../../Services/Shared/CommonService";
import { checkIfImageIsValid } from "../../../Services/Shared/Handlers";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";
import { userProfileValidationSchema } from "../../../utility/textFieldValidation";

function UserProfile(props) {
  const {
    getUserLanguage,
    userLanguage,
    fetchCountriesList,
    updateUserImageOnHeader,
    asignUserProfileImage,
    formGotClosed,
    formGotOpened,
    isFormOpen,
  } = useContext(DataContext);

  const language = getUserLanguage();
  var polyglot = polyglotLoader(getUserLanguage());
  const [countries, setCountries] = useState([]);
  const [positions, setPositions] = useState([]);
  const [user, setUser] = useState([]);
  const [prefix, setPrefix] = useState([]);
  const [isUserLoaded, setIsUserLoaded] = useState(false);
  const [image, setImage] = useState({
    preview: "",
    raw: "",
  });
  const [originalImagePreview, setOriginalImgPreview] = useState("");
  const [originalImageRaw, setOriginalImgRaw] = useState("");
  const [windowHeight, setWindowHeight] = useState(0);
  const [openConfirmBox, setOpenConfirmBox] = useState(false); // this value handles the confirm box of exit form
  // CODE FOR CALCULATE HEIGHT BASEON SCREEN SIZE===
  let resizeWindw = () => {
    setWindowHeight(window.innerHeight);
  };
  const getInitialValues = () => ({
    firstname: user.firstname,
    lastname: user.lastname,
    email: user.email,
    positionid: user.positionid,
    countryid: user.countryid,
    entity: user.entity,
    department: user.department,
    phone: user.phone,
    userid: user.userid,
    prefixID: user.prefixID || "",
  });

  // GET LOGGEDIN USER PROFILE DETAILS
  async function getUserProfile() {
    const res = await UserService.get().getUserProfile();
    if (res.CODE) {
      setPositions(res.positions);
      //setUser(res.userData)
      setUser({
        firstname: res.userData.firstname,
        countryid: res.userData.countryid,
        email: res.userData.email,
        entity: res.userData.entity,
        lastname: res.userData.lastname,
        phone: res.userData.phone,
        positionid: res.userData.positionid,
        department: res.userData.department,
        userid: res.userData.userid,
        prefixID: res.userData.prefixID,
      });
      setIsUserLoaded(true);
      if (res.image) {
        var imageBlob = await CommonService.get().getBufferImageInBlob(res.image);
        var imageStr = URL.createObjectURL(imageBlob);
        setImage({
          preview: imageStr,
          raw: JSON.stringify({
            data: res.image.data,
          }),
        });
        setOriginalImgPreview(imageStr);
        setOriginalImgRaw(
          JSON.stringify({
            data: res.image.data,
          }),
        );
      } else {
        setImage({
          preview: "",
          raw: "",
        });
        setOriginalImgPreview("");
        setOriginalImgRaw("");
      }
    } else {
      displayToastMessage(res.MSG, "error");
    }
  }
  const userProfileUpdate = async (values, actions) => {
    try {
      var formData = new FormData();
      formData.append("file", image.raw);
      formData.append("user", JSON.stringify(values));
      var res = await UserService.get().userProfileUpdate(formData);
      if (res.CODE) {
        actions.resetForm();
        displayToastMessage(polyglot.t("Profile has been successfully updated"), "success");
        asignUserProfileImage(res.profileImage);
        updateUserImageOnHeader(true);
        formGotClosed();
        props.history.goBack();
      } else {
        displayToastMessage(polyglot.t(res.MSG), "error");
      }
    } catch (err) {
      displayToastMessage(polyglot.t(err.message), "error");
    }
  };
  // close confirm box and redirect back
  const closeFormAndRedirect = ({ resetForm }) => {
    toggleConfirmBox();
    resetForm();
    formGotClosed();
    // redirect back
    props.history.goBack();
  };
  // toggle confirm box when form is open
  const toggleConfirmBox = () => {
    setOpenConfirmBox(!openConfirmBox);
  };
  const checkIfFormIsEmptyAndGoback = () => {
    try {
      if (isFormOpen) {
        // form is in open state, hence user cannot navigate to other screens
        toggleConfirmBox();
      } else {
        props.history.goBack();
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getUserProfile();
    resizeWindw();
    window.addEventListener("resize", resizeWindw);
    return () => window.removeEventListener("resize", resizeWindw);
  }, []);

  useEffect(() => {
    getPrefix(language);
  }, [language, userLanguage]);

  // FETCH PRIORITY FROM API
  async function getPrefix(language) {
    var res = await UserService.get().getPrefixNames(language);
    if (res.CODE) {
      setPrefix(res.prefixNames);
    } else {
      displayToastMessage(polyglot.t(res.MSG), "error");
    }
  }

  // get countries from api on language change
  const getCountries = async () => {
    const countriesList = await fetchCountriesList();
    setCountries(countriesList);
  };
  // Runs when user language got updated
  useEffect(() => {
    getCountries();
  }, [userLanguage]);
  const handleFile = async (e) => {
    const fileInput = e;
    // fileInput = [] when user cancels the upload
    if (fileInput.length > 0) {
      var filePath = fileInput[0].name;
      var allowedExtensions = /(\.jpg|.jpeg|.png)$/i;

      if (filePath !== "") {
        if (!allowedExtensions.exec(filePath)) {
          displayToastMessage(
            polyglot.t("Please upload file having extensions .jpg or .png or .jpeg only"),
            "info",
          );
          fileInput[0].value = "";
          return false;
        }
        let isimageValid = await checkIfImageIsValid(fileInput);
        if (!isimageValid) {
          displayToastMessage(
            polyglot.t("Please upload file having extensions .jpg or .png or .jpeg only"),
            "info",
          );
          return false;
        }
      }

      if (fileInput[0].size) {
        var fileSize = fileInput[0].size / 1028 / 1028; //size in MB
        if (fileSize <= 2) {
          setImage({
            preview: URL.createObjectURL(fileInput[0]),
            raw: fileInput[0],
          });
        } else {
          displayToastMessage(polyglot.t("image size cannot exceed 2 MB"), "error");
        }
      }
    }
  };

  const errorLoadingImage = () => {
    displayToastMessage(
      polyglot.t("Please upload file having extensions .jpg or .png or .jpeg only"),
      "info",
    );
    // If error found on the image, then set the original image as the raw
    setImage({
      preview: "",
      raw: originalImageRaw,
    });
  };

  const showUserImage = () => {
    try {
      let expectedUserImage = null;
      if (image.preview) {
        expectedUserImage = image.preview;
      } else if (originalImagePreview) {
        expectedUserImage = originalImagePreview;
      } else if (!originalImagePreview || originalImagePreview !== "") {
        // load dummy image
        expectedUserImage = icongroup;
      }
      return expectedUserImage;
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  return (
    <div style={{ minHeight: windowHeight }} className="queen-container">
      <div className="row profile-pic-update">
        <div className="container">
          <div className="col-md-2 ScreenFit-qb"></div>
          <div className="col-md-8 ScreenFit-qb">
            {isUserLoaded ? (
              <div className="card mb-3">
                <div className="row m-0">
                  <div className="headerStyle-cc col-md-12">
                    <h1 className="headerText-cc">{polyglot.t("My Profile")}</h1>
                  </div>
                </div>
                <div className="card-body user-login">
                  <div className="upload-avtar-top mb-4">
                    <div className="col-md-2 update-profile-pic" id="avatar-backgroud">
                      <Avatar id={originalImagePreview ? "upload-avtar-img-data" : "upload-avtar-img"}>
                        {
                          <img
                            alt="flag"
                            className={image.preview ? "change-avatar-image-data" : "change-avatar-image"}
                            src={showUserImage()}
                            onError={errorLoadingImage}
                          />
                        }
                      </Avatar>
                    </div>
                    <div className="col-md-10 upload-profile-pic">
                      <div className="upload-profile-photo-wrapper full-width">
                        <CustomDropZone
                          onFilesAdded={handleFile}
                          isSingleFileUpload
                          accept={"image/jpeg,image/x-png"}
                        />
                      </div>
                      <p className="ImageContent-two">
                        <b>{polyglot.t("Maximum upload file size:2 MB")}</b>
                      </p>
                    </div>
                  </div>
                  <Formik
                    onSubmit={userProfileUpdate}
                    initialValues={getInitialValues()}
                    validationSchema={userProfileValidationSchema}
                  >
                    {(prop) => {
                      const { values, handleChange, handleSubmit, errors, touched, dirty } = prop;
                      // FORMIK FORM - HANDLE FORMOPEN OR CLOSE STATUS
                      const updateFormStatusBasedOnDirty = () => {
                        if (dirty) {
                          formGotOpened();
                        }
                        if (!dirty) {
                          formGotClosed();
                        }
                      };
                      updateFormStatusBasedOnDirty();
                      return (
                        <div>
                          <div className="row mb-3">
                            <div className="col-md-3">
                              {getUserLanguage() === "en" && (
                                <>
                                  <Select
                                    placeholder={polyglot.t("Salutation *")}
                                    value={Number(values.prefixID).toString()}
                                    onSelect={(value) =>
                                      handleChange("prefixID")({
                                        target: { value },
                                      })
                                    }
                                  >
                                    {prefix.map((prefix, index) => {
                                      return (
                                        <SelectOption key={index} value={Number(prefix.id).toString()}>
                                          {polyglot.t(prefix.name)}
                                        </SelectOption>
                                      );
                                    })}
                                  </Select>
                                </>
                              )}
                              {getUserLanguage() === "ger" && (
                                <>
                                  <Select
                                    placeholder={polyglot.t("Salutation *")}
                                    value={Number(values.prefixID).toString()}
                                    onSelect={(value) =>
                                      handleChange("prefixID")({
                                        target: { value },
                                      })
                                    }
                                  >
                                    {prefix.map((prefix, index) => {
                                      return (
                                        <SelectOption key={index} value={Number(prefix.id).toString()}>
                                          {polyglot.t(prefix.name)}
                                        </SelectOption>
                                      );
                                    })}
                                  </Select>
                                </>
                              )}
                              {errors.prefixID && touched.prefixID ? (
                                <div className="error">{polyglot.t(errors.prefixID)}</div>
                              ) : null}
                              <br />
                            </div>
                            <div className="col-md-3">
                              <Input
                                inputBoxSize="sm"
                                placeholder={polyglot.t("First name *")}
                                className="Box"
                                value={values.firstname}
                                onChange={handleChange("firstname")}
                                aria-label={polyglot.t("first name of the user")}
                              />
                              {errors.firstname && touched.firstname ? (
                                <span className="error">{errors.firstname}</span>
                              ) : null}
                            </div>
                            <div className="col-md-6">
                              <Input
                                inputBoxSize="sm"
                                placeholder={polyglot.t("Last Name *")}
                                className="Box"
                                value={values.lastname}
                                onChange={handleChange("lastname")}
                                aria-label={polyglot.t("last name of the user")}
                              />
                              {errors.lastname && touched.lastname ? (
                                <span className="error">{errors.lastname}</span>
                              ) : null}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-6">
                              <Input
                                inputBoxSize="sm"
                                placeholder={polyglot.t("Email *")}
                                className="Box"
                                value={values.email}
                                disabled
                                aria-label={polyglot.t("email id")}
                              />
                              {errors.email && touched.email ? (
                                <span className="error">{errors.email}</span>
                              ) : null}
                            </div>
                            <div className="col-md-6">
                              {positions.length > 0 && (
                                <Select
                                  placeholder={polyglot.t("Position_noStar")}
                                  className="update-position"
                                  showSearchOnToggle={true}
                                  value={values.positionid}
                                  onSelect={(value) =>
                                    handleChange("positionid")({
                                      target: { value },
                                    })
                                  }
                                  aria-label={polyglot.t("Position_noStar")}
                                >
                                  <SelectGroupTitle>{polyglot.t("Position_noStar")}</SelectGroupTitle>
                                  {positions.map((position, index) => {
                                    return (
                                      <SelectOption key={index} value={position.positionid}>
                                        {position.name}
                                      </SelectOption>
                                    );
                                  })}
                                </Select>
                              )}
                              {errors.positionid && touched.positionid ? (
                                <span className="error">{errors.positionid}</span>
                              ) : (
                                null &&
                                errors.positionid && <span className="error">{errors.positionid}</span>
                              )}
                            </div>
                          </div>
                          <div className="row mb-3">
                            <div className="col-md-6">
                              {countries.length > 0 && (
                                <Select
                                  placeholder={polyglot.t("Country_noStar")}
                                  showSearchOnToggle={true}
                                  value={values.countryid}
                                  onSelect={(value) =>
                                    handleChange("countryid")({
                                      target: { value },
                                    })
                                  }
                                  aria-label={polyglot.t("Country_noStar")}
                                >
                                  <SelectGroupTitle>{polyglot.t("Country_noStar")}</SelectGroupTitle>
                                  {countries.map((country, index) => {
                                    if (index < 1) {
                                      return (
                                        <SelectOption key={index + 1} value={country.countryid}>
                                          {country.name}
                                        </SelectOption>
                                      );
                                    }
                                  })}
                                  <hr className="" />
                                  {countries.map((country, index) => {
                                    if (index > 0)
                                      return (
                                        <SelectOption key={index + 1} value={country.countryid}>
                                          {country.name}
                                        </SelectOption>
                                      );
                                  })}
                                </Select>
                              )}
                              {errors.countryid && touched.countryid ? (
                                <span className="error">{errors.countryid}</span>
                              ) : (
                                null && errors.countryid && <span className="error">{errors.countryid}</span>
                              )}
                            </div>
                            <div className="col-md-6">
                              <Input
                                inputBoxSize="sm"
                                placeholder={polyglot.t("Organisation")}
                                className="Box"
                                value={values.entity}
                                onChange={handleChange("entity")}
                                aria-label={polyglot.t("Organisation")}
                              />
                              {errors.entity && touched.entity ? (
                                <span className="error">{errors.entity}</span>
                              ) : null}
                            </div>
                          </div>
                          <div className="row ">
                            <div className="col-md-6">
                              <Input
                                inputBoxSize="sm"
                                placeholder={polyglot.t("Department *")}
                                className="Box"
                                value={values.department}
                                onChange={handleChange("department")}
                                aria-label={polyglot.t("Department")}
                              />
                              {errors.department && touched.department ? (
                                <span className="error">{errors.department}</span>
                              ) : null}
                            </div>
                            <div className="col-md-6">
                              <Input
                                inputBoxSize="sm"
                                placeholder={polyglot.t("PhoneNumber*")}
                                className="Box"
                                value={values.phone}
                                onChange={handleChange("phone")}
                                aria-label={polyglot.t("Phone number")}
                              />
                              {errors.phone && touched.phone ? (
                                <span className="error">{errors.phone}</span>
                              ) : null}
                            </div>
                          </div>
                          <div className="row mt-2">
                            <div className="col-md-5">
                              <ButtonSecondary
                                className="save-info-up"
                                onClick={checkIfFormIsEmptyAndGoback}
                                iconName={closeIcon}
                                ariaLabel={polyglot.t("Cancel")}
                              >
                                {polyglot.t("Cancel")}
                              </ButtonSecondary>
                            </div>
                            <div className="col-md-7 text-right">
                              <ButtonPrimary
                                className="save-info-up"
                                onClick={handleSubmit}
                                type="submit"
                                ariaLabel={polyglot.t("Save Information")}
                              >
                                {polyglot.t("Save Information")}
                              </ButtonPrimary>
                            </div>
                          </div>
                          <ConfirmBoxExitForm
                            show={openConfirmBox}
                            heading={"Cancel update"}
                            cancel={toggleConfirmBox}
                            proceed={() =>
                              closeFormAndRedirect({
                                resetForm: prop.resetForm,
                              })
                            }
                          />
                        </div>
                      );
                    }}
                  </Formik>
                </div>
              </div>
            ) : (
              <div className="container-fluid">
                <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
              </div>
            )}
          </div>
          <div className="col-md-2 ScreenFit"></div>
        </div>
      </div>
    </div>
  );
}
UserProfile.propTypes = {
  history: PropTypes.object,
};

export default withRouter(UserProfile);
