import CommonService from "../Shared/CommonService";

class RecipientResponseService {
  constructor() {
    this.baseApiUrl = CommonService.get().getGRCBaseApiUrl();
  }

  getRecipientQuestionAnswersURL(id, uid, dependency_question_id) {
    return `${this.baseApiUrl}/getRecipientQuestionAnswers/${id}/${uid}/${dependency_question_id}`;
  }
  getTextDataPerfAssessmentURL(id) {
    return `${this.baseApiUrl}/getTextDataPerfAssessment/${id}`;
  }
  putRecipientQuestionAnswersReadURL() {
    return `${this.baseApiUrl}/putRecipientQuestionAnswersRead`;
  }
  downloadAssessmentFileURL() {
    return `${this.baseApiUrl}/downloadAssessmentFileRead`;
  }
  getRecipientAssessmentsURL(projectid) {
    return `${this.baseApiUrl}/getRecipientAssessments/${projectid}`;
  }
}

// Enable Singleton behavior on this class
RecipientResponseService.__instance = null;
RecipientResponseService.get = () => {
  if (!RecipientResponseService.__instance) {
    RecipientResponseService.__instance = new RecipientResponseService();
  }
  return RecipientResponseService.__instance;
};

export default RecipientResponseService;
