import "../../../Assets/Css/QueenBase/Users/User.css";
import { Pagination } from "antd";
import { Select, SelectOption } from "appkit-react";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";

import DeleteUser from "./DeleteUser";
import EditUser from "./EditUser";
import UserScreenLegend from "./UserScreenLegend";
import ViewUser from "./ViewUser";
import userflow from "../../../Assets/Images/QueenBase/Root/image-user.svg";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/QueenBase/Root/loading-spin.svg";
import CommonService from "../../../Services/Shared/CommonService";
import UserService from "../../../Services/QueenBase/Users/UserService";
import { hasEditPermission, hasViewPermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";
import { PER_PAGE_COUNT } from "../../../Constants/Constant";

const PER_PAGE = PER_PAGE_COUNT;
// RENDERS THE USER LIST
function UserList({ getUserCount, userCount, rightLoadingCard }) {
  let mounted = true;
  const [userList, setUserList] = useState([]);
  const [edit, setEdit] = useState(false);
  const [showDeleteUserModal, setshowDeleteUserModal] = useState(false);
  const [user, setUser] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [pagelist, setPageList] = useState([]);
  const [searchStr, setSearch] = useState("");
  const [loading, setloading] = useState(true);
  const { getCurrentUserPermissions, getUserLanguage, formGotOpened, formGotClosed } =
    useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const currentUserPermissions = getCurrentUserPermissions();
  const [sortOrder, setSortOrder] = useState("Newest"); // default sort by is done based on "Newest" records
  const [selectedStatus, setSelectedStatus] = useState("all"); // all status is selected by default

  // FETCH USER IMAGES BASED ON THE USER LIST RECEIVED FROM API
  const fetchUserImages = async (userList_from_API) => {
    const res = await UserService.get().getUserList_Images();
    if (res.CODE) {
      const userListClone = [];
      let userObj = {};
      userList_from_API.forEach((user) => {
        userObj = { ...user };
        res.userImagesList.forEach((item) => {
          if (item.userid === user.userid) {
            if (item.image) {
              const imageBlob = CommonService.get().getBufferImageInBlob(item.image);
              const imageStr = URL.createObjectURL(imageBlob);
              userObj.image = imageStr;
            }
          }
        });
        userListClone.push(userObj);
      });
      setUserList(userListClone);
      setPageList(userListClone);
    }
  };

  // FETCH USERS LIST FROM API
  const getUserList = async () => {
    setloading(true);
    setPageList([]);
    var res = await UserService.get().getUserList_withoutImages();
    if (res.CODE) {
      res.userList.map((user) => {
        if (user.image) {
          var imageBlob = CommonService.get().getBufferImageInBlob(user.image);
          var imageStr = URL.createObjectURL(imageBlob);
          user.image = imageStr;
        }
      });
      if (mounted) {
        setUserList(res.userList);
        setPageList(res.userList);
        // default sort order is "newest"
        setSortOrder("Newest");
        fetchUserImages(res.userList);
        rightLoadingCard(res.userList);
      }
    } else {
      displayToastMessage(res.MSG, "error");
    }
    setloading(false);
  };
  // ACTIVATE A USER
  const activateUser = async (id) => {
    try {
      const executeActivateUser = async () => {
        const body = {
          userID: id,
        };
        var res = await UserService.get().activateUser(body);
        if (res.CODE) {
          displayToastMessage(res.MSG, "success");
          // fetch user list
          getUserList();
          // fetch user count
          getUserCount();
        } else {
          displayToastMessage(res.MSG, "error");
        }
      };
      if (Number(userCount.freetempusers) > 0) {
        executeActivateUser();
      } else {
        displayToastMessage(
          polyglot.t(
            "There are no balance to create a temporary user. Kindly buy more temporary users to proceed.",
            "error",
          ),
        );
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // CANCEL USER MODAL
  const cancelUserModal = (userid, username, email) => {
    setUser({ userid, username, email });
    setshowDeleteUserModal((prev) => !prev);
  };
  // ON CLICK OF EDIT ICON
  const onEdit = (id) => {
    var res = userList.map((user) => {
      if (user.userid === id) {
        user.isedit = !user.isedit;
        return user;
      }
      return user;
    });
    setUserList(res);
    setEdit(true);
  };
  // ON CANCELING EDIT FORM
  const onCancel = (id) => {
    var res = userList.map((user) => {
      if (user.userid === id) {
        user.isedit = !user.isedit;
        return user;
      }
      return user;
    });
    setUserList(res);
  };
  // HANDLE PAGINATION PAGE CHANGE
  const handlePageChange = (change) => {
    setCurrentPage(change);
  };
  // HANDLE ON SEARCH FUNCTION
  const onSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };
  // GET USERS OF CURRENT PAGE
  const getCurrentPageUsers = (users) => {
    return users.slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE);
  };
  // RUNS ON LOAD
  useEffect(() => {
    getUserList();
    return function cleanup() {
      mounted = false;
    };
  }, []);
  //sortOrder useEffect
  useEffect(() => {
    if (sortOrder) {
      setCurrentPage(1);
      switch (sortOrder) {
        case "A-Z":
          setPageList([
            ...pagelist.sort((a, b) => {
              return a.firstname.localeCompare(b.firstname);
            }),
          ]);
          return;
        case "Z-A":
          const filteredList = pagelist.sort((a, b) => {
            return b.firstname.localeCompare(a.firstname);
          });
          setPageList([...filteredList]);
          return;
        case "Created On":
          setPageList([
            ...pagelist.sort((a, b) => {
              return moment(a.createddate).isAfter(moment(b.createddate)) ? 0 : -1;
            }),
          ]);
          return;
        case "Newest":
          setPageList([
            ...pagelist.sort((a, b) => {
              return moment(a.createddate).isBefore(moment(b.createddate)) ? 0 : -1;
            }),
          ]);
          return;
        default:
          return;
      }
    }
  }, [sortOrder]);

  // KEYS OF USER STATUS
  const filterUserStatusKeys = (listObj) => {
    let isMatching = false;
    switch (selectedStatus) {
      case "all":
        // no filter is applied
        isMatching = true;
        break;
      case "active":
        // users with inactive = 0 && isExpired = false && isDeleted = 0 are considered as active users
        if (!listObj.inactive && !listObj.isExpired && !listObj.isDeleted) {
          isMatching = true;
        } else {
          isMatching = false;
        }
        break;
      case "inActive":
        // users with inactive = 1 && isExpired = true are isDeleted = 0 considered as inactive users
        if ((listObj.inactive || listObj.isExpired) && !listObj.isDeleted) {
          isMatching = true;
        } else {
          isMatching = false;
        }
        break;
      case "deleted":
        // users with isDeleted = 1 are considered as deleted users
        if (listObj.isDeleted) {
          isMatching = true;
        } else {
          isMatching = false;
        }
        break;
      default:
        isMatching = true;
    }
    return isMatching;
  };
  // SEARCH KEYS
  const searchKeys = (listObj) => {
    const keys = ["firstname", "lastname", "email"];
    const keyValues = keys.map((key) => listObj[key]);
    return keyValues.toString();
  };

  // FILTER USERS BASED ON SEARCH TEXT
  const filterItems = () => {
    let filteredItems = [...pagelist];
    // filter based on selected user status
    filteredItems = pagelist.filter((r) => filterUserStatusKeys(r));
    // filter based on search string
    if (searchStr !== "") {
      const regex = new RegExp(searchStr, "i");
      filteredItems = filteredItems.filter((r) => {
        return regex.test(searchKeys(r));
      });
    }
    return filteredItems;
  };

  // CHOOSE USER STATUS
  const chooseUserStatus = (value) => {
    setCurrentPage(1);
    setSelectedStatus(value);
  };

  // DISPLAYS SEARCH AND SORT
  const searchFilter = () => {
    return (
      <div className="row m-0">
        <div className="col-md-8 p-0 m-b-2">
          <div className="row m-l-0 m-r-0 filter-search-qb">
            <div className={`searchIcon`}>
              <input
                value={searchStr}
                className="SearchBar search-input"
                placeholder={polyglot.t("Search user")}
                onChange={onSearch}
                autoComplete={"off"}
                aria-label={polyglot.t("Search user")}
              />
            </div>
            <div className="search-sort">
              {getUserLanguage() === "ger" && (
                <Select
                  id="filter-id"
                  placeholder={polyglot.t("Sort")}
                  value={sortOrder}
                  onSelect={setSortOrder}
                >
                  <SelectOption key="A-Z" value="A-Z">
                    {polyglot.t("User Name")}&nbsp;
                    <span className="appkiticon icon-arrow-up-outline"></span>
                  </SelectOption>
                  <SelectOption key="Z-A" value="Z-A">
                    {polyglot.t("User Name")}&nbsp;
                    <span className="appkiticon icon-arrow-down-outline"></span>
                  </SelectOption>
                  <SelectOption key="Newest" value="Newest">
                    {polyglot.t("Newest")}
                  </SelectOption>
                  <SelectOption key="Created On" value="Created On">
                    {polyglot.t("Oldest")}
                  </SelectOption>
                </Select>
              )}
              {getUserLanguage() === "en" && (
                <Select
                  id="filter-id"
                  placeholder={polyglot.t("Sort")}
                  value={sortOrder}
                  onSelect={setSortOrder}
                >
                  <SelectOption key="A-Z" value="A-Z">
                    {polyglot.t("User Name")}&nbsp;
                    <span className="appkiticon icon-arrow-up-outline"></span>
                  </SelectOption>
                  <SelectOption key="Z-A" value="Z-A">
                    {polyglot.t("User Name")}&nbsp;
                    <span className="appkiticon icon-arrow-down-outline"></span>
                  </SelectOption>
                  <SelectOption key="Newest" value="Newest">
                    {polyglot.t("Newest")}
                  </SelectOption>
                  <SelectOption key="Created On" value="Created On">
                    {polyglot.t("Oldest")}
                  </SelectOption>
                </Select>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-4 p-0 m-b-2">
          <Select
            placeholder={polyglot.t("User status")}
            id="filter-user-status"
            value={selectedStatus}
            onSelect={chooseUserStatus}
          >
            <SelectOption key="all" value="all">
              {polyglot.t("All users")}
            </SelectOption>
            <SelectOption key="active" value="active">
              {polyglot.t("Active")}
            </SelectOption>
            <SelectOption key="inActive" value="inActive">
              {polyglot.t("Inactive")}
            </SelectOption>
            <SelectOption key="deleted" value="deleted">
              {polyglot.t("Deleted")}
            </SelectOption>
          </Select>
        </div>
      </div>
    );
  };
  return (
    <div>
      <DeleteUser
        user={user}
        show={showDeleteUserModal}
        cancelUserModal={cancelUserModal}
        fetchUserAfterDelete={getUserList}
        fetchUserCountAfterDelete={getUserCount}
      />
      {!loading ? (
        userList.length > 0 ? (
          <div>
            <h1 className="main_title_qb m-b-18 ">{polyglot.t("List of Users")}</h1>
            {searchFilter()}
            <div className="user-align pt-0">
              {/* if no results are available to show, then show no results message */}
              {getCurrentPageUsers(filterItems()).length === 0 && (
                <div className="no-users-aailable">{polyglot.t("No records available")}</div>
              )}
              {getCurrentPageUsers(filterItems()).map((user, index) => {
                return user.isedit ? (
                  <div key={index} className="edituser">
                    {hasEditPermission(currentUserPermissions) && (
                      <EditUser
                        fetchUserAfterEdit={getUserList}
                        key={index}
                        onCancel={onCancel}
                        userData={user}
                      />
                    )}
                  </div>
                ) : (
                  <div key={index} className="">
                    {hasViewPermission(currentUserPermissions) && (
                      <ViewUser
                        cancelUserModal={cancelUserModal}
                        onEdit={onEdit}
                        user={user}
                        activateUser={activateUser}
                        userCount={userCount}
                      />
                    )}
                  </div>
                );
              })}
            </div>{" "}
          </div>
        ) : (
          <div className="user-flow-list">
            <img alt="flag" src={userflow} className="flow-img" />
            <p className="headertest mt-3 mb-2">{polyglot.t("Create users")}</p>
            <p className="user-flow-text">
              {polyglot.t(
                "Users you add here will get an invite to log into Queen Base. They can only work on projects/solutions as per the permissions assigned to their accounts.",
              )}
            </p>
          </div>
        )
      ) : (
        <div className="container-fluid">
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      )}
      {hasViewPermission(currentUserPermissions) && (
        <div className="user-pagenation">
          {filterItems(pagelist).length > PER_PAGE && (
            <Pagination
              current={currentPage}
              size="small"
              pageSize={PER_PAGE}
              total={filterItems(pagelist).length}
              onChange={handlePageChange}
              showSizeChanger={false}
            />
          )}
        </div>
      )}
      {userList.length > 0 ? <UserScreenLegend /> : ""}
    </div>
  );
}
UserList.propTypes = {
  getUserCount: PropTypes.func,
  userCount: PropTypes.object,
};

export default UserList;
