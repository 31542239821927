import React from "react";
import "../../Assets/Css/DFED/FastForwardBtn.css";
import { Tooltip } from "antd";
import fastFarwordIcon from "../../Assets/Icons/speedometer-outline.svg";
import { polyglotLoader } from "../../Services/Shared/Translate";
import ButtonPrimaryWithIcon from "../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
// FAST FORWARD BUTTON CLASS WITH TOOLTIP
const FastforwardButton = (props) => {
  var polyglot = polyglotLoader();
  return (
    <>
      <ButtonPrimaryWithIcon
        className="fastForwardBtn"
        iconImg={fastFarwordIcon}
        onClick={props.onClick}
        iconSize={32}
        ariaLabel={polyglot.t("Create a process pipeline outlining the approach at each stage.")}
      >
        <Tooltip
          placement="bottomLeft"
          title={<span>{polyglot.t("Create a process pipeline outlining the approach at each stage.")}</span>}
        >
          {props.name}
        </Tooltip>
      </ButtonPrimaryWithIcon>
    </>
  );
};

export default FastforwardButton;
