// import React, { Component } from "react";
import React, { useEffect } from "react";
import "../../../../Assets/Css/DFED/Profile.css";
import { RadioGroup, Checkbox, Select, SelectOption, Input, Tooltip } from "appkit-react";

import { FormErrors } from "./FormErrors";
import RadioGroupCustom from "../../../../Assets/Components/RadioGroupCustom/RadioGroupCustom";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import FormFileUpload from "../../Shared/components/FormFileUpload";

export const GetProfileName = (props) => {
  const polyglot = polyglotLoader();

  return (
    <div className="dropDownInputWidth">
      <Input
        suffix={
          <Tooltip
            content={[
              <div className="a-h6" key={1} role="presentation" aria-label={polyglot.t("Profile Name")}>
                {polyglot.t("Profile Name")}
              </div>,
              <div
                className="a-h6 font-weight-normal"
                key={2}
                role="presentation"
                aria-label={polyglot.t(
                  "Give you desired profile name i.e. windowsProfile, LinuxProfile or FinanceProfile",
                )}
              >
                {polyglot.t(
                  "Give you desired profile name i.e. windowsProfile, LinuxProfile or FinanceProfile",
                )}
              </div>,
            ]}
            placement="right"
            tooltipTheme="light"
            className="auth-tooltip tabFocus"
            trigger="click"
          >
            <i className="icon-information-fill appkiticon" style={{ color: "#ffbf1f", fontSize: 16 }} />
          </Tooltip>
        }
        placeholder={polyglot.t("Profile Name")}
        aria-label={polyglot.t("Profile Name")}
        id="profileNameId"
        name="profileName"
        autoFocus
        value={
          props.profileVal !== 0 || props.profileVal === ""
            ? props.profileVal
            : props.isViewProfile === true
            ? ""
            : ""
        }
        disabled={props.isViewProfile === true ? true : false}
        onChange={(e) => props.changed(props.attr, e, false)}
        className={props.error.length > 0 ? "formInputErrors" : "profile-name-input-dfed"}
      />
      {/* <h1> props.attr </h1> */}
    </div>
  );
};
// RADIO VIEW COMPONENT RESPONSIBLE FOR DISPLAYING RADION BUTTON ON UI========================
export const RadioView = (props) => {
  if (props.isNewProfile === true) {
    var defaultValue = "2";
  } else {
    var defaultValue = props.processVal;
  }
  const polyglot = polyglotLoader();
  var data = props.radioViewData;
  const options = [];
  let optionObj = {};
  data.forEach((obj) => {
    optionObj = { ...obj };
    optionObj.value = obj.PR_PT_ID;
    optionObj.name = polyglot.t(obj.PR_PT_NAME.trim());
    optionObj.disabled = props.isViewProfile === true ? true : false;
    options.push(optionObj);
  });
  return (
    <div>
      {data.length > 0 ? (
        <RadioGroupCustom
          options={options}
          defaultValue={defaultValue}
          value={props.processVal}
          onChange={(e) => props.changed(props.attr, e, false)}
        />
      ) : (
        <div> {polyglot.t("No Data Found for Process Type.")} </div>
      )}
    </div>
  );
};

export const CheckBoxView = (props) => {
  var data = props.checkBoxViewData;
  return (
    <div>
      {data.length > 0 ? (
        data.map((value, key) => {
          return (
            <div key={key + "_checkbox_view"} className="recoverySettingsCheckboxDiv">
              <Checkbox
                key={value.id}
                value={JSON.stringify(value.id)}
                name={value.name}
                data-checkboxname="recoverCheckBoxList"
                onChange={(e) => props.changed(value.attr, e, true)}
                disabled={props.isViewProfile}
                checked={value.isChecked}
              >
                {value.title}
              </Checkbox>
              <p className="checkBoxPText"> {value.subTitle} </p>
            </div>
          );
        })
      ) : (
        <div> No Data Found </div>
      )}
    </div>
  );
};

export const TabView1 = (props) => {
  var timeZoneData = props.timeZoneData,
    analysisLanguage = props.analysisLanguage;
  var tab1FirstCheckboxList = props.tab1FirstCheckboxList,
    tab1SecondCheckboxList = props.tab1SecondCheckboxList,
    tab1MiddleCheckboxList = props.tab1MiddleCheckboxList;
  const polyglot = polyglotLoader();
  return (
    <div className="tbContentStrt">
      <div className="form-group">
        <label className="cmnLblStyl" aria-label={polyglot.t("Timezone")}>
          {polyglot.t("Timezone")}
        </label>
        {timeZoneData.length > 0 ? (
          <div className="dropDownInputWidth">
            <Select
              aria-label={polyglot.t("Choose Time Zone")}
              placeholder={polyglot.t("Choose Time Zone")}
              name="generalTimeZone"
              defaultValue={props.isViewProfile === true ? props.selectedTimeZone : ""}
              value={props.selectedTimeZone || ""}
              showSearchOnToggle={true}
              onSelect={(e) => props.changed(props.attrTimeZone, e, false)}
              className="timezone"
              disabled={props.isViewProfile === true ? true : false}
            >
              {timeZoneData.map((value, key) => {
                return (
                  <SelectOption key={key + "_timeZone_tab1"} value={value.TIMEZONE_ID}>
                    {" "}
                    {value.TIMEZONE}
                  </SelectOption>
                );
              })}
            </Select>
          </div>
        ) : (
          <div> {polyglot.t("No Data found")} </div>
        )}
        <p className="subTextFont subTextMarginForCheckBox TextCutIssue">
          {" "}
          {polyglot.t("This option adjusts timezone offsets displayed in Nuix")}, <br />{" "}
          {polyglot.t("and should be the same as the evidence timezone")}.
          <br />
          <FormErrors formErrors={props.timeZoneError} />
        </p>
      </div>
      {/* <div className="form-group">
                <label className="cmnLblStyl" aria-label={polyglot.t("Analysis language")}>{polyglot.t("Analysis language")}</label>
                {(analysisLanguage.length > 0) ?
                    <div className="dropDownInputWidth">
                        <Select
                            aria-label={polyglot.t("Choose Language")}
                            placeholder={polyglot.t("Choose Language")}
                            name="genAnaLan"
                            showSearchOnToggle={true}
                            multiple
                            disabled={(props.isViewProfile === true) ? true : false}
                            value={props.selectedGenAnalysisLan}
                            onSelect={(e) => props.changed(props.attrGenAnaLan, e, false)}
                            className="analysi"
                        >
                            {analysisLanguage.map((value, key) => {
                                return (
                                    <SelectOption
                                        key={key + "_analysisLanguage_tab1"}
                                        value={value.ID}
                                    >
                                        {polyglot.t(value.language)}
                                    </SelectOption>)
                            })}
                        </Select>
                    </div>
                    : <div> {polyglot.t("No Data found")}</div>}
                <p className="subTextFont subTextMarginForCheckBox"> {polyglot.t("Specifies the language to use for text analysis when indexing")}.
                <br />
                    <FormErrors formErrors={props.analysisLanError} />
                </p>
            </div> */}
      {/* <div className="form-group tb1CmnChkbxDiv">
                {(tab1FirstCheckboxList.length > 0) ?
                    tab1FirstCheckboxList.map((value, key) => {
                        return (<div key={key + "_firstCheckBoxList_"}>
                            <Checkbox
                                key={value.id}
                                value={JSON.stringify(value.id)}
                                data-checkboxname={"genTabFirstCheckBoxList"}
                                name={value.name}
                                onChange={(e) => props.changed(value.attr, e, true)}
                                disabled={(props.isViewProfile === true) ? true : false}
                                checked={value.isChecked}
                            >
                                {value.title}
                            </Checkbox>
                            <p className="checkBoxPText"> {value.subTitle} </p>
                        </div>
                        )
                    })
                    : <div> {polyglot.t("No Data found")} </div>
                }
            </div>
            <div className="form-group mrgnTp5Percnt">
                <div className='secondary-input-container SecondaryInCon'>
                    <label className="cmnLblStyl" aria-label={polyglot.t("Carving block size")}> {polyglot.t("Carving block size")} </label>
                    <Input role="presentation" className="dropDownInputWidth aInputTextCustom inputboxHeight"
                        name="genCarvingBlock"
                        id="carvingBlckSize"
                        aria-label="genCarvingBlock"
                        onChange={(e) => props.changed(props.attrGenCarvingBlock, e, false)}
                        value={(props.carvingBlockSizeView !== 0) ? props.carvingBlockSizeView : ""}
                        disabled={(props.isViewProfile === true) ? true : false}
                    />
                    <p className="subTextFont subTextMarginForCheckBox pt-3"> {polyglot.t("The block size of the file system. (Integer value)")} </p>
                </div>
            </div>
            <div className="form-group tb1CmnChkbxDiv mrgnTp5Percnt">
                {(tab1MiddleCheckboxList.length > 0) ?
                    tab1MiddleCheckboxList.map((value, key) => {
                        return (<div key={key + "_middlecheckbox_tab1"}>
                            <Checkbox
                                key={value.id}
                                value={JSON.stringify(value.id)}
                                data-checkboxname={"genTabMiddleCheckBoxList"}
                                name={value.name}
                                checked={value.isChecked}
                                onClick={(e) => props.changed(value.attr, e, true)}
                                disabled={(props.isViewProfile === true) ? true : false}
                            >
                                {value.title}
                            </Checkbox>
                            <p className="checkBoxPText"> {value.subTitle} </p>
                        </div>
                        )
                    })
                    : <div> {polyglot.t("No Data found")} </div>
                }
            </div> */}
      <div className="form-group ">
        <div className="secondary-input-container SecondaryInCon">
          <label className="cmnLblStyl" aria-label={polyglot.t("Max digest size")}>
            {" "}
            {polyglot.t("Max digest size")}{" "}
          </label>
          <Input
            role="presentation"
            className="dropDownInputWidth aInputTextCustom"
            name="maxDigestSize"
            id="maxDigestSizeId"
            aria-label="maxDigestSize"
            onChange={(e) => props.changed(props.attrGenMaxDigestSize, e, false)}
            value={props.maxDigestSizeView !== 0 ? props.maxDigestSizeView : ""}
            disabled={props.isViewProfile === true ? true : false}
          />
          <p className="subTextFont subTextMarginForCheckBox">
            {" "}
            {polyglot.t("Maximum file size to calculate hash for")}.{" "}
          </p>
        </div>
      </div>
      <div className="form-group tb1CmnChkbxDiv mrgnTp5Percnt">
        {tab1SecondCheckboxList.length > 0 ? (
          tab1SecondCheckboxList.map((value, key) => {
            return (
              <div key={key + "_tab_sec_chck_b"}>
                <Checkbox
                  key={value.id}
                  value={JSON.stringify(value.id)}
                  data-checkboxname={"genTabSecondCheckBoxList"}
                  name={value.name}
                  checked={value.isChecked}
                  onClick={(e) => props.changed(value.attr, e, true)}
                  disabled={props.isViewProfile === true ? true : false}
                  // checked={(props.isViewProfile === true)?value.isChecked:false}
                >
                  {value.title}
                </Checkbox>
                <p className="checkBoxPText"> {value.subTitle} </p>
              </div>
            );
          })
        ) : (
          <div> {polyglot.t("No Data found")} </div>
        )}
      </div>
    </div>
  );
};

export const TabView2 = (props) => {
  const polyglot = polyglotLoader();
  var tab2NSFEncoding = props.tab2NSFEncoding;
  return (
    <div className="tbContentStrt">
      <div className="form-group">
        <div className="secondary-input-container SecondaryInCon">
          <label className="cmnLblStyl" aria-label={polyglot.t("NSF Credentials File")}>
            {polyglot.t("NSF Credentials File")}
          </label>
          <br />
          {props.isViewProfile === false ? (
            <div key="nsfFile">
              <div className="form-group files">
                <FormFileUpload formFileUpload={props.csvUploadHnadler.bind(this)} />
              </div>
              {/* UPLOADED FILE NAME SHOWING HERE */}
              <label className="cmnLblStyl">
                {props.csvFileDetails && props.csvFileDetails.name ? props.csvFileDetails.name : ""}
              </label>

              <span id="csvUploadTypeErr"></span>
            </div>
          ) : (
            <Input
              role="presentation"
              aria-label={polyglot.t("Input")}
              placeholder={polyglot.t("Input")}
              // name="nsfCredentialsFile"
              className="dropDownInputWidth aInputTextCustom"
              id="nsfCredentialsFilesId"
              // onChange={(e) => props.changed(props.nsfCredentialsNameAttr, e, false)}

              value={props.NSFCredentialFileNmView !== 0 ? props.NSFCredentialFileNmView : ""}
              disabled={props.isViewProfile === true ? true : false}
            />
          )}

          {/* <Input
                    placeholder="Input"
                    name="nsfCredentialsFile"
                    className="dropDownInputWidth aInputTextCustom" 
                    id="nsfCredentialsFilesId"
                    onChange={(e) => props.changed(props.nsfCredentialsNameAttr, e, false)}

                    value={(props.NSFCredentialFileNmView !== 0 )?props.NSFCredentialFileNmView:''}
                    disabled={(props.isViewProfile === true )?true:false}
                /> */}
          <p className="subTextFont">
            {" "}
            {polyglot.t("Full path and file name for the .CSV file format")} <br />{" "}
            {polyglot.t("(NSF file name, ID file path and name, password) do not use headers")}.
            <br />
            <FormErrors formErrors={props.NSFFileError} />
          </p>
        </div>
      </div>
      <div className="form-group">
        <label className="cmnLblStyl">{polyglot.t("NSF Credentials File Encoding")}</label>
        {tab2NSFEncoding.length > 0 ? (
          <div className="dropDownInputWidth">
            <Select
              aria-label={polyglot.t("Choose File Encoding")}
              placeholder={polyglot.t("Choose File Encoding")}
              name="fileEncoding"
              value={props.isViewProfile === true ? props.nsfFileEncoding : null}
              showSearchOnToggle={props.isViewProfile === true ? false : true}
              // showSearchOnToggle={true}
              onSelect={(e) => props.changed(props.nsfFileEncodingAttr, e, false)}
              disabled={props.isViewProfile === true ? true : false}
            >
              {tab2NSFEncoding.map((value, key) => {
                return (
                  <SelectOption
                    key={key + "_nsf_enc"}
                    value={value.PR_FE_ID}
                    aria-label={polyglot.t(value.PR_FE_TYPE)}
                  >
                    {" "}
                    {polyglot.t(value.PR_FE_TYPE)}
                  </SelectOption>
                );
              })}
            </Select>
          </div>
        ) : (
          <div> {polyglot.t("No Data found")} </div>
        )}
        <p className="subTextFont subTextMarginForCheckBox">
          <br />
          <FormErrors formErrors={props.NSFFileEncError} />
        </p>
      </div>
    </div>
  );
};

export const TabView3 = (props) => {
  const polyglot = polyglotLoader();
  var tab3FileType = props.tab3FileType;
  return (
    <div className="tbContentStrt">
      <div className="form-group">
        {tab3FileType.length > 0 ? (
          tab3FileType.map((value, key) => {
            return (
              <div key={key + "_tab3_filetype"}>
                <Checkbox
                  key={value.ID + "_tab3_filetype"}
                  value={value.ID}
                  data-checkboxname={"fileTypeTabCheckBoxList"}
                  name={value.name}
                  checked={value.isChecked}
                  onChange={props.fileTypeChanged}
                  disabled={props.isViewProfile === true ? true : false}
                >
                  {polyglot.t(value.FILE_TYPE)}
                </Checkbox>
                <p className="checkBoxPText"> </p>
              </div>
            );
          })
        ) : (
          <div> {polyglot.t("No Data found")} </div>
        )}
      </div>
    </div>
  );
};

export const TabView4 = (props) => {
  const polyglot = polyglotLoader();
  var tab4MimeType = props.tab4MimeType;
  return (
    <div className="tbContentStrt">
      <div className="form-group">
        {tab4MimeType.length > 0 ? (
          tab4MimeType.map((value, key) => {
            return (
              <div key={key + "_tab4_mimetype"}>
                <Checkbox
                  key={value.ID}
                  value={value.ID}
                  data-checkboxname={"mimeTypeTabCheckBoxList"}
                  name={value.name}
                  checked={value.isChecked}
                  onChange={props.mimeTypeChanged}
                  disabled={props.isViewProfile === true ? true : false}
                  // checked={value.isChecked}
                  // checked={(props.isViewProfile === true)?value.isChecked:false}
                >
                  {value.MIME_TYPE}
                </Checkbox>
                <p className="checkBoxPText"> </p>
              </div>
            );
          })
        ) : (
          <div> {polyglot.t("No Data found")} </div>
        )}
      </div>
    </div>
  );
};

export const TabView5 = (props) => {
  const polyglot = polyglotLoader();
  useEffect(() => {}, [props]);
  var tab5Pdf = props.tab5Pdf;
  var tab5Images = props.tab5Images;
  var tab5ImagesType = props.tab5ImagesType;
  var tab5ExcludeType = props.tab5ExcludeType;
  var tab5TextSection = props.tab5TextSection;
  var tab5Quality = props.tab5Quality;
  var tab5AnalysisLanguage = props.tab5AnalysisLanguage;
  var selectedVal = "";
  var selectedRotation = "";
  let tempSelectedLan = [];
  // let tempChecked = '';
  if (props.isViewProfile === true) {
    selectedVal = props.ocrQuality.toString();
    selectedRotation = props.ocrRation.toString();

    tempSelectedLan = props.ocrLanguageVal.toString().split(",");
  }
  return (
    <div className="tbContentStrt">
      <div className="pdfSection">
        <div className="profileHeading ocrTabHed">
          {" "}
          <p> {polyglot.t("PDF")} </p>{" "}
        </div>
        <div className="form-group">
          {tab5Pdf.length > 0 ? (
            tab5Pdf.map((value, key) => {
              // return (<Radio key={key+"_ocr_pdf_selection"} value={value.ID} > {value.CATEGORY} </Radio>)
              return (
                <div key={key + "_ocr_pdf_selection"}>
                  <label
                    className={
                      props.isViewProfile === true
                        ? "customeRadioContainer radioDisable"
                        : "customeRadioContainer radioEnable"
                    }
                  >
                    {value.CATEGORY}
                    <input
                      role="presentation"
                      type={"radio"}
                      name={"ocrPDFSelection"}
                      value={value.ID}
                      // WORKING wiht Warninig :=> checked={(props.selectedPdfVal === value.ID*1 || props.selectedPdfVal === value.ID)?true:null}
                      checked={
                        props.selectedPdfVal === value.ID * 1 || props.selectedPdfVal === value.ID
                          ? true
                          : props.isViewProfile === true
                          ? false
                          : null
                      }
                      disabled={props.isViewProfile === true ? true : false}
                      onChange={(e) => props.changed(props.tab5PDFAttr, e, false)}
                    />
                    <span className="radioCheckMark"></span>
                  </label>
                </div>
              );
            })
          ) : (
            <div> {polyglot.t("No Data found")} </div>
          )}
          {/* </RadioGroup> */}
        </div>
      </div>
      <div className="horizontalLine">
        {" "}
        <hr />{" "}
      </div>
      <div className="imageSection">
        <div className="profileHeading ocrTabHed">
          {" "}
          <p> {polyglot.t("Images")} </p>{" "}
        </div>
        <div className="form-group">
          {/* <RadioGroup 
                        name="ocrNonSpecificImg" 
                        // onChange={props.changed}
                        // onChange={(e) => props.changedTEST(props.attr, e, false, true)} 
                        onChange={(e) => props.changed(props.tab5NonSpecificImgAttr, e, false)} 
                    > */}
          {tab5Images.length > 0 ? (
            tab5Images.map((value, key) => {
              // return (<Radio key={key+"_non_spec_img"} value={value.ID} > {value.SUB_CATEGORY} </Radio>)
              return (
                <div key={key + "_non_spec_img"}>
                  <label
                    className={
                      props.isViewProfile === true
                        ? "customeRadioContainer radioDisable"
                        : "customeRadioContainer radioEnable"
                    }
                  >
                    {value.SUB_CATEGORY}
                    <input
                      role="presentation"
                      type={"radio"}
                      name={"ocrNonSpecificImg"}
                      // value={(value.ID != null || value.ID != undefined)?value.ID:''}
                      value={value.ID}
                      // checked={(props.selectedNoneImgVal ===   value.ID*1 || props.selectedNoneImgVal === value.ID)?true:null}
                      checked={
                        props.selectedNoneImgVal === value.ID * 1 || props.selectedNoneImgVal === value.ID
                          ? true
                          : props.isViewProfile === true
                          ? false
                          : null
                      }
                      // defaultChecked={(props.selectedNoneImgVal === value.ID*1 || props.selectedNoneImgVal === value.ID)?true:null}
                      // checked={(props.selectedNoneImgVal.includes(value.ID))?true:false}
                      // disabled={(props.selectedPdfVal !== 0)?true:false}
                      disabled={props.isViewProfile === true ? true : false}
                      onChange={(e) => props.changed(props.tab5NonSpecificImgAttr, e, false)}
                    />
                    <span className="radioCheckMark"></span>
                  </label>
                </div>
              );
            })
          ) : (
            <div> {polyglot.t("No Data found")} </div>
          )}
          {/* </RadioGroup> */}
          <div className="subTextMargin">
            {tab5ImagesType.length > 0 ? (
              tab5ImagesType.map((value, key) => {
                return (
                  <div key={key + "_img_type_tab5"}>
                    <Checkbox
                      key={value.ID}
                      value={value.ID}
                      data-checkboxname={"ocrTabSpecificImgCheckBoxList"}
                      name={value.name}
                      checked={value.isChecked}
                      // onChange={props.checkChanged}
                      onChange={props.ocrSpecificImageChange}
                      disabled={props.isViewProfile === true ? true : false}
                      // checked={(props.isViewProfile === true)?value.isChecked:null}
                    >
                      {value.SUB_CATEGORY}
                    </Checkbox>
                  </div>
                );
              })
            ) : (
              <div>{polyglot.t("No Data found")} </div>
            )}
          </div>
          <p className="checkBoxPText"> </p>
          <div className="horizontalLine">
            {" "}
            <hr />{" "}
          </div>
        </div>
        <div className="checkBoxSection">
          {tab5ExcludeType.length > 0 ? (
            tab5ExcludeType.map((value, key) => {
              return (
                <div key={key + "_tab5_exc_type"}>
                  <Checkbox
                    key={value.id}
                    value={JSON.stringify(value.id)}
                    data-checkboxname={"ocrTabExcludeCheckBoxList"}
                    name={value.name}
                    checked={value.isChecked}
                    onChange={(e) => props.changed(value.attr, e, true)}
                    disabled={props.isViewProfile === true ? true : false}
                    // checked={value.isChecked}
                    // checked={(props.isViewProfile === true)?value.isChecked:null}
                  >
                    {value.title}
                  </Checkbox>
                  <p className="checkBoxPText"> </p>
                </div>
              );
            })
          ) : (
            <div> {polyglot.t("No Data found")} </div>
          )}
          <div className="horizontalLine">
            {" "}
            <hr />{" "}
          </div>
          <p className="checkBoxPText"> </p>
          <RadioGroup
            name="ocrTextModify"
            onChange={(e) => props.changed(props.tab5ModifyTextAttr, e, false)}
          >
            {tab5TextSection.length > 0 ? (
              tab5TextSection.map((value, key) => {
                return (
                  <div key={key + "_ocr_text_modify"}>
                    <label
                      className={
                        props.isViewProfile === true
                          ? "customeRadioContainer radioDisable"
                          : "customeRadioContainer radioEnable"
                      }
                    >
                      {value.title}
                      <input
                        role="presentation"
                        type={"radio"}
                        name={"ocrTextModify"}
                        className={"ocrModifyTextClass"}
                        // value={(props.isViewProfile === true)?JSON.stringify(value.id):''}
                        value={value.id}
                        // value={value.id}

                        // checked={(props.selectedCcrModifyText === value.id*1 || props.selectedCcrModifyText === value.id)?true:(props.isViewProfile === true)?false:null}

                        // checked={(props.selectedCcrModifyText*1 === value.id*1 || props.selectedCcrModifyText === value.id)?true:(props.isViewProfile === true)?false:null}
                        disabled={props.isViewProfile === true ? true : false}
                        onChange={(e) => props.changed(props.tab5ModifyTextAttr, e, false)}
                      />
                      <span className="radioCheckMark"></span>
                    </label>
                  </div>
                );
              })
            ) : (
              <div> {polyglot.t("No Data found")} </div>
            )}
          </RadioGroup>
        </div>
        <p className="checkBoxPText"> </p>
        <div className="horizontalLine">
          {" "}
          <hr />{" "}
        </div>
        <p className="checkBoxPText"> </p>
        <div className="qualityRotationSection">
          <div className="form-group">
            <label className="cmnLblStyl">{polyglot.t("Quality")}</label>
            {tab5Quality.length > 0 ? (
              <div className="dropDownInputWidth">
                <Select
                  aria-label="Choose Quality"
                  placeholder="Choose Quality"
                  name="qualityDropDwon"
                  value={props.isViewProfile === true ? selectedVal : null}
                  showSearchOnToggle={true}
                  onSelect={(e) => props.changed(props.ocrQualityAttr, e, false)}
                  disabled={props.isViewProfile === true ? true : false}
                >
                  {tab5Quality.map((value, key) => {
                    return (
                      <SelectOption key={key + "_quality_tab5"} value={value.PR_Q_ID}>
                        {" "}
                        {value.PR_Q_NAME}
                      </SelectOption>
                    );
                  })}
                </Select>
              </div>
            ) : (
              <div> {polyglot.t("No Data found")} </div>
            )}
            {/* <p style={subText}> This option adjusts timezone offsets displayed in Nuix, and should be the same as the evidence timezone. </p> */}
            <FormErrors formErrors={props.ocrQualityErr} />
          </div>
          <div className="form-group">
            <label className="cmnLblStyl">{polyglot.t("Rotation")}</label>
            {props.tab5Rotation.length > 0 ? (
              <Select
                aria-label="Choose Rotation"
                placeholder="Choose Rotation"
                name="rotationDropDown"
                value={props.isViewProfile === true ? selectedRotation : null}
                showSearchOnToggle={true}
                onSelect={(e) => props.changed(props.ocrRationAttr, e, false)}
                className="dropDownInputWidth"
                disabled={props.isViewProfile === true ? true : false}
              >
                {props.tab5Rotation.map((value, key) => {
                  return (
                    <SelectOption key={key + "_rotation_tab5"} value={value.PR_ROTATION_ID}>
                      {" "}
                      {value.ROTATION_NAME}
                    </SelectOption>
                  );
                })}
              </Select>
            ) : (
              <div> {polyglot.t("No Data found")} </div>
            )}
            <FormErrors formErrors={props.ocrRotationErr} />
          </div>
        </div>
        <div className="horizontalLine">
          {" "}
          <hr />{" "}
        </div>
        <p className="checkBoxPText"> </p>
        <div className="languageSection">
          <div className="form-group">
            <label className="cmnLblStyl">{polyglot.t("Language")}</label>
            {tab5AnalysisLanguage.length > 0 ? (
              <Select
                aria-label={polyglot.t("Choose language")}
                placeholder={polyglot.t("Choose language")}
                showSearchOnToggle={true}
                multiple
                // value={tempSelectedLan}
                value={props.isViewProfile === true ? tempSelectedLan : null}
                onSelect={(e) => props.changed(props.ocrLanguageAttr, e, false)}
                className="dropDownInputWidth"
                disabled={props.isViewProfile === true ? true : false}
              >
                {tab5AnalysisLanguage.map((value, key) => {
                  return (
                    <SelectOption key={key + "_tab5_ana_lan"} value={value.ID}>
                      {value.language}
                    </SelectOption>
                  );
                })}
              </Select>
            ) : (
              <div> {polyglot.t("No Data found")} </div>
            )}
            <FormErrors formErrors={props.ocrLanguageErr} />
          </div>
        </div>

        <div className="horizontalLine">
          {" "}
          <hr />{" "}
        </div>
        <p className="checkBoxPText"> </p>
        <div className="form-group">
          <div className="secondary-input-container SecondaryInCon">
            <label className="cmnLblStyl" aria-label={polyglot.t("OCR Timeout value")}>
              {" "}
              {polyglot.t("OCR Timeout value")}{" "}
            </label>
            <Input
              className="dropDownInputWidth aInputTextCustom"
              role="presentation"
              aria-label={polyglot.t("OCR Timeout value")}
              name="ocrTimeoutValue"
              id="ocrTimeoutValInputId"
              onChange={(e) => props.changed(props.ocrTimeOutAttr, e, false)}
              value={props.selectedOcrTimeoutVal !== 0 ? props.selectedOcrTimeoutVal : ""}
              disabled={props.isViewProfile === true ? true : false}
            />
            <p className="subTextFont subTextMarginForCheckBox">
              {" "}
              {polyglot.t("Give OCR Time out value in minutes")}{" "}
            </p>
          </div>
        </div>

        <div className="form-group">
          <div key="isOcrCache">
            {props.ocrClearCacheData.length > 0 ? (
              props.ocrClearCacheData.map((value, key) => {
                return (
                  <div key={key + "_tab5_exc_type"}>
                    <Checkbox
                      key={value.id}
                      value={JSON.stringify(value.id)}
                      data-checkboxname={"ocrTabClearCacheCheckBoxList"}
                      name={value.name}
                      checked={value.isChecked}
                      onChange={(e) => props.changed(value.attr, e, true)}
                      disabled={props.isViewProfile === true ? true : false}
                      // checked={value.isChecked}
                      // checked={(props.isViewProfile === true)?value.isChecked:null}
                    >
                      {value.title}
                    </Checkbox>
                    <p className="checkBoxPText"> </p>
                  </div>
                );
              })
            ) : (
              <div> {polyglot.t("No Data found")} </div>
            )}
            {/* <Checkbox
                            key="1" 
                            value={JSON.stringify(1)} 
                            name="isOcrClearCache"
                            onChange={(e) => props.changed(props.ocrClearCacheAttr, e, true)}

                            disabled={(props.isViewProfile === true)?true:false}
                            // checked={props.isCheckedOcrCache}
                            checked={(props.isViewProfile === true)?props.isCheckedOcrCache:null}
                        > 
                            Clear OCR cache on completion
                        </Checkbox> */}
            <p className="checkBoxPText"> </p>
          </div>
        </div>
      </div>
    </div>
  );
};
