import React, { useContext, useEffect } from "react";

import NoAccess from "../../../Assets/Components/NoAccess";
import ProtectedRoute from "../../../Routes/ProtectedRoute";
import { getProjectID_Of_solution_module } from "../../../Services/Shared/Handlers";
import { hasViewPermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";
import "../../../Assets/Css/COMMON/Admin.css";
import Admin from "./Admin";

// RENDERS ON PATH /ri/admin
export default (props) => {
  // FETCH DATA FROM CONTEXT
  const { checkAndFetchRole, getCurrentUserPermissions } = useContext(DataContext);
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    const projectId = getProjectID_Of_solution_module();
    const screenName = "Admin";
    // TODO send Risk Intelligence
    checkAndFetchRole(screenName, "Connected Risk Intelligence", projectId);
  }, []);
  const currentUserPermissions = getCurrentUserPermissions();
  return (
    <>
      {/* RENDER PATH IF USER HAS PERMISSION TO VIEW */}
      {hasViewPermission(currentUserPermissions) && (
        <ProtectedRoute
          path="/"
          // component={Loadable({ loader: () => import('./Admin'), loading: LoadingIndicator })}
          component={Admin}
        />
      )}
      {/* SHOW 'NO ACCESS' IF USER DOES NOT HAVE PERMISSION TO VIEW */}
      {!hasViewPermission(currentUserPermissions) && <NoAccess />}
    </>
  );
};
