import { Pagination } from "antd";
import { Select, SelectOption } from "appkit-react";
import { startCase, upperFirst } from "lodash";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";

import DeleteInvoice from "./DeleteInvoice";
import EditInvoiceForm from "./EditInvoiceForm";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import { deleteFill } from "../../../Assets/Icons";
import editImg from "../../../Assets/Icons/pencil-outline.svg";
import download from "../../../Assets/Images/DFED/icon-download.svg";
import { PER_PAGE_COUNT } from "../../../Constants/Constant";
import AdminService from "../../../Services/QueenBase/Admin/AdminServices";
import { getDisplayDate } from "../../../Services/Shared/Handlers";
import { hasEditPermission, hasDeletePermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";

const PER_PAGE = PER_PAGE_COUNT;
// list of invoices
function ListOfInvoice(props) {
  const [pagelist, setPageList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchStr, setSearch] = useState("");
  // DATA FROM CONTEXT
  const { getCurrentUserPermissions, getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const currentUserPermissions = getCurrentUserPermissions();
  const [sortOrder, setSortOrder] = useState(""); // default sort by is done based on "Newest" records
  const [showDeleteClientInvoice, setshowDeleteClientInvoice] = useState(false);
  const [modeldata, setModeldata] = useState();
  const [InvoiceList, setInvoiceList] = useState([]);
  // GET CLIENT INVOICING DETAILS
  async function ClientInvoicingDetails() {
    try {
      setPageList([]);
      var res = await AdminService.get().getClientInvoicingDetails();
      setPageList(res.list);
      setInvoiceList(res.list);
      // default sort order is "newest"
      setSortOrder("Newest");
    } catch (err) {
      displayToastMessage(polyglot.t(err.message), "error");
    }
  }
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    ClientInvoicingDetails();
  }, []);
  // HANDLE PAGE CHANGE
  const handlePageChange = (change) => {
    setCurrentPage(change);
  };
  // SEARCH KEYS
  const searchKeys = (listObj) => {
    const keys = ["client_invoice_ref", "clientname", "name", "STATUS", "created_at"];
    const keyValues = keys.map((key) => {
      if (key === "created_at") {
        const isodate = listObj[key];
        const displayedDate = getDisplayDate(isodate);
        return displayedDate;
      } else {
        return listObj[key];
      }
    });
    return keyValues.toString();
  };
  // function to search
  const onSearch = (e) => {
    var list = InvoiceList.filter((r) => searchKeys(r).toLowerCase().includes(e.target.value.toLowerCase()));
    setPageList(list);
    setSearch(e.target.value);
    setCurrentPage(1);
  };

  // FILTER LIST OF INVOICES WHICH ARE TO BE DISPLAYED
  const filterDisplayList = (projects) => {
    let filteredProjects = [...projects];
    if (searchStr !== "") {
      filteredProjects = projects.filter((r) => {
        return searchKeys(r).toLowerCase().includes(searchStr.toLowerCase());
      });
    }
    return filteredProjects;
  };
  // get list of invoices of current page
  const getCurrentPageProjects = (filteredProjects) => {
    return filteredProjects.slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE);
  };
  // sort order
  useEffect(() => {
    if (sortOrder) {
      setCurrentPage(1);
      switch (sortOrder) {
        case "A-Z":
          setPageList([
            ...pagelist.sort((a, b) => {
              return a.client_invoice_ref.localeCompare(b.client_invoice_ref);
            }),
          ]);
          return;
        case "Z-A":
          const filteredList = pagelist.sort((a, b) => {
            return b.client_invoice_ref.localeCompare(a.client_invoice_ref);
          });
          setPageList([...filteredList]);
          return;
        case "Created On":
          setPageList([
            ...pagelist.sort((a, b) => {
              return moment(a.created_at).isAfter(moment(b.created_at)) ? 0 : -1;
            }),
          ]);

          return;
        case "Newest":
          setPageList([
            ...pagelist.sort((a, b) => {
              return moment(a.created_at).isBefore(moment(b.created_at)) ? 0 : -1;
            }),
          ]);
          return;
        default:
          return;
      }
    }
  }, [sortOrder]);
  // search for invoices
  const adminInvoiceSearch = () => {
    return (
      <div className="col-md-12 p-0 m-l-1-3 m-b-3">
        <div className="row m-l-0 m-r-0 filter-search-qb">
          <div className={`searchIcon`}>
            <input
              className="SearchBar search-input"
              value={searchStr}
              placeholder={polyglot.t("Search invoices")}
              onChange={onSearch}
              aria-label={polyglot.t("Search invoices")}
            />
          </div>
          <div className="search-sort">
            {getUserLanguage() === "ger" && (
              <Select
                id="filter-id-admin"
                placeholder={polyglot.t("Sort")}
                value={sortOrder}
                onSelect={setSortOrder}
              >
                <SelectOption key="A-Z" value="A-Z">
                  {polyglot.t("Invoice")}&nbsp;
                  <span className="appkiticon icon-arrow-up-outline"></span>
                </SelectOption>
                <SelectOption key="Z-A" value="Z-A">
                  {polyglot.t("Invoice")}&nbsp;
                  <span className="appkiticon icon-arrow-down-outline"></span>
                </SelectOption>
                <SelectOption key="Newest" value="Newest">
                  {polyglot.t("Newest")}
                </SelectOption>
                <SelectOption key="Created On" value="Created On">
                  {polyglot.t("Oldest")}
                </SelectOption>
              </Select>
            )}
            {getUserLanguage() === "en" && (
              <Select
                id="filter-id-admin"
                placeholder={polyglot.t("Sort")}
                value={sortOrder}
                onSelect={setSortOrder}
              >
                <SelectOption key="A-Z" value="A-Z">
                  {polyglot.t("Invoice")}&nbsp;
                  <span className="appkiticon icon-arrow-up-outline"></span>
                </SelectOption>
                <SelectOption key="Z-A" value="Z-A">
                  {polyglot.t("Invoice")}&nbsp;
                  <span className="appkiticon icon-arrow-down-outline"></span>
                </SelectOption>
                <SelectOption key="Newest" value="Newest">
                  {polyglot.t("Newest")}
                </SelectOption>
                <SelectOption key="Created On" value="Created On">
                  {polyglot.t("Oldest")}
                </SelectOption>
              </Select>
            )}
          </div>
        </div>
      </div>
    );
  };
  // DOWNLAOD CLIENT INVOICE
  const downloadClientInvoice = async (r) => {
    try {
      const downloadResponse = await AdminService.get().downloadClientInvoice(r.invoice_attachment); //invoice_attachment
      const buffer = Buffer.from(downloadResponse.file.buffer);
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", downloadResponse.file.fileName);
      document.body.appendChild(link);
      link.click();
      displayToastMessage(polyglot.t("Invoice has been downloded"), "success");
    } catch (error) {
      if (
        (error.message = "First argument must be a string, Buffer, ArrayBuffer, Array, or array-like object.")
      ) {
        displayToastMessage(`${polyglot.t("File not found")}!!`, "error");
      } else {
        displayToastMessage(error.message, "error");
      }
    }
    return "";
  };
  // CANCEL CLIENT INVOICE
  const cancelClientInvoice = (r) => {
    setModeldata(r);
    setshowDeleteClientInvoice((prev) => !prev);
  };
  // OPEN EDIT INVOICE FORM
  const onEdit = (r) => {
    const list = [...pagelist];
    list.map((res) => {
      if (res.client_invoice_ref === r.client_invoice_ref) {
        res.isedit = res.isedit === 1 ? 0 : 1;
      } else {
        res.isedit = 0;
      }
    });
    setPageList(list);
  };
  // CLOSE EDIT INVOICE FORM
  const onEditClose = (refId) => {
    setPageList(
      pagelist.map((r) => {
        if (r.client_invoice_ref === refId) {
          return {
            ...r,
            isedit: false,
          };
        }
        return r;
      }),
    );
  };
  // HEADING OF INVOICE LIST
  const defaultRow = () => {
    return (
      <div className="AdminPadding-invoice">
        <div className="row Admin-data-invoice m-0">
          <div className="col-md-12 p-0">
            <div className="Admin-tittle-stastic">
              <div className="row">
                <div className="col-md-2">
                  <strong> {polyglot.t("Invoice ID")}</strong>
                </div>
                <div className="col-md-2">
                  <strong> {polyglot.t("Client")} </strong>
                </div>
                <div className="col-md-2">
                  <strong> {polyglot.t("Created on")} </strong>
                </div>
                <div className="col-md-2">
                  <strong> {polyglot.t("Project")}</strong>
                </div>
                <div className="col-md-2">
                  <strong>{polyglot.t("Status")}</strong>
                </div>
                <div className="col-md-2 p-0 pl-2">
                  <strong> {polyglot.t("Action")}</strong>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <>
      <DeleteInvoice
        modeldata={modeldata}
        show={showDeleteClientInvoice}
        cancelClientInvoice={cancelClientInvoice}
        fetchClientsAfterDelete={ClientInvoicingDetails}
      />
      <div className="col-md-9 p-0">
        <h1 className="main_title_qb m-l-1-3 m-b-18">{polyglot.t("Invoice List")} </h1>
        {adminInvoiceSearch()}
        {defaultRow()}
        {pagelist && pagelist.length > 0 ? (
          getCurrentPageProjects(filterDisplayList(pagelist)).map((r, index) => {
            return r.isedit ? (
              <EditInvoiceForm
                key={"edit_invoice_" + index}
                r={r}
                onClose={onEditClose}
                fetchClientsAfterEdit={ClientInvoicingDetails}
                onEdit={onEdit}
              />
            ) : (
              <div className="AdminPadding-invoice" key={index}>
                <div className="row Admin-data-invoice m-0">
                  <div className="col-md-12 p-0">
                    <div className=" Admin-tittle-stastic">
                      <div className="row">
                        <div className="col-md-2">{r.client_invoice_ref}</div>
                        <div className="col-md-2">{upperFirst(r.clientid)}</div>
                        <div className="col-md-2">{getDisplayDate(r.created_at)}</div>
                        <div className="col-md-2">{startCase(r.name)}</div>
                        <div className="col-md-2">{upperFirst(r.STATUS)}</div>
                        <div className="col-md-2">
                          <div className="row">
                            {hasEditPermission(currentUserPermissions) && (
                              <ButtonIcon
                                iconImg={editImg}
                                imgWidth={16}
                                alt="edit"
                                onClick={() => onEdit(r)}
                                title={polyglot.t("Edit invoice")}
                                ariaLabel={polyglot.t("Edit invoice")}
                              />
                            )}
                            <ButtonIcon
                              iconImg={download}
                              alt="download"
                              onClick={() => downloadClientInvoice(r)}
                              title={polyglot.t("Download invoice")}
                              ariaLabel={polyglot.t("Download invoice")}
                            />
                            {hasDeletePermission(currentUserPermissions) && (
                              <ButtonIcon
                                iconName={deleteFill}
                                alt="delete"
                                onClick={() => cancelClientInvoice(r, index)}
                                title={polyglot.t("Delete invoice")}
                                ariaLabel={polyglot.t("Delete invoice")}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <div className="AdminPadding-invoice">
            <div className="row Admin-data-invoice m-0">
              <div className="col-md-12 p-0">
                <div className="Admin-tittle-stastic-notfound">{polyglot.t("No Invoice Found")}</div>
              </div>
            </div>
          </div>
        )}
        <div className="AdminPadding-invoice-pagnation mt-2">
          <div className="AdminPadding-invoice-pagnation-client">
            {pagelist.length > 5 && (
              <Pagination
                current={currentPage}
                size="small"
                pageSize={PER_PAGE}
                total={pagelist.length}
                onChange={handlePageChange}
                showSizeChanger={false}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ListOfInvoice;
