import { Accordions, AccordionItem, AccordionItemHeader, AccordionItemBody } from "appkit-react";
import React, { useState } from "react";

import { polyglotLoader, getCurrentLanguage } from "../../../Services/Shared/Translate";
import "./Keywords.css";

const DownloadKeyWord = () => {
  var polyglot = polyglotLoader();
  const contentText1 = (
    <div className=" col-md-12 p-0">
      <p className="key-txt-title mt-0">{polyglot.t("Single Character Input:?")}</p>
      <p className="key-txt-search m-0">
        {polyglot.t(
          "The single character wildcard search looks for terms that match with the single character replaced. For example, to search for 'text' or 'test' you can use the search",
        )}
        {":"}
        <p className="dark-txt">"{polyglot.t("te?t")}"</p>
      </p>
      <p className="key-txt-title mt-0">{polyglot.t("Multiple Character Input:*")}</p>
      <p className="key-txt-search m-0">
        {polyglot.t(
          "Multiple character wildcard searches look for 0 or more characters. For example,to search for test,tests or tester, you can use the search",
        )}
        {":"}
        <p className="dark-txt">"{polyglot.t("test*")}"</p>
      </p>
    </div>
  );
  const contentText2 = (
    <div className=" col-md-12 p-0">
      <p className="key-txt-title mt-0">{polyglot.t("Input: ~")}</p>
      <span className="key-txt-search m-0">
        {polyglot.t(
          "To do a fuzzy search use the tilde symbol '~' at the end of single word term. For example",
        )}
      </span>
      {getCurrentLanguage() === "ger" ? null : (
        <span className="m-0">
          {" " + polyglot.t("to search for a term similar to “roam”, use:")}
          <p className="dark-txt">"{polyglot.t("roam~")}"</p>{" "}
          {polyglot.t("This search may return results containing “roams” or “foam”.")}
        </span>
      )}
    </div>
  );
  const contentText3 = (
    <div className=" col-md-12 p-0">
      <p className="key-txt-search ">
        <span className="key-txt-title ">{polyglot.t("AND:")}</span>
        {" " + polyglot.t("To search for documents that contain 'boats' and 'cars' use the following query:")}
        <p className="dark-txt">
          "{polyglot.t("boats")}" AND "{polyglot.t("cars")}"
        </p>
      </p>
      <p className="key-txt-search">
        <span className="key-txt-title ">{polyglot.t("OR:")}</span>
        {" " +
          polyglot.t(
            "To search for documents that contain 'boats' or 'cars' use either of the following queries:",
          )}
        <p className="dark-txt">
          "{polyglot.t("boats")}" OR "{polyglot.t("cars")}"
        </p>
      </p>
      <p className="key-txt-search ">
        <span className="key-txt-title ">{polyglot.t("NOT:")}</span>
        {" " + polyglot.t("To search for documents that contain 'boats' not 'cars' use the following query:")}
        <p className="dark-txt">
          "{polyglot.t("boats")}" NOT "{polyglot.t("cars")}"
        </p>
      </p>
      <p className="key-txt-search ">
        <span className="key-txt-title ">{"+:"}</span>
        {" " +
          polyglot.t(
            "To search for documents that contain 'boats' and may contain 'cars' use the following query:",
          )}
        <p className="dark-txt">
          "{polyglot.t("boats")}+{polyglot.t("cars")}"
        </p>
      </p>
      <p className="key-txt-search">
        <span className="key-txt-title ">{"-:"}</span>
        {" " +
          polyglot.t("To search for documents that contain 'boats' but not 'cars' use the following query:")}
        <p className="dark-txt">
          "{polyglot.t("boats")}-{polyglot.t("cars")}"
        </p>
      </p>
    </div>
  );
  const contentText4 = (
    <div className=" col-md-12 p-0">
      <p className="key-txt-search ">
        {polyglot.t("The following operators are accepted: ")}
        <p className="dark-txt">
          {polyglot.t("W/n (Within N words), PRE/n, W/p (Within Paragraph), W/s (Within Sentence)")}
        </p>
      </p>
      <p className="key-txt-search">
        {polyglot.t(
          "To search for documents that contain ships and within 3 words ferries use the following query: ",
        )}
        <p className="dark-txt">
          "{polyglot.t("ships")}" W/3 "{polyglot.t("ferries")}"
        </p>
      </p>
      <p className="key-txt-search ">
        {polyglot.t(
          "To search for documents that contain bicycles precedes by not more than 2 words vehicles use the following query: ",
        )}
        <p className="dark-txt">
          "{polyglot.t("bicycles")}" PRE/2 "{polyglot.t("vehicles")}"
        </p>
      </p>
    </div>
  );
  const contentText5 = (
    <div className=" col-md-12 p-0">
      <p className="key-txt-search ">
        {polyglot.t("Apostrophe is acceptable within the whole word, for example")}
        <p className="dark-txt">
          "{polyglot.t("can't")}" OR "{polyglot.t("doesn't")}"
        </p>
      </p>
    </div>
  );
  const [selectedAccordianTab, setSelectedKeywordTab] = useState([0]);
  const openAccordation = (e) => {
    if (selectedAccordianTab[0] === e) {
      setSelectedKeywordTab([]);
    } else {
      setSelectedKeywordTab([e]);
    }
  };
  return (
    <div>
      <div className=" col-md-12">
        <h5 className="key-search">{polyglot.t("Keyword search tips")}</h5>
        <p className="key-txt-search">
          {polyglot.t(
            "Our search engine provides advanced search functionality such as proximity, stemming, wildcards and fuzzy searches. It also supports the use of Boolean operators  (AND, OR, NOT).",
          )}
        </p>
      </div>
      <div className="col-md-12 accordianHeaderKeyword">
        <Accordions onChange={openAccordation} activeItem={selectedAccordianTab} multiOpen={false}>
          <AccordionItem itemId={0}>
            <AccordionItemHeader className="accordionHeader" title={polyglot.t("Wildcards")} />
            <AccordionItemBody>{contentText1}</AccordionItemBody>
          </AccordionItem>
          <AccordionItem itemId={1} multiOpen={true}>
            <AccordionItemHeader className="accordionHeader" title={polyglot.t("Fuzzy search")} />
            <AccordionItemBody>{contentText2}</AccordionItemBody>
          </AccordionItem>
          <AccordionItem itemId={2}>
            <AccordionItemHeader className="accordionHeader" title={polyglot.t("Boolean operators")} />
            <AccordionItemBody>{contentText3}</AccordionItemBody>
          </AccordionItem>
          <AccordionItem itemId={3}>
            <AccordionItemHeader className="accordionHeader" title={polyglot.t("Search connectors")} />
            <AccordionItemBody>{contentText4}</AccordionItemBody>
          </AccordionItem>
          <AccordionItem itemId={4}>
            <AccordionItemHeader className="accordionHeader" title={polyglot.t("Apostrophe")} />
            <AccordionItemBody>{contentText5}</AccordionItemBody>
          </AccordionItem>
        </Accordions>
      </div>
    </div>
  );
};
export default DownloadKeyWord;
