import HttpClient from "../Shared/HttpClient";
import { getUserIDFromSession } from "../Shared/Authenticate";

class AdminService {
  constructor() {
    this.client = new HttpClient();
  }
  // API TO FETCH RI THEMES
  fetchRIThemes = async () => {
    const res = await this.client.riGet(`/fetchRIThemes`);
    return res;
  };
  // API TO ADD REQUEST
  createRIRequest = async (data) => {
    // ADDING SESSION USERID
    data.createdBy = getUserIDFromSession();
    const res = await this.client.ripost(`/createRIRequest`, data);
    return res;
  };

  createRICleansedDataset = async (data) => {
    const res = await this.client.ripost(`/createRICleansedDataset`, data);
    return res;
  };

  updateRIRequestStatus = async (data) => {
    const res = await this.client.ripost(`/updateRIRequestStatus`, data);
    return res;
  };

  getClientUserID = async () => {
    const res = await this.client.riGet(`/getClientUserID`);
    return res;
  };

  // API TO ADD REQUEST
  getAllRequests = async () => {
    const res = await this.client.riGet(`/fetchAllRequests/${sessionStorage.getItem("selectedProject")}`);
    return res;
  };
}

// Enable Singleton behavior on this class
AdminService.__instance = null;
AdminService.get = () => {
  if (!AdminService.__instance) {
    AdminService.__instance = new AdminService();
  }
  return AdminService.__instance;
};

export default AdminService;
