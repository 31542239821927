import React, { Component } from "react";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import "../../../Assets/Css/DFED/Admin.css";
import AnalysisGrid from "./Views/AnalysisGrid";
import UpdateProcessingPage from "./Views/UpdateProcessing";
import ReviewGrid from "./Views/ReviewGrid";
import ProductionGrid from "./Views/ProductionGrid";
import { getAnslysisKeywordDetails } from "../../../Services/DFED/AdminService";
import NoAccess from "../../../Assets/Components/NoAccess";
import ButtonGroupCustom from "../../../Assets/Components/ButtonGroupCustom/ButtonGroupCustom";
// DFED ADMIN MAIN CLASS RESPONSIBLE FOR RENDERING PROCESSING, ANALYSIS, REVIEW,PRODUCTION SCREEN OF ADMIN================
class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      analysisGridData: [],
      visible: false,
      mode: "top",
      selectedProjectId: props.location.state.selectedProjectId,
      showProcessTab: false,
      showAnalysisTab: false,
      showReviewTab: false,
      showProductionTab: false,
      defaultAdminComponent: "adminProcessTab t-l-b-r-4 b-l-b-r-4",
      isViewPermission: true,
      selectedTab: "Process",
    };
    this.showAdminForm = this.showAdminForm.bind(this);
  }
  static contextType = DataContext;
  polyglot = polyglotLoader();
  // LIFE CYCLE METHOD FOR GETTING ALL ANALYSIS PROCESSING DATA================
  componentDidMount() {
    this.dommanipulation(this);
    //this.dataForAnalysisGrid(this);
    this.setState({
      showProcessTab: true,
      defaultAdminComponent: "adminProcessTab t-l-b-r-4 b-l-b-r-4",
    });
  }
  // CREATING ANALYSIS DATA FOR TABLE===================
  dataForAnalysisGrid = async () => {
    let isInterruptProcess = false;
    if (isInterruptProcess === false) {
      await getAnslysisKeywordDetails(parseInt(this.state.selectedProjectId))
        .then((anaKeyData) => {
          if (anaKeyData && anaKeyData.data) {
            if (anaKeyData.data && anaKeyData.data.length > 0) {
              this.setState({ analysisGridData: anaKeyData.data });
            } else {
              //   alert("Error featching analysis details.")
            }
          }
        })
        .catch((err) => {
          if (err && err.status === 403) {
            this.setState({
              isViewPermission: false,
            });
          }
        });
      // alert("Error while sending request.")
    }
  };
  handleModeChange = (e) => {
    const mode = e.target.value;
    this.setState({ mode });
  };
  dommanipulation = () => {};
  showAdminForm(value, id) {
    let selectedOption = value;
    if (selectedOption === "Process") {
      this.setState({
        showProcessTab: true,
        showAnalysisTab: false,
        showReviewTab: false,
        showProductionTab: false,
        selectedTab: value,
      });
    }
    if (selectedOption === "Analysis") {
      this.setState({
        showAnalysisTab: true,
        showProcessTab: false,
        showReviewTab: false,
        showProductionTab: false,
        defaultAdminComponent: "",
        selectedTab: value,
      });
    }
    if (selectedOption === "Review") {
      this.setState({
        showReviewTab: true,
        showAnalysisTab: false,
        showProcessTab: false,
        showProductionTab: false,
        defaultAdminComponent: "",
        selectedTab: value,
      });
    }
    if (selectedOption === "Production") {
      this.setState({
        showProductionTab: true,
        showReviewTab: false,
        showAnalysisTab: false,
        showProcessTab: false,
        defaultAdminComponent: "",
        selectedTab: value,
      });
    }
  }
  // RENDER METHOD FOR ADMIN SCREEN. IT WILL RENDER ALL 4 TAB DATA============================
  render() {
    var polyglot = polyglotLoader();
    var adminHome = (
      <div className="adminContainer">
        <div className="row">
          <div className="col-md-12 allAdminTabs btn-group-limit-click-area">
            <ButtonGroupCustom
              value={this.state.selectedTab}
              onChange={(e, v) => {
                this.showAdminForm(v);
              }}
              tabs={[
                { value: "Process", name: "Process" },
                { value: "Analysis", name: "Analysis" },
                { value: "Review", name: "Review" },
                { value: "Production", name: "Production" },
              ]}
            />
            {this.state.showProcessTab && (
              <div>
                <UpdateProcessingPage
                  projectid={this.props.location.state.selectedProjectId}
                ></UpdateProcessingPage>
              </div>
            )}
            {this.state.showAnalysisTab && (
              <div>
                <AnalysisGrid selectedProjectId={this.state.selectedProjectId} selectedProjectName={sessionStorage.getItem("selectedProjectName")}></AnalysisGrid>
              </div>
            )}
            {this.state.showReviewTab && (
              <div>
                <ReviewGrid
                  projectid={this.props.location.state.selectedProjectId}
                  projectname={sessionStorage.getItem("selectedProjectName")}
                ></ReviewGrid>
              </div>
            )}
            {this.state.showProductionTab && (
              <div>
                <ProductionGrid
                  projectid={this.props.location.state.selectedProjectId}
                  projectname={sessionStorage.getItem("selectedProjectName")}
                ></ProductionGrid>
              </div>
            )}
          </div>
        </div>
      </div>
    );

    if (this.state.isViewPermission === false) {
      return <NoAccess />;
    } else {
      return adminHome;
    }
  }
}
export default Admin;
