import { Button } from "appkit-react";
import React from "react";

import CustomModal from "../../../Assets/Components/Modal";
import AdminService from "../../../Services/QueenBase/Admin/AdminServices";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";
// THIS IS THE COMPONENT WHICH RENDERS TO DELETE INVOICE
function DeleteInvoice({ show, cancelClientInvoice, history, modeldata, fetchClientsAfterDelete }) {
  var polyglot = polyglotLoader();
  // DELETE INVOICE
  const deleteInvoice = async () => {
    var res = await AdminService.get().DeleteClientInvoice(modeldata);
    if (res.CODE) {
      displayToastMessage(res.MSG, "success");
      // history.push(path.VIEW_ADMIN_INVOICE);
      cancelClientInvoice();
      fetchClientsAfterDelete();
    } else {
      displayToastMessage(res.MSG, "error");
    }
  };
  return (
    <>
      <CustomModal
        show={show}
        onCancel={cancelClientInvoice}
        title={polyglot.t("Deleting Invoice")}
        Footer={[
          <Button size="lg" className="primary_button" onClick={() => deleteInvoice()} key={"delete_invoice"}>
            {polyglot.t("Yes, delete invoice")}
          </Button>,
        ]}
      >
        <>
          <div className="row">
            <div className="col-md-12 break-word">
              <p>
                {polyglot.t("Do you really want to delete the following Invoice")}:&nbsp;
                <b>{modeldata && modeldata.client_invoice_ref}</b> ?
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 break-word">
              {polyglot.t(
                "All data had added to the invoice section  will also eliminated and will no longer part of the system",
              )}
            </div>
          </div>
        </>
      </CustomModal>
    </>
  );
}

export default DeleteInvoice;
