import * as Yup from "yup";

import {
  MAX_ASSESSMENT_NAME_LENGTH,
  MAX_CATEGORY_NAME_LENGTH,
  MAX_DESCRIPTION_LENGTH,
} from "../Constants/Constant";
import {
  FIELD_LENGTH_REGEX,
  FIELD_REGEX,
  FIELD_REGEX_NO_AMBERSAND,
  QUESTIONS_REGEX,
} from "../Constants/regex";
import { polyglotLoader as grcPolyglotLoader } from "../Services/Shared/TranslateGRC";
import { polyglotLoader } from "../Services/Shared/Translate";

const polyglotGrc = grcPolyglotLoader();
const polyglot = polyglotLoader();

export const TextFieldLengthValidation = (value) => {
  if (value.length > 40) {
    return false;
  } else {
    return true;
  }
};

export const TextFieldAlphaNumericValidation = (value) => {
  var regExp = /^[A-Za-z]+$/;
  if (!value.match(regExp)) {
    return false;
  } else {
    return true;
  }
};
export const validationType = {
  success: 0,
  lengthError: 1,
  charError: 2,
};
export const TextFieldLengthRegexValidation_NOTUSED = (value) => {
  const regexFieldNameStringPattern = /^[A-Za-z0-9ßÜüÖöäÄëËïÏéèç _.\-@;,()\[\]]+$/;
  if (value.length > 4) {
    return validationType.lengthError;
  }

  if (value.match(regexFieldNameStringPattern)) {
    return validationType.success;
  } else {
    return validationType.charError;
  }
};
export const TextFieldLengthRegexValidation = (value) => {
  const regexFieldNameStringPattern = /^[A-Za-z0-9ßÜüÖöäÄëËïÏéèç _.\-@;,()\[\]]+$/;
  if (value.match(regexFieldNameStringPattern)) {
    return true;
  } else {
    return false;
  }
};

export const TextFieldLengthRegexFieldValidation = (value) => {
  const regexFieldStringPattern = /^[A-Za-z0-9ßÜüÖöäÄëËïÏéèç _.\-@;,()'&\[\]]+$/;
  if (value.match(regexFieldStringPattern)) {
    return true;
  } else {
    return false;
  }
};

export const specialCharactersFieldRegexSchema = Yup.string().matches(
  FIELD_REGEX,
  polyglotGrc.t("fieldValidation.specialCharacters", { exceptions: "_.-@;&,()[] ßÜüÖöäÄ ëËïÏ éèç" }),
);

export const questionnaireValidationSchema = Yup.object().shape({
  question_text: Yup.string()
    .matches(
      QUESTIONS_REGEX,
      polyglotGrc.t("fieldValidation.specialCharacters", { exceptions: "_.-@;&,()[]/? ßÜüÖöäÄ ëËïÏ éèç" }),
    )
    .trim()
    .max(
      MAX_DESCRIPTION_LENGTH,
      polyglotGrc.t("fieldValidation.maxLength", { length: MAX_DESCRIPTION_LENGTH }),
    )
    .required(polyglotGrc.t("fieldValidation.notEmpty")),
  assessmentid: Yup.number().required(
    polyglotGrc.t("fieldValidation.isRequired", { field: "Assessment type" }),
  ),
  answertypeid: Yup.number().required(polyglotGrc.t("fieldValidation.isRequired", { field: "Answer type" })),
  scoring_top: Yup.number().required(polyglotGrc.t("fieldValidation.isRequired", { field: "Scoring" })),
});

export const assessmentValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(
      MAX_ASSESSMENT_NAME_LENGTH,
      polyglotGrc.t("fieldValidation.maxLength", { length: MAX_ASSESSMENT_NAME_LENGTH }),
    )
    .matches(
      FIELD_REGEX_NO_AMBERSAND,
      polyglotGrc.t("fieldValidation.specialCharacters", { exceptions: "_.-@;&,()[] ßÜüÖöäÄ ëËïÏ éèç" }),
    )
    .required(`* ${polyglotGrc.t("fieldValidation.isRequired", { field: "Name" })}`),
  description: specialCharactersFieldRegexSchema
    .trim()
    .max(
      MAX_DESCRIPTION_LENGTH,
      polyglotGrc.t("fieldValidation.maxLength", { length: MAX_DESCRIPTION_LENGTH }),
    )
    .required(polyglotGrc.t("fieldValidation.isRequired", { field: "Description" })),
});

export const categoryValidationSchema = Yup.object().shape({
  name: Yup.string()
    .trim()
    .max(
      MAX_CATEGORY_NAME_LENGTH,
      polyglotGrc.t("fieldValidation.maxLength", { length: MAX_CATEGORY_NAME_LENGTH }),
    )
    .matches(
      FIELD_LENGTH_REGEX,
      polyglotGrc.t("fieldValidation.specialCharacters", { exceptions: "_.-@;&,()[] ßÜüÖöäÄ ëËïÏ éèç" }),
    )
    .required(`* ${polyglotGrc.t("fieldValidation.isRequired", { field: "Name" })}`),
  description: specialCharactersFieldRegexSchema
    .required(polyglotGrc.t("fieldValidation.isRequired", { field: "Description" }))
    .trim(),
});

export const userProfileValidationSchema = Yup.object().shape({
  prefixID: Yup.string().required("* Salutation is required"),
  firstname: specialCharactersFieldRegexSchema.required(polyglot.t("First Name is required")),
  lastname: specialCharactersFieldRegexSchema.required(polyglot.t("Last Name is required")),
  email: Yup.string().email("Invalid email").required(polyglot.t("Email is Required")),
  positionid: Yup.number().min(1, polyglot.t("Position required")),
  countryid: Yup.number().min(1, polyglot.t("Country required")),
  entity: specialCharactersFieldRegexSchema.required(polyglot.t("Organisation is required")),
  department: specialCharactersFieldRegexSchema.required(polyglot.t("Department is required")),
  phone: Yup.number().required(polyglot.t("Phone Number is required")),
});
