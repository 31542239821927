/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/display-name */
import { DatePicker, ConfigProvider } from "antd";
import gr from "antd/lib/locale-provider/de_DE";
import { Input, Select, SelectGroupTitle, SelectOption, TextArea } from "appkit-react";
import moment from "moment";
import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useImperativeHandle, useState, useContext } from "react";

import calendar from "../../../Assets/Icons/calendar.svg";
import ProjectService from "../../../Services/QueenBase/Projects/ProjectService";

import "moment/locale/de";
import { getSelectedTextForMultiSelectBox } from "../../../Services/Shared/Handlers";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";

// STEP 1 OF PROJECT FORM
const ProjectForm = forwardRef((props, ref) => {
  const [projectNameError, setProjectNameError] = useState(null);
  const [maximumDeadlineDate, setMaxDeadlineDate] = useState(null);
  const [minimumDeadlineDate, setMinDeadlineDate] = useState(null);
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader();
  // IMPERATIVE HANDLER TO HANDLE FUNCTIONS OF THIS COMPONENT
  useImperativeHandle(ref, () => ({}));

  // GET PROJECT FORM DETAILS
  const getProjectFormDetails = async () => {
    var res = await ProjectService.get().getProjectFormDetails();
    if (res.CODE === 1) {
      props.setFetchedData("users", res.userList);
    } else {
      displayToastMessage(res.MSG, "error");
    }
  };

  // GET MAXIMUM AND MINIMUM DATES FROM THE LIST OF MILESTONES ASSIGNED THE PROJECT
  const getLastMilestoneDeadlineDate = () => {
    const solutionMilestones = props.solutionMilestones.flatMap(({ milestoneList }) => milestoneList);
    if (solutionMilestones.length) {
      const sortedMilestoneDeadlineDates = solutionMilestones
        .map(({ deadline }) => moment(deadline))
        .sort((firstDate, secondDate) => firstDate.valueOf() - secondDate.valueOf());
      const minDeadLineDate = sortedMilestoneDeadlineDates.at(0);
      const maxDeadLineDate = sortedMilestoneDeadlineDates.at(-1);
      setMaxDeadlineDate(maxDeadLineDate);
      setMinDeadlineDate(minDeadLineDate);
    }
  };

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    // if user list is already present, then do not call getProjoectFormDetails
    if (props.users) {
      if (props.users.length === 0) {
        getProjectFormDetails();
      }
    } else {
      getProjectFormDetails();
    }
    getLastMilestoneDeadlineDate();
  }, []);

  // RESTRICT START DATES AND DISPLAY ON DATE PICKER
  const restrictStartDates = (current) => {
    const currentDate = moment(current);
    if (minimumDeadlineDate) {
      return currentDate.isAfter(minimumDeadlineDate, "day");
    }

    if (values.enddate) {
      const projectEndDate = moment(values.enddate);
      return currentDate.isSameOrAfter(projectEndDate, "day");
    }

    return false;
  };

  // RESTRICT END DATES AND DISPLAY ON DATE PICKER
  const restrictEndDates = (current) => {
    const currentDate = moment(current);
    if (maximumDeadlineDate) {
      return currentDate.isBefore(maximumDeadlineDate, "day");
    }

    if (values.startdate) {
      const projectStartDate = moment(values.startdate);
      return currentDate.isSameOrBefore(projectStartDate, "day");
    }

    return false;
  };
  // LOADING SELECT BOX FOR USERS
  const loadingUsers = (title) => (
    <Select placeholder={`${title} *`} multiple showSearchOnToggle={true}>
      <SelectGroupTitle>{title} List </SelectGroupTitle>
      <SelectOption>{polyglot.t("Loading users...")}</SelectOption>
    </Select>
  );

  //FORMIK VALUES FROM PARENT COMPONENT
  const { values, handleChange, errors, touched, users } = props;
  // HANDLE CHANGE IN PROJECT NAME
  const handleProjectNameChange = (value) => {
    handleChange("name")({ target: { value } });
  };
  return (
    <div>
      <div className="row m-0">
        <div className="col-md-8 create-project-form-fields p-0">
          <div className="col-md-12">
            <Input
              inputBoxSize="sm"
              placeholder={polyglot.t("Project name *")}
              value={values.name}
              onChange={handleProjectNameChange}
              aria-label={polyglot.t("create a project name")}
            />
            {errors.name && touched.name ? <div className="error">{polyglot.t(errors.name)}</div> : null}
            {errors.invalidProjectName && touched.invalidProjectName && (
              <div className="error">{errors.invalidProjectName}</div>
            )}
            {projectNameError && <div className="error">{projectNameError}</div>}
            <br />
          </div>
          <div className="Subrow col-md-12">
            <div className="col-md-6 datepicker">
              {getUserLanguage() === "ger" ? (
                <ConfigProvider locale={gr}>
                  <DatePicker
                    disabledDate={restrictStartDates}
                    placeholder={polyglot.t("Start date *")}
                    value={values.startdate}
                    // defaultPickerValue={moment}
                    onChange={(value) => handleChange("startdate")({ target: { value } })}
                    format={"DD.MM.YYYY"}
                    className="antd-datepicker-custom-styles"
                    suffixIcon={<img src={calendar} width={14} height={14} />}
                  />
                </ConfigProvider>
              ) : (
                <DatePicker
                  disabledDate={restrictStartDates}
                  placeholder={polyglot.t("Start date *")}
                  value={values.startdate}
                  // defaultPickerValue={moment}
                  onChange={(value) => handleChange("startdate")({ target: { value } })}
                  format={"DD.MM.YYYY"}
                  className="antd-datepicker-custom-styles"
                  suffixIcon={<img src={calendar} width={14} height={14} />}
                />
              )}
              {errors.startdate && touched.startdate ? (
                <div className="error">{polyglot.t(errors.startdate)}</div>
              ) : null}
              <br />
            </div>

            <div className="col-md-6 datepicker-two">
              {getUserLanguage() === "ger" ? (
                <ConfigProvider locale={gr}>
                  <DatePicker
                    disabledDate={restrictStartDates}
                    placeholder={polyglot.t("End date*")}
                    value={values.enddate}
                    // defaultPickerValue={moment}
                    onChange={(value) => handleChange("enddate")({ target: { value } })}
                    format={"DD.MM.YYYY"}
                    className="antd-datepicker-custom-styles"
                    suffixIcon={<img src={calendar} width={14} height={14} />}
                  />
                </ConfigProvider>
              ) : (
                <DatePicker
                  disabledDate={restrictEndDates}
                  placeholder={polyglot.t("End date*")}
                  value={values.enddate}
                  // defaultPickerValue={moment}
                  onChange={(value) => handleChange("enddate")({ target: { value } })}
                  format={"DD.MM.YYYY"}
                  className="antd-datepicker-custom-styles"
                  suffixIcon={<img src={calendar} width={14} height={14} />}
                />
              )}
              {errors.enddate && touched.enddate ? (
                <div className="error">{polyglot.t(errors.enddate)}</div>
              ) : null}
              <br />
            </div>
          </div>
          <div className="col-md-12">
            {users.length > 0 && values.leadList.length === 0 && (
              <Select
                placeholder={polyglot.t("Project lead *")}
                multiple
                value={values.leadList}
                showSearchOnToggle={true}
                onSelect={(value) => handleChange("leadList")({ target: { value } })}
              >
                <SelectGroupTitle>{polyglot.t("Project lead")}</SelectGroupTitle>
                {users.map((user, index) => {
                  return (
                    <SelectOption key={index} value={user.userid}>
                      {user.firstname} {user.lastname}
                    </SelectOption>
                  );
                })}
              </Select>
            )}
            {getUserLanguage() === "ger" && (
              <>
                {users.length > 0 && values.leadList.length > 0 && (
                  <Select
                    placeholder={polyglot.t("Project lead *")}
                    multiple
                    value={values.leadList}
                    showSearchOnToggle={true}
                    onSelect={(value) => handleChange("leadList")({ target: { value } })}
                    customSelectAllStringOnToggle={polyglot.t("All selected")}
                    customSelectedCountStringGenerator={(activeItemsLength) =>
                      getSelectedTextForMultiSelectBox(activeItemsLength, polyglot)
                    }
                  >
                    <SelectGroupTitle>{polyglot.t("Project lead")}</SelectGroupTitle>
                    {users.map((user, index) => {
                      return (
                        <SelectOption key={index} value={user.userid}>
                          {user.firstname} {user.lastname}
                        </SelectOption>
                      );
                    })}
                  </Select>
                )}
              </>
            )}
            {getUserLanguage() === "en" && (
              <>
                {users.length > 0 && values.leadList.length > 0 && (
                  <Select
                    placeholder={polyglot.t("Project lead *")}
                    multiple
                    value={values.leadList}
                    showSearchOnToggle={true}
                    onSelect={(value) => handleChange("leadList")({ target: { value } })}
                  >
                    <SelectGroupTitle>{polyglot.t("Project lead")}</SelectGroupTitle>
                    {users.map((user, index) => {
                      return (
                        <SelectOption key={index} value={user.userid}>
                          {user.firstname} {user.lastname}
                        </SelectOption>
                      );
                    })}
                  </Select>
                )}
              </>
            )}
            {users.length === 0 && loadingUsers("Project lead")}
            {errors.leadList && touched.leadList ? (
              <div className="error">{polyglot.t(errors.leadList)}</div>
            ) : (
              null && errors.leadList && <span className="error">{polyglot.t(errors.leadList)}</span>
            )}
            {users.length !== 0 && errors.currentUserNotPresent && (
              <div className="error">{polyglot.t(errors.currentUserNotPresent)}</div>
            )}
            <br />
          </div>
        </div>
        {/* <div className="ml-3"></div> */}
        <div className="col-md-4 create-project-notes">
          <div className="col-md-12">
            <TextArea
              placeholder={polyglot.t("Project Description")}
              className="Notes proj-notes-txt-area"
              value={values.notes}
              onChange={(value) => handleChange("notes")({ target: { value } })}
              aria-label="enter project description"
            />
            {errors.notes ? <div className="error">{polyglot.t(errors.notes)}</div> : null}
          </div>
        </div>
      </div>
    </div>
  );
});

ProjectForm.propTypes = {
  history: PropTypes.object,
  values: PropTypes.object,
  handleChange: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  users: PropTypes.array,
  setFetchedData: PropTypes.func,
};

export default ProjectForm;
