import React, { Component } from "react";
import * as d3 from "d3";
import $ from "jquery";
import "../../../Components/DFED/Review/BarChart.css";
import { getReviewBarchart } from "../../../../src/Services/DFED/ReviewService";
class ProductionBarChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      filterYear: "",
      ReviewBarchartDetails: [],
      selectedProjectId: props.location ? props.location.state.selectedProjectId : props.selectedProjectId,
      selectedProjectName: sessionStorage.getItem("selectedProjectName"),
    };
  }
  componentDidMount() {
    this.getReviewBarchartData();
    this.yearChange();
  }
  //get file type names
  async getReviewBarchartData() {
    let ReviewBarchartData = await getReviewBarchart(this.state.selectedProjectId);
    if (ReviewBarchartData && ReviewBarchartData.data) {
      let tempData = JSON.parse(JSON.stringify(ReviewBarchartData.data));
      function groupBy(array, f) {
        var groups = {};
        array.forEach(function (o) {
          var group = JSON.stringify(f(o));
          groups[group] = groups[group] || [];
          groups[group].push(o);
        });
        return Object.keys(groups).map(function (group) {
          return groups[group];
        });
      }
      var result = groupBy(tempData, function (item) {
        return [item.years, item.months];
      });
      var finalData = [];
      for (var i = 0; i < result.length; i++) {
        var temp = {
          date: result[i][0].REVIEW_UPLOADED_ON,
          Not_Completed: 0,
          Completed: 0,
          months: result[i][0].months,
          years: result[i][0].years,
        };
        finalData.push(temp);
      }
      for (let i = 0; i < result.length; i++) {
        innerLoopData(result[i]);
      }
      function innerLoopData(row) {
        for (let i = 0; i < row.length; i++) {
          updateFinalData(row[i].months, row[i].years, row[i].REVIEW_STATUS);
        }
      }
      function updateFinalData(months, years, REVIEW_STATUS) {
        for (let i = 0; i < finalData.length; i++) {
          if (
            parseInt(finalData[i].months) === parseInt(months) &&
            parseInt(finalData[i].years) === parseInt(years)
          ) {
            if (parseInt(REVIEW_STATUS) === 3) {
              finalData[i].Completed = finalData[i].Completed + 1;
            } else if (parseInt(REVIEW_STATUS) === 1) {
              finalData[i].Not_Completed = finalData[i].Not_Completed + 1;
            }
          }
        }
      }
      var tempMaxVal = "";
      if (this.state.filterYear === "") {
        tempMaxVal = d3.max(finalData, function (d) {
          return +d.years;
        });
      } else {
        tempMaxVal = this.state.filterYear;
      }
      this.setState(
        {
          ReviewBarchartDetails: finalData,
          filterYear: tempMaxVal,
        },
        () => {
          this.initChart();
        },
      );
    }
  }
  yearChange = () => {
    let tempThis = this;
    $("#year").change(function () {
      tempThis.setState(
        {
          filterYear: $("option:selected", this).text(),
        },
        () => {
          tempThis.initChart();
        },
      );
    });
  };
  initChart = () => {
    var parseDate = d3.timeFormat("%b");
    let filterYear = this.state.filterYear;
    var allSalesData = "";
    allSalesData = JSON.parse(JSON.stringify(this.state.ReviewBarchartDetails));
    var tempSalesData = "";
    var yearList = "";
    var monthsList = "";
    var updatedSalesData = "";
    tempSalesData = [...allSalesData];
    yearList = [...new Set(allSalesData.map((d) => new Date(d.date).getFullYear()))];
    monthsList = [...new Set(allSalesData.map((d) => parseDate(new Date(d.date))))];
    updatedSalesData = tempSalesData.filter(function (d) {
      return new Date(d.date).getFullYear() === parseInt(filterYear);
    });
    this.loadChart(updatedSalesData, yearList, monthsList, allSalesData, filterYear);
  };
  loadChart = (updatedSalesData, yearList, monthsList, salesData, filterYear) => {
    d3.select("#year")
      .selectAll("option")
      .data(yearList)
      .enter()
      .append("option")
      .text((d) => d);
    $("#year").val(filterYear);
    d3.select("svg").selectAll("*").remove();
    var group = ["Not_Completed", "Completed"];
    var parseDate = d3.timeFormat("%b");
    var mainDiv = "#charts";
    var mainDivName = "charts";
    this.createChartLegend(mainDiv, group);
    updatedSalesData.forEach(function (d) {
      d = type(d);
    });
    var layers = d3.stack().keys(group).offset(d3.stackOffsetDiverging)(updatedSalesData);
    var svg = d3.select("svg"),
      margin = {
        top: 20,
        right: 30,
        bottom: 60,
        left: 60,
      },
      width = +svg.attr("width"),
      height = +svg.attr("height");
    let allMonth = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    var x = d3
      .scaleBand()
      .rangeRound([margin.left, width - margin.right])
      .padding(0.1);

    x.domain(
      allMonth.map(function (d) {
        return d;
      }),
    );
    var y = d3.scaleLinear().rangeRound([height - margin.bottom, margin.top]);
    y.domain([d3.min(layers, stackMin), d3.max(layers, stackMax)]);
    function stackMin(layers) {
      return d3.min(layers, function (d) {
        return d[0];
      });
    }
    function stackMax(layers) {
      return d3.max(layers, function (d) {
        return d[1];
      });
    }
    var z = d3.scaleOrdinal(d3.schemeCategory10);
    z = (key) => {
      let clr = "";
      if (key === "Completed") {
        // clr = '#D93954'
        clr = "#E47487";
      } else if (key === "Not_Completed") {
        // clr = '#E47487'
        clr = "#D93954";
      }
      return clr;
    };
    var maing = svg.append("g").attr("class", "mainG").selectAll("g").data(layers);
    var g = maing
      .enter()
      .append("g")
      .attr("class", "GunderMain")
      .attr("fill", function (d) {
        return z(d.key);
      });
    var rect = g
      .selectAll("rect")
      .data(function (d) {
        d.forEach(function (d1) {
          d1.key = d.key;
          return d1;
        });
        return d;
      })
      .enter()
      .append("rect")
      .attr("data", function (d) {
        var data = {};
        data["key"] = d.key;
        data["value"] = d.data[d.key];
        var total = 0;
        group.map(function (d1) {
          return (total = total + d.data[d1]);
        });
        data["total"] = total;
        return JSON.stringify(data);
      })
      .attr("width", 35)
      .attr("class", "reactClass")
      .attr("x", function (d) {
        return x(d.data.date);
      })
      .attr("y", function (d) {
        return y(d[1]);
      })
      .attr("height", function (d) {
        return y(d[0]) - y(d[1]);
      });
    rect.on("mouseover", function () {
      var currentEl = d3.select(this);
      var fadeInSpeed = 120;
      d3.select("#recttooltip_" + mainDivName)
        .transition()
        .duration(fadeInSpeed)
        .style("opacity", function () {
          return 1;
        });
      d3.select("#recttooltip_" + mainDivName).attr("transform", function (d) {
        var mouseCoords = d3.pointer(this.parentNode);
        var xCo = 0;
        if (mouseCoords[0] + 10 >= width * 0.8) {
          xCo = mouseCoords[0] - parseFloat(d3.selectAll("#recttooltipRect_" + mainDivName).attr("width"));
        } else {
          xCo = mouseCoords[0] + 10;
        }
        var yCo = 0;
        if (mouseCoords[0] + 10 >= width * 0.8) {
          yCo = mouseCoords[1] + 10;
        } else {
          yCo = mouseCoords[1];
        }
        var x = xCo;
        var y = yCo;
        return "translate(" + x + "," + y + ")";
      });
      //CBT:calculate tooltips text
      var tooltipData = JSON.parse(currentEl.attr("data"));
      d3.selectAll("#recttooltipText_" + mainDivName).text("");
      var yPos = 0;
      d3.selectAll("#recttooltipText_" + mainDivName)
        .append("tspan")
        .attr("x", 0)
        .attr("y", yPos * 10)
        .attr("dy", "1.9em")
        .text(tooltipData.key + ":  " + tooltipData.value);
      yPos = yPos + 1;
      d3.selectAll("#recttooltipText_" + mainDivName)
        .append("tspan")
        .attr("x", 0)
        .attr("y", yPos * 10)
        .attr("dy", "1.9em")
        .text("Total" + ":  " + tooltipData.total);
      //CBT:calculate width of the text based on characters
      var dims = helpers.getDimensions("recttooltipText_" + mainDivName);
      d3.selectAll("#recttooltipText_" + mainDivName + " tspan").attr("x", dims.w + 4);
      d3.selectAll("#recttooltipRect_" + mainDivName)
        .attr("width", dims.w + 10)
        .attr("height", dims.h + 20);
    });
    rect.on("mousemove", function () {
      var currentEl = d3.select(this);
      currentEl.attr("r", 7);
      d3.selectAll("#recttooltip_" + mainDivName).attr("transform", function (d) {
        var mouseCoords = d3.pointer(this.parentNode);
        var xCo = 0;
        if (mouseCoords[0] + 10 >= width * 0.8) {
          xCo = mouseCoords[0] - parseFloat(d3.selectAll("#recttooltipRect_" + mainDivName).attr("width"));
        } else {
          xCo = mouseCoords[0] + 10;
        }
        var x = xCo;
        var yCo = 0;
        if (mouseCoords[0] + 10 >= width * 0.8) {
          yCo = mouseCoords[1] + 10;
        } else {
          yCo = mouseCoords[1];
        }
        var y = yCo;
        return "translate(" + x + "," + y + ")";
      });
    });
    rect.on("mouseout", function () {
      d3.select(this);
      d3.select("#recttooltip_" + mainDivName)
        .style("opacity", function () {
          return 0;
        })
        .attr("transform", function (d, i) {
          var x = -500;
          var y = -500;
          return "translate(" + x + "," + y + ")";
        });
    });
    svg
      .append("g")
      .attr("class", "monthsG")
      .attr("transform", "translate(0," + y(0) + ")")
      .call(d3.axisBottom(x))
      .append("text")
      .attr("x", width / 2)
      .attr("y", margin.bottom * 0.5)
      .attr("dx", "0.32em")
      .attr("fill", "#000")
      .attr("font-weight", "bold")
      .attr("text-anchor", "start")
      .text("");

    svg
      .append("g")
      .attr("class", "prodG")
      .attr("transform", "translate(" + margin.left + ",0)")
      .call(d3.axisLeft(y))
      .append("text")
      .attr("transform", "rotate(-90)")
      .attr("x", 0 - height / 2)
      .attr("y", 15 - margin.left)
      .attr("dy", "0.32em")
      .attr("fill", "#000")
      .attr("font-weight", "bold")
      .attr("text-anchor", "middle")
      .text("");

    $(".monthsG g").each(function () {
      let temp1 = $(this).attr("transform").split("(")[1];
      let firstVal = temp1.split(",")[0];
      let secondValTemp = temp1.split(",")[1];
      if (secondValTemp.charAt(secondValTemp.length - 1) === ")") {
        secondValTemp = secondValTemp.substr(0, secondValTemp.length - 1);
      }
      d3.select(this).attr("transform", "translate(" + (firstVal - 10) + "," + secondValTemp + ")");
    });
    var rectTooltipg = svg
      .append("g")
      .attr("font-family", "sans-serif")
      .attr("font-size", 10)
      .attr("text-anchor", "end")
      .attr("id", "recttooltip_" + mainDivName)
      .attr("style", "opacity:0")
      .attr("transform", "translate(-500,-500)");
    rectTooltipg
      .append("rect")
      .attr("id", "recttooltipRect_" + mainDivName)
      .attr("x", 0)
      .attr("width", 120)
      .attr("height", 80)
      .attr("opacity", 0.71)
      .style("fill", "#000000");
    rectTooltipg
      .append("text")
      .attr("id", "recttooltipText_" + mainDivName)
      .attr("x", 30)
      .attr("y", 15)
      .attr("fill", function () {
        return "#fff";
      })
      .style("font-size", function (d) {
        return 10;
      })
      .style("font-family", function (d) {
        return "arial";
      })
      .text(function (d, i) {
        return "";
      });
    function type(d) {
      d.date = parseDate(new Date(d.date));
      group.forEach(function (c) {
        d[c] = +d[c];
      });
      return d;
    }
    var helpers = {
      getDimensions: function (id) {
        var el = document.getElementById(id);
        var w = 0,
          h = 0;
        if (el) {
          var dimensions = el.getBBox();
          w = dimensions.width;
          h = dimensions.height;
        } else {
        }
        return {
          w: w,
          h: h,
        };
      },
    };
  }; //OVER: LOAD CHART
  createChartLegend = (mainDiv, group) => {
    $("#Legend_charts").remove();
    // var z = d3.scaleOrdinal(d3.schemeCategory10);
    var z = (key) => {
      let clr = "";
      if (key === "Completed") {
        // clr = '#D93954'
        clr = "#E47487";
      } else if (key === "Not_Completed") {
        // clr = '#E47487'
        clr = "#D93954";
      }
      return clr;
    };
    var mainDivName = mainDiv.substr(1, mainDiv.length);
    $(mainDiv).before(
      "<div id='Legend_" +
        mainDivName +
        "' class='pmd-card-body' style='margin-top:0; margin-bottom:30px; margin-left: 11%;'></div>",
    );
    var keys = group;
    keys.forEach(function (d) {
      var cloloCode = z(d);
      var val = d;
      if (d === "Not_Completed") {
        val = "Not Completed";
      }
      $("#Legend_" + mainDivName).append(
        "<br/><span class='team-graph team1' style='display: inline-block; margin-right:10px;'>\
                  <span style='background:" +
          cloloCode +
          ";width: 10px;height: 10px;display: inline-block;vertical-align: middle;'>&nbsp;</span>\
                  <span style='padding-top: 0;font-family:Source Sans Pro, sans-serif;font-size: 13px;display: inline;'>" +
          val +
          " </span>\
              </span>",
      );
    });
  };
  render() {
    return (
      <div>
        <div className="Select-year col-md-1">
          <select id="year"></select>
        </div>
        <div id="charts">
          <svg width="800" height="500" className="myCustomSvg"></svg>
        </div>
      </div>
    );
  }
}
export default ProductionBarChart;
