import React, { Component } from "react";
import BackButton from "../Shared/button/BackButton";
import { Modal, ModalHeader, ModalBody, Checkbox, Select, SelectOption } from "appkit-react";
import SearchBar from "../../DFED/Shared/components/FormSearchBar";
import AnalysisService from "../../../../src/Services/DFED/AnalysisService";
import "./AddNewAnalysis.css";
import "../../DFED/Processing/Processing.css";
import "../Shared/button/button.css";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import {
  TextFieldLengthValidation,
  TextFieldLengthRegexValidation,
} from "../../../utility/textFieldValidation";
import rightNormalIcon from "../../../Assets/Images/DFED/icon-chevron-right.svg";
import rightBlackIcon from "../../../Assets/Images/DFED/icon-chevron-right-black.svg";
import leftBlackIcon from "../../../Assets/Images/DFED/icon-chevron-left-black.svg";
import leftNormalIcon from "../../../Assets/Images/DFED/icon-chevron-left.svg";
import CustomModal from "../../../Assets/Components/Modal";
import displayToastMessage from "../../../Components/QueenBase/Root/displayToastMessage";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import { infoIcon } from "../../../Assets/Icons";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";

const analysisService = new AnalysisService();
// THIS CLASS IS RESPONSIBLE FOR ADDING NEW ANALYSIS DATA WITH THE HELP OF DUAL BOX DESIGN PATTERN=====================

export class AddNewAnalysis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasetsRecords: [],
      leftSideAvailableCustodian: null,
      selectedAvailableParent: null,
      selectedAvailableParentIndex: null,
      selectedProcessingParent: null,
      selectedProcessingParentIndex: null,
      custodiansWithProfiles: [],
      showUploadKeyword: false,
      showUploadKeyword1: false,
      rightSideProcessingCustodianImage: [],
      rightSideProcessingCustodianOriginal: [],
      leftSideSelectedChildrenArray: [],
      AfterRemoveleftSideAvailableCustodian: [],
      avaliableCheckedListAll: [],
      avaliableCustodianChecked: false,
      processingCheckedListAll: [],
      processingCustodianChecked: false,
      search_text: null,
      filterAvailableCustodian: [],
      leftSideWidtBeforeClick: "col-sm-5 col-md-5 customClassForCol-5 col-xs-12 b-g-w m-t-5 pb-3",
      rightSideWidtBeforeClick: "col-sm-5 col-md-5 customClassForCol-5 col-xs-12 b-g-w m-t-5",
      leftsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
      rightsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
      propfileDropdownValue: [],
      selectedProfileId: null,
      analysisName: "",
      visibleTermsModel: false,
      analysisNameFromReview: "",
      runthruoghFromReview: null,
      AnalysisID: null,
      RunthroughID: null,
      checkedProcessingRightSideChild: [],
      checkedAvaliableLeftSideChild: [],
      leftSideAvaliableSelectAll: false,
      leftSideAvalableParentExpandAll: [],
      rightSideProcessingSelectAll: false,
      rightSideProcessingParentExpandAll: [],
      textFieldLengthError: false,
      openConfirmBox: false,
      textFieldCharError: false,
      currentSelectedLeftCustodian: null,
      currentSelectedRightCustodian: null,
    };
    this.expandAvailableParent = this.expandAvailableParent.bind(this);
    this.expandProcessingParent = this.expandProcessingParent.bind(this);
    this.selectAvaliableCustodianChild = this.selectAvaliableCustodianChild.bind(this);
    this.selectProcessingCustodianChild = this.selectProcessingCustodianChild.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.avaliableCustodianOnChange = this.avaliableCustodianOnChange.bind(this);
    this.processingCustodianOnChange = this.processingCustodianOnChange.bind(this);
    this.filterAvailableCustodian = this.filterAvailableCustodian.bind(this);
    this.runProcessing = this.runProcessing.bind(this);
  }

  static contextType = DataContext;

  // ------------------added for amalysis----------------------
  analysisNameHandler = (e) => {
    const polyglot = polyglotLoader();
    if (TextFieldLengthValidation(e.target.value)) {
      // NAME VALIDATION=============
      if (!TextFieldLengthRegexValidation(e.target.value) && e.target.value !== "") {
        displayToastMessage(polyglot.t("name_validaton"), "error");
        this.setState({
          analysisName: e.target.value,
          textFieldLengthError: false,
          textFieldCharError: true,
        });
      } else {
        this.setState({ analysisName: e.target.value, textFieldLengthError: false });
      }
    } else {
      this.setState({ textFieldLengthError: true });
    }
  };
  showModal = () => {
    this.setState({ visibleTermsModel: true }, () => {});
  };

  handleConfirm = (e) => {
    this.setState({
      visibleTermsModel: false,
    });
  };
  // ------------------added for analysis------------------------------
  // LIFECYCLE METHOD FOR GEETING ALL DATASETS AND CUSTODIAN=====================
  componentDidMount() {
    if (this.props.fromReview) {
      const name = this.props.dataForRunThrough.data.AnalysisName;
      const AnalysisID = this.props.dataForRunThrough.data.AnalysisID;
      const RunthroughID = this.props.dataForRunThrough.data.runthroughID;
      const runthruogh = this.props.dataForRunThrough.data.Runthrough;
      const runthruoghN = Number(runthruogh.split("through")[1]) + 1;
      this.setState({
        analysisNameFromReview: name,
        runthruoghFromReview: runthruoghN,
        AnalysisID: AnalysisID,
        RunthroughID: RunthroughID,
      });
    }
    if (this.props.locationStateForRunData.analysisNameFromReview_fro_key) {
      this.setState({
        analysisNameFromReview: this.props.locationStateForRunData.analysisNameFromReview_fro_key,
        runthruoghFromReview: this.props.locationStateForRunData.runthruoghFromReview_fro_key,
      });
    }

    this.getKeyWords();
    this.getCustodianRecords();
  }
  // GET ALL KEYWORDS FOR CREATING ANALYSIS======================

  getKeyWords = () => {
    analysisService
      .getKeyWords(this.props.location)
      .then((result) => {
        if (result?.data?.length > 0) {
          this.setState({
            propfileDropdownValue: result.data,
          });
        } else {
          return null;
        }
      })
      .catch((err) => {});
  };

  // RUNS WHEN COMPONENT GETS UPDATED
  componentDidUpdate(prevProps, prevState) {
    if (
      this.rightSideProcessingCustodianOriginal(prevState) ||
      this.processingProfile(prevState) ||
      this.analysisName(prevState)
    ) {
      const { formGotOpened } = this.context;
      formGotOpened();
    }

    if (
      prevState.currentSelectedLeftCustodian !== this.state.currentSelectedLeftCustodian &&
      !this.state.leftSideAvalableParentExpandAll.includes(this.state.currentSelectedLeftCustodian)
    ) {
      const leftSideAvalableParentExpandAll = [
        ...this.state.leftSideAvalableParentExpandAll,
        this.state.currentSelectedLeftCustodian,
      ];
      this.setState({ leftSideAvalableParentExpandAll });
    }

    if (
      prevState.currentSelectedRightCustodian !== this.state.currentSelectedRightCustodian &&
      !this.state.rightSideProcessingParentExpandAll.includes(this.state.currentSelectedRightCustodian)
    ) {
      const rightSideProcessingParentExpandAll = [
        ...this.state.rightSideProcessingParentExpandAll,
        this.state.currentSelectedRightCustodian,
      ];
      this.setState({ rightSideProcessingParentExpandAll });
    }
  }

  //rightSideProcessingCustodianOriginal return value for form
  rightSideProcessingCustodianOriginal = (prevState) => {
    if (this.state.rightSideProcessingCustodianOriginal.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  //selectedProfileId return value
  processingProfile = (prevState) => {
    if (this.state.selectedProfileId) {
      if (this.state.selectedProfileId !== prevState.selectedProfileId) {
        return true;
      }
    } else {
      return false;
    }
  };

  //selectedProfileId return value
  analysisName = (prevState) => {
    if (this.state.analysisName) {
      if (this.state.analysisName !== prevState.analysisName) {
        return true;
      }
    } else {
      return false;
    }
  };
  // GETTING ALL CUSTODIAN WITH DATASETS======================

  getCustodianRecords() {
    if (this.props.locationStateForRunData.analysisNameFromReview_fro_key) {
      const datasetsRecords = this.props.locationStateForRunData.leftPalalData_fro_key;

      if (datasetsRecords?.length > 0) {
        datasetsRecords.forEach(
          (custodianData) => (custodianData.custodian_datasets_num = custodianData.custodian_datasets.length),
        );
      }

      this.setState({
        datasetsRecords: this.props.locationStateForRunData.leftPalalData_fro_key,
        leftSideAvailableCustodian: this.props.locationStateForRunData.leftPalalData_fro_key,
        filterAvailableCustodian: this.props.locationStateForRunData.leftPalalData_fro_key,
        rightSideProcessingCustodianOriginal: this.props.locationStateForRunData.rightPalalData_fro_key,
      });
    } else if (this.props.fromReview) {
      // -------------------modify------------
      analysisService
        .fromReviewData(
          this.props.location,
          this.props.dataForRunThrough.data.AnalysisID,
          this.props.dataForRunThrough.data.runthroughID,
        )
        .then((result) => {
          if (result?.data?.length > 0) {
            result.data.forEach(
              (custodianData) =>
                (custodianData.custodian_datasets_num = custodianData.custodian_datasets.length),
            );

            this.setState({
              datasetsRecords: result.data,
              leftSideAvailableCustodian: result.data,
              filterAvailableCustodian: result.data,
            });
          } else {
            return null;
          }
        })
        .catch((err) => {});

      analysisService
        .fromReviewData_right(
          this.props.location,
          this.props.dataForRunThrough.data.AnalysisID,
          this.props.dataForRunThrough.data.runthroughID,
        )
        .then((result) => {
          if (result?.data?.length > 0) {
            this.setState({
              rightSideProcessingCustodianOriginal: result.data,
            });
          } else {
            return null;
          }
        })
        .catch((err) => {});
    } else {
      analysisService
        .getAnalysisCustodianInfo(this.props.location)
        .then((result) => {
          if (result?.data?.length > 0) {
            result.data.forEach((custodianData) => {
              custodianData.isParentChecked = false;
              custodianData.custodian_datasets.forEach(
                (childDatasets) => (childDatasets.isChildChecked = false),
              );
              custodianData.custodian_datasets_num = custodianData.custodian_datasets.length;
            });
            this.setState({
              datasetsRecords: result.data,
              leftSideAvailableCustodian: result.data,
              filterAvailableCustodian: result.data,
            });
          } else {
            return null;
          }
        })
        .catch((err) => {});
    }
  }
  // LEFT SIDE BOX CUSTOADIAN EXPAND FUNCTIONALITY====================

  expandAvailableParent(dataSets, index, event) {
    //============ CODE FOR WENW E CLICK ON EXPAND ICON FROM LEFT SIDE=====
    const tempLeftSideAvalableParentExpandAll = this.state.leftSideAvalableParentExpandAll;
    // CHECKING IF ALREADY ELEMENT IS THERE OR NOT
    const leftSideAvalableExpandIndex = tempLeftSideAvalableParentExpandAll.findIndex(
      (data) => data === dataSets.CRT_ID,
    );
    if (leftSideAvalableExpandIndex === -1) {
      tempLeftSideAvalableParentExpandAll.push(dataSets.CRT_ID);
    }
    this.setState({
      selectedAvailableParentIndex: index,
      selectedAvailableParent: dataSets,
      showUploadKeyword: true,
      leftSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12 customClassForCol-5  b-g-w",
      leftsidecustodiandivstyle: "tot-check tot-check-reducewidth m-l-0 m-r-0 m-0 p-r-0",
      checkedAvaliableLeftSideChild: [],
      leftSideAvalableParentExpandAll: tempLeftSideAvalableParentExpandAll,
      currentSelectedLeftCustodian: dataSets.CRT_ID,
    });
  }
  // EXPAND RIGHT SIDE BOX CUSTODIAN=========================

  expandProcessingParent(dataSets, index, event) {
    //============ CODE FOR WENW E CLICK ON EXPAND ICON FROM LEFT SIDE=====
    const tempRightSideProcessingParentExpandAll = this.state.rightSideProcessingParentExpandAll;
    // CHECKING IF ALREADY ELEMENT IS THERE OR NOT
    const rightSideProcessingExpandIndex = tempRightSideProcessingParentExpandAll.findIndex(
      (data) => data === dataSets.CRT_ID,
    );

    if (rightSideProcessingExpandIndex === -1) {
      tempRightSideProcessingParentExpandAll.push(dataSets.CRT_ID);
    }
    this.setState({
      selectedProcessingParentIndex: index,
      selectedProcessingParent: dataSets,
      showUploadKeyword1: true,
      rightSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12  customClassForCol-5 b-g-w",
      rightsidecustodiandivstyle: "tot-check tot-check-reducewidth m-l-0 m-r-0 m-0 p-r-0",
      checkedProcessingRightSideChild: [],
      rightSideProcessingParentExpandAll: tempRightSideProcessingParentExpandAll,
      currentSelectedRightCustodian: dataSets.CRT_ID,
    });
  }

  // Code for selecting child from left side custodian
  selectAvaliableCustodianChild(event, dataSets) {
    const templeftSideAvailableCustodian = Object.assign([], this.state.leftSideAvailableCustodian);

    const leftsideParentIndex =
      templeftSideAvailableCustodian &&
      templeftSideAvailableCustodian.findIndex(
        (custodianData) => dataSets.Custodian_id === custodianData.CRT_ID,
      );

    if (event.target.checked) {
      if (leftsideParentIndex !== -1) {
        templeftSideAvailableCustodian[leftsideParentIndex].custodian_datasets.forEach((childDatasets) => {
          if (childDatasets.processingID === dataSets.processingID) {
            childDatasets.isChildChecked = true;
          }
        });
      }
      this.setState({
        leftSideAvailableCustodian: templeftSideAvailableCustodian,
      });
    } else {
      templeftSideAvailableCustodian[leftsideParentIndex].custodian_datasets.forEach((childDatasets) => {
        if (childDatasets.processingID === dataSets.processingID) {
          childDatasets.isChildChecked = false;
        }
      });
      this.setState({
        leftSideAvailableCustodian: templeftSideAvailableCustodian,
        leftSideAvaliableSelectAll: false,
      });
    }
  }

  //CODE FOR SELECTING RIGHT SIDE CUSTODIAN DATASET CHILD//
  selectProcessingCustodianChild(event, dataSets) {
    const temprightSideProcessingCustodianImage = Object.assign(
      [],
      this.state.rightSideProcessingCustodianOriginal,
    );
    const rightsideParentIndex =
      temprightSideProcessingCustodianImage &&
      temprightSideProcessingCustodianImage.findIndex(
        (custodianData) => dataSets.Custodian_id === custodianData.CRT_ID,
      );

    if (event.target.checked) {
      if (rightsideParentIndex !== -1) {
        temprightSideProcessingCustodianImage[rightsideParentIndex].custodian_datasets.forEach(
          (childDatasets) => {
            if (childDatasets.processingID === dataSets.processingID) {
              childDatasets.isChildChecked = true;
            }
          },
        );
      }
      this.setState({
        rightSideProcessingCustodianImage: temprightSideProcessingCustodianImage,
      });
    } else {
      temprightSideProcessingCustodianImage[rightsideParentIndex].custodian_datasets.forEach(
        (childDatasets) => {
          if (childDatasets.processingID === dataSets.processingID) {
            childDatasets.isChildChecked = false;
          }
        },
      );
      this.setState({
        rightSideProcessingCustodianImage: temprightSideProcessingCustodianImage,
        rightSideProcessingSelectAll: false,
      });
    }
  }

  //ADD FUNCTIONALITY==========//
  handleAdd() {
    const temprightSideProcessingCustodianImage = Object.assign(
      [],
      this.state.rightSideProcessingCustodianOriginal,
    );
    const templeftSideAvailableCustodian = Object.assign([], this.state.leftSideAvailableCustodian);
    const tempAvaliableCheckdListAll = Object.assign([], this.state.avaliableCheckedListAll);
    const removedElementFromLeft = [];

    let leftSideParentCustodianIndex;
    let rightSideParentIndex;

    // CASE 1: "SELECT ALL" checkbox - select ALL CUSTODIANS WITH ALL DATASETS
    if (this.state.leftSideAvaliableSelectAll) {
      // IF ALREADY SOME ELEMENT IS THERE ON THE RIGHT SIDE THEN WILL GO WITH THIS BLOCK
      if (temprightSideProcessingCustodianImage?.length > 0) {
        for (let i = 0; i < templeftSideAvailableCustodian.length; i++) {
          const index = temprightSideProcessingCustodianImage.findIndex(
            (data) => data.CRT_ID === templeftSideAvailableCustodian[i].CRT_ID,
          );

          if (index === -1) {
            temprightSideProcessingCustodianImage.push(templeftSideAvailableCustodian[i]);
          } else {
            //  CHECK IF CHILD ALREADY IS THERE OR NOT
            for (let j = 0; j < templeftSideAvailableCustodian[i].custodian_datasets.length; j++) {
              const checkChildIndexInRight = temprightSideProcessingCustodianImage[
                index
              ].custodian_datasets.findIndex(
                (childData) =>
                  childData.processingID ===
                  templeftSideAvailableCustodian[i].custodian_datasets[j].processingID,
              );

              if (checkChildIndexInRight === -1) {
                temprightSideProcessingCustodianImage[index].custodian_datasets.push(
                  templeftSideAvailableCustodian[i].custodian_datasets[j],
                );
              }
            }
          }
        }

        temprightSideProcessingCustodianImage.forEach((custodianData) => {
          custodianData.isParentChecked = false;
          custodianData.partial = true;
          custodianData.custodian_datasets.forEach((childDatasets) => (childDatasets.isChildChecked = false));
        });

        this.setState({
          rightSideProcessingCustodianOriginal: temprightSideProcessingCustodianImage,
          leftSideAvailableCustodian: [],
          leftSideAvaliableSelectAll: false,
          avaliableCheckedListAll: [],
          filterAvailableCustodian: [],
          leftSideAvalableParentExpandAll: [],
          selectedAvailableParentIndex: null,
          selectedAvailableParent: null,
          showUploadKeyword: false,
          leftSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12  customClassForCol-5 b-g-w m-t-5 p-b-15",
          leftsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
          checkedAvaliableLeftSideChild: [],
          currentSelectedLeftCustodian: null,
        });
      } else {
        //=========== THIS COD EXECUTE WHEN RIGHT SIDE IS BLANK AND ALL SELECT CHECKBOX CLICKED FOR LEFT SIDE===
        templeftSideAvailableCustodian.forEach((custodianData) => {
          custodianData.isParentChecked = false;
          custodianData.custodian_datasets.forEach((childDatasets) => (childDatasets.isChildChecked = false));
        });

        this.setState({
          rightSideProcessingCustodianOriginal: templeftSideAvailableCustodian,
          leftSideAvailableCustodian: [],
          leftSideAvaliableSelectAll: false,
          avaliableCheckedListAll: [],
          filterAvailableCustodian: [],
          leftSideAvalableParentExpandAll: [],
          selectedAvailableParentIndex: null,
          selectedAvailableParent: null,
          showUploadKeyword: false,
          leftSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12  customClassForCol-5 b-g-w m-t-5 p-b-15",
          leftsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
          checkedAvaliableLeftSideChild: [],
          currentSelectedLeftCustodian: null,
        });
      }
    }

    // CASE 2: checkbox to select ALL DATASETS OF ONE CUSTODIAN
    if (tempAvaliableCheckdListAll.length > 0 && !this.state.leftSideAvaliableSelectAll) {
      let expandCustodianObj;

      for (let i = 0; i < tempAvaliableCheckdListAll.length; i++) {
        const self = this;
        rightSideParentIndex = temprightSideProcessingCustodianImage.findIndex(
          (data) => data.CRT_ID === self.state.avaliableCheckedListAll[i],
        );

        // CASE: when PART of custodian's datasets are already on RIGHT SIDE
        if (rightSideParentIndex !== -1) {
          const self = this;
          leftSideParentCustodianIndex = templeftSideAvailableCustodian.findIndex(
            (value) => value.CRT_ID === self.state.avaliableCheckedListAll[i],
          );

          if (templeftSideAvailableCustodian[leftSideParentCustodianIndex]) {
            for (
              let j = 0;
              j < templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length;
              j++
            ) {
              const checkChildIndexInRight = temprightSideProcessingCustodianImage[
                rightSideParentIndex
              ].custodian_datasets.findIndex(
                (childData) =>
                  childData.processingID ===
                  templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j]
                    .processingID,
              );

              if (checkChildIndexInRight === -1) {
                temprightSideProcessingCustodianImage[rightSideParentIndex].custodian_datasets.push(
                  templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j],
                );
              }
            }

            templeftSideAvailableCustodian.splice(leftSideParentCustodianIndex, 1);
            removedElementFromLeft.push(tempAvaliableCheckdListAll[i]);
          }
        } else {
          const self = this;
          leftSideParentCustodianIndex = templeftSideAvailableCustodian.findIndex(
            (value) => value.CRT_ID === self.state.avaliableCheckedListAll[i],
          );

          temprightSideProcessingCustodianImage.push(
            templeftSideAvailableCustodian[leftSideParentCustodianIndex],
          );
          templeftSideAvailableCustodian.splice(leftSideParentCustodianIndex, 1);
          removedElementFromLeft.push(tempAvaliableCheckdListAll[i]);
        }
      }

      const self = this;
      const custodianIndex = templeftSideAvailableCustodian.findIndex(
        (value) => value.CRT_ID === self.state.currentSelectedLeftCustodian,
      );

      //must be selected current custodian to expand and make his datasets visible
      //below are cases related to already clicked expand arrow
      if (this.state.currentSelectedLeftCustodian) {
        //CASE 1: if we had custodian with expand datasets
        //but we moved him to the right side
        //and now there is no custodian left on the left side
        if (!templeftSideAvailableCustodian.length) {
          expandCustodianObj = {
            leftSideAvalableParentExpandAll: [],
            selectedAvailableParentIndex: null,
            selectedAvailableParent: null,
            showUploadKeyword: false,
            leftSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12  customClassForCol-5 b-g-w m-t-5 p-b-15",
            leftsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
            checkedAvaliableLeftSideChild: [],
            currentSelectedLeftCustodian: null,
          };
          //CASE 2: if we had custodian with expand datasets
          //and we moved him to the right side
          //but he was on the buttom of the list
        } else if (templeftSideAvailableCustodian.length <= leftSideParentCustodianIndex) {
          expandCustodianObj = {
            selectedAvailableParentIndex: templeftSideAvailableCustodian.length - 1,
            selectedAvailableParent:
              templeftSideAvailableCustodian[templeftSideAvailableCustodian.length - 1],
            showUploadKeyword: true,
            leftSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12 customClassForCol-5  b-g-w",
            leftsidecustodiandivstyle: "tot-check tot-check-reducewidth m-l-0 m-r-0 m-0 p-r-0",
            checkedAvaliableLeftSideChild: [],
            currentSelectedLeftCustodian:
              templeftSideAvailableCustodian[templeftSideAvailableCustodian.length - 1].CRT_ID,
          };
          //CASE 3: if we had custodian with expand datasets
          //and we moved him to the right side
        } else if (custodianIndex === -1) {
          expandCustodianObj = {
            currentSelectedLeftCustodian: templeftSideAvailableCustodian[leftSideParentCustodianIndex].CRT_ID,
          };
          //CASE 4: if we had custodian with expand datasets
          //and we moved ANOTHER custodian to the right side
        } else {
          const selectedAvailableParentIndex = templeftSideAvailableCustodian.findIndex(
            (custodian) => custodian.CRT_ID === this.state.currentSelectedLeftCustodian,
          );
          expandCustodianObj = { selectedAvailableParentIndex };
        }
      }

      temprightSideProcessingCustodianImage.forEach((custodianData) => {
        custodianData.isParentChecked = false;
        custodianData.partial = true;
        custodianData.custodian_datasets.forEach((childDatasets) => (childDatasets.isChildChecked = false));
      });

      //  WRITING LOGIC FOR MENTAIN CHECKBOX ARRAY CONSISTENT
      const newAvaliableCheckedListAll = tempAvaliableCheckdListAll.filter(
        (e) => removedElementFromLeft.indexOf(e) === -1,
      );

      this.setState({
        leftSideAvailableCustodian: templeftSideAvailableCustodian,
        rightSideProcessingCustodianOriginal: temprightSideProcessingCustodianImage,
        avaliableCheckedListAll: newAvaliableCheckedListAll,
        filterAvailableCustodian: templeftSideAvailableCustodian,
        ...expandCustodianObj,
      });
    }

    // CASE 3: USE "EXPAND ARROW" to select EACH DATASET OF CUSTODIAN
    if (
      this.state.leftSideAvalableParentExpandAll.length > 0 &&
      !this.state.leftSideAvaliableSelectAll &&
      !tempAvaliableCheckdListAll.length
    ) {
      let expandCustodianObj;

      for (let i = 0; i < this.state.leftSideAvalableParentExpandAll.length; i++) {
        const self = this;
        rightSideParentIndex = temprightSideProcessingCustodianImage.findIndex(
          (data) => data.CRT_ID === self.state.leftSideAvalableParentExpandAll[i],
        );

        // CASE: when PART of custodian's datasets are already on RIGHT SIDE
        if (rightSideParentIndex !== -1) {
          const self = this;
          leftSideParentCustodianIndex = templeftSideAvailableCustodian.findIndex(
            (value) => value.CRT_ID === self.state.leftSideAvalableParentExpandAll[i],
          );

          if (templeftSideAvailableCustodian[leftSideParentCustodianIndex]) {
            for (
              let j = 0;
              j < templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length;
              j++
            ) {
              const checkChildIndexInRight = temprightSideProcessingCustodianImage[
                rightSideParentIndex
              ].custodian_datasets.findIndex(
                (childData) =>
                  childData.processingID ===
                  templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j]
                    .processingID,
              );

              if (
                checkChildIndexInRight === -1 &&
                templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j]
                  .isChildChecked
              ) {
                temprightSideProcessingCustodianImage[rightSideParentIndex].custodian_datasets.push(
                  templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j],
                );
              }
            }

            const newAvaliableCheckedListAll = templeftSideAvailableCustodian[
              leftSideParentCustodianIndex
            ].custodian_datasets.filter((e) => !e.isChildChecked);

            templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets =
              newAvaliableCheckedListAll;

            if (templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length > 1) {
              templeftSideAvailableCustodian[leftSideParentCustodianIndex].partial = false;
            }
            if (!templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length) {
              templeftSideAvailableCustodian[leftSideParentCustodianIndex].partial = true;
              temprightSideProcessingCustodianImage[rightSideParentIndex].partial = true;
              templeftSideAvailableCustodian.splice(leftSideParentCustodianIndex, 1);
            }
          }
        }
        // CASE: when NONE of custodian's datasets are on RIGHT SIDE
        else {
          const self = this;
          const temp_custodian_datasets = [];
          leftSideParentCustodianIndex = templeftSideAvailableCustodian.findIndex(
            (value) => value.CRT_ID === self.state.leftSideAvalableParentExpandAll[i],
          );

          for (
            let j = 0;
            j < templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length;
            j++
          ) {
            if (
              templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j]
                .isChildChecked
            ) {
              temp_custodian_datasets.push(
                templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j],
              );
            }
          }

          if (temp_custodian_datasets.length > 0) {
            const { CRT_ID, custodian_Name, custodian_datasets_num } =
              templeftSideAvailableCustodian[leftSideParentCustodianIndex];

            const obj = {
              CRT_ID,
              custodian_Name,
              partial: temp_custodian_datasets.length === custodian_datasets_num,
              custodian_datasets: temp_custodian_datasets,
              custodian_datasets_num,
            };

            temprightSideProcessingCustodianImage.push(obj);
            const newAvaliableCheckedListAll = templeftSideAvailableCustodian[
              leftSideParentCustodianIndex
            ].custodian_datasets.filter((e) => !e.isChildChecked);
            templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets =
              newAvaliableCheckedListAll;

            if (templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length > 1) {
              templeftSideAvailableCustodian[leftSideParentCustodianIndex].partial = false;
            }
            if (!templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length) {
              templeftSideAvailableCustodian[leftSideParentCustodianIndex].partial = true;
              templeftSideAvailableCustodian.splice(leftSideParentCustodianIndex, 1);
            }
          }
        }
      }

      const self = this;
      const custodianIndex = templeftSideAvailableCustodian.findIndex(
        (value) => value.CRT_ID === self.state.currentSelectedLeftCustodian,
      );

      if (this.state.currentSelectedLeftCustodian) {
        if (!templeftSideAvailableCustodian.length) {
          expandCustodianObj = {
            leftSideAvalableParentExpandAll: [],
            selectedAvailableParentIndex: null,
            selectedAvailableParent: null,
            showUploadKeyword: false,
            leftSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12  customClassForCol-5 b-g-w m-t-5 p-b-15",
            leftsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
            checkedAvaliableLeftSideChild: [],
            currentSelectedLeftCustodian: null,
          };
        } else if (templeftSideAvailableCustodian.length <= leftSideParentCustodianIndex) {
          expandCustodianObj = {
            selectedAvailableParentIndex: templeftSideAvailableCustodian.length - 1,
            selectedAvailableParent:
              templeftSideAvailableCustodian[templeftSideAvailableCustodian.length - 1],
            showUploadKeyword: true,
            leftSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12 customClassForCol-5  b-g-w",
            leftsidecustodiandivstyle: "tot-check tot-check-reducewidth m-l-0 m-r-0 m-0 p-r-0",
            checkedAvaliableLeftSideChild: [],
            currentSelectedLeftCustodian:
              templeftSideAvailableCustodian[templeftSideAvailableCustodian.length - 1].CRT_ID,
          };
        } else if (custodianIndex === -1) {
          const currentSelectedLeftCustodian =
            templeftSideAvailableCustodian[leftSideParentCustodianIndex].CRT_ID;
          const selectedAvailableParentIndex = templeftSideAvailableCustodian.findIndex(
            (custodian) => custodian.CRT_ID === currentSelectedLeftCustodian,
          );

          expandCustodianObj = {
            currentSelectedLeftCustodian,
            selectedAvailableParentIndex,
          };
        }
      }

      temprightSideProcessingCustodianImage.forEach((custodianData) => {
        custodianData.isParentChecked = false;
        custodianData.custodian_datasets.forEach((childDatasets) => (childDatasets.isChildChecked = false));
      });

      this.setState({
        leftSideAvailableCustodian: this.state.leftSideAvaliableSelectAll
          ? []
          : templeftSideAvailableCustodian,
        rightSideProcessingCustodianOriginal: temprightSideProcessingCustodianImage,
        filterAvailableCustodian: templeftSideAvailableCustodian,
        ...expandCustodianObj,
      });
    }
  }

  //CODE FOR REMOVE FROM LEFT SIDE CHILD//
  handleRemove() {
    const temprightSideProcessingCustodianImage = Object.assign(
      [],
      this.state.rightSideProcessingCustodianOriginal,
    );
    const tempLeftSideAvailableCustodian = Object.assign([], this.state.leftSideAvailableCustodian);
    const tempProcessingCheckdListAll = Object.assign([], this.state.processingCheckedListAll);
    const removedElementFromRight = [];

    let rightSideParentIndex;
    let leftSideParentCustodianIndex;

    // CASE 1: "SELECT ALL" checkbox - select ALL CUSTODIANS WITH ALL DATASETS
    if (this.state.rightSideProcessingSelectAll) {
      if (tempLeftSideAvailableCustodian && tempLeftSideAvailableCustodian.length > 0) {
        for (let i = 0; i < temprightSideProcessingCustodianImage.length; i++) {
          const index = tempLeftSideAvailableCustodian.findIndex(
            (data) => data.CRT_ID === temprightSideProcessingCustodianImage[i].CRT_ID,
          );

          if (index === -1) {
            tempLeftSideAvailableCustodian.push(temprightSideProcessingCustodianImage[i]);
          } else {
            //  CHECK IF CHILD ALREADY IS THERE OR NOT
            for (let j = 0; j < temprightSideProcessingCustodianImage[i].custodian_datasets.length; j++) {
              const checkChildIndexInRight = tempLeftSideAvailableCustodian[
                index
              ].custodian_datasets.findIndex(
                (childData) =>
                  childData.processingID ===
                  temprightSideProcessingCustodianImage[i].custodian_datasets[j].processingID,
              );

              if (checkChildIndexInRight === -1) {
                tempLeftSideAvailableCustodian[index].custodian_datasets.push(
                  temprightSideProcessingCustodianImage[i].custodian_datasets[j],
                );
              }
            }
          }
        }
        tempLeftSideAvailableCustodian.forEach((custodianData) => {
          custodianData.isParentChecked = false;
          custodianData.partial = true;
          custodianData.custodian_datasets.forEach((childDatasets) => (childDatasets.isChildChecked = false));
        });

        this.setState({
          rightSideProcessingCustodianOriginal: [],
          leftSideAvailableCustodian: tempLeftSideAvailableCustodian,
          rightSideProcessingSelectAll: false,
          processingCheckedListAll: [],
          filterAvailableCustodian: tempLeftSideAvailableCustodian,
          rightSideProcessingParentExpandAll: [],
          selectedProcessingParentIndex: null,
          selectedProcessingParent: null,
          showUploadKeyword1: false,
          rightSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12 customClassForCol-5 b-g-w m-t-5 p-b-15",
          rightsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
          checkedProcessingRightSideChild: [],
          currentSelectedRightCustodian: null,
        });
      } else {
        //=========== THIS COD EXECUTE WEN RIGHT SIDE IS BLANK AND ALL SELECT CHECKBOX CLICKED FOR LEFT SIDE===
        temprightSideProcessingCustodianImage.forEach((custodianData) => {
          custodianData.isParentChecked = false;
          custodianData.custodian_datasets.forEach((childDatasets) => (childDatasets.isChildChecked = false));
        });

        this.setState({
          rightSideProcessingCustodianOriginal: [],
          leftSideAvailableCustodian: temprightSideProcessingCustodianImage,
          rightSideProcessingSelectAll: false,
          processingCheckedListAll: [],
          filterAvailableCustodian: temprightSideProcessingCustodianImage,
          rightSideProcessingParentExpandAll: [],
          selectedProcessingParentIndex: null,
          selectedProcessingParent: null,
          showUploadKeyword1: false,
          rightSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12 customClassForCol-5 b-g-w m-t-5 p-b-15",
          rightsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
          checkedProcessingRightSideChild: [],
          currentSelectedRightCustodian: null,
        });
      }
    }

    // CASE 2: checkbox to select ALL DATASETS OF ONE CUSTODIAN
    if (tempProcessingCheckdListAll.length > 0 && !this.state.rightSideProcessingSelectAll) {
      let expandCustodianObj;
      for (let i = 0; i < tempProcessingCheckdListAll.length; i++) {
        const self = this;
        rightSideParentIndex = tempLeftSideAvailableCustodian.findIndex(
          (data) => data.CRT_ID === self.state.processingCheckedListAll[i],
        );

        // CASE: when PART of custodian's datasets are already on LEFT SIDE
        if (rightSideParentIndex !== -1) {
          const self = this;
          leftSideParentCustodianIndex = temprightSideProcessingCustodianImage.findIndex(
            (value) => value.CRT_ID === self.state.processingCheckedListAll[i],
          );

          if (temprightSideProcessingCustodianImage[leftSideParentCustodianIndex]) {
            for (
              let j = 0;
              j <
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets.length;
              j++
            ) {
              const checkChildIndexInRight = tempLeftSideAvailableCustodian[
                rightSideParentIndex
              ].custodian_datasets.findIndex(
                (childData) =>
                  childData.processingID ===
                  temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j]
                    .processingID,
              );

              if (checkChildIndexInRight === -1) {
                tempLeftSideAvailableCustodian[rightSideParentIndex].custodian_datasets.push(
                  temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j],
                );
              }
            }

            temprightSideProcessingCustodianImage.splice(leftSideParentCustodianIndex, 1);
            removedElementFromRight.push(tempProcessingCheckdListAll[i]);
          }
        }
        // CASE: when NONE of custodian's datasets are on LEFT SIDE
        else {
          const self = this;
          leftSideParentCustodianIndex = temprightSideProcessingCustodianImage.findIndex(
            (value) => value.CRT_ID === self.state.processingCheckedListAll[i],
          );

          tempLeftSideAvailableCustodian.push(
            temprightSideProcessingCustodianImage[leftSideParentCustodianIndex],
          );
          temprightSideProcessingCustodianImage.splice(leftSideParentCustodianIndex, 1);
          removedElementFromRight.push(tempProcessingCheckdListAll[i]);
        }
      }

      const self = this;
      const custodianIndex = temprightSideProcessingCustodianImage.findIndex(
        (value) => value.CRT_ID === self.state.currentSelectedRightCustodian,
      );

      //must be selected current custodian to expand and make his datasets visible
      //below are cases related to already clicked expand arrow
      if (this.state.currentSelectedRightCustodian) {
        //CASE 1: if we had custodian with expand datasets
        //but we moved him to the left side
        //and now there is no custodian left on the right side
        if (!temprightSideProcessingCustodianImage.length) {
          expandCustodianObj = {
            rightSideProcessingParentExpandAll: [],
            selectedProcessingParentIndex: null,
            selectedProcessingParent: null,
            showUploadKeyword1: false,
            rightSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12 customClassForCol-5 b-g-w m-t-5 p-b-15",
            rightsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
            checkedProcessingRightSideChild: [],
            currentSelectedRightCustodian: null,
          };
          //CASE 2: if we had custodian with expand datasets
          //and we moved him to the left side
          //but he was on the buttom of the list
        } else if (temprightSideProcessingCustodianImage.length <= leftSideParentCustodianIndex) {
          expandCustodianObj = {
            selectedProcessingParentIndex: temprightSideProcessingCustodianImage.length - 1,
            selectedProcessingParent:
              temprightSideProcessingCustodianImage[temprightSideProcessingCustodianImage.length - 1],
            showUploadKeyword1: true,
            rightSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12  customClassForCol-5 b-g-w",
            rightsidecustodiandivstyle: "tot-check tot-check-reducewidth m-l-0 m-r-0 m-0 p-r-0",
            checkedProcessingRightSideChild: [],
            currentSelectedRightCustodian:
              temprightSideProcessingCustodianImage[temprightSideProcessingCustodianImage.length - 1].CRT_ID,
          };
          //CASE 3: if we had custodian with expand datasets
          //and we moved him to the left side
        } else if (custodianIndex === -1) {
          expandCustodianObj = {
            currentSelectedRightCustodian:
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].CRT_ID,
          };
          //CASE 4: if we had custodian with expand datasets
          //and we moved ANOTHER custodian to the left side
        } else {
          const selectedProcessingParentIndex = temprightSideProcessingCustodianImage.findIndex(
            (custodian) => custodian.CRT_ID === this.state.currentSelectedRightCustodian,
          );
          expandCustodianObj = { selectedProcessingParentIndex };
        }
      }

      tempLeftSideAvailableCustodian.forEach((custodianData) => {
        custodianData.isParentChecked = false;
        custodianData.partial = true;
        custodianData.custodian_datasets.forEach((childDatasets) => (childDatasets.isChildChecked = false));
      });

      //  WRITING LOGIC FOR MENTAIN CHECKBOX ARRAY CONSISTENT
      const newProcessingCheckedListAll = tempProcessingCheckdListAll.filter(
        (e) => removedElementFromRight.indexOf(e) === -1,
      );

      this.setState({
        rightSideProcessingCustodianOriginal: temprightSideProcessingCustodianImage,
        leftSideAvailableCustodian: tempLeftSideAvailableCustodian,
        processingCheckedListAll: newProcessingCheckedListAll,
        filterAvailableCustodian: tempLeftSideAvailableCustodian,
        selectedProcessingParentIndex: leftSideParentCustodianIndex,
        ...expandCustodianObj,
      });
    }

    // CASE 3: USE "EXPAND ARROW" to select EACH DATASET OF CUSTODIAN
    if (
      this.state.rightSideProcessingParentExpandAll &&
      this.state.rightSideProcessingParentExpandAll.length > 0 &&
      !this.state.rightSideProcessingSelectAll &&
      !tempProcessingCheckdListAll.length
    ) {
      let expandCustodianObj;

      for (let i = 0; i < this.state.rightSideProcessingParentExpandAll.length; i++) {
        const self = this;
        rightSideParentIndex = tempLeftSideAvailableCustodian.findIndex(
          (data) => data.CRT_ID === self.state.rightSideProcessingParentExpandAll[i],
        );

        // CASE: when PART of custodian's datasets are already on RIGHT SIDE
        if (rightSideParentIndex !== -1) {
          const self = this;
          leftSideParentCustodianIndex = temprightSideProcessingCustodianImage.findIndex(
            (value) => value.CRT_ID === self.state.rightSideProcessingParentExpandAll[i],
          );

          if (temprightSideProcessingCustodianImage[leftSideParentCustodianIndex]) {
            for (
              let j = 0;
              j <
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets.length;
              j++
            ) {
              const checkChildIndexInRight = tempLeftSideAvailableCustodian[
                rightSideParentIndex
              ].custodian_datasets.findIndex(
                (childData) =>
                  childData.processingID ===
                  temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j]
                    .processingID,
              );

              if (
                checkChildIndexInRight === -1 &&
                temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j]
                  .isChildChecked
              ) {
                tempLeftSideAvailableCustodian[rightSideParentIndex].custodian_datasets.push(
                  temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j],
                );
              }
            }

            const newAvaliableCheckedListAll = temprightSideProcessingCustodianImage[
              leftSideParentCustodianIndex
            ].custodian_datasets.filter((e) => !e.isChildChecked);

            temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets =
              newAvaliableCheckedListAll;

            if (
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets.length >
              1
            ) {
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].partial = false;
            }
            if (
              !temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets.length
            ) {
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].partial = true;
              tempLeftSideAvailableCustodian[rightSideParentIndex].partial = true;
              temprightSideProcessingCustodianImage.splice(leftSideParentCustodianIndex, 1);
            }
          }
        }
        // CASE: when NONE of custodian's datasets are on LEFT SIDE
        else {
          const self = this;
          const temp_custodian_datasets = [];
          leftSideParentCustodianIndex = temprightSideProcessingCustodianImage.findIndex(
            (value) => value.CRT_ID === self.state.rightSideProcessingParentExpandAll[i],
          );
          rightSideParentIndex = tempLeftSideAvailableCustodian.findIndex(
            (data) => data.CRT_ID === self.state.rightSideProcessingParentExpandAll[i],
          );

          for (
            let j = 0;
            j < temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets.length;
            j++
          ) {
            if (
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j]
                .isChildChecked
            ) {
              temp_custodian_datasets.push(
                temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j],
              );
            }
          }

          if (temp_custodian_datasets.length > 0) {
            const { CRT_ID, custodian_Name, custodian_datasets_num } =
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex];

            const obj = {
              CRT_ID,
              custodian_Name,
              partial: temp_custodian_datasets.length === custodian_datasets_num,
              custodian_datasets: temp_custodian_datasets,
              custodian_datasets_num,
            };

            tempLeftSideAvailableCustodian.push(obj);
            const newAvaliableCheckedListAll = temprightSideProcessingCustodianImage[
              leftSideParentCustodianIndex
            ].custodian_datasets.filter((e) => !e.isChildChecked);

            temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets =
              newAvaliableCheckedListAll;

            if (
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets.length >
              1
            ) {
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].partial = false;
            }
            if (
              !temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets.length
            ) {
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].partial = true;
              temprightSideProcessingCustodianImage.splice(leftSideParentCustodianIndex, 1);
            }
          }
        }
      }

      const self = this;
      const custodianIndex = temprightSideProcessingCustodianImage.findIndex(
        (value) => value.CRT_ID === self.state.currentSelectedRightCustodian,
      );

      if (this.state.currentSelectedRightCustodian) {
        if (!temprightSideProcessingCustodianImage.length) {
          expandCustodianObj = {
            rightSideProcessingParentExpandAll: [],
            selectedProcessingParentIndex: null,
            selectedProcessingParent: null,
            showUploadKeyword1: false,
            rightSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12 customClassForCol-5 b-g-w m-t-5 p-b-15",
            rightsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
            checkedProcessingRightSideChild: [],
            currentSelectedRightCustodian: null,
          };
        } else if (temprightSideProcessingCustodianImage.length <= leftSideParentCustodianIndex) {
          expandCustodianObj = {
            selectedProcessingParentIndex: temprightSideProcessingCustodianImage.length - 1,
            selectedProcessingParent:
              temprightSideProcessingCustodianImage[temprightSideProcessingCustodianImage.length - 1],
            showUploadKeyword1: true,
            rightSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12  customClassForCol-5 b-g-w",
            rightsidecustodiandivstyle: "tot-check tot-check-reducewidth m-l-0 m-r-0 m-0 p-r-0",
            checkedProcessingRightSideChild: [],
            currentSelectedRightCustodian:
              temprightSideProcessingCustodianImage[temprightSideProcessingCustodianImage.length - 1].CRT_ID,
          };
        } else if (custodianIndex === -1) {
          const currentSelectedRightCustodian =
            temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].CRT_ID;
          const selectedProcessingParentIndex = temprightSideProcessingCustodianImage.findIndex(
            (custodian) => custodian.CRT_ID === currentSelectedRightCustodian,
          );

          expandCustodianObj = {
            currentSelectedRightCustodian,
            selectedProcessingParentIndex,
          };
        }
      }

      tempLeftSideAvailableCustodian.forEach((custodianData) => {
        custodianData.isParentChecked = false;
        custodianData.custodian_datasets.forEach((childDatasets) => (childDatasets.isChildChecked = false));
      });

      this.setState({
        rightSideProcessingCustodianOriginal: this.state.rightSideProcessingSelectAll
          ? []
          : temprightSideProcessingCustodianImage,
        leftSideAvailableCustodian: tempLeftSideAvailableCustodian,
        filterAvailableCustodian: tempLeftSideAvailableCustodian,
        ...expandCustodianObj,
      });
    }
  }

  avaliableCustodianOnChange(dataSets, event) {
    const templeftSideAvailableCustodian = JSON.parse(JSON.stringify(this.state.leftSideAvailableCustodian));
    const leftsideParentIndex =
      templeftSideAvailableCustodian &&
      templeftSideAvailableCustodian.findIndex((custodianData) => dataSets.CRT_ID === custodianData.CRT_ID);
    if (event.target.checked) {
      if (leftsideParentIndex !== -1) {
        templeftSideAvailableCustodian[leftsideParentIndex].isParentChecked = true;
        templeftSideAvailableCustodian[leftsideParentIndex].custodian_datasets.forEach(
          (childDatasets) => (childDatasets.isChildChecked = true),
        );
      }
      //================== MARKED SELECT ALL CHECKED IF ALL PARENT CHECKBOX WITH CHECKED=================================

      this.setState(
        (prevState) => ({
          avaliableCheckedListAll: [...prevState.avaliableCheckedListAll, dataSets.CRT_ID],
          leftSideAvailableCustodian: templeftSideAvailableCustodian,
        }),
        () => {
          if (templeftSideAvailableCustodian.length === this.state.avaliableCheckedListAll.length) {
            this.setState({
              leftSideAvaliableSelectAll: true,
            });
          }
        },
      );
    } else {
      if (leftsideParentIndex !== -1) {
        templeftSideAvailableCustodian[leftsideParentIndex].isParentChecked = false;
        templeftSideAvailableCustodian[leftsideParentIndex].custodian_datasets.forEach(
          (childDatasets) => (childDatasets.isChildChecked = false),
        );
      }
      const tempavailableChecked = this.state.avaliableCheckedListAll;
      const avalibaleCheckedIndex = tempavailableChecked.findIndex(
        (data) => parseInt(event.target.value) === data,
      );
      tempavailableChecked.splice(avalibaleCheckedIndex, 1);

      if (avalibaleCheckedIndex !== -1) {
        this.setState({
          avaliableCheckedListAll: tempavailableChecked,
        });
      }
      this.setState({
        leftSideAvailableCustodian: templeftSideAvailableCustodian,
        leftSideAvaliableSelectAll: false,
      });
    }
  }
  //====================CODE FOR WHEN WE CHECKED SELECT ALL AVAILABLE CUSTODIAN DATA ====================================

  selectAllAvailable(event) {
    if (event.target.checked) {
      //  CALLING COMMON FUNCTION BASED ON EVENT ===========
      this.setParentChildCheckBox(
        event.target.checked,
        JSON.parse(JSON.stringify(this.state.leftSideAvailableCustodian)),
        "left",
      );
    } else {
      // HERE ALSO CALLING COMMON FUNCTION BASED ON EVENT TARGET VALUE===============
      this.setParentChildCheckBox(
        event.target.checked,
        JSON.parse(JSON.stringify(this.state.leftSideAvailableCustodian)),
        "left",
      );
    }
  }
  //=================== COMMON FUNCTION FOR SET RESET CHECKBOX BASED ON CHECKED TRUE OR FALSE==================
  setParentChildCheckBox(eventValue, datasourceValue, boxSide) {
    const tempAvaliableCheckedListAll = this.state.avaliableCheckedListAll;
    const tempProcessingCheckedListAll = this.state.processingCheckedListAll;
    const templeftSideAvailableCustodian = datasourceValue;
    templeftSideAvailableCustodian &&
      templeftSideAvailableCustodian.forEach((custodianData) => {
        custodianData.isParentChecked = eventValue;
        if (eventValue) {
          if (boxSide === "left") {
            tempAvaliableCheckedListAll.push(custodianData.CRT_ID);
          }
          if (boxSide === "right") {
            tempProcessingCheckedListAll.push(custodianData.CRT_ID);
          }
        }
        custodianData.custodian_datasets.forEach(
          (childDatasets) => (childDatasets.isChildChecked = eventValue),
        );
      });
    if (boxSide === "left") {
      this.setState({
        leftSideAvailableCustodian: templeftSideAvailableCustodian,
        leftSideAvaliableSelectAll: eventValue,
        avaliableCheckedListAll: eventValue === false ? [] : tempAvaliableCheckedListAll,
      });
    }
    if (boxSide === "right") {
      this.setState({
        rightSideProcessingCustodianOriginal: templeftSideAvailableCustodian,
        rightSideProcessingSelectAll: eventValue,
        processingCheckedListAll: eventValue === false ? [] : this.state.processingCheckedListAll,
      });
    }
  }

  processingCustodianOnChange(dataSets, event) {
    const tempRightSideProcessingCustodianOriginal = JSON.parse(
      JSON.stringify(this.state.rightSideProcessingCustodianOriginal),
    );
    const rightSideParentIndex =
      tempRightSideProcessingCustodianOriginal &&
      tempRightSideProcessingCustodianOriginal.findIndex(
        (custodianData) => dataSets.CRT_ID === custodianData.CRT_ID,
      );
    if (event.target.checked) {
      if (rightSideParentIndex !== -1) {
        tempRightSideProcessingCustodianOriginal[rightSideParentIndex].isParentChecked = true;
        tempRightSideProcessingCustodianOriginal[rightSideParentIndex].custodian_datasets.forEach(
          (childDatasets) => (childDatasets.isChildChecked = true),
        );
      }
      //================== MARKED SELECT ALL CHECKED IF ALL PARENT CHECKBOX WITH CHECKED=================================

      this.setState(
        (prevState) => ({
          processingCheckedListAll: [...prevState.processingCheckedListAll, dataSets.CRT_ID],
          rightSideProcessingCustodianOriginal: tempRightSideProcessingCustodianOriginal,
        }),
        () => {
          if (
            tempRightSideProcessingCustodianOriginal.length === this.state.processingCheckedListAll.length
          ) {
            this.setState({
              rightSideProcessingSelectAll: true,
            });
          }
        },
      );
    } else {
      if (rightSideParentIndex !== -1) {
        tempRightSideProcessingCustodianOriginal[rightSideParentIndex].isParentChecked = false;
        tempRightSideProcessingCustodianOriginal[rightSideParentIndex].custodian_datasets.forEach(
          (childDatasets) => (childDatasets.isChildChecked = false),
        );
      }
      const tempavailableChecked = this.state.processingCheckedListAll;
      const avalibaleCheckedIndex = tempavailableChecked.findIndex(
        (data) => parseInt(event.target.value) === data,
      );
      tempavailableChecked.splice(avalibaleCheckedIndex, 1);

      if (avalibaleCheckedIndex !== -1) {
        this.setState({
          processingCheckedListAll: tempavailableChecked,
        });
      }
      this.setState({
        rightSideProcessingCustodianOriginal: tempRightSideProcessingCustodianOriginal,
        rightSideProcessingSelectAll: false,
      });
    }
  }

  //====================CODE FOR WHEN WE CHECKED SELECT ALL PROCESSING CUSTODIAN DATA ====================================

  selectAllProcessingAvailable(event) {
    if (event.target.checked) {
      //  CALLING COMMON FUNCTION BASED ON EVENT ===========
      this.setParentChildCheckBox(
        event.target.checked,
        JSON.parse(JSON.stringify(this.state.rightSideProcessingCustodianOriginal)),
        "right",
      );
    } else {
      // HERE ALSO CALLING COMMON FUNCTION BASED ON EVENT TARGET VALUE===============
      this.setParentChildCheckBox(
        event.target.checked,
        JSON.parse(JSON.stringify(this.state.rightSideProcessingCustodianOriginal)),
        "right",
      );
    }
  } //=============================CODE FOR FILTER CUSTODIAN BASED ON CUSTODIAN NAME===========================//
  filterAvailableCustodian(label, value) {
    const templeftSideAvailableCustodian = JSON.parse(JSON.stringify(this.state.filterAvailableCustodian));
    if (value) {
      const custodianFilterdList =
        templeftSideAvailableCustodian &&
        templeftSideAvailableCustodian.filter((el) =>
          el["custodian_Name"].toLowerCase().includes(value.toLowerCase()),
        );
      this.setState({
        leftSideAvailableCustodian: custodianFilterdList,
      });
    } else {
      this.setState({
        leftSideAvailableCustodian: this.state.filterAvailableCustodian,
      });
    }
  }

  runProcessing() {
    const { formGotClosed } = this.context;
    const polyglot = polyglotLoader();
    const tempSaveProcessingData = JSON.parse(
      JSON.stringify(this.state.rightSideProcessingCustodianOriginal),
    );
    const data = {};
    const tempObj = [];
    const permissionData = {
      moduleName: "Analysis",
      Permission: "Create",
      solutionName: "Connected eDiscovery",
      projectId: this.props.projectID,
    };
    for (let i = 0; i < tempSaveProcessingData.length; i++) {
      for (let j = 0; j < tempSaveProcessingData[i].custodian_datasets.length; j++) {
        let obj = {
          PROJECT_ID: Number(this.props.location),
          CUSTODIAN_ID: tempSaveProcessingData[i].CRT_ID,
          DATASET_ID: tempSaveProcessingData[i].custodian_datasets[j].DataSetID,
          PROFILE_ID: this.state.selectedProfileId,
          COLLECTION_STATUS: 1,
          PROCESSING_STATUS: 1,
          CLIENT_ID: 1,
        };
        tempObj.push(obj);
      }
    }
    data.ANALYSIS_NAME = this.state.analysisName;
    data.PROJECT_ID = this.props.location;
    data.CREATED_BY = null;
    data.ANALYSIS_TYPE = 1;
    data.tempObj = tempObj;
    data.KEYWORD_LIST_ID = this.state.selectedProfileId;
    data.ANALYSIS_ID = Number(this.props.dataForRunThrough.id);
    data.ANALYSIS_RUNTHROUGH = Number(this.state.runthruoghFromReview);
    if (this.props.fromReview) {
      data.ANALYSIS_RUNTHROUGH_ID = Number(this.props.dataForRunThrough.data.runthroughID);
    }

    if (this.state.selectedProfileId === null) {
      displayToastMessage(polyglot.t("Please select Keyword List"), "error");
    } else {
      if (!TextFieldLengthRegexValidation(this.state.analysisName) && this.state.analysisName !== "") {
        displayToastMessage(
          polyglot.t("Please check your Analysis name, following special characters are allowed. ()[]@,.;_-"),
          "error",
        );
      } else {
        if (this.props.fromReview || this.props.locationStateForRunData.analysisNameFromReview_fro_key) {
          analysisService
            .saveNewRunThrough(data)
            .then((data) => {
              if (data.status === 200) {
                displayToastMessage(polyglot.t("Analysis request added Successfully"), "success");
                this.setState({
                  textFieldCharError: false,
                });
                this.props.showlistProcessing();
                this.props.goBack();
              } else {
                displayToastMessage(polyglot.t(data.data.status), "error");
              }
            })
            .catch((err) => {});
        } else {
          analysisService
            .saveNewAnalysis(data, permissionData)
            .then((data) => {
              if (data.status === 200) {
                let message = data.data;
                if (message.status === "Analysis name already exists.") {
                  displayToastMessage(
                    polyglot.t("Analysis name already exists, please enter new name."),
                    "error",
                  );
                } else {
                  displayToastMessage(polyglot.t("Analysis request added Successfully"), "success");
                  this.props.goBack();
                }
                formGotClosed();
              } else {
                displayToastMessage(polyglot.t(data.data.status), "error");
              }
            })
            .catch((err) => {});
        }
      }
    }
  }

  onChangeProfile(data) {
    this.setState({
      selectedProfileId: parseInt(data),
    });
    // ------------added------------
    if (data === "createNewList") {
      if (this.props.fromReview) {
        localStorage.setItem("HideResfromKeyProp", "true");
        this.props.history.push({
          pathname: "/dfed/keywords",
          state: {
            selectedProjectId: this.props.projectID,
            leftPalalData: this.state.leftSideAvailableCustodian,
            rightPalalData: this.state.rightSideProcessingCustodianOriginal,
            analysisNameFromReview: this.state.analysisNameFromReview,
            runthruoghFromReview: this.state.runthruoghFromReview,
          },
        });
      } else {
        this.props.history.push({
          pathname: "/dfed/keywords",
          state: { selectedProjectId: this.props.projectID },
        });
      }
      localStorage.setItem("analysisNameFromReview", this.state.analysisNameFromReview);
      localStorage.setItem("runthruoghFromReview", this.state.runthruoghFromReview);
    }
  }

  //=================== FUNCTION FOR ENABLE AND DISABLE REMOVE BUTTON BASED ON CONDITION
  shouldremoveButtonDisabled() {
    const returnValue = this.enableAndDisbaleAddRemoveButton(
      this.state.rightSideProcessingCustodianOriginal,
      this.state.processingCheckedListAll,
    );
    return returnValue;
  }
  // ====================FUNCTION FOR ENABLE AND DISABLE ADD  BUTTON BASED ON CONDITION
  shouldAddButtonDisabled() {
    const returnValue = this.enableAndDisbaleAddRemoveButton(
      this.state.leftSideAvailableCustodian,
      this.state.avaliableCheckedListAll,
    );
    return returnValue;
  }
  //============ COMMON FUNCTION FOR DISBALE BOTH BUTTON ADD OR REMOVE BASED ON CONDITION==============
  enableAndDisbaleAddRemoveButton(dataSourceForCustodian, checkListAll) {
    const templeftSideAvailableCustodian = dataSourceForCustodian;
    let leftSideAvalableCheckox = false;
    if (templeftSideAvailableCustodian && templeftSideAvailableCustodian.length > 0) {
      for (let i = 0; i < templeftSideAvailableCustodian.length; i++) {
        if (
          templeftSideAvailableCustodian[i].custodian_datasets &&
          templeftSideAvailableCustodian[i].custodian_datasets.length > 0
        ) {
          for (let j = 0; j < templeftSideAvailableCustodian[i].custodian_datasets.length; j++) {
            if (templeftSideAvailableCustodian[i].custodian_datasets[j].isChildChecked === true) {
              leftSideAvalableCheckox = true;
              break;
            }
          }
        }
      }
    }
    if (checkListAll?.length > 0 || leftSideAvalableCheckox) {
      return false;
    } else {
      return true;
    }
  }

  // ==================== CODE FOR DISABLED RUN PROCESSING BUTTON BASED ON CONDITION IF PROFILE AND CUSTODIAN AVALIBALE THEN I HAVE TO SHOW ENABLE ================
  shouldRunProcessingButtonDisabled() {
    if (
      this.state.analysisName !== "" &&
      this.state.rightSideProcessingCustodianOriginal &&
      this.state.rightSideProcessingCustodianOriginal.length > 0 &&
      this.state.selectedProfileId
    ) {
      return false;
    } else {
      return true;
    }
  }

  showTextModal = () => {
    this.setState({
      visibleTextModel: true,
    });
  };

  handleTextConfirm = (e) => {
    this.setState({
      visibleTextModel: false,
    });
  };

  // Close and redirect form
  closeFormAndRedirect = () => {
    try {
      const { formGotClosed } = this.context;
      // close the popup
      this.toggleConfirmBox();
      // mark the form is closed on the store
      formGotClosed();
      // close custodian form
      this.props.goBack();
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  toggleConfirmBox = () => {
    this.setState({
      openConfirmBox: !this.state.openConfirmBox,
    });
  };

  // check and close the form
  checkAndCloseForm = () => {
    try {
      const { isFormOpen, formGotClosed } = this.context;
      if (isFormOpen) {
        // values of the form got changed - then show confirm box
        this.setState({ openConfirmBox: true });
      } else {
        // if form is not considered as open(values of the form are not changed yet), then close the form
        this.props.goBack();
        formGotClosed();
        this.setState({ openConfirmBox: false });
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  render() {
    const polyglot = polyglotLoader();
    return (
      <div className="p-0" style={{ width: "100%" }}>
        <div className="row col-lg-10 pl-0">
          <div className="col-md-3 col-lg-3">
            <ButtonPrimary
              disabled={this.shouldRunProcessingButtonDisabled()}
              onClick={this.runProcessing.bind()}
              ariaLabel={polyglot.t("Run analysis")}
            >
              {polyglot.t("Run analysis")}
            </ButtonPrimary>
          </div>
          <div className="col-md-3 col-lg-3">
            <BackButton
              onBackButtonClick={this.checkAndCloseForm}
              buttonText={polyglot.t("Back")}
            ></BackButton>
          </div>
        </div>
        <div>
          <h5 className="headingAnalysis">{polyglot.t("Create process analysis")}</h5>
        </div>
        {this.props.fromReview || this.props.locationStateForRunData.analysisNameFromReview_fro_key ? (
          <div className="row mt-4 m-l-0 m-r-0">
            <div className="col-md-4  col-sm-12 col-xs-12 p-0  tex-ali inp-row justify mb-2">
              <span className="p-0  font20Itc  des-col">
                {this.state.analysisNameFromReview} - Run Through {this.state.runthruoghFromReview}
              </span>
            </div>
            <div className="col-md-8"></div>
          </div>
        ) : (
          <div className="row mt-4 m-l-0 m-r-0">
            <div className="col-md-8  col-sm-12 col-xs-12  tex-ali inp-row justify mb-2 disp-block p-l-0 enteranalys">
              <input
                onChange={this.analysisNameHandler}
                type="text"
                value={this.state.analysisName}
                aria-label={polyglot.t("Enter Analysis name")}
                placeholder={polyglot.t("Enter Analysis name") + ".." + " * "}
                className="p-0 anx-txt-width des-col  analysis-input-text"
                id="analysisNameId1"
                autoFocus
              />
              <ButtonIcon
                className="m-l-1-p mt-1"
                onClick={() => this.showTextModal()}
                iconName={infoIcon}
                ariaLabel={polyglot.t("Maximum character length is 40")}
                title={polyglot.t("Info")}
              />
              {this.state.textFieldLengthError ? (
                <p className="txt-field-error m-0 pl-3 p-11">
                  {polyglot.t("Maximum character length is 40")}
                </p>
              ) : null}
            </div>
            <div className="col-md-4"></div>
          </div>
        )}

        {/*=========================== CODE FOR TWO BOXES LEFT AND RIGHT=================== */}
        <div className="row mainProcessingDiv m-l-0-1">
          <div className={this.state.leftSideWidtBeforeClick}>
            <div className="text-Avl-two">
              {/* <span className="text-Avl-two">{polyglot.t("Available data")}</span> */}
              {polyglot.t("Available data")}
            </div>
            <div
              className={`row searchBoxProcessingDiv m-l-0 m-r-0 ${
                this.state.leftSideAvailableCustodian &&
                this.state.leftSideAvailableCustodian.length === 0 &&
                "mb-2"
              }`}
            >
              <div className="pod-title pt-0" style={{ width: "100%" }}>
                <SearchBar
                  labelClass={"full-width"}
                  nameOfSearch={"Custodians"}
                  aria-label="Search for Custodians"
                  customClass={"availableProcessingCustodian"}
                  id="search"
                  name="serach_text"
                  val={this.state.search_text}
                  onChange={this.filterAvailableCustodian.bind(this)}
                />
              </div>
            </div>
            <div className="row selectAllLeftSide m-l-0 m-r-0">
              {this.state.leftSideAvailableCustodian && this.state.leftSideAvailableCustodian.length > 0 && (
                <div className="selectAllDiv">
                  <Checkbox
                    name="select_All_Avaliable"
                    checked={this.state.leftSideAvaliableSelectAll}
                    onClick={this.selectAllAvailable.bind(this)}
                    className="check-list-one"
                  >
                    {polyglot.t("Select All")}
                  </Checkbox>
                </div>
              )}
            </div>
            <div className={this.state.leftsidecustodiandivstyle}>
              {this.state.leftSideAvailableCustodian &&
                this.state.leftSideAvailableCustodian.map((element, index) => {
                  const currentSelectedLeftCustodian = this.state.currentSelectedLeftCustodian;
                  const haveAllDatasets =
                    element.custodian_datasets.length === element.custodian_datasets_num;

                  return (
                    <div className="process-selct-data " key={index}>
                      <Checkbox
                        name="CRT_FIRST_NAME"
                        key={element.CRT_ID}
                        aria-label={element.CRT_ID}
                        value={element.CRT_ID}
                        checked={element.isParentChecked}
                        onChange={this.avaliableCustodianOnChange.bind(this, element)}
                        className="check-list-one "
                      ></Checkbox>

                      <div
                        className="custo_name"
                        title={element.custodian_Name}
                        aria-label={element.custodian_Name}
                        style={{
                          fontWeight: element.CRT_ID === currentSelectedLeftCustodian ? "bold" : "normal",
                          maxWidth: !haveAllDatasets ? "47%" : "85%",
                        }}
                      >
                        {element.custodian_Name}
                      </div>
                      {!haveAllDatasets && (
                        <div
                          style={{
                            minWidth: "max-content",
                            margin: "0 15px",
                            color: "#d96e34",
                            fontWeight: "bold",
                          }}
                        >
                          {`${element.custodian_datasets.length} of total ${element.custodian_datasets_num} datasets remaining`}
                        </div>
                      )}
                      {this.state.showUploadKeyword === true && (
                        <span
                          className="customArrow2"
                          onClick={this.expandAvailableParent.bind(this, element, index)}
                        ></span>
                      )}
                      {this.state.showUploadKeyword !== true && (
                        <span
                          className="customArrow"
                          onClick={this.expandAvailableParent.bind(this, element, index)}
                        ></span>
                      )}
                    </div>
                  );
                })}
            </div>
            {this.state.showUploadKeyword ? (
              <div className="check-sublist-one">
                <div className="mb-4 mt-1">
                  <div>
                    <div className="tot-check-child-two ">
                      {this.state.selectedAvailableParentIndex != null &&
                        this.state.leftSideAvailableCustodian.length > 0 &&
                        this.state.leftSideAvailableCustodian[this.state.selectedAvailableParentIndex] &&
                        this.state.leftSideAvailableCustodian[this.state.selectedAvailableParentIndex]
                          .custodian_datasets.length > 0 &&
                        this.state.leftSideAvailableCustodian[
                          this.state.selectedAvailableParentIndex
                        ].custodian_datasets.map((element, index) => {
                          return (
                            <div className="processing-sub-check">
                              <Checkbox
                                name="CRT_FIRST_NAME"
                                onChange={(e) => this.selectAvaliableCustodianChild(e, element)}
                                key={element.processingID}
                                value={element.processingID}
                                aria-label={element.processingID}
                                className="check-list-one"
                                checked={element.isChildChecked}
                              ></Checkbox>
                              <div className="custo_name" title={element.DataSetName}>
                                {element.DataSetName} ({element.profileName})
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {/*====================CODE FOR MIDDLE BOX DIV=================  */}
          <div className="col-sm-2 col-md-2 add-remove-button center-align-content">
            <button
              id="add-btn"
              disabled={this.shouldAddButtonDisabled()}
              className={
                this.shouldAddButtonDisabled()
                  ? "ProcessingAddBtnDisable no-pointer b-color"
                  : "ProcessingAddBtn cursor-pointer "
              }
              onClick={this.handleAdd.bind(this)}
            >
              {polyglot.t("Add")}
              {this.shouldAddButtonDisabled() ? (
                <img class="p-l-7" src={rightBlackIcon} alt="" />
              ) : (
                <img className="p-l-7" src={rightNormalIcon} alt="" />
              )}
            </button>
            <br />
            <br />
            <button
              disabled={this.shouldremoveButtonDisabled()}
              className={
                this.shouldremoveButtonDisabled()
                  ? "ProcessingAddBtnDisable no-pointer b-color"
                  : "ProcessingAddBtn cursor-pointer "
              }
              id="remove-btn"
              onClick={this.handleRemove.bind(this)}
            >
              {this.shouldremoveButtonDisabled() ? (
                <img class="p-r-7 p-b-1" src={leftBlackIcon} alt="" />
              ) : (
                <img class="p-r-7 p-b-1" src={leftNormalIcon} alt="" />
              )}
              {polyglot.t("Remove")}
            </button>
          </div>
          {/*======================== CODE FOR RIGHT SIDE BOXES====================== */}
          <div className={this.state.rightSideWidtBeforeClick}>
            <div
              className={`pod-title ${
                this.state.rightSideProcessingCustodianOriginal &&
                this.state.rightSideProcessingCustodianOriginal.length === 0 &&
                "mb-2"
              }`}
            >
              <div className="text-Avl-two">{polyglot.t("Analysis mapping")}</div>
              <Select
                placeholder={polyglot.t("Choose keyword list...")}
                onSelect={(e) => this.onChangeProfile(e)}
              >
                <SelectOption value={"-2"}>{polyglot.t("Without keyword list")}</SelectOption>
                <SelectOption value={"-1"}>{polyglot.t("Boost it with AIRO")}</SelectOption>
                <hr className="" />
                <SelectOption value={"createNewList"}>
                  <span className="newKeyword">{"+ Create New List"}</span>
                </SelectOption>
                <hr className="" />
                {this.state.propfileDropdownValue &&
                  this.state.propfileDropdownValue.length > 0 &&
                  this.state.propfileDropdownValue.map((data) => {
                    return (
                      <SelectOption key={data.value} value={data.ID}>
                        {data.LIST_NAME}
                      </SelectOption>
                    );
                  })}
              </Select>
            </div>
            {this.state.rightSideProcessingCustodianOriginal &&
              this.state.rightSideProcessingCustodianOriginal.length > 0 && (
                <div className="selectAllDiv p-l-0">
                  <Checkbox
                    name="select_All_Avaliable"
                    className="check-list-one "
                    onClick={this.selectAllProcessingAvailable.bind(this)}
                    checked={this.state.rightSideProcessingSelectAll}
                  >
                    {" "}
                    {polyglot.t("Select All")}
                  </Checkbox>
                </div>
              )}

            <div className={this.state.rightsidecustodiandivstyle}>
              {this.state.rightSideProcessingCustodianOriginal &&
                this.state.rightSideProcessingCustodianOriginal.length > 0 &&
                this.state.rightSideProcessingCustodianOriginal.map((element, index) => {
                  const datasetInflection = element.custodian_datasets_num === 1 ? "dataset" : "datasets";
                  const currentSelectedRightCustodian = this.state.currentSelectedRightCustodian;
                  return (
                    <div className="process-selct-data " key={index}>
                      <Checkbox
                        name="CRT_FIRST_NAME"
                        key={index}
                        aria-label={element.CRT_ID}
                        value={element.CRT_ID}
                        checked={element.isParentChecked}
                        onChange={this.processingCustodianOnChange.bind(this, element)}
                        className="check-list-one"
                      ></Checkbox>
                      <div
                        className="custo_name"
                        title={element.custodian_Name}
                        aria-label={element.custodian_Name}
                        style={{
                          fontWeight: element.CRT_ID === currentSelectedRightCustodian ? "bold" : "normal",
                        }}
                      >
                        {element.custodian_Name}
                      </div>
                      <div
                        style={{
                          minWidth: "max-content",
                          margin: "0 15px",
                          color: "#d96e34",
                          fontWeight: "bold",
                        }}
                      >
                        {`${element.custodian_datasets.length} of ${element.custodian_datasets_num} ${datasetInflection} selected`}
                      </div>
                      <span
                        className="customArrow2"
                        onClick={this.expandProcessingParent.bind(this, element, index)}
                      ></span>
                    </div>
                  );
                })}
            </div>

            {this.state.showUploadKeyword1 ? (
              <div className="check-sublist-one">
                <div className=" mb-4 mt-1">
                  <div>
                    <div className="tot-check-child-two">
                      {this.state.selectedProcessingParent !== null &&
                        this.state.rightSideProcessingCustodianOriginal.length > 0 &&
                        this.state.rightSideProcessingCustodianOriginal[
                          this.state.selectedProcessingParentIndex
                        ].custodian_datasets &&
                        this.state.rightSideProcessingCustodianOriginal[
                          this.state.selectedProcessingParentIndex
                        ].custodian_datasets.map((element, index) => {
                          return (
                            <div className="process-selct-data " key={index}>
                              <Checkbox
                                name="CRT_FIRST_NAME"
                                key={element.processingID}
                                aria-label={element.processingID}
                                value={element.processingID}
                                onChange={(e) => this.selectProcessingCustodianChild(e, element)}
                                checked={element.isChildChecked}
                              ></Checkbox>
                              <div
                                className="custo_name"
                                title={element.DataSetName}
                                aria-label={element.DataSetName}
                              >
                                {element.DataSetName} ({element.profileName})
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>

        <Modal className="form-modal" visible={this.state.visibleTermsModel} onCancel={this.handleConfirm}>
          <ModalHeader>
            <div className="a-modal-example-header">
              <div className="modal-title-left">
                <div className="modal-title">
                  {" "}
                  <h5>{polyglot.t("Terms And Conditions")}</h5>
                </div>
              </div>
            </div>
          </ModalHeader>
          <ModalBody>
            <div className="row m-l-0 m-r-0">
              <div className="col-12">
                <div>
                  <div className="flex-fill"></div>
                </div>
              </div>
            </div>
          </ModalBody>
        </Modal>
        <CustomModal
          show={this.state.visibleTextModel}
          onCancel={this.handleTextConfirm}
          title={polyglot.t("Analysis")}
        >
          <div className="row m-l-0 m-r-0 ">
            <div className="col-12 p-0">
              <div>
                <p className="para-txt w-b-b-a word-break">
                  {polyglot.t(
                    "In the dropdown of the Analysis mapping, please select the Keyword List you want to use.",
                  )}
                </p>
                <p className="para-txt w-b-b-a break-word">
                  {polyglot.t(
                    "Please add data you want to include into this analysis run by selecting the Dataset(s) and clicking ",
                  )}{" "}
                  &nbsp;
                  <span className="para-head">{polyglot.t("Add")}</span>
                </p>
                <p className="para-txt w-b-b-a word-break">
                  {polyglot.t("Once you've added all the desired information, please click on ")}
                  <span className="para-head">{polyglot.t("Run analysis")}</span>
                  {" " + polyglot.t("to schedule the job")}.
                </p>
              </div>
            </div>
          </div>
        </CustomModal>
        {/* show confirm box if the form is open */}
        {this.state.openConfirmBox && (
          <ConfirmBoxExitForm
            show={this.state.openConfirmBox}
            heading={"Cancel update"}
            cancel={this.toggleConfirmBox}
            proceed={this.closeFormAndRedirect}
          />
        )}
      </div>
    );
  }
}

export default AddNewAnalysis;
