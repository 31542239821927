/* eslint-disable react/no-unescaped-entities */
import { Avatar, Tooltip } from "appkit-react";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState, useContext } from "react";

import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import { deleteFill } from "../../../Assets/Icons";
import icongroup from "../../../Assets/Icons/avatar-outline.svg";
import chevron from "../../../Assets/Icons/down-chevron-outline.svg";
import moon from "../../../Assets/Icons/moon-outline.svg";
import up from "../../../Assets/Icons/up-chevron-outline.svg";
import editlogo from "../../../Assets/Images/QueenBase/Root/pencil-outline.svg";
import clock from "../../../Assets/Images/QueenBase/Root/time-outline.svg";
import { daysValidator } from "../../../Constants/Constant";
import { hasEditPermission, hasDeletePermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader, getCurrentLanguage } from "../../../Services/Shared/Translate";

// RENDERS VIEW USER
function ViewUser({ user, onEdit, cancelUserModal, activateUser, userCount }) {
  const { getCurrentUserPermissions, getUserLanguage } = useContext(DataContext);
  const [show, setShow] = useState(false);
  const [expandUserId, setExpandUserId] = useState("");
  var polyglot = polyglotLoader(getUserLanguage());

  const tempUserCanBeActivatedFromDate = moment(user.createddate).add(90 - 14, "days");
  const allowTempUserToActivate =
    moment().isSameOrAfter(tempUserCanBeActivatedFromDate, "days") && user.istemp;

  // ON EXPANDING A USER
  const showView = () => {
    setShow((prev) => !prev);
    setExpandUserId(user.userid);
  };
  // TOOLTIP OF USER NAME
  const TooltipUserName = (user) => {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td
                className="capitalisedText"
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#000",
                }}
              >{`${user.firstname} ${user.lastname}`}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const getActivateButton = () => {
    if (
      hasDeletePermission(currentUserPermissions) &&
      Number(userCount.freetempusers) > 0 &&
      (user.inactive || user.isExpired || allowTempUserToActivate) &&
      !user.isDeleted
    ) {
      return (
        <div className="col-md-8 p-0 ">
          <ButtonSecondary onClick={() => activateUser(user.userid)} ariaLabel={polyglot.t("Activate")}>
            {polyglot.t("Activate")}
          </ButtonSecondary>
        </div>
      );
    }
    return null;
  };

  const getUserStatus = () => {
    if (user.isDeleted && user.istemp !== 1) return <span>{polyglot.t(" User deleted")}</span>;
    if (user.Role === "Admin") return <span>{polyglot.t("PwC user")}</span>;
    return null;
  };

  const currentUserPermissions = getCurrentUserPermissions();
  return (
    <div className="f-acc">
      <div className="row users-data ml-0 mr-0 pt-0">
        <div className="col-md-10">
          <div className="">
            <div>
              <div className="row">
                <div className="col-md-1 userlist-avatar">
                  <Avatar>
                    <img
                      style={{ borderRadius: "100%", height: "30px" }}
                      alt="user.i"
                      src={user.image ? user.image : icongroup}
                    />
                  </Avatar>
                </div>
                <div className="col-md-3 m-auto">
                  <Tooltip content={TooltipUserName(user)} placement="top">
                    <div className="usertitle-view-text User-data-desc view-center-name f-acc capitalisedText">
                      <strong>{user.firstname}</strong>
                    </div>
                  </Tooltip>
                </div>
                <div className="col-md-8 f-acc m-auto">
                  {user.istemp ? (
                    user.inactive ? (
                      user.isExpired ? (
                        <div>
                          &nbsp;
                          {user.isDeleted === 1 && <span>{polyglot.t("user deleted")}</span>}
                        </div>
                      ) : (
                        <div>
                          <img width={16} height={16} src={moon} alt="moon" />
                          &nbsp;
                          <img src={clock} alt="clocksuer" />
                          &nbsp;
                          <span>
                            {polyglot.t("temp_deactivated")}
                            {daysValidator(user.dateinactivediff)}
                            {getCurrentLanguage() === "ger" && polyglot.t("deactivated")}
                          </span>
                        </div>
                      )
                    ) : (
                      <div className="m-auto">
                        <span className="moon-temp">
                          <img width={16} height={16} src={moon} alt="moonuser" />
                        </span>
                        {user.isDeleted != 1 && (
                          <span>
                            {polyglot.t("temp_created")}
                            {daysValidator(user.datediffTemp)}
                            {getCurrentLanguage() === "ger" && polyglot.t("Created")}
                          </span>
                        )}
                        {user.isDeleted == 1 && user.istemp == 1 && (
                          <span>{polyglot.t("temporary user deleted")}</span>
                        )}
                      </div>
                    )
                  ) : user.inactive ? (
                    <div className="userlist-data">
                      <img src={clock} alt="clock" />
                      <span className="User-active-days">
                        {" "}
                        {polyglot.t("deactivated")}&nbsp;
                        {daysValidator(user.dateinactivediff)}
                      </span>
                    </div>
                  ) : null}
                  {getUserStatus()}
                </div>
              </div>
            </div>
          </div>

          {show && expandUserId === user.userid && (
            <div className="expandedView">
              <div className="row fadeIn mb-4">
                <div className="col-md-12">
                  <div className="UserEditMode">
                    <div className="row mt-3">
                      <div className="col-md-6 ">
                        <p className="user-label-details">{polyglot.t("Salutation")}</p>
                        <p className="user-data-details">
                          {user.salutation ? polyglot.t(user.salutation) : ""}
                        </p>
                      </div>
                      <div className="col-md-6 ">
                        <p className="user-label-details">{polyglot.t("First name")}</p>
                        <p className="user-data-details">{user.firstname}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <p className="user-label-details">{polyglot.t("Last name")} </p>
                        <p className="user-data-details">{user.lastname}</p>
                      </div>
                      <div className="col-md-6">
                        <p className="user-label-details">{polyglot.t("Email")}</p>
                        <p className="user-data-details">{user.email}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <p className="user-label-details">{polyglot.t("Position_noStar")}</p>
                        <p className="user-data-details">{user.positionname}</p>
                      </div>
                      <div className="col-md-6">
                        <p className="user-label-details">{polyglot.t("Country_noStar")}</p>
                        <p className="user-data-details">{user.countryname}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <p className="user-label-details">{polyglot.t("Organisation_noStar")}</p>
                        <p className="user-data-details">{user.entity}</p>
                      </div>
                      <div className="col-md-6">
                        <p className="user-label-details">{polyglot.t("Department_noStar")}</p>
                        <p className="user-data-details">{user.department}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <p className="user-label-details">{polyglot.t("PhoneNumber")}</p>
                        <p className="user-data-details">{user.phone}</p>
                      </div>
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="col-md-2 mt-point7rem">
          <div className="row d-flex justify-content-around">
            <span className="inactive.date">{user.DateDiff}</span>
            {hasEditPermission(currentUserPermissions) &&
              !user.inactive &&
              !user.isExpired &&
              !allowTempUserToActivate &&
              !user.isDeleted && (
                <ButtonIcon
                  iconImg={editlogo}
                  onClick={() => onEdit(user.userid)}
                  alt="edit"
                  ariaLabel={polyglot.t("edit user")}
                  title={polyglot.t("Edit")}
                />
              )}
            {hasDeletePermission(currentUserPermissions) &&
              !user.inactive &&
              !user.isExpired &&
              !allowTempUserToActivate &&
              !user.isDeleted && (
                <div className="col-md-4 p-0 text-align-center">
                  <ButtonIcon
                    onClick={() => cancelUserModal(user.userid, user.firstname, user.email)}
                    iconName={deleteFill}
                    alt="delete"
                    ariaLabel={polyglot.t("delete user")}
                    title={polyglot.t("Delete")}
                  />
                </div>
              )}
            {getActivateButton()}
            <div
              className={`${
                !user.inactive && !user.isDeleted
                  ? "col-md-4"
                  : user.inactive && !user.isDeleted && Number(userCount.freetempusers) > 0
                  ? "col-md-4"
                  : "col-md-12"
              } p-0 text-align-right`}
            >
              <ButtonIcon
                imgWidth={16}
                onClick={showView}
                iconImg={show && expandUserId === user.userid ? up : chevron}
                alt={show && expandUserId === user.userid ? "show less" : "show more"}
                ariaLabel={
                  show && expandUserId === user.userid ? polyglot.t("compress") : polyglot.t("expand")
                }
                title={show && expandUserId === user.userid ? polyglot.t("Collapse") : polyglot.t("Expand")}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
ViewUser.propTypes = {
  user: PropTypes.object,
  onEdit: PropTypes.func,
  cancelUserModal: PropTypes.func,
  userCount: PropTypes.object,
};

export default ViewUser;
