import { Panel, Tooltip } from "appkit-react";
import { upperFirst } from "lodash";
import React, { useEffect, useState } from "react";

import CustomModal from "../../../Assets/Components/Modal";
import SearchService from "../../../Services/CSS/SearchService";
import { getDisplayDate } from "../../../Services/Shared/Handlers";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";

function CssDetailsList(props) {
  const { show, toggleViewModal, formBody } = props;
  const polyglot = polyglotLoader();
  //details data
  const [detailsData, setDetailsData] = useState({});
  // VIEW PROJECT NAME ON HOVER
  const TooltipProjectName = (Item) => {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#000",
                  textTransform: "capitalize",
                }}
              >
                {Item}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  const handleCancel = () => {
    const body = {};
    toggleViewModal(body);
  };

  // FETCH SOURCES FROM API
  async function getDetailsList() {
    const { searchAuditID } = props;
    var res = await SearchService.get().getDetailsData(formBody.Id, searchAuditID);
    if (res.CODE && res.requestedDetails) {
      setDetailsData(res.requestedDetails);
    } else {
      displayToastMessage(polyglot.t(res.MSG), "error");
    }
  }

  // RUNS ON LOAD
  useEffect(() => {
    getDetailsList();
  }, []);

  const displayAddressList = () => {
    try {
      return detailsData.address_list.map((data, i) => (
        <React.Fragment key={i}>
          <tr>
            <td>{data.address}</td>
            <td>{data.country}</td>
            <td>{data.city}</td>
            <td>{data.postal_code}</td>
          </tr>
        </React.Fragment>
      ));
    } catch (err) {
      return "No Data Found";
    }
  };

  const displayNameList = () => {
    try {
      return detailsData.name_list.map((data) => {
        if (data.name.toLowerCase().includes(formBody.searchterm.toLowerCase())) {
          var details = <span>{data.name}</span>;
        } else {
          var details = <span>{data.name}</span>;
        }
        return (
          <tr>
            <td className="cssSearchDetails">
              <Tooltip content={TooltipProjectName(details)} placement="top">
                <div className="td-width-details">{details}</div>
              </Tooltip>
            </td>
            <td>{getDisplayDate(data.date_from)}</td>
            <td>{getDisplayDate(data.date_to)}</td>
          </tr>
        );
      });
    } catch (err) {
      return "No Data Found";
    }
  };

  const displayDateOfBirthList = () => {
    try {
      return detailsData.dob_list.map((data) => (
        <React.Fragment>
          <tr>
            <td>{getDisplayDate(data.dob)}</td>
          </tr>
        </React.Fragment>
      ));
    } catch (err) {
      return "No Data Found";
    }
  };

  const displayCountryList = () => {
    try {
      return detailsData.country_list.map((data) => (
        <React.Fragment>
          <tr>
            <td></td>
          </tr>
        </React.Fragment>
      ));
    } catch (err) {
      return "No Data Found";
    }
  };

  const displayAddressTable = () => {
    return (
      <Panel className="adduserpanel-three">
        <b style={{ marginLeft: "15px" }}>{polyglot.t("Address List")} :&nbsp;</b>
        {detailsData.address_list && (
          <div className="card-spacing">
            <div className="row">
              {detailsData.address_list.length === 0 && (
                <table className="table">
                  <thead>
                    <tr>
                      <th className="Queen-Mile">
                        <b>{polyglot.t("No data found")}</b>
                      </th>
                    </tr>
                  </thead>
                </table>
              )}
              {detailsData.address_list.length > 0 && (
                <table className="table">
                  <thead>
                    <tr>
                      <th className="Queen-Mile">
                        <b>{polyglot.t("Address")}</b>
                      </th>
                      <th className="Queen-Mile">
                        <b>{polyglot.t("Countrys")}</b>
                      </th>
                      <th className="Queen-Mile">
                        <b>{polyglot.t("City")}</b>
                      </th>
                      <th className="Queen-Mile">
                        <b>{polyglot.t("Postal Code")}</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{displayAddressList()}</tbody>
                </table>
              )}
            </div>
          </div>
        )}
        {!detailsData.address_list && (
          <table className="table">
            <thead>
              <tr>
                <th className="Queen-Mile">
                  <b>{polyglot.t("No data found")}</b>
                </th>
              </tr>
            </thead>
          </table>
        )}
      </Panel>
    );
  };

  const displayNameListTable = () => {
    return (
      <Panel className="adduserpanel-three">
        <b style={{ marginLeft: "15px" }}>{polyglot.t("Name List")} :&nbsp;</b>
        {detailsData.name_list && (
          <div className="card-spacing" style={{ marginLeft: "16px" }}>
            <div className="row">
              {detailsData.name_list.length === 0 && (
                <table className="table">
                  <thead>
                    <tr>
                      <th className="Queen-Mile">
                        <b>{polyglot.t("No data found")}</b>
                      </th>
                    </tr>
                  </thead>
                </table>
              )}
              {detailsData.name_list.length > 0 && (
                <table className="table">
                  <thead>
                    <tr>
                      <th className="Queen-Mile">
                        <b>{polyglot.t("Name Details")}</b>
                      </th>
                      <th className="Queen-Mile">
                        <b>{polyglot.t("From Date")}</b>
                      </th>
                      <th className="Queen-Mile">
                        <b>{polyglot.t("To Date")}</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{displayNameList()}</tbody>
                </table>
              )}
            </div>
          </div>
        )}
        {!detailsData.name_list && (
          <table className="table">
            <thead>
              <tr>
                <th className="Queen-Mile">
                  <b>{polyglot.t("No data found")}</b>
                </th>
              </tr>
            </thead>
          </table>
        )}
      </Panel>
    );
  };

  const displayDOBTable = () => {
    return (
      <Panel className="adduserpanel-three">
        <b style={{ marginLeft: "14px" }}>{polyglot.t("DOB List")} :&nbsp;</b>
        {detailsData.dob_list && (
          <div className="card-spacing">
            <div className="row">
              {detailsData.dob_list.length === 0 && (
                <table className="table">
                  <thead>
                    <tr>
                      <th className="Queen-Mile">
                        <b>{polyglot.t("No data found")}</b>
                      </th>
                    </tr>
                  </thead>
                </table>
              )}
              {detailsData.dob_list.length > 0 && (
                <table className="table">
                  <thead>
                    <tr>
                      <th className="Queen-Mile">
                        <b>{polyglot.t("Birthday")}</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{displayDateOfBirthList()}</tbody>
                </table>
              )}
            </div>
          </div>
        )}
        {!detailsData.dob_list && (
          <table className="table">
            <thead>
              <tr>
                <th className="Queen-Mile">
                  <b>{polyglot.t("No data found")}</b>
                </th>
              </tr>
            </thead>
          </table>
        )}
      </Panel>
    );
  };

  const displayCountryTable = () => {
    return (
      <Panel className="adduserpanel-three">
        <b style={{ marginLeft: "14px" }}>{polyglot.t("Country List")} :&nbsp;</b>
        {detailsData.country_list && (
          <div className="card-spacing">
            <div className="row">
              {detailsData.country_list.length === 0 && (
                <table className="table">
                  <thead>
                    <tr>
                      <th className="Queen-Mile">
                        <b>{polyglot.t("No data found")}</b>
                      </th>
                    </tr>
                  </thead>
                </table>
              )}
              {detailsData.country_list.length > 0 && (
                <table className="table">
                  <thead>
                    <tr>
                      <th className="Queen-Mile">
                        <b>{polyglot.t("Country")}</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{displayCountryList()}</tbody>
                </table>
              )}
            </div>
          </div>
        )}
        {!detailsData.country_list && (
          <table className="table">
            <thead>
              <tr>
                <th className="Queen-Mile">
                  <b>{polyglot.t("No data found")}</b>
                </th>
              </tr>
            </thead>
          </table>
        )}
      </Panel>
    );
  };

  return (
    <CustomModal
      show={show}
      onCancel={handleCancel}
      title={polyglot.t(`Details of sanctions ID : `) + formBody.Id}
      footer={[]}
      size="lg"
      showCloseButton
    >
      <>
        <div className="modal-txt-open">
          <Panel className="adduserpanel-three updateBackground">
            <div className="card-spacing">
              <div className="row">
                <div className=" col-md-3">
                  <b>{polyglot.t("Type")}</b> :&nbsp;
                  {polyglot.t(upperFirst(formBody.type))}
                </div>
                <div className=" col-md-3">
                  <b>{polyglot.t("List")}</b> :&nbsp;
                  {polyglot.t(upperFirst(formBody.source))}
                </div>
                <div className=" col-md-3">
                  <b>{polyglot.t("Programmes")}</b> :&nbsp;
                  {polyglot.t(upperFirst(formBody.program))}
                </div>
              </div>
            </div>
          </Panel>
          {displayAddressTable()}
          {displayNameListTable()}
          {displayDOBTable()}
          {displayCountryTable()}
        </div>
      </>
    </CustomModal>
  );
}

export default CssDetailsList;
