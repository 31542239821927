import HttpClient from "../../Shared/HttpClient";
class NotificationService {
  constructor() {
    this.client = new HttpClient();
  }

  async getNotificationListsLists(language) {
    const result = await this.client.get(`/getNotificationLists?language=${language}`);
    return result;
  }

  // API TO MARK NOTIFICATION AS READ
  async markNotificationAsRead(notificationId) {
    const result = await this.client.post("/markNotificationAsRead", { NOTIFICATION_ID: notificationId });
    return result;
  }
}

// Enable Singleton behavior on this class
NotificationService.__instance = null;
NotificationService.get = () => {
  if (!NotificationService.__instance) {
    NotificationService.__instance = new NotificationService();
  }
  return NotificationService.__instance;
};

export default NotificationService;
