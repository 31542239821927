/* eslint-disable react/jsx-key */
import { Draggable, Droppable } from "react-drag-and-drop";
import React, { forwardRef, useEffect, useState } from "react";
import { Avatar, Checkbox, Tooltip } from "appkit-react";
import CommonService from "../../../Services/Shared/CommonService";
import DragUser from "../../../Assets/Icons/avatar-outline.svg";
import icongroup from "../../../Assets/Icons/avatar-outline.svg";
import ProjectService from "../../../Services/QueenBase/Projects/ProjectService";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import AddUserFromProject from "./AddUserFromProject";
import UserService from "../../../Services/QueenBase/Users/UserService";
import displayToastMessage from "../Root/displayToastMessage";
import ButtonGroupCustom from "../../../Assets/Components/ButtonGroupCustom/ButtonGroupCustom";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import { infoIcon } from "../../../Assets/Icons";

const TooltipInnerText = ({ children }) => (
  <div>
    <span>{children}</span>
    <ButtonIcon className="tooltip-text1" iconName={infoIcon} alt="info" ariaLabel="" />
  </div>
);

class ProjectModuleUserWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userImagesList: [], // will receive user images from API
    };
  }

  // callback to cath the cancelRequest
  cancelRequestCallback = (cancelRequest) => {
    this.cancelRequest = cancelRequest;
  };

  // fetch user images api
  fetchUserImages = async () => {
    try {
      const res = await UserService.get().getUserList_Images(this.cancelRequestCallback);
      if (res.CODE) {
        this.setState({ userImagesList: res.userImagesList });
      } else {
        throw new Error("Error on fetching user images");
      }
    } catch (err) {
      // log axios cancel request here
      return [];
    }
  };

  componentDidMount() {
    // call user images on load
    this.fetchUserImages();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.cancelAPIs !== prevProps.cancelAPIs) {
      this.CancelAPI();
    }
  }

  // cancel the api call
  CancelAPI = () => {
    this.cancelRequest && this.cancelRequest("canceling the api call");
  };

  // RENDERS THE STEP4 OF THE PORJECT FORM
  render() {
    const { userImagesList } = this.state;
    return (
      <ProjectModuleUser
        cancelRequest={this.cancelRequest}
        userImagesList={userImagesList}
        {...this.props} // pass all the props which wrapper has received
      />
    );
  }
}

export default ProjectModuleUserWrapper;

// STEP 4 OF PROJECT FORM - TO CHOOSE USERS ON A SOLUTION
const ProjectModuleUser = forwardRef((props, ref) => {
  var polyglot = polyglotLoader();

  const {
    mainUsersList,
    selectedSolutionsList,
    solutionList,
    moduleUserFieldChange,
    currentTab,
    setFetchedData,
  } = props;

  const [usersList, setUsersList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [usersToAllSolutions, setUsersToAllSolutions] = useState({
    isChecked: false,
    currentSolutionId: 0,
  });
  const [canOpenAddUserForm, setOpenAddUserForm] = useState(false);
  const [userCount, setUserCount] = useState({});

  const MemberInfo = "Member role information";
  const ReviewerInfo = "Reviewer role information";
  const [isApplyUsersCheckboxDisabled, setIsApplyUsersCheckboxDisabled] = useState(false);

  // assign user images on load
  useEffect(() => {
    appendUserImagesToUser(props.userImagesList);
  }, [props.userImagesList]);

  const appendUserImagesToUser = (userImages) => {
    try {
      const usersList = [...mainUsersList];
      const listOfUsers = [];
      let userListObj = {};
      usersList.forEach((user) => {
        userListObj = { ...user };
        userImages.forEach((item) => {
          if (item.userid === user.userid) {
            if (item.image) {
              var imageBlob = CommonService.get().getBufferImageInBlob(item.image);
              var imageStr = URL.createObjectURL(imageBlob);
              userListObj.image = imageStr;
            }
          }
        });
        listOfUsers.push(userListObj);
        setUsersList(listOfUsers);
        moduleUserFieldChange("getSolutionList", selectedSolutionsList);
      });
      return listOfUsers;
    } catch (err) {
      return [];
    }
  };

  // GET USERS ASSIGNED TO THE SOLUTION
  const getProjectSolutionUsers = async () => {
    try {
      setUsersList(mainUsersList);
      moduleUserFieldChange("getSolutionList", selectedSolutionsList);
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // GET USER COUNT
  const getUserCount = async () => {
    var res = await UserService.get().getUserCount();
    if (res.CODE) {
      setUserCount(res.usercount);
    } else {
      displayToastMessage(res.MSG, "error");
    }
  };

  // HANDLE DROP USERS - MEMBERS
  const onDropUser = (data) => {
    var item = JSON.parse(data.user);
    var list = [...solutionList];
    let count = 0;

    list[item.index].projectForm.usersList.map((res) => {
      if (res.value === item.userid) {
        count++;
      }
    });
    if (count === 0) {
      list[item.index].projectForm.usersList.push({
        value: item.userid,
        label: item.firstname + " " + item.lastname,
      });
      moduleUserFieldChange("solutionList", list);
    } else {
      displayToastMessage(polyglot.t("Duplicate user is not allowed"), "error");
    }
  };

  // RUNS ON INITIAL LOAD AND WHEN MAIN USER LIST GETS UPDATED
  useEffect(() => {
    getProjectSolutionUsers();
    getUserCount();
  }, [mainUsersList]);

  // check if users are present in current solution
  const checkIfUsersAreAssignedToSolution = (solutionId) => {
    try {
      let isUsersAssignedToSolution = false;
      if (solutionList.length > 0) {
        const bodyOfCurrentSolution = solutionList.find((each) => each.id === solutionId);
        const members = bodyOfCurrentSolution.projectForm.usersList;
        const reviewers = bodyOfCurrentSolution.projectForm.reviewersList;
        if (members.length > 0 || reviewers.length > 0) {
          isUsersAssignedToSolution = true;
        }
        return isUsersAssignedToSolution;
      } else {
        return isUsersAssignedToSolution;
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  const checkIfApplyUserCheckboxIsDisabled = () => {
    try {
      const solutionID = currentTab;
      const areUsersPresentInCurrentSolution = checkIfUsersAreAssignedToSolution(solutionID);
      if (!areUsersPresentInCurrentSolution) {
        // if users are not present in the current solution, then disable checkbox
        setIsApplyUsersCheckboxDisabled(true);
      } else {
        // if users are present in the current solution, then enable checkbox
        setIsApplyUsersCheckboxDisabled(false);
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // RUNS ON INITIAL LOAD AND WHEN SOLUTION LIST IS UPDATED OR WHEN CURRENT TAB IS CHANGED
  useEffect(() => {
    checkIfApplyUserCheckboxIsDisabled();
  }, [solutionList, currentTab]);

  // GET PROJECT FORM DETAILS
  const getProjectFormDetails = async () => {
    var res = await ProjectService.get().getProjectFormDetails();
    if (res.CODE === 1) {
      setFetchedData("users", res.userList);
    } else {
      displayToastMessage(res.MSG, "error");
    }
  };

  // GET ALL USERS
  const getAllUsers = () => {
    getProjectFormDetails();
  };

  // SEARCH USERS
  const onSearch = (e) => {
    setSearchText(e.target.value);
  };

  // REMOVE USER - MEMBERS LIST
  const handleCloseUsersList = (solutionObj, each, index) => {
    const list = [...solutionList];
    list.forEach((solution) => {
      if (solution.id === solutionObj.id) {
        solution.projectForm.usersList.forEach((userObj) => {
          if (userObj.value === each.value) {
            solution.projectForm.usersList.splice(index, 1);
          }
        });
      }
    });
    moduleUserFieldChange("solutionList", list);
  };

  // DISPLAY NAME ON TOOLTIP
  const TooltipText = (name) => (
    <div
      style={{
        display: "flex",
        textAlign: "center",
        fontWeight: "bold",
        maxWidth: "22.5rem",
        color: "#000",
      }}
    >
      {name}
    </div>
  );

  // DISPLAY DRAGGABLE USERS LIST
  const displayUsers = (solution, index) => {
    const filteredUsers = usersList.filter(
      (user) =>
        user.firstname.toLowerCase().includes(searchText.toLowerCase()) ||
        user.lastname.toLowerCase().includes(searchText.toLowerCase()),
    );
    const Tooltipdata = (user) => {
      return (
        <div>
          <p style={{ fontWeight: "bold", color: "#000" }}>
            {user.firstname} {user.lastname}
          </p>
        </div>
      );
    };
    return filteredUsers.map((user, i) => {
      return (
        <Draggable
          key={index + i + 1}
          type="user"
          data={JSON.stringify({
            firstname: user.firstname,
            lastname: user.lastname,
            userid: user.userid,
            id: solution.id,
            index: index,
          })}
        >
          <div className="list-group-item d-flex  align-items-center UserNames" key={i}>
            <Avatar
              size="large"
              src={
                <img
                  style={{
                    borderRadius: "100%",
                    height: "30px",
                  }}
                  src={user.image ? user.image : icongroup}
                  alt="user"
                  aria-label="user image"
                />
              }
            />
            <Tooltip content={Tooltipdata(user)} placement="top">
              <div className="UserNames project-title-overflow">
                {user.firstname} {user.lastname}
              </div>
            </Tooltip>
          </div>
        </Draggable>
      );
    });
  };
  // GET SOLUTION NAME AS TAB LABEL
  const getLabel = (solution) => {
    const solutionName = solution.name;
    return solutionName;
  };
  // WHEN USER CHANGES TO ANOTHER TAB
  const changeTab = (event, value) => {
    moduleUserFieldChange("setCurrentTab", Number(value));
  };

  // ASSIGN CURRENT USERS TO ALL THE SOLUTIONS
  const chooseCurrentUsersForAllSolutions = (event) => {
    const isChecked = event.target.checked;
    const usersToAllSolutionsClone = { ...usersToAllSolutions };
    usersToAllSolutionsClone.isChecked = isChecked;
    usersToAllSolutionsClone.currentSolutionId = currentTab;
    moduleUserFieldChange("currentUsersToAllSolutions", {
      currentSolutionId: currentTab,
      assignCurrentUsersToAllSolutions: isChecked,
    });
    setUsersToAllSolutions(usersToAllSolutionsClone);
  };

  // TOGGLE ADD USER FORM
  const toggleAddUserForm = (isOpen) => {
    setOpenAddUserForm(isOpen);
  };

  // DISPLAY SOLUTION TABS
  const displayTabs = () => {
    const idsPreset = solutionList.map((item) => item.id);
    const tabs = [];
    let tabObject = {};
    solutionList.forEach((item) => {
      tabObject = {};
      tabObject.value = item.id.toString();
      tabObject.name = item.name;
      tabs.push(tabObject);
    });
    const isCurrentTabPresent = idsPreset.includes(currentTab);
    if (isCurrentTabPresent) {
      return (
        <div className="project-avaluser-data">
          <ButtonGroupCustom
            value={currentTab.toString()}
            onChange={(e, v) => {
              changeTab(e, v);
            }}
            tabs={tabs}
          />
          {solutionList.map((solution, index) => {
            if (solution.id === currentTab) {
              return (
                <div className="row mt-3">
                  <div className="col-md-5">
                    <div className="mt-4 subtitle_two_title_qb m-b-18">{polyglot.t("Available Users")}</div>
                    <div className="UsersListSearch_projects ">
                      <input
                        value={searchText}
                        className="serachinput_project"
                        prefix={
                          <span
                            className="appkiticon icon-search-outline baseline-search-icon "
                            style={{ fontSize: 16 }}
                          />
                        }
                        placeholder={polyglot.t("Search users")}
                        onChange={onSearch}
                        aria-label="search users"
                      />
                    </div>
                    <br />
                    <div className="ScrollUsers">
                      <div className="list-group">
                        <div
                          className="list-group-item d-flex  align-items-center cursor"
                          onClick={() => toggleAddUserForm(true)}
                        >
                          <span>
                            {" "}
                            <b className="subtitle_two_title_qb"> {polyglot.t("+ Add new user")}</b>{" "}
                          </span>
                        </div>
                        {usersList.length > 0 && displayUsers(solution, index)}
                        {usersList.length === 0 && <p>{polyglot.t("No users to display")}</p>}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-1"></div>

                  <div className="col-md-6 mt-4">
                    <div>
                      <p className="mb-3 subtitle_two_title_qb">
                        <b>{polyglot.t("Your Selection")}</b>
                      </p>
                    </div>
                    <div className="row p-0">
                      <div className="col-md-3 project-userdrag-pic">
                        <img src={DragUser} alt="user" aria-label="user image" />
                      </div>
                      <div className="col-md-9 project-userdrag-row">
                        <span className="project-userdrag-desc">
                          {/* <b>{polyglot.t('Drag & drop')}</b>&nbsp; */}
                          {polyglot.t("Drag & Drop users to their role on this project.")}
                        </span>
                      </div>
                    </div>
                    <div className="mt-3">
                      <div className=" subtitle_three_title_qb_dark">
                        <Tooltip
                          content={TooltipText(polyglot.t(MemberInfo))}
                          placement="top"
                          tooltipTheme="light"
                        >
                          <TooltipInnerText>{polyglot.t("Members (optional)")}</TooltipInnerText>
                        </Tooltip>
                      </div>

                      <Droppable types={["user"]} className="droppable-area-worklist" onDrop={onDropUser}>
                        {solution.projectForm.usersList.map((each, index) => {
                          return (
                            <li
                              key={index}
                              className="droppable-users subtitle_three_title_qb_dark updateCloseIcon"
                            >
                              {each.label}
                              <span
                                className="appkiticon icon-close-fill close-icon"
                                onClick={() => handleCloseUsersList(solution, each, index)}
                              ></span>
                            </li>
                          );
                        })}
                      </Droppable>
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      );
    }
  };
  return (
    <div>
      {solutionList.length > 0 && displayTabs()}
      <div className="row">
        <div className="col-md-8">
          <div className="project-avaluser-data">
            <Checkbox
              disabled={isApplyUsersCheckboxDisabled}
              checked={usersToAllSolutions.isChecked}
              onChange={chooseCurrentUsersForAllSolutions}
            >
              <div className="subtitle_three_title_qb_dark">
                {" "}
                {polyglot.t("Apply same users to all solutions")}
              </div>
            </Checkbox>
          </div>
        </div>

        <div className="col-md-4"></div>
      </div>
      <div>
        <AddUserFromProject
          isOpen={canOpenAddUserForm}
          close={toggleAddUserForm}
          getAllUsers={getAllUsers}
          userCount={userCount}
        />
      </div>
    </div>
  );
});
