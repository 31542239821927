import * as path from "../../../Constants/Path";

import { Select, SelectGroupTitle, SelectOption } from "appkit-react";
import { Formik } from "formik";
import React, { useEffect, useState, useContext } from "react";
import * as Yup from "yup";

import { LoginContext } from "./LoginContext";
import displayToastMessage from "./displayToastMessage";
import "../../../Assets/Css/QueenBase/Root/Login.css";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import LinkText from "../../../Assets/Components/LinkText/LinkText";
import NoAccess from "../../../Assets/Components/NoAccess";
import LoginService from "../../../Services/QueenBase/Root/LoginService";
import { expire_my_session, check_If_User_LoggedIn } from "../../../Services/Shared/Authenticate";
import { storeLoginTimeStampBeforeJWT } from "../../../Services/Shared/LoginHandler";
import { polyglotLoader } from "../../../Services/Shared/Translate";

const ONLY_NUMBER_REGEX = /^\d+$/;

const isNumeric = (val) => ONLY_NUMBER_REGEX.test(val);

const ChooseClientOnLogin = (props) => {
  const { setLoginResponse, initializeLogin, successfulLogin, getUserLanguage } = useContext(LoginContext);
  var polyglot = polyglotLoader(getUserLanguage);

  const [showNoAccess, setShowNoAccess] = useState(false);
  const [showChooseClient, setShowChooseClient] = useState(false);
  const [associatedClients, setAssociatedClients] = useState([]);

  const login = async (loginValues) => {
    initializeLogin();
    try {
      // API TO LOGIN A USER BASED ON THE IDAM DETAILS
      var res = await LoginService.get().loginAuthenticateOnIDAMLogin(loginValues);
      if (res.CODE) {
        setLoginResponse(res);
        successfulLogin(res);
        props.history.push(path.QUEEN_BASE_PATH);
      } else {
        displayToastMessage(res.MSG, "error");
      }
    } catch (err) {
      displayToastMessage(polyglot.t("Invalid credentials Please try again!"), "error");
    }
  };

  const checkAssociatedClients = (clients, userid) => {
    const totalClients = clients.length;
    switch (totalClients) {
      case 0:
        setShowNoAccess(true);
        break;
      case 1:
        const clientuserid = clients[0].clientuserid;
        const loginReq = {
          clientUserId: userid,
          clientDBName: clientuserid,
        };
        login(loginReq);
        break;
      default:
        setShowChooseClient(true);
        setAssociatedClients(clients);
        break;
    }
  };

  // CHECK IF CODE IS VALID ON IDAM LOGIN
  const checkIfCodeIsValid = async (userid, code) => {
    try {
      var res = await LoginService.get().checkIfCodeIsValid(userid, code);
      if (res.CODE) {
        checkAssociatedClients(res.clients, userid);
      } else {
        setShowNoAccess(true);
      }
    } catch (err) {
      setShowNoAccess(true);
    }
  };

  useEffect(() => {
    // get userid and code from params
    const { userid, code } = { ...props.match.params };
    // if user is logged in, then redirect user to the initial path of the application
    if (check_If_User_LoggedIn()) {
      document.body.classList.remove("bg-img");
      const applicationInitialPath = `${process.env.REACT_APP_PROJECT_NAME}${path.QUEEN_BASE_PATH}`;
      window.location.href = applicationInitialPath;
    } else {
      document.body.classList.remove("bg-img");
      // if user is not logged in, then check if the code sent on the url is valid and run the login process
      checkIfCodeIsValid(userid, code);
      storeLoginTimeStampBeforeJWT();
    }
  }, []);

  const displayChooseClientForm = () => {
    const initialClientFormValues = {
      clientid: "",
    };
    const clintFormValidationSchema = Yup.object().shape({
      clientid: Yup.number().required(polyglot.t("Client is required")),
    });
    const submitForm = (values) => {
      const { userid } = { ...props.match.params };
      const clientuserid = associatedClients.find((obj) => obj.clientid === values.clientid).clientuserid;
      const loginDetails = {
        clientUserId: userid,
        clientDBName: clientuserid,
      };
      login(loginDetails);
    };
    const backToLogin = () => {
      expire_my_session();
    };
    return (
      <Formik
        onSubmit={submitForm}
        initialValues={initialClientFormValues}
        validationSchema={clintFormValidationSchema}
        data-testid="choose-client-form"
      >
        {(prop) => {
          const { values, handleChange, handleSubmit, errors, touched } = prop;
          return (
            <form onSubmit={handleSubmit}>
              <div>
                <Select
                  placeholder={polyglot.t("Choose a client *")}
                  multiple={false}
                  value={values.clientid}
                  showSearchOnToggle={true}
                  onSelect={(value) => handleChange("clientid")({ target: { value } })}
                  className="Box"
                  aria-label={polyglot.t("Choose a client *")}
                >
                  <SelectGroupTitle>{polyglot.t("Client")}</SelectGroupTitle>
                  {associatedClients.map((clientObj) => {
                    return (
                      <SelectOption key={clientObj.clientid} value={clientObj.clientid}>
                        {!isNumeric(clientObj.clientname) ? clientObj.clientname : clientObj.clientid}
                      </SelectOption>
                    );
                  })}
                </Select>
                {errors.clientid && touched.clientid ? (
                  <span className="error">{errors.clientid}</span>
                ) : null}
                <br />
                <div className="row">
                  <div className="col-md-6">
                    <ButtonPrimary type="submit" ariaLabel={polyglot.t("Submit")}>
                      {polyglot.t("Submit")}
                    </ButtonPrimary>
                  </div>
                  <div className="col-md-6">
                    <p className="ForgotPassword p-t-5">
                      <LinkText onClick={backToLogin} ariaLabel={polyglot.t("Back to login")}>
                        {polyglot.t("Back to login")}
                      </LinkText>
                    </p>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  };

  return (
    <div>
      {showNoAccess && (
        <div className="loginBackground-without-img">
          <div className="d-flex">
            <div className="container">
              <NoAccess />
              <div className="row">
                <div className="col-md-4"></div>
                <div className="col-md-4 text-align-center">
                  <ButtonPrimary onClick={expire_my_session} ariaLabel={polyglot.t("Back to login")}>
                    {polyglot.t("Back to login")}
                  </ButtonPrimary>
                </div>
                <div className="col-md-4"></div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!showNoAccess && associatedClients.length > 0 && (
        <div className="login-bg-image">
          <div className="container">
            <div className="login-form">
              <div className="d-flex">
                <div className="card bg-light margin-0-auto min-width-25">
                  <div className="card-header header_login Header_resposnive_login">
                    <h1 className="headerText_Login">{polyglot.t("Welcome to Queen")}</h1>
                  </div>
                  <div className="card-body user-login">{showChooseClient && displayChooseClientForm()}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ChooseClientOnLogin;
