/* eslint-disable no-mixed-spaces-and-tabs */
import { Panel, Tooltip } from "appkit-react";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";

import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import "../../../Assets/Css/GRC/RightPanel.css";

// VIEW REQUEST COMPONENT
function ViewResponse({ Item, handleClick }) {
  var polyglot = polyglotLoader();
  // VIEW REQUEST NAME ON HOVER
  const TooltipRequestName = (Item) => {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#000",
                  textTransform: "capitalize",
                }}
              >
                {Item}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  // STATUS TAG
  const statusTag = () => {
    if (Item.status === 0) {
      return polyglot.t("open");
    }
    if (Item.status === 1) {
      return polyglot.t("closed");
    }
  };

  // ACTIONS
  const actions = () => {
    return (
      <>
        <ButtonIcon
          alt="More info"
          className="appkiticon icon-view-fill analysisGridViewIcon editer_view"
          title={polyglot.t("more_info")}
          onClick={() => handleClick(Item)}
          ariaLabel={polyglot.t("more_info")}
        />
      </>
    );
  };

  //Response Page Data
  return (
    <div>
      {
        <div className="p-0 m-b-2">
          <div className="row view-center mr-0">
            <div className="col-md-12 p-0">
              <Panel className="a-panel-project">
                <div className="a-text-heading  a-mb-10">
                  <div className="row">
                    <div className="col-md-3 m-auto">
                      <Tooltip content={TooltipRequestName(Item.email)} placement="top">
                        <div className="project-title-overflow recipientTable f-acc view-center-name project-name">
                          {Item.email}
                        </div>
                      </Tooltip>
                    </div>
                    <div className="col-md-3 m-auto">
                      <div className="view-center-imssage"></div>
                    </div>
                    <div className="col-md-2 m-auto">
                      <div className="view-center-imssage">
                        {Item.complete}/{Item.total}
                      </div>
                    </div>
                    <div className="col-md-2 m-auto">
                      <div className="view-center-imssage">{statusTag()}</div>
                    </div>
                    <div className="col-md-2 m-auto ">
                      <div className="row pl-1 ">{actions()}</div>
                    </div>
                  </div>
                </div>
              </Panel>
            </div>
          </div>
        </div>
      }
    </div>
  );
}
ViewResponse.propTypes = {
  Item: PropTypes.object,
  index: PropTypes.number,
  history: PropTypes.object,
};

export default withRouter(ViewResponse);
