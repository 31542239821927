/* eslint-disable no-mixed-spaces-and-tabs */
import {
  Input,
  TextArea,
  Accordions,
  AccordionItem,
  AccordionItemHeader,
  AccordionItemBody,
} from "appkit-react";
import { TextEditor } from "appkit-react-editor";
import React, { useState } from "react";

import { useHttpClient } from "../../../../Services/GRC/http-hook";
import { polyglotLoader } from "../../../../Services/Shared/TranslateGRC";
//import RadioGroupCustom from "../../../Assets/Components/RadioGroupCustom/RadioGroupCustom";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";
import { checkIfNumberIsDecimal } from "../../handlers";

const EditDependancyAnswerBlock = ({ setAnswerdata, answers, disableAnswerTypes, answerTypeId, usemode }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const keyOfFirstAnswerBlock = "answer-block1";
  const [expandCollapseKey, setExpandCollapseKey] = React.useState([keyOfFirstAnswerBlock]);
  // const taggleHandle = () => settoggleview((toggleview) => !toggleview);
  const polyglot = polyglotLoader();
  // modal
  const [showModal, setShowModal] = useState(false);
  const [redioQuestionsDefaultValue, setRedioQuestionsDefaultValue] = useState(-1);
  const [questionDescription, setQuestionDescription] = useState("");
  const [currentquestion, setCurrentQuestion] = useState(0);
  //const [chieldCateqoriesByRoot, setChieldCateqoriesByRoot] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const [scoringError, setScoringError] = useState("");
  const [weightageError, setWeightageError] = useState("");

  // VALIDATION OF SCORING FIELD
  const validationOfScoring = (value) => {
    try {
      let validationSuccessful = false;
      let errorMsg = "";
      // should not consider a decimal value
      if (checkIfNumberIsDecimal(Number(value))) {
        errorMsg = "Scoring cannot be in decimal value";
      }
      // min = 1, max = no limit
      if (Number(value) < 1) {
        errorMsg = "Scoring cannot be less than one";
      }
      setScoringError(errorMsg);
      if (errorMsg === "") {
        // validation successful
        validationSuccessful = true;
      } else {
        validationSuccessful = false;
      }
      return validationSuccessful;
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // VALIDATION OF WEIGHTAGE FIELD
  const validationOfWeightage = (value) => {
    try {
      let validationSuccessful = false;
      let errorMsg = "";
      // min = 0.1, max = no limit
      if (Number(value) < 0.1) {
        errorMsg = "Weightage cannot be less than 0.1";
      }
      setWeightageError(errorMsg);
      if (errorMsg === "") {
        // validation successful
        validationSuccessful = true;
      } else {
        validationSuccessful = false;
      }
      return validationSuccessful;
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // VALIDATION OF ANSWER TEXT
  const validationOfAnswerText = (value) => {
    try {
      let validationSuccessful = false;
      // restrict the user with max of 2000 char limit
      if (value.length > 2000) {
        // do not update the value
        validationSuccessful = false;
      } else {
        validationSuccessful = true;
      }
      return validationSuccessful;
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // VALIDATION OF ADDITIONAL ANSWER TEXT
  const validationOfAdditionalAnswerText = (value) => {
    try {
      let validationSuccessful = false;
      // restrict the user with max of 2000 char limit
      if (value.length > 2000) {
        // do not update the value
        validationSuccessful = false;
      } else {
        validationSuccessful = true;
      }
      return validationSuccessful;
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // updates the answer data
  const updateAnswerData = (e, index, field) => {
    try {
      const updateData = answers.map((item, i) => (i === index ? Object.assign(item, { [field]: e }) : item));
      setAnswerdata(updateData);
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  const handleChanges = (e, index, field) => {
    switch (field) {
      case "answer_text":
        if (validationOfAnswerText(e)) {
          updateAnswerData(e, index, field);
        } else {
          // validation of answer_text is failed
        }
        break;
      case "additional_answer_text":
        if (validationOfAdditionalAnswerText(e)) {
          updateAnswerData(e, index, field);
        } else {
          // validation of additional_answer_text is failed
        }
        break;
      case "scoring":
        // validation of scoring
        updateAnswerData(e, index, field);
        if (validationOfScoring(e)) {
          // validation of scoring is successful and is valid
        } else {
          // validation of scoring is failed
          updateAnswerData(true, index, "hasError");
        }
        break;
      case "weightage":
        // validation of weightage
        updateAnswerData(e, index, field);
        if (validationOfWeightage(e)) {
          // validation of weightage is successful and is valid
        } else {
          // validation of weightage is failed
          updateAnswerData(true, index, "hasError");
        }
        break;
      default:
        updateAnswerData(e, index, field);
    }
    // all the validations are successfull, then update hasError to false
    const scoringValue = answers[index].scoring;
    const weightageValue = answers[index].weightage;
    if (validationOfScoring(scoringValue) && validationOfWeightage(weightageValue)) {
      updateAnswerData(false, index, "hasError");
    }
  };
  const openModal = (cIndex) => {
    setCurrentIndex(cIndex);
    setShowModal(true);
  };
  const addDependancy = () => {
    // update handle change
    const updateData = answers.map((item, i) =>
      i === currentIndex ? Object.assign(item, { ["dependency_question_id"]: currentquestion }) : item,
    );
    setAnswerdata(updateData);
    setShowModal(false);
  };

  const updateQuestionDescription = (description, questionid) => {
    setQuestionDescription(description);
    setCurrentQuestion(questionid);
  };

  // EXPANDINGA ND COLLAPSEING BASED ON CONDITION
  const expandCollpaseHandler = (e) => {
    if (expandCollapseKey[0] === e) {
      setExpandCollapseKey([]);
    } else {
      setExpandCollapseKey([e]);
    }
  };

  const getAnswerBox = ({ answerIndex, answer, isReadonly }) => {
    const defaultProps = {
      placeholder: polyglot.t("answer_of_your_quest"),
      className: "Notes question-txt-area",
      "aria-label": polyglot.t("answer_of_your_quest"),
      value: answer.answer_text,
    };

    if (isReadonly || answerTypeId === 5) {
      return (
        <div className="readonly-answer-block" dangerouslySetInnerHTML={{ __html: answer.answer_text }}></div>
      );
    }

    return (
      <TextEditor
        {...defaultProps}
        initialHtmlContent={answer.answer_text}
        toolbar={{
          controls: [["Bold", "Italic"]],
        }}
        onChange={(e) => {
          handleChanges(e, answerIndex, "answer_text");
        }}
      />
    );
  };

  // DISPLAY ANSWER BLOCK IN ACCORDIANS
  const displayAnswerBoxInAccordians = (answer, i) => {
    try {
      const key = `answer-block${i + 1}`;
      // READONLY WHEN ANSWER TEXT IS BLANK WHICH IN CASE OF CUSTOME AND FREETEXTBOX
      let readonlyBox = disableAnswerTypes.includes(answer.answer_text);
      if (usemode && usemode == "readonly") {
        readonlyBox = true;
      }
      if (answer.answer_text === "not applicable") {
        return <></>;
      } else {
        return (
          <AccordionItem itemId={key} key={key}>
            <AccordionItemHeader title={`Answer ${i + 1}`} />
            <AccordionItemBody>
              <div className="ans-block mb-5">
                <div className="row mt-4">
                  <div className="col-md-6">
                    {getAnswerBox({ answer, isReadonly: readonlyBox, answerIndex: i })}
                    {/* IF FIELD IS EDITABLE, THEN SHOW THE BELOW div */}
                    {!readonlyBox && (
                      <div>
                        {/* MAX NUMBER OF CHARACTERS ALLOWED IS 2000 */}
                        {answer.answer_text.length}/2000
                      </div>
                    )}
                  </div>
                  <div className="col-md-6">
                    <TextArea
                      placeholder={polyglot.t("additional_answer_information_texts")}
                      disabled={usemode && usemode == "readonly" ? true : false}
                      className="Notes question-txt-area"
                      aria-label={polyglot.t("additional_answer_information_texts")}
                      onChange={(e) => {
                        handleChanges(e, i, "additional_answer_text");
                      }}
                      value={answer.additional_answer_text}
                    />
                    {/* MAX NUMBER OF CHARACTERS ALLOWED IS 2000 */}
                    {usemode && usemode == "readonly" ? null : (
                      <div>{answer.additional_answer_text.length}/2000</div>
                    )}
                  </div>
                </div>
                <div className="row mt-4">
                  <div className="col-md-4"></div>
                  <div className="col-md-4"></div>
                  <div className="col-md-4">
                    <Input
                      type="number"
                      inputBoxSize="sm"
                      placeholder="Weightage *"
                      disabled={usemode && usemode == "readonly" ? true : false}
                      onChange={(e) => {
                        handleChanges(e, i, "weightage");
                      }}
                      value={answer.weightage.toString()}
                    />
                    {answer.hasError && weightageError.length > 0 && (
                      <div className="error">{weightageError}</div>
                    )}
                  </div>
                </div>
              </div>
            </AccordionItemBody>
          </AccordionItem>
        );
      }
    } catch (err) {
      displayToastMessage(`Error while displaying answer block, ${err.message}`, "error");
    }
  };

  return (
    <>
      <div className="overflow-auto answers-block-accordian">
        <Accordions activeItem={expandCollapseKey} onChange={expandCollpaseHandler}>
          {answers.map((answer, i) => {
            return displayAnswerBoxInAccordians(answer, i);
          })}
        </Accordions>
      </div>
    </>
  );
};

/* AnswerBlock.propTypes = {
  handleChange: PropTypes.func,
}; */
export default EditDependancyAnswerBlock;
