/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-spaces-and-tabs */
import "../../../Assets/Css/QueenBase/Root/Header.css";
import { Avatar, Select, SelectOption, Badge } from "appkit-react";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { withRouter, useLocation } from "react-router-dom";
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from "reactstrap";

import TimeAgo from "./TimeAgo";
import displayToastMessage from "./displayToastMessage";
import icongroup from "../../../Assets/Icons/avatar-outline.svg";
import LinkText from "../../../Assets/Components/LinkText/LinkText";
import { logoutIcon, homeImg } from "../../../Assets/Icons";
import UserService from "../../../Services/QueenBase/Users/UserService";
import CommonService from "../../../Services/Shared/CommonService";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import notification from "../../../Assets/Icons/bell.svg";
import logo from "../../../Assets/Icons/logo.png";
import loginicon from "../../../Assets/Images/QueenBase/Root/icon-login.svg";
import * as path from "../../../Constants/Path";
import NotificationService from "../../../Services/QueenBase/Notification/NotificationService";
import ApplicationCongif from "../../../Services/Shared/ApplicationCongif";
import {
  check_If_User_LoggedIn,
  expire_my_session,
  getFirstNameFromSession,
} from "../../../Services/Shared/Authenticate";
import { DataContext } from "../../../Services/Shared/Store.js";
import { polyglotLoader, setCurrentLanguage } from "../../../Services/Shared/Translate";
import { subscribeToNotifications } from "../../../Services/Shared/WebSocket";

function Header(props) {
  const {
    setUserLanguage,
    getUserLanguage,
    updateUserImageOnHeader,
    isUpdateUserImageOnHeader,
    isFormOpen,
    formGotClosed,
    userProfileImage,
    asignUserProfileImage,
  } = useContext(DataContext);
  const [NotificationList, setNotificationListList] = useState([]);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [applicationName, SetApplicationName] = useState();
  const [notificationCount, setNotificationCount] = useState(0);
  const [userOptionsOpen, setUserOptionsOpen] = useState(false);
  const [expandHeader, setExpandHeader] = useState(false);

  const [openConfirmBox, setOpenConfirmBox] = useState(false); // this value handles the confirm box of exit form
  const [clientName, setClientName] = useState("");
  const [isTestAccount, setIsTestAccount] = useState(false);
  const location = useLocation();
  let count = 0;
  const language = getUserLanguage();
  const toggle = () => setDropdownOpen((prevState) => !prevState);
  const toggleUserOptions = () => setUserOptionsOpen((prevState) => !prevState);

  const home = () => {
    if (sessionStorage.getItem("jwt")) {
      props.history.push(path.QUEEN_BASE_PATH);
      window.location.reload();
    } else props.history.push(path.LOGIN_PATH);
  };
  const projectHome = () => {
    if (sessionStorage.getItem("jwt")) {
      props.history.push(path.PROJECT_PATH);
      window.location.reload();
    } else props.history.push(path.LOGIN_PATH);
  };

  const UserProfileroute = () => {
    props.history.push(path.USER_PROFILE);
  };
  const logOut = () => {
    expire_my_session();
  };
  const tootipdata = () => {
    return (
      <div onClick={UserProfileroute} className="tip-data">
        Edit Profile
      </div>
    );
  };
  var polyglot = polyglotLoader(props.language);
  const changeLanguage = (value) => {
    setCurrentLanguage(value);
    setUserLanguage(value);
  };

  const orderByCreatedOn = (notificationsList) => {
    return notificationsList.sort((a, b) => {
      return new Date(b.NOTIFICATION_CREATED_ON) - new Date(a.NOTIFICATION_CREATED_ON);
    });
  };

  const assignNotificationsToState = (fetchedNotifications) => {
    let unReadCount = 0;
    fetchedNotifications.forEach((item, index) => {
      if (item.NOTIFICATION_ISREAD === false) {
        unReadCount += 1;
      }
    });
    const formatedNotifications = orderByCreatedOn(fetchedNotifications);
    setNotificationListList(formatedNotifications);
    setNotificationCount(unReadCount);
  };

  // GET LOGGEDIN USER PROFILE DETAILS
  async function getUserLoggedinProfile() {
    try {
      const res = await UserService.get().getUserProfile();
      if (res.CODE) {
        if (res.image) {
          var imageBlob = await CommonService.get().getBufferImageInBlob(res.image);
          var imageStr = URL.createObjectURL(imageBlob);
          asignUserProfileImage(imageStr);
        } else {
          asignUserProfileImage("");
        }
        setClientName(res.clientName);
        setIsTestAccount(res.isTestAccount);
      } else {
        displayToastMessage(res.MSG, "error");
      }
      updateUserImageOnHeader(false);
    } catch (err) {
      displayToastMessage(polyglot.t("Error while fetching user details"), "error");
    }
  }

  // get user image when isUpdateUserImageOnHeader is true
  useEffect(() => {
    if (isUpdateUserImageOnHeader) {
      getUserLoggedinProfile();
    }
  }, [isUpdateUserImageOnHeader]);

  useEffect(() => {
    let isFetchNotificationErrorShown = false;
    subscribeToNotifications(language, (data) => {
      if (data.CODE) {
        assignNotificationsToState(data.notifications);
      } else {
        if (!isFetchNotificationErrorShown) {
          displayToastMessage(polyglot.t("Error while fetching notifications"), "error");
          isFetchNotificationErrorShown = true;
        }
      }
    });
  }, [language]);

  // API TO GET APPLICATION CONFIG DETAILS
  async function getApplicationConfig() {
    const config = await ApplicationCongif.get().getApplicationConfig();
    SetApplicationName(config.title);
  }
  useEffect(() => {
    getApplicationConfig();
  }, []);

  // API TO MARK NOTIFICATION AS READ
  const markNotificationAsRead = async (notificationId) => {
    try {
      // API TO MARK NOTIFICATION AS READ
      NotificationService.get().markNotificationAsRead(notificationId);
    } catch (err) {
      displayToastMessage(this.polyglot.t("Failed updating notification status"), "error");
    }
  };

  const notificationClicked = (notificationId) => {
    markNotificationAsRead(notificationId);
  };
  const showUserImage = () => {
    const invalidImageformats = ["undefined", "null", undefined, null, ""];
    const storedUserImage = userProfileImage;
    if (invalidImageformats.indexOf(storedUserImage) >= 0) {
      return icongroup;
    }
    return storedUserImage;
  };
  const sortNotifications = (a, b) => {
    return new Date(b.NOTIFICATION_CREATED_ON).getTime() - new Date(a.NOTIFICATION_CREATED_ON).getTime();
  };

  // API TO GET APPLICATION CONFIG DETAILS
  async function getApplicationConfig() {
    const config = await ApplicationCongif.get().getApplicationConfig();
    SetApplicationName(config.title);
  }
  useEffect(() => {
    getApplicationConfig();
  }, []);

  const captureHomeKeyUp = (event) => {
    if (event.keyCode === 13) {
      return checkFormStatusAndRedirect();
    }
  };

  const expandHeaderFunc = () => {
    setExpandHeader((prev) => !prev);
  };
  // CLOSE FORM AND NAVIGATE TO VIEW PROJECTS
  const closeFormAndRedirect = () => {
    toggleConfirmBox();
    home();
    formGotClosed();
  };
  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  const toggleConfirmBox = () => {
    setOpenConfirmBox((prev) => !prev);
  };
  // CHECK FORM STATUS AND REDIRECT
  const checkFormStatusAndRedirect = () => {
    if (isFormOpen) {
      // form is in open state, hence user cannot navigate to other screens
      toggleConfirmBox();
    } else {
      home();
    }
  };

  return (
    <header style={{ height: "73px" }}>
      <nav style={{ height: "73px" }} className="navbar navbar-expand-lg navbar-light white-header pt-0 pb-0">
        <a
          className="navbar-brand"
          tabIndex="1"
          onKeyUp={captureHomeKeyUp}
          onClick={checkFormStatusAndRedirect}
        >
          <img
            className="logoimg"
            alt="logo"
            src={logo}
            title="PwC - Connected Digital Services"
            aria-label="PwC - Connected Digital Services"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          onClick={expandHeaderFunc}
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse justify-content-end ${expandHeader && "show"}`}>
          <ul className="navbar d-flex mb-0 pt-0 pb-0">
            <li className="active mr-2" aria-label={polyglot.t("Notification bell")}>
              {check_If_User_LoggedIn() && (
                <Dropdown className="customDropDownNotification_bell" isOpen={dropdownOpen} toggle={toggle}>
                  <DropdownToggle>
                    <img
                      alt="notification-bell"
                      src={notification}
                      width={30}
                      height={30}
                      aria-label={polyglot.t("Notification bell")}
                    />
                    <Badge className="notification-badge-appkit" size="small">
                      {notificationCount >= 100 ? "99+" : notificationCount}
                    </Badge>
                  </DropdownToggle>
                  <DropdownMenu className="notification-dropdown-list">
                    <div className="t-a-c-bell_notification">
                      <div
                        className={
                          NotificationList.length > 0 ? "list-of-notifications notification-scrollBar" : ""
                        }
                      >
                        {NotificationList.length > 0 &&
                          NotificationList.sort(sortNotifications).map((notificationData, index) => {
                            count++;
                            return (
                              <DropdownItem
                                key={index}
                                className={notificationData.NOTIFICATION_ISREAD ? "bg-read" : "bg-unread"}
                                onClick={() => notificationClicked(notificationData.NOTIFICATION_ID)}
                              >
                                <ul className="notification__list dropdown hide m-b-0">
                                  <li className="list__item">
                                    <a className="list__item--link p-b-0 p-t-0">
                                      <span
                                        className="bell-messages"
                                        dangerouslySetInnerHTML={{
                                          __html: notificationData.NOTIFICATION_MESSAGE,
                                        }}
                                      ></span>
                                    </a>
                                  </li>
                                  <div className="notification-footer">
                                    <span className="dateField">
                                      <TimeAgo seconds={notificationData.seconds} /> by{" "}
                                      <b>{notificationData.CREATED_BY}</b>
                                    </span>
                                  </div>
                                </ul>
                              </DropdownItem>
                            );
                          })}
                        {NotificationList.length === 0 && (
                          <span className="messages">
                            <DropdownItem>
                              <ul className="notification__list dropdown hide m-b-0">
                                <li className="list__item">
                                  <a className="list__item__link p-b-0 p-t-0">
                                    <span className="bell-messages">
                                      <b> {polyglot.t("Currently you don't have  any  Notification.")} </b>
                                    </span>
                                  </a>
                                </li>
                              </ul>
                            </DropdownItem>
                          </span>
                        )}
                      </div>
                    </div>
                  </DropdownMenu>
                </Dropdown>
              )}
            </li>
            <li>
              <ButtonIcon
                imgWidth={24}
                iconImg={homeImg}
                onClick={projectHome}
                alt="homeimg"
                title={polyglot.t("home")}
                ariaLabel={polyglot.t("home")}
              />
            </li>
            <li id="headerdownarrow" className="" aria-label={polyglot.t("Change language")}>
              {getUserLanguage() === "ger" && (
                <Select
                  defaultValue={getUserLanguage()}
                  onSelect={changeLanguage}
                  aria-label={polyglot.t("Change language")}
                >
                  <SelectOption key={1} value={"en"}>
                    EN
                  </SelectOption>
                  {/* <SelectOption key={2} value={'ger'}>
                          DE
                      </SelectOption> */}
                </Select>
              )}
              {getUserLanguage() === "en" && (
                <Select
                  defaultValue={getUserLanguage()}
                  onSelect={changeLanguage}
                  aria-label={polyglot.t("Change language")}
                >
                  <SelectOption key={1} value={"en"}>
                    EN
                  </SelectOption>
                  {/* <SelectOption key={2} value={'ger'}>
                          DE
                      </SelectOption> */}
                </Select>
              )}
            </li>

            <li className="dropdown">
              {check_If_User_LoggedIn() && (
                <>
                  <Dropdown
                    className="customDropDown_useroptions"
                    isOpen={userOptionsOpen}
                    toggle={toggleUserOptions}
                  >
                    <DropdownToggle>
                      <div className="h-avatar-text">
                        <Avatar
                          src={
                            <img
                              alt=""
                              style={{
                                borderRadius: "100%",
                                height: "24px",
                                width: "24px",
                                border: "3px",
                              }}
                              src={showUserImage()}
                            />
                          }
                        />
                        &nbsp;
                        <div className="useroptions-username">
                          <div className="text-align-left">{getFirstNameFromSession()}</div>
                          <div className="text-align-left">{clientName}</div>
                          <div className="text-align-left test-account">
                            {isTestAccount && polyglot.t("Test account")}
                          </div>
                        </div>
                      </div>
                    </DropdownToggle>
                    <DropdownMenu className="user-options-menu">
                      <DropdownItem onClick={UserProfileroute}>
                        <div className="cursor">{polyglot.t("Edit Profile")}</div>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </>
              )}
            </li>
            <li className="pl-2">
              {check_If_User_LoggedIn() && (
                <LinkText onClick={logOut} ariaLabel={polyglot.t("Logout")} href={"javascript:;"}>
                  <img
                    width={20}
                    height={20}
                    className={`appkiticon ${logoutIcon} w-32`}
                    src={loginicon}
                    alt="logout"
                  />
                  &nbsp;{polyglot.t("Logout")}
                </LinkText>
              )}
            </li>
          </ul>
        </div>
      </nav>
      {/* This confirm box will open if the form in the application is in open state */}
      <ConfirmBoxExitForm
        show={openConfirmBox}
        heading={"Cancel update"}
        cancel={toggleConfirmBox}
        proceed={closeFormAndRedirect}
      />
    </header>
  );
}
Header.propTypes = {
  history: PropTypes.object,
  language: PropTypes.func,
  props: PropTypes.object,
};
export default withRouter(Header);
