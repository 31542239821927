import React, { Component } from "react";
import $ from "jquery";
import {
  Select,
  SelectOption,
  Button,
  Accordions,
  AccordionItem,
  AccordionItemHeader,
  AccordionItemBody,
} from "appkit-react";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "antd/dist/antd.css";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import { DataContext } from "../../../../Services/Shared/Store.js";
import displayToastMessage from "../../../../Components/QueenBase/Root/displayToastMessage";
import {
  getproductiondetailsGridDetails,
  editProductionService,
} from "../../../../Services/DFED/AdminService";
import { getDisplayDate } from "../../../../Services/Shared/Handlers";
import CustomModal from "../../../../Assets/Components/Modal";
import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { getProductionDetailsById, downloadProductionFile } from "../../../../Services/DFED/ReviewService";
import LinkText from "../../../../Assets/Components/LinkText/LinkText";
import "../../../../Assets/Css/DFED/ProductionGrid.css";
$.DataTable = require("datatables.net-dt");
$.fn.DataTable.ext.errMode = "none";
require("datatables.net-buttons-dt");
require("datatables.net-buttons/js/buttons.html5.min");
// THIS CLASS IS RESPONSIBLE FOR HANDLING ADMIN PRODUCTION TAB RELATED FUNCTIONALITY===================
class ProductionGrid extends Component {
  polyglot = polyglotLoader();
  constructor(props) {
    super(props);
    this.state = {
      projectid: this.props.projectid,
      projectname: this.props.projectname,
      productionGridData: [],
      visible: false,
      tabValue: "1",
      selectedEditId: "",
      editedPrepareReviewStatus: null,
      fileDetails: "",
      isCorrectFile: false,
      searchType: "",
      productionDetails: [],
      projectName: "",
    };
  }
  static contextType = DataContext;
  // LIFECYCLE METHOD FOR GETTING DATA =================
  componentDidMount() {
    this.getProductionDetails();
    // this.dataStructureForProductionGrid(this);
  }
  //get review grid details
  async getProductionDetails() {
    let reviewInfo = await getproductiondetailsGridDetails(this.state.projectid);
    if (reviewInfo && reviewInfo.data) {
      reviewInfo.data.forEach(function (a) {
        a.REVIEW_UPLOADED_ON = a.REVIEW_UPLOADED_ON.substring(0, 10);
      });
      this.setState(
        {
          productionGridData: reviewInfo.data,
        },
        () => {
          this.dataStructureForProductionGrid(this);
        },
      );
    }
  }
  // FILE VALIDATION=====================
  validateFileType = (event) => {
    if (!event.target.files[0]) {
      $("#anaKeyWedExcelTypeErr1").text("");
      $("#anaKeyWedExcelTypeSucessfull2").text("");
      this.setState({ isCorrectFile: false });
      return false;
    }
    let excelFile = event.target.files[0];
    let allowType2 = ["x-zip-compressed", "zip", "zip-compressed"];
    let errMsg = "";
    let tempType = excelFile.type.split("/");
    $("#anaKeyWedExcelTypeErr1").text(errMsg);
    if (!allowType2.includes(tempType[1])) {
      errMsg = "  " + excelFile.name + this.polyglot.t(" is not valid file type.");
      $("#anaKeyWedExcelTypeErr1").text(errMsg);
      this.setState({ isCorrectFile: false });
      return false;
    }
    var fileReader = new FileReader();
    const scope = this;
    const polyglot = polyglotLoader();
    fileReader.readAsDataURL(excelFile);
    fileReader.onload = function (e) {
      scope.setState(
        {
          fileDetails: { data: fileReader.result.split(",").pop(), fileName: excelFile.name },
        },
        () => {
          // $("#anaKeyWedExcelTypeSucessfull2").text(polyglot.t("File Validate Sucessfully."));
          setTimeout(function () {
            $("#anaKeyWedExcelTypeSucessfull2").text("");
          }, 2500);
        },
      );
      scope.setState({
        isCorrectFile: true,
      });
      return true;
      // processExcel(e.target.result, scope)
    };
  };
  // REVIEW ONCAHNGE======================

  prepareReviewOnChng = (value) => {
    if (Number(value)) {
      this.setState({
        editedPrepareReviewStatus: value,
      });
    }
    if (Number(value) !== 3) {
      this.setState({
        fileDetails: "",
        isCorrectFile: false,
      });
    }
  };

  dommanipulation = () => {};

  // SHOW MODAL FUNCTIONALITY======================
  showModal = (status_id, selectedId) => {
    this.setState(
      {
        selectedEditId: selectedId,
        visible: true,
        editedPrepareReviewStatus: String(status_id),
      },
      () => {
        this.getSelectedProductionDetails(this);
      },
    );
  };
  handleCancel = (e) => {
    this.setState({
      visible: false,
      fileDetails: "",
    });
    if (this.fileInput) {
      this.fileInput.value = "";
    }
    $("#anaKeyWedExcelTypeErr1").text("");
    $("#anaKeyWedExcelTypeSucessfull2").text("");
  };
  // MODAL SAVE FUNCTIONALITY====================
  handleConfirm = async (e) => {
    let isIntrruptEdit = false;
    if (this.state.selectedEditId && this.state.selectedEditId !== "") {
    } else {
      isIntrruptEdit = true;
    }
    if (isIntrruptEdit === false) {
      let data = {
        REVIEW_STATUS: this.state.editedPrepareReviewStatus,
        editId: this.state.selectedEditId,
        PROJECT_ID: this.state.projectid,
        projectname: this.state.projectname,
      };
      let formData = {};
      formData = new FormData();
      if (this.state.fileDetails !== "") {
        formData.append("production_file", JSON.stringify(this.state.fileDetails));
        formData.append("docproduction_data", JSON.stringify(data));
      } else {
        formData.append("docproduction_data", JSON.stringify(data));
      }
      let isEditArtm = await editProductionService(formData);
      if (isEditArtm && isEditArtm.hasOwnProperty("status")) {
        if (parseInt(isEditArtm.status) === 200) {
          if (isEditArtm.data == "The file uploaded is invalid") {
            displayToastMessage(this.polyglot.t(isEditArtm.data), "error");
          } else {
            displayToastMessage(this.polyglot.t(isEditArtm.data), "success");
          }
          this.getProductionDetails();
          if (this.fileInput) {
            this.fileInput.value = "";
          }
        }
      } else {
        //("Error while Edit request.")
      }
      this.setState({
        visible: false,
        fileDetails: "",
      });
    } else {
      //alert("Error while validating your input.")
    }
  };
  onTabChange(e) {
    this.setState({
      tabValue: e.target.getAttribute("data-value"),
    });
  }
  dataStructureForProductionGrid = () => {
    var tempData = this.state.productionGridData;
    var dataForTbl = [];
    tempData.forEach((ele1) => {
      let editedPrepareReviewStatus;
      if (
        ele1.REVIEW_STATUS === "0" ||
        ele1.REVIEW_STATUS === "1" ||
        ele1.REVIEW_STATUS === null ||
        ele1.REVIEW_STATUS === ""
      ) {
        editedPrepareReviewStatus = this.polyglot.t("Not Started");
      } else if (ele1.REVIEW_STATUS === "2") {
        editedPrepareReviewStatus = this.polyglot.t("In Progress");
      } else {
        editedPrepareReviewStatus = this.polyglot.t("Completed");
      }
      ele1.REVIEW_UPLOADED_ON = getDisplayDate(ele1.REVIEW_UPLOADED_ON);
      dataForTbl.push([
        ele1.Req_ID,
        ele1.CP_PROJECT_NAME,
        ele1.REVIEW_SEARCH_TYPE,
        ele1.REVIEW_UPLOADED_ON,
        editedPrepareReviewStatus,
      ]);
    }); //OVER : MAIN FOREACH
    this.drawProductionGrid(this, dataForTbl);
  };
  drawProductionGrid = (temp1, dataForTbl) => {
    temp1.$el = $(temp1.el);
    // let pageLength = 7;
    var disText = "";
    temp1.$el.DataTable({
      lengthMenu: [5, 10, 15, 20],
      sDom: "lfrtp",
      oLanguage: {
        emptyTable: `${this.polyglot.t("No data available for this project")}`,
        sLengthMenu: `${this.polyglot.t("Show")} <select class="tabFocus">
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="-1">${this.polyglot.t("All")}</option>
        </select> ${this.polyglot.t("entries")}
        `,
        sSearch: `${this.polyglot.t("Search")}`,
      },
      columns: [
        { title: this.polyglot.t("ID") },
        { title: this.polyglot.t("Project Name") },
        { title: this.polyglot.t("Search Type") },
        { title: this.polyglot.t("Requested On") },
        { title: this.polyglot.t("Status") },
        { bVisible: false, searchable: false },
        {
          title: this.polyglot.t("Action"),
          class: "actionSortingRemove",
          data: null,
          mRender: (data, type, row) => {
            return (
              '<button aria-label="' +
              this.polyglot.t("edit") +
              '" class="appkiticon icon-edit-outline classprocessing_edit analysisGridEditIcon classproduction_" id="production_' +
              row[0] +
              "_" +
              row[4] +
              '" data-id="' +
              row[0] +
              '">  </button>&nbsp; &nbsp;' +
              '<button aria-label="' +
              this.polyglot.t("view") +
              '" class="appkiticon icon-view-fill analysisGridViewIcon editer_view"' +
              'id="view_model_' +
              row[0] +
              "_" +
              row[0] +
              "_" +
              row[2] +
              '"> </button>'
            );
          },
        },
      ],
      order: [[3, "desc"]],
      data: dataForTbl,
      lenghtType: "simple",
      searching: true,
      destroy: true,
      language: {
        info: "_PAGE_ of _PAGES_",
        paginate: {
          firt: "<<",
          previous: '<i_ngcontent-c10="" class="appkiticon icon-left-chevron-fill"> </i>',
          next: '<i_ngcontent-c10="" class="appkiticon icon-right-chevron-fill"> </i>',
          last: ">>",
        },
      },
      stateSave: true,
      drawCallback: function () {
        if (dataForTbl.length <= 0) {
          $(".dataTables_paginate").hide();
        } else {
          $(".dataTables_paginate").show();
        }
      }, // OVER : drawback function
    }); // OVER : Data Table

    var tempReactThis = this;
    $(document).on("click", "[id*='production_']", function () {
      const polyglot = polyglotLoader();
      let idData = this.id.split("_");
      let selectedId = idData[1];
      let editedPrepareReviewStatus = idData[2];
      let forId = [polyglot.t("Not Started"), polyglot.t("In Progress"), polyglot.t("Completed")];
      tempReactThis.showModal(forId.indexOf(editedPrepareReviewStatus) + 1, selectedId);
    });
    var tempReacThis = this;
    $(document).on("click", "[id*='view_model_']", function () {
      let idData = this.id.split("_");
      tempReacThis.showViewModal(tempReacThis, idData[2], idData[3], idData[4]);
    });
    disText = $("#analysisGridTable").text();
    var pagNum = "";
    pagNum = disText.replace(pagNum, "<span>" + pagNum + "</span>");
    disText = disText.replace(pagNum, "<span>" + pagNum + "</span>");
  }; // OVER : Draw Grid
  isSaveDisabled = () => {
    if (Number(this.state.editedPrepareReviewStatus) === 3) {
      if (this.state.fileDetails !== "" && this.state.isCorrectFile) {
        return false;
      }
      return true;
    }
    if (Number(this.state.editedPrepareReviewStatus)) {
      return false;
    }
    return true;
  };
  showViewModal = (temthis, id, projectName, searchType) => {
    this.setState(
      {
        selectedEditId: id,
        visibleViewModal: true,
        projectName: projectName,
        searchType: searchType,
      },
      () => {
        this.getSelectedProductionDetails(this);
      },
    );
  };
  // GETTING PRODUCTION DETAILS BASED ON PRODUCTION ID
  getSelectedProductionDetails = async () => {
    const polyglot = polyglotLoader();
    try {
      let productionId = "";
      let isProceed = false;
      if (this.state.selectedEditId !== "") {
        productionId = this.state.selectedEditId;
        isProceed = true;
      }
      if (isProceed) {
        const body = {
          productionId: productionId,
        };
        const { data: apiResponse } = await getProductionDetailsById(body);
        if (apiResponse.CODE) {
          const productionDetails = apiResponse.data;
          this.setState({
            productionDetails,
          });
        } else {
          displayToastMessage(polyglot.t(apiResponse.data.MSG), "error");
        }
      } else {
        displayToastMessage(polyglot.t("Something went wrong while fetching review details"), "error");
      }
    } catch (err) {
      displayToastMessage(polyglot.t(err.message), "error");
    }
  };
  handleViewCancel = (e) => {
    this.setState({
      visibleViewModal: false,
    });
  };
  // DOWNLOAD UPLOADED FILE ON CLICK
  downloadUplodedFile = async () => {
    const body = {
      productionId: this.state.selectedEditId,
    };
    try {
      // API TO DOWNLOAD UPLOADED FILE ON WORKFLOW
      const downloadfileResponse = await downloadProductionFile(body);
      const downloadfile = downloadfileResponse.data;
      if (downloadfile.CODE) {
        const buffer = Buffer.from(downloadfile.file.buffer);
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", downloadfile.file.fileName);
        link.click();
        displayToastMessage(downloadfile.MSG, "success");
      } else {
        if (downloadfile.error.errno === -4058) {
          displayToastMessage(`${this.polyglot.t("File not found")}!!`, "error");
        } else {
          displayToastMessage(`${this.polyglot.t("File not found")}!!`, "error");
        }
      }
    } catch (error) {
      if (
        (error.message = "First argument must be a string, Buffer, ArrayBuffer, Array, or array-like object.")
      ) {
      } else {
        displayToastMessage(error.message, "error");
      }
    }
  };

  render() {
    const { productionDetails } = this.state;
    return (
      <div className="container analysisGridContainer p-0">
        <div className="row analysisGridRow">
          <div className="col-md-12 DFED-Production-admin">
            <table
              id="analysisGridTable"
              className="display table-header-focus"
              width="100%"
              ref={(el) => (this.el = el)}
            />
          </div>
        </div>
        <div className="viewModelDiv">
          <CustomModal
            show={this.state.visibleViewModal}
            showCloseButton
            onCancel={this.handleViewCancel}
            title={this.polyglot.t(`Production details of ID : `) + this.state.projectName}
            footer={[]}
            size="sm"
          >
            <>
              <div className="modal-txt-open">
                {this.state.searchType !== "Criteria-based" && this.state.searchType !== "Criteria based" ? (
                  <div className="row">
                    <div className="f-acc-B col-md-6">{this.polyglot.t("Uploaded data")}:</div>
                    {productionDetails ? (
                      <div className="f-acc-B col-md-6 count-align-t">
                        {productionDetails.CLIENT_FILE_NAME ? (
                          <LinkText
                            onClick={this.downloadUplodedFile}
                            ariaLabel={this.state.productionDetails.CLIENT_FILE_NAME}
                          >
                            {this.state.productionDetails.CLIENT_FILE_NAME}
                          </LinkText>
                        ) : (
                          <b>{this.polyglot.t("File not available")}</b>
                        )}
                      </div>
                    ) : (
                      <div className="f-acc-B col-md-6 count-align-t cursor-notallowed">
                        <div>
                          <b>{this.polyglot.t("File not available")}</b>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  Object.keys(productionDetails).length > 0 &&
                  productionDetails.tags != null && (
                    <>
                      <div className="productionGrid">
                        <Accordions key={1} className="mb-2">
                          <AccordionItem itemId="1" key={1}>
                            <AccordionItemHeader
                              className="accordianHeightIssue"
                              title={this.polyglot.t("Custodian")}
                            />
                            <AccordionItemBody>
                              {[
                                ...new Set(
                                  productionDetails.details.map(
                                    ({ CUSTODIAN_FIRST_NAME }) => CUSTODIAN_FIRST_NAME,
                                  ),
                                ),
                              ].join(", ")}
                            </AccordionItemBody>
                          </AccordionItem>
                          <AccordionItem itemId="2" key={2}>
                            <AccordionItemHeader
                              className="accordianHeightIssue"
                              title={this.polyglot.t("Analysis")}
                            />
                            <AccordionItemBody>
                              {productionDetails.details.every(
                                ({ ANALYSIS_NAME }) => ANALYSIS_NAME !== null,
                              ) ? (
                                productionDetails.details.map(({ ANALYSIS_NAME, CUSTODIAN_FIRST_NAME }) => (
                                  <div>
                                    Custodian: {CUSTODIAN_FIRST_NAME} | Analysis: {ANALYSIS_NAME}
                                  </div>
                                ))
                              ) : (
                                <div>Analysis not selected</div>
                              )}
                            </AccordionItemBody>
                          </AccordionItem>
                          <AccordionItem itemId="3" key={3}>
                            <AccordionItemHeader
                              className="accordianHeightIssue"
                              title={this.polyglot.t("Datasets")}
                            />
                            <AccordionItemBody>
                              {productionDetails.details.map(
                                ({ ANALYSIS_NAME, DATASET_NAME, CUSTODIAN_FIRST_NAME }) => {
                                  if (ANALYSIS_NAME === null) {
                                    return (
                                      <div>
                                        Custodian: {CUSTODIAN_FIRST_NAME} | Dataset: {DATASET_NAME}
                                      </div>
                                    );
                                  }
                                  return (
                                    <div>
                                      Custodian: {CUSTODIAN_FIRST_NAME} | Analysis: {ANALYSIS_NAME} | Dataset:{" "}
                                      {DATASET_NAME}
                                    </div>
                                  );
                                },
                              )}
                            </AccordionItemBody>
                          </AccordionItem>
                          <AccordionItem itemId="4" key={4}>
                            <AccordionItemHeader
                              className="accordianHeightIssue"
                              title={this.polyglot.t("Tags")}
                            />
                            <AccordionItemBody>
                              <div>
                                {JSON.parse(productionDetails.tags)
                                  .map(({ review_criteria_tags }) => review_criteria_tags)
                                  .join(", ")}
                              </div>
                            </AccordionItemBody>
                          </AccordionItem>
                          <AccordionItem itemId="5" key={5}>
                            <AccordionItemHeader
                              className="accordianHeightIssue"
                              title={this.polyglot.t("Notes")}
                            />
                            <AccordionItemBody>
                              <div>{productionDetails.Notes}</div>
                            </AccordionItemBody>
                          </AccordionItem>
                        </Accordions>
                      </div>
                    </>
                  )
                )}
              </div>
            </>
          </CustomModal>
        </div>
        <CustomModal
          show={this.state.visible}
          onCancel={this.handleCancel}
          title={this.polyglot.t("Production details")}
          Footer={[
            <>
              {this.isSaveDisabled() ? (
                <ButtonPrimary disabled ariaLabel={this.polyglot.t("Save")}>
                  {this.polyglot.t("Save")}
                </ButtonPrimary>
              ) : (
                <ButtonPrimary onClick={this.handleConfirm} ariaLabel={this.polyglot.t("Save")}>
                  {this.polyglot.t("Save")}
                </ButtonPrimary>
              )}
            </>,
          ]}
          size="sm"
        >
          <>
            <div className="row mrgnBtm10">
              <div className="col-md-4 labelDiv">
                <span className="editTest-align">{this.polyglot.t("Production Status") + ":"}</span>
              </div>
              <div className="col-md-8 labelDiv padding-top-point5">
                <Select
                  value={this.state.editedPrepareReviewStatus}
                  showSearchOnToggle={true}
                  onSelect={this.prepareReviewOnChng.bind(this)}
                  className="admin-editinputboxes_align "
                >
                  <SelectOption key={"prepKeyStus_" + 1} value={"1"}>
                    {this.polyglot.t("Not Started")}
                  </SelectOption>
                  <SelectOption key={"prepKeyStus_" + 2} value={"2"}>
                    {this.polyglot.t("In Progress")}
                  </SelectOption>
                  <SelectOption key={"prepKeyStus_" + 3} value={"3"}>
                    {this.polyglot.t("Completed")}
                  </SelectOption>
                </Select>
              </div>
            </div>
            {productionDetails.REVIEW_FILE_NAME && (
              <div className="row mrgnBtm10">
                <div className="col-md-4 labelDiv">
                  <span className="editTest-align">{this.polyglot.t("Previous file uploaded")}:</span>
                </div>
                <span className="col-md-8 labelDiv sucessFntClr">{productionDetails.REVIEW_FILE_NAME}</span>
              </div>
            )}
            {Number(this.state.editedPrepareReviewStatus) === 3 ? (
              <div className="row">
                <div className="col-md-4 labelDiv">
                  <span className="editTest-align">{this.polyglot.t("Production File Upload :")}</span>
                </div>
                <div className="col-md-8 labelDiv padding-top-point5">
                  <input
                    placeholder="Input"
                    name="productionGridFile"
                    className="admin-editinputboxes_align cursor"
                    id="productionGridFileId"
                    type="file"
                    ref={(ref) => (this.fileInput = ref)}
                    //accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                    onChange={this.validateFileType.bind(this)}
                    aria-label={this.polyglot.t("Production File Upload :")}
                  />
                  <br />
                  <span id="anaKeyWedExcelTypeErr1" className="errorFntClr"></span>
                  <span id="anaKeyWedExcelTypeSucessfull2" className="sucessFntClr"></span>
                </div>
              </div>
            ) : (
              <div />
            )}
          </>
        </CustomModal>
      </div>
    );
  }
}
export default ProductionGrid;
