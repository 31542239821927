import React from "react";
import "./FormField.css";
// import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../../Services/Shared/Store.js";
import { polyglotLoader } from "../../../../Services/Shared/Translate";

class FormSearchBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      val: props.val,
    };

    this.onChange = this.onChange.bind(this);
  }
  static contextType = DataContext;

  onChange(event) {
    this.setState({
      val: event.target.value,
    });
    this.props.onChange(this.props.name, event.target.value);
  }
  render() {
    var polyglot = polyglotLoader();
    const otherProps = { ...this.props };
    // deleting onChange from otherProps just not not makee sure onChange interfears with the default onChange property of the <input /> field
    delete otherProps.onChange;
    const classList = this.props.customClass;
    const labelClass = this.props.labelClass ? this.props.labelClass : "";
    return (
      <div className={`${labelClass} searchIcon`}>
        <input
          onChange={this.onChange}
          className={`${classList} search-input`}
          placeholder={polyglot.t("Search for") + " " + polyglot.t(this.props.nameOfSearch)}
          {...otherProps}
        />
      </div>
    );
  }
}

export default FormSearchBar;
