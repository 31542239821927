import React, { Component } from "react";
import "../../../Assets/Css/DFED/AskForSupport.css";
import SupportService from "../../../Services/DFED/SupportService";
import { notification } from "antd";
import CommonButton from "../Shared/button/CommonButton";
import "../../../Components/DFED/Analysis/CommonStyles.css";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import "../../../Assets/Css/QueenBase/Root/Login.css";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import { getProjectID_Of_solution_module } from "../../../Services/Shared/Handlers";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";
const supportService = new SupportService();
class AskForSupport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      select: "",
      textArea: "",
      dropDownlist: [],
      selectedProjectId: null,
      openConfirmBox: false,
    };
    this.saveData = this.saveData.bind(this);
  }
  static contextType = DataContext;
  polyglot = polyglotLoader();

  checkIfFormIsEmpty = (prevState) => {
    try {
      let isFormEmpty = true;
      if (prevState.select !== this.state.select || prevState.textArea !== this.state.textArea) {
        isFormEmpty = false;
      } else {
        isFormEmpty = true;
      }
      return isFormEmpty;
    } catch (err) {
      displayToastMessage(err.message, "error");
      return true;
    }
  };
  componentDidUpdate(prevProps, prevState) {
    console.log("udate called");
    if (!this.checkIfFormIsEmpty(prevState)) {
      console.log("form opend");
      const { formGotOpened } = this.context;
      formGotOpened(); //eslint-disable-line
    }
  }

  // Close and redirect form
  closeFormAndRedirect = () => {
    try {
      const { formGotClosed } = this.context;
      // close the popup
      this.toggleConfirmBox();
      // mark the form is closed on the store
      formGotClosed();
      // close custodian form
      this.gobackHandler();
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  toggleConfirmBox = () => {
    this.setState({
      openConfirmBox: !this.state.openConfirmBox,
    });
  };
  // check and close the form
  checkAndCloseForm = () => {
    try {
      const { isFormOpen, formGotClosed } = this.context;
      if (isFormOpen) {
        // values of the form got changed - then show confirm box
        this.setState({ openConfirmBox: true });
      } else {
        // if form is not considered as open(values of the form are not changed yet), then close the form
        this.gobackHandler();
        formGotClosed();
        this.setState({ openConfirmBox: false });
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  selectFieldHandler = (e) => {
    this.setState({ select: e.target.value });
  };

  textAreaHandler = (e) => {
    this.setState({ textArea: e.target.value });
  };

  componentDidMount() {
    this.getSupportedService();
    if (this.props.location.state) {
      this.setState({ selectedProjectId: this.props.location.state.selectedProjectId });
    }
  }

  async getSupportedService() {
    let path = this.props.location;
    let url = path.pathname;
    if (url.includes("/dfed")) {
      let supportInfo = await supportService.SupportedService("dfed");
      if (supportInfo && supportInfo.data) {
        this.setState({
          dropDownlist: supportInfo.data,
        });
      }
    } else if (url.includes("/queenbase")) {
      let supportInfo = await supportService.SupportedService("queenbase");

      if (supportInfo && supportInfo.data) {
        this.setState({
          dropDownlist: supportInfo.data,
        });
      }
    } else if (url.includes("/gi")) {
      let supportInfo = await supportService.SupportedService("gi");
      if (supportInfo && supportInfo.data) {
        this.setState({
          dropDownlist: supportInfo.data,
        });
      }
    } else if (url.includes("/css")) {
      let supportInfo = await supportService.SupportedService("css");
      if (supportInfo && supportInfo.data) {
        this.setState({
          dropDownlist: supportInfo.data,
        });
      }
    } else {
      let supportInfo = await supportService.SupportedService("df");
      if (supportInfo && supportInfo.data) {
        this.setState({
          dropDownlist: supportInfo.data,
        });
      }
    }
  }

  saveData = () => {
    let self = this;
    let path = self.props.location;
    let url = path.pathname;
    let solutionName = "";
    let projectId = "";
    if (url.includes("/queenbase")) {
      solutionName = "Base";
      projectId = null;
    } else if (url.includes("/df")) {
      solutionName = "DF";
      projectId = getProjectID_Of_solution_module();
    } else {
      solutionName = "Ediscovery";
      projectId = self.state.selectedProjectId;
    }

    let data = {
      id: self.state.select,
      note: self.state.textArea,
      projectId: projectId,
    };

    if (!self.state.select) {
      displayToastMessage(this.polyglot.t("Please select request"), "error");
      return false;
    } else if (!self.state.textArea) {
      displayToastMessage(this.polyglot.t("Please enter request notes"), "error");
      return false;
    } else {
      supportService.insertSupportNotes(data, solutionName).then((res) => {
        if (res.status === 200) {
          let Msg = this.polyglot.t("Your request has submitted successfully");
          displayToastMessage(Msg, "success");
          this.setState({ select: "", textArea: "" });
        } else {
          let Msg = this.polyglot.t("Your request has not submitted");
          displayToastMessage(Msg, "error");
        }
        const { isFormOpen, formGotClosed } = this.context;
        formGotClosed();
        this.setState({ openConfirmBox: false });
        this.props.history.push({
          pathname: url,
          state: { selectedProjectId: this.state.selectedProjectId },
        });
      });
    }
  };

  gobackHandler = () => {
    let e = localStorage.getItem("previousComponent");
    switch (e) {
      case "Home":
        this.props.history.push({
          pathname: "/dfed/home",
          state: { selectedProjectId: this.state.selectedProjectId },
        });
        break;
      case "Custodians":
        this.props.history.push({
          pathname: "/dfed/custodian",
          state: { selectedProjectId: this.state.selectedProjectId },
        });
        break;
      case "Keywords":
        this.props.history.push({
          pathname: "/dfed/keywords",
          state: { selectedProjectId: this.state.selectedProjectId },
        });
        break;
      case "Profiles":
        this.props.history.push({
          pathname: "/dfed/profileList",
          state: { selectedProjectId: this.state.selectedProjectId },
        });
        break;
      case "Analysis":
        this.props.history.push({
          pathname: "/dfed/analysis",
          state: { selectedProjectId: this.state.selectedProjectId },
        });
        break;
      case "Processing":
        this.props.history.push({
          pathname: "/dfed/processing",
          state: { selectedProjectId: this.state.selectedProjectId },
        });
        break;
      case "Production":
        this.props.history.push({
          pathname: "/dfed/production",
          state: { selectedProjectId: this.state.selectedProjectId },
        });
        break;
      case "Review":
        this.props.history.push({
          pathname: "/dfed/reviewdetails",
          state: { selectedProjectId: this.state.selectedProjectId },
        });
        break;
      case "Admin":
        this.props.history.push({
          pathname: "/dfed/admin",
          state: { selectedProjectId: this.state.selectedProjectId },
        });
        break;
      // DF
      case "DF_Home":
        this.props.history.goBack();
        break;
      // GI
      case "GI_Home":
        this.props.history.goBack();
        break;
      //css
      case "CSS_Home":
        this.props.history.goBack();
        break;
      //queen base
      case "Queenbase_Solutions":
        this.props.history.push({
          pathname: "/queenbase/solutions/solution",
          url: "/queenbase/solutions/solution",
          label: this.polyglot.t("Solutions"),
          name: "solutions",
        });
        break;
      case "Queenbase_Projects":
        this.props.history.push({
          pathname: "/queenbase/projects/viewprojects",
          url: "/queenbase/projects/viewprojects",
          label: this.polyglot.t("Projects"),
          name: "projects",
        });
        break;
      case "Queenbase_Users":
        this.props.history.push({
          pathname: "/queenbase/users",
          url: "/queenbase/users",
          label: this.polyglot.t("Users"),
          name: "users",
        });
        break;
      case "Queenbase_Myrelationship":
        this.props.history.push({
          pathname: "/queenbase/mydashboard",
          url: "/queenbase/mydashboard",
          label: this.polyglot.t("My Relationship"),
          name: "myrelationship",
        });
        break;
      case "Queenbase_Companycenter":
        this.props.history.push({
          pathname: "/queenbase/companycenter",
          url: "/queenbase/companycenter",
          label: this.polyglot.t("Company Center"),
          name: "companycenter",
        });
        break;
      case "Queenbase_Admin":
        this.props.history.push({
          pathname: "/queenbase/admin",
          url: "/queenbase/admin",
          label: this.polyglot.t("PwC Admin"),
          name: "admin",
        });
        break;
      default:
        break;
    }
  };
  render() {
    var polyglot = polyglotLoader();
    let path = this.props.location;
    let url = path.pathname;
    let style = { textAlign: "left" };
    if (url.includes("/queenbase")) {
      style = { textAlign: "left", marginLeft: "10%" };
    }
    var buttonstyle = "#bfbfbf";
    if (this.state.select !== "" && this.state.textArea !== "") {
      buttonstyle = "#E0301E";
    }
    return (
      <div style={style} className="df-support-card">
        <div className="row m-l-0 m-r-0">
          <div className="col-md-4"></div>
          <div className="col-md-4"></div>
          <div className="col-md-4"></div>
        </div>

        <div className="row title-text">
          <label>{polyglot.t("We will help you to get that request done")}</label>
        </div>

        <div className="row pt-4 pb-4">
          <label>{polyglot.t("Please provide us some more information to your request.")}</label>
        </div>

        <div className="row mt-4 mb-4">
          <div className="col-xs-12 col-sm-12 col-md-6 p-0">
            <select
              style={{ width: "100%" }}
              value={this.state.select}
              className="select-ask"
              onChange={this.selectFieldHandler}
              name="select"
            >
              <option value="">{`${polyglot.t("Select")} *`}</option>
              {this.state.dropDownlist.map((item) => {
                return <option value={item.ID}>{polyglot.t(item.TYPE_NAME)}</option>;
              })}
            </select>
          </div>
          <div className="col-md-6"></div>
        </div>

        <div className="row text-area">
          <div className="col-xs-12 col-sm-12 col-md-6 p-0">
            <textarea
              className="text-area-input-field"
              onChange={this.textAreaHandler}
              name="textArea"
              value={this.state.textArea}
              cols="71"
              rows="4"
              placeholder={`${polyglot.t("Notes")} *`}
            ></textarea>
            <div className="col-md-6"></div>
          </div>
        </div>

        <div className="row ">
          <div className="col-xs-12 col-sm-12 col-md-4 col-lg-3 p-l-0 mt-4 tex-ali">
            <ButtonPrimary onClick={this.saveData} ariaLabel={polyglot.t("Send request")}>
              {polyglot.t("Send request")}
            </ButtonPrimary>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-3 p-r-0 mt-4 float-right">
            <ButtonSecondary onClick={this.checkAndCloseForm} ariaLabel={polyglot.t("Cancel")}>
              {polyglot.t("Cancel")}
            </ButtonSecondary>
          </div>
        </div>
        {this.state.openConfirmBox && (
          <ConfirmBoxExitForm
            show={this.state.openConfirmBox}
            heading={"Cancel update"}
            cancel={this.toggleConfirmBox}
            proceed={this.closeFormAndRedirect}
          />
        )}
      </div>
    );
  }
}
export default AskForSupport;
