import React from "react";
import { Switch } from "react-router-dom";

import GrcAssesmentProtectedRoute from "./GrcAssesmentProtectedRoute";
import PerformHome from "../Components/GRC/PerformAssessment/Pages/PerformHome";
import RecipientAssessment from "../Components/GRC/PerformAssessment/Pages/RecipientAssessment";
import * as path from "../Constants/GRCAssessmentPath";

// ROUTES OF GRC MODULE
const GRCAssessmentModuleRoutes = () => {
  return (
    <Switch>
      <GrcAssesmentProtectedRoute path={path.PERFORM_ASSESSMENT_PATH} component={PerformHome} />

      <GrcAssesmentProtectedRoute
        path={path.PERFORM_ASSESSMENT_QUESTION_PATH}
        component={RecipientAssessment}
      />
    </Switch>
  );
};

export default GRCAssessmentModuleRoutes;
