import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import SearchService from "../../../Services/CSS/SearchService";
import { getDisplayDate } from "../../../Services/Shared/Handlers";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";

function ViewSDNDates({ className }) {
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());

  const [sdnDate, setsdnDate] = useState([]);

  const fetchDate = async () => {
    try {
      var res = await SearchService.get().getLastUpdatedDate();
      if (res.CODE) {
        setsdnDate(res.data);
      } else {
        displayToastMessage(polyglot.t(res.MSG), "error");
      }
    } catch (err) {
      displayToastMessage(polyglot.t(err.message), "error");
    }
  };

  useEffect(() => {
    fetchDate();
  }, []);

  return (
    <div className={className}>
      {sdnDate.map((item, i) => {
        const isoDate = new Date(item.dateProcessed).toISOString();
        return (
          <div key={`row_${i + 1}`}>
            {polyglot.t(item.listName + " List last updated on ") +
              getDisplayDate(isoDate) +
              " " +
              new Date(isoDate).toLocaleTimeString()}
          </div>
        );
      })}
    </div>
  );
}

ViewSDNDates.propTypes = {
  className: PropTypes.string,
};
ViewSDNDates.defaultProps = {
  className: "",
};

export default ViewSDNDates;
