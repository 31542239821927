/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/display-name */
import React, { forwardRef, useEffect, useImperativeHandle, useContext, useCallback } from "react";
import { DatePicker, ConfigProvider } from "antd";
import gr from "antd/lib/locale-provider/de_DE";
import { Checkbox, Input, Tooltip } from "appkit-react";
import moment from "moment";

import "moment/locale/de";
import PropTypes from "prop-types";

import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import LinkText from "../../../Assets/Components/LinkText/LinkText";
import { plusCircle, saveIcon, deleteFill } from "../../../Assets/Icons";
import calendar from "../../../Assets/Icons/calendar.svg";
import editlogo from "../../../Assets/Icons/pencil-outline.svg";
import FlagIcon from "../../../Assets/Images/QueenBase/Projects/icon-flag.svg";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { textFieldValidation, fieldValidation } from "../../../Services/Shared/formHandlers";
import displayToastMessage from "../Root/displayToastMessage";

// STEP 3 OF PROJECT FORM
const ProjectSolutionMileStone = forwardRef((props, ref) => {
  const {
    setFetchedData,
    solutionMilestoneFieldChange,
    solutionMilestones,
    selectedSolutionsList,
    setNotify,
    notify,
    step1Data,
  } = props;
  let mounted = true;
  const { getUserLanguage, formGotOpened } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());

  // GET MILESTONES OF SOLUTIONS
  const getProjectSolutionMileStones = async () => {
    const res = { solutionlist: selectedSolutionsList };
    if (mounted) {
      setFetchedData("solutionList", res.solutionlist);
      solutionMilestoneFieldChange("getSolutionMilestones", res.solutionlist);
    }
  };

  // IMPERATIVE HANDLER TO HANDLE FUNCTIONS OF THIS COMPONENT
  useImperativeHandle(ref, () => ({
    // VALIDATE STEP 3 OF PROJECT FORM
    async validate() {
      let isStep3Valid = true;
      let isNotifyValid = true;
      let invalidNotifyMsg = "";
      const milestonesCounter = {
        emptyMilestoneForSolution: 0,
        hasMilestoneForSolution: 0,
      };
      // check milestones
      solutionMilestones.forEach((each) => {
        if (each.milestoneList.length === 0) {
          milestonesCounter.emptyMilestoneForSolution = milestonesCounter.emptyMilestoneForSolution + 1;
        }
        if (each.milestoneList.length > 0) {
          milestonesCounter.hasMilestoneForSolution = milestonesCounter.hasMilestoneForSolution + 1;
        }
        each.milestoneList.forEach((item) => {
          if (item.isedit) {
            isStep3Valid = false;
          }
        });
      });
      // check notify
      let isNumberOfDaysPresent = false;
      switch (notify.noofdays) {
        case 0: {
          isNumberOfDaysPresent = false;
          break;
        }
        case "0": {
          isNumberOfDaysPresent = false;
          break;
        }
        case "": {
          isNumberOfDaysPresent = false;
          break;
        }
        default: {
          isNumberOfDaysPresent = true;
          break;
        }
      }
      if (notify.ischecked && !isNumberOfDaysPresent) {
        isNotifyValid = false;
        invalidNotifyMsg = "Please provide number of days to get notified";
      }
      if (!notify.ischecked && isNumberOfDaysPresent) {
        isNotifyValid = false;
        invalidNotifyMsg = "Please mark the check box to get notified";
      }
      if (notify.ischecked && !milestonesCounter.hasMilestoneForSolution > 0) {
        isNotifyValid = false;
        invalidNotifyMsg = "Please add a milestone to get notified";
      }
      if (!checkIfNumberOfDaysToNotifyIsValid(notify.noofdays)) {
        // INPUT NUMBER OF DAYS IS NOT VALID
        isNotifyValid = false;
        invalidNotifyMsg =
          "Please enter a valid number without decimal values. The maximum number of days allowed is 365";
      }
      return { isStep3Valid, isNotifyValid, invalidNotifyMsg };
    },
  }));

  // ADD A MILESTONE TO THE SOLUTION
  const addMilestone = (item, index) => {
    let list = [...solutionMilestones];
    list[index].milestoneList.push({
      name: "",
      deadline: null,
      deadlinedate: null,
      isedit: 1,
    });
    solutionMilestoneFieldChange("setSolutionMilestones", list);
  };
  // SAVE EDIT MODE OF MILESTONE
  const onEditSave = (item, index) => {
    let list = [...solutionMilestones];
    let isInvalid = false;
    let isInvalidName = false;
    list.map((solution) => {
      if (solution.id === item.id) {
        if (
          solution.milestoneList[index].deadlinedate === "1970-01-01" ||
          solution.milestoneList[index].deadline === null
        ) {
          isInvalid = true;
        }
        if (
          solution.milestoneList[index].name === "" ||
          !fieldValidation(solution.milestoneList[index].name)
        ) {
          isInvalidName = true;
          isInvalid = true;
        }
      }
    });

    if (!isInvalid) {
      list.map((solution) => {
        if (solution.id === item.id) {
          solution.milestoneList[index].isedit = !solution.milestoneList[index].isedit;
        }
      });
      solutionMilestoneFieldChange("setSolutionMilestones", list);
    } else {
      displayToastMessage(polyglot.t("Please enter valid date !!"), "error");
    }
    if (isInvalidName) {
      displayToastMessage(polyglot.t("Please enter valid Milestone name !"), "error");
    }
  };
  // ON CHANGING THE NAME OF MILESTONE
  const onNameChange = (item, index, e) => {
    try {
      const updateMilestoneState = () => {
        let list = [...solutionMilestones];
        list.map((solution) => {
          if (solution.id === item.id) {
            solution.milestoneList[index].name = e;
          }
        });
        solutionMilestoneFieldChange("setSolutionMilestones", list);
      };
      if (e.length > 0) {
        const isTextValid = fieldValidation(e);
        if (isTextValid) {
          updateMilestoneState();
        } else {
          displayToastMessage(polyglot.t("Text should not contain any special characters"), "error");
        }
      } else {
        // user has emptied the text box
        updateMilestoneState();
      }
      formGotOpened();
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // ON CHANGING THE DATE OF A MILESTONE
  const onDateChange = (item, index, e) => {
    let list = [...solutionMilestones];
    list.map((solution) => {
      if (solution.id === item.id) {
        solution.milestoneList[index].deadline = e;
        let today = new Date(e);
        solution.milestoneList[index].deadlinedate = today.toISOString().split("T")[0];
      }
    });
    solutionMilestoneFieldChange("setSolutionMilestones", list);
  };
  // DELETE A MILESTONE
  const closeMileStone = (item, index) => {
    let list = [...solutionMilestones];
    list.map((solution) => {
      if (solution.id === item.id) {
        solution.milestoneList.splice(index, 1);
      }
    });
    solutionMilestoneFieldChange("setSolutionMilestones", list);
  };
  // CANCEL EDITING A MILESTONE
  const cancelMilestoneEdit = (item, index) => {
    let list = [...solutionMilestones];
    list.map((solution) => {
      if (solution.id === item.id) {
        solution.milestoneList[index].isedit = 0;
      }
    });
    solutionMilestoneFieldChange("setSolutionMilestones", list);
  };

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    getProjectSolutionMileStones();
    return function cleanup() {
      mounted = false;
    };
  }, []);

  // FILTER DATES SO THAT USER CAN CHOOSE PROJECT SPECIFIC DATES FOR A MILESTONE
  const filterDates = (currentDate) => {
    const current = new Date(currentDate).toLocaleDateString();
    const enddate = step1Data.enddate ? step1Data.enddate : null;
    const startdate = step1Data.startdate ? step1Data.startdate : null;
    // IF PROJECT START AND END DATES ARE PRESENT
    if (startdate && enddate) {
      let customStartDate = new Date(startdate).toLocaleDateString();
      let customEndDate = new Date(enddate).toLocaleDateString();
      return (
        (new Date(current) && new Date(current) < new Date(customStartDate)) ||
        (new Date(current) && new Date(current) > new Date(customEndDate))
      );
    }
    // IF PROJECT START DATE IS THERE BUT NO END DATE
    if (startdate && !enddate) {
      let customStartDate = new Date(startdate).toLocaleDateString();
      return new Date(current) && new Date(current) < new Date(customStartDate);
    }
  };

  // USED TO CHECK THE NUMBER OF DAYS GIVEN TO NOTIFY
  const checkIfNumberOfDaysToNotifyIsValid = (numberOfDays) => {
    try {
      let isNumberValid = true;
      const maxNumberOfDays = 365;
      if (numberOfDays > maxNumberOfDays) {
        isNumberValid = false;
      }
      // float numbers are not valid
      if (!textFieldValidation(numberOfDays.toString())) {
        isNumberValid = false;
      }
      return isNumberValid;
    } catch (err) {
      displayToastMessage(err.message, "error");
      return false;
    }
  };

  // CAPTURE NUMBER OF DAYS
  const captureNumberOfDays = (e) => {
    try {
      const value = Number(e.target.value);
      const isNotifyDaysValid = checkIfNumberOfDaysToNotifyIsValid(value);
      if (isNotifyDaysValid) {
        // VALID INPUT
        setNotify({
          ...notify,
          ["noofdays"]: value,
        });
      } else {
        // INPUT IS NOT VALID
        displayToastMessage(
          polyglot.t(
            "Please enter a valid number without decimal values. The maximum number of days allowed is 365",
          ),
          "error",
        );
      }
      formGotOpened();
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // TOOLTIP ON MILESTONE NAME
  const TooltipMilestoneName = (milestone) => {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td style={{ textAlign: "center", fontWeight: "bold", color: "#000" }}>{milestone.name}</td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  let invalidChars = [69];

  const handleDisabledCalendar = useCallback(
    (currentDate) => {
      const projectStartDate = moment(step1Data.startdate);
      const isBeforeStartDate = moment(currentDate).isBefore(projectStartDate, "day");
      if (step1Data.enddate) {
        const projectEndDate = moment(step1Data.enddate);
        const isAfterEndDate = moment(currentDate).isAfter(projectEndDate, "day");
        return isBeforeStartDate || isAfterEndDate;
      }
      return isBeforeStartDate;
    },
    [step1Data],
  );

  return (
    <div>
      <div className="row project-solution-pods">
        <div className="full-width">
          <div className="col-md-12">
            <p className="subtitle_two_title_qb">{polyglot.t("Create milestones")}</p>
            <p className="f-acc-B">
              {polyglot.t(
                "Plan your project better – define milestones and track their progress on the dashboard later.",
              )}
            </p>
            {solutionMilestones.length > 0 &&
              solutionMilestones.map((item, index) => {
                return (
                  <div key={index}>
                    <p className="mb-2 subtitle_two_title_qb">{item.name}</p>

                    {item.milestoneList.length > 0 &&
                      item.milestoneList.map((milestone, index) => {
                        return (
                          <div key={index}>
                            {milestone.isedit ? (
                              <div className="col-md-12">
                                <div className="cardHeight">
                                  <div className="card-body MilestoneCardBorder">
                                    <div className="card-text CardText">
                                      <div className="d-flex">
                                        <img src={FlagIcon} alt={FlagIcon} />
                                        <div className="col-md-5">
                                          <Input
                                            inputBoxSize="sm"
                                            placeholder={polyglot.t("Milestone name")}
                                            className="Box"
                                            value={milestone.name}
                                            onChange={(e) => onNameChange(item, index, e)}
                                            autoFocus
                                            aria-label="enter milestone name"
                                          />
                                        </div>
                                        <div className="col-md-4 datepicker-milestone">
                                          {getUserLanguage() === "ger" ? (
                                            <ConfigProvider locale={gr}>
                                              <DatePicker
                                                className="project-datepicker"
                                                placeholder={polyglot.t("Milestone deadline")}
                                                value={milestone.deadline}
                                                onChange={(e) => onDateChange(item, index, e)}
                                                format={"DD.MM.YYYY"}
                                                suffixIcon={<img src={calendar} width={14} height={14} />}
                                                disabledDate={handleDisabledCalendar}
                                              />
                                            </ConfigProvider>
                                          ) : (
                                            <DatePicker
                                              className="project-datepicker"
                                              placeholder={polyglot.t("Milestone deadline")}
                                              value={milestone.deadline}
                                              onChange={(e) => onDateChange(item, index, e)}
                                              format={"DD.MM.YYYY"}
                                              suffixIcon={<img src={calendar} width={14} height={14} />}
                                              disabledDate={handleDisabledCalendar}
                                            />
                                          )}
                                        </div>
                                        <div className="col-md-2 d-flex p-0">
                                          <ButtonIcon
                                            iconName={saveIcon}
                                            title={polyglot.t("Save")}
                                            onClick={() => onEditSave(item, index)}
                                            alt="save"
                                            ariaLabel={polyglot.t("save milestone")}
                                          />
                                          <ButtonIcon
                                            iconName={deleteFill}
                                            title={polyglot.t("Delete")}
                                            onClick={() => closeMileStone(item, index)}
                                            alt="delete"
                                            ariaLabel={polyglot.t("delete milestone")}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className="col-md-12">
                                <div className="cardHeight">
                                  <div className="card-body MilestoneCardBorder">
                                    <div className="card-text CardText">
                                      <div className="d-flex">
                                        <img src={FlagIcon} alt={FlagIcon} />
                                        <div className="col-md-4 usertitle-view-text vertical-center">
                                          <Tooltip content={TooltipMilestoneName(milestone)} placement="top">
                                            {milestone.name}
                                          </Tooltip>
                                        </div>
                                        <div className="col-md-4 milestone-font vertical-center">
                                          {moment(milestone.deadline).format("DD.MM.YYYY")}
                                        </div>
                                        <div className="col-md-3">
                                          <div className="d-flex justify-content-end">
                                            <ButtonIcon
                                              imgWidth={16}
                                              iconImg={editlogo}
                                              onClick={() => onEditSave(item, index)}
                                              alt="edit"
                                              ariaLabel={polyglot.t("edit milestone")}
                                              title={polyglot.t("Edit")}
                                            />
                                            <ButtonIcon
                                              iconName={deleteFill}
                                              onClick={() => closeMileStone(item, index)}
                                              alt="delete"
                                              ariaLabel={polyglot.t("delete milestone")}
                                              title={polyglot.t("Delete")}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </div>
                        );
                      })}

                    <p className="ProjectPlanColor">
                      <LinkText
                        onClick={() => addMilestone(item, index)}
                        ariaLabel={polyglot.t("Add milestone")}
                      >
                        <span className={`appkiticon ${plusCircle}`} alt="add">
                          &nbsp; <strong>{polyglot.t("Add milestone")}</strong>
                        </span>
                      </LinkText>
                    </p>

                    <div className="mb-4"></div>
                  </div>
                );
              })}
            <div className="row">
              <div className="col-md-12">
                <div className="project-avaluser-data p-0">
                  <Checkbox
                    checked={notify.ischecked}
                    onChange={(e) => setNotify({ ...notify, ["ischecked"]: e.target.checked })}
                  >
                    <div className="subtitle_three_title_qb_dark">
                      {polyglot.t("Notify me")}
                      <input
                        type="number"
                        min="0"
                        max="365"
                        value={parseInt(Number(notify.noofdays)).toString()}
                        onChange={captureNumberOfDays}
                        className="MileStoneDaysTextBox"
                        id="usr"
                        onKeyDown={(e) => {
                          if (invalidChars.includes(e.keyCode)) {
                            e.preventDefault();
                          }
                          e.target.style.width = `${(notify.noofdays.length + 1) * 8}px`;
                        }}
                      />
                      {polyglot.t("days before the deadline.")}
                    </div>
                  </Checkbox>
                </div>
                <div>
                  {polyglot.t("Note:The milestone deadline has to be between project start and end date.")}
                </div>
              </div>
              <div className="col-md-4"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
});

ProjectSolutionMileStone.propTypes = {
  history: PropTypes.object,
  setFetchedData: PropTypes.func,
  solutionMilestoneFieldChange: PropTypes.func,
  solutionMilestones: PropTypes.array,
  selectedSolutionsList: PropTypes.array,
  setNotify: PropTypes.any,
  notify: PropTypes.object,
};
export default ProjectSolutionMileStone;
