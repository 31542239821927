import React from "react";
import { Checkbox } from "appkit-react";
import SearchBar from "../../DFED/Shared/components/FormSearchBar";
import "../../DFED/Processing/Processing.css";
import "../../DFED/Shared/button/button.css";
import ProcessingService from "../../../Services/DFED/ProcessingService";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import rightNormalIcon from "../../../Assets/Images/DFED/icon-chevron-right.svg";
import rightBlackIcon from "../../../Assets/Images/DFED/icon-chevron-right-black.svg";
import leftBlackIcon from "../../../Assets/Images/DFED/icon-chevron-left-black.svg";
import leftNormalIcon from "../../../Assets/Images/DFED/icon-chevron-left.svg";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";

class dualBox extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      datasetsRecords: [],
      leftSideAvailableCustodian: null,
      selectedAvailableParent: null,
      selectedAvailableParentIndex: null,
      selectedProcessingParent: null,
      selectedProcessingParentIndex: null,
      showUploadKeyword: false,
      showUploadKeyword1: false,
      rightSideProcessingCustodianImage: [],
      rightSideProcessingCustodianOriginal: [],
      leftSideSelectedChildrenArray: [],
      AfterRemoveleftSideAvailableCustodian: [],
      avaliableCheckedListAll: [],
      avaliableCustodianChecked: false,
      processingCheckedListAll: [],
      processingCustodianChecked: false,
      search_text: null,
      filterAvailableCustodian: [],
      leftSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12  customClassForCol-5 b-g-w m-t-5 p-b-15",
      rightSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12 customClassForCol-5 b-g-w m-t-5 p-b-15",
      leftsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
      rightsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0 mt-2",
      propfileDropdownValue: [],
      selectedProfileId: null,
      allowRunProcessing: false,
      checkedProcessingRightSideChild: [],
      checkedAvaliableLeftSideChild: [],
      // CODE FROM HERE NEW
      leftSideAvaliableSelectAll: false,
      leftSideAvalableParentExpandAll: [],
      rightSideProcessingSelectAll: false,
      rightSideProcessingParentExpandAll: [],
      //projectID:props.location.state.selectedProjectId
    };
    this.expandAvailableParent = this.expandAvailableParent.bind(this);
    this.expandProcessingParent = this.expandProcessingParent.bind(this);
    this.selectAvaliableCustodianChild = this.selectAvaliableCustodianChild.bind(this);
    this.selectProcessingCustodianChild = this.selectProcessingCustodianChild.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.avaliableCustodianOnChange = this.avaliableCustodianOnChange.bind(this);
    this.processingCustodianOnChange = this.processingCustodianOnChange.bind(this);
    this.filterAvailableCustodian = this.filterAvailableCustodian.bind(this);
    this.runProcessing = this.runProcessing.bind(this);
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
    this.saveProcessingData = this.saveProcessingData.bind(this);
  }
  polyglot = polyglotLoader();
  componentDidMount() {
    this.getProfile();
    this.getCustodianRecords();
  }
  getProfile() {
    const processingService = new ProcessingService();
    let selectedProjectId = this.props.selectedProjectId;
    processingService
      .getProfile(selectedProjectId)
      .then((result) => {
        if (result && result.data && result.data.length > 0) {
          this.setState({
            propfileDropdownValue: result.data,
          });
        } else {
          return null;
        }
      })
      .catch((err) => {});
  }

  getCustodianRecords() {
    const processingService = new ProcessingService();
    let selectedProjectId = this.props.selectedProjectId;
    processingService
      .getProcessingCustodianRecords(selectedProjectId)
      .then((result) => {
        if (result && result.data && result.data.length > 0) {
          result.data.forEach(function (custodianData) {
            custodianData.isParentChecked = false;
            custodianData.custodian_datasets.forEach(function (childDatasets) {
              childDatasets.isChildChecked = false;
            });
          });
          this.setState({
            datasetsRecords: result.data,
            leftSideAvailableCustodian:
              (this.props.leftSideAvailableCustodian && this.props.leftSideAvailableCustodian.length > 0) ||
              (this.props.rightSideProcessingCustodianOriginal &&
                this.props.rightSideProcessingCustodianOriginal.length > 0)
                ? this.props.leftSideAvailableCustodian
                : result.data,
            filterAvailableCustodian: result.data,
            rightSideProcessingCustodianOriginal:
              this.props.rightSideProcessingCustodianOriginal &&
              this.props.rightSideProcessingCustodianOriginal.length > 0
                ? this.props.rightSideProcessingCustodianOriginal
                : [],
          });
        } else {
          return null;
        }
      })
      .catch((err) => {});
  }
  expandAvailableParent(dataSets, index, event) {
    //============ CODE FOR WENW E CLICK ON EXPAND ICON FROM LEFT SIDE=====
    let tempLeftSideAvalableParentExpandAll = this.state.leftSideAvalableParentExpandAll;
    // CHECKING IF ALREADY ELEMENT IS THERE OR NOT
    let leftSideAvalableExpandIndex = tempLeftSideAvalableParentExpandAll.findIndex(function (data) {
      return data === dataSets.CRT_ID;
    });
    if (leftSideAvalableExpandIndex === -1) {
      tempLeftSideAvalableParentExpandAll.push(dataSets.CRT_ID);
    }
    this.setState({
      selectedAvailableParentIndex: index,
      selectedAvailableParent: dataSets,
      showUploadKeyword: true,
      leftSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12 customClassForCol-5  b-g-w",
      leftsidecustodiandivstyle: "tot-check tot-check-reducewidth m-l-0 m-r-0 m-0 p-r-0",
      checkedAvaliableLeftSideChild: [],
      // WRIITING NEW CODE
      leftSideAvalableParentExpandAll: tempLeftSideAvalableParentExpandAll,
    });
  }
  expandProcessingParent(dataSets, index, event) {
    //============ CODE FOR WENW E CLICK ON EXPAND ICON FROM LEFT SIDE=====
    let tempRightSideProcessingParentExpandAll = this.state.rightSideProcessingParentExpandAll;
    // CHECKING IF ALREADY ELEMENT IS THERE OR NOT
    let rightSideProcessingExpandIndex = tempRightSideProcessingParentExpandAll.findIndex(function (data) {
      return data === dataSets.CRT_ID;
    });
    if (rightSideProcessingExpandIndex === -1) {
      tempRightSideProcessingParentExpandAll.push(dataSets.CRT_ID);
    }
    this.setState({
      selectedProcessingParentIndex: index,
      selectedProcessingParent: dataSets,
      showUploadKeyword1: true,
      rightSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12  customClassForCol-5 b-g-w",
      rightsidecustodiandivstyle: "tot-check tot-check-reducewidth m-l-0 m-r-0 m-0 p-r-0",
      checkedProcessingRightSideChild: [],
      // WRIITING NEW CODE
      rightSideProcessingParentExpandAll: tempRightSideProcessingParentExpandAll,
    });
  }
  // Code for selecting child from left side custodian
  selectAvaliableCustodianChild(event, dataSets) {
    let temprightSideProcessingCustodianImage = Object.assign(
      [],
      this.state.rightSideProcessingCustodianOriginal,
    );
    let templeftSideAvailableCustodian = Object.assign([], this.state.leftSideAvailableCustodian);
    let tempLeftSideAvalableParentExpandAll = this.state.leftSideAvalableParentExpandAll;
    let leftsideParentIndex =
      templeftSideAvailableCustodian &&
      templeftSideAvailableCustodian.findIndex(function (custodianData) {
        return dataSets.Custodian_id === custodianData.CRT_ID;
      });
    if (event.target.checked) {
      if (leftsideParentIndex !== -1) {
        templeftSideAvailableCustodian[leftsideParentIndex].custodian_datasets.forEach(function (
          childDatasets,
        ) {
          if (childDatasets.DataSetID === dataSets.DataSetID) {
            childDatasets.isChildChecked = true;
          }
        });
      }
      this.setState({
        leftSideAvailableCustodian: templeftSideAvailableCustodian,
      });
    } else {
      templeftSideAvailableCustodian[leftsideParentIndex].custodian_datasets.forEach(function (
        childDatasets,
      ) {
        if (childDatasets.DataSetID === dataSets.DataSetID) {
          childDatasets.isChildChecked = false;
        }
      });
      this.setState({
        leftSideAvailableCustodian: templeftSideAvailableCustodian,
        leftSideAvaliableSelectAll: false,
      });
    }
  }
  //CODE FOR SELECTING RIGHT SIDE CUSTODIAN DATASET CHILD//
  selectProcessingCustodianChild(event, dataSets) {
    let temprightSideProcessingCustodianImage = Object.assign(
      [],
      this.state.rightSideProcessingCustodianOriginal,
    );
    let rightsideParentIndex =
      temprightSideProcessingCustodianImage &&
      temprightSideProcessingCustodianImage.findIndex(function (custodianData) {
        return dataSets.Custodian_id === custodianData.CRT_ID;
      });
    if (event.target.checked) {
      if (rightsideParentIndex !== -1) {
        temprightSideProcessingCustodianImage[rightsideParentIndex].custodian_datasets.forEach(function (
          childDatasets,
        ) {
          if (childDatasets.DataSetID === dataSets.DataSetID) {
            childDatasets.isChildChecked = true;
          }
        });
      }
      this.setState({
        rightSideProcessingCustodianImage: temprightSideProcessingCustodianImage,
      });
    } else {
      temprightSideProcessingCustodianImage[rightsideParentIndex].custodian_datasets.forEach(function (
        childDatasets,
      ) {
        if (childDatasets.DataSetID === dataSets.DataSetID) {
          childDatasets.isChildChecked = false;
        }
      });
      this.setState({
        rightSideProcessingCustodianImage: temprightSideProcessingCustodianImage,
        rightSideProcessingSelectAll: false,
      });
    }
  }

  //ADD FUNCTIONALITY==========//
  handleAdd() {
    let temprightSideProcessingCustodianImage = Object.assign(
      [],
      this.state.rightSideProcessingCustodianOriginal,
    );
    let templeftSideAvailableCustodian = Object.assign([], this.state.leftSideAvailableCustodian);
    let tempAvaliableCheckdListAll = Object.assign([], this.state.avaliableCheckedListAll);
    let removedElementFromLeft = [];
    let tempLeftSideAvalableParentExpandAll = this.state.leftSideAvalableParentExpandAll;
    let templeftSideAvailableCustodian1 = Object.assign([], this.state.leftSideAvailableCustodian);
    // ==============CHECKING IF SELECT ALL FROM LEFT SIDE CLICKED OR NOT
    if (this.state.leftSideAvaliableSelectAll) {
      // IF ALREADY SOME ELEMENT IS THERE IN RIGHT SIDE THEN WILL GO WITH THIS BLOCK
      if (temprightSideProcessingCustodianImage && temprightSideProcessingCustodianImage.length > 0) {
        for (let i = 0; i < templeftSideAvailableCustodian.length; i++) {
          let index = temprightSideProcessingCustodianImage.findIndex(function (data) {
            return data.CRT_ID === templeftSideAvailableCustodian[i].CRT_ID;
          });
          if (index === -1) {
            temprightSideProcessingCustodianImage.push(templeftSideAvailableCustodian[i]);
          } else {
            //  CHECK IF CHILD ALREADY IS THERE OR NOT
            for (let j = 0; j < templeftSideAvailableCustodian[i].custodian_datasets.length; j++) {
              let checkChildIndexInRight = temprightSideProcessingCustodianImage[
                index
              ].custodian_datasets.findIndex(function (childData) {
                return (
                  childData.DataSetID === templeftSideAvailableCustodian[i].custodian_datasets[j].DataSetID
                );
              });
              if (checkChildIndexInRight === -1) {
                temprightSideProcessingCustodianImage[index].custodian_datasets.push(
                  templeftSideAvailableCustodian[i].custodian_datasets[j],
                );
              } else {
                //NOTHING HAVE TO DO
              }
            }
            //temprightSideProcessingCustodianImage[index].custodian_datasets.push(templeftSideAvailableCustodian[i].custodian_datasets)
          }
        }
        temprightSideProcessingCustodianImage.forEach(function (custodianData) {
          custodianData.isParentChecked = false;
          custodianData.partial = true;
          custodianData.custodian_datasets.forEach(function (childDatasets) {
            childDatasets.isChildChecked = false;
          });
        });

        this.setState({
          rightSideProcessingCustodianOriginal: temprightSideProcessingCustodianImage,
          leftSideAvailableCustodian: [],
          leftSideAvaliableSelectAll: false,
          avaliableCheckedListAll: [],
          filterAvailableCustodian: [],
        });
      } else {
        //=========== THIS COD EXECUTE WEN RIGHT SIDE IS BLANK AND ALL SELECT CHECKBOX CLICKED FOR LEFT SIDE===
        templeftSideAvailableCustodian.forEach(function (custodianData) {
          custodianData.isParentChecked = false;
          custodianData.custodian_datasets.forEach(function (childDatasets) {
            childDatasets.isChildChecked = false;
          });
        });
        this.setState({
          rightSideProcessingCustodianOriginal: templeftSideAvailableCustodian,
          leftSideAvailableCustodian: [],
          leftSideAvaliableSelectAll: false,
          avaliableCheckedListAll: [],
          filterAvailableCustodian: [],
        });
      }
    }
    if (this.state.avaliableCheckedListAll.length > 0 && this.state.leftSideAvaliableSelectAll !== true) {
      //======================= CODE FOR When WE  SELECT INDIVIDUAL CHECKBOX THEN WE WILL HANDLE THIS==================
      for (let i = 0; i < this.state.avaliableCheckedListAll.length; i++) {
        // CHECK IF PARENT FROM LEFT SIDE ALREADY THERE OR NOT IN RIGHT SIDE
        let self = this;
        let rightSideParentIndex = temprightSideProcessingCustodianImage.findIndex(function (data) {
          return data.CRT_ID === self.state.avaliableCheckedListAll[i];
        });
        //===  CHECKING IF PARENT ALREADY PRESENT IN RIGHT SIDE THEN ONLY CHILD HAVE TO MOVE=====
        if (rightSideParentIndex !== -1) {
          let self = this;
          let leftSideParentCustodianIndex = templeftSideAvailableCustodian.findIndex(function (value) {
            return value.CRT_ID === self.state.avaliableCheckedListAll[i];
          });
          if (templeftSideAvailableCustodian[leftSideParentCustodianIndex]) {
            for (
              let j = 0;
              j < templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length;
              j++
            ) {
              let checkChildIndexInRight = temprightSideProcessingCustodianImage[
                rightSideParentIndex
              ].custodian_datasets.findIndex(function (childData) {
                return (
                  childData.DataSetID ===
                  templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j].DataSetID
                );
              });
              if (checkChildIndexInRight === -1) {
                temprightSideProcessingCustodianImage[rightSideParentIndex].custodian_datasets.push(
                  templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j],
                );
              } else {
                //NOTHING HAVE TO DO
              }
            }
            templeftSideAvailableCustodian.splice(leftSideParentCustodianIndex, 1);
            removedElementFromLeft.push(this.state.avaliableCheckedListAll[i]);
          }
        } else {
          let self = this;
          let leftSideParentCustodianIndex = templeftSideAvailableCustodian.findIndex(function (value) {
            return value.CRT_ID === self.state.avaliableCheckedListAll[i];
          });
          temprightSideProcessingCustodianImage.push(
            templeftSideAvailableCustodian[leftSideParentCustodianIndex],
          );
          templeftSideAvailableCustodian.splice(leftSideParentCustodianIndex, 1);
          removedElementFromLeft.push(this.state.avaliableCheckedListAll[i]);
        }
      }
      temprightSideProcessingCustodianImage.forEach(function (custodianData) {
        custodianData.isParentChecked = false;
        custodianData.partial = true;
        custodianData.custodian_datasets.forEach(function (childDatasets) {
          childDatasets.isChildChecked = false;
        });
      });
      //  WRITING LOGIC FOR MENTAIN CHECKBOX ARRAY CONSISTENT
      let newAvaliableCheckedListAll = tempAvaliableCheckdListAll.filter(function (e) {
        return removedElementFromLeft.indexOf(e) === -1;
      });

      this.setState({
        leftSideAvailableCustodian: templeftSideAvailableCustodian,
        rightSideProcessingCustodianOriginal: temprightSideProcessingCustodianImage,
        avaliableCheckedListAll: newAvaliableCheckedListAll,
        filterAvailableCustodian: templeftSideAvailableCustodian,
      });
    }
    if (
      this.state.leftSideAvalableParentExpandAll.length > 0 &&
      this.state.leftSideAvaliableSelectAll !== true
    ) {
      for (let i = 0; i < this.state.leftSideAvalableParentExpandAll.length; i++) {
        // CHECK IF PARENT FROM LEFT SIDE ALREADY THERE OR NOT IN RIGHT SIDE
        let self = this;
        let rightSideParentIndex = temprightSideProcessingCustodianImage.findIndex(function (data) {
          return data.CRT_ID === self.state.leftSideAvalableParentExpandAll[i];
        });

        if (rightSideParentIndex !== -1) {
          let self = this;
          let leftSideParentCustodianIndex = templeftSideAvailableCustodian.findIndex(function (value) {
            return value.CRT_ID === self.state.leftSideAvalableParentExpandAll[i];
          });
          if (templeftSideAvailableCustodian[leftSideParentCustodianIndex]) {
            for (
              let j = 0;
              j < templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length;
              j++
            ) {
              let checkChildIndexInRight = temprightSideProcessingCustodianImage[
                rightSideParentIndex
              ].custodian_datasets.findIndex(function (childData) {
                return (
                  childData.DataSetID ===
                  templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j].DataSetID
                );
              });
              if (checkChildIndexInRight === -1) {
                if (
                  templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j]
                    .isChildChecked
                ) {
                  temprightSideProcessingCustodianImage[rightSideParentIndex].custodian_datasets.push(
                    templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j],
                  );
                }
              } else {
                //NOTHING HAVE TO DO
              }
            }
            let newAvaliableCheckedListAll = templeftSideAvailableCustodian[
              leftSideParentCustodianIndex
            ].custodian_datasets.filter(function (e) {
              return e.isChildChecked === false;
            });
            templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets =
              newAvaliableCheckedListAll;
            if (templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length > 1) {
              templeftSideAvailableCustodian[leftSideParentCustodianIndex].partial = false;
            }
            if (
              templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length === 0
            ) {
              templeftSideAvailableCustodian[leftSideParentCustodianIndex].partial = true;
              temprightSideProcessingCustodianImage[rightSideParentIndex].partial = true;
              templeftSideAvailableCustodian.splice(leftSideParentCustodianIndex, 1);
            }
            // templeftSideAvailableCustodian.splice(leftSideParentCustodianIndex, 1)
            //removedElementFromLeft.push(this.state.processingCheckedListAll[i])
          }
        }
        // IF ELEMENT IS NOT PRESENT
        else {
          let self = this;
          let temp_custodian_datasets = [];
          let leftSideParentCustodianIndex = templeftSideAvailableCustodian.findIndex(function (value) {
            return value.CRT_ID === self.state.leftSideAvalableParentExpandAll[i];
          });
          let rightSideParentIndex = temprightSideProcessingCustodianImage.findIndex(function (data) {
            return data.CRT_ID === self.state.leftSideAvalableParentExpandAll[i];
          });

          for (
            let j = 0;
            j < templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length;
            j++
          ) {
            if (
              templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j]
                .isChildChecked
            ) {
              temp_custodian_datasets.push(
                templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j],
              );
            }
          }
          if (temp_custodian_datasets && temp_custodian_datasets.length > 0) {
            let obj = {
              CRT_ID: templeftSideAvailableCustodian[leftSideParentCustodianIndex].CRT_ID,
              custodian_Name: templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_Name,
              partial:
                templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets &&
                templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length > 1
                  ? false
                  : true,
              custodian_datasets: temp_custodian_datasets,
            };
            temprightSideProcessingCustodianImage.push(obj);
            let newAvaliableCheckedListAll = templeftSideAvailableCustodian[
              leftSideParentCustodianIndex
            ].custodian_datasets.filter(function (e) {
              return e.isChildChecked === false;
            });
            templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets =
              newAvaliableCheckedListAll;
            if (templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length > 1) {
              templeftSideAvailableCustodian[leftSideParentCustodianIndex].partial = false;
            }
            if (
              templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length === 0
            ) {
              templeftSideAvailableCustodian[leftSideParentCustodianIndex].partial = true;
              templeftSideAvailableCustodian.splice(leftSideParentCustodianIndex, 1);
            }
          }
        }
      }
      temprightSideProcessingCustodianImage.forEach(function (custodianData) {
        custodianData.isParentChecked = false;
        custodianData.custodian_datasets.forEach(function (childDatasets) {
          childDatasets.isChildChecked = false;
        });
      });
      this.setState({
        leftSideAvailableCustodian: this.state.leftSideAvaliableSelectAll
          ? []
          : templeftSideAvailableCustodian,
        rightSideProcessingCustodianOriginal: temprightSideProcessingCustodianImage,
        filterAvailableCustodian: templeftSideAvailableCustodian,
      });
    }
  }

  //CODE FOR REMOVE FROM LEFT SIDE CHILD//
  handleRemove() {
    let temprightSideProcessingCustodianImage = Object.assign(
      [],
      this.state.rightSideProcessingCustodianOriginal,
    );
    let tempLeftSideAvailableCustodian = Object.assign([], this.state.leftSideAvailableCustodian);
    let tempProcessingCheckdListAll = Object.assign([], this.state.processingCheckedListAll);
    let removedElementFromRight = [];
    // ==============CHECKING IF SELECT ALL FROM LEFT SIDE CLICKED OR NOT
    if (this.state.rightSideProcessingSelectAll) {
      // IF ALREADY SOME ELEMENT IS THERE IN RIGHT SIDE THEN WILL GO WITH THIS BLOCK
      if (tempLeftSideAvailableCustodian && tempLeftSideAvailableCustodian.length > 0) {
        for (let i = 0; i < temprightSideProcessingCustodianImage.length; i++) {
          let index = tempLeftSideAvailableCustodian.findIndex(function (data) {
            return data.CRT_ID === temprightSideProcessingCustodianImage[i].CRT_ID;
          });
          if (index === -1) {
            tempLeftSideAvailableCustodian.push(temprightSideProcessingCustodianImage[i]);
          } else {
            //  CHECK IF CHILD ALREADY IS THERE OR NOT
            for (let j = 0; j < temprightSideProcessingCustodianImage[i].custodian_datasets.length; j++) {
              let checkChildIndexInRight = tempLeftSideAvailableCustodian[index].custodian_datasets.findIndex(
                function (childData) {
                  return (
                    childData.DataSetID ===
                    temprightSideProcessingCustodianImage[i].custodian_datasets[j].DataSetID
                  );
                },
              );
              if (checkChildIndexInRight === -1) {
                tempLeftSideAvailableCustodian[index].custodian_datasets.push(
                  temprightSideProcessingCustodianImage[i].custodian_datasets[j],
                );
              } else {
                //NOTHING HAVE TO DO
              }
            }
            //temprightSideProcessingCustodianImage[index].custodian_datasets.push(temprightSideProcessingCustodianImage[i].custodian_datasets)
          }
        }
        tempLeftSideAvailableCustodian.forEach(function (custodianData) {
          custodianData.isParentChecked = false;
          custodianData.partial = true;
          custodianData.custodian_datasets.forEach(function (childDatasets) {
            childDatasets.isChildChecked = false;
          });
        });

        this.setState({
          rightSideProcessingCustodianOriginal: [],
          leftSideAvailableCustodian: tempLeftSideAvailableCustodian,
          rightSideProcessingSelectAll: false,
          processingCheckedListAll: [],
          filterAvailableCustodian: tempLeftSideAvailableCustodian,
        });
      } else {
        //=========== THIS COD EXECUTE WEN RIGHT SIDE IS BLANK AND ALL SELECT CHECKBOX CLICKED FOR LEFT SIDE===
        temprightSideProcessingCustodianImage.forEach(function (custodianData) {
          custodianData.isParentChecked = false;
          custodianData.custodian_datasets.forEach(function (childDatasets) {
            childDatasets.isChildChecked = false;
          });
        });
        this.setState({
          rightSideProcessingCustodianOriginal: [],
          leftSideAvailableCustodian: temprightSideProcessingCustodianImage,
          rightSideProcessingSelectAll: false,
          processingCheckedListAll: [],
          filterAvailableCustodian: temprightSideProcessingCustodianImage,
        });
      }
    }
    if (this.state.processingCheckedListAll.length > 0 && this.state.rightSideProcessingSelectAll !== true) {
      //======================= CODE FOR When WE  SELECT INDIVIDUAL CHECKBOX THEN WE WILL HANDLE THIS==================
      for (let i = 0; i < this.state.processingCheckedListAll.length; i++) {
        // CHECK IF PARENT FROM LEFT SIDE ALREADY THERE OR NOT IN RIGHT SIDE
        let self = this;
        let rightSideParentIndex = tempLeftSideAvailableCustodian.findIndex(function (data) {
          return data.CRT_ID === self.state.processingCheckedListAll[i];
        });
        //===  CHECKING IF PARENT ALREADY PRESENT IN RIGHT SIDE THEN ONLY CHILD HAVE TO MOVE=====
        if (rightSideParentIndex !== -1) {
          let self = this;
          let leftSideParentCustodianIndex = temprightSideProcessingCustodianImage.findIndex(function (
            value,
          ) {
            return value.CRT_ID === self.state.processingCheckedListAll[i];
          });
          if (temprightSideProcessingCustodianImage[leftSideParentCustodianIndex]) {
            for (
              let j = 0;
              j <
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets.length;
              j++
            ) {
              let checkChildIndexInRight = temprightSideProcessingCustodianImage[
                rightSideParentIndex
              ].custodian_datasets.findIndex(function (childData) {
                return (
                  childData.DataSetID ===
                  temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j]
                    .DataSetID
                );
              });
              if (checkChildIndexInRight === -1) {
                tempLeftSideAvailableCustodian[rightSideParentIndex].custodian_datasets.push(
                  temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j],
                );
              } else {
                //NOTHING HAVE TO DO
              }
            }
            temprightSideProcessingCustodianImage.splice(leftSideParentCustodianIndex, 1);
            removedElementFromRight.push(this.state.processingCheckedListAll[i]);
          }
        } else {
          let self = this;
          let leftSideParentCustodianIndex = temprightSideProcessingCustodianImage.findIndex(function (
            value,
          ) {
            return value.CRT_ID === self.state.processingCheckedListAll[i];
          });
          tempLeftSideAvailableCustodian.push(
            temprightSideProcessingCustodianImage[leftSideParentCustodianIndex],
          );
          temprightSideProcessingCustodianImage.splice(leftSideParentCustodianIndex, 1);
          removedElementFromRight.push(this.state.processingCheckedListAll[i]);
        }
      }
      tempLeftSideAvailableCustodian.forEach(function (custodianData) {
        custodianData.isParentChecked = false;
        custodianData.partial = true;
        custodianData.custodian_datasets.forEach(function (childDatasets) {
          childDatasets.isChildChecked = false;
        });
      });
      //  WRITING LOGIC FOR MENTAIN CHECKBOX ARRAY CONSISTENT
      let newProcessingCheckedListAll = tempProcessingCheckdListAll.filter(function (e) {
        return removedElementFromRight.indexOf(e) === -1;
      });

      this.setState({
        rightSideProcessingCustodianOriginal: temprightSideProcessingCustodianImage,
        leftSideAvailableCustodian: tempLeftSideAvailableCustodian,
        processingCheckedListAll: newProcessingCheckedListAll,
        filterAvailableCustodian: tempLeftSideAvailableCustodian,
      });
    }
    if (
      this.state.rightSideProcessingParentExpandAll &&
      this.state.rightSideProcessingParentExpandAll.length > 0 &&
      this.state.rightSideProcessingSelectAll !== true
    ) {
      for (let i = 0; i < this.state.rightSideProcessingParentExpandAll.length; i++) {
        // CHECK IF PARENT FROM LEFT SIDE ALREADY THERE OR NOT IN RIGHT SIDE
        let self = this;
        let rightSideParentIndex = tempLeftSideAvailableCustodian.findIndex(function (data) {
          return data.CRT_ID === self.state.rightSideProcessingParentExpandAll[i];
        });

        if (rightSideParentIndex !== -1) {
          let self = this;
          let leftSideParentCustodianIndex = temprightSideProcessingCustodianImage.findIndex(function (
            value,
          ) {
            return value.CRT_ID === self.state.rightSideProcessingParentExpandAll[i];
          });
          if (temprightSideProcessingCustodianImage[leftSideParentCustodianIndex]) {
            for (
              let j = 0;
              j <
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets.length;
              j++
            ) {
              let checkChildIndexInRight = tempLeftSideAvailableCustodian[
                rightSideParentIndex
              ].custodian_datasets.findIndex(function (childData) {
                return (
                  childData.DataSetID ===
                  temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j]
                    .DataSetID
                );
              });
              if (checkChildIndexInRight === -1) {
                if (
                  temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j]
                    .isChildChecked
                ) {
                  tempLeftSideAvailableCustodian[rightSideParentIndex].custodian_datasets.push(
                    temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j],
                  );
                }
              } else {
                //NOTHING HAVE TO DO
              }
            }
            let newAvaliableCheckedListAll = temprightSideProcessingCustodianImage[
              leftSideParentCustodianIndex
            ].custodian_datasets.filter(function (e) {
              return e.isChildChecked === false;
            });
            temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets =
              newAvaliableCheckedListAll;
            if (
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets.length >
              1
            ) {
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].partial = false;
            }
            if (
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets
                .length === 0
            ) {
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].partial = true;
              tempLeftSideAvailableCustodian[rightSideParentIndex].partial = true;
              temprightSideProcessingCustodianImage.splice(leftSideParentCustodianIndex, 1);
            }
          }
        }
        // IF ELEMENT IS NOT PRESENT
        else {
          let self = this;
          let temp_custodian_datasets = [];
          let leftSideParentCustodianIndex = temprightSideProcessingCustodianImage.findIndex(function (
            value,
          ) {
            return value.CRT_ID === self.state.rightSideProcessingParentExpandAll[i];
          });
          let rightSideParentIndex = tempLeftSideAvailableCustodian.findIndex(function (data) {
            return data.CRT_ID === self.state.rightSideProcessingParentExpandAll[i];
          });

          for (
            let j = 0;
            j < temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets.length;
            j++
          ) {
            if (
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j]
                .isChildChecked
            ) {
              temp_custodian_datasets.push(
                temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j],
              );
            }
          }
          if (temp_custodian_datasets && temp_custodian_datasets.length > 0) {
            let obj = {
              CRT_ID: temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].CRT_ID,
              custodian_Name:
                temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_Name,
              partial:
                temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets &&
                temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets
                  .length > 1
                  ? false
                  : true,
              custodian_datasets: temp_custodian_datasets,
            };
            tempLeftSideAvailableCustodian.push(obj);
            let newAvaliableCheckedListAll = temprightSideProcessingCustodianImage[
              leftSideParentCustodianIndex
            ].custodian_datasets.filter(function (e) {
              return e.isChildChecked === false;
            });
            temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets =
              newAvaliableCheckedListAll;
            if (
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets.length >
              1
            ) {
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].partial = false;
            }
            if (
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets
                .length === 0
            ) {
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].partial = true;
              temprightSideProcessingCustodianImage.splice(leftSideParentCustodianIndex, 1);
            }
          }
        }
      }
      tempLeftSideAvailableCustodian.forEach(function (custodianData) {
        custodianData.isParentChecked = false;
        custodianData.custodian_datasets.forEach(function (childDatasets) {
          childDatasets.isChildChecked = false;
        });
      });
      this.setState({
        rightSideProcessingCustodianOriginal: this.state.rightSideProcessingSelectAll
          ? []
          : temprightSideProcessingCustodianImage,
        leftSideAvailableCustodian: tempLeftSideAvailableCustodian,
        filterAvailableCustodian: tempLeftSideAvailableCustodian,
      });
    }
  }

  avaliableCustodianOnChange(dataSets, event) {
    let templeftSideAvailableCustodian = JSON.parse(JSON.stringify(this.state.leftSideAvailableCustodian));
    let leftsideParentIndex =
      templeftSideAvailableCustodian &&
      templeftSideAvailableCustodian.findIndex(function (custodianData) {
        return dataSets.CRT_ID === custodianData.CRT_ID;
      });
    if (event.target.checked) {
      if (leftsideParentIndex !== -1) {
        templeftSideAvailableCustodian[leftsideParentIndex].isParentChecked = true;
        templeftSideAvailableCustodian[leftsideParentIndex].custodian_datasets.forEach(function (
          childDatasets,
        ) {
          childDatasets.isChildChecked = true;
        });
      }
      //================== MARKED SELECT ALL CHECKED IF ALL PARENT CHECKBOX WITH CHECKED=================================

      this.setState(
        (prevState) => ({
          avaliableCheckedListAll: [...prevState.avaliableCheckedListAll, dataSets.CRT_ID],
          leftSideAvailableCustodian: templeftSideAvailableCustodian,
        }),
        () => {
          if (templeftSideAvailableCustodian.length === this.state.avaliableCheckedListAll.length) {
            this.setState({
              leftSideAvaliableSelectAll: true,
            });
          }
        },
      );
    } else {
      if (leftsideParentIndex !== -1) {
        templeftSideAvailableCustodian[leftsideParentIndex].isParentChecked = false;
        templeftSideAvailableCustodian[leftsideParentIndex].custodian_datasets.forEach(function (
          childDatasets,
        ) {
          childDatasets.isChildChecked = false;
        });
      }
      let tempavailableChecked = this.state.avaliableCheckedListAll;
      let avalibaleCheckedIndex = tempavailableChecked.findIndex(function (data) {
        return parseInt(event.target.value) === data;
      });
      tempavailableChecked.splice(avalibaleCheckedIndex, 1);

      if (avalibaleCheckedIndex !== -1) {
        this.setState({
          avaliableCheckedListAll: tempavailableChecked,
        });
      }
      this.setState({
        leftSideAvailableCustodian: templeftSideAvailableCustodian,
        leftSideAvaliableSelectAll: false,
      });
    }
  }

  //====================CODE FOR WHEN WE CHECKED SELECT ALL AVAILABLE CUSTODIAN DATA ====================================

  selectAllAvailable(event) {
    if (event.target.checked) {
      //  CALLING COMMON FUNCTION BASED ON EVENT ===========
      this.setParentChildCheckBox(
        event.target.checked,
        JSON.parse(JSON.stringify(this.state.leftSideAvailableCustodian)),
        "left",
      );
    } else {
      // HERE ALSO CALLING COMMON FUNCTION BASED ON EVENT TARGET VALUE===============
      this.setParentChildCheckBox(
        event.target.checked,
        JSON.parse(JSON.stringify(this.state.leftSideAvailableCustodian)),
        "left",
      );
    }
  }
  //=================== COMMON FUNCTION FOR SET RESET CHECKBOX BASED ON CHECKED TRUE OR FALSE==================
  setParentChildCheckBox(eventValue, datasourceValue, boxSide) {
    let tempAvaliableCheckedListAll = this.state.avaliableCheckedListAll;
    let tempProcessingCheckedListAll = this.state.processingCheckedListAll;
    let templeftSideAvailableCustodian = datasourceValue;
    templeftSideAvailableCustodian &&
      templeftSideAvailableCustodian.forEach(function (custodianData) {
        custodianData.isParentChecked = eventValue;
        if (eventValue) {
          if (boxSide === "left") {
            tempAvaliableCheckedListAll.push(custodianData.CRT_ID);
          }
          if (boxSide === "right") {
            tempProcessingCheckedListAll.push(custodianData.CRT_ID);
          }
        }
        custodianData.custodian_datasets.forEach(function (childDatasets) {
          childDatasets.isChildChecked = eventValue;
        });
      });
    if (boxSide === "left") {
      this.setState({
        leftSideAvailableCustodian: templeftSideAvailableCustodian,
        leftSideAvaliableSelectAll: eventValue,
        avaliableCheckedListAll: eventValue === false ? [] : tempAvaliableCheckedListAll,
      });
    }
    if (boxSide === "right") {
      this.setState({
        rightSideProcessingCustodianOriginal: templeftSideAvailableCustodian,
        rightSideProcessingSelectAll: eventValue,
        processingCheckedListAll: eventValue === false ? [] : this.state.processingCheckedListAll,
      });
    }
  }

  processingCustodianOnChange(dataSets, event) {
    let tempRightSideProcessingCustodianOriginal = JSON.parse(
      JSON.stringify(this.state.rightSideProcessingCustodianOriginal),
    );
    let rightSideParentIndex =
      tempRightSideProcessingCustodianOriginal &&
      tempRightSideProcessingCustodianOriginal.findIndex(function (custodianData) {
        return dataSets.CRT_ID === custodianData.CRT_ID;
      });
    if (event.target.checked) {
      if (rightSideParentIndex !== -1) {
        tempRightSideProcessingCustodianOriginal[rightSideParentIndex].isParentChecked = true;
        tempRightSideProcessingCustodianOriginal[rightSideParentIndex].custodian_datasets.forEach(function (
          childDatasets,
        ) {
          childDatasets.isChildChecked = true;
        });
      }
      //================== MARKED SELECT ALL CHECKED IF ALL PARENT CHECKBOX WITH CHECKED=================================

      this.setState(
        (prevState) => ({
          processingCheckedListAll: [...prevState.processingCheckedListAll, dataSets.CRT_ID],
          rightSideProcessingCustodianOriginal: tempRightSideProcessingCustodianOriginal,
        }),
        () => {
          if (
            tempRightSideProcessingCustodianOriginal.length === this.state.processingCheckedListAll.length
          ) {
            this.setState({
              rightSideProcessingSelectAll: true,
            });
          }
        },
      );
    } else {
      if (rightSideParentIndex !== -1) {
        tempRightSideProcessingCustodianOriginal[rightSideParentIndex].isParentChecked = false;
        tempRightSideProcessingCustodianOriginal[rightSideParentIndex].custodian_datasets.forEach(function (
          childDatasets,
        ) {
          childDatasets.isChildChecked = false;
        });
      }
      let tempavailableChecked = this.state.processingCheckedListAll;
      let avalibaleCheckedIndex = tempavailableChecked.findIndex(function (data) {
        return parseInt(event.target.value) === data;
      });
      tempavailableChecked.splice(avalibaleCheckedIndex, 1);

      if (avalibaleCheckedIndex !== -1) {
        this.setState({
          processingCheckedListAll: tempavailableChecked,
        });
      }
      this.setState({
        rightSideProcessingCustodianOriginal: tempRightSideProcessingCustodianOriginal,
        rightSideProcessingSelectAll: false,
      });
    }
  }

  //====================CODE FOR WHEN WE CHECKED SELECT ALL PROCESSING CUSTODIAN DATA ====================================

  selectAllProcessingAvailable(event) {
    if (event.target.checked) {
      //  CALLING COMMON FUNCTION BASED ON EVENT ===========
      this.setParentChildCheckBox(
        event.target.checked,
        JSON.parse(JSON.stringify(this.state.rightSideProcessingCustodianOriginal)),
        "right",
      );
    } else {
      // HERE ALSO CALLING COMMON FUNCTION BASED ON EVENT TARGET VALUE===============
      this.setParentChildCheckBox(
        event.target.checked,
        JSON.parse(JSON.stringify(this.state.rightSideProcessingCustodianOriginal)),
        "right",
      );
    }
  }
  //CODE FOR FILTER CUSTODIAN BASED ON CUSTODIAN NAME//
  filterAvailableCustodian(label, value) {
    let templeftSideAvailableCustodian = JSON.parse(JSON.stringify(this.state.filterAvailableCustodian));
    if (value && value !== "") {
      let CustodianFilterdList =
        templeftSideAvailableCustodian &&
        templeftSideAvailableCustodian.filter((el) =>
          el["custodian_Name"].toLowerCase().includes(value.toLowerCase()),
        );
      this.setState({
        leftSideAvailableCustodian: CustodianFilterdList,
      });
    } else {
      this.setState({
        leftSideAvailableCustodian: this.state.filterAvailableCustodian,
      });
    }
  }
  runProcessing() {
    let tempSaveProcessingData = JSON.parse(JSON.stringify(this.state.rightSideProcessingCustodianOriginal));
    let tempObj = [];
    for (let i = 0; i < tempSaveProcessingData.length; i++) {
      for (let j = 0; j < tempSaveProcessingData[i].custodian_datasets.length; j++) {
        let obj = {
          PROJECT_ID: this.props.selectedProjectId,
          CUSTODIAN_ID: tempSaveProcessingData[i].CRT_ID,
          DATASET_ID: tempSaveProcessingData[i].custodian_datasets[j].DataSetID,
          PROFILE_ID: this.state.selectedProfileId,
          COLLECTION_STATUS: 1,
          PROCESSING_STATUS: 1,
          CLIENT_ID: 1,
        };
        tempObj.push(obj);
      }
    }
    const processingService = new ProcessingService();
    processingService
      .saveProcessing(tempObj)
      .then((data) => {
        this.props.showlistProcessing();
      })
      .catch((err) => {
        alert(err);
      });
  }

  onChangeProfile(data) {
    this.setState(
      {
        selectedProfileId: parseInt(data),
      },
      () => {
        if (
          this.state.rightSideProcessingCustodianOriginal &&
          this.state.rightSideProcessingCustodianOriginal.length > 0 &&
          this.state.selectedProfileId
        ) {
          this.setState({
            allowRunProcessing: true,
          });
        } else {
          this.setState({
            allowRunProcessing: false,
          });
        }
      },
    );
  }
  onBackButtonClick() {
    this.props.showlistProcessing();
  }

  // unchecks all the datasets from the given array
  uncheckDatasets = (datasets) => {
    try {
      const temp = [];
      let tempObj = {};
      datasets.forEach((each) => {
        tempObj = { ...each };
        tempObj.isChildChecked = false; // uncheck all the child checkboxes
        temp.push(tempObj);
      });
      return temp;
    } catch (err) {
      displayToastMessage(err.message, "error");
      return datasets;
    }
  };

  // uncheck parent and child items of the custodian array
  removeCheckboxOnRightSideProcessing = (rightSideProcessingCustodianOriginal) => {
    try {
      const temp = [];
      let tempObj = {};
      rightSideProcessingCustodianOriginal.forEach((each) => {
        tempObj = { ...each };
        tempObj.isParentChecked = false; // uncheck the parent checkbox
        tempObj.custodian_datasets = this.uncheckDatasets(each.custodian_datasets); // array of all unchecked child checkboxes
        temp.push(tempObj);
      });
      return temp;
    } catch (err) {
      displayToastMessage(err.message, "error");
      return rightSideProcessingCustodianOriginal;
    }
  };
  saveProcessingData() {
    try {
      if (
        this.state.rightSideProcessingCustodianOriginal &&
        this.state.rightSideProcessingCustodianOriginal.length > 0
      ) {
        let tempSaveProcessingData = JSON.parse(
          JSON.stringify(this.state.rightSideProcessingCustodianOriginal),
        );
        let tempObj = [];
        for (let i = 0; i < tempSaveProcessingData.length; i++) {
          for (let j = 0; j < tempSaveProcessingData[i].custodian_datasets.length; j++) {
            let obj = {
              PROJECT_ID: this.props.selectedProjectId,
              CUSTODIAN_ID: tempSaveProcessingData[i].CRT_ID,
              DATASET_ID: tempSaveProcessingData[i].custodian_datasets[j].DataSetID,
              COLLECTION_STATUS: 1,
              PROCESSING_STATUS: 1,
              CLIENT_ID: 1,
            };
            tempObj.push(obj);
          }
        }

        // remove isParentChecked from each custodian item present in this.state.rightSideProcessingCustodianOriginal
        const rightSideProcessingCustodianOriginalUnchecked = this.removeCheckboxOnRightSideProcessing(
          this.state.rightSideProcessingCustodianOriginal,
        );

        this.props.custodianData(
          tempObj,
          this.state.leftSideAvailableCustodian,
          rightSideProcessingCustodianOriginalUnchecked,
        );
      } else {
        this.polyglot.t("");
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  }

  //=================== FUNCTION FOR ENABLE AND DISABLE REMOVE BUTTON BASED ON CONDITION

  //=================== FUNCTION FOR ENABLE AND DISABLE REMOVE BUTTON BASED ON CONDITION
  shouldremoveButtonDisabled() {
    let returnValue = this.enableAndDisbaleAddRemoveButton(
      this.state.rightSideProcessingCustodianOriginal,
      this.state.processingCheckedListAll,
    );
    return returnValue;
  }
  // ====================FUNCTION FOR ENABLE AND DISABLE ADD  BUTTON BASED ON CONDITION
  shouldAddButtonDisabled() {
    let returnValue = this.enableAndDisbaleAddRemoveButton(
      this.state.leftSideAvailableCustodian,
      this.state.avaliableCheckedListAll,
    );
    return returnValue;
  }
  //============ COMMON FUNCTION FOR DISBALE BOTH BUTTON ADD OR REMOVE BASED ON CONDITION==============
  enableAndDisbaleAddRemoveButton(dataSourceForCustodian, checkListAll) {
    let templeftSideAvailableCustodian = dataSourceForCustodian;
    var leftSideAvalableCheckox = false;
    if (templeftSideAvailableCustodian && templeftSideAvailableCustodian.length > 0) {
      for (let i = 0; i < templeftSideAvailableCustodian.length; i++) {
        if (
          templeftSideAvailableCustodian[i].custodian_datasets &&
          templeftSideAvailableCustodian[i].custodian_datasets.length > 0
        ) {
          for (let j = 0; j < templeftSideAvailableCustodian[i].custodian_datasets.length; j++) {
            if (templeftSideAvailableCustodian[i].custodian_datasets[j].isChildChecked === true) {
              leftSideAvalableCheckox = true;
              break;
            }
          }
        }
      }
    }
    if ((checkListAll && checkListAll.length > 0) || leftSideAvalableCheckox) {
      return false;
    } else {
      return true;
    }
  }
  render() {
    return (
      <div className="">
        <div className="row mainProcessingDiv m-l-0-1">
          <div className={this.state.leftSideWidtBeforeClick}>
            <div className="row textAvialble m-l-0 m-r-0 justifycontent-center">
              <span className="text-Avl-two">{this.polyglot.t("Available data")}</span>
            </div>
            <div className="row searchBoxProcessingDiv m-l-0 m-r-0">
              <div className="pod-title pt-0" style={{ width: "100%" }}>
                <SearchBar
                  labelClass={"full-width"}
                  nameOfSearch={"Custodian"}
                  labelClass={"full-width"}
                  customClass={"availableProcessingCustodian"}
                  aria-label={this.polyglot.t("Search Custodian datasets")}
                  placeholder={this.polyglot.t("Search Custodian datasets")}
                  id="search"
                  name="serach_text"
                  val={this.state.search_text}
                  onChange={this.filterAvailableCustodian.bind(this)}
                />
              </div>
            </div>
            <div className="row selectAllLeftSide m-l-0 m-r-0">
              <div className="selectAllDiv">
                <Checkbox
                  name="select_All_Avaliable"
                  checked={this.state.leftSideAvaliableSelectAll}
                  onClick={this.selectAllAvailable.bind(this)}
                  className="check-list-one "
                >
                  {this.polyglot.t("Select All")}
                </Checkbox>
              </div>
            </div>
            <div className="">
              <div className={this.state.leftsidecustodiandivstyle}>
                {this.state.leftSideAvailableCustodian &&
                  this.state.leftSideAvailableCustodian.map((element, index) => {
                    return (
                      <div className="process-selct-data " key={index}>
                        <Checkbox
                          name="CRT_FIRST_NAME"
                          aria-label={element.CRT_ID}
                          key={element.CRT_ID}
                          value={element.CRT_ID}
                          checked={element.isParentChecked}
                          onChange={this.avaliableCustodianOnChange.bind(this, element)}
                          className="check-list-one"
                        ></Checkbox>
                        <div className="custo_name" title={element.custodian_Name}>
                          {element.custodian_Name}
                        </div>
                        {element.partial === false && (
                          <span class=" circle-dot circle-orange " aria-hidden="true"></span>
                        )}
                        {this.state.showUploadKeyword === true && (
                          <span
                            className="customArrow2"
                            onClick={this.expandAvailableParent.bind(this, element, index)}
                          ></span>
                        )}
                        {this.state.showUploadKeyword !== true && (
                          <span
                            className="customArrow"
                            onClick={this.expandAvailableParent.bind(this, element, index)}
                          ></span>
                        )}
                      </div>
                    );
                  })}
              </div>
              {this.state.showUploadKeyword ? (
                <div className="">
                  <div className="check-sublist-one">
                    <div className="mb-4 mt-1">
                      <div className="tot-check-child-two ">
                        {this.state.selectedAvailableParentIndex != null &&
                          this.state.leftSideAvailableCustodian.length > 0 &&
                          this.state.leftSideAvailableCustodian[this.state.selectedAvailableParentIndex] &&
                          this.state.leftSideAvailableCustodian[this.state.selectedAvailableParentIndex]
                            .custodian_datasets.length > 0 &&
                          this.state.leftSideAvailableCustodian[
                            this.state.selectedAvailableParentIndex
                          ].custodian_datasets.map((element, index) => {
                            return (
                              <div className="process-selct-data">
                                <Checkbox
                                  name="CRT_FIRST_NAME"
                                  aria-label={element.DataSetID}
                                  onChange={(e) => this.selectAvaliableCustodianChild(e, element)}
                                  key={element.DataSetID}
                                  value={element.DataSetID}
                                  className="check-list-one"
                                  checked={element.isChildChecked}
                                ></Checkbox>
                                <div className="custo_name" title={element.DataSetName}>
                                  {" "}
                                  {element.DataSetName}
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
          {/*====================CODE FOR MIDDLE BOX DIV=================  */}
          <div className="col-sm-2 col-md-2 res-re center-align-content">
            <button
              disabled={this.shouldAddButtonDisabled()}
              className={
                this.shouldAddButtonDisabled()
                  ? "ProcessingAddBtnDisable no-pointer b-color "
                  : "ProcessingAddBtn cursor-pointer "
              }
              id="add-btn"
              onClick={this.handleAdd.bind(this)}
            >
              {this.polyglot.t("Add")}
              {this.shouldAddButtonDisabled() ? (
                <img class="p-l-7" src={rightBlackIcon} alt="" />
              ) : (
                <img className="p-l-7" src={rightNormalIcon} alt="" />
              )}
            </button>
            <br />
            <br />
            <button
              disabled={this.shouldremoveButtonDisabled()}
              className={
                this.shouldremoveButtonDisabled()
                  ? "ProcessingAddBtnDisable no-pointer b-color "
                  : "ProcessingAddBtn cursor-pointer "
              }
              id="remove-btn"
              onClick={this.handleRemove.bind(this)}
            >
              {this.shouldremoveButtonDisabled() ? (
                <img class="p-r-7 p-b-1" src={leftBlackIcon} alt="" />
              ) : (
                <img class="p-r-7 p-b-1" src={leftNormalIcon} alt="" />
              )}
              {this.polyglot.t("Remove")}
            </button>
          </div>
          {/*======================== CODE FOR RIGHT SIDE BOXES====================== */}
          <div className={this.state.rightSideWidtBeforeClick}>
            <div className="row textAvialble m-l-0 m-r-0 justifycontent-center">
              <span className="text-Avl-two">{this.polyglot.t("Current selection")}</span>
            </div>
            <div className="row textAvialble m-l-0 m-r-0 justifycontent-center p-t-0">
              <ButtonPrimary
                onClick={() => this.saveProcessingData()}
                ariaLabel={this.polyglot.t("Use selection")}
              >
                {this.polyglot.t("Use selection")}
              </ButtonPrimary>
            </div>
            <div className="selectAllDiv_padLeft24">
              <Checkbox
                name="select_All_Avaliable"
                className="check-list-one custo_name_nopadding"
                onClick={this.selectAllProcessingAvailable.bind(this)}
                checked={this.state.rightSideProcessingSelectAll}
              >
                {" "}
                {this.polyglot.t("Select All")}
              </Checkbox>
            </div>

            <div className={this.state.rightsidecustodiandivstyle}>
              {this.state.rightSideProcessingCustodianOriginal &&
                this.state.rightSideProcessingCustodianOriginal.length > 0 &&
                this.state.rightSideProcessingCustodianOriginal.map((element, index) => {
                  return (
                    <div className="process-selct-data ">
                      <Checkbox
                        name="CRT_FIRST_NAME"
                        aria-label={element.CRT_ID}
                        key={index}
                        value={element.CRT_ID}
                        checked={element.isParentChecked}
                        onChange={this.processingCustodianOnChange.bind(this, element)}
                        className="check-list-one"
                      ></Checkbox>
                      <div className="custo_name" title={element.custodian_Name}>
                        {" "}
                        {element.custodian_Name}{" "}
                      </div>
                      {element.partial === false && (
                        <span class=" circle-dot circle-orange " aria-hidden="true"></span>
                      )}
                      <span
                        className="ArrowIcon"
                        onClick={this.expandProcessingParent.bind(this, element, index)}
                      ></span>
                    </div>
                  );
                })}
            </div>

            {this.state.showUploadKeyword1 ? (
              <div className="check-sublist-one">
                <div className="mb-4 mt-1">
                  <div>
                    <div className="tot-check-child-two">
                      {this.state.selectedProcessingParent !== null &&
                        this.state.rightSideProcessingCustodianOriginal.length > 0 &&
                        this.state.rightSideProcessingCustodianOriginal[
                          this.state.selectedProcessingParentIndex
                        ].custodian_datasets.map((element, index) => {
                          return (
                            <div className="process-selct-data ">
                              <Checkbox
                                name="CRT_FIRST_NAME"
                                aria-label={element.DataSetID}
                                key={element.DataSetID}
                                value={element.DataSetID}
                                onChange={(e) => this.selectProcessingCustodianChild(e, element)}
                                checked={element.isChildChecked}
                              ></Checkbox>
                              <div className="custo_name" title={element.DataSetName}>
                                {" "}
                                {element.DataSetName}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  }
}

export default dualBox;
