import { Tag, DatePicker, ConfigProvider } from "antd";
import gr from "antd/lib/locale-provider/de_DE";
import { Input } from "appkit-react";
import { Formik, FieldArray } from "formik";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useHistory } from "react-router-dom";
import { object, string, array, date } from "yup";

import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import calendar from "../../../Assets/Icons/calendar.svg";
import AdminServiceRI from "../../../Services/RI/AdminService";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader, getCurrentLanguage } from "../../../Services/Shared/Translate";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";

function RequestFormFields(props) {
  const polyglot = polyglotLoader();
  const language = getCurrentLanguage();
  const { formGotOpened, formGotClosed } = useContext(DataContext);

  // INITIAL VALUES OF THE FORMIK FORM
  const initialValues = {
    entity: "", // value on change of entity
    entities: [], // all the entity values which user has entered
    time_scope_from: "",
    time_scope_to: "",
  };

  const [formValues, setFormValues] = useState({ ...initialValues });
  const [result, setResult] = useState([]);

  const history = useHistory();
  const filterIt = (arr, searchKey) => {
    return arr.filter(function (obj) {
      let pattern = new RegExp(searchKey, "gi");
      if (obj.themes.search(pattern) !== -1) {
        return obj;
      }
    });
  };

  // VALIDATION SCHEMA OF THE FORM
  const validationSchema = object().shape({
    entity: string(),
    entities: array().required("Entity is required"),
    time_scope_from: date().nullable().required(polyglot.t("Select the start date")),
    time_scope_to: date().nullable().required(polyglot.t("Select the end date")),
  });

  // SUBMIT FORM
  const submitForm = async (values, actions) => {
    try {
      values.projectName = sessionStorage.getItem("selectedProjectName");
      values.projectId = sessionStorage.getItem("selectedProject");
      if (!values.projectName) {
        displayToastMessage(polyglot.t("Project selection failed"), "error");
        return false;
      }
      const res = await AdminServiceRI.get().createRIRequest(values);
      if (res.CODE) {
        displayToastMessage(polyglot.t(res.MSG), "success");
        // Creating folder structure
        try {
          const newRequestID = res.list[0].ID;
          // let folderPath = res.folderPath;
          // const resPy = await AdminServiceRI.get().createFolder({id:newRequestID, folderPath: folderPath});
          const clientuserid = res.clientuserid;

          // COMMENTED TO BE RESOLVED IN THE FUTURE
          // NOW THERE IS NO ENDPOINT "/createFolder" ON BACKEND
          // const resPy = await AdminServiceRI.get().createFolder({
          //   id: newRequestID,
          //   clientuserid,
          // });
        } catch (err) {
          displayToastMessage(err.message, "error");
        }
        actions.resetForm();
        history.push("/ri/home");
      } else {
        displayToastMessage(polyglot.t(res.MSG), "error");
      }
    } catch (err) {
      displayToastMessage(polyglot.t(err.MSG), "error");
    }
  };

  // GET MOMENT DATE
  const getMomentDate = (isoDate) => {
    if (!isoDate || isoDate === "") {
      return "";
    } else {
      return moment(new Date(isoDate));
    }
  };

  //RUNS ON FIELD CHANGE
  const fieldChange = (key, value, handleChange) => {
    const formValuesClone = { ...formValues };
    formValuesClone[key] = value;
    setFormValues(formValuesClone);
    handleChange(key)({ target: { value } });
  };

  return (
    <Formik initialValues={initialValues} onSubmit={submitForm} validationSchema={validationSchema}>
      {(formikProps) => {
        const { values, handleChange, handleSubmit, touched, errors, dirty } = formikProps;

        // FORMIK FORM - HANDLE FORMOPEN OR CLOSE STATUS
        const updateFormStatusBasedOnDirty = () => {
          if (dirty) {
            formGotOpened();
          }
          if (!dirty) {
            formGotClosed();
          }
        };
        updateFormStatusBasedOnDirty();

        const restrictStartDates = (current, endDate) => {
          const customDate = new Date(endDate);
          customDate.setDate(customDate.getDate());
          return (
            (current && current.valueOf() < new Date("2015-01-01").getTime()) ||
            (current && current.valueOf() > Date.now()) ||
            (current && current > customDate)
          );
        };

        const restrictEndDates = (current, selectedStartDate) => {
          const customDate = new Date(selectedStartDate);
          customDate.setDate(customDate.getDate());
          return (
            (current && current.valueOf() < new Date("2015-01-01").getTime()) ||
            (current && current.valueOf() > Date.now()) ||
            (current && current < customDate)
          );
        };

        return (
          <>
            <div className="mb-4">
              <FieldArray
                name="entities"
                render={(arrayHelpers) => {
                  const addValuesToEntitiesList = () => {
                    if (
                      values.entity !== "" &&
                      !values.entities.map((e) => e.toLocaleLowerCase()).includes(values.entity)
                    ) {
                      arrayHelpers.push(values.entity);
                      handleChange("entity")({
                        target: {
                          value: "",
                        },
                      });
                    }
                  };
                  // CATCH KEY UP WHILE ENTERING ENTITY
                  const catchKeyDownOnEntity = (event) => {
                    // Note: entity will be assigned on clicking enter
                    if (!errors.entity) {
                      switch (event.keyCode) {
                        case 13:
                          return addValuesToEntitiesList();
                      }
                    }
                  };
                  return (
                    <>
                      <p className="small">{polyglot.t("Press ENTER key to add entity")}</p>
                      <Input
                        inputBoxSize="sm"
                        placeholder={`${polyglot.t("Enter entities of interest")} *`}
                        value={values.entity}
                        onChange={handleChange("entity")}
                        onKeyDown={catchKeyDownOnEntity}
                      />
                      {errors.entity && touched.entity && <div className="error">{errors.entity}</div>}
                      {errors.entities && touched.entities && <div className="error">{errors.entities}</div>}
                      <div className="mt-1">
                        {values.entities.map((code, i) => (
                          <Tag color={"#108ee9"} key={code} closable onClose={() => arrayHelpers.remove(i)}>
                            {code}
                          </Tag>
                        ))}
                      </div>
                    </>
                  );
                }}
              />
            </div>
            <div className="row" styles={{ marginTop: "10px", marginBottom: "10px" }}>
              <div className="col-6">
                {language === "ger" ? (
                  <ConfigProvider locale={gr}>
                    <DatePicker
                      style={{ width: "100%" }}
                      disabledDate={(current) => restrictStartDates(current, values.time_scope_to)}
                      placeholder={`${polyglot.t("From date")} *`}
                      value={getMomentDate(values.time_scope_from)}
                      defaultPickerValue={moment}
                      onChange={(val) => fieldChange("time_scope_from", val, handleChange)}
                      format={"DD.MM.YYYY"}
                      className="antd-datepicker-custom-styles"
                      suffixIcon={<img src={calendar} width={14} height={14} />}
                    />
                  </ConfigProvider>
                ) : (
                  <DatePicker
                    style={{ width: "100%" }}
                    disabledDate={(current) => restrictStartDates(current, values.time_scope_to)}
                    placeholder={`${polyglot.t("From date")} *`}
                    value={getMomentDate(values.time_scope_from)}
                    defaultPickerValue={moment}
                    onChange={(val) => fieldChange("time_scope_from", val, handleChange)}
                    format={"DD.MM.YYYY"}
                    className="antd-datepicker-custom-styles"
                    suffixIcon={<img src={calendar} width={14} height={14} />}
                  />
                )}
                {errors.time_scope_from && touched.time_scope_from ? (
                  <div className="error">{errors.time_scope_from}</div>
                ) : null}
              </div>
              <div className="col-6">
                {language === "ger" ? (
                  <ConfigProvider locale={gr}>
                    <DatePicker
                      style={{ width: "100%" }}
                      disabledDate={(current) => restrictEndDates(current, values.time_scope_from)}
                      placeholder={`${polyglot.t("To date")} *`}
                      value={getMomentDate(values.time_scope_to)}
                      defaultPickerValue={moment}
                      onChange={(val) => fieldChange("time_scope_to", val, handleChange)}
                      format={"DD.MM.YYYY"}
                      className="antd-datepicker-custom-styles"
                      suffixIcon={<img src={calendar} width={14} height={14} />}
                    />
                  </ConfigProvider>
                ) : (
                  <DatePicker
                    style={{ width: "100%" }}
                    disabledDate={(current) => restrictEndDates(current, values.time_scope_from)}
                    placeholder={`${polyglot.t("To date")} *`}
                    value={getMomentDate(values.time_scope_to)}
                    defaultPickerValue={moment}
                    onChange={(val) => fieldChange("time_scope_to", val, handleChange)}
                    format={"DD.MM.YYYY"}
                    className="antd-datepicker-custom-styles"
                    suffixIcon={<img src={calendar} width={14} height={14} />}
                  />
                )}
                {errors.time_scope_to && touched.time_scope_to ? (
                  <div className="error">{errors.time_scope_to}</div>
                ) : null}
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-md-6 text-align-left">
                <ButtonPrimary onClick={handleSubmit} ariaLabel={polyglot.t("Submit request")}>
                  {polyglot.t("Submit request")}
                </ButtonPrimary>
              </div>
            </div>
          </>
        );
      }}
    </Formik>
  );
}

export default RequestFormFields;
