import React, { useContext } from "react";

import CreateProject from "./CreateProject";
import NoAccess from "../../../Assets/Components/NoAccess";
import { hasCreatePermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";

// RENDERS ON /queenbase/projects/createproject
const ProjectFormContainer = () => {
  // data from context
  const { getCurrentUserPermissions } = useContext(DataContext);
  const currentUserPermissions = getCurrentUserPermissions();
  return (
    <>
      {/* RENDER COMPONENT IF USER HAS PERMISSION TO CREATE */}
      {hasCreatePermission(currentUserPermissions) && <CreateProject />}
      {/* RENDER 'NO ACCESS' IF USER DOES NOT HAVE ACCESS TO CREATE */}
      {!hasCreatePermission(currentUserPermissions) && <NoAccess />}
    </>
  );
};

export default ProjectFormContainer;
