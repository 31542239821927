import axios from "axios";

import { getUserIDFromSession } from "../Shared/Authenticate";
import ErrorHandler from "../Shared/ErrorHandler";
axios.defaults.baseURL = process.env.REACT_APP_SERVER_HOST;
axios.defaults.headers.post["Content-Type"] = "application/json";
//get the review counts
export function getTechnicalTemplate(path, ProjectName, ANALYSIS_NAME, REVIEW_NAME) {
  return axios(`/getTechnicalTemplate/${path}/${ProjectName}/${ANALYSIS_NAME}/${REVIEW_NAME}`, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}

export function getTechnicalReport(project_id) {
  return axios(`/getTechnicalReport/${project_id}`, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}
// get the production files count
export function getProductionCountInfo(project_id) {
  return axios(`/getProductionCount/${project_id}`, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}

export function downloadMultipleFileService(updateSelectedDataWithPath, selectFolder) {
  return axios("/downloadMultipleZip", {
    method: "POST",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
    responseType: "arraybuffer",
    data: { updateSelectedDataWithPath: updateSelectedDataWithPath, downloadType: selectFolder },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}
export function downloadSingleFileService(pathList, selectFolder) {
  return axios("/downloadSingleZip", {
    method: "POST",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
    responseType: "arraybuffer",
    data: { downloadPath: pathList, selectFolder: selectFolder },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}
//get the review counts
export function getReviewInfo(project_id) {
  return axios(`/getReviewInfo/${project_id}`, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return Promise.reject(ErrorHandler.get().customErrorHandler(error.response));
    });
}

// get the custodian count
export function getCustodianCount(project_id, ANALYSIS_ID) {
  return axios(`/getCustodianCount/${project_id}/${ANALYSIS_ID}`, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}

export function getProductionRequestData(projectId) {
  return axios
    .get(`/getProductionRequestData/${projectId}`, {
      mode: "no-cors",
      headers: {
        "Access-Control-Allow-Origin": "*",
        "Content-Type": "application/json",
        Authorization: "Bearer " + sessionStorage.getItem("jwt"),
        loggedInUserId: getUserIDFromSession(),
      },
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}

// get the custodian names
export function getCustodianNamesInfo(project_id, ANALYSIS_ID) {
  return axios(`/getCustodianNames/${project_id}/${ANALYSIS_ID}`, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}
//get the review barchart data
export function getReviewBarchart(project_id) {
  return axios(`/getReviewBarchartData/${project_id}`, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}
// get the review gb data
export function getreviewGbInfo(project_id, ANALYSIS_ID) {
  return axios(`/getreviewGbData/${project_id}/${ANALYSIS_ID}`, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}

// get the review custodian filter
export function getreviewCustodianFilterData(project_id, CUSTODIAN_ID, ANALYSIS_ID) {
  return axios(`/getreviewCustodianFilter/${project_id}/${CUSTODIAN_ID}/${ANALYSIS_ID}`, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}
// get the analysis names
export function getAnalysisNamesInfo(project_id) {
  return axios(`/getAnalysisNames/${project_id}`, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}
// get the Analysis Names,Document count,review hours
export function getReviewDocumentCountInfo(project_id, ANALYSIS_ID) {
  return axios(`/getReviewDocumentCount/${project_id}/${ANALYSIS_ID}`, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}
// get the review file names
export function getReviewFileNamesInfo() {
  return axios("/getReviewFileNames", {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}

// get the review tag names
export function getReviewTagsInfo() {
  return axios("/getReviewTags", {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}
// get the review dataset names by projectid and custodianid
export function getReviewDatasetNamesInfo(project_id, custodian_id) {
  return axios(`/getReviewDatasetNames/${project_id}/${custodian_id}`, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}

// get the review dataset names by projectid
export function getReview_DatasetNames_Info(project_id) {
  return axios(`/getReview_DatasetNames/${project_id}`, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}
// get the review file types
export function get_review_production_types() {
  return axios("/get_reviewproduction_types", {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}
// get the review analysis names by projectid and custodianid  and datasetid
export function getReviewAnalysisNamesInfo(project_id, custodian_id, dataset_id) {
  return axios(`/getReviewAnalysisNames/${project_id}/${custodian_id}/${dataset_id}`, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}
// post the production criterai details
export function postProduction(data) {
  return axios("/post_Criteria_ProductionData", {
    method: "POST",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
    data: data,
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      // return { false: false, msg: error };
      return Promise.reject(ErrorHandler.get().customErrorHandler(error.response));
    });
}
//post the upload file and production doc list data
export function postDocProduction(formData) {
  return new Promise((resolve, reject) => {
    axios
      .post("/post_Doclist_ProductionData", formData, {
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("jwt"),
          loggedInUserId: getUserIDFromSession(),
          "Content-Type": "multipart/form-data",
        },
      })
      .then((reply) => {
        resolve(reply);
      })
      .catch((error) => {
        return Promise.reject(ErrorHandler.get().customErrorHandler(error.response));
      });
  });
}
// get the review production results
export function ReviewProdctionFinalResult(req_id, project_id) {
  return axios(`/getReviewProductionFinalResult/${req_id}/${project_id}`, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}
// get the review production results by project id
export function ReviewProductionFinalResultById(project_id) {
  return axios(`/getReviewProductionFinalResultById/${project_id}`, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return Promise.reject(ErrorHandler.get().customErrorHandler(error.response));
    });
}
// get the review reviewselection names by projectid
export function getReviewSelectionDataInfo(AnalysisID, runthroughID, project_id, CUSTODIAN_ID) {
  return axios(`/getReviewSelectionDataById/${AnalysisID}/${runthroughID}/${project_id}/${CUSTODIAN_ID}`, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}
// post the review selection data details
export function postReviewSelectionData(data) {
  return axios("/post_ReviewSelection_Data", {
    method: "POST",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
    data: data,
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}

// get the review solution count
export function getReviewCount(project_id) {
  return axios(`/getReviewCount/${project_id}`, {
    method: "GET",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}

// GET PRODUCTION DETAILS
export function getProductionDetailsById(data) {
  return axios("/getProductionDetailsById", {
    method: "POST",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
    data: data,
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}

// DOWNLOAD PRODUCTION DETAILS
export function downloadProductionFile(data) {
  return axios(`/downloadProductionFile`, {
    method: "POST",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
    data: data,
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}

// get the review details
export function getReviewDetailsById(data) {
  return axios("/getReviewDetailsById", {
    method: "POST",
    mode: "no-cors",
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      loggedInUserId: getUserIDFromSession(),
    },
    data: data,
  })
    .then((_res) => {
      return _res;
    })
    .catch((error) => {
      return { false: false, msg: error };
    });
}
