import axios from "axios";

import HttpClient from "../Shared/HttpClient";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_HOST;
// axios.defaults.headers.common['Authorization'] = "JWT_TOKEN";  // Yet to complete
axios.defaults.headers.post["Content-Type"] = "application/json";

export async function getProjectInfo() {
  const client = new HttpClient();
  const res = await client.dfedGet("/getProjectsInfo");
  return res;
}
