import displayToastMessage from "../../QueenBase/Root/displayToastMessage";

// const validTokensForKeyword = [['{', '}'], ['[', ']'], ['(', ')']]
// //WRITTEN BY SHEKHAR  VALIDATE INPUT VALUE IN KEYWORD WITH ALL POSSIBLE TOKENS=====
// export const validateInputKeyword1 = (inputStr) => {

//     if (inputStr === null) {
//         return true
//     }
//     var expression = inputStr.split('')
//     var stack = []
//     for (var i = 0; i < expression.length; i++) {
//         if (checkIsParanthesisExist(expression[i])) {
//             if (checkIsOpenParanthesisExist(expression[i])) {
//                 stack.push(expression[i])
//             } else {
//                 if (stack.length === 0) {
//                     return false
//                 }
//                 var top = stack.pop();
//                 console.log('top---', top)
//                 if (!isTokenMatchedWithInputString(top, expression[i])) {
//                     return false

//                 }
//             }
//         }
//     }
//     var returnValue = stack.length === 0 ? true : false
//     if (returnValue) {
//         return true
//     }
//     else {
//         return false
//     }
// }
// // CHECKING IS PARANTHESIS EXIST OR NOT
// const checkIsParanthesisExist = (char) => {
//     var str = '{}[]()'
//     if (str.indexOf(char) > -1) {
//         return true;
//     } else {
//         return false
//     }
// }
// // =====CODE FOR OPEN PARANTHESIS CHECK FROM VALID TOKEN DEFINE IN STATE=====
// const checkIsOpenParanthesisExist = (paranthesisChar) => {
//     for (var j = 0; j < validTokensForKeyword.length; j++) {
//         if (validTokensForKeyword[j][0] === paranthesisChar) {
//             return true
//         }
//     }
//     return false
// }

// const isTokenMatchedWithInputString = (topOfStack, closedParanthesis) => {
//     for (var k = 0; k < validTokensForKeyword.length; k++) {
//         if (validTokensForKeyword[k][0] === topOfStack && validTokensForKeyword[k][1] === closedParanthesis) {
//             return true
//         }
//     }
//     return false
// }

//---------------------------------------------------------

export const validateInputKeyword = (str, errorMsg) => {
  try {
    if (str === undefined || (str !== undefined && str.length === 0)) return true;

    if (errorMsg.length > 0) errorMsg.pop();

    var s = str.trim();
    if (s.length === 0) {
      errorMsg.push("Text cannot contain whitespaces");
      return false;
    }

    var stack = checkForValidChars(s, errorMsg);

    if (isStackEmpty(stack)) {
      //"abc OR xyz"
      var sArray = [];
      s = s.toLowerCase();
      if (s.indexOf("and") !== -1) {
        sArray = s.split("and");
      } else if (s.indexOf("or") !== -1) {
        sArray = s.split("or");
      } else if (s.indexOf("not") !== -1) {
        sArray = s.split("not");
      } else {
        return true;
      }

      //"abc or xyz"
      var isBracketsHandled = false;
      for (var i = 0; i < sArray.length; i++) {
        if (sArray[i].length === 0) return false;
        sArray[i] = sArray[i].trim();
        var currStack = checkForValidChars(sArray[i], errorMsg);
        if (!isStackEmpty(currStack)) {
          if (!isBracketsHandled && isFirstLastCharBracket(s)) {
            isBracketsHandled = true;
            s = s.substring(1, s.length - 1);
            var tempStack = checkForValidChars(s, errorMsg);
            if (!isStackEmpty(tempStack)) return false;
          } else {
            return false;
          }
        }
      }
      return true;
    }

    errorMsg.push("Missing closing parantheses/quotes : " + stack);
    return isStackEmpty(stack);
  } catch (err) {
    displayToastMessage(err.message, "error");
  }
};
function isStackEmpty(stack) {
  return stack.length === 0;
}

function peek(stack) {
  return stack[stack.length - 1];
}

function isFirstLastCharBracket(str) {
  return (
    (str.charAt(0) === "(" || str.charAt(0) === "{" || str.charAt(0) === "[") &&
    (str.charAt(str.length - 1) === ")" ||
      str.charAt(str.length - 1) === "}" ||
      str.charAt(str.length - 1) === "]")
  );
}

function checkForValidChars(s, errorMsg) {
  var pairs = {
    "}": "{",
    "]": "[",
    ")": "(",
  };

  var stack = [];
  for (var i = 0; i < s.length; ++i) {
    switch (s.charAt(i)) {
      case "&":
      case "||":
      case "@":
        errorMsg.push("Invalid characters found : " + s.charAt(i));
        return false;
      case "[":
      case "{":
      case "(":
        stack.push(s.charAt(i));
        break;
      case "]":
      case "}":
      case ")":
        if (isStackEmpty(stack) || peek(stack) !== pairs[s.charAt(i)]) {
          errorMsg.push("Missing opening parantheses : " + s.charAt(i));
          return false;
        }
        stack.pop();
        break;
      case '"':
      case "'":
        if (isStackEmpty(stack) || peek(stack) !== s.charAt(i)) {
          stack.push(s.charAt(i));
        } else {
          stack.pop();
        }
    }
  }
  return stack;
}
// text field validation for keyword
export const checkForValidCharactersInKeywordText = (text) => {
  try {
    const returnBody = {
      isValid: true,
      message: "",
    };
    const notValidElements = ["!", "@", "#", "$", "%", "^", "&", "=", ":", "<", ">", "/"];
    const splittedName = text.split("");
    splittedName.forEach((t) => {
      if (notValidElements.indexOf(t) >= 0) {
        // the string is not valid
        returnBody.isValid = false;
        returnBody.message = "Please enter a valid string";
      }
    });
    return returnBody;
  } catch (err) {
    return {
      isValid: false,
      message: err.message,
    };
  }
};

const keywordOperators = ["AND", "OR", "NOT", "+", "-", "W/s", "W/p"];
const keywordRegexOperators = [/W\/\d+/, /PRE\/\d+/];
/**
 *
 * @param {string} keyword
 * @returns {boolean} true if keyword is valid
 */
export const validateKeyword = (keyword) => {
  let bracketsBalance = 0;
  let inQuotes = false;
  let previousWasOperator = false;
  let previousWasKeyword = false;

  for (let charIndex = 0; charIndex < keyword.length; charIndex++) {
    const char = keyword[charIndex];
    const nextChar = keyword[charIndex + 1];
    if (char === '"') {
      inQuotes = !inQuotes;
      previousWasOperator = false;
      previousWasKeyword = !inQuotes;
      if (previousWasKeyword && nextChar !== undefined && ![")", " "].includes(nextChar)) {
        return false;
      }
    }

    if (inQuotes) {
      continue;
    }

    if (char === "(") {
      if (nextChar !== undefined && !['"', "("].includes(nextChar)) {
        return false;
      }
      bracketsBalance++;
    } else if (char === ")") {
      if (nextChar !== undefined && ![")", " "].includes(nextChar)) {
        return false;
      }
      bracketsBalance--;
      if (bracketsBalance < 0) {
        return false;
      }
    }

    if (char === " ") {
      if (nextChar === " ") {
        return false;
      }
      const nextSpaceIndex = keyword.indexOf(" ", charIndex + 1);
      if (nextSpaceIndex === -1) {
        return false;
      }
      const operator = keyword.slice(charIndex + 1, nextSpaceIndex);
      const isValidOperator =
        keywordOperators.includes(operator) || keywordRegexOperators.some((regex) => regex.test(operator));

      if (!isValidOperator || !previousWasKeyword || previousWasOperator) {
        return false;
      }
      previousWasOperator = true;
      previousWasKeyword = false;
      charIndex = nextSpaceIndex;
    }
  }

  return bracketsBalance === 0 && !inQuotes && previousWasKeyword;
};
