import axios from "axios";
import HttpClient from "../Shared/HttpClient";
axios.defaults.baseURL = process.env.REACT_APP_SERVER_HOST;
axios.defaults.headers.post["Content-Type"] = "application/json";

export default class ProcessingService {
  getProfile = async (projectId) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getProcessingProfiles/${projectId}`);
    return res;
  };

  saveProcessing = async (processingData) => {
    const client = new HttpClient();
    const res = await client.dfedPost("/saveProcessing", {
      body: {
        processingData: processingData,
      },
    });
    return res;
  };

  getProcessingCustodianRecords = async (projectId) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/ProcessingInfo/${projectId}`);
    return res;
  };

  getAllProcessingCustodian = async (projectId) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getAllCustodianProcessingInfo/${projectId}`);
    return res;
  };

  getProcessingStatusCount = async (project_id) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getProcessingCount/${project_id}`);
    return res;
  };
}
