import PropTypes from "prop-types";
import React from "react";
import { Redirect, Route } from "react-router-dom";

import { check_If_User_LoggedIn } from "../Services/Shared/Authenticate";

// THIS COMPONENT CHECKS IF USER IS LOGGEDIN OR NOT BEFORE DISPLAYING THE DESIRED SCREEN AND THEN RETURNS THE APPROPRIATE SCREEN
function ProtectedRoute({ component: Component, ...rest }) {
  return (
    <Route
      {...rest}
      render={(props) => {
        if (check_If_User_LoggedIn()) {
          return <Component {...props} />;
        } else {
          return (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          );
        }
      }}
    />
  );
}
ProtectedRoute.propTypes = {
  component: PropTypes.func,
  location: PropTypes.object,
};

export default ProtectedRoute;
