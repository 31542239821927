import React, { Component } from "react";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/QueenBase/Root/loading-spin.svg";
import $ from "jquery";
import { Select, SelectOption, Button, Input, TextArea } from "appkit-react";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "antd/dist/antd.css";
import ProjectSurveyService from "../../../Services/DF/ProjectSurveyService";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import AdminService from "../../../Services/DF/AdminService";
import { get_QlikView_Dashboard_URL_With_documentID, getFileNameFromPath } from "../../DF/handlers";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import CustomModal from "../../../Assets/Components/Modal";
import LinkText from "../../../Assets/Components/LinkText/LinkText";
import { upperFirst } from "lodash";
import CompanyCenterService from "../../../Services/QueenBase/CompanyCenter.js/CompanyCenterService";
import {
  getProjectID_Of_solution_module,
  getDisplayDate,
  capitaliseFirstLetter,
} from "../../../Services/Shared/Handlers";
import moment from "moment";
import "../../../Assets/Css/DFED/Admin.css";

import { customNumberFormat } from "../../../utility/CustomNumberFormat";
import GetPriceInGermanFormat from "../Root/GetPriceInGermanFormat";
$.DataTable = require("datatables.net-dt");
$.fn.DataTable.ext.errMode = "none";
require("datatables.net-buttons-dt");
const jzip = require("jszip");
require("datatables.net-buttons/js/buttons.html5.min");
window.JSZip = jzip;
require("../../../Services/Shared/Date-de");

// PROCESS TAB OF ADMIN SCREEN OF DF MODULE
class InvoiceGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      targetList: [],
      showEditModal: false,
      showViewModal: false,
      selectedRowBody: {},
    };
  }
  static contextType = DataContext;
  polyglot = polyglotLoader();
  // RUNS ON INITIAL LOAD
  componentDidMount() {
    this.setState(
      {
        targetList: this.props.projectsList,
      },
      () => {
        this.dataStructureForProcessGrid(this);
      },
    );
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.projectsList !== this.state.targetList) {
      this.setState(
        {
          targetList: this.props.projectsList,
        },
        () => {
          this.dataStructureForProcessGrid(this);
        },
      );
    }
  }

  downloadInvoice = async (r) => {
    try {
      const downloadResponse = await CompanyCenterService.get().downloadInvoice(r); //invoice_attachment
      const buffer = Buffer.from(downloadResponse.file.buffer);
      const url = window.URL.createObjectURL(new Blob([buffer]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", downloadResponse.file.fileName);
      document.body.appendChild(link);
      link.click();
      displayToastMessage(this.polyglot.t("Invoice has been downloded"), "success");
    } catch (error) {
      if (
        (error.message = "First argument must be a string, Buffer, ArrayBuffer, Array, or array-like object.")
      ) {
        displayToastMessage(`${this.polyglot.t("File not found")}!!`, "error");
      } else {
        displayToastMessage(error.message, "error");
      }
    }
    return "";
  };

  // CREATE DATA STRUCTURE FOR UPLOAD TABLE
  dataStructureForProcessGrid = () => {
    var tempData = this.state.targetList;
    var dataForTbl = [];
    tempData.forEach((ele1) => {
      const amount = GetPriceInGermanFormat(customNumberFormat(ele1.invoice_amount));
      // console.log(ele1.created_at);
      dataForTbl.push([
        ele1.client_invoice_ref,
        ele1.Project,

        capitaliseFirstLetter(ele1.invoice_description),
        amount,
        getDisplayDate(ele1.created_at),
        getDisplayDate(ele1.due_date),
        // moment(ele1.created_at).format("DD.MM.YYYY"),
        // moment(ele1.due_date).format("DD.MM.YYYY"),
        this.polyglot.t(ele1.Status),
        ele1.time_range,
        ele1.invoice_attachment,
      ]);
    });
    this.drawProcessGrid(this, dataForTbl);
  };
  // DRAW PROCESS GRID
  drawProcessGrid = (temp1, dataForTbl) => {
    temp1.$el = $(temp1.el);
    var disText = "";
    temp1.$el.DataTable({
      lengthMenu: [5, 10, 15, 20],
      sDom: "lfrtp",
      oLanguage: {
        emptyTable: `${this.polyglot.t("No records found")}`,
        sLengthMenu: `${this.polyglot.t("Show")} <select class="tabFocus">
          <option value="5">5</option>
          <option value="10">10</option>
          <option value="15">15</option>
          <option value="20">20</option>
          <option value="-1">All</option>
          </select> ${this.polyglot.t("entries")}
          `,
        sSearch: `${this.polyglot.t("Search")} `,
      },
      responsive: true,
      columns: [
        { title: this.polyglot.t("Invoice ID") },
        { title: this.polyglot.t("Project"), class: "tdmaxwidth" },
        {
          title: this.polyglot.t("Descriptions"),
          mRender: (data, type, row) => {
            var data1 =
              "<Tooltip title ='" +
              row[2] +
              "'  placement='top'><div class='project-title-overflow' style='width:70px'>" +
              row[2] +
              " </div></Tooltip>";
            return data1;
          },
        },
        {
          title: this.polyglot.t("Amount"),
        },
        { title: this.polyglot.t("Invoice Date"), type: "date-de" },
        { title: this.polyglot.t("Due date"), type: "date-de" },
        { title: this.polyglot.t("Status") },
        {
          title: this.polyglot.t("Time Range"),
          mRender: (data, type, row) => {
            var data1 =
              "<Tooltip title ='" +
              row[7] +
              "'  placement='top'><div class='project-title-overflow' style='width:70px'>" +
              row[7] +
              " </div></Tooltip>";
            return data1;
          },
        },
        {
          title: this.polyglot.t("Action"),
          class: "actionSortingRemove",
          mRender: (data, type, row) => {
            return (
              '<button aria-label="' +
              this.polyglot.t("edit") +
              '" class="appkiticon icon-download-light-outline analysisGridEditIcon" style="width:30px" id="procesdata`@*' +
              row[8] +
              '">  </button>'
            );
          },
        },
      ],
      data: dataForTbl,
      lenghtType: "simple",
      searching: true,
      destroy: true,
      language: {
        info: "_PAGE_ of _PAGES_",
        paginate: {
          firt: "<<",
          previous: '<i_ngcontent-c10="" class="appkiticon icon-left-chevron-fill"> </i>',
          next: '<i_ngcontent-c10="" class="appkiticon icon-right-chevron-fill"> </i>',
          last: ">>",
        },
      },
      stateSave: true,
      drawCallback: function () {
        if (dataForTbl.length <= 0) {
          $(".dataTables_paginate").hide();
        } else {
          $(".dataTables_paginate").show();
        }
      }, // OVER : drawback function
    });
    var tempReactThis = this;
    $(document).on("click", "[id*='procesdata`@*']", function (e) {
      let idData = this.id.split("@*");
      e.stopImmediatePropagation();
      tempReactThis.downloadInvoice(idData[1]);
    });

    disText = $("#analysisGridTable").text();
    var pagNum = "";
    pagNum = disText.replace(pagNum, "<span>" + pagNum + "</span>");
    disText = disText.replace(pagNum, "<span>" + pagNum + "</span>");
  };

  render() {
    return (
      <div className="container row analysisGridContainer p-0">
        <div className="row analysisGridRow">
          <div className="col-md-12 DFED-Review-admin mr-3">
            <table
              id="analysisGridTable"
              className="display table-header-focus processGrid"
              width="100%"
              ref={(el) => (this.el = el)}
            />
          </div>
        </div>
      </div>
    );
  }
}
export default InvoiceGrid;
