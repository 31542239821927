import React from "react";
import Loadable from "react-loadable";
import LoadingIndicator from "../Components/QueenBase/Root/LoadingIndicator";
import ProtectedRoute from "./ProtectedRoute";
import { Switch } from "react-router-dom";
import StartContextProvider from "../Components/DF/Start/Contexts/StartContext";
import UploadHome from "../Components/DF/Upload/Home";
import FormContainer from "../Components/DF/Upload/FormContainer";
import DashboardHome from "../Components/DF/Dashboard/Home";
import HomeContainer from "../Components/DF/HomeContainer";
import AdminContainer from "../Components/DF/Admin/AdminContainer";
import UploadContainer from "../Components/DF/Upload/UploadContainer";
import DashboardContainer from "../Components/DF/Dashboard/DashboardContainer";
import StartWorkflow from "../Components/DF/Start";
import { DF_HOME, DF_UPLOAD, DF_DASHBOARD, DF_ADMIN, DF_START, DF_SUPPORT } from "../Constants/Path";

// ROUTES OF DF MODULE
function DFModuleRoutes() {
  return (
    <StartContextProvider>
      <Switch>
        <ProtectedRoute path={DF_HOME} component={HomeContainer} />
        <ProtectedRoute path={DF_UPLOAD} component={UploadContainer} />
        <ProtectedRoute path={DF_DASHBOARD} component={DashboardContainer} />
        <ProtectedRoute path={DF_ADMIN} component={AdminContainer} />
        <ProtectedRoute path={DF_START} component={StartWorkflow} />
        <ProtectedRoute
          path={DF_SUPPORT}
          component={Loadable({
            loader: () => import("../Components/DFED/Support/Support"),
            loading: LoadingIndicator,
          })}
        />
      </Switch>
    </StartContextProvider>
  );
}

export default DFModuleRoutes;

// ROUTES OF DF UPLOAD SCREEN
export const UploadRoutes = () => {
  return (
    <Switch>
      <ProtectedRoute path={"/df/upload/view"} component={UploadHome} />
      <ProtectedRoute path={"/df/upload/form"} component={FormContainer} />
    </Switch>
  );
};

// ROUTES OF DF DASHBOARD SCREEN
export const DashboardRoutes = () => {
  return (
    <Switch>
      <ProtectedRoute path={"/df/dashboard/view"} component={DashboardHome} />
    </Switch>
  );
};
