import { Progress } from "antd";
import { Panel, Divider, Tooltip } from "appkit-react";
import React from "react";

import { getDisplayDate } from "../../../../Services/Shared/Handlers";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import { customNumberFormat } from "../../../../utility/CustomNumberFormat";
import GetPriceInGermanFormat from "../../Root/GetPriceInGermanFormat";
import ProgressChart from "../ProgressChart";
import ApexChart from "../RequestCharts";

// BOX DATA COMPONENT
export default class BoxData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
    };
  }
  // RENDERS HTML CONTENT OF THIS COMPONENT
  render() {
    var polyglot = polyglotLoader();
    if (this.props.dashboard && this.props.dashboard == "true") {
    }
    // PERCENTAGE USED
    const PercentageUsed = (VolumeUsed, TotalVolume) => {
      if (VolumeUsed && TotalVolume) {
        let PercentageUsed = (VolumeUsed * 100) / TotalVolume;
        return PercentageUsed;
      }
      return 0;
    };

    // VIEW PROJECT NAME ON HOVER
    const DisplayTooltipText = (text) => {
      return (
        <div>
          <table>
            <tbody>
              <tr>
                <td
                  style={{
                    textAlign: "center",
                    fontWeight: "bold",
                    color: "#000",
                    textTransform: "capitalize",
                  }}
                >
                  {text}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      );
    };

    return (
      <Panel className="d-panel">
        <div className="row">
          <p className="d-title">{polyglot.t(this.props.label)}</p>
        </div>
        <Divider />
        <div className="row">
          <div className="col-md-12">
            {(this.props.divId == "regularusers" ||
              this.props.divId == "dataupload" ||
              this.props.divId == "temporaryusers" ||
              this.props.divId == "custodian" ||
              this.props.divId == "documents") &&
              this.props.dataprops !== undefined && (
                <div className="row">
                  <h2 className="d-custodian-value widget-h2">
                    {GetPriceInGermanFormat(customNumberFormat(this.props.dataprops), true) == "NaN"
                      ? 0
                      : GetPriceInGermanFormat(customNumberFormat(this.props.dataprops), true)}
                  </h2>
                </div>
              )}
            {this.props.divId == "expiringusers" && this.props.dataprops && (
              <span>
                {this.props.dataprops.userData[1].length > 0 && (
                  <table className="table boxtable">
                    <thead>
                      <tr>
                        <th className="Queen-Mile boxth">
                          <b className="milestonesTR">{polyglot.t("Name")}</b>
                        </th>
                        <th className="Queen-Mile boxth">
                          <b className="milestonesTR">{polyglot.t("Expiry Date")}</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.dataprops.userData[1].map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="boxtd">
                              {item.username.length > 10 ? (
                                <Tooltip content={DisplayTooltipText(item.username)} placement="top">
                                  {item.username.substring(0, 11) + "..."}
                                </Tooltip>
                              ) : (
                                item.username
                              )}
                            </td>
                            {/*  <td className="boxtd">{(item.username.length > 15) ? item.username.substring(0, 16) + '...' : item.username}</td> */}
                            <td className="boxtd">{getDisplayDate(item.expirydate)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}

                {this.props.dataprops.userData[1] == 0 && (
                  <div className="nowidgetdata">{polyglot.t("No Expiring User Accounts")}</div>
                )}
              </span>
            )}
            {this.props.divId == "projectmilestones" && this.props.dataprops && (
              <span>
                <div className="Box-Circle">
                  <div className="Box-Data-Circle">
                    {this.props.dataprops.userData.map((item, index) => {
                      return (
                        <div key={index}>
                          <div className="BoxCircles">
                            <Progress
                              type="circle"
                              percent={100}
                              width={40}
                              format={(percent) => `${item.totalprojects}`}
                              strokeColor={"#333131"}
                            />
                            <Tooltip content={DisplayTooltipText(polyglot.t("Total"))} placement="top">
                              <p className="projects-data-txt">{polyglot.t("Total")}</p>
                            </Tooltip>
                          </div>
                          <div className="BoxCircles">
                            <Progress
                              type="circle"
                              percent={100}
                              width={40}
                              format={(percent) => `${item.completeprojects}`}
                              strokeColor={"#7CFC00"}
                            />
                            <Tooltip content={DisplayTooltipText(polyglot.t("Complete"))} placement="top">
                              <p className="projects-data-txt">{polyglot.t("Complete")}</p>
                            </Tooltip>
                          </div>
                          <div className="BoxCircles">
                            <Progress
                              type="circle"
                              percent={100}
                              width={40}
                              format={(percent) => `${item.incompleteprojects}`}
                              strokeColor={"#ff7f00"}
                            />
                            <Tooltip content={DisplayTooltipText(polyglot.t("Incomplete"))} placement="top">
                              <p className="projects-data-txt">{polyglot.t("Incomplete")}</p>
                            </Tooltip>
                          </div>
                          <div className="BoxCircles">
                            <Progress
                              type="circle"
                              percent={100}
                              width={40}
                              format={(percent) => `${item.overdueprojects}`}
                              strokeColor={"#fe2712"}
                            />
                            <Tooltip content={DisplayTooltipText(polyglot.t("Overdue"))} placement="top">
                              <p className="projects-data-txt">{polyglot.t("Overdue")}</p>
                            </Tooltip>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </span>
            )}

            {this.props.divId == "projects" && this.props.dataprops && (
              <div className="Box-Progress">
                <div className="Box-Data-Progress">
                  {this.props.dataprops.userData.map((item, index) => {
                    return (
                      <div key={index}>
                        <div className="">
                          <Progress
                            percent={(item.ActiveProjects / item.TotalProjects) * 100}
                            width={40}
                            format={(percent) => `${item.ActiveProjects}`}
                            strokeColor={"#7CFC00"}
                          />
                          <p className="projects-data-txt">{polyglot.t("Active")}</p>
                        </div>
                        <div className="">
                          <Progress
                            percent={(item.InActiveProjects / item.TotalProjects) * 100}
                            width={40}
                            format={(percent) => `${item.InActiveProjects}`}
                            strokeColor={"#808080"}
                          />
                          <p className="projects-data-txt">{polyglot.t("Inactive")}</p>
                        </div>
                        <div className="">
                          <Progress
                            percent={(item.TotalProjects / item.TotalProjects) * 100}
                            width={40}
                            format={(percent) => `${item.TotalProjects}`}
                            strokeColor={"#333131"}
                          />
                          <p className="projects-data-txt">{polyglot.t("Total")}</p>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}

            {this.props.divId == "packageconsumption" && this.props.dataprops && (
              <>
                {this.props.dataprops.userData && this.props.dataprops.userData.length > 0 && (
                  <div className="Box-Progress packageconsumption-progress">
                    <div className="Box-Data-Progress">
                      {this.props.dataprops.userData.map((item, index) => {
                        var packagevolume = JSON.parse(item.packageVolume);
                        var engpercent = PercentageUsed(
                          packagevolume[0].VolumeUsed ? packagevolume[0].VolumeUsed : 0,
                          packagevolume[0].TotalVolume ? packagevolume[0].TotalVolume : 0,
                        );
                        var per = PercentageUsed(
                          packagevolume[0].VolumeUsed ? packagevolume[0].VolumeUsed : 0,
                          packagevolume[0].TotalVolume ? packagevolume[0].TotalVolume : 0,
                        );
                        var gerpercent = per.toString().replace(".", ",");
                        return (
                          item.solutionid != 4 &&
                          item.solutionid != 3 &&
                          item.solutionid != 2 &&
                          packagevolume[0].TotalVolume && (
                            <div key={index}>
                              <div className="">
                                <Progress
                                  percent={PercentageUsed(
                                    packagevolume[0].VolumeUsed ? packagevolume[0].VolumeUsed : 0,
                                    packagevolume[0].TotalVolume ? packagevolume[0].TotalVolume : 0,
                                  )}
                                  format={(percent) =>
                                    `${this.props.language == "en" ? engpercent : gerpercent}%`
                                  }
                                  width={40}
                                  strokeColor={"#808080"}
                                />
                                <div className="package-percentage">
                                  <p className="projects-data-txt percentage1">
                                    {polyglot.t(item.solutionName)}
                                  </p>
                                  <p className="projects-data-txt percentage2">
                                    {(packagevolume[0].VolumeUsed
                                      ? GetPriceInGermanFormat(
                                          customNumberFormat(packagevolume[0].VolumeUsed),
                                          true,
                                        )
                                      : 0) +
                                      " GB / " +
                                      (packagevolume[0].TotalVolume
                                        ? GetPriceInGermanFormat(
                                            customNumberFormat(packagevolume[0].TotalVolume),
                                            true,
                                          )
                                        : 0) +
                                      " GB"}
                                  </p>
                                </div>
                              </div>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                )}
                {(!this.props.dataprops.userData || this.props.dataprops.userData.length == 0) && (
                  <div className="nowidgetdata">{polyglot.t("No Data")}</div>
                )}
              </>
            )}

            {this.props.divId == "processdiagram" && this.props.dataprops && (
              <span>
                <div className="Box-Progress">
                  <div className="Box-Data-Progress">
                    <div>
                      <div className="">
                        <Progress
                          percent={Math.ceil(
                            (this.props.dataprops.userData.ToBeReviwedDataSets /
                              this.props.dataprops.userData.TOTAL) *
                              100,
                          )}
                          width={40}
                          strokeColor={"#175c2c"}
                        />
                        <p className="projects-data-txt">{polyglot.t("Analysed")}</p>
                      </div>
                      <div className="">
                        <Progress
                          percent={Math.ceil(
                            (this.props.dataprops.userData.AnalyzedDataSets /
                              this.props.dataprops.userData.TOTAL) *
                              100,
                          )}
                          width={40}
                          strokeColor={"#ffbf00"}
                        />
                        <p className="projects-data-txt">{polyglot.t("Processed")}</p>
                      </div>
                      <div className="">
                        <Progress
                          percent={Math.ceil(
                            (this.props.dataprops.userData.ToBeProcessedDataSets /
                              this.props.dataprops.userData.TOTAL) *
                              100,
                          )}
                          width={40}
                          strokeColor={"#675e5e"}
                        />
                        <p className="projects-data-txt">{polyglot.t("Uploaded")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            )}

            {this.props.divId == "upcomingmilestones" && this.props.dataprops && (
              <span>
                {this.props.dataprops.userData && this.props.dataprops.userData.length > 0 && (
                  <table className="table boxtable">
                    <thead>
                      <tr>
                        <th className="Queen-Mile boxth">
                          <b className="milestonesTR">{polyglot.t("Label")}</b>
                        </th>
                        <th className="Queen-Mile boxth">
                          <b className="milestonesTR">{polyglot.t("Due Date")}</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.dataprops.userData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="boxtd">
                              {item.milestonename.length > 15
                                ? item.milestonename.substring(0, 16) + "..."
                                : item.milestonename}
                            </td>
                            <td className="boxtd">{getDisplayDate(item.deadline)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
                {(!this.props.dataprops.userData || this.props.dataprops.userData.length == 0) && (
                  <div className="nowidgetdata">{polyglot.t("No Milestone")}</div>
                )}
              </span>
            )}
            {this.props.divId == "data" && this.props.dataprops && (
              <div className="packagevolume-box">
                <div className="d-progress-bar ml-0">
                  <Progress
                    style={{ minwidth: "360px" }}
                    percent={PercentageUsed(
                      JSON.parse(this.props.dataprops)[0].VolumeUsed,
                      JSON.parse(this.props.dataprops)[0].TotalVolume,
                    )}
                    format={(percent) =>
                      `${
                        this.props.language == "en"
                          ? PercentageUsed(
                              JSON.parse(this.props.dataprops)[0].VolumeUsed,
                              JSON.parse(this.props.dataprops)[0].TotalVolume,
                            )
                          : PercentageUsed(
                              JSON.parse(this.props.dataprops)[0].VolumeUsed,
                              JSON.parse(this.props.dataprops)[0].TotalVolume,
                            )
                              .toString()
                              .replace(".", ",")
                      }%`
                    }
                  />
                </div>
                <div className="row mt-4">
                  <div className="col-md-6">
                    <div className="d-flex">
                      <h2 className="dd-test">
                        {GetPriceInGermanFormat(
                          customNumberFormat(JSON.parse(this.props.dataprops)[0].TotalVolume),
                          true,
                        )}
                      </h2>
                      &nbsp;<span className="d-gb-value">GB</span>
                    </div>
                    <span className="Queen-tot-value">{polyglot.t("Total volume")}</span>
                  </div>
                  <div className="col-md-6">
                    <span className="row Queen-Solution-gb">
                      <h2 className="dd-test">
                        {this.props.dataprops[0].VolumeUsed
                          ? GetPriceInGermanFormat(
                              customNumberFormat(JSON.parse(this.props.dataprops)[0].VolumeUsed),
                              true,
                            )
                          : 0}
                      </h2>
                      &nbsp;<span className="d-gb-value">GB</span>
                    </span>
                    <p className="Queen-tot-value">{polyglot.t("Used")}</p>
                  </div>
                </div>
              </div>
            )}
            {this.props.divId == "queenbaseprogress" && this.props.dataprops && (
              <span>
                <div className="Box-Circle">
                  <div className="Box-Data-Circle">
                    <div className="row queenbaseprogress">
                      <div className="col-md-2 progressBoxCircles">
                        <p className="projects-data-txt">{polyglot.t("Data Request")}</p>
                        <Progress
                          type="circle"
                          percent={Math.ceil(
                            (this.props.dataprops.userData[0].ParameterFileRequestCount /
                              this.props.dataprops.userData[0].Total) *
                              100,
                          )}
                          width={40}
                          strokeColor={"#eb8074"}
                        />
                      </div>
                      <div className="col-md-2 progressBoxCircles">
                        <p className="projects-data-txt">{polyglot.t("Data Uploaded")}</p>
                        <Progress
                          type="circle"
                          percent={Math.ceil(
                            (this.props.dataprops.userData[0].UploadedFileCount /
                              this.props.dataprops.userData[0].Total) *
                              100,
                          )}
                          width={40}
                          strokeColor={"#eb8074"}
                        />
                      </div>
                      <div className="col-md-2 progressBoxCircles">
                        <p className="projects-data-txt">{polyglot.t("Data Import")}</p>
                        <Progress
                          type="circle"
                          percent={Math.ceil(
                            (this.props.dataprops.userData[0].DataCheckCompletedDocCount /
                              this.props.dataprops.userData[0].Total) *
                              100,
                          )}
                          width={40}
                          strokeColor={"#eb8074"}
                        />
                      </div>
                      <div className="col-md-2 progressBoxCircles">
                        <p className="projects-data-txt">{polyglot.t("Data Processing")}</p>
                        <Progress
                          type="circle"
                          percent={Math.ceil(
                            (this.props.dataprops.userData[0].ProcessingCompletedCount /
                              this.props.dataprops.userData[0].Total) *
                              100,
                          )}
                          width={40}
                          strokeColor={"#eb8074"}
                        />
                      </div>
                      <div className="col-md-2 progressBoxCircles">
                        <p className="projects-data-txt">{polyglot.t("Dashboard Loading")}</p>
                        <Progress
                          type="circle"
                          percent={Math.ceil(
                            (this.props.dataprops.userData[0].DashboardLoadingCount /
                              this.props.dataprops.userData[0].Total) *
                              100,
                          )}
                          width={40}
                          strokeColor={"#eb8074"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </span>
            )}

            {this.props.divId == "topinvoices" && this.props.dataprops && (
              <span>
                {this.props.dataprops.userData && this.props.dataprops.userData.length > 0 && (
                  <table id="tinvoice" className="table boxtable">
                    <thead>
                      <tr>
                        <th className="Queen-Mile boxth">
                          <b className="milestonesTR">{polyglot.t("Invoice Ref")}</b>
                        </th>
                        <th className="Queen-Mile boxth">
                          <b className="milestonesTR">{polyglot.t("Amount")}</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.dataprops.userData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="boxtd">
                              {item.client_invoice_ref.length > 7 ? (
                                <Tooltip
                                  content={DisplayTooltipText(item.client_invoice_ref)}
                                  placement="top"
                                >
                                  {item.client_invoice_ref.substring(0, 8) + "..."}
                                </Tooltip>
                              ) : (
                                item.client_invoice_ref
                              )}
                            </td>
                            <td className="boxtd">
                              <Tooltip
                                content={DisplayTooltipText(
                                  GetPriceInGermanFormat(customNumberFormat(item.invoice_amount)) == "€NaN" ||
                                    GetPriceInGermanFormat(customNumberFormat(item.invoice_amount)) ==
                                      "NaN € €"
                                    ? this.props.language == "en"
                                      ? "€0"
                                      : "0 €"
                                    : GetPriceInGermanFormat(
                                        customNumberFormat(item.invoice_amount, true),
                                        false,
                                        true,
                                      ),
                                )}
                                placement="top"
                              >
                                <div className="boxtd invoiceTest">
                                  {GetPriceInGermanFormat(customNumberFormat(item.invoice_amount)) ==
                                    "€NaN" ||
                                  GetPriceInGermanFormat(customNumberFormat(item.invoice_amount)) == "NaN € €"
                                    ? this.props.language == "en"
                                      ? "€0"
                                      : "0 €"
                                    : GetPriceInGermanFormat(
                                        customNumberFormat(item.invoice_amount, true),
                                        false,
                                        true,
                                      )}{" "}
                                </div>
                              </Tooltip>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
                {(!this.props.dataprops.userData || this.props.dataprops.userData.length == 0) && (
                  <div className="nowidgetdata">{polyglot.t("No Invoice Details")}</div>
                )}
              </span>
            )}
            {this.props.divId == "expensiveinvoices" && this.props.dataprops && (
              <span>
                {this.props.dataprops.userData && this.props.dataprops.userData.length > 0 && (
                  <table id="pbill" className="table boxtable">
                    <thead>
                      <tr>
                        <th className="Queen-Mile boxth">
                          <b className="milestonesTR">{polyglot.t("Project")}</b>
                        </th>
                        <th className="Queen-Mile boxth">
                          <b className="milestonesTR">{polyglot.t("Amount")}</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.props.dataprops.userData.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td className="boxtd">
                              {item.projectname.length > 11 ? (
                                <Tooltip content={DisplayTooltipText(item.projectname)} placement="top">
                                  {item.projectname.substring(0, 12) + "..."}
                                </Tooltip>
                              ) : (
                                item.projectname
                              )}

                              {/*    
                              {(item.projectname.length > 15) ? item.projectname.substring(0, 16) + '...' : item.projectname} */}
                            </td>
                            <td className="boxtd">
                              <Tooltip
                                content={DisplayTooltipText(
                                  GetPriceInGermanFormat(customNumberFormat(item.invoice_sum)) == "€NaN" ||
                                    GetPriceInGermanFormat(customNumberFormat(item.invoice_sum)) == "NaN € €"
                                    ? this.props.language == "en"
                                      ? "€0"
                                      : "0 €"
                                    : GetPriceInGermanFormat(
                                        customNumberFormat(item.invoice_sum, true),
                                        false,
                                        true,
                                      ),
                                )}
                                placement="top"
                              >
                                <div className="boxtd billingTest">
                                  {GetPriceInGermanFormat(customNumberFormat(item.invoice_sum)) == "€NaN" ||
                                  GetPriceInGermanFormat(customNumberFormat(item.invoice_sum)) == "NaN € €"
                                    ? this.props.language == "en"
                                      ? "€0"
                                      : "0 €"
                                    : GetPriceInGermanFormat(
                                        customNumberFormat(item.invoice_sum, true),
                                        false,
                                        true,
                                      )}
                                </div>
                              </Tooltip>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                )}
                {(!this.props.dataprops.userData || this.props.dataprops.userData.length == 0) && (
                  <div className="nowidgetdata">{polyglot.t("No Invoice Details")}</div>
                )}
              </span>
            )}

            {this.props.divId == "totalrequests" && this.props.dataprops && (
              <span>
                <div className="d-flex justify-content-between total-head align-items-center">
                  <b>Total: {this.props.dataprops.data.targetList.length}</b>
                </div>
                <div className="d-flex justify-content-between  align-items-center card-spacing">
                  <div>
                    <ApexChart rawData={this.props.dataprops.data.categoryCount} />
                  </div>
                </div>
              </span>
            )}

            {this.props.divId == "requeststatus" && this.props.dataprops && (
              <span>
                <div className="d-flex justify-content-between total-head align-items-center">
                  <b>Total: {this.props.dataprops.data.targetList.length}</b>
                </div>
                <div className="d-flex justify-content-between  align-items-center card-spacing">
                  <div>
                    <ProgressChart rawData={this.props.dataprops.data.statusCount} />
                  </div>
                </div>
              </span>
            )}
          </div>
        </div>
      </Panel>
    );
  }
}
