import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import CssMain from "../Components/CSS/Main";
import DfMain from "../Components/DF/Main";
import DFEDMain from "../Components/DFED/Main";
import Main from "../Components/GI/Main";
import GRCMain from "../Components/GRC/Main";
import ForgotPassword from "../Components/QueenBase/Root/ForgotPassword";
import Home from "../Components/QueenBase/Root/Home";
import Login from "../Components/QueenBase/Root/Login";
import UserProfile from "../Components/QueenBase/Root/UserProfile";
import LoginContextProvider from "../Components/QueenBase/Root/LoginContext";
import EnterNewPassword from "../Components/QueenBase/Root/EnterNewPassword";
import UpdateUser from "../Components/QueenBase/Root/UpdateUser";
import IdamLogin from "../Components/QueenBase/Root/IdamLogin";
import ChooseClientOnLogin from "../Components/QueenBase/Root/ChooseClientOnLogin";
import DisclaimerComponent from "../Components/QueenBase/Root/Disclaimer";
import TermsAndUseComponent from "../Components/QueenBase/Root/TermsAndUseComponent";
import PrivacyPolicyComponent from "../Components/QueenBase/Root/PrivacyPolicyComponent";
import CreditsComponent from "../Components/QueenBase/Root/CreditsComponent";
import LoginIdam from "../Components/QueenBase/Root/LoginIdam";
import CookieSettingComponent from "../Components/QueenBase/Root/CookieSettingComponent";
import RiMain from "../Components/RI/Main";
import * as path from "../Constants/Path";

// FOOTER ROUTES
export const FooterRoutes = () => (
  <>
    <Route path={path.FOOTER_DISCLAMIER_PATH} component={DisclaimerComponent} />
    <Route path={path.FOOTER_TERMS_OF_USE_PATH} component={TermsAndUseComponent} />
    <Route path={path.FOOTER_PRIVACY_PATH} component={PrivacyPolicyComponent} />
    <Route path={path.FOOTER_CREDITS_PATH} component={CreditsComponent} />
    <Route path={path.FOOTER_CREDITS_COOKIES} component={CookieSettingComponent} />
  </>
);

// ROUTES OF APPLICATION AFTER LOGIN
const isGrcEnabled = process.env.REACT_APP_GRC_ENABLED === "true";
function LandingRoute() {
  return (
    <div id="middleContent">
      <Switch>
        <ProtectedRoute path={path.QUEEN_BASE_PATH} component={Home} />
        <ProtectedRoute
          path="/dfed"
          // component={Loadable({
          // 	loader: () => import("../Components/DFED/Main"),
          // 	loading: LoadingIndicator
          // })}
          component={DFEDMain}
        />
        <ProtectedRoute path="/df" component={DfMain} />
        <ProtectedRoute path="/gi" component={Main} />
        <ProtectedRoute path="/CSS" component={CssMain} />
        <ProtectedRoute path="/ri" component={RiMain} />
        <ProtectedRoute path={path.USER_PROFILE} component={UserProfile} />
        {isGrcEnabled && <ProtectedRoute path="/grc" component={GRCMain} />}
      </Switch>
      {/* <div className="footer" >Footer</div> */}
    </div>
  );
}

// ROUTES OF APPLICATION BEFORE LOGIN
const isProduction = process.env.REACT_APP_ENVIRONMENT === "production";
export const RoutesBeforeLogin = () => {
  return (
    <LoginContextProvider>
      <Switch>
        <Route exact path="/" render={() => <Redirect to={path.LOGIN_PATH} />} />
        <Route exact path={path.LOGIN_PATH} component={isProduction ? LoginIdam : Login} />
        <Route path={path.IDAM_PATH} component={IdamLogin} />
        <Route path={path.FORGOTKEY_PATH} component={ForgotPassword} />
        <Route path={path.ENTER_NEW_KEY} component={EnterNewPassword} />
        <Route path={path.UPDATE_USER_PROFILE} component={UpdateUser} />
        <Route path={`${path.CHOOSE_CLIENT_ON_LOGIN}/:userid/:code`} component={ChooseClientOnLogin} />
        <FooterRoutes />
      </Switch>
    </LoginContextProvider>
  );
};

export default LandingRoute;
