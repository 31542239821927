import { Input } from "appkit-react";
import { startCase } from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";

import { LoginContext } from "./LoginContext";
import displayToastMessage from "./displayToastMessage";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import eye from "../../../Assets/Icons/view-outline.svg";
import cross from "../../../Assets/Images/QueenBase/Root/cross-validate.svg";
import approve from "../../../Assets/Images/QueenBase/Root/icon-check-2.svg";
import iconeye from "../../../Assets/Images/QueenBase/Root/icon-eye-closed.svg";
import { SOLUTION_LIST_PATH, UPDATE_USER_PROFILE } from "../../../Constants/Path";
import LoginService from "../../../Services/QueenBase/Root/LoginService";
import { polyglotLoader } from "../../../Services/Shared/Translate";
function UpdatePassword(props) {
  const { loginResponse, successfulLogin } = useContext(LoginContext);

  var polyglot = polyglotLoader();
  const { isfirst, firstname, jwt, userid } = loginResponse;

  const [isPassword, setIsPassword] = useState(true);
  const [password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [validationMessages] = useState([
    {
      isError: false,
      message: "At least 8 characters",
    },
    {
      isError: false,
      message: "At least one number",
    },
    {
      isError: false,
      message: "At least one special character (!,.-?%$...)",
    },
    {
      isError: false,
      message: "At least one capital letter",
    },
    {
      isError: false,
      message: "At least one small letter",
    },
    {
      isError: false,
      message: "Password matched",
    },
  ]);

  const handlePassword = () => {
    setIsPassword((prev) => !prev);
  };

  // SUBMIT UPDATED PASSWORD TO API
  const updatePassword = async () => {
    try {
      let count = 0;
      validationMessages.map((item) => {
        if (item.isError === true) {
          count++;
        }
      });
      if (count > 0) {
        displayToastMessage(polyglot.t("Password is incorrect"), "error");
      } else {
        sessionStorage.setItem("jwt", jwt);
        const userObj = { userid };
        sessionStorage.setItem("user", JSON.stringify(userObj));
        var res = await LoginService.get().updatePassword({ password });
        if (res == true) {
          sessionStorage.removeItem("jwt");
          sessionStorage.removeItem("user");
          displayToastMessage(polyglot.t("Password updated successfully"), "success");
          if (isfirst) {
            props.history.push(UPDATE_USER_PROFILE);
            return null;
          }
          successfulLogin();
          props.history.push(SOLUTION_LIST_PATH);
        } else {
          displayToastMessage(res, "error");
        }
      }
    } catch (err) {
      displayToastMessage(polyglot.t("Invalid credentials Please try again!"), "error");
    }
  };
  const handleChange = (value) => {
    validate(value, "password");
    setPassword(value);
  };
  const handleConfirmPasswordChange = (value) => {
    validate(value, "confirm-password");
    setConfirmPassword(value);
  };

  const checkIfPasswordsMatch = (text, key) => {
    if (key === "password") {
      if (text === confirmpassword) {
        return true;
      }
    }
    if (key === "confirm-password") {
      if (text === password) {
        return true;
      }
    }
    return false;
  };

  const validate = (value, key) => {
    var res = [...validationMessages];

    if (value.length < 8) {
      res[0].isError = true;
    } else {
      res[0].isError = false;
    }

    if (value.search(/\d/) == -1) {
      res[1].isError = true;
    } else {
      res[1].isError = false;
    }

    if (value.search(/[^a-zA-Z0-9]/) == -1) {
      res[2].isError = true;
    } else {
      res[2].isError = false;
    }

    if (/([A-Z]+)/g.test(value)) {
      res[3].isError = false;
    } else {
      res[3].isError = true;
    }

    if (/([a-z]+)/g.test(value)) {
      res[4].isError = false;
    } else {
      res[4].isError = true;
    }

    if (key === "password") {
      const doesPasswordsMatch = checkIfPasswordsMatch(value, key);
      if (doesPasswordsMatch) {
        res[5].isError = false;
      } else {
        res[5].isError = true;
      }
    }
    if (key === "confirm-password") {
      const doesPasswordsMatch = checkIfPasswordsMatch(value, key);
      if (doesPasswordsMatch) {
        res[5].isError = false;
      } else {
        res[5].isError = true;
      }
    }
  };
  const displayfirsttimetext = () => {
    if (isfirst == 1) {
      return (
        <div className="new-pass-desc">
          {" "}
          {polyglot.t("Since this is your first login, please choose a new password.")}{" "}
        </div>
      );
    } else {
      return <div className="new-pass-desc"> {polyglot.t("please choose a new password.")} </div>;
    }
  };

  const displayPasswordForm = () => {
    return (
      <div className="card-body user-login forgetpassword-card">
        <span className="font-weight-medium text-nowrap">
          <b>{polyglot.t("Set up a new password")}</b>
        </span>
        <br />
        {displayfirsttimetext()}
        {/* <div className="new-pass-desc"> {polyglot.t('Since this is your first login, please choose a new password.')} </div> */}
        <br />
        <Input
          inputBoxSize="sm"
          placeholder={polyglot.t("New Password")}
          type={isPassword ? "password" : "text"}
          value={password}
          onChange={handleChange}
          suffix={
            <img
              src={isPassword ? eye : iconeye}
              title={isPassword ? "Show Password" : "Hide Password"}
              className="cursor"
              onClick={handlePassword}
            ></img>
          }
          aria-label={polyglot.t("New Password")}
        />
        <br />
        <Input
          inputBoxSize="sm"
          placeholder={polyglot.t("Confirm Password")}
          type={isPassword ? "password" : "text"}
          value={confirmpassword}
          onChange={handleConfirmPasswordChange}
          suffix={
            <img
              src={isPassword ? eye : iconeye}
              title={isPassword ? "Show Password" : "Hide Password"}
              className="cursor"
              onClick={handlePassword}
            ></img>
          }
          aria-label={polyglot.t("Confirm Password")}
        />
        {password.length > 0 ? (
          <div className="row">
            <ul className="update-validation">
              {validationMessages &&
                validationMessages.map((item, index) => {
                  return (
                    <li key={index}>
                      <img src={item.isError ? cross : approve} title="check" alt="check" />
                      &nbsp;&nbsp;<span>{item.message}</span>
                    </li>
                  );
                })}
            </ul>
          </div>
        ) : (
          ""
        )}
        <div className="row">
          <div className="col-md-5 mt-3">
            <ButtonPrimary onClick={updatePassword} ariaLabel={polyglot.t("Set Password")}>
              {polyglot.t("Set Password")}
            </ButtonPrimary>
          </div>
          <div className="col-md-7"></div>
        </div>
      </div>
    );
  };

  return (
    <div className="container" style={{ minHeight: "740px" }}>
      <div className="login-form">
        <div className="d-flex">
          <div className="card bg-light margin-0-auto min-width-25">
            <div className="card-header header_login Header_resposnive_login">
              <h1 className="headerText_Login">
                {`${startCase(firstname)}, ${polyglot.t("Welcome to Queen")}`}
              </h1>
            </div>
            {displayPasswordForm()}
          </div>
        </div>
      </div>
    </div>
  );
}
UpdatePassword.propTypes = {
  history: PropTypes.object,
};

export default UpdatePassword;
