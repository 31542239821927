import React, { Component } from "react";
import { Accordions, AccordionItem, AccordionItemHeader, AccordionItemBody, Tooltip } from "appkit-react";
import CommonButton from "../Shared/button/CommonButton";
import OutlineButton from "../Shared/button/OutlineButton";
import "../Processing/Processing.css";
import { Collapse } from "antd";
import { Input } from "reactstrap";
import CreateProcessing from "./CreateProcessing";
import ProcessingService from "../../../Services/DFED/ProcessingService";
import { Pagination } from "antd";
import "antd/dist/antd.css";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import { checkPermission } from "../../../utility/checkPermission";
import commonFunctionForCallService from "../../../utility/commonFunctionForCallService";
import * as module_Solution_Name from "../../../Constants/moduleName";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import NoAccess from "../../../Assets/Components/NoAccess";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { infoIcon } from "../../../Assets/Icons";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";

const { Panel } = Collapse;

export class Processing extends Component {
  constructor(props) {
    super();
    this.state = {
      showCustodian: props.location.state.showCustodian ?? false,
      showCustodianListComponent: props.location.state.showCustodianListComponent ?? false,
      showImageDiv: props.location.state.showImageDiv ?? false,
      MainDiv: props.location.state.MainDiv ?? true,
      allCustodianProcessing: [],
      selectedProjectId: props.location.state.selectedProjectId,
      accordinActiveKey: [],
      processingCount: 0,
      currentPage: 1,
      tempStateProcessData: [],
      rolePermission: {},
      isViewPermission: true,
      backupProcessingData: [],
    };
    this.showListProcessing = this.showListProcessing.bind(this);
    this.accordianClickHandler = this.accordianClickHandler.bind(this);
    this.onSearch = this.onSearch.bind(this);
  }
  static contextType = DataContext;

  componentDidMount() {
    this.getAllProcessingCustodian(parseInt(this.state.selectedProjectId));
    this.getProcessingCount(parseInt(this.state.selectedProjectId));
    this.getApplicationRolesWithPermission();
  }

  componentDidUpdate(prevProps) {
    const prevPropsRedirect = prevProps.location?.state.redirectFromSameUrl;
    const propsRedirect = this.props.location?.state.redirectFromSameUrl;

    if (
      prevPropsRedirect !== propsRedirect &&
      propsRedirect !== undefined &&
      this.state.showImageDiv === true
    ) {
      this.setState({
        showImageDiv: false,
        MainDiv: true,
      });
    }
  }

  // ============== CODE FOR GETTING ROLE==========
  getApplicationRolesWithPermission = async () => {
    let selectedProjectId = this.state.selectedProjectId;
    let rolePermission = await commonFunctionForCallService.commonFunctionForCallService(
      module_Solution_Name.DFED_SOLUTION,
      selectedProjectId,
      module_Solution_Name.PROCESSING_MODULE,
    );
    if (rolePermission) {
      this.setState({
        rolePermission: rolePermission,
      });
    }
  };

  async getProcessingCount(newSelectedProjectId) {
    let selectedProjectId = newSelectedProjectId;
    const processingService = new ProcessingService();
    let getKeyWordsCount = await processingService.getProcessingStatusCount(selectedProjectId);
    if (getKeyWordsCount && getKeyWordsCount.status === 200) {
      if (getKeyWordsCount.data.length > 0) {
        let data = getKeyWordsCount.data;
        this.setState({
          processingCount: data[0].total,
        });
      }
    }
  }
  getAllProcessingCustodian(newSelectedProjectId) {
    const processingService = new ProcessingService();
    let selectedProjectId = newSelectedProjectId;
    processingService
      .getAllProcessingCustodian(selectedProjectId)
      .then((result) => {
        if (result && result.data && result.data.length > 0) {
          this.setState(
            {
              allCustodianProcessing: result.data,
              tempStateProcessData: result.data,
              backupProcessingData: result.data,
            },
            () => {
              this.getProcessingCount(parseInt(this.state.selectedProjectId));
              this.visiableDataHandler(1);
            },
          );
        } else {
          return null;
        }
      })
      .catch((err) => {
        if (err.status === 403) {
          this.setState({
            isViewPermission: false,
          });
        }
      });
  }

  newProcessingRun = () => {
    var polyglot = polyglotLoader();
    const processingService = new ProcessingService();
    processingService
      .getProcessingCustodianRecords(this.state.selectedProjectId)
      .then((result) => {
        // CHECK CONDITION  IF RESULT CONTAIN DATASET OR NOT==============
        if (result.data.CODE === 0) {
          displayToastMessage(polyglot.t("Please add datasets before new processing run"), "error");
        } else {
          this.setState({
            showCustodian: true,
            showCustodianListComponent: false,
            showImageDiv: true,
            MainDiv: false,
          });
        }
      })
      .catch((err) => {});
  };
  closeCustodian() {
    if (this.state.showCustodian) {
      this.setState({
        showCustodian: false,
        showPersonalCustodianForm: false,
        showGroupCustodianForm: false,
        showCustodianListComponent: false,
        showImageDiv: false,
        MainDiv: true,
      });
    }

    this.setState({ MainDiv: true });
  }

  showListProcessing() {
    this.getAllProcessingCustodian(parseInt(this.state.selectedProjectId));
    this.setState({
      showImageDiv: false,
    });
  }
  accordianClickHandler(e) {
    if (this.state.accordinActiveKey[0] === e) {
      this.setState({ accordinActiveKey: [] });
    } else {
      this.setState({ accordinActiveKey: [e] });
    }
  }

  onChangeTablePageHandler = (page) => {
    this.setState({ currentPage: page });
    this.visiableDataHandler(page);
  };

  visiableDataHandler(page) {
    let lastIndex = 7 * page;
    let firstIndex = lastIndex - 7;
    let tempCustodianProcessData = JSON.parse(JSON.stringify(this.state.tempStateProcessData));
    let visData = tempCustodianProcessData.slice(firstIndex, lastIndex);
    this.setState({ allCustodianProcessing: visData });
  }

  onSearch = (e) => {
    let tempCustodianProcessData = JSON.parse(JSON.stringify(this.state.backupProcessingData));
    var list = tempCustodianProcessData.filter(
      (item) => item.CustodianName.toLowerCase().search(e.target.value.toLowerCase()) !== -1,
    );
    this.setState({ allCustodianProcessing: list, tempStateProcessData: list }, () => {
      this.visiableDataHandler(1);
    });
  };

  getCount() {
    var polyglot = polyglotLoader();
    if (this.state.processingCount === 1) {
      return (
        <div className="col-md-6 tex-ali-processing p-l-0 p-r-0" aria-label={polyglot.t("processing run")}>
          <h5 className="main_title_qb m-b-21">
            {" "}
            {this.state.processingCount} {polyglot.t("processing run")}
          </h5>
        </div>
      );
    } else if (this.state.processingCount > 1) {
      return (
        <div className="col-md-6 tex-ali-processing p-l-0 p-r-0">
          <h5 className="main_title_qb m-b-21">
            {" "}
            {this.state.processingCount} {polyglot.t("processing run(s)")}
          </h5>
        </div>
      );
    } else {
      return (
        <div className="col-md-6 tex-ali-processing p-l-0 p-r-0">
          <h5 className="main_title_qb m-b-21">
            {" "}
            {0} {polyglot.t("processing run")}
          </h5>
        </div>
      );
    }
  }

  bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes == 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return Math.round(bytes / Math.pow(1024, i), 2) + " " + sizes[i];
  };
  hoursToDays = (hour) => {
    if (isNaN(hour)) {
      var hoursToDays = "";
    } else if (hour <= 1) {
      var hoursToDays = hour + " " + "hour";
    } else if (hour > 1 && hour <= 24) {
      var hoursToDays = hour + " " + "hours";
    } else {
      var hoursToDays1 = hour % 24;
      var hoursToDays =
        hoursToDays1 > 12 ? Math.round(hour / 24) + " " + "days" : Math.round(hour / 24) + 1 + " " + "days";
    }
    return hoursToDays;
  };
  // DISPLAY NAME ON TOOLTIP
  TooltipText = (name) => {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#000",
                }}
              >
                {name}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  render() {
    var polyglot = polyglotLoader();
    const set1 = (
      <div className="">
        <div className="row col-lg-10 pl-0">
          {checkPermission(this.state.rolePermission, module_Solution_Name.PROCESSING_MODULE, "Create") && (
            <div className="col-md-3 col-lg-3">
              <ButtonPrimary onClick={this.newProcessingRun} ariaLabel={polyglot.t("New processing run")}>
                {polyglot.t("New processing run")}
              </ButtonPrimary>
            </div>
          )}
          {!checkPermission(this.state.rolePermission, module_Solution_Name.PROCESSING_MODULE, "Create") && (
            <div className="col-md-3 col-lg-3"></div>
          )}
          <div
            className="col-md-3 col-lg-4"
            onClick={() =>
              this.props.history.push({
                pathname: "/dfed/keywords",
                state: { selectedProjectId: this.state.selectedProjectId },
              })
            }
          >
            <OutlineButton name={polyglot.t("Set up keyword lists")} />
          </div>
          <div className="col-lg-3 col-md-6"></div>
        </div>

        <div className="row m-t-21  m-l-0 m-r-0">{this.getCount()}</div>
        {this.state.processingCount && this.state.processingCount > 0 ? (
          <div className="row m-l-0 m-r-0 m-t-4">
            <div className="col-md-3 p-0 search-analysis m-b-15">
              <Input
                className="analysis-search"
                aria-label={polyglot.t("Search for processing")}
                placeholder={polyglot.t("Search for processing")}
                value={this.state.query}
                onChange={this.onSearch.bind(this)}
              />
            </div>
            <div className="col-md-3"></div>
          </div>
        ) : (
          ""
        )}
        <div className="row m-l-0 m-r-0 m-t-14">
          <div className="col-md-8 p-l-0 processing-collapse">
            <div className="dfed-expand-list-cards" style={{ marginBottom: "0.9375rem" }}>
              {this.state.allCustodianProcessing &&
                this.state.allCustodianProcessing.map((data, index) => {
                  return (
                    <Accordions
                      key={index}
                      className="mb-2"
                      onChange={this.accordianClickHandler}
                      activeItem={this.state.accordinActiveKey}
                    >
                      <AccordionItem itemId={index} key={index}>
                        <AccordionItemHeader title={data.CustodianName} />
                        <AccordionItemBody>
                          {data.dataset.map((childData, index1) => {
                            return (
                              <div className="row childDatasetsDiv mb-4 m-l-0 m-r-0" key={index1}>
                                <div className="col-sm-4 col-md-4 processingCustodianName txt-overflow">
                                  Dataset: {childData.DataSetName}{" "}
                                  {childData.ProfileName && <p>Profile: {childData.ProfileName}</p>}
                                </div>
                                <div className="col-sm-8 col-md-8 p-t-9 progressingStatusDiv">
                                  {childData.Status === 1 && (
                                    <div className="progress progressBarDiv processing-list-progressbar">
                                      <div className="progressStatus1"></div>
                                    </div>
                                  )}
                                  {childData.Status === 2 && (
                                    <div className="progress progressBarDiv processing-list-progressbar">
                                      <div className="progressStatus2"></div>
                                    </div>
                                  )}
                                  {childData.Status === 3 && (
                                    <div className="progress progressBarDiv processing-list-progressbar">
                                      <div className="progressStatus3"></div>
                                    </div>
                                  )}
                                  {childData.Status === 3 && (
                                    <>
                                      <span className="processingfilesize">
                                        {polyglot.t("File size: ")}
                                        {this.bytesToSize(childData.File_Size)}
                                      </span>
                                      <span className="processingstatusText" aria-label={polyglot.t("Done")}>
                                        {polyglot.t("Done")}
                                      </span>
                                    </>
                                  )}
                                  {childData.Status === 2 && (
                                    <>
                                      <span className="processingfilesize">
                                        {polyglot.t("File size: ")}
                                        {this.bytesToSize(childData.File_Size)}
                                      </span>
                                      {childData.processing_time ? (
                                        <>
                                          <span
                                            className="processingstatusTextNoestimation"
                                            aria-label={polyglot.t("Processing")}
                                          >
                                            {polyglot.t("Processing")}
                                            &nbsp; ({polyglot.t("estimated time for completion : ")}
                                            {this.hoursToDays(childData.processing_time)
                                              ? this.hoursToDays(childData.processing_time)
                                              : ""}
                                            )
                                          </span>
                                        </>
                                      ) : (
                                        <span
                                          className="processingstatusTextNoestimation"
                                          aria-label={polyglot.t("Processing")}
                                        >
                                          {polyglot.t("Processing")} ({polyglot.t("processing time not set")})
                                          <Tooltip
                                            content={this.TooltipText(
                                              "Reach out to admin to get accurate processing time",
                                            )}
                                            placement="top"
                                          >
                                            <ButtonIcon
                                              className="tooltip-text"
                                              iconName={infoIcon}
                                              alt="info"
                                              ariaLabel={polyglot.t(
                                                "reach out to admin to get accurate processing time",
                                              )}
                                            />{" "}
                                          </Tooltip>
                                        </span>
                                      )}
                                    </>
                                  )}

                                  {childData.Status === 1 && (
                                    <>
                                      <span className="processingfilesize">
                                        {polyglot.t("File size: ")}
                                        {this.bytesToSize(childData.File_Size)}
                                      </span>
                                      {childData.processing_time ? (
                                        <>
                                          <span
                                            className="processingstatusTextNoestimation"
                                            aria-label={polyglot.t("Pushed for processing")}
                                          >
                                            {polyglot.t("Pushed for processing")}
                                            &nbsp; ({polyglot.t("estimated time for completion : ")}
                                            {this.hoursToDays(childData.processing_time)
                                              ? this.hoursToDays(childData.processing_time)
                                              : ""}
                                            )
                                          </span>
                                        </>
                                      ) : (
                                        <span
                                          className="processingstatusTextNoestimation"
                                          aria-label={polyglot.t("Pushed for processing")}
                                        >
                                          {polyglot.t("Pushed for processing")} (
                                          {polyglot.t("processing time not set")})
                                          <Tooltip
                                            content={this.TooltipText(
                                              "Reach out to admin to get accurate processing time",
                                            )}
                                            placement="top"
                                          >
                                            <ButtonIcon
                                              className="tooltip-text"
                                              iconName={infoIcon}
                                              alt="info"
                                              ariaLabel={polyglot.t(
                                                "reach out to admin to get accurate processing time",
                                              )}
                                            />
                                          </Tooltip>
                                        </span>
                                      )}
                                    </>
                                  )}
                                </div>
                              </div>
                            );
                          }, this)}
                        </AccordionItemBody>
                      </AccordionItem>
                    </Accordions>
                  );
                })}
            </div>
            <div className="row m-t-16 m-l-n-1 m-r-0">
              {this.state.tempStateProcessData && this.state.tempStateProcessData.length > 7 ? (
                <Pagination
                  current={this.state.currentPage}
                  size="small"
                  pageSize={7}
                  total={this.state.tempStateProcessData.length}
                  onChange={this.onChangeTablePageHandler}
                />
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="col-md-4 p-l-20 processing-static-code margin-minus-0-5">
            <div>
              <p className="para-txt">
                {polyglot.t(
                  "Here you can see all the processing jobs that have been created throughout the project. By clicking on one of the Custodians, you can consult the current status of (running) processing jobs associated with this Custodian",
                )}
                .
              </p>
              <p>
                <span className="para-txt">
                  {polyglot.t("If you want to create a new Processing Job, please click on ")}
                </span>
                <span className="para-head">{polyglot.t("New processing runn")}</span>
                <span className="para-txt">{polyglot.t("at the top of the screeen")}.</span>
              </p>
            </div>
            <div className="cust-right-div-text-title mb-2">
              {" "}
              <b>{polyglot.t("Processing complete?")}</b>
            </div>
            <div className="cust-right-div-text">
              {" "}
              {polyglot.t(
                "You can now send other datasets for processing, or create keyword lists and send processed datasets for analysis.",
              )}
            </div>
          </div>
        </div>
      </div>
    );

    const set2 = (
      <div className="row m-l-0 m-r-0">
        {" "}
        <CreateProcessing
          selectedProjectId={this.state.selectedProjectId}
          showlistProcessing={this.showListProcessing.bind(this)}
        />
      </div>
    );
    const set3 = <NoAccess />;
    var newset;
    if (this.state.showImageDiv && this.state.isViewPermission) {
      newset = set2;
    } else if (this.state.isViewPermission && !this.state.showImageDiv) {
      newset = set1;
    } else {
      newset = set3;
    }
    return newset;
  }
}

export default Processing;
