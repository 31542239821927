import React from "react";
import Dropzone from "../../dropzone/Dropzone";
import "./groupCustodian.css";
import "../personalCustodian/personalCustodian.css";
import { Avatar, Button, Input, Select, SelectGroupTitle, SelectOption } from "appkit-react";
import FormFieldComponent from "../../Shared/components/FormFieldComponent";
import FormTextAreaComponent from "../../Shared/components/FormTextAreaComponent";
import FormSingleSelect from "../../Shared/components/FormSingleSelect";
import FormSingleSelectWithDescription from "../../Shared/components/FormSingleSelectWithDescription";
import "appkit-react/style/appkit-react.default.css";
import CustodianService from "../../../../Services/DFED/CustodianService";
import { Form } from "antd";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import { DataContext } from "../../../../Services/Shared/Store.js";
import closeIcon from "../../../../Assets/Images/DFED/icon-x.svg";
import Polyglot from "node-polyglot";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";
import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import download from "../../../../Assets/Images/DFED/icon-download.svg";
import {
  TextFieldLengthRegexFieldValidation,
  TextFieldLengthValidation,
} from "../../../../utility/textFieldValidation";
import Progress from "../../progress/Progress";
import { CUSTODIAN_TYPE } from "../../../../Constants/Constant";
// GROUP CUSTODIAN CLASS RESPONSIBLE FOR CREATING GROUP CUSTODIAN AND EDITING OF GROUP CUSTODIAN===================
class GroupCustodianComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      formData: {
        CRT_TYPE_ID: this.props.custodianTypeID ? this.props.custodianTypeID : null,
        CRT_FIRST_NAME: "",
        CRT_MIDDLE_NAME: "",
        CRT_LAST_NAME: "",
        CRT_EMAIL: "",
        CRT_POSITION_ID: "",
        CRT_COUNTRY_ID: "",
        CRT_ENTITY: "",
        CRT_DEPARTMENT: "",
        CRT_ALIAS: "",
        CC_CLASSIFICATION_TYPE: "",
        CRT_CLASSIFICATION_ID: "",
        CRT_NOTES: "",
        CRT_FILE: [],
        position_Name: null,
        CRT_PROJECT_ID: this.props.selectedProjectId,
        CRT_PROJECT_NAME: this.props.selectedProjectName,
        textFieldLengthError: false,
      },
      saveButtonDisabled:
        this.props.isCustodianEditableAfterProcessed === false && this.props.editableFormData !== null
          ? false
          : true,
      custodianRecords: null,
      CountryList: null,
      ClassificationList: null,
      positionList: null,
      positionSelectValue: null,
      files: [],
      filesize: null,
      textFieldAliasError: false,
      requestProgress: null,
    };
    // BINDING FUNCTION FOR NOT LOOSING SCOPE OF THIS===============
    this.onChangeFormField = this.onChangeFormField.bind(this);
    this.saveFormData = this.saveFormData.bind(this);
    this.formFileUpload = this.formFileUpload.bind(this);
    this.downloadCustodianById = this.downloadCustodianById.bind(this);
  }
  static contextType = DataContext;
  polyglot = polyglotLoader();

  // LIFE CYCLE METHOD FOR HANDLING API SERVICES AND GETTING ALL REQUIRED FIELD DATA LIKE COUNTRY,POSITION,CLASSIFICATION

  componentDidMount() {
    let selectedPosition = null;
    const custodianService = new CustodianService();
    const { getLanguageKeyAsPerAPI } = this.context;
    const lang = getLanguageKeyAsPerAPI();
    custodianService.getCountry(lang).then((countryResult) => {
      if (countryResult && countryResult.data) {
        this.setState(
          {
            CountryList: countryResult.data,
          },
          () => {},
        );
      }
    });
    // get Classification
    custodianService.getClassifications().then((classificationResult) => {
      if (classificationResult && classificationResult.data) {
        this.setState(
          {
            ClassificationList: classificationResult.data,
          },
          () => {},
        );
      }
    });
    custodianService.getPositions().then((positionResult) => {
      if (positionResult && positionResult.data) {
        this.setState(
          {
            positionList: positionResult.data,
          },
          () => {},
        );
      }
    });

    if (this.props.editableFormData) {
      var fileArr = this.props.editableFormData[0].files
        ? this.props.editableFormData[0].files.split(";")
        : null;
      let tempFileArr = [];
      if (fileArr && fileArr.length > 0) {
        for (let i = 0; i < fileArr.length; i++) {
          let obj = { name: fileArr[i] };
          tempFileArr.push(obj);
        }
      }
      this.setState({
        formData: this.props.editableFormData[0],
        positionSelectValue: selectedPosition,
      });

      this.setState((prevState) => {
        return {
          formData: {
            ...prevState.formData,
            CRT_FILE: tempFileArr,
          },
        };
      });
    }
  }
  // DROP FUNCTIONALITY FOR FILE==========

  onDrop = (acceptedFiles, index) => {
    this.formFileUpload(acceptedFiles);
  };

  // RUNS WHEN COMPONENT GETS UPDATED
  componentDidUpdate(prevProps, prevState) {
    if (prevState.formData.CRT_ALIAS !== this.state.formData.CRT_ALIAS) {
      this.checkIsAliasUnique(this.state.formData).then((isAliasUnique) => {
        this.setState({ textFieldAliasError: !isAliasUnique });
      });
    }

    if (prevState.textFieldAliasError !== this.state.textFieldAliasError) {
      this.setState({
        saveButtonDisabled: this.state.textFieldAliasError || !this.checkFieldValidation(),
      });
    }

    const isFormViewOnly = this.props.isCustodianEditableAfterProcessed;
    if (!isFormViewOnly && this.state.formData !== prevState.formData && "CRT_ID" in prevState.formData) {
      const { formGotOpened } = this.context;
      formGotOpened();
    }
  }

  // FORM FIELD ON CHANGE HANDLER
  onChangeFormField(label, value, id) {
    let val = value;
    let name = label;
    if (id === "manualInput") {
      if (label === "CRT_POSITION_ID") {
        this.setState((prevState) => {
          return {
            formData: {
              ...prevState.formData,
              position_Name: value,
            },
          };
        });
      }
    }
    if (label === "CRT_FIRST_NAME") {
      if (TextFieldLengthValidation(val)) {
        this.setState((prevState) => {
          return {
            formData: {
              ...prevState.formData,
              CRT_ALIAS: this.props.editableFormData === null ? val : prevState.formData.CRT_ALIAS,
            },
          };
        });
        this.setState({ textFieldLengthError: false });
      } else {
        this.setState({ textFieldLengthError: true });
      }
    }
    if (label === "CRT_DEPARTMENT") {
      this.setState((prevState) => {
        return {
          formData: {
            ...prevState.formData,
          },
        };
      });
    }
    if (label === "CRT_ENTITY") {
      this.setState((prevState) => {
        return {
          formData: {
            ...prevState.formData,
          },
        };
      });
    }

    if (!id) {
      this.setState(
        (prevState) => {
          return {
            formData: {
              ...prevState.formData,
              [name]: val,
            },
          };
        },
        () => {
          if (this.checkFieldValidation()) {
            this.setState({
              saveButtonDisabled: false,
            });
          } else {
            this.setState({
              saveButtonDisabled: true,
            });
          }
        },
      );
    }
  }

  // FILE UPLOAD FUNCTIONALITY=====================
  formFileUpload(data, index, FilesData) {
    if (data.length > 0) {
      let obj = {
        name: data[0].name,
      };

      const regex = new RegExp(/(?<process>[\w\.-]*)((\.exe)|(\.js)|(\.html)|(\.asp)|(\.php))/gm);
      if (
        this.state.formData.CRT_FILE &&
        this.state.formData.CRT_FILE.length < 5 &&
        data[0].size < 104857600 &&
        !regex.test(data[0].name)
      ) {
        this.setState({
          formData: {
            ...this.state.formData,
            CRT_FILE: [...this.state.formData["CRT_FILE"], obj],
          },
          files: [...this.state.files, data[0]],
          filesize: data[0].size,
        });
      } else {
        displayToastMessage(data[0].name + " " + `is not a valid file`, "error");
        this.setState({
          formData: {
            ...this.state.formData,
          },
          filesize: data[0].size,
        });
      }
    }
  }

  // Download Files of custodian
  downloadCustodianById(downloadIndex, cid, data) {
    let metadata;
    this.state.formData.CRT_FILE.map((fileName, index) => {
      var fname = fileName.name.split(",")[1];
      fname = fname.split(".")[1];
      if (cid == index) {
        metadata = {
          fileExtension: fname,
          projectName: this.props.selectedProjectName,
          CRT_ALIAS: this.state.formData.CRT_ALIAS,
          CRT_ID: this.state.formData.CRT_ID,
          CRT_TYPE_ID: this.state.formData.CRT_TYPE_ID,
          CRT_FILE: fileName.name,
        };
      }
    });

    const custodianService = new CustodianService();
    custodianService
      .downloadCustodianById(metadata)
      .then((response) => {
        if (response.data) {
          if (response.data.CODE === 1) {
            displayToastMessage(this.polyglot.t(`${response.data.MSG}`), "success"); // Downloaded successfully, translation added
            const buffer = Buffer.from(response.data.buffer);
            const url = window.URL.createObjectURL(new Blob([buffer]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", response.data.fileName);
            document.body.appendChild(link);
            link.click();
          } else {
            displayToastMessage(this.polyglot.t(`${response.data.MSG}`), "error");
          }
        }
      })
      .catch((error) => {});
  }
  // DELETE FILE UPLOAD FUNCTIONALITY
  deleteUploadedFile(filedata, index, data) {
    this.state.formData.CRT_FILE.splice(index, 1);
    this.state.files.splice(index, 1);
    this.setState({
      formData: {
        ...this.state.formData,
      },
      files: this.state.files,
    });
  }

  onChangeCountry = (value) => {
    const form = { ...this.state.formData };
    form.CRT_COUNTRY_ID = value;
    this.setState({
      formData: form,
    });
  };

  checkIsAliasUnique = async (currentFormData) => {
    const currentAlias = currentFormData?.CRT_ALIAS;
    const custodianId = currentFormData?.CRT_ID;

    let selectedProjectId = this.props.selectedProjectId;
    const custodianService = new CustodianService();

    const allCustodians = await custodianService.getCustodianData(selectedProjectId);
    let custodiansList = allCustodians.data;

    if (custodianId) {
      custodiansList = allCustodians.data.filter((data) => Number(data.CRT_ID) !== Number(custodianId));
    }

    return !custodiansList.some(({ CRT_ALIAS: custodianAlias }) => custodianAlias === currentAlias);
  };

  // VALIDATING THE INPUT FIELD===========

  checkFieldValidation() {
    if (
      this.state.formData.CRT_CLASSIFICATION_ID !== "" &&
      this.state.formData.CRT_CLASSIFICATION_ID !== null &&
      this.state.formData.CRT_ALIAS !== "" &&
      this.state.formData.CRT_ALIAS !== null &&
      !this.state.textFieldAliasError &&
      ((this.state.formData.CRT_DEPARTMENT !== null && this.state.formData.CRT_DEPARTMENT !== "") ||
        (this.state.formData.CRT_ENTITY !== null && this.state.formData.CRT_ENTITY !== "")) &&
      this.props.isCustodianEditableAfterProcessed === false
    ) {
      return true;
    } else {
      return false;
    }
  }

  onUploadProgress = (progressEvent) => {
    let progress = (progressEvent.loaded / progressEvent.total) * 100;
    this.setState({ requestProgress: progress });
  };

  // SAVE CUSTODIAN FUNCTION=================
  saveFormData() {
    // validation with special char
    let isValidationSuccessfulGroup = true;
    // CRT_ENTITY
    if (
      this.state.formData.CRT_ENTITY != "" &&
      this.state.formData.CRT_ENTITY != null &&
      !TextFieldLengthRegexFieldValidation(this.state.formData.CRT_ENTITY)
    ) {
      isValidationSuccessfulGroup = false;
    }
    // CRT_DEPARTMENT
    if (
      this.state.formData.CRT_DEPARTMENT != "" &&
      this.state.formData.CRT_DEPARTMENT != null &&
      !TextFieldLengthRegexFieldValidation(this.state.formData.CRT_DEPARTMENT)
    ) {
      isValidationSuccessfulGroup = false;
    }
    // CRT_NOTES
    if (
      this.state.formData.CRT_NOTES != "" &&
      this.state.formData.CRT_NOTES != null &&
      !TextFieldLengthRegexFieldValidation(this.state.formData.CRT_NOTES)
    ) {
      isValidationSuccessfulGroup = false;
    }
    // CRT_ALIAS
    if (
      this.state.formData.CRT_ALIAS != "" &&
      this.state.formData.CRT_ALIAS != null &&
      !TextFieldLengthRegexFieldValidation(this.state.formData.CRT_ALIAS)
    ) {
      isValidationSuccessfulGroup = false;
    }

    if (!isValidationSuccessfulGroup) {
      displayToastMessage(this.polyglot.t("field_validaton"), "error");
    } else {
      // Logic for split First Name and Last Name Based on Alias//

      this.saveGroupCustodianData();
    }
  }

  saveGroupCustodianData = () => {
    const { formGotClosed } = this.context;

    if (Number(this.state.formData.CRT_TYPE_ID) === CUSTODIAN_TYPE.GROUP) {
      const selectedProjectId = this.props.selectedProjectId;
      const custodianService = new CustodianService();
      const formData = new FormData();

      this.state.files.forEach((file) => {
        formData.append("file", file);
        formData.append("fileName", file.name);
      });

      formData.append("custodianFormData", JSON.stringify(this.state.formData));
      formData.append("projectName", this.props.selectedProjectName);

      custodianService
        .handleCustodianFormData(formData, this.onUploadProgress)
        .then((result) => {
          if (result.data.CODE === 0) {
            displayToastMessage(result.data.MSG, "error");
            return null;
          }
          if (result && result.data && result.data.exist) {
            displayToastMessage(
              this.polyglot.t("Custodian with same Alias is already exist Please enter different name"),
              "error",
            );
          } else {
            displayToastMessage(
              this.polyglot.t(
                `Custodian ${this.props.editableFormData ? "updated" : "created"} successfully`,
              ),
              "success",
            );

            custodianService.getCustodianData(selectedProjectId).then((result) => {
              if (result && result.data) {
                this.setState(
                  {
                    custodianRecords: result.data,
                  },
                  () => {
                    this.props.showCustodianList(this.state.custodianRecords);
                  },
                );
              }
            });
          }
          formGotClosed();
        })
        .catch((error) => {
          displayToastMessage(error.response.data, "error");
        });
    }
  };

  renderProgress = () => {
    return (
      <div className="col-md-8 p-r-0">
        <div className="row">
          <div className="col-md-10" style={{ marginLeft: "10%" }}>
            <div className="ProgressWrapper" style={{ width: "200%", float: "left" }}>
              <Progress progress={this.state.requestProgress ?? 0} />
            </div>
          </div>
        </div>
      </div>
    );
  };

  // CHECK AND DISABLE ALIAS FIELD
  isAliasFieldDisabled = () => {
    let isDisabled = false;
    if (this.props.isCustodianEditableAfterProcessed) {
      isDisabled = true;
    }
    return isDisabled;
  };

  // INPUT FIELD DISABLE IF DATASET ALREADY PROCESSED FOR THIS CUSTODIAN===========

  textFieldDIsableHandler = () => {
    if (this.props.isCustodianEditableAfterProcessed === true) {
      return true;
      // && this.props.editableFormData !== null
    } else {
      return false;
    }
  };
  // RENDER FUNCTION FOR GROUP CUSTODIAN===

  render() {
    var polyglot = polyglotLoader();
    return (
      <div className="">
        <Form>
          <div className="row m-l-0 m-r-0">
            <div className="col-sm-12 col-md-12 inputFieldGroup p-b-5">
              <span>{polyglot.t("Group")}</span>
            </div>
            <div className="col-sm-12 col-md-6">
              <FormFieldComponent
                aria-label={polyglot.t("Name")}
                disabledTextFields={this.textFieldDIsableHandler()}
                placeholder={polyglot.t("Name") + " *"}
                id="fname"
                name="CRT_FIRST_NAME"
                val={this.state.formData.CRT_FIRST_NAME}
                onChangeFormField={this.onChangeFormField.bind(this)}
              ></FormFieldComponent>
            </div>
            <div
              className="col-sm-12 col-md-12 inputFieldGroup m-t-28 p-b-5"
              aria-label={polyglot.t("Location")}
            >
              <span>{polyglot.t("Location")}</span>
            </div>
            {this.state.CountryList && this.state.CountryList.length > 0 && (
              <div className="col-sm-12 col-md-6">
                {/* <FormSingleSelect disabledTextFields={this.textFieldDIsableHandler()} 
                preFillSelectWithDescription={this.props.preFillSelectWithDescription} 
                placeholder={polyglot.t("Country *")} id="country" name="CRT_COUNTRY_ID" 
                val={this.state.formData.CRT_COUNTRY_ID} options={this.state.CountryList} 
                onChangeFormField={this.onChangeFormField.bind(this)}></FormSingleSelect> */}

                <Select
                  disabled={this.textFieldDIsableHandler()}
                  preFillSelectWithDescription={this.props.preFillSelectWithDescription}
                  className="update-position"
                  placeholder={this.polyglot.t("Country ")}
                  showSearchOnToggle={true}
                  aria-label={this.polyglot.t("Country ")}
                  value={Number(this.state.formData.CRT_COUNTRY_ID)}
                  id="country"
                  name="CRT_COUNTRY_ID"
                  onSelect={(value) => {
                    this.onChangeCountry(value);
                  }}
                >
                  <SelectGroupTitle>{this.polyglot.t("CountryTitle")} </SelectGroupTitle>
                  {this.state.CountryList.map((country, index) => {
                    if (index < 1)
                      return (
                        <SelectOption key={index + 1} value={country.value}>
                          {country.label}
                        </SelectOption>
                      );
                  })}
                  <hr className="" />
                  {this.state.CountryList.map((country, index) => {
                    if (index > 0)
                      return (
                        <SelectOption key={index + 1} value={country.value}>
                          {country.label}
                        </SelectOption>
                      );
                  })}
                </Select>
              </div>
            )}
            <div className="col-sm-12 col-md-6">
              <FormFieldComponent
                disabledTextFields={this.textFieldDIsableHandler()}
                aria-label={polyglot.t("Entity")}
                placeholder={
                  this.state.formData.CRT_DEPARTMENT !== null && this.state.formData.CRT_DEPARTMENT !== ""
                    ? polyglot.t("Entity")
                    : polyglot.t("Entity *")
                }
                id="entity"
                name="CRT_ENTITY"
                val={this.state.formData.CRT_ENTITY}
                onChangeFormField={this.onChangeFormField.bind(this)}
              ></FormFieldComponent>
            </div>
            <div className="col-sm-12 col-md-6">
              <FormFieldComponent
                disabledTextFields={this.textFieldDIsableHandler()}
                placeholder={
                  this.state.formData.CRT_ENTITY != null && this.state.formData.CRT_ENTITY !== ""
                    ? polyglot.t("Department ")
                    : polyglot.t("Department *")
                }
                id="department"
                name="CRT_DEPARTMENT"
                val={this.state.formData.CRT_DEPARTMENT}
                aria-label={polyglot.t("Department ")}
                onChangeFormField={this.onChangeFormField.bind(this)}
              ></FormFieldComponent>
            </div>

            <div className="col-sm-12 col-md-12 inputFieldGroup m-t-28 p-b-5">
              <span>{polyglot.t("General")}</span>
            </div>
            <div className="col-sm-12">
              {this.state.textFieldAliasError ? (
                <p className="txt-field-error m-0 " aria-label={polyglot.t("Alias should be unique")}>
                  {polyglot.t("Alias should be unique")}
                </p>
              ) : null}
            </div>
            <div className="col-sm-12 col-md-6">
              <FormFieldComponent
                disabledTextFields={this.isAliasFieldDisabled()}
                aria-label={polyglot.t("Alias *")}
                placeholder={polyglot.t("Alias *")}
                id="alias"
                name="CRT_ALIAS"
                val={this.state.formData.CRT_ALIAS}
                onChangeFormField={this.onChangeFormField.bind(this)}
              ></FormFieldComponent>
            </div>

            <div className="col-sm-12 col-md-6">
              <FormSingleSelectWithDescription
                disabledTextFields={this.textFieldDIsableHandler()}
                preFillSelectWithDescription={this.props.preFillSelectWithDescription}
                aria-label={polyglot.t("Classifications *")}
                placeholder={polyglot.t("Classifications *")}
                id="classifications"
                val={this.state.formData.CRT_CLASSIFICATION_ID}
                name="CRT_CLASSIFICATION_ID"
                onChangeFormField={this.onChangeFormField.bind(this)}
                classificationOptions={this.state.ClassificationList}
              />
            </div>
            <div className="col-sm-12 col-md-12">
              <FormTextAreaComponent
                disabledTextFields={this.textFieldDIsableHandler()}
                aria-label={polyglot.t("Notes_custodian")}
                placeholder={polyglot.t("Notes_custodian")}
                id="notes"
                name="CRT_NOTES"
                val={this.state.formData.CRT_NOTES}
                onChangeFormField={this.onChangeFormField.bind(this)}
              ></FormTextAreaComponent>
            </div>
            <div className="col-sm-12 col-md-12 p-t-10 p-b-10">
              <div className="file-upload-header">{polyglot.t("Upload additional information")}</div>
              <div className="file-upload-header-description p-t-5 p-b-15">
                {polyglot.t("(E.g. employee contract, consent, etc.)")}
              </div>
              <div className="file-upload-header-description f-t-14 p-b-15 text-color">
                {polyglot.t(
                  "Note: Maximum number of files that can be uploaded is 5 (Max size : 100 MB each) ",
                )}
              </div>
              <div id="dfed-custodian-personal">
                <Dropzone
                  disabled={this.textFieldDIsableHandler()}
                  onFilesAdded={(e) => this.onDrop(e)}
                ></Dropzone>
              </div>
            </div>
            <div className="col-sm-12 col-md-12">
              <div className="">
                {this.state.formData.CRT_FILE &&
                  this.state.formData.CRT_FILE.length > 0 &&
                  this.state.formData.CRT_FILE.map((acceptedFile, index) => (
                    <div className="uploadedFileList m-b-6">
                      {acceptedFile.name.split(",").length > 1
                        ? acceptedFile.name.split(",")[1]
                        : acceptedFile.name}
                      &nbsp;&nbsp;
                      {/* Condition for, if files is no avalible in formdata, disable the download icon */}
                      {this.state.formData.files && this.state.formData.files !== "undefined" ? (
                        <img
                          src={download}
                          alt={download}
                          key={index}
                          onClick={this.downloadCustodianById.bind(
                            this,
                            acceptedFile.name.split(",")[0],
                            index,
                          )}
                          className=" cursor"
                        ></img>
                      ) : null}
                      <div className="fileDeleteIcon">
                        <button
                          disabled={this.textFieldDIsableHandler()}
                          onClick={this.deleteUploadedFile.bind(this, acceptedFile.name.split(",")[0], index)}
                          className="cursor"
                        >
                          <img
                            src={closeIcon}
                            alt={this.polyglot.t("Remove file")}
                            aria-label={this.polyglot.t("Remove file")}
                          />
                        </button>
                      </div>
                    </div>
                  ))}
                {this.state.requestProgress && <div className="col-md-10 p-r-0">{this.renderProgress()}</div>}
              </div>
              {this.state.formData.CRT_FILE && this.state.formData.CRT_FILE.length === 5 && (
                <div className="text-danger">
                  Maximum file upload limit is 5 you crossed that limit. sorry you can't upload more.
                </div>
              )}
              {this.state.filesize != null && this.state.filesize > 104857600 && (
                <div
                  className="text-danger"
                  aria-label={this.polyglot.t("Maximum file upload size is 100 MB only")}
                >
                  {this.polyglot.t("Maximum file upload size is 100 MB only")}
                </div>
              )}
            </div>
          </div>
          &nbsp;
          <div className="row m-l-0 m-r-0">
            <div className="col-sm-6 col-md-6 p-l-15">
              <ButtonPrimary
                disabled={this.state.saveButtonDisabled}
                onClick={this.saveFormData.bind(this)}
                ariaLabel={this.polyglot.t("Save custodian")}
              >
                {this.polyglot.t("Save custodian")}
              </ButtonPrimary>
            </div>
          </div>
          {this.props.isCustodianEditableAfterProcessed && (
            <div className="row m-l-0 m-r-0">
              <div className="file-upload-header-description f-s-14 p-b-15 text-color p-l-16">
                {polyglot.t("* You Can't update this custodian. it is already processed.")}
              </div>
            </div>
          )}
        </Form>
      </div>
    );
  }
}

export default GroupCustodianComponent;
