import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";

import { polyglotLoader } from "../../../Services/Shared/Translate";

const initialOptions = {
  colors: ["#800000", "#B22222", "#FF0000"],
  dataLabels: {
    enabled: false,
  },
  legend: {
    formatter: function (val, opts) {
      return val;
    },
    position: "bottom",
  },
  labels: [],
};

function ProgressChart({ rawData }) {
  const polyglot = polyglotLoader();
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState(initialOptions);

  // build chart data
  const buildChartData = () => {
    let label = [];
    let seriesData = [];
    for (const dataObj of rawData) {
      const translatedCategory = polyglot.t(dataObj.Category);
      label.push(translatedCategory);
      seriesData.push(parseInt(dataObj.COUNT));
    }
    const optionsClone = options;
    optionsClone.labels = label;
    setSeries(seriesData);
    setOptions(optionsClone);
  };

  // runs when raw data is updated
  useEffect(() => {
    buildChartData();
  }, [rawData]);
  return (
    <div className="donut req-donut">
      {series.length === 0 && <Chart options={options} series={series} type="donut" width="220" />}
      {series.length && <Chart options={options} series={series} type="donut" width="230" />}
    </div>
  );
}

export default ProgressChart;
