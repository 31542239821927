import React, { useContext } from "react";

import Solution from "./Solution";
import NoAccess from "../../../Assets/Components/NoAccess";
import { hasViewPermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";

// RENDERS ON queenbase/solutions/solution
export default (props) => {
  // DATA FROM CONTEXT
  const { getCurrentUserPermissions, loadingRoles } = useContext(DataContext);
  const currentUserPermissions = getCurrentUserPermissions();
  return (
    <>
      {/* RENDERS COMPONENT IF USER HAS VIEW PERMISSION */}
      {hasViewPermission(currentUserPermissions) && <Solution {...props} />}
      {/* RENDERS 'NO ACCESS' IF USER DOES NOT HAVE VIEW PERMISSION */}
      {!loadingRoles && !hasViewPermission(currentUserPermissions) && <NoAccess />}
    </>
  );
};
