import React, { useContext, useEffect, useState } from "react";

import UserForm from "./UserForm";
import AdminService from "../../../Services/QueenBase/Admin/AdminServices";
import UserService from "../../../Services/QueenBase/Users/UserService";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";

// CONTAINER WHICH CONTAINS USER FORM
const UserFormContainer = (props) => {
  const { getUserLanguage, userLanguage, fetchCountriesList, formGotClosed } = useContext(DataContext);
  const { navigateBack, userCount } = props;
  var polyglot = polyglotLoader(getUserLanguage());
  const [countries, setCountries] = useState([]);
  const [formError, setFormError] = useState("");
  const [positions, setPositions] = useState([]);
  const [customcreate, setCustomcreate] = useState("");
  const [customselect, setCustomselect] = useState("");
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [prefix, setPrefix] = useState([]);
  const [roles, setRoles] = useState([]);

  // FETCH POSITION COUNTRY FROM API
  async function getPositionCountry() {
    var res = await UserService.get().getPositionCountry();
    if (res.CODE) {
      setCountries(res.countries);
      res.positions.map((each, index) => {
        each.value = each.positionid;
        each.label = each.name;
        delete each.positionid;
      });
      setPositions(res.positions);
    } else {
      displayToastMessage(res.MSG, "error");
    }
  }

  async function getRoles() {
    const res = await AdminService.get().GetUsersRoles();
    if (res.CODE) {
      setRoles(res.data);
    } else {
      displayToastMessage(res.MSG, "error");
    }
  }

  const language = getUserLanguage();
  // FETCH PRIORITY FROM API
  async function getPrefix(language) {
    var res = await UserService.get().getPrefixNames(language);
    if (res.CODE) {
      setPrefix(res.prefixNames);
    } else {
      displayToastMessage(polyglot.t(res.MSG), "error");
    }
  }

  // RUNS ON LOAD
  useEffect(() => {
    getPositionCountry();
    getRoles();
  }, []);

  useEffect(() => {
    getPrefix(language);
  }, [language]);

  // get countries from api on language change
  const getCountries = async () => {
    const countriesList = await fetchCountriesList();
    setCountries(countriesList);
  };
  // Runs when user language got updated
  useEffect(() => {
    getCountries();
  }, [userLanguage]);

  // CREATE USER FUNCTION ON SUBMITTING FORM
  const createUser = async (user) => {
    setCustomcreate(user.createcustom);
    setCustomselect(user.selectcustom);
    user.positionid = user.positionid.label;
    Object.assign(user, { createcustom: customcreate });
    Object.assign(user, { selectcustom: customselect });
    setDisableSubmit(true);
    const res = await UserService.get().createUser({ ...user, istemp: user.istemp ? 1 : 0 });
    if (res.CODE) {
      displayToastMessage(polyglot.t("user_created"), "success");
      navigateBack();
      formGotClosed();
    } else {
      setFormError(res.MSG);
      displayToastMessage(res.MSG, "error");
      setDisableSubmit(false);
    }
  };

  return (
    <UserForm
      onSubmit={createUser}
      countries={countries}
      formError={formError}
      positions={positions}
      setCustomselect={setCustomselect}
      setCustomcreate={setCustomcreate}
      userCount={userCount}
      disableSubmit={disableSubmit}
      setDisableSubmit={setDisableSubmit}
      roles={roles}
      prefix={prefix}
    />
  );
};

export default UserFormContainer;
