/* eslint-disable no-mixed-spaces-and-tabs */
import { Avatar, Panel, Tooltip } from "appkit-react";
import PropTypes from "prop-types";
import React, { useState, useContext, useEffect } from "react";
import { withRouter } from "react-router-dom";

import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import LinkText from "../../../Assets/Components/LinkText/LinkText";
import { deleteFill, rightChevronFill } from "../../../Assets/Icons";
import icongroup from "../../../Assets/Icons/avatar-outline.svg";
import Notes from "../../../Assets/Icons/comment-outline.svg";
import chevron from "../../../Assets/Icons/down-chevron-outline.svg";
import Accordion from "../../../Assets/Icons/up-chevron-outline.svg";
import information from "../../../Assets/Images/QueenBase/Root/icon-info.svg";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/QueenBase/Root/loading-spin.svg";
import edit from "../../../Assets/Images/QueenBase/Root/pencil-outline.svg";
import { hasEditPermission, hasDeletePermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";
import {
  getDisplayDate,
  setProjectID_Of_solution_module,
  setProjectName_Of_solution_module,
  setSolutionID_Of_solution_module,
} from "../../../Services/Shared/Handlers";
import { CSS_SEARCH, DFED_HOME, DF_HOME, GI_HOME } from "../../../Constants/Path";
import httpRequest from "../../../Services/Shared/HttpClient";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";
// VIEW PROJECT COMPONENT
function ViewProject({ history, Item, index, onView, onEdit, deleteProjectModule }) {
  const [show] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedProjectIDToExpand, setSelectedProjectIDToExpand] = useState(null);
  const [projectSolutions, setProjectSolutions] = useState(null); // value updates when user expands a project
  // const [showNotes, setShowNotes] = useState(false);
  var polyglot = polyglotLoader();

  const HttpRequest = new httpRequest();

  const { getCurrentUserPermissions, getProjectPermissions } = useContext(DataContext);
  const currentUserPermissions = getCurrentUserPermissions();
  // VIEW PROJECT NAME ON HOVER
  const TooltipProjectName = (Item) => {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#000",
                  textTransform: "capitalize",
                }}
              >
                {Item.NAME}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  // NAVIGATE TO SOLUTIONS OF THE PROJECT
  const navigate = (EachSolution, project) => {
    let path = EachSolution.ROUTEPATH;
    const solutionID = EachSolution.SOLUTION_ID;
    setSolutionID_Of_solution_module(solutionID);
    setProjectID_Of_solution_module(project.PROJECTID);
    setProjectName_Of_solution_module(project.NAME);
    if (EachSolution.ROUTEPATH === "/df/home") {
      path = DF_HOME;
      history.push(path);
    }
    // navigate to Connected eDiscovery
    if (EachSolution.NAME === "Connected eDiscovery") {
      path = DFED_HOME;
      let data = { selectedProjectId: project.PROJECTID };
      history.push({ pathname: path, state: data });
    }
    if (EachSolution.NAME === "Connected Global Intelligence") {
      path = GI_HOME;
      history.push(path);
    }
    // navigate to Connected Risk Intelligence
    if (EachSolution.ROUTEPATH === "/ri/home") {
      path = "/ri/home";
      history.push(path);
    }
    if (EachSolution.ROUTEPATH === "/css/search") {
      path = CSS_SEARCH;
      history.push(path);
    }
    if (EachSolution.ROUTEPATH === "/grc/home") {
      path = "/grc/home";
      history.push(path);
    }
  };

  // GET BASE64 STRING WITH HEAD
  const getBase64WithHead = (base64Body) => {
    const base64Head = "data:image/png;base64";
    return `${base64Head}, ${base64Body}`;
  };

  // get project details of the expanded project
  const callProjectDetails = async () => {
    try {
      setIsLoading(true);
      let data = await HttpRequest.post("/getProjectDetails", { projectid: selectedProjectIDToExpand });
      setIsLoading(false);
      if (data.CODE) {
        setProjectSolutions(data.data[0].SOLUTION);
        setSelectedProjectIDToExpand(null);
      } else {
        displayToastMessage(data.MSG, "error");
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // run only when user toggles the expand view
  useEffect(() => {
    if (selectedProjectIDToExpand) {
      callProjectDetails();
    }
  }, [selectedProjectIDToExpand]);

  // empty unwanted records on state when view is collapsed
  useEffect(() => {
    // if view is collapsed
    if (!Item.isview) {
      setProjectSolutions(null);
      setSelectedProjectIDToExpand(null);
    } else {
      // if view is expanded
      // need to call API to get solutions
      setSelectedProjectIDToExpand(Item.PROJECTID);
    }
  }, [Item.isview]);

  // toggle expand/collapse of view project
  const toggleExpand = () => {
    try {
      // update the isview in parent component
      onView(Item, index);
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // DISPLAY USER IMAGES
  const displayUserImages = (projectuserimages) => {
    if (projectuserimages) {
      return projectuserimages.map((list, index) => {
        if (index <= 3) {
          return (
            <Avatar
              key={index}
              size="large"
              className="project-users-phots"
              title={list.FIRSTNAME}
              src={
                <img
                  alt={"user"}
                  className="view-project-avatar user-image"
                  src={list.PROJECTUSERIMAGE ? `${getBase64WithHead(list.PROJECTUSERIMAGE)}` : icongroup}
                />
              }
            />
          );
        }
      });
    }
    return (
      <Avatar
        size="large"
        className="project-users-phots"
        src={<img alt={icongroup} className="view-project-avatar user-image" src={icongroup} />}
      />
    );
  };

  // DISPLAY SOLUTION CARDS
  const displaySolutionCard = (Item) => {
    const parsedSolutions = projectSolutions ? JSON.parse(projectSolutions) : [];
    if (Item.isview === 1 && parsedSolutions.length > 0) {
      return (
        <>
          <div className="border-top a-border-light"></div>
          {parsedSolutions.map((sol, index) => {
            return (
              <div key={index}>
                <div className="row padding-1">
                  <div className="col-md-7">
                    <div className="cardbody MilestoneCardBorder" style={{ borderLeftColor: sol.COLOR }}>
                      <div className="card-text CardText">
                        <div className="d-flex ModulePosition">
                          <div className="col-md-8 view-solution-center-margin">
                            <div className="project-created-users">{sol.NAME}</div>
                          </div>
                          <div
                            className="col-md-3 view-solution-center-margin text-right viewprjctlist"
                            style={{ marginTop: "0.7rem" }}
                          >
                            {/* <span className="project-start view-solution-center-margin cursor" onClick={() => navigate(sol, Item)}>{polyglot.t("Go To")} <span className='appkiticon icon-right-chevron-fill'></span></span> */}
                            <LinkText
                              onClick={() => navigate(sol, Item)}
                              ariaLabel={`${polyglot.t("Go To")} ${sol.NAME}`}
                            >
                              {polyglot.t("Go To")}
                              <span className={`appkiticon ${rightChevronFill}`} />
                            </LinkText>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </>
      );
    }
    if (Item.isview && isLoading) {
      return (
        <LoadingSpinner
          width={44}
          height={44}
          fill={"#ef3829"}
          style={{ display: "flex", marginTop: "0", marginLeft: "47%" }}
        />
      );
    }
  };

  return (
    <div>
      {
        <div className="row view-center mr-0">
          <div className="col-md-8 p-0">
            <Panel className="a-panel-project" style={{ cursor: "pointer" }} onClick={toggleExpand}>
              <div className="a-text-heading  a-mb-10">
                <div className="row">
                  <div className="col-md-3 ">
                    <Tooltip content={TooltipProjectName(Item)} placement="top">
                      <div className="project-title-overflow f-acc view-center-name project-name">
                        {Item.NAME}
                      </div>
                    </Tooltip>
                  </div>

                  <div className="col-md-3 m-auto">
                    <div>{getDisplayDate(Item.STARTDATE)}</div>
                  </div>
                  <div className="col-md-4 m-auto">
                    <div className="view-center-imssage">{displayUserImages(Item.projectuserimages)}</div>
                  </div>
                  <div className="col-md-2 m-auto">
                    <div className="row d-flex justify-content-around">
                      {hasEditPermission(currentUserPermissions) &&
                        hasEditPermission(getProjectPermissions(Item.PROJECTID)) && (
                          <ButtonIcon
                            iconImg={edit}
                            onClick={() => onEdit(Item, index)}
                            alt={polyglot.t("edit")}
                            ariaLabel={polyglot.t("Edit project")}
                            title={polyglot.t("Edit")}
                          />
                        )}
                      {hasDeletePermission(currentUserPermissions) &&
                        hasDeletePermission(getProjectPermissions(Item.PROJECTID)) && (
                          <ButtonIcon
                            iconName={deleteFill}
                            onClick={() => deleteProjectModule(Item)}
                            alt={polyglot.t("delete")}
                            ariaLabel={polyglot.t("Delete project")}
                            title={polyglot.t("Delete")}
                          />
                        )}
                      <ButtonIcon
                        imgWidth={16}
                        iconImg={Item.isview ? Accordion : chevron}
                        onClick={toggleExpand}
                        alt={Item.isview ? "expand" : "close"}
                        ariaLabel={polyglot.t("Expand project")}
                        title={!Item.isview ? polyglot.t("Expand") : polyglot.t("Collapse")}
                      />
                    </div>
                  </div>
                </div>
                {displaySolutionCard(Item)}

                {Item.NOTES === ""
                  ? null
                  : Item.isview === 1 && (
                      <div>
                        <div className="a-py-10 border-top a-border-light a-mt-20"></div>
                        <div className="col-md-row view_project_notes">
                          <img width={16} height={16} alt={Notes} src={Notes} />
                          &nbsp;
                          <span className="font-weight-normal">{Item.NOTES}</span>
                          {Item.isnote === 1 && (
                            <div className="mt-2 project-notes">
                              <p id="project_desc">
                                {Item.NOTES === ""
                                  ? polyglot.t("No notes given to this project")
                                  : Item.NOTES}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
              </div>
            </Panel>
            {show ? (
              <div className="mt-4 mb-4">
                <div className="col-md-1 project-inform-pic pl-0">
                  <img src={information} alt={icongroup} />
                </div>
                <div className=" col-md-8 project-inform-row pl-0">
                  <span className="project-inform-desc">
                    {polyglot.t("We will inform you as soon as possible when")} <b>Solution X</b>{" "}
                    {polyglot.t("is activated and you can edit the project to set up the solution")}
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="mb-3"></div>
        </div>
      }
    </div>
  );
}
ViewProject.propTypes = {
  Item: PropTypes.object,
  index: PropTypes.number,
  onView: PropTypes.func,
  onEdit: PropTypes.func,
  deleteProjectModule: PropTypes.func,
  history: PropTypes.object,
};

export default withRouter(ViewProject);
