import "../../../../Assets/Css/QueenBase/CompanyCenter/CompanyCenter.css";
import { Input } from "appkit-react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { DataContext } from "../../../../Services/Shared/Store";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import GetPriceInGermanFormat from "../../Root/GetPriceInGermanFormat";
import BasePackageDetailsPanel from "../Panels/BasePackageDetailsPanel";

function BasePackageTemporaryUsersCard({
  packageInfo,
  onSubmit,
  hasCreatePermission,
  initialValues,
  validationSchema,
}) {
  const { getUserLanguage } = useContext(DataContext);
  const polyglot = polyglotLoader(getUserLanguage());

  return (
    <Formik onSubmit={onSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {(props) => {
        const { values, isSubmitting, handleChange, handleSubmit, errors, touched, dirty } = props;
        return (
          <BasePackageDetailsPanel
            title={polyglot.t("Temporary  users")}
            footer={[
              hasCreatePermission && (
                <ButtonPrimary
                  onClick={handleSubmit}
                  ariaLabel={polyglot.t("Add temporary users")}
                  disabled={
                    isSubmitting ||
                    !dirty ||
                    !(Object.keys(errors).length === 0 && errors.constructor === Object)
                  }
                >
                  {polyglot.t("Add temporary users")}
                </ButtonPrimary>
              ),
            ]}
          >
            <div className="users-list">
              <p className=" f-acc-B">
                {polyglot.t("Cost based on your active package")}
                &nbsp;
                <b>
                  &apos;{packageInfo.name}&apos;: &nbsp;
                  <br />
                  {GetPriceInGermanFormat(`${packageInfo.temporaryuserprice}`)}
                </b>
                &nbsp;{polyglot.t("per user")}&nbsp;
                {polyglot.t("Validfor", {
                  name: packageInfo.noofmonths,
                })}
              </p>
              <>
                <div>
                  <div className="col-md-12">
                    <div className="row">
                      <div className="col-md-12 p-0">
                        <Input
                          className=" user-amount-field mb-0"
                          placeholder={polyglot.t("Number of users")}
                          value={values.tempCount}
                          onChange={handleChange("tempCount")}
                          type="number"
                          aria-label={polyglot.t("Number of users")}
                        />
                      </div>
                      <div className="col-md-12 p-0">
                        {errors.tempCount && touched.tempCount ? (
                          <div className="error-cc pt-0">{errors.tempCount}</div>
                        ) : (
                          null &&
                          errors.tempCount &&
                          touched.tempCount && <span className="error-cc">{errors.tempCount}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="mt-2">
                    <div className="subtitle_two_title_qb">
                      {values.tempCount > 0 ? (
                        <div className="project-title-overflow f-acc view-center-name project-name p-0">
                          {GetPriceInGermanFormat(`${values.tempCount * packageInfo.temporaryuserprice}`)}
                        </div>
                      ) : (
                        <div className="project-title-overflow f-acc view-center-name project-name p-0">
                          €0
                        </div>
                      )}
                    </div>
                    <div className="f-acc-B num-test-user ">
                      {polyglot.t("Validfor", {
                        name: packageInfo.noofmonths,
                      })}
                    </div>
                  </div>
                </div>
              </>
            </div>
          </BasePackageDetailsPanel>
        );
      }}
    </Formik>
  );
}

BasePackageTemporaryUsersCard.propTypes = {
  packageInfo: PropTypes.object,
  onSubmit: PropTypes.func,
  hasCreatePermission: PropTypes.bool,
  initialValues: PropTypes.object,
  validationSchema: PropTypes.object,
  values: PropTypes.object,
  isSubmitting: PropTypes.bool,
  handleChange: PropTypes.func,
  handleSubmit: PropTypes.func,
  errors: PropTypes.object,
  touched: PropTypes.object,
  dirty: PropTypes.bool,
};

export default BasePackageTemporaryUsersCard;
