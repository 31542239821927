import React, { useContext, useEffect } from "react";
import Loadable from "react-loadable";
import LoadingIndicator from "../../../Components/QueenBase/Root/LoadingIndicator";
import NoAccess from "../../../Assets/Components/NoAccess";
import { DataContext } from "../../../Services/Shared/Store";
import { hasViewPermission } from "../../../Services/Shared/RoleHandler";
import ProtectedRoute from "../../../Routes/ProtectedRoute";
import { getProjectID_Of_solution_module } from "../../../Services/Shared/Handlers";
// importing admin css file for gi
import "../../../Assets/Css/COMMON/Admin.css";

// RENDERS ON PATH /df/dashboard
export default (props) => {
  const { checkAndFetchRole, getCurrentUserPermissions } = useContext(DataContext);
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    const projectId = getProjectID_Of_solution_module();
    const screenName = "Admin";
    checkAndFetchRole(screenName, "Connected Global Intelligence", projectId);
  }, []);
  const currentUserPermissions = getCurrentUserPermissions();
  return (
    <>
      {/* RENDER PATH IF USER HAS PERMISSION TO VIEW */}
      {hasViewPermission(currentUserPermissions) && (
        <ProtectedRoute
          path="/"
          component={Loadable({ loader: () => import("./Admin"), loading: LoadingIndicator })}
        />
      )}
      {/* SHOW 'NO ACCESS' IF USER DOES NOT HAVE PERMISSION TO VIEW */}
      {!hasViewPermission(currentUserPermissions) && <NoAccess />}
    </>
  );
};
