/* eslint-disable react/prop-types */
import React, { useEffect } from "react";

import { COMPANY_CENTER_VIEW_PATH } from "../../../Constants/Path";
import CompanyCenterRouter from "../../../Routes/CompanyCentreRoute";

// WILL RENDER ON /queenbase/companycenter
// RENDERS COMPANY CENTER ROUTER
function CompanyCenterContainer(props) {
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    if (props.location.pathname === "/queenbase/companycenter") {
      props.history.push(COMPANY_CENTER_VIEW_PATH);
    }
  });
  return <CompanyCenterRouter />;
}

export default CompanyCenterContainer;
