import React, { useState } from "react";

import "./index.css";
import AssessmentQuestionPreview from "./AssessmentQuestionPreview";

const PreviewQuestionTree = ({ data = [], selectedId }) => {
  return (
    <div className="d-tree">
      <ul className="d-flex d-tree-container flex-column">
        {data.map((tree, i) => (
          <TreeNode key={i} node={tree} selectedId={selectedId} />
        ))}
      </ul>
    </div>
  );
};
const TreeNode = ({ node, handleChanged, selectedId }) => {
  const [childVisible, setChildVisiblity] = useState(true);
  const [getQuestionCount, setGetQuestionCount] = useState(0);
  const CurrentHandler = () => {
    setChildVisiblity((v) => !v);
  };
  const hasChild = node.children ? true : false;

  const loadIcon = () => {
    if (node.depth == 2) {
      return (
        <span
          className="appkiticon icon-news-outline  custom-grc-icon"
          onClick={(e) => setChildVisiblity((v) => !v)}
        />
      );
    } else {
      return (
        <span
          className="appkiticon icon-folder-closed-outline custom-grc-icon"
          onClick={(e) => setChildVisiblity((v) => !v)}
        />
      );
    }
  };
  const onGetQuestionCount = (questionnCount) => {
    setGetQuestionCount(questionnCount);
  };
  return (
    <li className="d-tree-node border-0" key={node.value}>
      <div className="d-flex">
        <div className={`col d-tree-head p-0 ${childVisible ? "selected-node" : ""}`}>
          {node.depth === 2 ? (
            <>
              {getQuestionCount > 0 && loadIcon()}
              <div
                key={node.value}
                className={`d-inline  questionaire_text pl-2  ${childVisible ? "selected-node" : ""}`}
              >
                {getQuestionCount > 0 ? `${node.label}(${getQuestionCount})` : ""}
                <div className={getQuestionCount > 0 ? "card" : ""}>
                  <div className={getQuestionCount > 0 ? "card-body" : ""}>
                    <AssessmentQuestionPreview
                      parentId={node.value}
                      selectedId={selectedId}
                      setQuestionCount={onGetQuestionCount}
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              {loadIcon()}
              <div
                key={node.value}
                className={`d-inline questionaire_text pl-2  ${childVisible ? "selected-node" : ""}`}
              >
                {node.label}
              </div>
            </>
          )}
        </div>
      </div>
      {hasChild && childVisible && (
        <div className="d-tree-content">
          <ul className="d-flex d-tree-container flex-column">
            <PreviewQuestionTree data={node.children} selectedId={selectedId} />
          </ul>
        </div>
      )}
    </li>
  );
};
export default PreviewQuestionTree;
