import { Divider, Panel } from "appkit-react";
import PropTypes from "prop-types";
import React from "react";

import "../../../../Assets/Css/QueenBase/CompanyCenter/CompanyCenter.css";

function BasePackageDetailsPanel(props) {
  const { title, footer, period } = props;
  return (
    <Panel className="panel-content-1">
      <div className="row">
        {period ? (
          <div className="col-md-12">
            <div className="cc-title pl-0 pr-0 ">
              <label className=" subtitle_two_title_qb">{title}</label>
              <label className="packageDetailsCard">{period}</label>
            </div>
          </div>
        ) : (
          <div className="cc-title subtitle_two_title_qb">{title}</div>
        )}
      </div>
      <Divider />
      <Divider />
      <div className="companycenter-card-body">{props.children}</div>
      <div className="d-flex justify-content-between a-mt-20 align-items-center users-list">
        {footer.map((f) => f)}
      </div>
    </Panel>
  );
}

BasePackageDetailsPanel.propTypes = {
  title: PropTypes.string,
  footer: PropTypes.array,
  period: PropTypes.string,
  children: PropTypes.element,
};

export default BasePackageDetailsPanel;
