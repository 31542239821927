/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unreachable */
import { Panel } from "appkit-react";
import { Formik } from "formik";
import PropTypes from "prop-types";

import displayToastMessage from "../Root/displayToastMessage";
import moment from "moment";
import React, { useEffect, useRef, useState, useContext } from "react";
import { polyglotLoader } from "../../../Services/Shared/Translate";

import { withRouter } from "react-router-dom";
import { date, object, string, array } from "yup";

import { isStep1Valid } from "./handler";
import ConfirmBox from "./ConfirmBox";
import PrevNextButton from "./PrevNextButton.js";
import ProjectForm from "./ProjectForm";
import ProjectModuleUserWrapper from "./ProjectModuleUser";
import ProjectSolution from "./ProjectSolution";
import ProjectSolutionMileStone from "./ProjectSolutionMileStone";
import SignContractInProjectForm from "./SignContractInProjectForm";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";
import close from "../../../Assets/Images/QueenBase/Projects/icon-x.svg";
import * as path from "../../../Constants/Path";
import { FIELD_REGEX, FIELD_LENGTH_REGEX } from "../../../Constants/regex";
import ProjectService from "../../../Services/QueenBase/Projects/ProjectService";
import { getUserIDFromSession } from "../../../Services/Shared/Authenticate";
import { DataContext } from "../../../Services/Shared/Store";
import { textFieldValidation } from "../../../Services/Shared/formHandlers";

// PROJECT FORM WHICH IS USED TO CREATE AND UPDATE PROJECT
function CreateProject(props) {
  const { history, selectedProject, projectFormObj } = props;
  const { getUserLanguage, isFormOpen, formGotOpened, formGotClosed } = useContext(DataContext);
  const [cancelAPIs, setCancelAPIs] = useState(false);

  const currentUserID = getUserIDFromSession();
  var polyglot = polyglotLoader();
  //FORMIK
  const initialProjectFormValues = {
    name: "",
    startdate: "",
    enddate: null,
    sponserList: [],
    leadList: [],
    notes: "",
  };

  const initialProjectSolutionValues = {
    selectedSolutions: [], // array of solution ids
    selectedNotActivatedSolutions: [], // array of not activated solution ids
  };
  const initialSolutionMilestoneValues = {
    solutionMilestones: [], // json with solution id & milestones array
  };
  const initialFormSupportedData = {
    // data from API calls are stored here
    users: [],
    activatedSolutionList: [],
    pendingSolutionList: [],
    notActivatedSolutionList: [],
    solutionList: [],
  };
  const initialModuleUserValues = {
    solutionList: [], // solutions with assigned user details
    currentTab: 0,
  };
  const initialShowSignContract = { show: false, solutionId: "" };
  const initialNotifyValues = { noofdays: 0, ischecked: false };

  const [currentPage, setCurrentPage] = useState(1);
  const [projectFormValues, setProjectFormValues] = useState(initialProjectFormValues);
  const [leadList, setLeadList] = useState([]);
  const [projectSolutionValues, setProjectSolutionValues] = useState(initialProjectSolutionValues);
  const [solutionMilestoneValues, setSolutionMilestoneValues] = useState(initialSolutionMilestoneValues);
  const [moduleUserValues, setModuleUserValues] = useState(initialModuleUserValues);
  const [formSupportedData, setFormSupportedData] = useState(initialFormSupportedData);
  const [showSignContract, setShowSignContract] = useState(initialShowSignContract);
  const [notifyObj, setNotify] = useState(initialNotifyValues);
  const [showEditForm, setShowEditForm] = useState(false);
  const [openConfirmBox, setOpenConfirmBox] = useState(false);

  const [projectNameError, setProjectNameError] = useState(null);
  const [disableSubmit, setDisableSubmit] = useState(false);

  let isCreateForm = true;
  if (selectedProject) {
    isCreateForm = false;
  }

  // MILESTONE LIST OF A SOLUTION IS GENERATED HERE BY TAKING SOLUTION ID
  const getMilestoneListOfSolution = (solutionId) => {
    const milestoneList = [];
    let milestoneObj = {};
    if (!isCreateForm) {
      projectFormObj.step3.forEach((element) => {
        if (element.id === solutionId) {
          element.milestoneList.forEach((item) => {
            milestoneObj = { ...item };
            milestoneObj.deadline = moment(new Date(item.deadline));
            milestoneObj.deadlinedate = new Date(item.deadline).toLocaleDateString();
            milestoneObj.isedit = false;
            milestoneList.push(milestoneObj);
          });
        }
      });
    }
    return milestoneList;
  };

  // ON EDIT, WE SET DATA OF THE FORM IN THIS FUNCTION
  const setEditFormData = () => {
    // -- set step1 data
    const step1Clone = { ...projectFormObj.step1 };
    let endDate = null;
    if (projectFormObj.step1.enddate) {
      endDate = moment(new Date(projectFormObj.step1.enddate));
    }
    step1Clone.enddate = endDate;
    step1Clone.startdate = moment(new Date(projectFormObj.step1.startdate));
    setProjectFormValues(step1Clone);
    setLeadList([...step1Clone.leadList]);

    // -- set step2 data
    const projectSolutionValuesClone = { ...projectSolutionValues };
    projectSolutionValuesClone.selectedSolutions = projectFormObj.step2.selectedSolutions;
    setProjectSolutionValues(projectSolutionValuesClone);

    // -- set step3 data
    const solutionMilestonesClone = [];
    let solutionMilestoneObj = {};
    projectFormObj.step3.forEach((item) => {
      solutionMilestoneObj = {};
      solutionMilestoneObj.id = item.id;
      solutionMilestoneObj.milestoneList = getMilestoneListOfSolution(item.id);
      solutionMilestonesClone.push(solutionMilestoneObj);
    });
    const solMilestoneValuesClone = { ...solutionMilestoneValues };
    solMilestoneValuesClone.solutionMilestones = solutionMilestonesClone;
    setSolutionMilestoneValues(solMilestoneValuesClone);

    // -- set notify data
    const initialNotifyValuesClone = { ...initialNotifyValues };
    initialNotifyValuesClone.noofdays = projectFormObj.notify.noofdays;
    initialNotifyValuesClone.ischecked = projectFormObj.notify.ischecked;
    setNotify(initialNotifyValuesClone);

    setShowEditForm(true);
  };

  // RUNS ON LOAD
  useEffect(() => {
    if (selectedProject) {
      setEditFormData();
    }
  }, []);

  // VALIDATION SCHEMA OF THE PROJECT FORM PRESENT ON STEP 1
  const validationSchema = object().shape({
    name: string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t("field_validaton"))
      .trim()
      .max(40, "Maximum character length is 40")
      .required(polyglot.t("* Name is required")),
    invalidProjectName: string(),
    startdate: object().required("Select the start date"),
    enddate: date().nullable(),
    sponserList: string(),
    leadList: array().required("select a project lead"),
    notes: string().matches(FIELD_REGEX, polyglot.t("field_validaton")).trim(),
    currentUserNotPresent: string(),
  });
  // NAVIGATE ON CLICKING PREVIOUS BUTTON
  const handlePrev = async () => {
    if (currentPage === 1) {
      setCurrentPage(1);
    } else if (currentPage === 3) {
      //third step
      const { isStep3Valid, isNotifyValid, invalidNotifyMsg } = await childRef2.current.validate();
      if (isStep3Valid & isNotifyValid) {
        setCurrentPage(currentPage - 1);
      } else if (!isStep3Valid) {
        displayToastMessage(polyglot.t("Please save all the unsaved changes"), "error");
      } else if (!isNotifyValid) {
        displayToastMessage(polyglot.t(invalidNotifyMsg), "error");
      }
    } else {
      setCurrentPage(currentPage - 1);
    }
  };
  // NAVIGATE TO SIGN CONTRACT OF A SOLUTION
  const navSign = (signContract) => {
    const setObj = {
      show: true,
      solutionId: signContract.solutionid,
    };
    setShowSignContract({ ...setObj });
    projectSolutionFieldChange("selectedNotActivatedSolutions", "CLEAR_ARRAY");
  };
  const childRef = useRef();
  const childRef1 = useRef();
  const childRef2 = useRef();

  // BEFORE SUBMITING DATA TO API, WE FORMAT THE DATA
  const formatStep3 = (jsonArray) => {
    const formattedArr = [];
    let formattedObj = {};
    jsonArray.forEach((obj) => {
      formattedObj = {
        id: obj.id,
        milestoneList: obj.milestoneList,
      };
      formattedArr.push(formattedObj);
    });
    return formattedArr;
  };
  // BEFORE SUBMITING DATA TO API, WE FORMAT THE DATA
  const formatStep4 = (jsonArray) => {
    const formattedArr = [];
    let formattedObj = {};
    jsonArray.forEach((obj) => {
      formattedObj = {
        id: obj.id,
        projectForm: {
          workStreamList: obj.projectForm.workStreamList,
          usersList: obj.projectForm.usersList,
          reviewersList: obj.projectForm.reviewersList,
        },
      };
      formattedArr.push(formattedObj);
    });
    return formattedArr;
  };

  // FETCH USER ROLE
  const { checkAndFetchRole } = useContext(DataContext);
  const fetchRoles = () => {
    const screenName = "Project";
    checkAndFetchRole(screenName, "Base");
  };

  // CLOSE FORM AND NAVIGATE TO VIEW PROJECTS
  const closebutton = () => {
    setCancelAPIs(true); // cancels the api calls made on step 4
    history.push({ pathname: path.VIEW_PROJECT_PATH });
    fetchRoles();
  };

  // ON RECEIVING MESSAGE FROM API, WE DISPLAY IT
  const displayAPIMsg = (apiResponse) => {
    if (apiResponse.CODE === 1) {
      displayToastMessage(polyglot.t(apiResponse.message), "success");
      if (isCreateForm) {
        formGotClosed();
        closebutton();
      } else {
        formGotClosed();
        closebutton();
      }
    } else {
      displayToastMessage(polyglot.t(apiResponse.message), "error");
      setDisableSubmit(false);
    }
  };

  // CREATE AND UPDATE PROJECT API
  const addProject = async () => {
    // NOTE: removed validation as per Chirag's requirement
    const valid = true;
    if (valid) {
      setDisableSubmit(true);
      const step1Data = { ...projectFormValues };
      delete step1Data["sponserList"];
      const formObj = {
        step1: step1Data,
        step2: {
          selectedSolutions: projectSolutionValues.selectedSolutions,
        },
        step3: formatStep3(solutionMilestoneValues.solutionMilestones),
        step4: formatStep4(moduleUserValues.solutionList),
        notify: notifyObj,
      };

      if (selectedProject) {
        // -- update project --
        const projectID = selectedProject.PROJECTID;
        const APIResponse = await ProjectService.get().updateProject(formObj, projectID);
        displayAPIMsg(APIResponse);
        formGotClosed();
      } else {
        // -- create project --
        const APIResponse = await ProjectService.get().createProject(formObj);
        displayAPIMsg(APIResponse);
        formGotClosed();
      }
    } else {
      displayToastMessage(polyglot.t("Add users to all roles and solutions"), "error");
    }
  };

  // ON CLICKING NEXT ON THE FORM
  const handleNext = async () => {
    if (currentPage === 4) {
      setCurrentPage(4);
    } else if (currentPage === 1) {
      //--first step--
      setCurrentPage(currentPage + 1);
    } else if (currentPage === 2) {
      //second step
      let valid = await childRef1.current.validate();

      if (valid) {
        setCurrentPage(currentPage + 1);
      } else {
        displayToastMessage(polyglot.t("Please activate the solution to proceed further"), "error");
      }
    } else if (currentPage === 3) {
      //third step
      const { isStep3Valid, isNotifyValid, invalidNotifyMsg } = await childRef2.current.validate();
      if (isStep3Valid & isNotifyValid) {
        setCurrentPage(currentPage + 1);
      } else if (!isStep3Valid) {
        displayToastMessage(polyglot.t("Please save all the unsaved changes"), "error");
      } else if (!isNotifyValid) {
        displayToastMessage(polyglot.t(invalidNotifyMsg), "error");
      }
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  // CHECK IF CURRENT LOGGEDIN USER IS ALREADY THERE ON LEADLIST
  const checkIfUserAlreadyExists = () => {
    let isUserExists = false;
    if (leadList.indexOf(currentUserID) >= 0) {
      isUserExists = true;
    }
    return isUserExists;
  };
  // ASSIGN VALUES TO PROJECT FORM LEAD LIST AFTER FETCHING USERS
  const assignLeadlistToProjectForm = (leadListClone) => {
    let leadListValue = [];
    if (leadListClone.length > projectFormValues.leadList.length) {
      leadListValue = leadListClone;
    } else {
      leadListValue = projectFormValues.leadList;
    }
    setProjectFormValues({ ...projectFormValues, leadList: leadListValue });
  };
  // ASSIGN CURRENT LOGGEDIN USER AS SPONSER OR LEAD
  const assignCurrentUserAsSponserOrLead = () => {
    const leadListClone = [...leadList];
    const isUserIdExists = checkIfUserAlreadyExists();
    if (!isUserIdExists) {
      leadListClone.push(currentUserID);
    }
    setLeadList(leadListClone);
    assignLeadlistToProjectForm(leadListClone);
  };

  // ASSIGN FETCHED DATA TO formSupportedData
  const setFetchedData = async (key, content) => {
    try {
      const formSupportedDataClone = { ...formSupportedData };
      switch (key) {
        case "getProjectSolutions":
          Object.keys(content).forEach((k) => {
            formSupportedDataClone[k] = content[k];
          });
          break;
        case "users":
          formSupportedDataClone[key] = content;
          if (isCreateForm) {
            assignCurrentUserAsSponserOrLead();
          }
          break;
        default:
          formSupportedDataClone[key] = content;
      }
      setFormSupportedData({ ...formSupportedDataClone });
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  /* CHECK IF PROJECT NAME ALREADY EXISTS
	PASSING: projectName: string
	*/
  const checkForDuplicateProjectName = async (key, value) => {
    if (key === "name") {
      var result = await ProjectService.get().CheckProjectName(value);
      if (result.data > 0) {
        setProjectNameError(true);
      } else {
        setProjectNameError(null);
      }
    }
  };
  // HANDLE CHAGNES ON STEP 1
  const projectFormFieldChange = (key, handleChange) => (event) => {
    const projectFormValuesClone = { ...projectFormValues };
    const value = event.target.value;
    projectFormValuesClone[key] = value;

    if (isCreateForm) {
      checkForDuplicateProjectName(key, value);
    }
    if (!isCreateForm) {
      if (key === "name") {
        const isChangeInProjectName = projectFormObj.step1.name === value ? false : true;
        if (isChangeInProjectName) {
          checkForDuplicateProjectName(key, value);
        } else {
          setProjectNameError(null);
        }
      }
    }

    setProjectFormValues({ ...projectFormValuesClone });
    handleChange(key)(event);
  };

  // HANDLE CHANGES ON STEP 2
  const projectSolutionFieldChange = (key, values) => {
    const projectSolutionValuesClone = { ...projectSolutionValues };
    if (key === "selectedSolutions") {
      const selectedSolutionsClone = [...projectSolutionValues.selectedSolutions];
      const itemIndex = selectedSolutionsClone.indexOf(values);
      if (itemIndex > -1) {
        // -- solution id already selected --
        if (isCreateForm) {
          selectedSolutionsClone.splice(itemIndex, 1);
        } else {
          // -- if solution is already there in projectFormObj.step2.selectedSolutions, do not remove it
          const isAlreadyPresent = projectFormObj.step2.selectedSolutions.indexOf(values);
          if (isAlreadyPresent >= 0) {
            displayToastMessage(this.polyglot.t("Cannot deselect a solution!!"), "info");
          } else {
            selectedSolutionsClone.splice(itemIndex, 1);
          }
        }
      } else {
        selectedSolutionsClone.push(values);
      }
      projectSolutionValuesClone[key] = selectedSolutionsClone;
    }
    if (key === "selectedNotActivatedSolutions" && values !== "CLEAR_ARRAY") {
      const selectedNotActivatedSolutionsClone = [...projectSolutionValues.selectedNotActivatedSolutions];
      // -- only single select i.e. only one id should be there in selectedNotActivatedSolutionsClone --
      const selectedSol = selectedNotActivatedSolutionsClone[0];
      selectedNotActivatedSolutionsClone.splice(0, 1);
      if (selectedSol !== values) {
        selectedNotActivatedSolutionsClone.push(values);
      }
      projectSolutionValuesClone[key] = selectedNotActivatedSolutionsClone;
    }
    if (key === "selectedNotActivatedSolutions" && values === "CLEAR_ARRAY") {
      projectSolutionValuesClone[key] = [];
    }
    setProjectSolutionValues(projectSolutionValuesClone);
    formGotOpened();
  };

  // GET DELETED IDS FROM THE GIVEN ARRAYS
  const getDeletedIDs = (newArr, currentArr) => {
    const deletedIDs = [];
    const keysCurrent = {};
    const keysNew = {};
    currentArr.forEach((item) => {
      keysCurrent[item.id] = item;
    });
    newArr.forEach((item) => {
      keysNew[item.id] = item;
    });
    currentArr.forEach((item) => {
      const obj = keysNew[item.id];
      if (!obj) {
        deletedIDs.push(item.id);
      }
    });
    return deletedIDs;
  };

  // GET VALID MILESTONE DATE
  const getValidMilestoneDate = (_deadlineDate) => {
    const deadlineDate = moment(_deadlineDate);
    const projectStartDate = moment(projectFormValues.startdate);

    const isBeforeStartDate = deadlineDate.isBefore(projectStartDate, "day");
    if (projectFormValues.enddate) {
      const projectEndDate = moment(projectFormValues.enddate);
      const isAfterEndDate = deadlineDate.isAfter(projectEndDate, "day");
      if (isBeforeStartDate || isAfterEndDate) {
        return null;
      }
      return deadlineDate;
    }

    if (!isBeforeStartDate) {
      return deadlineDate;
    }

    return null;
  };

  // GET VERIFIED MILESTONE LIST
  const getVerifiedMilestoneList = (milestoneList) => {
    const list = [];
    let obj = {};
    milestoneList.forEach((l) => {
      obj = Object.assign(l);
      // deadline = moment
      obj.deadline = getValidMilestoneDate(l.deadline);
      // deadlinedate = date string
      obj.deadlinedate = !getValidMilestoneDate(l.deadlinedate)
        ? null
        : new Date(getValidMilestoneDate(l.deadlinedate)).toLocaleDateString();
      obj.isedit = getValidMilestoneDate(l.deadline) === null ? true : false;
      list.push(obj);
    });
    return list;
  };
  // MODIFY SOLUTION MILESTONES
  const modifySolutionMilestones = (values, solutionMilestoneValuesClone) => {
    const solutionMilestones = [...solutionMilestoneValuesClone.solutionMilestones];
    let milestoneObj = {};
    const currentIDs = solutionMilestoneValues.solutionMilestones.map((item) => item.id);
    const deletedIDs = getDeletedIDs(values, solutionMilestoneValues.solutionMilestones);

    // -- check & add records --
    values.forEach((solution) => {
      milestoneObj = {
        id: solution.id,
        name: solution.name,
        title: solution.title,
        milestoneList: [],
      };
      const itemIndex = currentIDs.indexOf(solution.id);
      if (itemIndex >= 0) {
        // -- if exists, do nothing --
        return null;
      } else {
        // -- if not exists, add a record --
        solutionMilestones.push(milestoneObj);
      }
    });

    // -- check & remove records --
    const filtered = solutionMilestones.filter((item) => deletedIDs.indexOf(item.id) < 0);

    // -- modify key's values --
    filtered.map((item) => {
      values.forEach((solution) => {
        if (solution.id === item.id) {
          item.name = solution.name;
          item.title = solution.title;
        }
      });
    });

    const filterClone = [];
    let obj = {};
    filtered.forEach((item) => {
      obj = Object.assign(item);
      obj.milestoneList = getVerifiedMilestoneList(item.milestoneList);
      filterClone.push(obj);
    });
    return filtered;
  };

  // HANDLE CHANGES ON STEP 3
  const solutionMilestoneFieldChange = (key, values) => {
    const solutionMilestoneValuesClone = { ...solutionMilestoneValues };
    if (key === "getSolutionMilestones") {
      const modifiedSolutionMilestones = modifySolutionMilestones(values, solutionMilestoneValuesClone);
      solutionMilestoneValuesClone.solutionMilestones = modifiedSolutionMilestones;
    }
    if (key === "setSolutionMilestones") {
      solutionMilestoneValuesClone.solutionMilestones = values;
    }
    setSolutionMilestoneValues(solutionMilestoneValuesClone);

    const firstSolutionId =
      solutionMilestoneValuesClone.solutionMilestones.length > 0
        ? solutionMilestoneValuesClone.solutionMilestones[0].id
        : 0;
    moduleUserFieldChange("setCurrentTab", firstSolutionId);
  };

  // CHECK STEP 3 OF EDIT DATA
  const checkStep3OfEditData = (solutionId, solution) => {
    let solutionObj = {};
    const solutionIDsOfEditForm = projectFormObj.step3.map((element) => element.id);

    solutionObj.id = solutionId;
    solutionObj.name = solution.name;
    solutionObj.title = solution.title;
    if (solutionIDsOfEditForm.indexOf(solutionId) >= 0) {
      solutionObj.milestoneList = getMilestoneListOfSolution(solutionId);
    }

    return solutionObj;
  };

  // GET SELECTED SOLUTIONS LIST
  const getSelectedSolutionsList = () => {
    let selectedSolutionsList = [];
    let selectedSolutions = {};
    formSupportedData.activatedSolutionList.forEach((sol) => {
      if (projectSolutionValues.selectedSolutions.indexOf(sol.id) >= 0) {
        if (isCreateForm) {
          selectedSolutions = {
            id: sol.id,
            name: sol.name,
            title: sol.title,
          };
        } else {
          selectedSolutions = checkStep3OfEditData(sol.id, sol);
        }
        selectedSolutionsList.push(selectedSolutions);
      }
    });
    return selectedSolutionsList;
  };

  // GET USER NAME BY USER ID
  const getUserNameByID = (userId) => {
    let userName = "";
    const userObj = formSupportedData.users.filter((user) => user.userid === userId)[0];
    if (userObj) {
      userName = userObj.firstname + " " + userObj.lastname;
      return userName;
    } else {
      return false;
    }
  };
  // GET ASSIGNED USERS OF SOLUTION
  const getAssignedUsersOfSolution = (solutionId, key) => {
    const list = [];
    let listObj = {};
    if (isCreateForm) {
      return list;
    }
    projectFormObj.step4.forEach((item) => {
      if (item.id === solutionId) {
        item[key].forEach((userid) => {
          if (getUserNameByID(userid)) {
            listObj = {};
            listObj.value = userid;
            listObj.label = getUserNameByID(userid);
            list.push(listObj);
          }
        });
      }
    });
    return list;
  };

  // HANDLE CHANGES ON STEP 4
  const modifyUserSolutionList = (values, moduleUserValuesClone) => {
    const userSolutionList = [...moduleUserValuesClone.solutionList];
    let userSolutionObj = {};
    const currentIDs = moduleUserValues.solutionList.map((item) => item.id);
    const deletedIDs = getDeletedIDs(values, moduleUserValues.solutionList);

    values.forEach((solution) => {
      userSolutionObj = { ...solution };
      const itemIndex = currentIDs.indexOf(solution.id);
      if (itemIndex >= 0) {
        // -- if exists, do nothing --
        return null;
      } else {
        // -- if not exists, add a record --
        userSolutionObj.projectForm = {
          workStreamList: getAssignedUsersOfSolution(solution.id, "workStreamList"),
          workStreamUserList: [],
          usersList: getAssignedUsersOfSolution(solution.id, "usersList"),
          reviewersList: getAssignedUsersOfSolution(solution.id, "reviewersList"),
          userList: formSupportedData.users,
        };
        userSolutionList.push(userSolutionObj);
      }
    });

    // -- check & remove records --
    const filtered = userSolutionList.filter((item) => deletedIDs.indexOf(item.id) < 0);
    return filtered;
  };
  // GET CURRENT USERS BY SOLUTION ID
  const getCurrentUsersBySolutionId = (currentSolutionId) => {
    const currentUsersObj = {};
    moduleUserValues.solutionList.forEach((solution) => {
      if (solution.id === currentSolutionId) {
        currentUsersObj.workStreamList = solution.projectForm.workStreamList;
        currentUsersObj.usersList = solution.projectForm.usersList;
        currentUsersObj.reviewersList = solution.projectForm.reviewersList;
      }
    });
    return currentUsersObj;
  };
  // ASSIGN CURRENT USERS TO ALL SOLUTIONS
  const assignCurrentUsersToAllSolutions = (currentSolutionUsers) => {
    const solutionList = [];
    let solutionListObj = {};
    moduleUserValues.solutionList.forEach((solution) => {
      solutionListObj = { ...solution };
      solutionListObj.projectForm.workStreamList = currentSolutionUsers.workStreamList;
      solutionListObj.projectForm.usersList = currentSolutionUsers.usersList;
      solutionListObj.projectForm.reviewersList = currentSolutionUsers.reviewersList;
      solutionList.push(solutionListObj);
    });
    return solutionList;
  };
  // EMPTY USERS OF OTHER SOLUTIONS
  const emptyUsersOfOtherSolutions = (currentSolutionId, currentSolutionUsers) => {
    const solutionList = [];
    let solutionListObj = {};
    moduleUserValues.solutionList.forEach((solution) => {
      solutionListObj = { ...solution };
      if (solution.id === currentSolutionId) {
        solutionListObj.projectForm.workStreamList = currentSolutionUsers.workStreamList;
        solutionListObj.projectForm.usersList = currentSolutionUsers.usersList;
        solutionListObj.projectForm.reviewersList = currentSolutionUsers.reviewersList;
      } else {
        solutionListObj.projectForm.workStreamList = [];
        solutionListObj.projectForm.usersList = [];
        solutionListObj.projectForm.reviewersList = [];
      }
      solutionList.push(solutionListObj);
    });
    return solutionList;
  };

  // MODULE USER FIELD CHANGE
  const moduleUserFieldChange = (key, values) => {
    const moduleUserValuesClone = { ...moduleUserValues };
    if (key === "getSolutionList") {
      const modifiedUserSolutionList = modifyUserSolutionList(values, moduleUserValuesClone);
      moduleUserValuesClone.solutionList = modifiedUserSolutionList;
    }
    if (key === "currentUsersToAllSolutions") {
      const currentSolutionId = values.currentSolutionId;
      const currentSolutionUsers = getCurrentUsersBySolutionId(currentSolutionId);
      if (values.assignCurrentUsersToAllSolutions) {
        moduleUserValuesClone.solutionList = assignCurrentUsersToAllSolutions(currentSolutionUsers);
      } else {
        moduleUserValuesClone.solutionList = emptyUsersOfOtherSolutions(
          currentSolutionId,
          currentSolutionUsers,
        );
      }
    }
    if (key === "setCurrentTab") {
      moduleUserValuesClone.currentTab = values;
    }
    if (key === "setSolutionList") {
      moduleUserValuesClone.solutionList = values;
    }
    setModuleUserValues(moduleUserValuesClone);
    // formGotOpened();
  };

  // CHECK IF PROJECT NAME IS VALID
  const isProjectNameValid = (projectName) => {
    const validName = textFieldValidation(projectName);
    if (!validName) {
      /* NOTE: currently validating through regex */
    }
    return projectNameError ? polyglot.t("Project name already exists") : "";
  };
  // CHECK IF CURRENT USER EXISTS
  const isCurrentUserExists = (sponserList) => {
    if (leadList.includes(currentUserID)) {
      return "";
    }
    return "";
  };
  // MODIFY START DATE ERROR
  const modifyStartDateError = (startDateErr) => {
    if (startDateErr) {
      if (startDateErr.includes("null")) {
        return polyglot.t("Select the start date");
      }
    }
    return startDateErr;
  };

  // assign lead list to the step 1 of project form
  const getLeadListOnTheFly = () => {
    return projectFormValues.leadList;
  };

  // SAVE STEP 1 OF FORM ON CLICKING NEXT
  const saveStep1Data = (formObj) => {
    setProjectFormValues(formObj);
    handleNext();
  };

  const emptyEnddateIfNoStartdateAvailable = (values) => {
    // empty end date if start date is cleared
    if (!values.startdate) {
      return null;
    }
    return values.enddate;
  };

  // RENDER THE FORM
  const renderPage = () => {
    let projectFormErrors = {};
    switch (currentPage) {
      case 1:
        return (
          <Formik
            onSubmit={saveStep1Data}
            initialValues={projectFormValues}
            validationSchema={validationSchema}
          >
            {(prop) => {
              const { values, handleChange, handleSubmit, errors, touched, dirty } = prop;
              // FORMIK FORM - HANDLE FORMOPEN OR CLOSE STATUS
              const updateFormStatusBasedOnDirty = () => {
                if (dirty) {
                  formGotOpened();
                }
                if (!dirty) {
                  formGotClosed();
                }
              };
              updateFormStatusBasedOnDirty();
              projectFormErrors = { ...errors };
              projectFormErrors.startdate = modifyStartDateError(errors.startdate);
              projectFormErrors.invalidProjectName = isProjectNameValid(values.name);
              projectFormErrors.currentUserNotPresent = isCurrentUserExists(values.sponserList);
              values.leadList = getLeadListOnTheFly(handleChange);
              values.enddate = emptyEnddateIfNoStartdateAvailable(values);
              return (
                <div>
                  <ProjectForm
                    ref={childRef}
                    values={values}
                    validationSchema={validationSchema}
                    handleChange={(key) => projectFormFieldChange(key, handleChange)}
                    errors={projectFormErrors}
                    touched={touched}
                    dirty={dirty}
                    setFetchedData={setFetchedData}
                    users={formSupportedData.users}
                    solutionMilestones={solutionMilestoneValues.solutionMilestones}
                  />

                  <PrevNextButton
                    handleNext={isStep1Valid(projectFormErrors) && handleSubmit}
                    currentPage={currentPage}
                    disableNext={formSupportedData.users.length > 0 ? false : true}
                    isEdit={!isCreateForm}
                  />
                </div>
              );
            }}
          </Formik>
        );
      case 2:
        return (
          <div>
            <ProjectSolution
              navSign={navSign}
              ref={childRef1}
              setFetchedData={setFetchedData}
              activatedSolutionList={formSupportedData.activatedSolutionList}
              pendingSolutionList={formSupportedData.pendingSolutionList}
              notActivatedSolutionList={formSupportedData.notActivatedSolutionList}
              projectSolutionFieldChange={projectSolutionFieldChange}
              selectedSolutions={projectSolutionValues.selectedSolutions}
              selectedNotActivatedSolutions={projectSolutionValues.selectedNotActivatedSolutions}
            />

            <PrevNextButton
              handlePrev={handlePrev}
              handleNext={handleNext}
              currentPage={currentPage}
              isEdit={!isCreateForm}
            />
          </div>
        );

      case 3:
        return (
          <div>
            <ProjectSolutionMileStone
              ref={childRef2}
              setFetchedData={setFetchedData}
              solutionMilestoneFieldChange={solutionMilestoneFieldChange}
              solutionMilestones={solutionMilestoneValues.solutionMilestones}
              selectedSolutionsList={getSelectedSolutionsList()}
              notify={notifyObj}
              setNotify={setNotify}
              step1Data={projectFormValues}
            />

            <PrevNextButton
              currentPage={currentPage}
              handleNext={handleNext}
              handlePrev={handlePrev}
              isEdit={!isCreateForm}
            />
          </div>
        );

      case 4:
        return (
          <div>
            <ProjectModuleUserWrapper
              setFetchedData={setFetchedData}
              mainUsersList={formSupportedData.users}
              selectedSolutionsList={getSelectedSolutionsList()}
              solutionList={moduleUserValues.solutionList}
              moduleUserFieldChange={moduleUserFieldChange}
              currentTab={moduleUserValues.currentTab}
              cancelAPIs={cancelAPIs}
            />

            <PrevNextButton
              addProject={addProject}
              currentPage={currentPage}
              handlePrev={handlePrev}
              isEdit={!isCreateForm}
              disableNext={disableSubmit}
            />
          </div>
        );
      default:
        return null;
    }
  };

  // check if form is empty before canceling the form
  const checkIfFormIsEmptyAndGoback = () => {
    if (isFormOpen) {
      // show confirm box
      toggleConfirmBox();
    } else {
      closebutton();
    }
  };

  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  const toggleConfirmBox = () => {
    setOpenConfirmBox((prev) => !prev);
  };

  // CLOSE FORM AND NAVIGATE TO VIEW PROJECTS
  const closeFormAndRedirect = () => {
    toggleConfirmBox();
    closebutton();
    formGotClosed();
  };

  // DISPLAY EDIT FORM
  const displayEditForm = () => {
    if (showSignContract.show) {
    } else {
      return (
        <div className="container">
          <div className="col-md-7 Projecttab pl-0 Queen-project-lg-view m-auto">
            <div className="mb-4"></div>
            <Panel className="PannelPadding a-panel-project">
              <div className="col-md-12">
                <div className="row mb-3">
                  <div className="col-md-10 l-h-2">
                    <b className="subtitle_title_qb">{polyglot.t("Update Project ")} </b>
                  </div>
                  <div className="col-md-2 text-right">
                    <div>
                      <ButtonIcon
                        iconImg={close}
                        alt="cancel"
                        onClick={checkIfFormIsEmptyAndGoback}
                        ariaLabel={polyglot.t("Cancel update project")}
                        title={polyglot.t("Cancel")}
                      />
                    </div>
                  </div>
                </div>
              </div>
              {renderPage()}
            </Panel>
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      <div className="row m-0">
        {isCreateForm && (
          <div className="container">
            {!showSignContract.show && (
              <div className="col-md-7 Projecttab pl-0 Queen-project-lg-view m-auto">
                <div className="mb-4"></div>
                <Panel className="PannelPadding a-panel-project">
                  <div className="col-md-12">
                    <div className="row mb-3">
                      <div className="col-md-10 l-h-2">
                        <b className="subtitle_title_qb">{polyglot.t("Create a Project")} </b>
                      </div>
                      <div className="col-md-2 text-right">
                        <div>
                          <ButtonIcon
                            iconImg={close}
                            alt="cancel"
                            onClick={checkIfFormIsEmptyAndGoback}
                            ariaLabel={polyglot.t("Cancel create project")}
                            title={polyglot.t("Cancel")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  {renderPage()}
                </Panel>
              </div>
            )}
          </div>
        )}
        {!isCreateForm && showEditForm && displayEditForm()}

        <ConfirmBoxExitForm
          show={openConfirmBox}
          heading={"Cancel update"}
          cancel={toggleConfirmBox}
          proceed={closeFormAndRedirect}
        />
      </div>
      {showSignContract.show && (
        <SignContractInProjectForm
          solutionId={showSignContract.solutionId}
          hideSignContract={() => setShowSignContract(initialShowSignContract)}
        />
      )}
    </div>
  );
}
CreateProject.propTypes = {
  history: PropTypes.object,
  selectedProject: PropTypes.object,
  projectFormObj: PropTypes.object,
};

export default withRouter(CreateProject);
