/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable sort-imports */
/* eslint-disable react/prop-types */
import "../../../../Assets/Css/QueenBase/Users/User.css";
import "../../../../Assets/Css/COMMON/Form.css";
import { DatePicker, ConfigProvider } from "antd";
import gr from "antd/lib/locale-provider/de_DE";
import { Input, Select, SelectGroupTitle, SelectOption, TextArea } from "appkit-react";
import { Formik } from "formik";
import moment from "moment";
import React, { useContext, useState, useEffect } from "react";
import * as Yup from "yup";

import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { getProjectID_Of_solution_module } from "../../../../Services/Shared/Handlers";
import { DataContext } from "../../../../Services/Shared/Store";
import { polyglotLoader } from "../../../../Services/Shared/Translate";

import "moment/locale/de";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";
import RequestFormService from "../../../../Services/GI/RequestFormServices";
import FileUpload from "../IndividualRequestForm/FileUpload";
import calendar from "../../../../Assets/Icons/calendar.svg";
import { specialCharactersFieldRegexSchema } from "../../../../utility/textFieldValidation";

// ENTITY FORM SUBMIT PROCESS
const EntityComponent = (props) => {
  const { getUserLanguage, formGotClosed, formGotOpened } = useContext(DataContext);
  const language = getUserLanguage();
  var polyglot = polyglotLoader(getUserLanguage());
  const [priority, setPriority] = useState([]);
  const [category, setCategory] = useState([]);
  const [files, setFiles] = useState([]);
  const [targetList, setTargetList] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { onSubmit } = props;

  //INITIALVALUES OF FORM DATA
  const initialValues = {
    TARGET_NAME: "",
    INCIDENCE: "",
    PRIORITY_ID: "",
    REGISTRATION_NUMBER: "",
    CATEGORY_ID: "",
    ADDRESS: "",
    MISC: "",
    CRT_FILE: [],
    COMMENT: "",
    ID: "",
  };

  //if editvalues are pass to this form
  if (props.editableFormData) {
    initialValues.TARGET_NAME = props.editableFormData.TargetName;
    initialValues.INCIDENCE = moment(props.editableFormData.DOB);
    initialValues.PRIORITY_ID = props.editableFormData.PriorityID;
    initialValues.REGISTRATION_NUMBER = props.editableFormData.IdentificationNumber;
    initialValues.ADDRESS = props.editableFormData.address;
    initialValues.MISC = props.editableFormData.Misc;
    initialValues.CRT_FILE = props.editableFormData.files;
    initialValues.COMMENT = props.editableFormData.ClientComments;
    initialValues.ID = props.editableFormData.ID;
  }

  // FETCH REQUEST DETAILS
  const fetchRequestDetails = async () => {
    try {
      // API TO FETCH LIST OF ALL WORKFLOWS BY PROJECTID
      const projectId = getProjectID_Of_solution_module();
      const body = {
        projectId,
      };
      const res = await RequestFormService.get().fetchTargetDetails(language, body);
      if (res.CODE) {
        const fetchRequestDetails = res.data.targetList;
        setTargetList(fetchRequestDetails);
      } else {
        displayToastMessage(res.MSG, "error");
      }
    } catch (error) {
      displayToastMessage(error.message, "error");
    }
  };

  // CHECK IF TARGET NAME IS VALID
  const isTargetNameValid = (name) => {
    let isValid = true; // output will be a boolean
    //if editvalues are pass to this form
    if (props.editableFormData) {
      const initialTargetname = props.editableFormData.TargetName;
      // when edit form loads, then do not check for the existing name
      if (initialTargetname === name) {
        // if previous name and current name match, then it is considered as no error
        isValid = true;
        return isValid;
      }
    }
    const listOfTargetNames = targetList.map((obj) => obj.TargetName);
    if (listOfTargetNames.includes(name)) {
      // Target name already exists
      isValid = false;
      return isValid;
    }
    return isValid;
  };

  // FETCH PRIORITY FROM API
  async function getPriority() {
    var res = await RequestFormService.get().getPriorityData(language);
    if (res.CODE) {
      setPriority(res.data);
    } else {
      displayToastMessage(polyglot.t(res.MSG), "error");
    }
  }

  // FETCH CATEGORY FROM API
  async function getCategory() {
    var res = await RequestFormService.get().getCategoryData(language);
    if (res.CODE) {
      setCategory(res.data);
    } else {
      displayToastMessage(polyglot.t(res.MSG), "error");
    }
  }
  //fetch category data
  const isCategoryUpdate = () => {
    var filterCategory = [];
    var filterCategory = category.filter((item) => item.ID == 2 || item.ID == 1);
    return filterCategory;
  };

  // RUNS ON LOAD
  useEffect(() => {
    getPriority();
    getCategory();
    fetchRequestDetails();
  }, []);

  const comment = props.editableFormData
    ? { COMMENT: Yup.string().required(polyglot.t("Comment is required")) }
    : {};
  // VALIDATION OF THE ENTITY FORM
  const currentDate = new Date();
  const tomorrowDate = new Date(currentDate.setDate(currentDate.getDate() + 1));
  const validationSchema = Yup.object().shape({
    TARGET_NAME: specialCharactersFieldRegexSchema
      .max(40, polyglot.t("Maximum character length is 40"))
      .min(3, polyglot.t("Minimum character length is 3"))
      .trim()
      .required(polyglot.t("Target name is required")),
    INCIDENCE: Yup.date()
      .required(polyglot.t("Date of incorporation is required"))
      .max(tomorrowDate, polyglot.t("Date cannot be greater than today")),
    PRIORITY_ID: Yup.string().required(polyglot.t("Priority  is required")),
    CATEGORY_ID: Yup.string().required(polyglot.t("Category  is required")),
    REGISTRATION_NUMBER: Yup.string().required(polyglot.t("Registration number  is required")),
    ADDRESS: Yup.string().required(polyglot.t("Address  is required")),
    ...comment,
  });

  // SUBMIT FORM TO CREATE ENTITY REQUEST
  const formSubmit = async (values, { resetForm }) => {
    setDisableSubmit(true);
    const projectId = getProjectID_Of_solution_module();
    let formData = new FormData();
    //appending files to formData
    for (let i = 0; i < files.length; i++) {
      formData.append("file", files[i]);
    }
    const requestFormData = values;
    requestFormData.projectId = projectId;
    const FORM_NAME = "ENTITY";
    requestFormData.FORM_NAME = FORM_NAME;
    requestFormData.TYPE_ID = 2;
    //appending requestData to formData
    formData.append("RequestFormData", JSON.stringify(requestFormData));
    if (props.editableFormData) {
      var res = await RequestFormService.get().editRequestForm(formData);
    } else {
      var res = await RequestFormService.get().saveRequestForm(formData);
    }
    if (res.CODE) {
      displayToastMessage(polyglot.t("Your request has submitted successfully", "success"));
      resetForm();
      props.showRequestList();
    } else {
      displayToastMessage(polyglot.t(res.MSG), "error");
      setDisableSubmit(false);
    }
  };
  const updateUploadedFiles = (uploadedFiles) => {
    setFiles(uploadedFiles);
  };
  return (
    <Formik onSubmit={formSubmit} initialValues={initialValues} validationSchema={validationSchema}>
      {(prop) => {
        const { values, handleChange, handleSubmit, errors, touched, dirty } = prop;
        // FORMIK FORM - HANDLE FORMOPEN OR CLOSE STATUS
        const updateFormStatusBasedOnDirty = () => {
          if (dirty) {
            formGotOpened();
          }
          if (!dirty) {
            formGotClosed();
          }
        };
        updateFormStatusBasedOnDirty();
        // assign error to the error object only if targetname is not valid
        if (!isTargetNameValid(values.TARGET_NAME)) {
          errors.TARGET_NAME = "Target name already exists";
        }
        // if target name is valid, then remove the previously assigned error
        if (isTargetNameValid(values.TARGET_NAME)) {
          if (errors.TARGET_NAME === "Target name already exists") {
            delete errors["TARGET_NAME"];
          }
        }
        return (
          <div>
            <div className="row mt-1">
              <div className="col-md-6 ">
                <Input
                  inputBoxSize="sm"
                  placeholder={polyglot.t("Target name *")}
                  value={values.TARGET_NAME}
                  onChange={handleChange("TARGET_NAME")}
                  aria-label={polyglot.t("Target Name")}
                />
                {errors.TARGET_NAME && touched.TARGET_NAME ? (
                  <div className="error">{polyglot.t(errors.TARGET_NAME)}</div>
                ) : null}
                <br />
              </div>

              <div className="col-md-6 " id="datepicker-admin">
                {getUserLanguage() === "ger" ? (
                  <ConfigProvider locale={gr}>
                    <DatePicker
                      placeholder={polyglot.t("Date of incorporation *")}
                      value={values.INCIDENCE ? moment(values.INCIDENCE) : ""}
                      onChange={(value) =>
                        handleChange("INCIDENCE")({
                          target: { value: value || "" },
                        })
                      }
                      format={"DD.MM.YYYY"}
                      className="antd-datepicker-custom-styles full-width"
                      suffixIcon={<img src={calendar} width={14} height={14} />}
                    />
                  </ConfigProvider>
                ) : (
                  <DatePicker
                    placeholder={polyglot.t("Date of incorporation *")}
                    value={values.INCIDENCE ? moment(values.INCIDENCE) : ""}
                    onChange={(value) =>
                      handleChange("INCIDENCE")({
                        target: { value: value || "" },
                      })
                    }
                    format={"DD.MM.YYYY"}
                    className="antd-datepicker-custom-styles full-width"
                    suffixIcon={<img src={calendar} width={14} height={14} />}
                  />
                )}

                {errors.INCIDENCE && touched.INCIDENCE ? (
                  <div className="error">{polyglot.t(errors.INCIDENCE)}</div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 ">
                <Input
                  inputBoxSize="sm"
                  placeholder={polyglot.t("Company registration number *")}
                  value={values.REGISTRATION_NUMBER}
                  onChange={handleChange("REGISTRATION_NUMBER")}
                  aria-label={polyglot.t("Company registartion number")}
                />
                {errors.REGISTRATION_NUMBER && touched.REGISTRATION_NUMBER ? (
                  <div className="error">{polyglot.t(errors.REGISTRATION_NUMBER)}</div>
                ) : null}
                <br />
              </div>
              <div className="col-md-6 ">
                <Select
                  placeholder={polyglot.t("Select priority *")}
                  value={values.PRIORITY_ID}
                  onSelect={(value) => handleChange("PRIORITY_ID")({ target: { value } })}
                  aria-label={polyglot.t("Select priority")}
                >
                  <SelectGroupTitle>{polyglot.t("Priority")} </SelectGroupTitle>
                  {priority.map((r, index) => {
                    return (
                      <SelectOption key={index + 1} value={r.ID}>
                        {r.PriorityType}
                      </SelectOption>
                    );
                  })}
                </Select>
                {errors.PRIORITY_ID && touched.PRIORITY_ID ? (
                  <div className="error">{polyglot.t(errors.PRIORITY_ID)}</div>
                ) : (
                  null &&
                  errors.PRIORITY_ID && <span className="error">{polyglot.t(errors.PRIORITY_ID)}</span>
                )}
                <br />
              </div>
            </div>
            <div className="row">
              <div className="col-md-12  ">
                <Select
                  placeholder={polyglot.t("Select category *")}
                  value={values.CATEGORY_ID}
                  onSelect={(value) => handleChange("CATEGORY_ID")({ target: { value } })}
                  aria-label={polyglot.t("Select category *")}
                >
                  <SelectGroupTitle>{polyglot.t("Category")} </SelectGroupTitle>
                  {isCategoryUpdate().map((r, index) => {
                    return (
                      <SelectOption key={index + 1} value={r.ID}>
                        {r.Category}
                      </SelectOption>
                    );
                  })}
                </Select>
                {errors.CATEGORY_ID && touched.CATEGORY_ID ? (
                  <div className="error">{polyglot.t(errors.CATEGORY_ID)}</div>
                ) : (
                  null &&
                  errors.CATEGORY_ID && <span className="error">{polyglot.t(errors.CATEGORY_ID)}</span>
                )}
                <br />
              </div>
            </div>

            <div className="row ">
              <div className="col-md-12  ">
                <TextArea
                  cols={10}
                  rows={10}
                  placeholder={polyglot.t("Address of the target *")}
                  value={values.ADDRESS}
                  onChange={handleChange("ADDRESS")}
                  aria-label={polyglot.t("Adddress of the target")}
                />
                {errors.ADDRESS && touched.ADDRESS ? (
                  <div className="error">{polyglot.t(errors.ADDRESS)}</div>
                ) : null}
                <br />
              </div>
            </div>

            <div className="row ">
              <div className="col-md-12  ">
                <TextArea
                  cols={10}
                  rows={10}
                  placeholder={polyglot.t("Other information/comments")}
                  value={values.MISC}
                  onChange={handleChange("MISC")}
                  aria-label={polyglot.t("Other information/comments")}
                />
                {errors.MISC && touched.MISC ? <div className="error">{polyglot.t(errors.MISC)}</div> : null}
                <br />
              </div>
            </div>
            {props.editableFormData && (
              <div className="row ">
                <div className="col-md-12  ">
                  <TextArea
                    cols={10}
                    rows={10}
                    placeholder={polyglot.t("Enter comment *")}
                    value={values.COMMENT}
                    onChange={handleChange("COMMENT")}
                    aria-label={polyglot.t("Enter comment")}
                  />
                  {errors.COMMENT && touched.COMMENT ? (
                    <div className="error">{polyglot.t(errors.COMMENT)}</div>
                  ) : null}
                  <br />
                </div>
              </div>
            )}
            <div className="row">
              <FileUpload
                files={values.CRT_FILE}
                handleChange={handleChange}
                fieldName="CRT_FILE"
                updateUploadedFiles={updateUploadedFiles}
                isEdit={props.editableFormData ? true : false}
              />
            </div>

            <div className="mt-3">
              <ButtonPrimary
                onClick={handleSubmit}
                disabled={disableSubmit}
                ariaLabel={polyglot.t("Submit Request")}
              >
                {polyglot.t("Submit Request")}
              </ButtonPrimary>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default EntityComponent;
