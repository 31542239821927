import "../../../Assets/Css/QueenBase/Projects/ProjectSelection.css";
import PropTypes from "prop-types";
import React, { useEffect, useContext } from "react";

import { VIEW_ADMIN } from "../../../Constants/Path";
import AdminRoute from "../../../Routes/AdminRoute";
import { DataContext } from "../../../Services/Shared/Store";

// RENDERS ON /queenbase/admin
const AdminIndex = (props) => {
  // DATA FROM CONTEXT
  const { checkAndFetchRole } = useContext(DataContext);
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    const paths = ["/queenbase/admin", "/queenbase/admin/"];
    if (paths.includes(props.location.pathname)) {
      props.history.push(VIEW_ADMIN);
    }
  });
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    const screenName = "Client Admin";
    checkAndFetchRole(screenName, "Base");
  }, []);
  return <AdminRoute />;
};
AdminIndex.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default AdminIndex;
