import { Panel } from "appkit-react";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import { withRouter } from "react-router-dom";

import UserFormContainer from "./UserFormContainer";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";
import { closeIcon } from "../../../Assets/Icons";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";

// CREATE USER COMPONENT
function CreateUser({ buttonClick, userCount }) {
  const [openConfirmBox, setOpenConfirmBox] = useState(false); // this value handles the confirm box of exit form
  const { getUserLanguage, isFormOpen, formGotClosed, formGotOpened } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  // NAVIGATE BACK ON CLICKING BACK
  const navigateBack = () => {
    buttonClick();
  };

  // check if form is empty before canceling the form
  const checkIfFormIsEmptyAndGoback = () => {
    if (isFormOpen) {
      // show confirm box
      toggleConfirmBox();
    } else {
      buttonClick();
    }
  };

  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  const toggleConfirmBox = () => {
    setOpenConfirmBox(!openConfirmBox);
  };

  // CLOSE FORM AND NAVIGATE TO VIEW PROJECTS
  const closeFormAndRedirect = () => {
    toggleConfirmBox();
    buttonClick();
    formGotClosed();
  };
  return (
    <div>
      <div className="AddUserPannel">
        <Panel className="panel add-user a-panel-user">
          <div className="row  ">
            <div className="col-md-11 ">
              <h5 className="main_title_qb mb-4">{polyglot.t("Add a new user")}</h5>
            </div>
            <div className="col-md-1 close-data">
              <ButtonIcon
                onClick={checkIfFormIsEmptyAndGoback}
                iconName={closeIcon}
                alt={"close"}
                ariaLabel={polyglot.t("cancel user creation")}
                title={polyglot.t("Close")}
                className="a-btn-floating-secondary-user"
              />
            </div>
          </div>
          <UserFormContainer navigateBack={navigateBack} userCount={userCount} />
        </Panel>
      </div>
      <ConfirmBoxExitForm
        show={openConfirmBox}
        heading={"Cancel update"}
        cancel={toggleConfirmBox}
        proceed={closeFormAndRedirect}
      />
    </div>
  );
}

CreateUser.propTypes = {
  buttonClick: PropTypes.func,
  userCount: PropTypes.number,
};

export default withRouter(CreateUser);
