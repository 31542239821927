import "./ChooseProject.css";
import { Panel, Select, SelectOption } from "appkit-react";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { withRouter } from "react-router-dom";

import { StartContext } from "./Contexts/StartContext";
import PanelWithRadio from "./SharedComponents/PanelWithRadio";
import PanelWithRadioData from "./SharedComponents/PanelWithRadioData";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import AlertTriangle from "../../../Assets/Images/DF/icon-alert-triangle.svg";
import ProjectSurveyService from "../../../Services/DF/ProjectSurveyService";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";

// CHOOSE A PROJECT ON STEP 2 OF WORKFLOW FORM
const ChooseProject = (props) => {
  const { next, goBack, nextPage } = props;
  const {
    projectSurvey,
    updateProjectSurvey,
    updateWorkflowRecord,
    updateProjectSurveyIsUploadedSelectedProject,
    project_id,
  } = useContext(StartContext);
  var polyglot = polyglotLoader();
  const [workflows, setWorkflows] = useState([]);
  const [loading, setLoading] = useState(false);
  // FETCH WORKFLOWS LIST
  const fetchProjectSurvey = async () => {
    try {
      setLoading(true);
      // API TO FETCH LIST OF ALL WORKFLOWS BY PROJECTID
      const res = await ProjectSurveyService.get().getProjectSurveyDetails(project_id);
      setLoading(false);
      if (res.CODE === 0) {
        displayToastMessage(res.MSG, "error");
      } else {
        const fetchProjectSurveys = res.data;
        setWorkflows(fetchProjectSurveys);
      }
    } catch (error) {
      displayToastMessage(error.message, "error");
    }
  };

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    fetchProjectSurvey();
  }, []);

  // SELECT A WORKFLOW
  const selectedProject = (value) => {
    updateProjectSurveyIsUploadedSelectedProject(0, value);
    const workFlowItem = workflows.filter((item) => item.project_survey_id === value)[0];
    updateWorkflowRecord(workFlowItem);
  };
  // NAVIAGE TO WORKFLOW FORM WITH EMPTY DETAILS
  const goToUploadClicked = () => {
    updateProjectSurvey("is_existing_project", 1);
    updateWorkflowRecord({});
    next(nextPage);
  };
  // NAVIGATE TO WORKFLOW FORM WITH FILLED DETAILS
  const goToDashboardClicked = () => {
    updateProjectSurvey("is_existing_project", 0);
    next(nextPage);
  };
  // DISPLAY NEXT BUTTON
  const displayNextButton = () => {
    if (projectSurvey.selected_workflow_id === "" && projectSurvey.is_uploaded === 1) {
      return (
        <ButtonPrimary onClick={goToUploadClicked} ariaLabel={polyglot.t("Next")}>
          {polyglot.t("Next")}
        </ButtonPrimary>
      );
    } else if (projectSurvey.selected_workflow_id === "") {
      return (
        <ButtonPrimary disabled onClick={goToUploadClicked} ariaLabel={polyglot.t("Next")}>
          {polyglot.t("Next")}
        </ButtonPrimary>
      );
    } else if (projectSurvey.selected_workflow_id !== "") {
      return (
        <ButtonPrimary
          onClick={goToDashboardClicked}
          ariaLabel={polyglot.t("Duplicate existing workflow data")}
        >
          {polyglot.t("Duplicate existing workflow data")}
        </ButtonPrimary>
      );
    }
    return null;
  };

  // DISPLAY LOADING SELECT BOX
  const displayLoadingSelect = () => {
    return (
      <Select
        className="select-style"
        placeholder={polyglot.t("Choose existing workflow")}
        style={{ backgroundColor: "#f2f2f2" }}
      >
        <SelectOption>{polyglot.t("Loading...")}</SelectOption>
      </Select>
    );
  };
  // DISPLAY SELECT BOX WITH NO WORKFLOWS PRESENT
  const displayNoWorkflowSelect = () => {
    return (
      <Select
        disabled={workflows.length === 0}
        className="select-style"
        placeholder={polyglot.t("No other workflow available")}
        style={{ backgroundColor: "#f2f2f2" }}
        value={null}
      >
        <SelectOption>{polyglot.t("No other workflow available")}</SelectOption>
      </Select>
    );
  };
  // DISPLAY SELECT BOX WITH WORKFLOWS LIST
  const displayWorkflowSelect = () => {
    return (
      <Select
        disabled={workflows.length === 0}
        className="select-style"
        placeholder={polyglot.t("Choose existing workflow")}
        onSelect={selectedProject}
        style={{ backgroundColor: "#f2f2f2" }}
        value={projectSurvey.selected_workflow_id}
      >
        {workflows.map((workflow) => {
          return (
            <SelectOption key={workflow.project_survey_id} value={workflow.project_survey_id}>
              {workflow.workflow_name}
            </SelectOption>
          );
        })}
      </Select>
    );
  };

  return (
    <div>
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <div className="main_title_qb" style={{ marginTop: "5%", textAlign: "center" }}>
            {polyglot.t("Do you want to use existing data?")}
          </div>
          <div className="ml-auto a-font-12 a-opacity-80">
            <div className="f-acc-B DF-review-content" style={{ textAlign: "center" }}>
              {polyglot.t(
                "You can use data from another project for your audit, or you can start a completely new project.",
              )}
            </div>
          </div>
          <Panel className="margin-top-50">
            <div style={{ padding: "20px" }}>
              <PanelWithRadioData
                item={projectSurvey}
                handleCheck={updateProjectSurveyIsUploadedSelectedProject}
              />

              <div className="row margin1em">
                <div className="col-md-1 paddingRight-zero paddingPoint5em-auto display-inline-flex heightOfImage mt-2">
                  <img src={AlertTriangle} />
                  &nbsp;&nbsp;
                </div>
                <div className="col-md-11 paddingLeft-zero paddingPoint5em-auto">
                  <div className="subtitle_three_title_qb">
                    {polyglot.t(
                      "Please note that this option reflects the current status of the project you select – you’ll be using the same modules and working directly on the dashboard.",
                    )}
                  </div>
                </div>
              </div>
              <div className="margin-halfAuto" id="selectchervon">
                {loading && displayLoadingSelect()}
                {!loading && workflows.length === 0 && displayNoWorkflowSelect()}
                {!loading && workflows.length > 0 && displayWorkflowSelect()}
              </div>
            </div>
          </Panel>
        </div>
        <div className="col-md-3"></div>
      </div>

      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6">
          <PanelWithRadio item={projectSurvey} handleCheck={updateProjectSurveyIsUploadedSelectedProject} />
        </div>
        <div className="col-md-3"></div>
      </div>
      <div className="row">
        <div className="col-md-3"></div>
        <div className="col-md-6 df-button-style text-left">
          <div className="d-flex justify-content-between">
            <ButtonSecondary onClick={goBack} ariaLabel={polyglot.t("Back")}>
              {polyglot.t("Back")}
            </ButtonSecondary>
            {displayNextButton()}
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
    </div>
  );
};

ChooseProject.propTypes = {
  next: PropTypes.any,
  nextPage: PropTypes.any,
};

export default withRouter(ChooseProject);
