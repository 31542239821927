import HttpClient from "../../Shared/HttpClient";

class AdminService {
  constructor() {
    this.client = new HttpClient();
  }
  /* GET REQUIRED DATA FROM API TO CREATE A CLIENT
	WE RECEIVE:
	basicPackage: LIST OF BASE PACKAGES
	countries: LIST OF COUNTRIES
	position: LIST OF POSITIONS
	solutionList: LIST OF SOLUTIONS
	solutionPackage: LIST OF PACKAGES OF SOLUTIONS
	*/
  async getAdmin(language) {
    const result = await this.client.get(`/getAdmin?language=${language}`);
    return result;
  }

  // API TO CREATE A CLIENT
  // PASSING: form object
  async clientCreation(ClientData) {
    const result = await this.client.post("/CreateClient", ClientData);
    return result;
  }
  async getNumberOfAvailableSDs() {
    return await this.client.get("/getNumberOfAvailableSDs");
  }
  // GET LIST OF CLIENTS FROM API
  async ListOfClients() {
    const result = await this.client.get("/ListOfClients");
    return result;
  }
  // API TO DELETE A CLIENT
  // PASSING: client object
  async DeleteClient(ClientData) {
    const result = await this.client.post("/DeleteClient", ClientData);
    return result;
  }
  // GET ROLES TO CREATE A CLIENT
  // WE GET clientSponser and clientAdmin FROM THIS API
  async GetRoles() {
    const res = await this.client.get("/GetRoles");
    return res;
  }

  // GET ROLES TO CREATE A USER
  async GetUsersRoles() {
    const res = await this.client.get("/GetUsersRoles");
    return res;
  }

  // API TO CHECK IF CLIENT NAME IS ALREADY EXISTS
  async CheckClientName(name) {
    const result = await this.client.get(`/CheckClientName?name=${name}`);
    return result;
  }

  /* GET DETAILS OF CLIENT INVOICING
	WE RECEIVE:
	client: LIST OF CLIENTS
	list: LIST OF INVOICES
	project: LIST OF PROJECTS
	status: LIST OF STATUS OF INVOICES
	*/
  async getClientInvoicingDetails() {
    const result = await this.client.get("/getClientInvoicingDetails");
    return result;
  }
  /* API TO CREATE AN INVOICE BY PASSING FORM OBJECT */
  async createInvoice(userData) {
    const result = await this.client.post("/createInvoice", userData);
    return result;
  }
  //Invoice Download
  async downloadClientInvoice(invoice_attachment) {
    const body = {
      invoice_attachment,
    };

    const result = await this.client.post("/downloadClientInvoice", body);
    return result;
  }
  // API TO Delete Invoice from List
  async DeleteClientInvoice(modeldata) {
    const result = await this.client.post("/DeleteClientInvoice", modeldata);
    return result;
  }
  // API TO UPDATE INVOICE BY PASSING FORM OBJECT
  async updateInvoice(userData) {
    const result = await this.client.post("/updateInvoice", userData);
    return result;
  }
  // API TO GET LIST OF BASE PACKAGES
  async getBasePackageInfo() {
    const result = await this.client.get("/getBasePackageInfo");
    return result;
  }
  // GET LIST OF ENTITY TYPES
  async getEntityType() {
    const res = await this.client.get("/getEntityType");
    return res;
  }
  // API TO GET CLIENT DETAILS BY PASSING CLIENTID
  async getClientDetail(clientid) {
    const result = await this.client.get(`/getClientDetail?clientid=${clientid}`);
    return result;
  }
  // API TO UPDATE CLIENT
  async updateClient(client) {
    const result = await this.client.post("/updateClient", client);
    return result;
  }
  // API TO FETCH CLIENT DETAILS ON PASSING CLIENTID
  async fetchClientDetailsById(clientid) {
    const result = await this.client.get(`/fetchClientDetailsById?clientid=${clientid}`);
    return result;
  }
  // API TO ACTIVATE IDAM ON A USER BY PASSING AN OBJECT
  async activateIDAMonUser(body) {
    const result = await this.client.post("/activateIDAMonUser", body);
    return result;
  }

  // API TO FETCH LIST OF COUNTRIES BY LANGUAGE
  fetchCountriesListByLanguage = async (lang) => {
    const res = await this.client.get(`/fetchCountriesListByLanguage/${lang}`);
    return res;
  };
  // API TO FETCH LIST OF COUNTRIES BY LANGUAGE FOR DFED
  fetchCountriesListByLanguageForDFED = async (lang) => {
    const res = await this.client.get(`/fetchCountriesListByLanguageForDFED/${lang}`);
    return res;
  };

  // save message of the day
  saveMessageOfTheDay = async (body) => {
    const result = await this.client.post("/saveMessageOfTheDay", body);
    return result;
  };
  // fetch message of the day
  fetchMessageOfTheDay = async () => {
    const result = await this.client.get("/fetchMessageOfTheDay");
    return result;
  };
}

// Enable Singleton behavior on this class
AdminService.__instance = null;
AdminService.get = () => {
  if (!AdminService.__instance) {
    AdminService.__instance = new AdminService();
  }
  return AdminService.__instance;
};

export default AdminService;
