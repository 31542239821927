import React, { useState, useEffect } from "react";

import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/GRC/loading-spin.svg";
import RecipientService from "../../../Services/GRC/RecipientService";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import ErrorPanel from "../Shared/ErrorPanel";
import "./index.css";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import editImg from "../../../Assets/Icons/pencil-outline.svg";
import { deleteFill } from "../../../Assets/Icons";
import DeleteQuestion from "./DeleteQuestion";

const polyglot = polyglotLoader();
const CategoryAddTreeQuestions = ({ parentId, handleChanged, mode, getCategories }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);
  const [questionToDelete, setQuestionToDelete] = useState({});
  const [openDeleteConfirmBox, setOpenDeleteConfirmBox] = useState(false);

  const closeDeleteConfirmBox = () => {
    try {
      setOpenDeleteConfirmBox(false);
      setQuestionToDelete({});
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  const displayName = (index) => "Question " + String(index + 1).padStart(2, "0");

  const handleDeleteQuestion = (question, index) => {
    try {
      const { value, parent_id, label } = question;
      const display_name = displayName(index);
      const questionItem = { id: value, parent_id, label, display_name };
      setOpenDeleteConfirmBox(true);
      setQuestionToDelete(questionItem);
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  const getSelectedQuestionaries = async () => {
    try {
      const responseData = await sendRequest(
        RecipientService.get().getRecipientAassessmentQuestionsURL(parentId),
      );
      if (responseData) {
        setAssessmentQuestions(responseData);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getSelectedQuestionaries();
  }, [sendRequest]);

  return (
    <>
      {error && <ErrorPanel error={error} onClear={clearError} />}
      {isLoading && (
        <div>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      )}

      {!isLoading &&
        assessmentQuestions &&
        assessmentQuestions.map((item, i) => {
          return (
            <div key={item.value} className="justify-content-between d-flex font-16px p-t-0 p-b-0 width-hund">
              <div className="left-padding m-auto m-l-0 display-inline-flex w-55 ">
                <label title={item.label} htmlFor={item.value} className="ml-1 p-0 hide-long-text">
                  <span
                    className="appkiticon icon-circle-checkmark-outline custom-grc-icon"
                    style={{ marginRight: "0.25rem" }}
                  />
                  <span id={item.value} className="custom_create_question">
                    {displayName(i)}
                  </span>
                </label>
              </div>
              <ButtonIcon
                imgWidth={16}
                name="delete"
                onClick={() => handleDeleteQuestion(item, i)}
                iconName={deleteFill}
                ariaLabel={polyglot.t("Delete")}
              />
              <ButtonIcon
                imgWidth={16}
                className="a-btn-floating a-btn-floating-secondary a-keyboard-hover-only-div"
                name={item.value}
                onClick={(e) => {
                  handleChanged(e, item.value, 3, "node.tagClassName", "ques", "edit");
                }}
                iconImg={editImg}
              />
            </div>
          );
        })}
      <DeleteQuestion
        Item={questionToDelete}
        show={openDeleteConfirmBox}
        close={closeDeleteConfirmBox}
        getQuestions={getSelectedQuestionaries}
        getCategories={getCategories}
      />
    </>
  );
};
export default CategoryAddTreeQuestions;
