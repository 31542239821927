import jwtUtil from "../../../Constants/Jwt";
import HttpClient from "../../Shared/HttpClient";

class CompanyCenterService {
  constructor() {
    this.client = new HttpClient();
  }
  async getCompanyCenterList(language) {
    const result = await this.client.get(`/getCompanyCenterList?language=${language}`);
    return result;
  }
  async getBasePackageConsumptionDetails(language) {
    const result = await this.client.get(`/getBasePackageConsumptionDetails?language=${language}`);
    return result;
  }
  /*
	BUY TEMPORARY USERS BY PASSING:
	tempCount: number,
	price: number,
	noofmonths: number
	*/
  async buyTempUsersInCompanyCenter(tempData) {
    var tempdata = jwtUtil.encrypt(tempData);
    const result = await this.client.post("/buyTempUsersInCompanyCenter", tempdata);
    return result;
  }
  async buyAdditionalVolume(volumeData) {
    var volumedata = jwtUtil.encrypt(volumeData);
    const result = await this.client.post("/buyAdditionalVolume", volumedata);
    return result;
  }
  /* API TO UPGRADE BASE PACKAGE BY PASSING basepackage: number */
  async upgradeBasePackage(basepackage) {
    const result = await this.client.post("/upgradeBasePackage", basepackage);
    return result;
  }
  //list of invoices based on Selection of Projects BY PASSING projectId
  async getInvoicingdetails(projectId) {
    const result = await this.client.get(`/getInvoicingdetails?projectId=${projectId}`);
    return result;
  }

  //df pacckage details
  async getDFPackageConsumptionDetails() {
    const result = await this.client.get(`/getDFPackageConsumptionDetails`);
    return result;
  }

  //dfed pacckage details
  //dfed pacckage consumption details
  async getDFEDPackageConsumptionDetails() {
    const result = await this.client.get(`/getDFEDPackageConsumptionDetails`);
    return result;
  }
  //Invoice Download
  async downloadInvoice(invoice_attachment) {
    const body = {
      invoice_attachment,
    };
    const result = await this.client.post("/downloadInvoice", body);
    return result;
  }
}

// Enable Singleton behavior on this class
CompanyCenterService.__instance = null;
CompanyCenterService.get = () => {
  if (!CompanyCenterService.__instance) {
    CompanyCenterService.__instance = new CompanyCenterService();
  }
  return CompanyCenterService.__instance;
};

export default CompanyCenterService;
