import axios from "axios";
import HttpClient from "../Shared/HttpClient";
import ErrorHandler from "../Shared/ErrorHandler";
import { getUserIDFromSession } from "../Shared/Authenticate";
axios.defaults.baseURL = process.env.REACT_APP_SERVER_HOST;
// axios.defaults.headers.common['Authorization'] = "JWT_TOKEN";  // Yet to complete
axios.defaults.headers.post["Content-Type"] = "application/json";

export default class CustodianService {
  handleCustodianFormData(formData, onUploadProgress) {
    return new Promise((resolve, reject) => {
      // axios.defaults.headers.post["authorization"] = 'Bearer ' + sessionStorage.getItem('jwt')
      axios
        .post("/custodianData", formData, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            loggedInUserId: getUserIDFromSession(),
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress,
        })
        .then((reply) => {
          resolve(reply);
        })
        .catch((error) => {
          //reject(error)
          ErrorHandler.get().customErrorHandler(error.response);
        });
    });
  }

  getCustodianData = async (projectId) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getCustodianData/${projectId}`);
    return res;
  };

  getGlobalCustodianDuplicat = async (projectId) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getGlobalCustodianDuplicat/${projectId}`);
    return res;
  };

  getCountry = async (language) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getCountry/${language}`);
    return res;
  };

  getClassifications = async () => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getClassification`);
    return res;
  };

  getCustodianById = async (Id) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getCustodianById/${Id}`);
    return res;
  };

  getPositions = async () => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getPositions`);
    return res;
  };

  downloadCustodianById = async (metadata) => {
    const client = new HttpClient();
    const res = await client.dfedPost("/downloadCustodianById", metadata);
    return res;
  };

  deleteCustodianById = async (Id, projectId) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/deleteCustodianById/${Id}/${projectId}`);
    return res;
  };
  getCustodianCategories = async () => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getCustodianCategories`);
    return res;
  };

  searchCustodian = async (str) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/searchCustodian/${str}`);
    return res;
  };

  getTemplate = async (path) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getCustodianTemplate/${path}`);
    return res;
  };

  saveMultipleCustodian = async (formData, onUploadProgress) => {
    const client = new HttpClient();
    const res = await client.dfedPost(
      "/uploadMultipleCustodian",
      formData,
      { "Content-Type": "multipart/form-data" },
      onUploadProgress,
    );
    return res;
  };
}
