import React from "react";
import "./OutlineButton.css";
import ButtonSecondary from "../../../../Assets/Components/ButtonSecondary/ButtonSecondary";

const OutlineButton = (props) => {
  return (
    <ButtonSecondary ariaLabel={props.name} onClick={props.onClick} className={props.className}>
      {props.name}
    </ButtonSecondary>
  );
};

export default OutlineButton;
