import React, { Component } from "react";
import "../../DFED/Review/Review.css";
import { ButtonGroup, ButtonGroupItem, Panel } from "appkit-react";
import DocumentList from "./DocumentList/DocumentList";
import Criteria from "./Criteria/Criteria";
import "../../DFED/Review/ProductionTypes.css";
import "../../DFED/Processing/Processing.css";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import {
  getReviewFileNamesInfo,
  get_review_production_types,
} from "../../../../src/Services/DFED/ReviewService";
import "../../DFED/Custodian/custodianList/custodianList.css";
import ProductionFiles from "./ProductionFiles";
import ProductionDataTable from "../Review/productionDataTable";
// import closeIcon from "../../../Assets/Images/DFED/icon-x.svg"
import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import ButtonPrimaryExpandable from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryExpandable";
import ButtonGroupCustom from "../../../Assets/Components/ButtonGroupCustom/ButtonGroupCustom";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import { backCircle, closeIcon } from "../../../Assets/Icons";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";

export class ProductionTypes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productionResult: props.productionResult,
      showPersonalCustodianForm: true, // this value is true by default to show the tab 1 on initail load
      showGroupCustodianForm: false,
      showImageDiv: false,
      reviewFileNames: [],
      reviewProductionSearchtype: "",
      reviewDocSearchType: "",
      reviewCriteriaSearchType: "",
      selectedProjectId: props.selectedProjectId,
      selectedProjectName: props.selectedProjectName,
      redirectToTableNextPage: false,
      redirectToProductionNextPage: false,
      uploadedFlag: false,
      openConfirmBox: false,
      selectedTab: "Review DocumentList",
    };
    this.closeReview = this.closeReview.bind(this);
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
  }
  static contextType = DataContext;

  onBackButtonClick() {
    if (this.state.productionResult && this.state.productionResult.length > 0) {
      this.setState({
        redirectToTableNextPage: true,
        uploadedFlag: false,
      });
    } else {
      this.setState({
        redirectToProductionNextPage: true,
      });
    }
  }
  componentDidMount() {
    this.getReviewFileNames();
    this.review_DatasetNames();
  }
  //get file type names
  async getReviewFileNames() {
    let reviewFileNamesInfo = await getReviewFileNamesInfo();
    if (reviewFileNamesInfo && reviewFileNamesInfo.data) {
      this.setState({
        reviewFileNames: reviewFileNamesInfo.data,
      });
    }
  }

  //get review tab names
  async review_DatasetNames() {
    let review_production_types = await get_review_production_types();
    if (review_production_types && review_production_types.data) {
      let review_Doctype = review_production_types.data[0].REVIEW_SEARCH_TYPE;
      let review_Criteria = review_production_types.data[1].REVIEW_SEARCH_TYPE;

      this.setState({
        reviewProductionSearchtype: review_Doctype,
        reviewDocSearchType: review_Doctype,
        reviewCriteriaSearchType: review_Criteria,
      });
    }
  }
  //showing tab selection
  showCustodianForm(value, id) {
    let selectedOption = value;
    if (selectedOption === "Review DocumentList") {
      this.setState({
        showPersonalCustodianForm: true,
        showGroupCustodianForm: false,
        custodianTypeID: 1,
        reviewProductionSearchtype: this.state.reviewDocSearchType,
        selectedTab: value,
      });
    }
    if (selectedOption === "Review Criteria") {
      this.setState({
        showGroupCustodianForm: true,
        showPersonalCustodianForm: false,
        custodianTypeID: 2,
        reviewProductionSearchtype: this.state.reviewCriteriaSearchType,
        selectedTab: value,
      });
    }
  }
  closeReview() {
    if (this.state.productionResult && this.state.productionResult.length > 0) {
      this.setState({
        redirectToTableNextPage: true,
      });
    } else {
      this.setState({
        redirectToProductionNextPage: true,
      });
    }
  }
  docNav = (uploadedFlag) => {
    this.setState({
      redirectToTableNextPage: true,
      uploadedFlag: uploadedFlag,
    });
  };

  // Close and redirect form
  closeFormAndRedirect = () => {
    try {
      const { formGotClosed } = this.context;
      // close the popup
      this.toggleConfirmBox();
      // mark the form is closed on the store
      formGotClosed();
      if (this.state.productionResult && this.state.productionResult.length > 0) {
        this.setState({
          redirectToTableNextPage: true,
          uploadedFlag: false,
        });
      } else {
        this.setState({
          redirectToProductionNextPage: true,
        });
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  toggleConfirmBox = () => {
    this.setState({
      openConfirmBox: !this.state.openConfirmBox,
    });
  };

  // check and close the form
  checkAndCloseForm = () => {
    try {
      const { isFormOpen, formGotClosed } = this.context;
      if (isFormOpen) {
        // values of the form got changed - then show confirm box
        this.setState({ openConfirmBox: true });
      } else {
        // if form is not considered as open(values of the form are not changed yet), then close the form
        // close custodian form
        if (this.state.productionResult && this.state.productionResult.length > 0) {
          this.setState({
            redirectToTableNextPage: true,
            uploadedFlag: false,
          });
        } else {
          this.setState({
            redirectToProductionNextPage: true,
          });
        }
        formGotClosed();
        this.setState({ openConfirmBox: false });
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  render() {
    var polyglot = polyglotLoader();
    const tabs = [
      /* Reference Purpose
        {name: this.state.reviewDocSearchType} & {name: this.state.reviewCriteriaSearchType}
      */
      { value: "Review DocumentList", name: "Document list-based" },
      { value: "Review Criteria", name: "Criteria-based" },
    ];
    const set1 = (
      <div>
        <div className="row m-l-0 m-r-0">
          <div className="col-md-4 col-lg-3 p-r-20 pl-0">
            <ButtonPrimaryWithIcon
              onClick={this.checkAndCloseForm}
              ariaLabel={polyglot.t("Back")}
              alt={polyglot.t("Back")}
              iconName={backCircle}
            >
              {polyglot.t("Back")}
            </ButtonPrimaryWithIcon>
          </div>
          <div className="col-md-4 col-lg-6 p-r-20"></div>
        </div>
        <br />
        <div className="panel">
          <div className="row panel_type m-r-0 m-l-0">
            <div className="col-md-12 col-lg-8 m-t-5 pl-0">
              <Panel>
                <br />
                <div className="a-text-caption  a-text-616 ">
                  <div className="row crossIcon">
                    <ButtonIcon
                      alt="close"
                      iconName={closeIcon}
                      onClick={this.checkAndCloseForm}
                      ariaLabel={polyglot.t("Close")}
                      title={polyglot.t("Close")}
                    />
                  </div>
                  <br />
                  <br />
                  <div className="col-md-9">
                    <h1 className="subtitle_title_qb ">
                      {polyglot.t("Select the type of production request")}
                    </h1>
                  </div>
                  <div className="">
                    <div className="col-sm-12 col-md-12 p-t-12 mt-2 p-b-10 ">
                      <ButtonGroupCustom
                        onChange={(e, v) => {
                          this.showCustodianForm(v);
                        }}
                        tabs={tabs}
                        value={this.state.selectedTab}
                      />
                    </div>
                  </div>
                  {this.state.showPersonalCustodianForm && (
                    <DocumentList
                      docNav={this.docNav}
                      selectedProjectId={this.state.selectedProjectId}
                      selectedProjectName={this.state.selectedProjectName}
                      reviewFiles={this.state.reviewFileNames}
                      searchType={this.state.reviewProductionSearchtype}
                      productionResult={this.state.productionResult}
                    ></DocumentList>
                  )}
                  {this.state.showGroupCustodianForm && (
                    <Criteria
                      docNav={this.docNav}
                      selectedProjectId={this.state.selectedProjectId}
                      reviewFiles={this.state.reviewFileNames}
                      searchType={this.state.reviewProductionSearchtype}
                      productionResult={this.state.productionResult}
                    ></Criteria>
                  )}
                </div>
                <br />
                <br />
              </Panel>
            </div>
            <div className="col-md-12 col-lg-4">
              <div>
                <p className="para-txt mb-1">
                  {polyglot.t("You can choose between two types of requests to identify the documents:")}
                </p>
                <p className="para-txt mb-1">{"1. " + polyglot.t("Document list-based")}</p>
                <p className="para-txt ">{"2. " + polyglot.t("Criteria-based")}</p>
                <p className="para-txt ">
                  {polyglot.t("Once you’ve added all the information, please click on")}
                  &nbsp;
                  <span className="para-head">{polyglot.t("Request production")}.</span>
                </p>
              </div>
            </div>
            {/* show confirm box if the form is open */}
            {this.state.openConfirmBox && (
              <ConfirmBoxExitForm
                show={this.state.openConfirmBox}
                heading={"Cancel update"}
                cancel={this.toggleConfirmBox}
                proceed={this.closeFormAndRedirect}
              />
            )}
          </div>
        </div>
      </div>
    );
    const set2 = (
      <ProductionFiles
        selectedProjectId={this.state.selectedProjectId}
        productionResult={this.state.productionResult}
      />
    );
    const set3 = (
      <ProductionDataTable
        uploadedFlag={this.state.uploadedFlag}
        selectedProjectId={this.state.selectedProjectId}
        productionResult={this.state.productionResult}
        docNav={this.docNav}
        showBackButton={this.props.showBackButton}
        newProps={this.props.newProps}
      />
    );
    var newset;
    if (this.state.redirectToProductionNextPage) {
      newset = set2;
    } else if (this.state.redirectToTableNextPage) {
      newset = set3;
    } else {
      newset = set1;
    }
    return newset;
  }
}

export default ProductionTypes;
