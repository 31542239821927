/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-spaces-and-tabs */
import "react-multi-carousel/lib/styles.css";
import "../../../Assets/Css/QueenBase/Solutions/Solution.css";
import { Checkbox } from "appkit-react";
import PropTypes from "prop-types";
import React, { useEffect, useState, useContext } from "react";

import {
  ChooseDFPackages,
  ChoosePackages,
  SolutionBriefingCard,
  TermsAndConditionssolutions,
  PackageLegend,
  PackageLegendCss,
} from "./ReviewSolutionComponents";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import { backCircle } from "../../../Assets/Icons";
import backbutton from "../../../Assets/Images/QueenBase/Root/icon-arraow-prev-red.svg";
import info from "../../../Assets/Images/QueenBase/Root/icon-chervon-right-red.svg";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/QueenBase/Root/loading-spin.svg";
import * as path from "../../../Constants/Path";
import SolutionService from "../../../Services/QueenBase/Solutions/SolutionService";
import { hasCreatePermission } from "../../../Services/Shared/RoleHandler";
import { ScrollToComponent } from "../../../Services/Shared/ScrollToComponent";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { customNumberFormat } from "../../../utility/CustomNumberFormat";
import displayToastMessage from "../Root/displayToastMessage";

function ReviewSolution(props) {
  const { fromPage, hideSignContract } = props;
  const { id } = props.location.state;
  const [solutionId, setSolutionId] = useState(id);
  const [solutionList] = useState(props.location.state.solutionList);
  const [currentSolution, setCurrentSolution] = useState(props.location.state.solution);
  const [previous, setPrevious] = useState({});
  const [next, setNext] = useState({});
  const [packageList, setPackageList] = useState([]);
  const [solution, setSolution] = useState({});
  const [packageId, setPackageId] = useState();
  const [multilist, setMultilist] = useState([]);
  const [terms, setTerms] = useState(false);
  const [termserror, setTermsError] = useState("");
  const [selectedDFPackages, setSelectedDFPackages] = useState([]);
  const [OptedPackages, setOptedPackages] = useState([]);
  const [DFPackagesList, setDFPackagesList] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [isSolutionPackageLoading, setIsSolutionPackageLoading] = useState(false);
  const { getCurrentUserPermissions, getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const language = getUserLanguage();
  const currentUserPermissions = getCurrentUserPermissions();

  // check if a package is selected for GI solution
  const checkIfGIPackageIsSelected = () => {
    try {
      const selectedGIPackages = packageList.filter((each) => each.isselected === 1);
      return {
        isPackageSelected: selectedGIPackages.length > 0,
      };
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  /*
		when user enters GI solution, then check if a package is already selected
		if a package is already selected, then do not show terms and conditions on UI
		if a package is not selected at all, then show terms and conditions on UI
	*/
  useEffect(() => {
    // solution id 4 = "connected global intelligence"
    // if solution "connected global intelligence" is selected, then select first package as default
    if (currentSolution.id === 4 && packageList.length > 0) {
      // check if a package is already selected for GI solution
      const GIpackage = checkIfGIPackageIsSelected();
      if (GIpackage?.isPackageSelected) {
        // empty the already selected packageID
        // it is used to hide the terms and conditions
        setPackageId();
      } else {
        /* select a package and then show the terms and conditions component, so that user can sign up for the solution */
        // first package of the package list is considered as default package
        const defaultPackage = packageList[0];
        const defaultPackageId = defaultPackage?.id;
        setPackageId(defaultPackageId);
      }
    }
  }, [currentSolution, packageList, packageId]);

  // GET NEWLY SELECTED PACKAGES
  const getNewlySelectedPackages = (packagesStr, packageId) => {
    const alreadySelectedDFPackages = [];
    DFPackagesList.forEach((r) => {
      if (r?.isSelected) {
        alreadySelectedDFPackages.push(r?.PlanID);
      }
    });
    const newlySelectedPackages = [];
    let totalSelectedPackages = [];
    if (packagesStr !== "") {
      totalSelectedPackages = packagesStr.split(",");
      totalSelectedPackages.forEach((id) => {
        if (alreadySelectedDFPackages.indexOf(Number(id)) < 0) {
          newlySelectedPackages.push(Number(id));
        }
      });
    } else {
      newlySelectedPackages.push(packageId);
    }
    return newlySelectedPackages;
  };

  // FUNCTION TO DISABLE SUBMIT BUTTON IF REQUIRED
  const isSubmitDisabled = () => {
    let isDisabled = disableSubmit;
    // check if terms are accepted
    if (!terms) {
      isDisabled = true;
    }
    return isDisabled;
  };

  const getSelectedPackages = () => {
    try {
      let selectedPackagesList = packageList.filter((r) => r?.isChecked === 1);
      /*
				if current solution is "Connected Global Intelligence",
				then first package will be the default package
			*/
      if (currentSolution.id === 4) {
        const defaultPackage = packageList[0];
        selectedPackagesList = [defaultPackage];
      } else {
        // selectedPackagesList was already assigned a value above
        // hence there is nothing to do here
      }
      return selectedPackagesList;
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // FUNCTION TO SIGN A CONTRACT ON A PACKAGE
  const signContract = async () => {
    let isFormValid = true;
    const params = {};
    let packagesStr = "";
    let selectedSolutions = getSelectedPackages();
    if (solution.id === 2) {
      selectedDFPackages.map((eachPackage, index) => {
        if (index == 0) {
          packagesStr = packagesStr + eachPackage;
        } else {
          packagesStr = packagesStr + "," + eachPackage;
        }
      });
      params.solutionid = solution.id;
      params.packageId = packagesStr;
      if (selectedDFPackages.length === 0) {
        isFormValid = false;
        displayToastMessage(polyglot.t("Please choose a package to proceed"), "error");
      }
    } else {
      if (selectedSolutions.length === 0) {
        isFormValid = false;
        displayToastMessage(polyglot.t("Please choose a package to proceed"), "error");
      }
      params.solutionid = solution.id;
      params.packageId = packageId;
    }
    // we need to send params.selectedPackages as a params to API
    if (terms && isFormValid) {
      setDisableSubmit(true);
      params.newlySelectedPackages = getNewlySelectedPackages(packagesStr, packageId).toString();
      let APIResponse = await SolutionService.get().buySolutionPackage(params);
      if (APIResponse.CODE) {
        displayToastMessage(APIResponse.MSG, "success");
        /*Note: use navigate function instead,
				props.history.push(path.SOLUTION_LIST_PATH);
				*/
        navigate();
      } else {
        displayToastMessage(`${polyglot.t(APIResponse.MSG)}`, "error");
        setDisableSubmit(false);
      }
    }
    if (!terms) {
      const errorMsg = polyglot.t("please accept the terms and conditions");
      setTermsError(errorMsg);
    }
  };

  // GO BACK FUNCTION
  const navigate = () => {
    // IF WE NAVIGATE TO THIS PAGE FROM PROJECT FORM, THEN HIDE THE SIGN CONTRACT
    if (fromPage === "PROJECT_FORM") {
      hideSignContract();
    } else {
      // GO BACK FUNCTION
      props.history.goBack();
    }
  };

  // GET DF PACKAGES FROM API
  const GetDfPackages = async (solutionObj) => {
    const data = await SolutionService.get().getDfSolutionPackages(solutionObj, language);
    setDFPackagesList(data);
    /* NOTE: Basic Analyses should be selected by default */
    const packagesList = [];
    let packageObj = {};
    data.forEach((list) => {
      packageObj = { ...list };
      /* Basic analysis is not selected by default now
			if (list.PlanName === 'Basic Analysis') {
				packageObj.isSelected = 1;
			}
			 */
      packagesList.push(packageObj);
    });
    await setMultilist(packagesList);
    return packagesList;
  };

  // SELECT ALREADY OPTED PACKAGES
  const SelectAlreadyOptedPackages = async (packages) => {
    const selectedPackages = [];
    packages.forEach((list) => {
      if (list.isSelected === 1) {
        selectedPackages.push(list.PlanID);
      }
    });
    setSelectedDFPackages(selectedPackages);
  };

  // GET PACKAGES OF SELECTED SOLUTION
  const GetSelectedPackages = (packagesList) => {
    let selectedPackages = packagesList.filter((r) => r?.isselected === 1);
    if (selectedPackages.length > 0) {
      let selectedLowerPackages = packagesList.filter(
        (r) => r?.packagesize < selectedPackages[0].packagesize,
      );
      let packagesToBeDiabled = selectedLowerPackages.concat(selectedPackages);
      setOptedPackages(packagesToBeDiabled);
    }
  };

  // ASSIGN INITIAL FORM DATA
  const assignInitialFormData = () => {
    setPackageId();
    setTerms(false);
  };
  /* GET PACKAGES OF THE SOLUTION
	PASSING: solutionId: number, language: string
	*/
  const getSolutionPackage = async () => {
    try {
      setIsSolutionPackageLoading(true);
      var res = await SolutionService.get().getSolutionPackage({ solutionId, language });
      if (res.CODE) {
        setSolution(res.solution);
        setPrevious(res.previous);
        setNext(res.next);
        assignInitialFormData();
        const Packages = res?.packageList;
        const index = Packages?.findIndex((item) => item.title === "Ad Hoc");
        const element = Packages[index];
        let giPackages = [];

        const indexGI = Packages?.findIndex((item) => item.title === "Virtual Asset screening");
        const elementGI = Packages[indexGI];
        if (solutionId === 6) {
          Packages.push({
            id: 300,
            title: "Enterprise",
            price: null,
            body: {
              expiredin: "",
              PlanDescription: [
                { name: null },
                { name: null },
                { name: null },
                {
                  name: "Upon request",
                  value: "",
                  tick: "false",
                  plus: "false",
                  infoicon: "false",
                },
                { name: null },
                { name: null },
                { name: null },
              ],
            },
          });
        }

        if (elementGI) {
          giPackages = Packages.map((item) =>
            item.title === "Virtual Asset screening" ? { ...item, price: "Pricing upon request" } : item,
          );
        }

        if (element) {
          // eslint-disable-next-line
          Packages?.pop();
          // eslint-disable-next-line
          Packages?.unshift(element);
        }

        await setPackageList(elementGI ? giPackages.filter((el) => el) : Packages.filter((el) => el));
        await GetSelectedPackages(elementGI ? giPackages : Packages);
        setMultilist(res.multilist);
        if (res.solution.id === 2) {
          let packages = await GetDfPackages({ solutionId, language });
          await SelectAlreadyOptedPackages(packages);
        }
        setIsSolutionPackageLoading(false);
      } else {
        displayToastMessage(res.MSG, "error");
        setIsSolutionPackageLoading(false);
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // THIS FUNCTION RUNS ON CHOOSING A PACKAGE
  const handlePackage = (item) => {
    var list = [...packageList];
    list.map((res) => {
      if (res) {
        if (res.id === item.id) {
          res.isChecked = res?.isChecked === 1 ? 0 : 1;
        } else {
          res.isChecked = 0;
        }
      }
      return null;
    });
    setPackageList(list);
    if (item.id !== packageId) {
      setPackageId(item.id);
    } else {
      setPackageId();
    }
    ScrollToComponent("TermsAndConditionssolutions");
  };

  // CHECK IF DF PACKAGE IS SELECTED
  const checkIfDFPackageIsSelected = (item) => {
    const isIDExists = selectedDFPackages.indexOf(item.PlanID) >= 0;
    if (isIDExists) {
      return true;
    }
    return false;
  };

  // CHECK IF DF SELECTED
  const CheckIfDfSelected = () => {
    const isIDExists = selectedDFPackages.length > 0 ? true : false;
    if (isIDExists) {
      return true;
    }
    return false;
  };

  // FUNCTION TO HANDLE SELECTION OF DF PACKAGES
  const selectMultiListPackages = (item) => {
    if (item.isSelected != 1) {
      let selectedDFPackagesClone = [];
      const isIDExists = selectedDFPackages.indexOf(item.PlanID) >= 0;
      if (isIDExists) {
        // remove selected id
        selectedDFPackages.map((dfPackageID) => {
          if (item.PlanID === dfPackageID) {
            // skip
          } else {
            selectedDFPackagesClone.push(dfPackageID);
          }
          return null;
        });
      } else {
        // assign selected id
        /* Note: removed multi select
				selectedDFPackagesClone = [...selectedDFPackages];
				*/
        selectedDFPackagesClone.push(item.PlanID);
      }
      setSelectedDFPackages([...selectedDFPackagesClone]);
      ScrollToComponent("TermsAndConditionssolutions");
    }
  };

  // NAVIGATE TO PREVIOUS SOLUTION
  const handlePrev = () => {
    setSolutionId(previous.previd);
    setPackageId();
    setSelectedDFPackages([]);
    setTerms(false);
    const currentSolutionObj = solutionList.find((obj) => obj.id === previous.previd);
    setCurrentSolution(currentSolutionObj);
  };

  // NAVIGATE TO NEXT SOLUTION
  const handleNext = () => {
    setSolutionId(next.nextid);
    setPackageId();
    setSelectedDFPackages([]);
    setTerms(false);
    const currentSolutionObj = solutionList.find((obj) => obj.id === next.nextid);
    setCurrentSolution(currentSolutionObj);
  };

  // RUNS ON LOAD
  useEffect(() => {
    if ((solutionId, language)) {
      getSolutionPackage();
    } else {
      props.history.push(path.SOLUTION_LIST_PATH);
    }
  }, [solutionId, language]);

  // CHECK IF A DF PACKAGE IS SELECTED
  const areDFPackagesSelected = () => {
    const alreadySelectedPackages = [];
    DFPackagesList.forEach((r) => {
      if (r?.isSelected) {
        alreadySelectedPackages.push(r?.PlanID);
      }
    });
    let isNewSelected = false;
    selectedDFPackages.forEach((id) => {
      if (alreadySelectedPackages.indexOf(id) < 0) {
        isNewSelected = true;
      }
    });
    return isNewSelected;
  };

  return (
    <div className="review_solutions_sidebar">
      <div className="mt-3 mb-5 m-l-1">
        <div className="">
          <div className="col-md-12 p-0">
            <ButtonPrimaryWithIcon iconName={backCircle} onClick={navigate} ariaLabel={polyglot.t("Back")}>
              {polyglot.t("Back")}
            </ButtonPrimaryWithIcon>
          </div>
          <div className="row ml-0 mr-0">
            <div className="col-md-12 p-0">
              <br />
              <div className="d-flex mb-4 ml-0">
                <div className="col-md-5 nex-prev-b p-0">
                  {previous ? (
                    <div>
                      <div className="next-prev-text cursor_menu cursor" onClick={handlePrev}>
                        <ButtonIcon
                          onClick={handlePrev}
                          className="m-l-minus-5"
                          iconImg={backbutton}
                          alt={polyglot.t("left")}
                          ariaLabel={polyglot.t("Previous Solution:")}
                          title={polyglot.t("Previous solution")}
                        />
                        <p className="m-b-0 line-height-2point8 m-l-1 prevNextColor">
                          {polyglot.t("Previous Solution:")} &nbsp;
                          {previous.prevname}
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
                <div className="col-md-1 nex-prev-b"></div>
                {next ? (
                  <>
                    <div className="col-md-6">
                      <div className="next-prev-text cursor_menu cursor  full-width justify-content-end">
                        <p className="next-prev-text cursor prevNextColor" onClick={handleNext}>
                          {polyglot.t("Next Solution:")} &nbsp;{next.nextname}&nbsp;
                        </p>
                        <ButtonIcon
                          onClick={handleNext}
                          className="m-r-minus-10"
                          iconImg={info}
                          alt={polyglot.t("right")}
                          ariaLabel={polyglot.t("Next Solution:")}
                          title={polyglot.t("Next solution")}
                        />
                      </div>
                    </div>
                    {/* <div className="col-md-1 nex-prev-b cursor_menu pl-0">
												<ButtonIcon
													onClick={handleNext} 
													iconImg={info}
													alt={polyglot.t("right")}
													ariaLabel={polyglot.t("Next Solution:")}
													title={polyglot.t("Next solution")}
												/>
											</div> */}
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>

        {Object.keys(solution).length > 0 && (
          <div className="row mr-0">
            <SolutionBriefingCard solution={solution} />
          </div>
        )}
        <div className="p-0 mt-4">
          <div className="row mb-1 mr-0">
            {multilist.length > 0 && solution.availablitystatus && !isSolutionPackageLoading && (
              <ChooseDFPackages
                JSONArray={multilist}
                solution={solution}
                checkIfDFPackageIsSelected={checkIfDFPackageIsSelected}
                selectMultiListPackages={selectMultiListPackages}
                CheckIfDfSelected={CheckIfDfSelected}
              />
            )}
            {multilist.length === 0 &&
              packageList.length > 0 &&
              solution.availablitystatus &&
              !isSolutionPackageLoading && (
                <ChoosePackages
                  JSONArray={packageList}
                  solution={solution}
                  customNumberFormat={customNumberFormat}
                  handlePackage={handlePackage}
                  OptedPackages={OptedPackages}
                />
              )}
            {isSolutionPackageLoading && (
              <div className="col-md-12" style={{ textAlign: "center" }}>
                <LoadingSpinner width={54} height={54} fill={"#ef3829"} />
              </div>
            )}
          </div>
        </div>
        <div>{solution.availablitystatus && <PackageLegend />}</div>
        <div>{solution.id === 6 && <PackageLegendCss />}</div>

        {packageId != undefined || areDFPackagesSelected() ? (
          <>
            <TermsAndConditionssolutions solution={solution} />
            {hasCreatePermission(currentUserPermissions) && (
              <>
                {currentSolution && currentSolution.availablitystatus && (
                  <>
                    <br />
                    <div className="row pt-3 pb-3 m-0 mr-0">
                      <div className="col-md-8">
                        <div className="row mr-0">
                          <div>
                            <Checkbox
                              checked={terms}
                              onChange={() => {
                                setTerms((prev) => !prev);
                                setTermsError("");
                              }}
                              className="box-padding-reviewsolution"
                              aria-label={
                                terms
                                  ? polyglot.t("agreed terms and conditions")
                                  : polyglot.t("choose to agreed terms and conditions")
                              }
                            >
                              <div className=" iaccept subtitle_three_title_qb_dark">
                                {polyglot.t("I Accept, Terms and Conditions for")}
                              </div>
                            </Checkbox>
                          </div>
                        </div>
                        {termserror !== "" && <span className="errortext">* {termserror}</span>}
                      </div>
                      <div className="col-md-4"></div>
                    </div>
                    <div className="row m-0 mr-0">
                      <div className="col-md-8 p-0">
                        <ButtonPrimary
                          onClick={signContract}
                          disabled={isSubmitDisabled()}
                          ariaLabel={polyglot.t("Sign Contract")}
                        >
                          {polyglot.t("Sign Contract")}
                        </ButtonPrimary>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </>
        ) : null}
      </div>
    </div>
  );
}

ReviewSolution.propTypes = {
  history: PropTypes.object,
  location: PropTypes.object,
};

export default ReviewSolution;
