import React, { useState } from "react";

import "./AnalysisList.css";
import right from "../../../Assets/Images/DFED/icon-chevron-right-black.svg";
import "./CommonStyles.css";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import AnalysisService from "../../../Services/DFED/AnalysisService";
import { getDisplayDate } from "../../../Services/Shared/Handlers";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { infoIcon } from "../../../Assets/Icons";
import CustomModal from "../../../Assets/Components/Modal";

import { Accordions, AccordionItem, AccordionItemHeader, AccordionItemBody, Tooltip } from "appkit-react";

// ANALYSIS LIST COMPONENT IS RESPONSIBLE FOR LISTING ALL ANALYSIS CREATED===================
const Tooltiptext = (text) => {
  return (
    <div>
      <table>
        <tbody>
          <tr>
            <td
              style={{
                textAlign: "center",
                fontWeight: "bold",
                color: "#000",
                textTransform: "capitalize",
              }}
            >
              {text}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

const analysisService = new AnalysisService();

const getAnalysisDetailsById = async (analysisId) => {
  const { data: analysisDetails } = await analysisService.AnalysisDetailsById(analysisId);
  return analysisDetails;
};

const AnalysisList = (props) => {
  const polyglot = polyglotLoader();
  const [analysisesData, setAnalysisesData] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const openModal = () => {
    setShowModal(true);
    getAnalysisDetailsById(props.data.ID).then((analysisData) => setAnalysisesData(analysisData));
  };

  const closeModal = () => {
    setAnalysisesData(null);
    setShowModal(false);
  };

  const getCustodianNameById = (custodianId) => {
    const { CRT_FIRST_NAME, CRT_LAST_NAME } = analysisesData.custodians.find(
      (custodian) => custodian.CRT_ID === custodianId,
    );
    return `${CRT_FIRST_NAME} ${CRT_LAST_NAME || ""}`;
  };

  // CODE FOR SHOWING PROGRESS BAR BASED ON STATUS VALUE===================

  const progressBar = (prep, gen, run) => {
    let res = ["", "", "inprogress", "success"];
    return (
      <ul className="progressbar1 ">
        <li className={res["3"]}></li>
        <li className={res[prep]}></li>
        <li className={res[run]}></li>
        <li className={res[gen]}></li>
      </ul>
    );
  };

  return (
    <div>
      <CustomModal
        show={showModal}
        onCancel={closeModal}
        title={`${polyglot.t("Analysis Data")} for ${
          analysisesData ? analysisesData.analysis.ANALYSIS_NAME : ""
        }`}
        showCloseButton={true}
        size="sm"
      >
        <div className="modal-txt-open">
          {analysisesData && (
            <>
              <span className="analysis-label-binding">
                {polyglot.t("Created on")} :{" "}
                {analysisesData && analysisesData.analysis.CREATED_ON
                  ? getDisplayDate(analysisesData.analysis.CREATED_ON)
                  : "NA"}
              </span>
              <Accordions key={1} className="mb-2">
                <AccordionItem itemId={"1"} key={1}>
                  <AccordionItemHeader title="Custodians" />
                  <AccordionItemBody>
                    {analysisesData && analysisesData.custodians.length > 0 ? (
                      analysisesData.custodians
                        .map(({ CRT_FIRST_NAME, CRT_LAST_NAME }) => `${CRT_FIRST_NAME} ${CRT_LAST_NAME || ""}`)
                        .join(", ")
                    ) : (
                      <div>No Custodian</div>
                    )}
                  </AccordionItemBody>
                </AccordionItem>
                <AccordionItem itemId={"2"} key={2}>
                  <AccordionItemHeader title="Datasets" />
                  <AccordionItemBody>
                    {analysisesData && analysisesData.datasets.length > 0
                      ? analysisesData.datasets.map(({ CUSTODIAN_ID, DT_DATASET_NAME, DT_ID }) => (
                          <div key={`${CUSTODIAN_ID}-${DT_ID}`}>
                            Custodian: {getCustodianNameById(CUSTODIAN_ID)} | Dataset: {DT_DATASET_NAME}
                          </div>
                        ))
                      : "No datasets list"}
                  </AccordionItemBody>
                </AccordionItem>
                <AccordionItem itemId={"3"} key={3}>
                  <AccordionItemHeader title="Keywords list" />
                  <AccordionItemBody>
                    {analysisesData && analysisesData.keywords.length > 0
                      ? analysisesData.keywords.map(({ LIST_NAME }) => LIST_NAME).join(", ")
                      : "No Keyword list"}
                  </AccordionItemBody>
                </AccordionItem>
              </Accordions>
            </>
          )}
        </div>
      </CustomModal>
      <div className="row liat-name m-l-0 m-r-0 cursor-pointer margin-minus-0-1">
        <div className="ans-lst-div">
          <div className="row m-l-0 m-r-0">
            <div className=" col-md-3">
              <Tooltip placement="top" content={Tooltiptext(props.data.ANALYSIS_NAME)}>
                <div className="list-name analysis-label-binding project-title-overflow">
                  {props.data.ANALYSIS_NAME}
                </div>
              </Tooltip>
            </div>
            <div className=" col-md-3">
              <span className="list-name analysis-label-binding">
                {getDisplayDate(props.data.CREATED_ON)}
              </span>
            </div>

            {/* <div className=" col-md-1 p-0 pt-05">
                                <img id="listWait" src={img} alt="wait" className="" />
                                <label className="list-num "> {props.data.RUNTHROUGH}</label>
                            </div> */}

            <div className="  col-md-1p-0 tex-ali-c show-in-mob">
              <img src={right} alt="wait" className="pt-10 cursor-pointer" />
            </div>
            {/* <div className="col-md-3"></div> */}

            <div className="col-md-4 p-0">
              {progressBar(
                props.data.PREPARE_KEYWORD_STATUS,
                props.data.REPORT_GENERATED_STATUS,
                props.data.RUNNING_KEYWORD_STATUS,
              )}
            </div>

            <div className=" col-md-1 p-0 tex-ali-c hide-in-mob">
              <ButtonIcon
                iconImg={right}
                alt="expand"
                ariaLabel={"expand"}
                onClick={props.goToRunThrough}
                title={polyglot.t("Expand")}
              />
            </div>
            <ButtonIcon
              iconName={infoIcon}
              alt="info"
              onClick={openModal}
              ariaLabel={polyglot.t("More info")}
              title={polyglot.t("Info")}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AnalysisList;
