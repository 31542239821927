import displayToastMessage from "../../Components/QueenBase/Root/displayToastMessage";
import { LOGIN_PATH } from "../../Constants/Path";
import LoginService from "../../Services/QueenBase/Root/LoginService";

export const logout_on_intrusion = async () => {
  sessionStorage.clear();
  localStorage.clear();
  window.location.href = `${process.env.REACT_APP_PROJECT_NAME}${LOGIN_PATH}`;
};
export const expire_my_session = async () => {
  try {
    if (check_If_User_LoggedIn()) {
      // LOGOUT CURRENT USER
      const logoutRes = await LoginService.get().logoutCurrentUser();
      if (logoutRes.isIntrusion || logoutRes.CODE) {
        if (logoutRes.URL) {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = logoutRes.URL;
        } else {
          sessionStorage.clear();
          localStorage.clear();
          window.location.href = `${process.env.REACT_APP_PROJECT_NAME}${LOGIN_PATH}`;
        }
      } else {
        displayToastMessage(logoutRes.MSG, "error");
      }
    } else {
      sessionStorage.clear();
      localStorage.clear();
      window.location.href = `${process.env.REACT_APP_PROJECT_NAME}${LOGIN_PATH}`;
    }
  } catch (err) {
    displayToastMessage(err.message, "error");
  }
};

export const check_api_error_response = (errorMsg) => {
  if (errorMsg === "Request failed with status code 401") {
    alert("Your Jwt is expired. Please login again");
    expire_my_session();
  }
};

export const getUserIDFromSession = () => {
  const userObj = sessionStorage.getItem("user");
  if (userObj) {
    const parsedObj = JSON.parse(userObj);
    return parsedObj.userid;
  }
  return userObj;
};

export const getFirstNameFromSession = () => {
  const userObj = sessionStorage.getItem("user");
  const parsedObj = JSON.parse(userObj);
  return parsedObj.firstname;
};

// CALL API TO REFRESH JWT TOKEN
export const refresh_jwt_token = async () => {
  try {
    const refreshedJwtToken = await LoginService.get().refreshJWT();
    if (refreshedJwtToken.token) {
      sessionStorage.removeItem("jwt");
      sessionStorage.setItem("jwt", refreshedJwtToken.token);
      return "SUCCESS";
    } else {
      expire_my_session();
      return "FAILED";
    }
  } catch (err) {
    expire_my_session();
    return "FAILED";
  }
};

export const check_If_User_LoggedIn = () => {
  const userid = getUserIDFromSession();
  if (userid) {
    return true;
  }
  return false;
};
