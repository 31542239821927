import React from "react";
const ErrorPanel = (props) => {
  const handleClose = (e) => {
    e.preventDefault();
    props.onClear();
  };
  return (
    <div className="alert alert-danger d-flex align-items-center" role="alert">
      <svg className="bi flex-shrink-0 me-2" width="24" height="24" role="img" aria-label="Danger:"></svg>
      <div>{props.error}</div>
      <button
        type="button"
        className="btn btn-light"
        onClick={(e) => {
          handleClose(e);
        }}
      >
        Okay
      </button>
    </div>
  );
};
export default ErrorPanel;
