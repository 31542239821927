import axios from "axios";
import { useState, useCallback, useRef, useEffect } from "react";

import { getUserIDFromSession } from "../Shared/Authenticate";

export const useHttpPostClient = () => {
  const [isLoadingPost, setIsLoading] = useState(false);
  const [errorPost, setError] = useState();

  const activeHttpRequests = useRef([]);
  const generateHeadersModules = () => {
    return {
      Authorization: "Bearer " + sessionStorage.getItem("jwt"),
      project_id: sessionStorage.getItem("selectedProject"),
      loggedInUserId: getUserIDFromSession(),
    };
  };
  const sendRequestPost = useCallback(async (url, body = null) => {
    setIsLoading(true);
    const httpAbortCtrl = new AbortController();
    activeHttpRequests.current.push(httpAbortCtrl);

    try {
      const response = await axios.post(url, body, {
        headers: generateHeadersModules(),
      });

      const responseData = response.data;

      activeHttpRequests.current = activeHttpRequests.current.filter((reqCtrl) => reqCtrl !== httpAbortCtrl);

      setIsLoading(false);
      return responseData;
    } catch (err) {
      setError(err.message);
      setIsLoading(false);
      throw err;
    }
  }, []);

  const clearErrorPost = () => {
    setError(null);
  };

  useEffect(() => {
    return () => {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      activeHttpRequests.current.forEach((abortCtrl) => abortCtrl.abort());
    };
  }, []);

  return { isLoadingPost, errorPost, sendRequestPost, clearErrorPost };
};
