import { TextArea } from "appkit-react";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import * as Yup from "yup";

import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import CustomModal from "../../../Assets/Components/Modal";
import { FIELD_REGEX } from "../../../Constants/regex";
import AdminService from "../../../Services/QueenBase/Admin/AdminServices";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";

const MessageOfTheDayForm = ({ showMODForm, toggleMessageOfTheDayForm }) => {
  const { reloadMessageOfTheDay } = useContext(DataContext);
  const polyglot = polyglotLoader();
  const initialValues = {
    message_english: "",
    message_german: "",
  };

  const validationSchema = Yup.object().shape({
    message_english: Yup.string()
      .max(400, "Maximum character length is 400")
      .matches(FIELD_REGEX, polyglot.t("field_validaton"))
      .trim(),
    message_german: Yup.string()
      .max(400, "Maximum character length is 400")
      .matches(FIELD_REGEX, polyglot.t("field_validaton"))
      .trim(),
  });

  // save message of the day form
  const saveMessageForm = async (formBody) => {
    if (
      (formBody.message_english.length > 0 && formBody.message_german.length < 1) ||
      (formBody.message_english.length < 1 && formBody.message_german.length > 0)
    ) {
      displayToastMessage(polyglot.t("Please fill both language"), "error");
    } else {
      try {
        const APIResponse = await AdminService.get().saveMessageOfTheDay(formBody);
        if (APIResponse.CODE) {
          displayToastMessage(polyglot.t(APIResponse.MSG), "success");
          // trigger the reload to fetch the saved message of the day
          reloadMessageOfTheDay();
          // used to close the form
          toggleMessageOfTheDayForm();
        }
        if (!APIResponse.CODE) {
          displayToastMessage(polyglot.t(APIResponse.MSG), "error");
        }
      } catch (err) {
        displayToastMessage(err.message, "error");
      }
    }
  };

  return (
    <Formik onSubmit={saveMessageForm} initialValues={initialValues} validationSchema={validationSchema}>
      {(formikProps) => {
        const { values, handleChange, handleSubmit, errors, touched } = formikProps;
        return (
          <CustomModal
            show={showMODForm}
            onCancel={toggleMessageOfTheDayForm}
            title={polyglot.t("Message of the day form")}
            Footer={[
              <ButtonPrimary size="lg" key={1} onClick={handleSubmit}>
                {polyglot.t("Save")}
              </ButtonPrimary>,
            ]}
            size="md"
          >
            <>
              <div className="mb-3">
                <TextArea
                  placeholder={polyglot.t("Message in english")}
                  value={values.message_english}
                  onChange={handleChange("message_english")}
                  aria-label={polyglot.t("Message in english")}
                />
                {errors.message_english && touched.message_english && (
                  <div className="error">{polyglot.t(errors.message_english)}</div>
                )}
              </div>
              <div className="mb-3">
                <TextArea
                  placeholder={polyglot.t("Message in german")}
                  value={values.message_german}
                  onChange={handleChange("message_german")}
                  aria-label={polyglot.t("Message in german")}
                />
                {errors.message_german && touched.message_german && (
                  <div className="error">{polyglot.t(errors.message_german)}</div>
                )}
              </div>
            </>
          </CustomModal>
        );
      }}
    </Formik>
  );
};

MessageOfTheDayForm.propTypes = {
  showMODForm: PropTypes.bool,
  toggleMessageOfTheDayForm: PropTypes.func,
};
MessageOfTheDayForm.defaultProps = {
  showMODForm: false,
  toggleMessageOfTheDayForm: () => {},
};

export default MessageOfTheDayForm;
