import PropTypes from "prop-types";
import React, { createContext, useState } from "react";

//import { DataContext } from '../../Services/Shared/Store';
import StorageKeys from "../../../../Services/Shared/SessionKeys";
import { userLanguage } from "../../../../Services/Shared/Translate";

export const GRCAssessmentLoginContext = createContext();

const GRCAssessmentLoginContextProvider = (props) => {
  const [loginResponse, setLoginResponse] = useState({});

  const successfulLogin = (apiResponse_login) => {
    const responseObj = apiResponse_login ? apiResponse_login : loginResponse;
    sessionStorage.setItem("ispwc", responseObj.ispwc);
    sessionStorage.setItem("jwt", responseObj.jwt);
    delete responseObj.jwt;
    sessionStorage.setItem("user", JSON.stringify(responseObj));
    sessionStorage.setItem("isFirst", responseObj.isfirst);
    sessionStorage.setItem("userImage", null);
    sessionStorage.setItem("loginTimeStamp", new Date().toLocaleString());
    sessionStorage.removeItem("loginTimeStamp_BeforeJWT");
    setLoginResponse({});
  };
  const successful_FirstUser_Login = (updateFirstUserResponse) => {
    sessionStorage.setItem("ispwc", updateFirstUserResponse.ispwc);
    delete updateFirstUserResponse.jwt;
    sessionStorage.setItem("user", JSON.stringify(updateFirstUserResponse));
    sessionStorage.setItem("isFirst", updateFirstUserResponse.isfirst);
    sessionStorage.setItem("userImage", updateFirstUserResponse.userImage);
    sessionStorage.setItem("loginTimeStamp", new Date().toLocaleString());
    sessionStorage.removeItem("loginTimeStamp_BeforeJWT");
    setLoginResponse({});
  };
  const initializeLogin = () => {
    setLoginResponse({});
    const choosenLanguage = userLanguage();
    if (!choosenLanguage) {
      sessionStorage.clear();
      sessionStorage.setItem(StorageKeys.USERLANGUAGE, "en");
    } else {
      sessionStorage.clear();
      sessionStorage.setItem(StorageKeys.USERLANGUAGE, choosenLanguage);
    }
    //localStorage.clear();
  };

  const providerValue = {
    loginResponse,
    setLoginResponse,
    successfulLogin,
    successful_FirstUser_Login,
    initializeLogin,
  };
  return (
    <GRCAssessmentLoginContext.Provider value={{ ...providerValue }}>
      {props.children}
    </GRCAssessmentLoginContext.Provider>
  );
};

GRCAssessmentLoginContextProvider.propTypes = {
  children: PropTypes.any,
};

export default GRCAssessmentLoginContextProvider;
