import React from "react";

import { polyglotLoader } from "../../../Services/Shared/Translate";

// THIS COMPONENT IS USED TO DISPLAY NO ACCESS CONTENT ON THE SCREEN
const PerformInvalid = () => {
  const polyglot = polyglotLoader();
  return (
    <>
      <div className="row mt-5" style={{ marginRight: "0px" }}>
        <div className="col-md-3"></div>
        <div className="col-md-6" style={{ textAlign: "center" }}>
          <span className="appkiticon icon-alert-fill no-access-icon" />
          <p className="noaccess_header mt-3 mb-2">
            {polyglot.t("You don't have permission to view this page")}
          </p>
        </div>
        <div className="col-md-3"></div>
      </div>
    </>
  );
};

export default PerformInvalid;
