import React, { useContext, useEffect, useState } from "react";

import displayToastMessage from "../../../Components/QueenBase/Root/displayToastMessage";
import AdminService from "../../../Services/QueenBase/Admin/AdminServices";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";

function MessageOfTheDay() {
  const { loadMessageOfTheDay, stopReloadMessageOfTheDay, getUserLanguage } = useContext(DataContext);
  const [messages, setMessages] = useState("");
  const polyglot = polyglotLoader();
  const userLanguage = getUserLanguage();

  // fetch message of the day
  const fetchMessageOfTheDay = async () => {
    try {
      const APIResponse = await AdminService.get().fetchMessageOfTheDay();
      if (APIResponse.CODE) {
        setMessages(APIResponse.messages);
      }
      if (!APIResponse.CODE) {
        displayToastMessage(polyglot.t(APIResponse.MSG), "error");
      }
      // As we fetched messageOfTheDay, stop the reload of message of the day
      stopReloadMessageOfTheDay();
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  useEffect(() => {
    // fetch message of the day if loadMessageOfTheDay is true
    if (loadMessageOfTheDay) {
      fetchMessageOfTheDay();
    }
  }, [loadMessageOfTheDay]);

  useEffect(() => {
    fetchMessageOfTheDay();
  }, []);

  return (
    <>
      {messages.message_in_english && messages.message_in_english != "" && (
        <div className="bg-messageOfTheDay">
          <div className="container col-md-10 p-0 textColordiv">
            {userLanguage === "en" && <div className="m-l-1 pt-1 pb-1">{messages.message_in_english}</div>}
          </div>
        </div>
      )}
      {messages.message_in_german && messages.message_in_german != "" && (
        <div className="bg-messageOfTheDay">
          <div className="container col-md-10 p-0 textColordiv">
            {userLanguage === "ger" && <div className="m-l-1 pt-1 pb-1">{messages.message_in_german}</div>}
          </div>
        </div>
      )}
    </>
  );
}

export default MessageOfTheDay;
