import React, { useState, useEffect } from "react";

import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/GRC/loading-spin.svg";
import RecipientService from "../../../Services/GRC/RecipientService";
import { useHttpRemoteClient } from "../../../Services/GRC/http-hook-remote";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import ErrorPanel from "../Shared/ErrorPanel";
import "./Pages/index.css";

const RecipientQuestionPreview = ({ parentId, handleChanged, totalQuestions, setid, loadtype }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpRemoteClient();
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);

  const polyglot = polyglotLoader();
  const getSelectedQuestionaries = async () => {
    try {
      const uid = sessionStorage.getItem("grc_recipient_user_uid");
      const responseData = await sendRequest(
        RecipientService.get().getRecipientAassessmentQuestionsByUidURL(parentId, uid),
      );

      if (responseData) {
        setAssessmentQuestions(responseData);
      }
    } catch (err) {}
  };
  useEffect(() => {
    getSelectedQuestionaries();
  }, [sendRequest, setid]);

  return (
    <>
      {error && <ErrorPanel error={error} onClear={clearError} />}
      {isLoading && (
        <div>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      )}

      {!isLoading &&
        assessmentQuestions.length > 0 &&
        assessmentQuestions.map((item, i) => {
          return (
            <div
              key={item.value}
              className="p-0"
              onClick={(e) => {
                handleChanged(e, item.value, "ques", i + 1);
              }}
            >
              <div className="quest-style">
                {item && (
                  <span
                    fontSize="small"
                    className={` appkiticon icon-circle-checkmark-fill custom-grc-icon ${
                      item.skip === -1 ? "" : item.skip === 1 ? "partial-icon" : "green-icon"
                    }`}
                  />
                )}

                {parseInt(item.value) === parseInt(setid) && loadtype === "ques" ? (
                  <span className="active-question">
                    {" "}
                    {polyglot.t("Question")} {i + 1}
                  </span>
                ) : (
                  <span className="quest_pading">
                    {polyglot.t("Question")} {i + 1}
                  </span>
                )}
              </div>
            </div>
          );
        })}
    </>
  );
};
export default RecipientQuestionPreview;
