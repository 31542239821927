import { Progress } from "antd";
import { Panel } from "appkit-react";

import { upperFirst } from "lodash";
import React, { useContext, useState } from "react";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonSecondaryWithIcon from "../../../Assets/Components/ButtonSecondary/ButtonSecondaryWithIcon";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";
import { backCircle, closeIcon } from "../../../Assets/Icons";
import ProjectSurveyService from "../../../Services/DF/ProjectSurveyService";
import ProjectService from "../../../Services/QueenBase/Projects/ProjectService";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { upload_bulk_files_to_api } from "../../../Services/Shared/UploadFilesToAPI";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import CustomDropZone from "../Start/SharedComponents/CustomDropZone";

const ProvideData = (props) => {
  const { data, goBack } = props;
  const { workflow_id, backToUpload, project_id, workflow_name } = data;
  const { formGotOpened, formGotClosed, isFormOpen } = useContext(DataContext);
  const [fileObj, setFileObj] = useState({});
  const [uploadPercent, setUploadPercent] = useState(0);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  var polyglot = polyglotLoader();

  // HANDLES FILE UPLOAD FUNCTION
  const fileUploaded = async (file) => {
    formGotOpened();
    setFileObj({
      file: file,
      name: file[0].name,
      size: file[0].size,
    });
  };
  // CATCH THE PROGRESS OF UPLOAD FUNCTION
  const catchProgress = (progress) => {
    const percent = progress[fileObj.name].percentage.toFixed(2);
    setUploadPercent(percent);
  };
  /* GET PROJECT NAME FROM PROJECT ID FROM API */
  const getProjectNameFromID = async () => {
    return new Promise(async (resolve) => {
      try {
        const projectNameRes = await ProjectService.get().getProjectNameFromID(project_id);
        if (projectNameRes.CODE === 1) {
          resolve(projectNameRes.name);
        }
      } catch (err) {
        displayToastMessage(err, "error");
      }
    });
  };
  // GET WORKFLOW NAME FROM ID
  const getWorkflowNameFromID = async () => {
    return new Promise(async (resolve) => {
      try {
        // API TO GET WORKFLOW NAME FROM ID
        const workflowNameRes = await ProjectSurveyService.get().getWorkflowNameFromID(workflow_id);
        if (workflowNameRes.CODE === 1) {
          resolve(workflowNameRes.name);
        }
      } catch (err) {
        displayToastMessage(err, "error");
      }
    });
  };

  // GO BACK HOME
  const navigateBack = () => {
    props.history.goBack();
  };
  // GO BACK TO HOME
  const backToHome = () => {
    props.history.push(`/df/home`);
  };

  // UPLOAD DATA TO API
  const uploadData = async () => {
    const projectName = await getProjectNameFromID();
    const workflowName = await getWorkflowNameFromID();

    const body = {
      projectName,
      workflowName,
    };
    const stringifiedBody = JSON.stringify(body);
    const URL = `${process.env.REACT_APP_SERVER_DF_BASE_HOST}/df_uploadXtractorZip`;
    const FILES = fileObj.file;
    try {
      // UPLOAD BUILK FILES TO API
      const res = await upload_bulk_files_to_api(URL, FILES, catchProgress, stringifiedBody);
      if (res.CODE) {
        displayToastMessage(res.MSG, "success");
      } else {
        let MSG = "";
        if (res.MSG) {
          MSG = res.MSG;
        } else {
          MSG = "Something went wrong, please try again later !!";
        }
        displayToastMessage(MSG, "error");
      }
    } catch (error) {
      displayToastMessage(error.message, "error");
    }
  };

  // SUBMIT UPLOADED FILE DETAILS
  const pushIntoProcessing = async () => {
    const body = {
      projectSurveyId: workflow_id,
      processStatusId: 1,
      fileName: fileObj.name,
      projectId: project_id,
    };
    setDisableSubmit(true);
    try {
      // API TO SAVE UPLOADED FILE DETAILS OF A WORKFLOW
      const res = await ProjectSurveyService.get().updateProcessStatusOfProjectSurvey(body);
      if (res.CODE) {
        displayToastMessage(polyglot.t("Successfully pushed to processing"), "success");
        backToHome();
        formGotClosed();
      } else {
        displayToastMessage(res.MSG, "error");
        setDisableSubmit(false);
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
      setDisableSubmit(false);
    }
  };

  // REMOVE UPLOADED FILE OBJECT
  const removeFileObj = () => {
    setFileObj({});
    setUploadPercent(0);
  };

  // CHECK IF UPLOAD BUTTON IS DISABLED
  const isUploadButtonDisabled = () => {
    let isDisabled = false;
    if (!fileObj.name) {
      isDisabled = true;
    }
    if (Number(uploadPercent) > 0 && Number(uploadPercent) <= 100) {
      isDisabled = true;
    }
    return isDisabled;
  };

  // VIEW TO UPLOAD FILE
  const UploadFileView = () => {
    if (!fileObj.name) {
      return (
        <div>
          <div className="Content">
            <div>
              <CustomDropZone
                onFilesAdded={fileUploaded}
                customDropZoneBoxStyle={{
                  width: "100%",
                  margin: "0",
                  padding: "30px",
                }}
              />
            </div>
          </div>
        </div>
      );
    }
  };
  // DISPLAY UPLOADED FILES
  const displayUploadedFiles = () => {
    if (fileObj.name) {
      const sizeInKB = fileObj.size / 1000;
      const sizeInMB = fileObj.size / 1024 / 1024;
      return (
        <div>
          <span>
            <b>{fileObj.name}</b>
          </span>
          <div className="row">
            <div className={Number(uploadPercent) === 0 ? "col-md-11" : "col-md-12"}>
              <Progress percent={Number(uploadPercent)} size="small" />
            </div>
            {Number(uploadPercent) === 0 && (
              <div className="col-md-1 text-align-right" style={{ marginTop: "-1%" }}>
                <ButtonIcon
                  onClick={removeFileObj}
                  iconName={closeIcon}
                  ariaLabel={polyglot.t("Close")}
                  title={polyglot.t("Close")}
                />
              </div>
            )}
          </div>

          <div className="row">
            <div className="col-md-6">
              {sizeInMB > 1 && <p className="text-align-left">{sizeInMB.toFixed(2)} MB</p>}
              {sizeInMB < 1 && <p className="text-align-left">{sizeInKB.toFixed(2)} KB</p>}
            </div>
            <div className="col-md-6">
              {Number(uploadPercent) < 100 && Number(uploadPercent) > 0 && (
                <p className="text-align-right">{polyglot.t("Uploading...")}</p>
              )}
              {Number(uploadPercent) === 100 && (
                <p className="text-align-right">
                  {polyglot.t("Uploaded")} <span className="appkiticon icon-check-mark-fill pointer"></span>
                </p>
              )}
            </div>
          </div>
        </div>
      );
    }
  };

  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  const toggleConfirmBox = () => {
    setOpenConfirmBox(!openConfirmBox);
  };

  // CLOSE FORM AND NAVIGATE TO VIEW PROJECTS
  const closeFormAndRedirect = () => {
    toggleConfirmBox();
    formGotClosed();
    navigateBack();
  };

  const checkAndNavigateBack = () => {
    try {
      if (!isFormOpen) {
        navigateBack();
      } else {
        toggleConfirmBox();
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  return (
    <div className="DF-mt-3">
      <div className="col-md-6" style={{ minHeight: "60vh" }}>
        <Panel className="provideData-panel a-panel padding40">
          <div className="downloadPrmtrMdlHdn">
            {polyglot.t("Upload data ")}&nbsp;{upperFirst(workflow_name)}
          </div>
          <div>
            <p className="provideData-description">
              {polyglot.t("Please upload the ZIP file created by Xtractor.")}
            </p>
          </div>
          {UploadFileView()}
          {displayUploadedFiles()}

          <div className="row">
            <div className="col-md-6 mt-4">
              <ButtonSecondaryWithIcon
                onClick={checkAndNavigateBack}
                iconName={backCircle}
                ariaLabel={polyglot.t("Back")}
              >
                {polyglot.t("Back")}
              </ButtonSecondaryWithIcon>
            </div>
            {Number(uploadPercent) === 100 ? (
              <div className="col-md-6 mt-4 text-align-right">
                <ButtonPrimary
                  onClick={pushIntoProcessing}
                  disabled={disableSubmit}
                  ariaLabel={polyglot.t("Push into processing")}
                >
                  {polyglot.t("Push into processing")}
                </ButtonPrimary>
              </div>
            ) : (
              <div className="col-md-6 mt-4 text-align-right">
                <ButtonPrimary
                  onClick={uploadData}
                  disabled={isUploadButtonDisabled()}
                  ariaLabel={polyglot.t("Upload data")}
                >
                  {polyglot.t("Upload data")}
                </ButtonPrimary>
              </div>
            )}
          </div>
        </Panel>
      </div>
      <ConfirmBoxExitForm
        show={openConfirmBox}
        heading={"Cancel update"}
        cancel={toggleConfirmBox}
        proceed={closeFormAndRedirect}
      />
    </div>
  );
};

export default ProvideData;
