import io from "socket.io-client";

import { getUserIDFromSession } from "./Authenticate";
import displayToastMessage from "../../Components/QueenBase/Root/displayToastMessage";

const subscribeToNotifications = (language, cb) => {
  const token = "Bearer " + sessionStorage.getItem("jwt");
  const loggedinUserid = getUserIDFromSession();
  const socket = io(`${process.env.REACT_APP_NOTIFICATION_SERVER_HOST}/get-bell-notifications`, {
    auth: {
      token,
      loggedinUserid,
    },
    query: {
      language,
    },
    transports: ["websocket"],
  });

  const socketConnect = () => {
    try {
      /* socket connected */
      const socketBody = {
        userid: loggedinUserid,
      };
      socket.emit("subscribeToNotifications", JSON.stringify(socketBody));
      socket.on("get-notifications", (data) => cb(data));
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  socket.on("connect", socketConnect);
  socket.on("disconnect", () => {
    /* socket disconnected */
    // do not end the socket - let socket try reconnecting
    // socket.emit('end')
  });
  socket.on("connect_error", () => {
    /* socket disconnected */
    // do not end the socket - let socket try reconnecting
    // socket.emit('end')
  });
};

export { subscribeToNotifications };
