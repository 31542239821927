import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import { LoginContext } from "./LoginContext";
import UpdatePassword from "./UpdatePassword";
import { LOGIN_PATH } from "../../../Constants/Path";

const EnterNewPassword = (props) => {
  const { loginResponse } = useContext(LoginContext);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (loginResponse.CODE) {
      setOpen(true);
    } else {
      props.history.push(LOGIN_PATH);
    }
  }, []);

  return (
    <>
      {open && (
        <div className="login-bg-image">
          <UpdatePassword history={props.history} />
        </div>
      )}
    </>
  );
};
EnterNewPassword.propTypes = {
  history: PropTypes.object,
};

export default EnterNewPassword;
