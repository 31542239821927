import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import $ from "jquery";

import "antd/dist/antd.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import moment from "moment";
import { useHistory } from "react-router-dom";

import { CREATE_QUESTIONNAIRE_PATH, CREATE_CATEGORY_PATH } from "../../../Constants/Path";
import "./index.css";
import DeleteListView from "./DeleteListView";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
$.DataTable = require("datatables.net-dt");

/**
 * display the page ui in table view
 *
 * @param {array} table_data
 * @returns table view
 */
const HomeListViewContainer = ({ table_data = [], getCategories }) => {
  const history = useHistory();
  const polyglot = polyglotLoader();
  const map = {};
  const roots = [];
  const [dataForTbl, setDataForTbl] = useState([]);
  const [questionnaireToDelete, setQuestionnaireToDelete] = useState({});
  const [openDeleteConfirmBox, setOpenDeleteConfirmBox] = useState(false);

  const closeDeleteConfirmBox = () => {
    try {
      // close delete confirm box
      setOpenDeleteConfirmBox(false);
      setQuestionnaireToDelete({});
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  const drawDataTable = () => {
    const data = dataForTbl;
    const CONST_DESC_SHORT_LENGTH = 18;
    const table = $("#grcHomeGridTable").DataTable({
      lengthMenu: [5, 10, 15, 20],
      Language: {
        emptyTable: `${polyglot.t("No data available")}`,
        sLengthMenu: `${polyglot.t("Show")} <select class="tabFocus">
                          <option value="5">5</option>
                          <option value="10">10</option>
                          <option value="15">15</option>
                          <option value="20">20</option>
                          <option value="-1">${polyglot.t("All")}</option>
                          </select> ${polyglot.t("entries")}
                          `,
        sSearch: `${polyglot.t("Search")}`,
      },
      columns: [
        { data: "serial", title: polyglot.t("Sl. No"), searchable: false },
        {
          data: "name",
          title: polyglot.t("name.label"),
          class: "td-width",
        },
        { data: "question_count", title: polyglot.t("Question Count") },
        {
          title: polyglot.t("category.label"),
          orderable: true,
          mRender: (data, type, row) => {
            //Giving one extra space after ,
            return row.category.replace(/\s*\,/g, ", ");
          },
        },
        {
          title: polyglot.t("Sub-Category"),
          orderable: true,
          class: "td-width",
          mRender: (data, type, row) => {
            //Giving one extra space after ,
            const hoghlightText = row.subcategory.replace(/\s*\,/g, ", ");
            return '<div class="category-width" title="' + hoghlightText + '">' + hoghlightText + "</div>";
          },
        },
        {
          title: polyglot.t("Actions"),
          orderable: false,
          data: null,
          mRender: (data, type, row) => {
            let deleteIcon =
              ' &nbsp; &nbsp; <button aria-label="' +
              polyglot.t("delete") +
              '" class="appkiticon icon-delete-fill processGridEditIcon"  id="delete_datasets_' +
              row.id +
              '">  </button>';
            let editIcon =
              '<button aria-label="' +
              polyglot.t("edit") +
              '" class="appkiticon icon-edit-outline " id="edit_datasets_' +
              row.id +
              '">  </button> ';

            if (row.asigned_assessments.length > 0) {
              deleteIcon =
                ' &nbsp; &nbsp; <button aria-label="' +
                polyglot.t("delete") +
                '" class="appkiticon icon-delete-fill iconColor"  id="delete_datasets_' +
                row.id +
                '" disabled= "disabled">  </button>';

              editIcon =
                '<button aria-label="' +
                polyglot.t("edit") +
                '" class="appkiticon icon-edit-outline iconColor" id="edit_datasets_' +
                row.id +
                '" disabled= "disabled">  </button> ';
            }
            return (
              editIcon +
              deleteIcon +
              '&nbsp; &nbsp; <button aria-label="' +
              polyglot.t("view") +
              '" class="appkiticon icon-view-fill processGridViewIcon" id="view_datasets_' +
              row.id +
              '">  </button> '
            );
          },
        },

        {
          className: "details-control",
          orderable: false,
          data: null,
          searchable: false,
          defaultContent: "",
        },
      ],
      order: [[0, "desc"]],
      data: data,
      destroy: true,
      lengthType: "simple",
      language: {
        searchPlaceholder: polyglot.t("search"),
        search: "",
        info: "_PAGE_ of _PAGES_",
        paginate: {
          firt: "<<",
          previous: '<i_ngcontent-c10="" class="appkiticon icon-left-chevron-fill"> </i>',
          next: '<i_ngcontent-c10="" class="appkiticon icon-right-chevron-fill"> </i>',
          last: ">>",
        },
      },
      info: false,
      stateSave: false,
      drawCallback: function () {
        if (data.length <= 0) {
          $(".dataTables_paginate").hide();
        } else {
          $(".dataTables_paginate").show();
        }
      }, // OVER : drawback function
    });
    // Add event listener for opening and closing details
    $("#grcHomeGridTable tbody").on("click", "td.details-control", function () {
      let tr = $(this).closest("tr");
      let row = table.row(tr);

      if (row.child.isShown()) {
        // This row is already open - close it
        row.child.hide();
        tr.removeClass("shown");
      } else {
        // Open this row
        row.child(format(row.data())).show();
        tr.addClass("shown");
      }
    });
    const handleDeleteQuestionnaire = (questionnaireItem) => {
      try {
        // open delete confirm box
        setOpenDeleteConfirmBox(true);
        setQuestionnaireToDelete(questionnaireItem);
      } catch (err) {
        displayToastMessage(err.message, "error");
      }
    };

    $(document).on("click", "[id*='view_datasets_']", function () {
      const idData = this.id.split("_");
      const body = {
        id: idData[2],
      };
      history.push({
        pathname: `${CREATE_QUESTIONNAIRE_PATH}/${body.id}`,
        state: { mode: "add", id: 0 },
      });
    });
    $(document).on("click", "[id*='edit_datasets_']", function () {
      const idData = this.id.split("_");
      const body = {
        id: idData[2],
      };

      history.push({
        pathname: `${CREATE_CATEGORY_PATH}/${body.id}`,
        state: { mode: "edit", id: body.id },
      });
    });
    $(document).on("click", "[id*='delete_datasets_']", function () {
      const idData = this.id.split("_");

      let targetbody = dataForTbl.filter((e) => e.id == idData[2]);
      if (targetbody.length > 0) {
        const body = {
          id: idData[2],
          name: targetbody[0].name,
        };

        handleDeleteQuestionnaire(body);
      }
    });
    $(document).on("click", "[id*='edit_datasets_']", function () {
      const idData = this.id.split("_");
      const body = {
        id: idData[2],
      };

      history.push({
        pathname: `${CREATE_CATEGORY_PATH}/${body.id}`,
        state: { mode: "edit", id: body.id },
      });
    });
    $(document).on("click", "[id*='edit_datasets_']", function () {
      const idData = this.id.split("_");
      const body = {
        id: idData[2],
      };

      history.push({
        pathname: `${CREATE_CATEGORY_PATH}/${body.id}`,
        state: { mode: "edit", id: body.id },
      });
    });
    $(document).on("click", "[id*='edit_datasets_']", function () {
      const idData = this.id.split("_");
      const body = {
        id: idData[2],
      };

      history.push({
        pathname: `${CREATE_CATEGORY_PATH}/${body.id}`,
        state: { mode: "edit", id: body.id },
      });
    });
  };

  const getCount = (s) => {
    if (s.length === 0) {
      return "N/A";
    }
    //ele1.question_count,
    let questionCount = 0;
    for (let j = 0; j < s.length; j++) {
      if (s[j].children.length > 0) {
        for (let g = 0; g < s[j].children.length; g++) {
          questionCount = questionCount + parseInt(s[j].children[g].question_count);
        }
      }
    }
    return questionCount;
  };
  const getSubCategory = (s) => {
    if (s.length === 0) {
      return "N/A";
    }

    let subcategorieNames = [];
    for (let j = 0; j < s.length; j++) {
      if (s[j].children.length > 0) {
        for (let g = 0; g < s[j].children.length; g++) {
          subcategorieNames.push(s[j].children[g].name);
        }
      }
    }

    if (subcategorieNames.length > 3) {
      const firstThree = subcategorieNames.slice(0, 3);
      return (
        firstThree.join(",") +
        " + <span class='category-count'>" +
        (subcategorieNames.length - 3).toString() +
        "</span>"
      );
    }
    return subcategorieNames.join(",");
  };
  const getCategory = (v) => {
    if (v.length === 0) {
      return "N/A";
    }
    let categories = [];
    v.map((item) => {
      return categories.push(item.name);
    });
    if (categories.length > 3) {
      const firstThree = categories.slice(0, 3);
      return (
        firstThree.join(",") +
        " + <span class='category-count'>" +
        (categories.length - 3).toString() +
        "</span>"
      );
    }
    return categories.join(",");
  };
  const getAssesments = (jsonData) => {
    if (jsonData) {
      const loadData = JSON.stringify(jsonData);
      const loadData1 = JSON.parse(loadData);

      return loadData1;
    }
    return "";
  };

  const prepareTableData = () => {
    table_data.forEach((v, i) => {
      map[v.id] = i;
      table_data[i].children = [];
    });

    table_data.forEach((v) =>
      v.parent_id !== 0 ? table_data[map[v.parent_id]].children.push(v) : roots.push(v),
    );
    let requiredTableData = [];
    if (roots) {
      roots.forEach((ele1, i) => {
        requiredTableData.push({
          serial: i + 1,
          id: ele1.id,
          name: ele1.name,
          category: getCategory(ele1.children),
          subcategory: getSubCategory(ele1.children),
          description: ele1.description,
          asigned_assessments: getAssesments(ele1.asigned_assessments),
          question_count: getCount(ele1.children),
          created_by: ele1.created_by,
          created_date: ele1.created_date,
          modified_by: ele1.modified_by,
          modified_date: ele1.modified_date,
          is_locked: ele1.is_locked,
          count: ele1.created_by,
        });
      }); //OVER : MAIN FOREACH
    }
    setDataForTbl(requiredTableData);
  };
  useEffect(() => {
    prepareTableData();
  }, [table_data]);

  useEffect(() => {
    drawDataTable();
  }, [dataForTbl]);

  const format = (d) => {
    if (d !== undefined) {
      return `<div className='row'><p><strong>Description :</strong> ${d.description}</p></div>
        <div className='row'><p><strong>Assigned Assessments :</strong> ${
          d.asigned_assessments.length > 0
            ? d.asigned_assessments
                .substring(1, d.asigned_assessments.length - 1)
                .split("},{")
                .map((itm, i) => {
                  return "<span>" + JSON.parse("{" + itm + "}").name + "</span>";
                })
            : "-N/A-"
        }</p></div>
        <div className='row'><p><strong>Created By :</strong> ${
          d.created_by
        }, <strong>Created Date :</strong> ${moment(d.created_date).format("DD.MM.YYYY")}</p></div>`;
    } else {
      return "<div>Loading...</div>";
    }
    // `d` is the original data object for the row
  };

  return (
    <>
      <div className="processGridContainer">
        <div className="col-md-12 DF-process-admin dataTables_length searchData" id="datatable-keytable">
          <table
            id="grcHomeGridTable"
            className="display table-header-focus processGrid sortingalignment"
            width="100%"
          />
        </div>
        <DeleteListView
          Item={questionnaireToDelete}
          show={openDeleteConfirmBox}
          close={closeDeleteConfirmBox}
          getCategories={getCategories}
        />
      </div>
    </>
  );
};
HomeListViewContainer.propTypes = {
  table_data: PropTypes.array,
};

HomeListViewContainer.defaultProps = {
  table_data: [],
};
export default HomeListViewContainer;
