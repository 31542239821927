//this component has only  login with idam  Functionality
import "../../../Assets/Css/QueenBase/Root/Login.css";

import { Panel } from "appkit-react/lib/Panel";
import React, { useContext, useEffect } from "react";

import { LoginContext } from "./LoginContext";
import displayToastMessage from "./displayToastMessage";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import LinkText from "../../../Assets/Components/LinkText/LinkText";
import { openExternalUrl } from "../../../Services/Shared/Handlers";
import { DataContext } from "../../../Services/Shared/Store.js";
import { polyglotLoader } from "../../../Services/Shared/Translate";

function LoginIdam(props) {
  const { getUserLanguage } = useContext(DataContext);
  const { initializeLogin } = useContext(LoginContext);
  var polyglot = polyglotLoader(getUserLanguage());

  useEffect(() => {
    initializeLogin();
    document.body.classList.remove("bg-img");
  }, []);

  // RUN IDAM LOGIN FUNTION ON CLICKING ON LOGIN BUTTON
  const onIDAMLogin = () => {
    try {
      window.location = `${process.env.REACT_APP_SERVER_HOST_BASE}/idamAuthenticate`;
    } catch (err) {
      displayToastMessage(this.polyglot.t("Something went wrong!!"), "error");
    }
  };
  return (
    <div>
      <div className="login-bg-image">
        <div className="container">
          <div className="login-form">
            <div className="idam-login-form-wrapper">
              <div className="card bg-light mb-5 margin-0-auto min-width-25">
                <div className="card-header header_login Header_resposnive_login">
                  <h1 className="headerText_Login">{polyglot.t("Welcome to Queen")}</h1>
                </div>
                <div className="card-body login-idam-user-login">
                  <div className="col-md-12 login-button-fullpadding text-align-center">
                    <ButtonPrimary
                      className="full-width-btn"
                      onClick={onIDAMLogin}
                      ariaLabel={polyglot.t("Login")}
                    >
                      {polyglot.t("Login")}
                    </ButtonPrimary>
                  </div>
                </div>
              </div>
              <>
                <Panel className="login-idam-info-card">
                  <div className="row">
                    <div className="col-md-12 login-idam-user-login">
                      {polyglot.t(`For information on PwC's Connected Digital Services, please visit us`)}
                      &nbsp;
                      {getUserLanguage() == "en" ? (
                        <LinkText
                          className="darkTextUnderline footerAnchor"
                          onClick={() =>
                            openExternalUrl(
                              "https://www.pwc.de/en/consulting/forensic-services/connected-digital-services.html",
                            )
                          }
                          ariaLabel={"here"}
                        >
                          {polyglot.t(`here`)}
                        </LinkText>
                      ) : (
                        <LinkText
                          className="darkTextUnderline footerAnchor"
                          onClick={() =>
                            openExternalUrl(
                              "https://www.pwc.de/de/managementberatung/forensic-services/connected-digital-services.html",
                            )
                          }
                          ariaLabel={"here"}
                        >
                          {polyglot.t(`here`)}
                        </LinkText>
                      )}
                    </div>
                  </div>
                </Panel>
              </>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginIdam;
