import React, { useState, useEffect } from "react";

import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/GRC/loading-spin.svg";
import RecipientService from "../../../Services/GRC/RecipientService";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import EmptyPanel from "../Shared/EmptyPanel";
import ErrorPanel from "../Shared/ErrorPanel";
import { GetBreadcrumHTMLByString } from "../handlers";

const RightQuestionnaireText = ({ id }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [categoryData, setcategoryData] = useState({});

  const getSelectedQuestionaries = async () => {
    try {
      const responseData = await sendRequest(RecipientService.get().getTextDataURL(id));

      if (responseData) {
        setcategoryData(responseData);

        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    if (id !== undefined) {
      getSelectedQuestionaries();
    }
  }, [sendRequest, id]);
  return (
    <div className="row p-0 mr-3">
      {error && <ErrorPanel error={error} onClear={clearError} />}
      {isLoading && (
        <div>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      )}

      {!isLoading && !categoryData && <EmptyPanel massage={"No data found."} />}
      {!isLoading && categoryData && (
        <div className="col-md-12 mx-3">
          <GetBreadcrumHTMLByString breadcrumString={categoryData.breadcrum} />
          <hr></hr>
          <div className="custome-min-height">
            <p className="p-3">
              <span className="main_title_qb1 headingtitle">Description: </span>
              {categoryData.description}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
export default RightQuestionnaireText;
