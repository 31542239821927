import React, { useEffect } from "react";

import { DashboardRoutes } from "../../../Routes/DFModuleRoutes";
import "../../../Assets/Css/DF/Upload/upload.css";

// RENDERS PATHS OF DASHBOARD
const Dashboard = (props) => {
  // ON LOAD, CHECK THE URL AND THEN RETURN DASHBOARD ROUTES
  useEffect(() => {
    const paths = ["/df/dashboard", "/df/dashboard/"];
    if (paths.includes(props.location.pathname)) {
      props.history.push("/df/dashboard/view");
    }
  });
  return <DashboardRoutes />;
};

export default Dashboard;
