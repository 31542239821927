/* eslint-disable sort-imports */
/* eslint-disable no-mixed-spaces-and-tabs */
import { Col, Row } from "antd";
import { Checkbox, List, ListItem, Panel, Tooltip, Divider } from "appkit-react";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import iconinfo from "../../../Assets/Images/QueenBase/Root/icon-info.svg";
import { DFPackageInfoIconContent } from "../../../Services/Shared/Handlers";
import { DataContext } from "../../../Services/Shared/Store.js";
import { polyglotLoader, userLanguage } from "../../../Services/Shared/Translate";
import GetPriceInGermanFormat from "../Root/GetPriceInGermanFormat";
import displayToastMessage from "../Root/displayToastMessage";

// SOLUTION BRIEFING CARD
export const SolutionBriefingCard = (props) => {
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const { solution } = props;
  return (
    <div className="col-md-12">
      <div className="card cardHeight">
        <div className="card-body ContractBorder">
          <h1 className="subtitle_title_qb mt-3 ">{solution.body.header}</h1>
          <div className="a-py-10 border-top a-mt-10"></div>
          <span className="f-acc-B">{solution.body.body.title}</span>
          <div className="card-text">
            <div className="col-md-12">
              {solution.body.body.data.map((item, index) => {
                return (
                  <div className="row " key={index}>
                    <List key={item.id}>
                      <ListItem key={item.id}>
                        <span className="appkiticon icon-check-mark-fill pointer"></span>
                        <span className="f-acc-B">{item.Description}</span>
                      </ListItem>
                    </List>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      <div className="mb-2"></div>
    </div>
  );
};
SolutionBriefingCard.propTypes = {
  solution: PropTypes.object,
};

// OVERALL COSTS HEADING
export const OverallCostsHeading = (props) => {
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const { solution, totalOfSelectedDFPackages } = props;
  const totalCost = totalOfSelectedDFPackages();
  return (
    <div className="row col-md-12 p-0 additional-package-margin">
      <div className="col-md-12 mt-3">
        <div className="row">
          <div className="col-md-8 ">
            <p className="subtitle_two_title_qb">{polyglot.t("Available packages")}</p>
          </div>
          <div className="col-md-4">
            {solution.id === 2 && <p className="a-h4 5 ">{GetPriceInGermanFormat(`${totalCost}`)}</p>}
          </div>
        </div>
        <p className="subtitle_three_title_qb_dark">
          {polyglot.t("Youcanchoosebetweenthefollowingpackagesfor")}{" "}
        </p>
      </div>
    </div>
  );
};
OverallCostsHeading.propTypes = {
  solution: PropTypes.object,
  totalOfSelectedDFPackages: PropTypes.func,
};

// CHOOSE DF PACKAGES
export const ChooseDFPackages = (props) => {
  const { getUserLanguage } = useContext(DataContext);
  const [selectedPlanID, setSelectedPlanId] = useState("");
  var polyglot = polyglotLoader(getUserLanguage());
  const { JSONArray, checkIfDFPackageIsSelected, solution, selectMultiListPackages, CheckIfDfSelected } =
    props;
  // ON LOAD - GET THE SELECTED PLAN ID
  useEffect(() => {
    let selectedPlanId = "";
    JSONArray.forEach((itemlist) => {
      if (itemlist.isSelected) {
        selectedPlanId = itemlist.PlanID;
      }
    });
    setSelectedPlanId(selectedPlanId);
  }, []);
  // CHECK IF PACKAGE IS DISABLED
  const disableOptedPackages = (eachpackage) => {
    if (eachpackage.isSelected === 1 || selectedPlanID >= eachpackage.PlanID) {
      return true;
    } else {
      return false;
    }
  };

  // DISABLE ALL THE PACKAGES IF ATLEAST ONE PACKAGE IS SELECTED
  const checkAndDisabledPackageSelection = () => {
    try {
      let isCheckBoxDisabled = false;
      const selectedPackages = JSONArray.filter((each) => each.isSelected);
      // selectedPackages has the details of the selected packages
      if (selectedPackages.length > 0) {
        // if packages are already selected, then disable the checkbox
        isCheckBoxDisabled = true;
        return isCheckBoxDisabled;
      } else {
        // if there is no selected package, then the checkbox will be enabled
        return isCheckBoxDisabled;
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // RETURNS AN ICON BASED ON THE descriptionObject
  const getDescriptionIcon = (descObj) => {
    if (descObj.plus === "true") {
      return "appkiticon icon-circle-plus-outline pointer cursor-default";
    }
    if (descObj.tick === "true") {
      return "appkiticon icon-check-mark-fill pointer cursor-default";
    }
    return "";
  };

  return JSONArray.map((itemlist, index) => {
    const PlanDescription = JSON.parse(itemlist.PlanDescription);
    if (itemlist.PlanID) {
      return (
        <div
          className={`col-md-3 respo-tab marginBottom-20px ${CheckIfDfSelected() ? "" : ""}`}
          key={index + 1}
        >
          <div className="cardHeight">
            <div
              className={`card-body ${
                checkIfDFPackageIsSelected(itemlist)
                  ? `${disableOptedPackages(itemlist) ? "ContractBorder-four" : "ContractBorder-three"}`
                  : "ContractBorder-four"
              }`}
              // style={{
              // 	cursor: 'pointer'
              // }}
              // onClick={() => selectMultiListPackages(itemlist)}
            >
              <>
                <div className="col-md-10 solution-data-display">
                  <h6 className="a-h4 mt-0 solution-data-display">{itemlist.PlanName}</h6>
                </div>
                <div className="col-md-2 solution-select-display ">
                  <Checkbox
                    checked={checkIfDFPackageIsSelected(itemlist) || itemlist.isSelected}
                    disabled={checkAndDisabledPackageSelection()}
                    // defaultChecked={Boolean(checkIfDFPackageIsSelected(itemlist)) || Boolean(itemlist.isSelected)}
                    // disabled={disableOptedPackages(itemlist)}
                    onChange={() => selectMultiListPackages(itemlist)}
                    aria-label={polyglot.t("Choose package")}
                  />
                </div>
              </>
              <div className="border-top">
                <div className="row marg_t_b ml-0">
                  <strong className="a-h4">{GetPriceInGermanFormat(itemlist.price)}</strong>
                  &nbsp;
                  <span className="marginpoint35 ml-0 mb-0 edisCoveryPerYear">{polyglot.t("Per_year")}</span>
                </div>
                {PlanDescription.map((descriptionObj, i) => {
                  return (
                    <div key={i} className="row marg_t_b ml-0 mr-0">
                      <span className={getDescriptionIcon(descriptionObj)}></span>
                      <div className="col-md-10 p-0 f-acc-B">
                        <div className="row">
                          <div className="col-md-11 pr-0">
                            {descriptionObj.name}&nbsp;
                            {descriptionObj.value && "-"}&nbsp;
                            {descriptionObj.value}
                          </div>
                          <div className="col-md-1 p-0">
                            {descriptionObj.infoicon === "true" && (
                              <Tooltip
                                content={DFPackageInfoIconContent()}
                                placement="left"
                                className="a-md-pop"
                                tooltipTheme="light"
                              >
                                <img
                                  src={iconinfo}
                                  className="infoicon-solution pointer"
                                  alt="more info"
                                  aria-label={polyglot.t("More info")}
                                />
                              </Tooltip>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          <div className="mb-2"></div>
        </div>
      );
    }
    return null;
  });
};
OverallCostsHeading.propTypes = {
  JSONArray: PropTypes.array,
  checkIfDFPackageIsSelected: PropTypes.func,
  solution: PropTypes.object,
  selectMultiListPackages: PropTypes.func,
  CheckIfDfSelected: PropTypes.func,
};

// CHOOSE PACKAGES OF A SOLUTION
export const ChoosePackages = (props) => {
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const { JSONArray, solution, customNumberFormat, handlePackage, OptedPackages } = props;

  // DISABLE ALL THE PACKAGES IF ATLEAST ONE PACKAGE IS SELECTED
  const checkAndDisabledPackageSelection = () => {
    try {
      let isCheckBoxDisabled = false;
      const selectedPackages = JSONArray.filter((each) => each?.isselected);
      // selectedPackages has the details of the selected packages
      if (selectedPackages.length > 0) {
        // if packages are already selected, then disable the checkbox
        isCheckBoxDisabled = true;
        return isCheckBoxDisabled;
      } else {
        // if there is no selected package, then the checkbox will be enabled
        return isCheckBoxDisabled;
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // CHECK IF PACKAGE IS SELECTED
  const CheckIfPackageSelected = (item) => {
    if (item?.isselected === 1 || item?.isChecked === 1) {
      return true;
    } else {
      return false;
    }
  };
  // RETURNS AN ICON BASED ON THE descriptionObject
  const getDescriptionIcon = (descObj) => {
    if (descObj.plus === "true") {
      return "appkiticon icon-circle-plus-outline pointer cursor-default";
    }
    if (descObj.tick === "true") {
      return "appkiticon icon-check-mark-fill pointer cursor-default";
    }
    return "";
  };

  // display list item with icon and text
  const displayListItem = (descriptionObj) => {
    try {
      return (
        <>
          <span className={getDescriptionIcon(descriptionObj)}></span>

          <div className="col-md-10 p-0 f-acc-B">
            {descriptionObj.sideHeading === "true" ? (
              <strong>{descriptionObj.name}</strong>
            ) : (
              <>
                {descriptionObj.name}
                {descriptionObj.sup == "true" ? <sup>1</sup> : ""}
              </>
            )}
          </div>
        </>
      );
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // DISPLAY CHILD LIST
  const displayChildList = (descriptionObj) => {
    try {
      return (
        <div className="ml-4 pl-3">
          {descriptionObj.children.map((each, i) => {
            const slNo = i + 1;
            return (
              <div className="row">
                <span>{slNo}.&nbsp;</span>
                <div className="p-0 f-acc-B">{each.name}</div>
              </div>
            );
          })}
        </div>
      );
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  const getPrice = (item) => {
    if (item.id === 300) return <span className="marginpoint35 ml-0 mb-0">consumption based</span>;

    return item.title != "Virtual Asset screening" && item.price !== null ? (
      <>
        <strong className="a-h4">{GetPriceInGermanFormat(item.price)}</strong>
        &nbsp;
        <span className="marginpoint35 ml-0 mb-0">{polyglot.t(item.body.expiredin)}</span>
      </>
    ) : (
      <strong className="a-h4">{polyglot.t(item.price)}</strong>
    );
  };

  return JSONArray.map((item, index) => {
    if (item)
      return (
        <div className={`${JSONArray.length > 3 ? "col-md-3" : "col-md-4"} respo-tab mb-3`} key={index + 1}>
          <div className="cardHeight">
            <div
              className={`card-body ${
                item?.isChecked === 1 ? "ContractBorder-three" : "ContractBorder-four"
              } `}
            >
              <div className="col-md-10 solution-data-display">
                {item.title.length <= 11 && solution.body.header == "Connected Global Intelligence" && (
                  <h6 className="a-h4 mt-0 solution-data-display heightIssue">
                    {item.title} <label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</label>
                  </h6>
                )}
                {item.title.length > 11 && solution.body.header == "Connected Global Intelligence" && (
                  <h6 className="a-h4 mt-0 solution-data-display heightIssue">{item.title}</h6>
                )}
                {solution.body.header != "Connected Global Intelligence" && (
                  <h6 className="a-h4 mt-0 solution-data-display ">{item.title}</h6>
                )}
              </div>
              {solution.body.header != "Connected Global Intelligence" && (
                <div className="col-md-2 solution-select-display ">
                  <Checkbox
                    checked={CheckIfPackageSelected(item)}
                    onChange={() => handlePackage(item)}
                    disabled={checkAndDisabledPackageSelection()}
                    aria-label={polyglot.t("Choose package")}
                  />
                </div>
              )}
              <Divider />
              <div className="a-mt-15 data-margin mt-2">
                <div className="row marg_t_b">{getPrice(item)}</div>
                {item.body.PlanDescription &&
                  item.body.PlanDescription.map((descriptionObj, i) => {
                    return (
                      <div key={i} className="row marg_t_b">
                        {displayListItem(descriptionObj)}
                        {descriptionObj.children && displayChildList(descriptionObj)}
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
          <div className="mb-2"></div>
        </div>
      );
  });
};
ChoosePackages.propTypes = {
  JSONArray: PropTypes.array,
  solution: PropTypes.object,
  customNumberFormat: PropTypes.func,
  handlePackage: PropTypes.func,
};

// TERMS AND CONDITIONS OF A SIGNING A SOLUTION
export const TermsAndConditionssolutions = (props) => {
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const { solution } = props;
  return (
    <div className="TermsAndConditionssolutions">
      <div className="row m-0">
        <div className="col-md-12 mt-2 mb-2 p-0">
          <div>
            <p className=" subtitle_two_title_qb">
              <strong>{polyglot.t("TermsandConditions")}</strong>
            </p>
          </div>
          <p className="f-acc-B">
            {userLanguage() === "en" && (
              <>
                {polyglot.t("Belowyouwillfindonlytheparagraphsthatchangein")}
                <br />
                {polyglot.t("To the terms and conditions that have already been signed")}{" "}
                <b>{solution.body.header}</b> {polyglot.t("are shown below.")}
              </>
            )}
            {userLanguage() === "ger" && (
              <>
                {polyglot.t("review-solution-TandC-p1")}.
                <br />
                {polyglot.t("review-solution-TandC-p2")} <b>{solution.body.header}</b>.
              </>
            )}
          </p>
        </div>
      </div>
      <div className="row mr-0">
        <div className="col-md-8">
          <div className="card x">
            <div className="card-body marginLeft-1px">
              <b>2.1 </b>
              <p className="subtitle_three_title_qb">
                {polyglot.t(
                  "The Engagement shall take effect as soon as we have received the written agreement regarding the Engagement duly signed by you and us, except if article",
                )}{" "}
                <b>2.3</b>&nbsp;
                {polyglot.t("below applies")}.
              </p>
              <br />
              <b>2.2 </b>
              <p className="subtitle_three_title_qb">
                {polyglot.t(
                  "The Engagement shall take effect as soon as we have received the written agreement regarding the Engagement duly signed by you and us, except if article",
                )}{" "}
                <b>2.3</b>&nbsp;{polyglot.t("below applies")}.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
TermsAndConditionssolutions.propTypes = {
  solution: PropTypes.object,
};

// DF BASIC ANALYSIS CARD
export const DFBasicAnalaysisCard = () => {
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  return (
    <div className="col-md-8 ">
      <Panel className="df-panel-height analysisCard">
        <Row>
          <Col span={16} className="queen-cc-temp f-acc">
            <b>Base Analysis</b>
          </Col>
        </Row>
        <div className="queen-cc-temp-text  f-acc-B">
          {polyglot.t(
            "The general ledger module is the basis of all analysis. It includes the financial transactions that are part of your balance and P/L sheet and texts for the most common codes in SAP (document type, posting key, etc.).",
          )}
        </div>
      </Panel>
    </div>
  );
};

// PACKAGE LEGEND
export const PackageLegend = () => {
  const polyglot = polyglotLoader();
  return (
    <div className="container m-0 p-0 mt-4 mb-4">
      <div className="row mr-0">
        <div className="col-md-4">
          <Panel className="p-2">
            <div className="row margin-center-1em">
              <div className="col-md-1">
                <span className="appkiticon icon-check-mark-fill pointer cursor-default"></span>
              </div>
              <div className="col-md-4">{polyglot.t("Included")}</div>
              <div className="col-md-1">
                <span className="appkiticon icon-circle-plus-outline pointer cursor-default"></span>
              </div>
              <div className="col-md-5">{polyglot.t("Additional")}</div>
            </div>
          </Panel>
        </div>
      </div>
    </div>
  );
};
// PACKAGE LEGEND
export const PackageLegendCss = () => {
  const polyglot = polyglotLoader();
  return (
    <div className="container m-0 p-0 mt-4 mb-4">
      <div className="row mr-0">
        <div className="col-md-12">
          <Panel className="p-2">
            <div
              className="row margin-center-1em faq-answer"
              dangerouslySetInnerHTML={{
                __html: polyglot.t(
                  "Please use the 24/7 concierge service to request your individual quote. Prices may vary depending on volume and size.",
                ),
              }}
            ></div>
          </Panel>
        </div>
      </div>
    </div>
  );
};
