/* eslint-disable no-mixed-spaces-and-tabs */
// Admin Basic Package
import { Radio } from "appkit-react";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import GetPriceInGermanFormat from "../Root/GetPriceInGermanFormat";

// CLIENT CREATION FORM - STEP 2 - TO CHOOSE ADMIN PACKAGE
function AdminPackage(props) {
  const { getUserLanguage } = useContext(DataContext);
  const { basicPackage } = props;
  var polyglot = polyglotLoader(getUserLanguage());
  const colors = ["#ffb600", "#eb8c00", "#d93954", "#a43e50"];
  // HANDLE PACKAGE
  const handlePackage = (item) => {
    var list = [...basicPackage];
    list.map((res) => {
      if (res.id === item.id) {
        res.isselected = res.isselected === 1 ? 0 : 1;
      } else {
        res.isselected = 0;
      }
    });
    props.updateClientFormData("basicPackage", list);
    props.updateClientFormData("package", item);
  };

  return (
    <div>
      <h1 className="main_title_qb m-l-1-3">{polyglot.t("Connected Digital Services base packages")}</h1>
      <div className="gridRow-basic-package  gridRow-responsive Admin-Package-grid display-flex">
        {basicPackage && basicPackage.length > 0 ? (
          basicPackage.map((item, index) => {
            return (
              <div key={index}>
                <div className="admin-cardHeight">
                  <div
                    className={"Admin-card-body-package"}
                    style={{ borderTop: `5px solid ${colors[index]}` }}
                  >
                    <div className="display-flex">
                      <div>
                        <div className="subtitle_title_qb">{item.name}</div>
                      </div>
                      <div>
                        <Radio
                          className="mb-0"
                          checked={item.isselected == 1 ? true : false}
                          onChange={() => handlePackage(item)}
                          aria-label={polyglot.t("Choose Package")}
                        />
                      </div>
                    </div>
                    <div className="a-py-0 border-top a-border-dark a-mt-2">
                      <div className="Admin-users-list">
                        <div className="row marg_t_b ml-0">
                          <strong className="a-h4">{GetPriceInGermanFormat(item.price)}</strong>
                          &nbsp;
                          <span className="marginpoint25 ml-0 mb-0">{polyglot.t("Per_year")}</span>
                        </div>
                        <b>{polyglot.t("Users")}</b>
                        <div className="row marg_t_b ml-0">
                          <span className="appkiticon icon-check-mark-fill pointer ul-icon cursor-default"></span>
                          <div className="col-md-10 p-0 f-acc-B">
                            <div className="row">
                              <div className="col-md-12 pr-0">
                                {polyglot.t("up to")}&nbsp;
                                {item.noofusers}&nbsp;
                                {polyglot.t("users")}
                              </div>
                            </div>
                          </div>
                        </div>
                        <b>{polyglot.t("Additional users")}</b>
                        <div className="row marg_t_b ml-0">
                          <span className="appkiticon icon-circle-plus-outline pointer ul-icon cursor-default"></span>
                          <div className="col-md-10 p-0 f-acc-B">
                            <div className="row">
                              <div className="col-md-12 pr-0">
                                {GetPriceInGermanFormat(item.tempuserprice.toString())}{" "}
                                {polyglot.t("per user per year")}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          <h6 className="a-h3  ml-2">
            <strong>{polyglot.t("No packages are available")}</strong>
          </h6>
        )}
      </div>
    </div>
  );
}
AdminPackage.propTypes = {
  updateClientFormData: PropTypes.func,
  basicPackage: PropTypes.array,
};
export default AdminPackage;
