import React from "react";

import "./button.css";
import ButtonSecondaryWithIcon from "../../../../Assets/Components/ButtonSecondary/ButtonSecondaryWithIcon";
import { backCircle } from "../../../../Assets/Icons";
export class BackButton extends React.Component {
  constructor(props) {
    super(props);
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
  }

  onBackButtonClick() {
    this.props.onBackButtonClick();
  }
  render() {
    return (
      <ButtonSecondaryWithIcon
        iconName={backCircle}
        onClick={this.onBackButtonClick}
        ariaLabel={this.props.buttonText}
      >
        {this.props.buttonText}
      </ButtonSecondaryWithIcon>
    );
  }
}
export default BackButton;
