import React from "react";
import "./CommonButton.css";

const CommonButton = (props) => {
  return (
    <button
      className="primary_button"
      style={{ height: props.height, fontSize: props.fontSize, backgroundColor: props.BgColor }}
    >
      {props.children} {props.name}{" "}
    </button>
  );
};

export default CommonButton;
