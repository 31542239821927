import React, { Component } from "react";
import { Slider } from "appkit-react-slider";
import PropTypes from "prop-types";
import "../../../Assets/Css/CSS/Search.css";
import { Input } from "appkit-react";

class Scoring extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: this.props.scoringParameter,
      sliderValue: this.props.scoringParameter,
    };
  }

  onChange = (value) => {
    if (value == "") {
      value = this.props.min;
    } else {
      value = parseFloat(value).toFixed(2);
    }
    this.setState({ value });
  };

  onEnterPress = (val, evt) => {
    let value = Number(val);
    if (Number.isNaN(value) || value < this.props.min) {
      value = this.props.min;
    } else if (value > this.props.max) {
      value = this.props.max;
    }

    this.setState({
      value,
      sliderValue: value,
    });
  };

  onSliderChange = (value) => {
    this.setState({
      value,
      sliderValue: value,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.value !== this.state.value) {
      // update the parent state value related to scoring
      this.props.updateScoringParmeter(this.state.value);
    }
  }

  render() {
    return (
      <div className="a-slider-wrapper  ">
        <div className="a-slider-header">
          <div className="mr-auto a-label-wrapper">
            <label className="a-slider-label">{this.props.label}</label>
          </div>
          <div className="slid">
            <Slider
              value={parseFloat(this.state.sliderValue).toFixed(2)}
              min={this.props.min}
              max={this.props.max}
              step={this.props.step}
              marks={{ 0.7: "0.70", 0.8: "0.80", 0.9: "0.90", 1: "1.00" }}
              onChange={this.onSliderChange}
            />
          </div>
          <div className="a-label-wrapper">
            <Input
              size={"sm"}
              placeholder={parseFloat(this.state.value).toFixed(2)}
              onChange={this.onChange}
              onEnterPress={this.onEnterPress}
              className="scoring-text-box"
              type="number"
              value={parseFloat(this.state.value)}
            />
          </div>
        </div>
      </div>
    );
  }
}

Scoring.propTypes = {
  scoringParameter: PropTypes.number,
};
Scoring.defaultProps = {
  scoringParameter: 0.7,
};

export default Scoring;
