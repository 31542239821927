/* eslint-disable no-prototype-builtins */
//Client Form to Create User or admin
import "../../../Assets/Css/QueenBase/Admin/Admin.css";
import { Formik } from "formik";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";

import AdminPackage from "./AdminPackage";
import AdminSolution from "./AdminSolution";
import AdminUserCreation from "./AdminUserCreation";
import ClientCreationForm from "./ClientCreationForm";
import ConfirmClientCreation from "./ConfirmClientCreation";
import PrevNextButtons from "./PrevNextButtons";
import * as path from "../../../Constants/Path";
import { FIELD_LENGTH_REGEX, PHONE_REGEX, FIELD_REGEX } from "../../../Constants/regex";
import AdminService from "../../../Services/QueenBase/Admin/AdminServices";
import SolutionService from "../../../Services/QueenBase/Solutions/SolutionService";
import httpRequest from "../../../Services/Shared/HttpClient";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";

// THIS IS THE COMPONENT WHICH RENDERS TO CREATE A CLIENT
function ClientCreation(props) {
  const HttpRequest = new httpRequest();
  const [currentPage, setCurrentPage] = useState(1);
  const [countries, setCountries] = useState([]);
  const [position, setPosition] = useState([]);
  const [roles, SetRoles] = useState([]);
  const [ClientFormData, setClientFormData] = useState({});
  const [entityTypes, SetEntityTypes] = useState([]);
  const [DfPricing, setDfPricing] = useState([]);
  const [DFPackagesList, setDFPackagesList] = useState([]);
  const [multilist, setMultilist] = useState([]);
  const [disableCreateClient, setDisableCreateClient] = useState(false);

  const { getUserLanguage, userLanguage, fetchCountriesList, formGotOpened, formGotClosed } =
    useContext(DataContext);
  const language = getUserLanguage();
  var polyglot = polyglotLoader(getUserLanguage());

  const { isFormEmpty } = props;

  const initialValues = {
    ClientName: ClientFormData.ClientName || "",
    Clientemail: ClientFormData.Clientemail || "",
    ClientEntity: ClientFormData.ClientEntity || "",
    ClientEntityid: ClientFormData.ClientEntityid || "",
    ClientDepartment: ClientFormData.ClientDepartment || "",
    ClientContactPerson: ClientFormData.ClientContactPerson || "",
    ClientPhone: ClientFormData.ClientPhone || "",
    ClientAddress: ClientFormData.ClientAddress || "",
    Clientcountryid: ClientFormData.Clientcountryid || 0,
  };
  // VALIDATION SCHEMA FOR THE CLIENT FORM
  const validationSchema = Yup.object().shape({
    ClientName: Yup.string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t("field_validaton"))
      .required("* Name is required")
      .trim(),
    Clientemail: Yup.string().email("Invalid email").required("Email is Required"),
    Clientcountryid: Yup.number().min(1, polyglot.t("Country is required")),
    ClientEntity: Yup.string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t("field_validaton"))
      .required("Organisation is required")
      .trim(),
    ClientEntityid: Yup.number().required("* Entity Type is required"),
    ClientDepartment: Yup.string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t("field_validaton"))
      .required("Department is required")
      .trim(),
    ClientPhone: Yup.string()
      .matches(PHONE_REGEX, "Invalid phone number")
      .min(5, "Phone number must be at least 5 characters")
      .max(20, "Phone number must be at most 20 characters")
      .required("Phone Number is required"),
    ClientContactPerson: Yup.string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t("field_validaton"))
      .required("Contact Person is required")
      .trim(),
    ClientAddress: Yup.string()
      .matches(FIELD_REGEX, polyglot.t("field_validaton"))
      .required("* Address is required")
      .trim(),
  });

  // UPDATE CLIENT FORM DATA
  const updateClientFormData = (key, value) => {
    setClientFormData({ ...ClientFormData, [`${key}`]: value });
  };

  useEffect(() => {
    return () => {
      props.checkOp();
    };
  }, []);

  // handle field change of step 1
  const handleStep1FieldChange = (key, handleChange) => (event) => {
    const value = event.target.value;
    handleChange(key)(event);
    updateClientFormData(key, value);
  };

  // TOTAL OF SELECTED DF PACKAGES
  const totalOfSelectedDFPackages = (solutionId) => {
    let dfsolution = ClientFormData.solutionList.filter((item) => solutionId === item.id);
    const totalPackagesSelected = dfsolution[0].packages.length;
    let Price = 0;
    if (totalPackagesSelected != 0 && DfPricing.length > 0) {
      dfsolution[0].packages.forEach((obj) => {
        Price = obj.price;
      });
    }
    return Price;
  };

  // HANDLE NEXT
  const handleNext = (values) => {
    setClientFormData({ ...values, ...ClientFormData });
    if (currentPage === 2) {
      if (ClientFormData.hasOwnProperty("package")) {
        setCurrentPage(currentPage + 1);
      } else {
        displayToastMessage(polyglot.t("Please select a package"), "error");
      }
    } else if (currentPage === 3) {
      let validate = { solutionNamesWithErrors: [], statuses: [] };
      let activeSolutions = ClientFormData.solutionList.filter((r) => r.isselected === 1);
      if (activeSolutions.length > 0) {
        let dfSolution = activeSolutions.filter((r) => r.id === 2);
        if (dfSolution.length > 0) {
          if (dfSolution[0].packages.length > 0) {
            validate.statuses.push(true);
          } else {
            const msg = polyglot.t("solution_pacakge", { name: dfSolution[0].name });
            validate.solutionNamesWithErrors.push(dfSolution[0].name);
            validate.statuses.push(false);
          }
        }
        let Solutions = activeSolutions.filter((r) => r.id != 2);
        Solutions.map((each) => {
          if (Object.keys(each.package).length > 0) {
            validate.statuses.push(true);
          } else {
            validate.statuses.push(false);
            const msg = polyglot.t("solution_pacakge", { name: each.name });
            validate.solutionNamesWithErrors.push(each.name);
          }
        });

        let isValid = true;
        validate.statuses.forEach((status) => {
          if (!status) {
            isValid = false;
          }
        });
        if (isValid) {
          setCurrentPage(currentPage + 1);
        } else {
          if (validate.solutionNamesWithErrors.length === 0) {
          }
          const msg = `Please select a package for solution(s) ${validate.solutionNamesWithErrors.join(
            ", ",
          )}`;
          displayToastMessage(msg, "error");
        }
      } else {
        displayToastMessage(polyglot.t("Please select a solution"), "error");
      }
    } else if (currentPage == 4) {
      if (ClientFormData.hasOwnProperty("UsersList") && ClientFormData.UsersList.length > 0) {
        let numberOfClientSponsers = 0;
        let numberOfExistingUsers = 0;
        ClientFormData.UsersList.forEach((user) => {
          if (user.userroleid === 8) {
            numberOfExistingUsers += 1;
          }
          if (user.userroleid === 10) {
            numberOfClientSponsers += 1;
          }
        });
        let isStepValid = true;
        if (numberOfClientSponsers < 1) {
          displayToastMessage(polyglot.t("Please add aleast one client sponsor"), "error");
          isStepValid = false;
        }
        if (numberOfExistingUsers < 1) {
          displayToastMessage(polyglot.t("Please add aleast one existing user"), "error");
          isStepValid = false;
        }
        if (isStepValid) {
          setCurrentPage(currentPage + 1);
        }
      } else {
        displayToastMessage(
          polyglot.t("Please add atleast one client sponsor and one existing user"),
          "error",
        );
      }
    } else {
      setCurrentPage(currentPage + 1);
    }
  };

  // HANDLE PREVIOUS CLICK
  const handlePrev = () => {
    setCurrentPage(currentPage - 1);
  };

  // SET DF PACKAGES
  const SetDfPackages = async () => {
    var data = await SolutionService.get().getSolutionPackagesPricing();
    setDfPricing(data);
  };
  // FILTER BASIC ANALYSIS
  const FilterBasicAnalysis = async (packages, solutions) => {
    let Packages = [...packages];
    let DefaultPackages = [];
    const dfPackages = await SolutionService.get().getDfSolutionPackages({
      solutionId: 2,
    });
    solutions.map((each) => {
      if (each.id === 2) {
        each.packages = DefaultPackages;
      }
    });
    return { Packages, solutions };
  };
  /* GET REQUIRED DATA FROM API TO CREATE A CLIENT
	WE RECEIVE:
	basicPackage: LIST OF BASE PACKAGES
	countries: LIST OF COUNTRIES
	position: LIST OF POSITIONS
	solutionList: LIST OF SOLUTIONS
	solutionPackage: LIST OF PACKAGES OF SOLUTIONS
	*/
  async function getAdmin(language) {
    var res = await AdminService.get().getAdmin(language);
    if (res.CODE) {
      let dfpackages = await SetDfPackages();
      let { Packages, solutions } = await FilterBasicAnalysis(res.solutionPackage, res.solutionList);
      const index = Packages?.findIndex((item) => item.title === "Ad Hoc");
      const element = Packages[index];

      // eslint-disable-next-line
      Packages?.pop();
      // eslint-disable-next-line
      Packages?.unshift(element);
      let obj = {
        basicPackage: res.basicPackage,
        solutionPackage: Packages,
        solutionList: solutions,
        UsersList: [],
        dfpackages: dfpackages,
      };
      // GET LIST OF ENTITY TYPES
      let entityTypes = await HttpRequest.get("/getEntityType");
      SetEntityTypes(entityTypes);
      setClientFormData(obj);
      res.position.map((each) => {
        each.value = each.positionid;
        each.label = each.name;
        delete each.positionid;
      });
      setPosition(res.position);
    } else {
      displayToastMessage(polyglot.t(res.MSG), "error");
    }
  }
  // GET ROLES TO CREATE A CLIENT
  // WE GET clientSponser and clientAdmin FROM THIS API
  async function getRoles() {
    let res = await AdminService.get().GetRoles();
    SetRoles(res.data);
  }
  // SUBMIT CLIENT CREATION FORM
  const CreateClient = async () => {
    let clientData = {
      ClientName: ClientFormData.ClientName,
      Clientemail: ClientFormData.Clientemail,
      ClientEntity: ClientFormData.ClientEntity,
      EntityTypeID: ClientFormData.ClientEntityid,
      ClientDepartment: ClientFormData.ClientDepartment,
      ClientContactPerson: ClientFormData.ClientContactPerson,
      ClientPhone: ClientFormData.ClientPhone,
      ClientAddress: ClientFormData.ClientAddress,
      Clientcountryid: ClientFormData.Clientcountryid,
    };
    let activeSolutions = ClientFormData.solutionList.filter((r) => r.isselected === 1);
    let solutions = [];
    let solutionPackage = [];
    let solutionDfPackage = [];
    activeSolutions.map((each) => {
      let obj = {};
      obj.solutionId = each.id;
      obj.name = each.name;
      solutions.push(obj);
      if (each.id === 2) {
        each.packages.map((eachPackage) => {
          let Packageobj = {};
          Packageobj.packageId = eachPackage.DFPlanID;
          Packageobj.solutionId = eachPackage.solutionid;
          solutionDfPackage.push(Packageobj);
        });
      } else {
        let Packageobj = {};
        Packageobj.packageId = each.package.id;
        Packageobj.solutionId = each.package.solutionid;
        solutionPackage.push(Packageobj);
      }
    });
    let obj = {
      clientDetails: clientData,
      BasePackageId: ClientFormData.package.id,
      solutions: solutions,
      solutionPackage: solutionPackage,
      solutionDfPackage: solutionDfPackage,
      users: ClientFormData.UsersList,
    };
    // API TO CREATE A CLIENT
    // PASSING: form object
    const res = await AdminService.get().clientCreation(obj);
    if (res.CODE) {
      props.history.push(path.ADMIN_PATH);
      displayToastMessage(
        polyglot.t("clientCreationSuccess", { clientName: ClientFormData.ClientName }),
        "success",
      );
      formGotClosed();
    } else if (res.MSG === "USEREMAIL ALREADY EXISTS") {
      displayToastMessage(polyglot.t("sponsorsEmailExist"), "error");
    } else {
      displayToastMessage(polyglot.t("Something went wrong!"), "error");
    }
  };

  // FETCH DATA AND RESET FORM
  const fetchDataAndResetForm = () => {
    setCurrentPage(1);
    getAdmin(language);
    getRoles();
  };

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    fetchDataAndResetForm();
  }, [language]);

  // get countries from api on language change
  const getCountries = async () => {
    const countriesList = await fetchCountriesList();
    setCountries(countriesList);
  };
  // Runs when user language got updated
  useEffect(() => {
    getCountries();
  }, [userLanguage]);

  // STEPS OF CLIENT FORM
  const renderPage = () => {
    switch (currentPage) {
      case 1:
        return (
          <Formik onSubmit={handleNext} initialValues={initialValues} validationSchema={validationSchema}>
            {(prop) => {
              const { values, handleChange, handleSubmit, errors, touched, dirty } = prop;
              // FORMIK FORM - HANDLE FORMOPEN OR CLOSE STATUS
              const updateFormStatusBasedOnDirty = () => {
                if (dirty) {
                  formGotOpened();
                }
                if (!dirty) {
                  formGotClosed();
                }
              };
              setTimeout(() => {
                updateFormStatusBasedOnDirty();
                isFormEmpty(values);
              }, 0);
              return (
                <div>
                  <ClientCreationForm
                    countries={countries}
                    values={values}
                    handleChange={(key) => handleStep1FieldChange(key, handleChange)}
                    errors={errors}
                    touched={touched}
                    entityTypes={entityTypes}
                  />
                  <PrevNextButtons currentPage={currentPage} handleNext={handleSubmit} />
                </div>
              );
            }}
          </Formik>
        );

      case 2:
        return (
          <div>
            <AdminPackage
              updateClientFormData={updateClientFormData}
              basicPackage={ClientFormData.basicPackage}
              packageId={ClientFormData.packageId}
            />
            <PrevNextButtons currentPage={currentPage} handleNext={handleNext} handlePrev={handlePrev} />
          </div>
        );

      case 3:
        return (
          <div>
            <AdminSolution
              updateClientFormData={updateClientFormData}
              solutionList={ClientFormData.solutionList}
              solutionPackage={ClientFormData.solutionPackage}
              solutionId={ClientFormData.solutionId}
              solutionPackageid={ClientFormData.solutionPackageid}
              DfPricing={DfPricing}
            />
            <PrevNextButtons currentPage={currentPage} handleNext={handleNext} handlePrev={handlePrev} />
          </div>
        );

      case 4:
        return (
          <Formik onSubmit={handleNext} initialValues={initialValues} validationSchema={validationSchema}>
            {(prop) => {
              const { values, handleChange, handleSubmit, errors, touched } = prop;
              return (
                <div>
                  <AdminUserCreation
                    countries={countries}
                    position={position}
                    roles={roles}
                    ClientFormData={ClientFormData}
                    updateClientFormData={updateClientFormData}
                    UsersList={ClientFormData.UsersList}
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                  <PrevNextButtons
                    currentPage={currentPage}
                    handleNext={handleNext}
                    handlePrev={handlePrev}
                  />
                </div>
              );
            }}
          </Formik>
        );

      case 5:
        return (
          <div>
            <ConfirmClientCreation
              ClientFormData={ClientFormData}
              roles={roles}
              totalOfSelectedDFPackages={totalOfSelectedDFPackages}
            />
            <PrevNextButtons
              currentPage={currentPage}
              handleNext={handleNext}
              handlePrev={handlePrev}
              CreateClient={CreateClient}
              disabled={disableCreateClient}
            />
          </div>
        );
    }
  };
  return (
    <div className="custom-container">
      <div className=" Admin-PannelPadding ">{renderPage()}</div>
    </div>
  );
}
ClientCreation.propTypes = {
  history: PropTypes.object,
};
export default ClientCreation;
