import React from "react";
import { withRouter, useHistory } from "react-router-dom";

import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import ButtonSecondaryWithIcon from "../../../Assets/Components/ButtonSecondary/ButtonSecondaryWithIcon";
import { plusCircle, downloadIcon, uploadIcon } from "../../../Assets/Icons";
import * as path from "../../../Constants/Path";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";

const HomeTop = (props) => {
  const polyglot = polyglotLoader();
  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  const handleCreateQuestionnaire = (e) => {
    e.preventDefault();
    props.history.push({
      pathname: path.CREATE_CATEGORY_PATH,
      state: { mode: "add", id: 0 },
    });
  };
  return (
    <>
      <div className="col-md-9 justify-content-between p-0 custome-left">
        <ButtonPrimaryWithIcon
          iconName={plusCircle}
          onClick={(e) => {
            handleCreateQuestionnaire(e);
          }}
          ariaLabel={polyglot.t("create")}
        >
          {polyglot.t("create")}
        </ButtonPrimaryWithIcon>
        <ButtonSecondaryWithIcon iconName={uploadIcon} ariaLabel={polyglot.t("upload")}>
          {polyglot.t("upload")}
        </ButtonSecondaryWithIcon>
        <ButtonSecondaryWithIcon iconName={downloadIcon} ariaLabel={polyglot.t("download.label")}>
          {polyglot.t("download.label")}
        </ButtonSecondaryWithIcon>
      </div>
      {/* <div className="col-md-3 justify-content-end text-right custome-right">
        <ButtonSecondaryWithIcon
          iconName={backCircle}
          onClick={goBack}
          ariaLabel={polyglot.t("back")}
        >
          {polyglot.t("back")}
        </ButtonSecondaryWithIcon>
      </div> */}
    </>
  );
};
export default withRouter(HomeTop);
