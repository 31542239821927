import { Button, Input, Select, SelectGroupTitle, SelectOption } from "appkit-react";
import React, { useEffect, useState } from "react";

import CustomModal from "../../../Assets/Components/Modal";
import HomeService from "../../../Services/GRC/HomeService";
import QuestionService from "../../../Services/GRC/QuestionService";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import { specialCharactersFieldRegexSchema } from "../../../utility/textFieldValidation";

export const TransferQuestionnaireModal = ({ show, onCancel, questionnaireToTransfer }) => {
  const [clientId, setClientId] = useState(null);
  const [questionnaireName, setQuestionnaireName] = useState("");
  const [questionnaireNameErrorMsg, setQuestionnaireNameErrorMsg] = useState("");
  const [clientsList, setClientsList] = useState([]);
  const { sendRequest } = useHttpClient();
  const polyglot = polyglotLoader();

  const handleClientIdChange = (value) => {
    setClientId(value);
  };

  const handleQuestionnaireNameChange = (value) => {
    setQuestionnaireNameErrorMsg("");
    setQuestionnaireName(value);
  };

  const getClientsList = async () => {
    try {
      const responseData = await sendRequest(QuestionService.get().getClientsList(), "GET");
      setClientsList(responseData);
    } catch (error) {
      displayToastMessage(error.message, "error");
    }
  };

  useEffect(() => {
    getClientsList();
  }, []);

  const onTransferQuestionnaire = async () => {
    if (!clientId) {
      return displayToastMessage(polyglot.t("transfer_questionnaire.error.client_id"), "error");
    }
    if (!questionnaireName) {
      const errorMessage = polyglot.t("transfer_questionnaire.error.name");
      setQuestionnaireNameErrorMsg(errorMessage);
      return displayToastMessage(errorMessage, "error");
    }
    try {
      await specialCharactersFieldRegexSchema.validate(questionnaireName);
    } catch (validationError) {
      setQuestionnaireNameErrorMsg(validationError.message);
      return displayToastMessage(validationError.message, "error");
    }

    const body = {
      clientId,
      questionnaireName,
    };

    try {
      setQuestionnaireNameErrorMsg("");
      const responseData = await sendRequest(
        HomeService.get().transferQuestionnaire(questionnaireToTransfer.id),
        "POST",
        JSON.stringify(body),
      );
      if (responseData) {
        onCancel();
        displayToastMessage(
          `${polyglot.t("questionnaire.label")} '${questionnaireToTransfer.name}' ${polyglot.t(
            "transfer_questionnaire.success",
          )}`,
          "success",
        );
      }
    } catch (error) {
      setQuestionnaireNameErrorMsg(error.message);
      displayToastMessage(error.message, "error");
    }
  };

  return (
    <CustomModal
      show={show}
      onCancel={onCancel}
      title={polyglot.t("transfer_questionnaire.label")}
      Footer={[
        <Button size="lg" className="primary_button" onClick={onTransferQuestionnaire} key={"confirmbox"}>
          {polyglot.t("transfer")}
        </Button>,
      ]}
    >
      <div className="row py-2">
        <div className="col-md-4">
          <span>{polyglot.t("enter_client")}</span>
        </div>
        <div className="col-md-8">
          {clientsList.length && (
            <Select
              placeholder={polyglot.t("enter_client")}
              multiple={false}
              value={clientId}
              showSearchOnToggle={true}
              onSelect={handleClientIdChange}
              className="Box"
              aria-label={polyglot.t("enter_client")}
            >
              <SelectGroupTitle>{polyglot.t("Client")}</SelectGroupTitle>
              {clientsList.map(({ clientid }) => (
                <SelectOption key={clientid} value={clientid}>
                  {clientid}
                </SelectOption>
              ))}
            </Select>
          )}
        </div>
      </div>
      <div className="row py-2">
        <div className="col-md-4">
          <span>{polyglot.t("transfer_questionnaire.name")}</span>
        </div>
        <div className="col-md-8">
          <Input
            onChange={handleQuestionnaireNameChange}
            hasError={questionnaireNameErrorMsg.length > 0}
            errMsg={questionnaireNameErrorMsg}
          />
        </div>
      </div>
    </CustomModal>
  );
};
