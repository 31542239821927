/* eslint-disable no-mixed-spaces-and-tabs */
import React from "react";
import { useHistory } from "react-router-dom";

import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import ButtonSecondaryWithIcon from "../../../Assets/Components/ButtonSecondary/ButtonSecondaryWithIcon";
import { downloadImg, backArrowFill } from "../../../Assets/Icons";
import download from "../../../Assets/Images/DFED/icon-download.svg";
import { polyglotLoader } from "../../../Services/Shared/Translate";

function QuestionButtons(props) {
  const history = useHistory();
  var polyglot = polyglotLoader();
  const backHandle = () => {
    history.push("/home");
  };

  return (
    <div>
      <div className="row m-l-1 m-b-18">
        <div className="col-lg-6 col-md-6 d-flex pl-0 pr-0">
          <ButtonPrimaryWithIcon
            className="category-upload"
            iconName={downloadImg}
            ariaLabel={polyglot.t("Upload")}
          >
            {polyglot.t("Upload")}
          </ButtonPrimaryWithIcon>
          <ButtonSecondaryWithIcon
            iconImg={download}
            iconName={downloadImg}
            ariaLabel={polyglot.t("download.label")}
          >
            {" "}
            {polyglot.t("download.label")}
          </ButtonSecondaryWithIcon>
        </div>

        <div className="col-lg-6 col-md-6 text-right">
          <ButtonSecondaryWithIcon
            iconName={backArrowFill}
            onClick={backHandle}
            ariaLabel={polyglot.t("Back")}
          >
            {polyglot.t("Back")}
          </ButtonSecondaryWithIcon>
        </div>
      </div>
    </div>
  );
}

export default QuestionButtons;
