import { Panel } from "appkit-react";
import * as d3 from "d3";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";

import overview from "../../../../Assets/Icons/pie-chart-outline.svg";
import { polyglotLoader } from "../../../../Services/Shared/Translate";

// DRILL DOWN COMPONENT
function DrillDown(props) {
  var polyglot = polyglotLoader();
  const { childItems } = props;
  const [DrillDownData, setDrillData] = useState(props.drillDownData);
  const [childData, setChildData] = useState([]);
  var getWidth = window.innerWidth / 3.4;
  var getHeight = window.innerWidth / 1.4;
  var getRadius = window.innerWidth / 11.3;
  var getMargin = window.innerWidth / 54.64;

  // FETCH CHILD DATA
  const fetchChildData = (data) => {
    setChildData(data);
    props.FetchchildData(data);
  };

  // RESET DRILL DOWN
  const ResetDrillDown = async () => {
    fetchChildData({});
    DrillDownData.map((each) => {
      delete each.childData;
      each.IsActive = false;
    });
    setDrillData(DrillDownData);
    var visualization = {};
    await RenderDrillDown(visualization, fetchChildData, DrillDownData).donut.show();
  };

  // SCROLL TO A SPECIFIC COMPONENT
  const ScrollToComponent = () => {
    const parent = window;
    let Scroll = document.getElementsByClassName("scrollTo");
    if (Scroll.length) {
      parent.scrollTo(0, Scroll[0].offsetTop);
    }
  };

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    ScrollToComponent();
    handleResize();
  }, [childItems.label]);

  // HANDLE RESIZE
  const handleResize = () => {
    getMargin = 50;
    getRadius = (410 * 25) / 100;
    var visualization = {};
    async function DrillDownFetch() {
      await RenderDrillDown(visualization, fetchChildData, DrillDownData).donut.show();
    }
    DrillDownFetch();
  };

  window.addEventListener("resize", handleResize);

  // RENDER DRILL DOWN
  const RenderDrillDown = (visualization, fetchChildData, DrillDownData) => {
    visualization.donut = {};
    var topLevelItem = { label: "" };
    const dataOriginal = DrillDownData.slice(0);
    var selectedPath = [];
    var transformAttrValue = function (adjustLeft) {
      var leftValue = getMargin + getRadius;
      if (adjustLeft) {
        leftValue += adjustLeft;
      }
      return "translate(" + leftValue + "," + (getRadius + getMargin) + ")";
    };

    var colors = d3.scaleOrdinal(["#d93954", "#eb8c00", "#933401"]);

    var chart,
      pieSVG,
      chartLabelsGroup,
      chartCenterLabelGroup,
      chartCenterLabel,
      arcSmall,
      pie,
      path,
      arcOver;

    function zoomIn(d) {
      fetchChildData(d.data);
    }

    function drawPrimaryPaths(data) {
      var pathUpdate = chart.selectAll("path").data(pie(data));

      var pathEnter = pathUpdate.enter().append("path");

      pathUpdate.exit().remove();

      var pathEnterUpdate = pathEnter.merge(pathUpdate);

      pathEnterUpdate
        .style("fill", function (d) {
          return colors(d.data.colorIndex);
        })
        .attr("class", "dd-path")
        .attr("d", function (d) {
          var startAngle = d.startAngle,
            endAngle = d.endAngle;

          let Arc = d3
            .arc()
            .outerRadius(getRadius)
            .innerRadius(getRadius - 20)
            .startAngle(function () {
              return startAngle;
            })
            .endAngle(function () {
              return endAngle;
            })
            .innerRadius(function () {
              return getRadius - 20;
            })
            .outerRadius(function () {
              return getRadius;
            });

          let ArcOver = d3
            .arc()
            .outerRadius(getRadius * 1.1)
            .innerRadius(getRadius - 20)
            .startAngle(function () {
              return startAngle;
            })
            .endAngle(function () {
              return endAngle;
            })
            .innerRadius(function () {
              return getRadius - 20;
            })
            .outerRadius(function () {
              return getRadius * 1.1;
            });
          if (d.data.IsActive) {
            return ArcOver();
          }
          return Arc();
        })
        .each(function (d) {
          this._current = d;
        })
        .on("mouseover", function () {
          d3.select(this).transition().duration(100).ease(d3.easeQuadOut).attr("d", arcOver);
        })
        .on("mouseout", function () {
          d3.select(this)
            .transition()
            .duration(500)
            .ease(d3.easeBounceOut)
            .attr("d", function (d) {
              var startAngle = d.startAngle,
                endAngle = d.endAngle;

              let Arc = d3
                .arc()
                .outerRadius(getRadius)
                .innerRadius(getRadius - 20)
                .startAngle(function () {
                  return startAngle;
                })
                .endAngle(function () {
                  return endAngle;
                })
                .innerRadius(function () {
                  return getRadius - 20;
                })
                .outerRadius(function () {
                  return getRadius;
                });

              let ArcOver = d3
                .arc()
                .outerRadius(getRadius * 1.1)
                .innerRadius(getRadius - 20)
                .startAngle(function () {
                  return startAngle;
                })
                .endAngle(function () {
                  return endAngle;
                })
                .innerRadius(function () {
                  return getRadius - 20;
                })
                .outerRadius(function () {
                  return getRadius * 1.1;
                });
              if (d.data.IsActive) {
                return ArcOver();
              }
              return Arc();
            });
        })
        .on("click", function (_event, d) {
          zoomIn(d);
          data.map((obj) => {
            if (obj.label == d.data.label) {
              obj.IsActive = true;
            } else {
              obj.IsActive = false;
            }
          });
          drawPrimaryPaths(data);
        });
      path = pathEnterUpdate;
      return pathEnterUpdate;
    }

    // CAPITALISE FIRST LETTER
    function capitaliseFirstLetter(str) {
      const firstLetter = str.slice(0, 1).toUpperCase();
      const remainingStr = str.slice(1).toLowerCase();
      return `${firstLetter}${remainingStr}`;
    }
    // UPDATE PIE CHART LABELS
    function updatePieLabels() {
      chartCenterLabel.text(getCurrentItem().label);

      chartLabelsGroup.html("");

      var sliceLabels = chartLabelsGroup.selectAll("text").data(pie(path.data()));
      var currentData = getCurrentItemData();

      sliceLabels
        .enter()
        .append("text")
        .transition()
        .duration(750)
        .style("font-family", "PwC Helvetica Neue")
        .attr("class", "outer-label")
        .attr("transform", function (d) {
          var d1 = 90;
          var d2 = 100;
          var d3 = 100;
          var d4 = 100;
          var d5 = 100;
          var d6 = 100;
          if (d.startAngle === 0) {
            return "translate(" + [d1, -d2] + ")";
          }
          if (d.startAngle === 3.7699111843077517) {
            return "translate(" + [-d3, d4] + ")";
          }
          if (d.startAngle === 5.026548245743669) {
            return "translate(" + [-d5, -d6] + ")";
          }
        })
        .text(function (d, i) {
          return capitaliseFirstLetter(`${currentData[i].label} (${currentData[i].count})`);
        });
    }

    // GET CURRENT ITEM
    function getCurrentItem() {
      if (selectedPath.length === 0) {
        return topLevelItem;
      }
      var currentItem = DrillDownData;
      for (var i = 0; i < selectedPath.length; i++) {
        if (i + 1 < selectedPath.length) {
          currentItem = currentItem[selectedPath[i]].childData;
        } else currentItem = currentItem[selectedPath[i]];
      }
      return currentItem;
    }

    // GET CURRENT ITEM DATA
    function getCurrentItemData(minusIndex) {
      minusIndex = minusIndex | 0;
      var currentItem = DrillDownData;

      for (var i = 0; i < selectedPath.length - minusIndex; i++) {
        currentItem = currentItem[selectedPath[i]].childData;
      }
      return currentItem;
    }

    visualization.donut.show = function (DrillDownData) {
      var svgContainer = d3.select("#graph-container");
      svgContainer.html("");
      DrillDownData = dataOriginal.slice(0);
      selectedPath = [];

      //Primary Chart
      pieSVG = svgContainer
        .append("svg")
        .attr("id", "svg-container")
        .attr("class", "donut-chart-custom-styles");
      // .attr('width', getWidth)
      // .attr('height', getHeight);

      chart = pieSVG.append("g").attr("class", "primary").attr("transform", transformAttrValue());

      chartLabelsGroup = pieSVG
        .append("g")
        .attr("class", "labelGroup")
        .attr("transform", transformAttrValue(-35));

      chartCenterLabelGroup = pieSVG
        .append("g")
        .attr("class", "labelCenterGroup")
        .attr("transform", transformAttrValue())
        .style("text-transform", "");

      chartCenterLabel = chartCenterLabelGroup
        .append("text")
        .attr("dy", ".35em")
        .attr("class", "chartLabel center")
        .attr("text-anchor", "middle");

      d3.arc()
        .outerRadius(getRadius)
        .innerRadius(getRadius - 20);

      arcSmall = d3
        .arc()
        .outerRadius(getRadius + 50)
        .innerRadius(getRadius + 40);

      // Arc Interaction Sizing

      arcOver = d3
        .arc()
        .outerRadius(getRadius * 1.1)
        .innerRadius(getRadius - 20);

      pie = d3
        .pie()
        .value(function (d) {
          return d.value;
        })
        .sort(null);

      path = drawPrimaryPaths(DrillDownData);

      updatePieLabels();

      pieSVG.append("g").attr("class", "secondary").attr("transform", transformAttrValue());
    };

    return visualization;
  };

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    var visualization = {};
    async function DrillDownFetch() {
      await RenderDrillDown(visualization, fetchChildData, DrillDownData).donut.show();
    }
    DrillDownFetch();
  }, []);

  return (
    <Panel className="Milestone-Queen-tot-two">
      <div className="display-flex">
        <div className="flex-start">
          <img alt="overview" src={overview} className="d-icons" />
          <p className="d-title">{polyglot.t("Overview")}</p>
        </div>
        <div className="reset-container-dd">
          {Object.keys(childData).length ? (
            <span title="reset" className="icon-refresh-fill cursor" onClick={() => ResetDrillDown()}></span>
          ) : null}
        </div>
      </div>
      <div id="graph-container" style={{ height: "300px" }}></div>
    </Panel>
  );
}

DrillDown.propTypes = {
  drillDownData: PropTypes.array,
  FetchchildData: PropTypes.func,
  data: PropTypes.object,
};

export default DrillDown;
