import { Input, TextArea, Select, SelectOption, SelectGroupTitle, Checkbox } from "appkit-react";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";

import EditAnswerBlock from "./EditAnswerBlock";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import SelectCustom from "../../../Assets/Components/Select";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/GRC/loading-spin.svg";
import QuestionService from "../../../Services/GRC/QuestionService";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import { useHttpPostClient } from "../../../Services/GRC/http-hook-post";
import { useHttpPutClient } from "../../../Services/GRC/http-hook-put";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";

import "../Question/index.css";
import { questionnaireValidationSchema } from "../../../utility/textFieldValidation";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import { AnswerType } from "../Question/AnswerType";
import EmptyPanel from "../Shared/EmptyPanel";
import ErrorPanel from "../Shared/ErrorPanel";
import { GetBreadcrumHTMLByString } from "../handlers";

const RightQuestionnaireQuestion = ({ id, usemode }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { isLoadingPut, errorPut, sendRequestPut, clearErrorPut } = useHttpPutClient();
  const { isLoadingPost, errorPost, sendRequestPost, clearErrorPost } = useHttpPostClient();
  const polyglot = polyglotLoader();

  // manage data load
  const [answerTypes, setAnswertypes] = useState([]);
  const [disabledAnswerTypes, setDisabledAnswerTypes] = useState([]);
  const [assessmentTypes, setAssessmenttypes] = useState([]);
  const [tagList, setTags] = useState([]);
  const [editQuestionData, setEditQuestionData] = useState([]);
  const [categoryPath, setCategoryPath] = useState("");
  const [categoryId, setCategoryId] = useState();

  const [customCount, setCustomCount] = useState(2);
  const [tagsData, settagsData] = useState([]);
  const [answersdata, setAnswerdata] = useState([]);

  const initialValuesOfForm = {
    id: 0,
    question_text: "",
    addcomment: false,
    commentmandatory: false,
    addfile: false,
    fileuploadmandatory: false,
    assessmentid: "",
    answertypeid: "",
    not_applicable: false,
    scoring_top: "",
  };

  const [initialFormValues, setInitialFormValues] = useState(initialValuesOfForm);

  // compress the long question text
  const limitQuestionText = (questionText) => {
    try {
      let displayQuestionText = "";
      const charactersOfViewableQuestionText = 20;
      if (questionText.length > charactersOfViewableQuestionText) {
        displayQuestionText = `${questionText.substring(0, charactersOfViewableQuestionText)}...`;
      } else {
        displayQuestionText = questionText;
      }
      return displayQuestionText;
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  const getDisabledAnswertypes = (answerTypeList) => {
    let newAnswerTypelist = answerTypeList.filter((e) => e.options === "values");
    let disableList = [];
    newAnswerTypelist.forEach((element) => {
      disableList.push(element.values.split(","));
    });
    return [...new Set(disableList.flat())];
  };

  const onLoadListData = async () => {
    try {
      const assessmentTypeList = await sendRequest(QuestionService.get().getAssessmentTypesURL());
      setAssessmenttypes(assessmentTypeList);

      const answerTypeList = await sendRequest(QuestionService.get().getAnswerTypeURL());
      setAnswertypes(answerTypeList);
      setDisabledAnswerTypes(getDisabledAnswertypes(answerTypeList));

      const responseTagsData = await sendRequest(QuestionService.get().getTagsURL());
      setTags(responseTagsData);

      const editQuestionData = await sendRequest(QuestionService.get().getEditQuestionDataURL(id));

      let selectedTags = [];
      selectedTags.length = 0;
      if (editQuestionData[2].length > 0) {
        selectedTags = editQuestionData[2].map((e) => {
          return e.id;
        });
      }
      settagsData(selectedTags);
      setEditQuestionData(editQuestionData);
      const receivedBreadCrumString = editQuestionData[0][0].breadcrumString;
      const questionText = limitQuestionText(editQuestionData[0][0].question_text);
      setCategoryPath(`${receivedBreadCrumString} > ${questionText}`);
      setCategoryId(editQuestionData[0][0].parent_id);
      setAnswerdata(editQuestionData[1]);
      setCustomCount(
        editQuestionData[1].filter((question) => question.answer_text !== "not applicable").length,
      );

      //
      let initNewArrDq = [];
      initNewArrDq.length = 0;
      for (let k = 0; k < editQuestionData[1].length; k++) {
        initNewArrDq.push({
          id: k,
          dependency_question: [],
          mode: parseInt(editQuestionData[1][k].dependency_question_id) > 0 ? "edit" : "add",
        });
      }
      sessionStorage.setItem("depenent_quest_Id", JSON.stringify(initNewArrDq));

      let initialValuesOfEditForm = {};
      initialValuesOfEditForm.id = editQuestionData[0][0].id;

      initialValuesOfEditForm.question_text = editQuestionData[0][0].question_text;
      initialValuesOfEditForm.addcomment = editQuestionData[0][0].is_add_comment;
      initialValuesOfEditForm.commentmandatory = editQuestionData[0][0].is_comment_mandatory;
      initialValuesOfEditForm.addfile = editQuestionData[0][0].is_add_file;
      initialValuesOfEditForm.not_applicable = editQuestionData[0][0].not_applicable;
      initialValuesOfEditForm.scoring_top = editQuestionData[0][0].score ? editQuestionData[0][0].score : "";
      initialValuesOfEditForm.fileuploadmandatory = editQuestionData[0][0].is_file_mandatory;
      initialValuesOfEditForm.assessmentid = editQuestionData[0][0].assessment_type_id;
      initialValuesOfEditForm.answertypeid = editQuestionData[0][0].answer_type_id;

      setInitialFormValues(initialValuesOfEditForm);

      //load answer type
      //setEditAnsType(editQuestionData[0][0].answer_type_id)
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } catch (err) {
      // "The user aborted a request."
      // displayToastMessage(err.message, "error");
    }
  };
  useEffect(() => {
    onLoadListData();
  }, [sendRequest, id]);

  let newArr = [];
  let newArrDq = [];
  const setAnsType = (value) => {
    const selectedAnswertypes = answerTypes.filter((item) => item.id === value)[0];
    newArr.length = 0;
    newArrDq.length = 0;

    if (selectedAnswertypes.options === "values") {
      const elments = selectedAnswertypes.values.split(selectedAnswertypes.config);
      for (let k = 0; k < elments.length; k++) {
        newArr.push({
          id: k,
          answer_text: elments[k],
          additional_answer_text: "",
          scoring: 0,
          weightage: 0,
          dependency_question_id: 0,
          readonly: selectedAnswertypes.is_readonly,
          hasError: false, // checks if the row has any error or not
        });
        newArrDq.push({
          id: k,
          dependency_question: [],
          mode: parseInt(setAnswerdata.dependency_question_id) > 0 ? "edit" : "add",
        });
      }
    } else if (selectedAnswertypes.options === "freebox") {
      newArr.push({
        id: 1,
        answer_text: "",
        additional_answer_text: "",
        scoring: 0,
        weightage: 0,
        dependency_question_id: 0,
        readonly: selectedAnswertypes.is_readonly,
        hasError: false, // checks if the row has any error or not
      });
      newArrDq.push({
        id: 1,
        dependency_question: [],
        mode: parseInt(setAnswerdata.dependency_question_id) > 0 ? "edit" : "add",
      });
    } else if (selectedAnswertypes.options === "renge") {
      for (let i = 0; i < customCount; i++) {
        newArr.push({
          id: i,
          answer_text: "",
          additional_answer_text: "",
          scoring: 0,
          weightage: 0,
          dependency_question_id: 0,
          readonly: selectedAnswertypes.is_readonly,
          hasError: false, // checks if the row has any error or not
        });
        newArrDq.push({
          id: i,
          dependency_question: [],
          mode: parseInt(setAnswerdata.dependency_question_id) > 0 ? "edit" : "add",
        });
      }
    }
    sessionStorage.setItem("depenent_quest_Id", JSON.stringify(newArrDq));

    setAnswerdata(newArr);
  };
  const decrementCount = (e, min) => {
    e.preventDefault();
    if (customCount > min) {
      const count = customCount - 1;
      setCustomCount(count);
      // to do update setAnswerdata()
      const answersWithoutNotApplicable = answersdata.filter(
        (answer) => answer.answer_text !== "not applicable",
      );
      answersWithoutNotApplicable.pop();
      setAnswerdata(answersWithoutNotApplicable);
    }
  };
  const incrementCount = (e, max) => {
    e.preventDefault();
    if (customCount < max) {
      const count = customCount + 1;
      setCustomCount(count);

      const answersWithoutNotApplicable = answersdata.filter(
        (answer) => answer.answer_text !== "not applicable",
      );
      let newAnstwerTemplate = {
        id: answersWithoutNotApplicable.length + 1,
        answer_text: "",
        additional_answer_text: "",
        scoring: 0,
        weightage: 0,
        dependency_question_id: 0,
        readonly: false,
        hasError: false, // checks if the row has any error or not
      };
      setAnswerdata([...answersWithoutNotApplicable, newAnstwerTemplate]);
    }
  };

  const resetAdditionalFormFields = () => {
    try {
      // empty tags feild
      settagsData([]);
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };
  const handlerEditQuestionSubmit = async (values, { setSubmitting, setErrors, setStatus, resetForm }) => {
    const errorsInAnswerData = answersdata.filter((each) => each.hasError === true);
    if (errorsInAnswerData.length > 0) {
      return displayToastMessage(
        "Please resolve all the errors present in answer boxes before saving the question",
        "error",
      );
    }
    const answersWithoutNotApplicable = answersdata.filter(
      (answer) => answer.answer_text !== "not applicable",
    );
    const emptyQuestionsInAnswerData = answersWithoutNotApplicable.filter(
      (answer) => answer.answer_text.trim().length === 0,
    );
    if (emptyQuestionsInAnswerData.length > 0) {
      const emptyAnswersIndexes = emptyQuestionsInAnswerData.map(
        (emptyAnswer) => answersWithoutNotApplicable.indexOf(emptyAnswer) + 1,
      );
      return displayToastMessage(
        `Answers cannot be empty. Please fill in answers: ${emptyAnswersIndexes.join(", ")}.`,
        "error",
      );
    }

    let finalanswerdata = answersdata;
    if (!values.not_applicable) {
      finalanswerdata = answersdata.filter((e) => e.answer_text != "not applicable");
      setAnswerdata(finalanswerdata);
    } else {
      let cntNA = answersdata.find((e) => e.answer_text === "not applicable");
      // // not applicable not found
      if (!cntNA) {
        let notApplicableArr = {
          id: answersdata.length,
          answer_text: "not applicable",
          additional_answer_text: "",
          scoring: 0,
          weightage: 0,
          dependency_question_id: 0,
          readonly: true,
          hasError: false,
        };
        finalanswerdata = answersdata.concat(notApplicableArr);
      }
    }
    const createdBy = JSON.parse(sessionStorage.getItem("user")).userid;
    if (errorsInAnswerData.length === 0) {
      const putinput = {
        id: values.id,
        parent_id: categoryId,
        question_text: values.question_text,
        assessment_type_id: values.assessmentid,
        answer_type_id: values.answertypeid,
        is_add_comment: values.addcomment,
        is_comment_mandatory: values.commentmandatory,
        created_by: createdBy,
        is_add_file: values.addfile,
        is_file_mandatory: values.fileuploadmandatory,
        not_applicable: values.not_applicable,
        scoring_top: values.scoring_top,
        question_type_id: 1, // if question add through category then 1,if direct add 0
        selected_tags: tagsData.toString(),
        selected_answers: JSON.stringify(finalanswerdata),
        language_id: 1, // 1=>english
      };

      const response = await sendRequestPut(
        QuestionService.get().putQuestionURL(),
        JSON.stringify(putinput),
        {
          "Content-Type": "application/json",
        },
      );

      if (response > 0) {
        displayToastMessage(polyglot.t("Question updated successfully"), "success");
        // setlatestId(response);
        resetForm({});
        resetAdditionalFormFields();
        setAnswerdata([]);
        // fetch all the apis which will run at the time of initial load
        onLoadListData();
      }

      let sessionStuffDq = JSON.parse(sessionStorage.getItem("depenent_quest_Id"));

      for (let i = 0; i < sessionStuffDq.length; i++) {
        if (sessionStuffDq[i].dependency_question.length != 0) {
          if (sessionStuffDq[i].mode === "add") {
            const result = await sendRequestPost(
              QuestionService.get().postDependancyQuestionURL(),
              JSON.stringify(sessionStuffDq[i].dependency_question),
              {
                "Content-Type": "application/json",
              },
            );
          } else {
            const resultEdit = await sendRequestPut(
              QuestionService.get().putDependencyQuestionURL(),
              JSON.stringify(sessionStuffDq[i].dependency_question),
              {
                "Content-Type": "application/json",
              },
            );
          }
        }
      }
    }
  };
  const onChangeTags =
    (formField) =>
    ({ target }) => {
      const data = target.value;
      settagsData(data);
    };

  return (
    <>
      {usemode && usemode == "readonly" ? null : (
        <div className="row">
          <div className="col-md-12 p-0">
            <h5 className="headertest p-l-1rem">{polyglot.t("edit_question")}</h5>
            <div className="a-py-10 border-top a-border-light a-mt-10 category-divider"></div>
          </div>
        </div>
      )}

      <div className="row">
        <div className="col-md-12">
          {usemode && usemode == "readonly" ? null : (
            <>
              {categoryPath && (
                <div className="category-breadcrumb-box input-field-height">
                  <GetBreadcrumHTMLByString breadcrumString={categoryPath} />
                </div>
              )}
            </>
          )}

          <br />
        </div>
      </div>
      {error && <ErrorPanel error={error} onClear={clearError} />}
      {isLoading && (
        <div>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      )}
      {!isLoading && editQuestionData.length === 0 && <EmptyPanel massage={"No data found."} />}
      {!isLoading && initialFormValues && (
        <Formik
          initialValues={initialFormValues}
          onSubmit={handlerEditQuestionSubmit}
          validationSchema={questionnaireValidationSchema}
          enableReinitialize
        >
          {(props) => {
            const { values, handleChange, handleBlur, handleSubmit, errors, touched, setFieldValue } = props;
            return (
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <TextArea
                      placeholder={`${polyglot.t("description.label")}*`}
                      value={values.question_text}
                      onChange={handleChange("question_text")}
                      className="Notes proj-notes-txt-area"
                      aria-label={polyglot.t("description.label")}
                      disabled={usemode && usemode == "readonly" ? true : false}
                    />
                    {/* MAX NUMBER OF CHARACTERS ALLOWED IS 2000 */}
                    {usemode && usemode == "readonly" ? null : <div>{values.question_text.length}/2000</div>}
                    {errors.question_text && touched.question_text ? (
                      <div className="error">{polyglot.t(errors.question_text)}</div>
                    ) : null}
                    <br />
                  </div>
                  <div className="noofcharacters">{/*    (Number of characters{charCount}) */}</div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <SelectCustom
                      onSelect={onChangeTags}
                      placeholder={
                        usemode && usemode == "readonly" ? polyglot.t("no_tags") : polyglot.t("select_tags")
                      }
                      multiple
                      showSelectAll
                      value={tagsData}
                      JSONArray={tagList}
                      optionKey="value"
                      optionLabel="label"
                      formLabel="tags"
                      disabled={usemode && usemode == "readonly" ? true : false}
                    />
                  </div>
                </div>
                <div className="row p-t-1point25rem">
                  <div className="col-md-7">
                    <div className="row input-field-height">
                      <div className="col-md-6 m-auto m-l-0">
                        <Checkbox
                          disabled={usemode && usemode == "readonly" ? true : false}
                          name="addcomment"
                          checked={values.addcomment}
                          onChange={handleChange("addcomment")}
                        >
                          Add comment field
                        </Checkbox>
                      </div>
                      {values.addcomment && (
                        <div className="col-md-6 m-auto m-l-0">
                          <Checkbox
                            disabled={usemode && usemode == "readonly" ? true : false}
                            name="commentmandatory"
                            checked={values.commentmandatory}
                            onChange={handleChange("commentmandatory")}
                          >
                            Comment mandatory
                          </Checkbox>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="col-md-5">
                    <Select
                      className=""
                      disabled={usemode && usemode == "readonly" ? true : false}
                      placeholder={`${polyglot.t("type_of_assessment")} *`}
                      value={values.assessmentid}
                      onSelect={(value) => handleChange("assessmentid")({ target: { value } })}
                    >
                      <SelectGroupTitle>{polyglot.t("assessment.type")}</SelectGroupTitle>
                      {assessmentTypes.map((assessmentType, index) => {
                        return (
                          <SelectOption key={index + 1} value={assessmentType.id}>
                            {assessmentType.name}
                          </SelectOption>
                        );
                      })}
                    </Select>
                    {touched.assessmentid ? (
                      <span className="error">{errors.assessmentid}</span>
                    ) : (
                      null && errors.assessmentid && <span className="error">{errors.assessmentid}</span>
                    )}
                  </div>
                </div>
                <div className="row p-t-1point25rem">
                  <div className="col-md-7">
                    <div className="row input-field-height">
                      <div className="col-md-6 m-auto m-l-0">
                        <Checkbox
                          disabled={usemode && usemode == "readonly" ? true : false}
                          name="addfile"
                          checked={values.addfile}
                          onChange={handleChange("addfile")}
                        >
                          Add file
                        </Checkbox>
                      </div>
                      {values.addfile && (
                        <div className="col-md-6 m-auto m-l-0">
                          <Checkbox
                            disabled={usemode && usemode == "readonly" ? true : false}
                            name="fileuploadmandatory"
                            checked={values.fileuploadmandatory}
                            onChange={handleChange("fileuploadmandatory")}
                          >
                            File upload mandatory
                          </Checkbox>
                        </div>
                      )}
                    </div>
                  </div>
                  <AnswerType
                    answerTypes={answerTypes}
                    customCount={customCount}
                    polyglot={polyglot}
                    touched={touched}
                    decrementCount={decrementCount}
                    errors={errors}
                    handleChange={handleChange}
                    incrementCount={incrementCount}
                    setAnsType={setAnsType}
                    values={values}
                    disabled={usemode === "readonly"}
                  />
                </div>
                <div className="row p-t-1point25rem">
                  <div className="col-md-7">
                    <div className="row input-field-height">
                      <div className="col-md-6 m-auto m-l-0">
                        <Checkbox
                          name="not_applicable"
                          disabled={usemode && usemode == "readonly" ? true : false}
                          checked={values.not_applicable}
                          onChange={handleChange("not_applicable")}
                        >
                          Not applicable
                        </Checkbox>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5">
                    <Input
                      type="number"
                      inputBoxSize="sm"
                      placeholder="Scoring *"
                      disabled={usemode && usemode == "readonly" ? true : false}
                      onChange={handleChange("scoring_top")}
                      onKeyPress={(e) => {
                        const regex = /[0-9]/;
                        // PREVENT ANY THING IF IT IS NOT 0-9
                        if (!regex.test(e.key)) {
                          e.preventDefault();
                        }
                      }}
                      value={values.scoring_top.toString()}
                    />
                    {errors.scoring_top && touched.scoring_top ? (
                      <div className="error">{polyglot.t(errors.scoring_top)}</div>
                    ) : null}
                  </div>
                </div>

                <div className="row p-t-1point25rem">
                  <div className="col-md-12">
                    {answersdata.length > 0 && (
                      <EditAnswerBlock
                        setAnswerdata={setAnswerdata}
                        answers={answersdata}
                        categoryid={categoryId}
                        previous_ans_Id={values.answertypeid}
                        disableAnswerTypes={disabledAnswerTypes}
                        questId={editQuestionData[0][0].id}
                        answerTypeId={values.answertypeid}
                        usemode={usemode}
                      />
                    )}
                  </div>
                </div>
                {usemode && usemode == "readonly" ? null : (
                  <div className="row">
                    <div className="col-md-12">
                      <ButtonPrimary
                        className="custome-pos float-right"
                        onClick={handleSubmit}
                        ariaLabel={polyglot.t("update")}
                      >
                        {polyglot.t("update")}
                      </ButtonPrimary>
                    </div>
                  </div>
                )}

                <div className="col-md-12 edit-clientform-button p-0">
                  <div className="d-flex justify-content-between"></div>
                </div>
              </div>
            );
          }}
        </Formik>
      )}
    </>
  );
};

export default RightQuestionnaireQuestion;
