import { Input, TextArea, Panel, Progress } from "appkit-react";
import { Formik } from "formik";
import React, { useState, useEffect, useCallback } from "react";

import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { ReactComponent as LoadingSpinner } from "../../../../Assets/Images/GRC/loading-spin.svg";
import AssessmentService from "../../../../Services/GRC/AssessmentService";
import "./index.css";
import "../../../../Assets/Css/GRC/style.css";
import { ASSESSMENT_SELECTQUESTIONS_PATH } from "../../../../Constants/Path";
import { useHttpClient } from "../../../../Services/GRC/http-hook";
import { useHttpPostClient } from "../../../../Services/GRC/http-hook-post";
import { getProjectID_Of_solution_module } from "../../../../Services/Shared/Handlers";
import { polyglotLoader } from "../../../../Services/Shared/TranslateGRC";
import { assessmentValidationSchema } from "../../../../utility/textFieldValidation";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";
import ErrorPanel from "../../Shared/ErrorPanel";
import DropdownContainer from "../DropdownContainer";

/**
 * Start to create assessment with addedd questionnarie
 *
 * @param {props} props
 * @returns
 */
const Create = (props) => {
  const [selectedQuestionnaires, setSelectedQuestionnaires] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { sendRequestPost } = useHttpPostClient();
  const [loadCategoryAssessment, setloadCategoryAssessment] = useState([]);
  const polyglot = polyglotLoader();
  const initialValuesOfForm = {
    name: "",
    industry: "",
    description: "",
  };

  useEffect(() => {
    const getCategoriesAssessment = async () => {
      try {
        const allQuestionnaires = await sendRequest(AssessmentService.get().getAssessmentCategoriesURL());
        if (allQuestionnaires === undefined) {
          return [];
        }

        const map = {};
        const roots = [];

        allQuestionnaires.forEach((node, i) => {
          map[node.value] = i;
          node.className = "appkiticon material-icons icon-folder-closed-outline";
        });

        allQuestionnaires.forEach((node) => node.tagLebel === "0" && roots.push(node));

        setloadCategoryAssessment(roots);
      } catch (err) {
        console.log(err);
      }
    };
    getCategoriesAssessment();
  }, [sendRequest]);

  const handleCreateAssessment = async (values, { setSubmitting, setErrors, setStatus, resetForm }) => {
    const projectid = getProjectID_Of_solution_module();
    const created_by = JSON.parse(sessionStorage.getItem("user")).userid;
    const { name, description, industry } = values;
    const assessmentCheck = {
      name,
      projectid,
      userid: created_by,
    };

    const responseData = await sendRequestPost(
      AssessmentService.get().postExistAssessmentURL(),
      JSON.stringify(assessmentCheck),
      {
        "Content-Type": "application/json",
      },
    );
    if (responseData > 0) {
      displayToastMessage(polyglot.t("name.existsError"), "error");
      values.name = "";
      values.description = "";
      return false;
    }

    //if NOT THEN POST
    else {
      if (selectedQuestionnaires.length === 0) {
        displayToastMessage(polyglot.t("questionnaire.selectDefault"), "error");
        return false;
      }
      const postData = {
        name,
        description,
        created_by,
        industry,
        licence_package: "",
        selected_questionary: selectedQuestionnaires.join(","),
        projectid,
      };
      const response = await sendRequestPost(
        AssessmentService.get().postAssessmentURL(),
        JSON.stringify(postData),
        {
          "Content-Type": "application/json",
        },
      );

      // if POST SUCESS REDIRECT NEXT

      if (response > 0) {
        displayToastMessage(polyglot.t("assessment.successfully.added"), "success");
        resetForm({});
        // move next page
        props.history.push({
          pathname: ASSESSMENT_SELECTQUESTIONS_PATH,
          state: { mode: "add", id: response, title: name },
        });
      }
    }
  };

  const onChange = useCallback((_currentNode, selectedNodes) => {
    setSelectedQuestionnaires([...selectedNodes].map((node) => node.value));
  }, []);

  return (
    <div className="row m-0">
      <div className="col-md-3 pl-0 mb-2 d-inline-block">
        <Panel className="panel panel-top-free-space sr-questionaries2">
          {error && <ErrorPanel error={error} onClear={clearError} />}
          {isLoading && (
            <div>
              <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
            </div>
          )}
          {!isLoading && loadCategoryAssessment && (
            <div>
              <p className="left-panel-heading">Select Questionnaires</p>
              {selectedQuestionnaires.length > 0 && (
                <p className="for-bold">
                  <span className="text-danger">{selectedQuestionnaires.length}</span> Selected
                  Questionnaire(s)
                </p>
              )}
              <DropdownContainer
                data={loadCategoryAssessment}
                onChange={onChange}
                className="mdl-assessment assessment-create"
                showDropdown={"always"}
                pageType={"assessment"}
                inlineSearchInput={true}
              />
            </div>
          )}
        </Panel>
      </div>

      <div className="col-md-9 usertoadd-responsive p-0 mt-3 pr-3">
        <div className="row">
          <div className="col-md-12 grc-assment-progressbar">
            <Progress percent={3} indicators={["Create", "Set Questions", "Preview"]} />
          </div>
        </div>

        <Panel className="padding-1rem mt-3">
          <div className="row">
            <div className="col-md-12">
              <Formik
                initialValues={initialValuesOfForm}
                onSubmit={handleCreateAssessment}
                validationSchema={assessmentValidationSchema}
              >
                {(props) => {
                  const { values, handleChange, handleSubmit, errors, touched, isSubmitting, dirty } = props;
                  return (
                    <div>
                      <div className="row">
                        <div className="col-md-12">
                          <h3 className="right-panel-heading">Create new assessment</h3>
                          <hr className="p-0" />
                        </div>
                      </div>
                      <div className="row top-spacing">
                        <div className="col-md-12">
                          <Input
                            inputBoxSize="sm"
                            placeholder={`${polyglot.t("name.label")}*`}
                            value={values.name}
                            onChange={handleChange("name")}
                            aria-label={polyglot.t("name.label")}
                          />
                          {(errors.name && touched.name) || dirty ? (
                            <div className="error">{errors.name}</div>
                          ) : null}
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12 ">
                          <Input
                            inputBoxSize="sm"
                            placeholder={`${polyglot.t("industry")}`}
                            value={values.industry}
                            onChange={handleChange("industry")}
                            aria-label={polyglot.t("industry")}
                          />
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-md-12">
                          <TextArea
                            placeholder={`${polyglot.t("description.label")}*`}
                            value={values.description}
                            onChange={handleChange("description")}
                            className="Notes proj-notes-txt-area"
                            aria-label={polyglot.t("description.label")}
                          />
                          {(errors.description && touched.description) || dirty ? (
                            <div className="error">{errors.description}</div>
                          ) : null}
                          <br />
                        </div>
                        {/* <div className="noofcharacters">(Number of characters)</div> */}
                        <div className="noofcharacters">{values.description.length}/2000</div>
                      </div>

                      <div className="row">
                        <div className="col-md-12">
                          <ButtonPrimary
                            className="custome-pos m-b-2"
                            onClick={handleSubmit}
                            ariaLabel={polyglot.t("save")}
                            disabled={
                              isSubmitting ||
                              !dirty ||
                              !(Object.keys(errors).length === 0 && errors.constructor === Object)
                            }
                          >
                            Save
                          </ButtonPrimary>
                        </div>
                      </div>
                    </div>
                  );
                }}
              </Formik>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default Create;
