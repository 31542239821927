import { Button } from "appkit-react";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";

import CustomModal from "../../../Assets/Components/Modal";
import HomeService from "../../../Services/GRC/HomeService";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";

function DeleteQuestion({ show, close, Item, getQuestions, getCategories }) {
  const { sendRequest } = useHttpClient();
  const polyglot = polyglotLoader();

  // DELETE QUESTION API
  const handleOnDelete = async () => {
    try {
      const responseData = await sendRequest(HomeService.get().deleteQuestionURL(Item.id), "DELETE");
      if (responseData[0].CODE) {
        displayToastMessage(`${Item.display_name} ${polyglot.t("deleted successfully")}`, "success");
        getQuestions();
        getCategories();
        close();
      } else {
        throw new Error(responseData);
      }
    } catch (error) {
      displayToastMessage(error.message, "error");
    }
  };

  return (
    <div>
      <CustomModal
        show={show}
        onCancel={close}
        title={polyglot.t("delete.question.heading")}
        Footer={[
          <Button size="lg" className="primary_button" onClick={handleOnDelete} key="1">
            {polyglot.t("yes, delete")}
          </Button>,
        ]}
      >
        <>
          <div className="row">
            <div className="col-md-12 word-break">
              <p>{polyglot.t("delete.question.data")}.</p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 word-break">
              {polyglot.t("are_you_sure_question")} <strong>{Item.display_name}</strong>?
            </div>
          </div>
        </>
      </CustomModal>
    </div>
  );
}
DeleteQuestion.propTypes = {
  show: PropTypes.bool,
  close: PropTypes.func,
  Item: PropTypes.object,
  getQuestions: PropTypes.func,
  getCategories: PropTypes.func,
};
export default withRouter(DeleteQuestion);
