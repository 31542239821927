/* eslint-disable react-hooks/exhaustive-deps */
import "../../../Assets/Css/QueenBase/Root/Login.css";
import { Input, Select, SelectGroupTitle, SelectOption } from "appkit-react";
import { Formik } from "formik";

import displayToastMessage from "./displayToastMessage";
import eye from "../../../Assets/Icons/view-outline.svg";
import iconeye from "../../../Assets/Images/QueenBase/Root/icon-eye-closed.svg";
import { polyglotLoader, userLanguage } from "../../../Services/Shared/Translate";
import { startCase, camelCase } from "lodash";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import * as Yup from "yup";

import { LoginContext } from "./LoginContext";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import LinkText from "../../../Assets/Components/LinkText/LinkText";
import Loader from "../../../Assets/Images/DFED/icon-loader.svg";
import * as path from "../../../Constants/Path";
import LoginService from "../../../Services/QueenBase/Root/LoginService";
import { check_If_User_LoggedIn, expire_my_session } from "../../../Services/Shared/Authenticate";
import { storeLoginTimeStampBeforeJWT } from "../../../Services/Shared/LoginHandler";
import { DataContext } from "../../../Services/Shared/Store.js";

const MAX_FAILURE_LOGIN_ATTEMPTS = 3;
const ONLY_NUMBER_REGEX = /^\d+$/;

const isNumeric = (val) => ONLY_NUMBER_REGEX.test(val);

const OTPBox_Styles = {
  height: "35px",
  width: "32px",
  backgroundColor: "#dedede",
  border: "none",
  fontWeight: "bold",
};

function Login(props) {
  const { setLoginResponse, initializeLogin, successfulLogin } = useContext(LoginContext);
  const [isPassword, setIsPassword] = useState(true);
  const [OTPContent, setOTPContent] = useState({});
  const [userOTP, setUserOTP] = useState("");
  const [isVerificationStarted, setIsVerificationStarted] = useState(false);
  const [showChooseClient, setShowChooseClient] = useState(false);
  const [associatedClients, setAssociatedClients] = useState([]);
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());

  const initialValues = {
    clientUserId: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    clientUserId: Yup.number().required(polyglot.t("User ID is required")),
    password: Yup.string().required(polyglot.t("Password is required")),
  });

  useEffect(() => {
    if (check_If_User_LoggedIn()) {
      document.body.classList.remove("bg-img");
      props.history.push(path.QUEEN_BASE_PATH);
    } else {
      initializeLogin();
      document.body.classList.add("bg-img");
    }
  }, []);

  const handlePassword = () => {
    setIsPassword((prev) => !prev);
  };

  const onForgotPwd = () => {
    props.history.push(path.FORGOTKEY_PATH);
  };
  const onIDAMLogin = () => {
    try {
      window.location = `${process.env.REACT_APP_SERVER_HOST_BASE}/idamAuthenticate`;
    } catch (err) {
      displayToastMessage("Something went wrong!!", "error");
    }
  };

  const generateOtp = async (values) => {
    try {
      var res = await LoginService.get().generateLoginOTP(values);
      if (res.CODE === 1) {
        const APIResponse = res;
        APIResponse.clientUserId = values.clientUserId;
        APIResponse.password = values.password;
        setOTPContent(APIResponse);
        displayToastMessage(polyglot.t(res.MSG), "success");
      } else {
        if (res.FailedAttempts >= MAX_FAILURE_LOGIN_ATTEMPTS) {
          displayToastMessage(
            polyglot.t("Your account has been locked due to multiple invalid logins"),
            "error",
          );
        } else {
          displayToastMessage(polyglot.t(res.MSG), "error");
        }
      }
    } catch (err) {
      displayToastMessage(polyglot.t("Invalid credentials Please try again!"), "error");
    }
  };

  const captureOTPInputs = async (event) => {
    if (event.keyCode === 13) {
      verifyOtp();
    }
  };

  const verifyOtp = async () => {
    if (userOTP === "") {
      displayToastMessage(polyglot.t("Please enter OTP"), "error");
    } else {
      setIsVerificationStarted(true);
      const loginDetails = {
        clientUserId: OTPContent.clientUserId,
      };
      const requestBody = {
        otp: userOTP,
        credentials: loginDetails,
      };
      var res = await LoginService.get().verifyLoginOTP(requestBody);
      setIsVerificationStarted(false);
      switch (res.CODE) {
        case 1:
          checkAssociatedClients(loginDetails);
          storeLoginTimeStampBeforeJWT();
          break;
        default:
          if (res.CODE === 2) {
            displayToastMessage(polyglot.t(res.MSG), "error");
            expire_my_session();
          } else {
            displayToastMessage(polyglot.t(res.MSG), "error");
          }
      }
    }
  };

  const didNotReceiveOTP = () => {
    const loginDetails = {
      clientUserId: OTPContent.clientUserId,
      password: OTPContent.password,
    };
    generateOtp(loginDetails);
  };

  // API TO FETCH CLIENT DETAILS OF THE USERID
  const checkAssociatedClients = async (values) => {
    try {
      var res = await LoginService.get().fetchClientListByUserId(values);

      if (res.CODE) {
        const totalClients = res.clients.length;
        switch (totalClients) {
          case 0:
            displayToastMessage(polyglot.t("No clients associated to your User ID"), "error");
            break;
          case 1: {
            const clientuserid = res.clients[0].clientuserid;
            login({ ...values, clientDBName: clientuserid });
            break;
          }
          default:
            setShowChooseClient(true);
            setAssociatedClients(res.clients);
            break;
        }
      } else {
        displayToastMessage(res.MSG, "error");
      }
    } catch (err) {
      displayToastMessage(polyglot.t("No clients associated to your User ID"), "error");
    }
  };

  const login = async (values) => {
    try {
      var res = await LoginService.get().handleLoginAuthenticate(values);
      if (res.CODE) {
        setLoginResponse(res);
        if (res.isfirst === 1) {
          props.history.push(path.ENTER_NEW_KEY);
          return null;
        }
        if (res.IsTempPwd) {
          props.history.push(path.ENTER_NEW_KEY);
          return null;
        }
        if (res.ispwc) {
          document.body.classList.remove("bg-img");
          props.history.push(path.ADMIN_PATH);
          return null;
        }

        if (!res.isFirst && !res.IsTempPwd) {
          successfulLogin(res);
          document.body.classList.remove("bg-img");
          props.history.push(path.QUEEN_BASE_PATH);
        }
      } else {
        displayToastMessage(res.MSG, "error");
      }
    } catch (err) {
      displayToastMessage(polyglot.t("Error while logging in, please contact your admin!"), "error");
    }
  };
  const displayLoginForm = () => {
    return (
      <Formik onSubmit={generateOtp} initialValues={initialValues} validationSchema={validationSchema}>
        {(prop) => {
          const { values, handleChange, handleSubmit, errors, touched } = prop;
          return (
            <form onSubmit={handleSubmit}>
              <div>
                <Input
                  inputBoxSize="sm"
                  placeholder={polyglot.t("UserId")}
                  value={values.clientUserId}
                  onChange={handleChange("clientUserId")}
                  className="Box"
                  type="number"
                  aria-label={polyglot.t("UserId")}
                  data-testid="username-input"
                />
                {errors.clientUserId && touched.clientUserId ? (
                  <span className="error">{errors.clientUserId}</span>
                ) : null}
                <br />
                <Input
                  inputBoxSize="sm"
                  placeholder={polyglot.t("Password")}
                  value={values.password}
                  type={isPassword ? "password" : "text"}
                  onChange={handleChange("password")}
                  data-testid="password-input"
                  className="Box"
                  suffix={
                    <img
                      alt=""
                      src={isPassword ? eye : iconeye}
                      title={isPassword ? polyglot.t("Show password") : polyglot.t("Hide password")}
                      className="cursor"
                      onClick={handlePassword}
                    />
                  }
                  aria-label={polyglot.t("Password")}
                />
                {errors.password && touched.password ? (
                  <span className="error">{errors.password}</span>
                ) : null}
                <br />
                <div
                  className="col-md-12 login-button-fullpadding primary_button text-align-center"
                  data-testid="login-button-wrapper"
                >
                  <ButtonPrimary type="submit" ariaLabel={polyglot.t("Log in")}>
                    {polyglot.t("Log in")}
                  </ButtonPrimary>
                </div>
                <div>
                  <div className="row">
                    <div className="col-md-6">
                      <p className="idam-link mt-3 mb-0">
                        <LinkText onClick={onIDAMLogin} ariaLabel={polyglot.t("IDAM Login")}>
                          {polyglot.t("IDAM Login")}
                        </LinkText>
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="ForgotPassword mt-3 mb-0">
                        <LinkText onClick={onForgotPwd} ariaLabel={polyglot.t("Forgot Password?")}>
                          {polyglot.t("Forgot Password?")}
                        </LinkText>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  };

  const displayOTPForm = () => {
    return (
      <div>
        <div>
          <h6>{polyglot.t("Two-factor authentication")}</h6>
          <div className="max-width-fit-content">
            {polyglot.t("We’ve sent you an email with a verification code.")}
          </div>
          <div className="otp-input-align" onKeyDown={captureOTPInputs}>
            <OtpInput
              inputStyle={OTPBox_Styles}
              onChange={(otp) => setUserOTP(otp)}
              numInputs={6}
              value={userOTP}
              shouldAutoFocus={true}
              data-testid="otp-input"
              separator={
                <span
                  style={{
                    marginLeft: "20px",
                  }}
                />
              }
            />
          </div>
          {!isVerificationStarted && (
            <div
              className="row"
              style={{
                marginBottom: "20px",
                marginLeft: "-5PX",
              }}
            >
              <div className="col-md-5" data-testid="submit-otp-wrapper">
                <ButtonPrimary onClick={verifyOtp} ariaLabel={polyglot.t("Submit OTP")}>
                  {polyglot.t("Submit OTP")}
                </ButtonPrimary>
              </div>
            </div>
          )}
          <div>
            <LinkText
              className="ml-10px"
              onClick={didNotReceiveOTP}
              ariaLabel={polyglot.t("Didn’t get a verification code?")}
            >
              {polyglot.t("Didn’t get a verification code?")}
            </LinkText>
          </div>
        </div>
        {isVerificationStarted && (
          <p
            style={{
              fontSize: "10px",
              textAlign: "center",
              marginTop: "10px",
            }}
          >
            {polyglot.t("Checking your code")} <img width="40px" src={Loader} alt="loading..." />
          </p>
        )}
      </div>
    );
  };

  const displayChooseClientForm = () => {
    const initialClientFormValues = {
      clientid: "",
    };
    const clintFormValidationSchema = Yup.object().shape({
      clientid: Yup.number().required(polyglot.t("Client is required")),
    });
    const submitForm = (values) => {
      const clientuserid = associatedClients.find((obj) => obj.clientid === values.clientid).clientuserid;
      const loginDetails = {
        clientUserId: OTPContent.clientUserId,
        password: OTPContent.password,
        clientDBName: clientuserid,
      };
      login(loginDetails);
    };
    const backToLogin = () => {
      window.location.reload();
    };
    return (
      <Formik
        onSubmit={submitForm}
        initialValues={initialClientFormValues}
        validationSchema={clintFormValidationSchema}
      >
        {(prop) => {
          const { values, handleChange, handleSubmit, errors, touched } = prop;
          return (
            <form onSubmit={handleSubmit}>
              <div>
                {userLanguage() === "en" && (
                  <>
                    {associatedClients.length > 0 && (
                      <Select
                        placeholder={polyglot.t("Choose a client *")}
                        multiple={false}
                        value={values.clientid}
                        showSearchOnToggle={true}
                        onSelect={(value) => handleChange("clientid")({ target: { value } })}
                        className="Box"
                        aria-label={polyglot.t("Choose a client *")}
                      >
                        <SelectGroupTitle>{polyglot.t("Client")}</SelectGroupTitle>
                        {associatedClients.map((clientObj) => {
                          return (
                            <SelectOption key={clientObj.clientid} value={clientObj.clientid}>
                              {!isNumeric(clientObj.clientname) ? clientObj.clientname : clientObj.clientid}
                            </SelectOption>
                          );
                        })}
                      </Select>
                    )}
                  </>
                )}
                {userLanguage() === "ger" && (
                  <>
                    {associatedClients.length > 0 && (
                      <Select
                        placeholder={polyglot.t("Choose a client *")}
                        multiple={false}
                        value={values.clientid}
                        showSearchOnToggle={true}
                        onSelect={(value) => handleChange("clientid")({ target: { value } })}
                        className="Box"
                      >
                        <SelectGroupTitle>{polyglot.t("Client")}</SelectGroupTitle>
                        {associatedClients.map((clientObj) => {
                          return (
                            <SelectOption key={clientObj.clientid} value={clientObj.clientid}>
                              {!isNumeric(clientObj.clientname) ? clientObj.clientname : clientObj.clientid}
                            </SelectOption>
                          );
                        })}
                      </Select>
                    )}
                  </>
                )}
                {errors.clientid && touched.clientid ? (
                  <span className="error">{errors.clientid}</span>
                ) : null}
                <br />
                <div className="row">
                  <div className="col-md-5">
                    <ButtonPrimary type="submit" ariaLabel={polyglot.t("Submit")}>
                      {polyglot.t("Submit")}
                    </ButtonPrimary>
                  </div>
                  <div className="col-md-7">
                    <div className="ForgotPassword marginpointsix">
                      <LinkText onClick={backToLogin} ariaLabel={polyglot.t("Back to login")}>
                        {polyglot.t("Back to login")}
                      </LinkText>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          );
        }}
      </Formik>
    );
  };

  return (
    <>
      <div className="login-bg-image">
        <div className="container">
          <div className="login-form">
            <div className="d-flex">
              <div className="card bg-light margin-0-auto min-width-25">
                <div className="card-header header_login Header_resposnive_login">
                  <h1 className="headerText_Login">
                    {!OTPContent.CODE && `${polyglot.t("Welcome to Queen")}`}
                    {OTPContent.CODE === 1 &&
                      `${polyglot.t("Welcome to Queen")} ${startCase(OTPContent.userData.firstname)}`}
                  </h1>
                </div>
                <div className="card-body user-login">
                  {!OTPContent.CODE && displayLoginForm()}
                  {OTPContent.CODE === 1 && !showChooseClient && displayOTPForm()}
                  {showChooseClient && displayChooseClientForm()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

Login.propTypes = {
  history: PropTypes.object,
};

export default Login;
