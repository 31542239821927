import React, { Component } from "react";
import "react-table/react-table.css";
import "../../../Components/DFED/Review/Review.css";
import "./ReviewTabularData.css";
import "./productionDataTable.css";
import "./ProductionTypes.css";
import ProductionTypes from "../Review/ProductionTypes";
import ReviewTabularData from "../Review/ReviewTabularData";
import Review from "../Review/Review";
import BackButton from "../Shared/button/BackButton";
import ProductionBarChart from "../Review/ProductionBarChart";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import { ReviewProductionFinalResultById } from "../../../Services/DFED/ReviewService";
import OutlineButton from "../Shared/button/OutlineButton";
import $ from "jquery";
import "../../../Assets/Css/DFED/Admin.css";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "antd/dist/antd.css";
import { checkPermission } from "../../../utility/checkPermission";
import commonFunctionForCallService from "../../../utility/commonFunctionForCallService";
import * as module_Solution_Name from "../../../Constants/moduleName";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import { notification, Pagination } from "antd";
import { Input } from "reactstrap";
import { getDisplayDate } from "../../../Services/Shared/Handlers";
import { Tooltip, Tag } from "appkit-react";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonPrimaryExpandable from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryExpandable";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import { rightChevronImg } from "../../../Assets/Icons";
$.DataTable = require("datatables.net-dt");
$.fn.DataTable.ext.errMode = "none";
require("datatables.net-buttons-dt");
const jzip = require("jszip");
require("datatables.net-buttons/js/buttons.html5.min");
window.JSZip = jzip;

export class ProductionDataTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showPagination: true,
      pagination: true,
      redirectToFinalResultPage: false,
      req_id: "",
      redirectToNextPage: false,
      redirectToPreviousPage: false,
      selectedProjectId: props.location ? props.location.state.selectedProjectId : props.selectedProjectId,
      isFromReview: props.newProps.location ? props.newProps.location.state.isFromReview : false,
      selectedProjectName: sessionStorage.getItem("selectedProjectName"),
      productionResult: props.productionResult,
      showBackButton: props.showBackButton ? props.showBackButton : null,
      showBarChart: false,
      rolePermission: {},
      currentPage: 1,
      tempProductionData: props.productionResult,
      backUpProductionData: props.productionResult,
      lengthOfVisData: props.productionResult ? props.productionResult.length : 0,
    };
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
    this.onStatisticsClick = this.onStatisticsClick.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.showProductionPage = this.showProductionPage.bind(this);
  }
  static contextType = DataContext;
  polyglot = polyglotLoader();

  newProduceFiles = () => {
    if (checkPermission(this.state.rolePermission, module_Solution_Name.PRODUCTION_MODULE, "Create")) {
      this.setState({
        redirectToNextPage: true,
      });
    } else {
      displayToastMessage(
        this.polyglot.t("Unauthorize access ! you are not allowed to perform this action"),
        "error",
      );
    }
  };
  onBackButtonClick() {
    if (this.props.newProps) {
      if (this.props.newProps.newProps) {
        if (this.props.newProps.newProps.newProps) {
          this.props.newProps.newProps.newProps.history.push({
            pathname: "/dfed/reviewdetails",
            state: {
              selectedProjectId: this.state.selectedProjectId,
              selectedProjectName: this.state.selectedProjectName,
            },
          });
        } else {
          this.props.newProps.newProps.history.push({
            pathname: "/dfed/reviewdetails",
            state: {
              selectedProjectId: this.state.selectedProjectId,
              selectedProjectName: this.state.selectedProjectName,
            },
          });
        }
      } else {
        this.props.newProps.history.push({
          pathname: "/dfed/reviewdetails",
          state: {
            selectedProjectId: this.state.selectedProjectId,
            selectedProjectName: this.state.selectedProjectName,
          },
        });
      }
    } else {
      this.props.history.push({
        pathname: "/dfed/reviewdetails",
        state: {
          selectedProjectId: this.props.location,
          selectedProjectName: this.state.selectedProjectName,
        },
      });
    }
  }
  onStatisticsClick() {
    this.setState({
      showBarChart: true,
    });
  }
  //get production results
  async ReviewProductionResult() {
    let productionResult = await ReviewProductionFinalResultById(this.state.selectedProjectId);
    if (productionResult && productionResult.data) {
      productionResult.data.forEach(function (a) {
        a.REVIEW_UPLOADED_ON = a.REVIEW_UPLOADED_ON.substring(0, 10);
      });
      this.setState(
        {
          productionResult: productionResult.data,
          backUpProductionData: productionResult.data,
          tempProductionData: productionResult.data,
          lengthOfVisData: productionResult.data.length,
        },
        () => {
          this.visiableDataHandler(this.state.tempProductionData, 1);
          //this.dataStructureForProductionGrid(this);
        },
      );
    }
  }
  dataStructureForProductionGrid = () => {
    var tempData = this.state.productionResult;
    var dataForTbl = [];
    if (tempData) {
      tempData.forEach((ele1) => {
        let editedPrepareReviewStatus;
        if (
          ele1.REVIEW_STATUS === "0" ||
          ele1.REVIEW_STATUS === "1" ||
          ele1.REVIEW_STATUS === null ||
          ele1.REVIEW_STATUS === ""
        ) {
          editedPrepareReviewStatus = "In Progress";
        } else if (ele1.REVIEW_STATUS === "2") {
          editedPrepareReviewStatus = "In Progress";
        } else {
          editedPrepareReviewStatus = "Document Available";
        }
        dataForTbl.push([
          ele1.Req_ID,
          ele1.REVIEW_SEARCH_TYPE,
          ele1.REVIEW_UPLOADED_ON,
          ele1.name,
          editedPrepareReviewStatus,
        ]);
      }); //OVER : MAIN FOREACH
    }
    this.drawProductionGrid(this, dataForTbl);
  };
  drawProductionGrid = (temp1, dataForTbl) => {
    temp1.$el = $(temp1.el);
    // let pageLength = 7;
    var disText = "";
    temp1.$el.DataTable({
      lengthMenu: [5, 10, 15, 20],
      sDom: "lfrtp",
      oLanguage: {
        emptyTable: `${this.polyglot.t("No data available for this project")}`,
        sLengthMenu: `${this.polyglot.t("Show")} <select class="tabFocus">
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="-1">${this.polyglot.t("All")}</option>
        </select> ${this.polyglot.t("entries")}
        `,
        sSearch: `${this.polyglot.t("Search")}`,
      },
      columns: [
        { title: this.polyglot.t("Requested Id") },
        { title: this.polyglot.t("Requested Type") },
        { title: this.polyglot.t("Requested On") },
        { title: this.polyglot.t("Requested By") },
        { bVisible: false, searchable: false },
        {
          title: this.polyglot.t("Status"),
          data: null,
          mRender: function (data, type, row) {
            if (row[4] === "In Progress") {
              return '<button class=" Save_button no-pointer" disabled>In Progress</button>';
            } else {
              return (
                '<button class="Save_button cursor-pointer" id="reviewstatus_' +
                row[0] +
                '">Document Available</button>'
              );
            }
          },
        },
      ],
      columnDefs: [
        { type: "date", targets: 2 },
        { className: "text-center", targets: 0 },
      ],
      order: [[2, "desc"]],
      data: dataForTbl,
      lenghtType: "simple",
      searching: true,
      destroy: true,
      language: {
        info: "_PAGE_ of _PAGES_",
        paginate: {
          firt: "<<",
          previous: '<i_ngcontent-c10="" class="appkiticon icon-left-chevron-fill"> </i>',
          next: '<i_ngcontent-c10="" class="appkiticon icon-right-chevron-fill"> </i>',
          last: ">>",
        },
      },
      drawCallback: function () {
        if (dataForTbl.length <= 0) {
          $(".dataTables_paginate").hide();
        } else {
          $(".dataTables_paginate").show();
        }
      }, // OVER : drawback function
    }); // OVER : Data Table

    var tempReactThis = this;
    $(document).on("click", "[id*='reviewstatus_']", function () {
      let idData = this.id.split("_");
      let status_id = idData[1];
      tempReactThis.showPage(status_id);
    });
    disText = $("#productionData").text();
    var pagNum = "";
    pagNum = disText.replace(pagNum, "<span>" + pagNum + "</span>");
    disText = disText.replace(pagNum, "<span>" + pagNum + "</span>");
  }; // OVER : Draw Grid
  showPage = (status_id) => {
    this.setState({
      req_id: status_id,
      redirectToFinalResultPage: true,
    });
  };
  showProductionPage = (id) => {
    this.setState({
      req_id: id,
      redirectToFinalResultPage: true,
    });
  };
  componentDidMount() {
    const { formGotClosed } = this.context;
    //this.dataStructureForProductionGrid(this);
    if (this.props.docNav) {
      if (this.props.uploadedFlag) {
        let data = {
          message: this.polyglot.t(
            "Request was sent out. We will get back to you as soon as your documents are ready.",
          ),
          duration: 5,
        };
        displayToastMessage(data.message, "success");
        formGotClosed();
      }
      this.ReviewProductionResult();
    }
    this.getApplicationRolesWithPermission();
    this.visiableDataHandler(this.state.productionResult, 1);
  }

  getApplicationRolesWithPermission = async () => {
    let selectedProjectId = this.state.selectedProjectId;
    let rolePermission = await commonFunctionForCallService.commonFunctionForCallService(
      module_Solution_Name.DFED_SOLUTION,
      selectedProjectId,
      module_Solution_Name.PRODUCTION_MODULE,
    );
    if (rolePermission) {
      this.setState({
        rolePermission: rolePermission,
      });
      if (this.state.isFromReview) {
        this.newProduceFiles();
      }
    }
  };

  visiableDataHandler(data, page) {
    let lastIndex = 4 * page;
    let firstIndex = lastIndex - 4;
    let visData = data ? data.slice(firstIndex, lastIndex) : [];
    this.setState({ productionResult: visData });
  }

  onSearch = (e) => {
    let tempCustodianProcessData = JSON.parse(JSON.stringify(this.state.tempProductionData));
    var list = this.state.backUpProductionData.filter(
      (item) => item.name.toLowerCase().search(e.target.value.toLowerCase()) !== -1,
    );
    this.setState(
      {
        productionResult: list,
        tempProductionData: list,
        lengthOfVisData: list.length,
        currentPage: 1,
      },
      () => {
        this.visiableDataHandler(this.state.tempProductionData, 1);
      },
    );
  };
  onChangeTablePageHandler = (page) => {
    this.setState({ currentPage: page });
    this.visiableDataHandler(this.state.tempProductionData, page);
  };
  isInProgress = (childProduction) => {
    let isInProgress = false;
    if (
      childProduction.REVIEW_STATUS === "1" ||
      childProduction.REVIEW_STATUS === "0" ||
      childProduction.REVIEW_STATUS === "2"
    ) {
      isInProgress = true;
    }
    return isInProgress;
  };

  render() {
    var polyglot = polyglotLoader();
    const set1 = (
      <div>
        <div className="">
          <div className="row m-l-0 m-r-0">
            {checkPermission(this.state.rolePermission, module_Solution_Name.PRODUCTION_MODULE, "Create") && (
              <div className="col-md-4 col-lg-3 p-l-0 p-r-20">
                <ButtonPrimaryExpandable
                  onClick={this.newProduceFiles}
                  ariaLabel={polyglot.t("Request document production")}
                >
                  {polyglot.t("Request document production")}
                </ButtonPrimaryExpandable>
              </div>
            )}
            {/* {checkPermission(this.state.rolePermission, module_Solution_Name.PRODUCTION_MODULE, 'Create') &&
              <div className="col-md-4 col-lg-3 p-l-0 p-r-20" onClick={() => this.props.history.push({ pathname: "/dfed/admin", state: { selectedProjectId: this.props.location.state.selectedProjectId } })} aria-label={polyglot.t("View to Admin")}>
                <OutlineButton>{polyglot.t("View to Admin")}</OutlineButton>
              </div>
            } */}
            <div className="col-md-4 col-lg-3 p-l-0 p-r-20">
              {this.state.showBackButton && (
                <BackButton
                  onBackButtonClick={this.onBackButtonClick}
                  buttonText={polyglot.t("Back")}
                ></BackButton>
              )}
            </div>
          </div>
          <div className="row m-t-21 m-l-0 m-r-0">
            <div className="col-md-6 tex-ali-processing p-l-0 p-r-0">
              <h1 className="main_title_qb m-b-21" aria-label={polyglot.t("Document request")}>
                {this.state.lengthOfVisData}{" "}
                {this.state.lengthOfVisData > 1
                  ? polyglot.t("Document requests")
                  : polyglot.t("Document request")}
              </h1>
            </div>
          </div>
          <div className="row m-l-0 m-r-0">
            <div className="col-md-3 p-0 search-analysis m-b-15 margin-top-plus-0-3 ">
              <Input
                className="analysis-search"
                placeholder={polyglot.t("Search for production")}
                aria-label={polyglot.t("Search for production")}
                value={this.state.query}
                onChange={this.onSearch.bind(this)}
              />
            </div>
            <div className="col-md-3"></div>
          </div>
        </div>
        <div className="row ml-0 margin-top-plus-1-4">
          <div className="col-md-12 col-lg-8 table p-l-0 ">
            <div className="row m-l-0 m-r-0">
              <div class="a-panel a-panel-project mb-2 p-l-0 p-r-0" style={{ width: "100%" }}>
                <div class="a-panel-content">
                  <div class="a-text-heading">
                    <div class="row m-l-0 m-r-0">
                      <div class="col-md-1 p-l-0 p-r-0">
                        <Tooltip
                          content={polyglot.t("Requested Id")}
                          placement="top"
                          className="a-md-pop"
                          tooltipTheme="light"
                        >
                          <div class="project-title-overflow f-acc view-center-name f-w-b">
                            {polyglot.t("ID")}
                          </div>
                        </Tooltip>
                      </div>
                      <div class="col-md-2 p-l-0 p-r-0">
                        <Tooltip
                          content={polyglot.t("Requested Type")}
                          placement="top"
                          className="a-md-pop"
                          tooltipTheme="light"
                        >
                          <div class="project-title-overflow f-acc view-center-name f-w-b">
                            {polyglot.t("Type")}
                          </div>
                        </Tooltip>
                      </div>
                      <div class="col-md-2 p-l-0 p-r-0">
                        <Tooltip
                          content={polyglot.t("Requested Date")}
                          placement="top"
                          className="a-md-pop"
                          tooltipTheme="light"
                        >
                          <div class="project-title-overflow f-acc view-center-name f-w-b">
                            {polyglot.t("Date")}
                          </div>
                        </Tooltip>
                      </div>
                      <div class="col-md-2 p-l-0 p-r-0 ">
                        <Tooltip
                          content={polyglot.t("Requested By")}
                          placement="top"
                          className="a-md-pop"
                          tooltipTheme="light"
                        >
                          <div class="project-title-overflow f-acc view-center-name f-w-b">
                            {polyglot.t("Requested By")}
                          </div>
                        </Tooltip>
                      </div>
                      <div class="col-md-3 p-l-0 p-r-0">
                        <Tooltip
                          content={polyglot.t("Status")}
                          placement="top"
                          className="a-md-pop"
                          tooltipTheme="light"
                        >
                          <div class="project-title-overflow f-acc view-center-name f-w-b">
                            {polyglot.t("Status")}
                          </div>
                        </Tooltip>
                      </div>
                      <div class="col-md-2 p-l-0 p-r-0">
                        <Tooltip
                          content={polyglot.t("Action")}
                          placement="top"
                          className="a-md-pop"
                          tooltipTheme="light"
                        >
                          <div class="project-title-overflow f-acc view-center-name f-w-b">
                            {polyglot.t("Action")}
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-l-0 m-r-0">
              {this.state.productionResult &&
                this.state.productionResult.length > 0 &&
                this.state.productionResult.map((childProduction, index) => {
                  return (
                    <div class="a-panel a-panel-project mb-2 p-l-0 p-r-0 " style={{ width: "100%" }}>
                      <div class="a-panel-content">
                        <div class="a-text-heading">
                          <div class="row m-l-0 m-r-0">
                            <div class="col-md-1 productionDatatableColumnData p-l-0 p-r-0">
                              <div class="project-title-overflow f-acc view-center-name">
                                {polyglot.t(childProduction.Req_ID)}
                              </div>
                            </div>
                            <div class="col-md-2 p-l-0 p-r-0 productionDatatableColumnData">
                              <Tooltip
                                content={polyglot.t(childProduction.REVIEW_SEARCH_TYPE)}
                                placement="top"
                                className="a-md-pop"
                                tooltipTheme="light"
                              >
                                <div class="project-title-overflow f-acc view-center-name">
                                  {polyglot.t(childProduction.REVIEW_SEARCH_TYPE)}
                                </div>
                              </Tooltip>
                            </div>
                            <div class="col-md-2 p-l-0 p-r-0 productionDatatableColumnData">
                              <div class="project-title-overflow  view-center-name">
                                {getDisplayDate(childProduction.REVIEW_UPLOADED_ON)}
                              </div>
                            </div>
                            <div class="col-md-2 p-l-0 p-r-0 productionDatatableColumnData ">
                              <div class="project-title-overflow view-center-name">
                                {polyglot.t(childProduction.name)}
                              </div>
                            </div>
                            <div class="col-md-3 p-l-0 p-r-0 productionDatatableColumnData text-overflow-none">
                              <div class="project-title-overflow  view-center-name text-overflow-none">
                                {childProduction.REVIEW_STATUS === "1" && (
                                  <Tag className="df-ns">{polyglot.t("Not started")}</Tag>
                                )}
                                {childProduction.REVIEW_STATUS === "2" && (
                                  <Tag className="df-ip">{polyglot.t("In progress")}</Tag>
                                )}
                                {childProduction.REVIEW_STATUS === "3" && (
                                  <Tag className="df-pfa">{polyglot.t("Document Available")}</Tag>
                                )}
                              </div>
                            </div>
                            <div class="col-md-2 p-l-0 p-r-0 productionDatatableColumnData ">
                              <div class="project-title-overflow view-center-name">
                                {!this.isInProgress(childProduction) && (
                                  <ButtonIcon
                                    iconImg={rightChevronImg}
                                    alt="Proceed"
                                    onClick={this.showProductionPage.bind(this, childProduction.Req_ID)}
                                    ariaLabel={polyglot.t("Proceed")}
                                    title={polyglot.t("Proceed")}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="col-md-12 col-lg-4 margin-top-minus-0-5">
            <div className="margin-left-minus-0-9">
              <p className="para-txt">
                {polyglot.t(
                  "All the created Productions are listed in this section. For the current state of a specific production, please consult the ",
                )}
                <span className="para-head">{" " + polyglot.t("Status")}</span>
                {" " + polyglot.t("column. If documents are available for download, please click on ")}
                <span className="para-head">{polyglot.t("Document Available")}.</span>
              </p>
              <div>
                <p className="para-txt">
                  {polyglot.t("If you want to request a new Production, please click on ")}
                  <span className="para-head" aria-label={polyglot.t("Request document production")}>
                    {polyglot.t("Request document production")}.
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
        {this.state.lengthOfVisData && this.state.lengthOfVisData > 4 ? (
          <Pagination
            current={this.state.currentPage}
            size="small"
            pageSize={4}
            total={this.state.lengthOfVisData}
            onChange={this.onChangeTablePageHandler}
          />
        ) : (
          ""
        )}

        <br></br>
        {this.state.showBarChart ? (
          <ProductionBarChart
            selectedProjectId={this.state.selectedProjectId}
            selectedProjectName={this.state.selectedProjectName}
          />
        ) : null}
      </div>
    );
    const set2 = (
      <ProductionTypes
        selectedProjectId={this.state.selectedProjectId}
        selectedProjectName={this.state.selectedProjectName}
        productionResult={this.state.productionResult}
        showBackButton={this.state.showBackButton}
        newProps={this.props}
      />
    );
    const set3 = (
      <Review
        selectedProjectId={this.state.selectedProjectId}
        selectedProjectName={this.state.selectedProjectName}
        newProps={this.props.newProps}
      />
    );
    const set4 = (
      <ReviewTabularData
        selectedProjectId={this.state.selectedProjectId}
        req_id={this.state.req_id}
        showBackButton={this.state.showBackButton}
        newProps={this.props}
      />
    );
    var newset;
    if (this.state.redirectToNextPage) {
      newset = set2;
    } else if (this.state.redirectToPreviousPage) {
      newset = set3;
    } else if (this.state.redirectToFinalResultPage) {
      newset = set4;
    } else {
      newset = set1;
    }
    return newset;
  }
}
export default ProductionDataTable;
