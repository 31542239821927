import "../../../Assets/Css/QueenBase/CompanyCenter/CompanyCenter.css";
import { Checkbox } from "appkit-react";
import React, { useContext, useEffect, useState } from "react";

import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import NoAccess from "../../../Assets/Components/NoAccess";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/QueenBase/Root/loading-spin.svg";
import * as path from "../../../Constants/Path";
import AdminService from "../../../Services/QueenBase/Admin/AdminServices";
import CompanyCenterService from "../../../Services/QueenBase/CompanyCenter.js/CompanyCenterService";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import GetPriceInGermanFormat from "../Root/GetPriceInGermanFormat";
import displayToastMessage from "../Root/displayToastMessage";
// BASE PACKAGE UPGRADATION COMPONENT
function BasePackageUpgradation(props) {
  const { getCurrentUserPermissions } = useContext(DataContext);
  const currentUserPermissions = getCurrentUserPermissions();
  const [basicPackage, setBasicPackage] = useState();
  const [OptedPackages, setOptedPackages] = useState([]);
  const [loading, Setloading] = useState(true);
  const colors = ["#ffb600", "#eb8c00", "#d93954", "#a43e50"];
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const language = getUserLanguage();
  // GET BASE PACKAGE INFO FROM API
  async function getAdmin(language) {
    Setloading(true);
    var res = await AdminService.get().getBasePackageInfo(language);
    if (res.CODE) {
      setBasicPackage(res.basicPackage);
      await GetSelectedPackage(res.basicPackage);
    } else {
      displayToastMessage(res.MSG, "error");
    }
    Setloading(false);
  }
  // CHECK IF UPGRADE PACKAGE IS DISABLED
  const isUpgradePackageDisabled = () => {
    let isDisabled = false;
    const selectedpackage = basicPackage.filter((r) => r.isChecked === 1);
    if (selectedpackage.length <= 0) {
      isDisabled = true;
    }
    return isDisabled;
  };
  // UPGRADE PACKAGE API
  const UpgradePackage = async () => {
    let selectedpackage = basicPackage.filter((r) => r.isChecked === 1);
    if (selectedpackage.length > 0) {
      var res = await CompanyCenterService.get().upgradeBasePackage(selectedpackage[0]);
      if (res.CODE) {
        displayToastMessage(polyglot.t("Base package upgraded"), "success");
        props.history.push(path.COMPANY_CENTER_VIEW_PATH);
      } else {
        displayToastMessage(res.MSG, "error");
      }
    } else {
      displayToastMessage(polyglot.t("Please select a package"), "error");
    }
  };
  // PREPARES LIST OF PACKAGES WHICH ARE LOWER THAN THE SELECTED PACKAGE
  const GetSelectedPackage = (packagesList) => {
    let SelectedPackage = props.location.state[0];
    let selectedLowerPackages = [];
    for (let each of packagesList) {
      if (each.id <= SelectedPackage.id) {
        selectedLowerPackages.push(each);
      }
    }
    setOptedPackages(selectedLowerPackages);
  };
  // DISABLE ALREADY SELECTED / LOWER PACKAGES
  const disableSelectedPackages = (EachPackage) => {
    if (OptedPackages.length > 0) {
      let response;
      for (let obj of OptedPackages) {
        if (obj.id === EachPackage.id) {
          response = true;
          break;
        } else {
          response = false;
        }
      }
      return response;
    }
  };
  // HANDLE CHOOSING A PACKAGE
  const handlePackage = (item) => {
    var list = [...basicPackage];
    list.map((res) => {
      if (res.id === item.id) {
        res.isChecked = res.isChecked === 1 ? 0 : 1;
      } else {
        res.isChecked = 0;
      }
    });
    setBasicPackage(list);
  };

  // RUNS ON LOAD
  useEffect(() => {
    getAdmin();
  }, []);

  // RETURN TRUE IF PACKAGE IS SELECTED BY THE USER
  const CheckBasePackage = (item) => {
    let response = false;
    if (item.isChecked === 1 || props.location.state[0].id === item.id) {
      response = true;
    } else {
      response = false;
    }
    return response;
  };

  // DISPLAY LOADING SPINNER
  const displayLoadingSpinner = () => {
    return (
      <div className="row">
        <div className="col-md-12" style={{ textAlign: "center" }}>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      </div>
    );
  };

  // DISPLAY LOADING SPINNER IF LOADING IS TRUE
  if (loading) {
    return displayLoadingSpinner();
  }

  return (
    <>
      {basicPackage && basicPackage.length > 0 ? (
        <div className="mt-3">
          {props.location.state[0].packageUpgradeStatus &&
          props.location.state[0].packageUpgradeStatus === 1 ? (
            <h1 className="Admin-title-header m-l-0-9">{polyglot.t("View package")}</h1>
          ) : (
            <h1 className="Admin-title-header m-l-0-9">{polyglot.t("Upgrade Package")}</h1>
          )}

          <div className="row Admin-Package-grid">
            {basicPackage && basicPackage.length > 0 ? (
              basicPackage.map((item, index) => {
                return (
                  <div className="col-md-3 pl-0" key={index}>
                    <div className="cardHeight-cc-Basepackage pl-0 ">
                      <div
                        className={"card-body-package-cc-Basepackage ml-0"}
                        style={{ borderTop: `5px solid ${colors[index]}` }}
                      >
                        <div className="col-md-12 Admin-project-data-display pr-0">
                          <div className="row">
                            <div className=" col-md-9  Admin-project-data-plan">{item.name}</div>
                            <div className="col-md-3 text-align-right pr-0">
                              <Checkbox
                                className=""
                                checked={CheckBasePackage(item)}
                                onChange={() => handlePackage(item)}
                                disabled={disableSelectedPackages(item)}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="a-py-0 border-top a-border-dark a-mt-2">
                          <div className="Admin-users-list">
                            <div className="row marg_t_b ml-0">
                              <strong className="a-h4">{GetPriceInGermanFormat(item.price)}</strong>
                              &nbsp;
                              <span className="marginpoint35 ml-0 mb-0">{polyglot.t("Per_year")}</span>
                            </div>

                            <div className="row marg_t_b ml-0 mr-0">
                              <span className="appkiticon icon-check-mark-fill pointer ul-icon cursor-default"></span>
                              <div className="col-md-10 p-0 f-acc-B">
                                <div className="row">
                                  <div className="col-md-12 pr-0">
                                    {item.noofusers}&nbsp;
                                    {polyglot.t("users")}
                                    {/* Note: temporary removal
																{` (${polyglot.t('@')} ${GetPriceInGermanFormat((item.priceperuser).toString())} ${polyglot.t('per user per month')})`}
																	*/}
                                  </div>
                                </div>
                              </div>
                            </div>
                            <b>{polyglot.t("Additional users")}</b>
                            <div className="row marg_t_b ml-0 mr-0">
                              <span className="appkiticon icon-circle-plus-outline pointer ul-icon cursor-default"></span>
                              <div className="col-md-10 p-0 f-acc-B">
                                <div className="row">
                                  <div className="col-md-12 pr-0">
                                    {GetPriceInGermanFormat(item.tempuserprice.toString())}{" "}
                                    {polyglot.t("per user for")} {item.noofmonths}{" "}
                                    {item.noofmonths === 1
                                      ? polyglot.t("month")
                                      : polyglot.t("basePackage_months")}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mb-2"></div>
                  </div>
                );
              })
            ) : (
              <h6 className="a-h3  ml-2">
                <strong>{polyglot.t("No packages are available")}</strong>
              </h6>
            )}
          </div>
          <div className="row base-package-upgrade-buttons-row">
            {props.location.state[0].packageUpgradeStatus &&
            props.location.state[0].packageUpgradeStatus === 1 ? (
              // <Button size='sx' className="secondary_button_transparent m-align-2" onClick={() => props.history.push(path.COMPANY_CENTER_VIEW_PATH)}>{polyglot.t("Close")}</Button>
              <ButtonSecondary
                onClick={() => props.history.push(path.COMPANY_CENTER_VIEW_PATH)}
                ariaLabel={polyglot.t("Close")}
              >
                {polyglot.t("Close")}
              </ButtonSecondary>
            ) : (
              <ButtonSecondary
                onClick={() => props.history.push(path.COMPANY_CENTER_VIEW_PATH)}
                ariaLabel={polyglot.t("Cancel Upgrade")}
              >
                {polyglot.t("Cancel Upgrade")}
              </ButtonSecondary>
            )}
            {props.location.state[0].packageUpgradeStatus &&
            props.location.state[0].packageUpgradeStatus === 1 ? null : (
              <ButtonPrimary
                onClick={() => UpgradePackage()}
                disabled={isUpgradePackageDisabled()}
                ariaLabel={polyglot.t("Upgrade Package")}
              >
                {polyglot.t("Upgrade Package")}
              </ButtonPrimary>
            )}
          </div>
        </div>
      ) : (
        <NoAccess />
      )}
    </>
  );
}

export default BasePackageUpgradation;
