import { Badge } from "appkit-react";
import React, { useEffect, useState } from "react";

import { polyglotLoader } from "../../../Services/Shared/Translate";
import "./index.css";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import CustomModal from "../../../Assets/Components/Modal";

const polyglot = polyglotLoader();

const convertSingleDigitToTwo = (num) => {
  const length = num.toString().length;
  if (length === 1) {
    return `0${num}`;
  }
  return num;
};

const timer = (timerFromNow) => {
  const expiryTime = new Date(timerFromNow).getTime();
  const now = new Date().getTime();
  const distance = expiryTime - now;
  const mins = convertSingleDigitToTwo(Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60)));
  const secs = convertSingleDigitToTwo(Math.floor((distance % (1000 * 60)) / 1000));
  return { mins: Number(mins), secs: Number(secs) };
};
const checkIfTimeIsValid = (mins, secs) => {
  if (mins < 0 || secs < 0) {
    return false;
  }
  if (mins === 0 && secs === 0) {
    return false;
  }
  if (isNaN(mins) || isNaN(secs)) {
    return false;
  }
  return true;
};
const appendZero = (num) => {
  return ("0" + num).slice(-2);
};

const IdleTimeoutAlert = (props) => {
  const { continueSession, sessionExpires, openAlert, idleTimeoutTimerMaxTime } = props;

  const [expiryCounter, setExpiryCounter] = useState(null); // expiryCounter format = '00:00'
  const [showContinue, setShowContinue] = useState(true);

  let sessionInterval;

  const runTimer = () => {
    const { mins, secs } = timer(idleTimeoutTimerMaxTime);
    let isTimeValid = checkIfTimeIsValid(mins, secs);
    if (isTimeValid) {
      const formatedMin = appendZero(mins);
      const formatedSecs = appendZero(secs);
      setExpiryCounter(`${formatedMin}:${formatedSecs}`);
      clearInterval(sessionInterval);
    } else {
      setShowContinue(false);
      sessionExpires();
      clearInterval(sessionInterval);
    }
  };

  useEffect(() => {
    if (openAlert && idleTimeoutTimerMaxTime) {
      sessionInterval = setInterval(() => runTimer(), 1000);
    }
  });

  const logout = () => {
    sessionExpires();
  };
  const proceed = () => {
    continueSession();
  };
  const userCancelsPopup = () => {
    // If user cancels the popup, we will consider it as continue session
    proceed();
  };

  return (
    <CustomModal
      show={openAlert}
      onCancel={userCancelsPopup}
      runContinue={proceed}
      showContinueButton={showContinue}
      title={polyglot.t("Session Timeout")}
      Footer={[
        <ButtonPrimary key={1} onClick={logout}>
          {polyglot.t("Logout")}
        </ButtonPrimary>,
      ]}
    >
      <>
        {polyglot.t("Your session will expire in")} <Badge type={"warning"}>{expiryCounter}</Badge>
        <br />
        {polyglot.t("Would you like to continue or logout")} ?
      </>
    </CustomModal>
  );
};

export default IdleTimeoutAlert;
