import React from "react";
import "appkit-react/style/appkit-react.default.css";
import Download from "../../../../Assets/Images/DFED/icon-download.svg";
import DownloadButton from "../../../DFED/Shared/button/DownloadButton";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import ButtonSecondary from "../../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import { downloadIcon, downloadImg } from "../../../../Assets/Icons";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";

// imports services//
import RequestFormService from "../../../../Services/GI/RequestFormServices";
import ButtonCTA from "../../../../Assets/Components/ButtonCTA/ButtonCTA";
var polyglot = polyglotLoader();

class DownloadTemplate extends React.Component {
  constructor(props) {
    super(props);

    this.downloadTemplate = this.downloadTemplate.bind(this);
  }

  // DOWNLOAD PARAMETER FILE ON CLICK
  downloadTemplate = async () => {
    try {
      // API TO DOWNLOAD A PARAMETER FILE OF A WORKFLOW
      const downloadResponse = await RequestFormService.get().fetchTemplate();

      if (downloadResponse.CODE) {
        const buffer = Buffer.from(downloadResponse.file.buffer);
        const url = window.URL.createObjectURL(new Blob([buffer]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", downloadResponse.file.fileName);
        document.body.appendChild(link);
        link.click();
        displayToastMessage(downloadResponse.MSG, "success");
        document.body.removeChild(link);
      } else {
        if (downloadResponse.MSG.errno === -4058) {
          displayToastMessage(`${polyglot.t("File not found")}!!`, "error");
        } else {
          displayToastMessage(downloadResponse.MSG, "error");
        }
      }
    } catch (error) {
      if (
        (error.message = "First argument must be a string, Buffer, ArrayBuffer, Array, or array-like object.")
      ) {
      } else {
        displayToastMessage(error.message, "error");
      }
    }
  };

  render() {
    var polyglot = polyglotLoader();
    const maxSize = 1000000;
    return (
      <div className="">
        <div className="row mt-3 fade-in-top m-l-0 ">
          <div className="col-sm-12 col-md-12 p-l-0">
            <div className="col-sm-12 col-md-12 m-t-10 pl-0 pr-0">
              <ButtonCTA
                onClick={this.downloadTemplate.bind(this)}
                ariaLabel={polyglot.t("Download template")}
                className="full-width-btn border-1px"
              >
                <img src={downloadImg} alt="download" className="mr-2" />
                {polyglot.t("Download template")}
              </ButtonCTA>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default DownloadTemplate;
