import "../../../Assets/Css/QueenBase/CompanyCenter/CompanyCenter.css";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";

function NoDataMsg({ solutionName }) {
  const { getUserLanguage } = useContext(DataContext);
  const language = getUserLanguage();
  const polyglot = polyglotLoader(language);

  return (
    <div style={{ textAlign: "center" }}>
      <p className="headertest mt-4 mb-2">{solutionName}</p>
      <p className="user-flow-text">{polyglot.t("No data found")}.</p>
    </div>
  );
}
NoDataMsg.propTypes = {
  solutionName: PropTypes.string,
};
export default NoDataMsg;
