import { Input, TextArea, Tooltip } from "appkit-react";
import { Formik } from "formik";
import React, { useEffect, useState } from "react";

import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { infoIcon, downloadImg } from "../../../Assets/Icons";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/GRC/loading-spin.svg";
import RecipientResponseService from "../../../Services/GRC/RecipientResponseService";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import { useHttpPostClient } from "../../../Services/GRC/http-hook-post";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import { GetPerformAssessmentBreadcrum } from "../PerformAssessment/PerformHandlers";
import EmptyPanel from "../Shared/EmptyPanel";
import ErrorPanel from "../Shared/ErrorPanel";
import "./Pages/index.css";
import QuestionTags from "../Shared/QuestionTags";

const RecipientResponseQuesAnswers = ({ questionIndex, id, setsetid, setApiValue, uid }) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();

  const { sendRequestPost } = useHttpPostClient();

  const initialValuesOfForm = {
    comment: "",
  };

  const [loadAnswer, setloadAnswer] = useState([]);
  const [breadcrumHeading, setBreadcrumHeading] = useState({});
  const [loadQuestion, setloadQuestion] = useState("");
  const [dependencyQuestionId, setDependencyQuestionId] = useState(0);
  const [initialFormValues, setInitialFormValues] = useState(initialValuesOfForm);
  const [isCommentMandatory, setIsCommentMandatory] = useState(false);
  const [isButtonFieldValue, setIsButtonFieldValue] = useState("next");
  const [directionValue, setDirectionValue] = useState(0);
  const [submittedAnswers, setSubmittedAnswers] = useState(0);
  const [freeTextValue, setFreeTextValue] = useState(0);
  const [answerType, setAnswerType] = useState();
  const [loadingDone, setLoadingDone] = useState(false);
  const [questionId, setQuestionId] = useState();
  const [currntquestionIndex, setCurrentQuestionIndex] = useState(0);
  const [firstQuestBackBttnVisible, setFirstQuestBackBttnVisible] = useState(0);
  const [isLastQuestion, setIsLastQuestion] = useState(0);
  const [allAnswered, setAllAnswered] = useState(0);
  const [filesToDownload, setFilesToDownload] = useState([]);
  const polyglot = polyglotLoader();

  const getCategoriesAssessment = async (passId) => {
    try {
      setLoadingDone(false);

      if (isNaN(passId)) {
        passId = questionId;
      }
      setQuestionId(passId);

      const responseData = await sendRequest(
        RecipientResponseService.get().getRecipientQuestionAnswersURL(passId, uid, dependencyQuestionId),
      );

      const breadcrumData = await sendRequest(
        RecipientResponseService.get().getTextDataPerfAssessmentURL(responseData[0][0].parent_id),
      );
      setBreadcrumHeading(
        `${breadcrumData.breadcrum} > Question `, // use this after Questio --> ${questionIndex}
      );

      setAnswerType(responseData[0][0].answer_type_id);

      setFirstQuestBackBttnVisible(responseData[0][0].q_sequence);
      setIsCommentMandatory(responseData[0][0].is_comment_mandatory);
      let initialValuesOfEditForm = {};
      initialValuesOfEditForm.comment = responseData[1][0].comment_text;

      setInitialFormValues(initialValuesOfEditForm);

      const filesToDownload = responseData[3].length ? responseData[3] : [];
      setFilesToDownload(filesToDownload);

      if (responseData.length > 0) {
        setloadQuestion(responseData[0][0]);
        setloadAnswer(responseData[1]);
        setIsLastQuestion(responseData[0][0].is_last_index);
        setAllAnswered(responseData[0][0].all_answered);
      }
      let ids = responseData[1].map((t) => (parseInt(t.question_answer) === 1 ? t.id : 0));
      let idsAnswer = responseData[1].find((t) => parseInt(t.question_answer) === 1);

      if (typeof idsAnswer !== "undefined" && idsAnswer !== null) {
        setSubmittedAnswers(idsAnswer.id);
      } else {
        setSubmittedAnswers(0);
      }

      setLoadingDone(true);
    } catch (err) {
      console.log(err);
    }
  };

  /**
   * save/update data of the form
   */
  const handleAnswerSubmit = async (values, { setSubmitting, setErrors, setStatus, resetForm }) => {
    const putinput = {
      unique_id: uid,
      question_id: questionId,
      answer_id: submittedAnswers,
      comment_text: values.comment ? values.comment : "",
      recipient_file: "",
      is_skip: 0,
      dependencyQuestionId: dependencyQuestionId,
      direction: directionValue, //0, // 1 => back direction| 0 => next direction
    };

    //set value
    const response = await sendRequestPost(
      RecipientResponseService.get().putRecipientQuestionAnswersReadURL(),
      JSON.stringify(putinput),
      {
        "Content-Type": "application/json",
      },
    );

    setApiValue(false);

    if (parseInt(response[0][0].IS_DQ) === 1) {
      setDependencyQuestionId(response[0][0].question_id);
      setsetid(questionId);
      setQuestionId(questionId);
    } else {
      setDependencyQuestionId(0);
      setsetid(parseInt(response[0][0].question_id));
      setQuestionId(parseInt(response[0][0].question_id));
    }
  };
  useEffect(() => {
    getCategoriesAssessment(id);
  }, [sendRequest, id, dependencyQuestionId]);

  const downloadFile = async (fileUniqId) => {
    if (fileUniqId !== "") {
      const putFile = {
        file_id: fileUniqId,
        unique_id: uid,
        question_id: questionId,
      };

      const response = await sendRequestPost(
        RecipientResponseService.get().downloadAssessmentFileURL(),
        JSON.stringify(putFile),
        {
          "Content-Type": "application/json",
        },
      );

      if (response) {
        const buf = Buffer.from(response.buffer);
        const url = window.URL.createObjectURL(new Blob([buf]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", response.fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
      }
    }
  };

  const Tooltipdata = (item) => {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#000",
                  overflowWwrap: "break-word",
                }}
                className="tooptipdata"
              >
                {item}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  const DisplayUploadedFiles = () => (
    <div className="col-md-12">
      {filesToDownload.map((fileName) => (
        <div className="row file-upload-box p-0 mb-2">
          <span style={{ marginRight: "10px" }}>
            <b>{fileName}</b>
          </span>
          <ButtonIcon
            iconImg={downloadImg}
            alt="download"
            onClick={() => downloadFile(fileName)}
            title={polyglot.t("Download file")}
            ariaLabel={polyglot.t("Download file")}
          />
        </div>
      ))}
    </div>
  );

  return (
    <>
      {error && <ErrorPanel error={error} onClear={clearError} />}
      {isLoading && (
        <div>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      )}
      {loadAnswer.length === 0 && <EmptyPanel massage={"No data found."} />}
      {!isLoading && loadAnswer.length > 0 && initialFormValues && (
        <>
          {/* header section load breadcame */}
          <div className="row mt-3">
            <div className="col-md-12 p-0 border-bottom">
              <GetPerformAssessmentBreadcrum breadcrumString={breadcrumHeading} />
            </div>
          </div>

          {/* Question text area here */}
          <div className="row mt-3">
            {loadQuestion && (
              <div className="col-md-12 custome-border">
                <p className="p-0 m-0 font-weight-bold">{polyglot.t("question")}:</p>

                <label className="question-txt-area">{loadQuestion.question_text}</label>
              </div>
            )}
          </div>

          {/* form section here */}
          <div className="row mt-3 padding_1rem">
            <div className="col-md-12">
              {loadingDone && initialFormValues && (
                <Formik initialValues={initialFormValues} onSubmit={handleAnswerSubmit}>
                  {function Render(props) {
                    const { values, handleChange, handleSubmit, errors, touched } = props;
                    return (
                      <>
                        <div className="row ">
                          <div className="col-md-12 p-0 float-left">
                            <QuestionTags key={id} ids={id} />
                          </div>
                        </div>
                        <div className="row ">
                          <div className="col-md-12 p-0">
                            Please select select one out of following options:
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-9">
                            {loadAnswer.map((item, i) => (
                              <div key={i} className="row pt-2">
                                <div className="col-md-11 w-98 p-0">
                                  {/*  Answer type 5 for Free textbox */}
                                  {answerType === 5 ? (
                                    <Input
                                      className="btn btn-out-as text-left  w-100"
                                      readOnly={false}
                                      type={"number"}
                                      id={item.id}
                                      value={freeTextValue}
                                    />
                                  ) : (
                                    <>
                                      <label
                                        id={item.id}
                                        className={`${
                                          parseInt(item.question_answer) === 1
                                            ? "default-selected-ans"
                                            : "not-selected-ans"
                                        } btn btn-out-as text-left  w-100`}
                                        dangerouslySetInnerHTML={{ __html: item.answer_text }}
                                      ></label>
                                    </>
                                  )}
                                </div>
                                <div className="col-md-1 w-2 p-0 info-bttn">
                                  {item.additional_answer_text.length > 0 && (
                                    <Tooltip
                                      content={Tooltipdata(item.additional_answer_text)}
                                      placement="top"
                                      className="a-md-pop"
                                    >
                                      <ButtonIcon iconName={infoIcon} />
                                    </Tooltip>
                                  )}
                                </div>
                              </div>
                            ))}
                          </div>
                          <div className="col-md-3">
                            {loadQuestion.is_add_comment && (
                              <>
                                <TextArea
                                  placeholder={`${polyglot.t("comment")} ${isCommentMandatory ? "*" : ""}`}
                                  readOnly={true}
                                  value={values.comment}
                                  onChange={handleChange("comment")}
                                  className="custome-question-txt-area"
                                  aria-label={polyglot.t("comment")}
                                />
                                <div className="noofcharacters p-0">{values.comment.length}/2000</div>
                                {errors.comment && touched.comment ? (
                                  <div className="error">{errors.comment}</div>
                                ) : null}
                              </>
                            )}
                          </div>
                        </div>
                        {loadQuestion.is_add_file && filesToDownload.length > 0 && (
                          <div className="row mt-4">
                            <div className="col-md-12 p-0">
                              <div className="upload-profile-photo-wrapper dummy-drop">
                                <DisplayUploadedFiles />
                              </div>
                            </div>
                          </div>
                        )}
                        <div className="row mt-3 mb-3">
                          <div className="col-md-4 p-0">
                            {firstQuestBackBttnVisible > 0 && (
                              <ButtonPrimary
                                className="mr-1"
                                onClick={(e) => {
                                  setIsButtonFieldValue("back");
                                  handleSubmit(e);
                                  setDirectionValue(1);
                                }}
                                name="back"
                                ariaLabel={polyglot.t("Back")}
                              >
                                {polyglot.t("Back")}
                              </ButtonPrimary>
                            )}
                          </div>
                          <div className="col-md-4 p-0"></div>
                          <div className="col-md-4 float-right">
                            {isLastQuestion ? null : (
                              <ButtonPrimary
                                onClick={(e) => {
                                  setIsButtonFieldValue("next");
                                  handleSubmit(e);
                                  setDirectionValue(0);
                                  setCurrentQuestionIndex(parseInt(questionIndex) + 1);
                                }}
                                ariaLabel={polyglot.t("next")}
                                name="next"
                              >
                                {polyglot.t("next")}
                              </ButtonPrimary>
                            )}
                          </div>
                        </div>
                      </>
                    );
                  }}
                </Formik>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RecipientResponseQuesAnswers;
