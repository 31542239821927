import React, { useContext, useEffect } from "react";
import Loadable from "react-loadable";
import LoadingIndicator from "../../QueenBase/Root/LoadingIndicator";
import NoAccess from "../../../Assets/Components/NoAccess";
import { DataContext } from "../../../Services/Shared/Store";
import { hasViewPermission } from "../../../Services/Shared/RoleHandler";
import ProtectedRoute from "../../../Routes/ProtectedRoute";
import { getProjectID_Of_solution_module } from "../../../Services/Shared/Handlers";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/QueenBase/Root/loading-spin.svg";

// RENDERS ON PATH /df/dashboard
export default (props) => {
  const { checkAndFetchRole, getCurrentUserPermissions, loadingRoles } = useContext(DataContext);
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    const projectId = getProjectID_Of_solution_module();
    const screenName = "Home";
    checkAndFetchRole(screenName, "Connected Screening Service", projectId);
  }, []);
  const currentUserPermissions = getCurrentUserPermissions();
  return (
    <>
      {/* RENDER PATH IF USER HAS PERMISSION TO VIEW */}
      {hasViewPermission(currentUserPermissions) && (
        <ProtectedRoute
          path="/"
          component={Loadable({ loader: () => import("./Home"), loading: LoadingIndicator })}
        />
      )}
      {/* SHOW 'NO ACCESS' IF USER DOES NOT HAVE PERMISSION TO VIEW */}
      {!loadingRoles && !hasViewPermission(currentUserPermissions) && <NoAccess />}
      {/* IF Roles are loading then show loading symbol  */}
      {loadingRoles && <LoadingSpinner width={64} height={64} fill={"#ef3829"} />}
    </>
  );
};
