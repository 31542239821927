import React from "react";

import "./index.css";

const LeftQuestionnaireQuestionPreview = ({ parentId, handleChanged, setid, loadtype, questions = [] }) => {
  return (
    <>
      {questions &&
        questions.map((item, i) => {
          return (
            <div key={item} className="p-0">
              <div className="left-padding question-title">
                <span
                  className="appkiticon icon-circle-checkmark-outline custom-grc-icon"
                  style={{ marginRight: "4px" }}
                />
                {parseInt(item) === parseInt(setid) && loadtype === "ques" ? (
                  <span
                    className="test-text active-question"
                    onClick={(e) => {
                      console.log(questions, item, e);
                      handleChanged(e, item, "ques");
                    }}
                  >
                    Question {String(i + 1).padStart(2, "0")}
                  </span>
                ) : (
                  <span
                    className="test-text"
                    onClick={(e) => {
                      console.log(questions, item, e);
                      handleChanged(e, item, "ques");
                    }}
                  >
                    Question {String(i + 1).padStart(2, "0")}
                  </span>
                )}
              </div>
            </div>
          );
        })}
    </>
  );
};
export default LeftQuestionnaireQuestionPreview;
