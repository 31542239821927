import "../../../Components/DFED/upload/Upload.css";
import "../../../Components/DFED/Shared/components/FormField.css";
import "../../../Components/DFED/Review/DocumentList/DocumentList.css";
import PropTypes from "prop-types";
import React from "react";

import Dropzone from "../../../Components/DFED/dropzone/Dropzone";

// CUSTOM DROP ZONE USED TO UPLOAD FILES
const CustomDropZone = (props) => {
  const { onFilesAdded, customDropZoneBoxStyle, isSingleFileUpload, accept } = props;
  return (
    <Dropzone
      onFilesAdded={onFilesAdded}
      customDropZoneBoxStyle={customDropZoneBoxStyle}
      isSingleFileUpload={isSingleFileUpload}
      accept={accept}
    />
  );
};

CustomDropZone.propTypes = {
  onFilesAdded: PropTypes.any,
  customDropZoneBoxStyle: PropTypes.any,
  isSingleFileUpload: PropTypes.bool,
  accept: PropTypes.string,
};
CustomDropZone.defaultProps = {
  isSingleFileUpload: false,
  accept: "application/*, text/*",
};

export default CustomDropZone;
