import React from "react";
import "../../../Assets/Css/DFED/Custodians.css";
import image from "../../../Assets/Images/DFED/custodian_image.svg";
import { polyglotLoader } from "../../../Services/Shared/Translate";
// THIS CLASS RESPONSIBLE FOR SHOWING DEFAULT PAGE IF NO DATA FOUND IN CUSTODIAN =====================

const Custodians = () => {
  // CREATING POLYGLOT OBJECT FOR TRANSLATION=================
  var polyglot = polyglotLoader();
  return (
    <div>
      <br></br>
      <div className="row main-div">
        <div className="col-md-3 "></div>
        <div className="col-md-6">
          <label className="main_title_qb">{polyglot.t("Who do you want to look at?")}</label>
        </div>
        <div className="col-md-3"></div>
      </div>
      <div className="row main-div">
        <div className="col-md-3 "></div>
        <div className="col-md-6">
          <p className="sub-title m-b-0">
            {polyglot.t("Create single person or groups - called custodians.")}
          </p>
          <p className="sub-title m-b-0">
            {polyglot.t("Afterwards upload and process the related data files.")}
          </p>
        </div>
        <div className="col-md-3"></div>
      </div>
      <br></br>
    </div>
  );
};

export default Custodians;
