import "../../../../Assets/Css/QueenBase/CompanyCenter/CompanyCenter.css";
import { Select, SelectOption } from "appkit-react";
import PropTypes from "prop-types";
import React from "react";

function SolutionHeader({
  solutionName,
  selectOptions,
  onSelect,
  selectedPeriod,
  hasFilter = false,
  isBase = false,
  children,
}) {
  return (
    <div>
      <div style={{ display: "flex", alignItems: "center" }}>
        {isBase ? (
          <div className=" package-title main_title_qb mt-3">{solutionName}</div>
        ) : (
          <h5 className="subtitle_two_title_qb">{solutionName}</h5>
        )}
        {hasFilter && (
          <div style={{ marginLeft: "50px", width: "250px" }}>
            {selectOptions.length && (
              <Select id="keyword-filter" onSelect={onSelect} value={selectedPeriod}>
                {selectOptions.map((info) => (
                  <SelectOption key={info.period} value={info.period}>
                    {info.period}
                  </SelectOption>
                ))}
              </Select>
            )}
          </div>
        )}
      </div>
      {children}
    </div>
  );
}
SolutionHeader.propTypes = {
  solutionName: PropTypes.string,
  selectOptions: PropTypes.array,
  onSelect: PropTypes.func,
  selectedPeriod: PropTypes.string,
  hasFilter: PropTypes.bool,
  isBase: PropTypes.bool,
  children: PropTypes.element,
};
export default SolutionHeader;
