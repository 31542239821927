import React, { Component } from "react";
import { Select, SelectOption, Input } from "appkit-react";
import "../../../Assets/Css/DFED/Admin.css";
import displayToastMessage from "../../../Components/QueenBase/Root/displayToastMessage";
import AnalysisService from "../../../../src/Services/DFED/AnalysisService";
import "../../../Components/DFED/ReviewSelection/ReviewSelection.css";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import { getReviewSelectionDataInfo, postReviewSelectionData } from "../../../Services/DFED/ReviewService";
import {
  TextFieldLengthValidation,
  TextFieldLengthRegexValidation,
} from "../../../utility/textFieldValidation";
import CustomModal from "../../../Assets/Components/Modal";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import SelectCustom from "../../../Assets/Components/Select";

/**
 * @typedef Dataset
 * @type {object}
 * @property {string} DT_DATASET_NAME - dataset name.
 * @property {number} datasetID - dataset ID.
 */

/**
 * @typedef CustodianInfo
 * @type {object}
 * @property {number} analysisId - analysis ID.
 * @property {number} runThroughId - run through ID.
 * @property {number} custodianId - custodian ID.
 * @property {string} custodianName - custodian name.
 * @property {Dataset[]} datasets - array of custodian datasets.
 */

// REVIEW SELECTION CLASS RESPONSIBLE FOR REVIEW SELECTION DATA HANDLE AND EDIT====================
const analysisService = new AnalysisService();
class ReviewSelection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleTermsModel: false,
      selectedData: props.location.state.data ? props.location.state.data : "",
      selectedProjectId: props.location.state.selectedProjectId,
      ReviewSelectionResult: [],
      keywordsData: [],
      custodianData: [],
      datasetData: [],
      ReviewSelectionKeywordList: [],
      addedReviewName: "",
      textFieldLengthError: false,
      textFieldCharError: false,
      uniqueCustodianInDropDown: [],
      /**
       * @type {CustodianInfo[]} array with all custodians informations
       */
      custodianInfo: [],
      selectedRunThroughData: {}, // we receive data in it only after chosing an anlysis
    };
  }

  static contextType = DataContext;
  polyglot = polyglotLoader();

  async AnalysisLstData(tempSelectedProjectId) {
    await analysisService
      .AnalysisSummary(Number(tempSelectedProjectId))
      .then((analysInfo) => {
        function getCompletedAnalysisList(jsonList) {
          return jsonList.filter((each) => {
            if (
              each.PREPARE_KEYWORD_STATUS === "3" &&
              each.REPORT_GENERATED_STATUS === "3" &&
              each.RUNNING_KEYWORD_STATUS === "3"
            ) {
              return each;
            }
          });
        }
        const completedAnalysisList = getCompletedAnalysisList(analysInfo.data);
        this.setState({
          analysisLst: completedAnalysisList,
        });
      })
      .catch((err) => {
        if (err && err.status === 403) {
          this.setState({
            isViewPermission: false,
          });
        }
      });
  }
  //review selected data
  async ReviewSelectionDataInfo() {
    let tempCustodianData = 0;

    let ReviewSelectionResult = await getReviewSelectionDataInfo(
      this.state.selectedData.AnalysisID,
      this.state.selectedRunThroughData.runthroughID,
      this.state.selectedProjectId,
      tempCustodianData,
    );
    if (ReviewSelectionResult && ReviewSelectionResult.data && ReviewSelectionResult.data.length > 0) {
      let uniqueReviewResult = [];

      uniqueReviewResult.push(ReviewSelectionResult.data[0]);
      //   CODE FOR INSERTING UNIQUE CUSTODIAN IN DROPDOWN=======
      for (let i = 0; i < ReviewSelectionResult.data.length; i++) {
        let AvalableCustodianIndex = uniqueReviewResult.findIndex(function (data) {
          return data.CRT_ALIAS === ReviewSelectionResult.data[i].CRT_ALIAS;
        });
        if (AvalableCustodianIndex === -1) {
          uniqueReviewResult.push(ReviewSelectionResult.data[i]);
        }
      }
      this.setState({
        ReviewSelectionResult: ReviewSelectionResult.data,
        ReviewSelectionKeywordList:
          ReviewSelectionResult.data && ReviewSelectionResult.data[0] && ReviewSelectionResult.data[0].KEYWORD
            ? JSON.parse(ReviewSelectionResult.data[0].KEYWORD)
            : null,
        uniqueCustodianInDropDown: uniqueReviewResult,
      });
    }
  }
  // life cycle METHOD FOR HANDLING life cycle API DATA================
  componentDidMount() {
    window.scrollTo(0, 0);
    this.showModal();
    // this.ReviewSelectionDataInfo();
    this.AnalysisLstData(this.state.selectedProjectId);
  }
  showModal = () => {
    this.setState({ visibleTermsModel: true });
  };
  handleCancel = (e) => {
    this.setState({
      visibleTermsModel: false,
    });
    this.props.history.push({
      pathname: "/dfed/reviewdetails",
      state: { selectedProjectId: this.state.selectedProjectId },
    });
  };
  // SAVE FUNCTIONALITY FOR HANDLING REVIEW SELECTION==================s
  handleConfirm = async (e) => {
    let data = {
      PROJECT_ID: this.state.selectedProjectId,
      KEYWORD_LIST_ID: this.state.keywordsData,
      CUSTODIAN_ID: this.state.custodianData,
      DATASET_ID: this.state.datasetData,
      ANALYSIS_ID: this.state.selectedData.AnalysisID,
      RUNTHROUGH_COUNT: this.state.selectedRunThroughData.runthroughID,
      REVIEW_NAME: this.state.addedReviewName,
    };
    // REDIRECTING TO REVIEW LIST PAGE AFTER SUCCESSFULLY INSERTED THE DATA======================
    postReviewSelectionData(data).then((res) => {
      if (res.data === "Review Name Already Exist") {
        displayToastMessage(this.polyglot.t("Review Name Already Exist.Please Create New One"), "error");
      } else {
        this.props.history.push({
          pathname: "/dfed/reviewdetails",
          state: { selectedProjectId: this.state.selectedProjectId },
        });
        displayToastMessage(this.polyglot.t("Review request created successfully"), "success");
        this.setState({
          textFieldCharError: false,
        });
      }
    });
  };
  // REVIEW ONCHANGE EVENT HANDLER
  reviewnameOnChng = (e) => {
    const value = e;
    if (TextFieldLengthValidation(value)) {
      if (!TextFieldLengthRegexValidation(value) && value !== "") {
        displayToastMessage(this.polyglot.t("name_validaton"), "error");
        this.setState({ textFieldCharError: true });
      } else {
        this.setState({ addedReviewName: value, textFieldLengthError: false });
      }
    } else {
      this.setState({ textFieldLengthError: true });
    }
  };
  // KEYWORD ONCHANGE+==============
  onChangeKeywords = (fieldName) => (e) => {
    const data = e.target.value;
    this.setState({ keywordsData: data }, () => {});
  };
  // CUSTODIAN ONCHANGE EVENT=================
  onChangeCustodian = (fieldName) => (e) => {
    const data = e.target.value;
    this.setState({ custodianData: data }, () => {
      this.ReviewSelectionDataInfo();
    });
  };
  getAnalysisDetails = async (analysisId) => {
    try {
      const projectId = this.state.selectedProjectId;
      const { data: analysisDetails } = await analysisService.AnalysisDetails(analysisId, projectId);
      if (analysisDetails) {
        var total = 0;
        var SEARCH_TERM = 0;
        var TOP_LEVEL = 0;
        var ATTACHMENTS_NON_UNIQUE = 0;
        // CHECKING DATA IS THERE OR NOT
        if (analysisDetails && analysisDetails.length > 0) {
          if (analysisDetails[0].details) {
            analysisDetails[0].details.forEach((item) => {
              total = total + Number(item.TOP_LEVEL_DOC_ATTACHMENTS);
              SEARCH_TERM = SEARCH_TERM + Number(item.SEARCH_TERM);
              TOP_LEVEL = TOP_LEVEL + Number(item.TOP_LEVEL);
              ATTACHMENTS_NON_UNIQUE = ATTACHMENTS_NON_UNIQUE + Number(item.ATTACHMENTS_NON_UNIQUE);
            });
          }
          const custodianInfo = analysisDetails
            .flatMap(({ Custodian_Info }) => Custodian_Info)
            .map(({ ANALYSIS_ID, CUSTODIAN_ID, RUN_THROUGH_ID, custodainName, ds }) => ({
              analysisId: ANALYSIS_ID,
              custodianId: CUSTODIAN_ID,
              runThroughId: RUN_THROUGH_ID,
              custodianName: custodainName,
              datasets: ds,
            }));
          this.setState({
            custodianInfo,
            total1: total,
            SEARCH_TERM: SEARCH_TERM,
            TOP_LEVEL: TOP_LEVEL,
            ATTACHMENTS_NON_UNIQUE: ATTACHMENTS_NON_UNIQUE,
            checked: [analysisDetails.length - 1],
            openedAccordian: analysisDetails.length - 1,
            selectedRun: analysisDetails.length - 1,
          });
        }
        if (analysisDetails.length === 1) {
          this.setState({ selectedRunThroughData: analysisDetails[0] });
        } else {
          this.setState({ selectedRunThroughData: analysisDetails[analysisDetails.length - 1] });
        }
      }
      return true;
    } catch (err) {
      return false;
    }
  };
  onChangeAnalysis = async (analysisId) => {
    const selectedDataClone = { ...this.state.selectedData };
    selectedDataClone.AnalysisID = analysisId;
    this.setState({
      selectedData: selectedDataClone,
      custodianData: [], // empty selected custodians
      datasetData: [], // empty selected datasets
      uniqueCustodianInDropDown: [], // empty custodian dropdown values
      ReviewSelectionResult: [], // empty dataset dropdown values
      ReviewSelectionKeywordList: [], // empty keywords dropdown values
    });
    // after selecting an analysis, we need to call an API getAnalysisDetails/analysisID/projectID
    const analysisDetailsAPIComplete = await this.getAnalysisDetails(analysisId);
    if (analysisDetailsAPIComplete) {
      this.ReviewSelectionDataInfo();
    }
  };
  onChangeDataset = (fieldName) => (e) => {
    const data = e.target.value;
    this.setState({ datasetData: data });
  };

  /**
   * @param {number[]} selectedCustodiansIds Array of selected custodian ids
   */
  getDatasetsBySelectedCustodians(selectedCustodiansIds) {
    const datasetsWithCustodianDataAndLabel = this.state.custodianInfo.flatMap(
      ({ custodianId, custodianName, datasets }) =>
        datasets.map(({ datasetID: datasetId, DT_DATASET_NAME: datasetName }) => ({
          datasetId,
          custodianId,
          datasetLabel: `Custodian: ${custodianName} | Dataset: ${datasetName}`,
        })),
    );

    if (selectedCustodiansIds.length === 0) {
      return datasetsWithCustodianDataAndLabel;
    }

    const avaiableDatasets = datasetsWithCustodianDataAndLabel.filter(({ custodianId }) =>
      selectedCustodiansIds.includes(custodianId),
    );
    return avaiableDatasets;
  }

  /**
   * @param {CustodianInfo[]} custodiansInfo with all custodians informations
   */
  getUniqueCustodians(custodiansInfo) {
    const uniqueCustodiansIds = Array.from(new Set(custodiansInfo.map(({ custodianId }) => custodianId)));

    return uniqueCustodiansIds.map((custodianId) => {
      const { custodianName } = custodiansInfo.find((custodian) => custodian.custodianId === custodianId);
      return { custodianId, custodianName };
    });
  }

  // RENDER METHOD FOR HANDLING REVIEW SELECTION FUNCTIONALITY===================
  render() {
    var polyglot = polyglotLoader();
    return (
      <div>
        <CustomModal
          show={this.state.visibleTermsModel}
          onCancel={this.handleCancel}
          title={polyglot.t("Push to review")}
          Footer={[
            <>
              {this.state.keywordsData.length > 0 &&
              this.state.custodianData.length > 0 &&
              this.state.datasetData.length > 0 &&
              this.state.addedReviewName.length > 0 ? (
                <ButtonPrimary onClick={this.handleConfirm} ariaLabel={polyglot.t("Save")}>
                  {polyglot.t("Save")}
                </ButtonPrimary>
              ) : (
                <ButtonPrimary disabled onClick={this.handleConfirm} ariaLabel={polyglot.t("Save")}>
                  {polyglot.t("Save")}
                </ButtonPrimary>
              )}
            </>,
          ]}
          size="sm"
        >
          <>
            <div className="row ">
              <div className="col-md-4 labelDiv ">
                <span className="editTest-align">{polyglot.t("Review Name:")}</span>
              </div>
              <div className="col-md-8 labelDiv padding-top-point7">
                <Input
                  placeholder={polyglot.t("Enter review name")}
                  aria-label={polyglot.t("Enter review name")}
                  value={this.state.addedReviewName}
                  onChange={this.reviewnameOnChng.bind(this)}
                />
              </div>
            </div>
            <div className="row mrgnBtm10">
              <div className="col-md-4"> </div>
              <div className="col-md-8">
                {this.state.textFieldLengthError ? (
                  <p className="txt-field-error m-0 pt-1">{polyglot.t("Maximum character length is 40")}</p>
                ) : null}
              </div>
            </div>
            <div className="row mrgnBtm10">
              <div className="col-md-4 labelDiv">
                <span className="editTest-align">{polyglot.t("Analysis List:")}</span>
              </div>
              <div className="col-md-8 labelDiv padding-top-point7">
                {this.state.analysisLst && this.state.analysisLst.length > 0 ? (
                  <Select
                    onSelect={(e) => this.onChangeAnalysis(e)}
                    placeholder={polyglot.t("Select Analysis")}
                    multiple={false}
                  >
                    {this &&
                      this.state.analysisLst &&
                      this.state.analysisLst.length > 0 &&
                      this.state.analysisLst.map((data, index) => {
                        return (
                          <SelectOption key={index} value={data.ID}>
                            {data.ANALYSIS_NAME}
                          </SelectOption>
                        );
                      }, this)}
                  </Select>
                ) : (
                  <Select placeholder={polyglot.t("No Data found")} disabled>
                    {this &&
                      this.state.analysisLst &&
                      this.state.analysisLst.length > 0 &&
                      this.state.analysisLst.map((data, index) => {
                        return (
                          <SelectOption key={index} value={null}>
                            {polyglot.t("No Data found")}
                          </SelectOption>
                        );
                      }, this)}
                  </Select>
                )}
              </div>
            </div>
            <div className="row mrgnBtm10">
              <div className="col-md-4 labelDiv">
                <span className="editTest-align">{polyglot.t("Custodian Names:")}</span>
              </div>
              <div className="col-md-8 labelDiv padding-top-point7">
                {this.state.ReviewSelectionKeywordList &&
                this.state.ReviewSelectionKeywordList.length > 0 &&
                this.state.custodianInfo.length > 0 ? (
                  <SelectCustom
                    onSelect={this.onChangeCustodian}
                    placeholder={polyglot.t("Select custodian")}
                    multiple
                    showSelectAll
                    value={this.state.custodianData}
                    JSONArray={this.getUniqueCustodians(this.state.custodianInfo)}
                    optionKey="custodianId"
                    optionLabel="custodianName"
                  />
                ) : (
                  <Select placeholder={polyglot.t("No Data found")} disabled>
                    {this &&
                      this.state.analysisLst &&
                      this.state.analysisLst.length > 0 &&
                      this.state.analysisLst.map((data, index) => {
                        return (
                          <SelectOption key={index} value={null}>
                            {polyglot.t("No Data found")}
                          </SelectOption>
                        );
                      }, this)}
                  </Select>
                )}
              </div>
            </div>
            <div className="row mrgnBtm10">
              <div className="col-md-4 labelDiv">
                <span className="editTest-align">{polyglot.t("Dataset Names:")}</span>
              </div>
              <div className="col-md-8 labelDiv padding-top-point7">
                {this.state.custodianInfo.length > 0 && this.state.custodianData.length > 0 ? (
                  <SelectCustom
                    onSelect={this.onChangeDataset}
                    placeholder={polyglot.t("Select dataset")}
                    multiple
                    showSelectAll
                    value={this.state.datasetData}
                    JSONArray={this.getDatasetsBySelectedCustodians(this.state.custodianData)}
                    optionKey="datasetId"
                    optionLabel="datasetLabel"
                  />
                ) : (
                  <Select placeholder={polyglot.t("No Data found")} disabled>
                    {this &&
                      this.state.analysisLst &&
                      this.state.analysisLst.length > 0 &&
                      this.state.analysisLst.map((data, index) => {
                        return (
                          <SelectOption key={index} value={null}>
                            {polyglot.t("No Data found")}
                          </SelectOption>
                        );
                      }, this)}
                  </Select>
                )}
              </div>
            </div>
            <div className="row mrgnBtm10">
              <div className="col-md-4 labelDiv">
                <span className="editTest-align">{polyglot.t("Keyword:")}</span>
              </div>
              <div className="col-md-8 labelDiv padding-top-point7">
                {this.state.ReviewSelectionKeywordList && this.state.ReviewSelectionKeywordList.length > 0 ? (
                  <SelectCustom
                    onSelect={this.onChangeKeywords}
                    placeholder={polyglot.t("Select keywords")}
                    multiple
                    showSelectAll
                    value={this.state.keywordsData}
                    JSONArray={this.state.ReviewSelectionKeywordList[0].d}
                    optionKey="ID"
                    optionLabel="KEYWORD"
                  />
                ) : (
                  <Select placeholder={polyglot.t("No Data found")} disabled />
                )}
              </div>
            </div>
          </>
        </CustomModal>
      </div>
    );
  }
}

export default ReviewSelection;
