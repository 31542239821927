import React, { useState } from "react";

import LeftQuestionnaireQuestionPreview from "./LeftQuestionnaireQuestionPreview";
import "./index.css";

const LeftQuestionnaireTree = ({ data = [], handleChanged, setid, component }) => {
  return (
    <div className="d-tree p-0">
      <ul className="d-flex d-tree-container flex-column p-0">
        {data &&
          data.map((tree, i) => (
            <TreeNode key={i} node={tree} handleChanged={handleChanged} setid={setid} component={component} />
          ))}
      </ul>
    </div>
  );
};
const TreeNode = ({ node, handleChanged, setid, component }) => {
  const [childVisible, setChildVisiblity] = useState(true);

  const CurrentHandler = () => {
    setChildVisiblity((v) => !v);
  };
  const hasChild = node.children ? true : false;
  const loadIcon = () => {
    if (node.depth == 2) {
      return (
        <span
          className="appkiticon icon-news-outline  custom-grc-icon"
          onClick={(e) => setChildVisiblity((v) => !v)}
        />
      );
    } else {
      return (
        <span
          className="appkiticon icon-folder-closed-outline custom-grc-icon"
          onClick={(e) => setChildVisiblity((v) => !v)}
        />
      );
    }
  };

  return (
    <li className="d-tree-node border-0 " key={node.value}>
      <div className="d-flex">
        <div className={`col d-tree-head p-0  ${childVisible ? "selected-node" : ""}`}>
          {node.depth === 2 ? (
            <>
              {loadIcon()}
              <div
                key={node.value}
                className={`d-inline custome-tree-view-title ${childVisible ? "selected-node" : ""}`}
              >
                {parseInt(node.value) == parseInt(setid) && component !== "ques" ? (
                  <a
                    className="custom_Cat_Q p-0 ml-1 active-question"
                    onClick={(e) => {
                      handleChanged(e, node.value, "text");
                    }}
                  >
                    {node.label}
                  </a>
                ) : (
                  <a
                    className="custom_Cat_Q p-0 ml-1"
                    onClick={(e) => {
                      handleChanged(e, node.value, "text");
                    }}
                  >
                    {node.label}
                  </a>
                )}

                <LeftQuestionnaireQuestionPreview
                  parentId={node.value}
                  handleChanged={handleChanged}
                  setid={setid}
                  loadtype={component}
                  questions={node?.question_value ? node?.question_value?.split(" ") : []}
                />
              </div>
            </>
          ) : (
            <>
              {loadIcon()}
              <div
                key={node.value}
                className={`d-inline custome-tree-view-title ${childVisible ? "selected-node" : ""}`}
              >
                {node.value !== undefined && (
                  <>
                    {parseInt(node.value) == parseInt(setid) && component !== "ques" ? (
                      <a
                        className="custom_Cat_Q p-0 ml-1 active-question"
                        onClick={(e) => {
                          handleChanged(e, node.value, "text");
                        }}
                      >
                        {node.label}
                      </a>
                    ) : (
                      <a
                        className="custom_Cat_Q p-0 ml-1"
                        onClick={(e) => {
                          handleChanged(e, node.value, "text");
                        }}
                      >
                        {node.label}
                      </a>
                    )}
                  </>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {hasChild && childVisible && (
        <div className="d-tree-content">
          <ul className="d-flex d-tree-container flex-column">
            <LeftQuestionnaireTree
              data={node.children}
              handleChanged={handleChanged}
              setid={setid}
              component={component}
            />
          </ul>
        </div>
      )}
    </li>
  );
};
export default LeftQuestionnaireTree;
