import React, { Component } from "react";
import "../../../Components/DFED/Review/Review.css";
import OutlineButton from "../Shared/button/OutlineButton";
import img2 from "../../../Assets/Icons/survey-outline.svg";
import img1 from "../../../Assets/Icons/database-outline.svg";
import img from "../../../Assets/Icons/person-outline.svg";
import imgloader from "../../../Assets/Images/DFED/icon-review-loader.svg";
import "../Review/productionDataTable.css";
import displayToastMessage from "../../../Components/QueenBase/Root/displayToastMessage";
import ProductionDataTable from "../Review/productionDataTable";
import ProductionFiles from "../Review/ProductionFiles";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import AnalysisService from "../../../../src/Services/DFED/AnalysisService";
import {
  getReviewInfo,
  getReviewCount,
  getCustodianCount,
  getCustodianNamesInfo,
  getreviewGbInfo,
  getAnalysisNamesInfo,
  getReviewDocumentCountInfo,
  ReviewProductionFinalResultById,
  getTechnicalTemplate,
  getTechnicalReport,
  getreviewCustodianFilterData,
  getProductionRequestData,
} from "../../../Services/DFED/ReviewService";
import {
  Panel,
  Accordions,
  AccordionItemHeader,
  AccordionItemBody,
  AccordionItem,
  Select,
  SelectOption,
} from "appkit-react";
import { checkPermission } from "../../../utility/checkPermission";
import commonFunctionForCallService from "../../../utility/commonFunctionForCallService";
import * as module_Solution_Name from "../../../Constants/moduleName";
import NoAccess from "../../../Assets/Components/NoAccess";
import GetPriceInGermanFormat from "../../QueenBase/Root/GetPriceInGermanFormat";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { customNumberFormat } from "../../../utility/CustomNumberFormat";
import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";

const analysisService = new AnalysisService();

export class Review extends Component {
  constructor(props) {
    super();
    this.state = {
      redirectToTableNextPage: false,
      redirectToProductionNextPage: false,
      selectedProjectId: props.location ? props.location.state.selectedProjectId : props.selectedProjectId,
      selectedProjectName: sessionStorage.getItem("selectedProjectName"),
      reviewCounts: [],
      reviewGbData: "",
      totalDocument: [],
      custodianCounts: [],
      CustodianNames: [],
      AnalysisNames: [],
      ReviewDocumentCount: [],
      productionResult: [],
      analysisData: 0,
      reviewDataCount: [],
      ANALYSED_DOCUMENTS: [],
      AnalysisNames_ReviewHours: [],
      CUSTODIANID: [],
      datasets: [],
      analysis_name: props.location ? props.location.state.analysis_name : props.analysis_name,
      REVIEW_NAME: props.location ? props.location.state.REVIEW_NAME : props.REVIEW_NAME,
      TECHNICAL_REPORT_FILENAME: props.location
        ? props.location.state.TECHNICAL_REPORT_FILENAME
        : props.TECHNICAL_REPORT_FILENAME,
      rolePermission: {},
      rolePermissionForProduction: {},
      isViewPermission: true,
      pushedToReview: [],
      lengthOfVisData: 0,
      pushedLength: 0,
      completedAnalysisLst: [],
      selectedAnalysis: "total",
    };
  }
  polyglot = polyglotLoader();
  static contextType = DataContext;
  componentDidMount() {
    this.getReviewDetails();
    this.getCustodianCountInfo();
    this.getCustodianNames();
    this.getAnalysisNames();
    this.ReviewProductionResult();
    this.getReviewDocumentCount();
    this.getreviewGbdata();
    this.getreviewCustodianFilter();
    this.getApplicationRolesWithPermission();
    this.getApplicationRolesWithPermissionForProduction();
    this.getPushedToPreviewInfo();
    this.AnalysisLstData(this.state.selectedProjectId);
  }
  //download functionality
  downloadTemplate = (filrname) => {
    if (
      checkPermission(this.state.rolePermissionForProduction, module_Solution_Name.PRODUCTION_MODULE, "View")
    ) {
      getTechnicalTemplate(
        filrname,
        this.state.selectedProjectName,
        this.state.analysis_name,
        this.state.REVIEW_NAME,
      )
        .then((response) => {
          if (response.data) {
            var buf = Buffer.from(response.data.buffer);
            const url = window.URL.createObjectURL(new Blob([buf]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", response.data.fileName); //or any other extension
            link.click();
          } else {
            displayToastMessage(this.polyglot.t("File not available"), "error");
          }
        })
        .catch((error) => console.log(error));
    } else {
      displayToastMessage(
        this.polyglot.t("Unauthorize access ! you are not allowed to perform this action"),
        "error",
      );
    }
  };
  //get review page count details
  async getReviewDetails() {
    await getReviewInfo(this.state.selectedProjectId)
      .then((reviewInfo) => {
        if (reviewInfo && reviewInfo.data && reviewInfo.data[0]) {
          this.setState({
            reviewCounts: reviewInfo.data[0],
            totalDocument: reviewInfo.data[0].Total_Document,
          });
        }
      })
      .catch((err) => {
        if (err.status === 403) {
          this.setState({
            isViewPermission: false,
          });
        }
      });
  }
  //get custodian count
  async getCustodianCountInfo() {
    let reviewCustodianCount = await getCustodianCount(this.state.selectedProjectId, this.state.analysisData);
    if (reviewCustodianCount && reviewCustodianCount.data) {
      this.setState({
        custodianCounts: reviewCustodianCount.data[0],
      });
    }
  }
  mapPushedToPreviewInfoWithStatus(data) {
    const statusOrder = ["completed", "in_progress", "not_started"];
    return [
      ...data.map((item) => {
        const { PREPARE_KEYWORD_STATUS, RUNNING_KEYWORD_STATUS, REPORT_GENERATED_STATUS, ...rest } = item;
        const isCompleted =
          PREPARE_KEYWORD_STATUS === "3" && RUNNING_KEYWORD_STATUS === "3" && REPORT_GENERATED_STATUS === "3";
        if (isCompleted) {
          return {
            ...rest,
            status: "completed",
          };
        }
        const isInProgress = [
          PREPARE_KEYWORD_STATUS,
          RUNNING_KEYWORD_STATUS,
          REPORT_GENERATED_STATUS,
        ].includes("2");
        if (isInProgress) {
          return {
            ...rest,
            status: "in_progress",
          };
        }

        return {
          ...rest,
          status: "not_started",
        };
      }),
    ].sort((a, b) => {
      const aIndex = statusOrder.indexOf(a.status);
      const bIndex = statusOrder.indexOf(b.status);
      return aIndex - bIndex;
    });
  }

  //get pushedtoreview count
  async getPushedToPreviewInfo() {
    const pushedToReview = await getReviewCount(this.state.selectedProjectId);
    if (pushedToReview && pushedToReview.data) {
      const pushedToReviewWithStatus = this.mapPushedToPreviewInfoWithStatus(pushedToReview.data);
      const pushedLength = pushedToReviewWithStatus.filter((item) => item.status === "completed").length;
      this.setState({
        pushedToReview: pushedToReviewWithStatus,
        lengthOfVisData: pushedToReviewWithStatus.length,
        pushedLength,
      });
    }
  }
  //get cutodian names
  async getCustodianNames() {
    const { data: productionRequestData } = await getProductionRequestData(this.state.selectedProjectId);

    if(productionRequestData.datasets) {
      const datasets = productionRequestData.datasets.map(ds => ({ ...ds, CRT_ALIAS: productionRequestData.custodians?.find(el => el.CRT_ID === ds.CRT_ID)?.CRT_ALIAS ?? ""}));
      const analysedDatasets = this.state.analysisData ? datasets.filter(dataset => dataset.ANALYSIS_ID === this.state.analysisData) : datasets;
      this.setState({
        datasets: analysedDatasets.filter((value, index, self) => self.findIndex(v => v.DT_ID === value.DT_ID) === index)
      })
    }
    if (productionRequestData.custodians) {
      const custodians = this.state.analysisData ? productionRequestData.custodians.filter(custodian => custodian.ANALYSIS_ID === this.state.analysisData) : productionRequestData.custodians;
      this.setState({
        CustodianNames: custodians.filter((value, index, self) => self.findIndex(v => v.CRT_ID === value.CRT_ID) === index),
      });
    }
  }
  //get review GB data
  async getreviewGbdata() {
    const reviewreviewDataCount = await getreviewGbInfo(
      this.state.selectedProjectId,
      this.state.analysisData,
    );
    if (reviewreviewDataCount && reviewreviewDataCount.data) {
      const bytes_data = reviewreviewDataCount.data[0].FILE_SIZE;
      const gb_data = bytes_data / (1024 * 1024 * 1024);
      const tempGb = (Math.round(gb_data * 100) / 100).toFixed(2);
      this.setState({
        reviewDataCount: reviewreviewDataCount.data[0],
        ANALYSED_DOCUMENTS: reviewreviewDataCount.data[0].ANALYSED_DOCUMENTS,
        reviewGbData: tempGb,
      });
    }
  }
  //get production results
  async ReviewProductionResult() {
    let productionResult = await ReviewProductionFinalResultById(this.state.selectedProjectId);
    if (productionResult && productionResult.data) {
      productionResult.data.forEach(function (a) {
        a.REVIEW_UPLOADED_ON = a.REVIEW_UPLOADED_ON.substring(0, 10);
      });
      this.setState({
        productionResult: productionResult.data,
      });
    }
  }
  //get analysis names
  async getAnalysisNames() {
    let reviewAnalysisNames = await getAnalysisNamesInfo(this.state.selectedProjectId);
    if (reviewAnalysisNames && reviewAnalysisNames.data) {
      const uniqueAnalysis = reviewAnalysisNames.data.filter(
        (analysis, index, self) => self.findIndex((a) => a.ANALYSIS_ID === analysis.ANALYSIS_ID) === index,
      );
      this.setState({
        AnalysisNames: uniqueAnalysis,
      });
    }
  }
  //get review doc count and review hour
  async getReviewDocumentCount() {
    let ReviewDocumentCountInfo = await getReviewDocumentCountInfo(
      this.state.selectedProjectId,
      this.state.analysisData,
    );
    if (ReviewDocumentCountInfo.data) {
      this.setState({
        AnalysisNames_ReviewHours: ReviewDocumentCountInfo.data,
      });
    }
  }
  //get review custodian count filter
  async getreviewCustodianFilter() {
    let ReviewCustodianFilter = await getreviewCustodianFilterData(
      this.state.selectedProjectId,
      this.state.CUSTODIANID,
      this.state.analysisData,
    );
    if (ReviewCustodianFilter.data && ReviewCustodianFilter.data[0]) {
      this.setState({
        reviewDataCount: ReviewCustodianFilter.data[0],
      });
    }
  }
  onChangeAnalysis(data) {
    this.setState({ selectedAnalysis: data });
    if (data === "total") {
      this.setState(
        {
          analysisData: 0,
        },
        () => {
          this.getCustodianCountInfo();
          this.getReviewDocumentCount();
          this.getCustodianNames();
          this.getreviewGbdata();
          this.getreviewCustodianFilter();
        },
      );
    } else {
      this.setState(
        {
          analysisData: data.ANALYSIS_ID,
        },
        () => {
          this.getCustodianCountInfo();
          this.getReviewDocumentCount();
          this.getCustodianNames();
          this.getreviewGbdata();
          this.getreviewCustodianFilter();
        },
      );
    }
  }
  onChangeCustodian(data) {
    this.setState(
      {
        CUSTODIANID: data,
      },
      () => {
        this.getreviewCustodianFilter();
      },
    );
  }
  newProduceFiles = () => {
    if (this.props.newProps) {
      if (this.props.newProps.newProps) {
        this.props.newProps.newProps.history.push({
          pathname: "/dfed/production",
          state: {
            selectedProjectId: this.state.selectedProjectId,
            selectedProjectName: this.state.selectedProjectName,
            productionResult: this.state.productionResult,
            isFromReview: true,
            showBackButton: true,
          },
        });
      } else {
        this.props.newProps.history.push({
          pathname: "/dfed/production",
          state: {
            selectedProjectId: this.state.selectedProjectId,
            selectedProjectName: this.state.selectedProjectName,
            productionResult: this.state.productionResult,
            isFromReview: true,
            showBackButton: true,
          },
        });
      }
    } else {
      this.props.history.push({
        pathname: "/dfed/production",
        state: {
          selectedProjectId: this.state.selectedProjectId,
          selectedProjectName: this.state.selectedProjectName,
          productionResult: this.state.productionResult,
          isFromReview: true,
          showBackButton: true,
        },
      });
    }
  };

  // ============== CODE FOR GETTING ROLE==========
  getApplicationRolesWithPermission = async () => {
    let selectedProjectId = this.state.selectedProjectId;
    let rolePermission = await commonFunctionForCallService.commonFunctionForCallService(
      module_Solution_Name.DFED_SOLUTION,
      selectedProjectId,
      module_Solution_Name.REVIEW_MODULE,
    );
    if (rolePermission) {
      this.setState({
        rolePermission: rolePermission,
      });
    }
  };
  getApplicationRolesWithPermissionForProduction = async () => {
    let selectedProjectId = this.state.selectedProjectId;
    let rolePermission = await commonFunctionForCallService.commonFunctionForCallService(
      module_Solution_Name.DFED_SOLUTION,
      selectedProjectId,
      module_Solution_Name.PRODUCTION_MODULE,
    );
    if (rolePermission) {
      this.setState({
        rolePermissionForProduction: rolePermission,
      });
    }
  };

  // REDIRECT TO REVIEW PLATFORM URL
  redirectToReviewPlatformURL = () => {
    try {
      const REVIEW_PLATFORM_URL = this.props.location.state.REVIEW_PLATFORM_URL;
      if (REVIEW_PLATFORM_URL) {
        window.open(REVIEW_PLATFORM_URL);
      } else {
        const defaultURL = process.env.REACT_APP_REVIEW_PLATFORM_URL;
        window.open(defaultURL);
      }
    } catch (err) {
      displayToastMessage(this.polyglot.t("Review platform URL is not available"), "error");
    }
  };

  downloadReport = async () => {
    try {
      const res = await getTechnicalReport(this.state.selectedProjectId);
      const data = res.data;

      if (!data.CODE) {
        displayToastMessage(this.polyglot.t("File not available"), "error");
      } else {
        setTimeout(() => {
          const buffer = Buffer.from(data.buffer);
          const url = window.URL.createObjectURL(new Blob([buffer]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", data.fileName);
          link.click();
          displayToastMessage(this.polyglot.t("Downloaded successfully"), "success");
        }, 1000); // 1 sec sleep to collect Buffer properly
      }
    } catch (err) {
      displayToastMessage(this.polyglot.t(err.message), "error");
    }
  };

  async AnalysisLstData(tempSelectedProjectId) {
    await analysisService
      .AnalysisSummary(Number(tempSelectedProjectId))
      .then((analysInfo) => {
        function getCompletedAnalysisList(jsonList) {
          return jsonList.filter((each) => {
            if (
              each.PREPARE_KEYWORD_STATUS === "3" &&
              each.REPORT_GENERATED_STATUS === "3" &&
              each.RUNNING_KEYWORD_STATUS === "3"
            ) {
              return each;
            }
          });
        }
        const completedAnalysisList = getCompletedAnalysisList(analysInfo.data);
        this.setState({
          completedAnalysisLst: completedAnalysisList,
        });
      })
      .catch((err) => {
        if (err && err.status === 403) {
          this.setState({
            isViewPermission: false,
          });
        }
      });
  }

  checkIfUserCanCreateReview = () => {
    const polyglot = polyglotLoader();
    if (this.state.completedAnalysisLst.length === 0) {
      displayToastMessage(
        polyglot.t("Atleast one analysis request has to be completed to create a review request"),
        "error",
      );
    } else {
      this.pushToReview();
    }
  };

  // open add review screen
  pushToReview = () => {
    this.props.history.push({
      pathname: "/dfed/addreviewselection",
      state: {
        selectedProjectId: this.state.selectedProjectId,
      },
    });
  };

  render() {
    const polyglot = polyglotLoader();
    const title = (
      <p className="Review-files-data mb-0">
        {this.state.AnalysisNames_ReviewHours.length ? (
          ""
        ) : (
          <img src={imgloader} className="criteria-loader" alt="" />
        )}
        <span className="Review-files-status">
          <span className="Review-files-status">{GetPriceInGermanFormat(this.state.pushedLength, true)}</span>
          &nbsp;
        </span>
        {this.state.pushedLength > 1
          ? polyglot.t("analyses are ready from")
          : polyglot.t("analysis are ready from")}
        &nbsp;{GetPriceInGermanFormat(this.state.lengthOfVisData, true)}
      </p>
    );
    const set1 = (
      <div>
        <div className="row m-l-0 m-r-0"></div>
        {/* Info Content  Starts*/}
        <div className="row m-l-0 m-r-0">
          <div className="col-md-5 review-card p-0">
            <div className="panel">
              {this.state.pushedToReview.length > 0 && (
                <Panel className="Review-Files-start">
                  <br />
                  <div className="a-text-caption reviewTextDesign">
                    <span className="appkiticon icon-information-outline info-review"></span>
                    <span className="reviewPageText">
                      {polyglot.t("Your new files will be available for review shortly")}
                    </span>
                  </div>
                  <br />
                  <Accordions kind="transparent">
                    <AccordionItem itemId="panel-1">
                      <AccordionItemHeader title={title} className="pl-0" />
                      <AccordionItemBody>
                        <hr />
                      </AccordionItemBody>
                      <AccordionItemBody className="reviewScroll">
                        {this.state.pushedToReview?.length > 0 &&
                          this.state.pushedToReview.map((data) => {
                            return (
                              <div className="Review-anly-name" key={data.ID}>
                                <span className="analysis-status-image">
                                  {data.status === "completed" ? (
                                    <span className="icon-check-mark-fill appkiticon a-font-16 a-text-green"></span>
                                  ) : data.status === "in_progress" ? (
                                    <span className="a-loading a-loading-16 a-primary"></span>
                                  ) : (
                                    <span className="icon-minus-fill appkiticon a-font-16 a-text-red"></span>
                                  )}
                                </span>
                                {data.ANALYSIS_NAME}
                              </div>
                            );
                          })}
                      </AccordionItemBody>
                    </AccordionItem>
                  </Accordions>
                </Panel>
              )}
            </div>
          </div>
          <div className="col-md-8"></div>
        </div>
        {/* Info Content  Ends */}
        <br />
        <br />
        {/* Review Content  Starts*/}
        <div className="row m-l-0 m-r-0">
          <div className="col-md-9 p-0">
            <h1 className="textFont-Review-One">
              {polyglot.t("Findings from your data are ready for new review")}
            </h1>
            <div class="ml-auto a-font-12 a-opacity-80">
              <span className="DF-review-content">
                {polyglot.t(
                  "Below you can find key findings from our investigation. Download the full report or view online to see the full analysis.",
                )}
                <br />
                {polyglot.t(
                  "If you like to view a more in depth review navigate to the PwC Review Platform via the button below.",
                )}
              </span>
            </div>
          </div>
          <div className="col-md-4"></div>
        </div>
        {/* Review Content  Ends*/}
        <br />
        {/* Review Buttons Module  Starts*/}
        <div className="row m-l-0 m-r-0">
          <div className="col-md-12 p-0 mt-3 ">
            <div className="row m-l-0 m-r-0">
              <div className="col-md-6 col-lg-3  mt-3 tex-ali-p">
                <ButtonPrimaryWithIcon
                  onClick={this.checkIfUserCanCreateReview}
                  ariaLabel={polyglot.t("Add Review")}
                >
                  {polyglot.t("Add Review")}
                </ButtonPrimaryWithIcon>
              </div>

              <div className="col-md-6 col-lg-3  mt-3 tex-ali-p">
                <OutlineButton
                  className="button-expandable"
                  name={polyglot.t("View on Review Platform")}
                  onClick={this.redirectToReviewPlatformURL}
                />
              </div>

              <div className="col-md-6 col-lg-3  mt-3 tex-ali-p">
                <OutlineButton
                  className="button-expandable"
                  name={polyglot.t("Download Technical Report")}
                  onClick={this.downloadReport}
                />
              </div>
              {checkPermission(
                this.state.rolePermissionForProduction,
                module_Solution_Name.PRODUCTION_MODULE,
                "View",
              ) && (
                <div className="col-md-6 col-lg-3  mt-3 pl-0">
                  <ButtonPrimary
                    onClick={this.newProduceFiles}
                    ariaLabel={polyglot.t("Add production request")}
                  >
                    {polyglot.t("Add production request")}
                  </ButtonPrimary>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-3 p-0 mt-3 tex-ali-p"></div>
        </div>
        {/* Review Buttons Module  Ends*/}
        <br />
        {/* Filteranalysis  DropDown Module  Starts*/}
        <div className="row m-l-0 m-r-0">
          {this.state.AnalysisNames.length > 0 && (
            <div className="col-md-3 pl-0">
              <div className="textFont">{polyglot.t("Filter analysis")}</div>
              <div className="selectTextFont">
                <Select
                  placeholder={polyglot.t("Select analysis")}
                  onSelect={(e) => this.onChangeAnalysis(e)}
                  value={this.state.selectedAnalysis}
                >
                  <SelectOption value="total">{polyglot.t("Total")}</SelectOption>
                  {this.state.AnalysisNames &&
                    this.state.AnalysisNames.length > 0 &&
                    this.state.AnalysisNames.map((data, index) => {
                      return (
                        <SelectOption key={data.ANALYSIS_ID} value={data}>
                          {data.ANALYSIS_NAME}
                        </SelectOption>
                      );
                    })}
                </Select>
              </div>
            </div>
          )}
          <div className="col-md-8"></div>
        </div>
        <br />
        {/* Fileranalysis  DropDown Module  Ends*/}

        {/* Fileranalysis  Data Module  Starts*/}
        {this.state.AnalysisNames.length > 0 && <>
          <div className="row m-l-0 m-r-0 ">
            <div className="col-md-3 pl-0">
              <div className="a-h2 reviewtotaldoc-text">
                {this.state.custodianCounts && (
                  <b>
                    {this.state.custodianCounts.CRT_ALIAS
                      ? GetPriceInGermanFormat(this.state.custodianCounts.CRT_ALIAS, true)
                      : 0}{" "}
                  </b>
                )}
              </div>
              <span className="iconsTextAlign">
                <img
                  src={img}
                  width={16}
                  height={16}
                  className="criteria-loader"
                  alt="image_analyzecustodian"
                />
              </span>
              <span className="iconsText">
                {Number(this.state.custodianCounts.CRT_ALIAS) === 1
                  ? polyglot.t("Analysed custodian")
                  : polyglot.t("Analysed custodians")}
              </span>
            </div>
            <div className="col-md-3 pl-0">
              <div className="a-h2 reviewtotaldoc-text">
                {this.state.reviewGbData && (
                  <b>{this.state.reviewGbData ? customNumberFormat(this.state.reviewGbData, false) : 0}</b>
                )}
              </div>
              <span className="iconsTextAlign">
                <span>
                  <img src={img1} className="criteria-loader" alt="image_gbdata" width={16} height={16} />
                </span>
                <span className="iconsText"> {polyglot.t("GB of data processed")}</span>
              </span>
            </div>
            <div className="col-md-3 pl-0">
              <div className="a-h2 reviewtotaldoc-text">
                {this.state.reviewDataCount && (
                  <b>
                    {this.state.ANALYSED_DOCUMENTS
                      ? customNumberFormat(this.state.ANALYSED_DOCUMENTS, false)
                      : 0}
                  </b>
                )}
              </div>
              <span className="iconsTextAlign">
                <span>
                  <img
                    src={img2}
                    width={16}
                    height={16}
                    className="criteria-loader"
                    alt="image_analyseddocument"
                  />
                </span>
                <span className="iconsText"> {polyglot.t("Analyzed documents")}</span>
              </span>
            </div>
          </div>
          <div className="col-md-4"></div>
          <div className="a-py-10 a-border-light-Review a-mt-10 a-mb-20"></div>
          <div className="row m-l-0 m-r-0 ">
            {this.state.CustodianNames.length ?
              <div className="col-md-3 pl-0">
                <div className="a-h2 reviewtotaldoc-text">
                  {polyglot.t("Custodians")}
                </div>
                <span className="iconsTextAlign">
                  {this.state.CustodianNames.reduce((acc, curr, idx) => `${acc}${idx === 0 ? curr.CRT_ALIAS : `, ${curr.CRT_ALIAS}`}`, '')}
                </span>
              </div> : null
            }
            {this.state.datasets.length ? 
                <div className="col-md-3 pl-0">
                <div className="a-h2 reviewtotaldoc-text">
                  {polyglot.t("Datasets")}
                </div>
              {this.state.datasets.length ? this.state.datasets.map(dataset => <p style={{ margin: 0 }}>
                {polyglot.t("Custodian: ")} {dataset.CRT_ALIAS} | {polyglot.t("Dataset: ")} {dataset.DT_DATASET_NAME}
                </p>) : null}
              </div> : null
              }
            <div className="col-md-3 pl-0">            
            </div>
            </div>

          <div className="col-md-4"></div>
        </>
        }

        {/* Fileranalysis  Data Module  Ends*/}
      </div>
    );
    const set2 = (
      <ProductionDataTable
        selectedProjectId={this.state.selectedProjectId}
        selectedProjectName={this.state.selectedProjectName}
        productionResult={this.state.productionResult}
      />
    );
    const set3 = (
      <ProductionFiles
        selectedProjectId={this.state.selectedProjectId}
        selectedProjectName={this.state.selectedProjectName}
      />
    );
    var newset;
    if (this.state.redirectToTableNextPage && this.state.isViewPermission) {
      newset = set2;
    } else if (this.state.redirectToProductionNextPage && this.state.isViewPermission) {
      newset = set3;
    } else if (this.state.isViewPermission === false) {
      return <NoAccess />;
    } else {
      newset = set1;
    }
    return newset;
  }
}
export default Review;
