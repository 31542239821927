import axios from "axios";
import HttpClient from "../Shared/HttpClient";
import ErrorHandler from "../Shared/ErrorHandler";
import { getUserIDFromSession } from "../Shared/Authenticate";
axios.defaults.baseURL = process.env.REACT_APP_SERVER_HOST;
// axios.defaults.headers.common['Authorization'] = "JWT_TOKEN";  // Yet to complete
// axios.defaults.headers.post["Content-Type"] = "application/json";

export default class KeywordService {
  addKeywordList = async (formData) => {
    return new Promise((resolve, reject) => {
      // axios.defaults.headers.post["Authorization"]='Bearer ' + sessionStorage.getItem('jwt')
      axios
        .post("/keywordData", formData, {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("jwt"),
            loggedInUserId: getUserIDFromSession(),
            "Content-Type": "multipart/form-data",
          },
        })
        .then((reply) => {
          resolve(reply);
        })
        .catch((error) => {
          return Promise.reject(ErrorHandler.get().customErrorHandler(error.response));
        });
    });
  };

  getKeywordData = async (projectId) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getKeywordList/${projectId}`);
    return res;
  };

  deleteKeywordList = async (id, projectId) => {
    const client = new HttpClient();
    const res = await client.dfedPost(`/deleteKeywordList/${id}/${projectId}`);
    return res;
  };

  deleteKeyword = async (id, projectId) => {
    const client = new HttpClient();
    const res = await client.dfedPost("/deleteKeyword", {
      body: {
        keys: id,
        projectId: projectId,
      },
    });
    return res;
  };

  editKeyword = async (id, projectId, listName, updatedlistID) => {
    const client = new HttpClient();
    const res = await client.dfedPost("/editKeyword", {
      keys: id,
      projectId: projectId,
      listName,
      updatedlistID,
    });
    return res;
  };

  getTemplate = async (path) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getKeywordTemplate/${path}`);
    return res;
  };
}
