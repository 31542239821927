/* codes of German Umlant */
const germanUmlaut_codes = {
  ß: "\u00df",
  Ü: "\u00dc",
  Ö: "\u00d6",
  Ä: "\u00c4",
  ü: "\u00fc",
  ä: "\u00e4",
  ö: "\u00f6",
};

const germanUmlaut_codes_custome = {
  ß: "\u00df",
  Ü: "\u00dc",
  ü: "\u00fc",
  Ö: "\u00d6",
  ö: "\u00f6",
  ä: "\u00e4",
  Ä: "\u00c4",
};
const frenchUmlaut_codes_custome = {
  ë: "\u00eb",
  ï: "\u00ef",
  Ë: "\u00cb",
  Ï: "\u00cf",
};

const umlautValues = Object.keys(germanUmlaut_codes)
  .map((k) => {
    return germanUmlaut_codes[k];
  })
  .toString();

const umlautValuesGRCustome = Object.keys(germanUmlaut_codes_custome)
  .map((k) => {
    return germanUmlaut_codes_custome[k];
  })
  .toString();
const umlautValuesFRCustome = Object.keys(frenchUmlaut_codes_custome)
  .map((k) => {
    return frenchUmlaut_codes_custome[k];
  })
  .toString();
export const additionalSpecialCharacters = "éèç";

// This regular expression accepts only letters from a-z, A-Z, umlautValues and "space" with a length ranging from 1 to 40 characters
const regexStringWithUmlaut = `^[a-zA-Z${umlautValues} ]{1,40}$`;
export const ONLY_CHAR_REGEX = new RegExp(regexStringWithUmlaut);

// This regular expression accepts only letters from a-z, A-Z,0-9 umlautValues and "space" and "underscore" with a length ranging from 1 to 40 characters
const regexNameString = `^[a-zA-Z0-9${umlautValues} _]{1,40}$`;
export const NAME_REGEX = new RegExp(regexNameString);

const regexStringPhone = `^(\\(?\\+?[0-9]*\\)?)?[0-9_\\- \\(\\)]*$`;
export const PHONE_REGEX = new RegExp(regexStringPhone);

const regexStringPrice = `^(\\d*((?=\\d{3}))?\\d+)+([.]\\d{2})?$`;
export const PRICE_REGEX = new RegExp(regexStringPrice);

const regexURL_expression =
  /https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g;
export const URL_REGEX = new RegExp(regexURL_expression);
// This regular expression accepts only letters from a-z, A-Z umlautValues and "space" and "underscore", "full stop" with a length ranging from 1 to 40 characters
const regexStringWithUmlautFullstop = `^[a-zA-Z${umlautValues} .]{1,40}$`;
export const ONLY_CHAR_REGEX_Full_Stop = new RegExp(regexStringWithUmlautFullstop);

// This regular expression accepts only letters from a-z, A-Z,0-9 umlautValues and "space" and "_.-@&;,()\\[\\]"
const regexFieldString = `^[a-zA-Z0-9${umlautValuesGRCustome}${umlautValuesFRCustome}${additionalSpecialCharacters} _.\\-@&;,()\\[\\]]+$`;
export const FIELD_REGEX = new RegExp(regexFieldString);

// This regular expression accepts only letters from a-z, A-Z,0-9 umlautValues and "space" and "_.-@&;,()\\[\\]/?"
const regexQuestionsString = `^[a-zA-Z0-9${umlautValuesGRCustome}${umlautValuesFRCustome}${additionalSpecialCharacters} _.\\-@&;,()\\[\\]/?]+$`;
export const QUESTIONS_REGEX = new RegExp(regexQuestionsString);

// REGULAR EXPRESSION FOR POSITIVE NON DECIMAL NUMBERS WITH MAXIMUM LIMIT OF 100 NUMBERS
const regexNonDecimalNumberLimit100 = "^[0-9]{1,100}$";
export const NON_DECIMAL_NUMBER_LIMIT_100_REGEX = new RegExp(regexNonDecimalNumberLimit100);
const regexFieldNameString = `^[a-zA-Z0-9${umlautValuesGRCustome}${umlautValuesFRCustome}${additionalSpecialCharacters} _.\\-@;,()\\[\\]]+$`;
export const FIELD_NAME_REGEX = new RegExp(regexFieldNameString);

// This regular expression accepts only letters from a-z, A-Z,0-9 umlautValues and "space" and "_.-@&;,()\\[\\] till 1 to 60 chars"
const regexFieldLengthString = `^[a-zA-Z0-9${umlautValuesGRCustome}${umlautValuesFRCustome}${additionalSpecialCharacters} _.\\-@&;,()\\[\\]]{1,60}$`;
export const FIELD_LENGTH_REGEX = new RegExp(regexFieldLengthString);

// SAME AS FIELD_LENGTH_REGEX BUT DOES  NOT ALLOW &.
const regexFieldLengthStringWithoutamberSand = `^[a-zA-Z0-9${umlautValuesGRCustome}${umlautValuesFRCustome}${additionalSpecialCharacters} _.\\-@;,()\\[\\]]{1,40}$`;
export const FIELD_REGEX_NO_AMBERSAND = new RegExp(regexFieldLengthStringWithoutamberSand);

const regexAssessmentFileExtensions =
  /(\.pdf|.xls|.doc|.txt|.xlsx|.docx|.csv|.jpg|.png|.tiff|.zip|.ppt|.pptx)$/i;
export const FILE_EXTENSIONS = new RegExp(regexAssessmentFileExtensions);
