/* eslint-disable react/no-unescaped-entities */
import "../../../../Assets/Css/COMMON/Chart.css";
import { Panel } from "appkit-react";
import PropTypes from "prop-types";
import React, { useContext } from "react";
import { withRouter } from "react-router-dom";

import ProgressChart from "./ProgressChart";
import ApexChart from "./RequestCharts";
import note from "../../../../Assets/Icons/cogs-outline.svg";
import { DataContext } from "../../../../Services/Shared/Store";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
// RIGHT PANEL OF USER SCREEN
function RequestChartsDetails({ totalRequests, statusCountList, categoryCountList }) {
  const { getUserLanguage } = useContext(DataContext);
  const polyglot = polyglotLoader(getUserLanguage());

  return (
    <div>
      <>
        <Panel className="adduserpanel-three">
          <div className="d-flex justify-content-between a-mt-20 align-items-center card-spacing">
            <div>
              <span className="group-person ">
                <img src={note} alt="user" className="icon-card" />
                <h5 className="DF-User-data paddingLeft">{polyglot.t("Total Requests")}</h5>
              </span>
            </div>
            <div className="main_title_qb group-icon">
              <b>{totalRequests.length}</b>
            </div>
          </div>
          <div className="custom-divider"></div>
          <div className="d-flex justify-content-between  align-items-center card-spacing">
            <div>
              <ApexChart rawData={categoryCountList} />
            </div>
          </div>
        </Panel>

        <ProgressChart targetListCount={totalRequests.length} items={statusCountList} />
      </>
      {/* )} */}
    </div>
  );
}
RequestChartsDetails.propTypes = {
  totalRequests: PropTypes.array,
  statusCountList: PropTypes.array,
  categoryCountList: PropTypes.array,
};
export default withRouter(RequestChartsDetails);
