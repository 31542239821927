import React, { useState, useEffect } from "react";

import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/GRC/loading-spin.svg";
import RecipientService from "../../../Services/GRC/RecipientService";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import ErrorPanel from "../Shared/ErrorPanel";
import "./Pages/index.css";
import { getProjectID_Of_solution_module } from "../../../Services/Shared/Handlers";

const AssessmentViewQuestionPreview = ({
  parentId,
  handleChanged,
  totalQuestions,
  setid,
  loadtype,
  assessmentId,
}) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [assessmentQuestions, setAssessmentQuestions] = useState([]);

  useEffect(() => {
    console.log("uip", assessmentId);
    const getSelectedQuestionaries = async () => {
      try {
        const projectid = getProjectID_Of_solution_module();
        const responseData = await sendRequest(
          RecipientService.get().getRecipientAassessmentQuestionsByProjectURL(
            parentId,
            projectid,
            assessmentId,
          ),
        );
        if (responseData) {
          setAssessmentQuestions(responseData);
        }
      } catch (err) {
        console.log(err);
      }
    };
    getSelectedQuestionaries();
  }, [sendRequest]);
  return (
    <>
      {error && <ErrorPanel error={error} onClear={clearError} />}
      {isLoading && (
        <div>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      )}

      {!isLoading &&
        assessmentQuestions &&
        assessmentQuestions.map((item, i) => {
          return (
            <div key={item.value} className="p-0">
              <div className="quest-style">
                <span
                  className="appkiticon icon-circle-checkmark-outline custom-grc-icon"
                  style={{ marginRight: "4px" }}
                />
                {parseInt(item.value) === parseInt(setid) ? (
                  <span
                    className="active-question"
                    onClick={(e) => {
                      handleChanged(e, item.value, "ques");
                    }}
                  >
                    Question {String(i + 1).padStart(2, "0")}
                  </span>
                ) : (
                  <span
                    onClick={(e) => {
                      handleChanged(e, item.value, "ques");
                    }}
                  >
                    Question {String(i + 1).padStart(2, "0")}
                  </span>
                )}
              </div>
            </div>
          );
        })}
    </>
  );
};
export default AssessmentViewQuestionPreview;
