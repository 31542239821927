/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-mixed-spaces-and-tabs */
import "../../../Assets/Css/QueenBase/Root/Header.css";
import { Select, SelectOption } from "appkit-react";
import PropTypes from "prop-types";
import React, { useState, useContext } from "react";

//import {polyglotLoader, setCurrentLanguage} from "../../../Services/Shared/Translate";

import { withRouter, useLocation } from "react-router-dom";

import LinkText from "../../../Assets/Components/LinkText/LinkText";
import { logoutIcon } from "../../../Assets/Icons";
import logo from "../../../Assets/Icons/logo.png";
import loginicon from "../../../Assets/Images/QueenBase/Root/icon-login.svg";
import * as path from "../../../Constants/Path";
import {
  grc_check_If_User_LoggedIn,
  grc_expire_my_session,
} from "../../../Services/Shared/GrcAssesmentAuthenticate";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader, setCurrentLanguage } from "../../../Services/Shared/TranslateGRC";

import "./Pages/index.css";

function GRCAssessmentHeader(props) {
  const { getUserLanguage, setUserLanguage } = useContext(DataContext);

  const polyglot = polyglotLoader(getUserLanguage());

  const [applicationName, SetApplicationName] = useState();

  const [expandHeader, setExpandHeader] = useState(false);
  const [clientName, setClientName] = useState("");
  const location = useLocation();
  let count = 0;

  const home = () => {
    if (sessionStorage.getItem("jwt")) {
      props.history.push(path.QUEEN_BASE_PATH);
      window.location.reload();
    } else props.history.push(path.LOGIN_PATH);
  };
  const projectHome = () => {
    if (sessionStorage.getItem("jwt")) {
      props.history.push(path.PROJECT_PATH);
      window.location.reload();
    } else props.history.push(path.LOGIN_PATH);
  };

  const UserProfileroute = () => {
    props.history.push(path.USER_PROFILE);
  };
  const logOut = () => {
    grc_expire_my_session();
  };

  const changeLanguage = (value) => {
    setCurrentLanguage(value);
    setUserLanguage(value);
  };

  let user = sessionStorage.getItem("grc_recipient_user_email");

  return (
    <header>
      <nav className="navbar navbar-expand-lg navbar-light white-header pt-0 pb-0">
        <div className="navbar-brand" tabIndex="1">
          <img
            className="logoimg"
            alt="logo"
            src={logo}
            title="PwC - Connected Digital Services"
            aria-label="PwC - Connected Digital Services"
          />
        </div>

        <ul className="collapse navbar-collapse justify-content-end li-space">
          <li id="headerdownarrow" aria-label={polyglot.t("change_language")}>
            {getUserLanguage() === "ger" && (
              <Select
                defaultValue={getUserLanguage()}
                onSelect={changeLanguage}
                aria-label={polyglot.t("change_language")}
              >
                <SelectOption key={1} value={"en"}>
                  EN
                </SelectOption>
                <SelectOption key={2} value={"ger"}>
                  DE
                </SelectOption>
              </Select>
            )}
            {getUserLanguage() === "en" && (
              <Select
                defaultValue={getUserLanguage()}
                onSelect={changeLanguage}
                aria-label={polyglot.t("change_language")}
              >
                <SelectOption key={1} value={"en"}>
                  EN
                </SelectOption>
                <SelectOption key={2} value={"ger"}>
                  DE
                </SelectOption>
              </Select>
            )}
          </li>
          <li>
            <span className="font-weight-bold"> {polyglot.t("user")} :</span> {user}{" "}
          </li>
          <li className={location.pathname == "/PerformHome" ? "pl-2 mr-3" : "pl-2"}>
            {grc_check_If_User_LoggedIn() && (
              <LinkText onClick={logOut} ariaLabel={polyglot.t("logout")} href="javascript:;">
                <span className={`appkiticon ${logoutIcon}`} src={loginicon} alt="logout" />
                &nbsp;{polyglot.t("logout")}
              </LinkText>
            )}
          </li>
        </ul>
      </nav>
    </header>
  );
}
GRCAssessmentHeader.propTypes = {
  history: PropTypes.object,
  language: PropTypes.func,
  props: PropTypes.object,
};
export default withRouter(GRCAssessmentHeader);
