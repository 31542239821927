//previous and Next Button
import PropTypes from "prop-types";
import React from "react";

import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import { polyglotLoader } from "../../../Services/Shared/Translate";
// PREVIOUS AND NEXT BUTTONS OF CLIENT CREATION
const PrevNextButtons = (props) => {
  var polyglot = polyglotLoader();
  const { currentPage, handlePrev, CreateClient, handleNext, disabled } = props;
  return (
    <>
      <div className="a-py-10 border-top a-border-light a-mt-10 project-divider"></div>
      <div className="col-md-12">
        <div className="row">
          <div className="col-md-4 m-auto">
            {currentPage === 1 ? (
              ""
            ) : (
              <div className="text-left">
                <ButtonSecondary onClick={handlePrev} ariaLabel={polyglot.t("Back")}>
                  {polyglot.t("Back")}
                </ButtonSecondary>
              </div>
            )}
          </div>
          <div className="col-md-4" id="projectaddscreen1">
            {/* Note: removed appkit's pagination dots */}
          </div>
          <div className="col-md-4 m-auto">
            {currentPage === 5 ? (
              <div className="text-right">
                <ButtonPrimary
                  onClick={CreateClient}
                  disabled={disabled}
                  ariaLabel={polyglot.t("Create Client")}
                >
                  {polyglot.t("Create Client")}
                </ButtonPrimary>
              </div>
            ) : (
              <div className="text-right">
                <ButtonPrimary onClick={handleNext} ariaLabel={polyglot.t("Next")}>
                  {polyglot.t("Next")}
                </ButtonPrimary>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
PrevNextButtons.propTypes = {
  currentPage: PropTypes.number,
  handlePrev: PropTypes.func,
  CreateClient: PropTypes.func,
  handleNext: PropTypes.func,
};

export default PrevNextButtons;
