import { Button } from "appkit-react";
import PropTypes from "prop-types";
import React from "react";

import { plusCircle } from "../../Icons";

const ButtonSecondaryWithIcon = (props) => {
  const {
    size, // size can be 'sm', 'md', 'lg', 'xl'
    disabled,
    grayButton,
    type, // type can be 'submit', 'reset', 'button'
    isLoading,
    className,
    onClick,
    iconName,
    iconImg,
    alt,
    ariaLabel,
  } = props;
  return (
    <Button
      kind="secondary"
      size={size}
      disabled={disabled}
      gray={grayButton}
      type={type}
      isLoading={isLoading}
      className={`${className}`}
      onClick={onClick}
      aria-label={ariaLabel}
      role="link"
    >
      {!iconImg ? (
        <span alt={alt} className={`appkiticon ${iconName}`} aria-label={ariaLabel} role="link"></span>
      ) : (
        <>
          <img alt={alt} src={iconImg} aria-label={ariaLabel} />
          &nbsp;
        </>
      )}
      {props.children}
    </Button>
  );
};

ButtonSecondaryWithIcon.propTypes = {
  size: PropTypes.string,
  disabled: PropTypes.bool,
  grayButton: PropTypes.bool,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.any,
  onClick: PropTypes.func,
  iconName: PropTypes.string,
  iconImg: PropTypes.string,
  alt: PropTypes.string,
  ariaLabel: PropTypes.string,
};
ButtonSecondaryWithIcon.defaultProps = {
  size: "lg",
  disabled: false,
  grayButton: true,
  type: "button",
  isLoading: false,
  className: "",
  children: "primary button",
  iconName: plusCircle,
  alt: "image",
  ariaLabel: "button",
};

export default ButtonSecondaryWithIcon;
