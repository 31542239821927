/* eslint-disable react-hooks/exhaustive-deps */
import "../../../Assets/Css/QueenBase/Users/User.css";
import { Tooltip } from "appkit-react";
import React, { useEffect, useState, useContext } from "react";

import CreateUser from "./CreateUser";
import UserList from "./UserList";
import Usertoadd from "./Userstoadd";
import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";
import NoAccess from "../../../Assets/Components/NoAccess";
import { plusCircle, backCircle } from "../../../Assets/Icons";
import UserService from "../../../Services/QueenBase/Users/UserService";
import { hasCreatePermission, hasViewPermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";

// RENDERS ON /queenbase/users
function Users() {
  // FETCH DATA FROM CONTEXT
  const { checkAndFetchRole, loadingRoles, formGotOpened, formGotClosed, isFormOpen } =
    useContext(DataContext);
  let mounted = true;
  const [show, setShow] = useState(false);
  const [userCount, setUserCount] = useState({});
  const [tempData, setTempData] = useState({});
  const [rightLoad, setRightLoad] = useState(false);
  const [openConfirmBox, setOpenConfirmBox] = useState(false); // this value handles the confirm box of exit form
  const { getCurrentUserPermissions, getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const currentUserPermissions = getCurrentUserPermissions();

  // FETCH USER COUNT FROM API
  const getUserCount = async () => {
    var res = await UserService.get().getUserCount();
    if (res.CODE) {
      if (mounted) {
        setUserCount(res.usercount);
        setTempData({
          price: res.usercount.temporaryuserprice,
          noofmonths: res.usercount.noofmonths,
        });
      }
    } else {
      displayToastMessage(res.MSG, "error");
    }
  };

  // SHOW OR HIDE BACK BUTTON ON CLICK
  const buttonClick = () => {
    setShow((prev) => !prev);
  };
  //  RUNS IF show IS UPDATED
  useEffect(() => {
    getUserCount();
    return function cleanup() {
      mounted = false;
    };
  }, [show]);

  const rightLoadingCard = (data) => {
    setRightLoad(data);
  };
  // RUNS ON LOAD
  useEffect(() => {
    const screenName = "User";
    checkAndFetchRole(screenName, "Base");
  }, []);
  // DISPLAY TOOLTIP ON HOVER OF BUTTON
  const toolTipContent = () => {
    return (
      <div>
        <div className="tip-a-user">
          {polyglot.t("You have no more available user or temporary user profiles")}
        </div>
      </div>
    );
  };

  // check if form is empty before canceling the form
  const checkIfFormIsEmptyAndGoback = () => {
    if (isFormOpen) {
      // show confirm box
      toggleConfirmBox();
    } else {
      buttonClick();
    }
  };

  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  const toggleConfirmBox = () => {
    setOpenConfirmBox(!openConfirmBox);
  };

  // CLOSE FORM AND NAVIGATE TO VIEW PROJECTS
  const closeFormAndRedirect = () => {
    toggleConfirmBox();
    buttonClick();
    formGotClosed();
  };

  return (
    <div>
      <div className="">
        <div className="row ml-0 mr-0">
          {userCount.freeusers === 0 &&
          (userCount.freetempusers === 0 || userCount.freetempusers === null) ? (
            <>
              <div className="col-md-12 m-l-1 mt-3 m-b-18 p-0">
                {hasCreatePermission(currentUserPermissions) && (
                  <Tooltip content={toolTipContent()} placement="top">
                    <ButtonPrimaryWithIcon iconName={plusCircle} disabled ariaLabel={polyglot.t("Add user")}>
                      {polyglot.t("Add user")}
                    </ButtonPrimaryWithIcon>
                  </Tooltip>
                )}
              </div>
            </>
          ) : (
            <div className="col-md-12 m-l-1 mt-3 m-b-18 p-0">
              {hasCreatePermission(currentUserPermissions) && (
                <ButtonPrimaryWithIcon
                  disabled={Object.keys(userCount).length === 0 ? true : false}
                  onClick={checkIfFormIsEmptyAndGoback}
                  iconName={show ? backCircle : plusCircle}
                  alt={show ? "back" : "add"}
                  ariaLabel={show ? polyglot.t("Back") : polyglot.t("Add user")}
                >
                  {show ? polyglot.t("Back") : polyglot.t("Add user")}
                </ButtonPrimaryWithIcon>
              )}
            </div>
          )}
        </div>
        <div className="row m-0">
          <div className="col-md-8 side-test-form pl-0 m-l-1 mb-2">
            {show ? (
              <>
                {hasCreatePermission(currentUserPermissions) && (
                  <CreateUser buttonClick={buttonClick} userCount={userCount} />
                )}
              </>
            ) : (
              <>
                {hasViewPermission(currentUserPermissions) && (
                  <UserList
                    getUserCount={getUserCount}
                    userCount={userCount}
                    rightLoadingCard={rightLoadingCard}
                  />
                )}
                {!loadingRoles && !hasViewPermission(currentUserPermissions) && <NoAccess />}
              </>
            )}
          </div>
          <div className="col-md-3 usertoadd-responsive p-0 m-l-1">
            {rightLoad.length > 0 ? (
              <div>
                <Usertoadd userCount={userCount} tempData={tempData} fetchUserCount={getUserCount} />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <ConfirmBoxExitForm
        show={openConfirmBox}
        heading={"Cancel update"}
        cancel={toggleConfirmBox}
        proceed={closeFormAndRedirect}
      />
    </div>
  );
}

export default Users;
