import React, { useEffect } from "react";

import { UploadRoutes } from "../../../Routes/DFModuleRoutes";
import "../../../Assets/Css/DF/Upload/upload.css";

// RENDERS UPLOAD ROUTES OF DF MODULE
const Upload = (props) => {
  // RUNS ON INTIAL LOAD
  useEffect(() => {
    const paths = ["/df/upload", "/df/upload/"];
    // IF PATH DOES NOT MATCH, REDIRECT TO VIEW UPLOADS OF DF MODULE
    if (paths.includes(props.location.pathname)) {
      props.history.push("/df/upload/view");
    }
  });
  return <UploadRoutes />;
};

export default Upload;
