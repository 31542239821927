import HttpClient from "../../Shared/HttpClient";

class SolutionService {
  constructor() {
    this.client = new HttpClient();
  }

  // async  getSolutionList() {
  // 	const result = await this.client.get(
  // 		'/getSolutionList');
  // 	return result;
  // }

  /* GET PACKAGES OF THE SOLUTION
	PASSING: solutionId: number, language: string
	*/
  async getSolutionPackage(solution) {
    const result = await this.client.post("/getSolutionPackage", solution);
    return result;
  }
  /* GET PACKAGES OF DF/Connected Financial Analytics SOLUTION
	PASSING: solutionId: number, language: string
	*/
  async getDfSolutionPackages(solution) {
    const result = await this.client.post("/getDfSolutionPackages", solution);
    return result;
  }
  // FETCH PRICING OF SOLUTION "DF"
  async getSolutionPackagesPricing() {
    const result = await this.client.get("/getSolutionPackagesPricing");
    return result;
  }
  // BUY A PACKAGE ON THE SOLUTION
  /* PASSING: solutionid & packageId */
  async buySolutionPackage(packageData) {
    const result = await this.client.post("/buySolutionPackage", packageData);
    return result;
  }
  //Solution with language parameter
  async getSolutionList(language) {
    const result = await this.client.get(`/getSolutionList?language=${language}`);
    return result;
  }
}
// Enable Singleton behavior on this class
SolutionService.__instance = null;
SolutionService.get = () => {
  if (!SolutionService.__instance) {
    SolutionService.__instance = new SolutionService();
  }
  return SolutionService.__instance;
};

export default SolutionService;
