import axios from "axios";

import HttpClient from "../Shared/HttpClient";
axios.defaults.baseURL = process.env.REACT_APP_SERVER_HOST;
axios.defaults.headers.post["Content-Type"] = "application/json";

export async function getProfilesProcess() {
  const client = new HttpClient();
  const res = await client.dfedGet("/getProfilesProcessType");
  return res;
}

export async function getProfileAnalysisLanguage(language) {
  const client = new HttpClient();
  const res = await client.dfedGet(`/getProfileLanguage/${language}`);
  return res;
}

export async function getProfilesMailFileEncoding() {
  const client = new HttpClient();
  const res = await client.dfedGet("/getProfileMailFileEncoding");
  return res;
}

export async function getProfilesFileTypeService() {
  const client = new HttpClient();
  const res = await client.dfedGet("/getProfileFileType");
  return res;
}

export async function getProfilesMimeTypeService() {
  const client = new HttpClient();
  const res = await client.dfedGet("/getProfileMimeType");
  return res;
}

export async function getProfilesOcrPDFService() {
  const client = new HttpClient();
  const res = await client.dfedGet("/getProfileOcrPdf");
  return res;
}

export async function getProfilesOcrImageService() {
  const client = new HttpClient();
  const res = await client.dfedGet("/getProfileOcrImagesType");
  return res;
}

export async function getProfilesOcrQualityService() {
  const client = new HttpClient();
  const res = await client.dfedGet("/getProfileOcrQualityType");
  return res;
}

export async function postProfile(data) {
  const client = new HttpClient();
  const res = await client.dfedPost("/postProfileData", data);
  return res;
}

export async function editProfileService(data, profileId) {
  const client = new HttpClient();
  const res = await client.dfedPost("/editProfileData", { data: data, profileId: profileId });
  return res;
}

export async function uploadNsfCsv(formData) {
  const client = new HttpClient();
  const res = await client.dfedPost("/uploadNsf", formData, { "Content-Type": "multipart/form-data" });

  if (res.status === 200) {
    return true;
  } else {
    return false;
  }
}

export async function getProfilesOcrRotationService() {
  const client = new HttpClient();
  const res = await client.dfedGet("/getProfileOcrRotationType");
  return res;
}

export async function getProfilesOcrTimeOutService() {
  const client = new HttpClient();
  const res = await client.dfedGet("/getProfileOcrTimeout");
  return res;
}

export async function getProfilesGenTimezoneService() {
  const client = new HttpClient();
  const res = await client.dfedGet("/getProfileGenTimezone");
  return res;
}

export async function getAllProfilesService(data) {
  const client = new HttpClient();
  const res = await client.dfedPost("/getAllProfiles", { data });
  return res;
}

export async function custodianIsExistForProfileService() {
  const client = new HttpClient();
  const res = await client.dfedGet("/checkCustodainExist");
  return res;
}

export async function profileIsExistForProfileService(data) {
  const client = new HttpClient();
  const res = await client.dfedPost("/checkProfileIsExist", { data });
  return res;
}

export async function profileByIdService(data) {
  const client = new HttpClient();
  const res = await client.dfedPost("/getProfileById", { PR_ID: data });
  return res;
}

export async function profileDetailsById(data) {
  const client = new HttpClient();
  const res = await client.dfedPost("/getProfileDetailsById", { PR_ID: data });
  return res;
}

export async function allProfilesWithDetailsByProjectId(data) {
  const client = new HttpClient();
  const res = await client.dfedPost("/getAllProfilesWithDetailsByProjectId", { PR_PROJECT_ID: data });
  return res;
}

export async function isProfileInProcessService(data) {
  const client = new HttpClient();
  const res = await client.dfedPost("/isProfileInProcess", { PR_ID: data });
  return res;
}

export async function postDataSetUploadFile(data) {
  let tempData = [];
  for (var key of data.entries()) {
    tempData.push(key[1]);
  }

  const client = new HttpClient();
  const res = await client.dfedPost("/postDatasetUpload", { uploadData: data });
  return res;
}

export async function isExistProfileName(data) {
  const client = new HttpClient();
  const res = await client.dfedPost("/isExistedProfileName", { serviceData: data });
  return res;
}

export async function pushToProcessing(data) {
  const client = new HttpClient();
  const res = await client.dfedPost("/addToProcessingFrmProfile", data);
  return res;
}
