import { Panel } from "appkit-react";
import React, { useState, useEffect } from "react";

import { ReactComponent as LoadingSpinner } from "../../../../Assets/Images/GRC/loading-spin.svg";
import RecipientResponseService from "../../../../Services/GRC/RecipientResponseService";
import { useHttpClient } from "../../../../Services/GRC/http-hook";
import "../../../../Assets/Css/GRC/style.css";
import { getProjectID_Of_solution_module } from "../../../../Services/Shared/Handlers";
import EmptyPanel from "../../Shared/EmptyPanel";
import ErrorPanel from "../../Shared/ErrorPanel";
import LeftRecipient from "../LeftRecipient";
import RightPanel from "../RightPanel";

const RecipientResponse = () => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const [assessments, setassessments] = useState([]);
  const [assessment, setassessment] = useState({});

  const getAssessment = async () => {
    try {
      const projectid = getProjectID_Of_solution_module();
      const list = await sendRequest(RecipientResponseService.get().getRecipientAssessmentsURL(projectid));
      setassessment(list[0]);
      setassessments(list);
    } catch (err) {
      alert(err);
    }
  };

  useEffect(() => {
    getAssessment();
  }, [sendRequest]);

  const handleClick = (e, item) => {
    e.preventDefault();
    setassessment(item);
  };

  return (
    <div className="row m-0">
      <div className="col-md-3 pl-0 ">
        {error && <ErrorPanel error={error} onClear={clearError} />}
        {isLoading && (
          <div>
            <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
          </div>
        )}

        {!isLoading && assessments && (
          <Panel className="panel sr-questionaries">
            <LeftRecipient assessments={assessments} clickEvent={handleClick} />
          </Panel>
        )}
      </div>
      {error && <ErrorPanel error={error} onClear={clearError} />}
      {isLoading && (
        <div>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
      )}

      <div className="col-md-9 p-0 mt-2 pr-3">
        <div className="col-md-50 p-0">
          {assessments.length === 0 && <EmptyPanel massage={"No data found."} />}
          {!isLoading && assessments.length > 0 && <RightPanel assessment={assessment} />}
        </div>
      </div>
    </div>
  );
};
export default RecipientResponse;
