import "../../../Assets/Css/COMMON/Home.css";
import { Divider, Panel } from "appkit-react";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import LinkText from "../../../Assets/Components/LinkText/LinkText";
import ChevronDown from "../../../Assets/Icons/down-chevron-outline.svg";
import ChevronUp from "../../../Assets/Icons/up-chevron-outline.svg";
import RequestFormService from "../../../Services/GI/RequestFormServices";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";

// HOME COMPONENT OF GI SCREENS
const DisplayPackage = (props) => {
  const { lengthOfTargetList } = props;
  const { getUserLanguage, getLanguageKeyAsPerAPI } = useContext(DataContext);
  const [GIPackagesList, setGIPackagesList] = useState([]);
  const [expandHeader, setExpandHeader] = useState(false);
  const [targetList, setTargetList] = useState([]);
  var polyglot = polyglotLoader();
  const ToggleExpandHeader = () => {
    setExpandHeader(!expandHeader);
  };
  const language = getUserLanguage();

  // FETCH PACKAGE DETAILS OF GI
  const GetGIPackages = async (language) => {
    try {
      const languagekey = getLanguageKeyAsPerAPI(language);
      const data = await RequestFormService.get().fetchCategoryDetails(languagekey);
      if (data.CODE === 0) {
        displayToastMessage(data.MSG, "error");
      } else {
        const initalGIPackages = [];
        let obj = {};
        data.data.categoryList.forEach((r) => {
          obj = { ...r };
          obj.isHide = true;
          initalGIPackages.push(obj);
        });
        setGIPackagesList(initalGIPackages);
      }
    } catch (error) {
      displayToastMessage(error, "error");
    }
  };

  useEffect(() => {
    GetGIPackages(language);
  }, [language]);

  // TOGGLE SHOW MORE OF A PACKAGE
  const toggleItemHide = (itemId) => {
    const listClone = [];
    let analysisObj = {};
    GIPackagesList.forEach((item) => {
      analysisObj = { ...item };

      if (item.titleid === itemId) {
        analysisObj.isHide = !item.isHide;
      }
      listClone.push(analysisObj);
    });
    setGIPackagesList(listClone);
  };

  // DISPLAY LIST OF NOTES OF A PACAKGE
  const displayList = (jsonArray) => {
    return jsonArray.map((item, index) => (
      <ul>
        <li key={index} className="appkiticon icon-check-mark-fill icon-color para-style">
          &nbsp;&nbsp;
          <label className="f-acc-B" dangerouslySetInnerHTML={{ __html: item.details }}></label>
        </li>
      </ul>
    ));
  };

  // DISPLAY LIST OF PACKAGES AS A CARD VIEW
  const displayPackages = () => {
    return GIPackagesList.map((item, index) => {
      return (
        <div key={index} className="col-md-8 p-0">
          <Panel
            className={`df-panel-height analysisCard ${!item.isHide && "expand"}`}
            style={{ borderLeft: "5px solid", borderLeftColor: "#f9ce62" }}
          >
            <div style={{ float: "left" }} className="subtitle_two_title_qb">
              {item.title}
            </div>
            <div style={{ float: "right" }} className="subtitle_two_title_qb ">
              {item.description}
            </div>
            <div style={{ clear: "both" }}></div>
            <p className=" f-acc-B df-cc-temp-text para-style">{item.plan_description}</p>

            {!item.isHide && <ul>{displayList(item.cd)}</ul>}
            <Divider />
            <div style={{ float: "left" }} className="subtitle_two_title_qb m-t-5"></div>
            <LinkText
              style={{ float: "right" }}
              onClick={() => toggleItemHide(item.titleid)}
              className="expand-df-pacakge"
              ariaLabel={!item.isHide ? polyglot.t("Less details") : polyglot.t("See more")}
            >
              {!item.isHide ? polyglot.t("Less details") : polyglot.t("See more")}{" "}
              <img
                width={16}
                height={16}
                src={!item.isHide ? ChevronUp : ChevronDown}
                alt="toggle"
                aria-label={!item.isHide ? polyglot.t("compress") : polyglot.t("expand")}
              />
            </LinkText>
            <div style={{ clear: "both" }}></div>
          </Panel>
        </div>
      );

      if (expandHeader) {
        return (
          <div key={index} className="col-md-8 p-0">
            <Panel
              className={`df-panel-height analysisCard ${!item.isHide && "expand"}`}
              style={{ borderLeft: "5px solid", borderLeftColor: "#f9ce62" }}
            >
              <div style={{ float: "left" }} className="subtitle_two_title_qb">
                {item.title}
              </div>
              <div style={{ float: "right" }} className="subtitle_two_title_qb ">
                {item.description}
              </div>
              <div style={{ clear: "both" }}></div>
              {!item.isHide && <ul>{displayList(item.cd)}</ul>}
              <Divider />
              <div style={{ float: "left" }} className="subtitle_two_title_qb m-t-5"></div>
              <LinkText
                style={{ float: "right" }}
                onClick={() => toggleItemHide(item.titleid)}
                className="expand-df-pacakge"
                ariaLabel={!item.isHide ? polyglot.t("Less details") : polyglot.t("See more")}
              >
                {!item.isHide ? polyglot.t("Less details") : polyglot.t("See more")}{" "}
                <img
                  width={16}
                  height={16}
                  src={!item.isHide ? ChevronUp : ChevronDown}
                  alt="toggle"
                  aria-label={!item.isHide ? polyglot.t("compress") : polyglot.t("expand")}
                />
              </LinkText>
              <div style={{ clear: "both" }}></div>
            </Panel>
          </div>
        );
      }
    });
  };

  // DISPLAY LIST OF PACKAGES AS A CARD VIEW
  const displayPackagesExpand = () => {
    return GIPackagesList.map((item, index) => {
      return (
        <div key={index} className="col-md-8 p-0">
          <Panel
            className={`df-panel-height analysisCard ${!item.isHide && "expand"}`}
            style={{ borderLeft: "5px solid", borderLeftColor: "#f9ce62" }}
          >
            <div style={{ float: "left" }} className="subtitle_two_title_qb">
              {item.title}
            </div>
            <div style={{ float: "right" }} className="subtitle_two_title_qb ">
              {item.description}
            </div>
            <div style={{ clear: "both" }}></div>

            <p className=" f-acc-B df-cc-temp-text para-style">{item.plan_description}</p>
            {!item.isHide && <ul>{displayList(item.cd)}</ul>}
            <Divider />
            <div style={{ float: "left" }} className="subtitle_two_title_qb m-t-5"></div>
            <LinkText
              style={{ float: "right" }}
              onClick={() => toggleItemHide(item.titleid)}
              className="expand-df-pacakge"
              ariaLabel={!item.isHide ? polyglot.t("Less details") : polyglot.t("See more")}
            >
              {!item.isHide ? polyglot.t("Less details") : polyglot.t("See more")}{" "}
              <img
                width={16}
                height={16}
                src={!item.isHide ? ChevronUp : ChevronDown}
                alt="toggle"
                aria-label={!item.isHide ? polyglot.t("compress") : polyglot.t("expand")}
              />
            </LinkText>
            <div style={{ clear: "both" }}></div>
          </Panel>
        </div>
      );
    });
  };

  return (
    <div className="p-0 m-l-1-15">
      <>
        <div className="mt-3 full-width">
          <h1 className="main_title_qb">{polyglot.t("Modules")}</h1>
        </div>
        {displayPackages()}
      </>
    </div>
  );
};

DisplayPackage.propTypes = {
  history: PropTypes.any,
  location: PropTypes.object,
};

export default DisplayPackage;
