import React, { Component } from "react";
import BackButton from "../Shared/button/BackButton";
import "../../DFED/Processing/Processing.css";
import "../Shared/button/button.css";
import SearchBar from "../../DFED/Shared/components/FormSearchBar";
import { Checkbox, Select, SelectOption } from "appkit-react";
import ProcessingService from "../../../Services/DFED/ProcessingService";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import rightNormalIcon from "../../../Assets/Images/DFED/icon-chevron-right.svg";
import rightBlackIcon from "../../../Assets/Images/DFED/icon-chevron-right-black.svg";
import leftBlackIcon from "../../../Assets/Images/DFED/icon-chevron-left-black.svg";
import leftNormalIcon from "../../../Assets/Images/DFED/icon-chevron-left.svg";
import CustomModal from "../../../Assets/Components/Modal";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import { infoIcon } from "../../../Assets/Icons";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";

export class CreateProcessing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datasetsRecords: [],
      leftSideAvailableCustodian: null,
      selectedAvailableParent: null,
      selectedAvailableParentIndex: null,
      selectedProcessingParent: null,
      selectedProcessingParentIndex: null,
      showUploadKeyword: false,
      showUploadKeyword1: false,
      rightSideProcessingCustodianImage: [],
      rightSideProcessingCustodianOriginal: [],
      leftSideSelectedChildrenArray: [],
      AfterRemoveleftSideAvailableCustodian: [],
      avaliableCheckedListAll: [],
      avaliableCustodianChecked: false,
      processingCheckedListAll: [],
      processingCustodianChecked: false,
      search_text: null,
      filterAvailableCustodian: [],
      leftSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12  customClassForCol-5 b-g-w m-t-5 p-b-15",
      rightSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12 customClassForCol-5 b-g-w m-t-5 p-b-15",
      leftsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
      rightsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
      propfileDropdownValue: [],
      selectedProfileId: null,
      allowRunProcessing: false,
      checkedProcessingRightSideChild: [],
      checkedAvaliableLeftSideChild: [],
      // CODE FROM HERE NEW
      leftSideAvaliableSelectAll: false,
      leftSideAvalableParentExpandAll: [],
      rightSideProcessingSelectAll: false,
      rightSideProcessingParentExpandAll: [],
      openConfirmBox: false,

      currentSelectedLeftCustodian: null,
      currentSelectedRightCustodian: null,
    };
    this.expandAvailableParent = this.expandAvailableParent.bind(this);
    this.expandProcessingParent = this.expandProcessingParent.bind(this);
    this.selectAvaliableCustodianChild = this.selectAvaliableCustodianChild.bind(this);
    this.selectProcessingCustodianChild = this.selectProcessingCustodianChild.bind(this);
    this.handleAdd = this.handleAdd.bind(this);
    this.handleRemove = this.handleRemove.bind(this);
    this.avaliableCustodianOnChange = this.avaliableCustodianOnChange.bind(this);
    this.processingCustodianOnChange = this.processingCustodianOnChange.bind(this);
    this.filterAvailableCustodian = this.filterAvailableCustodian.bind(this);
    this.runProcessing = this.runProcessing.bind(this);
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
  }
  static contextType = DataContext;

  componentDidMount() {
    this.getProfile();
    this.getCustodianRecords();
  }
  getProfile() {
    const processingService = new ProcessingService();
    let selectedProjectId = this.props.selectedProjectId;
    processingService
      .getProfile(selectedProjectId)
      .then((result) => {
        if (result && result.data && result.data.length > 0) {
          this.setState({
            propfileDropdownValue: result.data,
          });
        } else {
          return null;
        }
      })
      .catch((err) => {});
  }

  getCustodianRecords() {
    const processingService = new ProcessingService();
    let selectedProjectId = this.props.selectedProjectId;
    processingService
      .getProcessingCustodianRecords(selectedProjectId)
      .then((result) => {
        if (result && result.data && result.data.length > 0) {
          result.data.forEach(function (custodianData) {
            custodianData.isParentChecked = false;
            custodianData.custodian_datasets.forEach(function (childDatasets) {
              childDatasets.isChildChecked = false;
            });
            custodianData.custodian_datasets_num = custodianData.custodian_datasets.length;
          });
          this.setState({
            datasetsRecords: result.data,
            leftSideAvailableCustodian: result.data,
            filterAvailableCustodian: result.data,
          });
        } else {
          return null;
        }
      })
      .catch((err) => {});
  }

  // RUNS WHEN COMPONENT GETS UPDATED
  componentDidUpdate(prevProps, prevState) {
    if (this.rightSideProcessingCustodianOriginal(prevState) || this.processingProfile(prevState)) {
      const { formGotOpened } = this.context;
      formGotOpened();
    }

    if (
      prevState.currentSelectedLeftCustodian !== this.state.currentSelectedLeftCustodian &&
      !this.state.leftSideAvalableParentExpandAll.includes(this.state.currentSelectedLeftCustodian)
    ) {
      const leftSideAvalableParentExpandAll = [
        ...this.state.leftSideAvalableParentExpandAll,
        this.state.currentSelectedLeftCustodian,
      ];
      this.setState({ leftSideAvalableParentExpandAll });
    }

    if (
      prevState.currentSelectedRightCustodian !== this.state.currentSelectedRightCustodian &&
      !this.state.rightSideProcessingParentExpandAll.includes(this.state.currentSelectedRightCustodian)
    ) {
      const rightSideProcessingParentExpandAll = [
        ...this.state.rightSideProcessingParentExpandAll,
        this.state.currentSelectedRightCustodian,
      ];
      this.setState({ rightSideProcessingParentExpandAll });
    }
  }

  //rightSideProcessingCustodianOriginal return value for form
  rightSideProcessingCustodianOriginal = (prevState) => {
    if (this.state.rightSideProcessingCustodianOriginal.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  //selectedProfileId return value
  processingProfile = (prevState) => {
    if (this.state.selectedProfileId) {
      if (this.state.selectedProfileId !== prevState.selectedProfileId) {
        return true;
      }
    } else {
      return false;
    }
  };

  expandAvailableParent(dataSets, index, event) {
    //============ CODE FOR PANEL CLICK ON EXPAND ICON FROM LEFT SIDE=====
    const tempLeftSideAvalableParentExpandAll = this.state.leftSideAvalableParentExpandAll;
    // CHECKING IF ALREADY ELEMENT IS THERE OR NOT
    const leftSideAvalableExpandIndex = tempLeftSideAvalableParentExpandAll.findIndex(function (data) {
      return data === dataSets.CRT_ID;
    });

    if (leftSideAvalableExpandIndex === -1) {
      tempLeftSideAvalableParentExpandAll.push(dataSets.CRT_ID);
    }

    this.setState({
      selectedAvailableParentIndex: index,
      selectedAvailableParent: dataSets,
      showUploadKeyword: true,
      leftSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12 customClassForCol-5  b-g-w",
      leftsidecustodiandivstyle: "tot-check tot-check-reducewidth m-l-0 m-r-0 m-0 p-r-0",
      checkedAvaliableLeftSideChild: [],
      leftSideAvalableParentExpandAll: tempLeftSideAvalableParentExpandAll,
      currentSelectedLeftCustodian: dataSets.CRT_ID,
    });
  }

  expandProcessingParent(dataSets, index, event) {
    //============ CODE FOR panel CLICK ON EXPAND ICON FROM LEFT SIDE=====
    const tempRightSideProcessingParentExpandAll = this.state.rightSideProcessingParentExpandAll;
    // CHECKING IF ALREADY ELEMENT IS THERE OR NOT
    const rightSideProcessingExpandIndex = tempRightSideProcessingParentExpandAll.findIndex(function (data) {
      return data === dataSets.CRT_ID;
    });
    if (rightSideProcessingExpandIndex === -1) {
      tempRightSideProcessingParentExpandAll.push(dataSets.CRT_ID);
    }

    this.setState({
      selectedProcessingParentIndex: index,
      selectedProcessingParent: dataSets,
      showUploadKeyword1: true,
      rightSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12  customClassForCol-5 b-g-w",
      rightsidecustodiandivstyle: "tot-check tot-check-reducewidth m-l-0 m-r-0 m-0 p-r-0",
      checkedProcessingRightSideChild: [],
      rightSideProcessingParentExpandAll: tempRightSideProcessingParentExpandAll,
      currentSelectedRightCustodian: dataSets.CRT_ID,
    });
  }

  // Code for selecting child from left side custodian
  selectAvaliableCustodianChild(event, dataSets) {
    let temprightSideProcessingCustodianImage = Object.assign(
      [],
      this.state.rightSideProcessingCustodianOriginal,
    );
    let templeftSideAvailableCustodian = Object.assign([], this.state.leftSideAvailableCustodian);
    let tempLeftSideAvalableParentExpandAll = this.state.leftSideAvalableParentExpandAll;
    let leftsideParentIndex =
      templeftSideAvailableCustodian &&
      templeftSideAvailableCustodian.findIndex(function (custodianData) {
        return dataSets.Custodian_id === custodianData.CRT_ID;
      });
    if (event.target.checked) {
      if (leftsideParentIndex !== -1) {
        templeftSideAvailableCustodian[leftsideParentIndex].custodian_datasets.forEach(function (
          childDatasets,
        ) {
          if (childDatasets.DataSetID === dataSets.DataSetID) {
            childDatasets.isChildChecked = true;
          }
        });
      }
      this.setState({
        leftSideAvailableCustodian: templeftSideAvailableCustodian,
      });
    } else {
      templeftSideAvailableCustodian[leftsideParentIndex].custodian_datasets.forEach(function (
        childDatasets,
      ) {
        if (childDatasets.DataSetID === dataSets.DataSetID) {
          childDatasets.isChildChecked = false;
        }
      });
      this.setState({
        leftSideAvailableCustodian: templeftSideAvailableCustodian,
        leftSideAvaliableSelectAll: false,
      });
    }
  }

  //CODE FOR SELECTING RIGHT SIDE CUSTODIAN DATASET CHILD//
  selectProcessingCustodianChild(event, dataSets) {
    let temprightSideProcessingCustodianImage = Object.assign(
      [],
      this.state.rightSideProcessingCustodianOriginal,
    );
    let rightsideParentIndex =
      temprightSideProcessingCustodianImage &&
      temprightSideProcessingCustodianImage.findIndex(function (custodianData) {
        return dataSets.Custodian_id === custodianData.CRT_ID;
      });
    if (event.target.checked) {
      if (rightsideParentIndex !== -1) {
        temprightSideProcessingCustodianImage[rightsideParentIndex].custodian_datasets.forEach(function (
          childDatasets,
        ) {
          if (childDatasets.DataSetID === dataSets.DataSetID) {
            childDatasets.isChildChecked = true;
          }
        });
      }
      this.setState({
        rightSideProcessingCustodianImage: temprightSideProcessingCustodianImage,
      });
    } else {
      temprightSideProcessingCustodianImage[rightsideParentIndex].custodian_datasets.forEach(function (
        childDatasets,
      ) {
        if (childDatasets.DataSetID === dataSets.DataSetID) {
          childDatasets.isChildChecked = false;
        }
      });
      this.setState({
        rightSideProcessingCustodianImage: temprightSideProcessingCustodianImage,
        rightSideProcessingSelectAll: false,
      });
    }
  }

  //ADD FUNCTIONALITY==========//
  handleAdd() {
    let temprightSideProcessingCustodianImage = Object.assign(
      [],
      this.state.rightSideProcessingCustodianOriginal,
    );
    let templeftSideAvailableCustodian = Object.assign([], this.state.leftSideAvailableCustodian);
    const tempAvaliableCheckdListAll = Object.assign([], this.state.avaliableCheckedListAll);
    let removedElementFromLeft = [];

    let leftSideParentCustodianIndex;
    let rightSideParentIndex;

    // CASE 1: "SELECT ALL" checkbox - select ALL CUSTODIANS WITH ALL DATASETS
    if (this.state.leftSideAvaliableSelectAll) {
      // IF ALREADY SOME ELEMENT IS THERE IN RIGHT SIDE THEN WILL GO WITH THIS BLOCK
      if (temprightSideProcessingCustodianImage && temprightSideProcessingCustodianImage.length > 0) {
        for (let i = 0; i < templeftSideAvailableCustodian.length; i++) {
          const index = temprightSideProcessingCustodianImage.findIndex(
            (data) => data.CRT_ID === templeftSideAvailableCustodian[i].CRT_ID,
          );

          if (index === -1) {
            temprightSideProcessingCustodianImage.push(templeftSideAvailableCustodian[i]);
          } else {
            //  CHECK IF CHILD ALREADY IS THERE OR NOT
            for (let j = 0; j < templeftSideAvailableCustodian[i].custodian_datasets.length; j++) {
              const checkChildIndexInRight = temprightSideProcessingCustodianImage[
                index
              ].custodian_datasets.findIndex(
                (childData) =>
                  childData.DataSetID === templeftSideAvailableCustodian[i].custodian_datasets[j].DataSetID,
              );

              if (checkChildIndexInRight === -1) {
                temprightSideProcessingCustodianImage[index].custodian_datasets.push(
                  templeftSideAvailableCustodian[i].custodian_datasets[j],
                );
              }
            }
          }
        }

        temprightSideProcessingCustodianImage.forEach((custodianData) => {
          custodianData.isParentChecked = false;
          custodianData.partial = true;
          custodianData.custodian_datasets.forEach((childDatasets) => (childDatasets.isChildChecked = false));
        });

        this.setState({
          rightSideProcessingCustodianOriginal: temprightSideProcessingCustodianImage,
          leftSideAvailableCustodian: [],
          leftSideAvaliableSelectAll: false,
          avaliableCheckedListAll: [],
          filterAvailableCustodian: [],
          leftSideAvalableParentExpandAll: [],
          selectedAvailableParentIndex: null,
          selectedAvailableParent: null,
          showUploadKeyword: false,
          leftSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12  customClassForCol-5 b-g-w m-t-5 p-b-15",
          leftsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
          checkedAvaliableLeftSideChild: [],
          currentSelectedLeftCustodian: null,
        });
      } else {
        //=========== THIS COD EXECUTE WEN RIGHT SIDE IS BLANK AND ALL SELECT CHECKBOX CLICKED FOR LEFT SIDE===
        templeftSideAvailableCustodian.forEach((custodianData) => {
          custodianData.isParentChecked = false;
          custodianData.custodian_datasets.forEach((childDatasets) => (childDatasets.isChildChecked = false));
        });

        this.setState({
          rightSideProcessingCustodianOriginal: templeftSideAvailableCustodian,
          leftSideAvailableCustodian: [],
          leftSideAvaliableSelectAll: false,
          avaliableCheckedListAll: [],
          filterAvailableCustodian: [],
          leftSideAvalableParentExpandAll: [],
          selectedAvailableParentIndex: null,
          selectedAvailableParent: null,
          showUploadKeyword: false,
          leftSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12  customClassForCol-5 b-g-w m-t-5 p-b-15",
          leftsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
          checkedAvaliableLeftSideChild: [],
          currentSelectedLeftCustodian: null,
        });
      }
    }

    // CASE 2: checkbox to select ALL DATASETS OF ONE CUSTODIAN
    if (tempAvaliableCheckdListAll.length > 0 && !this.state.leftSideAvaliableSelectAll) {
      let expandCustodianObj;

      for (let i = 0; i < this.state.avaliableCheckedListAll.length; i++) {
        const self = this;
        rightSideParentIndex = temprightSideProcessingCustodianImage.findIndex(
          (data) => data.CRT_ID === self.state.avaliableCheckedListAll[i],
        );

        // CASE: when PART of custodian's datasets are already on RIGHT SIDE
        if (rightSideParentIndex !== -1) {
          const self = this;
          leftSideParentCustodianIndex = templeftSideAvailableCustodian.findIndex(function (value) {
            return value.CRT_ID === self.state.avaliableCheckedListAll[i];
          });
          if (templeftSideAvailableCustodian[leftSideParentCustodianIndex]) {
            for (
              let j = 0;
              j < templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length;
              j++
            ) {
              const checkChildIndexInRight = temprightSideProcessingCustodianImage[
                rightSideParentIndex
              ].custodian_datasets.findIndex(
                (childData) =>
                  childData.DataSetID ===
                  templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j]
                    .DataSetID,
              );
              if (checkChildIndexInRight === -1) {
                temprightSideProcessingCustodianImage[rightSideParentIndex].custodian_datasets.push(
                  templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j],
                );
              }
            }
            templeftSideAvailableCustodian.splice(leftSideParentCustodianIndex, 1);
            removedElementFromLeft.push(this.state.avaliableCheckedListAll[i]);
          }
        } else {
          const self = this;
          leftSideParentCustodianIndex = templeftSideAvailableCustodian.findIndex(
            (value) => value.CRT_ID === self.state.avaliableCheckedListAll[i],
          );

          temprightSideProcessingCustodianImage.push(
            templeftSideAvailableCustodian[leftSideParentCustodianIndex],
          );
          templeftSideAvailableCustodian.splice(leftSideParentCustodianIndex, 1);
          removedElementFromLeft.push(this.state.avaliableCheckedListAll[i]);
        }
      }

      const self = this;
      const custodianIndex = templeftSideAvailableCustodian.findIndex(
        (value) => value.CRT_ID === self.state.currentSelectedLeftCustodian,
      );

      //must be selected current custodian to expand and make his datasets visible
      //below are cases related to already clicked expand arrow
      if (this.state.currentSelectedLeftCustodian) {
        //CASE 1: if we had custodian with expand datasets
        //but we moved him to the right side
        //and now there is no custodian left on the left side
        if (!templeftSideAvailableCustodian.length) {
          expandCustodianObj = {
            leftSideAvalableParentExpandAll: [],
            selectedAvailableParentIndex: null,
            selectedAvailableParent: null,
            showUploadKeyword: false,
            leftSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12  customClassForCol-5 b-g-w m-t-5 p-b-15",
            leftsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
            checkedAvaliableLeftSideChild: [],
            currentSelectedLeftCustodian: null,
          };
          //CASE 2: if we had custodian with expand datasets
          //and we moved him to the right side
          //but he was on the buttom of the list
        } else if (templeftSideAvailableCustodian.length <= leftSideParentCustodianIndex) {
          expandCustodianObj = {
            selectedAvailableParentIndex: templeftSideAvailableCustodian.length - 1,
            selectedAvailableParent:
              templeftSideAvailableCustodian[templeftSideAvailableCustodian.length - 1],
            showUploadKeyword: true,
            leftSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12 customClassForCol-5  b-g-w",
            leftsidecustodiandivstyle: "tot-check tot-check-reducewidth m-l-0 m-r-0 m-0 p-r-0",
            checkedAvaliableLeftSideChild: [],
            currentSelectedLeftCustodian:
              templeftSideAvailableCustodian[templeftSideAvailableCustodian.length - 1].CRT_ID,
          };
          //CASE 3: if we had custodian with expand datasets
          //and we moved him to the right side
        } else if (custodianIndex === -1) {
          expandCustodianObj = {
            currentSelectedLeftCustodian: templeftSideAvailableCustodian[leftSideParentCustodianIndex].CRT_ID,
          };
          //CASE 4: if we had custodian with expand datasets
          //and we moved ANOTHER custodian to the right side
        } else {
          const index = templeftSideAvailableCustodian.findIndex(
            (custodian) => custodian.CRT_ID === this.state.currentSelectedLeftCustodian,
          );
          expandCustodianObj = { selectedAvailableParentIndex: index };
        }
      }

      temprightSideProcessingCustodianImage.forEach(function (custodianData) {
        custodianData.isParentChecked = false;
        custodianData.partial = true;
        custodianData.custodian_datasets.forEach(function (childDatasets) {
          childDatasets.isChildChecked = false;
        });
      });

      //  WRITING LOGIC FOR  CHECKBOX ARRAY CONSISTENT
      const newAvaliableCheckedListAll = tempAvaliableCheckdListAll.filter(
        (e) => removedElementFromLeft.indexOf(e) === -1,
      );

      this.setState({
        leftSideAvailableCustodian: templeftSideAvailableCustodian,
        rightSideProcessingCustodianOriginal: temprightSideProcessingCustodianImage,
        avaliableCheckedListAll: newAvaliableCheckedListAll,
        filterAvailableCustodian: templeftSideAvailableCustodian,
        ...expandCustodianObj,
      });
    }

    // CASE 3: USE "EXPAND ARROW" to select EACH DATASET OF CUSTODIAN
    if (
      this.state.leftSideAvalableParentExpandAll.length > 0 &&
      !this.state.leftSideAvaliableSelectAll &&
      !tempAvaliableCheckdListAll.length
    ) {
      for (let i = 0; i < this.state.leftSideAvalableParentExpandAll.length; i++) {
        const self = this;
        rightSideParentIndex = temprightSideProcessingCustodianImage.findIndex(
          (data) => data.CRT_ID === self.state.leftSideAvalableParentExpandAll[i],
        );
        // CASE: when PART of custodian's datasets are already on RIGHT SIDE
        if (rightSideParentIndex !== -1) {
          const self = this;
          leftSideParentCustodianIndex = templeftSideAvailableCustodian.findIndex(
            (value) => value.CRT_ID === self.state.leftSideAvalableParentExpandAll[i],
          );

          if (templeftSideAvailableCustodian[leftSideParentCustodianIndex]) {
            for (
              let j = 0;
              j < templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length;
              j++
            ) {
              const checkChildIndexInRight = temprightSideProcessingCustodianImage[
                rightSideParentIndex
              ].custodian_datasets.findIndex(
                (childData) =>
                  childData.DataSetID ===
                  templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j]
                    .DataSetID,
              );
              if (checkChildIndexInRight === -1) {
                if (
                  templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j]
                    .isChildChecked
                ) {
                  temprightSideProcessingCustodianImage[rightSideParentIndex].custodian_datasets.push(
                    templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j],
                  );
                }
              }
            }
            const newAvaliableCheckedListAll = templeftSideAvailableCustodian[
              leftSideParentCustodianIndex
            ].custodian_datasets.filter((e) => !e.isChildChecked);

            templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets =
              newAvaliableCheckedListAll;

            if (templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length > 1) {
              templeftSideAvailableCustodian[leftSideParentCustodianIndex].partial = false;
            }
            if (!templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length) {
              templeftSideAvailableCustodian[leftSideParentCustodianIndex].partial = true;
              temprightSideProcessingCustodianImage[rightSideParentIndex].partial = true;
              templeftSideAvailableCustodian.splice(leftSideParentCustodianIndex, 1);
            }
          }
        }
        // CASE: when NONE of custodian's datasets are on RIGHT SIDE
        else {
          const self = this;
          const temp_custodian_datasets = [];
          leftSideParentCustodianIndex = templeftSideAvailableCustodian.findIndex(
            (value) => value.CRT_ID === self.state.leftSideAvalableParentExpandAll[i],
          );
          for (
            let j = 0;
            j < templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length;
            j++
          ) {
            if (
              templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j]
                .isChildChecked
            ) {
              temp_custodian_datasets.push(
                templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets[j],
              );
            }
          }
          if (temp_custodian_datasets && temp_custodian_datasets.length > 0) {
            const { CRT_ID, custodian_Name, custodian_datasets_num } =
              templeftSideAvailableCustodian[leftSideParentCustodianIndex];

            const obj = {
              CRT_ID,
              custodian_Name,
              partial: temp_custodian_datasets.length === custodian_datasets_num,
              custodian_datasets: temp_custodian_datasets,
              custodian_datasets_num,
            };
            temprightSideProcessingCustodianImage.push(obj);
            const newAvaliableCheckedListAll = templeftSideAvailableCustodian[
              leftSideParentCustodianIndex
            ].custodian_datasets.filter((e) => !e.isChildChecked);
            templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets =
              newAvaliableCheckedListAll;

            if (templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length > 1) {
              templeftSideAvailableCustodian[leftSideParentCustodianIndex].partial = false;
            }
            if (!templeftSideAvailableCustodian[leftSideParentCustodianIndex].custodian_datasets.length) {
              templeftSideAvailableCustodian[leftSideParentCustodianIndex].partial = true;
              templeftSideAvailableCustodian.splice(leftSideParentCustodianIndex, 1);
            }
          }
        }
      }

      const self = this;
      const custodianIndex = templeftSideAvailableCustodian.findIndex(
        (value) => value.CRT_ID === self.state.currentSelectedLeftCustodian,
      );

      let expandCustodianObj;

      if (this.state.currentSelectedLeftCustodian) {
        if (!templeftSideAvailableCustodian.length) {
          expandCustodianObj = {
            leftSideAvalableParentExpandAll: [],
            selectedAvailableParentIndex: null,
            selectedAvailableParent: null,
            showUploadKeyword: false,
            leftSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12  customClassForCol-5 b-g-w m-t-5 p-b-15",
            leftsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
            checkedAvaliableLeftSideChild: [],
            currentSelectedLeftCustodian: null,
          };
        } else if (templeftSideAvailableCustodian.length <= leftSideParentCustodianIndex) {
          expandCustodianObj = {
            selectedAvailableParentIndex: templeftSideAvailableCustodian.length - 1,
            selectedAvailableParent:
              templeftSideAvailableCustodian[templeftSideAvailableCustodian.length - 1],
            showUploadKeyword: true,
            leftSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12 customClassForCol-5  b-g-w",
            leftsidecustodiandivstyle: "tot-check tot-check-reducewidth m-l-0 m-r-0 m-0 p-r-0",
            checkedAvaliableLeftSideChild: [],
            currentSelectedLeftCustodian:
              templeftSideAvailableCustodian[templeftSideAvailableCustodian.length - 1].CRT_ID,
          };
        } else if (custodianIndex === -1) {
          const currentSelectedLeftCustodian =
            templeftSideAvailableCustodian[leftSideParentCustodianIndex].CRT_ID;
          const selectedAvailableParentIndex = templeftSideAvailableCustodian.findIndex(
            (custodian) => custodian.CRT_ID === currentSelectedLeftCustodian,
          );

          expandCustodianObj = {
            currentSelectedLeftCustodian,
            selectedAvailableParentIndex,
          };
        }
      }

      temprightSideProcessingCustodianImage.forEach((custodianData) => {
        custodianData.isParentChecked = false;
        custodianData.custodian_datasets.forEach((childDatasets) => (childDatasets.isChildChecked = false));
      });

      this.setState({
        leftSideAvailableCustodian: this.state.leftSideAvaliableSelectAll
          ? []
          : templeftSideAvailableCustodian,
        rightSideProcessingCustodianOriginal: temprightSideProcessingCustodianImage,
        filterAvailableCustodian: templeftSideAvailableCustodian,
        ...expandCustodianObj,
      });
    }
  }

  //CODE FOR REMOVE FROM LEFT SIDE CHILD//
  handleRemove() {
    let temprightSideProcessingCustodianImage = Object.assign(
      [],
      this.state.rightSideProcessingCustodianOriginal,
    );
    let tempLeftSideAvailableCustodian = Object.assign([], this.state.leftSideAvailableCustodian);
    let tempProcessingCheckdListAll = Object.assign([], this.state.processingCheckedListAll);
    let removedElementFromRight = [];
    // ==============CHECKING IF SELECT ALL FROM LEFT SIDE CLICKED OR NOT

    let rightSideParentIndex;
    let leftSideParentCustodianIndex;

    //CASE 1:
    if (this.state.rightSideProcessingSelectAll) {
      if (tempLeftSideAvailableCustodian && tempLeftSideAvailableCustodian.length > 0) {
        for (let i = 0; i < temprightSideProcessingCustodianImage.length; i++) {
          let index = tempLeftSideAvailableCustodian.findIndex(function (data) {
            return data.CRT_ID === temprightSideProcessingCustodianImage[i].CRT_ID;
          });
          if (index === -1) {
            tempLeftSideAvailableCustodian.push(temprightSideProcessingCustodianImage[i]);
          } else {
            //  CHECK IF CHILD ALREADY IS THERE OR NOT
            for (let j = 0; j < temprightSideProcessingCustodianImage[i].custodian_datasets.length; j++) {
              const checkChildIndexInRight = tempLeftSideAvailableCustodian[
                index
              ].custodian_datasets.findIndex(
                (childData) =>
                  childData.DataSetID ===
                  temprightSideProcessingCustodianImage[i].custodian_datasets[j].DataSetID,
              );
              if (checkChildIndexInRight === -1) {
                tempLeftSideAvailableCustodian[index].custodian_datasets.push(
                  temprightSideProcessingCustodianImage[i].custodian_datasets[j],
                );
              }
            }
          }
        }
        tempLeftSideAvailableCustodian.forEach(function (custodianData) {
          custodianData.isParentChecked = false;
          custodianData.partial = true;
          custodianData.custodian_datasets.forEach(function (childDatasets) {
            childDatasets.isChildChecked = false;
          });
        });

        this.setState({
          rightSideProcessingCustodianOriginal: [],
          leftSideAvailableCustodian: tempLeftSideAvailableCustodian,
          rightSideProcessingSelectAll: false,
          processingCheckedListAll: [],
          filterAvailableCustodian: tempLeftSideAvailableCustodian,
          rightSideProcessingParentExpandAll: [],
          selectedProcessingParentIndex: null,
          selectedProcessingParent: null,
          showUploadKeyword1: false,
          rightSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12 customClassForCol-5 b-g-w m-t-5 p-b-15",
          rightsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
          checkedProcessingRightSideChild: [],
          currentSelectedRightCustodian: null,
        });
      } else {
        //=========== THIS COD EXECUTE WEN RIGHT SIDE IS BLANK AND ALL SELECT CHECKBOX CLICKED FOR LEFT SIDE===
        temprightSideProcessingCustodianImage.forEach(function (custodianData) {
          custodianData.isParentChecked = false;
          custodianData.custodian_datasets.forEach(function (childDatasets) {
            childDatasets.isChildChecked = false;
          });
        });
        this.setState({
          rightSideProcessingCustodianOriginal: [],
          leftSideAvailableCustodian: temprightSideProcessingCustodianImage,
          rightSideProcessingSelectAll: false,
          processingCheckedListAll: [],
          filterAvailableCustodian: temprightSideProcessingCustodianImage,
          rightSideProcessingParentExpandAll: [],
          selectedProcessingParentIndex: null,
          selectedProcessingParent: null,
          showUploadKeyword1: false,
          rightSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12 customClassForCol-5 b-g-w m-t-5 p-b-15",
          rightsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
          checkedProcessingRightSideChild: [],
          currentSelectedRightCustodian: null,
        });
      }
    }
    // CASE 2: checkbox to select ALL DATASETS OF ONE CUSTODIAN
    if (this.state.processingCheckedListAll.length > 0 && this.state.rightSideProcessingSelectAll !== true) {
      let expandCustodianObj;

      for (let i = 0; i < this.state.processingCheckedListAll.length; i++) {
        const self = this;
        rightSideParentIndex = tempLeftSideAvailableCustodian.findIndex(
          (data) => data.CRT_ID === self.state.processingCheckedListAll[i],
        );

        // CASE: when PART of custodian's datasets are already on RIGHT SIDE
        if (rightSideParentIndex !== -1) {
          const self = this;
          leftSideParentCustodianIndex = temprightSideProcessingCustodianImage.findIndex(
            (value) => value.CRT_ID === self.state.processingCheckedListAll[i],
          );
          if (temprightSideProcessingCustodianImage[leftSideParentCustodianIndex]) {
            for (
              let j = 0;
              j <
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets.length;
              j++
            ) {
              const checkChildIndexInRight = tempLeftSideAvailableCustodian[
                rightSideParentIndex
              ].custodian_datasets.findIndex(
                (childData) =>
                  childData.DataSetID ===
                  temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j]
                    .DataSetID,
              );

              if (checkChildIndexInRight === -1) {
                tempLeftSideAvailableCustodian[rightSideParentIndex].custodian_datasets.push(
                  temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j],
                );
              }
            }
            temprightSideProcessingCustodianImage.splice(leftSideParentCustodianIndex, 1);
            removedElementFromRight.push(this.state.processingCheckedListAll[i]);
          }
        }
        // CASE: when NONE of custodian's datasets are on RIGHT SIDE
        else {
          const self = this;
          leftSideParentCustodianIndex = temprightSideProcessingCustodianImage.findIndex(
            (value) => value.CRT_ID === self.state.processingCheckedListAll[i],
          );
          tempLeftSideAvailableCustodian.push(
            temprightSideProcessingCustodianImage[leftSideParentCustodianIndex],
          );
          temprightSideProcessingCustodianImage.splice(leftSideParentCustodianIndex, 1);
          removedElementFromRight.push(this.state.processingCheckedListAll[i]);
        }
      }

      const self = this;
      const custodianIndex = temprightSideProcessingCustodianImage.findIndex(
        (value) => value.CRT_ID === self.state.currentSelectedRightCustodian,
      );

      //must be selected current custodian to expand and make his datasets visible
      //below are cases related to already clicked expand arrow
      if (this.state.currentSelectedRightCustodian) {
        //CASE 1: if we had custodian with expand datasets
        //but we moved him to the left side
        //and now there is no custodian left on the right side
        if (!temprightSideProcessingCustodianImage.length) {
          expandCustodianObj = {
            rightSideProcessingParentExpandAll: [],
            selectedProcessingParentIndex: null,
            selectedProcessingParent: null,
            showUploadKeyword1: false,
            rightSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12 customClassForCol-5 b-g-w m-t-5 p-b-15",
            rightsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
            checkedProcessingRightSideChild: [],
            currentSelectedRightCustodian: null,
          };
          //CASE 2: if we had custodian with expand datasets
          //and we moved him to the left side
          //but he was on the buttom of the list
        } else if (temprightSideProcessingCustodianImage.length <= leftSideParentCustodianIndex) {
          expandCustodianObj = {
            selectedProcessingParentIndex: temprightSideProcessingCustodianImage.length - 1,
            selectedProcessingParent:
              temprightSideProcessingCustodianImage[temprightSideProcessingCustodianImage.length - 1],
            showUploadKeyword1: true,
            rightSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12  customClassForCol-5 b-g-w",
            rightsidecustodiandivstyle: "tot-check tot-check-reducewidth m-l-0 m-r-0 m-0 p-r-0",
            checkedProcessingRightSideChild: [],
            currentSelectedRightCustodian:
              temprightSideProcessingCustodianImage[temprightSideProcessingCustodianImage.length - 1].CRT_ID,
          };
          //CASE 3: if we had custodian with expand datasets
          //and we moved him to the left side
        } else if (custodianIndex === -1) {
          expandCustodianObj = {
            currentSelectedRightCustodian:
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].CRT_ID,
          };
          //CASE 4: if we had custodian with expand datasets
          //and we moved ANOTHER custodian to the left side
        } else {
          const index = temprightSideProcessingCustodianImage.findIndex(
            (custodian) => custodian.CRT_ID === this.state.currentSelectedRightCustodian,
          );
          expandCustodianObj = { selectedProcessingParentIndex: index };
        }
      }
      tempLeftSideAvailableCustodian.forEach(function (custodianData) {
        custodianData.isParentChecked = false;
        custodianData.partial = true;
        custodianData.custodian_datasets.forEach(function (childDatasets) {
          childDatasets.isChildChecked = false;
        });
      });
      //  WRITING LOGIC FOR CHECKBOX ARRAY CONSISTENT

      const newProcessingCheckedListAll = tempProcessingCheckdListAll.filter(
        (e) => removedElementFromRight.indexOf(e) === -1,
      );

      this.setState({
        rightSideProcessingCustodianOriginal: temprightSideProcessingCustodianImage,
        leftSideAvailableCustodian: tempLeftSideAvailableCustodian,
        processingCheckedListAll: newProcessingCheckedListAll,
        filterAvailableCustodian: tempLeftSideAvailableCustodian,
        selectedProcessingParentIndex: leftSideParentCustodianIndex,
        ...expandCustodianObj,
      });
    }
    // CASE 3: USE "EXPAND ARROW" to select EACH DATASET OF CUSTODIAN
    if (
      this.state.rightSideProcessingParentExpandAll &&
      this.state.rightSideProcessingParentExpandAll.length > 0 &&
      !this.state.rightSideProcessingSelectAll &&
      !this.state.processingCheckedListAll.length
    ) {
      let expandCustodianObj;

      for (let i = 0; i < this.state.rightSideProcessingParentExpandAll.length; i++) {
        const self = this;
        rightSideParentIndex = tempLeftSideAvailableCustodian.findIndex(function (data) {
          return data.CRT_ID === self.state.rightSideProcessingParentExpandAll[i];
        });

        // CASE: when PART of custodian's datasets are already on RIGHT SIDE
        if (rightSideParentIndex !== -1) {
          const self = this;
          leftSideParentCustodianIndex = temprightSideProcessingCustodianImage.findIndex(
            (value) => value.CRT_ID === self.state.rightSideProcessingParentExpandAll[i],
          );

          if (temprightSideProcessingCustodianImage[leftSideParentCustodianIndex]) {
            for (
              let j = 0;
              j <
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets.length;
              j++
            ) {
              const checkChildIndexInRight = tempLeftSideAvailableCustodian[
                rightSideParentIndex
              ].custodian_datasets.findIndex(
                (childData) =>
                  childData.DataSetID ===
                  temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j]
                    .DataSetID,
              );
              if (checkChildIndexInRight === -1) {
                if (
                  temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j]
                    .isChildChecked
                ) {
                  tempLeftSideAvailableCustodian[rightSideParentIndex].custodian_datasets.push(
                    temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j],
                  );
                }
              }
            }
            const newAvaliableCheckedListAll = temprightSideProcessingCustodianImage[
              leftSideParentCustodianIndex
            ].custodian_datasets.filter((e) => !e.isChildChecked);
            temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets =
              newAvaliableCheckedListAll;
            if (
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets.length >
              1
            ) {
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].partial = false;
            }
            if (
              !temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets.length
            ) {
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].partial = true;
              tempLeftSideAvailableCustodian[rightSideParentIndex].partial = true;
              temprightSideProcessingCustodianImage.splice(leftSideParentCustodianIndex, 1);
            }
          }
        }
        // CASE: when NONE of custodian's datasets are on RIGHT SIDE
        else {
          const temp_custodian_datasets = [];
          const self = this;
          leftSideParentCustodianIndex = temprightSideProcessingCustodianImage.findIndex(
            (value) => value.CRT_ID === self.state.rightSideProcessingParentExpandAll[i],
          );

          for (
            let j = 0;
            j < temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets.length;
            j++
          ) {
            if (
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j]
                .isChildChecked
            ) {
              temp_custodian_datasets.push(
                temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets[j],
              );
            }
          }

          if (temp_custodian_datasets.length > 0) {
            const { CRT_ID, custodian_Name, custodian_datasets_num } =
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex];

            const obj = {
              CRT_ID,
              custodian_Name,
              partial: temp_custodian_datasets.length === custodian_datasets_num,
              custodian_datasets: temp_custodian_datasets,
              custodian_datasets_num,
            };
            tempLeftSideAvailableCustodian.push(obj);

            const newAvaliableCheckedListAll = temprightSideProcessingCustodianImage[
              leftSideParentCustodianIndex
            ].custodian_datasets.filter((e) => !e.isChildChecked);
            temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets =
              newAvaliableCheckedListAll;
            if (
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets.length >
              1
            ) {
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].partial = false;
            }
            if (
              !temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].custodian_datasets.length
            ) {
              temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].partial = true;
              temprightSideProcessingCustodianImage.splice(leftSideParentCustodianIndex, 1);
            }
          }
        }
      }

      const self = this;
      const custodianIndex = temprightSideProcessingCustodianImage.findIndex(
        (value) => value.CRT_ID === self.state.currentSelectedRightCustodian,
      );

      if (this.state.currentSelectedRightCustodian) {
        if (!temprightSideProcessingCustodianImage.length) {
          expandCustodianObj = {
            rightSideProcessingParentExpandAll: [],
            selectedProcessingParentIndex: null,
            selectedProcessingParent: null,
            showUploadKeyword1: false,
            rightSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12 customClassForCol-5 b-g-w m-t-5 p-b-15",
            rightsidecustodiandivstyle: "tot-check m-l-0 m-r-0 m-0 p-r-0",
            checkedProcessingRightSideChild: [],
            currentSelectedRightCustodian: null,
          };
        } else if (temprightSideProcessingCustodianImage.length <= leftSideParentCustodianIndex) {
          expandCustodianObj = {
            selectedProcessingParentIndex: temprightSideProcessingCustodianImage.length - 1,
            selectedProcessingParent:
              temprightSideProcessingCustodianImage[temprightSideProcessingCustodianImage.length - 1],
            showUploadKeyword1: true,
            rightSideWidtBeforeClick: "col-sm-5 col-md-5 col-xs-12  customClassForCol-5 b-g-w",
            rightsidecustodiandivstyle: "tot-check tot-check-reducewidth m-l-0 m-r-0 m-0 p-r-0",
            checkedProcessingRightSideChild: [],
            currentSelectedRightCustodian:
              temprightSideProcessingCustodianImage[temprightSideProcessingCustodianImage.length - 1].CRT_ID,
          };
        } else if (custodianIndex === -1) {
          const currentSelectedRightCustodian =
            temprightSideProcessingCustodianImage[leftSideParentCustodianIndex].CRT_ID;
          const selectedProcessingParentIndex = temprightSideProcessingCustodianImage.findIndex(
            (custodian) => custodian.CRT_ID === currentSelectedRightCustodian,
          );

          expandCustodianObj = {
            currentSelectedRightCustodian,
            selectedProcessingParentIndex,
          };
        }
      }

      tempLeftSideAvailableCustodian.forEach(function (custodianData) {
        custodianData.isParentChecked = false;
        custodianData.custodian_datasets.forEach(function (childDatasets) {
          childDatasets.isChildChecked = false;
        });
      });
      this.setState({
        rightSideProcessingCustodianOriginal: this.state.rightSideProcessingSelectAll
          ? []
          : temprightSideProcessingCustodianImage,
        leftSideAvailableCustodian: tempLeftSideAvailableCustodian,
        filterAvailableCustodian: tempLeftSideAvailableCustodian,
        ...expandCustodianObj,
      });
    }
  }
  avaliableCustodianOnChange(dataSets, event) {
    let templeftSideAvailableCustodian = JSON.parse(JSON.stringify(this.state.leftSideAvailableCustodian));
    let leftsideParentIndex =
      templeftSideAvailableCustodian &&
      templeftSideAvailableCustodian.findIndex(function (custodianData) {
        return dataSets.CRT_ID === custodianData.CRT_ID;
      });
    if (event.target.checked) {
      if (leftsideParentIndex !== -1) {
        templeftSideAvailableCustodian[leftsideParentIndex].isParentChecked = true;
        templeftSideAvailableCustodian[leftsideParentIndex].custodian_datasets.forEach(function (
          childDatasets,
        ) {
          childDatasets.isChildChecked = true;
        });
      }
      //================== MARKED SELECT ALL CHECKED IF ALL PARENT CHECKBOX WITH CHECKED=================================

      this.setState(
        (prevState) => {
          return {
            avaliableCheckedListAll: [...prevState.avaliableCheckedListAll, dataSets.CRT_ID],
            leftSideAvailableCustodian: templeftSideAvailableCustodian,
          };
        },
        () => {
          if (templeftSideAvailableCustodian.length === this.state.avaliableCheckedListAll.length) {
            this.setState({
              leftSideAvaliableSelectAll: true,
            });
          }
        },
      );
    } else {
      if (leftsideParentIndex !== -1) {
        templeftSideAvailableCustodian[leftsideParentIndex].isParentChecked = false;
        templeftSideAvailableCustodian[leftsideParentIndex].custodian_datasets.forEach(function (
          childDatasets,
        ) {
          childDatasets.isChildChecked = false;
        });
      }
      let tempavailableChecked = this.state.avaliableCheckedListAll;
      let avalibaleCheckedIndex = tempavailableChecked.findIndex(function (data) {
        return parseInt(event.target.value) === data;
      });
      tempavailableChecked.splice(avalibaleCheckedIndex, 1);

      if (avalibaleCheckedIndex !== -1) {
        this.setState({
          avaliableCheckedListAll: tempavailableChecked,
        });
      }
      this.setState({
        leftSideAvailableCustodian: templeftSideAvailableCustodian,
        leftSideAvaliableSelectAll: false,
      });
    }
  }
  //====================CODE FOR WHEN WE CHECKED SELECT ALL AVAILABLE CUSTODIAN DATA ====================================

  selectAllAvailable(event) {
    if (event.target.checked) {
      //  CALLING COMMON FUNCTION BASED ON EVENT ===========
      this.setParentChildCheckBox(
        event.target.checked,
        JSON.parse(JSON.stringify(this.state.leftSideAvailableCustodian)),
        "left",
      );
    } else {
      // HERE ALSO CALLING COMMON FUNCTION BASED ON EVENT TARGET VALUE===============
      this.setParentChildCheckBox(
        event.target.checked,
        JSON.parse(JSON.stringify(this.state.leftSideAvailableCustodian)),
        "left",
      );
    }
  }
  //=================== COMMON FUNCTION FOR SET RESET CHECKBOX BASED ON CHECKED TRUE OR FALSE==================
  setParentChildCheckBox(eventValue, datasourceValue, boxSide) {
    let tempAvaliableCheckedListAll = this.state.avaliableCheckedListAll;
    let tempProcessingCheckedListAll = this.state.processingCheckedListAll;
    let templeftSideAvailableCustodian = datasourceValue;
    templeftSideAvailableCustodian &&
      templeftSideAvailableCustodian.forEach(function (custodianData) {
        custodianData.isParentChecked = eventValue;
        if (eventValue) {
          if (boxSide === "left") {
            tempAvaliableCheckedListAll.push(custodianData.CRT_ID);
          }
          if (boxSide === "right") {
            tempProcessingCheckedListAll.push(custodianData.CRT_ID);
          }
        }
        custodianData.custodian_datasets.forEach(function (childDatasets) {
          childDatasets.isChildChecked = eventValue;
        });
      });
    if (boxSide === "left") {
      this.setState({
        leftSideAvailableCustodian: templeftSideAvailableCustodian,
        leftSideAvaliableSelectAll: eventValue,
        avaliableCheckedListAll: eventValue === false ? [] : tempAvaliableCheckedListAll,
      });
    }
    if (boxSide === "right") {
      this.setState({
        rightSideProcessingCustodianOriginal: templeftSideAvailableCustodian,
        rightSideProcessingSelectAll: eventValue,
        processingCheckedListAll: eventValue === false ? [] : this.state.processingCheckedListAll,
      });
    }
  }

  processingCustodianOnChange(dataSets, event) {
    let tempRightSideProcessingCustodianOriginal = JSON.parse(
      JSON.stringify(this.state.rightSideProcessingCustodianOriginal),
    );
    let rightSideParentIndex =
      tempRightSideProcessingCustodianOriginal &&
      tempRightSideProcessingCustodianOriginal.findIndex(function (custodianData) {
        return dataSets.CRT_ID === custodianData.CRT_ID;
      });
    if (event.target.checked) {
      if (rightSideParentIndex !== -1) {
        tempRightSideProcessingCustodianOriginal[rightSideParentIndex].isParentChecked = true;
        tempRightSideProcessingCustodianOriginal[rightSideParentIndex].custodian_datasets.forEach(function (
          childDatasets,
        ) {
          childDatasets.isChildChecked = true;
        });
      }
      //================== MARKED SELECT ALL CHECKED IF ALL PARENT CHECKBOX WITH CHECKED=================================

      this.setState(
        (prevState) => ({
          processingCheckedListAll: [...prevState.processingCheckedListAll, dataSets.CRT_ID],
          rightSideProcessingCustodianOriginal: tempRightSideProcessingCustodianOriginal,
        }),
        () => {
          if (
            tempRightSideProcessingCustodianOriginal.length === this.state.processingCheckedListAll.length
          ) {
            this.setState({
              rightSideProcessingSelectAll: true,
            });
          }
        },
      );
    } else {
      if (rightSideParentIndex !== -1) {
        tempRightSideProcessingCustodianOriginal[rightSideParentIndex].isParentChecked = false;
        tempRightSideProcessingCustodianOriginal[rightSideParentIndex].custodian_datasets.forEach(function (
          childDatasets,
        ) {
          childDatasets.isChildChecked = false;
        });
      }
      let tempavailableChecked = this.state.processingCheckedListAll;
      let avalibaleCheckedIndex = tempavailableChecked.findIndex(function (data) {
        return parseInt(event.target.value) === data;
      });
      tempavailableChecked.splice(avalibaleCheckedIndex, 1);

      if (avalibaleCheckedIndex !== -1) {
        this.setState({
          processingCheckedListAll: tempavailableChecked,
        });
      }
      this.setState({
        rightSideProcessingCustodianOriginal: tempRightSideProcessingCustodianOriginal,
        rightSideProcessingSelectAll: false,
      });
    }
  }

  //====================CODE FOR WHEN WE CHECKED SELECT ALL PROCESSING CUSTODIAN DATA ====================================

  selectAllProcessingAvailable(event) {
    if (event.target.checked) {
      //  CALLING COMMON FUNCTION BASED ON EVENT ===========
      this.setParentChildCheckBox(
        event.target.checked,
        JSON.parse(JSON.stringify(this.state.rightSideProcessingCustodianOriginal)),
        "right",
      );
    } else {
      // HERE ALSO CALLING COMMON FUNCTION BASED ON EVENT TARGET VALUE===============
      this.setParentChildCheckBox(
        event.target.checked,
        JSON.parse(JSON.stringify(this.state.rightSideProcessingCustodianOriginal)),
        "right",
      );
    }
  }

  //=============================CODE FOR FILTER CUSTODIAN BASED ON CUSTODIAN NAME===========================//
  filterAvailableCustodian(label, value) {
    // WRITTING JSON.parse and JSON.stringify bcoz it will not change original array.
    let templeftSideAvailableCustodian = JSON.parse(JSON.stringify(this.state.filterAvailableCustodian));
    if (value && value !== "") {
      let CustodianFilterdList =
        templeftSideAvailableCustodian &&
        templeftSideAvailableCustodian.filter((el) =>
          el["custodian_Name"].toLowerCase().includes(value.toLowerCase()),
        );
      this.setState({
        leftSideAvailableCustodian: CustodianFilterdList,
      });
    } else {
      this.setState({
        leftSideAvailableCustodian: this.state.filterAvailableCustodian,
      });
    }
  }

  //================================= CODE FOR WEN SOMEONE CLICK ON RUN PROCESSING AFTER ADDING CUSTODIAN AND CHOOSING PROFILE HERE LOGIC FOR SAVE PROCESSING DATA===========================
  async runProcessing() {
    const { formGotClosed } = this.context;
    let tempSaveProcessingData = JSON.parse(JSON.stringify(this.state.rightSideProcessingCustodianOriginal));
    let tempObj = [];
    for (let i = 0; i < tempSaveProcessingData.length; i++) {
      for (let j = 0; j < tempSaveProcessingData[i].custodian_datasets.length; j++) {
        let obj = {
          PROJECT_ID: this.props.selectedProjectId,
          CUSTODIAN_ID: tempSaveProcessingData[i].CRT_ID,
          DATASET_ID: tempSaveProcessingData[i].custodian_datasets[j].DataSetID,
          PROFILE_ID: this.state.selectedProfileId,
          COLLECTION_STATUS: 1,
          PROCESSING_STATUS: 1,
          CLIENT_ID: 1,
        };
        tempObj.push(obj);
      }
    }
    const processingService = new ProcessingService();
    await processingService
      .saveProcessing(tempObj)
      .then((data) => {
        this.props.showlistProcessing();
        formGotClosed();
      })
      .catch((error) => {
        displayToastMessage(error.response.data, "error");
      });
  }

  //================================ CODE FOR PROFILE ON CHANGE FROM PROFILE SELECTION DROPDOWN=========================
  onChangeProfile(data) {
    this.setState(
      {
        selectedProfileId: parseInt(data),
      },
      () => {
        if (
          this.state.rightSideProcessingCustodianOriginal &&
          this.state.rightSideProcessingCustodianOriginal.length > 0 &&
          this.state.selectedProfileId
        ) {
          this.setState({
            allowRunProcessing: true,
          });
        } else {
          this.setState({
            allowRunProcessing: false,
          });
        }
      },
    );
  }

  // Close and redirect form
  closeFormAndRedirect = () => {
    try {
      const { formGotClosed } = this.context;
      // close the popup
      this.toggleConfirmBox();
      // mark the form is closed on the store
      formGotClosed();
      // close custodian form
      this.props.showlistProcessing();
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  toggleConfirmBox = () => {
    this.setState({
      openConfirmBox: !this.state.openConfirmBox,
    });
  };

  //=======================BACK BUTTON FUNCTIONALITY===================================
  onBackButtonClick() {
    try {
      const { isFormOpen, formGotClosed } = this.context;
      if (isFormOpen) {
        // values of the form got changed - then show confirm box
        this.setState({ openConfirmBox: true });
      } else {
        // if form is not considered as open(values of the form are not changed yet), then close the form
        this.props.showlistProcessing();
        formGotClosed();
        this.setState({ openConfirmBox: false });
      }
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  }
  //=================== FUNCTION FOR ENABLE AND DISABLE REMOVE BUTTON BASED ON CONDITION
  shouldremoveButtonDisabled() {
    let returnValue = this.enableAndDisbaleAddRemoveButton(
      this.state.rightSideProcessingCustodianOriginal,
      this.state.processingCheckedListAll,
    );
    return returnValue;
  }
  // ====================FUNCTION FOR ENABLE AND DISABLE ADD  BUTTON BASED ON CONDITION
  shouldAddButtonDisabled() {
    let returnValue = this.enableAndDisbaleAddRemoveButton(
      this.state.leftSideAvailableCustodian,
      this.state.avaliableCheckedListAll,
    );
    return returnValue;
  }
  //============ COMMON FUNCTION FOR DISBALE BOTH BUTTON ADD OR REMOVE BASED ON CONDITION==============
  enableAndDisbaleAddRemoveButton(dataSourceForCustodian, checkListAll) {
    let templeftSideAvailableCustodian = dataSourceForCustodian;
    var leftSideAvalableCheckox = false;
    if (templeftSideAvailableCustodian && templeftSideAvailableCustodian.length > 0) {
      for (let i = 0; i < templeftSideAvailableCustodian.length; i++) {
        if (
          templeftSideAvailableCustodian[i].custodian_datasets &&
          templeftSideAvailableCustodian[i].custodian_datasets.length > 0
        ) {
          for (let j = 0; j < templeftSideAvailableCustodian[i].custodian_datasets.length; j++) {
            if (templeftSideAvailableCustodian[i].custodian_datasets[j].isChildChecked === true) {
              leftSideAvalableCheckox = true;
              break;
            }
          }
        }
      }
    }
    if ((checkListAll && checkListAll.length > 0) || leftSideAvalableCheckox) {
      return false;
    } else {
      return true;
    }
  }
  // ==================== CODE FOR DISABLED RUN PROCESSING BUTTON BASED ON CONDITION IF PROFILE AND CUSTODIAN AVAILABLE THEN I HAVE TO SHOW ENABLE ================
  shouldRunProcessingButtonDisabled() {
    if (
      this.state.rightSideProcessingCustodianOriginal &&
      this.state.rightSideProcessingCustodianOriginal.length > 0 &&
      this.state.selectedProfileId
    ) {
      return false;
    } else {
      return true;
    }
  }
  showModal = () => {
    this.setState({
      visibleTermsModel: true,
    });
  };

  handleConfirm = (e) => {
    this.setState({
      visibleTermsModel: false,
    });
  };

  render() {
    var polyglot = polyglotLoader();
    return (
      <div className="p-0" style={{ width: "100%" }}>
        <div className="row col-lg-10 pl-0">
          <div className="col-md-3 col-lg-3">
            <ButtonPrimary
              onClick={this.runProcessing.bind()}
              disabled={this.shouldRunProcessingButtonDisabled()}
              ariaLabel={polyglot.t("Start processing")}
            >
              {polyglot.t("Start processing")}
            </ButtonPrimary>
          </div>

          <div className="col-md-3 col-lg-3" aria-label={polyglot.t("Back")}>
            <BackButton
              onBackButtonClick={this.onBackButtonClick}
              buttonText={polyglot.t("Back")}
            ></BackButton>
          </div>
        </div>
        <div className="row m-l-0 m-r-0">
          <h1 className="col-md-3 p-0 mt-3  title m-l-0" style={{ textAlign: "left", marginLeft: "14px" }}>
            {" "}
            {polyglot.t("Processing")}
          </h1>
        </div>
        <div className="row m-l-0 m-r-0 ">
          <div
            className="m-auto-top"
            aria-label={polyglot.t(
              "Please select the custodians, datasets and processing profile you would like to use.",
            )}
          >
            {polyglot.t(
              "Please select the custodians, datasets and processing profile you would like to use.",
            )}
          </div>
          <ButtonIcon
            className="m-l-1-p"
            onClick={() => this.showModal()}
            iconName={infoIcon}
            alt="info"
            ariaLabel={polyglot.t("More info")}
            title={polyglot.t("More info")}
          />
        </div>
        {/*=========================== CODE FOR TWO BOXES LEFT AND RIGHT=================== */}
        <div className="row mainProcessingDiv m-l-0-1" aria-label={polyglot.t("Available data")}>
          <div className={this.state.leftSideWidtBeforeClick}>
            <div className="text-Avl-two">
              {/* <span className="text-Avl-two">{polyglot.t("Available data")}</span> */}
              {polyglot.t("Available data")}
            </div>
            <div
              className={`row searchBoxProcessingDiv m-l-0 m-r-0 ${
                this.state.leftSideAvailableCustodian &&
                this.state.leftSideAvailableCustodian.length === 0 &&
                "mb-2"
              }`}
            >
              <div className="pod-title pt-0" style={{ width: "100%" }}>
                <SearchBar
                  labelClass={"full-width"}
                  role="presentation"
                  aria-label="Search for custodian"
                  nameOfSearch={"custodian"}
                  customClass={"availableProcessingCustodian"}
                  id="search"
                  name="serach_text"
                  val={this.state.search_text}
                  onChange={this.filterAvailableCustodian.bind(this)}
                />
              </div>
            </div>
            <div className="row selectAllLeftSide m-l-0 m-r-0">
              {this.state.leftSideAvailableCustodian && this.state.leftSideAvailableCustodian.length > 0 && (
                <div className="selectAllDiv">
                  <Checkbox
                    name="select_All_Avaliable"
                    checked={this.state.leftSideAvaliableSelectAll}
                    onClick={this.selectAllAvailable.bind(this)}
                    className="check-list-one"
                  >
                    {polyglot.t("Select All")}
                  </Checkbox>
                </div>
              )}
            </div>
            <div className={this.state.leftsidecustodiandivstyle}>
              {this.state.leftSideAvailableCustodian &&
                this.state.leftSideAvailableCustodian.map((element, index) => {
                  const currentSelectedLeftCustodian = this.state.currentSelectedLeftCustodian;
                  const haveAllDatasets =
                    element.custodian_datasets.length === element.custodian_datasets_num;

                  return (
                    <div className="process-selct-data " key={index}>
                      <Checkbox
                        role="presentation"
                        name="CRT_FIRST_NAME"
                        key={element.CRT_ID}
                        value={element.CRT_ID}
                        aria-label={element.CRT_ID}
                        checked={element.isParentChecked}
                        onChange={this.avaliableCustodianOnChange.bind(this, element)}
                        className="check-list-one"
                      ></Checkbox>
                      <div
                        className="custo_name"
                        aria-label={element.custodian_Name}
                        title={element.custodian_Name}
                        style={{
                          fontWeight: element.CRT_ID === currentSelectedLeftCustodian ? "bold" : "normal",
                          maxWidth: !haveAllDatasets ? "47%" : "85%",
                        }}
                      >
                        {element.custodian_Name}
                      </div>
                      {!haveAllDatasets && (
                        <div
                          style={{
                            minWidth: "max-content",
                            margin: "0 15px",
                            color: "#d96e34",
                            fontWeight: "bold",
                          }}
                        >
                          {`${element.custodian_datasets.length} of total ${element.custodian_datasets_num} datasets remaining`}
                        </div>
                      )}
                      {this.state.showUploadKeyword === true && (
                        <span
                          className="customArrow2"
                          onClick={this.expandAvailableParent.bind(this, element, index)}
                        ></span>
                      )}
                      {this.state.showUploadKeyword !== true && (
                        <span
                          className="customArrow"
                          onClick={this.expandAvailableParent.bind(this, element, index)}
                        ></span>
                      )}
                    </div>
                  );
                })}
            </div>
            {this.state.showUploadKeyword ? (
              <div className="check-sublist-one">
                <div className="mb-4 mt-1">
                  <div>
                    <div className="tot-check-child-two">
                      {this.state.selectedAvailableParentIndex != null &&
                        this.state.leftSideAvailableCustodian.length > 0 &&
                        this.state.leftSideAvailableCustodian[this.state.selectedAvailableParentIndex] &&
                        this.state.leftSideAvailableCustodian[this.state.selectedAvailableParentIndex]
                          .custodian_datasets.length > 0 &&
                        this.state.leftSideAvailableCustodian[
                          this.state.selectedAvailableParentIndex
                        ].custodian_datasets.map((element, index) => {
                          return (
                            <div className="process-selct-data" key={index}>
                              <Checkbox
                                name="CRT_FIRST_NAME"
                                onChange={(e) => this.selectAvaliableCustodianChild(e, element)}
                                key={element.DataSetID}
                                value={element.DataSetID}
                                aria-label={element.DataSetID}
                                checked={element.isChildChecked}
                              ></Checkbox>
                              <div
                                className="custo_name"
                                aria-label={element.DataSetName}
                                title={element.DataSetName}
                              >
                                {element.DataSetName}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          {/*====================CODE FOR MIDDLE BOX DIV=================  */}
          <div className="col-sm-2 col-md-2 add-remove-button center-align-content">
            <button
              id="add-btn"
              disabled={this.shouldAddButtonDisabled()}
              className={
                this.shouldAddButtonDisabled()
                  ? "ProcessingAddBtnDisable no-pointer b-color"
                  : "ProcessingAddBtn cursor-pointer "
              }
              onClick={this.handleAdd.bind(this)}
            >
              {polyglot.t("Add")}
              {this.shouldAddButtonDisabled() ? (
                <img class="p-l-7" src={rightBlackIcon} alt="" />
              ) : (
                <img className="p-l-7" src={rightNormalIcon} alt="" />
              )}
            </button>
            <br />
            <br />
            <button
              disabled={this.shouldremoveButtonDisabled()}
              className={
                this.shouldremoveButtonDisabled()
                  ? "ProcessingAddBtnDisable no-pointer b-color"
                  : "ProcessingAddBtn cursor-pointer "
              }
              id="remove-btn"
              onClick={this.handleRemove.bind(this)}
            >
              {this.shouldremoveButtonDisabled() ? (
                <img class="p-r-7 p-b-1" src={leftBlackIcon} alt="" />
              ) : (
                <img class="p-r-7 p-b-1" src={leftNormalIcon} alt="" />
              )}
              {polyglot.t("Remove")}
            </button>
          </div>
          {/*======================== CODE FOR RIGHT SIDE BOXES====================== */}
          <div className={this.state.rightSideWidtBeforeClick}>
            <div
              className={`pod-title ${
                this.state.rightSideProcessingCustodianOriginal.length === 0 && "mb-2"
              }`}
            >
              <div className="text-Avl-two">{polyglot.t("Processing profile")}</div>
              <Select
                placeholder={polyglot.t("Choose processing profile")}
                onSelect={(e) => this.onChangeProfile(e)}
              >
                {this.state.propfileDropdownValue &&
                  this.state.propfileDropdownValue.length > 0 &&
                  this.state.propfileDropdownValue.map((data) => {
                    return (
                      <SelectOption key={data.value} value={data.value}>
                        {data.label}
                      </SelectOption>
                    );
                  })}
              </Select>
            </div>
            {this.state.rightSideProcessingCustodianOriginal &&
              this.state.rightSideProcessingCustodianOriginal.length > 0 && (
                <div className="selectAllDiv p-l-0">
                  <Checkbox
                    name="select_All_Avaliable"
                    className="check-list-one"
                    onClick={this.selectAllProcessingAvailable.bind(this)}
                    checked={this.state.rightSideProcessingSelectAll}
                  >
                    {" "}
                    {polyglot.t("Select All")}
                  </Checkbox>
                </div>
              )}

            <div className={this.state.rightsidecustodiandivstyle}>
              {this.state.rightSideProcessingCustodianOriginal &&
                this.state.rightSideProcessingCustodianOriginal.length > 0 &&
                this.state.rightSideProcessingCustodianOriginal.map((element, index) => {
                  const datasetInflection = element.custodian_datasets_num === 1 ? "dataset" : "datasets";
                  const currentSelectedRightCustodian = this.state.currentSelectedRightCustodian;
                  return (
                    <div className="process-selct-data " key={index}>
                      <Checkbox
                        name="CRT_FIRST_NAME"
                        key={index}
                        value={element.CRT_ID}
                        aria-label={element.CRT_ID}
                        checked={element.isParentChecked}
                        onChange={this.processingCustodianOnChange.bind(this, element)}
                        className="check-list-one"
                      ></Checkbox>
                      <div
                        className="custo_name"
                        aria-label={element.custodian_Name}
                        title={element.custodian_Name}
                        style={{
                          fontWeight: element.CRT_ID === currentSelectedRightCustodian ? "bold" : "normal",
                        }}
                      >
                        {element.custodian_Name}
                      </div>
                      <div
                        style={{
                          minWidth: "max-content",
                          margin: "0 15px",
                          color: "#d96e34",
                          fontWeight: "bold",
                        }}
                      >
                        {`${element.custodian_datasets.length} of ${element.custodian_datasets_num} ${datasetInflection} selected`}
                      </div>
                      <span
                        className="customArrow2"
                        onClick={this.expandProcessingParent.bind(this, element, index)}
                      ></span>
                    </div>
                  );
                })}
            </div>

            {this.state.showUploadKeyword1 ? (
              <div className="check-sublist-one">
                <div className="mb-4 mt-1">
                  <div>
                    <div className="tot-check-child-two">
                      {this.state.selectedProcessingParent !== null &&
                        this.state.rightSideProcessingCustodianOriginal.length > 0 &&
                        this.state.rightSideProcessingCustodianOriginal[
                          this.state.selectedProcessingParentIndex
                        ].custodian_datasets &&
                        this.state.rightSideProcessingCustodianOriginal[
                          this.state.selectedProcessingParentIndex
                        ].custodian_datasets.map((element, index) => {
                          return (
                            <div className="process-selct-data " key={index}>
                              <Checkbox
                                name="CRT_FIRST_NAME"
                                key={element.DataSetID}
                                value={element.DataSetID}
                                aria-label={element.DataSetID}
                                onChange={(e) => this.selectProcessingCustodianChild(e, element)}
                                checked={element.isChildChecked}
                              ></Checkbox>
                              <div
                                className="custo_name"
                                title={element.DataSetName}
                                aria-label={element.DataSetName}
                              >
                                {element.DataSetName}
                              </div>
                            </div>
                          );
                        })}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <CustomModal
          show={this.state.visibleTermsModel}
          onCancel={this.handleConfirm}
          title={polyglot.t("Processing")}
        >
          <div className="row m-l-0 m-r-0 ">
            <div className="col-12 p-0">
              <div>
                <p className="para-txt w-b-b-a word-break">
                  {polyglot.t(
                    "In the dropdown of the Processing profile, please select the Processing Profile you want to use.",
                  )}
                </p>
                <p className="para-txt w-b-b-a word-break ">
                  {polyglot.t(
                    "Please add data you want to include into this processing run by selecting the Dataset(s) and clicking ",
                  )}
                  <span className="para-head">{polyglot.t("Add")}</span> &nbsp;
                  {polyglot.t("klicken")}
                </p>
                <p className="para-txt w-b-b-a word-break">
                  {polyglot.t("Once you've added all the desired information, please click on ")}
                  <span className="para-head">{polyglot.t("Start processing ")}</span>
                  {" " + polyglot.t("to start the upload process")}.
                </p>
              </div>
            </div>
          </div>
        </CustomModal>
        {/* show confirm box if the form is open */}
        {this.state.openConfirmBox && (
          <ConfirmBoxExitForm
            show={this.state.openConfirmBox}
            heading={"Cancel update"}
            cancel={this.toggleConfirmBox}
            proceed={this.closeFormAndRedirect}
          />
        )}
      </div>
    );
  }
}

export default CreateProcessing;
