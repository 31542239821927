export const storeLoginTimeStampBeforeJWT = () => {
  sessionStorage.setItem("loginTimeStamp_BeforeJWT", new Date().toLocaleString());
};

export const startSessionTimerBeforeGeneratingJWT = () => {
  const loginTimeStamp = sessionStorage.getItem("loginTimeStamp_BeforeJWT");
  if (loginTimeStamp) {
    return true;
  }
  return false;
};
