import axios from "axios";
import HttpClient from "../Shared/HttpClient";
axios.defaults.baseURL = process.env.REACT_APP_SERVER_HOST;
axios.defaults.headers.post["Content-Type"] = "application/json";

export default class CustodianProcessDetails {
  custodianProcessDetails = async (data) => {
    const client = new HttpClient();
    const res = await client.dfedPost("/custodianProcessStatus", data);
    return res;
  };

  custodianProcessSummary = async (data) => {
    const client = new HttpClient();
    const res = await client.dfedPost("/custodianProcessSummary", data);
    return res;
  };

  getProcessingStatusCount = async (project_id) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getProcessingCount/${project_id}`);
    return res;
  };

  getAnalysisStatusCount = async (project_id) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getAnalysisCount/${project_id}`);
    return res;
  };

  getReviewStatusCount = async (project_id) => {
    const client = new HttpClient();
    const res = await client.dfedGet(`/getHomeReviewCount/${project_id}`);
    return res;
  };

  getMenu = async () => {
    const client = new HttpClient();
    const res = await client.dfedGet("/getMenu");
    return res;
  };
}
