import { Divider, Panel } from "appkit-react";
import { uniqBy } from "lodash";
import PropTypes from "prop-types";
import React, { useState } from "react";

import Projects from "./Projects";
import Users from "./Users";
import { ReactComponent as LoadingSpinner } from "../../../../Assets/Images/QueenBase/Root/loading-spin.svg";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import DashboardSolutions from "../DashboardSolutions/";

// DRILL DOWN CHILD
function DrillDownChild(props) {
  var polyglot = polyglotLoader();
  const { data, solutionsData, isChildDataLoading, toggleIsEdiscoveryGetData } = props;
  const [solution, setSolution] = useState(
    props.solutionsData.length > 0 ? props.solutionsData[0].solutionid : 0,
  );
  const [solutionEdiscoveryData, getEdiscoverySolutions] = useState([]);
  const [solutionDfData, getDfSolutions] = useState([]);
  const [solutionGiData, getGiSolutions] = useState([]);
  const [edexists, ediscoverysolutionexists] = useState(false);
  const [dfexists, dfsolutionexists] = useState(false);
  const [giexists, gisolutionexists] = useState(false);
  props.setpackageData(solution);
  props.getEdiscoverySolutions(solutionEdiscoveryData);
  props.getDfSolutions(solutionDfData);
  props.getGiSolutions(solutionGiData);
  props.ediscoverysolutionexists(edexists);
  props.dfsolutionexists(dfexists);
  props.gisolutionexists(giexists);

  // useEffect(() => {
  // 	props.setpackageData(solution);
  // 	props.getEdiscoverySolutions(solutionEdiscoveryData)
  // 	props.getDfSolutions(solutionDfData)
  // 	props.getGiSolutions(solutionGiData)
  // 	props.ediscoverysolutionexists(edexists)
  // 	props.dfsolutionexists(dfexists)
  // 	props.gisolutionexists(giexists)
  // }, [props]);

  // CAPITALISE FIRST LETTER
  function capitaliseFirstLetter(str) {
    const firstLetter = str.slice(0, 1).toUpperCase();
    const remainingStr = str.slice(1).toLowerCase();
    return `${firstLetter}${remainingStr}`;
  }

  // RENDER CHILD DATA
  const renderChildData = (each) => {
    switch (each.solutiontype) {
      case "Projects":
        data.childData.map((each) => {
          try {
            if (JSON.parse(each.solutions) && JSON.parse(each.solutions).length > 0) {
              let duplicated = uniqBy(JSON.parse(each.solutions), "solutionName");
              each.solutions = duplicated;
            }
          } catch (error) {
            var sol = JSON.stringify(each.solutions);
            if (JSON.parse(sol) && JSON.parse(sol).length > 0) {
              let duplicated = uniqBy(JSON.parse(sol), "solutionName");
              each.solutions = duplicated;
            }
          }
        });
        return <Projects data={data.childData} />;

      case "solutions":
        return (
          <DashboardSolutions
            dfsolutionexists={dfsolutionexists}
            ediscoverysolutionexists={ediscoverysolutionexists}
            gisolutionexists={gisolutionexists}
            getDfSolutions={getDfSolutions}
            getGiSolutions={getGiSolutions}
            getEdiscoverySolutions={getEdiscoverySolutions}
            dfDynamicWidgets={getDfSolutions}
            ediscoveryDynamicWidgets={getEdiscoverySolutions}
            giDynamicWidgets={getGiSolutions}
            setSolution={setSolution}
            solutionsData={solutionsData}
            toggleIsEdiscoveryGetData={toggleIsEdiscoveryGetData}
          />
        );

      case "users":
        return <Users data={data.childData} />;

      default:
        return (
          <div className="scrollTo floatLeft">
            <Divider className="cc-divider mt-5 drilldown-hr" />
            <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
          </div>
        );
    }
  };
  if (data.childData && data.childData.length > 0) {
    return (
      <div className="scrollTo dashboarddata sol-type">
        {/* <img src={flag} className="d-icons" /> */}
        <span className={data.solutiontype}>
          <p className="d-title title-heading">{capitaliseFirstLetter(data.label)}</p>
        </span>
        {renderChildData(data)}
      </div>
    );
  } else if (data.childData && data.childData.length == 0 && props.data.solutiontype == "solutions") {
    return (
      <div className="scrollTo dashboarddata">
        <Divider className="cc-divider mt-5  drilldown-hr" />
        {/* <img src={flag} className="d-icons" /> */}
        <span className={data.solutiontype}>
          <p className="d-title title-heading">{capitaliseFirstLetter(data.label)}</p>
        </span>
        <div className="row">
          <div className="col-md-12">
            <Panel>
              <div className="noprojects-dashboard">
                <strong>{polyglot.t("No solutions")}</strong>
              </div>
            </Panel>
          </div>
        </div>
      </div>
    );
  } else if (data.childData && data.childData.length == 0 && props.data.solutiontype == "Projects") {
    return (
      <div className="scrollTo dashboarddata">
        <Divider className="cc-divider mt-5  drilldown-hr" />
        {/* <img src={flag} className="d-icons" /> */}
        <span className={data.solutiontype}>
          <p className="d-title title-heading">{capitaliseFirstLetter(data.label)}</p>
        </span>
        <div className="row">
          <div className="col-md-12">
            <Panel>
              <div className="noprojects-dashboard">
                <strong>{polyglot.t("No projects")}</strong>
              </div>
            </Panel>
          </div>
        </div>
      </div>
    );
  } else if (data.childData && data.childData.length == 0 && props.data.solutiontype == "users") {
    return (
      <div className="scrollTo dashboarddata">
        <Divider className="cc-divider mt-5  drilldown-hr" />
        {/* <img src={flag} className="d-icons" /> */}
        <span className={data.solutiontype}>
          <p className="d-title title-heading">{capitaliseFirstLetter(data.label)}</p>
        </span>
        <div className="row">
          <div className="col-md-12">
            <Panel>
              <div className="noprojects-dashboard">
                <strong>{polyglot.t("No users")}</strong>
              </div>
            </Panel>
          </div>
        </div>
      </div>
    );
  }

  if (isChildDataLoading) {
    return (
      <div className="row dd-columns">
        <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
      </div>
    );
  }
  return <div />;
}

DrillDownChild.propTypes = {
  data: PropTypes.object,
  solutionsData: PropTypes.array,
};

export default DrillDownChild;
