import { Input, Select, SelectGroupTitle, SelectOption, Panel } from "appkit-react";
import { Formik } from "formik";
import React, { useContext, useState, useEffect } from "react";
import * as Yup from "yup";

import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import ConfirmBoxExitForm from "../../../Assets/Components/ConfirmBoxExitForm";
import { PHONE_REGEX, FIELD_REGEX, FIELD_LENGTH_REGEX } from "../../../Constants/regex";
import AdminService from "../../../Services/QueenBase/Admin/AdminServices";
import httpRequest from "../../../Services/Shared/HttpClient";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";

// THIS IS THE COMPONENT WHICH RENDERS TO EDIT INVOICE FORM
function EditClientDetails(props) {
  const { onClose, fetchClientsAfterEdit, each, ClientListData } = props;
  const [openConfirmBox, setOpenConfirmBox] = useState(false); // this value handles the confirm box of exit form
  const HttpRequest = new httpRequest();
  const [countries, setCountries] = useState([]);
  const [entityTypes, SetEntityTypes] = useState([]);
  const [disableSubmit, setDisableSubmit] = useState(false);
  const { getUserLanguage, userLanguage, fetchCountriesList, isFormOpen, formGotClosed, formGotOpened } =
    useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const language = getUserLanguage();
  const initialValues = {
    ClientName: ClientListData.ClientName,
    Clientemail: ClientListData.email,
    Clientcountryid: ClientListData.countryid,
    ClientOrganiation: ClientListData.entity,
    ClientEntityid: ClientListData.entityType,
    ClientContactPerson: ClientListData.contactperson,
    ClientDepartment: ClientListData.department,
    ClientPhone: ClientListData.phone,
    ClientAddress: ClientListData.address,
  };
  // VALIDATION SCHEMA FOR EDIT CLIENT FORM
  const validationSchema = Yup.object().shape({
    ClientName: Yup.string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t("field_validaton"))
      .required(polyglot.t("Name is required"))
      .nullable()
      .trim(),
    Clientemail: Yup.string()
      .email(polyglot.t("Invalid email"))
      .required(polyglot.t("Email is Required"))
      .nullable(),
    Clientcountryid: Yup.number().min(1, polyglot.t("Country required")),
    ClientOrganiation: Yup.string()
      .matches(FIELD_REGEX, polyglot.t("field_validaton"))
      .required(polyglot.t("Organisation is required"))
      .nullable()
      .trim(),
    ClientEntityid: Yup.number().required(polyglot.t("Entity Type is required")),
    ClientContactPerson: Yup.string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t("field_validaton"))
      .required(polyglot.t("Contact Person is required"))
      .nullable()
      .trim(),
    ClientDepartment: Yup.string()
      .matches(FIELD_LENGTH_REGEX, polyglot.t("field_validaton"))
      .required(polyglot.t("Department is required"))
      .nullable()
      .trim(),
    ClientPhone: Yup.string()
      .matches(PHONE_REGEX, polyglot.t("Invalid phone number"))
      .min(5, "Phone number must be at least 5 characters")
      .max(20, "Phone number must be at most 20 characters")
      .required(polyglot.t("Phone Number is required")),
    ClientAddress: Yup.string()
      .matches(FIELD_REGEX, polyglot.t("field_validaton"))
      .required(polyglot.t("* Address is required"))
      .trim(),
  });
  /* GET REQUIRED DATA FROM API TO CREATE A CLIENT
	WE RECEIVE:
	basicPackage: LIST OF BASE PACKAGES
	countries: LIST OF COUNTRIES
	position: LIST OF POSITIONS
	solutionList: LIST OF SOLUTIONS
	solutionPackage: LIST OF PACKAGES OF SOLUTIONS
	*/
  async function getAdmin(language) {
    var res = await AdminService.get().getAdmin(language);
    if (res.CODE) {
      let entityTypes = await HttpRequest.get("/getEntityType");
      SetEntityTypes(entityTypes);
    } else {
      displayToastMessage(polyglot.t(res.MSG), "error");
    }
  }
  // UPDATE CLIENT ON SUBMIT FORM
  const updateClient = async (client, actions) => {
    setDisableSubmit(true);
    // API TO UPDATE CLIENT
    const res = await AdminService.get().updateClient({
      ...client,
      clientid: ClientListData.clientid,
    });
    if (res.CODE) {
      displayToastMessage(polyglot.t("Client data updated", "success"));
      fetchClientsAfterEdit();
      formGotClosed();
      actions.resetForm();
    } else {
      displayToastMessage(res.MSG, "error");
      setDisableSubmit(false);
    }
  };
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    getAdmin(language);
  }, [language]);

  // get countries from api on language change
  const getCountries = async () => {
    const countriesList = await fetchCountriesList();
    setCountries(countriesList);
  };

  // check if form is empty before canceling the form
  const checkIfFormIsEmptyAndGoback = (client) => {
    if (isFormOpen) {
      // show confirm box
      toggleConfirmBox();
    } else {
      onClose(client);
    }
  };

  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  const toggleConfirmBox = () => {
    setOpenConfirmBox(!openConfirmBox);
  };

  // CLOSE FORM AND NAVIGATE TO VIEW PROJECTS
  const closeFormAndRedirect = (client) => {
    toggleConfirmBox();
    onClose(client);
    formGotClosed();
  };
  // Runs when user language got updated
  useEffect(() => {
    getCountries();
  }, [userLanguage]);
  return (
    <>
      <div className="row pl-0 mb-3 mr-0 ml-0">
        <div className="col-md-8 p-0">
          <Panel className="add-user a-panel-user">
            <div className="row">
              <div className="col-md-11 ">
                <h5 className="headertest mb-4">{polyglot.t("Edit ClientDetails")}</h5>
              </div>
            </div>
            <Formik onSubmit={updateClient} initialValues={initialValues} validationSchema={validationSchema}>
              {(props) => {
                const { values, handleChange, handleSubmit, errors, touched, dirty } = props;
                // FORMIK FORM - HANDLE FORMOPEN OR CLOSE STATUS
                const updateFormStatusBasedOnDirty = () => {
                  if (dirty) {
                    formGotOpened();
                  }
                  if (!dirty) {
                    formGotClosed();
                  }
                };
                setTimeout(() => {
                  updateFormStatusBasedOnDirty();
                }, 0);
                return (
                  <div>
                    <div className="row mt-1">
                      <div className="col-md-6 ">
                        <Input
                          inputBoxSize="sm"
                          placeholder={polyglot.t("Client name")}
                          value={values.ClientName}
                          onChange={handleChange("ClientName")}
                          aria-label={polyglot.t("Client name")}
                        />
                        {errors.ClientName && touched.ClientName ? (
                          <div className="error">{errors.ClientName}</div>
                        ) : null}
                        <br />
                      </div>
                      <div className="col-md-6">
                        <Input
                          inputBoxSize="sm"
                          placeholder={polyglot.t("Email*")}
                          value={values.Clientemail}
                          onChange={(value) => handleChange("Clientemail")({ target: { value } })}
                          aria-label={polyglot.t("Email*")}
                        />
                        {errors.Clientemail && touched.Clientemail ? (
                          <div className="error">{errors.Clientemail}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-md-6 ">
                        <Input
                          inputBoxSize="sm"
                          placeholder={polyglot.t("Organisation")}
                          value={values.ClientOrganiation}
                          onChange={(value) =>
                            handleChange("ClientOrganiation")({
                              target: { value },
                            })
                          }
                          aria-label={polyglot.t("Organisation")}
                        />
                        {errors.ClientOrganiation && touched.ClientOrganiation ? (
                          <div className="error">{errors.ClientOrganiation}</div>
                        ) : null}
                        <br />
                      </div>
                      <div className="a-dropdown a-dropdown-default col-md-6">
                        {entityTypes && entityTypes.length > 0 && (
                          <Select
                            placeholder={polyglot.t("Entity Type")}
                            value={values.ClientEntityid}
                            showSearchOnToggle={true}
                            onSelect={(value) =>
                              handleChange("ClientEntityid")({
                                target: { value },
                              })
                            }
                            aria-label={polyglot.t("Entity Type")}
                          >
                            <SelectGroupTitle>{polyglot.t("Entity Type")}</SelectGroupTitle>
                            {entityTypes.map((entity, index) => {
                              return (
                                <SelectOption key={index} value={entity.EntityID}>
                                  {entity.EntityName}
                                </SelectOption>
                              );
                            })}
                          </Select>
                        )}
                        {errors.Clientcountryid && touched.Clientcountryid ? (
                          <div className="error">{errors.ClientEntityid}</div>
                        ) : null}
                        <br />
                      </div>
                    </div>
                    <div className="row mt-1">
                      <div className="col-md-6 ">
                        <Input
                          inputBoxSize="sm"
                          placeholder={polyglot.t("ContactPerson ")}
                          value={values.ClientContactPerson}
                          onChange={(value) =>
                            handleChange("ClientContactPerson")({
                              target: { value },
                            })
                          }
                          aria-label={polyglot.t("ContactPerson ")}
                        />
                        {errors.ClientContactPerson && touched.ClientContactPerson ? (
                          <div className="error">{errors.ClientContactPerson}</div>
                        ) : null}
                        <br />
                      </div>
                      <div className="col-md-6">
                        <Input
                          inputBoxSize="sm"
                          placeholder={polyglot.t("PhoneNumber*")}
                          value={values.ClientPhone}
                          onChange={(value) => handleChange("ClientPhone")({ target: { value } })}
                          aria-label={polyglot.t("PhoneNumber*")}
                        />
                        {errors.ClientPhone && touched.ClientPhone ? (
                          <div className="error">{errors.ClientPhone}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <Input
                          inputBoxSize="sm"
                          placeholder={polyglot.t("Department*")}
                          value={values.ClientDepartment}
                          onChange={(value) =>
                            handleChange("ClientDepartment")({
                              target: { value },
                            })
                          }
                          aria-label={polyglot.t("Department*")}
                        />
                        {errors.ClientDepartment && touched.ClientDepartment ? (
                          <div className="error">{errors.ClientDepartment}</div>
                        ) : null}
                      </div>
                      <div className="a-dropdown a-dropdown-default col-md-6">
                        {countries.length > 0 && (
                          <Select
                            placeholder={polyglot.t("Country")}
                            value={values.Clientcountryid}
                            showSearchOnToggle={true}
                            onSelect={(value) =>
                              handleChange("Clientcountryid")({
                                target: { value },
                              })
                            }
                            aria-label={polyglot.t("Country")}
                          >
                            <SelectGroupTitle>{polyglot.t("Country Title")}</SelectGroupTitle>
                            {countries.map((country, index) => {
                              if (index < 1)
                                return (
                                  <SelectOption key={index + 1} value={country.countryid}>
                                    {country.name}
                                  </SelectOption>
                                );
                            })}
                            <hr className="" />
                            {countries.map((country, index) => {
                              if (index > 0)
                                return (
                                  <SelectOption key={index + 1} value={country.countryid}>
                                    {country.name}
                                  </SelectOption>
                                );
                            })}
                          </Select>
                        )}
                        {errors.Clientcountryid && touched.Clientcountryid ? (
                          <div className="error">{errors.Clientcountryid}</div>
                        ) : null}
                        <br />
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <Input
                          inputBoxSize="sm"
                          placeholder={polyglot.t("Client Address*")}
                          value={values.ClientAddress}
                          onChange={(value) => handleChange("ClientAddress")({ target: { value } })}
                          aria-label={polyglot.t("Client Address*")}
                        />
                        {errors.ClientAddress && touched.ClientAddress ? (
                          <div className="error">{errors.ClientAddress}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-12 edit-clientform-button p-0">
                      <div className="d-flex justify-content-between">
                        <ButtonSecondary
                          onClick={() => checkIfFormIsEmptyAndGoback(each.clientid)}
                          ariaLabel={polyglot.t("Cancel")}
                        >
                          {polyglot.t("Cancel")}
                        </ButtonSecondary>
                        <ButtonPrimary
                          disabled={disableSubmit}
                          onClick={handleSubmit}
                          ariaLabel={polyglot.t("Save Client")}
                        >
                          {polyglot.t("Save Client")}
                        </ButtonPrimary>
                      </div>
                    </div>
                    <ConfirmBoxExitForm
                      show={openConfirmBox}
                      heading={"Cancel update"}
                      cancel={toggleConfirmBox}
                      proceed={() => closeFormAndRedirect(each.clientid)}
                    />
                  </div>
                );
              }}
            </Formik>
          </Panel>
        </div>
      </div>
    </>
  );
}

export default EditClientDetails;
