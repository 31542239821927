import homeimg from "./Icons/home-outline.svg";
import rightIcon from "./Images/DFED/icon-chevron-right-black.svg";
import downloadSVG from "./Images/DFED/icon-download.svg";
import downloadSVG_white from "./Images/DFED/icon-download_white.svg";
import foldericon from "./Images/GRC/iconmonstr-folder-thin.svg";
import editimg from "./Images/QueenBase/Projects/icon-edit.svg";

export const backCircle = "icon-left-chevron-circle-outline";
export const plusCircle = "icon-circle-plus-outline";
export const deleteFill = "icon-delete-fill";
export const rightChevronFill = "icon-right-chevron-fill";
export const logoutIcon = "icon-logout-fill";
export const closeIcon = "icon-close-fill";
export const saveIcon = "icon-save-fill";
export const settingsIcon = "icon-setting-outline";
export const infoIcon = "icon-information-outline";
export const infoIconFill = "icon-information-fill";
export const barChartIcon = "icon-bar-chart-fill";
export const downloadImg = downloadSVG;
export const downloadImg_white = downloadSVG_white;
export const play_outline_Icon = "icon-play-outline";
export const backArrowFill = "icon-arrow-left-fill";
export const rightChevronImg = rightIcon;
export const uploadIcon = "icon-upload-outline";
export const downloadIcon = "icon-download-outline";
export const editImg = editimg;
export const homeImg = homeimg;
export const folderIcon = foldericon;
