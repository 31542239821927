import HttpClient from "./HttpClient";

class PermissionService {
  constructor() {
    this.client = new HttpClient();
  }

  async getPermissionList(moduleName, projectID, solutioonName) {
    const result = await this.client.dfedGet(
      `/getPermissionList/${moduleName}/${projectID}/${solutioonName}`,
    );
    return result;
  }
}
// Enable Singleton behavior on this class
PermissionService.__instance = null;
PermissionService.get = () => {
  if (!PermissionService.__instance) {
    PermissionService.__instance = new PermissionService();
  }
  return PermissionService.__instance;
};

export default PermissionService;
