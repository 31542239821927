import React, { Component } from "react";
import {
  Tooltip,
  Accordions,
  AccordionItem,
  AccordionItemHeader,
  AccordionItemBody,
  Panel,
  Input,
  Button,
} from "appkit-react";
import "./AddDatasets.css";
import "../upload/Upload.css";
import Upload from "../upload/Upload";
import { withRouter } from "react-router-dom";
import { getDatasetInfo } from "../../../Services/DFED/DataSetService";
import BackButton from "../Shared/button/BackButton";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import {
  TextFieldLengthValidation,
  TextFieldLengthRegexValidation,
} from "../../../utility/textFieldValidation";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonPrimaryExpandable from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryExpandable";
import { deleteFill } from "../../../Assets/Icons";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import CustomModal from "../../../Assets/Components/Modal";
import Dataset from "../../../Services/DFED/DataSet";

// THIS CLASS IS DATASET CLASS RESPONSIBLE FOR HANDLING DATASET OPERATION UPLOADING FILE AND CREATING DATASET======================
class AddDatasets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      files: [],
      dataSetDetails: [],
      finalFiles: [],
      uploading: false,
      uploadProgress: {},
      successfullUploaded: false,
      selectedName: props.selectedName,
      checkBox: true,
      datasets: [[""]],
      openedAccordian: ["0"],
      expandCollapseKey: [],
      expandCollapseUploadCardKey: [0], // to open the 1st accordian by default
      openedAccordianSet1: ["-1"],
      completeFilesList: [],
      uploadBtnVisiable: {
        display: "none",
      },
      isRedirectToProfile: false,
      propsData: this.props,
      isProcessedDatasetButtonDisabled: true,
      isUploading: false,
      textFieldLengthError: false,
      datasetName: "",
      visible: false,
      datasetId: "",
      fileid: "",
      subdatasetLength: "",
      subfilename: "",
      datasetnameDetails: "",
      wholeDatasetToBeDeleted: false
    };
    this.onBackButtonClick = this.onBackButtonClick.bind(this);
  }
  static contextType = DataContext;
  polyglot = polyglotLoader();
  checkStatusZero = (element) => {
    return element.status === 0;
  };
  async getDatasetDetails(custodianId, projectId) {
    let dataSetInfo = await getDatasetInfo(custodianId, projectId);
    if (dataSetInfo && dataSetInfo.data) {
      let isProcessedDatasetButtonDisabled = true;
      if (dataSetInfo.data.some(this.checkStatusZero)) {
        isProcessedDatasetButtonDisabled = false;
      }
      this.setState({
        dataSetDetails: dataSetInfo.data ? dataSetInfo.data : 0,
        isProcessedDatasetButtonDisabled: isProcessedDatasetButtonDisabled,
      });
    } else {
      this.setState({
        dataSetDetails: [],
        isProcessedDatasetButtonDisabled: true,
      });
    }
  }
  // ADD NEW DATASET FUNCTIONALITY
  addnewDataset = () => {
    let addnewDataset = this.state.datasets;
    let lastAdded = addnewDataset[addnewDataset.length - 1];
    if (lastAdded[0] !== "") {
      if (/^(?=[^])[A-Za-z0-9-_,.@\(\)\[\]]+$/.test(lastAdded[0]) === false && lastAdded[0] !== "") {
        displayToastMessage(
          this.polyglot.t(
            "Please check your previous dataset name, following special characters are allowed. ()[]@,.;_-",
          ),
          "error",
        );
      } else {
        let object = [""];
        addnewDataset.push(object);
        this.setState({ datasets: addnewDataset });
      }
    } else {
      displayToastMessage(this.polyglot.t("Please fill the previous dataset"), "error");
    }
    this.dataSetOpenHandler(addnewDataset.length - 1);
  };
  // DISABLING THE DATASET BUTTON=====================

  uploadBtnVisiablehandler = () => {
    var flag = false;
    this.state.datasets.forEach((element) => {
      if (element.length > 1 && element[0] !== "") {
        flag = true;
      }
    });
    if (flag) {
      this.setState({ uploadBtnVisiable: { display: "" } });
    } else {
      this.setState({ uploadBtnVisiable: { display: "none" } });
    }
  };
  // life cycle METHOD FOR HANDLING UPLOAD BUTTON DISABLE AND ENABLE==================

  componentDidMount() {
    this.uploadBtnVisiablehandler();
    let custodianData = this.props.selectedName;
    this.setState({
      selectedProject: this.props.location.state.selectedProjectId,
      selectedProjectName: sessionStorage.getItem("selectedProjectName"),
    });

    this.getDatasetDetails(custodianData.CRT_ID, this.props.location.state.selectedProjectId);
  }
  // REDIRECTING TO PROFILE PAGE AFTER UPLOADING THE DATASET====================

  processToProcessingPage = () => {
    if (this.state.dataSetDetails) {
      let arr = this.state.dataSetDetails.filter((filetobeProcessed) => {
        return filetobeProcessed.status === 0;
      });
      let complDataSetList = "";
      let complDataSetIDList = "";
      for (let eachDataSet = 0; eachDataSet < arr.length; eachDataSet++) {
        complDataSetIDList += "," + arr[eachDataSet].DATASET_ID;
        complDataSetList += "," + arr[eachDataSet].dataset;
      }
      complDataSetList = complDataSetList.slice(1, complDataSetList.length - 1);
      complDataSetIDList = complDataSetIDList.slice(1, complDataSetIDList.length);
      sessionStorage.setItem("dsets", JSON.stringify(arr));
      sessionStorage.setItem("datasets", complDataSetList);
      sessionStorage.setItem("datasetIDs", complDataSetIDList);
      sessionStorage.setItem("custo_Name", this.state.selectedName.CRT_ALIAS);
      sessionStorage.setItem("custodianId", this.state.selectedName.CRT_ID);
    }
    let datasetArr = sessionStorage.getItem("datasets");
    this.props.history.push({
      pathname: "/dfed/processing",
      state: {
        custodianId: this.state.selectedName.CRT_ID,
        custodainName: this.state.selectedName.CRT_ALIAS,
        datasetsName: datasetArr,
        selectedProjectId: this.props.location.state.selectedProjectId,
        fromTabNavigation: true,
        showCustodian: true,
        showCustodianListComponent: false,
        showImageDiv: true,
        MainDiv: false,
      },
    });
  };

  datasetNameHandler = (e, index) => {
    // REGEX For DATASET name validation==================
    let name = e.target.value;
    if (TextFieldLengthValidation(e.target.value)) {
      let data = this.state.datasets;
      data[index].shift();
      data[index].unshift(e.target.value);
      this.setState({ datasets: data }, () => {
        if (!TextFieldLengthRegexValidation(name) && name !== "") {
          displayToastMessage(this.polyglot.t("name_validaton"), "error");
        } else {
          this.changeDatasetsNameInFinalFileList(name, index);
          this.uploadBtnVisiablehandler();
          this.setState({ textFieldLengthError: false, datasetName: name });
        }
      });
    } else {
      this.setState({ textFieldLengthError: true });
    }
  };

  changeDatasetsNameInFinalFileList(datasetName, index) {
    let tempFinalFiles = this.state.finalFiles;
    for (let i = 0; i < tempFinalFiles.length; i++) {
      if (tempFinalFiles[i].datasetId === index) {
        tempFinalFiles[i].datasetName = datasetName;
      }
    }
    this.setState({ finalFiles: tempFinalFiles });
  }

  bytesToSize = (bytes) => {
    var sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (bytes === 0) return "0 Byte";
    var i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
    return (bytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
  };

  cancelUploadFile = (index, indexInner) => {
    let data = this.state.datasets;
    data[index].splice(indexInner, 1);
    this.setState({ datasetName: data });
    this.uploadBtnVisiablehandler();
  };
  handleCancel = (e) => {
    this.setState({
      visible: false,
    });
  };

  checkBoxHandler = (e) => {
    this.setState({ checkBox: !this.state.checkBox });
  };

  deleteSubdatasets = (fileIndexToRemove, index, dguid) => {
    let data = this.state.datasets;
    data[index].splice(fileIndexToRemove, 1);
    let filetobeRemoved = this.state.finalFiles.filter(function (el) {
      return el.dguid !== dguid;
    });
    this.setState({
      datasets: data,
      finalFiles: filetobeRemoved,
    });
  };
  // RESETTING THE DATASET
  datasetReset = async () => {
    if (this.props.selectedName) {
      let custodianData = this.props.selectedName;
      if (custodianData && custodianData.CRT_ID) {
        await this.getDatasetDetails(custodianData.CRT_ID, this.props.location.state.selectedProjectId);
      } else {
        this.props.history.push("/dfed/custodian");
      }
      this.setState({
        files: [],
        finalFiles: [],
        uploading: false,
        uploadProgress: {},
        successfullUploaded: false,
        selectedName: this.props.selectedName,
        checkBox: true,
        datasets: [[""]],
        openedAccordian: [0],
        openedAccordianSet1: [-1],
        completeFilesList: [],
        uploadBtnVisiable: {
          display: "none",
        },
        datasetName: "",
      });
    }
  };
  checkNavigateWhileUpload = (value) => {
    this.setState({
      isUploading: value,
    });
  };
  // FILE READER FUNCTIONALITY=================

  fileReaderHandler = (file, index) => {
    let filestobeUploaded = this.state.finalFiles;
    let data2 = this.state.datasets;
    filestobeUploaded = filestobeUploaded.concat(file);
    data2[index].push(file);
    this.setState({
      datasets: data2,
      finalFiles: filestobeUploaded,
    });
  };

  dataSetOpenHandler = (key) => {
    if (key === this.state.openedAccordian[0]) {
      key = "-1";
    }
    this.setState({ openedAccordian: key, openedAccordianSet1: "-1" });
  };

  dataSetOpenHandler1 = (key1) => {
    if (key1 === this.state.openedAccordianSet1) {
      key1 = "-1";
    }
    this.setState({ openedAccordianSet1: key1, openedAccordian: "-1" });
  };
  goToAskForSupport = () => {
    this.props.history.push("/dfed/support");
    localStorage.setItem("previousComponent", "Custodians");
  };
  onBackButtonClick() {
    if (this.state.isUploading === false) {
      this.props.goToCustodianList();
    } else {
      this.props.goToCustodianList();
    }
  }

  // DISPLAY NAME ON TOOLTIP
  TooltipName = (name) => {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#000",
                  textTransform: "capitalize",
                }}
              >
                {name}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };
  conformremoveFileFromList = (datasetId, fileid, subdatasetLength, filename, datasetName) => {
    this.setState({
      visible: true,
      datasetId,
      fileid: fileid,
      subdatasetLength: subdatasetLength,
      subfilename: filename,
      datasetnameDetails: datasetName,
      wholeDatasetToBeDeleted: false
    });
  };

  confirmDatasetDeletion = (datasetId, datasetName) => {
    this.setState({
      visible: true,
      wholeDatasetToBeDeleted: true,
      datasetId,
      datasetnameDetails: datasetName,
    });
  };

  removeFileFromList = async () => {
    const datasetId = this.state.datasetId;
    const subdatasetId = this.state.fileid;
    let subdatasetType;
    if (this.state.subdatasetLength == 1 || this.state.wholeDatasetToBeDeleted) {
      subdatasetType = "wholeDatasetDelete";
    } else {
      subdatasetType = "singleSubdatasetDelete";
    }

    const data = {
      datasetId,
      subdatasetId,
      subdatasetType,
    };
    const formData = new FormData();
    formData.append(
      "updateDeleteStatus",
      JSON.stringify({ ...data, projectId: this.state.selectedProjectId, projectName: this.state.selectedProjectName, custodianAlias: this.state.selectedName.CRT_ALIAS, datasetName: this.state.datasetnameDetails }),
    );
    const res = await Dataset.get().deleteDataset(formData);
    if (res.CODE) {
      displayToastMessage(this.polyglot.t("Data deleted sucessfully"), "success");
      // empty the state values
      this.setState({
        visible: false,
        datasetId: "",
        fileid: "",
        subdatasetLength: "",
        subfilename: "",
        datasetnameDetails: "",
      });
      // call the API to fetch the updated datasets
      const custodianData = this.props.selectedName;
      this.getDatasetDetails(custodianData.CRT_ID, this.props.location.state.selectedProjectId);
    } else {
      displayToastMessage(this.polyglot.t(res.MSG), "error");
    }
  };

  // EXPANDINGA ND COLLAPSEING BASED ON CONDITION
  expandCollpaseHandlerUploadCard = (e) => {
    if (this.state.expandCollapseUploadCardKey[0] === e) {
      this.setState({ expandCollapseUploadCardKey: [] });
    } else {
      this.setState({ expandCollapseUploadCardKey: [e] });
    }
  };
  // EXPANDINGA ND COLLAPSEING BASED ON CONDITION
  expandCollpaseHandler = (e) => {
    if (this.state.expandCollapseKey[0] === e) {
      this.setState({ expandCollapseKey: [] });
    } else {
      this.setState({ expandCollapseKey: [e] });
    }
  };

  getDeleteTranslationKey = () => {
    if (this.state.wholeDatasetToBeDeleted) {
      return "AreYouSureToDeleteWholeDataset"
    } else if (this.state.subdatasetLength > 1) {
      return "AreYouSureToDeleteFile";
    } 

    return "AreYouSureToDeleteTheLastFile";
  };

  // RENDER FUNCTION FOR RESPONSIBLE FOR HANDLING ALL UI RENDERING OD DATASET=====

  render() {
    const polyglot = polyglotLoader();
    const agreed = (
      <div>
        <div className="row m-l-0 m-r-0 col-lg-10 p-0 m-b-14">
          <div className="col-md-3 col-lg-3 p-l-0">
            <ButtonPrimary onClick={this.addnewDataset} ariaLabel={polyglot.t("addDataset")}>
              {polyglot.t("addDataset")}
            </ButtonPrimary>
          </div>
          <div className="col-md-3 col-lg-3 p-l-0">
            <BackButton
              onBackButtonClick={this.onBackButtonClick}
              buttonText={polyglot.t("Back")}
            ></BackButton>
          </div>
        </div>
        <div className="row m-l-0 m-r-0">
          <div className="col-md-8 p-0">
            <div className="row m-l-0 m-r-0 ">
              <div className="col-md-4 p-l-0">
                <Tooltip
                  content={this.TooltipName(this.state.selectedName.CRT_ALIAS)}
                  placement="top"
                  trigger="click"
                >
                  <div className="card-name-overflow-DF f-acc mt-2">{this.state.selectedName.CRT_ALIAS}</div>
                </Tooltip>
              </div>
              <div className="col-md-8 custom-display-flex justify-content-end pr-0">
                <div className="display-flex">
                  <div className="appendableClass m-r-5"></div>
                  <div>
                    <ButtonPrimaryExpandable
                      disabled={this.state.isProcessedDatasetButtonDisabled}
                      onClick={this.processToProcessingPage.bind(this)}
                      ariaLabel={polyglot.t("processUploadedDatasets")}
                    >
                      {polyglot.t("processUploadedDatasets")}
                    </ButtonPrimaryExpandable>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-md-12 p-0 dfed-expand-list-cards mt-2">
              {this.state.datasets.map((dataset, index) => {
                return (
                  <Accordions
                    activeItem={this.state.expandCollapseUploadCardKey}
                    onChange={this.expandCollpaseHandlerUploadCard}
                    className="mb-2"
                  >
                    <AccordionItem itemId={index} key={index}>
                      <AccordionItemHeader title={dataset[0]} />
                      <AccordionItemBody>
                        <Panel key={index} className="mt-2 box-shadow-none">
                          <div className="ContentFileU">
                            <div className="col-md-8 upload-box-col p-0 justify">
                              <Input
                                inputBoxSize="sm"
                                placeholder={polyglot.t("enter_dataset_name")}
                                value={dataset[0]}
                                onChange={(val) => this.datasetNameHandler({ target: { value: val } }, index)}
                                aria-label={polyglot.t("enter_dataset_name")}
                                className="mt-2"
                              />
                            </div>
                            <div className="pl-0 mt-1">
                              {this.state.textFieldLengthError && (
                                <div className="error">{polyglot.t("Maximum character length is 40")}</div>
                              )}
                            </div>
                          </div>
                          <Upload
                            UploadData={this.state.selectedName}
                            datasetName={this.state.datasets}
                            finalList={this.state.finalFiles}
                            fileReaderHandler={this.fileReaderHandler}
                            removeFileDataSet={this.deleteSubdatasets}
                            resetDataSet={this.datasetReset}
                            index={index}
                            dsprojectProps={this.props.location}
                            dataSetDetails={this.state.dataSetDetails}
                            checkNavigateWhileUpload={this.checkNavigateWhileUpload}
                          />
                        </Panel>
                      </AccordionItemBody>
                    </AccordionItem>
                  </Accordions>
                );
              })}
            </div>

            <div className="col-md-12 p-0 dfed-expand-list-cards mt-2">
              {this.state.dataSetDetails.length > 0 &&
                this.state.dataSetDetails.map((ds, index1) => {
                  return (
                    <div className="flex">
                      <div className="col-md-11 p-0">
                        <Accordions
                          activeItem={this.state.expandCollapseKey}
                          onChange={this.expandCollpaseHandler}
                          className="mb-2"
                        >
                          <AccordionItem itemId={index1} key={index1}>
                            <AccordionItemHeader title={ds.dataset} />
                            <AccordionItemBody>
                              <Panel key={index1} className="box-shadow-none">
                                <div className="DF-Progress-total">
                                  <div className="row m-l-0 m-r-0">
                                    <div className="Files">
                                      {ds.sub_datasets.map((subfile, subindex) => {
                                        return (
                                          <div key={subfile.FILE_NAME} className="Row row m-l-0 m-r-0">
                                            <div className="Filename col-md-2">
                                              <Tooltip
                                                content={this.TooltipName(subfile.FILE_NAME)}
                                                placement="right"
                                                trigger="click"
                                              >
                                                <div className="card-name-overflow-DF f-acc transformY-minus15">
                                                  {subfile.FILE_NAME}
                                                </div>
                                              </Tooltip>
                                            </div>

                                            <div className="col-md-8 DF-Cust-progress-bar">
                                              <>
                                                <div
                                                  style={{
                                                    float: "left",
                                                  }}
                                                >
                                                  {polyglot.t("File size: ")}
                                                  {this.bytesToSize(subfile.FILE_SIZE)}
                                                </div>
                                                <div
                                                  className="progress-status-css"
                                                  style={{
                                                    float: "right",
                                                  }}
                                                >
                                                  {polyglot.t("Uploaded")}
                                                </div>
                                                <div
                                                  style={{
                                                    clear: "both",
                                                  }}
                                                ></div>
                                              </>
                                            </div>
                                            {ds.status == 0 && (
                                              <div
                                                className="col-md-2 p-l-0 p-r-0 mb-10 ml-2"
                                                id="deleteImguploadedData"
                                              >
                                                <ButtonIcon
                                                  alt="delete"
                                                  title={polyglot.t("Delete")}
                                                  onClick={() =>
                                                    this.conformremoveFileFromList(
                                                      ds.DATASET_ID,
                                                      subfile.sub_dataset_id,
                                                      ds.sub_datasets.length,
                                                      subfile.FILE_NAME,
                                                      ds.dataset
                                                    )
                                                  }
                                                  iconName={deleteFill}
                                                  ariaLabel={polyglot.t("Delete")}
                                                />
                                              </div>
                                            )}
                                          </div>
                                        );
                                      })}
                                    </div>
                                  </div>
                                </div>
                              </Panel>
                            </AccordionItemBody>
                          </AccordionItem>
                        </Accordions>
                      </div>
                      <div className="col-md-1 p-0 flex-center">
                        <ButtonIcon
                            alt="delete"
                            title={polyglot.t("Delete")}
                            onClick={() => this.confirmDatasetDeletion(ds.DATASET_ID, ds.dataset)}
                            iconName={deleteFill}
                            ariaLabel={polyglot.t("Delete")}
                            disabled={ds.status}
                          />
                      </div>
                    </div>
                  );
                })}

              <CustomModal
                show={this.state.visible}
                onCancel={this.handleCancel}
                title={polyglot.t("Confirmation")}
                Footer={[
                  <Button size="lg" className="primary_button" onClick={this.removeFileFromList} key="1">
                    {polyglot.t("Confirm")}
                  </Button>,
                ]}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: polyglot.t(this.getDeleteTranslationKey(), {
                      name: this.state.wholeDatasetToBeDeleted ? this.state.datasetnameDetails : this.state.subfilename,
                    }),
                  }}
                ></div>
              </CustomModal>
            </div>
          </div>
          <div className="col-md-4 pl-25 pt-5">
            <div className="row m-l-0 m-r-0 p-t-2">
              <p className="para-txt">{polyglot.t("Please provide para_custodians")}</p>
              <p className="para-txt">
                {polyglot.t("You can either")}
                <span className="para-head">{" " + polyglot.t("Select your Files")} </span>
                {" " + polyglot.t("to browse your system for the file to be uploaded or can simply")}
                <span className="para-head">{" " + polyglot.t("Drag and Drop")} </span>
                {" " +
                  polyglot.t(
                    "the file. If your file(s) exceed a volume of 10 GBs, please use the SFTP connection of our system",
                  )}
                .
              </p>
              <p className="para-txt">
                {polyglot.t("Once you've added all the information, please click on ")}
                <span className="para-head">{" " + polyglot.t("Upload waiting sets")} </span>
                {" " + polyglot.t("to start the upload process")}.
              </p>
              <p className="para-head mt-3 mb-2">{polyglot.t("Max upload size")}</p>
              <p className="para-txt">
                {polyglot.t(
                  "Please note that data uploads are limited to 10 GB. If you need to upload files larger than 10 GB, please use our SFTP connection.",
                )}
                {polyglot.t("get additional upload text")}
                <a
                  href="https://transfer.pwc.de/file/d/CLIENTCODE/PROJECTNAME/CUSTODIAN/DATASET"
                  className="new-link"
                  target="_blank"
                >
                  https://transfer.pwc.de/file/d/CLIENTCODE/PROJECTNAME/CUSTODIAN/DATASET
                </a>
                )
              </p>
            </div>
          </div>
        </div>
      </div>
    );

    var visible;
    if (this.state.checkBox === true) {
      visible = agreed;
    }

    return <div> {visible}</div>;
  }
}
export default withRouter(AddDatasets);
