import React from "react";

import { rightChevronImg } from "../../Assets/Icons";

// Assign selected project id to session storage
export const setProjectID_Of_GRC_module = (projectId) => {
  sessionStorage.setItem("selectedProject", projectId);
};
// Get selected project id from session storage
export const getProjectID_Of_GRC_module = () => {
  const projectID = sessionStorage.getItem("selectedProject");
  return projectID;
};

// create breadcrum html using string
export const GetBreadcrumHTMLByString = ({ breadcrumString }) => {
  try {
    const listOfSteps = breadcrumString.split(">");
    const isStepActive = (total, currentIndex) => {
      if (total === currentIndex + 1) {
        return true;
      } else {
        return false;
      }
    };
    const displayRightArrow = (total, currentIndex) => {
      if (total === currentIndex + 1) {
        return "";
      } else {
        return <img src={rightChevronImg} alt="right arrow" />;
      }
    };
    return (
      <div className="display-inline-flex category-breadcrum-box-text ml-2">
        {listOfSteps.map((step, index) => {
          return (
            <div key={index}>
              <b
                className={`padding-point5rem ${
                  isStepActive(listOfSteps.length, index) && "active-breadcrum"
                }`}
              >
                {step}
              </b>
              {displayRightArrow(listOfSteps.length, index)}&nbsp;
            </div>
          );
        })}
      </div>
    );
  } catch (err) {
    return <></>;
  }
};

// CHECK IF THE INPUT NUMBER IS A DECIMAL VALUE OR NOT
export const checkIfNumberIsDecimal = (value) => {
  try {
    return Number(value) === value && value % 1 !== 0;
  } catch (err) {
    return false;
  }
};
