import { Panel } from "appkit-react";
import React, { useState } from "react";

import ConfirmBox from "./ConfirmBox";
import RequestFormFields from "./RequestFormFields";
import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import ButtonPrimaryWithIcon from "../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import { backCircle, closeIcon } from "../../../Assets/Icons";
import { polyglotLoader } from "../../../Services/Shared/Translate";

function RequestForm(props) {
  const { history } = props;
  const [openConfirmBox, setOpenConfirmBox] = useState(false);
  const polyglot = polyglotLoader();

  const navigateBack = () => {
    history.goBack();
  };
  const displayDiscription = () => {
    // discription of the form can be seen here
    return "";
  };
  // TOGGLE CONFIRM BOX BEFORE CLOSING THE FORM
  const toggleConfirmBox = () => {
    setOpenConfirmBox((prev) => !prev);
  };
  // form fields are displayed here
  const displayFormFields = () => {
    return <RequestFormFields />;
  };
  return (
    <div className="p-0 m-l-1-15">
      <>
        <div className="full-width">
          <div className="col-md-4 DF-mt-3 p-0">
            <ButtonPrimaryWithIcon
              onClick={toggleConfirmBox}
              iconName={backCircle}
              ariaLabel={polyglot.t("Back")}
            >
              {polyglot.t("Back")}
            </ButtonPrimaryWithIcon>
          </div>
        </div>
        <div className="full-width">
          <div className="row mt-5">
            <div className="col-md-8 mb-2">
              <Panel className="p-4">
                <div className="d-flex justify-content-between">
                  <h5 className="text-middle">{polyglot.t("Request form")}</h5>
                  <ButtonIcon
                    iconName={closeIcon}
                    alt="cancel"
                    onClick={toggleConfirmBox}
                    ariaLabel={polyglot.t("Cancel form")}
                  />
                </div>
                <div className="f-acc-14 m-b-3">{displayDiscription()}</div>
                {displayFormFields()}
              </Panel>
            </div>
            <div className="col-md-4">{/* content on right can be written here */}</div>
          </div>
        </div>
        <ConfirmBox
          show={openConfirmBox}
          heading={"Cancel form"}
          cancel={toggleConfirmBox}
          proceed={navigateBack}
        />
      </>
    </div>
  );
}

export default RequestForm;
