import React, { useState, useEffect } from "react";
import IdleTimer from "react-idle-timer";

import IdleTimeoutAlert from "../Assets/Components/IdleTimeoutAlert";
import {
  check_If_User_LoggedIn,
  expire_my_session,
  getUserIDFromSession,
  refresh_jwt_token,
} from "../Services/Shared/Authenticate";

const addMinsToCurrentTime = (minsToAdd) => {
  const now = new Date();
  const later = new Date(now);
  later.setMinutes(now.getMinutes() + minsToAdd);
  return later.getTime();
};

const IdleTimerContainer = () => {
  const [openSessionTimeoutAlert, setOpenSessionTimeoutAlert] = useState(false);
  const [idleTimeoutTimer, setIdleTimeoutTime] = useState(null);

  const isIdle = sessionStorage.getItem("idleTimeout");

  const generateIdleTimer = () => {
    const idleTimeoutTimerMaxTime = addMinsToCurrentTime(2);
    setIdleTimeoutTime(idleTimeoutTimerMaxTime);
  };

  useEffect(() => {
    if (check_If_User_LoggedIn()) {
      if (isIdle == "true") {
        if (!idleTimeoutTimer) {
          generateIdleTimer();
        }
        setOpenSessionTimeoutAlert(true);
      }
    }
  }, [isIdle]);

  const sessionAboutToTimout = () => {
    if (check_If_User_LoggedIn()) {
      if (!idleTimeoutTimer) {
        generateIdleTimer();
        setOpenSessionTimeoutAlert(true);
      }
      sessionStorage.setItem("idleTimeout", "true");
    }
  };
  const refreshToken = async () => {
    const refreshedJwtToken = await refresh_jwt_token();
    if (refreshedJwtToken === "SUCCESS") {
      setIdleTimeoutTime(null);
      setOpenSessionTimeoutAlert(false);
    }
  };
  const sessionExpires = () => {
    expire_my_session();
  };
  const continueSession = () => {
    const userid = getUserIDFromSession();
    if (userid) {
      refreshToken();
    } else {
      sessionExpires();
      setIdleTimeoutTime(null);
      setOpenSessionTimeoutAlert(false);
    }
    sessionStorage.setItem("idleTimeout", "false");
  };

  return (
    <>
      <IdleTimer
        onIdle={sessionAboutToTimout}
        timeout={1000 * 60 * 15} // 15mins
      />
      <IdleTimeoutAlert
        continueSession={continueSession}
        sessionExpires={sessionExpires}
        openAlert={openSessionTimeoutAlert}
        idleTimeoutTimerMaxTime={idleTimeoutTimer}
      />
    </>
  );
};

export default IdleTimerContainer;
