//Delete Clients Avaliable in list
import { Button } from "appkit-react";
import PropTypes from "prop-types";
import React from "react";
import { withRouter } from "react-router-dom";

import CustomModal from "../../../Assets/Components/Modal";
import * as path from "../../../Constants/Path";
import AdminService from "../../../Services/QueenBase/Admin/AdminServices";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../Root/displayToastMessage";

// THIS IS THE COMPONENT WHICH RENDERS TO DELETE CLIENT
function DeleteClient({ show, cancelClientModal, history, ClientData }) {
  var polyglot = polyglotLoader();
  // DELETE CLIENT
  const deleteClient = async () => {
    var res = await AdminService.get().DeleteClient(ClientData);
    if (res.CODE) {
      displayToastMessage(res.MSG, "success");
      history.push(path.ADMIN_PATH);
    } else {
      displayToastMessage(res.MSG, "error");
    }
  };
  return (
    <>
      <CustomModal
        show={show}
        onCancel={cancelClientModal}
        title={polyglot.t("Deleting Client")}
        Footer={[
          <Button size="lg" className="primary_button" onClick={() => deleteClient()} key={"delete_client"}>
            {polyglot.t("Yes ,delete client")}
          </Button>,
        ]}
      >
        <>
          <div className="row">
            <div className="col-md-12 break-word">
              <p>
                {polyglot.t("Do you really want to delete the following Client")}:&nbsp;
                <b>{ClientData.clientid}</b> ?
              </p>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 break-word">
              {polyglot.t(
                "All data had added to project and Solutions will also eliminated and will no longer part of the system",
              )}
            </div>
          </div>
        </>
      </CustomModal>
    </>
  );
}
DeleteClient.propTypes = {
  show: PropTypes.bool,
  cancelClientModal: PropTypes.func,
  ClientData: PropTypes.object,
  history: PropTypes.object,
};
export default withRouter(DeleteClient);
