import { useContext, useState, useEffect } from "react";

import { DataContext } from "./Store";

export default (permission) => {
  const [isPermissionAvailable, setIsPermissionAvailable] = useState(false);
  const { getCurrentUserPermissions } = useContext(DataContext);
  useEffect(() => {
    const isVaildPermission = getCurrentUserPermissions().includes(permission);
    setIsPermissionAvailable(isVaildPermission);
  });
  return isPermissionAvailable;
};
