/* eslint-disable indent */
import { Pagination } from "antd";
import { Select, SelectOption, Panel } from "appkit-react";
import moment from "moment";
import React, { useEffect, useState } from "react";

import WorkflowCard from "./WorkflowCard";
import uploadImg from "../../../Assets/Images/DF/image-upload.svg";
import { ReactComponent as LoadingSpinner } from "../../../Assets/Images/QueenBase/Root/loading-spin.svg";
import { PER_PAGE_COUNT } from "../../../Constants/Constant";
import ProjectSurveyService from "../../../Services/DF/ProjectSurveyService";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";

const PER_PAGE = PER_PAGE_COUNT;

const filterOptions = [
  { filter_value: "pushedToProcessing", filter_label: "Processing", key_id: 1 },
  { filter_value: "parameterFileAvailable", filter_label: "File available", key_id: 2 },
  { filter_value: "dashboardAvailable", filter_label: "Dashboard available", key_id: 3 },
  { filter_value: "parameterFileRequested", filter_label: "File requested", key_id: 4 },
  { filter_value: "inProgress", filter_label: "In progress", key_id: 5 },
  { filter_value: "all", filter_label: "All", key_id: 6 },
];

// DISPLAY WORKFLOW LIST
const WorkflowList = (props) => {
  const polyglot = polyglotLoader();
  const { projectId, tabName } = props;
  const [fetchedWorkflows, setFetchedWorkflows] = useState([]);
  const [workflows, setWorkflows] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchStr, setSearch] = useState("");
  const [sortOrder, setSortOrder] = useState(""); // default sort by is done based on "Newest" records i.e. newest records on top
  const [filter, setFilter] = useState("all"); // default filter is 'all'

  // FETCH WORKFLOWS
  const fetchProjectSurvey = async () => {
    try {
      setLoading(true);
      // API TO FETCH LIST OF ALL WORKFLOWS BY PROJECTID
      const res = await ProjectSurveyService.get().getProjectSurveyDetails(projectId);
      setLoading(false);
      if (res.CODE) {
        let fetchProjectSurveys = res.data;
        if (tabName === "Dashboard") {
          fetchProjectSurveys = res.data.filter((record) => record.dashboard_url);
        }
        if (tabName === "Upload") {
          fetchProjectSurveys = res.data.filter(
            (record) => record.dashboard_url === null || record.dashboard_url === "",
          );
        }
        setWorkflows(fetchProjectSurveys);
        setFetchedWorkflows(fetchProjectSurveys);
        setSortOrder("Newest"); // default sort by is done based on "Newest" records i.e. newest records on top
      } else {
        displayToastMessage(res.MSG, "error");
      }
    } catch (error) {
      displayToastMessage(error.message, "error");
    }
  };

  // RUNS ON INTIAL LOAD
  useEffect(() => {
    fetchProjectSurvey();
  }, []);

  // ON SEARCH FUNCTION
  const onSearch = (e) => {
    setSearch(e.target.value);
    setCurrentPage(1);
  };
  //sortOrder useEffect
  useEffect(() => {
    if (sortOrder) {
      setCurrentPage(1);
      switch (sortOrder) {
        case "A-Z": {
          setWorkflows([
            ...fetchedWorkflows.sort((a, b) => {
              return a.workflow_name.localeCompare(b.workflow_name);
            }),
          ]);
          return;
        }
        case "Z-A": {
          const filteredList = fetchedWorkflows.sort((a, b) => {
            return b.workflow_name.localeCompare(a.workflow_name);
          });
          setWorkflows([...filteredList]);
          return;
        }
        case "Created On": {
          const filteredList = fetchedWorkflows.sort((a, b) => {
            return moment(a.created_at).isAfter(moment(b.created_at)) ? 0 : -1;
          });
          setWorkflows([...filteredList]);
          return;
        }
        case "Newest": {
          const filteredList = fetchedWorkflows.sort((a, b) => {
            return moment(a.created_at).isBefore(moment(b.created_at)) ? 0 : -1;
          });
          setWorkflows([...filteredList]);
          return;
        }
        default:
          return;
      }
    }
  }, [sortOrder]);

  // RUNS ON UPDATE OF FILTER
  useEffect(() => {
    const filteredList = fetchedWorkflows.filter((Item) => {
      switch (filter) {
        case "pushedToProcessing":
          return (
            Item.parameter_file_status === 3 &&
            (Item.dashboard_url === "" || !Item.dashboard_url) &&
            (Item.uploaded_file_path !== "" || Item.uploaded_file_path)
          );
        case "parameterFileAvailable":
          return (
            Item.parameter_file_status === 3 &&
            (Item.dashboard_url === "" || !Item.dashboard_url) &&
            (Item.uploaded_file_path === "" || !Item.uploaded_file_path)
          );
        case "dashboardAvailable":
          return Item.parameter_file_status === 3 && Item.dashboard_url;
        case "parameterFileRequested":
          return Item.parameter_file_status === 1;
        case "inProgress":
          return Item.parameter_file_status === 2;
        case "all":
          return Item;
      }
    });
    setWorkflows(filteredList);
  }, [filter]);

  // FILTER WORKFLOWS
  const filterItems = (items) => {
    let filteredItems = [...items];
    if (searchStr !== "") {
      filteredItems = items.filter((r) => r.workflow_name.toLowerCase().includes(searchStr.toLowerCase()));
    }
    return filteredItems;
  };

  // GET LIST TO SHOW ON CURRENT PAGE
  const getCurrentPageItems = (filteredItems) => {
    return filteredItems.slice((currentPage - 1) * PER_PAGE, currentPage * PER_PAGE);
  };

  // HANDLE PAGE CHANGE
  const handlePageChange = (change) => {
    setCurrentPage(change);
  };

  // DISPLAY LOADING SPINNER
  const displayLoadingSpinner = () => {
    return (
      <div className="row">
        <div className="col-md-2"></div>
        <div className="col-md-6" style={{ textAlign: "center" }}>
          <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
        </div>
        <div className="col-md-4"></div>
      </div>
    );
  };

  // DISPLAY INITIAL HEADING
  const displayInitialHeading = () => {
    if (tabName !== "DF-Home") {
      return (
        <>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6" style={{ textAlign: "center" }}>
              <img id="Analysis" src={uploadImg} alt="asserts" />
            </div>
            <div className="col-md-3"></div>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-md-6" style={{ textAlign: "center" }}>
              <div className="project_heading">
                <h1 className="main_title_qb mt-3 mb-2">{polyglot.t("Create some Workflows")}</h1>
                <div className="subtitle_three_title_qb_dark">
                  {polyglot.t("The workflows which are created are used to upload parameter file")}.
                </div>
              </div>
            </div>
            <div className="col-md-3"></div>
          </div>
        </>
      );
    }
  };

  // DISPLAY SEARCH BOX
  const displaySearchBox = () => {
    return (
      <div className="d-flex ml-0">
        <div className="col-md-12 p-0 m-b-2">
          <div className="row m-l-0 m-r-0 filter-search-qb">
            <div className={`searchIcon`}>
              <input
                value={searchStr}
                className="SearchBar search-input"
                placeholder={polyglot.t("Search")}
                onChange={onSearch}
                aria-label={polyglot.t("Search")}
              />
            </div>
            <div className="searchbar-qb-filter">
              <Select
                id="qb-filter-id"
                placeholder={polyglot.t("Filter")}
                onSelect={setFilter}
                value={filter}
              >
                {filterOptions.map((filterOptionsData) => {
                  return (
                    <SelectOption key={filterOptionsData.key_id} value={filterOptionsData.filter_value}>
                      {polyglot.t(filterOptionsData.filter_label)}
                    </SelectOption>
                  );
                })}
              </Select>
            </div>
            <div className="search-sort">
              <Select
                id="filter-id"
                placeholder={polyglot.t("Sort")}
                value={sortOrder}
                onSelect={setSortOrder}
                aria-label={polyglot.t("Sort")}
              >
                <SelectOption key="A-Z" value="A-Z">
                  {polyglot.t("Name")} &nbsp;
                  <span className="appkiticon icon-arrow-up-outline"></span>
                </SelectOption>
                <SelectOption key="Z-A" value="Z-A">
                  {polyglot.t("Name")} &nbsp;
                  <span className="appkiticon icon-arrow-down-outline"></span>
                </SelectOption>
                <SelectOption key="Newest" value="Newest">
                  {polyglot.t("Newest")}
                </SelectOption>
                <SelectOption key="Created On" value="Created On">
                  {polyglot.t("Oldest")}
                </SelectOption>
              </Select>
            </div>
          </div>
        </div>
      </div>
    );
  };

  // DISPLAY HEADING BANNER OF THE WORKFLOWS
  const readingBanner = () => {
    return (
      <div className="row m-0">
        <div className="col-md-8 Projecttab_n  Queen-project-lg-view pl-0 pr-0">
          <Panel className="a-panel-project mb-2">
            <div className="a-text-heading">
              <div className="row">
                <div className="col-md-3">
                  <div className="project-title-overflow project-data-desc view-center-name pl-0 pr-0">
                    {polyglot.t("Name")}
                  </div>
                </div>
                <div className="col-md-2">
                  <div className="project-title-overflow project-data-desc view-center-name pl-0 pr-0">
                    {polyglot.t("Created on")}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="project-title-overflow project-data-desc view-center-name pl-0 pr-0">
                    {polyglot.t("Status")}
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="project-title-overflow project-data-desc view-center-name-action pl-0 margin-left-minus-five">
                        {polyglot.t("Action")}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Panel>
        </div>
      </div>
    );
  };

  // DISPLAY WORKFLOW CARD
  const displayItemList = () => {
    return (
      <div>
        <div>
          <h1 className="main_title_qb m-b-18">{polyglot.t("List of workflows")}</h1>
          <div className="df-dashboard-search p-0"> {displaySearchBox()}</div>
          {readingBanner()}
          {filterItems(workflows).length > 0 && (
            <div>
              {getCurrentPageItems(filterItems(workflows)).map((Item, index) => {
                return <WorkflowCard key={index} Item={Item} index={index} setLoading={setLoading} />;
              })}
            </div>
          )}
        </div>
        {filterItems(workflows).length === 0 && displayInitialHeading()}
      </div>
    );
  };

  return (
    <div>
      {loading && displayLoadingSpinner()}
      {!loading && workflows.length === 0 && displayInitialHeading()}
      {!loading && fetchedWorkflows.length > 0 && displayItemList()}

      <div className="pagenationstyles">
        {!loading && filterItems(workflows).length > PER_PAGE && (
          <Pagination
            current={currentPage}
            size="small"
            pageSize={PER_PAGE}
            total={filterItems(workflows).length}
            onChange={handlePageChange}
          />
        )}
      </div>
    </div>
  );
};

export default WorkflowList;
