import { Progress } from "antd";
import { Panel } from "appkit-react";
import React from "react";

import analysis from "../../../../Assets/Icons/search-outline.svg";
import { polyglotLoader } from "../../../../Services/Shared/Translate";

// PROGRESS CHART ON THE HOME SCREEN
function ProgressChart({ targetListCount, items }) {
  const polyglot = polyglotLoader();

  // GET TOTAL LIST OF REQUESTS NUMBER
  const getTotalListOfRequests = () => {
    return targetListCount;
  };

  // GET PERCENTAGE USED
  const PercentageUsed = (VolumeUsed, TotalVolume) => {
    if (VolumeUsed && TotalVolume) {
      let PercentageUsed = (VolumeUsed * 100) / TotalVolume;
      return PercentageUsed;
    }
    return 0;
  };

  // GET STROKE COLOR BY STATUS NAME
  const getStrokeColorByName = (name) => {
    switch (name) {
      case "Client requested":
        return "#DEDEDE";
      case "Information requested":
        return "#DEDEDE";
      case "Processing":
        return "#FFA929";
      case "Report available":
        return "#006652";
      default:
        return "#808080";
    }
  };

  return (
    <Panel className="adduserpanel-three">
      <div className="d-flex justify-content-between a-mt-20 align-items-center card-spacing">
        <div>
          <span className="group-person ">
            <img src={analysis} alt="user" className="icon-card" />
            <h5 className="DF-User-data paddingLeft">{polyglot.t("List of requests")}</h5>
          </span>
        </div>
        <div className="main_title_qb group-icon">
          <b>{getTotalListOfRequests()}</b>&nbsp;
        </div>
      </div>
      <div className="custom-divider"></div>

      <div className="card-spacing">
        {/* ITERATING LIST OF STATUS NAMES AND IT'S COUNT TO DISPLAY PROGRESS BAR */}
        {items.map((item, index) => {
          const elementKey = 1 + index;
          return (
            <div key={elementKey}>
              <div className="">
                <Progress
                  percent={PercentageUsed(item.COUNT, targetListCount)}
                  width={40}
                  format={(percent) => {
                    return `${percent.toFixed(1)}%`;
                  }}
                  strokeColor={getStrokeColorByName(item.Status)}
                />
                <p className="projects-data-txt">{polyglot.t(item.Status)}</p>
              </div>
            </div>
          );
        })}
      </div>
    </Panel>
  );
}

export default ProgressChart;
