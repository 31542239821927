import displayToastMessage from "../../Components/QueenBase/Root/displayToastMessage";
class ErrorHandler {
  customErrorHandler(error) {
    if (error.status === 403) {
      displayToastMessage(error.data, "error");
      return {
        status: 403,
        message: "Unauthorize access ! you are not allowed to perform this action",
      };
    } else if (error.status === 404) {
      displayToastMessage(`Oops! Sorry we can't find the page you're looking for.`, "error");
      return {
        status: 404,
        message: `Oops! Sorry we can't find the page you're looking for.`,
      };
    } else if (error.status === 500) {
      displayToastMessage("There is some internal server error! please contact with admin team", "error");
      return {
        status: 500,
        message: `There is some internal server error! please contact with admin team.`,
      };
    } else {
      displayToastMessage(error.data, "error");
      return error;
    }
  }
}

// Enable Singleton behavior on this class
ErrorHandler.__instance = null;
ErrorHandler.get = () => {
  if (!ErrorHandler.__instance) {
    ErrorHandler.__instance = new ErrorHandler();
  }
  return ErrorHandler.__instance;
};

export default ErrorHandler;
