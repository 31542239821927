import jwtUtil from "../../Constants/Jwt";
import HttpClient from "../Shared/HttpClient";

class AdminService {
  constructor() {
    this.client = new HttpClient();
  }

  // API TO GET LIST OF WORKFLOWS WHICH CAN BE DISPLAYED ON UPLOAD TAB ON ADMIN SCREEN
  async getUploadDetails(projectId) {
    const params = { projectid: projectId };
    const result = await this.client.dfget("/getUploadDetails", params);
    return result;
  }
  // API TO GET VIEW MORE DETAILS OF WORKFLOW - UPLOAD TAB ON ADMIN SCREEN
  async getUpload_ViewMore_Details(projectId, projectSurveyID) {
    const params = { projectid: projectId, projectSurveyID: projectSurveyID };
    const result = await this.client.dfget("/getUpload_ViewMore_Details", params);
    return result;
  }
  // API TO GET VIEW MORE DETAILS OF WORKFLOW - PROCESS TAB ON ADMIN SCREEN
  async getProcess_ViewMore_Details(projectId, projectSurveyID) {
    const params = { projectid: projectId, projectSurveyID: projectSurveyID };
    const result = await this.client.dfget("/getProcess_ViewMore_Details", params);
    return result;
  }
  // API TO UPLOAD PARAMETER FILE AND STATUS OF THE WORKFLOW
  async editUploadService(projectId, formData) {
    var encrypteData = jwtUtil.encrypt(projectId);
    const result = await this.client.dfpost("/editUploadService", formData, {
      ...encrypteData,
      "Content-Type": "multipart/form-data",
    });
    return result;
  }
  // API TO GET WORKFLOWS WHICH ARE DISPLAYED ON PROCESS SCREEN - ADMIN TAB
  async getProcessDetails(projectId) {
    const params = { projectid: projectId };
    const result = await this.client.dfget("/getProcessDetails", params);
    return result;
  }
  // API TO SUBMIT EDIT FORM OF A WORKFLOW ON PROCESS SCREEN
  async editProcessService(projectId, data) {
    var encrypteData = jwtUtil.encrypt(projectId);
    const result = await this.client.dfpost("/editProcessService", data, encrypteData);
    return result;
  }
}
// Enable Singleton behavior on this class
AdminService.__instance = null;
AdminService.get = () => {
  if (!AdminService.__instance) {
    AdminService.__instance = new AdminService();
  }
  return AdminService.__instance;
};

export default AdminService;
