import React, { useState, memo, useEffect } from "react";
import { polyglotLoader } from "../../../Services/Shared/TranslateGRC";
import { Input, TextArea } from "appkit-react";
import { Formik } from "formik";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import { useHttpClient } from "../../../Services/GRC/http-hook";
import { useHttpPostClient } from "../../../Services/GRC/http-hook-post";
import CategoryService from "../../../Services/GRC/CategoryService";
import HeaderSection from "./HeaderSection.js";
import "./index.css";
import QuestionService from "../../../Services/GRC/QuestionService";
import { useHttpPutClient } from "../../../Services/GRC/http-hook-put";
import { categoryValidationSchema } from "../../../utility/textFieldValidation";

const CreateForm = ({
  categoryType,
  setlatestId,
  categoryId,
  categoryPath,
  mode,
  setlatestRootId,
  categoryMode,
  incrementCategoryTypeByOne,
  getCurrentCategoryURL,
  fromMode,
}) => {
  const { sendRequest } = useHttpClient();
  const polyglot = polyglotLoader();
  const { sendRequestPost } = useHttpPostClient();
  const { sendRequestPut } = useHttpPutClient();

  const initialValuesOfForm = {
    id: 0,
    name: "",
    description: "",
  };

  const [initialFormValues, setInitialFormValues] = useState(initialValuesOfForm);
  const [categoryTypeLocal, setcategoryType] = useState(1);
  const [existCategoryId, setExistCategoryId] = useState(-1);
  const [currentRootId, setCurrentRootId] = useState(0);
  const [loadingDone, setLoadingDone] = useState(false);

  const onLoadCategories = async () => {
    try {
      setLoadingDone(false);

      if (categoryMode !== "add" && mode === "edit") {
        const responseData = await sendRequest(CategoryService.get().getCategoryURL(categoryId));
        let initialValuesOfEditForm = {};
        initialValuesOfEditForm.id = responseData[0].id;
        initialValuesOfEditForm.name = responseData[0].name;
        initialValuesOfEditForm.description = responseData[0].description;
        setCurrentRootId(responseData[0].root_id);
        setInitialFormValues(initialValuesOfEditForm);
      } else {
        let initialValuesOfFormAdd = {
          id: 0,
          name: "",
          description: "",
        };
        setLoadingDone(true);
        setInitialFormValues(initialValuesOfFormAdd);
        setCurrentRootId(categoryId);
      }
      setcategoryType(categoryType);
      setLoadingDone(true);
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  useEffect(() => {
    onLoadCategories();
  }, [sendRequest, categoryType, categoryId]);

  const handlerCategorySubmit = async (values, { setSubmitting, setErrors, setStatus, resetForm }) => {
    if (categoryMode === "add" && existCategoryId !== 0) {
      displayToastMessage(polyglot.t("name.existsError"), "error");
      setExistCategoryId(-1);
      return;
    }
    if (categoryMode === "add") {
      await createCategory(values, resetForm);
    } else if (categoryMode === "edit") {
      // update

      if (existCategoryId <= 0) {
        await updateCategory(values, resetForm);
      } else {
        if (existCategoryId == categoryId) {
          await updateCategory(values, resetForm);
        } else {
          displayToastMessage(polyglot.t("name.existsError"), "error");
          setExistCategoryId(-1);
          return;
        }
      }
      await onLoadCategories();
    }
  };

  const created_by = JSON.parse(sessionStorage.getItem("user")).userid;
  const createCategory = async (values, resetForm) => {
    const { name, description } = values;
    const categoryData = {
      name,
      description,
      created_by,
      root_id: categoryId ?? 0,
      level_id: categoryType ?? 0,
      language_id: 1,
    };

    const response = await sendRequestPost(
      CategoryService.get().postCategoryURL(),
      JSON.stringify(categoryData),
      {
        "Content-Type": "application/json",
      },
    );
    if (response > 0) {
      if (categoryType === 0) {
        setlatestRootId(response);
      }
      setlatestId(response);
      switch (categoryType) {
        case 0:
          displayToastMessage(polyglot.t("questionnaire.successfully.added"), "success");
          break;
        case 1:
          displayToastMessage(polyglot.t("category.successfully.added"), "success");
          break;
        case 2:
          displayToastMessage(polyglot.t("subcategory.successfully.added"), "success");
          break;
      }

      resetForm({});
      setInitialFormValues(initialValuesOfForm);
      const incrementingBody = {
        name,
        categoryIdNumber: response,
      };
      incrementCategoryTypeByOne(incrementingBody);
    }
  };
  const updateCategory = async (values, resetForm) => {
    let formObj = { ...values };
    const modified_by = JSON.parse(sessionStorage.getItem("user")).userid;
    const categoryData = {
      name: formObj.name,
      description: formObj.description,
      modified_by: modified_by,
      category_id: categoryId === undefined ? 0 : categoryId,
      language_id: 1,
    };
    /*   const response = await sendRequest(     
      `${process.env.REACT_APP_SERVER_GRC_BASE_HOST}/updateCategory`,
      "PUT",
      JSON.stringify(categoryData),
      {
        "Content-Type": "application/json",
      }
    ); */

    const response = await sendRequestPut(
      QuestionService.get().updateCategoryURL(),
      JSON.stringify(categoryData),
      {
        "Content-Type": "application/json",
      },
    );

    if (response > 0) {
      if (categoryType === 0) {
        setlatestRootId(response);
      }
      setlatestId(response);
      switch (categoryType) {
        case 0:
          displayToastMessage(polyglot.t("questionnaire.successfully.updated"), "success");
          break;
        case 1:
          displayToastMessage(polyglot.t("category.successfully.updated"), "success");
          break;
        case 2:
          displayToastMessage(polyglot.t("subcategory.successfully.updated"), "success");
          break;
      }
      resetForm({});
      setInitialFormValues(initialValuesOfForm);
      getCurrentCategoryURL();
      const incrementingBody = {
        name: formObj.name,
        categoryIdNumber: response,
      };
      incrementCategoryTypeByOne(incrementingBody);
    }
  };
  const handleDuplicateCheck = async (e) => {
    e.preventDefault();
    const categoryName = e.currentTarget.value;
    try {
      const categoryCheck = {
        name: categoryName,
        level_id: categoryType === undefined ? 0 : categoryType,
        root_id: currentRootId,
      };

      const responseData = await sendRequestPost(
        CategoryService.get().postExistCategoryURL(),
        JSON.stringify(categoryCheck),
        {
          "Content-Type": "application/json",
        },
      );
      setExistCategoryId(responseData);
      initialValuesOfForm.existCategory = existCategoryId;
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  const renderHeaderSection = () => {
    try {
      return (
        <HeaderSection
          cond={categoryType}
          categoryPath={categoryPath}
          categoryMode={categoryMode}
          fromMode={fromMode}
        />
      );
    } catch (err) {
      displayToastMessage(err.message, "error");
    }
  };

  return (
    <div className="row pl-0 mb-3 mr-0 ml-0">
      <div className="col-md-12 p-0">
        {parseInt(categoryType) === Number(categoryType) && renderHeaderSection()}
        {loadingDone && initialFormValues && (
          <Formik
            initialValues={initialFormValues}
            onSubmit={handlerCategorySubmit}
            validationSchema={categoryValidationSchema}
          >
            {function Render(props) {
              const {
                values,
                handleChange,
                handleBlur,
                handleSubmit,
                errors,
                touched,
                setFieldValue,
                isValid,
                isSubmitting,
                dirty,
              } = props;

              return (
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        inputBoxSize="sm"
                        placeholder={`${polyglot.t("name.label")}*`}
                        value={values.name}
                        onBlur={(e) => {
                          handleBlur("name");
                          handleDuplicateCheck(e);
                        }}
                        onChange={handleChange("name")}
                        aria-label={polyglot.t("name.label")}
                      />
                      {errors.name && touched.name ? <div className="error">{errors.name}</div> : null}
                      <br />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <TextArea
                        placeholder={`${polyglot.t("description.label")}*`}
                        value={values.description}
                        onChange={handleChange("description")}
                        className="Notes proj-notes-txt-area"
                        aria-label={polyglot.t("description.label")}
                      />
                      <div>{values.description.length}/2000</div>
                      {errors.description && touched.description ? (
                        <div className="error">{errors.description}</div>
                      ) : null}
                      <br />
                    </div>
                    <div className="noofcharacters"></div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      {categoryMode === "edit" ? (
                        <ButtonPrimary
                          className="custome-pos"
                          onClick={handleSubmit}
                          ariaLabel={polyglot.t("save")}
                        >
                          Update
                        </ButtonPrimary>
                      ) : (
                        <ButtonPrimary
                          className="custome-pos"
                          onClick={handleSubmit}
                          ariaLabel={polyglot.t("save")}
                          disabled={
                            isSubmitting ||
                            !dirty ||
                            !(Object.keys(errors).length === 0 && errors.constructor === Object)
                          }
                        >
                          Save
                        </ButtonPrimary>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12 edit-clientform-button p-0">
                    <div className="d-flex justify-content-between"></div>
                  </div>
                </div>
              );
            }}
          </Formik>
        )}
      </div>
    </div>
  );
};
export default memo(CreateForm);
