import React from "react";
import { Switch } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import AdminContainer from "../Components/QueenBase/Admin/AdminContainer";
import ClientInvoiceList from "../Components/QueenBase/Admin/ClientInvoiceList";
import * as path from "../Constants/Path";

// ROUTES OF ADMIN SCREENS
function AdminRoute() {
  return (
    <div>
      <Switch>
        <ProtectedRoute path={path.VIEW_ADMIN} component={AdminContainer} />
        <ProtectedRoute path={path.VIEW_ADMIN_INVOICE} component={ClientInvoiceList} />
      </Switch>
    </div>
  );
}

export default AdminRoute;
