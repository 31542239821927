import LeftPanelQuestionaris from "../LeftPanelQuestionaris";

import { Panel, Progress } from "appkit-react";
import React, { useState, useEffect, useCallback } from "react";

import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import { ReactComponent as LoadingSpinner } from "../../../../Assets/Images/GRC/loading-spin.svg";
import displayToastMessage from "../../../../Components/QueenBase/Root/displayToastMessage";
import { ASSESSMENT_PREVIEW_PATH } from "../../../../Constants/Path";
import AssessmentService from "../../../../Services/GRC/AssessmentService";
import { useHttpClient } from "../../../../Services/GRC/http-hook";
import { useHttpPostClient } from "../../../../Services/GRC/http-hook-post";
import { polyglotLoader } from "../../../../Services/Shared/TranslateGRC";
import ErrorPanel from "../../Shared/ErrorPanel";
import "./index.css";
import "../../../../Assets/Css/GRC/style.css";

import DropdownContainer from "../DropdownContainer";
/**
 * During assessment creation, custome questionanarie created from here
 *
 * @param {props} props
 * @returns
 */
const SelectQuestions = (props) => {
  const mapA = {};
  const rootsA = [];
  const polyglot = polyglotLoader();
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const { isLoadingPost, errorPost, sendRequestPost, clearErrorPost } = useHttpPostClient();
  const [categories, setCategories] = useState([]);
  const [totalNumberOfQuestions, setTotalNumberOfQuestions] = useState(0);
  const [selectedNodes, setSelectedNodes] = useState([]);

  const assessmentId =
    props.location.state !== undefined ? (
      props.location.state.id
    ) : (
      <ErrorPanel error={error} onClear={clearError} />
    );

  useEffect(() => {
    const getSelectedQuestionaries = async () => {
      try {
        const selectedQuestionnaires = await sendRequest(
          AssessmentService.get().getAssessmentCategoriesByIdURL(assessmentId),
        );

        const baseClassNames = "custome-border appkiticon material-icons ";
        const iconsForDepth = [
          "icon-folder-closed-outline",
          "icon-folder-closed-outline",
          "icon-news-outline",
          "icon-circle-checkmark-outline",
        ];

        selectedQuestionnaires.forEach((node, i) => {
          mapA[node.value] = i;
          node.expanded = true;
          node.className = baseClassNames + iconsForDepth[node.depth];
          selectedQuestionnaires[i].children = [];
        });

        selectedQuestionnaires.forEach((node) => {
          node.tagLebel !== 0
            ? selectedQuestionnaires[mapA[node.tagLebel]].children.push(node)
            : rootsA.push(node);
        });
        setCategories(rootsA);
      } catch (err) {
        console.log(err);
      }
    };

    getSelectedQuestionaries();
  }, [sendRequest]);

  const handleSubmitAssessmentQuestions = async () => {
    if (selectedNodes.length === 0) {
      displayToastMessage("Please select any questions", "error");
      return;
    }

    const createdBy = JSON.parse(sessionStorage.getItem("user")).userid;

    const postinput = {
      assessment_id: assessmentId,
      created_by: createdBy,
      selected_questions: selectedNodes.join(","),
    };

    const response = await sendRequestPost(
      AssessmentService.get().postAssessmentQurstionsURL(),
      JSON.stringify(postinput),
      {
        "Content-Type": "application/json",
      },
    );

    //go to next : preview
    props.history.push({
      pathname: ASSESSMENT_PREVIEW_PATH,
      state: {
        mode: "add",
        id: assessmentId,
        title: props.location.state.title,
      },
    });
    return;
  };

  const onChange = useCallback(async (_currentNode, selectedNodes) => {
    const selectedNodesIds = selectedNodes.map((node) => node.value);
    setSelectedNodes(selectedNodesIds);

    const responseCountData = await AssessmentService.get().postAssessmentQuestionCountURL(
      selectedNodesIds.join(","),
    );

    setTotalNumberOfQuestions(responseCountData);
  }, []);

  return (
    <div className="row m-0">
      <div className="col-md-3 pl-0 mb-2 d-inline-block">
        <Panel className="panel h-100 panel-top-free-space">
          <LeftPanelQuestionaris selectedId={assessmentId} />
        </Panel>
      </div>

      <div className="col-md-9 usertoadd-responsive p-0 mt-3 pr-3">
        <div className="row">
          <div className="col-md-12 grc-assment-progressbar">
            <Progress percent={45} indicators={["Create", "Set Questions", "Preview"]} />
          </div>
        </div>
        <Panel className="p-0 mt-3">
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <span className="right-panel-heading width-40">
                    {props.location.state !== undefined ? props.location.state.title : ""}
                  </span>
                  <span className="float-right width-40">
                    Total number of questions :
                    <span id="select-total-questions" className="highlight-red">
                      {totalNumberOfQuestions}
                    </span>
                  </span>
                </div>
                {props.location.state != null && error && <ErrorPanel error={error} onClear={clearError} />}
                {isLoading && (
                  <div>
                    <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
                  </div>
                )}
                {!isLoading && categories && (
                  <div className="panel-top-free-space left-20-padding right-20-padding">
                    <DropdownContainer
                      data={categories}
                      className="mdl-assessment"
                      showDropdown={"always"}
                      showPartiallySelected={true}
                      onChange={onChange}
                    />
                  </div>
                )}
                <div className="card-footer bg-transparent">
                  <ButtonPrimary
                    className="custome-pos"
                    onClick={(e) => handleSubmitAssessmentQuestions(e)}
                    ariaLabel={polyglot.t("save")}
                  >
                    {polyglot.t("save")}
                  </ButtonPrimary>
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </div>
    </div>
  );
};

export default SelectQuestions;
