export const LOGIN_PATH = "/login";
export const FORGOTKEY_PATH = "/forgotkey";
export const ENTER_NEW_KEY = "/enter-new-key";
export const UPDATE_USER_PROFILE = "/update-user-profile";
export const QUEEN_BASE_PATH = "/queenbase";
export const PROJECT_PATH = QUEEN_BASE_PATH + "/projects";
export const SOLUTION_PATH = QUEEN_BASE_PATH + "/solutions";
export const USER_PATH = QUEEN_BASE_PATH + "/users";
export const ADMIN_PATH = QUEEN_BASE_PATH + "/admin";
export const SUPPORT_PATH = QUEEN_BASE_PATH + "/support";
export const COMPANY_CENTER_PATH = QUEEN_BASE_PATH + "/companycenter";
export const DASHBOARD_PATH = QUEEN_BASE_PATH + "/mydashboard";
export const VIEW_PROJECT_PATH = PROJECT_PATH + "/viewprojects";
export const EDIT_PROJECT_PATH = PROJECT_PATH + "/editproject";
export const CREATE_PROJECT_PATH = PROJECT_PATH + "/createproject";
export const SOLUTION_LIST_PATH = SOLUTION_PATH + "/solution";
export const SOLUTION_REVIEW_PATH = SOLUTION_PATH + "/reviewsolution";
export const USER_PROFILE = "/userprofile";
export const VIEW_ADMIN = ADMIN_PATH + "/view";
export const PAGENOTFOUND_PATH = "/pagenotfound";
export const COMPANY_CENTER_VIEW_PATH = COMPANY_CENTER_PATH + "/view";
export const BASEPACKAGEUPGRADATION_PATH = COMPANY_CENTER_PATH + "/basePackageUpgradation";
export const VIEW_ADMIN_INVOICE = ADMIN_PATH + "/invoice";
export const IDAM_PATH = "/idam";
export const CHOOSE_CLIENT_ON_LOGIN = "/choose-client-onlogin";
export const FOOTER_DISCLAMIER_PATH = "/disclamier";
export const FOOTER_PRIVACY_PATH = "/privacy";
export const FOOTER_TERMS_OF_USE_PATH = "/term-of-use";
export const FOOTER_CREDITS_PATH = "/credits";
export const FOOTER_CREDITS_COOKIES = "/cookies";

// DF PATHS
export const DF_PATH = "/df";
export const DF_HOME = DF_PATH + "/home";
export const DF_UPLOAD = DF_PATH + "/upload";
export const DF_DASHBOARD = DF_PATH + "/dashboard";
export const DF_ADMIN = DF_PATH + "/admin";
export const DF_START = DF_PATH + "/start";
export const DF_SUPPORT = DF_PATH + "/support";

// CSS PATHS
export const CSS_PATH = "/css";
export const CSS_HOME = CSS_PATH + "/history";
export const CSS_SEARCH = CSS_PATH + "/search";
export const CSS_ADMIN = CSS_PATH + "/admin";
export const CSS_SUPPORT = CSS_PATH + "/support";

// GI PATHS
export const GI_PATH = "/gi";
export const GI_HOME = GI_PATH + "/home";
export const GI_ADMIN = GI_PATH + "/admin";

// DFED PATHS
export const DFED_PATH = "/dfed";
export const DFED_HOME = DFED_PATH + "/home";

// RI PATHS
export const RI_PATH = "/ri";
export const RI_HOME = RI_PATH + "/home";
//GRC
// GRC Category & Question
export const GRC_PATH = "/grc";
export const CREATE_QUESTION_PATH = GRC_PATH + "/createquestion";
export const CREATE_CATEGORY_PATH = GRC_PATH + "/createcategory";

export const CREATE_QUESTIONNAIRE_PATH = GRC_PATH + "/questionnaire";
export const CREATE_GRCADMIN_PATH = GRC_PATH + "/grcadmin";
export const CREATE_GRC_HOME = GRC_PATH + "/home";
export const EDIT_QUESTIONNAIRE_PATH = GRC_PATH + "/EditQuestion";

// GRC Assessment
export const CREATE_ASSESSMENT_PATH = GRC_PATH + "/Assessment/Pages/AssessmentHome";
export const ASSESSMENT_CLIENT_QUESTIONS_PATH = GRC_PATH + "/Assessment/Pages/ClientQuestions";
export const ASSESSMENT_CREATE_PATH = GRC_PATH + "/Assessment/Pages/Create";
export const ASSESSMENT_VIEW_PATH = GRC_PATH + "/Assessment/Pages/AssessmentView";
export const ASSESSMENT_PREVIEW_PATH = GRC_PATH + "/Assessment/Pages/Preview";
export const ASSESSMENT_RECIPIENT_SHARE_PATH = GRC_PATH + "/Assessment/Pages/RecipientAssessmentShare";
export const ASSESSMENT_SELECTQUESTIONS_PATH = GRC_PATH + "/Assessment/Pages/SelectQuestions";

export const RECIPIENT_ANSWERS_PATH = GRC_PATH + "/PerformAssessment/Pages/RecipientAnswers";
export const RECIPIENT_RESPONSE_DETAILS_PATH = GRC_PATH + "/RecipientResponse/Pages/RecipientResponseDetails";
export const RECIPIENT_RESPONSE_PATH = GRC_PATH + "/RecipientResponse/Pages/RecipientResponse";

export const ASSESSMENT_GRID_VIEW_PATH = GRC_PATH + "/assessment-grid-view";
export const ASSESSMENT_LIST_VIEW_PATH = GRC_PATH + "/assessment-list-view";
/// GRC END ///
