import React, { useState, useEffect } from "react";

import ButtonPrimaryWithIcon from "../../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import { plusCircle } from "../../../../Assets/Icons";
import { ReactComponent as LoadingSpinner } from "../../../../Assets/Images/GRC/loading-spin.svg";
import * as path from "../../../../Constants/Path";
import AssessmentService from "../../../../Services/GRC/AssessmentService";
import { useHttpClient } from "../../../../Services/GRC/http-hook";
import { getProjectID_Of_solution_module } from "../../../../Services/Shared/Handlers";
import { polyglotLoader } from "../../../../Services/Shared/TranslateGRC";
import ErrorPanel from "../../Shared/ErrorPanel";
import AssessmentListView from "../AssessmentListView";

/**
 * Landing page assessment section,display all list of assessments
 *
 * @param {props} props
 * @returns
 */
const AssessmentHome = (props) => {
  const [loadAssessment, setLoadAssessment] = useState([]);
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const polyglot = polyglotLoader();

  useEffect(() => {
    const getAssessment = async () => {
      const projectid = getProjectID_Of_solution_module();
      const userid = JSON.parse(sessionStorage.getItem("user")).userid;
      try {
        const responseData = await sendRequest(
          AssessmentService.get().getAssessmentsByProjectURL(projectid, userid),
        );

        setLoadAssessment(responseData);
      } catch (err) {
        alert(err);
      }
    };
    getAssessment();
  }, [sendRequest]);

  const handleCreateQuestionnaire = () => {
    try {
      localStorage.removeItem("selectedtags");
    } catch (err) {
      console.log(err);
    }
    props.history.push({
      pathname: path.ASSESSMENT_CREATE_PATH,
    });
  };

  return (
    <div className="grchome">
      <div className="row justify-content-start p-0 pr-3">
        <div className="col-md-12 pl-0">
          <h1 className="main_title_qb ">{polyglot.t("list_of_assessments")}</h1>
        </div>
      </div>

      <div className="row justify-content-start p-0 pr-3">
        <div className="col-md-4 justify-content-between pl-0 custome-left">
          <ButtonPrimaryWithIcon
            iconName={plusCircle}
            onClick={handleCreateQuestionnaire}
            ariaLabel={polyglot.t("create")}
          >
            {polyglot.t("create")}
          </ButtonPrimaryWithIcon>
        </div>
      </div>
      <div className="row justify-content-start p-0 pr-3">
        {error && <ErrorPanel error={error} onClear={clearError} />}
        {isLoading && (
          <div>
            <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
          </div>
        )}

        {!isLoading && loadAssessment.length >= 0 && <AssessmentListView grid_data={loadAssessment} />}
      </div>
    </div>
  );
};
export default AssessmentHome;
