import axios from "axios";

import HttpClient from "../Shared/HttpClient";

axios.defaults.baseURL = process.env.REACT_APP_SERVER_HOST;
axios.defaults.headers.post["Content-Type"] = "application/json";

export async function getPwcAdminAnalysisUploadedFile(analysisId) {
  const client = new HttpClient();
  const res = await client.dfedGet(`/getPwcAdminAnalysisUploadedFile/${analysisId}`);
  return res;
}

export async function getAnslysisKeywordDetails(projectId) {
  const client = new HttpClient();
  const res = await client.dfedPost("/getAnsKeywordDetailsAnalysisGrid", { projectId: projectId });
  return res;
}
export async function getreviewdetailsGridDetails(projectId) {
  const client = new HttpClient();
  const res = await client.dfedGet(`/getreviewdetailsGrid/${projectId}`);
  return res;
}
export async function editReviewRunMapService(formData) {
  const client = new HttpClient();
  const res = await client.dfedPost("/editReviewRunMap", formData, { "Content-Type": "multipart/form-data" });
  return res;
}
export async function getproductiondetailsGridDetails(projectId) {
  const client = new HttpClient();
  const res = await client.dfedGet(`/getproductiondetailsGrid/${projectId}`);
  return res;
}

export async function editProductionService(formData) {
  const client = new HttpClient();
  const res = await client.dfedPost("/editProductionService", formData, {
    "Content-Type": "multipart/form-data",
  });
  return res;
}

export async function uploadAnalysisGridFile(formData) {
  const client = new HttpClient();
  const res = await client.dfedPost("/uploadAnaGridFile", formData, {
    "Content-Type": "multipart/form-data",
  });
  if (res) {
    if (res.hasOwnProperty("status") && res.status === 200) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
}

export async function editAnalysisRunMapService(data, editId, pipeLineId, analysisId, projectID) {
  const client = new HttpClient();
  const res = await client.dfedPost("/editAnalysisRunTMap", {
    data: data,
    editId: editId,
    pipeLineId: pipeLineId,
    analysisId: analysisId,
    projectId: projectID,
  });
  return res;
}

export async function getAnalysisDetailsByRunThrougIdService(tempRunThrougId) {
  const client = new HttpClient();
  const res = await client.dfedPost("/getAnaRunThrgDetalsById", { armID: tempRunThrougId });
  return res;
}
export async function getdataForProcessingAdmin(projectID) {
  const client = new HttpClient();
  const res = await client.dfedGet(`/getdataForProcessingAdmin/${projectID}`);
  return res;
}
export async function getProcessingDatasetsDetailsAdmin(DataSetID, ProjectID) {
  const client = new HttpClient();
  const res = await client.dfedGet(`/getProcessingDatasetsDetailsAdmin/${ProjectID}/${DataSetID}`);
  return res;
}
export async function postProcessAdminDetails(
  collectionStatus,
  processingStatus,
  id,
  fastfarwordID,
  ProjectID,
  processingTime,
) {
  const client = new HttpClient();
  const res = await client.dfedPost("/postProcessAdminDetails", {
    collectionStatus: collectionStatus,
    processingStatus: processingStatus,
    id: id,
    fastfarwordID: fastfarwordID,
    ProjectID: ProjectID,
    processingTime,
  });
  return res;
}
