module.exports = {
  checkPermission: function (rolePermission, ModuleName, action) {
    try {
      let isPermission = false;
      if (ModuleName === rolePermission.ModuleName && rolePermission.Permission.includes(action)) {
        isPermission = true;
        return isPermission;
      } else {
        isPermission = false;
        return isPermission;
      }
      // }else{
      // 	return false
      // }
    } catch (err) {
      return false;
    }
  },
};
