import CommonService from "../Shared/CommonService";

class QuestionService {
  constructor() {
    this.baseApiUrl = CommonService.get().getGRCBaseApiUrl();
  }

  getAssessmentTypesURL() {
    return `${this.baseApiUrl}/getAssessmentTypes`;
  }
  getAnswerTypeURL() {
    return `${this.baseApiUrl}/getAnswerType`;
  }
  getTagsURL() {
    return `${this.baseApiUrl}/getTags`;
  }
  getEditQuestionDataURL(question_id) {
    return `${this.baseApiUrl}/getEditQuestionData/${question_id}`;
  }
  getQuestionsbyParent(categoryid) {
    return `${this.baseApiUrl}/getQuestionsbyParent/3/${categoryid}`;
  }
  putQuestionURL() {
    return `${this.baseApiUrl}/putQuestion`;
  }
  putDependencyQuestionURL() {
    return `${this.baseApiUrl}/putDependencyQuestion`;
  }
  postQuestionURL() {
    return `${this.baseApiUrl}/postQuestion`;
  }
  postDependancyQuestionURL() {
    return `${this.baseApiUrl}/postDependancyQuestion`;
  }
  getTagsByQuestionIdURL(question_id) {
    return `${this.baseApiUrl}/getTagsByQuestionId/${question_id}`;
  }
  getEditDependencyQuestionDataURL(resp) {
    return `${this.baseApiUrl}/getEditDependencyQuestionData/${resp}`;
  }
  updateCategoryURL() {
    return `${this.baseApiUrl}/updateCategory`;
  }
  getClientsList() {
    return `${this.baseApiUrl}/getClientsList`;
  }
}

// Enable Singleton behavior on this class
QuestionService.__instance = null;
QuestionService.get = () => {
  if (!QuestionService.__instance) {
    QuestionService.__instance = new QuestionService();
  }
  return QuestionService.__instance;
};

export default QuestionService;
