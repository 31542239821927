import { Select, SelectGroupTitle, SelectOption, Input } from "appkit-react";
import { Formik, FieldArray, useField } from "formik";
import $ from "jquery";
import React, { useEffect, useContext, useState } from "react";

import ButtonIcon from "../../../../Assets/Components/ButtonIcon/ButtonIcon";

import TagsInput from "react-tagsinput";
import { object, string, number } from "yup";

import ButtonPrimary from "../../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonPrimaryWithIcon from "../../../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import CustomModal from "../../../../Assets/Components/Modal";
import { plusCircle } from "../../../../Assets/Icons";
import AdminServiceRI from "../../../../Services/RI/AdminService";
import { getProjectID_Of_solution_module } from "../../../../Services/Shared/Handlers";
import { DataContext } from "../../../../Services/Shared/Store";
import { polyglotLoader } from "../../../../Services/Shared/Translate";
import displayToastMessage from "../../../QueenBase/Root/displayToastMessage";

function ProcessGridEdit(props) {
  const { show, toggleEditModal, formBody, getTargetList } = props;
  const polyglot = polyglotLoader();
  const { getUserLanguage } = useContext(DataContext);
  const [statusList, setStatusList] = useState([
    { ID: "1", value: "Request placed" },
    { ID: "2", value: "Data cleansing" },
    { ID: "3", value: "Processing" },
    { ID: "4", value: "Dashboard available" },
  ]);
  const [selectedStatus, setSelectedStatus] = useState("");
  const [fileObj, setFileObj] = useState({});
  const [tagsEntity, setTagsEntity] = useState([]);
  const [currentTableauUrl, setCurrentTableauUrl] = useState("");

  const handleStatus = (st) => {
    switch (st) {
      case "1":
        $("#cleenDataset").show();
        $("#processing").hide();
        $("#dashboard").hide();
        $("#cleanDataset-status2").hide();
        break;
      case "2":
        $("#cleenDataset").show();
        $("#processing").hide();
        $("#dashboard").hide();
        $("#cleanDataset-status2").show();
        break;
      case "3":
        $("#cleenDataset").hide();
        $("#processing").show();
        $("#dashboard").hide();
        $("#cleanDataset-status2").hide();
        break;
      case "4":
        $("#cleenDataset").hide();
        $("#processing").hide();
        $("#dashboard").show();
        $("#cleanDataset-status2").hide();
        break;
      default:
        $("#cleenDataset").hide();
        $("#processing").hide();
        $("#dashboard").hide();
        $("#cleanDataset-status2").hide();
    }
  };

  handleStatus(selectedStatus);

  const getGroupsValues = (formBody) => {
    let grps1 = [];
    if (formBody.groupName) {
      formBody.groupName.map((e, i) => {
        grps1.push({ id: i, grp: e.grp, target: e.target.join(",") });
      });
      return grps1;
    }
    let grps = [];
    if (formBody.entities) {
      formBody.entities.map((e, i) => {
        grps.push({ id: i, grp: e, target: "" });
      });
    }
    return grps;
  };

  // INITIAL VALUES OF THE FORM
  const initialValues = {
    status: formBody.status,
    entities: formBody.entities,
    tableauUrl: formBody.tableauURL,
    generateQueryStatus: formBody.generateQueryStatus,
    initialRun: formBody.initialRun,
    scheduleDownload: formBody.scheduleDownload,
    groups: getGroupsValues(formBody),
  };

  const getValidationShape = () => {
    const schema = {
      status: number().required("Status is required"),
    };

    if (selectedStatus === "3") {
      // i.e. status = processing
      schema.generateQueryStatus = string().required("Generate query is required");
      schema.initialRun = string().required("Initial run is required");
      schema.scheduleDownload = string().required("Schedule download is required");
    }
    if (selectedStatus === "4") {
      // i.e. status = dashboard available
      const URL =
        /^((https?|ftp):\/\/)?(www.)?(((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:)*@)?(((\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5])\.(\d|[1-9]\d|1\d\d|2[0-4]\d|25[0-5]))|((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.?)(:\d*)?)(\/((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)+(\/(([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)*)*)?)?(\?((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|[\uE000-\uF8FF]|\/|\?)*)?(\#((([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(%[\da-f]{2})|[!\$&'\(\)\*\+,;=]|:|@)|\/|\?)*)?$/i;

      schema.tableauUrl = string().matches(URL, "Enter a valid url").required("Tableau Url is required");
    }

    return schema;
  };

  const validationShape = getValidationShape();
  // VALIDATION SCHEMA FOR FORM
  const validationSchema = object().shape(validationShape);

  // CHECK FORM VALIDATION
  const checkFormValidation = (statusId) => {
    return true;
  };

  // SUBMIT THE FORM
  const submitForm = async (values, actions) => {
    try {
      const projectId = getProjectID_Of_solution_module();
      const id = Number(formBody.id);
      let formData = new FormData();

      // appending process grid form details
      const formObj = { ...values };
      formObj.projectId = projectId;
      formObj.id = id;
      formObj.entities = formBody.entities;
      let targetError = false;
      let targetName = [];
      const formValidationSuccess = checkFormValidation(values.status);
      if (formObj.status == "1" || formObj.status == "2") {
        $(".inputrow").each((i, e) => {
          let inputId = e.id.split("_")[1];
          if ($("#inputRow_" + inputId).css("display") !== "none") {
            if ($("#inputGrp_" + inputId).val() === "") {
              $("#gerror_" + inputId).removeClass("hidden_div");
              targetError = true;
            }
            if ($("#inputEntity_" + inputId).val() === "") {
              $("#error_" + inputId).removeClass("hidden_div");
              targetError = true;
            }
            targetName = [];
            formObj.groups.map((e) => {
              targetName.push({ grp: e.grp, target: e.target.split(",") });
            });
          }
        });
        formObj.targetName = JSON.stringify(targetName);
        if (formValidationSuccess && !targetError) {
          formData.append("formBody", JSON.stringify(formObj));
          const res = await AdminServiceRI.get().createRICleansedDataset(formObj);
          // Add api call with post
          if (res.CODE) {
            const body = {};
            toggleEditModal(body);
            getTargetList();
            actions.resetForm();
            displayToastMessage(polyglot.t("Your request has submitted successfully", "success"));
          } else {
            displayToastMessage(polyglot.t(res.MSG), "error");
          }
        }
      }
      if (formObj.status == "3") {
        if (formValidationSuccess) {
          // CONNECING WITH BACKEND PYTHON FASTAPI

          const resClientuserid = await AdminServiceRI.get().getClientUserID();
          if (resClientuserid.CODE) {
            if (resClientuserid.CODE == 1) {
            } else {
              displayToastMessage(polyglot.t(resClientuserid.MSG), "error");
            }
          }
        }
      }
      if (formObj.status == "4") {
        const res = await AdminServiceRI.get().updateRIRequestStatus(formObj);
        if (res.CODE) {
          const body = {};
          toggleEditModal(body);
          getTargetList();
          actions.resetForm();
          displayToastMessage(polyglot.t("Your request has submitted successfully", "success"));
        } else {
          displayToastMessage(polyglot.t(res.MSG), "error");
        }
      }
    } catch (err) {
      displayToastMessage(polyglot.t(err.message), "error");
    }
  };

  // CHECK IF SUBMIT BUTTON IS DISABLED
  const isSubmitDisabled = () => {
    let isDisabled = false;
    if (selectedStatus === "1") {
      // i.e. status = Request placed
      isDisabled = true;
    }
    return isDisabled;
  };

  // REMOVE FILE OBJECT
  const removeFileObj = () => {
    setFileObj({});
  };

  const GroupsItem = ({ index, ctx, frm }) => {
    // Binding `isCompleted` using index of todos array
    const [contentField, contentMeta, contentHelpers] = useField(`groups[${index}].grp`);
    const [contentFieldTarget, contentMetaTarget, contentHelpersTarget] = useField(`groups[${index}].target`);
    const onContentInput = (e) => {
      contentHelpers.setValue(e);
      if (e === "") {
        $("#gerror_" + index).removeClass("hidden_div");
      } else {
        $("#gerror_" + index).addClass("hidden_div");
      }
    };
    const onContentBlur = () => {
      contentHelpers.setTouched(true);
    };
    const onContentInputTarget = (e) => {
      contentHelpersTarget.setValue(e);
      if (e === "") {
        $("#error_" + index).removeClass("hidden_div");
      } else {
        $("#error_" + index).addClass("hidden_div");
      }
    };
    const onContentBlurTarget = () => {
      contentHelpersTarget.setTouched(true);
    };
    const removeRow = (c) => {
      frm.setFieldValue(
        "groups",
        frm.values.groups.filter((g) => g.id !== c.id),
      );
    };

    return (
      <div className="row  inputrow" id={"inputRow_" + index}>
        <div className="col-md-6">
          <Input
            inputBoxSize="sm"
            value={ctx.grp}
            onChange={onContentInput}
            onBlur={onContentBlur}
            id={"inputGrp_" + index}
          />
          <div id={"gerror_" + index} className="error hidden_div">
            {polyglot.t("Group name is required")}
          </div>
        </div>
        <div className="col-md-5">
          <Input
            inputBoxSize="sm"
            value={ctx.target}
            onChange={onContentInputTarget}
            onBlur={onContentBlurTarget}
            id={"inputEntity_" + index}
          />
          <div id={"error_" + index} className="error hidden_div">
            {polyglot.t("Target name is required")}
          </div>
        </div>
        <div className="col-md-1">
          <ButtonIcon
            onClick={() => removeRow(ctx)}
            iconName={"icon-circle-minus-outline"}
            ariaLabel={polyglot.t("Remove")}
            id={"inputBtn_" + index}
          />
        </div>
      </div>
    );
  };

  useEffect(() => {}, [formBody]);

  if (Object.keys(formBody).length) {
    return (
      <>
        <Formik onSubmit={submitForm} initialValues={initialValues} validationSchema={validationSchema}>
          {(formikProps) => {
            const { values, handleChange, handleSubmit, errors, touched } = formikProps;

            // CANCEL THE FORM
            const handleCancel = () => {
              const body = {};
              toggleEditModal(body);
              getTargetList();
              formikProps.resetForm();
            };

            if (selectedStatus == undefined || selectedStatus == "") {
              setSelectedStatus(formBody.status);
            }
            setCurrentTableauUrl(formBody.tableauURL);

            setTagsEntity(formBody.entities);

            return (
              <CustomModal
                size="md"
                key={formBody.id}
                show={show}
                onCancel={handleCancel}
                title={`${polyglot.t("Details for request ID")}: ${formBody.id}`}
                Footer={[
                  <ButtonPrimary
                    size="lg"
                    className="primary_button ml-4"
                    onClick={handleSubmit}
                    key={1}
                    disabled={isSubmitDisabled(values)}
                  >
                    {polyglot.t("Save")}
                  </ButtonPrimary>,
                ]}
              >
                <div className="row mrgnBtm10">
                  <div className="col-md-4 labelDiv">
                    <span className="editTest-align">{`${polyglot.t("Status")}  *:`}</span>
                  </div>
                  <div className="col-md-8">
                    <Select
                      placeholder={polyglot.t("Status")}
                      value={selectedStatus}
                      onSelect={(value) => {
                        handleChange("status")({ target: { value } });
                        setSelectedStatus(value);
                        handleStatus(value);
                      }}
                      aria-label={polyglot.t("Status")}
                    >
                      <SelectGroupTitle>{polyglot.t("Status")} </SelectGroupTitle>
                      {statusList.map((each, index) => {
                        return (
                          <SelectOption key={index + 1} value={each.ID}>
                            {each.value}
                          </SelectOption>
                        );
                      })}
                    </Select>
                    {errors.status && touched.status && (
                      <div className="error">{polyglot.t(errors.status)}</div>
                    )}
                  </div>
                </div>

                <div id="cleenDataset">
                  <div className="row mrgnBtm10">
                    <div className="col-md-4 labelDiv">
                      <span className="editTest-align">{`${polyglot.t("Entities")}  *:`}</span>
                    </div>
                    <div className="col-md-8">
                      {tagsEntity && (
                        <TagsInput
                          value={tagsEntity}
                          inputProps={{
                            className: "react-tagsinput-input",
                            placeholder: "",
                          }}
                          disabled
                        />
                      )}
                    </div>
                  </div>
                  <div id="cleanDataset-status2">
                    <FieldArray name="groups">
                      {({ form, ...fieldArrayHelpers }) => {
                        const onAddClick = () => {
                          fieldArrayHelpers.push({
                            id: values.groups.length,
                            grp: "",
                            target: "",
                          });
                        };

                        return (
                          <React.Fragment>
                            <div className="row mrgnBtm10">
                              <ButtonPrimaryWithIcon
                                onClick={onAddClick}
                                className="ml-3"
                                iconName={plusCircle}
                                ariaLabel={polyglot.t("Add Group")}
                              >
                                {polyglot.t("Add Group")}
                              </ButtonPrimaryWithIcon>
                            </div>
                            {form.values.groups.map((e, index) => (
                              <GroupsItem key={index} index={index} ctx={e} frm={form} />
                            ))}
                          </React.Fragment>
                        );
                      }}
                    </FieldArray>
                  </div>
                </div>
                <div id="processing">
                  <div className="row mrgnBtm10">
                    <div className="col-md-4 labelDiv">
                      <span className="editTest-align">{`${polyglot.t("Generate query")}  *:`}</span>
                    </div>
                    <div className="col-md-8">
                      <Select
                        placeholder={polyglot.t("Generate query")}
                        value={values.generateQueryStatus}
                        onSelect={(value) => {
                          handleChange("generateQueryStatus")({
                            target: { value },
                          });
                        }}
                        aria-label={polyglot.t("Generate query")}
                      >
                        <SelectGroupTitle>{polyglot.t("Generate query")} </SelectGroupTitle>
                        <SelectOption key={1} value={"false"}>
                          No
                        </SelectOption>
                        <SelectOption key={2} value={"true"}>
                          Yes
                        </SelectOption>
                      </Select>
                      {errors.generateQueryStatus && touched.generateQueryStatus && (
                        <div className="error">{polyglot.t(errors.generateQueryStatus)}</div>
                      )}
                    </div>
                  </div>
                  <div className="row mrgnBtm10">
                    <div className="col-md-4 labelDiv">
                      <span className="editTest-align">{`${polyglot.t("Start initial Run")}  *:`}</span>
                    </div>
                    <div className="col-md-8">
                      <Select
                        placeholder={polyglot.t("Start initial Run")}
                        value={values.initialRun}
                        onSelect={(value) => {
                          handleChange("initialRun")({ target: { value } });
                        }}
                        aria-label={polyglot.t("Start initial Run")}
                      >
                        <SelectGroupTitle>{polyglot.t("Start initial Run")} </SelectGroupTitle>
                        <SelectOption key={3} value={"false"}>
                          No
                        </SelectOption>
                        <SelectOption key={4} value={"true"}>
                          Yes
                        </SelectOption>
                      </Select>
                      {errors.initialRun && touched.initialRun && (
                        <div className="error">{polyglot.t(errors.initialRun)}</div>
                      )}
                    </div>
                  </div>
                  <div className="row mrgnBtm10">
                    <div className="col-md-4 labelDiv">
                      <span className="editTest-align">{`${polyglot.t("Schedule daily download")}  *:`}</span>
                    </div>
                    <div className="col-md-8">
                      <Select
                        placeholder={polyglot.t("Schedule daily download")}
                        value={values.scheduleDownload}
                        onSelect={(value) => {
                          handleChange("scheduleDownload")({ target: { value } });
                        }}
                        aria-label={polyglot.t("Schedule daily download")}
                      >
                        <SelectGroupTitle>{polyglot.t("Schedule daily download")} </SelectGroupTitle>
                        <SelectOption key={5} value={"false"}>
                          No
                        </SelectOption>
                        <SelectOption key={6} value={"true"}>
                          Yes
                        </SelectOption>
                      </Select>
                      {errors.scheduleDownload && touched.scheduleDownload && (
                        <div className="error">{polyglot.t(errors.scheduleDownload)}</div>
                      )}
                    </div>
                  </div>
                </div>
                <div id="dashboard">
                  <div className="row mrgnBtm10">
                    <div className="col-md-4 labelDiv">
                      <span className="editTest-align">{`${polyglot.t("Tableau Url")}  *:`}</span>
                    </div>
                    <div className="col-md-8">
                      <Input
                        inputBoxSize="sm"
                        name="tableauUrl"
                        onChange={handleChange("tableauUrl")}
                        value={values.tableauUrl}
                      />

                      {errors.tableauUrl && touched.tableauUrl ? (
                        <div className="error">{polyglot.t(errors.tableauUrl)}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </CustomModal>
            );
          }}
        </Formik>
      </>
    );
  }
  return <></>;
}

export default ProcessGridEdit;
