//admin Solution
import "react-multi-carousel/lib/styles.css";
import { Checkbox, Divider, List, ListItem } from "appkit-react";
import PropTypes from "prop-types";
import React, { useContext, useState } from "react";
import Carousel from "react-multi-carousel";

import SolutionPackagesModal from "./SolutionPackagesModal";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
import { AdminCarousal } from "../../../Json/solutions.json";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { customNumberFormat } from "../../../utility/CustomNumberFormat";
import GetPriceInGermanFormat from "../Root/GetPriceInGermanFormat";

// STEP 3 OF CLIENT CREATION
function AdminSolution(props) {
  let { solutionPackage, solutionList, solutionId, DfPricing } = props;
  const [show, setShow] = useState(false);
  const { getUserLanguage } = useContext(DataContext);
  const [carousal] = useState(AdminCarousal);
  var polyglot = polyglotLoader(getUserLanguage());
  // CHECK IF DF PACKAGE IS SELECTED
  const checkIfDFPackageIsSelected = (item) => {
    var Solutionlist = [...solutionList];
    const currentSolution = Solutionlist.find((r) => r.id === item.solutionid);
    if (currentSolution && currentSolution.packages) {
      if (currentSolution.packages.find((r) => r.id === item.id)) {
        return true;
      }
    }
    return false;
  };

  // CHECK SOLUTION SELECTED
  const checkSolutionSelected = (solution) => {
    if (solutionList.length > 0) {
      let selectedSolutions = solutionList.filter((r) => r.isselected === 1);
      let Solution = selectedSolutions.filter((r) => r.id === solution.id);
      if (Solution.length > 0) {
        return true;
      } else {
        return false;
      }
    }
  };
  // toggle package field, if solution is selected & deselected
  const togglePackage = (solutionObj, isChecked) => {
    /*
			if solution id is connected global intelligence === 4,
			then user will not choose any package. The system should automatically assign a package to the user
		*/
    const GI_SolutionID = 4;
    if (solutionObj.id === GI_SolutionID && isChecked) {
      // user has selected the GI solution
      const packagesOfGISolution = solutionPackage.filter((each) => each.solutionid === GI_SolutionID);
      const defaultSelectedPackage = packagesOfGISolution.length > 0 ? packagesOfGISolution[0] : {};
      const selectedPackage = defaultSelectedPackage;
      // run add package function here to add and toggle value to the key 'add' to the solution list
      AddPackage(solutionId);
      return selectedPackage;
    } else if (solutionObj.id === GI_SolutionID && !isChecked) {
      // user has deselected the GI solution
      // run add package function here to add and toggle value to the key 'add' to the solution list
      AddPackage(solutionId);
      return {};
    }
    // IF SOLUTION IS NOT DF and NOT GI SOLUTION
    if (solutionObj.id !== 2 && solutionObj !== GI_SolutionID) {
      const packageClone = solutionObj.package;
      // IF NO PACKAGE IS SELECTED
      if (!solutionObj.package) {
        return {};
      }
      // IF PACAKGE IS ALREADY SELECTED ON SOLUTION
      if (Object.keys(solutionObj.package).length > 0) {
        // DESELECT THIS PACKAGE FROM SOLUTIONPACKAGE LIST
        toggleSolutionPackage(solutionObj.package, solutionObj.id);
        return {};
      }
      return packageClone;
    }
    // IF SELECTED SOLITION IS DF
    if (solutionObj.id === 2) {
      const packageClone = solutionObj.packages;
      // IF NO PACKAGE IS SELECTED
      if (!solutionObj.packages) {
        return [];
      }
      // IF PACAKGE IS ALREADY SELECTED ON SOLUTION
      if (solutionObj.packages.length > 0) {
        // DESELECT THIS PACKAGE FROM SOLUTIONPACKAGE LIST
        const firstPackageOfList = solutionObj.packages[0];
        toggleSolutionPackage(firstPackageOfList, solutionObj.id);
        return [];
      }
      return packageClone;
    }
  };
  // HANDLE SOLUTION
  const handleSolution = (item, event) => {
    const isChecked = event.target.checked;
    var list = [...solutionList];
    list.map((res) => {
      if (res.id === item.id) {
        // mark the solution as selected or deselected
        res.isselected = res.isselected === 1 ? 0 : 1;
        if (res.id !== 2) {
          res.package = togglePackage(res, isChecked);
        }
        if (res.id === 2) {
          res.packages = togglePackage(res, isChecked);
        }
      }
    });
    props.updateClientFormData("solutionList", list);
    props.updateClientFormData("solutionId", item.id);
  };

  // CHECK SOLUTION PACKAGES
  const CheckSolutionPackages = (solutionPackage) => {
    var Solutionlist = [...solutionList];
    let Ispackage = false;
    Solutionlist.map((each) => {
      if (solutionPackage.solutionid === each.id) {
        if (each.package != undefined && each.package.id === solutionPackage.id) {
          Ispackage = true;
        } else {
          Ispackage = false;
        }
      }
    });
    return Ispackage;
  };

  // used to check/uncheck solution package of a solution
  const toggleSolutionPackage = (packageObj, solutionId) => {
    var list = [...solutionPackage];
    list.map((res) => {
      if (res.solutionid === solutionId) {
        if (res.id === packageObj.id) {
          res.isselected = res.isselected === 1 ? 0 : 1;
        } else {
          res.isselected = 0;
        }
      }
    });
    props.updateClientFormData("solutionPackage", list);
  };

  //SolutionPackage singleselect
  const handleSolutionPackageSingle = (item, solutionId) => {
    var Solutionlist = [...solutionList];
    Solutionlist.map((res) => {
      if (res.id === solutionId) {
        res.package = item;
      }
    });
    props.updateClientFormData("solutionList", Solutionlist);
    toggleSolutionPackage(item, solutionId);
    props.updateClientFormData("solutionPackageid", item.id);
  };

  //selct DF packages
  const handleSolutionPackage = (item, solutionId) => {
    let packages = [];
    var Solutionlist = [...solutionList];
    Solutionlist.map((res) => {
      if (res.id === item.solutionid) {
        if (!res.packages) {
          packages.push(item);
          res.packages = packages;
        } else if (res.packages.length === 0) {
          res.packages.push(item);
        } else {
          const initialPackages = [];
          packages = initialPackages.concat([{ ...item }]);
          res.packages = packages;
        }
      }
    });
    props.updateClientFormData("solutionList", Solutionlist);
    toggleSolutionPackage(item, solutionId);
    props.updateClientFormData("solutionPackageid", item.id);
  };

  // TOGGLE PACKAGE POPUP
  const CloseModal = () => {
    setShow((prev) => !prev);
  };

  // SHOW PACKAGES
  const ShowPackages = (item) => {
    setShow(true);
    props.updateClientFormData("solutionId", item.id);
  };

  // ADD PACKAGE
  const AddPackage = (solutionId) => {
    let solutions = [...solutionList];
    solutions.map((each) => {
      if (each.id === solutionId) {
        each.add = each.add === 1 ? 0 : 1;
      }
    });
    props.updateClientFormData("solutionList", solutions);
  };
  return (
    <div className="admin-solutions">
      {show ? (
        <SolutionPackagesModal
          show={show}
          CloseModal={CloseModal}
          solutionId={solutionId}
          handleSolutionPackage={handleSolutionPackage}
          checkIfDFPackageIsSelected={checkIfDFPackageIsSelected}
          CheckSolutionPackages={CheckSolutionPackages}
          customNumberFormat={customNumberFormat}
          handleSolutionPackageSingle={handleSolutionPackageSingle}
          GetPriceInGermanFormat={GetPriceInGermanFormat}
          solutionPackage={solutionPackage}
          AddPackage={AddPackage}
          DfPricing={DfPricing}
          solutionList={solutionList}
        />
      ) : null}
      <div className="Admin-caurosal">
        <span className="main_title_qb m-l-1-4">{polyglot.t("Solutions")}</span>
        {solutionList && solutionList.length > 0 && (
          <>
            <Carousel swipeable={true} responsive={carousal}>
              {solutionList.map((solution, index) => {
                return (
                  <div
                    key={index}
                    className="card Admin-cardStyle"
                    style={{
                      borderTop: "5px solid",
                      borderTopColor: solution.body.cardColor,
                    }}
                  >
                    <div className="Admin-card-body Admin-divrelative">
                      <div className="display-flex">
                        <div>
                          <h5 className="subtitle_title_qb">{solution.body.header}</h5>
                        </div>

                        <div>
                          {
                            <Checkbox
                              checked={checkSolutionSelected(solution)}
                              onChange={(e) => handleSolution(solution, e)}
                              aria-label={polyglot.t("choose solution")}
                              className="check-list-alignment"
                            />
                          }
                        </div>
                      </div>
                      <Divider className="divider Admin-solu-divider" />
                      <p className="f-acc-B break-word">{solution.body.body.title}</p>
                      <div className="row padding-align">
                        <div className="col-md-12">
                          {solution.body.body.data.map((item, index) => {
                            return (
                              <div className="row" key={index}>
                                <List key={item.id}>
                                  <ListItem key={item.id}>
                                    <span className="appkiticon icon-check-mark-fill pointer cursor-default"></span>
                                    <span className="f-acc-B break-word">{item.Description}</span>
                                  </ListItem>
                                </List>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      {solution.availablitystatus && checkSolutionSelected(solution) === true ? (
                        <div className="p-l-0 mt-3 ">
                          <ButtonSecondary
                            onClick={() => ShowPackages(solution)}
                            ariaLabel={polyglot.t("Choose Package")}
                          >
                            {polyglot.t("Choose Package")}
                          </ButtonSecondary>
                        </div>
                      ) : null}
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </>
        )}
        <b />
      </div>
    </div>
  );
}
AdminSolution.propTypes = {
  history: PropTypes.object,
  solutionPackage: PropTypes.array,
  solutionList: PropTypes.array,
  solutionId: PropTypes.number,
  updateClientFormData: PropTypes.func,
  DfPricing: PropTypes.array,
};
export default AdminSolution;
