import React from "react";
import { Switch } from "react-router-dom";

import ProtectedRoute from "./ProtectedRoute";
import AssessmentGridView from "../Components/GRC/Assessment/AssessmentGridView";
import AssessmentListView from "../Components/GRC/Assessment/AssessmentListView";
import AssessmentHome from "../Components/GRC/Assessment/Pages/AssessmentHome";
import AssessmentView from "../Components/GRC/Assessment/Pages/AssessmentView";
import ClientQuestions from "../Components/GRC/Assessment/Pages/ClientQuestions";
import Create from "../Components/GRC/Assessment/Pages/Create";
import Preview from "../Components/GRC/Assessment/Pages/Preview";
import RecipientAssessmentShare from "../Components/GRC/Assessment/Pages/RecipientAssessmentShare";
import SelectQuestions from "../Components/GRC/Assessment/Pages/SelectQuestions";
import Category from "../Components/GRC/Category/Category";
import GRCAdmin from "../Components/GRC/GRCAdmin/grc-admin";
import Home from "../Components/GRC/Home/Home";
import QuestionContainer from "../Components/GRC/Question/QuestionContainer";
import Questionnaire from "../Components/GRC/Questionnaire/questionnaire";
import RecipientResponse from "../Components/GRC/RecipientResponse/Pages/RecipientResponse";
import RecipientResponseDetails from "../Components/GRC/RecipientResponse/Pages/RecipientResponseDetails";
import * as path from "../Constants/Path";

// ROUTES OF GRC MODULE
const GRCModuleRoutes = () => {
  return (
    <Switch>
      <ProtectedRoute path={path.CREATE_GRC_HOME} component={Home} />

      <ProtectedRoute path={path.ASSESSMENT_LIST_VIEW_PATH} component={AssessmentListView} />
      <ProtectedRoute path={path.ASSESSMENT_GRID_VIEW_PATH} component={AssessmentGridView} />
      <ProtectedRoute path={path.CREATE_QUESTION_PATH} component={QuestionContainer} />
      <ProtectedRoute path={path.CREATE_CATEGORY_PATH} component={Category} />
      <ProtectedRoute path={path.CREATE_ASSESSMENT_PATH} component={AssessmentHome} />

      <ProtectedRoute path={path.RECIPIENT_RESPONSE_PATH} component={RecipientResponse} />
      <ProtectedRoute
        path={`${path.RECIPIENT_RESPONSE_DETAILS_PATH}/:uid`}
        component={RecipientResponseDetails}
      />

      <ProtectedRoute path={path.ASSESSMENT_CLIENT_QUESTIONS_PATH} component={ClientQuestions} />
      <ProtectedRoute path={path.ASSESSMENT_CREATE_PATH} component={Create} />
      <ProtectedRoute path={`${path.ASSESSMENT_VIEW_PATH}/:id`} component={AssessmentView} />
      <ProtectedRoute path={path.ASSESSMENT_PREVIEW_PATH} component={Preview} />
      <ProtectedRoute path={path.ASSESSMENT_RECIPIENT_SHARE_PATH} component={RecipientAssessmentShare} />
      <ProtectedRoute path={path.ASSESSMENT_SELECTQUESTIONS_PATH} component={SelectQuestions} />
      <ProtectedRoute path={path.CREATE_QUESTIONNAIRE_PATH} component={Questionnaire} />
      <ProtectedRoute path={path.CREATE_GRCADMIN_PATH} component={GRCAdmin} />
    </Switch>
  );
};

export default GRCModuleRoutes;
