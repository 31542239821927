/* eslint-disable no-dupe-class-members */
import HttpClient from "../../Shared/HttpClient";

class ProjectService {
  constructor() {
    this.client = new HttpClient();
  }
  /* API TO GET PROJECT FORM DETAILS */
  async getProjectFormDetails() {
    const result = await this.client.get("/getProjectFormDetails");
    return result;
  }

  async getProjectSolutionUsersEdit(data) {
    const result = await this.client.post("/getProjectSolutionUsersEdit", data);
    return result;
  }

  async getProjectSolutionMileStonesEdit(projectFormData) {
    const result = await this.client.post("/getProjectSolutionMileStonesEdit", projectFormData);
    return result;
  }
  async getProjectFormByUser() {
    const result = await this.client.get("/getProjectFormByUser");
    return result;
  }
  /* GET ACTIVATED AND NOT ACTIVATED SOLUTIONS BASED ON THE LANGUAGE */
  async getProjectSolutions(langauge) {
    const result = await this.client.get(`/getProjectSolutions?langauge=${langauge}`);
    return result;
  }
  /* API TO GET PROJECTS OF LOGGEDIN USER */
  async getProjectSolutionUserList() {
    const result = await this.client.get("/getProjectSolutionUserList");
    return result;
  }

  async getProjectSolutionPackages(data) {
    const result = await this.client.post("/getProjectSolutionPackages", data);
    return result;
  }
  async updateProjectSolutionUsers(data) {
    const result = await this.client.post("/updateProjectSolutionUsers", data);
    return result;
  }

  /* Not using the below API
	async  getProjectSolutionUsers(data) {
		const result = await this.client.post(
			'/getProjectSolutionUsers',data);
		return result;
	} */
  /* Not using the below API
	async  getProjectSolutionMileStones() {
		const result = await this.client.get(
			'/getProjectSolutionMileStones');
		return result;
	} */
  /* Not using the below API
	async  getProjectMilestoneUsers() {
		const result = await this.client.get(
			'/getProjectMilestoneUsers');
		return result;
	} */

  async buyProjectSolutionPackage(packageData) {
    const result = await this.client.post("/buyProjectSolutionPackage", packageData);
    return result;
  }
  async updateProjectFormDetails(projectFormData) {
    const result = await this.client.post("/updateProjectFormDetails", projectFormData);
    return result;
  }
  async updateProjectSolution(projectFormData) {
    const result = await this.client.post("/updateProjectSolution", projectFormData);
    return result;
  }

  async updateProjectMilestone(projectFormData) {
    const result = await this.client.post("/updateProjectMilestone", projectFormData);
    return result;
  }
  /* Not using the below API
	async getProjectSolutionMileStones(projectFormData){  
		const result = await this.client.post(
			'/getProjectSolutionMileStones',
			projectFormData
		);
		return result;  
	} */

  /* API TO CREATE A PROJECT
	PASSING:
	projectFormData: formObj
	*/
  async createProject(projectFormData) {
    const result = await this.client.post("/createProject", projectFormData);
    return result;
  }
  /* CHECK IF PROJECT NAME ALREADY EXISTS
	PASSING: projectName: string
	*/
  async CheckProjectName(name) {
    const result = await this.client.get(`/CheckProjectName?name=${name}`);
    return result;
  }

  /* GET PROJECT FORM DATA BY PROJECT ID
	PASSING:
	projectid: string
	*/
  async getProjectFormDataById(projectId) {
    const body = { projectId };
    const result = await this.client.post("/getProjectFormDataById", body);
    return result;
  }
  /* API TO UPDATE PROJECT
	PASSING:
	projectID: string,
	projectFormData: object
	*/
  async updateProject(projectFormData, projectID) {
    const body = {
      projectFormData,
      projectID,
    };
    const result = await this.client.post("/updateProject", body);
    return result;
  }

  // API TO GET LIST OF PROJECTS WITH PROJECTID AND NAME
  async getProjectsDetailsWithSolution() {
    const result = await this.client.dfget("/getProjectsDetailsWithSolution");
    return result;
  }

  async getProjectsListBySolution(solution) {
    const body = {
      solution,
    };
    const result = await this.client.post("/getProjectList", body);
    return result;
  }
  /* GET PROJECT NAME FROM PROJECT ID
	PASSING: projectId: string
	*/
  async getProjectNameFromID(projectId) {
    const result = await this.client.get(`/getProjectNameFromID/${projectId}`);
    return result;
  }
  // GET SOLUTION NAMES OF A PROEJCT ID
  async getSolutionNameFromProjectID(projectId) {
    const result = await this.client.post(`/getSolutionNamesByProjectID`, { projectid: projectId });
    return result;
  }
}

// Enable Singleton behavior on this class
ProjectService.__instance = null;
ProjectService.get = () => {
  if (!ProjectService.__instance) {
    ProjectService.__instance = new ProjectService();
  }
  return ProjectService.__instance;
};

export default ProjectService;
