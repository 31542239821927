import React, { useContext, useEffect } from "react";
import Loadable from "react-loadable";
import LoadingIndicator from "../../../Components/QueenBase/Root/LoadingIndicator";
import NoAccess from "../../../Assets/Components/NoAccess";
import { DataContext } from "../../../Services/Shared/Store";
import { hasViewPermission } from "../../../Services/Shared/RoleHandler";
import ProtectedRoute from "../../../Routes/ProtectedRoute";
import { getProjectID_Of_solution_module } from "../../../Services/Shared/Handlers";

// RENDERS ON PATH /ri/home
export default (props) => {
  // FETCH DATA FROM CONTEXT
  const { checkAndFetchRole, getCurrentUserPermissions } = useContext(DataContext);
  // RUNS ON INITIAL LOAD
  useEffect(() => {
    const projectId = getProjectID_Of_solution_module();
    const screenName = "Home";
    // TODO send Risk Intelligence
    checkAndFetchRole(screenName, "Connected Risk Intelligence", projectId);
  }, []);
  const currentUserPermissions = getCurrentUserPermissions();
  return (
    <>
      {/* RENDER PATH IF USER HAS PERMISSION TO VIEW */}
      {hasViewPermission(currentUserPermissions) && (
        <ProtectedRoute
          path="/"
          component={Loadable({ loader: () => import("./Home"), loading: LoadingIndicator })}
        />
      )}
      {/* SHOW 'NO ACCESS' IF USER DOES NOT HAVE PERMISSION TO VIEW */}
      {!hasViewPermission(currentUserPermissions) && <NoAccess />}
    </>
  );
};
