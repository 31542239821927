import React from "react";
import CreatableSelect from "react-select/creatable";
import "./FormField.css";

class FormSingleSelectAutoComplete extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      val: this.props.preFillSelectWithDescription
        ? this.props.preFillSelectWithDescription[this.props.fillKey][0]
        : "",
      value: "",
      colourOptions: this.props.options,
      selectedOptionVal: "",
    };
  }

  componentDidMount() {
    if (
      this.props.setPositionValue &&
      this.props.preFillSelectWithDescription &&
      this.props.preFillSelectWithDescription[this.props.fillKey][0]
    ) {
      this.handleChange(
        {
          label: this.props.preFillSelectWithDescription[this.props.fillKey][0],
          value: this.props.preFillSelectWithDescription[this.props.fillKey][0],
          __isNew__: true,
        },
        { action: "create-option" },
      );
    }
  }

  handleChange = (newValue, actionMeta) => {
    this.setState({
      val: newValue,
    });
    if (newValue && newValue.value) {
      this.props.onChangeFormField(this.props.name, newValue.value, actionMeta.action);
    }
  };
  handleInputChange = (inputValue, actionMeta) => {
    if (inputValue !== "") {
      this.setState({
        val: inputValue,
      });
      this.props.onChangeFormField(this.props.name, inputValue, actionMeta.action);
    }
  };

  render() {
    return (
      <div>
        <CreatableSelect
          placeholder={this.props.placeholder}
          value={this.state.val}
          isClearable={this.props.isClearable}
          onChange={this.handleChange}
          onInputChange={this.handleInputChange}
          options={this.state.colourOptions}
          classNamePrefix="custom-creatable-select"
          isDisabled={this.props.disabledTextFields}
          {...this.props}
        />
      </div>
    );
  }
}

export default FormSingleSelectAutoComplete;
