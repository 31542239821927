import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "appkit-react";
import "../../../Assets/Css/DFED/CustodianTerms.css";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import { DataContext } from "../../../Services/Shared/Store.js";
import { InfoCircleOutlined } from "@ant-design/icons";
import ButtonPrimary from "../../../Assets/Components/ButtonPrimary/ButtonPrimary";
import ButtonSecondary from "../../../Assets/Components/ButtonSecondary/ButtonSecondary";
class TermsAndConditions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleTermsModel: false,
    };
    this.closeTermAndCondition = this.closeTermAndCondition.bind(this);
  }
  static contextType = DataContext;
  showModal = () => {
    this.setState(
      {
        visibleTermsModel: true,
      },
      () => {},
    );
  };
  handleConfirm = (e) => {
    this.setState({
      visibleTermsModel: false,
    });
  };

  closeTermAndCondition() {
    this.props.closeCustodian();
  }

  render() {
    var polyglot = polyglotLoader();
    return (
      <div className="">
        <div className="row  titleText_2  justify">
          <span className="tex-ali">{this.props.name}</span>
        </div>
        <div className="row ">
          <div className="col-md-10 col-lg-8 Cust-terms-data p-0 m-l-1">
            <div className="row mt-3">
              <div className="col-md-1 col-sm-2 col-xs-2">
                {/* <InfoCircleOutlined style={{ fontSize: "150%" }} /> */}
              </div>
              <div className="col-md-11 paddingLeft-zero paddingPoint5em-auto">
                <div className="DF-review-content">
                  {polyglot.t(
                    "Please ensure that data protection procedures are followed. If in doubt, consult your data protection officer before uploading any data or get in touch with a PwC data protection expert.",
                  )}
                </div>
              </div>
            </div>

            <div className="row ">
              <div className="col-md-1 col-sm-2 col-xs-2 tex-ali-c jus-con-c p-o m-0"></div>
              <div className="col-md-10 col-sm-10 col-xs-10 p-0 tex-ali-l"></div>
              <div className="col-md-1"></div>
            </div>
            <div className="row mt-3">
              <div className="col-md-1"></div>
              <div className="col-md-4 p-l-0">
                <ButtonPrimary onClick={this.props.checkBoxHandler} ariaLabel={polyglot.t("Proceed")}>
                  {polyglot.t("Proceed")}
                </ButtonPrimary>
              </div>
              <div className="col-md-4">
                <ButtonSecondary onClick={this.closeTermAndCondition} ariaLabel={polyglot.t("Cancel")}>
                  {polyglot.t("Cancel")}
                </ButtonSecondary>
              </div>
              <div className="col-sm-3"></div>
            </div>
          </div>
          <div className="col-md-2 col-lg-4"></div>
        </div>
        <div>
          <Modal className="form-modal" visible={this.state.visibleTermsModel} onCancel={this.handleConfirm}>
            <ModalHeader>
              <div className="a-modal-example-header">
                <div className="modal-title-left">
                  <div className="modal-title">
                    {" "}
                    <h5>{polyglot.t("Terms And Conditions")}</h5>
                  </div>
                </div>
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="row m-l-0 m-r-0">
                <div className="col-12">
                  <div>
                    <div className="flex-fill"></div>
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="button-comp">
                <Button kind="transparent" onClick={this.handleConfirm} size="lg">
                  {polyglot.t("Cancel")}{" "}
                </Button>
              </div>
            </ModalFooter>
          </Modal>
        </div>
      </div>
    );
  }
}
export default TermsAndConditions;
