import React, { useState, useEffect } from "react";

import AssessmentService from "../../../../Services/GRC/AssessmentService";
import { useHttpClient } from "../../../../Services/GRC/http-hook";

import "../../../../Assets/Css/GRC/style.css";
import { Panel } from "appkit-react";

import { polyglotLoader } from "../../../../Services/Shared/TranslateGRC";
import ErrorPanel from "../../Shared/ErrorPanel";
import { ReactComponent as LoadingSpinner } from "../../../../Assets/Images/GRC/loading-spin.svg";
import AssessmentViewAnswers from "../AssessmentViewAnswers";
import AssessmentViewTree from "../AssessmentViewTree";
import AssessmentViewTreeText from "../AssessmentViewTreeText";
import "./index.css";

/**
 * Landing page assessment section,display all list of assessments
 *
 * @param {props} props
 * @returns
 */
const AssessmentView = (props) => {
  const { isLoading, error, sendRequest, clearError } = useHttpClient();
  const id = props.match.params.id;
  const mapA = {};
  const rootsA = [];
  const polyglot = polyglotLoader();
  const [prewviewAssessment, setPrewviewAssessment] = useState([]);
  const [setid, setsetid] = useState("");
  const [loadComponent, setloadComponent] = useState("");

  const getSelectedQuestionaries = async () => {
    try {
      const id = props.match.params.id;

      const responseData = await sendRequest(AssessmentService.get().getAssessmentPreviewByIdURL(id));

      if (responseData.length > 0) {
        setsetid(responseData[0].value);
      }
      const list = responseData;

      list.forEach((v, i) => {
        mapA[v.value] = i;
        list[i].children = [];
      });

      list.forEach((v1, i) => {
        v1.tagLebel !== 0 ? list[mapA[v1.tagLebel]].children.push(v1) : rootsA.push(v1);
      });
      setPrewviewAssessment(rootsA);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    getSelectedQuestionaries();
  }, [sendRequest]);

  function handleContainerLoad(e, questionId, loadCompType) {
    setloadComponent(loadCompType);
    setsetid(questionId);
  }

  return (
    <div className="row m-0">
      <div className="col-md-3 col-sm-12 pl-0">
        <Panel className="panel h-100 top-spacing">
          <div className="row mt-1 pb-2 block">
            <div className="col-md-12 ml-n3">
              <h4 className="left-panel-heading ml-3">{polyglot.t("List of items")}</h4>
              <hr className="ruler"></hr>
              {error && <ErrorPanel error={error} onClear={clearError} />}
              {isLoading && (
                <div>
                  <LoadingSpinner width={64} height={64} fill={"#ef3829"} />
                </div>
              )}
              {!isLoading && prewviewAssessment && (
                <AssessmentViewTree
                  data={prewviewAssessment}
                  handleChanged={handleContainerLoad}
                  setid={setid}
                  component={loadComponent}
                  assessmentId={id}
                />
              )}
            </div>
          </div>
        </Panel>
      </div>

      <div className="col-md-9 usertoadd-responsive p-0 mt-3 pr-3">
        <Panel className="panel p-0">
          {loadComponent === "ques"
            ? prewviewAssessment.length > 0 && <AssessmentViewAnswers id={setid} setsetid={setsetid} />
            : prewviewAssessment.length > 0 && <AssessmentViewTreeText id={setid} />}
        </Panel>
      </div>
    </div>
  );
};

export default AssessmentView;
