/* eslint-disable no-mixed-spaces-and-tabs */
import "../../../../Assets/Css/DF/Solutions/Solution.css";
import { Panel, Radio } from "appkit-react";
import PropTypes from "prop-types";
import React, { useContext } from "react";

import { DataContext } from "../../../../Services/Shared/Store";

// COMPONENT WHICH HAS A CARD WITH RADIO BUTTON
const CardWithRadio = (props) => {
  const { formGotOpened, isFormOpen } = useContext(DataContext);
  const { item, selectedItem, handleCheck } = props;

  // change the form status and redirect to the next screen
  const changeFormStatusAsCardSelected = () => {
    if (!isFormOpen) {
      formGotOpened();
    }
  };

  // CHECK IF RADIO IS SELECTED
  const checkIsSelected = () => {
    let isSelected = false;
    if (item.survey_id === selectedItem) {
      isSelected = true;
    }
    return isSelected;
  };
  // HANDLE CHANGE ON SELECTION OF RADIO BUTTON
  const handleChange = (selectedObj) => {
    handleCheck("survey_id", selectedObj.survey_id);
    changeFormStatusAsCardSelected();
  };
  return (
    <div onClick={() => handleChange(item)} className="col-md-4 mb-3">
      <Panel className={`card-body-df df-respo  ${checkIsSelected() && "highlightCard"}`}>
        <div className="radio-df">
          <Radio
            aria-label="checked"
            role="none"
            checked={checkIsSelected()}
            onChange={() => handleChange(item)}
          />
        </div>
        <div>
          <div style={{ textAlign: "center" }} className="subtitle_title_qb">
            {item.title}
          </div>
          <div className="subtitle_three_title_qb_light">{item.description}</div>
        </div>
      </Panel>
    </div>
  );
};

CardWithRadio.propTypes = {
  item: PropTypes.object,
  selectedItem: PropTypes.any,
  handleCheck: PropTypes.any,
};

export default CardWithRadio;
