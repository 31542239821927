import { getUserIDFromSession } from "./Authenticate";

// SEND FILE TO API
const sendRequest = (file, URL, catchProgress, body) => {
  return new Promise((resolve, reject) => {
    const req = new XMLHttpRequest();

    req.upload.addEventListener("progress", (event) => {
      if (event.lengthComputable) {
        const copy = {};
        copy[file.name] = {
          status: "pending",
          percentage: (event.loaded / event.total) * 100,
        };
        catchProgress(copy);
      }
    });

    req.onload = function () {
      const copy = {};
      if (req.readyState === 4 && req.status === 200) {
        copy[file.name] = {
          status: "done",
          percentage: 100,
        };
        catchProgress(copy);
        resolve(true); // success
      } else if (req.readyState === 4 && req.statusText !== "OK") {
        // Error while uploading
        copy[file.name] = {
          status: "error",
          percentage: 0,
        };
        catchProgress(copy);
        resolve(false); // failed
      }
    };

    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", file.name);

    req.open("POST", URL);
    req.setRequestHeader("Access-Control-Allow-Origin", "*");
    req.setRequestHeader("Authorization", "Bearer " + sessionStorage.getItem("jwt"));
    req.setRequestHeader("loggedInUserId", getUserIDFromSession());
    req.setRequestHeader("paramsbody", body);
    req.send(formData);
  });
};

/*
   - - - - NOTE: data formats - - - -
  URL = string
  FILES = array of file objects
  catchProgress = function to catch the progress event
  body = stringified object
*/
// UPLOAD BUIK FILES TO API
export const upload_bulk_files_to_api = async (URL, FILES, catchProgress, body) => {
  const promises = [];
  FILES.forEach((file) => {
    promises.push(sendRequest(file, URL, catchProgress, body));
  });
  try {
    const uploadStatus = await Promise.all(promises);

    let isAPISuccess = true;
    uploadStatus.forEach((status) => {
      if (!status) {
        isAPISuccess = false;
      }
    });

    if (isAPISuccess) {
      return {
        CODE: 1,
        MSG: "Successfully uploaded",
      };
    } else {
      return {
        CODE: 0,
        MSG: "Upload failed!! Something went wrong",
      };
    }
  } catch (err) {
    return {
      CODE: 0,
      MSG: err,
    };
  }
};
