import { Panel, Tag, Tooltip } from "appkit-react";
import React, { useContext, useState, useEffect } from "react";
import { withRouter } from "react-router-dom";

import ButtonIcon from "../../../Assets/Components/ButtonIcon/ButtonIcon";
import { infoIcon, barChartIcon, uploadIcon, downloadIcon } from "../../../Assets/Icons";
import Loader from "../../../Assets/Images/DFED/icon-loader.svg";
import ProjectSurveyService from "../../../Services/DF/ProjectSurveyService";
import { getDisplayDate } from "../../../Services/Shared/Handlers";
import { hasViewPermission, hasEditPermission } from "../../../Services/Shared/RoleHandler";
import { DataContext } from "../../../Services/Shared/Store";
import { polyglotLoader } from "../../../Services/Shared/Translate";
import displayToastMessage from "../../QueenBase/Root/displayToastMessage";
import WorkflowDetails from "../ProjectSurveys/WorkflowDetails";
import {
  setWorkflowID_Of_DF_module,
  getWorkflowStatusName,
  setWorkflowName_Of_DF_module,
  removeWorkflow_Details_Of_DF_module,
} from "../handlers";

// WORKFLOW CARD
const WorkflowCard = (props) => {
  const polyglot = polyglotLoader();

  const [showInfo, setShowInfo] = useState(false);
  const [showCardLoader, setShowCardLoader] = useState(false);

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    // remove workflow id and name from the session on the initial load
    removeWorkflow_Details_Of_DF_module();
  }, []);

  // TOGGLE WORKFLOW INFO
  const toggleInfo = () => {
    setShowInfo((prev) => !prev);
  };

  const { getCurrentUserPermissions } = useContext(DataContext);
  const currentUserPermissions = getCurrentUserPermissions();
  const { Item, index, setLoading } = props;

  // ON EDIT FUNCTION
  const onEdit = () => {
    if (Item.parameter_file_status === 3) {
      props.history.push("/df/upload/form");
      setWorkflowName_Of_DF_module(Item.workflow_name);
      setWorkflowID_Of_DF_module(Item.project_survey_id);
    } else {
      displayToastMessage(polyglot.t("Your admin has to update parameter file to proceed"), "info");
    }
  };
  // ON CLICKING UPLOAD ICON FUNCTION
  const onUpload = () => {
    const statusName = getWorkflowStatusName(Item);
    if (statusName === "Dashboard available") {
      displayToastMessage(
        polyglot.t("Your dashboard is already present. Hence, you cannot upload a file"),
        "error",
      );
    }
    if (Item.parameter_file_status === 3) {
      props.history.push("/df/upload/form", { showUploadScreen: true });
      setWorkflowName_Of_DF_module(Item.workflow_name);
      setWorkflowID_Of_DF_module(Item.project_survey_id);
    } else {
      displayToastMessage(polyglot.t("Your admin has to update parameter file to proceed"), "info");
    }
  };

  // API TO GET WORKFLOW DASHBOARD
  let recallDashboardTokenCounter = 0;
  const getQlikViewURL = async (dashboardurl, groupname) => {
    setShowCardLoader(true);
    const recallCounter = recallDashboardTokenCounter + 1;
    recallDashboardTokenCounter = recallCounter;
    if (recallCounter >= 4) {
      displayToastMessage(polyglot.t("Unable to open dashboard URL, please try again"), "info");
      setShowCardLoader(false);
    } else {
      try {
        // API TO GET TOKEN ON DASHBOARD URL
        const res = await ProjectSurveyService.get().getQlikDashboardURL(groupname);
        setShowCardLoader(false);
        if (res.CODE) {
          const dashboard_URL = `${dashboardurl}${res.token}`;
          window.open(dashboard_URL);
          recallDashboardTokenCounter = 0;
        } else {
          if (res.MSG === "Gateway Timeout") {
            viewDashboard();
          } else {
            displayToastMessage(res.MSG, "error");
          }
        }
      } catch (err) {
        displayToastMessage(err.message, "error");
      }
    }
  };

  // VIEW DASHBOARD
  const viewDashboard = () => {
    if (Item.dashboard_url) {
      getQlikViewURL(Item.dashboard_url, Item.groupname);
    } else {
      displayToastMessage(polyglot.t("No dashboard available"), "info");
    }
  };
  // STATUS TAG
  const statusTag = () => {
    const statusName = getWorkflowStatusName(Item);
    switch (statusName) {
      case "Parameter file requested":
        return <Tag className="df-ns">{polyglot.t("Parameter file requested")}</Tag>;
      case "In progress":
        return <Tag className="df-ip">{polyglot.t("In progress")}</Tag>;
      case statusName:
        return <Tag className="df-pfa">{polyglot.t(statusName)}</Tag>;
    }
  };
  const TooltipName = (Item) => {
    return (
      <div>
        <table>
          <tbody>
            <tr>
              <td
                style={{
                  textAlign: "center",
                  fontWeight: "bold",
                  color: "#000",
                  textTransform: "capitalize",
                }}
              >
                {Item.workflow_name}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  };

  // CHECK IF UPLOAD BUTTON CAN BE SEEN ON THE UI
  const shouldShowUploadButton = () => {
    try {
      let showButton = true;
      const statusName = getWorkflowStatusName(Item);
      switch (statusName) {
        case "Parameter file requested":
          showButton = false;
          break;
        case "In progress":
          showButton = false;
          break;
        case "Dashboard available":
          // hide upload buton
          showButton = false;
          break;
        case "Pushed to processing":
          // hide upload button
          showButton = false;
          break;
        default:
          showButton = true;
      }
      return showButton;
    } catch (err) {
      return false;
    }
  };

  // CHECK IF DOWNLOAD BUTTON CAN BE SEEN ON THE UI
  const shouldShowDownloadButton = () => {
    try {
      let showButton = true;
      const statusName = getWorkflowStatusName(Item);
      switch (statusName) {
        case "Parameter file requested":
          showButton = false;
          break;
        case "In progress":
          showButton = false;
          break;
        default:
          showButton = true;
      }
      return showButton;
    } catch (err) {
      return false;
    }
  };

  return (
    <div className="row m-0">
      <div className="col-md-8 Projecttab_n  Queen-project-lg-view pl-0 pr-0">
        <Panel className="a-panel-project workflow-card-body a-mb-10">
          <div className="a-text-heading">
            <div className="row alignItemsCenter">
              <div className="col-md-3">
                <Tooltip content={TooltipName(Item)} placement="top">
                  <div className="card-name-overflow-DF project-data-desc mb-0">{Item.workflow_name}</div>
                </Tooltip>
              </div>
              <div className="col-md-2">
                <span className="workflow-date-css">{getDisplayDate(Item.created_at)}</span>
              </div>
              <div className="col-md-4 display-inline-table">{statusTag()}</div>

              <div className="col-md-3">
                <div className="row m-auto">
                  {hasViewPermission(currentUserPermissions) && (
                    <ButtonIcon
                      alt="More info"
                      title={polyglot.t("More info")}
                      onClick={toggleInfo}
                      iconName={infoIcon}
                      ariaLabel={polyglot.t("More info")}
                    />
                  )}
                  {hasEditPermission(currentUserPermissions) && shouldShowDownloadButton() && (
                    <ButtonIcon
                      title={polyglot.t("Download")}
                      onClick={onEdit}
                      alt="Download"
                      iconName={downloadIcon}
                      ariaLabel={polyglot.t("Download")}
                    />
                  )}
                  {hasEditPermission(currentUserPermissions) && shouldShowUploadButton() && (
                    <ButtonIcon
                      title={polyglot.t("Upload")}
                      onClick={onUpload}
                      alt="Upload"
                      iconName={uploadIcon}
                      ariaLabel={polyglot.t("Upload")}
                    />
                  )}
                  {hasViewPermission(currentUserPermissions) && (
                    <>
                      {Item.dashboard_url && (
                        <ButtonIcon
                          iconName={barChartIcon}
                          title={polyglot.t("View dashboard")}
                          onClick={viewDashboard}
                          alt="View dashboard"
                          ariaLabel={polyglot.t("View dashboard")}
                        />
                      )}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Panel>
      </div>
      <div className="mb-3">
        {showCardLoader && <img width="40px" className="move-left" src={Loader} alt="loading..." />}
      </div>
      {showInfo && <WorkflowDetails show={showInfo} item={Item} toggleInfo={toggleInfo} />}
    </div>
  );
};

export default withRouter(WorkflowCard);
