import { Button, Input } from "appkit-react";
import React, { useContext } from "react";

import "../../../Assets/Css/QueenBase/Root/Login.css";
import * as Yup from "yup";

import { LoginContext } from "./LoginContext";
import { DataContext } from "../../../Services/Shared/Store.js";
import { polyglotLoader } from "../../../Services/Shared/Translate";

import { Formik } from "formik";
function IdamLogin(props) {
  const { successfulLogin } = useContext(LoginContext);
  const { getUserLanguage } = useContext(DataContext);
  var polyglot = polyglotLoader(getUserLanguage());
  const initialValues = {
    email: "",
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email(polyglot.t("Invalid email")).required(polyglot.t("Email is Required")),
  });
  // const loginwithIdam = async (email) => {
  //     const res = await LoginService.get().loginwithIdam({...email});
  //     if (res.CODE) {
  //          successfulLogin(res);
  //          props.history.push(path.QUEEN_BASE_PATH);

  //     } else {
  //     	displayToastMessage(res.MSG, 'error');
  //     }
  // };
  const backToLogin = () => {
    window.location.reload();
  };
  return (
    <div>
      <Formik
        // onSubmit={loginwithIdam}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {(prop) => {
          const { values, handleChange, handleSubmit, errors, touched } = prop;
          return (
            <>
              <div className="loginBackground">
                <div className="display-flex">
                  <div className="container">
                    <div className="ScreenFit responsive-ipad">
                      <div className="card bg-light mb-3 CardPosition ">
                        <div className="card-header header_login Header_resposnive_login">
                          <h1 className="headerText_Login">{polyglot.t("Welcome to Queen")}</h1>
                        </div>
                        <div className="card-body user-login">
                          <span className="font-weight-medium text-nowrap user-label">
                            {polyglot.t("Account")}
                          </span>
                          <div className="col-md-12"></div>
                          <Input
                            inputBoxSize="sm"
                            placeholder={polyglot.t("Email *")}
                            value={values.email}
                            onChange={handleChange("email")}
                            aria-label={polyglot.t("Email *")}
                          />
                          {errors.email && touched.email ? <div className="error">{errors.email}</div> : null}

                          <br />
                          <div className="row">
                            <div className="col-md-5">
                              <Button
                                className="sign-txt prime-button"
                                type="submit"
                                onClick={handleSubmit}
                                aria-label={polyglot.t("Log in")}
                              >
                                {polyglot.t("Log in")}
                              </Button>
                            </div>
                            <div className="col-md-7">
                              <p className="ForgotPassword">
                                <a onClick={backToLogin} aria-label={polyglot.t("Back to login")}>
                                  {polyglot.t("Back to login")}
                                </a>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 ScreenFit"></div>
                  </div>
                </div>
              </div>
            </>
          );
        }}
      </Formik>
    </div>
  );
}

export default IdamLogin;
