import $ from "jquery";

export const ScrollToComponent = (Component) => {
  $(document).ready(function () {
    let Scroll = document.getElementsByClassName(Component);
    if (Scroll.length) {
      $("html,body").animate(
        {
          scrollTop: $(`.${Component}`).offset().top,
        },
        "slow",
      );
    }
  });
  return null;
};
