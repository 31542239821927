import "../../Assets/Css/DF/Home.css";
import { Col, Row } from "antd";
import { Divider, Panel } from "appkit-react";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";

import { StartContext, projectSurveyInitialObj } from "./Start/Contexts/StartContext";
import WorkflowList from "./Upload/WorkflowList";
import ButtonPrimaryWithIcon from "../../Assets/Components/ButtonPrimary/ButtonPrimaryWithIcon";
import LinkText from "../../Assets/Components/LinkText/LinkText";
import { plusCircle } from "../../Assets/Icons";
import ChevronDown from "../../Assets/Icons/down-chevron-outline.svg";
import ChevronUp from "../../Assets/Icons/up-chevron-outline.svg";
import SolutionService from "../../Services/QueenBase/Solutions/SolutionService";
import { getProjectID_Of_solution_module } from "../../Services/Shared/Handlers";
import { hasCreatePermission, hasViewPermission } from "../../Services/Shared/RoleHandler";
import { DataContext } from "../../Services/Shared/Store";
import { polyglotLoader } from "../../Services/Shared/Translate";
import displayToastMessage from "../QueenBase/Root/displayToastMessage";

// HOME COMPONENT OF DF SCREENS
const Home = (props) => {
  const { getCurrentUserPermissions, getUserLanguage } = useContext(DataContext);
  const { ProjectSurvey, setProjectSurvey, setDFCurrentPackages } = useContext(StartContext);
  const { setProjectID } = useContext(StartContext);
  const [DFPackagesList, setDFPackagesList] = useState([]);
  var polyglot = polyglotLoader();
  const language = getUserLanguage();
  const projectId = getProjectID_Of_solution_module();

  const currentUserPermissions = getCurrentUserPermissions();

  // FETCH PACKAGE DETAILS OF DF
  const GetDfPackages = async () => {
    try {
      const solutionObj = {
        solutionId: 2,
        language,
      };
      const data = await SolutionService.get().getDfSolutionPackages(solutionObj);
      if (data.CODE === 0) {
        displayToastMessage(data.MSG, "error");
      } else {
        const initalDFPackages = [];
        let obj = {};
        data.forEach((r) => {
          obj = { ...r };
          obj.isHide = true;
          initalDFPackages.push(obj);
        });
        setDFPackagesList(initalDFPackages);
        setDFCurrentPackages(initalDFPackages);
      }
    } catch (error) {
      displayToastMessage(error, "error");
    }
  };

  // RUNS ON INITIAL LOAD
  useEffect(() => {
    const projectSurvey = { ...projectSurveyInitialObj }; // projectSurvey will be replaced with API data
    projectSurvey.project_id = projectId;
    projectSurvey.is_existing_project = 0;
    projectSurvey.time_scope_from = "";
    projectSurvey.time_scope_to = "";
    projectSurvey.uploaded_file = "";
    projectSurvey.uploaded_file_path = "";

    setProjectSurvey(projectSurvey);
  }, [projectId, setProjectSurvey]);
  useEffect(() => {
    GetDfPackages(language);
  }, [language]);

  // TOGGLE SHOW MORE OF A PACKAGE
  const toggleItemHide = (itemId) => {
    const listClone = [];
    let analysisObj = {};
    DFPackagesList.forEach((item) => {
      analysisObj = { ...item };
      if (item.PlanID === itemId) {
        analysisObj.isHide = !item.isHide;
      }
      listClone.push(analysisObj);
    });
    setDFPackagesList(listClone);
  };

  // DISPLAY LIST OF NOTES OF A PACAKGE
  const displayList = (jsonArray) => {
    return JSON.parse(jsonArray).map((item, index) => (
      <li key={"displayList" + index} className="para-style f-acc-B">
        <span className="appkiticon icon-check-mark-fill icon-color"></span>
        &nbsp;&nbsp;
        {item.Note}
      </li>
    ));
  };

  // DISPLAY LIST OF WORKFLOWS
  const displayProgress = () => {
    return (
      <div className="home-progressCard">
        <WorkflowList projectId={projectId} tabName="DF-Home" />
      </div>
    );
  };

  // DISPLAY LIST OF PACKAGES AS A CARD VIEW
  const displayPackages = () => {
    return DFPackagesList.map((item, index) => {
      return (
        <div key={"displayPackage" + index} className="col-md-8 p-0">
          <Panel
            className={`df-panel-height analysisCard ${!item.isHide && "expand"}`}
            style={{ borderLeft: "5px solid", borderLeftColor: "#ffb600" }}
          >
            <Row>
              <Col span={16}>
                <div className="subtitle_two_title_qb">{item.title}</div>
              </Col>
              <Col span={8} />
            </Row>
            <p className=" f-acc-B df-cc-temp-text para-style">{item.description}</p>
            {!item.isHide && <ul>{displayList(item.Details)}</ul>}
            <Divider />
            <LinkText
              onClick={() => toggleItemHide(item.PlanID)}
              className="expand-df-pacakge"
              ariaLabel={!item.isHide ? polyglot.t("Less details") : polyglot.t("See more")}
            >
              {!item.isHide ? polyglot.t("Less details") : polyglot.t("See more")}{" "}
              <img
                width={16}
                height={16}
                src={!item.isHide ? ChevronUp : ChevronDown}
                alt="toggle"
                aria-label={!item.isHide ? polyglot.t("compress") : polyglot.t("expand")}
              />
            </LinkText>
          </Panel>
        </div>
      );
    });
  };

  // START CREATING A WORKFLOW
  const letsStart = () => {
    props.history.push({ pathname: "/df/start", state: { selectedProjectId: projectId } });
    setProjectID(projectId);
  };

  return (
    <div className="p-0 m-l-1-15">
      <>
        <div className="full-width">
          <div className="col-md-4 DF-mt-3 p-0">
            {hasCreatePermission(currentUserPermissions) && (
              <ButtonPrimaryWithIcon
                onClick={letsStart}
                iconName={plusCircle}
                ariaLabel={polyglot.t("Add a workflow")}
              >
                {polyglot.t("Add a workflow")}
              </ButtonPrimaryWithIcon>
            )}
          </div>
        </div>
        <div className="full-width">{hasViewPermission(currentUserPermissions) && displayProgress()}</div>
        <div className="mt-3 full-width">
          <h1 className="main_title_qb">{polyglot.t("ModulesAnalysis")}</h1>
        </div>
        {displayPackages()}
      </>
    </div>
  );
};

Home.propTypes = {
  history: PropTypes.any,
  location: PropTypes.object,
};

export default Home;
